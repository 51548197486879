import React from 'react'

import Dialog from 'components/Mui/Dialog'
import PhotoViewAllHeader from './PhotoViewAllHeader'
import PhotoViewAllBody from './PhotoViewAllBody'

export default ({ claimId, portfolios }) => {
  return (
    <Dialog
      noActions
      noForm
      symbol='__photoViewAllAction'
      paperProps={{
        style: {
          maxWidth: '60%'
        }
      }}
      title={<PhotoViewAllHeader claimId={claimId} portfolios={portfolios} />}
      content={<PhotoViewAllBody claimId={claimId} portfolios={portfolios} />}
    />
  )
}