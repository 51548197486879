import React from 'react'
import Dialog from 'components/Mui/Dialog'
import { Formik, Form } from 'formik'
import MoneyInput from 'components/Formik/MoneyInput'
import Grid from 'components/Mui/Grid'
import InfoBox from 'components/Mui/InfoBox'
import Button from 'components/Mui/Button'
import { graphql } from 'relay-hooks'
import { useMutation } from 'react-relay-mutation'
import { useFeeReloadContext } from '../../FeeReloadContext.js'

const mutation = graphql`
  mutation FeeItemEditDialogMutation($input: FeeItemEditInput!, $where: FeeItemUniqueWhere!) {
    feeItemEdit(input: $input, where: $where) {
      messages
      success
    }
  }
`

export default ({ symbol, feeDescription, supplier, feeItemId, authorisedAmount, authorisedMaxAmount }) => {
  const snackbar = window[Symbol.for('__snackbar')]
  const [editItem] = useMutation(mutation)
  const { reload, setReload } = useFeeReloadContext()
  return (
    <Dialog
      noForm
      noActions
      symbol={symbol}
      title="Edit Approved Item"
      content={
        <Formik
          initialValues={{ authorisedAmount }}
          validate={values => {
            let errors = {}

            if(!values.authorisedAmount) errors.authorisedAmount = 'Required!'
            if(String(values.authorisedAmount).length > (9 + 3)) errors.authorisedAmount = 'Maximum character limit is 9'
            if (authorisedMaxAmount !== null && authorisedMaxAmount !== undefined && values.authorisedAmount > authorisedMaxAmount) {
              errors.authorisedAmount = `The amount is over the limit`
            }

            return errors
          }}
          onSubmit={(values, actions) => {
            setTimeout(async () => {
              const res = await editItem({
                variables: {
                  input: {
                    authorisedAmount: parseFloat(values.authorisedAmount),
                  },
                  where: {
                    feeItemId,
                  }
                }
              })
              if(res) {
                actions.resetForm({
                  authorisedAmount: authorisedAmount
                })
                snackbar.toggleOpen({
                  message: res.feeItemEdit.messages[0]
                })
                if(res.feeItemEdit.success) {
                  setReload(!reload)
                  if(window[Symbol.for(symbol)]) {
                    window[Symbol.for(symbol)].handleClose()
                  }
                }
              } else {
                snackbar.toggleOpen({
                  message: 'Action has not succeed'
                })
              }
              actions.setSubmitting(false)
            }, 300)
          }}
        >
          {
            ({ isSubmitting, values, touched, setFieldValue }) => {
              return (
                <Form>
                  <Grid fluid justify='flex-end'>
                    {[
                      {
                        col: 4, label: 'Approved Item',
                        text: feeDescription,
                        component: InfoBox, name: 'feeType',
                        unmountOn: false
                      },
                      {
                        col: 4, label: 'Supplier',
                        text: supplier,
                        component: InfoBox, name: 'feeType',
                        unmountOn: false
                      },
                      {
                        col: 4, label: 'Approved Amount',
                        component: MoneyInput, name: 'authorisedAmount',
                        required: true,
                        unmountOn: false
                      },
                    ].map(({col, component: Component, unmountOn, readOnly=false, ...rest}, key) => (
                      !unmountOn && <Grid item xs={col} key={key}>
                        {Component && <Component readOnly={readOnly} {...rest}/>}
                      </Grid>
                    ))}
                    <Grid xs={1}>
                      <Button fullWidth label='Close'
                        disabled={isSubmitting}
                        onClick={() => {
                          if(window[Symbol.for(symbol)]){
                            window[Symbol.for(symbol)].handleClose()
                          }
                        }}
                      />
                    </Grid>
                    <Grid xs={1}>
                      <Button
                        fullWidth
                        disabled={isSubmitting}
                        label='Save'
                        type='submit'
                        variant='contained'
                        color='primary'
                      />
                    </Grid>
                  </Grid>
                </Form>
              )
            }
          }
        </Formik>
      }
    />
  )
}