/**
 * @flow
 * @relayHash bece133e9bb066e072f904ad91429fdf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimScoreFormWhere = {|
  id: string
|};
export type SurveyQueryVariables = {|
  scoreFormWhere?: ?ClaimScoreFormWhere
|};
export type SurveyQueryResponse = {|
  +claimScoreForm: ?{|
    +claimRef: ?string,
    +id: string,
    +logo: ?string,
    +supplierName: ?string,
    +ratingCompleted: boolean,
  |}
|};
export type SurveyQuery = {|
  variables: SurveyQueryVariables,
  response: SurveyQueryResponse,
|};
*/


/*
query SurveyQuery(
  $scoreFormWhere: ClaimScoreFormWhere
) {
  claimScoreForm(where: $scoreFormWhere) {
    claimRef
    id
    logo
    supplierName
    ratingCompleted
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "scoreFormWhere",
    "type": "ClaimScoreFormWhere",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimScoreForm",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "scoreFormWhere",
        "type": "ClaimScoreFormWhere"
      }
    ],
    "concreteType": "ClaimScoreForm",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "claimRef",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "logo",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplierName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ratingCompleted",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SurveyQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SurveyQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SurveyQuery",
    "id": null,
    "text": "query SurveyQuery(\n  $scoreFormWhere: ClaimScoreFormWhere\n) {\n  claimScoreForm(where: $scoreFormWhere) {\n    claimRef\n    id\n    logo\n    supplierName\n    ratingCompleted\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '32809d5438aa774055d5c6a61797d311';
module.exports = node;
