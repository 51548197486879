import React, { Component } from 'react'
import { parseFile } from 'common/utils'
import { withRouter } from 'react-router-dom'

import { Formik, Form } from 'formik'
import Input from 'components/Formik/Input'
import Switch from 'components/Formik/Switch'
import { blacklistedExtensions } from 'common/static_data'

import Dialog from 'components/Mui/Dialog'
import Button from 'components/Mui/Button'
import Grid from 'components/Mui/Grid'
import UploadLossAdjusterActionMutation from './UploadLossAdjusterActionMutation'

class UploadLossAdjusterAction extends Component {
  snackbar = window[Symbol.for('__snackbar')]
  inputRef = React.createRef()
  render() {
    const { dlRef, claimId, ...rest } = this.props
    return (
      <Dialog
        noForm
        noActions
        innerRef={dlRef}
        title='Upload Document'
        paperProps={{
          style: { maxWidth: '65%' }
        }}
        content={
          <Formik
            initialValues={{
              file: '',
              private: false,
              description: '',
            }}
            validate={(values) => {
              let errors = {}
              if(!values.description) errors.description = 'Required!'
              if(!values.file) errors.file = 'Required!'
              // if(values.description && commonCharactersRegexp.test(values.description)) errors.description = 'Invalid character'
              if(values.description.length > 200) errors.description = 'Maximum character limit is 200'
              const { name, size } = this.inputRef.current.files[0]
              if(blacklistedExtensions.find(e => e.toUpperCase() === `.${name.split('.')[name.split('.').length - 1]}`.toUpperCase())) {
                errors.file = 'File extension is not supported'
              }

              if(size > 31457280) errors.file = 'Maximum file size is 30MB'
              if(size < 1) errors.file = 'Minimum file size is 1 byte'

              if(name.length > 60) errors.file = 'Maximum file name length is 60'
              if(name.length < 5) errors.file = 'Minimum file name length is 5'
              return errors
            }}
            onSubmit={(values, actions) => {
              setTimeout(async () => {
                const submitValues = {...values}
                const { ok, fileBase64, fileName } = await parseFile(this.inputRef.current)
                if(ok) {
                  delete submitValues.file
                  const res = await UploadLossAdjusterActionMutation({
                    ...submitValues,
                    fileBase64,
                    documentFileName: fileName,
                    claimId,
                    path: this.props.location.pathname
                  })
                  if(res.ok) {
                    if(window[Symbol.for('__uploadLossAdjuster')]){
                      window[Symbol.for('__uploadLossAdjuster')].handleClose()
                    }
                    actions.resetForm({
                      file: '',
                      private: true,
                      description: '',
                    })
                    this.snackbar.toggleOpen({
                      message: res.message[0]
                    })
                  } else {
                    this.snackbar.toggleOpen({
                      message: 'Action has not succeed'
                    })
                  }
                  actions.setSubmitting(false)
                }
              }, 400)
            }}
          >
            {({ isSubmitting, values, touched }) => {
              return (
                <Form>
                  <Grid fluid spacing={8}>
                    {
                      [
                        {
                          col: 12, label: 'Choose a file',
                          component: Input, name: 'file',
                          inputRef: this.inputRef,
                          leadIcon: 'attach_file',
                          type: 'file', required: true,
                        },
                        {
                          col: 12, label: 'File Description',
                          component: Input, name: 'description',
                          type: 'text', multiline: true,
                          rowsMax: 5,
                          required: true,
                        },
                        {
                          col: 2, label: 'Privacy',
                          component: Switch, name: 'private',
                          labelOn: 'Private',
                          labelOff: 'Public'
                        },
                        {
                          col: 7,
                        },
                        {
                          col: 3, label: 'Submit',
                          component: Button, type: 'submit',
                          fullWidth: true, color: 'primary', variant: 'contained',
                          disabled: isSubmitting,
                        }
                      ].map(({col, component: Component, ...rest}, key) => (
                        <Grid item xs={col} key={key}>
                          {Component && <Component {...rest}/>}
                        </Grid>
                      ))
                    }

                  </Grid>
                </Form>
              )
            }}
          </Formik>
        }
        {...rest}
      />
    )
  }
}

export default withRouter(UploadLossAdjusterAction)