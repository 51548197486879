/**
 * @flow
 * @relayHash dd9e97517b0fe4acd418e6c3ee8978ee
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type OnsiteMakeSafeCompletedMutationVariables = {|
  where: ENDataPortfolioKey
|};
export type OnsiteMakeSafeCompletedMutationResponse = {|
  +claimMakeSafeComplete: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +makesafeCompletedDate: ?any
    |},
  |}
|};
export type OnsiteMakeSafeCompletedMutation = {|
  variables: OnsiteMakeSafeCompletedMutationVariables,
  response: OnsiteMakeSafeCompletedMutationResponse,
|};
*/


/*
mutation OnsiteMakeSafeCompletedMutation(
  $where: ENDataPortfolioKey!
) {
  claimMakeSafeComplete(where: $where) {
    messages
    success
    result {
      makesafeCompletedDate
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "makesafeCompletedDate",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OnsiteMakeSafeCompletedMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimMakeSafeComplete",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OnsiteMakeSafeCompletedMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimMakeSafeComplete",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "OnsiteMakeSafeCompletedMutation",
    "id": null,
    "text": "mutation OnsiteMakeSafeCompletedMutation(\n  $where: ENDataPortfolioKey!\n) {\n  claimMakeSafeComplete(where: $where) {\n    messages\n    success\n    result {\n      makesafeCompletedDate\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '089074af5a237b85fcd9c650a27485de';
module.exports = node;
