/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClaimDetailsCard_claim$ref: FragmentReference;
export type ClaimDetailsCard_claim = {|
  +riskname: ?string,
  +hasContents: boolean,
  +lodgeDate: ?any,
  +contentsRefNum: ?string,
  +additionalRefNumber: ?string,
  +buildingSumInsured: ?number,
  +agent: ?{|
    +id: string,
    +companyName: string,
    +companyId: number,
  |},
  +view: ?{|
    +fields: {|
      +catCode: {|
        +label: ?string
      |},
      +eventType: {|
        +label: ?string
      |},
      +internalAssessor: {|
        +label: ?string
      |},
      +externalLossAdjustingFirm: {|
        +label: ?string
      |},
      +policyType: {|
        +label: ?string
      |},
      +policyCover: {|
        +label: ?string
      |},
      +agent: {|
        +isDisplay: boolean,
        +label: ?string,
        +isDisabled: boolean,
        +name: string,
      |},
    |}
  |},
  +insurer: ?{|
    +companyId: number,
    +contentsref: ?boolean,
    +companyName: string,
    +policy: ?boolean,
    +enableMultipleRisks: ?boolean,
    +additionalRefNo: ?boolean,
    +cm2nd: ?boolean,
    +policyTypeSuppliersView: ?boolean,
    +policyCoverSuppliersView: ?boolean,
    +quickrepair: ?boolean,
    +useInternalAssessor: boolean,
  |},
  +caseManager: ?{|
    +managerName: string,
    +managerId: number,
  |},
  +externalLossAdjuster: ?{|
    +companyName: string,
    +companyId: number,
  |},
  +incidentDetail: ?{|
    +eventType: ?{|
      +eventName: string,
      +eventTypeId: number,
    |},
    +cATCode: ?{|
      +cATCodeName: string,
      +cATCodeId: number,
    |},
  |},
  +policyType: ?{|
    +policyTypeName: string,
    +policyTypeId: ?any,
  |},
  +policyCover: ?{|
    +policyCoverName: string,
    +policyCoverId: ?any,
  |},
  +brc: ?{|
    +managerName: string,
    +managerId: number,
  |},
  +refNumber: string,
  +building: ?{|
    +authorisedSupplier: ?{|
      +companyName: string
    |},
    +scopingSupplier: ?{|
      +companyName: string
    |},
    +jobSuppliers: ?$ReadOnlyArray<?{|
      +quote: ?{|
        +supplier: ?{|
          +companyName: string
        |}
      |}
    |}>,
  |},
  +restoration: ?{|
    +authorisedSupplier: ?{|
      +companyName: string
    |},
    +scopingSupplier: ?{|
      +companyName: string
    |},
    +jobSuppliers: ?$ReadOnlyArray<?{|
      +quote: ?{|
        +supplier: ?{|
          +companyName: string
        |}
      |}
    |}>,
  |},
  +contents: ?{|
    +authorisedSupplier: ?{|
      +companyName: string
    |},
    +scopingSupplier: ?{|
      +companyName: string
    |},
    +jobSuppliers: ?$ReadOnlyArray<?{|
      +quote: ?{|
        +supplier: ?{|
          +companyName: string
        |}
      |}
    |}>,
  |},
  +hATypeVisit: ?{|
    +visitName: string,
    +typeVisitId: number,
  |},
  +homeAssessor: ?{|
    +assesorName: string,
    +assesorId: number,
  |},
  +$refType: ClaimDetailsCard_claim$ref,
|};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyId",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "managerName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "managerId",
    "args": null,
    "storageKey": null
  }
],
v5 = [
  (v0/*: any*/)
],
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "authorisedSupplier",
    "storageKey": null,
    "args": null,
    "concreteType": "Company",
    "plural": false,
    "selections": (v5/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "scopingSupplier",
    "storageKey": null,
    "args": null,
    "concreteType": "Company",
    "plural": false,
    "selections": (v5/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "jobSuppliers",
    "storageKey": null,
    "args": null,
    "concreteType": "JobSupplier",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "quote",
        "storageKey": null,
        "args": null,
        "concreteType": "JobQuote",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplier",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": (v5/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "ClaimDetailsCard_claim",
  "type": "ClaimJob",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "riskname",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasContents",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lodgeDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "contentsRefNum",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "additionalRefNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "buildingSumInsured",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "agent",
      "storageKey": null,
      "args": null,
      "concreteType": "Company",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "view",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimJobView",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "fields",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimJobField",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "catCode",
              "storageKey": null,
              "args": null,
              "concreteType": "FieldInput",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "eventType",
              "storageKey": null,
              "args": null,
              "concreteType": "FieldInput",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "internalAssessor",
              "storageKey": null,
              "args": null,
              "concreteType": "FieldInput",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "externalLossAdjustingFirm",
              "storageKey": null,
              "args": null,
              "concreteType": "FieldInput",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "policyType",
              "storageKey": null,
              "args": null,
              "concreteType": "FieldInput",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "policyCover",
              "storageKey": null,
              "args": null,
              "concreteType": "FieldInput",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "agent",
              "storageKey": null,
              "args": null,
              "concreteType": "FieldInput",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "isDisplay",
                  "args": null,
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "isDisabled",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "insurer",
      "storageKey": null,
      "args": null,
      "concreteType": "Company",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "contentsref",
          "args": null,
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "policy",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "enableMultipleRisks",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "additionalRefNo",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cm2nd",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "policyTypeSuppliersView",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "policyCoverSuppliersView",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quickrepair",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "useInternalAssessor",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "caseManager",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimManager",
      "plural": false,
      "selections": (v4/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "externalLossAdjuster",
      "storageKey": null,
      "args": null,
      "concreteType": "Company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "incidentDetail",
      "storageKey": null,
      "args": null,
      "concreteType": "IncidentDetail",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "eventType",
          "storageKey": null,
          "args": null,
          "concreteType": "IncidentEvent",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "eventName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "eventTypeId",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "cATCode",
          "storageKey": null,
          "args": null,
          "concreteType": "CATCode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cATCodeName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cATCodeId",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "policyType",
      "storageKey": null,
      "args": null,
      "concreteType": "PolicyType",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "policyTypeName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "policyTypeId",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "policyCover",
      "storageKey": null,
      "args": null,
      "concreteType": "PolicyCover",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "policyCoverName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "policyCoverId",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "brc",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimManager",
      "plural": false,
      "selections": (v4/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "refNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "building",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimPortfolio",
      "plural": false,
      "selections": (v6/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "restoration",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimPortfolio",
      "plural": false,
      "selections": (v6/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contents",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimPortfolio",
      "plural": false,
      "selections": (v6/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "hATypeVisit",
      "storageKey": null,
      "args": null,
      "concreteType": "HATypeVisit",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "visitName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "typeVisitId",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "homeAssessor",
      "storageKey": null,
      "args": null,
      "concreteType": "InternalAssesor",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "assesorName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "assesorId",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '182f91f5bfbe1c224b9d857682df17ce';
module.exports = node;
