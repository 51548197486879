import React, { Component } from "react";
import Grid from "components/Mui/Grid";
import IconButton from "components/Mui/IconButton";
import { moneyFormat } from "common/utils/";

import MakeSafeUpdateItemContainer from "../../MakeSafeActions/MakeSafeUpdateItem/MakeSafeUpdateItemContainer";
import MakeSafeDeleteItemMutation from "../../MakeSafeActions/MakeSafeDeleteItem/MakeSafeDeleteItemMutation";

class MakeSafeListItem extends Component {
  snackbar = window[Symbol.for("__snackbar")];
  updateItemDlRef = React.createRef();

  render() {
    const {
      className,
      rowItemClassName,
      actionBtnClassName,
      item,
      isAction,
      portfolios,
      meta,
      fields,
      makeSafeInfo,
    } = this.props;
    return (
      <>
        <Grid fluid className={className} spacing={0}>
          {[
            {
              col: 2,
              text: item.costCentre.costCentreName,
              unMountOn: !fields.costCentre.isDisplay,
            },
            {
              col: true,
              text: `${item.costItem.itemDescription} ${
                item.lineDescription ? item.lineDescription : ""
              }`,
              unMountOn: !fields.description.isDisplay,
            },
            {
              col: 1,
              text: item.unit.unitName,
              unMountOn: !fields.unit.isDisplay,
            },
            { col: 1, text: item.qty, unMountOn: !fields.qty.isDisplay },
            {
              col: 1,
              text: `${moneyFormat(item.rate)}`,
              unMountOn: !fields.rate.isDisplay,
            },
            {
              col: 1,
              text: `${item.marginRate}%`,
              unMountOn: !fields.marginRate.isDisplay,
            },
            {
              col: 1,
              text: `${moneyFormat(item.subtotal)}`,
              unMountOn: !fields.subtotal.isDisplay,
            },
            {
              col: 1,
              unMountOn: !fields.action.isDisplay,
              text: (
                <>
                  <IconButton
                    icon="edit"
                    className={actionBtnClassName}
                    onClick={this.editItemOpen}
                  />
                  <IconButton
                    icon="delete"
                    className={actionBtnClassName}
                    onClick={this.deleteItem}
                  />
                </>
              ),
            },
          ].map(({ col, text, unMountOn }, key) => {
            if (unMountOn) return null;
            return (
              <Grid item key={key} xs={col} className={rowItemClassName}>
                {text}
              </Grid>
            );
          })}
        </Grid>
        <MakeSafeUpdateItemContainer
          updateItemDlRef={this.updateItemDlRef}
          meta={meta}
          data={{
            id: Number(item.makeSafeItemId),
            portfolioType: portfolios,
            locationId: Number(item.location.costLocationId),
            dimensions: item.dimensions,
            costCentreId: Number(item.costCentre.costCentreId),
            costItemId: Number(item.costItem.costItemId),
            lineDescription: item.lineDescription,
            unitId: Number(item.unit.unitId),
            marginRate: Number(item.marginRate),
            qty: Number(item.qty),
            rate: Number(item.rate),
          }}
        />
      </>
    );
  }
  editItemOpen = () => {
    this.updateItemDlRef.current.handleOpen();
  };
  deleteItem = async () => {
    const { item, portfolios } = this.props;

    const res = await MakeSafeDeleteItemMutation(
      {
        where: {
          id: item.makeSafeItemId,
          portfolioType: portfolios,
        },
      },
      item.id
    );

    if (res.success) {
      this.snackbar.toggleOpen({ message: res.message });
      const ref = window[Symbol.for("__refreshMakeSafe")];
      if (ref) ref();
    }
  };
}

export default MakeSafeListItem;
