import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const mutation = graphql`
  mutation CompleteQuoteActionMutation(
    $input: JobQuoteCompleteInput!
    $where: ENDataPortfolioKey!
  ) {
    jobQuoteComplete(where: $where, input: $input) {
      messages
      success
      result {
        completed
        reviewed
        quoteStatus {
          statusId
          statusName
        }
        jobQuoteId
        completed
        reviewed
        supplier {
          companyId
        }
        id
        actions {
          addLineItem {
            label
            isDisabled
            isDisplay
          }
          makeInitialCall {
            label
            isDisabled
            isDisplay
          }
          requestQuotes {
            label
            isDisabled
            isDisplay
          }
          reviewQuote {
            label
            isDisabled
            isDisplay
          }
          submitQuote {
            label
            isDisabled
            isDisplay
          }
          resetQuote {
            label
            isDisabled
            isDisplay
          }
        }
        scopeTypes {
          locations {
            id
            location {
              id
              costLocationId
              locationName
            }
            items {
              jobQuoteItemId
              accepted
              location {
                costLocationId
                locationName
              }
              dimensions
              costCentre {
                costCentreId
                costCentreName
              }
              costItem {
                costItemId
                itemDescription
              }
              lineDescription
              unit {
                unitId
                unitName
              }
              directsupply
              purchasePrice
              ageOfItem
              proofOfLoss
              policyLimit
              qty
              rate
              marginRate
              subtotal
              logdate
              policyLimit
              requestedQuote
              sirRate
              scopeType {
                name
                id
              }
            }
          }
          scopeType {
            id
            name
          }
          id
          gst
          margin
          sirGst
          sirMargin
          sirSubtotal
          sirTotal
          subtotal
          total
          discount
          fields {
            discount {
              isDisplay
              label
              isDisabled
            }
            sIRRate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            gst {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            total {
              label
              isDisplay
            }
            action {
              label
              isDisplay
            }
            costCentre {
              label
              isDisplay
            }
            description {
              label
              isDisplay
            }
            marginRate {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            qty {
              label
              isDisplay
            }
            rate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            unit {
              label
              isDisplay
            }
          }
        }
      }
    }
  }
`;

export default (variables) => {
  delete variables.where.claimId;
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (res) => {
        resolve({
          ok: true,
          success: res.jobQuoteComplete.success,
          message: res.jobQuoteComplete.messages[0],
        });
      },
      onError: (errors) => {
        console.log(errors);
        resolve({ ok: false });
      },
    });
  });
};
