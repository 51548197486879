/**
 * @flow
 * @relayHash 1153288376e0464a76ec1fef750bc15e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobMakeSafeItemsCreate = {|
  claimId: string,
  portfolioType: PortfolioType,
  items?: ?$ReadOnlyArray<?JobMakeSafeItemCreate>,
|};
export type JobMakeSafeItemCreate = {|
  costCentreId: number,
  costItemId: number,
  lineDescription: string,
  locationId: number,
  scopeTypeId?: ?string,
  propertyTypeId?: ?string,
  propertyNumber?: ?string,
  dimensions?: ?string,
  qty: number,
  unitId: number,
  marginRate: number,
  gst?: ?number,
  rate: number,
|};
export type MakeSafeAddItemMutationVariables = {|
  input: JobMakeSafeItemsCreate
|};
export type MakeSafeAddItemMutationResponse = {|
  +createJobMakeSafeItems: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?$ReadOnlyArray<?{|
      +id: string,
      +makeSafeItemId: number,
      +location: ?{|
        +costLocationId: number,
        +locationName: string,
      |},
      +dimensions: ?string,
      +costCentre: ?{|
        +costCentreName: string,
        +costCentreId: number,
      |},
      +costItem: ?{|
        +itemDescription: string,
        +costItemId: number,
      |},
      +lineDescription: string,
      +unit: ?{|
        +unitName: string,
        +unitId: number,
      |},
      +qty: number,
      +rate: ?number,
      +marginRate: number,
      +subtotal: ?number,
    |}>,
  |}
|};
export type MakeSafeAddItemMutation = {|
  variables: MakeSafeAddItemMutationVariables,
  response: MakeSafeAddItemMutationResponse,
|};
*/


/*
mutation MakeSafeAddItemMutation(
  $input: JobMakeSafeItemsCreate!
) {
  createJobMakeSafeItems(input: $input) {
    messages
    success
    result {
      id
      makeSafeItemId
      location {
        costLocationId
        locationName
        id
      }
      dimensions
      costCentre {
        costCentreName
        costCentreId
        id
      }
      costItem {
        itemDescription
        costItemId
        id
      }
      lineDescription
      unit {
        unitName
        unitId
      }
      qty
      rate
      marginRate
      subtotal
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobMakeSafeItemsCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "JobMakeSafeItemsCreate!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "makeSafeItemId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costLocationId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "locationName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimensions",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreId",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemDescription",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costItemId",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lineDescription",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "unit",
  "storageKey": null,
  "args": null,
  "concreteType": "QuoteItemUnit",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "unitName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "unitId",
      "args": null,
      "storageKey": null
    }
  ]
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "qty",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rate",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "marginRate",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MakeSafeAddItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobMakeSafeItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobMakeSafeItemsPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobMakeSafeItem",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "location",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              },
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costCentre",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costItem",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostItem",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/)
                ]
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MakeSafeAddItemMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobMakeSafeItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobMakeSafeItemsPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobMakeSafeItem",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "location",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costCentre",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v4/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costItem",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostItem",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "MakeSafeAddItemMutation",
    "id": null,
    "text": "mutation MakeSafeAddItemMutation(\n  $input: JobMakeSafeItemsCreate!\n) {\n  createJobMakeSafeItems(input: $input) {\n    messages\n    success\n    result {\n      id\n      makeSafeItemId\n      location {\n        costLocationId\n        locationName\n        id\n      }\n      dimensions\n      costCentre {\n        costCentreName\n        costCentreId\n        id\n      }\n      costItem {\n        itemDescription\n        costItemId\n        id\n      }\n      lineDescription\n      unit {\n        unitName\n        unitId\n      }\n      qty\n      rate\n      marginRate\n      subtotal\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ce8db2a6076fb899c8634148d805554e';
module.exports = node;
