import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";

import { Cube } from "components/Mui/Loader";
import Grid from "components/Mui/Grid";

import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";
import JobInfoPresentation from "./JobInfoPresentation";

export default () => {
  const meta = React.useContext(ClaimMetaContext);

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        where: { id: parseInt(meta.claim.claimId) },
      }}
      render={({ error, props }) => {
        if (error) throw new Error(error);
        //   return (
        //   <Grid fluid style={{ height: '100px' }}>
        //     <Grid item xs>
        //       System Error!
        //     </Grid>
        //   </Grid>
        // )
        if (!props)
          return (
            <Grid fluid style={{ height: "100px" }}>
              <Grid item xs>
                <Cube />
              </Grid>
            </Grid>
          );
        return (
          <JobInfoPresentation
            claim={props.claimJob}
            meta={meta}
            actions={props.claimJob.view.actions}
          />
        );
      }}
    />
  );
};

const query = graphql`
  query JobInfoQuery($where: ENDataEntityKey!) {
    claimJob(where: $where) {
      ...ClaimDetailsCard_claim
      ...ClientInfoCard_claim
      ...QuotingBuildersCard_claim
      ...QuotingRestorersCard_claim
      claimId
      buildingSumInsured
      refNumber
      contentsRefNum
      agent {
        id
        companyName
        companyId
      }
      incidentDetail {
        vulnerableMember
        incidentDate
        hold
        eventType {
          eventTypeId
        }
        cATCode {
          cATCodeId
        }
        riskAddress {
          line1
          suburb
          state
          postcode
        }
        habitableProperty
        asbestos
        category
      }
      policyType {
        policyTypeId
      }
      policyCover {
        policyCoverId
      }
      riskname
      additionalRefNumber
      homeAssessor {
        assesorId
        assesorName
      }
      caseManager {
        managerId
      }
      externalLossAdjuster {
        companyId
      }

      building {
        excessValue
        toCollectExcess
        sumInsured
        reallocate
      }
      contents {
        excessValue
        toCollectExcess
        sumInsured
      }
      restoration {
        excessValue
        toCollectExcess
        sumInsured
        reallocate
      }

      brc {
        managerId
      }
      insured {
        salutation
        name
        phone1
        phone2
        phone3
        fax
        email
        postalAddress {
          line1
          line2
          suburb
          state
          postcode
        }
      }
      requireCustomLogin
      customLoginEmail
      insurer {
        insrefnumLength
        companyId
      }

      tenantDetails {
        name
        phone1
        phone2
        phone3
      }

      claimDescription

      additionalData {
        id
        categoryName
        items {
          id
          title
          content
        }
      }
      view {
        actions {
          cashSettle {
            id
            label
            name
            isDisabled
            isDisplay
          }
        }
      }
    }
  }
`;
