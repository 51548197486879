import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";
import { mapUserType } from "common/utils";

const ClaimMetaContext = React.createContext({ data: null });
export default ClaimMetaContext;

export class ClaimMetaProvider extends Component {
  state = {
    refresh: true,
  };
  shouldComponentUpdate = (nextProps) => {
    if (this.props.claimId !== nextProps.claimId) return true;
    return false;
  };

  componentDidMount = () => {
    window[Symbol.for("__refreshClaimDetails")] = () =>
      this.setState({ refresh: !this.state.refresh }, () => this.forceUpdate());
  };

  componentWillUnmount = () => {
    delete window[Symbol.for("__refreshClaimDetails")];
  };
  render() {
    const { children, claimId } = this.props;
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query ClaimMetaContextQuery(
            $where: ENDataEntityKey!
            $updateTypeWhere: ClaimUniqueWhere!
          ) {
            currentUser {
              actions {
                createNewClaim {
                  isDisabled
                }
              }
            }
            user: me {
              type: userRole
              access: privileges {
                menus {
                  nodeName
                  pages {
                    nodeName
                    actions {
                      nodeName
                    }
                  }
                }
              }
              permitedCompanyIDs
              company {
                companyId
              }
            }
            claim: claimJob(where: $where) {
              id
              refNumber
              claimId
              hasBuilding
              hasContents
              hasRestoration

              feeTab {
                id
                isDisplay
              }

              actions {
                actionType
                isDisplay
                isDisabled
              }

              view {
                fields {
                  savings {
                    id
                    isDisplay
                    label
                  }
                }
                tabs {
                  communicationsTab {
                    id
                    isDisplay
                    label
                  }
                  documentsTab {
                    id
                    isDisplay
                    label
                  }
                  feeTab {
                    id
                    isDisplay
                    label
                  }
                  jobInfoTab {
                    id
                    isDisplay
                    label
                  }
                  jobNotesTab {
                    id
                    isDisplay
                    label
                  }
                  jobProgressTab {
                    id
                    isDisplay
                    label
                  }
                  lossAdjusterTab {
                    id
                    isDisplay
                    label
                  }
                  makeSafeTab {
                    id
                    isDisplay
                    label
                  }
                  reportTab {
                    id
                    isDisplay
                    label
                  }
                  scopeTab {
                    id
                    isDisplay
                    label
                  }
                  siteReportTab {
                    id
                    isDisplay
                    label
                  }
                  variationsTab {
                    id
                    isDisplay
                    label
                  }
                  quotesTab {
                    id
                    isDisplay
                    label
                  }
                  quoteTab {
                    id
                    isDisplay
                    label
                  }
                  internalAssessorTab {
                    id
                    isDisplay
                    label
                  }
                  historyTab {
                    id
                    isDisplay
                    label
                  }
                }
                actions {
                  makeInitialCall {
                    isDisabled
                    isDisplay
                    label
                    id
                  }
                  makeAppointment {
                    isDisabled
                    isDisplay
                    label
                    id
                  }
                  changeAppointment {
                    isDisabled
                    isDisplay
                    label
                    id
                  }
                  externalSourceData {
                    isDisabled
                    isDisplay
                    label
                    id
                  }
                  tenderClosingDate {
                    building {
                      isDisabled
                      isDisplay
                      label
                      id
                    }
                    contents {
                      isDisabled
                      isDisplay
                      label
                      id
                    }
                    restoration {
                      isDisabled
                      isDisplay
                      label
                      id
                    }
                  }
                  updateScopeType {
                    isDisabled
                    isDisplay
                  }
                  updateLossAdjusterStatus {
                    isDisabled
                    isDisplay
                  }
                  changeSubStatus {
                    isDisplay
                    isDisabled
                    label
                  }
                  createFeedback {
                    isDisabled
                    isDisplay
                  }
                  createFollowUp {
                    isDisabled
                    isDisplay
                  }
                  addLossAdjusterDocument {
                    isDisabled
                  }
                  addClaimPortfolio {
                    isDisabled
                  }
                  awaitingInfo {
                    label
                    isDisplay
                    isDisabled
                  }
                  addJobNote {
                    isDisabled
                    isDisplay
                  }
                  addCommunication {
                    isDisabled
                    isDisplay
                  }
                  addDocument {
                    isDisabled
                    isDisplay
                  }
                  claimFinalise {
                    isDisabled
                  }
                  updateClaim {
                    isDisabled
                  }
                  reallocateSupplier {
                    building {
                      isDisabled
                      isDisplay
                    }
                    contents {
                      isDisabled
                      isDisplay
                    }
                    restoration {
                      isDisabled
                      isDisplay
                    }
                  }
                }
              }

              incidentDetail {
                riskAddress {
                  postcode
                  line1
                  state
                  suburb
                }
              }

              insured {
                phone1
                name
              }

              insurer {
                companyId
                makesafeInitialcallmade
                makesafeAppointmentmade
                makesafeWorkscompleted

                contentauth
                nonPanelSupplier
                feeModule: isFeeModule
                isSirModule

                isAutoSelectSupplier

                isVideoToolModule

                isMarginUpdate

                isSpecialistModule

                policyTypeSuppliersView
                policyCoverSuppliersView
                isReportingModule
              }

              building {
                authorised
                scopingSupplier {
                  companyId
                }
                authorisedSupplier {
                  companyId
                }
                jobSuppliers {
                  jobSupplierId
                  requestType
                  quote {
                    id
                  }
                }
                progress {
                  initialCallMade
                  appointmentBooked
                }
                claimStatus {
                  statusId
                }
                isReallocateSupplier
              }

              restoration {
                authorised
                scopingSupplier {
                  companyId
                }
                authorisedSupplier {
                  companyId
                }
                jobSuppliers {
                  jobSupplierId
                  requestType
                  quote {
                    id
                  }
                }
                progress {
                  initialCallMade
                  appointmentBooked
                }
                claimStatus {
                  statusId
                }
                isReallocateSupplier
              }

              contents {
                jobSuppliers {
                  jobSupplierId
                  requestType
                  quote {
                    id
                  }
                }
                progress {
                  initialCallMade
                  appointmentBooked
                }
                claimStatus {
                  statusId
                }
                isReallocateSupplier
              }

              actions {
                actionType
                isDisplay
                isDisplay
              }
            }
            claimUpdateTypes(where: $updateTypeWhere) {
              value: id
              label: name
              privacy
              responseRequired
            }
          }
        `}
        variables={{
          where: { id: parseInt(claimId) },
          updateTypeWhere: { claimId: parseInt(claimId) },
          refresh: this.state.refresh,
        }}
        render={({ error, props }) => {
          if (!props) return null;
          if (!props.claim) return <Redirect to="/notfound" />;

          const { user, claim } = props;
          const type = mapUserType(user.type);
          const access = mapUserAccess(user.access);
          const companyAccess = mapCompanyAccess(claim.insurer);

          return (
            <ClaimMetaContext.Provider
              value={{
                ...props,
                companyAccess,
                user: {
                  ...props.user,
                  access,
                  type,
                },
              }}
            >
              {children}
            </ClaimMetaContext.Provider>
          );
        }}
      />
    );
  }
}

export const withClaimMeta = (Comp) => {
  return function WrapperComponent(props) {
    return (
      <ClaimMetaContext.Consumer>
        {(value) => <Comp meta={value} {...props} />}
      </ClaimMetaContext.Consumer>
    );
  };
};

export const useMetaContext = () => {
  const meta = React.useContext(ClaimMetaContext);
  return meta;
};

const mapUserAccess = (access) => {
  return access.menus.reduce((total, current) => {
    const thisPage = current.pages.reduce((total, current) => {
      const thisNode = {
        [current.nodeName]: current.actions.map((x) => x.nodeName),
      };
      return total ? { ...total, ...thisNode } : { ...thisNode };
    }, {});

    const thisNode = {
      [current.nodeName.toLowerCase()]: thisPage,
    };

    return total ? { ...total, ...thisNode } : { ...thisNode };
  }, {});
};

const mapCompanyAccess = (insurer) => ({
  makeSafe: {
    initialCall: Boolean(insurer.makesafeInitialcallmade),
    appointmentMade: Boolean(insurer.makesafeAppointmentmade),
    workCompleted: Boolean(insurer.makesafeWorkscompleted),
  },
});
