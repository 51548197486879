/**
 * @flow
 * @relayHash 8c1d716a8121862f3af4b81f23886462
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type AddPortfolioInput = {|
  scopingSupplierId?: ?string,
  portfolioType: PortfolioType,
|};
export type ENDataEntityKey = {|
  id: string
|};
export type AddNewClaimTypeActionMutationVariables = {|
  input: AddPortfolioInput,
  where: ENDataEntityKey,
|};
export type AddNewClaimTypeActionMutationResponse = {|
  +claimAddPortfolio: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type AddNewClaimTypeActionMutation = {|
  variables: AddNewClaimTypeActionMutationVariables,
  response: AddNewClaimTypeActionMutationResponse,
|};
*/


/*
mutation AddNewClaimTypeActionMutation(
  $input: AddPortfolioInput!
  $where: ENDataEntityKey!
) {
  claimAddPortfolio(input: $input, where: $where) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddPortfolioInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimAddPortfolio",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AddPortfolioInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ENDataEntityKey!"
      }
    ],
    "concreteType": "ClaimPortfolioPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddNewClaimTypeActionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddNewClaimTypeActionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddNewClaimTypeActionMutation",
    "id": null,
    "text": "mutation AddNewClaimTypeActionMutation(\n  $input: AddPortfolioInput!\n  $where: ENDataEntityKey!\n) {\n  claimAddPortfolio(input: $input, where: $where) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3e92e38f9c39716926d4663dfa7d4525';
module.exports = node;
