/**
 * @flow
 * @relayHash a887bd9d38c696c6cbe1672b5e5bd70e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimScoreBoardFilter = {|
  scoreBoardId?: ?string,
  suppliers?: ?$ReadOnlyArray<string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  regions?: ?$ReadOnlyArray<?string>,
  createDateFrom: any,
  createDateTo: any,
|};
export type GetScoreCardPdfMutationVariables = {|
  where: ClaimScoreBoardFilter
|};
export type GetScoreCardPdfMutationResponse = {|
  +pdf: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type GetScoreCardPdfMutation = {|
  variables: GetScoreCardPdfMutationVariables,
  response: GetScoreCardPdfMutationResponse,
|};
*/


/*
mutation GetScoreCardPdfMutation(
  $where: ClaimScoreBoardFilter!
) {
  pdf: pdfProduceForClaimScoreBoard(where: $where) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimScoreBoardFilter!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "pdf",
    "name": "pdfProduceForClaimScoreBoard",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ClaimScoreBoardFilter!"
      }
    ],
    "concreteType": "GeneralPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GetScoreCardPdfMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GetScoreCardPdfMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "GetScoreCardPdfMutation",
    "id": null,
    "text": "mutation GetScoreCardPdfMutation(\n  $where: ClaimScoreBoardFilter!\n) {\n  pdf: pdfProduceForClaimScoreBoard(where: $where) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7207061ac46557cb30c60d038e4a4aba';
module.exports = node;
