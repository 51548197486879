import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'
import { moneyFormat } from 'common/utils/'

class MakeSafeListFooter extends Component {
  render() {
    const { classes, report, makeSafeScopeTypes } = this.props
    const fields= makeSafeScopeTypes.fields

    return (
      <Grid fluid spacing={0} className={classes.row}>
        <Grid item xs={3} className={classes.rowBlock}>
          <span className={classes.rowContent}>
            <span className={classes.title}>
              {fields.margin.label}:
            </span>
            <span className={classes.value}>
              {moneyFormat(makeSafeScopeTypes.margin)}
            </span>
          </span>
        </Grid>
        <Grid item xs={3} className={classes.rowBlock}>
          <span className={classes.rowContent}>
             <span className={classes.title}>
              {fields.subtotal.label}:
            </span>
            <span className={classes.value}>
              {moneyFormat(makeSafeScopeTypes.subtotal)}
            </span>
          </span>
        </Grid>
        <Grid item xs={3} className={classes.rowBlock}>
          <span className={classes.rowContent}>
            <span className={classes.title}>
              {fields.gst.label}:
            </span>
            <span className={classes.value}>
              {moneyFormat(makeSafeScopeTypes.gst)}
            </span>
          </span>
        </Grid>
        <Grid item xs={3} className={classes.rowBlock}>
          <span className={classes.rowContent}>
            <span className={classes.title}>
              {fields.total.label}:
            </span>
            <span className={classes.value}>
              {moneyFormat(makeSafeScopeTypes.total)}
            </span>
          </span>
        </Grid>
      </Grid>  
    )
  }
}

export default withStyles(theme => ({
  row: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    background: theme.palette.grey[300],
    borderRadius: 8
  },
  title: {
    fontWeight: 600
  },
  value: {
    marginLeft: '1rem'
  },
  rowBlock: {
    display: 'flex'
  },
  rowContent: {
    margin: '0 auto'
  }
}))(MakeSafeListFooter)