import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation SubStatusChangeMutation($input: SubStatusUpdateInput!, $where: ClaimUniqueWhere!) {
    subStatusClaimUpdate(input: $input, where: $where) {
      success
      messages
      result {
        id
        name
        subStatusId
      }
    }
  }
`

export default (variables, id) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      updater: store => {

      },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.subStatusClaimUpdate.success,
          message: res.subStatusClaimUpdate.messages[0]
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}
