import React from 'react'
import environment from 'common/relay'
import { graphql, fetchQuery } from 'relay-runtime'
import { useMutation } from 'react-relay-mutation'
import useReactRouter from 'use-react-router'

import ToggleIcon from 'material-ui-toggle-icon'
import Icon from '@material-ui/core/Icon'
import { useQuery } from 'relay-hooks'

export default ({
  loading,
  claimId, callSessionId, endLocation,
  actionConfigs, isOwner,
  kurentoActions,
  photoUploadId,
  isSupportNoDevice
}) => {
  const [photos, setPhotos] = React.useState([])
  const [uploadDocument] = useMutation(uploadDocumentMutation)
  const [uploadPhoto] = useMutation(uploadPhotoMutation)
  const takingPictureButton = {
    name: 'takingPicture',
    icon: 'add_a_photo',
    onClick: async () => {
      const photo = kurentoActions.getPhoto()
      setPhotos(previous => [...previous, photo])

      await fetch(photo)
        .then(res => res.blob())
        .then(blob => new File([blob], `${Date.now()}.png`, { type: 'image/png' }))
        .then(async file => {
          await uploadPhoto({ variables: { sessionId: photoUploadId, file } })
          if (claimId) {
            await uploadDocument({ variables: { input: {
              claimId: parseInt(claimId),
              description: `Photo ${photos.length + 1} from video session`,
              isInvoice: false,
              private: false,
              documentFileName: `${Date.now()}.jpg`,
              fileBase64: photo.replace('data:image/png;base64,', '')
            }}})
          }
        })
    }
  }

  const { history } = useReactRouter()
  const [createJobNote] = useMutation(createJobNoteMutation)
  const hangupButton = {
    name: 'hangup',
    icon: 'call_end',
    color: 'danger',
    onClick: async () => {
      if (isOwner && claimId) {
        await createJobNote({ variables: { input: { claimId: parseInt(claimId), message: 'Video session has ended' } } })

        const res = await fetchQuery(environment, videoDataQuery, { id: callSessionId })
        const room = res.callSession.room
        const root = `${window.location.protocol}//${window.location.hostname}`
        let index = 0
        for(const video of room.videos) {
          index++
          await createJobNote({ variables: { input: {
            claimId: parseInt(claimId),
            private: 1,
            message: `Video Recording Link ${index}: ${root}/video/rooms/${room.id}/${video.id}`
          }}})
        }
      }

      kurentoActions.hangup()
      history.push(endLocation)
    }
  }

  const toggleVideoButton = {
    name: 'toggleVideo',
    onClick: kurentoActions.toggleAudio,
    icon: (
      <ToggleIcon
        on={kurentoActions.audioState}
        onIcon={<Icon>mic</Icon>}
        offIcon={<Icon>mic_off</Icon>}
      />
    )
  }
  const toggleAudioButton = {
    name: 'toggleAudio',
    onClick: kurentoActions.toggleVideo,
    icon: (
      <ToggleIcon
        on={kurentoActions.videoState}
        onIcon={<Icon>videocam</Icon>}
        offIcon={<Icon>videocam_off</Icon>}
      />
    )
  }

  const actionButtons = [
    takingPictureButton,
    toggleVideoButton,
    toggleAudioButton,
    hangupButton,
  ].filter(button => {
    if (loading && button.name === 'hangup') return true
    if (loading) return false
    if (isOwner) {
      if (isSupportNoDevice && ((button.name === 'toggleVideo') || (button.name ===  'toggleAudio'))) {
        return false
      } else {
        return true
      }
    } else if (actionConfigs) {
     return actionConfigs[button.name]
    }
    return false
  })

  return {
    photos,
    actionButtons
  }
}

const createJobNoteMutation = graphql`
  mutation useActionsCreateJobNoteMutation($input: ClaimNoteCreate!) {
    createClaimNote(input: $input) {
      success
    }
  }
`

const uploadDocumentMutation = graphql`
  mutation useActionsUploadDocumentMutation($input: ClaimDocumentCreate!) {
    createClaimDocument(input: $input) {
      success
    }
  }
`

const uploadPhotoMutation = graphql`
  mutation useActionsUploadPhotoMutation($sessionId: ID!, $file: Upload!) {
    photoUpload(sessionId: $sessionId, file: $file) {
      id
    }
  }
`

const videoDataQuery = graphql`
  query useActionsVideoQuery($id: ID!) {
    callSession(id: $id) {
      room {
        id
        videos {
          id
          url
        }
      }
    }
  }
`
