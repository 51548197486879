import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import Paper from 'components/Mui/Paper'
import IconButton from 'components/Mui/IconButton'

import LineItemListHeader from 'components/LineItemList/LineItemListHeader'
import ListItemListReport from 'components/LineItemList/ListItemListReport'
import LineItemListGroup from 'components/LineItemList/LineItemListGroup'
import ReportSummaryInfo from 'components/LineItemList/ReportSummaryInfo'
import QuoteGroupItem from './QuoteGroupItem'
import QuoteGroupDiscount from './QuoteGroupDiscount'

const QuoteGroupTable = ({ classes, quote, meta, portfolio, isAction, isOwnCompany, isContentsAction, tableItem }) => {
  const [ expand, setExpand ] = React.useState(true)
  const report = {[tableItem.fields.margin.label]: tableItem.margin, [tableItem.fields.subtotal.label]: tableItem.subtotal,
    [tableItem.fields.gst.label]: tableItem.gst, [tableItem.fields.total.label]: tableItem.total}
  return (
    <Paper
      title={(
        <LineItemListHeader
          cols={[
            { col: 2, name: tableItem.fields.costCentre.label, show: tableItem.fields.costCentre.isDisplay },
            { col: true, name: tableItem.fields.description.label, show: tableItem.fields.description.isDisplay },
            { col: 1, name: tableItem.fields.sIRRate.label, show: tableItem.fields.sIRRate.isDisplay },
            { col: 1, name: tableItem.fields.unit.label, show: tableItem.fields.unit.isDisplay },
            { col: 1, name: tableItem.fields.qty.label, show: tableItem.fields.qty.isDisplay },
            { col: 1, name: tableItem.fields.rate.label, show: tableItem.fields.rate.isDisplay },
            { col: 1, name: tableItem.fields.marginRate.label, show: tableItem.fields.marginRate.isDisplay },
            { col: 1, name: tableItem.fields.subtotal.label, show: tableItem.fields.subtotal.isDisplay },
            { col: 1, name: tableItem.fields.action.label, show: tableItem.fields.action.isDisplay }
          ]}
        />
      )}
      >
      <div>

        <div className={classes.scopeTypeRow}>
          <IconButton icon={expand ? 'expand_less' : 'expand_more' }
            className={classes.expandBtn}
            onClick={() => setExpand(!expand)}
          />
          {tableItem.scopeType.name}
        </div>
        <Collapse in={expand} timeout='auto' unmountOnExit>
          <div className={classes.root}>
            {tableItem.locations.length === 0
              ? <div className={classes.noItem}>No item</div>
              : (
                <>
                  {tableItem.locations.map((group, index) => (
                    <LineItemListGroup key={index}
                      isAction={isAction} group={group}
                      portfolio={portfolio}
                      isOwnCompany={isOwnCompany}
                      isContentsAction={isContentsAction}
                      quote={quote}
                      tableItem={tableItem}
                      ItemComponent={QuoteGroupItem}
                    />
                  ))}

                  {
                    tableItem.fields.discount.isDisplay &&
                      <QuoteGroupDiscount
                        portfolio={portfolio}
                        quote={quote}
                        tableItem={tableItem}
                      />
                  }
                  <ListItemListReport
                    report={report}
                    info={<ReportSummaryInfo meta={meta} portfolio={portfolio} marginBreakdown={tableItem.fields.marginBreakdown.isDisplay} />}
                  />
                </>
              )
            }
          </div>
        </Collapse>
      </div>
    </Paper>
  )
}

export default withStyles(theme => ({
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    background: theme.palette.grey[400],
    borderRadius: 8,
    justifyContent: 'flex-end'
  },
  scopeTypeRow: {
    fontWeight: 600,
    borderRadius: 8,
    background: theme.palette.grey[400],
    '&:hover': {
      background: theme.palette.grey[600],
    }
  },
  expandBtn: {
    margin: 0,
    padding: theme.spacing.unit
  },
  noItem: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
  subHeader: {
    fontStyle: 'italic',
    fontWeight: 600,
    background: theme.palette.grey[400],

    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    borderRadius: 8,

    borderTop: '1px solid #ececec'
  },
  root: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  discount: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '300px'
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit
  }
}))(QuoteGroupTable)
