import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation CancelAwaitingInfoActionMutation($where: ENDataPortfolioKey!) {
    claimCancelAwaitingInfo(where: $where) {
      success
      messages
      result {
        claimStatus {
          statusId
          statusName
        }
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        updater: store => {
          let payload = store.getRootField('claimCancelAwaitingInfo')
          let success = payload.getValue('success')
          if (success) {
            let result = payload.getLinkedRecord('result')
            let newStatus = result.getLinkedRecord('claimStatus')

            let storeRoot = store.getRoot()

            let claimJob = storeRoot.getLinkedRecord('claimJob(where:{"id":'+variables.where.id+'})')
            let claimPortfolio = claimJob.getLinkedRecord(variables.where.portfolioType.toLowerCase())

            claimPortfolio.setLinkedRecord(newStatus, 'claimStatus')
          }
        },
        onCompleted: res => {
          resolve({ok: true, success: res.claimCancelAwaitingInfo.success, message: res.claimCancelAwaitingInfo.messages[0]})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}