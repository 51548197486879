import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation AddVariationMutation($input: JobVariationCreate!) {
    createJobVariation(input: $input) {
      messages
      success
      result {
        variationId
          
        # date
        logdate
        # name
        variationTitle variationDescription
        # reason
        variationReason { reasonDescription }
        #cost
        total
        #status
        variationStatus
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      updater: store => {
        const payload = store.getRootField('createJobVariation')

        if(payload.getValue('success')) {
          const node = payload.getLinkedRecord('result')

          const root = store.getRoot()
          const conn = ConnectionHandler.getConnection(root, 'VariationsQuery_variations')
          const edge = ConnectionHandler.createEdge(store, conn, node, 'IJobVariationEdge')

          ConnectionHandler.insertEdgeAfter(conn, edge)
          // insertEdgeBefore
        }
      },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.createJobVariation.success,
          messages: res.createJobVariation.messages
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}