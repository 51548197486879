import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation AdjusterUpdateStatusMutation($input: ClaimStatusInput!, $where: ClaimUniqueWhere!) {
    adjusterUpdateStatus( where: $where, input: $input ){
      success
      messages
      result {
        view {
          fields {
            lossAdjusterStatus {
              displayValue
            }
          }
          actions {
            updateLossAdjusterStatus {
              isDisplay
              id
            }
          }
        }
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          resolve({
            ok: true,
            messages: res.adjusterUpdateStatus.messages[0]
          })
        },
        onError: res => {
          resolve({ ok: false })
        }
      }
    )
  })
}