import React from 'react'
import environment from 'common/relay'
import { graphql, QueryRenderer } from 'react-relay'
import { Dialog, Grid, withStyles, DialogTitle, DialogContent } from '@material-ui/core'
import IconButton from 'components/Mui/IconButton'
import DataTable from 'react-data-table-component'
import { moneyFormat } from 'common/utils'

const query = graphql`
  query ReportSummaryInfoQuery(
    $where: ClaimJobScopeTypeIndex!
  ) {
    claimJobScopeType(where: $where){
      id
      items{
        itemName
        marginRate
        rawSubtotal
        calculateAmount
        margin
        notCalculateAmount
        subtotal
        gst
        total
      }
      total{
        itemName
        marginRate
        rawSubtotal
        calculateAmount
        margin
        notCalculateAmount
        subtotal
        gst
        total
      }
      fields{
        marginRate{
          label
          order
          name
          isDisplay
        }
        gst {
          label
          order
          name
          isDisplay
        }
        itemName {
          label
          order
          name
          isDisplay
        }
        margin {
          label
          order
          name
          isDisplay
        }
        notCalculateAmount {
          label
          order
          name
          isDisplay
        }
        calculateAmount{
          label
          order
          name
          isDisplay
        }
        # rawSubtotal {
        #   label
        #   order
        #   name
        #   isDisplay
        # }
        subtotal {
          label
          order
          name
          isDisplay
        }
        total {
          label
          order
          name
          isDisplay
        }
      }
    }
  }
`

const ReportSummaryInfo = props => {
  const { meta, classes, portfolio, marginBreakdown } = props
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      {marginBreakdown ? <IconButton icon="info" className={classes.infoButton} onClick={handleOpen}/> : null}
      <Dialog
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.paper }}
      >
        <div>
          <Grid container style={{width:'100%'}}>
            <Grid item xs={6}>
              <DialogTitle>Margin Breakdown</DialogTitle>
            </Grid>
          </Grid>
          <DialogContent>
            <QueryRenderer
              environment={environment}
              query={query}
              variables={{
                where: {
                  claimId: meta.claim.claimId,
                  portfolioType: portfolio
                },
              }}
              render={({error, props}) => {
                if(!props) return 'Loading...'
                const tableHeader = Object.values(props.claimJobScopeType.fields).map(({name: selector, label:name, order, isDisplay}) =>
                ({name, selector, order, isDisplay, style:{fontSize:'16px'}})).sort((a,b)=>a.order-b.order)
                const data = props.claimJobScopeType.items
                const summary = [props.claimJobScopeType.total]
                const tableData = [...data, ...summary].map(e => {
                  return ({
                    itemName: e.itemName,
                    marginRate: `${e.marginRate.toFixed(2)}%`,
                    gst: moneyFormat(e.gst),
                    margin: moneyFormat(e.margin),
                    notCalculateAmount: moneyFormat(e.notCalculateAmount),
                    //rawSubtotal: moneyFormat(e.rawSubtotal),
                    calculateAmount: moneyFormat(e.calculateAmount),
                    subtotal: moneyFormat(e.subtotal),
                    total: moneyFormat(e.total),
                  })
                })
                return (
                  <div>
                    <DataTable
                      noHeader
                      columns={tableHeader}
                      data={tableData}
                      customStyles={customStyles}
                      // highlightOnHover
                      paginationServer
                    />
                  </div>
                )
              }}
            />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  )
}

export default withStyles(() => ({
  root: {
    zoom:'0.91'
  },
  paper: {
    minWidth: '95%',
    zoom: '0.91',
    maxWidth: 'initial',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  infoButton: {
    margin: 0,
    padding: 0
  }
}))(ReportSummaryInfo)

const customStyles = {
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: 'lightgrey',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '16px',
      fontWeight:'bold',
      padding: '1px',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      borderLeft: 'solid 1px lightgrey',
      '&:last-child': {
        borderRight: 'solid 1px lightgrey',
      }
    },
  },
  rows: {
    style: {
      '&:nth-child(even)': {
        backgroundColor: '#e6e6e6'
      },
      '&:last-child': {
        fontWeight: 'bold',
        borderBottom: 'solid 1px lightgrey',
        backgroundColor: 'white'
      },
      '&:hover': {
        backgroundColor: '#cccccc'
      },
    }
  },
  cells: {
    style: {
      justifyContent: 'center',
      fontWeight: 'inherit !important',
      borderLeft: 'solid 1px lightgrey',
      '&:last-child': {
        borderRight: 'solid 1px lightgrey',
      }
    },
  },
};

