import React, { Component } from 'react'
import { states } from 'common/static_data'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import Button from 'components/Mui/Button/'
import Input from 'components/Formik/Input/'
import PostcodeInput from 'components/Formik/PostcodeInput/'

class PostalAddressCard extends Component {
  render() {
    const { isAdmin } = this.props.user.type

    return (
      <Paper
        title={'Postal Address'}
        content={(
          <Grid fluid>
            {[
              {
                col: 8, component: Input, leadIcon: 'location_on',
                label: 'Postal Address',
                name: 'postalAddressLine1',
              },
              {
                col: 4, component: Button, leadIcon: 'content_copy',
                variant: 'outlined', color: 'primary',
                label: 'Same Address',
                onClick: this.sameAddressHandle,
                unMountOn: !isAdmin
              },

              {
                col: 4, component: Input, leadIcon: 'location_on',
                label: 'Suburb',
                name: 'postalAddressSuburb'
              },
              {
                col: 4, component: Input, leadIcon: 'location_on',
                label: 'State', select: true,
                options: states,
                name: 'postalAddressState'
              },
              {
                col: 4, component: PostcodeInput, leadIcon: 'location_on',
                label: 'Postcode',
                name: 'postalAddressPostcode'
              }
            ].map((child, key) => {
              const { col, component: Component, unMountOn, ...props } = child
              return (
                <Grid item xs={col} key={key}>
                  {Component && !unMountOn && <Component {...props}
                    readOnly={!isAdmin}
                  />}
                </Grid>
              )
            })}
          </Grid>  
        )}
      />
    )
  }
  sameAddressHandle = e => {
    const { values, setFieldValue } = this.props
    this.props.setTouched({
      ...this.props.touched,
      riskAddressLine1: true,
      riskAddressSuburb: true,
      riskAddressState: true,
      riskAddressPostcode: true,
    })

    setFieldValue('postalAddressLine1', values.riskAddressLine1, true)
    setFieldValue('postalAddressSuburb', values.riskAddressSuburb, true)
    setFieldValue('postalAddressState', values.riskAddressState, true)
    setFieldValue('postalAddressPostcode', values.riskAddressPostcode, true)
  }
}

export default PostalAddressCard