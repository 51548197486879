import React, { Component } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import { fetchQuery } from "relay-runtime";
import environment from "common/relay";
import { withRouter } from "react-router-dom";
import classNames from "clsx";

import { withStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import Input from "components/Formik/Input";
import Switch from "components/Formik/Switch";

import AcknowledgeCommunicationMutation from "../../Claim/ClaimDetails/ClaimDetailsAction/AcknowledgeCommunicationMutation";
import Grid from "components/Mui/Grid";
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon/";
import InfoBox from "components/Mui/InfoBox/";
import IconButton from "components/Mui/IconButton";
import Dialog from "components/Mui/Dialog";
import Button from "components/Mui/Button";

import ReplyMessageMutation from "./ReplyMessageMutation";

const query = graphql`
  query StandaloneCommunicationListItemQuery($where: ENDataEntityKey!) {
    claim: claimJob(where: $where) {
      view {
        tabs {
          historyTab {
            isDisplay
          }
        }
      }
    }
  }
`;

class StandaloneCommunicationListItem extends Component {
  dlRef = React.createRef();
  snackbar = window[Symbol.for("__snackbar")];
  state = {
    expand: false,
    acknowledged: false,
  };
  render() {
    const { acknowledged } = this.state;
    const { classes, index, item } = this.props;

    return (
      <>
        <Grid
          fluid
          spacing={0}
          onClick={(e) => {
            fetchQuery(environment, query, {
              where: {
                id: parseInt(item.claimId),
              },
            }).then((data) => {
              this.props.history.push(
                `/claim/${
                  data.claim.view.tabs.historyTab.isDisplay
                    ? "histories"
                    : "communications"
                }?id=${item.claimId}`
              );
            });
          }}
          className={classNames(classes.listItem, {
            [classes.acknowledged]: acknowledged,
          })}
        >
          <Grid item xs={1} className={classes.alignCenter}>
            {/* <IconButton className={classes.iconButton} onClick={this.expandHandle} icon={expand ? 'expand_less' : 'expand_more'} /> */}
            {index && <span className={classes.index}>{index}</span>}
            {item.portfolioType && <ClaimTypeIcon type={item.portfolioType} />}
          </Grid>
          <Grid className={classes.alignCenter} item xs={1}>
            {item.claimRef}
          </Grid>
          <Grid className={classes.alignCenter} item xs={1}>
            {item.customerName}
          </Grid>
          <Grid className={classes.alignCenter} item xs={1}>
            {item.sendDate}
          </Grid>
          <Grid className={classes.alignCenter} item xs={1}>
            {item.senderName}
          </Grid>
          <Grid className={classes.alignCenter} item xs={1}>
            {item.senderCompanyName}
          </Grid>
          <Grid className={classes.alignLeft} item xs={5}>
            {item.message}
          </Grid>
          <Grid className={classes.alignCenter} item xs={1}>
            <Grid fluid spacing={0} justify="center">
              <IconButton
                onClick={this.reply}
                className={classes.iconButton}
                icon="reply"
              />
              <IconButton
                onClick={(e) => this.acknowledge(e, item)}
                className={classes.iconButton}
                icon={acknowledged ? "check_circle" : "radio_button_unchecked"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            claimId: item.claimId,
            receiverCompanyId: item.senderCompanyId,
            receiverRole: item.senderRole,

            message: "",
            private: true,
          }}
          onSubmit={async (values, actions) => {
            setTimeout(async () => {
              const res = await ReplyMessageMutation({ input: { ...values } });
              if (res.ok) {
                actions.setSubmitting(false);
                this.snackbar.toggleOpen({ message: res.messages[0] });
                if (res.success) {
                  this.dlRef.current.handleClose();
                }
              }
            }, 400);
          }}
        >
          {({ isSubmitting, values }) => (
            <Dialog
              formComponent={Form}
              innerRef={this.dlRef}
              title="Reply"
              actions={[
                <Button
                  label="Reply"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                />,
              ]}
              content={
                <Grid fluid spacing={0}>
                  <Grid item xs={4}>
                    <InfoBox label="Ins Ref#" text={item.claimRef} />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoBox label="Customer Name" text={item.customerName} />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoBox label="Sender" text={item.senderName} />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoBox label="Company" text={item.senderCompanyName} />
                  </Grid>
                  <Grid item xs={12}>
                    <InfoBox label="Message" text={item.message} />
                  </Grid>
                  <Grid item xs={2}>
                    <Switch
                      label="Privacy"
                      name="private"
                      switchLabel={values.private ? "Private" : "asd"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      multiline
                      variant="outlined"
                      rowsMax={10}
                      rows={5}
                      label="Message"
                      name="message"
                    />
                  </Grid>
                </Grid>
              }
            />
          )}
        </Formik>
      </>
    );
  }
  expandHandle = (e) => {
    e.stopPropagation();
    this.setState({ expand: !this.state.expand });
  };
  acknowledge = async (e, item) => {
    e.stopPropagation();
    const res = await AcknowledgeCommunicationMutation({
      where: {
        id: item.communicationId,
        portfolioType: item.portfolioType,
      },
    });
    if (res.success) {
      this.setState({ acknowledged: true });
      const ref = window[Symbol.for("__refreshCommunication")];
      if (ref) ref.refresh();
    }
  };

  reply = (e) => {
    e.stopPropagation();
    this.dlRef.current.handleOpen();
  };
  getAddress = ({ line1, subburb, state, postcode }) =>
    `${line1}, ${subburb} ${state} ${postcode}`;
  getPhone = ({ phone1, phone2, phone3 }) =>
    `${phone1} ${phone2 ? ` ${phone2}` : ""} ${phone3 ? ` ${phone3}` : ""}`;
}

export default withStyles((theme) => ({
  listItem: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    "&:nth-child(even)": {
      background: theme.palette.grey[100],
    },
    "&:hover": {
      background: theme.palette.grey[300],
    },
    border: `${theme.palette.grey[300]} 1px solid`,
  },
  iconButton: {
    margin: "0 10px 0 0",
    padding: 0,
  },
  alignLeft: {
    display: "flex",
    alignItems: "center",
  },
  collapseWrapper: {
    width: "100%",
    borderTop: `1px solid ${theme.palette.grey[500]}`,
    marginTop: theme.spacing.unit,
    paddingTop: theme.spacing.unit,
  },
  index: {
    marginRight: "10px",
  },
  acknowledged: {
    background: `${theme.palette.grey[300]} !important`,
    color: theme.palette.grey[700],
    border: `${theme.palette.grey[500]} 1px solid`,
  },
  alignCenter: theme.mixins.alignJustifyContainer(theme),
  replyBox: {
    marginTop: "2rem",
  },
}))(
  createFragmentContainer(
    withRouter(StandaloneCommunicationListItem),
    graphql`
      fragment StandaloneCommunicationListItem_item on ClaimCommunication {
        claimId
        claimRef
        communicationId
        customerName
        message
        portfolioType
        recieverCompanyId
        recieverCompanyName
        sendDate
        senderCompanyId
        senderCompanyName
        senderId
        senderName
        senderRole
      }
    `
  )
);
