import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation EstimatorCreateMutation($input: EstimatorCreateInput!) {
    estimatorCreate(input: $input){
      messages
      success
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: ({ estimatorCreate }) => {
          const { messages, success } = estimatorCreate
          resolve({ messages, success })
        },
        onError: errors => {
          resolve({ messages: ['Unable to complete this action, please try again!'] })
        }
      }
    )
  })
}