import React, { Component } from "react";

import { states } from "common/static_data";
import { graphql, createFragmentContainer } from "react-relay";

import CheckboxGroup from "components/Formik/CheckboxGroup";
import PostcodeInput from "components/Formik/PostcodeInput";
import Input from "components/Formik/Input";
import PhoneInput from "components/Formik/PhoneInput";
import Switch from "components/Formik/Switch";

import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
// import Input from 'components/Mui/Input'

class AddClaimClientInfoCard extends Component {
  state = {
    hasBuilding: false,
    hasContents: false,
    hasRestoration: false,
  };
  render() {
    const {
      handleComplete,
      handlePostcodeChange,
      data,
      company,
      errors,
      values,
      claimJobFields,
      ...rest
    } = this.props;
    return (
      <Paper
        title="Customer Details" /* label:'Claim Client Info'|'Customer Details' */
        {...rest}
        content={
          <Grid fluid spacing={0}>
            <Grid item xs={12}>
              <Grid fluid>
                <Grid item xs={3}>
                  <CheckboxGroup
                    required
                    label="Supplier required"
                    /* label:'Portfolio Type'|'Supplier required' */ name="portfolioType"
                    row
                    error={
                      values.portfolioType.length === 0 &&
                      "Please choose at least one"
                    }
                    select
                    options={[
                      {
                        label: "Builder" /* label:'Building'|'Builder' */,
                        value: "Building",
                      },
                      {
                        label:
                          "Contents Provider" /* label:'Contents'|'Contents Provider' */,
                        value: "Contents",
                      },
                      {
                        label: "Restorer" /* label:'Restoration'|'Restorer' */,
                        value: "Restoration",
                      },
                    ]}
                  />
                </Grid>
                {[
                  {
                    col: 3,
                    label: "Salutation",
                    leadIcon: "school",
                    component: Input,
                    type: "text",
                    name: "insured_salutation",
                  },
                  {
                    col: 3,
                    label: "Customer" /* label:'Client name'|'Customer' */,
                    required: true,
                    leadIcon: "person",
                    component: Input,
                    type: "text",
                    name: "insured_name",
                  },
                  {
                    col: 3,
                    label:
                      "Customer Email" /* label:'Client Email'|'Customer Email' */,
                    leadIcon: "person",
                    component: Input,
                    type: "text",
                    name: "insured_email",
                  },
                  {
                    col: 3,
                    label:
                      "Property/Risk Address" /* label:'Risk Address'|'Property/Risk Address' */,
                    required: true,
                    leadIcon: "location_on",
                    component: Input,
                    type: "text",
                    name: "incidentDetail_riskAddress_line1",
                  },
                  {
                    col: 3,
                    label: "Suburb",
                    required: true,
                    leadIcon: "location_on",
                    component: Input,
                    type: "text",
                    name: "incidentDetail_riskAddress_suburb",
                  },
                  {
                    col: 3,
                    label: "State",
                    required: true,
                    leadIcon: "location_on",
                    component: Input,
                    type: "text",
                    name: "incidentDetail_riskAddress_state",
                    select: true,
                    options: states,
                  },
                  {
                    col: 3,
                    label: "Postcode",
                    required: true,
                    name: "incidentDetail_riskAddress_postcode",
                    leadIcon: "location_on",
                    component: PostcodeInput,
                    onBlur: (e) => handlePostcodeChange(e.target.value),
                  },
                  {
                    col: 3,
                    label: "Home Habitable?",
                    unmountOn: company.removeHabitableAsbestos,
                    component: Switch,
                    name: "incidentDetail_habitableProperty",
                  },
                  {
                    col: 3,
                    label: claimJobFields.vulnerableMember.label,
                    unmountOn: !claimJobFields.vulnerableMember.isDisplay,
                    component: Switch,
                    name: "vulnerableMember",
                  },
                  {
                    col: 3,
                    label: "Phone 1",
                    required: true,
                    leadIcon: "phone",
                    component: PhoneInput,
                    type: "text",
                    name: "insured_phone1",
                  },
                  {
                    col: 3,
                    label: "Phone 2",
                    leadIcon: "phone",
                    component: PhoneInput,
                    type: "text",
                    name: "insured_phone2",
                  },
                  {
                    col: 3,
                    label: "Require custom login?",
                    unmountOn: !Boolean(
                      company.setupcustomerlogin &&
                        company.setupcustomerlogin !== 0
                    ),
                    component: Switch,
                    name: "requireCustomLogin",
                  },
                  {
                    col: 3,
                    label: "Customer Login (e.g. Email Address)",
                    unmountOn: !Boolean(
                      company.setupcustomerlogin &&
                        company.setupcustomerlogin !== 0
                    ),
                    leadIcon: "location_on",
                    component: Input,
                    type: "text",
                    name: "customLoginEmail",
                  },
                  // {
                  //   col: 3, label:'Damaged Asbestos Present?',
                  //   unmountOn: company.removeHabitableAsbestos,
                  //   component: Switch, name: 'incidentDetail_asbestos'
                  // },
                  {
                    col: 3,
                    label: "Category of Claim",
                    unmountOn: company.hideCategoryOfClaim,
                    leadIcon: "location_on",
                    component: Input,
                    type: "text",
                    name: "incidentDetail_category",
                    select: true,
                    options: [
                      { label: "Non-urgent", value: "NonUrgent" },
                      { label: "SemiUrgent", value: "SemiUrgent" },
                      { label: "Urgent", value: "Urgent" },
                    ],
                  },
                ].map((child, key) => {
                  const {
                    col,
                    component: Component,
                    unmountOn,
                    ...props
                  } = child;
                  return (
                    <Grid item xs={col} key={key}>
                      {!unmountOn && <Component {...props} />}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        }
      />
    );
  }
}

export default createFragmentContainer(
  AddClaimClientInfoCard,
  graphql`
    fragment AddClaimClientInfoCard_company on CompanyProfile {
      removeHabitableAsbestos
      setupcustomerlogin
      hideCategoryOfClaim
    }
  `
);
