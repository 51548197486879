import React from 'react'
import { moneyFormat } from 'common/utils'
import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'

const GroupDiscount = ({ classes, tableItem }) => {
  const snackbar = window[Symbol.for('__snackbar')]

  return (
    <>
      <Grid fluid spacing={0} className={classes.row}>
        {[
          {col: true},
          {col: 2, bold: true, text: `${tableItem.fields.discount.label}:`},
          {col: 1, number: true, text: `${moneyFormat(tableItem.discount)}`},
        ].map(({ col, text, unMountOn, className, bold, number }, key) => {
          if(unMountOn) return null
          return (
            <Grid className={`${className} ${classes.alignCenter}`} item key={key} xs={col}>
              <div style={{fontWeight: bold? 'bold' : undefined}}>
                {text}
              </div>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default withStyles(theme => ({
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: '#f5f5f5',
    '&:hover': {
      background: theme.palette.grey[200],
    }
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit
  },
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
    padding: '4px',
    textAlign: 'center',
    flexWrap: 'wrap'
  },
  sir: {
    color: theme.palette.grey[800],
    fontStyle: 'italic'
  }
}))(GroupDiscount)
