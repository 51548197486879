import React from "react";
import environment from "common/relay";
import { graphql, QueryRenderer } from "react-relay";

import { Formik, Form } from "formik";
import Input from "components/Formik/Input";

import Dialog from "components/Mui/Dialog";
import { Grid, withStyles } from "@material-ui/core";
import Button from "components/Mui/Button";
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon";
import VariationDetailsList from "../../VariationDetailsList";
import ResetVariationValidationMutation from "./ResetVariationValidationMutation";
import CompleteVariationValidationMutation from "./CompleteVariationValidationMutation";

const query = graphql`
  query VariationValidationActionQuery($where: JobVariationIndex!) {
    jobVariations(where: $where) {
      edges {
        node {
          variationId
          actions {
            completeValidation {
              label
              isDisabled
              isDisplay
            }
            resetValidation {
              label
              isDisabled
              isDisplay
            }
          }
          scopeTypes {
            locations {
              id
              location {
                id
                costLocationId
                locationName
              }
              items {
                variationItemId
                accepted
                actions {
                  accept {
                    label
                    isDisabled
                    isDisplay
                  }
                  decline {
                    label
                    isDisabled
                    isDisplay
                  }
                  amend {
                    label
                    isDisabled
                    isDisplay
                  }
                }
                location {
                  costLocationId
                  locationName
                }
                dimensions
                costCentre {
                  costCentreId
                  costCentreName
                }
                costItem {
                  costItemId
                  itemDescription
                }
                lineDescription
                unit {
                  unitId
                  unitName
                }
                qty
                rate
                marginRate
                subtotal
                logdate
                sirRate
                lineStatusDescription
                note
                scopeType {
                  name
                  id
                }
              }
            }
            scopeType {
              id
              name
            }
            id
            gst
            margin
            sirGst
            sirMargin
            sirSubtotal
            sirTotal
            subtotal
            total
            discount
            fields {
              marginBreakdown {
                isDisplay
                label
              }
              discount {
                isDisplay
                label
                isDisabled
              }
              sIRRate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              gst {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              total {
                label
                isDisplay
              }
              action {
                label
                isDisplay
              }
              costCentre {
                label
                isDisplay
              }
              description {
                label
                isDisplay
              }
              marginRate {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              qty {
                label
                isDisplay
              }
              rate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              unit {
                label
                isDisplay
              }
            }
          }
        }
      }
    }
  }
`;

const VariationValidationAction = (props) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const { meta, portfolioType, classes } = props;
  const [refresh, setRefresh] = React.useState(false);
  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleResetVariationValidation = async (variationId) => {
    const confirm = await snackbar.toggleOpen({
      message: "Please confirm to reset the Variation validation",
      firstButton: "Yes",
      secondButton: "No",
      type: "confirm",
    });
    if (confirm.ok) {
      const res = await ResetVariationValidationMutation({
        where: {
          id: variationId,
          portfolioType: portfolioType,
        },
      });
      if (res.ok) {
        if (res.success) {
          snackbar.toggleOpen({ message: res.messages[0] });
          handleRefresh();
        }
        snackbar.toggleOpen({ message: res.messages[0] });
      }
    }
  };

  const handleCompleteVariationValidation = async (variationId) => {
    const confirm = await snackbar.toggleOpen({
      message: "Please confirm to Complete the Variation validation",
      firstButton: "Yes",
      secondButton: "No",
      type: "confirm",
    });
    if (confirm.ok) {
      const res = await CompleteVariationValidationMutation({
        where: {
          id: variationId,
          portfolioType: portfolioType,
        },
      });
      if (res.ok) {
        if (res.success) {
          snackbar.toggleOpen({ message: res.messages[0] });
          handleRefresh();
        }
        snackbar.toggleOpen({ message: res.messages[0] });
      }
    }
  };

  return (
    <Dialog
      title="Variation Validation"
      noForm
      minWidth="90%"
      symbol="__VariationValidationAction"
      content={
        <QueryRenderer
          environment={environment}
          query={query}
          variables={{
            where: {
              claimId: meta.claim.claimId,
              portfolios: portfolioType,
            },
            refresh,
          }}
          render={({ error, props }) => {
            if (!props || props.jobVariations.edges.length === 0) return null;

            const variation = props.jobVariations.edges[0].node;
            return (
              <Grid container>
                <Grid item xs={6} className={classes.portfolioIcon}>
                  <ClaimTypeIcon type={portfolioType} size="3rem" />
                </Grid>
                <Grid item xs={6} className={classes.portfolioIcon}>
                  {variation.actions.resetValidation.isDisplay && (
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={variation.actions.resetValidation.isDisabled}
                      label={variation.actions.resetValidation.label}
                      onClick={() =>
                        handleResetVariationValidation(variation.variationId)
                      }
                    />
                  )}
                  {variation.actions.completeValidation.isDisplay && (
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={variation.actions.completeValidation.isDisabled}
                      label={variation.actions.completeValidation.label}
                      onClick={() =>
                        handleCompleteVariationValidation(variation.variationId)
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <VariationDetailsList
                    variation={variation}
                    portfolio={portfolioType}
                    meta={meta}
                    isAction
                    isValidationAction
                    handleRefresh={handleRefresh}
                    validationRefresh={handleRefresh}
                  />
                </Grid>
              </Grid>
            );
          }}
        />
      }
    />
  );
};
export default withStyles((theme) => ({
  portfolioIcon: {
    textAlign: "end",
  },
}))(VariationValidationAction);
