const checkPermissions = async supported => {
  const video = Boolean(supported.video
    ? await navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .catch(error => console.log(`Get camera: ${error}`))
    : null)
  const audio = Boolean(supported.audio
    ? await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
      .catch(error => console.log(`Get microphone: ${error}`))
    : null)

  return { video, audio }
}

export default checkPermissions