/**
 * @flow
 * @relayHash 87915ab40c5497874827cf0acfdbca92
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type RequestCategory = "All" | "AssessorReport" | "Authorised" | "DesktopQuoting" | "Lost" | "ScopeValidation" | "Scoping" | "%future added value";
export type SupplierStatusFilter = "AcceptedOnly" | "RequestedAndAvailable" | "RequestedOnly" | "%future added value";
export type SupplierTypeFilter = "ManagerAndSupplier" | "QuotingSupplier" | "Supplier" | "SupplierExcludeScoping" | "%future added value";
export type JobQuoteIndex = {|
  id?: ?$ReadOnlyArray<number>,
  claimId?: ?string,
  scopeTypeId?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  requestCategory?: ?RequestCategory,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type ClaimParticipantIndex = {|
  claimId: string,
  postcode?: ?string,
  portfolioTypes?: ?$ReadOnlyArray<?PortfolioType>,
  participantStatus?: ?SupplierStatusFilter,
  participantType?: ?SupplierTypeFilter,
|};
export type ScopeValidationTitleAndButtonQueryVariables = {|
  where: JobQuoteIndex,
  claimParticipantsWhere?: ?ClaimParticipantIndex,
|};
export type ScopeValidationTitleAndButtonQueryResponse = {|
  +jobQuotes: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +jobQuoteId: number,
        +actions: ?{|
          +completeValidation: {|
            +id: string,
            +isDisabled: boolean,
            +isDisplay: boolean,
            +label: string,
          |},
          +resetValidation: {|
            +label: string,
            +isDisabled: boolean,
            +isDisplay: boolean,
          |},
          +submitValidation: {|
            +label: string,
            +isDisabled: boolean,
            +isDisplay: boolean,
          |},
        |},
      |}
    |}>
  |},
  +claimParticipants: ?$ReadOnlyArray<?{|
    +companyName: string,
    +companyId: number,
    +requested: boolean,
    +id: string,
  |}>,
|};
export type ScopeValidationTitleAndButtonQuery = {|
  variables: ScopeValidationTitleAndButtonQueryVariables,
  response: ScopeValidationTitleAndButtonQueryResponse,
|};
*/


/*
query ScopeValidationTitleAndButtonQuery(
  $where: JobQuoteIndex!
  $claimParticipantsWhere: ClaimParticipantIndex
) {
  jobQuotes(where: $where) {
    edges {
      node {
        jobQuoteId
        actions {
          completeValidation {
            id
            isDisabled
            isDisplay
            label
          }
          resetValidation {
            label
            isDisabled
            isDisplay
            id
          }
          submitValidation {
            label
            isDisabled
            isDisplay
            id
          }
        }
        id
      }
    }
  }
  claimParticipants(where: $claimParticipantsWhere) {
    companyName
    companyId
    requested
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JobQuoteIndex!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "claimParticipantsWhere",
    "type": "ClaimParticipantIndex",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "JobQuoteIndex!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "jobQuoteId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisabled",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "completeValidation",
  "storageKey": null,
  "args": null,
  "concreteType": "ActionControl",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ]
},
v8 = [
  (v6/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "claimParticipants",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "claimParticipantsWhere",
      "type": "ClaimParticipantIndex"
    }
  ],
  "concreteType": "ClaimParticipant",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requested",
      "args": null,
      "storageKey": null
    },
    (v3/*: any*/)
  ]
},
v10 = [
  (v6/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ScopeValidationTitleAndButtonQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobQuotes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuoteConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuoteEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobQuote",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobQuoteAction",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "resetValidation",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ActionControl",
                        "plural": false,
                        "selections": (v8/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "submitValidation",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ActionControl",
                        "plural": false,
                        "selections": (v8/*: any*/)
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      (v9/*: any*/)
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ScopeValidationTitleAndButtonQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobQuotes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuoteConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuoteEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobQuote",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobQuoteAction",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "resetValidation",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ActionControl",
                        "plural": false,
                        "selections": (v10/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "submitValidation",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ActionControl",
                        "plural": false,
                        "selections": (v10/*: any*/)
                      }
                    ]
                  },
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      (v9/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ScopeValidationTitleAndButtonQuery",
    "id": null,
    "text": "query ScopeValidationTitleAndButtonQuery(\n  $where: JobQuoteIndex!\n  $claimParticipantsWhere: ClaimParticipantIndex\n) {\n  jobQuotes(where: $where) {\n    edges {\n      node {\n        jobQuoteId\n        actions {\n          completeValidation {\n            id\n            isDisabled\n            isDisplay\n            label\n          }\n          resetValidation {\n            label\n            isDisabled\n            isDisplay\n            id\n          }\n          submitValidation {\n            label\n            isDisabled\n            isDisplay\n            id\n          }\n        }\n        id\n      }\n    }\n  }\n  claimParticipants(where: $claimParticipantsWhere) {\n    companyName\n    companyId\n    requested\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d8346d5644a9026f48f777069027541';
module.exports = node;
