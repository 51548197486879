import React, { Component, createRef } from "react";

import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";
import { states, filtersData } from "common/static_data";
import Collapse from "@material-ui/core/Collapse";

import Paper from "components/Mui/Paper";
import Grid from "components/Mui/Grid";
import Input from "components/Mui/Input";
import Form from "components/Mui/Form";
import MultipleSelect from "components/MultipleSelect";
import { CubeGrid } from "components/Mui/Loader";
import IconButton from "components/Mui/IconButton";

const query = graphql`
  query DashboardFilterQuery {
    currentUser {
      dashboardFilters {
        icon
        id
        isDisabled
        label
        name
        options {
          value: id
          group
          label
        }
        type
      }
    }
  }
`;

class DashboardFilter extends Component {
  keyWordsInput = createRef();

  state = {
    filterMore: false,
  };

  render() {
    return (
      <Grid container>
        <Grid item xs>
          <Paper
            style={{ position: "relative" }}
            content={
              <Form onChange={this.props.handleChangeChartFilter}>
                <QueryRenderer
                  environment={environment}
                  query={query}
                  render={({ error, props }) => {
                    if (error) return "Error";
                    if (!props)
                      return (
                        <div style={{ height: "60px" }}>
                          <CubeGrid />
                        </div>
                      );
                    const optionArray = props.currentUser.dashboardFilters;
                    const firstRow = optionArray.slice(0, 6);
                    const subRow = optionArray.slice(6);
                    return (
                      <>
                        <Grid fluid>
                          {firstRow.map((item, index) => {
                            return (
                              <Grid item xs={2} key={index}>
                                {item.type === "ComboBox_multiple" && (
                                  <MultipleSelect
                                    leadIcon={item.icon}
                                    label={item.label}
                                    id={item.name}
                                    outline
                                    filtered
                                    options={
                                      item.options !== 0
                                        ? item.options
                                        : [{ label: "Empty", value: "" }]
                                    }
                                  />
                                )}
                                {item.type === "ComboBox" && (
                                  <Input
                                    leadIcon={item.icon}
                                    label={item.label}
                                    name={item.name}
                                    select
                                    options={
                                      item.options !== 0
                                        ? item.options
                                        : [{ label: "Empty", value: "" }]
                                    }
                                  />
                                )}
                              </Grid>
                            );
                          })}
                        </Grid>
                        <Collapse
                          in={this.state.filterMore}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Grid fluid>
                            {subRow.map((item, index) => {
                              return (
                                <Grid item xs={2} key={index}>
                                  {item.type === "ComboBox_multiple" && (
                                    <MultipleSelect
                                      leadIcon={item.icon}
                                      label={item.label}
                                      id={item.name}
                                      outline
                                      filtered
                                      options={
                                        item.options !== 0
                                          ? item.options
                                          : [{ label: "Empty", value: "" }]
                                      }
                                    />
                                  )}
                                  {item.type === "ComboBox" && (
                                    <Input
                                      leadIcon={item.icon}
                                      label={item.label}
                                      name={item.name}
                                      select
                                      options={
                                        item.options !== 0
                                          ? item.options
                                          : [{ label: "Empty", value: "" }]
                                      }
                                    />
                                  )}
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Collapse>
                        <IconButton
                          style={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                          }}
                          icon={
                            this.state.filterMore
                              ? "expand_less"
                              : "expand_more"
                          }
                          onClick={(e) =>
                            this.setState({
                              filterMore: !this.state.filterMore,
                            })
                          }
                        />
                      </>
                    );
                  }}
                />
              </Form>
            }
          />
        </Grid>
      </Grid>
    );
  }
}

export default DashboardFilter;
