/**
 * @flow
 * @relayHash 0c62ee3ad166b5d28a0277621809f589
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupplierScoreFilter = {|
  scoreItemId: string,
  suppliers?: ?$ReadOnlyArray<string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  regions?: ?$ReadOnlyArray<?string>,
  createDateFrom: any,
  createDateTo: any,
|};
export type DashboardExportToExcelMutationVariables = {|
  where: SupplierScoreFilter
|};
export type DashboardExportToExcelMutationResponse = {|
  +csvProduceForSupplierScoreDetail: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type DashboardExportToExcelMutation = {|
  variables: DashboardExportToExcelMutationVariables,
  response: DashboardExportToExcelMutationResponse,
|};
*/


/*
mutation DashboardExportToExcelMutation(
  $where: SupplierScoreFilter!
) {
  csvProduceForSupplierScoreDetail(where: $where) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "SupplierScoreFilter!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "csvProduceForSupplierScoreDetail",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "SupplierScoreFilter!"
      }
    ],
    "concreteType": "GeneralPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardExportToExcelMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardExportToExcelMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DashboardExportToExcelMutation",
    "id": null,
    "text": "mutation DashboardExportToExcelMutation(\n  $where: SupplierScoreFilter!\n) {\n  csvProduceForSupplierScoreDetail(where: $where) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0eec00554f7875e6fc6a19be501be9b6';
module.exports = node;
