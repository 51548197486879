import React from "react";
import { fetchQuery, graphql } from "relay-runtime";
import environment from "common/relay";
import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";

const query = graphql`
  query useLineItemIndustryRateQuery(
    $where: QuoteItemSirUniqueWhere!
    $isIncluded: Boolean!
  ) {
    rates: quoteItemSir(where: $where) @include(if: $isIncluded) {
      id
      sirMarginRate
      sirRate
    }
  }
`;

export default (
  {
    portfolioType,
    unitId,
    descriptionId,
    isIncluded = false,
    type,
    setFieldValue,
    values,
    industryRatesAutoFill,
  },
  watches
) => {
  const meta = React.useContext(ClaimMetaContext);
  const [labourRate, setLabourRate] = React.useState();
  const [labourMargin, setLabourMargin] = React.useState();
  const [materialRate, setMaterialRate] = React.useState();
  const [materialMargin, setMaterialMargin] = React.useState();

  if (industryRatesAutoFill) {
    React.useEffect(() => {
      if (unitId && descriptionId) {
        const getData = async () => {
          const queryPromise = fetchQuery(environment, query, {
            where: {
              claimId: meta.claim.claimId,
              unitId: unitId,
              costDescriptionId: descriptionId,
              portfolioType: portfolioType,
            },
            isIncluded,
          }).then(({ rates }) => {
            if (rates && rates.sirRate) {
              if (type === "labour_") {
                setLabourRate(rates.sirRate);
              } else if (type === "material_") {
                setMaterialRate(rates.sirRate);
              }
            }
            if (rates && rates.sirMarginRate) {
              if (type === "labour_") {
                setLabourMargin(rates.sirMarginRate);
              } else if (type === "material_") {
                setMaterialMargin(rates.sirMarginRate);
              }
            }
          });

          return () => queryPromise.resolve();
        };

        getData();
      }
    }, watches);

    React.useEffect(() => {
      setFieldValue("labour_rate", labourRate);
    }, [labourRate]);

    React.useEffect(() => {
      setFieldValue("labour_marginRate", labourMargin);
    }, [labourMargin]);

    React.useEffect(() => {
      setFieldValue("material_rate", materialRate);
    }, [materialRate]);

    React.useEffect(() => {
      setFieldValue("material_marginRate", materialMargin);
    }, [materialMargin]);
  }

  return [labourRate, materialRate, labourMargin, materialRate];
};
