import React from "react";
import { fetchQuery, graphql } from "react-relay";
import environment from "common/relay";
import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";

import ClaimDetailsPortfolioFilter from "../../ClaimDetailsPortfolioFilter";
import QuotesActionPresentation from "./QuotesAction/QuotesActionPresentation";
// import QuotesPresentation from './QuotesPresentation/'

import usePortfolio from "hooks/usePortfolio";

import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";
import QuotesListHeader from "./QuotesListHeader";

import QuotesPresentation from "./QuotesPresentation/QuotesContainer";
import QuotesContentPresentation from "./QuotesContentPresentation/QuotesContentContainer";

const QuotesContainer = ({ classes }) => {
  const meta = React.useContext(ClaimMetaContext);
  // HANLDE HARD REFRESH
  const [refresh, setRefresh] = React.useState(true);
  const handleRefresh = () => setRefresh(!refresh);
  React.useEffect(() => {
    window[Symbol.for("__refreshQuotes")] = { handleRefresh };

    return () => {
      delete window[Symbol.for("__refreshQuotes")];
    };
  }, [refresh]);

  /**
   * HANDLE ROUTING AND DEFAULT SEARCH
   */
  const [portfolio, changePortfolio] = usePortfolio([
    "building",
    "contents",
    "restoration",
  ]);
  /**
   * HANDLE FETCHING DATA
   */
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    if (portfolio) {
      const variables = {
        first: 100000,
        where: {
          claimId: meta.claim.claimId,
          portfolios: portfolio,
        },
        claimJobWhere: {
          id: meta.claim.claimId,
        },
        refresh,
      };

      fetchQuery(environment, query, variables)
        .then((data) => setData(data))
        .catch((error) => console.log(error));
    }
  }, [meta.claim.claimId, portfolio, refresh]);

  // loading data
  if (!data) return null;
  const tenderClosingDate = meta.claim.view.actions.tenderClosingDate;
  const displayValidationValue =
    data.jobQuotes.edges[0] && data.jobQuotes.edges[0].node
      ? data.jobQuotes.edges[0].node.displayValidationValue
      : false;
  const displayValidation =
    data.jobQuotes.edges[0] && data.jobQuotes.edges[0].node.fields
      ? data.jobQuotes.edges[0].node.fields.scopeValidation
      : false;
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <QuotesActionPresentation
            data={data.claimJob}
            portfolioType={portfolio}
            jobQuoteId={
              data.jobQuotes.edges[0] && data.jobQuotes.edges[0].node.jobQuoteId
            }
            claimId={meta.claim.claimId}
            companyId={meta.claim.insurer.companyId}
            isDisplay={meta.claim.view.actions.updateScopeType.isDisplay}
            tenderClosingDate={tenderClosingDate}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <Paper
            className={classes.root}
            title={
              portfolio !== "Contents" ? (
                <QuotesListHeader
                  displayValidationValue={displayValidationValue}
                  displayValidation={displayValidation}
                />
              ) : null
            }
          >
            {portfolio !== "Contents" ? (
              <QuotesPresentation
                quotes={data.jobQuotes.edges}
                portfolioType={portfolio}
                meta={meta}
                claimJobData={data.claimJob}
                claimId={meta.claim.claimId}
              />
            ) : (
              <QuotesContentPresentation
                quotes={data.jobQuotes.edges}
                portfolioType={portfolio}
              />
            )}
          </Paper>
          <ClaimDetailsPortfolioFilter
            noAll
            portfolio={{
              hasBuilding: meta.claim.hasBuilding,
              hasRestoration: meta.claim.hasRestoration,
              hasContents: meta.claim.hasContents,
            }}
            changePortfolio={changePortfolio}
            value={portfolio.toLowerCase()}
          />
        </Grid>
      </Grid>
    </>
  );
};

/*
  <QuotesPresentation quotes={data.jobQuotes.edges}
    portfolioType={portfolio}
    claimJobData={data.claimJob}
  />
*/

export default withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing.unit * 3,
  },
}))(QuotesContainer);

const query = graphql`
  query QuotesQuery(
    $first: Int
    $where: JobQuoteIndex!
    $claimJobWhere: ENDataEntityKey!
  ) {
    claimJob(where: $claimJobWhere) {
      building {
        tenderCloseDate
      }
      restoration {
        tenderCloseDate
      }
    }
    jobQuotes(first: $first, where: $where) {
      edges {
        node {
          fields {
            scopeValidation {
              isDisplay
              isDisabled
              order
              required
              value
              name
              label
            }
          }
          displayValidationValue
          completed
          total
          approved
          reviewed
          jobQuoteId
          recommended
          quoteStatus {
            statusName
          }
          supplier {
            companyId
            companyName
            companyPhone1
            companyPhone2
          }

          costCentres {
            costCentre {
              costCentreId
              costCentreName
            }
            sirSubtotal
            total: subtotal
            validationSubtotal
          }

          deliveryCharges
          deliveryChargeUsed

          lineItems {
            # map item
            jobQuoteItemId
            scopeItemId

            # accepted item
            authorised

            # item
            costCentre {
              costCentreName
            }
            costItem {
              itemDescription
            }
            lineDescription

            #text
            subtotal

            substitutename

            # scope item
            qty
            policyLimit
          }
          collectExcess

          subtotal
          gst
          total

          sirSubtotal
          sirGst
          sirTotal

          validationTotal

          startAfterContractReceived
          estimatedTimeForRepairs
          originalTotal
          actions {
            acceptQuote {
              isDisplay
              isDisabled
              label
            }
            resetQuote {
              isDisplay
              isDisabled
              label
            }
            removeAuthorityQuote {
              isDisplay
              isDisabled
              label
            }
          }

          unProcessed: scopeTypes(processed: false) {
            gst
            subtotal
            total
            margin
            discount
            sirDiscount
            sirGst
            sirTotal
            sirSubtotal
            sirMargin
            validationDiscount
            validationGst
            validationMargin
            validationSubtotal
            validationTotal
            fields {
              margin {
                label
                isDisplay
                order
              }
              discount {
                label
                isDisplay
                order
              }
              gst {
                label
                isDisplay
                order
              }
              subtotal {
                label
                isDisplay
                order
              }
              total {
                label
                isDisplay
                order
              }
            }
            scopeType {
              name
              id
            }
            costCentres {
              costCentre {
                costCentreId
                costCentreName
              }
              sirSubtotal
              total: subtotal
              validationSubtotal
            }
          }
          processed: scopeTypes(processed: true) {
            gst
            subtotal
            total
            margin
            discount
            sirDiscount
            sirGst
            sirTotal
            sirSubtotal
            sirMargin
            validationDiscount
            validationGst
            validationMargin
            validationSubtotal
            validationTotal
            fields {
              margin {
                label
                isDisplay
                order
              }
              discount {
                label
                isDisplay
                order
              }
              gst {
                label
                isDisplay
                order
              }
              subtotal {
                label
                isDisplay
                order
              }
              total {
                label
                isDisplay
                order
              }
            }
            scopeType {
              name
              id
            }
            costCentres {
              costCentre {
                costCentreId
                costCentreName
              }
              sirSubtotal
              total: subtotal
              validationSubtotal
            }
          }
        }
      }
    }
  }
`;

/*
  gst
          jobQuoteId
          margin
          reviewed
          completed
          reviewed
          subtotal
          originalTotal
          supplier {
            companyId
            companyName
          }
          total
          lineItems {
            purchasePrice
            ageOfItem
            directsupply
            proofOfLoss
            costCentre {
              costCentreId
              costCentreName
            }
            costItem {
              itemDescription
            }
            dimensions
            gst
            jobQuoteItemId
            lineDescription
            location {
              costLocationId
              locationName
            }
            margin
            marginRate
            qty
            rate
            subtotal
            total
            unit {
              unitId
              unitName
            }
          }
*/
