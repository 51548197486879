import React from 'react'
import usePortfolio from 'hooks/usePortfolio'
import ClaimMetaContext from 'pages/Claim/ClaimDetails/ClaimMetaContext'

import Grid from 'components/Mui/Grid'

import ClaimDetailsPortfolioFilter from '../../ClaimDetailsPortfolioFilter'
import EnterDates from './EnterDates'
import StatusHistory from './StatusHistory'

const JobInProgress = () => {
  const meta = React.useContext(ClaimMetaContext)
  const [ portfolio, changePortfolio ] = usePortfolio(['building', 'restoration'])

  if(!portfolio) return null

  return (
    <>
    <Grid container>
      <Grid item xs={12} style={{ position: 'relative' }}>
        <ClaimDetailsPortfolioFilter noAll
          portfolio={{
            hasBuilding: meta.claim.hasBuilding,
            hasRestoration: meta.claim.hasRestoration
          }}
          changePortfolio={changePortfolio}
          value={portfolio.toLowerCase()}
        />
        <Grid fluid>
          <Grid item xs={7}>
            <EnterDates
              meta={meta}
              portfolios={portfolio}
            />
          </Grid>
          <Grid item xs={5}>
            <StatusHistory
              meta={meta}
              portfolios={portfolio}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
  )
}

export default JobInProgress