/**
 * @flow
 * @relayHash 99be0e534b3ac04994d7cc834fda9965
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type CostedScopeInput = {|
  costScopeOptionId?: ?string,
  costScopeAdditionalText?: ?string,
|};
export type RequestCostedScopeMutationVariables = {|
  where: ENDataPortfolioKey,
  input?: ?CostedScopeInput,
|};
export type RequestCostedScopeMutationResponse = {|
  +claimRequestCostedScope: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +insuranceCompletedSiteReport: boolean,
      +scopingSupplier: ?{|
        +companyName: string
      |},
    |},
  |}
|};
export type RequestCostedScopeMutation = {|
  variables: RequestCostedScopeMutationVariables,
  response: RequestCostedScopeMutationResponse,
|};
*/


/*
mutation RequestCostedScopeMutation(
  $where: ENDataPortfolioKey!
  $input: CostedScopeInput
) {
  claimRequestCostedScope(where: $where, input: $input) {
    messages
    success
    result {
      insuranceCompletedSiteReport
      scopingSupplier {
        companyName
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CostedScopeInput",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CostedScopeInput"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "insuranceCompletedSiteReport",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RequestCostedScopeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimRequestCostedScope",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "scopingSupplier",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RequestCostedScopeMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimRequestCostedScope",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "scopingSupplier",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "RequestCostedScopeMutation",
    "id": null,
    "text": "mutation RequestCostedScopeMutation(\n  $where: ENDataPortfolioKey!\n  $input: CostedScopeInput\n) {\n  claimRequestCostedScope(where: $where, input: $input) {\n    messages\n    success\n    result {\n      insuranceCompletedSiteReport\n      scopingSupplier {\n        companyName\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '678a07cd66adfb88d02b9cea5f0dcd88';
module.exports = node;
