/**
 * @flow
 * @relayHash 5cec82938e1a39531ed11b41628207db
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CallSessionUserType = "GUEST" | "OWNER" | "%future added value";
export type CallStatusEnum = "CHECK_REQUIREMENTS" | "CHECK_REQUIREMENTS_FAILED" | "CONNECTED" | "ENDED" | "INIT_SESSION" | "SIGNALLING" | "SIGNALLING_FAILED" | "%future added value";
export type RoomCreateDataInput = {|
  endataCaseId?: ?string,
  referenceNumber: string,
  name?: ?string,
  address?: ?string,
  phone?: ?string,
  email?: ?string,
|};
export type CreateRoomMutationVariables = {|
  data: RoomCreateDataInput
|};
export type CreateRoomMutationResponse = {|
  +roomCreate: ?{|
    +id: string,
    +callSessions: $ReadOnlyArray<{|
      +id: string,
      +userType: ?CallSessionUserType,
      +callStatus: {|
        +status: CallStatusEnum,
        +message: string,
      |},
    |}>,
  |}
|};
export type CreateRoomMutation = {|
  variables: CreateRoomMutationVariables,
  response: CreateRoomMutationResponse,
|};
*/


/*
mutation CreateRoomMutation(
  $data: RoomCreateDataInput!
) {
  roomCreate(data: $data) {
    id
    callSessions {
      id
      userType
      callStatus {
        status
        message
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "RoomCreateDataInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "data",
    "type": "RoomCreateDataInput"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userType",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "message",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateRoomMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "roomCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Room",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "callSessions",
            "storageKey": null,
            "args": null,
            "concreteType": "CallSession",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "callStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "CallStatusConfig",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateRoomMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "roomCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Room",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "callSessions",
            "storageKey": null,
            "args": null,
            "concreteType": "CallSession",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "callStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "CallStatusConfig",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateRoomMutation",
    "id": null,
    "text": "mutation CreateRoomMutation(\n  $data: RoomCreateDataInput!\n) {\n  roomCreate(data: $data) {\n    id\n    callSessions {\n      id\n      userType\n      callStatus {\n        status\n        message\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f327edd40e917b6d19413a4c4e288af4';
module.exports = node;
