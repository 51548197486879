import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import Dialog from "components/Mui/Dialog";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";

import UpdateContentItemForm from "components/LineItem/UpdateContentItemForm";
import ContentQuoteUpdateMutation from "./ContentQuoteUpdateMutation";

const QuoteContentItemUpdate = ({
  classes,
  jobQuoteId,
  portfolioType,
  initialValues,
}) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const refresh = window[Symbol.for("__refreshQuote")];

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => validate(values)}
      onSubmit={(input, actions) => {
        setTimeout(async () => {
          const variables = {
            where: { id: jobQuoteId },
            input: {
              proofOfLoss: input.proofOfLoss,
              rate: input.rate,
              substitutename: input.substitutename,
            },
          };
          actions.setSubmitting(false);

          const res = await ContentQuoteUpdateMutation(variables);
          if (res.ok) {
            snackbar.toggleOpen({ message: res.messages[0] });
            actions.setSubmitting(false);

            if (res.success) {
              refresh();
            }
          } else {
            snackbar.toggleOpen({
              message: "Unable to update the item, please try again",
            });
          }
        }, 400);
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Dialog
          noForm
          noActions
          symbol={`${jobQuoteId}__quoteUpdateItem`}
          paperClassname={classes.paper}
          title="Update Quote Item"
          content={
            <Form>
              <Grid fluid>
                <UpdateContentItemForm
                  values={values}
                  isSubmitting={isSubmitting}
                  jobQuoteId={jobQuoteId}
                  portfolioType={portfolioType}
                  setFieldValue={setFieldValue}
                  isQuote={true}
                />
              </Grid>
              <Grid fluid>
                <div style={{ marginLeft: "auto" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    label="Update"
                    type="submit"
                  />
                  <Button
                    color="primary"
                    label="Close"
                    onClick={() =>
                      window[
                        Symbol.for(`${jobQuoteId}__quoteUpdateItem`)
                      ].handleClose()
                    }
                  />
                </div>
              </Grid>
            </Form>
          }
        />
      )}
    </Formik>
  );
};

export default withStyles((theme) => ({
  paper: {
    maxWidth: "50% !important",
  },
}))(QuoteContentItemUpdate);

const validate = (values) => {
  let errors = {};

  if (!values.locationId) errors.locationId = "Required!";
  if (!values.costCentreId) errors.costCentreId = "Required!";
  if (!values.costItemId) errors.costItemId = "Required!";
  // if(isDirectSupply) {
  //   if(!values.directsupply && values.directsupply !== false) errors.directsupply = 'Required!'
  // }
  if (!values.qty) errors.qty = "Required!";
  if (!values.rate && values.rate !== 0) errors.rate = "Required!";

  if (values.lineDescription.length > 1500)
    errors.lineDescription = "Maximum character limit is 1500";
  if (values.qty.length > 4 + 3) errors.qty = "Maximum character limit is 4";
  if (
    (String(values.rate).indexOf("-") !== -1 &&
      String(values.rate).length > 7 + 4) ||
    (String(values.rate).indexOf("-") === -1 &&
      String(values.rate).length > 7 + 3)
  )
    errors.rate = "Maximum character limit is 7";
  if (String(values.substitutename).length > 250)
    errors.substitutename = "Maximum character limit is 250";

  return errors;
};
