/**
 * @flow
 * @relayHash eaf20b36fb62df43769fe62dee75ec73
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessRole = "Adjustor" | "Administrator" | "Agent" | "Builder" | "ContentSupplier" | "Customer" | "Estimator" | "Insurance" | "InternalAB" | "LossAdjuster" | "MultiTenderAssessor" | "Restorer" | "Specialist" | "SystemAdmin" | "UNDEFINED" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type SupplierStatusFilter = "AcceptedOnly" | "RequestedAndAvailable" | "RequestedOnly" | "%future added value";
export type SupplierTypeFilter = "ManagerAndSupplier" | "QuotingSupplier" | "Supplier" | "SupplierExcludeScoping" | "%future added value";
export type ClaimParticipantIndex = {|
  claimId: string,
  postcode?: ?string,
  portfolioTypes?: ?$ReadOnlyArray<?PortfolioType>,
  participantStatus?: ?SupplierStatusFilter,
  participantType?: ?SupplierTypeFilter,
|};
export type HistoryActionQueryVariables = {|
  where?: ?ClaimParticipantIndex
|};
export type HistoryActionQueryResponse = {|
  +claimParticipants: ?$ReadOnlyArray<?{|
    +company: ?{|
      +companyType: ?AccessRole
    |},
    +value: number,
    +group: string,
    +label: string,
  |}>
|};
export type HistoryActionQuery = {|
  variables: HistoryActionQueryVariables,
  response: HistoryActionQueryResponse,
|};
*/


/*
query HistoryActionQuery(
  $where: ClaimParticipantIndex
) {
  claimParticipants(where: $where) {
    company {
      companyType
      id
    }
    value: companyId
    group: companyRole
    label: companyName
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimParticipantIndex",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ClaimParticipantIndex"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyType",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "companyId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": "group",
  "name": "companyRole",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "HistoryActionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimParticipants",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimParticipant",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "company",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HistoryActionQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimParticipants",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimParticipant",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "company",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/)
            ]
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "HistoryActionQuery",
    "id": null,
    "text": "query HistoryActionQuery(\n  $where: ClaimParticipantIndex\n) {\n  claimParticipants(where: $where) {\n    company {\n      companyType\n      id\n    }\n    value: companyId\n    group: companyRole\n    label: companyName\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '08cda3188875e669d5e7e1a937d65a6e';
module.exports = node;
