import React from 'react'
import { useParams, useHistory, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import MediaList from './MediaList'
import MediaRoot from './MediaRoot'

export default ({ rooms, setSelectedRoom }) => {
  const { roomId } = useParams()
  const [selectedMedia, setSelectedMedia] = React.useState(null)

  const currentRoom = rooms ? rooms.find(room => room.id === roomId) : null
  const currentRoomId = currentRoom ? currentRoom.id : null
  React.useEffect(() => {
    if (currentRoomId) setSelectedRoom(currentRoomId)
  }, [currentRoomId])

  // const { push } = useHistory()
  // const { url } = useRouteMatch()
  // const firstMedia = currentRoom &&
  // React.useEffect(() => {
  //   if (currentRoomId && !selectedMedia) {
  //     console.log(currentRoom)

  //   }
  // }, [currentRoomId, selectedMedia])

  const photos = (currentRoom && currentRoom.photos.length) ? currentRoom.photos.filter(photo => photo).map(photo => ({ ...photo, type: 'Photo' })) : []
  const videos = (currentRoom && currentRoom.videos.length) ? currentRoom.videos.filter(video => video).map(video => ({ ...video, type: 'Video' })) : []
  const mediaItems = [...videos, ...photos]
  if (!currentRoom || !mediaItems.length) return 'No media found'

  const roomInfo = currentRoom.case
  const contactInfo = currentRoom.callSessions.find(session => session.userType === 'GUEST').contact

  return (
    <Grid container style={{ margin: 0, width: '100%', height: '100%' }}>
      <Grid item style={{ position: 'relative', zIndex: 2, height: '100%', minWidth: 200 }}>
        <MediaList items={mediaItems} selectedMedia={selectedMedia} />
      </Grid>
      <Grid item xs style={{ position: 'relative', zIndex: 1, height: '100%' }}>
        <Route path='/video/rooms/:roomId/:mediaId' render={() => <MediaRoot roomInfo={roomInfo} contactInfo={contactInfo} items={mediaItems} setSelectedMedia={setSelectedMedia} />} />
      </Grid>
    </Grid>
  )
}