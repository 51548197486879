import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Collapse from '@material-ui/core/Collapse'
import ClaimTypeIcon from 'components/Mui/ClaimTypeIcon'

import FeeItemList from './FeeItemList'
import { InvoiceActiveContextProvider } from './InvoiceActiveContext'

import moneyFormat from 'common/utils/moneyFormat'

export default ({ categories }) => {
  const initialState = { id: null }
  const reducer = (state, action) => {
    switch(action.type) {
      case 'mouseEnter':
        return {
          ...state,
          id: action.id
        }
      case 'mouseLeave':
        return {
          ...state,
          id: null
        }
      default:
        return state
    }
  }

  return (
    <List style={{ padding: 0 }}>
      <InvoiceActiveContextProvider initialState={initialState} reducer={reducer}>
        {categories.map(category => <FeeCategory key={category.id} category={category} />)}
      </InvoiceActiveContextProvider>
    </List>
  )
}
const FeeCategory = withStyles(theme => ({
  categoryHeader: {
    background: '#d4d4d4',
    fontWeight: 600,
    margin: 0,
    padding: '0 16px 0 0'
  },
  amount: {
    textAlign: 'center',
  },
})) (({ category, classes }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true)

  return (
    <React.Fragment key={category.id}>
      <ListItem component={Grid} fluid className={classes.categoryHeader}>
        <Grid item xs={4}>
          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
            <Icon>
              {isCollapsed ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </Icon>
          </IconButton>
          <ClaimTypeIcon type={category.claimPortfolioType} />
          {category.name}
        </Grid>
        <Grid item xs={1} className={classes.amount}>
          {moneyFormat(category.feeItemsAggregate.totalAuthorisedAmount)}
        </Grid>
        {/* <Grid item xs={1} /> */}
        {/* <Grid item xs={1} /> */}
        {/* <Grid item xs={1} /> */}
        {/* <Grid item xs={2}> */}
        {/*   {isCollapsed ? '' : 'Some status'} */}
        {/* </Grid> */}
      </ListItem>
      <Collapse in={isCollapsed}>
        <FeeItemList items={category.feeItems} />
      </Collapse>
      <Divider />
    </React.Fragment>
  )
})