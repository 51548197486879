import React, { Component } from 'react'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import Input from 'components/Formik/Input/'
import PhoneInput from 'components/Formik/PhoneInput/'

class TenantInfoCard extends Component {
  render() {
    const { user } = this.props

    return (
      <Paper
        title={'Main Contact Information'}
        style={{ height: '100%' }}
        content={(
          <Grid fluid>
            {[
              {
                col: 8, component: Input, leadIcon: 'person',
                label: 'Contact Name',
                name: 'tenantName',
                // value: claim.tenantDetails.name ? claim.tenantDetails.name : ''
              },
              { col: 4 },

              {
                col: 4, component: PhoneInput, leadIcon: 'phone',
                label: 'Phone 1',
                name: 'tenantPhone1',
                // value: claim.tenantDetails.phone1 ? claim.tenantDetails.phone1 : ''
              },
              {
                col: 4, component: PhoneInput, leadIcon: 'phone',
                label: 'Phone 2',
                name: 'tenantPhone2',
                // value: claim.tenantDetails.phone2 ? claim.tenantDetails.phone2 : ''
              },
              {
                col: 4, component: PhoneInput, leadIcon: 'phone',
                label: 'Phone 3',
                name: 'tenantPhone3',
                // value: claim.tenantDetails.phone3 ? claim.tenantDetails.phone3 : ''
              },
            ].map((child, key) => {
              const { col, component: Component, unMountOn, ...props } = child
              return (
                <Grid item xs={col} key={key}>
                  {Component && !unMountOn && <Component {...props}
                    readOnly={!user.type.isAdmin}
                  />}
                </Grid>
              )
            })}
          </Grid>  
        )}
      /> 
    )
  }
}

export default TenantInfoCard