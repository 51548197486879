import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Call from './Call'
import VideoCall from './VideoCall'
import Room from './Room'
import EndCall from './EndCall'
import MediaSession from './MediaSession'

import DialogPromise from 'components/Mui/DialogPromise'

const routes = [
  { exact: true, path: '/video/call', component: VideoCall },
  { exact: true, path: '/video/end-call', component: EndCall },
  { exact: false, path: '/video/rooms', component: MediaSession },
  { exact: true, component: () => <Redirect to='/video/call'/>  },
]

export default () => (
  <>
    <Switch>
      {routes.map((route, index) => <Route key={index} {...route} />)}
    </Switch>
    <DialogPromise symbol='__videoDialog' />
  </>
)