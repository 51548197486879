import React, { Component } from 'react'

import MuiCircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { withStyles} from '@material-ui/core/styles'

class CircularProgress extends Component {
  render() {
    const { classes, value, overrideColor, ...rest } = this.props
    return (
      <div className={classes.root}>
        <MuiCircularProgress
          size={120}
          value={100}
          // classes={classes.progress}
          style={{color: overrideColor}}
          {...rest}
        />
        <Typography className={classes.typo} component='span'>{value}</Typography>
      </div>
    )
  }
}

export default withStyles(theme => ({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  typo: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}))(CircularProgress)