/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type QuoteCostType = "Labour" | "Material" | "UnDefined" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MakeSafeListContainer_makeSafeScopeTypes$ref: FragmentReference;
export type MakeSafeListContainer_makeSafeScopeTypes = $ReadOnlyArray<{|
  +discount: ?number,
  +fields: ?{|
    +costCentre: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +description: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +discount: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +gst: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +margin: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +marginBreakdown: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +marginRate: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +qty: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +rate: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +sIRRate: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +subtotal: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +total: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +unit: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
    +action: {|
      +label: ?string,
      +isDisplay: boolean,
    |},
  |},
  +gst: ?number,
  +id: string,
  +locations: ?$ReadOnlyArray<?{|
    +dimensions: string,
    +id: string,
    +items: ?$ReadOnlyArray<?{|
      +costCentre: ?{|
        +costCentreId: number,
        +costCentreName: string,
        +costType: ?QuoteCostType,
        +directSupplier: boolean,
        +id: string,
      |},
      +costItem: ?{|
        +costItemId: number,
        +id: string,
        +itemDescription: string,
        +units: ?$ReadOnlyArray<?{|
          +defaultValue: number,
          +unitId: number,
          +unitName: string,
        |}>,
      |},
      +dimensions: ?string,
      +gst: ?number,
      +id: string,
      +lineDescription: string,
      +location: ?{|
        +costLocationId: number,
        +id: string,
        +locationName: string,
      |},
      +logdate: any,
      +makeSafeId: number,
      +makeSafeItemId: number,
      +margin: ?number,
      +marginRate: number,
      +qty: number,
      +rate: ?number,
      +scopeType: ?{|
        +id: string,
        +name: string,
      |},
      +subtotal: ?number,
      +total: ?number,
      +unit: ?{|
        +defaultValue: number,
        +unitName: string,
        +unitId: number,
      |},
    |}>,
    +location: ?{|
      +costLocationId: number,
      +locationName: string,
      +id: string,
    |},
  |}>,
  +margin: ?number,
  +scopeType: ?{|
    +id: string,
    +name: string,
  |},
  +subtotal: ?number,
  +total: ?number,
  +$refType: MakeSafeListContainer_makeSafeScopeTypes$ref,
|}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "label",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "isDisplay",
    "args": null,
    "storageKey": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "gst",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimensions",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "defaultValue",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unitId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unitName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costLocationId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "locationName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "margin",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "scopeType",
  "storageKey": null,
  "args": null,
  "concreteType": "JobScopeType",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MakeSafeListContainer_makeSafeScopeTypes",
  "type": "JobMakeSafeScopeTypeGroup",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "discount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fields",
      "storageKey": null,
      "args": null,
      "concreteType": "QuoteItemField",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "costCentre",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "description",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "discount",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldInput",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "gst",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "margin",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "marginBreakdown",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "marginRate",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "qty",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "rate",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "sIRRate",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "subtotal",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "total",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "unit",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "action",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldDisplay",
          "plural": false,
          "selections": (v0/*: any*/)
        }
      ]
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "locations",
      "storageKey": null,
      "args": null,
      "concreteType": "JobMakeSafeLocationGroup",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v2/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "items",
          "storageKey": null,
          "args": null,
          "concreteType": "JobMakeSafeItem",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "costCentre",
              "storageKey": null,
              "args": null,
              "concreteType": "QuoteCostCentre",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "costCentreId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "costCentreName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "costType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "directSupplier",
                  "args": null,
                  "storageKey": null
                },
                (v2/*: any*/)
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "costItem",
              "storageKey": null,
              "args": null,
              "concreteType": "QuoteCostItem",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "costItemId",
                  "args": null,
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "itemDescription",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "units",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "QuoteItemUnit",
                  "plural": true,
                  "selections": [
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/)
                  ]
                }
              ]
            },
            (v3/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lineDescription",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "location",
              "storageKey": null,
              "args": null,
              "concreteType": "QuoteCostLocation",
              "plural": false,
              "selections": [
                (v7/*: any*/),
                (v2/*: any*/),
                (v8/*: any*/)
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "logdate",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "makeSafeId",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "makeSafeItemId",
              "args": null,
              "storageKey": null
            },
            (v9/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "marginRate",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "qty",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "rate",
              "args": null,
              "storageKey": null
            },
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "unit",
              "storageKey": null,
              "args": null,
              "concreteType": "QuoteItemUnit",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                (v6/*: any*/),
                (v5/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "location",
          "storageKey": null,
          "args": null,
          "concreteType": "QuoteCostLocation",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            (v8/*: any*/),
            (v2/*: any*/)
          ]
        }
      ]
    },
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/)
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '5c02499fca63870d3379abfd996dde5d';
module.exports = node;
