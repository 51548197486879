/**
 * @flow
 * @relayHash 1cf2cfb3b1747fa9a200461795837151
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type ResetScopeMutationVariables = {|
  where: ENDataPortfolioKey
|};
export type ResetScopeMutationResponse = {|
  +jobQuoteReset: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +completed: boolean,
      +reviewed: boolean,
      +portfolioType: ?PortfolioType,
      +subtotal: ?number,
      +supplier: ?{|
        +companyId: number,
        +companyName: string,
      |},
    |},
  |}
|};
export type ResetScopeMutation = {|
  variables: ResetScopeMutationVariables,
  response: ResetScopeMutationResponse,
|};
*/


/*
mutation ResetScopeMutation(
  $where: ENDataPortfolioKey!
) {
  jobQuoteReset(where: $where) {
    messages
    success
    result {
      completed
      reviewed
      portfolioType
      subtotal
      supplier {
        companyId
        companyName
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completed",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reviewed",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "portfolioType",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyId",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ResetScopeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobQuoteReset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuotePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuote",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "supplier",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ResetScopeMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobQuoteReset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuotePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuote",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "supplier",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              },
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ResetScopeMutation",
    "id": null,
    "text": "mutation ResetScopeMutation(\n  $where: ENDataPortfolioKey!\n) {\n  jobQuoteReset(where: $where) {\n    messages\n    success\n    result {\n      completed\n      reviewed\n      portfolioType\n      subtotal\n      supplier {\n        companyId\n        companyName\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c7deb55c475d63f8a4f176da8b6d4840';
module.exports = node;
