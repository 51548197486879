import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MuiSwitch from '@material-ui/core/Switch'
import classNames from 'clsx'

class Switch extends Component {
  state = {
    defaultValue: false,
    innerValue: false
  }

  componentWillMount = () => {
    const { checked } = this.props
    // if(this.props.checked) this.setState({ value: this.props.checked })
    this.setState({ innerValue : checked, defaultValue: checked })
  }
  shouldComponentUpdate = nextProps => {
    if(
      this.state.innerValue !== nextProps.checked
      && nextProps.checked !== this.state.defaultValue
      && nextProps.checked !== undefined 
      && nextProps.checked !== null
    ) {
      this.setState({ innerValue: nextProps.checked })
    }

    return true
  }

  render() {
    const { innerValue } = this.state
    // eslint-disable-next-line
    const { 
      label, classes, inputProps, name,
      viewMode, disabled,
      labelOn, labelOff,
      checked, // don't remove
      ...res
    } = this.props

    return (
      <FormControl component='fieldset' className={classes.root} fullWidth disabled={viewMode || disabled}>
        <FormLabel component='legend' className={classNames(
          classes.label,
          { [classes.viewLabel]: viewMode }
        )}>
          {label}
        </FormLabel>
        <FormGroup row>
          <FormControlLabel className={classNames(
            classes.switchWrapper,
            { [classes.viewLabel]: viewMode }
          )}
            control={
              <MuiSwitch
                inputProps={{
                  ...inputProps,
                  name,
                  customtype: 'checkbox'
                }}
                checked={innerValue}
                onChange={this.handleChange}
                color='primary' {...res}
                className={viewMode ? classes.viewSwitch : ''}
              />
            }
            label={this.state.innerValue ? `${labelOn ? labelOn : 'Yes'}` : `${labelOff ? labelOff : 'No'}`}
          />
        </FormGroup>
      </FormControl>
    )
  }

  handleChange = event => this.setState({ innerValue: event.target.checked })
}

export default withStyles(theme => ({
  root: {
    width: '100%'
  },
  label: {
    fontSize: '0.8rem',
    fontWeight: 600
  },
  switchWrapper: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  viewLabel: {
    color: 'rgba(0, 0, 0, 0.78) !important',

    '& > span': {
      color: 'rgba(0, 0, 0, 0.78) !important'
    }
  },
  viewSwitch: {
    '& > span': {
      color: 'red !important'
    }
  }
}))(Switch)