/**
 * @flow
 * @relayHash 43182f60735dd8f880fd28476e144d23
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimNoteCreate = {|
  claimId: string,
  message: string,
  private: number,
  portfolioType?: ?PortfolioType,
|};
export type NewNoteActionMutationVariables = {|
  input: ClaimNoteCreate
|};
export type NewNoteActionMutationResponse = {|
  +createClaimNote: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +id: string,
      +jobNoteId: number,
      +logDate: ?any,
      +message: string,
      +portfolioType: ?PortfolioType,
      +private: number,
      +user: ?{|
        +userName: string,
        +company: ?{|
          +companyName: string
        |},
      |},
    |},
  |}
|};
export type NewNoteActionMutation = {|
  variables: NewNoteActionMutationVariables,
  response: NewNoteActionMutationResponse,
|};
*/


/*
mutation NewNoteActionMutation(
  $input: ClaimNoteCreate!
) {
  createClaimNote(input: $input) {
    success
    messages
    result {
      id
      jobNoteId
      logDate
      message
      portfolioType
      private
      user {
        userName
        company {
          companyName
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimNoteCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ClaimNoteCreate!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "jobNoteId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logDate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "message",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "portfolioType",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "private",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NewNoteActionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimNote",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimNotePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimNote",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "company",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NewNoteActionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimNote",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimNotePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimNote",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "company",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "NewNoteActionMutation",
    "id": null,
    "text": "mutation NewNoteActionMutation(\n  $input: ClaimNoteCreate!\n) {\n  createClaimNote(input: $input) {\n    success\n    messages\n    result {\n      id\n      jobNoteId\n      logDate\n      message\n      portfolioType\n      private\n      user {\n        userName\n        company {\n          companyName\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8652282fe72079c36ec504a754ad0bf';
module.exports = node;
