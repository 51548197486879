/**
 * @flow
 * @relayHash da727e7200d7efd799e7db383b2f1097
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JobContentAuthoriseInput = {|
  authorisedItems: $ReadOnlyArray<number>,
  deliveryChargeUsed: boolean,
|};
export type ENDataEntityKey = {|
  id: string
|};
export type AcceptContentItemMutationVariables = {|
  input: JobContentAuthoriseInput,
  where: ENDataEntityKey,
|};
export type AcceptContentItemMutationResponse = {|
  +jobContentAuthorise: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type AcceptContentItemMutation = {|
  variables: AcceptContentItemMutationVariables,
  response: AcceptContentItemMutationResponse,
|};
*/


/*
mutation AcceptContentItemMutation(
  $input: JobContentAuthoriseInput!
  $where: ENDataEntityKey!
) {
  jobContentAuthorise(where: $where, input: $input) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobContentAuthoriseInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "jobContentAuthorise",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "JobContentAuthoriseInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ENDataEntityKey!"
      }
    ],
    "concreteType": "JobQuotePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AcceptContentItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AcceptContentItemMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AcceptContentItemMutation",
    "id": null,
    "text": "mutation AcceptContentItemMutation(\n  $input: JobContentAuthoriseInput!\n  $where: ENDataEntityKey!\n) {\n  jobContentAuthorise(where: $where, input: $input) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '342b71a867707aee9c483796176227c8';
module.exports = node;
