/**
 * @flow
 * @relayHash d5fc3f118e08ef7e7a3fd90779352111
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EstimatorKey = {|
  id: string
|};
export type EstimatorUpdateInput = {|
  name?: ?string,
  phoneNumber?: ?string,
  disabled?: ?boolean,
|};
export type EstimatorUpdateMutationVariables = {|
  where: EstimatorKey,
  input: EstimatorUpdateInput,
|};
export type EstimatorUpdateMutationResponse = {|
  +estimatorUpdate: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type EstimatorUpdateMutation = {|
  variables: EstimatorUpdateMutationVariables,
  response: EstimatorUpdateMutationResponse,
|};
*/


/*
mutation EstimatorUpdateMutation(
  $where: EstimatorKey!
  $input: EstimatorUpdateInput!
) {
  estimatorUpdate(where: $where, input: $input) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "EstimatorKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "EstimatorUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "estimatorUpdate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "EstimatorUpdateInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "EstimatorKey!"
      }
    ],
    "concreteType": "EstimatorPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EstimatorUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "EstimatorUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "EstimatorUpdateMutation",
    "id": null,
    "text": "mutation EstimatorUpdateMutation(\n  $where: EstimatorKey!\n  $input: EstimatorUpdateInput!\n) {\n  estimatorUpdate(where: $where, input: $input) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c3bd9d628b1407d2786882c838e47acb';
module.exports = node;
