/**
 * @flow
 * @relayHash 0b29f11e2f0cf3687e7c3be00aa697c3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CommunicationButtonFragment_communications$ref = any;
export type CommunicationButtonQueryVariables = {||};
export type CommunicationButtonQueryResponse = {|
  +$fragmentRefs: CommunicationButtonFragment_communications$ref
|};
export type CommunicationButtonQuery = {|
  variables: CommunicationButtonQueryVariables,
  response: CommunicationButtonQueryResponse,
|};
*/


/*
query CommunicationButtonQuery {
  ...CommunicationButtonFragment_communications
}

fragment CommunicationButtonFragment_communications on Query {
  claimCommuications(where: {acknowledged: false, boxes: Inbox}, first: 2) {
    totalCount
    edges {
      node {
        message
        claimId
        claimRef
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CommunicationButtonQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "CommunicationButtonFragment_communications",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CommunicationButtonQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimCommuications",
        "storageKey": "claimCommuications(first:2,where:{\"acknowledged\":false,\"boxes\":\"Inbox\"})",
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 2,
            "type": "Int"
          },
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "acknowledged": false,
              "boxes": "Inbox"
            },
            "type": "CommunicationFilter"
          }
        ],
        "concreteType": "ClaimCommunicationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimCommunicationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimCommunication",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "message",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "claimId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "claimRef",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CommunicationButtonQuery",
    "id": null,
    "text": "query CommunicationButtonQuery {\n  ...CommunicationButtonFragment_communications\n}\n\nfragment CommunicationButtonFragment_communications on Query {\n  claimCommuications(where: {acknowledged: false, boxes: Inbox}, first: 2) {\n    totalCount\n    edges {\n      node {\n        message\n        claimId\n        claimRef\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
// prettier-ignore
(node/*: any*/).hash = '9799351614adee07209cc47086e30d58';
module.exports = node;
