import { commitMutation, graphql } from 'react-relay'
import { ConnectionHandler } from 'relay-runtime'
import environment from 'common/relay'

const mutation = graphql`
  mutation MakeSafeCompletedMutation($where: ENDataPortfolioKey!) {
    jobMakeSafeQuoteComplete(where: $where) {
      messages
      success
      result {
        completed
        actions {
            addLineItem {
              isDisplay
              isDisabled
              label
            }
            makeSafeAppointment {
              isDisplay
              isDisabled
              label
            }
            makeSafeInitialCall {
              isDisplay
              isDisabled
              label
            }
            onSiteMakeSafe {
              isDisplay
              isDisabled
              label
            }
            resetMakeSafe {
              isDisplay
              isDisabled
              label
            }
            submitMakeSafe {
              isDisplay
              isDisabled
              label
            }
          }
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      updater: store => {
        const success = store.getRootField('jobMakeSafeQuoteComplete').getValue('success')
        if(success) {
          const root = store.getRoot()
          const conn = ConnectionHandler.getConnection(root, 'MakeSafeContainerQuery_makeSafes')
          const node = conn.getLinkedRecords('edges')[0].getLinkedRecord('node')

          const newCompleted = store.getRootField('jobMakeSafeQuoteComplete')
            .getLinkedRecord('result')
            .getValue('completed')

          node.setValue(newCompleted, 'completed')
        }
      },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.jobMakeSafeQuoteComplete.success,
          messages: res.jobMakeSafeQuoteComplete.messages
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}
