/**
 * @flow
 * @relayHash f3318ee5fcb5db068d1410addad2d7d2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimCategory = "NonUrgent" | "SemiUrgent" | "Urgent" | "%future added value";
export type LogLevel = "Critical" | "Debug" | "Error" | "Information" | "None" | "Trace" | "Warning" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type ClaimJobCreate = {|
  refNumber: string,
  contentsRefNum?: ?string,
  claimDescription: string,
  insurerId: string,
  mapRef?: ?string,
  lotNumber?: ?string,
  planNumber?: ?string,
  casemanagerId: string,
  brcId?: ?string,
  homeAssessorId?: ?string,
  visitTypeID?: ?string,
  externalLossAdjusterId?: ?string,
  agentId?: ?string,
  multipleRisks?: ?boolean,
  riskname?: ?string,
  additionalRefNumber?: ?string,
  associatedRiskJobId?: ?number,
  requireCustomLogin?: ?boolean,
  customLoginEmail?: ?string,
  policyCoverId?: ?string,
  policyTypeId?: ?string,
  incidentDetail: IncidentDetailNewType,
  insured: ContactDetailInput,
  tenantDetails?: ?ContactInput,
  portfolios?: ?$ReadOnlyArray<?ClaimPortfolioCreate>,
|};
export type IncidentDetailNewType = {|
  eventTypeId: string,
  catCodeId: string,
  habitableProperty?: ?boolean,
  asbestos?: ?boolean,
  hold?: ?boolean,
  makeSafeRequired: boolean,
  vulnerableMember?: ?boolean,
  incidentDate: any,
  riskAddress: AddressInput,
  category?: ?ClaimCategory,
|};
export type AddressInput = {|
  line1?: ?string,
  line2?: ?string,
  suburb?: ?string,
  postcode?: ?string,
  state?: ?StateEnum,
|};
export type ContactDetailInput = {|
  name?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  phone3?: ?string,
  salutation?: ?string,
  email?: ?string,
  fax?: ?string,
  postalAddress?: ?AddressInput,
|};
export type ContactInput = {|
  name?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  phone3?: ?string,
|};
export type ClaimPortfolioCreate = {|
  portfolioType: PortfolioType,
  toProvideSiteReport: boolean,
  sumInsured: number,
  excessValue: number,
  toCollectExcess: boolean,
  scopingSupplierId: string,
  quotingSupplierIds?: ?$ReadOnlyArray<string>,
|};
export type AddClaimMutationVariables = {|
  input: ClaimJobCreate
|};
export type AddClaimMutationResponse = {|
  +createClaimJob: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +fieldErrors: ?$ReadOnlyArray<?{|
      +fieldName: string,
      +level: LogLevel,
      +message: string,
    |}>,
    +result: ?{|
      +claimId: number
    |},
  |}
|};
export type AddClaimMutation = {|
  variables: AddClaimMutationVariables,
  response: AddClaimMutationResponse,
|};
*/


/*
mutation AddClaimMutation(
  $input: ClaimJobCreate!
) {
  createClaimJob(input: $input) {
    success
    messages
    fieldErrors {
      fieldName
      level
      message
    }
    result {
      claimId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimJobCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ClaimJobCreate!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "fieldErrors",
  "storageKey": null,
  "args": null,
  "concreteType": "EntityFieldError",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fieldName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "level",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "claimId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddClaimMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJob",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddClaimMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJob",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddClaimMutation",
    "id": null,
    "text": "mutation AddClaimMutation(\n  $input: ClaimJobCreate!\n) {\n  createClaimJob(input: $input) {\n    success\n    messages\n    fieldErrors {\n      fieldName\n      level\n      message\n    }\n    result {\n      claimId\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2f854088d649954a8603ffd9e284e91';
module.exports = node;
