import React from "react";
import { Formik, Form } from "formik";
import { commonCharactersRegexp } from "common/inputRegex";

import Grid from "components/Mui/Grid";
import Dialog from "components/Mui/Dialog";
import Button from "components/Mui/Button";
import AddItemForm from "components/LineItem/AddItemForm";

import MakeSafeAddItemMutation from "./MakeSafeAddItemMutation";
import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";

const MakeSafeAddItem = ({ portfolioType, claimId, data }) => {
  const meta = useMetaContext();
  const snackbar = window[Symbol.for("__snackbar")];
  const handleClose = () => {
    const actions = window[Symbol.for("__makeSafeAddItem")];
    if (actions) actions.handleClose();
  };

  /**
   * METHODs
   */
  const getVariables = (values, portfolioType, claimId) => {
    let items = [];
    if (values.labour_costCentreId)
      items.push({
        locationId: Number(values.locationId),
        dimensions: values.dimensions,
        scopeTypeId: values.scopeTypeId,
        costCentreId: Number(values.labour_costCentreId),
        costItemId: Number(values.labour_costItemId),
        lineDescription: values.labour_lineDescription,
        unitId: Number(values.labour_unitId),
        marginRate: Number(values.labour_marginRate),
        qty: Number(values.labour_qty),
        rate: Number(values.labour_rate),
      });
    if (values.material_costCentreId)
      items.push({
        locationId: Number(values.locationId),
        dimensions: values.dimensions,
        scopeTypeId: values.scopeTypeId,
        costCentreId: Number(values.material_costCentreId),
        costItemId: Number(values.material_costItemId),
        lineDescription: values.material_lineDescription,
        unitId: Number(values.material_unitId),
        marginRate: Number(values.material_marginRate),
        qty: Number(values.material_qty),
        rate: Number(values.material_rate),
      });

    return {
      input: {
        claimId,
        portfolioType,
        items,
      },
    };
  };
  const scopeTypeId =
    data &&
    data.jobScopeTypes &&
    data.jobScopeTypes[0] &&
    data.jobScopeTypes[0].id
      ? data.jobScopeTypes[0].id
      : "";
  return (
    <Formik
      initialValues={{
        locationId: "",
        dimensions: "",
        scopeTypeId: scopeTypeId,
        labour_costCentreId: "",
        labour_costItemId: "",
        labour_lineDescription: "",
        labour_unitId: "",
        labour_marginRate: meta.claim.insurer.isMarginUpdate ? "" : 0,
        labour_qty: "",
        labour_rate: "",

        material_costCentreId: "",
        material_costItemId: "",
        material_lineDescription: "",
        material_unitId: "",
        material_marginRate: meta.claim.insurer.isMarginUpdate ? "" : 0,
        material_qty: "",
        material_rate: "",
      }}
      validate={(values) => validate(values)}
      onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
        setTimeout(async () => {
          const variables = getVariables(values, portfolioType, claimId);
          const refresh = window[Symbol.for("__refreshMakeSafe")];

          const res = await MakeSafeAddItemMutation(variables);
          setSubmitting(false);

          if (res.ok) {
            snackbar.toggleOpen({ message: res.message });
            if (res.success) {
              refresh();
            } else {
              snackbar.toggleOpen({ message: res.message });
            }
          }
        }, 400);
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => {
        return (
          <Dialog
            noActions
            noForm
            title="Add Make Safe Line Item"
            symbol="__makeSafeAddItem"
            paperProps={{
              style: { maxWidth: "50%" },
            }}
            content={
              <Form>
                <Grid fluid>
                  <AddItemForm
                    values={values}
                    isSubmitting={isSubmitting}
                    portfolioType={portfolioType}
                    setFieldValue={setFieldValue}
                    quotationType="MakeSafe"
                  />
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div>
                      <Button
                        color="primary"
                        label="Cancel"
                        onClick={handleClose}
                        disabled={isSubmitting}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        label="Add"
                        type="submit"
                        disabled={isSubmitting}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default MakeSafeAddItem;

const validate = (values) => {
  let errors = {};
  if (!values.locationId) errors.locationId = "Required!";
  if (!values.dimensions) errors.dimensions = "required!";
  if (values.dimensions && commonCharactersRegexp.test(values.dimensions))
    errors.dimensions = "Invalid character";
  if (values.dimensions.length > 200)
    errors.dimensions = "Maximum character limit is 200";

  if (!values.labour_costCentreId && !values.material_costCentreId) {
    errors.labour_costCentreId = "Select at least one";
    errors.material_costCentreId = "Select at least one";
  }
  if (values.labour_costCentreId) {
    if (!values.labour_unitId) errors.labour_unitId = "Required";
    if (!values.labour_qty) errors.labour_qty = "Required";
    if (!values.labour_rate && values.labour_rate !== 0)
      errors.labour_rate = "Required";
    if (!values.labour_costItemId) errors.labour_costItemId = "Required";

    if (String(values.labour_lineDescription).length > 1500)
      errors.labour_lineDescription = "Maximum character limit is 1500";
    if (String(values.labour_qty).length > 4 + 3)
      errors.labour_qty = "Maximum character limit is 4";
    if (
      (String(values.labour_rate).indexOf("-") !== -1 &&
        String(values.labour_rate).length > 7 + 4) ||
      (String(values.labour_rate).indexOf("-") === -1 &&
        String(values.labour_rate).length > 7 + 3)
    )
      errors.labour_rate = "Maximum character limit is 7";
  }
  if (values.material_costCentreId) {
    if (!values.material_unitId) errors.material_unitId = "Required";
    if (!values.material_qty) errors.material_qty = "Required";
    if (!values.material_rate && values.material_rate !== 0)
      errors.material_rate = "Required";
    if (!values.material_costItemId) errors.material_costItemId = "Required";

    if (String(values.material_lineDescription).length > 1500)
      errors.material_lineDescription = "Maximum character limit is 1500";
    if (String(values.material_qty).length > 4 + 3)
      errors.material_qty = "Maximum character limit is 4";
    if (
      (String(values.material_rate).indexOf("-") !== -1 &&
        String(values.material_rate).length > 7 + 4) ||
      (String(values.material_rate).indexOf("-") === -1 &&
        String(values.material_rate).length > 7 + 3)
    )
      errors.material_rate = "Maximum character limit is 7";
  }

  return errors;
};
