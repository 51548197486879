/**
 * @flow
 * @relayHash 1fff6e69e4159e32df198091e2919ebb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaymentReserveWhere = {|
  claimId?: ?string,
  feeCategoryId?: ?string,
  feeItemId?: ?string,
  invoiceId?: ?string,
|};
export type FeeInfoQueryVariables = {|
  where: PaymentReserveWhere
|};
export type FeeInfoQueryResponse = {|
  +paymentReserve: ?{|
    +id: string,
    +buildingReserve: number,
    +contentsReserve: number,
  |}
|};
export type FeeInfoQuery = {|
  variables: FeeInfoQueryVariables,
  response: FeeInfoQueryResponse,
|};
*/


/*
query FeeInfoQuery(
  $where: PaymentReserveWhere!
) {
  paymentReserve(where: $where) {
    id
    buildingReserve
    contentsReserve
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "PaymentReserveWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "paymentReserve",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "PaymentReserveWhere!"
      }
    ],
    "concreteType": "PaymentReserve",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "buildingReserve",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "contentsReserve",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeInfoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeInfoQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "FeeInfoQuery",
    "id": null,
    "text": "query FeeInfoQuery(\n  $where: PaymentReserveWhere!\n) {\n  paymentReserve(where: $where) {\n    id\n    buildingReserve\n    contentsReserve\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '328e249c14cdd30d302cda37d353416e';
module.exports = node;
