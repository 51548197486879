import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation UpdateSiteReportMutation($input: SiteReportInput!, $where: ENDataPortfolioKey!) {
    updateSiteReport(input: $input, where: $where) {
      success
      messages
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          resolve({ok: true, ...res})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}