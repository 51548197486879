import React, { Component } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { withStyles } from '@material-ui/core/styles'

import Grid from 'components/Mui/Grid'
import ClaimTypeIcon from 'components/Mui/ClaimTypeIcon/'

class JobNotesListItem extends Component {
  render() {
    const { classes, item } = this.props
    return (
      <Grid fluid spacing={0} className={classes.listItem}>
        <Grid item xs={2} className={classes.alignCenter}>
          <ClaimTypeIcon type={item.portfolioType}/>
          <span>{item.logDate}</span>
        </Grid>
        {[
          {col: 2, name: item.user && item.user.company.companyName, center: true, unmountOn: false/* !role.isAdmin */ },
          {col: 1, name: item.user && item.user.userName, center: true },
          {col: 1, name: item.private === 1 ? 'Private' : 'Public', center: true },
          {col: 6, name: item.message, center: false },
        ].map(({col, name, center, unmountOn}, key) => {
          const className = (name === '-' || center) ? classes.alignCenter : classes.alignLeft
          return !unmountOn && <Grid className={className} item key={key} xs={col}>{name ? name : '-'}</Grid>
        })}
      </Grid>
    )
  }
}

export default createFragmentContainer(
  withStyles(theme => ({
    listItem: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      '&:nth-child(even)': {
        background: theme.palette.grey[100]
      },
      '&:hover': {
        background: theme.palette.grey[300]
      },
      position: 'relative',
    },
    alignCenter: {
      ...theme.mixins.alignJustifyContainer(theme),
    },
    alignLeft: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  }))(JobNotesListItem),
  graphql`
    fragment JobNotesListItem_item on ClaimNote {
      portfolioType
      logDate
      private
      message
      user {
        userName
        company {
          companyName
        }
      } 
    }
  `
)