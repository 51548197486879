import React, { Component, createRef } from "react";
import { tryParse } from "common/utils";

class Form extends Component {
  form = createRef();
  render() {
    const { children } = this.props;
    return (
      <form
        ref={this.form}
        onChange={this.changeHandle}
        onSubmit={this.submitHandle}
      >
        {children}
      </form>
    );
  }

  // TODO OR NOT TODO
  changeHandle = (e) => {
    const { onChange } = this.props;
    if (e.target.hasAttribute("name")) {
      const data = this.getData(this.form.current);
      onChange && onChange(data);
    }
  };

  getData = (target) => {
    const formInputs = Array.from(target.querySelectorAll("[name]"));
    const data = formInputs
      .map((input) => {
        if (!(input.value || input.checked !== undefined)) return undefined;

        // if(input.name.split('-').length > 1) {
        //   console.log(input.name.split('-'))
        // }
        // console.log(input.value)
        if (input.getAttribute("customtype") === "money")
          return { [input.name]: parseInt(input.value.replace(",", "")) };

        switch (input.type) {
          case "text":
            return { [input.name]: tryParse(input.value) };
          case "date":
            return { [input.name]: input.value };
          case "checkbox":
            return { [input.name]: input.checked };

          default:
            return { [input.name]: input.value };
        }
      })
      .filter((x) => x !== null)
      .reduce((total, current) => ({ ...total, ...current }), {});

    return data;
  };

  submitHandle = (e) => {
    e.preventDefault();
    const data = this.getData(e.target);
    this.props.onSubmit && this.props.onSubmit(data);
  };
}

export default Form;

/*
  moment(input.value, 'YYYY-MM-DD').isValid() ?
            moment(input.value, 'YYYY-MM-DD').toISOString() : undefined
*/
