import React, { Component, Fragment } from "react";
import { graphql, QueryRenderer } from "react-relay";
import { SpinnerRect } from "components/Mui/Loader";
import environment from "common/relay";
import Filters from "pages/SupplierScorecard/Filters";
import Dashboard from "pages/SupplierScorecard/Dashboard";
import Comparison from "pages/SupplierScorecard/Comparison";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import GetScoreCardPdfMutation from "../SupplierScorecard/Filters/GetScoreCardPdfMutation";
import PrintIcon from "@material-ui/icons/Print";

import { withStyles } from "@material-ui/core/styles";

const SupplierScorecard = ({ classes }) => {
  const [where, setWhere] = React.useState({});
  const [isPdfLoading, setIsPdfLoading] = React.useState(false);
  const handlePdfPrint = async () => {
    setIsPdfLoading(true);
    const res = await GetScoreCardPdfMutation({ where: where });

    if (res.ok) {
      setIsPdfLoading(false);
      window.open(
        res.pdf,
        "Site Report Print Preview",
        "resizable, scrollbars, status"
      );
    }
  };

  const query = graphql`
    query SupplierScorecardQuery($where: ClaimScoreBoardFilter!) {
      claimScoreBoard(where: $where) {
        title
        insurerHeader
        insurerLogo
        scoreGroups {
          groupDescription
          orientation
          name
          icon
          items {
            id
            color
            ranking
            name
            unit
            value
          }
        }
        tables {
          name
          rows {
            id
            name
            fields {
              id
              color
              name
              ranking
              unit
              value
            }
          }
        }
      }
    }
  `;
  const filterVariable = (where) => {
    const arr = Object.assign({}, where);
    return Object.entries(arr)
      .map(([key, value]) => {
        if (Array.isArray(value) && value.length === 0) return [key, null];
        return [key, value];
      })
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  };
  return (
    <div>
      <Filters where={where} setWhere={setWhere} />
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{ where: filterVariable(where) }}
        render={({ error, props }) => {
          if (error) {
            return "Error!!";
          }

          if (!props) return <SpinnerRect />;
          const data = props.claimScoreBoard;
          if (!data) return <div>no data</div>;
          return (
            <div className={classes.root}>
              <Grid container xs={14} className={classes.headerWrapper}>
                <div className={classes.header}>
                  <div className={classes.headerLeft}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {data.insurerLogo && (
                        <img
                          className={classes.img}
                          src={data.insurerLogo}
                          alt="icon"
                        ></img>
                      )}
                    </div>
                  </div>
                  <div className={classes.headerRight}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className={classes.text}>{data.insurerHeader}</div>
                        <Button
                          style={{ border: 0, fontWeight: 600, color: "white" }}
                          disabled={isPdfLoading}
                          label="Print"
                          leadIcon={
                            !isPdfLoading ? (
                              <PrintIcon />
                            ) : (
                              <MuiCircularProgress color="inherit" size={24} />
                            )
                          }
                          onClick={handlePdfPrint}
                        />
                      </div>
                      <div>
                        <h1 className={classes.title}>{data.title}</h1>
                      </div>
                    </div>
                    <div className={classes.boxCut}></div>
                  </div>
                </div>
              </Grid>
              <Dashboard data={data.scoreGroups} where={where} />
              <Grid container xs={14}>
                <Comparison data={data.tables[0]} where={where} />
              </Grid>
            </div>
          );
        }}
      />
    </div>
  );
};

export default withStyles((Theme) => ({
  root: {
    width: "1000px",
    margin: "0 auto",
    backgroundColor: "white",
    padding: "10px",
  },
  img: {
    width: "98%",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.5)",
  },
  headerWrapper: {
    marginBottom: "20px",
  },
  header: {
    display: "flex",
  },
  headerLeft: {
    width: "10rem",
    height: "260px",
    border: "3px solid #003781",
    marginRight: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerRight: {
    display: "flex",
    position: "relative",
    backgroundColor: "#003781",
    width: "48rem",
    height: "260px",
  },
  title: {
    color: "white",
    fontSize: "45px",
    fontWeight: "normal",
    marginTop: "30px",
    marginLeft: "28px",
  },
  text: {
    color: "white",
    fontSize: "12px",
    marginTop: "25px",
    marginLeft: "30px",
  },
  boxCut: {
    width: "0",
    height: "0",
    borderLeft: "50px solid transparent",
    borderRight: "50px solid transparent",
    borderBottom: "50px solid white",
    position: "absolute",
    bottom: "-8px",
    right: "-33px",
    transform: "rotate(135deg)",
  },
}))(SupplierScorecard);
