import React from 'react'
import { moneyFormat } from 'common/utils'
import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'
import IconButton from 'components/Mui/IconButton'
import DeleteScopeItemMutation from '../ScopeActionBar/DeleteScopeItem/DeleteScopeItemMutation'
import NewUpdateScopeContentForm from '../ScopeActionBar/UpdateContentScopeItem/NewUpdateScopeContentForm'

const ScopeContentsGroupItem = ({ classes, item, isAction, portfolio, isOwnCompany, isContentsAction, meta, quote }) => {
  const snackbar = window[Symbol.for('__snackbar')]
  const refresh = window[Symbol.for('__refreshScope')]
  const [editMode, setEditMode] = React.useState(false)
  const deleteContentsScopeItem = async id => {
    const res = await DeleteScopeItemMutation({
      where: { id, portfolioType: portfolio }
    })

    if(res.ok) {
      snackbar.toggleOpen({ message: res.messages[0] })
      if(res.success) {
        refresh()
      }
    }
    else {
      snackbar.toggleOpen({ message: 'Cannot delete item, please try again' })
    }
  }

  return (
    <>
      {
        !editMode? (
          <Grid fluid spacing={0} className={classes.row}>
            {[
              // lodge date
              {   col: 1, text: item.logdate },
              // cost centre
              {   col: 2, text: item.costCentre.costCentreName },
              // description
              {col: true, text: `${item.costItem ? item.costItem.itemDescription : ''} ${item.lineDescription}` },
              // policy item limit
              {   col: 1, text: item.policyLimit },
              // direct supply
              {   col: 1, text: item.directsupply ? 'Yes' : 'No' },
              // purschase price
              {   col: 1, text: moneyFormat(item.purchasePrice), className: classes.truncate },
              // age
              {   col: 1, text: item.ageOfItem, className: classes.truncate },
              // proof of loss
              {   col: 1, text: item.proofOfLoss, className: classes.truncate },
              // qty
              {   col: 1, text: item.qty},
              // reserve price
              {   col: 1, text: moneyFormat(item.rate) },
              // subtotal
              // {   col: 1, text: moneyFormat(item.subtotal) },

              { col: 1, unMountOn: !isAction || item.requestedQuote === true, show: (quote && !quote.completed
                && (
                  isOwnCompany
                  || isContentsAction
                  || /*TOBE REMOVED*/ (meta.user.type.isAdmin && meta.claim.insurer.nonPanelSupplier) /*TOBE REMOVED*/
                )), text: (
                <>
                  <IconButton icon='edit' className={classes.actionBtn}
                    onClick={() =>
                      // editContentsScopeItem()
                      setEditMode(true)
                    }
                  />
                  <IconButton icon='delete' className={classes.actionBtn}
                    onClick={() => deleteContentsScopeItem(item.jobQuoteItemId)}
                  />
                </>
              )}
            ].filter(e => {
              if(e['show'] !== undefined){
                return e.show === true
              }
              return e
            }).map(({ col, text, unMountOn, className }, key) => {
              return (
                <Grid className={`${classes.alignCenter} ${className ? className : ''}`} item key={key} xs={col}>
                  {!unMountOn && text}
                </Grid>
              )
            })}
          </Grid>
        ) : (
          <NewUpdateScopeContentForm setEditMode={setEditMode} classes={classes} item={item} />
        )
      }

    </>
  )
}

export default withStyles(theme => ({
  truncate: {
    ...theme.mixins.truncate(theme),
    width: '100%',
    display: 'block !important',
  },
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: 'center',
    borderRadius: '8px',

    '&:nth-child(even)': {
      background: theme.palette.grey[100],
    },
    '&:hover': {
      background: theme.palette.grey[200],
    }
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit
  },
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
    padding: '4px',
    textAlign: 'center',
    flexWrap: 'wrap'
  }
}))(ScopeContentsGroupItem)
