/**
 * @flow
 * @relayHash f645953f568605fe6b778fcdaefaa762
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobVariationIndex = {|
  id?: ?$ReadOnlyArray<number>,
  claimId?: ?string,
  includeValidationItems?: ?boolean,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type VariationsQueryVariables = {|
  where: JobVariationIndex
|};
export type VariationsQueryResponse = {|
  +variations: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +variationId: number,
        +logdate: any,
        +variationTitle: string,
        +variationDescription: string,
        +variationReason: ?{|
          +reasonDescription: string
        |},
        +total: ?number,
        +variationStatus: ?string,
      |}
    |}>
  |}
|};
export type VariationsQuery = {|
  variables: VariationsQueryVariables,
  response: VariationsQueryResponse,
|};
*/


/*
query VariationsQuery(
  $where: JobVariationIndex!
) {
  variations: jobVariations(where: $where, first: 10000000) {
    edges {
      node {
        variationId
        logdate
        variationTitle
        variationDescription
        variationReason {
          reasonDescription
        }
        total
        variationStatus
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JobVariationIndex!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationId",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logdate",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationTitle",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationDescription",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "variationReason",
  "storageKey": null,
  "args": null,
  "concreteType": "JobVariationReason",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reasonDescription",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationStatus",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000000,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "JobVariationIndex!"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VariationsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "variations",
        "name": "__VariationsQuery_variations_connection",
        "storageKey": null,
        "args": null,
        "concreteType": "JobVariationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobVariation",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              },
              (v9/*: any*/)
            ]
          },
          (v10/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VariationsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "variations",
        "name": "jobVariations",
        "storageKey": null,
        "args": (v11/*: any*/),
        "concreteType": "JobVariationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobVariation",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ]
              },
              (v9/*: any*/)
            ]
          },
          (v10/*: any*/)
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": "variations",
        "name": "jobVariations",
        "args": (v11/*: any*/),
        "handle": "connection",
        "key": "VariationsQuery_variations",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "VariationsQuery",
    "id": null,
    "text": "query VariationsQuery(\n  $where: JobVariationIndex!\n) {\n  variations: jobVariations(where: $where, first: 10000000) {\n    edges {\n      node {\n        variationId\n        logdate\n        variationTitle\n        variationDescription\n        variationReason {\n          reasonDescription\n        }\n        total\n        variationStatus\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "variations"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a0f6d0e0c77239323e293e07e3d4dc5';
module.exports = node;
