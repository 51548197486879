import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const SubmitVariationValidationMutation = graphql`
  mutation SubmitVariationValidationMutation(
    $input: JobVariationValidationSubmitInput!
    $where: ENDataPortfolioKey!
  ) {
    jobVariationValidationSubmit(where: $where, input: $input) {
      success
      messages
    }
  }
`;

export default SubmitVariationValidationMutation;
