/**
 * @flow
 * @relayHash f924104dd822e6dda9776ec348c9bad3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppointmentInput = {|
  appointmentDate: any,
  note?: ?string,
|};
export type ENDataEntityKey = {|
  id: string
|};
export type QuoteAppointmentMadeActionMutationVariables = {|
  input: AppointmentInput,
  where: ENDataEntityKey,
|};
export type QuoteAppointmentMadeActionMutationResponse = {|
  +claimMakeAppointment: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +progress: ?{|
        +appointmentBooked: boolean
      |},
      +claimStatus: ?{|
        +statusId: number
      |},
    |},
  |}
|};
export type QuoteAppointmentMadeActionMutation = {|
  variables: QuoteAppointmentMadeActionMutationVariables,
  response: QuoteAppointmentMadeActionMutationResponse,
|};
*/


/*
mutation QuoteAppointmentMadeActionMutation(
  $input: AppointmentInput!
  $where: ENDataEntityKey!
) {
  claimMakeAppointment(input: $input, where: $where) {
    success
    messages
    result {
      progress {
        appointmentBooked
      }
      claimStatus {
        statusId
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AppointmentInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AppointmentInput!"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataEntityKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "progress",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimProgress",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "appointmentBooked",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "QuoteAppointmentMadeActionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimMakeAppointment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "claimStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimStatus",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "QuoteAppointmentMadeActionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimMakeAppointment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "claimStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimStatus",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "QuoteAppointmentMadeActionMutation",
    "id": null,
    "text": "mutation QuoteAppointmentMadeActionMutation(\n  $input: AppointmentInput!\n  $where: ENDataEntityKey!\n) {\n  claimMakeAppointment(input: $input, where: $where) {\n    success\n    messages\n    result {\n      progress {\n        appointmentBooked\n      }\n      claimStatus {\n        statusId\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a7a95ed0dcf8bc621e0ac7f809d1022d';
module.exports = node;
