/**
 * @flow
 * @relayHash 3221d70b72587830c45891fa759544ca
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RoomListRoot_room$ref = any;
export type RoomWhereInput = {|
  keyword?: ?string
|};
export type RoomListRootPaginationQueryVariables = {|
  count: number,
  cursor?: ?string,
  where: RoomWhereInput,
|};
export type RoomListRootPaginationQueryResponse = {|
  +account: ?{|
    +$fragmentRefs: RoomListRoot_room$ref
  |}
|};
export type RoomListRootPaginationQuery = {|
  variables: RoomListRootPaginationQueryVariables,
  response: RoomListRootPaginationQueryResponse,
|};
*/


/*
query RoomListRootPaginationQuery(
  $count: Int!
  $cursor: String
  $where: RoomWhereInput!
) {
  account {
    ...RoomListRoot_room_mjR8k
    id
  }
}

fragment RoomListRoot_room_mjR8k on Account {
  rooms(first: $count, after: $cursor, where: $where) {
    edges {
      node {
        id
        createdAt
        case {
          id
          referenceNumber
          name
          address
        }
        isDeleted
        callSessions {
          userType
          contact {
            email
            phone
            id
          }
          id
        }
        videos(isDeleted: false) {
          id
          url
          name
        }
        photos(isDeleted: false) {
          id
          url
          name
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "RoomWhereInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "RoomWhereInput"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "isDeleted",
    "value": false,
    "type": "Boolean"
  }
],
v5 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RoomListRootPaginationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "account",
        "storageKey": null,
        "args": null,
        "concreteType": "Account",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RoomListRoot_room",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "where",
                "variableName": "where",
                "type": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RoomListRootPaginationQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "account",
        "storageKey": null,
        "args": null,
        "concreteType": "Account",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "rooms",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "RoomConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "RoomEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Room",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "case",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Case",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "referenceNumber",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "address",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isDeleted",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "callSessions",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CallSession",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "userType",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "contact",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CallSessionContact",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "email",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "phone",
                                "args": null,
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "videos",
                        "storageKey": "videos(isDeleted:false)",
                        "args": (v4/*: any*/),
                        "concreteType": "File",
                        "plural": true,
                        "selections": (v5/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "photos",
                        "storageKey": "photos(isDeleted:false)",
                        "args": (v4/*: any*/),
                        "concreteType": "File",
                        "plural": true,
                        "selections": (v5/*: any*/)
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "rooms",
            "args": (v1/*: any*/),
            "handle": "connection",
            "key": "RoomListRoot_rooms",
            "filters": [
              "where"
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "RoomListRootPaginationQuery",
    "id": null,
    "text": "query RoomListRootPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $where: RoomWhereInput!\n) {\n  account {\n    ...RoomListRoot_room_mjR8k\n    id\n  }\n}\n\nfragment RoomListRoot_room_mjR8k on Account {\n  rooms(first: $count, after: $cursor, where: $where) {\n    edges {\n      node {\n        id\n        createdAt\n        case {\n          id\n          referenceNumber\n          name\n          address\n        }\n        isDeleted\n        callSessions {\n          userType\n          contact {\n            email\n            phone\n            id\n          }\n          id\n        }\n        videos(isDeleted: false) {\n          id\n          url\n          name\n        }\n        photos(isDeleted: false) {\n          id\n          url\n          name\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0812bb9962e5b6b8ca356f9755c327c4';
module.exports = node;
