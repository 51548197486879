import React from "react";
import Grid from "components/Mui/Grid";

import Input from "components/Formik/Input";
import { SingleReactSelect } from "components/Formik/ReactSelect";
import FloatInput from "components/Formik/FloatInput";
import MoneyInput from "components/Formik/MoneyInput";

import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";
import useLineItemIndustryRate from "hooks/useLineItemIndustryRate";

const AddItemGroupForm = ({
  type,
  values,
  centreOptions,
  itemOptions,
  portfolioType,
  setFieldValue,
  industryRatesAutoFill,
}) => {
  const unitOptions =
    values[`${type}costCentreId`] && values[`${type}costItemId`]
      ? (() => {
          const selectedItem = itemOptions.filter(
            (x) => x.value === values[`${type}costItemId`]
          );
          if (selectedItem.length !== 0) return selectedItem[0].units;
          return [];
        })()
      : [];

  const meta = useMetaContext();
  useLineItemIndustryRate(
    {
      portfolioType: portfolioType,
      unitId: values[`${type}unitId`],
      descriptionId: values[`${type}costItemId`],
      isIncluded: true,
      type: `${type}`,
      values,
      setFieldValue,
      industryRatesAutoFill,
    },
    [portfolioType, values[`${type}unitId`], values[`${type}costItemId`]]
  );

  return (
    <Grid fluid spacing={8}>
      {[
        {
          component: SingleReactSelect,
          label: "Cost Centre",
          name: `${type}costCentreId`,
          select: true,
          required: true,
          isClearable: true,
          options: centreOptions,
          rootField: true,
        },
        {
          component: SingleReactSelect,
          label: "Description",
          name: `${type}costItemId`,
          required: true,
          select: true,
          options: itemOptions,
          disabled: !values[`${type}costCentreId`],
        },
        {
          component: Input,
          rows: 3,
          rowsMax: 3,
          label: "More Details",
          name: `${type}lineDescription`,
        },
        {
          col: 6,
          component: Input,
          label: "Unit of Measure",
          name: `${type}unitId`,
          required: true,
          select: true,
          options: unitOptions,
          disabled: !values[`${type}costItemId`],
        },
        {
          col: 6,
          component: Input,
          label: "Margin",
          name: `${type}marginRate`,
          required: true,
          disabled: meta.claim.insurer.isMarginUpdate ? false : true,
        },
        {
          col: 6,
          component: FloatInput,
          label: "Quantity",
          name: `${type}qty`,
          required: true,
        },
        {
          col: 6,
          component: MoneyInput,
          label: "Rate Ex GST",
          name: `${type}rate`,
          required: true,
          allowNegative: true,
        },
      ].map(
        (
          { col = 12, unMountOn, component: C, disabled, rootField, ...props },
          index
        ) => (
          <Grid item xs={col} key={index}>
            {!unMountOn && (
              <C
                {...props}
                fullWidth
                variant="outlined"
                disabled={
                  disabled || (!values[`${type}costCentreId`] && !rootField)
                }
              />
            )}
          </Grid>
        )
      )}
    </Grid>
  );
};

export default React.memo(AddItemGroupForm);
