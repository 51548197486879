import React from 'react'
import { QueryRenderer, graphql } from 'react-relay'
import environment from 'common/relay'

import MakeSafePresentation from './MakeSafePresentation'
import MakeSafeAddItem from './MakeSafeActions/MakeSafeAddItem/'
import MakeSafeCompletedMutation from './MakeSafeActions/MakeSafeCompletedMutation'
import OnsiteMakeSafeCompletedMutation from './MakeSafeActions/OnsiteMakeSafeCompletedMutation'
import MakeSafeInitialCallMutation from './MakeSafeActions/MakeSafeInitialCallMutation'
import MakeSafeResetMutation from './MakeSafeActions/MakeSafeResetMutation'

import usePortfolio from 'hooks/usePortfolio'
import ClaimMetaContext from 'pages/Claim/ClaimDetails/ClaimMetaContext'
import MakeSafeListContainer from './MakeSafeList/MakeSafeListContainer'

const MakeSafeContainer = () => {
  // const addItemDlRef = React.createRef()
  const snackbar = window[Symbol.for('__snackbar')]
  const meta = React.useContext(ClaimMetaContext)

  /**
   * force update renderer
   */
  const [ update, setUpdate ] = React.useState(false)
  React.useEffect(() => {
    window[Symbol.for('__refreshMakeSafe')] = () => setUpdate(!update)

    return () => {
      delete window[Symbol.for('__refreshMakeSafe')]
    }
  }, [update])

  /**
   * routing and portfolio route
   */
  const [ portfolio, changePortfolio ] = usePortfolio(['building', 'restoration'])

  /**
   * METHODS
   */
  const handleNextStep = () => {
    window[Symbol.for('__nextStepAction')].handleOpen()
  }
  const handleAddNewItem = () => {
    window[Symbol.for('__makeSafeAddItem')].handleOpen()
  }
  const handleInitialCall = async id => {
    const confirm = await snackbar.toggleOpen({
      message: 'Have you made the initial call?',
      firstButton: 'Yes',
      secondButton: 'No',
      type: 'confirm',
    })
    if(confirm.ok) {
      const res = await MakeSafeInitialCallMutation({
        where: {
          id: meta.claim.claimId,
          portfolioType: portfolio
        }
      }, id)
      if(res.ok) {
        snackbar.toggleOpen({
          message: res.messages[0]
        })
        setUpdate(!update)
      }
    }
  }
  const handleAppointmentMade = () => {
    window[Symbol.for('__makeSafeAppointmentMade')] && window[Symbol.for('__makeSafeAppointmentMade')].handleOpen()
  }
  const handleOnsiteComplete = async id => {
    // console.log('asdasdas')
    const confirm = await snackbar.toggleOpen({
      message: 'Do you want to complete onsite make safe?',
      firstButton: 'Yes',
      secondButton: 'No',
      type: 'confirm',
    })
    if(confirm.ok) {
      const res = await OnsiteMakeSafeCompletedMutation({
        where: {
          id: meta.claim.claimId,
          portfolioType: portfolio
        }
      }, id)
      if(res.ok) {
        snackbar.toggleOpen({
          message: res.messages[0]
        })
        setUpdate(!update)
      }
    }
  }
  const handleResetMakeSafe = async () => {
    const confirm = await snackbar.toggleOpen({
      message: 'Do you want to reset make safe?',
      firstButton: 'Yes',
      secondButton: 'No',
      type: 'confirm',
    })
    if(confirm.ok) {
      const res = await MakeSafeResetMutation({
        where: {
          id: meta.claim.claimId,
          portfolioType: portfolio
        }
      })
      if(res.ok) {
        snackbar.toggleOpen({
          message: res.messages[0]
        })
      }
    }
  }
  const handleMakeSafeCompleted = async () => {
    const confirm = await snackbar.toggleOpen({
      message: 'Do you want to complete make safe?',
      firstButton: 'Yes',
      secondButton: 'No',
      type: 'confirm',
    })
    if(confirm.ok) {
      const res = await MakeSafeCompletedMutation({
        where: {
          id: meta.claim.claimId,
          portfolioType: portfolio
        }
      })
      if(res.ok) {
        // console.log(res)
        snackbar.toggleOpen({
          message: res.messages[0]
        })
      }
    }
  }

  if(!portfolio) return 'Loading...'

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        first: 10,
        after: null,
        where: {
          claimId: meta.claim.claimId,
          portfolios: portfolio
        },
        claimWhere: {
          id: meta.claim.claimId
        },
        locationsWhere: {
          portfolioType: portfolio
        },
        centresWhere: {
          portfolioType: portfolio,
          quotationType: "MakeSafe"
        },
        jobScopeWhere: {
          insurerId: meta.claim.insurer.companyId,
          quotationType: 'MakeSafe',
          portfolioType: portfolio,
          forManager: false
        },
        update: update,
      }}
      render={({ errors, props }) => {
        if(!props) return null

        return (
          <>
            <MakeSafePresentation
              meta={meta}
              makeSafes={props}
              claim={props}
              portfolios={portfolio}
              actions={{
                changePortfolio: changePortfolio,
                handleNextStep: handleNextStep,
                handleAddNewItem: handleAddNewItem,
                handleInitialCall: id => handleInitialCall(id),
                handleAppointmentMade: handleAppointmentMade,
                handleOnsiteComplete: id => handleOnsiteComplete(id),
                handleResetMakeSafe: handleResetMakeSafe,
                handleMakeSafeCompleted: handleMakeSafeCompleted,
              }}
            />
            <MakeSafeAddItem
              portfolioType={portfolio}
              claimId={meta.claim.claimId}
              data={props}
            />
          </>
        )
      }}
    />
  )
}

export default MakeSafeContainer

const query = graphql`
  query MakeSafeContainerQuery(
    $first: Int!,
    $after: String,
    $where: JobMakeSafeIndex!,
    $claimWhere: ENDataEntityKey!
    $locationsWhere: QuoteCostLocationIndex!
    $centresWhere: QuoteCostCentreIndex!
    $jobScopeWhere: JobScopeTypeWhere!
  ) {
    jobScopeTypes(where: $jobScopeWhere) {
      id
    }
    jobMakeSafes(first: $first, after: $after, where: $where) {
      edges {
        node {
          makeSafeId
          lineItems {
            id
          }
          scopeTypes{
            id
          }
        }
      }
    }

    ...MakeSafePresentation_makeSafes
    ...MakeSafePresentation_claim
    ...MakeSafeAddNewItemContainer
  }
`

/*

            <Dialog noForm noActions
              innerRef={addItemDlRef}
              title='Add Make Safe Line Item'
              paperProps={{
                style: { maxWidth: '50%' }
              }}
              content={
                <MakeSafeAddNewItemContainer
                  data={props}
                  claimId={meta.claim.claimId}
                  portfolios={portfolio}
                  dlRef={addItemDlRef}
                />
              }
            />
 */
