import React, { Component } from 'react'

import { withStyles } from '@material-ui/core'
import { Bouncer } from 'components/Mui/Loader'

class Landing extends Component {
  componentWillMount = () => {
    this.props.history.push('/user/login')
    // if(localStorage.TOKEN) this.props.history.push(localStorage.ROOT_ROUTE)
    // else this.props.history.push('/user/login')
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Bouncer />
      </div>
    )
  }
}

export default withStyles(theme => ({
  root: {
    position: 'absolute',
    zIndex: 9999,
    top: 0, right: 0, bottom: 0, left: 0,
    background: 'rgba(0, 0, 0, 0.6)',
  }
}))(Landing)