import React, { useRef } from "react";

import Grid from "components/Mui/Grid";
import MultipleSelect from "components/MultipleSelect";
import IconButton from "components/Mui/IconButton";
import Button from "components/Mui/Button";
import Input from "components/Mui/Input";
import Icon from "@material-ui/core/Icon";
import withStyles from "@material-ui/core/styles/withStyles";
import Collapse from "@material-ui/core/Collapse";
import DateInput from "components/Mui/DateInput";
import moment from "moment";

import WaterFallFilter from "./WaterfallFilter";

const Filters = ({
  classes,
  filters,
  isDateSearch,
  isWaterFallFilter,

  changeKeyWords,
  changeDate,
  refreshFilterField,

  filterWhere,
  filterChange,
}) => {
  const [filterMore, setFilterMore] = React.useState(false);

  const [isSubView, setIsSubView] = React.useState(false);
  const handleSubviewToggle = () => {
    filterChange({
      claimStatusStageIds: undefined,
    });
    setIsSubView((pre) => !pre);
  };

  const renderMultiFilter = (filter, key) => {
    return (
      <Grid item xs={2} key={key}>
        {filter.type === "ComboBox_multiple" && (
          <MultipleSelect
            id={filter.name}
            filterPage="ClaimListFilter"
            filtered
            outline
            refreshFilterField={refreshFilterField}
            leadIcon={filter.icon}
            label={filter.label}
            options={filter.options}
          />
        )}
        {filter.type === "Search" && <SearchField onSubmit={changeKeyWords} />}
        {filter.type === "Date" && (
          <DateField display={isDateSearch} onSubmit={changeDate} />
        )}
      </Grid>
    );
  };
  const renderFilters = (filters) => filters.map(renderMultiFilter);
  const firstRowFilters = 6;

  return (
    <>
      <Grid fluid>
        <Grid item xs>
          {!isSubView ? (
            <>
              <Grid fluid>
                {renderFilters(filters.slice(0, firstRowFilters))}
              </Grid>
              <Collapse in={filterMore} timeout="auto" unmountOnExit>
                <Grid fluid>
                  {renderFilters(filters.slice(firstRowFilters))}
                </Grid>
              </Collapse>
            </>
          ) : (
            <Grid fluid>
              <WaterFallFilter
                filterWhere={filterWhere}
                filterChange={filterChange}
              />
            </Grid>
          )}
        </Grid>
        <Grid item className={classes.actionArea} container direction="column">
          <Grid item style={{ height: 40 }}>
            {isWaterFallFilter && (
              <IconButton
                className={classes.actionButton}
                icon={isSubView ? "chevron_left" : "chevron_right"}
                onClick={handleSubviewToggle}
              />
            )}
          </Grid>
          <Grid item style={{ height: 40 }}>
            {!isSubView && (
              <IconButton
                className={classes.actionButton}
                icon={filterMore ? "expand_less" : "expand_more"}
                onClick={() => setFilterMore((pre) => !pre)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default withStyles({
  actionArea: {
    position: "relative",
    width: 50,
    margin: 0,
  },
  actionButton: {
    margin: 0,
    padding: "8px",
  },
})(Filters);

const SearchField = ({ onSubmit }) => {
  const keyWordsInput = useRef();
  const [searching, setSearching] = React.useState(false);
  const handleKeywordsChange = () => {
    const value = keyWordsInput.current.value;
    setSearching(true);

    onSubmit(value);
    setTimeout(() => setSearching(false), 1000);
  };

  return (
    <Input
      inputRef={keyWordsInput}
      label="Search"
      //onChange={this.keyWordsChange}
      inputProps={{ maxLength: 255 }}
      leadDom={<Icon>search</Icon>}
      trailDom={
        <Button
          variant="contained"
          color="primary"
          disabled={searching}
          label="Go"
          type="submit"
          onClick={handleKeywordsChange}
        />
      }
    />
  );
};

const DateField = ({ onSubmit, display }) => {
  const [date, setDate] = React.useState(null);

  if (!display) return null;

  return (
    <DateInput
      label="Incident Date"
      value={date}
      onChange={(date) => {
        setDate(date);
        onSubmit(date);
      }}
      maxDate={moment()}
    />
  );
};
