import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'

const LineItemListHeader = ({ classes, cols }) => {
  return (
    <Grid fluid className={classes.headerContainer}>
      {cols.filter(e => {
        if(e['show'] !== undefined){
          return e.show === true
        }
        return e
      }).map(({ col, name }, index) => {
        return (
          <Grid item key={index} xs={col}
            className={classes.alignCenter}
          >
            {name}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default withStyles(theme => ({
  headerContainer: {
    '& > div': {
      borderRight: '1px solid white',
    },
    '& > div:last-child': {
      borderRight: 0,
    }
  },
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
    textAlign: 'center'
  }
}))(LineItemListHeader)
