import React from "react";

import Dialog from "components/Mui/Dialog";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";

import { Formik, Form } from "formik";
import Input from "components/Formik/Input";

import CompleteScopeMutation from "./CompleteScopeMutation";

const CompleteScopeAction = ({ symbol, claimId, id, portfolioType }) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const handleClose = () => {
    const dlActions = window[Symbol.for(symbol)];
    if (dlActions) dlActions.handleClose();
  };

  return (
    <Dialog
      noForm
      noActions
      symbol={symbol}
      title="Complete Scope"
      content={
        <Formik
          initialValues={{
            startAfterContractReceived: "",
            estimatedTimeForRepairs: "",
          }}
          validate={(values) => {
            let errors = {};
            if (!values.startAfterContractReceived)
              errors.startAfterContractReceived = "required!";
            if (!values.estimatedTimeForRepairs)
              errors.estimatedTimeForRepairs = "required!";

            return errors;
          }}
          onSubmit={async (input, actions) => {
            const variables = {
              input: portfolioType !== "Contents" ? input : undefined,
              where: {
                claimId,
                id,
                portfolioType,
              },
            };

            const res = await CompleteScopeMutation(variables);
            if (res.ok) {
              snackbar.toggleOpen({ message: res.messages[0] });
              actions.setSubmitting(false);

              if (res.success) {
                handleClose();
                if(window[Symbol.for('__refreshClaimDetails')]) {
                  window[Symbol.for('__refreshClaimDetails')]()
                }
              }
            } else {
              actions.setSubmitting(false);
              snackbar.toggleOpen({
                message: "Can not complete scope, please try again",
              });
            }
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <Grid
                fluid
                spacing={8}
                justify="center"
                style={{ height: "100%" }}
              >
                <Grid item xs={6}>
                  <Input
                    select
                    required
                    name="startAfterContractReceived"
                    label="Start after receipt of contract"
                    options={timeRangeOptions}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    select
                    required
                    name="estimatedTimeForRepairs"
                    label="Estimated time for repairs"
                    options={timeRangeOptions}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div>
                    <Button
                      label="Close"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={handleClose}
                    />
                    <Button
                      label="Complete"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                    />
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      }
    />
  );
};

export default CompleteScopeAction;

const timeRangeOptions = [
  { label: "1 Week", value: "1 Week" },
  { label: "2 Weeks", value: "2 Week" },
  { label: "3 Weeks", value: "3 Week" },
  { label: "4 Weeks", value: "4 Week" },
  { label: "1 - 2 Months", value: "1 - 2 Months" },
  { label: "2 - 3 Months", value: "2 - 3 Months" },
  { label: "Above 3 Months", value: "Above 3 Months" },
];
