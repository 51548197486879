/**
 * @flow
 * @relayHash 1a7ab1a2cba3e6198600791c3f94af1d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JobValidationStatus = "ValidationAdjusted" | "ValidationCompleted" | "ValidationDecline" | "ValidationInProgress" | "ValidationNoAdjust" | "ValidationRequired" | "ValidationResetJob" | "ValidationUnableToValidate" | "ValidationUnsureDecline" | "ValidationWithdraw" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobValidationStartInput = {|
  reasonId?: ?string,
  message?: ?string,
  validationStatus: JobValidationStatus,
|};
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type StartVariationValidationMutationVariables = {|
  input: JobValidationStartInput,
  where: ENDataPortfolioKey,
|};
export type StartVariationValidationMutationResponse = {|
  +jobVariationValidationStart: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +actions: ?{|
        +completeValidation: {|
          +isDisabled: boolean,
          +isDisplay: boolean,
          +label: string,
        |},
        +resetValidation: {|
          +isDisabled: boolean,
          +isDisplay: boolean,
          +label: string,
        |},
        +submitValidation: {|
          +isDisabled: boolean,
          +isDisplay: boolean,
          +label: string,
        |},
        +viewValidation: {|
          +isDisabled: boolean,
          +isDisplay: boolean,
          +label: string,
        |},
        +startValidation: {|
          +isDisabled: boolean,
          +isDisplay: boolean,
          +label: string,
        |},
      |},
      +id: string,
      +variationId: number,
    |},
  |}
|};
export type StartVariationValidationMutation = {|
  variables: StartVariationValidationMutationVariables,
  response: StartVariationValidationMutationResponse,
|};
*/


/*
mutation StartVariationValidationMutation(
  $input: JobValidationStartInput!
  $where: ENDataPortfolioKey!
) {
  jobVariationValidationStart(where: $where, input: $input) {
    success
    messages
    result {
      actions {
        completeValidation {
          isDisabled
          isDisplay
          label
          id
        }
        resetValidation {
          isDisabled
          isDisplay
          label
          id
        }
        submitValidation {
          isDisabled
          isDisplay
          label
          id
        }
        viewValidation {
          isDisabled
          isDisplay
          label
          id
        }
        startValidation {
          isDisabled
          isDisplay
          label
          id
        }
      }
      id
      variationId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobValidationStartInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "JobValidationStartInput!"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisabled",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationId",
  "args": null,
  "storageKey": null
},
v10 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v8/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StartVariationValidationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobVariationValidationStart",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobVariationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariation",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "JobVariationAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "completeValidation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v7/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "resetValidation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v7/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "submitValidation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v7/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewValidation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v7/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "startValidation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v7/*: any*/)
                  }
                ]
              },
              (v8/*: any*/),
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StartVariationValidationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobVariationValidationStart",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobVariationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariation",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "JobVariationAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "completeValidation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "resetValidation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "submitValidation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewValidation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "startValidation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  }
                ]
              },
              (v8/*: any*/),
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "StartVariationValidationMutation",
    "id": null,
    "text": "mutation StartVariationValidationMutation(\n  $input: JobValidationStartInput!\n  $where: ENDataPortfolioKey!\n) {\n  jobVariationValidationStart(where: $where, input: $input) {\n    success\n    messages\n    result {\n      actions {\n        completeValidation {\n          isDisabled\n          isDisplay\n          label\n          id\n        }\n        resetValidation {\n          isDisabled\n          isDisplay\n          label\n          id\n        }\n        submitValidation {\n          isDisabled\n          isDisplay\n          label\n          id\n        }\n        viewValidation {\n          isDisabled\n          isDisplay\n          label\n          id\n        }\n        startValidation {\n          isDisabled\n          isDisplay\n          label\n          id\n        }\n      }\n      id\n      variationId\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '92152fdead6678143d513ffa0af41b98';
module.exports = node;
