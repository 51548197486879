import React, { Component } from 'react'
import { QueryRenderer, graphql } from 'react-relay'
import environment from 'common/relay'
import Dialog from 'components/Mui/Dialog'

import AddVariationPresentation from './AddVariationPresentation'

const query = graphql`
  query AddVariationQuery {
    reasons: jobVariationReasons {
      label: reasonDescription
      value: variationReasonId
    }
  }
`

class AddVariation extends Component {
  render() {
    const { dlRef } = this.props

    return (
      <Dialog noForm noActions
        innerRef={dlRef}
        title='Add Variation'
        paperProps={{
          style: { maxWidth: '50%' }  
        }}
        content={(
          <QueryRenderer
            environment={environment}
            query={query}
            render={({ errors, props }) => {
              if(!props) return null

              return <AddVariationPresentation
                {...props}
                {...this.props}
              />
            }}
          />    
        )}
      />
    )
  }
}

export default AddVariation