import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import Toolbar from '@material-ui/core/Toolbar'
import ToolbarButton from 'components/MuiExtended/ToolbarButton'

const VideoCallActionBar = ({ actions, classes }) => {
  return (
    <Toolbar className={classes.actionBar}>
      {actions.map(action => (
        <ToolbarButton
          key={action.name}
          {...action}
        />
      ))}
    </Toolbar>
  )
}

export default withStyles(theme => ({
  actionBar: {
    position: 'absolute',
    bottom: '1vmax',
    zIndex: 1500,
    width: '100%',
    left: 0,
    display: 'flex',
    justifyContent: 'center',
  }
}))(VideoCallActionBar)