import React from "react";
import { moneyFormat } from "common/utils";
import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import IconButton from "components/Mui/IconButton";
import TextField from "@material-ui/core/TextField";
import { MoneyFormatInput } from "components/Formik/MoneyInput";
import DiscountScopeUpdateActionMutation from "../ScopeActionBar/DiscountScopeUpdateAction/DiscountScopeUpdateActionMutation";
import MuiCircularProgress from "@material-ui/core/CircularProgress";

const ScopeGroupItem = ({
  classes,
  tableItem,
  portfolio,
  quote,
  isValidationAction,
}) => {
  const [editMode, setEditMode] = React.useState(false);
  const [discount, setDiscount] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);
  const snackbar = window[Symbol.for("__snackbar")];

  const edit = async () => {
    setDisabled(true);
    const res = await DiscountScopeUpdateActionMutation({
      input: {
        jobQuoteId: quote.jobQuoteId,
        portfolioType: portfolio,
        scopeTypeId: tableItem.scopeType.id,
        discount: Number(`-${discount}`),
      },
    });

    if (res.ok) {
      snackbar.toggleOpen({ message: res.messages[0] });
      setEditMode(false);
      if (res.success) {
        snackbar.toggleOpen({ message: res.messages[0] });
      }
    } else {
      snackbar.toggleOpen({
        message: "Error, please try again",
      });
      setEditMode(false);
    }
  };

  return (
    <>
      <Grid fluid spacing={0} className={classes.row}>
        {[
          { col: true },
          { col: 2, bold: true, text: `${tableItem.fields.discount.label}:` },
          { col: 1, number: true, text: `${moneyFormat(tableItem.discount)}` },
          {
            col: 1,
            unMountOn: !isValidationAction
              ? !tableItem.fields.action.isDisplay
              : !quote.actions.completeValidation.isDisplay,
            text: (
              <>
                {!editMode && (
                  <IconButton
                    icon="edit"
                    className={classes.actionBtn}
                    disabled={tableItem.fields.discount.isDisabled}
                    onClick={() => {
                      setDisabled(false);
                      setEditMode(true);
                    }}
                  />
                )}
                {editMode && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {!disabled && <p> - </p>}
                    {!disabled && (
                      <TextField
                        style={{ width: "70px" }}
                        onChange={(e) => setDiscount(e)}
                        disabled={disabled}
                        InputProps={{
                          inputComponent: MoneyFormatInput,
                          inputProps: {
                            autoComplete: "teriTeriMasuMasuKawaii",
                          },
                        }}
                      />
                    )}
                    {disabled && <MuiCircularProgress size="20px" />}
                    <IconButton
                      disabled={disabled}
                      style={{
                        width: "10px",
                        height: "10px",
                        marginLeft: "10px",
                      }}
                      icon="check"
                      onClick={() => edit()}
                    />
                    <IconButton
                      disabled={disabled}
                      style={{ width: "10px", height: "10px", margin: 0 }}
                      icon="close"
                      onClick={() => setEditMode(false)}
                    />
                  </div>
                )}
              </>
            ),
          },
        ].map(({ col, text, unMountOn, className, bold, number }, key) => {
          if (unMountOn) return null;
          return (
            <Grid
              className={`${className} ${classes.alignCenter}`}
              item
              key={key}
              xs={col}
            >
              <div style={{ fontWeight: bold ? "bold" : undefined }}>
                {text}
              </div>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default withStyles((theme) => ({
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: "center",
    borderRadius: "8px",
    backgroundColor: "#f5f5f5",
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit,
  },
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
    padding: "4px",
    textAlign: "center",
    flexWrap: "wrap",
  },
  sir: {
    color: theme.palette.grey[800],
    fontStyle: "italic",
  },
}))(ScopeGroupItem);
