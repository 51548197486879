/**
 * @flow
 * @relayHash e821c0a6519da8f0845e5ae07f19eccd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessRole = "Adjustor" | "Administrator" | "Agent" | "Builder" | "ContentSupplier" | "Customer" | "Estimator" | "Insurance" | "InternalAB" | "LossAdjuster" | "MultiTenderAssessor" | "Restorer" | "Specialist" | "SystemAdmin" | "UNDEFINED" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimCommunicationCreate = {|
  claimId: string,
  message: string,
  receiverCompanyId: number,
  private: boolean,
  receiverRole: AccessRole,
|};
export type CommunicationActionMutationVariables = {|
  input: ClaimCommunicationCreate
|};
export type CommunicationActionMutationResponse = {|
  +createClaimCommunication: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +id: string,
      +acknowledged: boolean,
      +claimId: ?string,
      +communicationId: number,
      +message: ?string,
      +acknowledgeUserName: ?string,
      +acknowledgeDate: ?any,
      +portfolioType: ?PortfolioType,
      +recieverCompanyId: ?number,
      +recieverCompanyName: ?string,
      +sendDate: any,
      +senderCompanyId: ?number,
      +senderCompanyName: ?string,
      +senderId: ?number,
      +senderName: ?string,
      +private: boolean,
      +actions: {|
        +acknowledge: {|
          +isDisplay: boolean
        |}
      |},
    |},
  |}
|};
export type CommunicationActionMutation = {|
  variables: CommunicationActionMutationVariables,
  response: CommunicationActionMutationResponse,
|};
*/


/*
mutation CommunicationActionMutation(
  $input: ClaimCommunicationCreate!
) {
  createClaimCommunication(input: $input) {
    messages
    success
    result {
      id
      acknowledged
      claimId
      communicationId
      message
      acknowledgeUserName
      acknowledgeDate
      portfolioType
      recieverCompanyId
      recieverCompanyName
      sendDate
      senderCompanyId
      senderCompanyName
      senderId
      senderName
      private
      actions {
        acknowledge {
          isDisplay
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimCommunicationCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ClaimCommunicationCreate!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "acknowledged",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "claimId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "communicationId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "message",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "acknowledgeUserName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "acknowledgeDate",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "portfolioType",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "recieverCompanyId",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "recieverCompanyName",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sendDate",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "senderCompanyId",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "senderCompanyName",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "senderId",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "senderName",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "private",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CommunicationActionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimCommunication",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimCommunicationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimCommunication",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimCommunicationAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "acknowledge",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CommunicationActionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimCommunication",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimCommunicationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimCommunication",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimCommunicationAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "acknowledge",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CommunicationActionMutation",
    "id": null,
    "text": "mutation CommunicationActionMutation(\n  $input: ClaimCommunicationCreate!\n) {\n  createClaimCommunication(input: $input) {\n    messages\n    success\n    result {\n      id\n      acknowledged\n      claimId\n      communicationId\n      message\n      acknowledgeUserName\n      acknowledgeDate\n      portfolioType\n      recieverCompanyId\n      recieverCompanyName\n      sendDate\n      senderCompanyId\n      senderCompanyName\n      senderId\n      senderName\n      private\n      actions {\n        acknowledge {\n          isDisplay\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '75040c085623e555aedd3ebe4620f1d8';
module.exports = node;
