import React from 'react'

import { useMachine } from '@xstate/react/lib/fsm'

import useReactRouter from 'use-react-router'
import queryString from 'query-string'
import { useMutation } from 'react-relay-mutation'

import useRoomSubscription from './hooks/useRoomSubscription'
import useActions from './hooks/useActions'

import JoinRoomMutation from './mutations/JoinRoomMutation'
import CreateJobNoteMutation from './mutations/CreateJobNoteMutation'
import DocPhotoUploadMutation from './mutations/DocPhotoUploadMutation'
import UploadPhotoMutation from './mutations/UploadPhotoMutation'

import webRtcMachine from './libs/webRtcMachine'
import checkSupported from './libs/checkSupported'
import checkPermissions from './libs/checkPermissions'
import localSignalling from './libs/localSignalling'
import destroyPeer from './libs/destroyPeer'

import VideoRoom from './VideoRoom'

export default () => {
  const videoDialogActions = window[Symbol.for(`__videoDialog`)]
  // const snackbar = window[Symbol.for('__snackbar')]

  const localVideoRef = React.useRef()
  const remoteVideoRef = React.useRef()
  const [joinRoom] = useMutation(JoinRoomMutation)

  const { location, history } = useReactRouter()
  const { callSessionId, claimId } = queryString.parse(location.search)

  const [loading, setLoading] = React.useState(true)
  const [peerMessage, setPeerMessage] = React.useState('')
  const {
    ready: isRoomSubscribeReady,
    room,
    ownerSession, guestSession,
    isOwner, endLocation,
    statusMessages,
    consentConfigs,
  } = useRoomSubscription({ callSessionId, claimId })

  const roomStatus = room ? room.status : null
  const ownerStatus = ownerSession ? ownerSession.callStatus : null
  const guestStatus = guestSession ? guestSession.callStatus : null
  React.useEffect(() => {
    if(roomStatus === 'ENDED') history.push(endLocation)
  }, [roomStatus, endLocation])
  React.useEffect(() => {
    if (roomStatus !== 'ENDED' && guestStatus && isOwner) setPeerMessage(guestStatus.message)
  }, [roomStatus, guestStatus, isOwner])
  React.useEffect(() => {
    // const roomEnded = roomStatus === 'ENDED'
    const ownerConnected = Boolean(ownerStatus && ownerStatus.status === 'CONNECTED')
    const guestConnected = Boolean(guestStatus && guestStatus.status === 'CONNECTED')

    // !roomEnded &&
    if (ownerConnected && guestConnected) setLoading(false)
    else setLoading(true)
  }, [ownerStatus, guestStatus]) // roomStatus,

  const [state, send] = useMachine(webRtcMachine)

  const isStatuses = Boolean(statusMessages)
  const [debug, setDebug] = React.useState(null)
  React.useEffect(() => {
    if (!statusMessages) return () => {}

    const start = async () => {
      console.log(state.value)
      switch (state.value) {
        case 'idle':
          send('START', { message: statusMessages.CHECK_REQUIREMENTS })
          break

        case 'consentCheck':
          if (isOwner || !consentConfigs.isChecking) {
            return send({ type: 'RESOLVE', message: statusMessages.CHECK_REQUIREMENTS })
          }

          return videoDialogActions.toggleOpen({
            title: consentConfigs.title,
            content: consentConfigs.content,
            firstButton: 'Accept',
            secondButton: 'Cancel'
          })
            .then(res => {
              if (res.ok) send({ type: 'RESOLVE', message: statusMessages.CHECK_REQUIREMENTS })
              else history.push(endLocation)
            })

          // return await snackbar.toggleOpen({
          //   message: 'Agree to the privacy policy to continue',
          //   firstButton: 'Agree',
          //   secondButton: 'Cancel',
          //   type: 'confirm',
          // })
          //   .then(res => {
          //     if (res.ok) {
          //       send({ type: 'RESOLVE', message: statusMessages.CHECK_REQUIREMENTS })
          //     }
          //     else {
          //       send({ type: 'REJECT', message: 'Please agree to the policy to continue' })
          //     }
          //   })

        case 'supportDeviceCheck':
          return await checkSupported()
            .then(supported => {
              send({ type: 'RESOLVE', supported, message: statusMessages.CHECK_REQUIREMENTS })
            })
            .catch((error) => {
              send({ type: 'REJECT', message: statusMessages.CHECK_REQUIREMENTS_FAILED })
            })

        case 'permissionDeviceCheck':
          return await checkPermissions(state.context.supported)
            .then(permitted => {
              if (!permitted.video && !permitted.audio) {
                send({ type: 'REJECT', message: 'Please allow at least camera or microphone to continue' })
              }
              else {
                send({ type: 'RESOLVE', permitted, message: statusMessages.SIGNALLING })
              }

            })
            .catch(error => {
              send({ type: 'REJECT', message: statusMessages.CHECK_REQUIREMENTS_FAILED })
            })

        case 'localSignalling':
          const mode = 'sendRecv'
          const { permitted } = state.context
          return await localSignalling(mode, {
            localVideoRef, remoteVideoRef,
            mediaConstraints: {
              video: permitted && permitted.video ? { facingMode: 'environment' } : false,
              audio: permitted && permitted.audio
            },
            configuration: {
              iceServers: [
                { urls: 'turn:videotest.endataclaims.com:3478', username: 'endata', credential: '567tyughj' },
                { urls: 'stun:videotest.endataclaims.com:3478' },
                { urls: 'stun:stun.l.google.com:19302' },
              ]
            }
          })
            .then(({ kurentoPeer, signallingData }) => {
              console.log(signallingData)
              send({ type: 'RESOLVE', message: statusMessages.SIGNALLING, kurentoPeer, signallingData })
            })
            .catch(() => {
              send({ type: 'REJECT', message: statusMessages.SIGNALLING_FAILED })
            })

        case 'joinRoom':
          const { signallingData, kurentoPeer } = state.context
          await joinRoom({
            variables: {
              where: { callSessionId },
              data: {
                sdpOffer: signallingData.offer,
                clientIceCandidates: signallingData.candidates
              }
            }
          })
            .then(async ({ roomJoin: { serverIceCandidates, sdpAnswer }}) => {
              setDebug({ serverIceCandidates, sdpAnswer })

              await new Promise(resolve => setTimeout(() => resolve(), 500))
              await new Promise((resolve, reject) => {
                kurentoPeer.processAnswer(sdpAnswer, async err => {
                  if(err) reject(err)
                  resolve()
                })
              })

              await new Promise(resolve => setTimeout(() => resolve(), 500))
              serverIceCandidates.forEach(_candidate => {
                kurentoPeer.addIceCandidate(JSON.parse(_candidate), err => {
                  if (err) return console.log(err)
                })
              })

              // kurentoPeer.processAnswer(sdpAnswer, async err => {
              //   if (err) {
              //     send({ type: 'REJECT', message: 'Failed to join a room' })
              //     return console.log(err)
              //   }
              //   await new Promise(resolve => setTimeout(() => resolve(), 500))
              // })
            })
            .then(() => {
              // send({ type: 'RESOLVE', message: statusMessages.CONNECTED })
            })
            .catch(error => {
              console.log(error)
              send({ type: 'REJECT', message: 'Session is expired' })
              setTimeout(() => history.push(endLocation), 500)
            })

        default:
          break
      }
    }
    start()

    // TODO
    // return () => {
    // console.log('clean request')
    //   if (state.value === 'end' && state.context.kurentoPeer) {
    //     console.log('cleaned up')
    //     hangup(state.context.kurentoPeer)
    //   }
    // }
  }, [JSON.stringify(state), send, isStatuses, localVideoRef, remoteVideoRef, callSessionId, consentConfigs.isChecking])

  const [createJobNote] = useMutation(CreateJobNoteMutation)
  const hangup = async () => {
    if (isOwner && claimId) {
      await createJobNote({
        variables: {
          input: { claimId: parseInt(claimId), message: 'Video session has ended' }
        }
      })
    }

    destroyPeer(state.context.kurentoPeer)
    history.push(endLocation)
  }

  const [photos, setPhotos] = React.useState([])
  const [docPhotoUpload] = useMutation(DocPhotoUploadMutation)
  const uploadDoc = async (file) => {
    if (!claimId) return null

    return docPhotoUpload({
      variables: {
        input: {
          claimId: parseInt(claimId), description: `Photo ${photos.length + 1} from video session`, isInvoice: false, private: false,
          documentFileName: file.fileName, fileBase64: file.fileBase64,
        }
      }
    })
  }
  const [uploadPhoto] = useMutation(UploadPhotoMutation)
  const getPhoto = async () => {
    const photo = state.context.kurentoPeer.currentFrame.toDataURL()
    setPhotos(previous => [...previous, photo])

    await fetch(photo)
      .then(res => res.blob())
      .then(blob => new File([blob], `${Date.now()}.jpg`, { type: 'image/png' }))
      .then(async file => {
        if (claimId) {
          const file64 = {
            fileName: `${Date.now()}.jpg`,
            fileBase64: photo.replace('data:image/png;base64,', '')
          }
          await uploadDoc(file64)
        }

        await uploadPhoto({ variables: { sessionId: callSessionId, file } })
      })
  }

  const [videoState, setVideoState] = React.useState(true)
  const toggleVideo = () => {
    setVideoState(previous => {
      const peer = state.context.kurentoPeer
      peer.videoEnabled = !videoState
      return peer.videoEnabled
    })
  }
  const [audioState, setAudioState] = React.useState(true)
  const toggleAudio = () => {
    setAudioState(previous => {
      const peer = state.context.kurentoPeer
      peer.audioEnabled = !audioState
      return peer.audioEnabled
    })
  }
  React.useEffect(() => {
    const peer = state.context.kurentoPeer
    if(peer) {
      if (videoState !== peer.videoEnabled) setVideoState(peer.videoEnabled)
      if (audioState !== peer.audioEnabled) setVideoState(peer.audioEnabled)
    }
  })
  const actionButtons = useActions({
    isOwner,
    loading,
    actionConfigs: room ? room.account.clientActionsConfig : null,
    actions: {
      getPhoto,
      hangup,
      videoState, toggleVideo,
      audioState, toggleAudio,
    }
  })

  // HOT FIX FOR IOS problem of remote stream not starting
  const peer = state.context.kurentoPeer
  const [remote, setRemote] = React.useState(null)
  React.useEffect(() => {
    const start = () => {
      const remote = peer.getRemoteStream()
      if (remote) {
        setRemote(remote)
      }
    }

    setTimeout(() => {
      if (peer) start()
    }, 1000)
  }, [peer])
  React.useEffect(() => {
    if (remote && remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remote
      remoteVideoRef.current.play()
    }
  }, [remote, remoteVideoRef])
  console.log(peer)

  return (
    <>
      {remote && (
        <div style={{ padding: 16 }}>
          <p>id: {remote.id}</p>
          <p>
            active: {String(remote.active)}
          </p>
          <p>
            guest: {guestStatus.status}
          </p>
          candidates: {state.context.signallingData && String(state.context.signallingData.candidates.length)}
        </div>
      )}
      <VideoRoom
        viewMode={isOwner ? 'host' : 'guest'}

        loading={loading}
        message={state.context.message}
        peerMessage={peerMessage}

        localVideoRef={localVideoRef}
        remoteVideoRef={remoteVideoRef}

        photos={photos}
        actions={actionButtons}
      />
    </>
  )
}