import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation QuoteItemUpdateMutation(
    $where: ENDataPortfolioKey!,
    $input: QuoteItemUpdate!
  ) {
    updateJobQuoteItem(where: $where, input: $input) {
      messages
      success
      # result {
      #   jobQuoteId
      #   accepted
      #   costCentre { costCentreId costCentreName }
      #   costItem { costItemId itemDescription }
      #   location { costLocationId locationName }
      #   unit { unitId unitName }
      #   dimensions
      #   lineDescription
      #   marginRate
      #   qty
      #   subtotal
      #   rate
      # }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.updateJobQuoteItem.success,
          message: res.updateJobQuoteItem.messages[0]
        })
      },
      onError: errors => {
        resolve({ ok: true, message: 'System Error, please contact administrator' })
      }
    })
  })
}
