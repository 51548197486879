/**
 * @flow
 * @relayHash d25ab070929a42d1e2421a2ecb3b70ac
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DocumentsList$ref = any;
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimItemFilter = {|
  id?: ?number,
  claimId: string,
  keyWords?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type DocumentsQueryVariables = {|
  first: number,
  after?: ?string,
  where?: ?ClaimItemFilter,
|};
export type DocumentsQueryResponse = {|
  +$fragmentRefs: DocumentsList$ref
|};
export type DocumentsQuery = {|
  variables: DocumentsQueryVariables,
  response: DocumentsQueryResponse,
|};
*/


/*
query DocumentsQuery(
  $first: Int!
  $after: String
  $where: ClaimItemFilter
) {
  ...DocumentsList
}

fragment DocumentsList on Query {
  claimDocuments(first: $first, after: $after, where: $where) {
    edges {
      node {
        ...DocumentsListItem_item
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment DocumentsListItem_item on ClaimDocument {
  id
  documentId
  portfolioType
  uploadDate
  company {
    companyName
    id
  }
  url
  private
  description
  amountInvoice
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimItemFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ClaimItemFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "DocumentsList",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DocumentsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimDocuments",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimDocumentConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDocumentEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimDocument",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "documentId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "portfolioType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "uploadDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "company",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "companyName",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "private",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "amountInvoice",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "claimDocuments",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "DocumentsList_claimDocuments",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DocumentsQuery",
    "id": null,
    "text": "query DocumentsQuery(\n  $first: Int!\n  $after: String\n  $where: ClaimItemFilter\n) {\n  ...DocumentsList\n}\n\nfragment DocumentsList on Query {\n  claimDocuments(first: $first, after: $after, where: $where) {\n    edges {\n      node {\n        ...DocumentsListItem_item\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment DocumentsListItem_item on ClaimDocument {\n  id\n  documentId\n  portfolioType\n  uploadDate\n  company {\n    companyName\n    id\n  }\n  url\n  private\n  description\n  amountInvoice\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '02e7e10648050e7404de4df34afa8406';
module.exports = node;
