import React from 'react'

import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'

const VideoCallImageBar = ({ classes, isOwner, photos }) => {
  return (
    <div className={clsx(
      classes.imageBar,
      {[classes.imageBarGuest]: !isOwner})}
    >
      {photos.map((photo, index) => (
        <img
          key={index}
          className={classes.image}
          src={photo}
          alt='ENData'
        />
      ))}
    </div>
  )
}

export default withStyles(theme => ({
  imageBar: {
    height: '100%',
    flexGrow: 1,
    width: 200,
    padding: 8,
    overflow: 'auto'
  },
  imageBarGuest: {
    display: 'none'
  },
  image: {
    width: '100%'
  }
}))(VideoCallImageBar)