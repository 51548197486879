import React, { Component, Fragment } from 'react'
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'

import Grid from 'components/Mui/Grid'
import { Cube } from 'components/Mui/Loader'
import CommunicationListFilter from './CommunicationListFilter'
import CommunicationList from './CommunicationList'
import { withStyles } from '@material-ui/core/styles'

const query = graphql`
  query CommunicationTasksQuery($first: Int!, $after: String, $where: CommunicationFilter) {
    ...CommunicationList
  }
`

class Communication extends Component {
  state = {
    variables: {
      first: 40,
      after: null,
      where: {
        acknowledged: false,
        boxes: 'Inbox'
      }
    }
  }

  render() {
    const { variables } = this.state
    const { classes } = this.props

    return(
      <Fragment>
        <CommunicationListFilter filterChange={this.filterChange}/>
        <QueryRenderer
          environment={environment}
          query={query}
          variables={variables}
          render={({ error, props }) => {
            if(error) {
              // console.log(error)
              return 'Error!!'
            }

            if(!props) return (
              <Grid fluid style={{ height: '100px' }}>
                <Grid item xs>
                  <Cube />
                </Grid>
              </Grid>
            )

            return (
              <Fragment>
                <div className={classes.root}>
                  <CommunicationList data={props} />
                </div>
              </Fragment>
            )
          }}
        />
      </Fragment>
    )
  }
  filterChange = where => {
    this.setState({ variables: {
      ...this.state.variables,
      where: {
        ...this.state.variables.where,
        ...where
      }
    }})
  }
}

export default withStyles(Theme => ({
  root: {
    transformOrigin: '8rem 0rem',
    width :'109%',
    transform: 'scale(0.91)',
    marginTop:'-10px'
  }
}))(Communication)