import React from 'react'
import { Dialog, IconButton, Paper, withStyles, DialogTitle, CircularProgress } from '@material-ui/core'
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'
import InfoIcon from '@material-ui/icons/Info'
import InfoBox from 'components/Mui/InfoBox'
import { useMetaContext } from 'pages/Claim/ClaimDetails/ClaimMetaContext'

const query = graphql`
  query FeeInfoQuery(
    $where: PaymentReserveWhere!
  ) {
    paymentReserve(where: $where){
      id
      buildingReserve
      contentsReserve
    }
  }
`

const FeeInfo = props => {
  const meta = useMetaContext()
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = props.classes
  const InvoiceAction = props.invoiceItem && props.invoiceItem.feeInvoice.actions.filter(e=>
    e.actionType==="RESERVE_DETAIL_VIEW"
  )[0]
  const feeAction = props.feeItem && props.feeItem.actions.filter(e=>
    e.actionType==="RESERVE_DETAIL_VIEW"
  )[0]
  return (
    <div>
    {InvoiceAction && InvoiceAction.isDisplay &&
      <IconButton style={{padding: '1px'}} onClick={handleClickOpen} 
      className={props.classes.info} disabled={InvoiceAction.isDisabled}>
        <InfoIcon style={{fontSize:'20px'}} />
      </IconButton>}
      {feeAction && feeAction.isDisplay &&
        <IconButton style={{padding: '1px'}} onClick={handleClickOpen} 
        className={props.classes.info} disabled={feeAction.isDisabled}>
          <InfoIcon style={{fontSize:'20px'}} />
        </IconButton>}
      <Dialog 
        open={open}
        onClose={handleClose}
        paperProps={{style: { maxWidth: '65%', minWidth:'20%' }}}       
      >
      <DialogTitle>{
        feeAction && feeAction.isDisplay 
        ?
          "Reserve Breakdown"
        : 
          "Payment Breakdown"
      }</DialogTitle>
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{
          where: {
            claimId: meta.claim.claimId,
            feeItemId: props.feeItem && props.feeItem.feeItemId,
            invoiceId: props.invoiceItem && props.invoiceItem.feeInvoice.feeInvoiceId
          },
        }}
        render={({ error, props}) => {
          if(error) {
            return 'Error!!'
          }

          return (
            <Paper className={classes.paper}>
            {!props && <div className={classes.loading}><CircularProgress /></div>}
            { props &&
              <div>
                <InfoBox label={'Building'} text={props.paymentReserve.buildingReserve} className={classes.infoBox} />
                <InfoBox label={'Contents'} text={props.paymentReserve.contentsReserve} className={classes.infoBox} />
              </div>
            }
           
            </Paper>
          )
        }}
      /> 
      </Dialog>
    </div>
  )
}

export default withStyles(theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
  infoBox: {
    maxHeight: 300
  },
  loading:{
    display:'flex',
    justifyContent:'center'
  }
}))(FeeInfo)