import React, { Component } from 'react'
import { Formik, Form } from 'formik'
import { withClaimMeta } from 'pages/Claim/ClaimDetails/ClaimMetaContext/'
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'
import AdjusterUpdateStatusMutation from './AdjusterUpdateStatusMutation'

import Grid from '@material-ui/core/Grid'
import Input from 'components/Formik/Input'
import Dialog from 'components/Mui/Dialog'
import Button from 'components/Mui/Button'

// import LossAdjusterStatusMutation from './LossAdjusterStatusMutation'
const query = graphql`
  query LossAdjusterStatusQuery($lossAdjusterWhere: ClaimStatusWhere) {
    claimStatus(
      where: $lossAdjusterWhere
    ){
      id        
      value: statusId
      label: statusName
    }
  }
`
class LossAdjusterStatusAction extends Component {
  snackbar = window[Symbol.for('__snackbar')]

  render() {
    return (
      <Dialog
        noForm noActions
        symbol='__LossAdjusterStatusAction'
        title='Change Status'
        content={(
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              lossAdjusterWhere: {
                claimId: this.props.claimId,
                forLossAdjuster: true
              }
            }}
            render={({ error, props }) => {
              if(error) {
                return 'Error!!'
              }
              if(!props) return (
                <Grid fluid style={{ height: '100px' }}>
                  <Grid item xs>
                    Loading...
                  </Grid>
                </Grid>
              )
              return (
                <Formik
                  initialValues={{
                      statusId: '',
                      statusNote:''
                    }}
                  validate={values => {
                    let errors = {}
                    if(!values.statusId) errors.statusId = 'Required!'
                    if(!values.statusNote) errors.statusNote = 'Required!'
                    return errors
                  }}
                  onSubmit={async (values) => {
                    const variables = {
                      input:{
                        statusId: values.statusId,
                        statusNote: values.statusNote
                      },
                      where: {
                        claimId: this.props.claimId
                        
                      }
                    }
                    const res = await AdjusterUpdateStatusMutation(variables)
                    if(res.ok){
                      if(res.success) {
                        this.snackbar.toggleOpen({message: res.messages})
                        this.handleDlClose()
                      } else {
                        this.snackbar.toggleOpen({message: res.messages})
                        this.handleDlClose()
                      }
                    }
                    else {
                      this.snackbar.toggleOpen({message: 'Server error'})
                    }
                    }}
                  >
                  {({ values, isSubmitting }) => {
                    return (
                    <Form>
                      <Grid container>
                        <Grid item xs={12}>
                          <Input 
                            required
                            name='statusId'
                            label='Status'
                            options={props.claimStatus}
                            select
                            value={values.statusId}
                            
                          />
                          <Input required
                          label='Additional Comments'
                          name='statusNote'
                          value={values.statusNote}
                        />
                        </Grid>
                        <div style={{ marginLeft: 'auto' }}>
                          <Button label='Submit' type='submit' variant='contained' color='primary'
                            disabled={isSubmitting}
                          />
                        </div>
                      </Grid>
                    </Form>
                  )}}
                </Formik>
          )}} />
        )}
      />  
    )
  }

  handleDlClose = () => {
    if (window[Symbol.for('__LossAdjusterStatusAction')]) {
      window[Symbol.for('__LossAdjusterStatusAction')].handleClose()
    } 
  }
}

export default withClaimMeta(LossAdjusterStatusAction)