import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
import InfoBox from "components/Mui/InfoBox";
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon";
import IconButton from "components/Mui/IconButton";
import Collapse from "@material-ui/core/Collapse";
import { SpinnerRect } from "components/Mui/Loader";

import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";

import { withClaimMeta } from "../ClaimMetaContext";

const query = graphql`
  query ClaimDetailsInfoQuery($where: ENDataEntityKey!) {
    claim: claimJob(where: $where) {
      refNumber
      lodgeDate
      view {
        actions {
          updateLossAdjusterStatus {
            id
            isDisplay
          }
        }
        fields {
          catCode {
            label
          }
          eventType {
            label
          }
          internalAssessor {
            label
          }
          lossAdjusterStatus {
            label
            displayValue
            isDisplay
          }
          policyType {
            label
          }
          policyCover {
            label
          }
        }
      }
      insurer {
        companyName
        policyTypeSuppliersView
        policyCoverSuppliersView
      }
      insured {
        name
        phone1
        phone2
        phone3
      }
      policyCover {
        policyCoverName
      }
      policyType {
        policyTypeName
      }
      incidentDetail {
        eventType {
          eventName
        }
        cATCode {
          cATCodeName
        }
        riskAddress {
          line1
          suburb
          state
          postcode
        }
      }
      building {
        claimStatus {
          statusName
        }
        scopingSupplier {
          companyName
          companyPhone1
          companyPhone2
        }
        authorisedSupplier {
          companyName
          companyPhone1
          companyPhone2
        }
        jobSuppliers {
          quote {
            supplier {
              companyName
            }
            quoteStatus: quoteJobStatus {
              statusName
            }
          }
        }
        toCollectExcess
        excessValue
      }
      restoration {
        claimStatus {
          statusName
        }
        scopingSupplier {
          companyName
          companyPhone1
          companyPhone2
        }
        authorisedSupplier {
          companyName
          companyPhone1
          companyPhone2
        }
        jobSuppliers {
          quote {
            supplier {
              companyName
            }
            quoteStatus: quoteJobStatus {
              statusName
            }
          }
        }
        toCollectExcess
        excessValue
      }
      contents {
        claimStatus {
          statusName
        }
        toCollectExcess
        excessValue
      }
    }
  }
`;

class ClaimDetailsInfo extends Component {
  state = {
    infoMore: false,
  };

  render() {
    const vehicleActions = window[Symbol.for("__vehicleActions")];
    const { infoMore } = this.state;
    const { classes } = this.props;

    const { claim, user } = this.props.meta;
    const { isAdmin, isBuilder, isRestorer, isContent } = user.type;

    return (
      <Grid container>
        <Grid item xs>
          <Paper
            className={classes.paper}
            content={
              <QueryRenderer
                environment={environment}
                query={query}
                variables={{ where: { id: claim.claimId } }}
                render={({ error, props }) => {
                  if (error) return "Error!";

                  if (!props)
                    return (
                      <Grid fluid style={{ height: "100px" }}>
                        <Grid item xs>
                          <SpinnerRect />
                        </Grid>
                      </Grid>
                    );

                  const { claim } = props;

                  return (
                    <div className={classes.content}>
                      <Grid fluid>
                        {[
                          { label: "Insurance Ref #", text: claim.refNumber },
                          {
                            label: "Customer" /* label:'Client'|'Customer' */,
                            text: claim.insured && claim.insured.name,
                          },
                          {
                            label:
                              "Property/Risk Address" /* label:'Risk Address'|'Property/Risk Address' */,
                            text:
                              claim.incidentDetail.riskAddress &&
                              this.getAddress(claim.incidentDetail.riskAddress),
                          },

                          {
                            label: "Phone(s)",
                            text: claim.insured && this.getPhone(claim.insured),
                          },
                          {
                            label: vehicleActions.isVehicleReport
                              ? "Claim and Status"
                              : "Builder and Status",
                            text:
                              this.getBuildernStats(claim, {
                                isAdmin,
                                isBuilder,
                                isRestorer,
                                isContent,
                              }) || "N/A",
                            iconDom: this.getBuildernStats(claim, {
                              isAdmin,
                              isBuilder,
                              isRestorer,
                              isContent,
                            }) && <ClaimTypeIcon type="Building" />,
                            tooltip: this.getSupplierTooltip(claim.building),
                          },
                          {
                            label: vehicleActions.isVehicleReport
                              ? "Surveyor and Status"
                              : "Restorer and Status",
                            text:
                              this.getRestorernStats(claim, {
                                isAdmin,
                                isBuilder,
                                isRestorer,
                                isContent,
                              }) || "N/A",
                            iconDom: this.getRestorernStats(claim, {
                              isAdmin,
                              isBuilder,
                              isRestorer,
                              isContent,
                            }) && <ClaimTypeIcon type="Restoration" />,
                            tooltip: this.getSupplierTooltip(claim.restoration),
                          },
                          {
                            label: vehicleActions.isVehicleReport
                              ? "Supplier and Status"
                              : "Contents Status",
                            text:
                              this.getContentStats(claim, {
                                isAdmin,
                                isBuilder,
                                isRestorer,
                                isContent,
                              }) || "N/A",
                            iconDom: this.getContentStats(claim, {
                              isAdmin,
                              isBuilder,
                              isRestorer,
                              isContent,
                            }) && <ClaimTypeIcon type="Contents" />,
                          },
                          {
                            label: claim.view.fields.lossAdjusterStatus.label,
                            text:
                              (claim.view.fields.lossAdjusterStatus &&
                                claim.view.fields.lossAdjusterStatus
                                  .displayValue) ||
                              "N/A",
                            unMount:
                              !claim.view.fields.lossAdjusterStatus.isDisplay,
                          },
                        ].map(
                          (row, index) =>
                            !row.unMount && (
                              <Grid item xs={3} key={index}>
                                <InfoBox
                                  label={row.label}
                                  text={row.text}
                                  iconDom={row.iconDom}
                                  tooltip={row.tooltip}
                                />
                              </Grid>
                            )
                        )}
                      </Grid>

                      <Collapse in={infoMore} timeout="auto" unmountOnExit>
                        <Grid fluid>
                          {[
                            { label: "FNOL", text: claim.lodgeDate },
                            {
                              label: claim.view.fields.eventType.label,
                              text:
                                claim.incidentDetail.eventType &&
                                claim.incidentDetail.eventType.eventName,
                            },
                            {
                              label: claim.view.fields.catCode.label,
                              text:
                                claim.incidentDetail.cATCode &&
                                claim.incidentDetail.cATCode.cATCodeName,
                            },
                            {
                              label:
                                claim.view.fields.policyType
                                  .label /* label:'Policy Type'|'Distributor' */,
                              text:
                                claim.policyType &&
                                claim.policyType.policyTypeName,
                              unMount: !claim.insurer.policyTypeSuppliersView,
                            },

                            {
                              label:
                                claim.view.fields.policyCover
                                  .label /* label:'Policy Cover'|'PDS Reference' */,
                              text:
                                claim.policyCover &&
                                claim.policyCover.policyCoverName,
                              unMount: !claim.insurer.policyCoverSuppliersView,
                            },
                            {
                              label: "Insurance Company",
                              text: claim.insurer && claim.insurer.companyName,
                            },
                          ].map(
                            (row, index) =>
                              !row.unMount && (
                                <Grid item xs={3} key={index}>
                                  <InfoBox label={row.label} text={row.text} />
                                </Grid>
                              )
                          )}
                        </Grid>
                      </Collapse>

                      <IconButton
                        className={classes.infoMore}
                        icon={infoMore ? "expand_less" : "expand_more"}
                        onClick={(e) =>
                          this.setState({ infoMore: !this.state.infoMore })
                        }
                      />
                    </div>
                  );
                }}
              />
            }
          />
        </Grid>
      </Grid>
    );
  }
  getAddress = (address) => {
    const { line1, suburb, state, postcode } = address;

    return `${line1}, ${suburb} ${state} ${postcode}`;
  };
  getPhone = (details) => {
    const { phone1, phone2, phone3 } = details;
    return `${phone1}${phone2 ? `, ${phone2}` : ""}${
      phone3 ? `, ${phone3}` : ""
    }`;
  };

  getBuildernStats = ({ building }, user) => {
    if (building) {
      if (user.isAdmin || user.isRestorer || user.isContent) {
        const name =
          building && building.authorisedSupplier
            ? building.authorisedSupplier.companyName
            : building && building.scopingSupplier
            ? building.scopingSupplier.companyName
            : null;
        const status = building.claimStatus.statusName;
        const value = building.toCollectExcess
          ? `- $${building.excessValue}`
          : null;

        return `${name ? name : ""}${status ? `, ${status}` : ""}${
          value ? `, Excess ${value}` : ""
        }`;
      }
      if (user.isBuilder) {
        const name =
          building.jobSuppliers &&
          building.jobSuppliers[0].quote &&
          building.jobSuppliers[0].quote.supplier
            ? building.jobSuppliers[0].quote.supplier.companyName
            : "";
        const status =
          building.jobSuppliers[0] && building.jobSuppliers[0].quote
            ? building.jobSuppliers[0].quote.quoteStatus.statusName
            : "";
        const value = building.toCollectExcess
          ? `- $${building.excessValue}`
          : null;

        return `${name ? name : ""}${status ? `, ${status}` : ""}${
          value ? `, Excess ${value}` : ""
        }`;
      }
    }
    return null;
  };
  getRestorernStats = ({ restoration }, user) => {
    if (restoration) {
      if (user.isAdmin || user.isBuilder || user.isContent) {
        const name = restoration.authorisedSupplier
          ? restoration.authorisedSupplier.companyName
          : restoration.scopingSupplier
          ? restoration.scopingSupplier.companyName
          : null;
        const status = restoration.claimStatus.statusName;
        const value = restoration.toCollectExcess
          ? `- $${restoration.excessValue}`
          : null;

        return `${name ? name : ""}${status ? `, ${status}` : ""}${
          value ? `, Excess ${value}` : ""
        }`;
      }
      if (user.isRestorer) {
        const name =
          restoration.jobSuppliers &&
          restoration.jobSuppliers[0] &&
          restoration.jobSuppliers[0].quote &&
          restoration.jobSuppliers[0].quote.supplier
            ? restoration.jobSuppliers[0].quote.supplier.companyName
            : "";
        const status =
          restoration.jobSuppliers &&
          restoration.jobSuppliers[0] &&
          restoration.jobSuppliers[0].quote
            ? restoration.jobSuppliers[0].quote.quoteStatus.statusName
            : "";
        const value = restoration.toCollectExcess
          ? `- $${restoration.excessValue}`
          : null;

        return `${name ? name : ""}${status ? `, ${status}` : ""}${
          value ? `, Excess ${value}` : ""
        }`;
      }
    }
    return null;
  };
  getContentStats = ({ contents }, user) => {
    if (contents) {
      const { claimStatus, toCollectExcess, excessValue } = contents;
      return `${claimStatus.statusName} ${
        toCollectExcess ? `, Excess - $${excessValue}` : ""
      }`;
    }

    return null;
  };

  getSupplierTooltip = (portfilio) => {
    if (portfilio) {
      const supplier =
        portfilio.authorisedSupplier || portfilio.scopingSupplier;
      return (
        <>
          {supplier && supplier.companyName && (
            <p>{supplier.companyName.split("-")[0].trim()}</p>
          )}
          {supplier && supplier.companyPhone1 && (
            <p>{supplier.companyPhone1}</p>
          )}
          {supplier && supplier.companyPhone2 && (
            <p>{supplier.companyPhone2}</p>
          )}
        </>
      );
    }

    return null;
  };
}

const styles = (theme) => ({
  paper: {
    position: "relative",
  },
  infoMore: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  content: {
    marginBottom: "-8px",
    marginTop: "-8px",
  },
});

export default withClaimMeta(withStyles(styles)(ClaimDetailsInfo));
