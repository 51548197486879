/**
 * @flow
 * @relayHash a90d2ce9e973ce251326930da5946874
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimJobStatus = "VariationApproved" | "VariationRejected" | "VariationReset" | "VariationSubmitted" | "%future added value";
export type JobRecommendationStatus = "NotRecommended" | "Recommended" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuoteRequestStatus = "AdditionalItems" | "AppointmentMade" | "AwaitingInfo" | "AwaitingInfoContents" | "ClaimFinalised" | "InitialCallMade" | "JobAuthorised" | "JobLost" | "NewClaims" | "OfferScopeAcc" | "QuoteSubmitted" | "WaitingQuotes" | "WaitingResult" | "WaitingScope" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type ClaimJobFilter = {|
  id?: ?string,
  parentId?: ?string,
  subject?: ?string,
  createDateFrom?: ?any,
  createDateTo?: ?any,
  incidentDate?: ?any,
  incidentDateFrom?: ?any,
  incidentDateTo?: ?any,
  keyWords?: ?string,
  refNumber?: ?string,
  postcode?: ?string,
  managerTeams?: ?$ReadOnlyArray<?string>,
  managers?: ?$ReadOnlyArray<string>,
  insurers?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  agents?: ?$ReadOnlyArray<string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  buildingSuppliers?: ?$ReadOnlyArray<string>,
  contentsSuppliers?: ?$ReadOnlyArray<string>,
  restorationSuppliers?: ?$ReadOnlyArray<string>,
  buildingStatus?: ?$ReadOnlyArray<string>,
  contentsStatus?: ?$ReadOnlyArray<string>,
  restorationStatus?: ?$ReadOnlyArray<string>,
  lossAdjusterStatus?: ?$ReadOnlyArray<string>,
  suppliers?: ?$ReadOnlyArray<string>,
  status?: ?$ReadOnlyArray<string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  jobStatuses?: ?$ReadOnlyArray<string>,
  claimStatusStageIds?: ?$ReadOnlyArray<string>,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  states?: ?$ReadOnlyArray<?StateEnum>,
  variationStatuses?: ?$ReadOnlyArray<?ClaimJobStatus>,
  requestStatuses?: ?$ReadOnlyArray<?QuoteRequestStatus>,
  recommendationStatus?: ?$ReadOnlyArray<JobRecommendationStatus>,
  or?: ?ClaimJobFilter,
  and?: ?ClaimJobFilter,
  not?: ?ClaimJobFilter,
|};
export type ClaimDetailsCardQueryVariables = {|
  eventTypeWhere?: ?ClaimJobFilter,
  catCodesWhere?: ?ClaimJobFilter,
  policyTypesWhere?: ?ClaimJobFilter,
  policyCoversWhere?: ?ClaimJobFilter,
  managersWhere?: ?ClaimJobFilter,
  internalAssessorsWhere?: ?ClaimJobFilter,
  assessorTypeVisitsWhere?: ?ClaimJobFilter,
  adjustersWhere?: ?ClaimJobFilter,
  agentWhere?: ?ClaimJobFilter,
  isFetched: boolean,
|};
export type ClaimDetailsCardQueryResponse = {|
  +eventTypes?: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +catCodes?: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +policyTypes?: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +policyCovers?: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +managers?: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +internalAssessors?: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +assessorTypeVisits?: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +adjusters?: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +agent?: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
|};
export type ClaimDetailsCardQuery = {|
  variables: ClaimDetailsCardQueryVariables,
  response: ClaimDetailsCardQueryResponse,
|};
*/


/*
query ClaimDetailsCardQuery(
  $eventTypeWhere: ClaimJobFilter
  $catCodesWhere: ClaimJobFilter
  $policyTypesWhere: ClaimJobFilter
  $policyCoversWhere: ClaimJobFilter
  $managersWhere: ClaimJobFilter
  $internalAssessorsWhere: ClaimJobFilter
  $assessorTypeVisitsWhere: ClaimJobFilter
  $adjustersWhere: ClaimJobFilter
  $agentWhere: ClaimJobFilter
  $isFetched: Boolean!
) {
  eventTypes: claimFilterOptions(where: $eventTypeWhere) @include(if: $isFetched) {
    label: name
    value
    id
  }
  catCodes: claimFilterOptions(where: $catCodesWhere) @include(if: $isFetched) {
    label: name
    value
    id
  }
  policyTypes: claimFilterOptions(where: $policyTypesWhere) @include(if: $isFetched) {
    label: name
    value
    id
  }
  policyCovers: claimFilterOptions(where: $policyCoversWhere) @include(if: $isFetched) {
    label: name
    value
    id
  }
  managers: claimFilterOptions(where: $managersWhere) @include(if: $isFetched) {
    label: name
    value
    id
  }
  internalAssessors: claimFilterOptions(where: $internalAssessorsWhere) @include(if: $isFetched) {
    label: name
    value
    id
  }
  assessorTypeVisits: claimFilterOptions(where: $assessorTypeVisitsWhere) @include(if: $isFetched) {
    label: name
    value
    id
  }
  adjusters: claimFilterOptions(where: $adjustersWhere) @include(if: $isFetched) {
    label: name
    value
    id
  }
  agent: claimFilterOptions(where: $agentWhere) @include(if: $isFetched) {
    label: name
    value
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventTypeWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "catCodesWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "policyTypesWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "policyCoversWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "managersWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "internalAssessorsWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "assessorTypeVisitsWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "adjustersWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "agentWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isFetched",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "eventTypeWhere",
    "type": "ClaimJobFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "catCodesWhere",
    "type": "ClaimJobFilter"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "policyTypesWhere",
    "type": "ClaimJobFilter"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "policyCoversWhere",
    "type": "ClaimJobFilter"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "managersWhere",
    "type": "ClaimJobFilter"
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "internalAssessorsWhere",
    "type": "ClaimJobFilter"
  }
],
v10 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "assessorTypeVisitsWhere",
    "type": "ClaimJobFilter"
  }
],
v11 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "adjustersWhere",
    "type": "ClaimJobFilter"
  }
],
v12 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "agentWhere",
    "type": "ClaimJobFilter"
  }
],
v13 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ClaimDetailsCardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isFetched",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "eventTypes",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "catCodes",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v5/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "policyTypes",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v6/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "policyCovers",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v7/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "managers",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v8/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "internalAssessors",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v9/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "assessorTypeVisits",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v10/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "adjusters",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v11/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "agent",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v12/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v4/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ClaimDetailsCardQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isFetched",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "eventTypes",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "catCodes",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v5/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "policyTypes",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v6/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "policyCovers",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v7/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "managers",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v8/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "internalAssessors",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v9/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "assessorTypeVisits",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v10/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "adjusters",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v11/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": "agent",
            "name": "claimFilterOptions",
            "storageKey": null,
            "args": (v12/*: any*/),
            "concreteType": "FilterOption",
            "plural": true,
            "selections": (v13/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ClaimDetailsCardQuery",
    "id": null,
    "text": "query ClaimDetailsCardQuery(\n  $eventTypeWhere: ClaimJobFilter\n  $catCodesWhere: ClaimJobFilter\n  $policyTypesWhere: ClaimJobFilter\n  $policyCoversWhere: ClaimJobFilter\n  $managersWhere: ClaimJobFilter\n  $internalAssessorsWhere: ClaimJobFilter\n  $assessorTypeVisitsWhere: ClaimJobFilter\n  $adjustersWhere: ClaimJobFilter\n  $agentWhere: ClaimJobFilter\n  $isFetched: Boolean!\n) {\n  eventTypes: claimFilterOptions(where: $eventTypeWhere) @include(if: $isFetched) {\n    label: name\n    value\n    id\n  }\n  catCodes: claimFilterOptions(where: $catCodesWhere) @include(if: $isFetched) {\n    label: name\n    value\n    id\n  }\n  policyTypes: claimFilterOptions(where: $policyTypesWhere) @include(if: $isFetched) {\n    label: name\n    value\n    id\n  }\n  policyCovers: claimFilterOptions(where: $policyCoversWhere) @include(if: $isFetched) {\n    label: name\n    value\n    id\n  }\n  managers: claimFilterOptions(where: $managersWhere) @include(if: $isFetched) {\n    label: name\n    value\n    id\n  }\n  internalAssessors: claimFilterOptions(where: $internalAssessorsWhere) @include(if: $isFetched) {\n    label: name\n    value\n    id\n  }\n  assessorTypeVisits: claimFilterOptions(where: $assessorTypeVisitsWhere) @include(if: $isFetched) {\n    label: name\n    value\n    id\n  }\n  adjusters: claimFilterOptions(where: $adjustersWhere) @include(if: $isFetched) {\n    label: name\n    value\n    id\n  }\n  agent: claimFilterOptions(where: $agentWhere) @include(if: $isFetched) {\n    label: name\n    value\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae087525a219d493b041172add7bbb25';
module.exports = node;
