import React, { Component } from "react";
import environment from "common/relay";
import { withStyles } from "@material-ui/core/styles";
import { QueryRenderer, graphql } from "react-relay";
import { withRouter } from "react-router-dom";

import QuickAction from "components/Mui/QuickActions/QuickAction";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";

import { Formik, Form } from "formik";
import Switch from "components/Formik/Switch";
import Input from "components/Formik/Input";

import CommunicationActionMutation from "./CommunicationActionMutation";

const query = graphql`
  query CommunicationActionQuery($where: ClaimParticipantIndex) {
    claimParticipants(where: $where) {
      company {
        companyType
      }
      value: companyId
      group: companyRole
      label: companyName
    }
  }
`;

class CommunicationAction extends Component {
  qaRef = React.createRef();
  snackbar = window[Symbol.for("__snackbar")];

  render() {
    const { classes, claimId, staticContext, ...rest } = this.props;
    return (
      <QuickAction
        title="New Communication"
        innerRef={this.qaRef}
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              where: {
                claimId,
                participantStatus: "AcceptedOnly",
              },
            }}
            render={({ error, props }) => {
              if (!props) return "Loading...";
              const receiverOptions = props.claimParticipants.map((e) => ({
                ...e,
                value: `${e.company.companyType}_${e.group}_${e.value}`,
                group: e.group,
              }));
              return (
                <Formik
                  initialValues={{
                    receiverCompanyId: "",
                    message: "",
                    private: false,
                  }}
                  validate={(values) => {
                    let errors = {};

                    if (!values.receiverCompanyId)
                      errors.receiverCompanyId = "required";
                    if (!values.message) errors.message = "required";

                    return errors;
                  }}
                  onSubmit={(values, actions) => {
                    const [
                      receiverRole,
                      receiverGroup,
                      receiverCompanyId,
                    ] = values.receiverCompanyId.split("_");

                    setTimeout(async () => {
                      const res = await CommunicationActionMutation({
                        claimId,
                        message: values.message,
                        receiverCompanyId: parseInt(receiverCompanyId),
                        receiverRole,
                        private: values.private,
                        path: this.props.location.pathname,
                      });
                      if (res.ok) {
                        actions.setSubmitting(false);
                        this.snackbar.toggleOpen({
                          message: res.message[0],
                        });
                        if (res.success) {
                          actions.resetForm({
                            receiverCompanyId: "",
                            message: "",
                            private: false,
                          });
                          this.qaRef.current.toggleOpen();
                        }
                      }
                    }, 400);
                  }}
                >
                  {({ isSubmitting, values, touched }) => {
                    return (
                      <Form>
                        <Grid fluid spacing={0} className={classes.root}>
                          <Grid item xs={12}>
                            <Input
                              label="Send to"
                              select
                              required
                              name="receiverCompanyId"
                              options={receiverOptions}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              required
                              fullWidth
                              variant="outlined"
                              label="Message"
                              name="message"
                              multiline
                              rows={5}
                              rowsMax={10}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Switch
                              label="Privacy"
                              name="private"
                              labelOn="Private"
                              labelOff="Public"
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <Button
                              label="Submit"
                              type="submit"
                              variant="contained"
                              color="primary"
                              fullWidth
                              disabled={isSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              );
            }}
          />
        }
        {...rest}
      />
    );
  }
}

export default withStyles((theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
  },
}))(withRouter(CommunicationAction));
