/**
 * @flow
 * @relayHash 7fdf52d9ccc8946456a0d5fb32fde0ea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupervisorKey = {|
  id: string
|};
export type SupervisorUpdateInput = {|
  name?: ?string,
  phoneNumber?: ?string,
  disabled?: ?boolean,
|};
export type SupervisorUpdateMutationVariables = {|
  where: SupervisorKey,
  input: SupervisorUpdateInput,
|};
export type SupervisorUpdateMutationResponse = {|
  +supervisorUpdate: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type SupervisorUpdateMutation = {|
  variables: SupervisorUpdateMutationVariables,
  response: SupervisorUpdateMutationResponse,
|};
*/


/*
mutation SupervisorUpdateMutation(
  $where: SupervisorKey!
  $input: SupervisorUpdateInput!
) {
  supervisorUpdate(where: $where, input: $input) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "SupervisorKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SupervisorUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "supervisorUpdate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SupervisorUpdateInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "SupervisorKey!"
      }
    ],
    "concreteType": "SupervisorPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SupervisorUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SupervisorUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SupervisorUpdateMutation",
    "id": null,
    "text": "mutation SupervisorUpdateMutation(\n  $where: SupervisorKey!\n  $input: SupervisorUpdateInput!\n) {\n  supervisorUpdate(where: $where, input: $input) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '62b06aa9d4a48e34149a7dfecc954b4c';
module.exports = node;
