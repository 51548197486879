import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'

class QuickActions extends Component {
  render() {
    const { classes, children } = this.props
    return (
      <div className={classes.root}>
        { children }
      </div>
    )
  }
}

export default withStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    zIndex: '500',
  }
}))(QuickActions)