import React, { Component } from "react";
import Dialog from "components/Mui/Dialog";

import VariationAddLineItemPresentation from "./VariationAddLineItemPresentation";

class VariationAddLineItem extends Component {
  render() {
    const { dlRef, variationId, portfolios, handleRefresh, scopeTypeId } =
      this.props;

    return (
      <Dialog
        noForm
        noActions
        innerRef={dlRef}
        paperProps={{
          style: { maxWidth: "50%" },
        }}
        title="Add Line Item"
        content={
          <VariationAddLineItemPresentation
            scopeTypeId={scopeTypeId}
            dlRef={dlRef}
            variationId={variationId}
            portfolio={portfolios}
            handleRefresh={handleRefresh}
          />
        }
      />
    );
  }
}

export default VariationAddLineItem;

/*

  <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              quoteCostLocationsWhere: {
                portfolioType: portfolios
              },
              quoteCostCentresWhere: {
                portfolioType: portfolios
              },
            }}
            render={({ errors, props }) => {
              if(!props) return null

              const locationOptions = props.locations.edges.map(x => x.node)
              const centresOptions = props.centres.edges.map(x => x.node)

              return (
                <VariationAddLineItemPresentation
                  dlRef={dlRef}
                  locationOptions={locationOptions}
                  centresOptions={centresOptions}
                  variationId={variationId}
                  portfolios={portfolios}
                  handleRefresh={handleRefresh}
                />
              )
            }}
          />
 */
