/**
 * @flow
 * @relayHash c6e0acb4c3d27bcf5dac592564afadba
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ENDataEntityKey = {|
  id: string
|};
export type DashboardKpiDetailsItemQueryVariables = {|
  where: ENDataEntityKey
|};
export type DashboardKpiDetailsItemQueryResponse = {|
  +claim: ?{|
    +view: ?{|
      +tabs: {|
        +historyTab: {|
          +isDisplay: boolean
        |}
      |}
    |}
  |}
|};
export type DashboardKpiDetailsItemQuery = {|
  variables: DashboardKpiDetailsItemQueryVariables,
  response: DashboardKpiDetailsItemQueryResponse,
|};
*/


/*
query DashboardKpiDetailsItemQuery(
  $where: ENDataEntityKey!
) {
  claim: claimJob(where: $where) {
    view {
      tabs {
        historyTab {
          isDisplay
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataEntityKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardKpiDetailsItemQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "claim",
        "name": "claimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "view",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJobView",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tabs",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobTab",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "historyTab",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "HistoryTab",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardKpiDetailsItemQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "claim",
        "name": "claimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "view",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJobView",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tabs",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobTab",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "historyTab",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "HistoryTab",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DashboardKpiDetailsItemQuery",
    "id": null,
    "text": "query DashboardKpiDetailsItemQuery(\n  $where: ENDataEntityKey!\n) {\n  claim: claimJob(where: $where) {\n    view {\n      tabs {\n        historyTab {\n          isDisplay\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9047028147ba62330858ab7ebb707f29';
module.exports = node;
