/**
 * @flow
 * @relayHash 53f79bb9c9915c3573dd5c70c3b54076
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimItemFilter = {|
  id?: ?number,
  claimId: string,
  keyWords?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type GetLossAdjusterPdfMutationVariables = {|
  where: ClaimItemFilter
|};
export type GetLossAdjusterPdfMutationResponse = {|
  +pdf: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type GetLossAdjusterPdfMutation = {|
  variables: GetLossAdjusterPdfMutationVariables,
  response: GetLossAdjusterPdfMutationResponse,
|};
*/


/*
mutation GetLossAdjusterPdfMutation(
  $where: ClaimItemFilter!
) {
  pdf: pdfProduceForAdjusterDocuments(where: $where) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimItemFilter!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "pdf",
    "name": "pdfProduceForAdjusterDocuments",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ClaimItemFilter!"
      }
    ],
    "concreteType": "GeneralPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GetLossAdjusterPdfMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GetLossAdjusterPdfMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "GetLossAdjusterPdfMutation",
    "id": null,
    "text": "mutation GetLossAdjusterPdfMutation(\n  $where: ClaimItemFilter!\n) {\n  pdf: pdfProduceForAdjusterDocuments(where: $where) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '88700462298f71d87ae1b9af5899ec08';
module.exports = node;
