import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation QuoteExportToExcelMutation($where: JobQuoteIndex!) {
    csv: csvProduceForJobQuote(where: $where) {
      success
      messages
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          const { success, messages } = res.csv
          resolve({ok: true, success, messages})
        },
        onError: errors => {
          resolve({ok: false, messages: ['Unable to complete this action. Please try again!']})
        }
      }
    )
  })
}
