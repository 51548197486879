import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation TenderClosingDateActionMutation($where: ClaimPortfoliosUniqueWhere!, $input: TenderCloseDateInput!) {
    claimUpdateTenderCloseDate(where: $where, input: $input) {
      success
      messages
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: ({claimUpdateTenderCloseDate}) => {
          const { messages, success } = claimUpdateTenderCloseDate
          resolve({ok: true, messages, success})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}
