import React from 'react'
import { Link } from 'react-router-dom'
import { CircularProgress, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, ListItemIcon, TextField, InputAdornment, IconButton } from '@material-ui/core'
import CallIcon from '@material-ui/icons/Call'
// import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'
import { useMutation, graphql } from 'relay-hooks'

const RoomList = ({ loading, rooms, selectedRoom, keyword, setKeyword, listLoading, setListLoading }) => {
  // const { roomId } = useParams()
  const roomsString = JSON.stringify(rooms)
  React.useEffect(()=>{
    setListLoading(false)
  },[roomsString])

  return (
    <Paper elevation={10} style={{ height: '100%', overflow: 'auto' }}>
      <SearchField keyword={keyword} setKeyword={setKeyword} />
      <List>
        {
          loading ? <CircularProgress /> :
          (rooms && rooms.length) ? rooms.map(room => <Room key={room.id} room={room} selectedRoom={selectedRoom} />) :
          <div style={{ pading: 16}}>No room found</div>
        }
      </List>
      {listLoading && <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress style={{ height: '40px', width:'40px' }} /></div>}
    </Paper>
  )
}
export default RoomList

const SearchField = ({ keyword, setKeyword }) => {
  const [textInput, setTextInput] = React.useState(keyword || '')

  return (
    <TextField
      autoFocus
      style={{ padding: 8 }}
      placeholder='search for call'

      value={textInput}
      onChange={e => setTextInput(e.target.value)}
      onKeyPress={e => {
        if (e.key === 'Enter') setKeyword(textInput)
      }}

      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={() => setKeyword(textInput)}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

const Room = ({ room, selectedRoom }) => {
  const { id, case: { referenceNumber }, createdAt } = room

  const currentDate = new Date(createdAt)

  const snackbar = window[Symbol.for('__snackbar')]
  const [commit] = useMutation(
    graphql`
      mutation RoomListDeleteMutation($id: ID!) {
        roomDelete(id: $id) {
          id
          isDeleted
          deletedAt
        }
      }
    `,
    {
      configs: [{
        type: 'NODE_DELETE',
        deletedIDFieldName: 'id'
      }]
    }
  )
  const handleDeleteRoom = async id => {
    const confirm = await snackbar.toggleOpen({
      message: 'Please confirm to delete the item',
      firstButton: 'Delete',
      secondButton: 'Cancel',
      type: 'confirm'
    })
    if(confirm.ok) {
      const res = await commit({ variables: { id } })
      if (res) snackbar.toggleOpen({ message: 'Deleted' })
    }
  }

  return (
    <>
      <ListItem
        button
        selected={selectedRoom === id}
        component={Link}
        to={`/video/rooms/${id}`}
      >
        <ListItemIcon>
          <CallIcon />
        </ListItemIcon>
        <ListItemText
          primary={referenceNumber}
          secondary={`${currentDate.toLocaleTimeString()} ${currentDate.toLocaleDateString()}`}
        />
        {/* {selectedRoom === id && (
          <ListItemSecondaryAction>
            <ChevronRightIcon />
          </ListItemSecondaryAction>
        )} */}
        <ListItemSecondaryAction>
          <IconButton onClick={() => handleDeleteRoom(id)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  )
}
