/**
 * @flow
 * @relayHash 89575484115677a1b6a6c3fe8fb655ed
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DashboardKpiDetailsPresentation$ref = any;
export type DateRangeOption = "Annual" | "Daily" | "FinancialYear" | "Month" | "Monthly" | "Quarter" | "Today" | "UnDefined" | "Week" | "Weekly" | "Yearly" | "%future added value";
export type JobRecommendationStatus = "NotRecommended" | "Recommended" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type CaseReportIndex = {|
  internalFilterName: string,
  filter?: ?CaseReportFilter,
|};
export type CaseReportFilter = {|
  insurers?: ?$ReadOnlyArray<string>,
  managers?: ?$ReadOnlyArray<string>,
  managerTeams?: ?$ReadOnlyArray<?string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  agents?: ?$ReadOnlyArray<string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  buildingSuppliers?: ?$ReadOnlyArray<string>,
  contentsSuppliers?: ?$ReadOnlyArray<string>,
  restorationSuppliers?: ?$ReadOnlyArray<string>,
  buildingStatus?: ?$ReadOnlyArray<string>,
  contentsStatus?: ?$ReadOnlyArray<string>,
  restorationStatus?: ?$ReadOnlyArray<string>,
  lossAdjusterStatus?: ?$ReadOnlyArray<string>,
  suppliers?: ?$ReadOnlyArray<string>,
  status?: ?$ReadOnlyArray<string>,
  feeTypeIds?: ?$ReadOnlyArray<string>,
  invoiceStatus?: ?$ReadOnlyArray<string>,
  variationStatus?: ?$ReadOnlyArray<string>,
  kpiCategories?: ?$ReadOnlyArray<string>,
  regions?: ?$ReadOnlyArray<?string>,
  dateRange?: ?DateRangeOption,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  states?: ?$ReadOnlyArray<?StateEnum>,
  recommendationStatus?: ?$ReadOnlyArray<JobRecommendationStatus>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type DashboardKpiDetailsContainerQueryVariables = {|
  first?: ?number,
  after?: ?string,
  where: CaseReportIndex,
|};
export type DashboardKpiDetailsContainerQueryResponse = {|
  +$fragmentRefs: DashboardKpiDetailsPresentation$ref
|};
export type DashboardKpiDetailsContainerQuery = {|
  variables: DashboardKpiDetailsContainerQueryVariables,
  response: DashboardKpiDetailsContainerQueryResponse,
|};
*/


/*
query DashboardKpiDetailsContainerQuery(
  $first: Int
  $after: String
  $where: CaseReportIndex!
) {
  ...DashboardKpiDetailsPresentation
}

fragment DashboardKpiDetailsPresentation on Query {
  reportingClaimJobGeneral(first: $first, after: $after, where: $where) {
    edges {
      node {
        ...DashboardKpiDetailsItem_details
        id
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
  }
}

fragment DashboardKpiDetailsItem_details on ClaimJobGeneral {
  refNumber
  insuredName
  caseManagerName
  reportManagerName
  status {
    statusName
    id
  }
  daysAtStatus
  claimId
  portfolioType
  reportStatusName
  reportCategoryName
  reportItemId
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "CaseReportIndex!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "CaseReportIndex"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardKpiDetailsContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "DashboardKpiDetailsPresentation",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardKpiDetailsContainerQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reportingClaimJobGeneral",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobGeneralReportConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJobGeneralReportEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobGeneral",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "refNumber",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "insuredName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "caseManagerName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "reportManagerName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "status",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimStatus",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "statusName",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "daysAtStatus",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "claimId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "portfolioType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "reportStatusName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "reportCategoryName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "reportItemId",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "reportingClaimJobGeneral",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "DashboardKpiDetailsPresentation_reportingClaimJobGeneral",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DashboardKpiDetailsContainerQuery",
    "id": null,
    "text": "query DashboardKpiDetailsContainerQuery(\n  $first: Int\n  $after: String\n  $where: CaseReportIndex!\n) {\n  ...DashboardKpiDetailsPresentation\n}\n\nfragment DashboardKpiDetailsPresentation on Query {\n  reportingClaimJobGeneral(first: $first, after: $after, where: $where) {\n    edges {\n      node {\n        ...DashboardKpiDetailsItem_details\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n  }\n}\n\nfragment DashboardKpiDetailsItem_details on ClaimJobGeneral {\n  refNumber\n  insuredName\n  caseManagerName\n  reportManagerName\n  status {\n    statusName\n    id\n  }\n  daysAtStatus\n  claimId\n  portfolioType\n  reportStatusName\n  reportCategoryName\n  reportItemId\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5752cd50241cfa6bc0db13c92d0cfdaf';
module.exports = node;
