/**
 * @flow
 * @relayHash ccbc3d15eaa873b2b7d40cb0777b379c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MediaListDeleteMutationVariables = {|
  id: string
|};
export type MediaListDeleteMutationResponse = {|
  +mediaDelete: ?{|
    +id: string,
    +isDeleted: boolean,
    +deletedAt: ?any,
  |}
|};
export type MediaListDeleteMutation = {|
  variables: MediaListDeleteMutationVariables,
  response: MediaListDeleteMutationResponse,
|};
*/


/*
mutation MediaListDeleteMutation(
  $id: ID!
) {
  mediaDelete(id: $id) {
    id
    isDeleted
    deletedAt
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "mediaDelete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id",
        "type": "ID!"
      }
    ],
    "concreteType": "File",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isDeleted",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedAt",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MediaListDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaListDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "MediaListDeleteMutation",
    "id": null,
    "text": "mutation MediaListDeleteMutation(\n  $id: ID!\n) {\n  mediaDelete(id: $id) {\n    id\n    isDeleted\n    deletedAt\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd81c50a52c666c6a629001e25d234309';
module.exports = node;
