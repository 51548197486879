/**
 * @flow
 * @relayHash a4b5ec72cf3648bc82585cd0e82655ee
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuotationType = "AssessorReport" | "MakeSafe" | "Scope" | "UnDefined" | "Variation" | "%future added value";
export type QuoteCostType = "Labour" | "Material" | "UnDefined" | "%future added value";
export type QuoteCostLocationIndex = {|
  portfolioType: PortfolioType
|};
export type QuoteCostCentreIndex = {|
  insurerId?: ?number,
  costType?: ?QuoteCostType,
  portfolioType: PortfolioType,
  quotationType: QuotationType,
|};
export type JobScopeTypeWhere = {|
  insurerId: string,
  claimId?: ?string,
  forManager: boolean,
  quotationType: QuotationType,
  portfolioType: PortfolioType,
|};
export type MakeSafeUpdateItemContainerQueryVariables = {|
  locationsWhere: QuoteCostLocationIndex,
  centresWhere: QuoteCostCentreIndex,
  jobScopeWhere: JobScopeTypeWhere,
|};
export type MakeSafeUpdateItemContainerQueryResponse = {|
  +locations: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +label: string,
        +value: number,
      |}
    |}>
  |},
  +centres: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +label: string,
        +value: number,
      |}
    |}>
  |},
  +jobScopeItems: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
|};
export type MakeSafeUpdateItemContainerQuery = {|
  variables: MakeSafeUpdateItemContainerQueryVariables,
  response: MakeSafeUpdateItemContainerQueryResponse,
|};
*/


/*
query MakeSafeUpdateItemContainerQuery(
  $locationsWhere: QuoteCostLocationIndex!
  $centresWhere: QuoteCostCentreIndex!
  $jobScopeWhere: JobScopeTypeWhere!
) {
  locations: quoteCostLocations(where: $locationsWhere) {
    edges {
      node {
        label: locationName
        value: costLocationId
        id
      }
    }
  }
  centres: quoteCostCentres(where: $centresWhere) {
    edges {
      node {
        label: costCentreName
        value: costCentreId
        id
      }
    }
  }
  jobScopeItems: jobScopeTypes(where: $jobScopeWhere) {
    label: name
    value: id
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "locationsWhere",
    "type": "QuoteCostLocationIndex!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "centresWhere",
    "type": "QuoteCostCentreIndex!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobScopeWhere",
    "type": "JobScopeTypeWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "locationsWhere",
    "type": "QuoteCostLocationIndex!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "locationName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "costLocationId",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "centresWhere",
    "type": "QuoteCostCentreIndex!"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "costCentreName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "costCentreId",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "jobScopeWhere",
    "type": "JobScopeTypeWhere!"
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "id",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MakeSafeUpdateItemContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "locations",
        "name": "quoteCostLocations",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "QuoteCostLocationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "QuoteCostLocationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "centres",
        "name": "quoteCostCentres",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "QuoteCostCentreConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "QuoteCostCentreEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "jobScopeItems",
        "name": "jobScopeTypes",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": "JobScopeType",
        "plural": true,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MakeSafeUpdateItemContainerQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "locations",
        "name": "quoteCostLocations",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "QuoteCostLocationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "QuoteCostLocationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v10/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "centres",
        "name": "quoteCostCentres",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "QuoteCostCentreConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "QuoteCostCentreEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v10/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "jobScopeItems",
        "name": "jobScopeTypes",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": "JobScopeType",
        "plural": true,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MakeSafeUpdateItemContainerQuery",
    "id": null,
    "text": "query MakeSafeUpdateItemContainerQuery(\n  $locationsWhere: QuoteCostLocationIndex!\n  $centresWhere: QuoteCostCentreIndex!\n  $jobScopeWhere: JobScopeTypeWhere!\n) {\n  locations: quoteCostLocations(where: $locationsWhere) {\n    edges {\n      node {\n        label: locationName\n        value: costLocationId\n        id\n      }\n    }\n  }\n  centres: quoteCostCentres(where: $centresWhere) {\n    edges {\n      node {\n        label: costCentreName\n        value: costCentreId\n        id\n      }\n    }\n  }\n  jobScopeItems: jobScopeTypes(where: $jobScopeWhere) {\n    label: name\n    value: id\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '48f951feb0ebd829f22b5fe3ea27618e';
module.exports = node;
