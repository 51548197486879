import React from "react";
import classNames from "clsx";
import Grid from "components/Mui/Grid";
import IconButton from "components/Mui/IconButton";

const QuotesDetail = ({ row, meta, classes, displayValidationValue }) => {
  if (!row.setExpand && row.expand === false) return null;
  if (row.isDisplay === false) return null;
  if (row.isScopeType === false) return null;
  // if(row.isScopeType === true && row.isProcessed === true && meta.claim.view.actions.updateScopeType.isDisplay === true)
  //   return <Grid item xs={12} className={classes.scopeType}>
  //             <IconButton
  //               // icon={row.expand ? 'expand_less' : 'expand_more' }
  //               size="small"
  //               className={classes.expandBtn}
  //               // onClick={() => row.setExpand(!row.expand)}
  //             />
  //             <p>{row.scopeType}</p>
  //           </Grid>
  if (row.isScopeType === true) {
    //if(meta.claim.view.actions.updateScopeType.isDisplay !== true) return null
    return (
      <Grid item xs={12} className={classes.scopeType}>
        <p>{row.scopeType}</p>
      </Grid>
    );
  }
  if (row.info === true) {
    if (row.unMount === true) return null;
    return (
      <Grid item xs={12}>
        <p style={{ fontWeight: "bold" }}>{row.name}</p>
      </Grid>
    );
  }
  if (row.name === null)
    return <Grid item xs={12} className={classes.break}></Grid>;
  if (row.unMount === true) return null;
  return (
    <Grid item xs={12} className={classes.row}>
      <Grid fluid spacing={0} style={{ flexWrap: "noWrap" }}>
        <Grid
          item
          xs={2}
          className={classNames(
            { [classes.item]: row.item },
            { [classes.itemTotal]: row.itemTotal },
            { [classes.sumary]: row.sumary }
          )}
        >
          {row.name}
        </Grid>
        {meta.claim.insurer.isSirModule && (
          <Grid
            item
            xs={1}
            className={classNames(
              classes.content,
              classes.sir,
              { [classes.supplier]: row.supplier },
              { [classes.status]: row.status }
            )}
          >
            {row.sir}
          </Grid>
        )}

        {/* this is Validation bodys */}
        {displayValidationValue.isDisplay && (
          <Grid
            item
            xs={1}
            className={classNames(
              classes.content,
              classes.sir,
              { [classes.supplier]: row.supplier },
              { [classes.status]: row.status }
            )}
          >
            {row.variation}
          </Grid>
        )}

        <Grid
          item
          xs={1}
          className={classNames(
            classes.content,
            { [classes.supplier]: row.supplier },
            { [classes.status]: row.status }
          )}
        >
          {row.scoping}
        </Grid>
        {row.quoting.map((quote, index) => (
          <Grid
            item
            xs={1}
            key={index}
            className={classNames(
              classes.content,
              { [classes.supplier]: row.supplier },
              { [classes.status]: row.status }
            )}
          >
            {quote === "-" ? null : quote ? quote : "-"}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default QuotesDetail;
