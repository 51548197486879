/**
 * @flow
 * @relayHash 03b3e207a3c04e882dc4b4ce718d6435
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JobNotesList$ref = any;
export type ClaimJobStatus = "VariationApproved" | "VariationRejected" | "VariationReset" | "VariationSubmitted" | "%future added value";
export type JobRecommendationStatus = "NotRecommended" | "Recommended" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuoteRequestStatus = "AdditionalItems" | "AppointmentMade" | "AwaitingInfo" | "AwaitingInfoContents" | "ClaimFinalised" | "InitialCallMade" | "JobAuthorised" | "JobLost" | "NewClaims" | "OfferScopeAcc" | "QuoteSubmitted" | "WaitingQuotes" | "WaitingResult" | "WaitingScope" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type ClaimItemFilter = {|
  id?: ?number,
  claimId: string,
  keyWords?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type ClaimJobFilter = {|
  id?: ?string,
  parentId?: ?string,
  subject?: ?string,
  createDateFrom?: ?any,
  createDateTo?: ?any,
  incidentDate?: ?any,
  incidentDateFrom?: ?any,
  incidentDateTo?: ?any,
  keyWords?: ?string,
  refNumber?: ?string,
  postcode?: ?string,
  managerTeams?: ?$ReadOnlyArray<?string>,
  managers?: ?$ReadOnlyArray<string>,
  insurers?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  agents?: ?$ReadOnlyArray<string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  buildingSuppliers?: ?$ReadOnlyArray<string>,
  contentsSuppliers?: ?$ReadOnlyArray<string>,
  restorationSuppliers?: ?$ReadOnlyArray<string>,
  buildingStatus?: ?$ReadOnlyArray<string>,
  contentsStatus?: ?$ReadOnlyArray<string>,
  restorationStatus?: ?$ReadOnlyArray<string>,
  lossAdjusterStatus?: ?$ReadOnlyArray<string>,
  suppliers?: ?$ReadOnlyArray<string>,
  status?: ?$ReadOnlyArray<string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  jobStatuses?: ?$ReadOnlyArray<string>,
  claimStatusStageIds?: ?$ReadOnlyArray<string>,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  states?: ?$ReadOnlyArray<?StateEnum>,
  variationStatuses?: ?$ReadOnlyArray<?ClaimJobStatus>,
  requestStatuses?: ?$ReadOnlyArray<?QuoteRequestStatus>,
  recommendationStatus?: ?$ReadOnlyArray<JobRecommendationStatus>,
  or?: ?ClaimJobFilter,
  and?: ?ClaimJobFilter,
  not?: ?ClaimJobFilter,
|};
export type ENDataEntityKey = {|
  id: string
|};
export type JobNotesPageQueryVariables = {|
  first?: ?number,
  after?: ?string,
  where?: ?ClaimItemFilter,
  claimJobWhere?: ?ClaimJobFilter,
  claimWhere: ENDataEntityKey,
|};
export type JobNotesPageQueryResponse = {|
  +claimJob: ?{|
    +view: ?{|
      +actions: {|
        +awaitingInfo: {|
          +label: string,
          +isDisplay: boolean,
          +isDisabled: boolean,
        |}
      |}
    |}
  |},
  +claimJobs: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +building: ?{|
          +progress: ?{|
            +initialCallMade: boolean,
            +appointmentBooked: boolean,
          |},
          +claimStatus: ?{|
            +statusId: number
          |},
          +scopingSupplier: ?{|
            +companyId: number
          |},
        |},
        +restoration: ?{|
          +progress: ?{|
            +initialCallMade: boolean,
            +appointmentBooked: boolean,
          |},
          +claimStatus: ?{|
            +statusId: number
          |},
          +scopingSupplier: ?{|
            +companyId: number
          |},
        |},
      |}
    |}>
  |},
  +$fragmentRefs: JobNotesList$ref,
|};
export type JobNotesPageQuery = {|
  variables: JobNotesPageQueryVariables,
  response: JobNotesPageQueryResponse,
|};
*/


/*
query JobNotesPageQuery(
  $first: Int
  $after: String
  $where: ClaimItemFilter
  $claimJobWhere: ClaimJobFilter
  $claimWhere: ENDataEntityKey!
) {
  ...JobNotesList
  claimJob(where: $claimWhere) {
    view {
      actions {
        awaitingInfo {
          label
          isDisplay
          isDisabled
          id
        }
      }
    }
    id
  }
  claimJobs(where: $claimJobWhere) {
    edges {
      node {
        building {
          progress {
            initialCallMade
            appointmentBooked
          }
          claimStatus {
            statusId
            id
          }
          scopingSupplier {
            companyId
            id
          }
          id
        }
        restoration {
          progress {
            initialCallMade
            appointmentBooked
          }
          claimStatus {
            statusId
            id
          }
          scopingSupplier {
            companyId
            id
          }
          id
        }
        id
      }
    }
  }
}

fragment JobNotesList on Query {
  claimNotes(first: $first, after: $after, where: $where) {
    edges {
      node {
        portfolioType
        ...JobNotesListItem_item
        id
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}

fragment JobNotesListItem_item on ClaimNote {
  portfolioType
  logDate
  private
  message
  user {
    userName
    company {
      companyName
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimItemFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "claimJobWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "claimWhere",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "claimWhere",
    "type": "ENDataEntityKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisabled",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "claimJobWhere",
    "type": "ClaimJobFilter"
  }
],
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "progress",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimProgress",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "initialCallMade",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "appointmentBooked",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyId",
  "args": null,
  "storageKey": null
},
v9 = [
  (v6/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimStatus",
    "storageKey": null,
    "args": null,
    "concreteType": "ClaimStatus",
    "plural": false,
    "selections": [
      (v7/*: any*/)
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "scopingSupplier",
    "storageKey": null,
    "args": null,
    "concreteType": "Company",
    "plural": false,
    "selections": [
      (v8/*: any*/)
    ]
  }
],
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ClaimItemFilter"
  }
],
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v12 = [
  (v6/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimStatus",
    "storageKey": null,
    "args": null,
    "concreteType": "ClaimStatus",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v11/*: any*/)
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "scopingSupplier",
    "storageKey": null,
    "args": null,
    "concreteType": "Company",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      (v11/*: any*/)
    ]
  },
  (v11/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "JobNotesPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "JobNotesList",
        "args": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "view",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJobView",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "awaitingInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJobs",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": "ClaimJobConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJobEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJob",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "building",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimPortfolio",
                    "plural": false,
                    "selections": (v9/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "restoration",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimPortfolio",
                    "plural": false,
                    "selections": (v9/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "JobNotesPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimNotes",
        "storageKey": null,
        "args": (v10/*: any*/),
        "concreteType": "ClaimNoteConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimNoteEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimNote",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "portfolioType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "logDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "private",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "message",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "userName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "company",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Company",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "companyName",
                            "args": null,
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v11/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "claimNotes",
        "args": (v10/*: any*/),
        "handle": "connection",
        "key": "JobNotesList_claimNotes",
        "filters": []
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "view",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJobView",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "awaitingInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v11/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v11/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJobs",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": "ClaimJobConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJobEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJob",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "building",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimPortfolio",
                    "plural": false,
                    "selections": (v12/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "restoration",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimPortfolio",
                    "plural": false,
                    "selections": (v12/*: any*/)
                  },
                  (v11/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "JobNotesPageQuery",
    "id": null,
    "text": "query JobNotesPageQuery(\n  $first: Int\n  $after: String\n  $where: ClaimItemFilter\n  $claimJobWhere: ClaimJobFilter\n  $claimWhere: ENDataEntityKey!\n) {\n  ...JobNotesList\n  claimJob(where: $claimWhere) {\n    view {\n      actions {\n        awaitingInfo {\n          label\n          isDisplay\n          isDisabled\n          id\n        }\n      }\n    }\n    id\n  }\n  claimJobs(where: $claimJobWhere) {\n    edges {\n      node {\n        building {\n          progress {\n            initialCallMade\n            appointmentBooked\n          }\n          claimStatus {\n            statusId\n            id\n          }\n          scopingSupplier {\n            companyId\n            id\n          }\n          id\n        }\n        restoration {\n          progress {\n            initialCallMade\n            appointmentBooked\n          }\n          claimStatus {\n            statusId\n            id\n          }\n          scopingSupplier {\n            companyId\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment JobNotesList on Query {\n  claimNotes(first: $first, after: $after, where: $where) {\n    edges {\n      node {\n        portfolioType\n        ...JobNotesListItem_item\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment JobNotesListItem_item on ClaimNote {\n  portfolioType\n  logDate\n  private\n  message\n  user {\n    userName\n    company {\n      companyName\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '939d47fe493e34a4b79b9db0010e0635';
module.exports = node;
