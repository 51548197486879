import React from 'react'
import {
  TextField,
  Button,
  MenuItem,
} from '@material-ui/core'
import Dialog from 'components/Mui/Dialog'
import Grid from 'components/Mui/Grid'
import RequestCostedScopeMutation from './RequestCostedScopeMutation'

const RequestCostedScopeWithOptions = props => {
  const [value, setValue] = React.useState('')
  const [comment, setComment] = React.useState('')
  const [istouched, setIsTouched] = React.useState(false)
  if (!props.options) return null
  return (
      <Dialog 
        noForm
        noActions
        title="Request Costed Scope" 
        symbol='__requestCostedScope'
        paperProps={{style: { maxWidth: '65%', minWidth:'20%' }}}
        content={
          <Grid container justify="flex-end" >
            <Grid item xs={12}>
              <TextField
              select
              label="Scope options"
              value={value}
              variant="outlined"
              onChange={e => setValue(e.target.value)}
              fullWidth
              error={istouched && !value}
              helperText={istouched && !value ? 'Required!' : null}
            >
              {props.options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </Grid>
            <Grid item xs={12} >
              <TextField
              label="Additional Comments"
              required
              multiline
              rowsMax={4}
              value={comment}
              onChange={(e)=>setComment(e.target.value)}
              variant="outlined"
              fullWidth
              error={istouched && !comment}
              helperText={istouched && !comment ? 'Required!' : null}
            />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={2}>
              <Button label="Submit" fullWidth color="primary" variant="contained" onClick={() => {
                if (!value || !comment) {
                  setIsTouched(true)
                  return null
                }
                value && handleSubmit(props, value, comment)
                setIsTouched(false)
              }} >Submit</Button>
            </Grid>
          </Grid> 
        } /> 
  )
}
const handleSubmit = async (props, value, comment) => {
  const { meta, portfolios, claim } = props
  const snackbar = window[Symbol.for('__snackbar')]
  const actions = window[Symbol.for('__requestCostedScope')]
  const mutationRes = await RequestCostedScopeMutation({
      where: {
        id: meta.claim.claimId,
        portfolioType: portfolios
      },
      input:{
        costScopeOptionId: value,
        costScopeAdditionalText: comment
      }
    }, claim.id, meta.claim.claimId, props.setIsdisplay)
    if(mutationRes.success) {
      snackbar.toggleOpen({
        message: mutationRes.messages[0]
      })
      props.forceUpdate()
    }
  actions.handleClose()
}

export default RequestCostedScopeWithOptions
