import React, { Component } from 'react'
import { graphql, createRefetchContainer } from 'react-relay'
import { fetchQuery } from "relay-runtime"
import environment from "common/relay"
import { Link } from 'react-router-dom'

import Button from 'components/Mui/Button'
import Menu from '@material-ui/core/Menu'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from 'components/Mui/IconButton'


const query = graphql`
  query CommunicationButtonFragmentQuery(
    $where: ENDataEntityKey!
  ) {
    claim: claimJob(where: $where) {
      view {
        tabs {
          historyTab {
            isDisplay
          }
        }
      }
    }
  }
`
class CommunicationButtonFragment extends Component {
  state = {

  }

  componentDidMount = () => {
    window[Symbol.for('__refreshCommunication')] = {
      refresh: () => {
        this.props.relay.refetch(
          {},  // Our refetchQuery needs to know the `itemID`
          null,  // We can use the refetchVariables as renderVariables
          null,
          {force: true},  // Assuming we've configured a network layer cache, we want to ensure we fetch the latest data.
        )
      }
    }
  }

  componentWillUnmount = () => {
    delete window[Symbol.for('__refreshCommunication')]
  }

  render() {
    const { classes, icon } = this.props
    const { claimCommuications } = this.props.communications
    const { totalCount } = claimCommuications
    const { anchorEl } = this.props
    return (
      <>
        <IconButton
          icon={icon}
          className={classes.iconButton}
          iconClass={classes.icon}
          onClick={this.props.handleClick}
          badgeContent={totalCount} badgeMax={99} badgeColor='error'
        />

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.props.handleClose}
          disableAutoFocusItem={true}
          className={classes.menu}
        >
          <List
            component='nav'
            className={classes.list}
            subheader={<ListSubheader className={classes.subheader} component='div'>Communication Tasks</ListSubheader>}
          >
          { claimCommuications && claimCommuications.edges.map((edge, key) => (
              <ListItem key={key} button onClick={() => {
                fetchQuery(environment, query, {
                  where: {
                    id: parseInt(edge.node.claimId),
                  },
                }).then(data => {
                  this.props.updateId(edge.node.claimId, data.claim.view.tabs.historyTab.isDisplay)
                });
              }}>
                <ListItemText secondaryTypographyProps={{noWrap: true}} primary={edge.node.claimRef} secondary={edge.node.message} />
              </ListItem>
            )
          )}
            <ListItem className={classes.total}>
              <ListItemText primary={`Total: ${totalCount}`}/>
              <Button component={Link} to='/communication'
                variant='outlined' color='primary'
                onClick={this.props.handleClose}
                label='See more...'
              />
            </ListItem>
          </List>
        </Menu>
      </>
    )
  }
}

export default createRefetchContainer(CommunicationButtonFragment, graphql`
  fragment CommunicationButtonFragment_communications on Query {
    claimCommuications (
      where: {
        acknowledged: false
        boxes: Inbox
      }
        first: 2
    ) {
      totalCount
      edges {
        node {
          message
          claimId
          claimRef
        }
      }
    }
  }
`,
  graphql`
    query CommunicationButtonFragmentRefetchQuery {
      ...CommunicationButtonFragment_communications
    }
  `
)
