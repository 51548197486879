/**
 * @flow
 * @relayHash 169da3472eba495153763c5000fd3226
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SpecialistCategoryWhere = {|
  claimId: string,
  specialistId?: ?number,
|};
export type SpecialistRequestAdd_CategoryQueryVariables = {|
  where: SpecialistCategoryWhere
|};
export type SpecialistRequestAdd_CategoryQueryResponse = {|
  +options: ?$ReadOnlyArray<?{|
    +label: string,
    +value: number,
  |}>
|};
export type SpecialistRequestAdd_CategoryQuery = {|
  variables: SpecialistRequestAdd_CategoryQueryVariables,
  response: SpecialistRequestAdd_CategoryQueryResponse,
|};
*/


/*
query SpecialistRequestAdd_CategoryQuery(
  $where: SpecialistCategoryWhere!
) {
  options: specialistCategories(where: $where) {
    label: name
    value: specialistCategoryId
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "SpecialistCategoryWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "SpecialistCategoryWhere!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "specialistCategoryId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SpecialistRequestAdd_CategoryQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "options",
        "name": "specialistCategories",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SpecialistCategory",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SpecialistRequestAdd_CategoryQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "options",
        "name": "specialistCategories",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SpecialistCategory",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SpecialistRequestAdd_CategoryQuery",
    "id": null,
    "text": "query SpecialistRequestAdd_CategoryQuery(\n  $where: SpecialistCategoryWhere!\n) {\n  options: specialistCategories(where: $where) {\n    label: name\n    value: specialistCategoryId\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12e5973a3fe230bf854dd9cc1dfe3931';
module.exports = node;
