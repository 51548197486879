/**
 * @flow
 * @relayHash 1ef3408484b49c2f42997d4e733d62a2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeeInvoiceStatusWhere = {|
  claimId: string
|};
export type FeeInvoiceUpdateDialogQueryVariables = {|
  where: FeeInvoiceStatusWhere
|};
export type FeeInvoiceUpdateDialogQueryResponse = {|
  +feeInvoiceStatus: ?$ReadOnlyArray<?{|
    +label: string,
    +value: number,
  |}>
|};
export type FeeInvoiceUpdateDialogQuery = {|
  variables: FeeInvoiceUpdateDialogQueryVariables,
  response: FeeInvoiceUpdateDialogQueryResponse,
|};
*/


/*
query FeeInvoiceUpdateDialogQuery(
  $where: FeeInvoiceStatusWhere!
) {
  feeInvoiceStatus(where: $where) {
    label: name
    value: invoiceStatusId
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "FeeInvoiceStatusWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "FeeInvoiceStatusWhere!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "invoiceStatusId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeInvoiceUpdateDialogQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feeInvoiceStatus",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FeeInvoiceStatus",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeInvoiceUpdateDialogQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feeInvoiceStatus",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FeeInvoiceStatus",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FeeInvoiceUpdateDialogQuery",
    "id": null,
    "text": "query FeeInvoiceUpdateDialogQuery(\n  $where: FeeInvoiceStatusWhere!\n) {\n  feeInvoiceStatus(where: $where) {\n    label: name\n    value: invoiceStatusId\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4dbaf21979027c22da930b66874d72cd';
module.exports = node;
