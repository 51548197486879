/* eslint-disable react/prop-types, react/jsx-handler-names */
import React from "react";
import { Field } from "formik";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

import {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  // Placeholder,
  SingleValue,
  ValueContainer,
  MenuList,
} from "./reactSelectComponents";

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  // Placeholder,
  SingleValue,
  ValueContainer,
  MenuList,
};

const FieldWrapper = (props) => {
  return <Field component={IntegrationReactSelect} {...props} />;
};

const IntegrationReactSelect = (props) => {
  // const [ anchorEl, setAnchorEl ] = React.useState(null)
  const { classes, theme } = props;
  const { field, form } = props;

  const handleChangeSingle = (value) => {
    form.setFieldValue(field.name, value ? value.value : null);
  };

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit",
      },
    }),
  };

  const value =
    props.options &&
    props.options.find((e) => String(e.value) === String(field.value));

  return (
    <div className={classes.root}>
      <Select
        id={`react-select-${field.name}`}
        isClearable={props.isClearable}
        isDisabled={props.disabled || props.readOnly}
        classes={classes}
        // menuIsOpen={true}
        styles={selectStyles}
        options={props.options}
        components={components}
        value={value ? value : null}
        onChange={handleChangeSingle}
        onBlur={() => form.setTouched({ ...form.touched, [field.name]: true })}
        placeholder={""}
        isContentTable={props.isContentTable}
        // readOnly={props.readOnly}
        textFieldProps={{
          label: props.label,
          disabled: props.disabled,
          required: props.required,
          error: Boolean(form.errors[field.name]),
          helperText: form.errors[field.name],
          // anchor: [ anchorEl, setAnchorEl ]
        }}
        field={field}
      />
    </div>
  );
};

export default withStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
    },
    input: {
      display: "flex",
      padding: "9.595px 14px",
    },
    valueContainer: {
      display: "flex",
      flexWrap: "wrap",
      flex: 1,
      alignItems: "center",
      overflow: "hidden",
    },
    chip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: "1rem",
      maxWidth: "95%",
      ...theme.mixins.truncate(theme),
    },
    placeholder: {
      position: "absolute",
      left: 16,
      fontSize: "1rem",
    },
    menu: {
      position: "absolute",
      zIndex: 1400,
      // marginTop: theme.spacing.unit,
      top: 64 /*  */,
      // left: 0,
      // right: 0,
      overflow: "auto",
      maxWidth: 300,
      maxHeight: 600,
    },
    // menuList: {
    //   overflowX: 'auto'
    // }
    // divider: {
    //   height: theme.spacing.unit * 2,
    // },
  }),
  { withTheme: true }
)(FieldWrapper);
