/**
 * @flow
 * @relayHash 797cd66aef5120374c1a0a5c9b4bc779
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type ENDataEntityKey = {|
  id: string
|};
export type StatusHistoryQueryVariables = {|
  first: number,
  after?: ?string,
  where: ENDataPortfolioKey,
  claimJobWhere: ENDataEntityKey,
|};
export type StatusHistoryQueryResponse = {|
  +subStatusOption: ?$ReadOnlyArray<?{|
    +value: string,
    +label: ?string,
  |}>,
  +claimJob: ?{|
    +lastSubStatus: ?{|
      +id: string,
      +name: ?string,
      +subStatusId: string,
    |}
  |},
  +jobWorkProgresses: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +logdate: ?any,
        +statusdate: ?any,
        +datepopup: ?boolean,
        +completed: boolean,
        +status: ?{|
          +statusId: number,
          +statusName: string,
          +datePopup: boolean,
        |},
      |}
    |}>
  |},
|};
export type StatusHistoryQuery = {|
  variables: StatusHistoryQueryVariables,
  response: StatusHistoryQueryResponse,
|};
*/


/*
query StatusHistoryQuery(
  $first: Int!
  $after: String
  $where: ENDataPortfolioKey!
  $claimJobWhere: ENDataEntityKey!
) {
  subStatusOption: subStatus {
    value: id
    label: name
    id
  }
  claimJob(where: $claimJobWhere) {
    lastSubStatus {
      id
      name
      subStatusId
    }
    id
  }
  jobWorkProgresses(first: $first, after: $after, where: $where) {
    edges {
      node {
        id
        logdate
        statusdate
        datepopup
        completed
        status {
          statusId
          statusName
          datePopup
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "claimJobWhere",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "claimJobWhere",
    "type": "ENDataEntityKey!"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "lastSubStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimSubStatus",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subStatusId",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logdate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusdate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "datepopup",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completed",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusId",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusName",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "datePopup",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v16 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StatusHistoryQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "subStatusOption",
        "name": "subStatus",
        "storageKey": null,
        "args": null,
        "concreteType": "SubStatus",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v3/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "jobWorkProgresses",
        "name": "__StatusHistory_jobWorkProgresses_connection",
        "storageKey": null,
        "args": null,
        "concreteType": "JobProgressConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobProgressEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobProgress",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "status",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimJobStatusInfo",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ]
                  },
                  (v13/*: any*/)
                ]
              },
              (v14/*: any*/)
            ]
          },
          (v15/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StatusHistoryQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "subStatusOption",
        "name": "subStatus",
        "storageKey": null,
        "args": null,
        "concreteType": "SubStatus",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v3/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v4/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobWorkProgresses",
        "storageKey": null,
        "args": (v16/*: any*/),
        "concreteType": "JobProgressConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobProgressEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobProgress",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "status",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimJobStatusInfo",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v4/*: any*/)
                    ]
                  },
                  (v13/*: any*/)
                ]
              },
              (v14/*: any*/)
            ]
          },
          (v15/*: any*/)
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "jobWorkProgresses",
        "args": (v16/*: any*/),
        "handle": "connection",
        "key": "StatusHistory_jobWorkProgresses",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "StatusHistoryQuery",
    "id": null,
    "text": "query StatusHistoryQuery(\n  $first: Int!\n  $after: String\n  $where: ENDataPortfolioKey!\n  $claimJobWhere: ENDataEntityKey!\n) {\n  subStatusOption: subStatus {\n    value: id\n    label: name\n    id\n  }\n  claimJob(where: $claimJobWhere) {\n    lastSubStatus {\n      id\n      name\n      subStatusId\n    }\n    id\n  }\n  jobWorkProgresses(first: $first, after: $after, where: $where) {\n    edges {\n      node {\n        id\n        logdate\n        statusdate\n        datepopup\n        completed\n        status {\n          statusId\n          statusName\n          datePopup\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "jobWorkProgresses"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '63b507362c91d02dd8ff503dbb7e59f8';
module.exports = node;
