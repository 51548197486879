import React from "react";
import Paper from "components/Mui/Paper";
import Grid from "components/Mui/Grid";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import Form from "components/Mui/Form";
import MultipleSelect from "components/MultipleSelect";
import { withStyles } from "@material-ui/core/styles";
import Button from "components/Mui/Button";
import { TextField, InputAdornment, Icon } from "@material-ui/core";
import DateInput from "components/Mui/DateInput";
import GetScoreCardPdfMutation from "./GetScoreCardPdfMutation";
import moment from "moment";
import PrintIcon from "@material-ui/icons/Print";
import transformFilterGroup from "common/utils/transformFilterGroup";
import IconButton from "components/Mui/IconButton";
import Collapse from "@material-ui/core/Collapse";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircle from "@material-ui/icons/AccountCircle";

const Filters = ({ classes, where, setWhere }) => {
  const query = graphql`
    query FiltersQuery {
      currentUser {
        claimScoreBoardFilters {
          name
          type
          label
          options {
            value: id
            label
            group
          }
        }
      }
      claimScoreBoards {
        value: id
        title
      }
    }
  `;
  const [dateFrom, setDateFrom] = React.useState(moment().startOf("year"));
  const [dateTo, setDateTo] = React.useState(moment());
  const [optionsWhere, setOptionsWhere] = React.useState(null);
  const [dateWhere, setDateWhere] = React.useState({});
  const [filterMore, setFilterMore] = React.useState(false);
  const [scoreBoardType, setScoreBoardType] = React.useState();
  const handleChange = (data) => {
    setOptionsWhere(transformFilterGroup(data));
  };

  const stringOptionsWhere = JSON.stringify(optionsWhere);
  const stringDateWhere = JSON.stringify(dateWhere);
  React.useEffect(() => {
    setWhere({
      ...where,
      ...optionsWhere,
      ...dateWhere,
      createDateFrom: dateFrom.format("YYYY/MM/DD"),
      createDateTo: dateTo.format("YYYY/MM/DD"),
      scoreBoardId: scoreBoardType,
    });
  }, [dateFrom, dateTo, stringOptionsWhere, stringDateWhere, scoreBoardType]);
  const firstRowFilters = 5;
  return (
    <div
      className={classes.root}
      style={{ paddingLeft: "0px", paddingRight: "0px" }}
    >
      <Form onChange={handleChange}>
        <Grid fluid>
          <Grid xs>
            <Paper>
              <Grid fluid>
                <QueryRenderer
                  environment={environment}
                  query={query}
                  render={({ error, props }) => {
                    if (error) {
                      return "Error!!";
                    }
                    if (!props) return <div>Loading...</div>;
                    return (
                      <div style={{ display: "flex", width: "100%" }}>
                        <Grid
                          fluid
                          spacing={8}
                          style={{ paddingLeft: "20px", paddingRight: "20px" }}
                        >
                          <Grid item xs={2}>
                            <SelectScoreCardBoard
                              option={props}
                              boardType={scoreBoardType}
                              setBoardType={setScoreBoardType}
                              // setDateFrom={setDateFrom}
                              // setDateTo={setDateTo}
                              // setOptionsWhere={setOptionsWhere}
                              // optionsWhere={optionsWhere}
                              // setDateWhere={setDateWhere}
                              // dateWhere={dateWhere}
                              // setFilterMore={setFilterMore}
                              // setWhere={setWhere}
                              // where={where}
                            />
                          </Grid>
                          <DateField
                            label="Date from"
                            date={dateFrom}
                            setDate={setDateFrom}
                            maxDate={dateTo}
                          />
                          <DateField
                            label="Date to"
                            date={dateTo}
                            setDate={setDateTo}
                            minDate={dateFrom}
                          />
                          {props.currentUser &&
                            props.currentUser.claimScoreBoardFilters &&
                            props.currentUser.claimScoreBoardFilters
                              .slice(0, firstRowFilters)
                              .map((filter) => {
                                if (filter.type === "Date")
                                  if (
                                    filter.name !== "createDateFrom" &&
                                    filter.name !== "createDateTo"
                                  )
                                    return (
                                      <Grid item xs={2}>
                                        <CommonDateField
                                          label={filter.label}
                                          name={filter.name}
                                          dateWhere={dateWhere}
                                          date={
                                            Object.keys(dateWhere).length
                                              ? dateWhere[filter.name]
                                              : null
                                          }
                                          setDateWhere={setDateWhere}
                                        />
                                      </Grid>
                                    );
                                if (filter.type === "ComboBox_multiple")
                                  return (
                                    <Grid item xs={2}>
                                      {filter.type === "ComboBox_multiple" && (
                                        <MultiSelectFilter
                                          label={filter.label}
                                          options={filter.options ? filter.options : []}
                                          name={filter.name}
                                          filtered
                                          outline
                                        />
                                      )}
                                    </Grid>
                                  );
                              })}
                          {filterMore ? (
                            props.currentUser &&
                            props.currentUser.claimScoreBoardFilters &&
                            props.currentUser.claimScoreBoardFilters
                              .slice(firstRowFilters)
                              .map((filter) => {
                                if (filter.type === "Date")
                                  if (
                                    filter.name !== "createDateFrom" &&
                                    filter.name !== "createDateTo"
                                  )
                                    return (
                                      <Grid item xs={2}>
                                        <CommonDateField
                                          label={filter.label}
                                          name={filter.name}
                                          dateWhere={dateWhere}
                                          date={
                                            Object.keys(dateWhere).length
                                              ? dateWhere[filter.name]
                                              : null
                                          }
                                          setDateWhere={setDateWhere}
                                        />
                                      </Grid>
                                    );
                                if (filter.type === "ComboBox_multiple")
                                  return (
                                    <Grid item xs={2}>
                                      {filter.type === "ComboBox_multiple" && (
                                        <MultiSelectFilter
                                          label={filter.label}
                                          options={filter.options}
                                          name={filter.name}
                                          filtered
                                          outline
                                        />
                                      )}
                                    </Grid>
                                  );
                              })
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <IconButton
                          style={{
                            display: "flex",
                            height: "30px",
                            padding: "0px",
                            marginTop: "20px",
                          }}
                          className={classes.actionButton}
                          icon={filterMore ? "expand_less" : "expand_more"}
                          onClick={() => setFilterMore((pre) => !pre)}
                        />
                      </div>
                    );
                  }}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default withStyles({
  root: {
    width: "100%",
    padding: "10px",
    position: "sticky",
    zIndex: "999",
    transform: "scale(1,0.95)",
    //zoom: "0.91",
    top: "55px",
  },
  "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
    root: {
      width: "110%",
    },
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
})(Filters);

const MultiSelectFilter = (props) => {
  return (
    <MultipleSelect
      filtered
      outline
      leadIcon="assignment_ind"
      label={props.label}
      id={props.name}
      fullWidth
      refreshFilterField={false}
      options={props.options}
    />
  );
};

const SelectScoreCardBoard = ({
  option,
  boardType,
  setBoardType,
  // dateFrom,
  // setDateFrom,
  // dateTo,
  // setDateTo,
  // optionsWhere,
  // setOptionsWhere,
  // dateWhere,
  // setDateWhere,
  // setFilterMore,
  // where,
  // setWhere,
}) => {
  if (!boardType) setBoardType(option.claimScoreBoards[0].value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBoardType(event.target.value);
    // setDateFrom(moment().startOf("year"));
    // setDateTo(moment());
    // setOptionsWhere(null);
    // setDateWhere({});
    // setFilterMore(false);
  };
  return (
    <TextField
      label="Score Board"
      id="scoreBoardId"
      select
      outline
      leadIcon="assignment_ind"
      value={boardType}
      fullWidth
      variant="outlined"
      onChange={handleChange}
      name="scoreBoardId"
      InputLabelProps={{
        shrink: true,
      }}
    >
      {option.claimScoreBoards.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.title}
        </MenuItem>
      ))}
    </TextField>
  );
};

const DateField = ({ date, setDate, label, minDate, maxDate }) => {
  return (
    <Grid item xs={2}>
      <DateInput
        label={label}
        value={date}
        clearable={false}
        leadIcon={"event_note"}
        onChange={(date) => {
          setDate(date);
        }}
        minDate={minDate ? minDate : undefined}
        maxDate={maxDate ? maxDate : moment()}
      />
    </Grid>
  );
};
const CommonDateField = ({
  dateWhere,
  setDateWhere,
  label,
  name,
  minDate,
  maxDate,
}) => {
  const newDate = {};
  return (
    <DateInput
      label={label}
      value={Object.keys(dateWhere).length ? dateWhere[name] : null}
      clearable={false}
      leadIcon={"event_note"}
      onChange={(date) => {
        newDate[name] = date.format("YYYY/MM/DD");
        setDateWhere({ ...dateWhere, ...newDate });
      }}
      minDate={minDate ? minDate : undefined}
      maxDate={maxDate ? maxDate : moment()}
    />
  );
};
