import React from 'react'

import Paper from 'components/Mui/Paper'
import Grid from 'components/Mui/Grid'

import Input from 'components/Mui/Input'
import InfoBox from 'components/Mui/InfoBox'
import Button from 'components/Mui/Button'

export default ({ title, fields }) => {
  const renderInput = ({ componentType, value, ...props }) => {
    switch(componentType) {
      case 'TEXT_FIELD':
        return <Input {...props} value={value} />
      case 'BUTTON':
        return <Button {...props} />
      default:
        return <InfoBox {...props} text={value} />
    }
  }

  return (
    <Paper title={title}>
      <Grid container>
        {fields && fields.map((field, index) => (
          <Grid xs={field.xs} key={index}>
            {renderInput(field)}
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}