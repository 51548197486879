import React from 'react'
import Grid from 'components/Mui/Grid'
// import Input from 'components/Mui/Input'
import Button from 'components/Mui/Button'

export default props => {
  const themeActions = window[Symbol.for('__themeActions')]
  const vehicleActions = window[Symbol.for('__vehicleActions')]
  return (
    <Grid fluid justify='center' spacing={0}>
      <Grid item xs>
        {['endata', 'sophia', 'amber', 'jade'].map((color, index) => (
          <Button variant='contained' color='primary'
            key={index}
            label={color}
            onClick={e => themeActions.setTheme(color)}
          />    
        ))}
        <Button variant='contained' color='primary'
          label="home"
          onClick={e => vehicleActions.setIsVehicleReport(false)}
          />  
        <Button variant='contained' color='primary'
          label="car"
          onClick={e => vehicleActions.setIsVehicleReport(true)}
        />  
      </Grid>
    </Grid>
  )
}