/**
 * @flow
 * @relayHash 74a3fa6d7ee71961edb979968fadefb0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type RequestCategory = "All" | "AssessorReport" | "Authorised" | "DesktopQuoting" | "Lost" | "ScopeValidation" | "Scoping" | "%future added value";
export type JobQuoteIndex = {|
  id?: ?$ReadOnlyArray<number>,
  claimId?: ?string,
  scopeTypeId?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  requestCategory?: ?RequestCategory,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type QuoteExportToExcelMutationVariables = {|
  where: JobQuoteIndex
|};
export type QuoteExportToExcelMutationResponse = {|
  +csv: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type QuoteExportToExcelMutation = {|
  variables: QuoteExportToExcelMutationVariables,
  response: QuoteExportToExcelMutationResponse,
|};
*/


/*
mutation QuoteExportToExcelMutation(
  $where: JobQuoteIndex!
) {
  csv: csvProduceForJobQuote(where: $where) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JobQuoteIndex!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "csv",
    "name": "csvProduceForJobQuote",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "JobQuoteIndex!"
      }
    ],
    "concreteType": "GeneralPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "QuoteExportToExcelMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "QuoteExportToExcelMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "QuoteExportToExcelMutation",
    "id": null,
    "text": "mutation QuoteExportToExcelMutation(\n  $where: JobQuoteIndex!\n) {\n  csv: csvProduceForJobQuote(where: $where) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '500f6015705b2436db1792a33a6bddec';
module.exports = node;
