import React from 'react'
import { useQuery, graphql, usePagination } from 'relay-hooks'
import { Grid } from '@material-ui/core'
import RoomListRoot from './RoomListRoot'
import { SpinnerRect } from 'components/Mui/Loader'

import Fabs from 'components/Mui/Fabs'
import Fab from 'components/Mui/Fabs/Fab'
import VideoCallInitialDialog from 'components/VideoCallInitialDialog'

import uaParser from 'ua-parser-js'

const query = graphql`
  query MediaSessionQuery($where: RoomWhereInput!) {
    account {
      id
      companyConfigs { videoLogo }
      ...RoomListRoot_room @arguments(where: $where)
    }
  }
`

export default () => {
  const [keyword, setKeyword] = React.useState(null)


  // React.useEffect(() => {
  //   const parser = new uaParser()
  //   const userAgentInfo = parser.getResult()
  //   console.log(userAgentInfo)

  //   fetch('https://extreme-ip-lookup.com/json')
  //     .then(res => res.json())
  //     .then(res => ({
  //       ip: res.query,
  //       lat: res.lat,
  //       long: res.lon,
  //       city: res.city,
  //       region: res.region,
  //       country: res.country,
  //       countryCode: res.countryCode,
  //       continent: res.continent
  //     }))
  //     .then(console.log)
  // }, [])

  const { props, error } = useQuery({
    query,
    variables: {
      where: {
        keyword
      }
    }
  })
  const loading = !props

  // if(!props) return <CircularProgress />
  if(error) return <>Error, please try again</>

  // const rooms = props && props.account && props.account.rooms.edges.filter(e=>e.node).map(e=>e.node)
  // console.log(rooms)
if(!props) return <SpinnerRect />
  return (
    <>
      <Grid container direction='column' style={{ margin: 0, width: '100%', height: 'calc(100vh - 55px)'}}>
          <Grid item xs style={{ height: '100%' }}>
            <RoomListRoot
              loading={loading}
              fragmentData={props.account}
              videoLogo={props && props.account && props.account.companyConfigs && props.account.companyConfigs.videoLogo}
              keyword={keyword}
              setKeyword={setKeyword}
            />
          </Grid>
        </Grid>

      <VideoCallInitialDialog
        symbol={'__initiateVideoCallDialogActionInList'}
      />
      <Fabs
        mainFab={<Fab icon='add' label='NEW' style={{ bottom: 10, right: 20 }} />}
        childFabs={[
          <Fab
            icon='videocam' label='Video Session' backgroundColor='#2B78FE'
            onClick={() => {
              if (window[Symbol.for('__initiateVideoCallDialogActionInList')]) {
                window[Symbol.for('__initiateVideoCallDialogActionInList')].handleOpen()
              }
            }}
          />,
        ]}
      />
    </>
  )
}
