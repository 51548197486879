import React from 'react'
import environment from 'common/relay'
import { graphql, QueryRenderer } from 'react-relay'

import { Formik, Form } from 'formik'
import Input from 'components/Formik/Input'

import Dialog from 'components/Mui/Dialog'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'
import ClaimTypeIcon from 'components/Mui/ClaimTypeIcon'
import AddNewClaimTypeActionMutation from './AddNewClaimTypeActionMutation'

const query = graphql`
  query AddNewClaimTypeActionQuery(
    $buildersWhere: ClaimJobFilter
    $restorersWhere: ClaimJobFilter
  ) {
    Building: claimFilterOptions(where: $buildersWhere){
      label: name
      value
    }
    Restoration: claimFilterOptions(where: $restorersWhere){
      label: name
      value
    }
  }
`

class AddNewClaimTypeAction extends React.Component {
  snackbar = window[Symbol.for('__snackbar')]
  render() {
    const { meta } = this.props
    const insurerId = meta.claim.insurer.companyId
    const postcode = meta.claim.incidentDetail.riskAddress.postcode
    return (
      <Dialog
        title='Add claim type'
        noForm
        symbol={this.props.symbol}
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              buildersWhere: {
                subject: 'suppliers',
                insurers: insurerId,
                portfolios: 'Building', postcode: String(postcode)
              },
              restorersWhere: {
                subject: 'suppliers',
                insurers: insurerId,
                portfolios: 'Restoration', postcode: String(postcode)
              },
            }}
            render={({error, props}) => {
              if(!props) return 'Loading...'
              return (
                <Grid container justify='space-evenly' alignItems='stretch'>
                  {
                    [
                      {unmountOn: meta.claim.hasBuilding, type:'Building', inputLabel: 'Builder'},
                      {unmountOn: meta.claim.hasRestoration, type:'Restoration', inputLabel: 'Restorer'},
                      {unmountOn: meta.claim.hasContents, type:'Contents', inputLabel: 'Restorer'},
                    ].map(({ unmountOn, type, inputLabel }, key) => {
                      const isContents = type === 'Contents'
                      if(unmountOn) return null
                      return (
                        <Grid item xs key={key}>
                          <Grid fluid spacing={8} justify='center' style={{height: '100%'}}>
                            <Grid item xs={12}>
                              <Grid fluid spacing={8} justify='center'>
                                <ClaimTypeIcon
                                  style={{
                                    fontSize: '2.5rem'
                                }} type={type}/>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Formik
                                initialValues={{
                                  scopingSupplierId: ''
                                }}
                                validate={(values) => {
                                  let errors = {}

                                  if(!values.scopingSupplierId && !isContents) errors.scopingSupplierId = 'Required!'

                                  return errors
                                }}
                                onSubmit={(values, actions) => {
                                  setTimeout(async () => {
                                    const variables = {
                                      input: {
                                        ...values,
                                        portfolioType: type,
                                      },
                                      where: { id: meta.claim.claimId },
                                    }
                                    variables.input.scopingSupplierId = parseInt(values.scopingSupplierId)
                                    if(isContents) delete variables.input.scopingSupplierId

                                    const res = await AddNewClaimTypeActionMutation(variables, meta.claim.id)
                                    actions.setSubmitting(false)
                                    if(res.ok) {
                                      this.snackbar.toggleOpen({
                                        message: res.messages[0]
                                      })
                                      if(res.success) {
                                        if(window[Symbol.for('__refreshClaimDetails')]) {
                                          window[Symbol.for('__refreshClaimDetails')]()
                                        }
                                      }
                                    } else {
                                      this.snackbar.toggleOpen({
                                        message: 'System error!'
                                      })
                                    }
                                  }, 400)
                                }}
                              >
                                {({ isSubmitting, values }) => {
                                  return (
                                    <Form>
                                      <Grid fluid spacing={8} justify='center'>
                                        {!isContents && (
                                          <Grid item xs={12}>
                                            <Input
                                              required
                                              name='scopingSupplierId'
                                              label={inputLabel}
                                              options={props[type]}
                                              select
                                              value={values.scopingSupplierId}
                                            />
                                          </Grid>
                                        )}
                                        <Button
                                          fullWidth
                                          variant='contained'
                                          type='submit'
                                          color='primary'
                                          label='Add type'
                                          disabled={isSubmitting}
                                        />
                                      </Grid>
                                    </Form>
                                  )
                                }}
                              </Formik>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              )
            }}
          />
        }
      />
    )
  }
}

export default AddNewClaimTypeAction