/**
 * @flow
 * @relayHash 47ce9f5989aec17de88724e530f14294
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type CompleteScopeValidationMutationVariables = {|
  where: ENDataPortfolioKey
|};
export type CompleteScopeValidationMutationResponse = {|
  +jobScopeValidationComplete: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type CompleteScopeValidationMutation = {|
  variables: CompleteScopeValidationMutationVariables,
  response: CompleteScopeValidationMutationResponse,
|};
*/


/*
mutation CompleteScopeValidationMutation(
  $where: ENDataPortfolioKey!
) {
  jobScopeValidationComplete(where: $where) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "jobScopeValidationComplete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ENDataPortfolioKey!"
      }
    ],
    "concreteType": "JobQuotePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CompleteScopeValidationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CompleteScopeValidationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CompleteScopeValidationMutation",
    "id": null,
    "text": "mutation CompleteScopeValidationMutation(\n  $where: ENDataPortfolioKey!\n) {\n  jobScopeValidationComplete(where: $where) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7d25d23888aa2c161f8426c371efaac9';
module.exports = node;
