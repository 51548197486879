import React, { Component } from "react";
import { QueryRenderer, graphql } from "react-relay";
import environment from "common/relay";
import { withStyles } from "@material-ui/core/styles";
import Paper from "components/Mui/Paper";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";
import { Formik, Form } from "formik";
import DateInput from "components/Formik/DateInput";
import { MenuItem, TextField } from "@material-ui/core";

import StatusHistoryMutation from "./StatusHistoryMutation";
import SubStatusChangeMutation from "./SubStatusChangeMutation";
import EditHistoryDialog from './EditHistoryDialog'

const query = graphql`
  query StatusHistoryQuery(
    $first: Int!
    $after: String
    $where: ENDataPortfolioKey!
    $claimJobWhere: ENDataEntityKey!
  ) {
    subStatusOption: subStatus {
      value: id
      label: name
    }
    claimJob(where: $claimJobWhere) {
      lastSubStatus {
        id
        name
        subStatusId
      }
    }
    jobWorkProgresses(first: $first, after: $after, where: $where)
    @connection(key: "StatusHistory_jobWorkProgresses", filters: []) {
      edges {
        node {
          id
          logdate
          statusdate
          datepopup
          completed
          status {
            statusId
            statusName
            datePopup
          }
        }
      }
    }
  }
`;

class StatusHistory extends Component {
  state = {
    refetch: false,
    subStatusId: "",
  };

  snackbar = window[Symbol.for("__snackbar")];

  render() {
    const { classes, portfolios, meta } = this.props;
    const isAction =
      (portfolios === "Building" && meta.user.type.isBuilder) ||
      (portfolios === "Restoration" && meta.user.type.isRestorer);
    const subStatusAction = meta.claim.view.actions.changeSubStatus;
    const handleCardRefresh = () => {
      this.setState({ refetch: !this.state.refetch })
    }
    return (
      <Paper
        title="Job Status History"
        className={classes.paper}
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              first:500,
              claimJobWhere: {
                id: meta.claim.claimId,
              },
              where: {
                id: meta.claim.claimId,
                portfolioType: portfolios,
              },
              refetch: this.state.refetch,
            }}
            render={({ errors, props }) => {
              if (!props) return "Loading...";
              if (props.jobWorkProgresses.edges.length === 0) return "Nothing found";
              const lastOne = props.jobWorkProgresses.edges
                .filter(({ node }) => node.completed === false)
                .map((x) => x.node)[0];
              const subStatusOptions = props.subStatusOption;
              const subStatusName =
                subStatusOptions.filter(
                  (e) => e.value === this.state.subStatusId
                )[0] &&
                subStatusOptions.filter(
                  (e) => e.value === this.state.subStatusId
                )[0].label
                  ? subStatusOptions.filter(
                      (e) => e.value === this.state.subStatusId
                    )[0].label
                  : "";
              return (
                <Grid fluid spacing={0}>
                  {subStatusAction.isDisplay && (
                    <Grid
                      item
                      xs={12}
                      className={`${classes.listItem} ${classes.lastSubStatus}`}
                    >
                      <Grid fluid spacing={0} alignItems="center">
                        <Grid item xs>{`Sub Status: ${
                          props.claimJob.lastSubStatus
                            ? props.claimJob.lastSubStatus.name
                            : subStatusName
                        }`}</Grid>
                        <Grid item xs>
                          <TextField
                            select
                            label={subStatusAction.label}
                            disabled={subStatusAction.isDisabled}
                            value={
                              this.state.subStatusId
                                ? this.state.subStatusId
                                : props.claimJob.lastSubStatus &&
                                  props.claimJob.lastSubStatus.subStatusId
                                ? props.claimJob.lastSubStatus.subStatusId
                                : ""
                            }
                            variant="outlined"
                            fullWidth
                            option
                            onChange={(e) =>
                              this.setState(
                                { subStatusId: e.target.value },
                                () =>
                                  SubStatusChangeMutation({
                                    where: { claimId: meta.claim.claimId },
                                    input: {
                                      subStatusId: this.state.subStatusId,
                                    },
                                  })
                              )
                            }
                          >
                            {subStatusOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {isAction && lastOne && (
                    <Grid item xs={12} className={classes.listItem}>
                      <Formik
                        initialValues={{
                          claimJobId: meta.claim.claimId,
                          portfolioType: portfolios,
                          statusDate: "",
                        }}
                        validate={(values) => {
                          let errors = {};
                          if (!values.statusDate && lastOne.status.datePopup)
                            errors.statusDate = "Required!";

                          return errors;
                        }}
                        onSubmit={({ statusDate, ...rest }, actions) => {
                          setTimeout(async () => {
                            const res = await StatusHistoryMutation({
                              input: {
                                ...rest,
                                statusDate: statusDate
                                  ? statusDate.format("YYYY-MM-DDTHH:mm:ss")
                                  : undefined,
                              },
                            });

                            if (res.ok) {
                              handleCardRefresh()
                              this.snackbar.toggleOpen({
                                message: res.message,
                              });
                            } else {
                              actions.setSubmitting(false);
                            }
                          }, 400);
                        }}
                      >
                        {({ isSubmitting, values }) => (
                          <Form>
                            <Grid fluid spacing={0} alignItems="center">
                              <Grid item xs>
                                Change Job Status
                              </Grid>
                              <Grid item xs>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  label={lastOne.status ? lastOne.status.statusName : ''}
                                  disabled={isSubmitting}
                                />
                              </Grid>
                              {lastOne.status.datePopup && (
                                <Grid item xs>
                                  <DateInput name="statusDate" />
                                </Grid>
                              )}
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                  )}
                  <Grid item xs={12} className={classes.header}>
                    <Grid fluid spacing={0} alignItems="center">
                      <Grid item xs={5}>
                        Date
                      </Grid>
                      <Grid item xs={7}>
                        Status Change
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.listItem}>
                    {props.jobWorkProgresses.edges.map(({ node }, key) => {
                      if (!node.completed) return null;

                      return (
                        <Grid
                          fluid
                          spacing={0}
                          alignItems="center"
                          key={key}
                          style={{ padding: 8 }}
                        >
                          <Grid item xs={5}>
                            {node.statusdate}
                          </Grid>
                          <Grid item xs={5}>
                            {node.status ? node.status.statusName : ''}
                          </Grid>
                          {
                            node.datepopup &&
                              <Grid item xs={2}>
                                <EditHistoryDialog data={node} portfolios={portfolios} refresh={handleCardRefresh} />
                              </Grid>
                          }
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            }}
          />
        }
      />
    );
  }
}

export default withStyles((theme) => ({
  header: {
    height: "2rem",
    background: theme.palette.primary.light,
    padding: "5px",
    color: theme.palette.primary.contrastText,
    borderRadius: "5px",
    fontWeight: 700,
  },
  listItem: {
    padding: "5px",
    "&:nth-child(even)": {
      background: theme.palette.grey[100],
    },
    "&:hover": {
      background: theme.palette.grey[300],
    },
  },
  lastSubStatus: {
    background: theme.palette.grey[100],
  },
  paper: {
    // maxHeight: '60vh',
    overflow: "auto",
  },
}))(StatusHistory);
