import React, { Component } from "react";
import { createPaginationContainer, graphql } from "react-relay";
import classNames from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
import ClaimItem from "./ClaimItem";

class ClaimListPagination extends Component {
  componentDidMount = () =>
    document
      .querySelector("#claimListPagination_scrolling-list")
      .addEventListener("scroll", this.scrollHandler);
  componentWillUnmount = () =>
    document
      .querySelector("#claimListPagination_scrolling-list")
      .removeEventListener("scroll", this.scrollHandler);

  render() {
    const { classes, user } = this.props;
    const { edges } = this.props.data.claimJobs;
    const header = this.props.data.currentUser;
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            Found {this.props.data.claimJobs.totalCount} claims
          </Grid>
        </Grid>
        <Grid container className={classes.paperContainer}>
          <Grid item xs={12} className={classes.paperWrapper}>
            <Paper
              className={classes.paper}
              titleClass={classes.noGut}
              title={
                <Grid fluid spacing={0} className={classes.headerContainer}>
                  {[
                    {
                      name: "Type",
                      className: classNames(classes.claimType, classes.col),
                    },
                    {
                      name: "Ins Ref #",
                      className: classNames(classes.insRef, classes.col),
                    },
                    {
                      name: "Ins Company",
                      className: classNames(classes.insComp, classes.col),
                    },

                    {
                      name: "FNOL",
                      className: classNames(classes.fnol, classes.col),
                      unMount: !user.isAdmin,
                    },

                    {
                      name: "Request Date",
                      className: classNames(classes.requestDate, classes.col),
                      unMount: user.isAdmin,
                    },
                    {
                      name: "Request",
                      className: classNames(classes.request, classes.col),
                      unMount: user.isAdmin,
                    },

                    {
                      name: "Customer" /* label:'Client'|'Customer' */,
                      className: classNames(classes.client, classes.col),
                    },
                    {
                      name: "Suburb",
                      className: classNames(classes.suburb, classes.col),
                    },
                    {
                      name: "State",
                      className: classNames(classes.state, classes.col),
                    },
                    {
                      name: "Value",
                      className: classNames(classes.value, classes.col),
                    },
                    {
                      name: header.claimListFields.lossAdjusterStatus.label,
                      className: classNames(classes.builder, classes.col),
                      unMount: !header.claimListFields.lossAdjusterStatus
                        .isDisplay,
                    },
                    {
                      name: "Status",
                      className: classNames(classes.status, classes.col),
                      unMount: user.isAdmin,
                    },
                    {
                      name: user.isBuilder ? "Restorer" : "Builder",
                      className: classNames(classes.builder, classes.col),
                      unMount: user.isContent,
                    },

                    {
                      name: "Building Status",
                      className: classNames(
                        classes.buildingStatus,
                        classes.col
                      ),
                      unMount: !user.isAdmin,
                    },
                    {
                      name: "Days",
                      className: classNames(classes.days, classes.col),
                      unMount: !user.isAdmin,
                    },
                    {
                      name: "Restorer",
                      className: classNames(classes.restorer, classes.col),
                      unMount: !user.isAdmin,
                    },
                    {
                      name: "Restoration Status",
                      className: classNames(
                        classes.restorationStatus,
                        classes.col
                      ),
                      unMount: !user.isAdmin,
                    },
                    {
                      name: "Contents Status",
                      className: classNames(
                        classes.contentsStatus,
                        classes.col
                      ),
                      unMount: !user.isAdmin,
                    },
                  ].map(
                    (row, index) =>
                      !row.unMount && (
                        <Grid item className={row.className} key={index}>
                          {row.name}
                        </Grid>
                      )
                  )}
                </Grid>
              }
            >
              <div
                id="claimListPagination_scrolling-list"
                className={classes.listBody}
              >
                {edges.map(({ node }, index) => (
                  <ClaimItem
                    key={index}
                    accessLevel={header}
                    claim={node}
                    classes={classes}
                    index={index + 1}
                    admin={node.__fragments.ClaimItem_admin ? node : null}
                    builder={node.__fragments.ClaimItem_builder ? node : null}
                    restorer={node.__fragments.ClaimItem_restorer ? node : null}
                    contents={node.__fragments.ClaimItem_contents ? node : null}
                  />
                ))}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  scrollHandler = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight * 0.7) {
      this._loadMore();
    }
  };
  _loadMore = () => {
    if (!this.props.relay.hasMore()) return;
    else if (this.props.relay.isLoading()) return;

    this.props.relay.loadMore();
  };
}

export default createPaginationContainer(
  withStyles((theme) => ({
    root: {
      marginTop: "-10px",
    },
    noGut: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    listBody: {
      maxHeight: "80vh",
      overflow: "auto",
    },
    headerContainer: {
      flexWrap: "nowrap",
      "& > div": {
        borderRight: "1px solid white",
      },
      "& > div:last-child": {
        borderRight: 0,
      },
    },

    "@media screen and (max-width: 1000px)": {
      paperContainer: {
        width: "110% !important",
        transformOrigin: "0rem 0rem !important",
      },
    },
    paperContainer: {
      width: "109%",
      transformOrigin: "10rem 0rem",
      transform: "scale(0.9)",
    },

    paperWrapper: {
      overflow: "auto",
    },
    paper: {
      width: "max-content",
      display: "table",
      margin: "0 auto",
    },

    centerCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    row: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      alignItems: "center",

      "&:nth-child(even)": {
        background: theme.palette.grey[100],
      },
      "&:hover": {
        background: theme.palette.grey[300],
      },
      flexWrap: "nowrap",
    },
    col: {
      textAlign: "center",
    },
    innerOfRow: {
      flexWrap: "nowrap",
    },

    expandBtn: {
      margin: 0,
      padding: theme.spacing.unit,
    },
    typeIcon: {
      margin: theme.spacing.unit,
      marginRight: 0,
    },
    claimType: { width: "160px" },
    insRef: {
      width: "161px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    insComp: { width: "177px" },

    fnol: { width: "150px" },
    requestDate: { width: "150px" },
    request: { width: "150px" },

    client: {
      width: "121px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    suburb: {
      width: "121px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    state: { width: "61px" },

    value: {
      width: "82px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    status: { width: "151px" },

    builder: { width: "185px", overflow: "hidden", textOverflow: "ellipsis" },

    buildingStatus: { width: "190px" },
    days: { width: "120px" },
    restorer: { width: "190px" },
    restorationStatus: { width: "190px" },
    contentsStatus: { width: "170px" },

    collapseWrapper: {
      width: "100%",
      borderTop: `1px solid ${theme.palette.grey[500]}`,
      marginTop: theme.spacing.unit,
      paddingTop: theme.spacing.unit,
    },
  }))(ClaimListPagination),
  graphql`
    fragment ClaimListPagination on Query {
      currentUser {
        claimListFields {
          lossAdjusterStatus {
            name
            label
            isDisplay
          }
        }
      }
      claimJobs(first: $first, after: $after, where: $where)
        @connection(key: "ClaimListPagination_claimJobs") {
        edges {
          node {
            ...ClaimItem_claim
            ...ClaimItem_admin @include(if: $isAdmin)
            ...ClaimItem_builder @include(if: $isBuilder)
            ...ClaimItem_restorer @include(if: $isRestorer)
            ...ClaimItem_contents @include(if: $isContent)
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  `,
  {
    query: graphql`
      query ClaimListPaginationQuery(
        $first: Int!
        $after: String
        $where: ClaimJobFilter
        $isAdmin: Boolean!
        $isBuilder: Boolean!
        $isRestorer: Boolean!
        $isContent: Boolean!
      ) {
        ...ClaimListPagination
      }
    `,
    getVariables(props, paginationInfo, fragmentVariables) {
      const { cursor } = paginationInfo;
      return {
        ...fragmentVariables,
        after: cursor,
      };
    },
  }
);
