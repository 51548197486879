import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation NewNoteActionMutation($input: ClaimNoteCreate!) {
    createClaimNote(input: $input) {
      success
      messages
      result {
        id
        jobNoteId
        logDate
        message
        portfolioType
        private
        user {
          userName
          company {
            companyName
          }
        }
      }
    }
  }
`

export default (input, first) => {
  console.log(input)
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables: { input },
//         updater: store => {
//           const payload = store.getRootField('createClaimNote')
//           const newNote = payload.getLinkedRecord('result')
// 
//           const storeRoot = store.getRoot()
//           const noteListRecord = storeRoot.getLinkedRecord(`claimNotes(where:{"claimId":"${input.claimId}"})`)
//           const noteList = noteListRecord.getLinkedRecords('edges')
// 
//           const newEdge = noteListRecord.getOrCreateLinkedRecord(`edges:${noteList.length}`, 'IClaimNoteEdge')
//           newEdge.setLinkedRecord(newNote, 'node')
// 
//           const newNoteList = [ ...noteList ]
//           newNoteList.unshift(newEdge)
// 
//           noteListRecord.setLinkedRecords(newNoteList, 'edges')
//         },
        onCompleted: res => resolve({ok: res.success}),
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}