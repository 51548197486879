import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation SupervisorUpdateMutation(
    $where: SupervisorKey!
    $input: SupervisorUpdateInput!
  ) {
    supervisorUpdate(where: $where, input: $input){
      messages
      success
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: ({ supervisorUpdate }) => {
          const { messages, success } = supervisorUpdate
          resolve({ messages, success })
        },
        onError: errors => {
          resolve({ messages: ['Unable to complete this action, please try again!'] })
        }
      }
    )
  })
}
