import React, { Component, createRef } from 'react'

import Chart from 'chart.js'
import { getColor } from 'common/utils/'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'

class ChartPaper extends Component {
  element = createRef()
  chart = ''
  componentDidMount = () => {
    const { type, data, options } = this.props
    const color = '#0F5B78'
    const colorSet = [
      ...Array(3).fill(1).map((a, i) => getColor(color, i*0.3, 0.6)),
      ...Array(3).fill(1).map((a, i) => getColor(color, i*-0.3, 0.6))
    ]
    const colorBorderSet = [
      ...Array(3).fill(1).map((a, i) => getColor(color, i*0.3, 1)),
      ...Array(3).fill(1).map((a, i) => getColor(color, i*-0.3, 1))
    ]

    if(data) {
      data.datasets.map(set => set.backgroundColor = set.backgroundColor ? set.backgroundColor : colorSet)
      data.datasets.map(set => set.borderColor = set.borderColor ? set.borderColor : colorBorderSet)
      this.chart = new Chart(this.element.current, {
        type,
        data,
        options
      })
    }
  }

  render() {
    const { width, height, title } = this.props // content, title, updated
    return (
      <Paper 
        title={title}
        content={
          <Grid fluid>
            <canvas onClick={this.handleClick} ref={this.element} width={width} height={height}></canvas>
          </Grid>
        }
      />
    )
  }

  handleClick = e => {
    // const a = this.chart.getElementAtEvent(e)
  }
}

export default ChartPaper