import React from 'react'

import Paper from 'components/Mui/Paper'
import Button from 'components/Mui/Button'
import Grid from 'components/Mui/Grid'


import FeeInvoiceUploadMultiDialog from '../Actions/FeeInvoiceUploadMultiDialog'
import FeeItemCreateDialog from '../Actions/FeeItemCreateDialog'
import FeeUpdateReservesDialog from '../Actions/FeeUpdateReservesDialog'

export default ({ actions }) => {
  const getAcions = (actions, feeItemId) => {
    return actions.map(action => {
      switch(action.actionType) {
        case 'FEE_ADD':
          return {
            ...action,
            icon: 'edit',
            onClick: () => {
              if(window[Symbol.for('_feeItemCreateDialog')]){
                window[Symbol.for('_feeItemCreateDialog')].handleOpen()
              }
            },
          }
        case 'INVOICE_BULK_UPLOAD':
          return {
            ...action,
            icon: 'cloud_upload',
            onClick: () => {
              if(window[Symbol.for('__feeInvoiceUploadMultiDialog')]){
                window[Symbol.for('__feeInvoiceUploadMultiDialog')].handleOpen()
              }
            }
          }
        case 'RESERVE_UPDATE':
          return {
            ...action,
            icon: 'cloud_upload',
            onClick: () => {
              if(window[Symbol.for('__feeUpdateReserveDialog')]){
                window[Symbol.for('__feeUpdateReserveDialog')].handleOpen()
              }
            }
          }
        default:
          return action
      }
    })
  }

  const a = getAcions(actions)
  const feeAddComponent = a.find(e => e.actionType === 'FEE_ADD')
  const feePreserveComponent = a.find(e => e.actionType === 'RESERVE_UPDATE')
  const bulkUploadComponent = a.find(e => e.actionType === 'INVOICE_BULK_UPLOAD')

  return (
    <Paper>
      <FeeInvoiceUploadMultiDialog symbol='__feeInvoiceUploadMultiDialog'></FeeInvoiceUploadMultiDialog>
      <FeeItemCreateDialog symbol="_feeItemCreateDialog"></FeeItemCreateDialog>
      <FeeUpdateReservesDialog symbol="__feeUpdateReserveDialog"></FeeUpdateReservesDialog>
      <Grid fluid justify='space-between'>
        <Grid item xs={2}>
          <Button fullWidth label='Next Step' variant='outlined' color='primary' onClick={() => {
              if(window[Symbol.for('__nextStepAction')]){
                  window[Symbol.for('__nextStepAction')].handleOpen()
              }
          }}/>
        </Grid>
        <Grid item xs={4}></Grid>
        {/* <Grid item xs={2}> */}
        {/*   <Button fullWidth label='Notify' variant='outlined' color='primary' */}
        {/*     onClick={this.notify} */}
        {/*   /> */}
        {/* </Grid> */}
        {
          [bulkUploadComponent, feePreserveComponent, feeAddComponent].map((button, key) => (
            <Grid item xs={2}>
              {button.isDisplay && (
                <Button
                  fullWidth variant='outlined' color='primary'
                  label={button.name}
                  onClick={button.onClick}
                  disabled={button.disabled}
                />
              )}
            </Grid>
          ))
        }
      </Grid>
    </Paper>
  )
}