import React from 'react'
import { Dialog, IconButton, Paper, withStyles } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

const DashboardInfo = props => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const createMarkup = () => {
    return {
      __html: props.groupDescription
    }
  }
  return (
    <div>
    {props.groupDescription && 
      <IconButton style={{padding: '1px'}} onClick={handleClickOpen} className={props.classes.info}>
        <InfoIcon style={{fontSize:'20px'}} />
      </IconButton>}
      <Dialog 
        open={open}
        onClose={handleClose}
        paperProps={{style: { maxWidth: '65%', minWidth:'20%' }}}       
      >
        <Paper className={props.classes.paper}>
          <div dangerouslySetInnerHTML={createMarkup()}>
          </div>
        </Paper>
      </Dialog>
    </div>
  )
}

export default withStyles(theme => ({
  info: {
    position: 'absolute',
    right: '10px',
    top: '10px'
  },
  paper: {
    padding: theme.spacing.unit * 2,
  }
}))(DashboardInfo)