import React from "react";
import { Formik, Form } from "formik";
import HistoriesList from "./HistoriesList";
import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";
import usePortfolioFilter from "hooks/usePortfolioFilter";

export default (props) => {
  const meta = React.useContext(ClaimMetaContext);
  const [portfolio, handlePortfolio] = usePortfolioFilter(meta);
  const [updateTypeId, setUpdateTypeId] = React.useState()

  return (
    <Formik
      initialValues={{updateTypeId: ''}}
      validate={(values) => {
        if(values.updateTypeId !== '') {
          setUpdateTypeId(values.updateTypeId)
        }
    }}>
      {() => {
        return (
          <Form>
            <HistoriesList
              data={props}
              portfolio={portfolio}
              handlePortfolio={handlePortfolio}
              updateTypeId={updateTypeId}
              {...props}
            />
          </Form>
        )
      }}
    </Formik>
  );
};
