import React from 'react'

import useReactRouter from 'use-react-router'
import queryString from 'query-string'

import useRoomSubscription from '../Room/hooks/useRoomSubscription'
import useStatus from './hooks/useStatus'
import useCheckRequirements from './hooks/useCheckRequirements'
import useKurento from './hooks/useKurento'
import useJoinRoom from './hooks/useJoinRoom'
import useActions from '../Room/hooks/useActions'

import { useMutation } from 'react-relay-mutation'
import DocPhotoUploadMutation from '../Room/mutations/DocPhotoUploadMutation'
import CreateJobNoteMutation from '../Room/mutations/CreateJobNoteMutation'
import UploadPhotoMutation from '../Room/mutations/UploadPhotoMutation'

import VideoCall from './VideoCall'

export default () => {
  const { location, history } = useReactRouter()
  const { callSessionId, claimId } = queryString.parse(location.search)

  // HOOK: ROOM SUBSCRIPTION
  const { room, ownerSession, guestSession } = useRoomSubscription({ callSessionId })
  const isOwner = Boolean(
    ((ownerSession ? ownerSession.id : null) === callSessionId) ||
    localStorage.TOKEN
  )
  // if(guestSession) console.log(`https://muiendata.test:3000/video/call?callSessionId=${guestSession.id}`)

  // HOOK: END LOCATION
  const [endLocation, setEndLocation] = React.useState(null)
  React.useEffect(() => {
    if(!isOwner) {
      setEndLocation('/video/end-call')
    }
    else if(ownerSession) {
      if (!claimId) setEndLocation(`/video/rooms/${room.id}`)
      else if(ownerSession.id === callSessionId) setEndLocation(`/claim/job-info?id=${claimId}`)
    }
  }, [ownerSession, isOwner])

  // HOOK: ROOM STATUS, SESSION STATUS EFFECTS
  const { loading, ownerMessage, guestMessage, setGuestMessage } = useStatus({
    roomStatus: room ? room.status : null,
    ownerSessionStatus: ownerSession ? ownerSession.callStatus : null,
    guestSessionStatus: guestSession ? guestSession.callStatus : null,
    endLocation
  })

  // HOOK: CHECK REQUIREMENTS
  const [done, setDone] = React.useState(false)
  const { done: checkRequirementDone, isVideoInput, isAudioOutput } = useCheckRequirements()

  // if requirementErrors update status
  React.useEffect(() => {
    if(checkRequirementDone) {
      if(!isOwner && !isVideoInput) {
        setGuestMessage(`You don't have a camera to make a video call or not allow the app to use it`)
      }
      else {
        setDone(true)
      }
    }
  }, [isOwner, checkRequirementDone, isVideoInput, isAudioOutput])

  // HOOK: USE KURENTO
  const localVideoRef = React.useRef(null)
  const remoteVideoRef = React.useRef(null)
  const mediaConstraints = {
    video: isVideoInput ? { /* width: 640, */ /* framerate: 15, */ facingMode: 'environment' } : false,
    audio: isAudioOutput ? true : false,
  }
  const configuration = { iceServers: [
    { urls: 'turn:videotest.endataclaims.com:3478', username: 'endata', credential: '567tyughj' },
    { urls: 'stun:videotest.endataclaims.com:3478'},
    { urls: 'stun:stun.l.google.com:19302'},
  ]}
  const { peer, ready: kurentoReady, signallingData, helpers, actions } = useKurento({
    begin: done,
    localVideoRef, remoteVideoRef,
    mediaConstraints, configuration,
  })

  // HOOK: JOIN ROOM AND SIGNALLING
  const { data } = useJoinRoom({ ready: kurentoReady, callSessionId, signallingData })
  React.useEffect(() => {
    if(data) {
      helpers.setServerCandidates(data.serverIceCandidates)
      helpers.setAnswer(data.sdpAnswer)
    }
  }, [data, helpers])

  // HOOK: map actions with action configs
  const [photos, setPhotos] = React.useState([])
  const [docPhotoUpload] = useMutation(DocPhotoUploadMutation)
  const [uploadPhoto] = useMutation(UploadPhotoMutation)
  const getPhoto = async () => {
    const photo = actions.getPhoto()
    setPhotos(previous => [...previous, photo])

    const file = await fetch(photo)
      .then(res => res.blob())
      .then(blob => new File([blob], `${Date.now()}.jpg`, { type: 'image/png' }))
    console.log(file)
    const res = await uploadPhoto({ variables: {
      sessionId: callSessionId,
      file
    }})
    console.log(res)

    if (claimId) {
      const file64 = {
        fileName: `${Date.now()}.jpg`,
        fileBase64: photo.replace('data:image/png;base64,', '')
      }

      await docPhotoUpload({
        variables: {
          input: {
            claimId: parseInt(claimId),
            description: `Photo ${photos.length + 1} from video session`,
            documentFileName: file64.fileName,
            fileBase64: file64.fileBase64,
            isInvoice: false,
            private: false,
          }
        }
      })
    }
  }

  const [createJobNote] = useMutation(CreateJobNoteMutation)
  const hangup = async () => {
    if(isOwner && claimId) {
      await createJobNote({
        variables: {
          input: {
            claimId: parseInt(claimId),
            message: 'Video session has ended'
          }
        }
      })
    }

    // actions.hangup()
    history.push(endLocation)
  }

  const actionButtons = useActions({
    isOwner,
    loading,
    actionConfigs: room ? room.account.clientActionsConfig : null,
    actions: {
      ...actions,
      getPhoto,
      hangup
    }
  })

  const [remote, setRemote] = React.useState(null)
  React.useEffect(() => {
    const start = () => {
      const remote = peer.getRemoteStream()
      if(remote) {
        setRemote(remote)
      }
    }

    setTimeout(() => {
      if(peer) start()
    }, 1000)
  }, [peer])
  React.useEffect(() => {
    if(remote && remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remote
      remoteVideoRef.current.play()
    }
  }, [remote, remoteVideoRef])

  return (
    <VideoCall
      loading={loading}

      ownerMessage={ownerMessage}
      guestMessage={guestMessage}

      localVideoRef={localVideoRef}
      remoteVideoRef={remoteVideoRef}

      isOwner={isOwner}

      actions={actionButtons}

      photos={photos}
    />
  )
}