import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";

import Grid from "components/Mui/Grid";
import { Cube } from "components/Mui/Loader";
import LossAdjusterList from "./LossAdjusterList";
import LossAdjusterViewAllAction from "./LossAdjusterActions/LossAdjusterViewAllAction";

const query = graphql`
  query LossAdjusterQuery(
    $first: Int!
    $after: String
    $where: ClaimItemFilter
    $claimJobWhere: ENDataEntityKey!
  ) {
    ...LossAdjusterList
  }
`;

const LossAdjuster = (props) => {
  const variables = {
    first: 15,
    after: null,
    where: {
      claimId: props.claimId,
    },
    claimJobWhere: {
      id: props.claimId,
    },
  };

  return (
    <>
      <QueryRenderer
        environment={environment}
        query={query}
        variables={variables}
        render={({ error, props: data }) => {
          if (error) {
            // console.log(error)
            return "Error!!";
          }

          if (!data)
            return (
              <Grid fluid style={{ height: "100px" }}>
                <Grid item xs>
                  <Cube />
                </Grid>
              </Grid>
            );

          return <LossAdjusterList claimId={props.claimId} data={data} />;
        }}
      />
      <LossAdjusterViewAllAction claimId={props.claimId} />
    </>
  );
};

// class LossAdjuster extends Component {
//   state = {
//
//   }
//
//   componentWillMount = () => this.setState({ variables: {
//     ...this.state.variables,
//     where: {
//       claimId: this.props.claimId
//     }
//   }})
//
//   componentDidUpdate = prevProps => {
//     if(prevProps.claimId !== this.props.claimId) {
//       this.setState({
//         variables: {
//           ...this.state.variables,
//           where: {
//             claimId: this.props.claimId
//           }
//         }
//       })
//     }
//   }
//
//   render() {
//     const { actions } = this.props
//     const { variables } = this.state
//
//
//   }
// }

export default LossAdjuster;
