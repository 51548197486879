import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation ResetQuoteMutation($where: ENDataPortfolioKey!) {
    jobQuoteReset(where: $where) {
      messages
      success
      result {
        completed
        reviewed
        quoteStatus {
          statusId
          statusName
        }
        jobQuoteId
        completed reviewed
        supplier { companyId }
        id
        actions {
          addLineItem {
            label
            isDisabled
            isDisplay
          }
          makeInitialCall {
            label
            isDisabled
            isDisplay
          }
          requestQuotes {
            label
            isDisabled
            isDisplay
          }
          reviewQuote {
            label
            isDisabled
            isDisplay
          }
          submitQuote {
            label
            isDisabled
            isDisplay
          }
          resetQuote {
            label
            isDisabled
            isDisplay
          }
        }
        scopeTypes {
          locations {
            id
            location {
              id
              costLocationId
              locationName
            }
            items {
              jobQuoteItemId
              accepted
              location { costLocationId locationName } dimensions
              costCentre { costCentreId costCentreName }
              costItem { costItemId itemDescription } lineDescription
              unit { unitId unitName }
              directsupply
              purchasePrice
              ageOfItem
              proofOfLoss
              policyLimit
              qty
              rate
              marginRate
              subtotal
              logdate
              policyLimit
              requestedQuote
              sirRate
              scopeType {
                name
                id
              }
            }
          }
          scopeType {
            id
            name
          }
          id
          gst
          margin
          sirGst
          sirMargin
          sirSubtotal
          sirTotal
          subtotal
          total
          discount
          fields {
            discount {
              isDisplay
              label
              isDisabled
            }
            sIRRate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            gst {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            total {
              label
              isDisplay
            }
            action {
              label
              isDisplay
            }
            costCentre {
              label
              isDisplay
            }
            description {
              label
              isDisplay
            }
            marginRate {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            qty {
              label
              isDisplay
            }
            rate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            unit {
              label
              isDisplay
            }
          }
        }
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        // updater: store => {
        //   const payload = store.getRootField('jobQuoteReset')
        //   if(payload.getValue('success')) {
        //     const newCompleted = payload.getLinkedRecord('result').getValue('completed')
        //     const newReviewed = payload.getLinkedRecord('result').getValue('reviewed')
        //     const storeRoot = store.getRoot()
        //     const conn = ConnectionHandler.getConnection(storeRoot, 'ScopeQuery_jobQuotes')
        //     console.log({newCompleted, newReviewed})
        //     conn.getLinkedRecords('edges')[0]
        //       .getLinkedRecord('node')
        //       .setValue(newCompleted, 'completed')
        //     conn.getLinkedRecords('edges')[0]
        //       .getLinkedRecord('node')
        //       .setValue(newReviewed, 'reviewed')
        //   }
        // },
        onCompleted: res => {
          resolve({
            ok: true,
            success: res.jobQuoteReset.success,
            messages: res.jobQuoteReset.messages
          })
        },
        onError: errors => {
          // console.log(errors)
          resolve({ok: false})
        }
      }
    )
  })
}
