/**
 * @flow
 * @relayHash bd21770edd42bd4c6a945104da239af4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type AcknowledgeHistoryMutationVariables = {|
  where: ENDataPortfolioKey
|};
export type AcknowledgeHistoryMutationResponse = {|
  +claimUpdateAcknowledge: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +acknowledged: ?boolean,
      +id: string,
    |},
  |}
|};
export type AcknowledgeHistoryMutation = {|
  variables: AcknowledgeHistoryMutationVariables,
  response: AcknowledgeHistoryMutationResponse,
|};
*/


/*
mutation AcknowledgeHistoryMutation(
  $where: ENDataPortfolioKey!
) {
  claimUpdateAcknowledge(where: $where) {
    messages
    success
    result {
      acknowledged
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimUpdateAcknowledge",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ENDataPortfolioKey!"
      }
    ],
    "concreteType": "ClaimUpdateHistoryPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "result",
        "storageKey": null,
        "args": null,
        "concreteType": "ClaimUpdateHistory",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "acknowledged",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AcknowledgeHistoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AcknowledgeHistoryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AcknowledgeHistoryMutation",
    "id": null,
    "text": "mutation AcknowledgeHistoryMutation(\n  $where: ENDataPortfolioKey!\n) {\n  claimUpdateAcknowledge(where: $where) {\n    messages\n    success\n    result {\n      acknowledged\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e9c8fe4c74d091ef1823a387429ede15';
module.exports = node;
