import React from 'react'
import QuoteGroupTable from './QuoteGroupTable'

const QuoteItemList = ({ classes, quote, meta, portfolio, isAction, isOwnCompany, isContentsAction }) => {
  const tableArray = quote && quote.scopeTypes
  if (!tableArray) return null
  return (
    <div>
      {tableArray.map(e => {
        return(
          <QuoteGroupTable quote={quote} portfolio={portfolio} meta={meta}
          isAction={isAction} isOwnCompany={isOwnCompany}
          isContentsAction={isContentsAction} tableItem={e}/>
        )})}
    </div>
  )
}

export default QuoteItemList
