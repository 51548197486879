import React from 'react'
import GroupTable from './GroupTable'

const ScopeTypeLineItemListGroup = ({ quote, ScopeTypeOptions, portfolioType, update, quotationType }) => {
  const tableArray = quote && quote.scopeTypes
  const [ loading, setLoading ] = React.useState(false)
  if (!tableArray) return null
  return (
    <div style={{width: '100%'}}>
      {tableArray.map(e => {
        return(
          <GroupTable
            quote={quote}
            tableItem={e}
            ScopeTypeOptions={ScopeTypeOptions}
            portfolioType={portfolioType}
            update={update}
            loading={loading}
            setLoading={setLoading}
            quotationType={quotationType}
          />
        )})}
    </div>
  )
}

export default ScopeTypeLineItemListGroup
