import { assign } from '@xstate/fsm'
import { Machine } from 'xstate'

const webRtcMachine = Machine({
  id: 'webrtc',
  initial: 'idle',
  context: {
    message: 'Creating new room'
  },
  states: {
    idle: {
      on: {
        START: {
          target: 'consentCheck', // consentCheck supportDeviceCheck
          actions: assign({
            message: 'Checking user consent!'
          })
        }
      }
    },
    consentCheck: {
      on: {
        RESOLVE: {
          target: 'supportDeviceCheck',
          actions: assign({
            message: (_, e) => e.message
          })
        },
        REJECT: {
          target: 'endWithError',
          actions: assign({
            message: (_, e) => e.message
          })
        },
      }
    },
    supportDeviceCheck: {
      on: {
        RESOLVE: {
          target: 'permissionDeviceCheck',
          actions: assign({
            message: (_, e) => e.message,
            supported: (_, e) => e.supported,
          })
        },
        REJECT: {
          target: 'endWithError',
          actions: assign({
            message: (_, e) => e.message
          })
        },
      }
    },
    permissionDeviceCheck: {
      on: {
        RESOLVE: {
          target: 'localSignalling',
          actions: assign({
            message: (_, e) => e.message,
            permitted: (_, e) => e.permitted,
          })
        },
        REJECT: {
          target: 'endWithError',
          actions: assign({
            message: (_, e) => e.message
          })
        }
      }
    },
    localSignalling: {
      on: {
        REJECT: {
          target: 'endWithError',
          actions: assign({
            message: (_, e) => e.message
          })
        },
        RESOLVE: {
          target: 'joinRoom',
          actions: assign({
            message: (_, e) => e.message,
            kurentoPeer: (_, e) => e.kurentoPeer,
            signallingData: (_, e) => e.signallingData
          })
        }
      }
    },
    joinRoom: {
      on: {
        REJECT: {
          target: 'endWithError',
          actions: assign({
            message: (_, e) => e.message
          })
        },
        RESOLVE: {
          target: 'joinedRoom',
          actions: assign({
            message: (_, e) => e.message
          })
        },
      }
    },
    joinedRoom: {
      on: {
        end: {
          target: 'end'
        }
      }
    },
    end: { type: 'final' },
    endWithError: { type: 'final' },
  },
})

export default webRtcMachine