import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation UploadLossAdjusterActionMutation($input: ClaimLossAdjusterDocumentCreate!) {
    createClaimLossAdjusterDocument(input: $input){
      success
      messages
      result{
        id
        uploadDate
        company {
          companyName
        }
        reportType {
          reportTypeName
        }
        url
        private
        description
      }
    }
  }
`

export default input => {
  const { path } = input
  delete input.path
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables: { input },
        updater: store => {
          if(path === '/claim/loss-adjuster') {
            const payload = store.getRootField('createClaimLossAdjusterDocument')
            const newLossAdjusterDoc = payload.getLinkedRecord('result')

            if(payload.getValue('success')) {
              const newId = newLossAdjusterDoc.getValue('id')
              const root = store.getRoot()
              const conn = ConnectionHandler.getConnection(root, 'LossAdjusterList_claimLossAdjusterDocuments')
              const newEdge = ConnectionHandler.createEdge(store, conn, newLossAdjusterDoc, 'IClaimLossAdjusterDocumentEdge')
              ConnectionHandler.insertEdgeBefore(conn, newEdge, newId)
            }
          }
        },
        onCompleted: res => {
          resolve({
            ok: true,
            success: res.createClaimLossAdjusterDocument.success,
            message: res.createClaimLossAdjusterDocument.messages
          })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}