import React from 'react'
import { Formik, Form } from 'formik'
import { Grid, withStyles, IconButton } from '@material-ui/core';
import Button from 'components/Mui/Button'
import followUpActionMutation from './FollowUpActionMutation'
import Input from 'components/Formik/Input'
import DateInput from 'components/Formik/DateInput'
import QuickAction from 'components/Mui/QuickActions/QuickAction'
import InfoIcon from '@material-ui/icons/Info'

const FollowUp = props => {
  const { classes, portfolio, claimId, handleRefresh, ...rest } = props
  const qaRef = React.createRef()
  const snackbar = window[Symbol.for('__snackbar')]
  return (
      <QuickAction
        extraButton = {<Info />}
        title="Follow Up"
        innerRef={qaRef}
        // paperProps={{style: { maxWidth: '65%', minWidth:'20%' }}}
        content={
          <Formik
          initialValues={{
            nextFollowUpDate: undefined, note: ''
          }}
          onSubmit={async (values, actions) => {
            const res = await followUpActionMutation({
              where: { claimId: claimId },
              input: {
                note: values.note,
                nextFollowUpDate: values.nextFollowUpDate? values.nextFollowUpDate.format("YYYY/MM/DD"): undefined
              }
            })
            if (res.ok) {
              if(window[Symbol.for('__HistoriesRefresh')]){
                window[Symbol.for('__HistoriesRefresh')].handleRefresh()
              }
              actions.resetForm({nextFollowUpDate: '', note: ''})
              snackbar.toggleOpen({message: res.messages[0]})
              qaRef.current.toggleOpen()
            }
          }}
          >
          {(props) => {
            return (
              <Form>
                <Grid fluid spacing={0} className={classes.root}>
                  <Grid item xs={12}>
                      <Input
                        className={classes.input}
                        required
                        label='Notes'
                        name='note'
                        multiline rows={5} rowsMax={10}
                      />
                    </Grid>
                  <Grid item xs={12} className={classes.date}>
                    <Grid fluid spacing={0}>
                      {
                        [
                          {
                            component: DateInput,
                            leadIcon: 'event',
                            clearable: true,
                            label: 'Next Follow Up Date',
                            name: 'nextFollowUpDate'
                          },
                        ].map(({component: Component, ...props}, key) => (
                          <Grid item xs={12} key={key}>
                            <Component
                              leadIcon={props.leadIcon}
                              clearable={props.clearable}
                              type={props.type}
                              required={props.required}
                              label={props.label}
                              name={props.name}
                              fullWidth
                            />
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={12} justify='flex-end' className={classes.button}>
                    <Button label='Submit' variant='contained' color='primary' type='submit'/>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
          </Formik>

        }
        {...rest}
        />
  )
}

const styles = {
  icon: {
    fontSize: '40px',
  },
  button: {
    display: 'flex'
  }
};

export default withStyles(styles)(FollowUp);

const toggleFollowUpInfo = () => {
  if(window[Symbol.for('__FollowUpInfo')]){
    window[Symbol.for('__FollowUpInfo')].toggleOpen()
  }
}

const Info = props => {
  return (
    <IconButton style={{padding: '1px'}} onClick={toggleFollowUpInfo} className={props.className}>
      <InfoIcon style={{fontSize:'20px'}} />
    </IconButton>
  )
}
