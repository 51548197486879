/**
 * @flow
 * @relayHash 8ce03b982452ceaa197300967cbc27e9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimCategory = "NonUrgent" | "SemiUrgent" | "Urgent" | "%future added value";
export type LogLevel = "Critical" | "Debug" | "Error" | "Information" | "None" | "Trace" | "Warning" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type ClaimJobInput = {|
  refNumber?: ?string,
  contentsRefNum?: ?string,
  additionalRefNumber?: ?string,
  claimDescription?: ?string,
  policyCoverId?: ?string,
  policyTypeId?: ?string,
  mapRef?: ?string,
  lotNumber?: ?string,
  planNumber?: ?string,
  eventTypeId?: ?string,
  catCodeId?: ?string,
  incidentDate?: ?any,
  habitableProperty?: ?boolean,
  asbestos?: ?boolean,
  hold?: ?boolean,
  makeSafeRequired?: ?boolean,
  casemanagerId?: ?string,
  brcId?: ?string,
  homeAssessorId?: ?string,
  visitTypeId?: ?string,
  externalLossAdjusterId?: ?string,
  agentId?: ?string,
  managedByENData?: ?boolean,
  riskname?: ?string,
  multipleRisks?: ?boolean,
  associatedRiskJobId?: ?number,
  requireCustomLogin?: ?boolean,
  customLoginEmail?: ?string,
  vulnerableMember?: ?boolean,
  insuredSalutation?: ?any,
  insuredName?: ?any,
  insuredPhone1?: ?any,
  insuredPhone2?: ?any,
  insuredPhone3?: ?any,
  insuredFax?: ?any,
  insuredEmail?: ?any,
  postalAddressLine1?: ?string,
  postalAddressLine2?: ?string,
  postalAddressSuburb?: ?string,
  postalAddressState?: ?StateEnum,
  postalAddressPostcode?: ?string,
  riskAddressLine1?: ?string,
  riskAddressLine2?: ?string,
  riskAddressSuburb?: ?string,
  riskAddressState?: ?StateEnum,
  riskAddressPostcode?: ?string,
  tenantName?: ?any,
  tenantPhone1?: ?any,
  tenantPhone2?: ?any,
  tenantPhone3?: ?any,
  category?: ?ClaimCategory,
  portfolios?: ?$ReadOnlyArray<?ClaimPortfolioInput>,
|};
export type ClaimPortfolioInput = {|
  portfolioType: PortfolioType,
  policyCoverId?: ?string,
  policyTypeId?: ?string,
  description?: ?string,
  sumInsured?: ?number,
  excessValue?: ?number,
  toCollectExcess?: ?boolean,
  quotingSupplierIds?: ?$ReadOnlyArray<string>,
|};
export type ENDataEntityKey = {|
  id: string
|};
export type UpdateClaimMutationVariables = {|
  input: ClaimJobInput,
  where: ENDataEntityKey,
|};
export type UpdateClaimMutationResponse = {|
  +updateClaimJob: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +fieldErrors: ?$ReadOnlyArray<?{|
      +fieldName: string,
      +level: LogLevel,
      +message: string,
    |}>,
    +result: ?{|
      +refNumber: string,
      +view: ?{|
        +fields: {|
          +lossAdjusterStatus: {|
            +id: string,
            +displayValue: ?string,
            +isDisplay: boolean,
          |}
        |}
      |},
      +insured: ?{|
        +name: ?string,
        +phone1: ?string,
        +phone2: ?string,
        +phone3: ?string,
      |},
      +incidentDetail: ?{|
        +eventType: ?{|
          +eventName: string
        |},
        +cATCode: ?{|
          +cATCodeName: string
        |},
        +riskAddress: ?{|
          +line1: ?string,
          +suburb: ?string,
          +state: ?StateEnum,
          +postcode: ?string,
        |},
      |},
      +building: ?{|
        +claimStatus: ?{|
          +statusName: ?string
        |},
        +excessValue: ?number,
        +toCollectExcess: ?boolean,
      |},
      +restoration: ?{|
        +claimStatus: ?{|
          +statusName: ?string
        |},
        +excessValue: ?number,
        +toCollectExcess: ?boolean,
      |},
      +contents: ?{|
        +claimStatus: ?{|
          +statusName: ?string
        |},
        +toCollectExcess: ?boolean,
        +excessValue: ?number,
      |},
      +policyCover: ?{|
        +policyCoverName: string,
        +policyCoverId: ?any,
      |},
      +policyType: ?{|
        +policyTypeName: string,
        +policyTypeId: ?any,
      |},
    |},
  |}
|};
export type UpdateClaimMutation = {|
  variables: UpdateClaimMutationVariables,
  response: UpdateClaimMutationResponse,
|};
*/


/*
mutation UpdateClaimMutation(
  $input: ClaimJobInput!
  $where: ENDataEntityKey!
) {
  updateClaimJob(input: $input, where: $where) {
    success
    messages
    fieldErrors {
      fieldName
      level
      message
    }
    result {
      refNumber
      view {
        fields {
          lossAdjusterStatus {
            id
            displayValue
            isDisplay
          }
        }
      }
      insured {
        name
        phone1
        phone2
        phone3
      }
      incidentDetail {
        eventType {
          eventName
        }
        cATCode {
          cATCodeName
        }
        riskAddress {
          line1
          suburb
          state
          postcode
        }
      }
      building {
        claimStatus {
          statusName
          id
        }
        excessValue
        toCollectExcess
        id
      }
      restoration {
        claimStatus {
          statusName
          id
        }
        excessValue
        toCollectExcess
        id
      }
      contents {
        claimStatus {
          statusName
          id
        }
        toCollectExcess
        excessValue
        id
      }
      policyCover {
        policyCoverName
        policyCoverId
      }
      policyType {
        policyTypeName
        policyTypeId
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimJobInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ClaimJobInput!"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataEntityKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "fieldErrors",
  "storageKey": null,
  "args": null,
  "concreteType": "EntityFieldError",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fieldName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "level",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "refNumber",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "view",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimJobView",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fields",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimJobField",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "lossAdjusterStatus",
          "storageKey": null,
          "args": null,
          "concreteType": "FieldInput",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "displayValue",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isDisplay",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "insured",
  "storageKey": null,
  "args": null,
  "concreteType": "ContactDetail",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "phone1",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "phone2",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "phone3",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "incidentDetail",
  "storageKey": null,
  "args": null,
  "concreteType": "IncidentDetail",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventType",
      "storageKey": null,
      "args": null,
      "concreteType": "IncidentEvent",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "eventName",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "cATCode",
      "storageKey": null,
      "args": null,
      "concreteType": "CATCode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cATCodeName",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "riskAddress",
      "storageKey": null,
      "args": null,
      "concreteType": "Address",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "line1",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "suburb",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "postcode",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "claimStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimStatus",
  "plural": false,
  "selections": [
    (v10/*: any*/)
  ]
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "excessValue",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "toCollectExcess",
  "args": null,
  "storageKey": null
},
v14 = [
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/)
],
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "policyCover",
  "storageKey": null,
  "args": null,
  "concreteType": "PolicyCover",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "policyCoverName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "policyCoverId",
      "args": null,
      "storageKey": null
    }
  ]
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "policyType",
  "storageKey": null,
  "args": null,
  "concreteType": "PolicyType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "policyTypeName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "policyTypeId",
      "args": null,
      "storageKey": null
    }
  ]
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "claimStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimStatus",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v6/*: any*/)
  ]
},
v18 = [
  (v17/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v6/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateClaimMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateClaimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJob",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "building",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimPortfolio",
                "plural": false,
                "selections": (v14/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "restoration",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimPortfolio",
                "plural": false,
                "selections": (v14/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contents",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimPortfolio",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v13/*: any*/),
                  (v12/*: any*/)
                ]
              },
              (v15/*: any*/),
              (v16/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateClaimMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateClaimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJob",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "building",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimPortfolio",
                "plural": false,
                "selections": (v18/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "restoration",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimPortfolio",
                "plural": false,
                "selections": (v18/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contents",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimPortfolio",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v13/*: any*/),
                  (v12/*: any*/),
                  (v6/*: any*/)
                ]
              },
              (v15/*: any*/),
              (v16/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateClaimMutation",
    "id": null,
    "text": "mutation UpdateClaimMutation(\n  $input: ClaimJobInput!\n  $where: ENDataEntityKey!\n) {\n  updateClaimJob(input: $input, where: $where) {\n    success\n    messages\n    fieldErrors {\n      fieldName\n      level\n      message\n    }\n    result {\n      refNumber\n      view {\n        fields {\n          lossAdjusterStatus {\n            id\n            displayValue\n            isDisplay\n          }\n        }\n      }\n      insured {\n        name\n        phone1\n        phone2\n        phone3\n      }\n      incidentDetail {\n        eventType {\n          eventName\n        }\n        cATCode {\n          cATCodeName\n        }\n        riskAddress {\n          line1\n          suburb\n          state\n          postcode\n        }\n      }\n      building {\n        claimStatus {\n          statusName\n          id\n        }\n        excessValue\n        toCollectExcess\n        id\n      }\n      restoration {\n        claimStatus {\n          statusName\n          id\n        }\n        excessValue\n        toCollectExcess\n        id\n      }\n      contents {\n        claimStatus {\n          statusName\n          id\n        }\n        toCollectExcess\n        excessValue\n        id\n      }\n      policyCover {\n        policyCoverName\n        policyCoverId\n      }\n      policyType {\n        policyTypeName\n        policyTypeId\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd3a5b2310f5720a65a4e6e25b4b57ec2';
module.exports = node;
