import React from "react";
import { moneyFormat } from "common/utils";

export const getData = (data, type) => {
  const scopeType = data[0].node[`${type}`].map((e) => {
    return useExpand(e);
  });
  const scoping = [];
  const quotesArray = [];
  const quotes = [];
  const lengthOfCostCentre = data[0].node[`${type}`][0]
    ? data[0].node[`${type}`].map((e) => e.costCentres.length)
    : [];

  data[0].node[`${type}`].forEach((e) => {
    e.costCentres.forEach((i) => {
      scoping.push({
        sir: i.sirSubtotal,
        variation: i.validationSubtotal,
        scoping: i.total,
        item: true,
        name: i.costCentre.costCentreName,
        id: `${e.scopeType.id}_${i.costCentre.costCentreId}`,
      });
    });
  });

  data
    .filter((_e, i) => i !== 0)
    .forEach((e) => {
      e.node[`${type}`].forEach((i) => {
        const costCentres = i.costCentres.map((item) => {
          return {
            sirSubtotal: item.sirSubtotal ? item.sirSubtotal : "-",
            validationSubtotal: item.validationSubtotal
              ? item.validationSubtotal
              : "-",
            total: item.total ? item.total : "-",
            id: `${i.scopeType.id}_${item.costCentre.costCentreId}`,
          };
        });
        quotesArray.push(costCentres);
      });
    });

  quotesArray.forEach((e) => {
    quotes.push(
      e.reduce((sum, cur) => {
        const current_item = { [cur.id]: cur.total };
        if (!sum) return current_item;
        return {
          ...sum,
          ...current_item,
        };
      }, {})
    );
  });

  const final = scoping.map((e) => {
    return {
      name: e.name,
      item: e.item,
      id: e.id,
      sir: moneyFormat(e.sir),
      variation: moneyFormat(e.variation),
      scoping: Boolean(e.scoping) ? moneyFormat(e.scoping) : null,
      quoting: quotes
        .map((quote) => {
          if (!quote[e.id]) return null;
          if (quote[e.id] === "-") return "-";
          return moneyFormat(quote[e.id]);
        })
        .filter((e) => e),
    };
  });

  scopeType.forEach((e, index) => {
    const order = lengthOfCostCentre
      .filter((_e, ind) => ind < index)
      .reduce((a, b) => a + b, 0);
    final.splice(index + order, 0, e);
  });
  getSummaryData(data, final, lengthOfCostCentre, type);
  // getExpand(final, lengthOfCostCentre)
  return final;
};

const transformSummaryData = (data, type, sumField) => {
  const scopeTypeLength = data[0].node[`${type}`].length;
  const quotesLength = data.filter((_e, index) => index !== 0).length;
  const originalArray = [];
  const transformedArray = [];
  data
    .filter((_e, i) => i !== 0)
    .forEach((e) => {
      originalArray.push(
        e.node[`${type}`].reduce((sum, cur, index) => {
          const curItem = { [index]: moneyFormat(cur[`${sumField}`]) };
          if (!sum) return curItem;
          return {
            ...sum,
            ...curItem,
          };
        }, {})
      );
    });
  if (originalArray.length > 0) {
    Object.keys(originalArray[0]).forEach((_e, index) => {
      transformedArray[index] = [];
    });
  }
  originalArray.forEach((e) => {
    for (let i = 0; i < Object.keys(e).length; i++) {
      //it used to be transformedArray[i].push(e[i])
      transformedArray.push(e[i]);
    }
  });
  return [
    Object.values(transformedArray).reduce((sum, cur) => {
      return sum.concat(cur);
    }, []),
    scopeTypeLength,
    quotesLength,
  ];
};

const getDiscountData = (data, type) => {
  const [discountArray, scopeTypeLength, quotesLength] = transformSummaryData(
    data,
    type,
    "discount"
  );
  return data[0].node[`${type}`].map((e, index) => {
    return {
      name: e.fields.discount.label,
      order: e.fields.discount.order,
      isDisplay: e.fields.discount.isDisplay,
      sir: moneyFormat(e.sirDiscount),
      variation: moneyFormat(e.validationDiscount),
      scoping: moneyFormat(e.discount),
      quoting:
        discountArray.length > 1
          ? discountArray.slice(
              index * quotesLength,
              (index + 1) * quotesLength
            )
          : discountArray,
      sumary: true,
    };
  });
};

const getMarginData = (data, type) => {
  const [MarginArray, scopeTypeLength, quotesLength] = transformSummaryData(
    data,
    type,
    "margin"
  );
  return data[0].node[`${type}`].map((e, index) => {
    return {
      name: e.fields.margin.label,
      order: e.fields.margin.order,
      isDisplay: e.fields.margin.isDisplay,
      sir: moneyFormat(e.sirMargin),
      variation: moneyFormat(e.validationMargin),
      scoping: moneyFormat(e.margin),
      quoting:
        MarginArray.length > 1
          ? MarginArray.slice(index * quotesLength, (index + 1) * quotesLength)
          : MarginArray,
      sumary: true,
    };
  });
};

const getSubtotalData = (data, type) => {
  const [subtotalArray, scopeTypeLength, quotesLength] = transformSummaryData(
    data,
    type,
    "subtotal"
  );
  return data[0].node[`${type}`].map((e, index) => {
    return {
      name: e.fields.subtotal.label,
      order: e.fields.subtotal.order,
      isDisplay: e.fields.subtotal.isDisplay,
      sir: moneyFormat(e.sirSubtotal),
      variation: moneyFormat(e.validationSubtotal),
      scoping: moneyFormat(e.subtotal),
      quoting:
        subtotalArray.length > 1
          ? subtotalArray.slice(
              index * quotesLength,
              (index + 1) * quotesLength
            )
          : subtotalArray,
      sumary: true,
    };
  });
};

const getGstData = (data, type) => {
  const [gstArray, scopeTypeLength, quotesLength] = transformSummaryData(
    data,
    type,
    "gst"
  );
  return data[0].node[`${type}`].map((e, index) => {
    return {
      name: e.fields.gst.label,
      order: e.fields.gst.order,
      isDisplay: e.fields.gst.isDisplay,
      sir: moneyFormat(e.sirGst),
      variation: moneyFormat(e.validationGst),
      scoping: moneyFormat(e.gst),
      quoting:
        gstArray.length > 1
          ? gstArray.slice(index * quotesLength, (index + 1) * quotesLength)
          : gstArray,
      sumary: true,
    };
  });
};

const getTotalData = (data, type) => {
  const [totalArray, scopeTypeLength, quotesLength] = transformSummaryData(
    data,
    type,
    "total"
  );
  return data[0].node[`${type}`].map((e, index) => {
    return {
      name: e.fields.total.label,
      order: e.fields.total.order,
      isDisplay: e.fields.total.isDisplay,
      sir: moneyFormat(e.sirTotal),
      variation: moneyFormat(e.validationTotal),
      scoping: moneyFormat(e.total),
      quoting:
        totalArray.length > 1
          ? totalArray.slice(index * quotesLength, (index + 1) * quotesLength)
          : totalArray,
      sumary: true,
    };
  });
};

const sortSummaryData = (arr, str, end) => {
  const preSorted = arr.slice(0, str);
  const postSorted = arr.slice(end);
  const sorted = arr.slice(str, end).sort((a, b) => a.order - b.order);
  arr.length = 0;
  arr.push.apply(arr, preSorted.concat(sorted).concat(postSorted));
  return arr;
};

const getSummaryData = (data, final, lengthOfCostCentre, type) => {
  const margin = getMarginData(data, type);
  const discount = getDiscountData(data, type);
  const subTotal = getSubtotalData(data, type);
  const gst = getGstData(data, type);
  const total = getTotalData(data, type);
  const emptyLine = [...Array(total.length)].map(() => {
    return { name: null };
  });
  emptyLine.forEach((e, index) => {
    const order =
      lengthOfCostCentre
        .map((e) => e + 1)
        .filter((_e, ind) => ind <= index)
        .reduce((a, b) => a + b, 0) + index;
    final.splice(order, 0, e);
  });
  discount.forEach((e, index) => {
    const order =
      lengthOfCostCentre
        .map((e) => e + 2)
        .filter((_e, ind) => ind <= index)
        .reduce((a, b) => a + b, 0) + index;
    final.splice(order, 0, e);
  });
  margin.forEach((e, index) => {
    const order =
      lengthOfCostCentre
        .map((e) => e + 3)
        .filter((_e, ind) => ind <= index)
        .reduce((a, b) => a + b, 0) + index;
    final.splice(order, 0, e);
  });
  subTotal.forEach((e, index) => {
    const order =
      lengthOfCostCentre
        .map((e) => e + 4)
        .filter((_e, ind) => ind <= index)
        .reduce((a, b) => a + b, 0) + index;
    final.splice(order, 0, e);
  });
  gst.forEach((e, index) => {
    const order =
      lengthOfCostCentre
        .map((e) => e + 5)
        .filter((_e, ind) => ind <= index)
        .reduce((a, b) => a + b, 0) + index;
    final.splice(order, 0, e);
  });
  total.forEach((e, index) => {
    const order =
      lengthOfCostCentre
        .map((e) => e + 6)
        .filter((_e, ind) => ind <= index)
        .reduce((a, b) => a + b, 0) + index;
    final.splice(order, 0, e);
  });
  lengthOfCostCentre.forEach((e, index) => {
    if (index === 0) {
      sortSummaryData(final, e + 2, e + 7);
    }
    if (index > 0) {
      const preOrderSum = lengthOfCostCentre
        .filter((_e, ind) => ind < index)
        .map((e) => e + 7)
        .reduce((a, b) => a + b, 0);
      sortSummaryData(final, preOrderSum + e + 2, preOrderSum + e + 7);
    }
  });
  return final;
};

const useExpand = (e) => {
  // const [expand, setExpand] = React.useState(true)
  return {
    scopeType: e.scopeType.name,
    isScopeType: true,
    isProcessed: true,
    subtotal: e.subtotal,
    total: e.total,
    gst: e.gst,
    // expand: expand,
    // setExpand: setExpand
  };
};

// const getExpand = (data, length) => {
//   const expandArray = data.filter(e => e.isScopeType).map(e => e.expand)
//   const lengthArrayWithExtra = length.map(e => e + 7)
//   const orderArray = lengthArrayWithExtra.map((_e, index) => lengthArrayWithExtra.slice(0, index + 1).reduce((a, b) => a + b))
//   const getOrder = (index, array) => {
//     const order = []
//     array.forEach((e, ind) => {
//       if(index < e) order.push(ind)
//     })
//     return Math.min(...order)
//   }
//   return data.map((e, index) => {
//     return {...e, ...e['expand'] = expandArray[getOrder(index, orderArray)]}
//   })
// }
