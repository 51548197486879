const transformFilterGroup = data => {
  if(data.constructor === Object) {
    const keyArray = Object.keys(data)
    const valuesArray = Object.values(data).map(e => {
        if(Object.keys(e).length > 0 && e.constructor === Object){
          return Object.values(e).reduce((prev,next)=>{
                return prev.concat(next)
              })
        } else if (Array.isArray(e)) {
          return e
        } else {
          return undefined
        }
      })
    return keyArray.reduce((obj, k, i) => ({...obj, [k]: valuesArray[i] }), {})
  }
}
export default transformFilterGroup