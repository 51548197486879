/**
 * @flow
 * @relayHash 4fa0f38200fe8ff8d1cffca999a18560
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimUniqueWhere = {|
  claimId: string
|};
export type AdjusterInitialCallMutationVariables = {|
  where: ClaimUniqueWhere
|};
export type AdjusterInitialCallMutationResponse = {|
  +adjusterInitialCall: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type AdjusterInitialCallMutation = {|
  variables: AdjusterInitialCallMutationVariables,
  response: AdjusterInitialCallMutationResponse,
|};
*/


/*
mutation AdjusterInitialCallMutation(
  $where: ClaimUniqueWhere!
) {
  adjusterInitialCall(where: $where) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimUniqueWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "adjusterInitialCall",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ClaimUniqueWhere!"
      }
    ],
    "concreteType": "ClaimJobPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdjusterInitialCallMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AdjusterInitialCallMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AdjusterInitialCallMutation",
    "id": null,
    "text": "mutation AdjusterInitialCallMutation(\n  $where: ClaimUniqueWhere!\n) {\n  adjusterInitialCall(where: $where) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '954744a485f52d84c98bfb0c1fb0ed68';
module.exports = node;
