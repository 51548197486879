/**
 * @flow
 * @relayHash 7e9214b02010b2b73328ed3e833f63b8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ENDataEntityKey = {|
  id: string
|};
export type QuoteInitialCallContentMutationVariables = {|
  where: ENDataEntityKey
|};
export type QuoteInitialCallContentMutationResponse = {|
  +jobContentInitialCall: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type QuoteInitialCallContentMutation = {|
  variables: QuoteInitialCallContentMutationVariables,
  response: QuoteInitialCallContentMutationResponse,
|};
*/


/*
mutation QuoteInitialCallContentMutation(
  $where: ENDataEntityKey!
) {
  jobContentInitialCall(where: $where) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "jobContentInitialCall",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ENDataEntityKey!"
      }
    ],
    "concreteType": "JobQuotePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "QuoteInitialCallContentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "QuoteInitialCallContentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "QuoteInitialCallContentMutation",
    "id": null,
    "text": "mutation QuoteInitialCallContentMutation(\n  $where: ENDataEntityKey!\n) {\n  jobContentInitialCall(where: $where) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd5592000f2c120351d69d79dcdc60fa1';
module.exports = node;
