/**
 * @flow
 * @relayHash 50c92a6b1a3bdbd3509ca075bc2bfcaa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EstimatorIndex = {|
  isDisabled?: ?boolean
|};
export type EstimatorQueryVariables = {|
  after?: ?string,
  first?: ?number,
  where?: ?EstimatorIndex,
|};
export type EstimatorQueryResponse = {|
  +estimators: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +phoneNumber: string,
        +disabled: boolean,
      |}
    |}>
  |}
|};
export type EstimatorQuery = {|
  variables: EstimatorQueryVariables,
  response: EstimatorQueryResponse,
|};
*/


/*
query EstimatorQuery(
  $after: String
  $first: Int
  $where: EstimatorIndex
) {
  estimators(after: $after, first: $first, where: $where) {
    edges {
      node {
        id
        name
        phoneNumber
        disabled
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "EstimatorIndex",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "estimators",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "EstimatorIndex"
      }
    ],
    "concreteType": "EstimatorConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "EstimatorEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Estimator",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "phoneNumber",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "disabled",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EstimatorQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "EstimatorQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "EstimatorQuery",
    "id": null,
    "text": "query EstimatorQuery(\n  $after: String\n  $first: Int\n  $where: EstimatorIndex\n) {\n  estimators(after: $after, first: $first, where: $where) {\n    edges {\n      node {\n        id\n        name\n        phoneNumber\n        disabled\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c0c530c4422e407f7a106af2b8af4ac';
module.exports = node;
