/**
 * @flow
 * @relayHash 05ca864f999a2b3378218b0fbe5a35b0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type ResetClaimActionMutationVariables = {|
  where: ENDataPortfolioKey
|};
export type ResetClaimActionMutationResponse = {|
  +claimResetFinalise: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +claimStatus: ?{|
        +statusId: number,
        +statusName: ?string,
      |},
      +finaliseReason: ?{|
        +name: string
      |},
      +dateCompleted: ?any,
    |},
  |}
|};
export type ResetClaimActionMutation = {|
  variables: ResetClaimActionMutationVariables,
  response: ResetClaimActionMutationResponse,
|};
*/


/*
mutation ResetClaimActionMutation(
  $where: ENDataPortfolioKey!
) {
  claimResetFinalise(where: $where) {
    success
    messages
    result {
      claimStatus {
        statusId
        statusName
        id
      }
      finaliseReason {
        name
        id
      }
      dateCompleted
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dateCompleted",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ResetClaimActionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimResetFinalise",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "claimStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimStatus",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "finaliseReason",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimFinaliseReason",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ]
              },
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ResetClaimActionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimResetFinalise",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "claimStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimStatus",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v8/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "finaliseReason",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimFinaliseReason",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/)
                ]
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ResetClaimActionMutation",
    "id": null,
    "text": "mutation ResetClaimActionMutation(\n  $where: ENDataPortfolioKey!\n) {\n  claimResetFinalise(where: $where) {\n    success\n    messages\n    result {\n      claimStatus {\n        statusId\n        statusName\n        id\n      }\n      finaliseReason {\n        name\n        id\n      }\n      dateCompleted\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d3bb01b6b086214aab6b64bd8bf5d3b';
module.exports = node;
