import React, { Component } from "react";
import environment from "common/relay";
import { graphql, QueryRenderer } from "react-relay";

import { Formik, Form } from "formik";
import Dialog from "components/Mui/Dialog";
import Button from "components/Mui/Button";
import Input from "components/Formik/Input";
import Grid from "components/Mui/Grid";
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon";
import AwaitingInfoActionMutation from "./AwaitingInfoActionMutation";
import CancelAwaitingInfoActionMutation from "./CancelAwaitingInfoActionMutation";

const query = graphql`
  query AwaitingInfoActionQuery($claimJobWhere: ENDataEntityKey!) {
    building: claimFilterOptions(
      where: { subject: "awaitingInfoReasons", portfolios: Building }
    ) {
      name
      value
    }
    contents: claimFilterOptions(
      where: { subject: "awaitingInfoReasons", portfolios: Contents }
    ) {
      name
      value
    }
    restoration: claimFilterOptions(
      where: { subject: "awaitingInfoReasons", portfolios: Restoration }
    ) {
      name
      value
    }
    claimJob(where: $claimJobWhere) {
      hasBuilding
      hasContents
      hasRestoration
      building {
        claimStatus {
          statusId
          statusName
        }
      }

      restoration {
        claimStatus {
          statusId
          statusName
        }
      }

      contents {
        claimStatus {
          statusId
          statusName
        }
      }
    }
  }
`;

class AwaitingInfoAction extends Component {
  state = {
    buildingStatusId: "",
    contentStatusId: "",
    restorationStatusId: "",
  };

  snackbar = window[Symbol.for("__snackbar")];

  render() {
    const { dlRef, claimId, meta } = this.props;

    return (
      <Dialog
        innerRef={dlRef}
        title="New Awaiting Info"
        noForm
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              claimJobWhere: {
                id: claimId,
              },
            }}
            render={({ error, props }) => {
              if (!props) return "Loading...";
              return (
                <Grid container justify="space-evenly" alignItems="stretch">
                  {[
                    {
                      unmountOn:
                        !props.claimJob.hasBuilding ||
                        (!meta.user.type.isAdmin && !meta.user.type.isBuilder),
                      type: "Building",
                    },
                    {
                      unmountOn:
                        !props.claimJob.hasContents ||
                        (!meta.user.type.isAdmin && !meta.user.type.isContent),
                      type: "Contents",
                    },
                    {
                      unmountOn:
                        !props.claimJob.hasRestoration ||
                        (!meta.user.type.isAdmin && !meta.user.type.isRestorer),
                      type: "Restoration",
                    },
                  ].map(({ unmountOn, type }, key) => {
                    if (!unmountOn)
                      return (
                        <Grid item xs key={key}>
                          <Grid
                            fluid
                            spacing={8}
                            justify="center"
                            style={{ height: "100%" }}
                          >
                            <Grid item xs={12}>
                              <Grid fluid spacing={8} justify="center">
                                <ClaimTypeIcon
                                  style={{ fontSize: "2.5rem" }}
                                  type={type}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              {props.claimJob[type.toLowerCase()].claimStatus
                                .statusId === 11 ? (
                                <Grid
                                  fluid
                                  spacing={8}
                                  justify="center"
                                  alignItems="center"
                                >
                                  Claim finalised
                                </Grid>
                              ) : props.claimJob[type.toLowerCase()].claimStatus
                                  .statusId === 33 ||
                                (type === "Contents" &&
                                  props.claimJob[type.toLowerCase()].claimStatus
                                    .statusId === 18) ? (
                                <Grid
                                  fluid
                                  spacing={8}
                                  justify="center"
                                  alignItems="center"
                                >
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    label="Cancel Awaiting Info"
                                    onClick={(e) =>
                                      this.handleCancel({
                                        ...e,
                                        portfolioType: type,
                                      })
                                    }
                                  />
                                </Grid>
                              ) : (
                                <Formik
                                  initialValues={{
                                    awaitingInfoReasonId: "",
                                    note: "",
                                  }}
                                  validate={(values) => {
                                    let errors = {};
                                    if (!values.awaitingInfoReasonId)
                                      errors.awaitingInfoReasonId = "Required!";

                                    return errors;
                                  }}
                                  onSubmit={async (values, actions) => {
                                    const res =
                                      await AwaitingInfoActionMutation({
                                        input: {
                                          awaitingInfoReasonId: parseInt(
                                            values.awaitingInfoReasonId
                                          ),
                                          note: values.note,
                                        },
                                        where: {
                                          id: this.props.claimId,
                                          portfolioType: type,
                                        },
                                      });
                                    if (res.ok) {
                                      if (res.success) {
                                        this.snackbar.toggleOpen({
                                          message: "Awaiting for information",
                                        });
                                        this.props.handleRefresh();
                                      } else {
                                        this.snackbar.toggleOpen({
                                          message: res.message,
                                        });
                                      }
                                    }
                                  }}
                                >
                                  {({ isSubmitting, values }) => {
                                    return (
                                      <Form>
                                        <Grid
                                          fluid
                                          spacing={8}
                                          justify="center"
                                        >
                                          <Grid item xs={12}>
                                            <Input
                                              required
                                              name="awaitingInfoReasonId"
                                              label="Reason"
                                              options={props[
                                                type.toLowerCase()
                                              ].map(({ name, value }) => ({
                                                label: name,
                                                value,
                                              }))}
                                              select
                                              value={
                                                values.awaitingInfoReasonId
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Input
                                              type="text"
                                              label="Additional Notes"
                                              multiline
                                              rowsMax={10}
                                              name="note"
                                            />
                                          </Grid>
                                          <Button
                                            fullWidth
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            label="Wait For Info"
                                            disabled={isSubmitting}
                                          />
                                        </Grid>
                                      </Form>
                                    );
                                  }}
                                </Formik>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    return null;
                  })}
                </Grid>
              );
            }}
          />
        }
        // actions={[
        //   <Button label='Submit' type='submit' variant='contained' color='primary'/>
        // ]}
      />
    );
  }

  handleCancel = async ({ portfolioType }) => {
    const res = await CancelAwaitingInfoActionMutation({
      where: {
        id: this.props.claimId,
        portfolioType,
      },
    });

    if (res.ok) {
      if (res.success) {
        this.snackbar.toggleOpen({ message: "Cancelled" });
        this.props.handleRefresh();
      } else {
        this.snackbar.toggleOpen({ message: res.message });
      }
    }
  };
}

export default AwaitingInfoAction;
