import React from 'react'
import Paper from 'components/Mui/Paper'
import { Grid } from '@material-ui/core'
import { Formik } from 'formik'
import { SingleReactSelect } from 'components/Formik/ReactSelect'
import DateTimeInput from 'components/Formik/DateTimeInput'
import DateInput from 'components/Formik/DateInput'
import Input from 'components/Mui/Input'
import Switch from 'components/Formik/Switch'
import carplanimg from './carplanimg.png'
import carimg from './carimg.png'

const VehicleReportContent = () => {
  return (
      <Formik onSubmit={()=>{}} >
        {props => {
          return (
            <Grid container style={{marginTop:'8px', width:'100%'}} >
              <Grid item xs={12}>
                <CompanyName />
              </Grid>
              <Grid item xs={12}>
                <AccidentDetails />
              </Grid>
              <Grid item xs={12}>
                <DriverDetails trafficOffence={props.values.trafficOffence} contactAsAbove={props.values.contactAsAbove}/>
              </Grid>
              <Grid item xs={12}>
                <VehicleDetails />
              </Grid>
              <Grid item xs={12}>
                <ThirdPartyDetails />
              </Grid>
              <Grid item xs={12}>
                <Witness witness={props.values.witness}/>
              </Grid>
              <Grid item xs={12}>
                <Police policeAttend={props.values.policeAttend}/>
              </Grid>
            </Grid>
          )
        }}
      </Formik>
  )
}

export default VehicleReportContent

const CompanyName = () => {
  return (
    <Paper title='Company name'>
      <Grid container justify="space-between">
        <Grid xs={6}>
          <Input label="Company name" />
        </Grid>
        <Grid xs={4}>
          <Input label="Policy No." />
        </Grid>
      </Grid>
    </Paper>
  )
}

const AccidentDetails = () => {
  return (
    <Paper title='Accident details'>
      <Grid container justify="space-between">
        <Grid xs={6}>
          <DateTimeInput label='Date of accident' name='datetime' />
        </Grid>
        <Grid xs={12}>
          <Input label="Place of accident" />
        </Grid>
        <Grid xs={12}>
          <Input label="Town/Suburb" />
        </Grid>
        <Grid xs={10} style={{display:"flex", alignItems:'center'}}>
            <div>Speed at time of accident - your vehicle</div>
            <Grid item xs={2} style={{marginLeft:'5px'}}>
              <Input label="K/mh" />
            </Grid>
        </Grid>
        <Grid xs={5}>
          <SingleReactSelect label="Weather conditions" select={true} name='weatherCondition'
          options={[{label:'Sunny', value:1},{label:'Overcast', value:2},{label:'Raining', value:3}]} />
        </Grid>
        <Grid xs={5}>
          <SingleReactSelect label="Conditions of road" select={true} name='roadCondition'
          options={[{label:'Wet', value:1},{label:'Dry', value:2},{label:'Rough', value:3}]} />
        </Grid>
        <Grid xs={12}>
          <Input label="Describe accident circumstances" multiline rows={5}/>
        </Grid>
        <Grid xs={12}>
          <img src={carplanimg} alt="Car Plan" style={{width:'100%'}}/>
        </Grid>
      </Grid>
    </Paper>
  )
}

const DriverDetails = props => {
  return (
    <Paper title='Driver details'>
      <Grid container justify="space-between">
        <Grid xs={6}>
          <Input label="Surname" />
        </Grid>
        <Grid xs={6}>
          <Input label="Given name(s)" />
        </Grid>
        <Grid xs={6}>
          <Input label="Occupation" />
        </Grid>
        <Grid xs={6}>
          <Input label="Telephone No. (work)" />
        </Grid>
        <Grid xs={6}>
          <Input label="Licence No." />
        </Grid>
        <Grid xs={2}>
          <DateInput label='Expiry date' name='expiryDate' />
        </Grid>
        <Grid xs={2}>
          <DateInput label='Date of birth' name='DOB' />
        </Grid>
        <Grid xs={1}>
          <Input label="Age" />
        </Grid>
        <Grid xs={8}>
          <span>Have you ever been convicted of any traffic offence or had your licence suspended?</span>
          <SingleReactSelect label="Conditions of road" select={true} name='trafficOffence'
          options={[{label:'Yes', value:1},{label:'No', value:2}]} />
        </Grid>
        <Grid xs={12}>
          <Input label="If Yes, please give details" multiline rows={3}
          disabled={props.trafficOffence !== 1}
          />
        </Grid>
          <Grid xs={10} style={{display:"flex", alignItems:'center'}}>
              <div>Use of vehicle at the time of accident/loss:</div>
              <Grid item xs={4} style={{marginLeft:'5px'}}>
                <SingleReactSelect label="Use of vehicle" select={true} name='useOfVehicle'
                options={[{label:'Business', value:1},{label:'Private', value:2}]} />
              </Grid>
          </Grid>
          <Grid xs={12} style={{display:"flex", alignItems:'center'}}>
              <div>Preferred contact for the claim as above:</div>
              <Grid item xs={1} style={{marginLeft:'5px'}}>
                <Switch name="contactAsAbove"/>
              </Grid>
              <div>(We may contact this contact for an assessment) </div>
          </Grid>
          <Grid xs={6}>
            <Input label="Name" disabled={props.contactAsAbove}/>
          </Grid>
          <Grid xs={6}>
            <Input label="Phone" disabled={props.contactAsAbove}/>
          </Grid>
          <Grid xs={12}>
            <Input label="Email" disabled={props.contactAsAbove}/>
          </Grid>
      </Grid>
    </Paper>
  ) 
}

const VehicleDetails = () => {
  return (
    <Paper title='Your vehicle details'>
      <Grid container justify="space-between">
        <Grid container xs={8} justify="space-between">
          <Grid item xs={6}>
            <Input label="Registration No." />
          </Grid>
          <Grid item xs={6}>
            <DateInput label='Reg. Expiry date' name='regexpirydate' />
          </Grid>
          <Grid item xs={6}>
              <Input label="Vehicle type" />
          </Grid>
          <Grid item xs={6}>
              <Input label="Vehicle make" />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <img src={carimg} alt="Car img" style={{width:'100%', height:'100%'}}/>
        </Grid>
      </Grid>
    </Paper>
  )
}

const ThirdPartyDetails = () => {
  return (
    <Paper title='Third party details'>
      <Grid container justify="space-between">
        <Grid xs={8}>
          <Input label="Drivers name" />
        </Grid>
        <Grid xs={4}>
          <Input label="Telephone No." />
        </Grid>
        <Grid xs={9}>
            <Input label="Address" />
        </Grid>
        <Grid xs={1}>
            <Input label="State" />
        </Grid>
        <Grid xs={1}>
            <Input label="Postcode" />
        </Grid>
        <Grid xs={8}>
          <Input label="Owners name" />
        </Grid>
        <Grid xs={4}>
          <Input label="Telephone No." />
        </Grid>
        <Grid xs={9}>
            <Input label="Address" />
        </Grid>
        <Grid xs={1}>
            <Input label="State" />
        </Grid>
        <Grid xs={1}>
            <Input label="Postcode" />
        </Grid>
        <Grid container xs={12}>
          <Grid container xs={8}>
            <Grid item xs={12}>
              <Input label="Name of insurance company" />
            </Grid>
            <Grid item xs={12}>
              <Input label="Policy No." />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <img src={carimg} alt="Car img" style={{width:'100%', height:'100%'}}/>
          </Grid>
        </Grid>
        <Grid xs={4}>
            <Input label="Licence No." />
        </Grid>
        <Grid xs={4}>
          <DateInput label='Date of birth' name='DOB2' />
        </Grid>
        <Grid xs={4}>
            <Input label="Registration No." />
        </Grid>
        <Grid xs={6}>
            <Input label="Vehicle type" />
        </Grid>
        <Grid xs={6}>
            <Input label="Vehicle make" />
        </Grid>
        <Grid xs={12}>
          <Input label="Description of damage to vehicle" multiline rows={5}/>
          <span>*(if more than one vehicle involved attach details)</span>
        </Grid>
      </Grid>
    </Paper>
  )
}

const Witness = props => {
  return (
    <Paper title='Witness'>
      <Grid container justify="space-between">
        <Grid xs={10} style={{display:"flex", alignItems:'center'}}>
          <div>Were there any witnesses to the accident: </div>
          <Grid item xs={2} style={{marginLeft:'5px'}}>
            <Switch name="witness"/>
          </Grid>
        </Grid>
        <Grid xs={12}>
            <Input label="Witness name" disabled={!props.witness}/>
        </Grid>
        <Grid xs={9}>
          <Input label="Witness address" disabled={!props.witness}/>
        </Grid>
        <Grid xs={1}>
          <Input label="State" disabled={!props.witness}/>
        </Grid>
        <Grid xs={1}>
          <Input label="Postcode" disabled={!props.witness}/>
        </Grid>
        <Grid xs={6}>
          <SingleReactSelect select={true} name='typewitness' disabled={!props.witness}
          options={[{label:'Independent', value:1},{label:'Your vehicle', value:2},{label:'Third party vehicle', value:3}]} />
        </Grid>
        <Grid xs={10} style={{display:"flex", alignItems:'center'}}>
          <div>Note: Passengers in your Vehicle </div>
          <Grid item xs={4} style={{marginLeft:'5px'}}>
            <Input label="Phone contact" disabled={!props.witness}/>
          </Grid>
          <span>*(Other witness please attach details)</span>
        </Grid>
      </Grid>
    </Paper>
  )
}

const Police = props => {
  return (
    <Paper title='Your vehicle details'>
      <Grid container justify="space-between">
        <Grid xs={6}>
          <Grid xs={10} style={{display:"flex", alignItems:'center'}}>
            <div>Were Police advised of the accident? </div>
            <Grid item xs={4} style={{marginLeft:'5px'}}>
              <Switch name="policeAdvised"/>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6}>
          <Grid xs={10} style={{display:"flex", alignItems:'center'}}>
            <div>Did Police attend the accident? </div>
            <Grid item xs={4} style={{marginLeft:'5px'}}>
              <Switch name="policeAttend"/>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
            <Input label="Police station" />
        </Grid>
        <Grid xs={10} style={{display:"flex", alignItems:'center'}}>
          <div>If Yes, Police report #</div>
          <Grid item xs={4} style={{marginLeft:'5px'}}>
            <Input label="Police report" disabled={!props.policeAttend}/>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}