import React from "react";
import ScopeGroupTable from "./ScopeGroupTable";
import environment from "common/relay";
import { graphql, QueryRenderer } from "react-relay";
import ScopeGroupCostCentreTable from "./ScopeGroupCostCentreTable";

const query = graphql`
  query ScopeItemListQuery($where: JobQuoteIndex!) {
    jobQuotes(where: $where) {
      edges {
        node {
          jobQuoteId
          actions {
            completeValidation {
              id
              isDisabled
              isDisplay
              label
            }
            resetValidation {
              label
              isDisabled
              isDisplay
            }
            submitValidation {
              label
              isDisabled
              isDisplay
            }
          }
          scopeTypes {
            properties {
              locations {
                location {
                  locationName
                  costLocationId
                  id
                }
                items {
                  jobQuoteItemId
                  quoteItemType
                  accepted
                  actions {
                    accept {
                      label
                      isDisabled
                      isDisplay
                    }
                    decline {
                      label
                      isDisabled
                      isDisplay
                    }
                    amend {
                      label
                      isDisabled
                      isDisplay
                    }
                    validate {
                      label
                      isDisabled
                      isDisplay
                    }
                  }
                  location {
                    costLocationId
                    locationName
                  }
                  dimensions
                  costCentre {
                    costCentreId
                    costCentreName
                  }
                  costItem {
                    costItemId
                    itemDescription
                  }
                  lineDescription
                  unit {
                    unitId
                    unitName
                  }
                  directsupply
                  purchasePrice
                  ageOfItem
                  proofOfLoss
                  policyLimit
                  qty
                  rate
                  marginRate
                  subtotal
                  logdate
                  policyLimit
                  requestedQuote
                  sirRate
                  lineStatusDescription
                  note
                  scopeType {
                    name
                    id
                  }
                }
                validationItems {
                  jobQuoteItemId
                  quoteItemType
                  accepted
                  actions {
                    accept {
                      label
                      isDisabled
                      isDisplay
                    }
                    decline {
                      label
                      isDisabled
                      isDisplay
                    }
                    amend {
                      label
                      isDisabled
                      isDisplay
                    }
                    validate {
                      label
                      isDisabled
                      isDisplay
                    }
                  }
                  location {
                    costLocationId
                    locationName
                  }
                  dimensions
                  costCentre {
                    costCentreId
                    costCentreName
                  }
                  costItem {
                    costItemId
                    itemDescription
                  }
                  lineDescription
                  unit {
                    unitId
                    unitName
                  }
                  directsupply
                  purchasePrice
                  ageOfItem
                  proofOfLoss
                  policyLimit
                  qty
                  rate
                  marginRate
                  subtotal
                  logdate
                  policyLimit
                  requestedQuote
                  sirRate
                  lineStatusDescription
                  note
                  scopeType {
                    name
                    id
                  }
                }
              }
            }
            costCentres {
              costCentre {
                costCentreName
                costCentreId
                costType
                directSupplier
              }
              units {
                qty
                subtotal
                unitName
                validationQty
                validationSubtotal
              }
            }
            locations {
              id
              location {
                id
                costLocationId
                locationName
              }
              items {
                jobQuoteItemId
                quoteItemType
                accepted
                actions {
                  accept {
                    label
                    isDisabled
                    isDisplay
                  }
                  decline {
                    label
                    isDisabled
                    isDisplay
                  }
                  amend {
                    label
                    isDisabled
                    isDisplay
                  }
                  validate {
                    label
                    isDisabled
                    isDisplay
                  }
                }
                location {
                  costLocationId
                  locationName
                }
                dimensions
                costCentre {
                  costCentreId
                  costCentreName
                }
                costItem {
                  costItemId
                  itemDescription
                }
                lineDescription
                unit {
                  unitId
                  unitName
                }
                directsupply
                purchasePrice
                ageOfItem
                proofOfLoss
                policyLimit
                qty
                rate
                marginRate
                subtotal
                logdate
                policyLimit
                requestedQuote
                sirRate
                lineStatusDescription
                note
                scopeType {
                  name
                  id
                }
              }
            }
            scopeType {
              id
              name
            }
            id
            gst
            margin
            sirGst
            sirMargin
            sirSubtotal
            sirTotal
            subtotal
            total
            discount
            fields {
              marginBreakdown {
                isDisplay
                label
              }
              discount {
                isDisplay
                label
                isDisabled
              }
              sIRRate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              gst {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              total {
                label
                isDisplay
              }
              action {
                label
                isDisplay
              }
              costCentre {
                label
                isDisplay
              }
              description {
                label
                isDisplay
              }
              marginRate {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              qty {
                label
                isDisplay
              }
              rate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              unit {
                label
                isDisplay
              }
            }
          }
        }
      }
    }
  }
`;

const ScopeItemList = ({
  classes,
  quote,
  meta,
  portfolio,
  isAction,
  isValidationAction,
  setValidationRefresh,
  isProperties,
  isShowAction,
  isDisplay,
  isValidationItem,
}) => {
  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    window[Symbol.for("__refreshScopeList")] = () => setRefresh(!refresh);
    return () => {
      setRefresh(false);
      delete window[Symbol.for("__refreshScopeList")];
    };
  }, [refresh]);

  if (quote) {
    const tableArray = quote && quote.scopeTypes;
    if (!tableArray) return null;
    return (
      <div>
        {tableArray.map((e, index) => {
          return (
            <ScopeGroupTable
              key={index}
              quote={quote}
              portfolio={portfolio}
              isAction={isAction}
              meta={meta}
              //validationRefresh={validationRefresh}
              isValidationAction={isValidationAction}
              tableItem={e}
              isProperties={isProperties}
              isShowAction={isShowAction}
              setRefresh={setRefresh}
            />
          );
        })}
        {isDisplay && meta.user.type.isAdmin && (
          <div style={{ marginTop: "5rem" }}>
            <ScopeGroupCostCentreTable
              quote={quote}
              portfolio={portfolio}
              meta={meta}
              setRefresh={setRefresh}
            />
          </div>
        )}
      </div>
    );
  } else
    return (
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{
          where: {
            claimId: meta.claim.claimId,
            portfolios: portfolio,
            requestCategory: "ScopeValidation",
          },
          refresh,
        }}
        render={({ error, props }) => {
          if (!props) return "Loading...";
          const quote =
            props.jobQuotes.edges.length !== 0
              ? props.jobQuotes.edges[0].node
              : null;
          if (!quote || !quote.scopeTypes.length) return "No Data";
          const tableArray = quote && quote.scopeTypes;
          if (!tableArray) return null;
          return (
            <div>
              {tableArray.map((e, index) => {
                return (
                  <ScopeGroupTable
                    key={index}
                    quote={quote}
                    portfolio={portfolio}
                    isAction={isAction}
                    meta={meta}
                    //validationRefresh={validationRefresh}
                    isValidationAction={isValidationAction}
                    setValidationRefresh={setValidationRefresh}
                    tableItem={e}
                    isProperties={isProperties}
                    isShowAction={isShowAction}
                    setRefresh={setRefresh}
                    isValidationItem={isValidationItem}
                  />
                );
              })}
              {isDisplay && meta.user.type.isAdmin && (
                <ScopeGroupCostCentreTable
                  //quote={quote}
                  portfolio={portfolio}
                  meta={meta}
                  //setRefresh={setRefresh}
                  isDisplay={isDisplay}
                />
              )}
            </div>
          );
        }}
      />
    );
};

export default ScopeItemList;
