/**
 * @flow
 * @relayHash b834f2fcc73e3ca020ed21e8bb6c72b5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimItemFilter = {|
  id?: ?number,
  claimId: string,
  keyWords?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type GetDocumentsPdfMutationVariables = {|
  where: ClaimItemFilter
|};
export type GetDocumentsPdfMutationResponse = {|
  +pdf: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type GetDocumentsPdfMutation = {|
  variables: GetDocumentsPdfMutationVariables,
  response: GetDocumentsPdfMutationResponse,
|};
*/


/*
mutation GetDocumentsPdfMutation(
  $where: ClaimItemFilter!
) {
  pdf: pdfProduceForClaimDocuments(where: $where) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimItemFilter!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "pdf",
    "name": "pdfProduceForClaimDocuments",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ClaimItemFilter!"
      }
    ],
    "concreteType": "GeneralPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GetDocumentsPdfMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GetDocumentsPdfMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "GetDocumentsPdfMutation",
    "id": null,
    "text": "mutation GetDocumentsPdfMutation(\n  $where: ClaimItemFilter!\n) {\n  pdf: pdfProduceForClaimDocuments(where: $where) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6de9378b167a3b85ef892e724b789e98';
module.exports = node;
