import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation OnsiteMakeSafeCompletedMutation($where: ENDataPortfolioKey!) {
    claimMakeSafeComplete(where: $where) {
      messages
      success
      result {
        makesafeCompletedDate
      }
    }
  }
`

export default (variables, id) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      updater: store => {
        const success = store.getRootField('claimMakeSafeComplete')
        .getValue('success')
        if(success) {
          const claim = store.get(id)
          const claimPortfolio = claim.getLinkedRecord(variables.where.portfolioType.toLowerCase())
          
          const newMakesafeCompletedDate = store.getRootField('claimMakeSafeComplete')
            .getLinkedRecord('result')
            .getValue('makesafeCompletedDate')

          claimPortfolio.setValue(newMakesafeCompletedDate, 'makesafeCompletedDate')
        }
      },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.claimMakeSafeComplete.success,
          messages: res.claimMakeSafeComplete.messages
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}