import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const mutation = graphql`
  mutation StartVariationValidationMutation(
    $input: JobValidationStartInput!
    $where: ENDataPortfolioKey!
  ) {
    jobVariationValidationStart(where: $where, input: $input) {
      success
      messages
      result {
        actions {
          completeValidation {
            isDisabled
            isDisplay
            label
          }
          resetValidation {
            isDisabled
            isDisplay
            label
          }
          submitValidation {
            isDisabled
            isDisplay
            label
          }
          viewValidation {
            isDisabled
            isDisplay
            label
          }
          startValidation {
            isDisabled
            isDisplay
            label
          }
          # completeVariationValidation {
          #   isDisabled
          #   isDisplay
          #   label
          # }
          # resetVariationValidation {
          #   isDisabled
          #   isDisplay
          #   label
          # }
          # submitVariationValidation {
          #   isDisabled
          #   isDisplay
          #   label
          # }
          # viewVariationValidation {
          #   isDisabled
          #   isDisplay
          #   label
          # }
          # startValidation {
          #   isDisabled
          #   isDisplay
          #   label
          # }
          # startVariationValidation {
          #   isDisabled
          #   isDisplay
          #   label
          # }
        }
        id
        variationId
      }
    }
  }
`;

export default (variables) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (res) => {
        resolve({
          ok: true,
          success: res.jobVariationValidationStart.success,
          messages: res.jobVariationValidationStart.messages,
        });
      },
      onError: (errors) => resolve({ ok: false }),
    });
  });
};
