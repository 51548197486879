import React, { Component } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import { states, claimCategories } from "common/static_data";

import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
import Button from "components/Mui/Button";

import Input from "components/Formik/Input/";
import Switch from "components/Formik/Switch/";
import PhoneInput from "components/Formik/PhoneInput/";

class ClientInfoCard extends Component {
  finaliseClaimDl = React.createRef();

  render() {
    const { claim, user, metaClaim, actions } = this.props;
    const { isAdmin } = user.type;
    const { isDisabled } = metaClaim.view.actions.claimFinalise;
    return (
      <Paper
        title={
          "Customer & Property Information" /* label:'Client & Property Information'|'Customer & Property Information' */
        }
        content={
          <Grid fluid spacing={0}>
            <Grid item xs={6}>
              <Grid fluid>
                {[
                  {
                    col: 6,
                    component: Input,
                    leadIcon: "school",
                    label: "Salutations",
                    name: "insuredSalutation",
                  },
                  {
                    col: 6,
                    component: Input,
                    leadIcon: "person",
                    label: "Customer" /* label:'Client name'|'Customer' */,
                    name: "insuredName",
                    required: true,
                  },
                  {
                    col: 12,
                    component: Input,
                    leadIcon: "location_on",
                    label:
                      "Property/Risk Address" /* label:'Risk Address'|'Property/Risk Address' */,
                    name: "riskAddressLine1",
                    required: true,
                  },
                  {
                    col: 4,
                    component: Input,
                    leadIcon: "location_on",
                    label: "Suburb",
                    name: "riskAddressSuburb",
                    required: true,
                  },
                  {
                    col: 4,
                    component: Input,
                    leadIcon: "location_on",
                    label: "State",
                    name: "riskAddressState",
                    select: true,
                    required: true,
                    options: states,
                  },
                  {
                    col: 4,
                    component: Input,
                    leadIcon: "location_on",
                    label: "Postcode",
                    name: "riskAddressPostcode",
                    required: true,
                  },

                  {
                    col: 6,
                    component: PhoneInput,
                    leadIcon: "phone",
                    label: "Phone 1",
                    name: "insuredPhone1",
                    required: true,
                  },
                  {
                    col: 6,
                    component: PhoneInput,
                    leadIcon: "phone",
                    label: "Phone 2",
                    name: "insuredPhone2",
                  },
                  {
                    col: 6,
                    component: PhoneInput,
                    leadIcon: "phone",
                    label: "Phone 3",
                    name: "insuredPhone3",
                  },
                  {
                    col: 6,
                    component: PhoneInput,
                    leadIcon: "phone",
                    label: "Fax",
                    name: "insuredFax",
                  },
                ].map((child, key) => {
                  const {
                    col,
                    component: Component,
                    unMountOn,
                    ...props
                  } = child;
                  return (
                    <Grid item xs={col} key={key}>
                      {Component && !unMountOn && (
                        <Component {...props} readOnly={!isAdmin} />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid fluid>
                {[
                  {
                    col: 12,
                    component: Input,
                    leadIcon: "email",
                    label:
                      "Customer Email" /* label:'Client Email'|'Customer Email' */,
                    name: "insuredEmail",
                  },
                  {
                    col: 4,
                    component: Switch,
                    label: claim.view.fields.vulnerableMember.label,
                    name: "vulnerableMember",
                    unMountOn: !claim.view.fields.vulnerableMember.isDisplay,
                  },
                  {
                    col: 8,
                  },
                  {
                    col: 4,
                    component: Switch,
                    label: "Customer Login?",
                    name: "requireCustomLogin",
                    unMountOn: claim.insurer.setupcustomerlogin !== 1,
                  },
                  {
                    col: 8,
                    component: Input,
                    leadIcon: "email",
                    label: "Customer Login?",
                    name: "customLoginEmail",
                    placeholder: "e.g. Email Address",
                    unMountOn: claim.insurer.setupcustomerlogin !== 1,
                  },

                  {
                    col: 4,
                    component: Switch,
                    label: "Home Habitable?",
                    name: "habitableProperty",
                    unMountOn: claim.insurer.removeHabitableAsbestos,
                  },
                  // {
                  //   col: 6, component: Switch,
                  //   label:'Damaged Asbestos Present?',
                  //   name: 'asbestos',
                  //   unMountOn: claim.insurer.removeHabitableAsbestos
                  // },

                  {
                    col: 8,
                    component: Input,
                    name: "category",
                    label: "Category of Claim",
                    select: true,
                    options: claimCategories,
                    /// here
                    unMountOn: claim.insurer.hideCategoryOfClaim,
                  },
                  {
                    col: 3,
                    component: Button,
                    leadIcon: "done",
                    variant: "outlined",
                    color: "primary",
                    label: "Finalise Claim",

                    unMountOn: isDisabled || !isAdmin,
                    onClick: this.toggleFinaliseClaim,
                  },
                  {
                    col: 3,
                    component: Button,
                    leadIcon: "done",
                    variant: "outlined",
                    color: "primary",
                    label: "Cash Settle",

                    unMountOn: !actions.cashSettle.isDisplay,
                    disabled: actions.cashSettle.isDisabled,
                    onClick: () => {
                      const ref = window[Symbol.for("__cashSettleButton")];
                      if (ref) ref.handleOpen();
                    },
                  },
                ].map((child, key) => {
                  const {
                    col,
                    component: Component,
                    unMountOn,
                    ...props
                  } = child;
                  return (
                    <Grid item xs={col} key={key}>
                      {Component && !unMountOn && (
                        <Component {...props} readOnly={!isAdmin} />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        }
      />
    );
  }

  toggleFinaliseClaim = () => {
    const ref = window[Symbol.for("__finaliseClaimAction")];
    if (ref) ref.handleOpen();
  };
}

export default createFragmentContainer(
  ClientInfoCard,
  graphql`
    fragment ClientInfoCard_claim on ClaimJob {
      claimId
      requireCustomLogin
      customLoginEmail
      hasBuilding
      hasContents
      hasRestoration
      view {
        fields {
          vulnerableMember {
            displayValue
            isDisabled
            isDisplay
            label
            name
            required
            order
            value
          }
        }
      }
      insured {
        salutation
        name
        phone1
        phone2
        phone3
        fax
        email
      }
      incidentDetail {
        riskAddress {
          line1
          suburb
          state
          postcode
        }
        habitableProperty
        asbestos
        category
        vulnerableMember
      }
      insurer {
        setupcustomerlogin
        hideCategoryOfClaim
        removeHabitableAsbestos
      }
    }
  `
);
