import React, { Component } from 'react'

import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import CommunicationButtonFragment from './CommunicationButtonFragment'

const query = graphql`
query CommunicationButtonQuery {
  ...CommunicationButtonFragment_communications
}`

class CommunicationButton extends Component {
  state = {
    anchorEl: null
  }
  render() {
    const { classes, icon } = this.props
    const { anchorEl } = this.state

    return (
      <QueryRenderer
        environment={environment}
        query={query}
        render={({ error, props}) => {
          if(error) {
            // console.log(error)
            return 'Error!!'
          }

          if(!props) return <div>...</div>

          return (
            <CommunicationButtonFragment
              icon={icon}
              classes={classes}
              communications={props}
              handleClose={this.handleClose}
              handleClick={this.handleClick}
              updateId={this.updateId}
              anchorEl={anchorEl}
            />
          )
        }}
      />
    )
  }
  updateId = (id, access) => {
    this.props.history.push(`/claim/${access?'histories':'communications'}?id=${id}`)
    this.handleClose()
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }
}

export default withStyles(theme => ({
  list: {
    paddingBottom: 0,
    paddingRight: 0,
    width: '100%',
  },

  subheader: {
    borderBottom: '2px grey solid'
  },
  total: {
    borderTop: '2px grey solid'
  },
  iconButton: {
    height: '100%',
    // padding: theme.spacing.unit * 1.5
  },
  icon: {
    height: '100%',
    color: theme.palette.primary.contrastText,
    fontSize: '28px',
  },
  menu: {
    maxWidth: '25rem',
    '& div[role="document"]': {
      top: '50px !important'
    }
  }
}))(withRouter(CommunicationButton))
