import React, { Component } from "react";
import Grid from "components/Mui/Grid";
import { QueryRenderer, graphql } from "react-relay";
import environment from "common/relay";
import Paper from "components/Mui/Paper";

import DashboardFilter from "./DashboardFilter/";
import DashboardKpi from "./DashboardPresentation/DashboardKpi/";
import DashboardChart from "./DashboardPresentation/DashboardChart/";
import { withStyles } from "@material-ui/core";

import { CubeGrid } from "components/Mui/Loader";
import { decode } from "jsonwebtoken";
import transformFilterGroup from "common/utils/transformFilterGroup";

const query = graphql`
  query DashboardKpiQuery($where: DashboardWidgetIndex!) {
    dashboardWidgets(where: $where) {
      title
      viewName
      labels
      description
      data {
        dataSetName
        internalFilterName
        legend
        valueDisplay
        valueGroup
        valueUnit
        valueSense
        values
      }
    }
  }
`;

class Dashboard extends Component {
  state = {
    chartFilter: undefined,
  };
  render() {
    const type = mapUserType(decode(localStorage.TOKEN).role);
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <DashboardFilter
          handleChangeChartFilter={this.handleChangeChartFilter}
          userType={type}
        />
        <QueryRenderer
          environment={environment}
          query={query}
          variables={{
            where: {
              dashboardId: type.isSuperAdmin
                ? 5
                : type.isAgent
                ? 4
                : type.isRestorer
                ? 3
                : type.isBuilder
                ? 2
                : 1,
              filter: this.state.chartFilter,
            },
          }}
          render={({ error, props }) => {
            if (!props)
              return (
                <Grid
                  container
                  style={{ position: "relative", height: "100%" }}
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Paper
                      title="loading"
                      style={{
                        height: "10rem",
                      }}
                      content={
                        <Grid
                          fluid
                          spacing={0}
                          style={{ position: "relative", height: "100%" }}
                          alignItems="center"
                        >
                          <Grid item xs={4}>
                            <CubeGrid />
                          </Grid>
                        </Grid>
                      }
                    />
                  </Grid>
                </Grid>
                // <Paper>
                //   <Grid fluid spacing={0} style={{position:'relative', height: '100%'}} alignItems='center'>
                //     <Grid item xs={4}>
                //       <CubeGrid/>
                //     </Grid>
                //   </Grid>
                // </Paper>
              );
            const widgetData = props;
            const chartWidgets =
              widgetData.dashboardWidgets &&
              widgetData.dashboardWidgets.filter(
                (e) => e.viewName === "ChartJs"
              );
            const isShowChart = Boolean(chartWidgets.length !== 0);
            return (
              <Grid container spacing={0} style={{ marginTop: "10px" }}>
                <Grid item xs={12}>
                  <Grid fluid>
                    <Grid item xs={12}>
                      <DashboardKpi
                        type={type}
                        widgetData={props}
                        filter={this.state.chartFilter}
                        isShowChart={isShowChart}
                      />
                    </Grid>
                    {isShowChart && (
                      <DashboardChart type={type} chartWidgets={chartWidgets} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    );
  }
  handleChangeChartFilter = (data) => {
    this.setState({
      chartFilter: {
        ...transformFilterGroup(data),
      },
    });
  };
}

// const intData = d => {
//   const data = {...d}
//   Object.entries(data).forEach(([key, val]) => {
//     if(Array.isArray(val)) {
//       data[key] = val.map(v => TryParseInt(v))
//     } else if (typeof val === 'object') {
//       Object.entries(val).map(([k, io]) => {
//         if(Array.isArray(io)) {
//           data[key][k] = io.map(wut => {
//             return TryParseInt(wut)
//           })
//         }
//         return null
//       })
//     }
//   })
//   return data
// }

// const TryParseInt = (str) => {
//   // var retValue = undefined;
//   if(str !== null) {
//     if(str.length > 0) {
//       if (!isNaN(str)) {
//         return parseInt(str);
//       }
//     }
//   }
//   return str;
// }

const mapUserType = (type) => ({
  isAdmin:
    [
      "Administrator",
      "Insurance",
      "Adjustor",
      "LossAdjuster",
      "SystemAdmin",
      "Agent",
    ].indexOf(type) !== -1,
  isBuilder: ["Builder", "Estimator", "InternalAB"].indexOf(type) !== -1,
  isRestorer: type === "Restorer",
  isContent: type === "ContentSupplier",
  isAgent: type === "Agent",
  isSuperAdmin: type === "Administrator",
});

const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
    else if (obj[key] === undefined || obj[key] === "") delete obj[key];
  });
  return obj;
};

export default withStyles((theme) => ({
  root: {
    width: "110%",
    transformOrigin: "10rem 1rem",
    transform: "scale(0.9)",
  },
}))(Dashboard);
