import React from 'react'

import useReactRouter from 'use-react-router'
import queryString from 'query-string'
import uaParser from 'ua-parser-js'

import useVideoRoomSubscription from './hooks/useVideoRoomSubscription'
import useCheckingConsent from './hooks/useCheckingConsent'
import useSupportDevices from './hooks/useSupportDevices'
import usePermitDevices from './hooks/usePermitDevices'
import useKurento from './hooks/useKurento'
import useSignalling from './hooks/useSignalling'

import useCurrentState from './hooks/useCurrentState'
import useMessages from './hooks/useMessages'
import useActions from './hooks/useActions'

import useFixStartingRemoteStream from './hooks/useFixStartingRemoteStream'

import VideoRoom from '../Room/VideoRoom'
import { ICE_SERVERS } from 'common/configs'

export default () => {
  const { location, history } = useReactRouter()
  const getUserAgentInfo = () => {
    const parser = new uaParser()
    const userAgentInfo = parser.getResult()
    return JSON.stringify(userAgentInfo)
  }
  const userAgentInfo = getUserAgentInfo()
  const isAndroid = userAgentInfo.includes('Android') ? false: null
  const { callSessionId, claimId } = queryString.parse(location.search)
  const {
    ready,
    isOwner, endLocation,
    roomStatus, ownerStatus, guestStatus, guestMessage,

    consentConfigs, messageConfigs, actionConfigs, photoUploadId
  } = useVideoRoomSubscription({ callSessionId, claimId })

  const [isLoading, setIsLoading] = React.useState(true)
  const [isSupportNoDevice, setIsSupportNoDevice] = React.useState(isAndroid)
  const [forceStop, setForceStop] = React.useState(false)
  React.useEffect(() => {
    const roomEnded = roomStatus === 'ENDED'
    const ownerConnected = Boolean(ownerStatus === 'CONNECTED')
    const guestConnected = Boolean(guestStatus === 'CONNECTED')

    if (!roomEnded && ownerConnected && guestConnected) setIsLoading(false)
    else setIsLoading(true)
  }, [roomStatus, ownerStatus, guestStatus])

  React.useEffect(() => {
    if(roomStatus === 'ENDED') history.push(endLocation)
  }, [roomStatus, endLocation, history.push])

  const { ready: consentCheckingReady, approved } = useCheckingConsent({
    skip: !ready || roomStatus === 'ENDED',
    consentConfigs
  })

  const { ready: supportCheckingReady, supported } = useSupportDevices({
    skip: !consentCheckingReady || (consentCheckingReady && !approved),
  })

  const { ready: permissionCheckingReady, permitted } = usePermitDevices({
    skip: !supportCheckingReady,
    supported,
    isSupportNoDevice,
    setIsSupportNoDevice
  })

  const localVideoRef = React.useRef(null)
  const remoteVideoRef = React.useRef(null)
  const fakeStreamRef = React.useRef(null)
  const {
    ready: kurentoReady,
    error: kurentoError,
    peer, signallingData,
    helpers,
    actions: kurentoActions,
    dataChannelSend,
  } = useKurento({
    // begin: permissionCheckingReady && (permitted.video || permitted.audio),
    skip: !permissionCheckingReady || (permissionCheckingReady && !permitted.video && !permitted.audio),
    localVideoRef,
    remoteVideoRef,
    fakeStreamRef,
    mediaConstraints: {
      // isOwner ? 'user' : 'environment'
      video: permitted.video ? { facingMode: isOwner ? 'user' : 'environment' } : false,
      audio: permitted.audio ? true : false,
    },
    configuration: {
      iceServers: ICE_SERVERS
    },
    dataChannelEvents: {
      onmessage: message => {
        if (message.data.type === 'FAKE_VID_STREAM') onFakeStreamEvent()
      }
    },
    isSupportNoDevice,
    setForceStop
  })

  const { ready: signallingReady, serverSignallingData, error: signallingError } = useSignalling({
    skip: !kurentoReady,
    callSessionId,
    signallingData,
    isSupportNoDevice,
    forceStop,
  })

  const serverSignallingDataString = serverSignallingData ? JSON.stringify(serverSignallingData) : null
  React.useEffect(() => {
    if (signallingReady) {
      if(!signallingError) {
        const { answer, candidates } = serverSignallingData
        helpers.setAnswer(answer)
        helpers.setServerCandidates(candidates)
      }
    }
  }, [signallingReady, serverSignallingDataString, signallingError])

  const stateName = useCurrentState({
    ready,
    consentCheckingReady, approved,
    supportCheckingReady,
    permissionCheckingReady, permitted,
    kurentoReady, kurentoError,
    signallingReady, signallingError, isSupportNoDevice
  })
  const { message } = useMessages({ stateName, messageConfigs, kurentoError })

  // kick user out when session is expired or when they dont approve the consent
  React.useEffect(() => {
    if ((consentCheckingReady && !approved) || stateName === 'SIGNALLING_SERVER_FAILED') {
      history.push(endLocation)
    }
  }, [consentCheckingReady, approved, stateName, history.push, endLocation])

  const takePhotoCanvasRef = React.useRef()
  const takingLocalImage = () => {
    const canvas = takePhotoCanvasRef.current
    const context = canvas.getContext('2d')
    context.drawImage(localVideoRef.current, 0, 0, canvas.width, canvas.height)
    return canvas.toDataURL()
  }
  const { photos, actionButtons } = useActions({
    loading: isLoading,
    claimId, callSessionId, endLocation,
    actionConfigs, isOwner,
    kurentoActions: {
      ...kurentoActions,
      getPhoto: () => {
        return isOwner ? kurentoActions.getPhoto() : takingLocalImage()
      }
    },
    photoUploadId,
    isSupportNoDevice
  })

  // hot fix for ios > 11
  // cover use case of remote stream only contains audio tracks
  useFixStartingRemoteStream({ peer, remoteVideoRef })

  React.useEffect(() => {
    if (isOwner && !isLoading && !permitted.video) {
      console.log('here')
      dataChannelSend({ type: 'FAKE_VID_STREAM' })
    }
  }, [isOwner, isLoading, permitted.video, dataChannelSend])
  const [isDisplayRemote, setIsDisplayRemote] = React.useState(true)
  const onFakeStreamEvent = () => {
    setIsDisplayRemote(false)
  }

  return (
    <>
      <canvas ref={fakeStreamRef} style={{ display: 'none' }} />
      <VideoRoom
        viewMode={isOwner ? 'host' : 'guest'}
        isDisplayRemote={isDisplayRemote}
        loading={isLoading}
        message={message}
        peerMessage={guestMessage}

        localVideoRef={localVideoRef}
        remoteVideoRef={remoteVideoRef}

        photos={photos}
        actions={actionButtons}
      />
      <canvas ref={takePhotoCanvasRef} width={500} height={400} style={{ display: 'none' }} />
    </>
  )
}
