import React, { Component } from 'react'

import classNames from 'clsx'

import { withStyles } from '@material-ui/core/styles'
import MuiFab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'

import './animate.css'

class Fab extends Component {
  render() {
    const {
      classes, mainFab = false,
      backgroundColor,
      icon, label, display,
      style,
      ...rest
    } = this.props

    // console.log(this.props.display)

    return (
      <MuiFab
        style={{ backgroundColor }}
        color='primary'
        variant={label ? 'extended' : 'round' }
        aria-label='Add'
        className={classNames(
          classes.fab,
          // {'animated pulse infinite mainFab' : mainFab},
          {'animated fadeInUp' : !mainFab},
          {'hidden': !mainFab && !this.props.display}
        )}
        {...rest}
      >
        {label && <span>{label}</span>}
        {icon && <Icon className={classNames({[classes.extendedIcon]: label})}>{icon}</Icon>}
      </MuiFab>
    )
  }
}

export default withStyles(theme => ({
  fab: {
    margin: theme.spacing.unit,
    display: 'inline-flex',
    '&.hidden': {
      display: 'none'
    }
  },
  extendedIcon: {
    marginLeft: theme.spacing.unit,
  },
}))(Fab)