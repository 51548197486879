import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
// import { createMuiTheme, ThemeProvider } from  

const HexagonButton = ({ classes, type, color, variant,
  onClick, label, width, height, children, ...rest }) =>{
    return (
      <div className={classes[type]}><div className={classes[`${type}-in1`]}>
      <MuiButton
      color={'primary'}
      variant={'contained'}
      onClick={onClick}
      // className={classes[`${type}-in2`]}
      {...rest}
    >
    {children}
    </MuiButton>
      </div></div>
    )}

export default withStyles(theme => ({
//   'button-dark': {
//       overflow: 'hidden',
//       visibility: 'hidden',
//       cursor: 'pointer',
//       transform: 'rotate(120deg)',
//       width: '400px',
//       height: '200px',
//       margin: '0 0 0 -80px'
//   },
//   'button-dark-in1': {
//       overflow: 'hidden',
//       width: '100%',
//       height: '100%',
//       transform: 'rotate(-60deg)'
//   },
//   'button-dark-in2': {
//     width: '100%',
//     height: '100%',
//     visibility: 'visible',
//     backgroundColor: '#003781',
//     transform: 'rotate(-60deg)'
// },

}))(HexagonButton)

