import { graphql } from 'relay-runtime'

export default graphql`
  mutation JoinRoomMutation($where: RoomJoinWhereInput, $data: RoomJoinDataInput) {
    roomJoin(where: $where, data: $data) {
      sdpAnswer
      serverIceCandidates
      callSession { id callStatus { status message }}
    }
  }
`