import React, { Component } from "react";
import Button from "components/Mui/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import { useMutation } from "react-relay-mutation";
import SubmitScopeValidationMutation from "./SubmitScopeValidationMutation";
import Input from "components/Formik/Input";
import CheckboxGroup from "components/Formik/CheckboxGroup";

const SubmitScopeValidation = ({
  quote,
  portfolio,
  claimParticipants,
  setRefresh,
  refreshValidation,
  isApprove,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [submitValidation] = useMutation(SubmitScopeValidationMutation);

  const jobSuppliers = claimParticipants;
  if (!jobSuppliers) return "";
  const selectSuppliers = jobSuppliers
    .map((x) => {
      if (x.requested) {
        return String(x.companyId);
      }
    })
    .filter(Boolean);
  const noScopeOptions = claimParticipants.map((x) => {
    return {
      label: x.companyName,
      value: String(x.companyId),
    };
  });

  const requestQuoteStatus = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

  const snackbar = window[Symbol.for("__snackbar")];
  return (
    <div style={{ float: "right" }}>
      <Button
        variant="outlined"
        color="primary"
        disabled={quote.actions.submitValidation.isDisabled}
        label={quote.actions.submitValidation.label}
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          Submit Scope Validation
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                //isClaim: null,
                unableToValidate: false,
                requestQuote: null,
                comments: null,
                quotingSupplierIds: selectSuppliers,
              }}
              onSubmit={(values, actions) => {
                setTimeout(async () => {
                  const res = await submitValidation({
                    variables: {
                      input: {
                        unableToValidate: values.unableToValidate,
                        requestQuote: values.requestQuote,
                        comments: values.comments,
                        quotingSupplierIds: !isApprove
                          ? values.quotingSupplierIds
                          : null,
                      },
                      where: {
                        id: quote.jobQuoteId,
                        portfolioType: portfolio,
                      },
                    },
                  });
                  if (res) {
                    window[Symbol.for("__snackbar")].toggleOpen({
                      message: res.jobScopeValidationSubmit.messages,
                    });
                    handleClose();
                    window[Symbol.for("__refreshScope")]();
                    setRefresh(true);
                  } else {
                    snackbar.toggleOpen({
                      message: res.jobScopeValidationSubmit.messages,
                    });
                  }
                }, 400);
              }}
              validate={(values) => {
                let errors = {};

                if (!isApprove && values.requestQuote === null)
                  errors.requestQuote = "Required";
                if (
                  !isApprove &&
                  values.requestQuote &&
                  JSON.stringify(values.quotingSupplierIds) === "[]"
                ) {
                  errors.quotingSupplierIds =
                    "quoting Supplier is Required! At least one";
                  window[Symbol.for("__snackbar")].toggleOpen({
                    message: errors.quotingSupplierIds,
                  });
                }
                return errors;
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => {
                return (
                  <Form>
                    <Grid container spacing={8} justify="left">
                      {isApprove && (
                        <Grid item xs={12} style={{ marginBottom: "15px" }}>
                          <label>
                            <Field type="checkbox" name="unableToValidate" />
                            Unable to Validate Scope
                          </label>
                        </Grid>
                      )}
                      {!isApprove && (
                        <Grid item xs={12} style={{ marginTop: "10px" }}>
                          <Input
                            required
                            name="requestQuote"
                            label="Request Quotes"
                            options={requestQuoteStatus}
                            value={values.requestQuote}
                            select
                            isShrink
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Input
                          name="comments"
                          label={
                            isApprove
                              ? "Message To Supplier"
                              : "Communication To Case Manager"
                          }
                          rows={4}
                          value={values.comments}
                          isShrink
                        />
                      </Grid>
                      {!isApprove && values.requestQuote && (
                        <Grid item xs={12}>
                          <QuotingCheckbox
                            selectSuppliers={selectSuppliers}
                            noScopeOptions={noScopeOptions}
                            setFieldValue={setFieldValue}
                            portfolioType={portfolio}
                          />
                        </Grid>
                      )}
                      <Button
                        style={{
                          marginLeft: 0,
                          marginRight: 0,
                          marginTop: "20px",
                          width: "100%",
                        }}
                        fullWidth
                        variant="contained"
                        type="submit"
                        color="primary"
                        label="Submit Scope Validation"
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SubmitScopeValidation;

class QuotingCheckbox extends Component {
  componentDidMount = () => {
    this.props.setFieldValue("quotingSupplierIds", this.props.selectSuppliers);
  };
  render() {
    return (
      <CheckboxGroup
        required
        label={
          this.props.portfolioType === "Building"
            ? "Quoting Builders"
            : "Quoting Restorers"
        }
        name="quotingSupplierIds"
        select
        options={this.props.noScopeOptions}
      />
    );
  }
}
