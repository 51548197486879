import React from "react";

import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

const SpecialistCardHeader = ({ createSpecialistRequest, classes }) => {
  const openHandle = () => {
    const actions = window[Symbol.for("__specialistRequestDialog")];
    if (actions) actions.handleOpen();
  };
  if(!createSpecialistRequest) return null

  return (
    <div className={classes.root}>
      <p>Specialists</p>
      {createSpecialistRequest.isDisplay && (
        <IconButton
          className={classes.button}
          onClick={openHandle}
          disabled={createSpecialistRequest.isDisabled}
        >
          <Icon>add</Icon>
        </IconButton>
      )}
    </div>
  );
};

export default withStyles({
  root: {
    display: "flex",
  },
  button: {
    marginLeft: "auto",
    "& > span": {
      color: "white",
    },
  },
})(SpecialistCardHeader);
