/**
 * @flow
 * @relayHash 9b53bdf76e4ad0d00f6031e805cd405c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type LossAdjusterList$ref = any;
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimItemFilter = {|
  id?: ?number,
  claimId: string,
  keyWords?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type ENDataEntityKey = {|
  id: string
|};
export type LossAdjusterQueryVariables = {|
  first: number,
  after?: ?string,
  where?: ?ClaimItemFilter,
  claimJobWhere: ENDataEntityKey,
|};
export type LossAdjusterQueryResponse = {|
  +$fragmentRefs: LossAdjusterList$ref
|};
export type LossAdjusterQuery = {|
  variables: LossAdjusterQueryVariables,
  response: LossAdjusterQueryResponse,
|};
*/


/*
query LossAdjusterQuery(
  $first: Int!
  $after: String
  $where: ClaimItemFilter
  $claimJobWhere: ENDataEntityKey!
) {
  ...LossAdjusterList
}

fragment LossAdjusterList on Query {
  claimLossAdjusterDocuments(first: $first, after: $after, where: $where) {
    edges {
      node {
        ...LossAdjusterListItem_item
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  claimJob(where: $claimJobWhere) {
    view {
      actions {
        makeLossAdjusterInitialCall {
          isDisabled
          isDisplay
          label
          id
        }
        makeLossAdjusterAppointment {
          isDisabled
          isDisplay
          label
          id
        }
      }
    }
    id
  }
}

fragment LossAdjusterListItem_item on ClaimLossAdjusterDocument {
  id
  uploadDate
  company {
    companyName
    id
  }
  reportType {
    reportTypeName
  }
  url
  private
  description
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimItemFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "claimJobWhere",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ClaimItemFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "isDisabled",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "isDisplay",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "label",
    "args": null,
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LossAdjusterQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "LossAdjusterList",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LossAdjusterQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimLossAdjusterDocuments",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimLossAdjusterDocumentConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimLossAdjusterDocumentEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimLossAdjusterDocument",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "uploadDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "company",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "companyName",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "reportType",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LossAdjusterReportType",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reportTypeName",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "private",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "claimLossAdjusterDocuments",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "LossAdjusterList_claimLossAdjusterDocuments",
        "filters": []
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "claimJobWhere",
            "type": "ENDataEntityKey!"
          }
        ],
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "view",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJobView",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "makeLossAdjusterInitialCall",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "makeLossAdjusterAppointment",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "LossAdjusterQuery",
    "id": null,
    "text": "query LossAdjusterQuery(\n  $first: Int!\n  $after: String\n  $where: ClaimItemFilter\n  $claimJobWhere: ENDataEntityKey!\n) {\n  ...LossAdjusterList\n}\n\nfragment LossAdjusterList on Query {\n  claimLossAdjusterDocuments(first: $first, after: $after, where: $where) {\n    edges {\n      node {\n        ...LossAdjusterListItem_item\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  claimJob(where: $claimJobWhere) {\n    view {\n      actions {\n        makeLossAdjusterInitialCall {\n          isDisabled\n          isDisplay\n          label\n          id\n        }\n        makeLossAdjusterAppointment {\n          isDisabled\n          isDisplay\n          label\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment LossAdjusterListItem_item on ClaimLossAdjusterDocument {\n  id\n  uploadDate\n  company {\n    companyName\n    id\n  }\n  reportType {\n    reportTypeName\n  }\n  url\n  private\n  description\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f7d3ad8d108813f9cd2ada9c0b4f9eb4';
module.exports = node;
