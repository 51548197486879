import React from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "components/Mui/IconButton";
import Collapse from "@material-ui/core/Collapse";
import ScopeContentsNewItem from "pages/Claim/ClaimDetails/ClaimDetailsPage/Scope/ScopeContentsItemList/ScopeContentsNewItem";

const LineItemListGroup = ({
  classes,
  group,
  ItemComponent,
  isShowAction,
  isValidationItem,
  ...rest
}) => {
  const [expand, setExpand] = React.useState(true);
  const [isAddNewItem, setIsAddNewItem] = React.useState(false);
  if (group.name === "ScopeContentsAddLineItem") {
    if (!isAddNewItem) {
      return (
        <>
          {rest.isDisplayAddLineItem.isDisplay && (
            <div
              className={classes.groupRow}
              onClick={() => {
                setIsAddNewItem(true);
              }}
            >
              <IconButton
                icon="add"
                className={classes.expandBtn}
                onClick={() => {
                  setIsAddNewItem(true);
                }}
              />
              Add line Item
            </div>
          )}
        </>
      );
    } else {
      return (
        <div className={classes.groupRow}>
          <ScopeContentsNewItem
            setIsAddNewItem={setIsAddNewItem}
            jobQuoteId={rest.quote.jobQuoteId}
          />
        </div>
      );
    }
  }
  return (
    <div>
      <div className={classes.groupRow}>
        <IconButton
          icon={expand ? "expand_less" : "expand_more"}
          className={classes.expandBtn}
          onClick={() => setExpand(!expand)}
          style={{ marginLeft: rest.isProperties && "40px" }}
        />
        {group.name ? group.name : group.location.locationName}
        {group.items[0].dimensions ? ` - ${group.items[0].dimensions}` : ""}
      </div>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        {isValidationItem
          ? group.validationItems.map((item, index) => {
              return (
                <ItemComponent
                  key={index}
                  item={item}
                  isShowAction={isShowAction}
                  {...rest}
                />
              );
            })
          : group.items.map((item, index) => {
              return (
                <ItemComponent
                  key={index}
                  item={item}
                  isShowAction={isShowAction}
                  {...rest}
                />
              );
            })}
        {/* {group.items.map((item, index) => {
          return (
            <ItemComponent
              key={index}
              item={item}
              isShowAction={isShowAction}
              {...rest}
            />
          );
        })} */}
      </Collapse>
    </div>
  );
};

export default withStyles((theme) => ({
  groupRow: {
    fontWeight: 600,
    borderRadius: 8,
    background: theme.palette.grey[200],

    "&:nth-child(even)": {
      background: theme.palette.grey[300],
    },

    "&:hover": {
      background: theme.palette.grey[400],
    },
  },
  expandBtn: {
    margin: 0,
    marginLeft: "20px",
    padding: theme.spacing.unit,
  },
}))(LineItemListGroup);
