import React, { Component } from 'react'
import { QueryRenderer, graphql } from 'react-relay'
import environment from 'common/relay'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import Input from 'components/Formik/Input'

class BuildingInfo extends Component {
  render() {
    const { claim, data, isAbleToEdit } = this.props
    
    return (
      <Paper title={'Building Information'}
        content={(
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              claddingTypeWhere: { subject: 'claddingType', insurers: claim.insurer.companyId },
              houseTypeWhere: { subject: 'houseType', insurers: claim.insurer.companyId },
              roofTypeWhere: { subject: 'roofType', insurers: claim.insurer.companyId },
              dwellingTypeWhere: { subject: 'dwellingType', insurers: claim.insurer.companyId },
              foundationTypeWhere: { subject: 'foundationType', insurers: claim.insurer.companyId },
              dwellingAgeWhere: { subject: 'dwellingAge', insurers: claim.insurer.companyId },
            }}
            render={({ error, props }) => {
              if(!props) return 'Loading...'

              return (
                <Grid fluid>
                  {[
                    {
                      component: Input, leadIcon: 'assignment_ind',
                      label: 'House Type',
                      name: 'houseTypeId',
                      select: true, required: true,
                      options: this.getOptions(props.houseType,
                        data.siteReport && data.siteReport.houseType
                        && {
                          label: data.siteReport.houseType.houseTypeName,
                          value: data.siteReport.houseType.houseTypeId
                        }
                      )
                    },
                    {
                      component: Input, leadIcon: 'assignment_ind',
                      label: 'Cladding Type',
                      name: 'claddingTypeId',
                      select: true, required: true,
                      options: this.getOptions(props.claddingType,
                        data.siteReport && data.siteReport.claddingType
                        && {
                          label: data.siteReport.claddingType.claddingTypeName,
                          value: data.siteReport.claddingType.claddingTypeId
                        }
                      )
                    },
                    {
                      component: Input, leadIcon: 'assignment_ind',
                      label: 'Roof Type',
                      name: 'roofTypeId',
                      select: true, required: true,
                      options: this.getOptions(props.roofType,
                        data.siteReport && data.siteReport.roofType
                        && {
                          label: data.siteReport.roofType.roofTypeName,
                          value: data.siteReport.roofType.roofTypeId
                        }
                      )
                    },
                    {
                      component: Input, leadIcon: 'assignment_ind',
                      label: 'Condition of Dwelling',
                      name: 'dwellingTypeId',
                      select: true, required: true,
                      options: this.getOptions(props.dwellingType,
                        data.siteReport && data.siteReport.dwellingType
                        && {
                          label: data.siteReport.dwellingType.dwellingTypeName,
                          value: data.siteReport.dwellingType.dwellingTypeId
                        }
                      )
                    },
                    {
                      component: Input, leadIcon: 'assignment_ind',
                      label: 'Foundation Type',
                      name: 'foundationTypeId',
                      select: true, required: true,
                      options: this.getOptions(props.foundationType,
                        data.siteReport && data.siteReport.foundationType
                        && {
                          label: data.siteReport.foundationType.foundationTypeName,
                          value: data.siteReport.foundationType.foundationTypeId
                        }
                      )
                    },
                    {
                      component: Input, leadIcon: 'assignment_ind',
                      label: 'Age of Dwelling',
                      name: 'dwellingAgeId',
                      select: true, required: true,
                      options: this.getOptions(props.dwellingAge,
                        data.siteReport && data.siteReport.dwellingAge
                        && {
                          label: data.siteReport.dwellingAge.dwellingAgeName,
                          value: data.siteReport.dwellingAge.dwellingAgeId
                        }
                      )
                    }
                  ].map((child, key) => {
                    const { col = 6, component: Component, ...props } = child
                      return (
                        <Grid item xs={col} key={key}>
                          <Component {...props}
                            readOnly={!isAbleToEdit}
                          />
                        </Grid>
                      )
                    })  
                  }
                </Grid>
              )
            }}
          />
        )}
      />
    )
  }
  getOptions = (options, current) => {
    if(!current || current === null || current === undefined) return options

    return options
      .map(option => option.value)
      .indexOf(current.value.toString()) === -1
        ? [
          ...options, current
        ]
        : options
  }
}

export default BuildingInfo

const query = graphql`
  query BuildingInfoQuery(
    $houseTypeWhere: ClaimJobFilter,
    $claddingTypeWhere: ClaimJobFilter,
    $roofTypeWhere: ClaimJobFilter,
    $dwellingTypeWhere: ClaimJobFilter,
    $foundationTypeWhere: ClaimJobFilter,
    $dwellingAgeWhere: ClaimJobFilter
  ) {
    houseType: claimFilterOptions(where: $houseTypeWhere) {
      label: name
      value
    }
    claddingType: claimFilterOptions(where: $claddingTypeWhere) {
      label: name
      value
    }
    roofType: claimFilterOptions(where: $roofTypeWhere) {
      label: name
      value
    }
    dwellingType: claimFilterOptions(where: $dwellingTypeWhere) {
      label: name
      value
    }
    foundationType: claimFilterOptions(where: $foundationTypeWhere) {
      label: name
      value
    }
    dwellingAge: claimFilterOptions(where: $dwellingAgeWhere) {
      label: name
      value
    }
  }
`