import React, { Component } from 'react'
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'
import { withStyles } from '@material-ui/core/styles'

import Dialog from 'components/Mui/Dialog'
import Grid from 'components/Mui/Grid'
import ClaimTypeIcon from 'components/Mui/ClaimTypeIcon'
import InfoBox from 'components/Mui/InfoBox'

const query = graphql`
  query NextStepActionQuery($where: ENDataEntityKey!) {
    claimNextStep(where: $where) {
      statusName
      description
      nextStep
      portfolioType
    }
  }
`

class NextStepAction extends Component {
  render() {
    const { dlRef, claimId, classes, ...rest } = this.props

    return (
      <Dialog
        innerRef={dlRef}
        title=''
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              where: {
                id: claimId,
              }
            }}
            render={({ error, props }) => {
              if(!props) return 'Loading...'

              return (
                <Grid container justify='center' alignItems='stretch'>
                  {props.claimNextStep.map(({ portfolioType, statusName, description, nextStep }, y) => (
                    <Grid item xs key={y}>
                      <ClaimTypeIcon type={portfolioType} className={classes.portfolioType} />
                      <InfoBox label={statusName} text={description} className={classes.infoBox} />
                      <InfoBox label='Next Step' text={nextStep} className={classes.infoBox} />
                    </Grid>  
                  ))}
                </Grid>
              )
            }}
          />
        }
        {...rest}
      />
    )
  }
}

export default withStyles(theme => ({
  portfolioType: {
    margin: '0 auto 20px auto',
    display: 'flex',
    fontSize: '2.5rem',
  },
  infoBox: {
    maxHeight: 300
  }
}))(NextStepAction)