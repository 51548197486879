import React, { Component } from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { withClaimMeta } from 'pages/Claim/ClaimDetails/ClaimMetaContext/'

import Grid from '@material-ui/core/Grid'
import DateInput from 'components/Formik/DateInput'
import Dialog from 'components/Mui/Dialog'
import Button from 'components/Mui/Button'

import QuoteAppointmentMadeActionMutation from './QuoteAppointmentMadeActionMutation'

class AppointmentMadeAction extends Component {
  snackbar = window[Symbol.for('__snackbar')]

  render() {
    const { dlRef } = this.props

    return (
      <Dialog
        noForm noActions
        innerRef={dlRef}
        title='Appointment'
        content={(
          <Formik
            initialData={{
              date: moment()
            }}
            validate={values => {
              let errors = {}
              if(!values.date) errors.date = 'Required!'
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(async () => {
                const { meta } = this.props
                const variables = {
                  input:{
                    appointmentDate: values.date.format('YYYY-MM-DDTHH:mm:ss'),
                  },
                  where: {
                    id: meta.claim.claimId
                  }
                }
                const res = await QuoteAppointmentMadeActionMutation(variables)

                if(res.ok){
                  if(res.success) {
                    this.snackbar.toggleOpen({message: 'Appointment has been made'})
                    this.handleDlClose()
                    const ref = window[Symbol.for('__refreshQuote')]
                    if(ref) ref()
                  } else {
                    this.snackbar.toggleOpen({message: res.messages[0]})
                    this.handleDlClose()
                  }
                }
                else {
                  this.snackbar.toggleOpen({message: 'Server error'})
                }
              }, 400)
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <DateInput required
                      label='Appointment Date'
                      name='date'
                    />
                  </Grid>
                  <div style={{ marginLeft: 'auto' }}>
                    <Button label='Make appointment' type='submit' variant='contained' color='primary'
                      disabled={isSubmitting}
                    />
                    <Button label='Cancel' variant='outlined' color='primary'
                      onClick={this.handleDlClose}
                      disabled={isSubmitting}
                    />
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      />
    )
  }


  handleDlClose = () => {
    this.props.dlRef.current.handleClose()
  }
}

export default withClaimMeta(AppointmentMadeAction)
