import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
mutation SubmitSurveyMutation($where: ClaimScoreFormWhere!, $input: ClaimScoreInput!) {
  completeClaimScoreForm(where: $where, input: $input) {
    success
    messages
    result {
      ratingCompleted
      logo
      supplierName
    }
  }
}
`
// { id: 210655, portfolioType: Building }

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          resolve({ ok: true, ...res.completeClaimScoreForm })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}