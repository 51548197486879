/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type CommunicationsListItem_item$ref: FragmentReference;
export type CommunicationsListItem_item = {|
  +acknowledged: boolean,
  +claimId: ?string,
  +communicationId: number,
  +message: ?string,
  +acknowledgeUserName: ?string,
  +acknowledgeDate: ?any,
  +portfolioType: ?PortfolioType,
  +recieverCompanyId: ?number,
  +recieverCompanyName: ?string,
  +sendDate: any,
  +senderCompanyId: ?number,
  +senderCompanyName: ?string,
  +senderId: ?number,
  +senderName: ?string,
  +private: boolean,
  +actions: {|
    +acknowledge: {|
      +isDisplay: boolean
    |}
  |},
  +$refType: CommunicationsListItem_item$ref,
|};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CommunicationsListItem_item",
  "type": "ClaimCommunication",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "acknowledged",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "claimId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "communicationId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "acknowledgeUserName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "acknowledgeDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "portfolioType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "recieverCompanyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "recieverCompanyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sendDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "senderCompanyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "senderCompanyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "senderId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "senderName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "private",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actions",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimCommunicationAction",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "acknowledge",
          "storageKey": null,
          "args": null,
          "concreteType": "ActionControl",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isDisplay",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '5ddc395f88ee785aa13ff3c9f11544fa';
module.exports = node;
