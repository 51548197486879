import React from "react";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import AnnouncementDialogMutation from "./AnnouncementDialogMutation";

const AnnouncementDialog = ({ announcements, handleClose }) => {
  const [loading, setLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleConfirm = async () => {
    setLoading(true);
    const res = await AnnouncementDialogMutation({
      input: {
        announcementId: announcements[activeStep].node.id,
      },
    });
    if (res.success) {
      setLoading(false);
      activeStep === announcements.length - 1 ? handleClose() : handleNext();
    } else {
      setLoading(false);
    }
  };

  const getStepContent = (activeStep) => {
    if (
      announcements &&
      announcements[activeStep] &&
      announcements[activeStep].node
    )
      return announcements[activeStep].node.content;
  };

  return (
    <div>
      <Stepper
        activeStep={activeStep}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        {announcements.map((item, index) => (
          <Step key={index}>
            <StepLabel>{item.node.displayType}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div style={{ fontWeight: "600", fontSize: "larger" }}>
          {announcements[activeStep].node.title}
        </div>
        <p dangerouslySetInnerHTML={{ __html: getStepContent(activeStep) }} />
        <div style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={loading}
          >
            Acknowledge
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementDialog;
