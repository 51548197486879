/**
 * @flow
 * @relayHash f1869aa3e76e0858691d38aac3c94384
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuoteItemSirUniqueWhere = {|
  claimId: string,
  costDescriptionId: string,
  unitId: string,
  portfolioType: PortfolioType,
|};
export type useUpdateScopeLineItemQueryVariables = {|
  where: QuoteItemSirUniqueWhere,
  isIncluded: boolean,
|};
export type useUpdateScopeLineItemQueryResponse = {|
  +rates?: ?{|
    +id: string,
    +sirMarginRate: ?number,
    +sirRate: ?number,
  |}
|};
export type useUpdateScopeLineItemQuery = {|
  variables: useUpdateScopeLineItemQueryVariables,
  response: useUpdateScopeLineItemQueryResponse,
|};
*/


/*
query useUpdateScopeLineItemQuery(
  $where: QuoteItemSirUniqueWhere!
  $isIncluded: Boolean!
) {
  rates: quoteItemSir(where: $where) @include(if: $isIncluded) {
    id
    sirMarginRate
    sirRate
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "QuoteItemSirUniqueWhere!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isIncluded",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Condition",
    "passingValue": true,
    "condition": "isIncluded",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rates",
        "name": "quoteItemSir",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "where",
            "type": "QuoteItemSirUniqueWhere!"
          }
        ],
        "concreteType": "QuoteItemSir",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sirMarginRate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sirRate",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "useUpdateScopeLineItemQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "useUpdateScopeLineItemQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "useUpdateScopeLineItemQuery",
    "id": null,
    "text": "query useUpdateScopeLineItemQuery(\n  $where: QuoteItemSirUniqueWhere!\n  $isIncluded: Boolean!\n) {\n  rates: quoteItemSir(where: $where) @include(if: $isIncluded) {\n    id\n    sirMarginRate\n    sirRate\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '454aafbf145a62856d0431ca4f4ae107';
module.exports = node;
