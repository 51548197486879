import React from 'react'
import { Field } from 'formik'
import { InlineDatePicker } from 'material-ui-pickers'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import { IconButton } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear';

export default props => {
  return (
    <Field component={DateInput}
      {...props}
    />
  )
}

export const DateInput = ({ field, form, disabled, readOnly, ...rest }) => {
  return (
    <>
      <InlineDatePicker
        // Default styling
        variant='outlined'
        fullWidth

        // keyboard
        clearable
        disableOpenOnEnter
        animateYearScrolling={false}

        disabled={disabled || form.isSubmitting || readOnly}

        // Date format
        format='DD/MM/YYYY'
        placeholder='DD/MM/YYYY'
        // mask={value =>
        //   // handle clearing outside if value can be changed outside of the component
        //   value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
        // }

        // More
        // minDate, minDateMessage, maxDate, maxDateMessage

        // Formik Handling
        {...rest} {...field}
        value={field.value || null}
        onChange={value => {
          form.setFieldValue([field.name], value)
          form.setTouched({...form.touched, [field.name]: true})
        }}
        onBlur={e => {
          field.onBlur && field.onBlur(e)
        }}

        TextFieldComponent={props => <InputField {...props} field={field} form={form} clearable={rest.clearable} />}
      />
    </>
  )
}

const InputField = ({ form, field, InputProps, readOnly, ...props }) => {
  return (
    <TextField
      {...props}
      error={Boolean(form.errors[field.name])}
      helperText={form.errors[field.name]}
      InputProps={readOnly
        ? {
          readOnly
        }
        : {
          ...InputProps,
          inputProps: {
            autoComplete: 'removeAutoComplete',
            tabIndex:"-1",
          },
          startAdornment: (
            <InputAdornment position='start'>
              <Icon>event</Icon>
            </InputAdornment>
          ),
          endAdornment: props.clearable?(
            <InputAdornment position='end'>
              <IconButton onClick={e => clickClear(e, form, field)}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ):undefined
        }
      }
    />
  )
}

const clickClear = (e, form, field) => {
  e.stopPropagation()
  form.setFieldValue([field.name], undefined)
}
