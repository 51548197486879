import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";

// hooks
import usePortfolio from "hooks/usePortfolio";

// context
import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";

import Grid from "@material-ui/core/Grid";
import { Cube } from "components/Mui/Loader";
import ScopePresentation from "./ScopePresentation";

const Scope = (props) => {
  const meta = React.useContext(ClaimMetaContext);

  /**
   * HANDLE ROUTING AND DEFAULT SEARCH STRING
   */
  const [portfolio, changePortfolio] = usePortfolio([
    "building",
    "contents",
    "restoration",
  ]);

  /**
   * HANDLE HARD REFRESH
   */
  const [update, setUpdate] = React.useState(false);
  React.useEffect(() => {
    window[Symbol.for("__refreshScope")] = () => setUpdate(!update);
    return () => {
      delete window[Symbol.for("__refreshScope")];
    };
  }, [update]);

  /**
   * HANDLE REQUEST CATEGORIES
   */
  const [requestCategory, setRequestCategory] = React.useState("Scoping");
  const toggleRequestCategory = () => {
    if (requestCategory === "Scoping") setRequestCategory("Authorised");
    else setRequestCategory("Scoping");
  };
  React.useEffect(() => {
    if (meta.user.type.isAdmin) {
      const isAuthorised = portfolio
        ? meta.claim[portfolio.toLowerCase()].authorised
        : false;
      // requestCategory === 'Scoping' &&
      if (isAuthorised) {
        setRequestCategory("Authorised");
      } else {
        setRequestCategory("Scoping");
      }
    }
  }, [portfolio]);

  if (!portfolio) return null;
  return (
    <QueryRenderer
      environment={environment}
      query={preQuery}
      variables={{
        where: {
          claimId: meta.claim.claimId,
          portfolios: portfolio,
          requestCategory: requestCategory,
        },
      }}
      render={({ error, props }) => {
        const isProperties =
          props &&
          props.jobQuotes &&
          props.jobQuotes.edges.length &&
          props.jobQuotes.edges[0].node.fields.propertyNumber.isDisplay;
        return (
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              update,
              isProperties: isProperties ? true : false,
              isContent: portfolio === "Contents",
              where: {
                claimId: meta.claim.claimId,
                portfolios: portfolio,
                requestCategory: requestCategory,
              },
              jobScopeWhere: {
                insurerId: meta.claim.insurer.companyId,
                quotationType: "Scope",
                portfolioType: portfolio,
                forManager: false,
              },
            }}
            render={({ error, props }) => {
              if (!props) return <Cube />;
              return (
                <Grid container>
                  <Grid item xs={12}>
                    <ScopePresentation
                      quotes={props}
                      portfolio={portfolio}
                      changePortfolio={changePortfolio}
                      meta={meta}
                      requestCategory={[requestCategory, toggleRequestCategory]}
                      isProperties={isProperties}
                      setUpdate={setUpdate}
                    />
                  </Grid>
                </Grid>
              );
            }}
          />
        );
      }}
    />
  );
};

export default Scope;

const preQuery = graphql`
  query ScopePreQuery($where: JobQuoteIndex!) {
    jobQuotes(where: $where, first: 100000)
      @connection(key: "ScopeQuery_jobQuotes", filters: []) {
      edges {
        node {
          fields {
            propertyNumber {
              isDisplay
            }
          }
        }
      }
    }
  }
`;

const query = graphql`
  query ScopeQuery(
    $where: JobQuoteIndex!
    $jobScopeWhere: JobScopeTypeWhere!
    $isContent: Boolean!
    $isProperties: Boolean!
  ) {
    jobQuotes(where: $where, first: 100000)
      @connection(key: "ScopeQuery_jobQuotes", filters: []) {
      edges {
        node {
          id
          gst
          subtotal
          total
          margin
          fields {
            propertyNumber {
              label
            }
            propertyTypeId {
              label
            }
          }
          actions {
            startValidation {
              label
              isDisabled
              isDisplay
            }
            addLineItem {
              label
              isDisabled
              isDisplay
            }
            makeInitialCall {
              label
              isDisabled
              isDisplay
            }
            requestQuotes {
              label
              isDisabled
              isDisplay
            }
            reviewQuote {
              label
              isDisabled
              isDisplay
            }
            submitQuote {
              label
              isDisabled
              isDisplay
            }
            resetQuote {
              label
              isDisabled
              isDisplay
            }
            viewValidation {
              label
              isDisabled
              isDisplay
            }
            reallocateSupplier {
              label
              isDisabled
              isDisplay
            }
          }
          jobQuoteId
          completed
          reviewed
          supplier {
            companyId
          }

          lineItems @include(if: $isContent) {
            jobQuoteItemId
            accepted
            location {
              costLocationId
              locationName
            }
            dimensions
            costCentre {
              costCentreId
              costCentreName
            }
            costItem {
              costItemId
              itemDescription
            }
            lineDescription
            unit {
              unitId
              unitName
            }
            directsupply
            purchasePrice
            ageOfItem
            proofOfLoss
            policyLimit
            qty
            rate
            marginRate
            subtotal
            logdate
            policyLimit
            requestedQuote
            sirRate
          }

          scopeTypes @skip(if: $isContent) {
            locations @skip(if: $isProperties) {
              id
              location {
                id
                costLocationId
                locationName
              }
              items {
                jobQuoteItemId
                accepted
                location {
                  costLocationId
                  locationName
                }
                dimensions
                costCentre {
                  costCentreId
                  costCentreName
                }
                costItem {
                  costItemId
                  itemDescription
                }
                lineDescription
                unit {
                  unitId
                  unitName
                }
                directsupply
                purchasePrice
                ageOfItem
                proofOfLoss
                policyLimit
                qty
                rate
                marginRate
                subtotal
                logdate
                policyLimit
                requestedQuote
                sirRate
                scopeType {
                  name
                  id
                }
              }
            }
            properties @include(if: $isProperties) {
              id
              propertyName
              gst
              margin
              sirGst
              sirMargin
              sirSubtotal
              sirTotal
              subtotal
              total
              locations {
                id
                location {
                  id
                  costLocationId
                  locationName
                }
                items {
                  propertyNumber
                  propertyType {
                    id
                  }
                  jobQuoteItemId
                  accepted
                  location {
                    costLocationId
                    locationName
                  }
                  dimensions
                  costCentre {
                    costCentreId
                    costCentreName
                  }
                  costItem {
                    costItemId
                    itemDescription
                  }
                  lineDescription
                  unit {
                    unitId
                    unitName
                  }
                  directsupply
                  purchasePrice
                  ageOfItem
                  proofOfLoss
                  policyLimit
                  qty
                  rate
                  marginRate
                  subtotal
                  logdate
                  policyLimit
                  requestedQuote
                  sirRate
                  scopeType {
                    name
                    id
                  }
                }
              }
            }
            scopeType {
              id
              name
            }
            id
            gst
            margin
            sirGst
            sirMargin
            sirSubtotal
            sirTotal
            subtotal
            total
            discount
            fields {
              marginBreakdown {
                isDisplay
                label
              }
              discount {
                isDisplay
                label
                isDisabled
              }
              sIRRate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              gst {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              total {
                label
                isDisplay
              }
              action {
                label
                isDisplay
              }
              costCentre {
                label
                isDisplay
              }
              description {
                label
                isDisplay
              }
              marginRate {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              qty {
                label
                isDisplay
              }
              rate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              unit {
                label
                isDisplay
              }
            }
          }
        }
      }
    }
    jobScopeTypes(where: $jobScopeWhere) {
      id
    }
  }
`;
