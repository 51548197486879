/**
 * @flow
 * @relayHash b2cdf22e97f849fda8a019d5e44b7691
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessRole = "Adjustor" | "Administrator" | "Agent" | "Builder" | "ContentSupplier" | "Customer" | "Estimator" | "Insurance" | "InternalAB" | "LossAdjuster" | "MultiTenderAssessor" | "Restorer" | "Specialist" | "SystemAdmin" | "UNDEFINED" | "%future added value";
export type ExternalLoginQueryVariables = {||};
export type ExternalLoginQueryResponse = {|
  +me: ?{|
    +userName: string,
    +userRole: ?AccessRole,
    +hasMultipleAccess: boolean,
    +preference: ?{|
      +applicationFont: string,
      +applicationLanguage: string,
      +applicationTheme: string,
    |},
    +company: ?{|
      +customisation: ?{|
        +applicationAlias: ?string,
        +applicationFavicon: ?string,
        +applicationLogo: ?string,
        +displayCompanyLogo: ?string,
        +logoHeight: number,
      |}
    |},
    +privileges: ?{|
      +menus: ?$ReadOnlyArray<?{|
        +nodeName: string
      |}>
    |},
  |}
|};
export type ExternalLoginQuery = {|
  variables: ExternalLoginQueryVariables,
  response: ExternalLoginQueryResponse,
|};
*/


/*
query ExternalLoginQuery {
  me {
    userName
    userRole
    hasMultipleAccess
    preference {
      applicationFont
      applicationLanguage
      applicationTheme
    }
    company {
      customisation {
        applicationAlias
        applicationFavicon
        applicationLogo
        displayCompanyLogo
        logoHeight
      }
      id
    }
    privileges {
      menus {
        nodeName
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userName",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userRole",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasMultipleAccess",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "preference",
  "storageKey": null,
  "args": null,
  "concreteType": "UserPreference",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationFont",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationLanguage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationTheme",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customisation",
  "storageKey": null,
  "args": null,
  "concreteType": "CompanyCustomisation",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationAlias",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationFavicon",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationLogo",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "displayCompanyLogo",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "logoHeight",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "privileges",
  "storageKey": null,
  "args": null,
  "concreteType": "UserPrivileges",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "menus",
      "storageKey": null,
      "args": null,
      "concreteType": "PrivilegeMenu",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "nodeName",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ExternalLoginQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "UserProfile",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "company",
            "storageKey": null,
            "args": null,
            "concreteType": "CompanyProfile",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ExternalLoginQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "UserProfile",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "company",
            "storageKey": null,
            "args": null,
            "concreteType": "CompanyProfile",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ExternalLoginQuery",
    "id": null,
    "text": "query ExternalLoginQuery {\n  me {\n    userName\n    userRole\n    hasMultipleAccess\n    preference {\n      applicationFont\n      applicationLanguage\n      applicationTheme\n    }\n    company {\n      customisation {\n        applicationAlias\n        applicationFavicon\n        applicationLogo\n        displayCompanyLogo\n        logoHeight\n      }\n      id\n    }\n    privileges {\n      menus {\n        nodeName\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9555c0d69670f134f3c7edf28b1754f0';
module.exports = node;
