/**
 * @flow
 * @relayHash f2aaf4a476f7afcd0c0f20082ae062b2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimPortfoliosUniqueWhere = {|
  claimId: string,
  claimPortfolioType: PortfolioType,
|};
export type TenderCloseDateInput = {|
  tenderCloseDate: any
|};
export type TenderClosingDateActionMutationVariables = {|
  where: ClaimPortfoliosUniqueWhere,
  input: TenderCloseDateInput,
|};
export type TenderClosingDateActionMutationResponse = {|
  +claimUpdateTenderCloseDate: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type TenderClosingDateActionMutation = {|
  variables: TenderClosingDateActionMutationVariables,
  response: TenderClosingDateActionMutationResponse,
|};
*/


/*
mutation TenderClosingDateActionMutation(
  $where: ClaimPortfoliosUniqueWhere!
  $input: TenderCloseDateInput!
) {
  claimUpdateTenderCloseDate(where: $where, input: $input) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimPortfoliosUniqueWhere!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TenderCloseDateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimUpdateTenderCloseDate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "TenderCloseDateInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ClaimPortfoliosUniqueWhere!"
      }
    ],
    "concreteType": "ClaimPortfolioPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TenderClosingDateActionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "TenderClosingDateActionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "TenderClosingDateActionMutation",
    "id": null,
    "text": "mutation TenderClosingDateActionMutation(\n  $where: ClaimPortfoliosUniqueWhere!\n  $input: TenderCloseDateInput!\n) {\n  claimUpdateTenderCloseDate(where: $where, input: $input) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1eff7e122e895c42069e2d180cba1802';
module.exports = node;
