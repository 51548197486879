/**
 * @flow
 * @relayHash 533e512278267eb0b2cc629fd46cf96d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type useActionsUploadPhotoMutationVariables = {|
  sessionId: string,
  file: any,
|};
export type useActionsUploadPhotoMutationResponse = {|
  +photoUpload: ?{|
    +id: string
  |}
|};
export type useActionsUploadPhotoMutation = {|
  variables: useActionsUploadPhotoMutationVariables,
  response: useActionsUploadPhotoMutationResponse,
|};
*/


/*
mutation useActionsUploadPhotoMutation(
  $sessionId: ID!
  $file: Upload!
) {
  photoUpload(sessionId: $sessionId, file: $file) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sessionId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "file",
    "type": "Upload!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "photoUpload",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "file",
        "variableName": "file",
        "type": "Upload!"
      },
      {
        "kind": "Variable",
        "name": "sessionId",
        "variableName": "sessionId",
        "type": "ID!"
      }
    ],
    "concreteType": "Room",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "useActionsUploadPhotoMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "useActionsUploadPhotoMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "useActionsUploadPhotoMutation",
    "id": null,
    "text": "mutation useActionsUploadPhotoMutation(\n  $sessionId: ID!\n  $file: Upload!\n) {\n  photoUpload(sessionId: $sessionId, file: $file) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ef4e9c5a540bfbf0cb84b9adbb1c40b';
module.exports = node;
