import React from "react";
import { createPaginationContainer, graphql } from "react-relay";

import AcknowledgeHistoryMutation from "../../ClaimDetailsAction/AcknowledgeHistoryMutation";
import { withStyles } from "@material-ui/core/styles";
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon/";
import Grid from "components/Mui/Grid";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const HistoriesListItem = ({ classes, data, ...props }) => {
  const scrollHandler = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight * 0.7) {
      loadMore();
    }
  };
  const loadMore = () => {
    if (!props.relay.hasMore()) return null;
    else if (props.relay.isLoading()) return null;
    props.relay.loadMore();
  };

  React.useEffect(() => {
    document
      .querySelector("#historiesPagination_scrolling-list")
      .addEventListener("scroll", scrollHandler);
    return () => {
      document
        .querySelector("#historiesPagination_scrolling-list")
        .removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <React.Fragment>
      {
        <div
          className={classes.listBody}
          id="historiesPagination_scrolling-list"
        >
          {data.claimUpdateHistories &&
          data.claimUpdateHistories.edges.length !== 0
            ? data.claimUpdateHistories.edges.map(({ node }, k) => {
                return (
                  <Grid fluid className={classes.listItem}>
                    <Grid item xs={2} className={classes.alignCenter}>
                      <ClaimTypeIcon type={node.portfolioType} />
                      <span>{node.logDate}</span>
                    </Grid>
                    <Grid className={classes.alignCenter} item xs={1}>
                      {node.logCompanyName}
                    </Grid>
                    <Grid className={classes.alignCenter} item xs={1}>
                      {node.logUserName}
                    </Grid>
                    <Grid className={classes.alignCenter} item xs={2}>
                      {node.updateTypeName}
                    </Grid>
                    <Grid className={classes.alignLeft} item xs={3}>
                      {node.message}
                    </Grid>
                    <Grid className={classes.alignCenter} item xs={1}>
                      {node.receiverCompanyName}
                    </Grid>
                    <Grid className={classes.alignCenter} item xs={1}>
                      {node.acknowledged ? (
                        <Tooltip
                          title={
                            node.acknowledgeUserName && node.acknowledged
                              ? `${node.acknowledgeUserName} - ${node.acknowledgeDate}`
                              : ""
                          }
                        >
                          <Icon>check_circle</Icon>
                        </Tooltip>
                      ) : node.actions.acknowledge.isDisplay ? (
                        <Icon onClick={(e) => acknowledge(e, node)}>
                          radio_button_unchecked
                        </Icon>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid className={classes.alignCenter} item xs={1}>
                      {node.private ? "Private" : "Public"}
                    </Grid>
                  </Grid>
                );
              })
            : "No history found"}
        </div>
      }
    </React.Fragment>
  );
};
const acknowledge = async (e, node) => {
  e.stopPropagation();
  const res = await AcknowledgeHistoryMutation({
    where: {
      id: node.id,
      portfolioType: node.portfolioType,
    },
  });
  if (res.success) {
    const ref = window[Symbol.for("__refreshCommunication")];
    if (ref) ref.refresh();
  } else {
    const snackbar = window[Symbol.for("__snackbar")];
    snackbar.current.toggleOpen({ message: res.messages[0] });
  }
};

export default createPaginationContainer(
  withStyles((theme) => ({
    listItem: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      "&:nth-child(even)": {
        background: theme.palette.grey[100],
      },
      "&:hover": {
        background: theme.palette.grey[300],
      },
      position: "relative",
    },
    listBody: {
      maxHeight: "55vh",
      overflow: "auto",
    },
    alignCenter: {
      ...theme.mixins.alignJustifyContainer(theme),
      textAlign: "center",
    },
    alignLeft: {
      wordBreak: "break-word",
      display: "flex",
      alignContents: "flex-start",
    },
  }))(HistoriesListItem),
  graphql`
    fragment HistoriesListItem on Query {
      claimUpdateHistories(first: $first, after: $after, where: $where)
        @connection(
          key: "HistoriesListItem_claimUpdateHistories"
          filters: []
        ) {
        edges {
          node {
            id
            portfolioType
            logDate
            acknowledgeDate
            acknowledgeUserName
            logCompanyName
            logUserName
            updateTypeName
            message
            receiverCompanyName
            acknowledged
            private
            actions {
              acknowledge {
                isDisplay
              }
            }
          }
        }
      }
    }
  `,
  {
    query: graphql`
      query HistoriesListItemQuery(
        $first: Int!
        $after: String
        $where: ClaimUpdateWhere!
      ) {
        ...HistoriesListItem
      }
    `,
    getVariables(props, paginationInfo, fragmentVariables) {
      const { cursor } = paginationInfo;
      const { where, first } = fragmentVariables;
      return {
        first,
        after: cursor,
        where,
      };
    },
  }
);
