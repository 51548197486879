/**
 * @flow
 * @relayHash 62bee23e6452943dda8aa19b9f8b1962
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type RequestCategory = "All" | "AssessorReport" | "Authorised" | "DesktopQuoting" | "Lost" | "ScopeValidation" | "Scoping" | "%future added value";
export type JobQuoteIndex = {|
  id?: ?$ReadOnlyArray<number>,
  claimId?: ?string,
  scopeTypeId?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  requestCategory?: ?RequestCategory,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type ScopeValidationViewDialogFooterQueryVariables = {|
  where: JobQuoteIndex
|};
export type ScopeValidationViewDialogFooterQueryResponse = {|
  +jobQuotes: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +total: ?number,
        +validationTotal: ?number,
        +validationSavings: ?number,
        +validationSavingPercentage: ?string,
        +jobQuoteId: number,
      |}
    |}>
  |}
|};
export type ScopeValidationViewDialogFooterQuery = {|
  variables: ScopeValidationViewDialogFooterQueryVariables,
  response: ScopeValidationViewDialogFooterQueryResponse,
|};
*/


/*
query ScopeValidationViewDialogFooterQuery(
  $where: JobQuoteIndex!
) {
  jobQuotes(where: $where) {
    edges {
      node {
        total
        validationTotal
        validationSavings
        validationSavingPercentage
        jobQuoteId
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JobQuoteIndex!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "JobQuoteIndex!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "validationTotal",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "validationSavings",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "validationSavingPercentage",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "jobQuoteId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ScopeValidationViewDialogFooterQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobQuotes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuoteConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuoteEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobQuote",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ScopeValidationViewDialogFooterQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobQuotes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuoteConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuoteEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobQuote",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ScopeValidationViewDialogFooterQuery",
    "id": null,
    "text": "query ScopeValidationViewDialogFooterQuery(\n  $where: JobQuoteIndex!\n) {\n  jobQuotes(where: $where) {\n    edges {\n      node {\n        total\n        validationTotal\n        validationSavings\n        validationSavingPercentage\n        jobQuoteId\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b349adfddf5f31cbf57a568b3fc75b7d';
module.exports = node;
