import React from "react";
import environment from "common/relay";
import { graphql, fetchQuery } from "react-relay";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "components/Mui/IconButton";
import AnnouncementsBellDialog from "./AnnouncementsBellDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const AnnouncementBell = ({ icon, classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [announcements, setAnnouncements] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const [openSubDialog, setOpenSubDialog] = React.useState(false);
  const handleSubDialogOpen = (index) => {
    setActiveStep(index);
    setOpenSubDialog(true);
  };
  const handleSubDialogClose = () => {
    setOpenSubDialog(false);
  };

  const [activeStep, setActiveStep] = React.useState(0);

  const handleClick = (event) => {
    if (
      announcements &&
      announcements.edges &&
      announcements.edges.length === 0
    ) {
      handleClickOpen();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (Boolean(anchorEl)) {
      const variables = {
        first: 100000,
        where: {
          includeAcknowledged: true,
          disabled: false,
        },
      };
      fetchQuery(environment, query, variables)
        .then((data) => setAnnouncements(data.announcements))
        .catch((error) => console.log(error));
    }
  }, [anchorEl]);

  return (
    <>
      <IconButton
        className={classes.iconButton}
        iconClass={classes.icon}
        icon={icon}
        onClick={handleClick}
      />
      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className={classes.subheader}>Announcements</div>
        {announcements &&
          announcements.edges &&
          announcements.edges.length !== 0 &&
          announcements.edges.map((announcement, index) => (
            <MenuItem
              key={index}
              onClick={() => handleSubDialogOpen(index)}
              className={classes.list}
            >
              {announcement.node.displayType}
            </MenuItem>
          ))}
      </Menu>
      <Dialog
        open={openSubDialog}
        onClose={handleSubDialogClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <AnnouncementsBellDialog
            announcements={announcements}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleSubDialogClose={handleSubDialogClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Announcements</DialogTitle>
        <DialogContent>There is no announcements</DialogContent>
      </Dialog>
    </>
  );
};

const styles = (theme) => ({
  iconButton: {
    height: "100%",
  },
  icon: {
    height: "100%",
    color: theme.palette.primary.contrastText,
    fontSize: "28px",
  },
  menu: {
    '& div[role="document"]': {
      top: "50px !important",
    },
  },
  subheader: {
    color: "gray",
    textAlign: "center",
    paddingBottom: "10px",
    paddingTop: "10px",
    borderBottom: "2px grey solid",
  },
});

export default withStyles(styles)(AnnouncementBell);

const query = graphql`
  query AnnouncementsBellQuery($where: AnnouncementWhere!, $first: Int) {
    announcements(where: $where, first: $first) {
      edges {
        node {
          title
          content
          id
          type
          displayType
        }
      }
    }
  }
`;
