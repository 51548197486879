import React from 'react'

import { Formik, Form } from 'formik'
import DateInput from 'components/Formik/DateInput'
import moment from "moment";

import Dialog from 'components/Mui/Dialog'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'
import TenderClosingDateActionMutation from './TenderClosingDateActionMutation'

class TenderClosingDateAction extends React.Component {
  handleDlClose = () => {
    const action = window[Symbol.for('__tenderClosingDateAction')]
    if(action) action.handleClose()
  }
  render() {
    const {data, claimId, portfolioType} = this.props
    return (
      <Dialog
        title=''
        noForm
        noActions
        symbol='__tenderClosingDateAction'
        content={
          <Formik
            initialValues={{
              tenderCloseDate: data && data.tenderCloseDate? moment(data.tenderCloseDate, 'DD-MM-YYYY') : moment()
            }}
            validate={(values) => {
              let errors = {}
              if(!values.tenderCloseDate) errors.tenderCloseDate = 'Required!'

              return errors
            }}
            onSubmit={async (values, actions) => {
              const res = await TenderClosingDateActionMutation({
                where: { claimId: claimId, claimPortfolioType: portfolioType },
                input: {
                  tenderCloseDate: (typeof (values.tenderCloseDate) === 'string')? values.tenderCloseDate : values.tenderCloseDate.format("YYYY/MM/DD")
                }
              })
              if (res.ok) {
                window[Symbol.for('__snackbar')].toggleOpen({
                  message: res.messages[0]
                })
                if(window[Symbol.for('__refreshQuotes')] && res.success) {
                  window[Symbol.for('__refreshQuotes')].handleRefresh()
                }
                this.handleDlClose()
              }
            }}
          >
            {({ isSubmitting, values }) => {
              return (
                <Form>
                  <Grid fluid spacing={8} justify='flex-end'>
                      <Grid item xs={12}>
                        <DateInput
                          required
                          minDate={moment()}
                          name='tenderCloseDate'
                          label='Quote Closing Date'
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                          fullWidth
                          variant='contained'
                          type='submit'
                          color='primary'
                          label='Submit'
                          disabled={isSubmitting}
                        />
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        }
      />
    )
  }
}

export default TenderClosingDateAction
