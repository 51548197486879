import React from 'react'

import Icon from '@material-ui/core/Icon'
import Dialog from 'components/Mui/Dialog'
import IconButton from '@material-ui/core/IconButton'

export default ({ url, className, title }) => {
  const doc = React.useRef()
  const ext = url.substring(url.lastIndexOf('.') + 1, url.length).toLowerCase()
  const dialogable = ['pdf']
  const imageFormats = ['jpg', 'jpeg', 'png']
  return (
    <div className={className ? className : ''}>
      {
        dialogable.indexOf(ext) === -1 && imageFormats.indexOf(ext) === -1
          ? <a target='_blank' href={url} rel='noopener noreferrer'>
              <IconButton title={title} style={{ padding: '8px' }}>
                  <Icon>description</Icon>
              </IconButton>
            </a>
          :
          <IconButton title={title} style={{ padding: '8px' }} onClick={() => {
              doc.current.handleOpen()
            }}>
            <Icon>description</Icon>
          </IconButton>

      }
      <Dialog
        innerRef={doc}
        title='Document'
        content={
          <>
            {ext === 'pdf' && <iframe title='pdfviewer' src={url} style={{
              width:'80vw',
              height: '70vh',
            }}></iframe>}
            {imageFormats.indexOf(ext) !== -1 && <img
              style={{width: '100%'}} src={url}
              alt='endata'
            />
            }
          </>
        }
      />
    </div>
  )
}