import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import MuiTextField from '@material-ui/core/TextField'
// import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiInputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import { dateFormat } from 'common/utils'

class Input extends Component {
  state = {
    defaultValue: '',
    innerValue: ''
  }

  componentWillMount = () => {
    let { value, type } = this.props
    if(value !== undefined && value !== null) {
      if(type === 'datetime-local' && value) value = dateFormat(value)
      if(type === 'date' && value) value = dateFormat(value, 'date')
      this.setState({ innerValue : value, defaultValue: value })
    }
  }
  shouldComponentUpdate = nextProps => {
    // ?? something >>
    if(
      (
        !(this.props.type === 'date' && this.props.type === 'datetime-local')
        && String(this.state.innerValue) !== String(nextProps.value)
      )
      && (
        (this.props.type === 'date' && this.props.type === 'datetime-local')
        && String(this.state.innerValue) !== String(dateFormat(nextProps.value, this.props.type))
      )
      && nextProps.value !== this.state.defaultValue
      && nextProps.value !== undefined
      && nextProps.value !== null
    ) {
        const { noSpecial } = this.props
        const regex = new RegExp(/^[a-zA-Z0-9 ]+$/g)

        if(this.props.type === 'datetime-local') {
          this.setState({ innerValue: dateFormat(nextProps.value) })
        }
        else if(!this.props.select) {
          if(!(noSpecial && !regex.test(nextProps.value))) {
            // console.log(noSpecial)
            this.setState({ innerValue: nextProps.value })
          }
        }
        else {
          this.setState({ innerValue: nextProps.value })
        }
    }
    return true
  }

  render() {
    let {
      select, options, currentOption, noSpecial,
      type, value, error,
      leadDom, trailDom, inputProps,
      leadIcon, trailIcon,
      viewMode, disabled,
      classes,
      ...rest
    } = this.props
    const { innerValue } = this.state

    if(select && currentOption && Object.keys(currentOption).length !== 0) {
      if(options && options.length !== 0) {
        if(options.filter(x => x.value === currentOption.value).length === 0) {
          options = [ ...options, currentOption ]
        }
      } else {
        options = [ currentOption ]
      }
    }

    return (
      <MuiTextField {...rest}
        disabled={viewMode || disabled}
        error={error}
        type={type ? type : 'text'} value={innerValue} select={select}
        fullWidth variant='outlined'
        InputProps={{
          startAdornment: (
            leadIcon ? <MuiInputAdornment position='start'><Icon>{leadIcon}</Icon></MuiInputAdornment> :
            leadDom ? <MuiInputAdornment position='start'>{leadDom}</MuiInputAdornment> : null
          ),
          endAdornment: (
            trailIcon ? <MuiInputAdornment position='start'><Icon>{trailIcon}</Icon></MuiInputAdornment> :
            trailDom ? <MuiInputAdornment position='end'>{trailDom}</MuiInputAdornment> : null
          ),
          inputComponent: 'input',
          className: viewMode ? classes.view : ''
        }}
        // eslint-disable-next-line
        inputProps={{
          customtype: select ? 'text' : type ? type : 'text',
          maxlength: 10,

          ...inputProps
        }}
        onChange={this.handleChange}

        SelectProps={{native: select}}

        InputLabelProps={{ className: viewMode ? classes.view : '' }}
      >
        <option value=''></option>
        {select && options && options.map((option, key) => {
          return (
          // <MuiMenuItem key={key} value={option.value}>
          //   {option.label}
          // </MuiMenuItem>
          <option key={key} value={option.value}>{option.label}</option>
        )})}
      </MuiTextField>
    )
  }

  handleChange = event => {
    const { noSpecial } = this.props
    const regex = new RegExp(/^[a-zA-Z0-9 ]+$/g)

    if(!this.props.select) {
      if(!(noSpecial && !regex.test(event.target.value))) {
        this.setState({ innerValue: event.target.value })
      }
    } else {
      this.setState({ innerValue: event.target.value })
    }
  }
}

export default withStyles(theme => ({
  view: {
    color: 'rgba(0, 0, 0, 0.78) !important'
  }
}))(Input)