/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MakeSafePresentation_claim$ref: FragmentReference;
export type MakeSafePresentation_claim = {|
  +claim: ?{|
    +id: string,
    +building: ?{|
      +makesafeCompletedDate: ?any,
      +makeSafeProgress: ?{|
        +initialCallMade: boolean,
        +appointmentBooked: boolean,
      |},
      +scopingSupplier: ?{|
        +companyId: number
      |},
    |},
    +restoration: ?{|
      +makesafeCompletedDate: ?any,
      +makeSafeProgress: ?{|
        +initialCallMade: boolean
      |},
      +scopingSupplier: ?{|
        +companyId: number
      |},
    |},
  |},
  +$refType: MakeSafePresentation_claim$ref,
|};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "makesafeCompletedDate",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "initialCallMade",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "scopingSupplier",
  "storageKey": null,
  "args": null,
  "concreteType": "Company",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyId",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "MakeSafePresentation_claim",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "claimWhere",
      "type": "ENDataEntityKey!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "claim",
      "name": "claimJob",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "claimWhere",
          "type": "ENDataEntityKey!"
        }
      ],
      "concreteType": "ClaimJob",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "building",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimPortfolio",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "makeSafeProgress",
              "storageKey": null,
              "args": null,
              "concreteType": "ClaimProgress",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "appointmentBooked",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            (v2/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "restoration",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimPortfolio",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "makeSafeProgress",
              "storageKey": null,
              "args": null,
              "concreteType": "ClaimProgress",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ]
            },
            (v2/*: any*/)
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6465a5909dcc1d7b7750f8e4a189a8ff';
module.exports = node;
