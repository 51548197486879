import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import { useMutation } from 'react-relay-mutation'

import FeeInvoiceList from './FeeInvoiceList'
import FeeInvoiceUploadDialog from '../../Actions/FeeInvoiceUploadDialog'
import FeeItemEditDialog from '../../Actions/FeeItemEditDialog'
import FeeItemDeleteMutation from '../../Actions/FeeItemDeleteMutation'

import moneyFormat from 'common/utils/moneyFormat'
import FeeInfo from './FeeInfo'

import { useFeeReloadContext } from '../../FeeReloadContext'

export default ({ items }) => {
  return (
    <List component='div' disablePadding>
      {items.map((item, index) => <FeeItem key={index} item={item} />)}
    </List>
  )
}
const FeeItem = withStyles(theme => ({
  nested: {
    padding: `0 !important`,
    margin: 0,
    '&:hover': {
      background: theme.palette.grey[200],
    },
  },
  title: { paddingLeft: `${theme.spacing.unit * 11}px !important`, display: 'flex', alignItems:'center' },
  info: {marginLeft:'10px'},
  amount: { textAlign: 'center', paddingRight: '18px !important' },
  actions: { textAlign: 'center' },
  invoiceList: { padding: '0 !important', borderLeft: '1px solid rgba(0, 0, 0, 0.42)' },
  actionIcons: { padding: 8 }
})) (({ item, classes }) => {
  const snackbar = window[Symbol.for('__snackbar')]
  const { reload, setReload } = useFeeReloadContext()

  const [deleteFeeItem] = useMutation(FeeItemDeleteMutation)

  const getAcions = (actions, feeItemId) => {
    return actions.map(action => {
      switch(action.actionType) {
        case 'FEE_EDIT':
          return {
            ...action,
            // isDisplay: true,
            // isDisabled: false,
            icon: 'edit',
            onClick: () => {
              if(window[Symbol.for(`__feeItemEditDialog_${feeItemId}`)]){
                window[Symbol.for(`__feeItemEditDialog_${feeItemId}`)].handleOpen()
              }
            },
          }
        case 'INVOICE_UPLOAD':
          return {
            ...action,
            icon: 'cloud_upload',
            onClick: () => {
              if(window[Symbol.for(`__feeInvoiceUploadDialog_${feeItemId}`)]){
                window[Symbol.for(`__feeInvoiceUploadDialog_${feeItemId}`)].handleOpen()
              }
            }
          }
        case 'FEE_DELETE':
          return {
            ...action,
            icon: 'delete',
            onClick: async () => {
              const res = await window[Symbol.for('__snackbar')].toggleOpen({
                message: 'Please confirm to delete the item',
                firstButton: 'Delete',
                secondButton: 'Cancel',
                type: 'confirm'
              })
              if(res.ok) {
                const ret = await deleteFeeItem({
                  variables: {
                    where: {
                      feeItemId
                    }
                  }
                })
                snackbar.toggleOpen({
                  message: ret.feeItemDelete.messages[0]
                })
                if(ret.feeItemDelete.success) {
                  setReload(!reload)
                }
              }
            }
          }
        default:
          return action
      }
    })
  }

  return (
    <>
      <FeeInvoiceUploadDialog
        feeItemId={item.feeItemId}
        toBeInvoicedAmount={item.toBeInvoicedAmount}
        symbol={`__feeInvoiceUploadDialog_${item.feeItemId}`}
        feeDescription={item.description}
      />
      <FeeItemEditDialog
        feeDescription={item.description}
        supplier={item.supplier ? item.supplier.name : ''}
        feeItemId={item.feeItemId}
        authorisedAmount={item.authorisedAmount}
        authorisedMaxAmount={item.maxAmount}
        symbol={`__feeItemEditDialog_${item.feeItemId}`}
      />

      <ListItem className={classes.nested} component={Grid} fluid>
        <Grid item xs={4} className={classes.title}>
          <FeeInfo feeItem={item} />
          <p className={classes.info}>{`${item.description} ${item.supplier ? '- ' + item.supplier.name : ''}`}</p>
        </Grid>
        <Grid item xs={1} className={classes.amount}>
          {moneyFormat(item.authorisedAmount)}
        </Grid>
        <Grid item xs={1} className={classes.actions}>
          <Grid fluid>
            {getAcions(item.actions, item.feeItemId).filter(button=>button.actionType!=="RESERVE_DETAIL_VIEW").map((button, index) => {
              return (
                <Grid item xs={4} key={index}>
                  {
                    button.isDisplay && (
                      <IconButton
                        title={button.name}
                        disabled={button.isDisabled}
                        onClick={button.onClick}
                        className={classes.actionIcons}
                      >
                        <Icon>{button.icon}</Icon>
                      </IconButton>
                    )
                  }
                </Grid>
              )
            })}
          </Grid>

        </Grid>
        <Grid item xs={6} className={classes.invoiceList}>
          <FeeInvoiceList invoices={item.feeItemInvoices} />
        </Grid>
      </ListItem>
      <Divider />
    </>
  )
})