import React from "react";
import { graphql, fetchQuery } from "react-relay";
import environment from "common/relay";
import moment from "moment";
import { Formik, Form } from "formik";
import { markupToContext, contextToMarkup } from "common/utils";
import { TryParseInt } from "common/utils/intData";

// hooks
import usePortfolio from "hooks/usePortfolio";
import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";

import { siteReportTemplate } from "common/static_data";
import SiteReportPresentation from "./SiteReportPresentation";
import UpdateSiteReportMutation from "./UpdateSiteReportMutation";
import PreventNavigationDialog from "components/PreventNavigationDialog";

const SiteReport = () => {
  const snackbar = window[Symbol.for("__snackbar")];
  const meta = React.useContext(ClaimMetaContext);

  /**
   * METHODS
   */
  const getSiteReportDescription = (data, portfolio) => {
    const description =
      data.siteReportDescription && data.siteReportDescription.length !== 0
        ? data.siteReportDescription
        : siteReportTemplate[portfolio];
    const dkm = description.replace(new RegExp("<p></p>", "g"), "<p>&nbsp</p>");
    return markupToContext(dkm);
  };

  /**
   * routing and portfolio route
   */
  const [portfolio, changePortfolio] = usePortfolio([
    "building",
    "restoration",
  ]);

  /**
   * force update renderer
   */
  const [update, setUpdate] = React.useState(false);
  const forceUpdate = () => setUpdate(!update);
  React.useEffect(() => {
    window[Symbol.for("__refreshSiteReport")] = forceUpdate;
    return () => {
      delete window[Symbol.for("__refreshSiteReport")];
    };
  }, [update]);

  /**
   * fetch data
   */
  const [fetchData, setFetchData] = React.useState(null);
  React.useEffect(() => {
    if (portfolio) {
      const variables = {
        update,
        where: {
          id: meta.claim.claimId,
        },
        isBuilding: portfolio === "Building",
        isRestoration: portfolio === "Restoration",
        // claimParticipantsWhere: {
        //   claimId: meta.claim.claimId,
        //   portfolioTypes: portfolio,
        //   participantStatus: "RequestedAndAvailable",
        //   participantType: "Supplier",
        // },
        jobScopeTemplatesWhere: {
          portfolioType: portfolio,
        },
      };

      fetchQuery(environment, query, variables).then((data) => {
        console.log(data);
        setFetchData(data);
      });
    }
  }, [update, meta.claim.claimId, portfolio]);

  /**
   * portfolio data, isAbleToEdit
   */
  const [data, setData] = React.useState(null);
  const [action, setAction] = React.useState(null);
  //const [requestQuotesAction, setRequestQuotesAction] = React.useState(null);
  const [isAbleToEdit, setIsAbleToEdit] = React.useState(null);
  React.useEffect(() => {
    if (fetchData) {
      const data =
        portfolio === "Building"
          ? fetchData.claimJob.building
          : portfolio === "Restoration"
          ? fetchData.claimJob.restoration
          : null;
      setData(data);
      const action =
        portfolio === "Building"
          ? fetchData.claimJob.view.actions.releaseSiteReport.building
          : portfolio === "Restoration"
          ? fetchData.claimJob.view.actions.releaseSiteReport.restoration
          : null;
      setAction(action);
      // const requestQuotesAction =
      //   portfolio === "Building"
      //     ? fetchData.claimJob.view.actions.requestMultipleQuotesOrTenders
      //         .building
      //     : portfolio === "Restoration"
      //     ? fetchData.claimJob.view.actions.requestMultipleQuotesOrTenders
      //         .restoration
      //     : null;
      // setRequestQuotesAction(requestQuotesAction);

      if (data) {
        const isAbleToEdit =
          (portfolio === "Building" &&
            meta.user.type.isBuilder &&
            data.scopingSupplier &&
            data.scopingSupplier.companyId === meta.user.company.companyId &&
            data.toProvideSiteReport) ||
          (portfolio === "Restoration" &&
            meta.user.type.isRestorer &&
            data.scopingSupplier &&
            data.scopingSupplier.companyId === meta.user.company.companyId &&
            data.toProvideSiteReport) ||
          (meta.user.type.isAdmin && !data.toProvideSiteReport)
            ? true
            : false;

        setIsAbleToEdit(isAbleToEdit);
      }
    }
  }, [fetchData, portfolio]);

  if (!data) return null;
  //const claimParticipantData = fetchData.claimParticipants;
  const jobScopeTemplatesData = fetchData.jobScopeTemplates;
  const tenderClosingDate = fetchData.claimJob[`${portfolio.toLowerCase()}`]
    ? fetchData.claimJob[`${portfolio.toLowerCase()}`].tenderCloseDate
    : null;
  const vulnerableMember =
    fetchData.claimJob.view.fields.siteReportVulnerableMember;
  return (
    <Formik
      initialValues={{
        //xxx: ['1009']
        suppervisorId: data.supervisor ? data.supervisor.id : "",
        estimatorId: data.estimator ? data.estimator.id : "",
        claimRecommendationId: data.claimRecommendation
          ? data.claimRecommendation.claimRecommendationId
          : "",
        reportDate: data.reportDate
          ? moment(data.reportDate, "DD/MM/YYYY").add(10, "h")
          : null,
        emergencyMakeSafeWorks: data.emergencyMakeSafeWorks,
        isAClaimId:
          (data.siteReport &&
            data.siteReport.isAClaim &&
            data.siteReport.isAClaim.isAClaimId) ||
          null,
        // sumInsuredAdequate: data.sumInsuredAdequate,
        // building info
        houseTypeId:
          data.siteReport.houseType && data.siteReport.houseType.houseTypeId,
        claddingTypeId:
          data.siteReport.claddingType &&
          data.siteReport.claddingType.claddingTypeId,
        roofTypeId:
          data.siteReport.roofType && data.siteReport.roofType.roofTypeId,
        dwellingTypeId:
          data.siteReport.dwellingType &&
          data.siteReport.dwellingType.dwellingTypeId,
        foundationTypeId:
          data.siteReport.foundationType &&
          data.siteReport.foundationType.foundationTypeId,
        dwellingAgeId:
          data.siteReport.dwellingAge &&
          data.siteReport.dwellingAge.dwellingAgeId,
        // site report description
        siteReportDescription: getSiteReportDescription(data, portfolio),
        vulnerableMember: fetchData.claimJob.incidentDetail.vulnerableMember,
      }}
      validate={(values) => validate(values, isAbleToEdit)}
      onSubmit={({ siteReportDescription, ...values }, actions) => {
        setTimeout(async () => {
          const variables = {
            where: {
              id: meta.claim.claimId,
              portfolioType: portfolio,
            },
            input: {
              ...values,
              siteReportDescription: contextToMarkup(siteReportDescription),
            },
          };

          // Turn into integer
          Object.keys(variables.input).forEach((k) => {
            variables.input[k] = TryParseInt(variables.input[k]);
          });

          const res = await UpdateSiteReportMutation(variables);
          if (res.ok) {
            if (res.updateSiteReport.success) {
              actions.setTouched({});
              snackbar.toggleOpen({
                message: "Saved Successfully",
              });
              forceUpdate();
            } else {
              actions.setTouched({});
              snackbar.toggleOpen({
                message: res.updateSiteReport.messages[0],
              });
            }
          } else
            snackbar.toggleOpen({
              message: "Save Failed",
            });

          actions.setSubmitting(false);
        }, 400);
      }}
    >
      {({
        isSubmitting,
        touched,
        values,
        handleSubmit,
        submitForm,
        isValidating,
      }) => {
        const isTouched = Object.values(touched).indexOf(true) !== -1;
        return (
          <Form>
            <PreventNavigationDialog when={isTouched && isAbleToEdit} />
            <SiteReportPresentation
              isSubmitting={isSubmitting}
              isAbleToEdit={isAbleToEdit}
              claim={fetchData.claimJob}
              options={fetchData.costedScopeOptions}
              portfolios={portfolio}
              forceUpdate={forceUpdate}
              meta={meta}
              data={data}
              action={action}
              changePortfolio={changePortfolio}
              //requestQuotesAction={requestQuotesAction}
              //claimParticipantData={claimParticipantData}
              jobScopeTemplatesData={jobScopeTemplatesData}
              tenderClosingDate={tenderClosingDate}
              vulnerableMember={vulnerableMember}
              submitForm={submitForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default SiteReport;

const validate = (values, isAbleToEdit) => {
  let errors = {};
  if (!isAbleToEdit) return errors;
  [
    "suppervisorId",
    "estimatorId",
    "claimRecommendationId",
    "houseTypeId",
    "claddingTypeId",
    "roofTypeId",
    "dwellingTypeId",
    "foundationTypeId",
    "dwellingAgeId",
  ].forEach((e) => {
    if (!values[e]) errors[e] = "Required!";
  });
  return errors;
};

const query = graphql`
  query SiteReportQuery(
    $where: ENDataEntityKey!
    #$claimParticipantsWhere: ClaimParticipantIndex
    $jobScopeTemplatesWhere: ScopeTemplateWhere!
    $isBuilding: Boolean!
    $isRestoration: Boolean!
  ) {
    jobScopeTemplates(where: $jobScopeTemplatesWhere) {
      description
      value: id
      label: name
      portfolioType
    }
    claimJob(where: $where) {
      id
      insurer {
        companyId
        hideCategoryOfClaim
      }
      incidentDetail {
        vulnerableMember
        incidentDate
        category
      }
      caseManager {
        managerName
      }
      view {
        fields {
          siteReportVulnerableMember {
            id
            isDisplay
            label
          }
          vulnerableMember {
            id
            isDisabled
            isDisplay
            label
            name
          }
        }
        actions {
          releaseSiteReport {
            building @include(if: $isBuilding) {
              id
              isDisabled
              isDisplay
              label
              name
            }
            restoration @include(if: $isRestoration) {
              id
              isDisabled
              isDisplay
              label
              name
            }
          }
          # requestMultipleQuotesOrTenders {
          #   building @include(if: $isBuilding) {
          #     id
          #     isDisabled
          #     isDisplay
          #     label
          #     name
          #   }
          #   restoration @include(if: $isRestoration) {
          #     id
          #     isDisabled
          #     isDisplay
          #     label
          #     name
          #   }
          # }
        }
      }

      building @include(if: $isBuilding) {
        tenderCloseDate
        toProvideSiteReport
        authorised
        scopingSupplier {
          companyId
        }
        siteReportDescription
        toProvideSiteReport
        insuranceCompletedSiteReport

        #Report Details
        reportDate
        emergencyMakeSafeWorks
        sumInsured
        excessValue
        sumInsuredAdequate
        supervisor {
          id
          supervisorName: name
          supervisorNameWithPhone: nameWithPhone
        }
        estimator {
          id
          estimatorName: name
          estimatorNameWithPhone: nameWithPhone
        }
        claimRecommendation {
          claimRecommendationId
        }
        siteReport {
          isAClaim {
            isAClaimId
            isAClaimName
          }
        }

        # Building Info
        siteReport {
          houseType {
            houseTypeId
            houseTypeName
          }
          claddingType {
            claddingTypeId
            claddingTypeName
          }
          roofType {
            roofTypeId
            roofTypeName
          }
          dwellingType {
            dwellingTypeId
            dwellingTypeName
          }
          foundationType {
            foundationTypeId
            foundationTypeName
          }
          dwellingAge {
            dwellingAgeId
            dwellingAgeName
          }
        }
      }
      restoration @include(if: $isRestoration) {
        tenderCloseDate
        toProvideSiteReport
        authorised
        scopingSupplier {
          companyId
        }
        siteReportDescription
        toProvideSiteReport
        insuranceCompletedSiteReport

        #Report Details
        reportDate
        emergencyMakeSafeWorks
        sumInsured
        excessValue
        sumInsuredAdequate
        supervisor {
          id
          supervisorName: name
          supervisorNameWithPhone: nameWithPhone
        }
        estimator {
          id
          estimatorName: name
          estimatorNameWithPhone: nameWithPhone
        }
        claimRecommendation {
          claimRecommendationId
        }
        siteReport {
          isAClaim {
            isAClaimId
            isAClaimName
          }
        }

        # Building Info
        siteReport {
          houseType {
            houseTypeId
            houseTypeName
          }
          claddingType {
            claddingTypeId
            claddingTypeName
          }
          roofType {
            roofTypeId
            roofTypeName
          }
          dwellingType {
            dwellingTypeId
            dwellingTypeName
          }
          foundationType {
            foundationTypeId
            foundationTypeName
          }
          dwellingAge {
            dwellingAgeId
            dwellingAgeName
          }
        }
      }
    }
  }
`;
