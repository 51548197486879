/**
 * @flow
 * @relayHash 7958cd0ead9cebaf5097855a5f946ec8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CallStatusEnum = "CHECK_REQUIREMENTS" | "CHECK_REQUIREMENTS_FAILED" | "CONNECTED" | "ENDED" | "INIT_SESSION" | "SIGNALLING" | "SIGNALLING_FAILED" | "%future added value";
export type RoomJoinWhereInput = {|
  roomId?: ?string,
  callSessionId?: ?string,
|};
export type RoomJoinDataInput = {|
  accountId?: ?string,
  sdpOffer?: ?string,
  clientIceCandidates: $ReadOnlyArray<string>,
  userAgentInfo?: ?string,
  userAddressInfo?: ?string,
  isSupportNoDevice?: ?boolean,
  forceStop?: ?boolean,
|};
export type JoinRoomMutationVariables = {|
  where?: ?RoomJoinWhereInput,
  data?: ?RoomJoinDataInput,
|};
export type JoinRoomMutationResponse = {|
  +roomJoin: ?{|
    +sdpAnswer: ?string,
    +serverIceCandidates: $ReadOnlyArray<string>,
    +callSession: ?{|
      +id: string,
      +callStatus: {|
        +status: CallStatusEnum,
        +message: string,
      |},
    |},
  |}
|};
export type JoinRoomMutation = {|
  variables: JoinRoomMutationVariables,
  response: JoinRoomMutationResponse,
|};
*/


/*
mutation JoinRoomMutation(
  $where: RoomJoinWhereInput
  $data: RoomJoinDataInput
) {
  roomJoin(where: $where, data: $data) {
    sdpAnswer
    serverIceCandidates
    callSession {
      id
      callStatus {
        status
        message
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "RoomJoinWhereInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "RoomJoinDataInput",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "data",
    "type": "RoomJoinDataInput"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "RoomJoinWhereInput"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sdpAnswer",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "serverIceCandidates",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "message",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "JoinRoomMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "roomJoin",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "RoomJoinPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "callSession",
            "storageKey": null,
            "args": null,
            "concreteType": "CallSession",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "callStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "CallStatusConfig",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "JoinRoomMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "roomJoin",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "RoomJoinPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "callSession",
            "storageKey": null,
            "args": null,
            "concreteType": "CallSession",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "callStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "CallStatusConfig",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "JoinRoomMutation",
    "id": null,
    "text": "mutation JoinRoomMutation(\n  $where: RoomJoinWhereInput\n  $data: RoomJoinDataInput\n) {\n  roomJoin(where: $where, data: $data) {\n    sdpAnswer\n    serverIceCandidates\n    callSession {\n      id\n      callStatus {\n        status\n        message\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ce855af4f23c4bb563561032fa5eff3b';
module.exports = node;
