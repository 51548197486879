import React from 'react'
import {
  Button as MuiButton,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core'
import Button from "components/Mui/Button";
import environment from 'common/relay'
import { graphql, QueryRenderer } from 'react-relay'
import { Formik, Form } from "formik"
import DateInput from "components/Formik/DateInput"
import moment from 'moment'
import Input from 'components/Formik/Input'
import Grid from 'components/Mui/Grid'
import EditHistoryDialogMutation from './EditHistoryDialogMutation'
import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";

const query = graphql`
  query EditHistoryDialogQuery(
    $where: TransactionReasonWhere!
  ) {
      transactionReasons(where: $where) {
        value: transactionReasonId
        label: name
    }
  }
`


const EditHistoryDialog = ({data, portfolios, refresh}) => {
  const meta = useMetaContext();
  const snackbar = window[Symbol.for('__snackbar')]
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <React.Fragment>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        label="Update"
        onClick={handleOpen}
      />
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: { maxWidth: '65%', width: '50%' },
          }}
        >
          <DialogTitle>{`Update ${data.status.statusName}`}</DialogTitle>
          <DialogContent>
            <QueryRenderer
              environment={environment}
              query={query}
              variables={{
                where: {
                  insurerId: meta.claim.insurer.companyId,
                  transactionType: "JOB_PROGRESS_UPDATE"
                },
              }}
              render={({error, props}) => {
                if(!props) return null
                return (
                  <Formik
                    initialValues={{
                      statusDate: "",
                      reasonId: "",
                    }}
                    validate={(values) => {
                      let errors = {};
                      if (!values.reasonId) errors.reasonId = "Required!";
                      return errors;
                    }}
                    onSubmit={async( values, {resetForm} ) => {
                      handleClose()
                      const res = await EditHistoryDialogMutation({
                        input:{
                          claimJobId: meta.claim.claimId,
                          portfolioType: portfolios,
                          statusId: data.status.statusId,
                          reasonId: values.reasonId,
                          statusDate: new Date(values.statusDate).toLocaleDateString()
                        }
                      })
                      if(res.ok) {
                        refresh()
                        snackbar.toggleOpen({
                          message: res.message
                        })
                      }
                    }}
                  >
                    {({ isSubmitting, values }) => (
                      <Form>
                        <Grid container justify="flex-end" >
                          <Grid item xs={6}>
                            <DateInput name="statusDate" required />
                          </Grid>
                          <Grid item xs={6} >
                            <Input
                              required
                              name='reasonId'
                              label={"Reason"}
                              options={props.transactionReasons}
                              select
                              value={values.reasonId}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MuiButton label="Submit" type="submit" fullWidth color="primary" variant="contained">Submit</MuiButton>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                )
            }} />
          </DialogContent>
        </Dialog>
      </React.Fragment>
  )
}

export default EditHistoryDialog
