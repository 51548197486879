import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Mui/Button'
import MuiSnackbar from '@material-ui/core/Snackbar'
import { Prompt } from 'react-router-dom'

class PreventNavigationDialog extends React.Component {
  state = { open: false }
  callback
  __trigger = Symbol.for(`__PreventNavigationDialog_${Date.now()}`)

  componentDidMount() {
    window[this.__trigger] = this.toggleOpen
  }

  componentWillUnmount() {
    delete window[this.__trigger]
  }

  render() {
    const { when, classes, message, firstButton/* , secondButton */ } = this.props
    // const { open } = this.state

    return (
      <>
        <MuiSnackbar
          key={message}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.open}
          autoHideDuration={undefined}
          onClose={this.handleNo}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          className={classes.root}
          message={<div id='message-id' className={classes.messages}>{
            message ? message : 'You have made changes. To save, click on Save button. Otherwise click here'
          }</div>}
          action={[
            <Button key='firstButton' label={
              firstButton ? firstButton : 'Navigate without saving'
            } variant='contained' color='primary' size='small' onClick={this.handleYes}/>,
          ]}
        />
        <Prompt when={when} message={Symbol.keyFor(this.__trigger)} />
      </>
    )
  }

  toggleOpen = allowTransitionCallback => {
    this.callback = allowTransitionCallback
    this.setState({ open: true })
  }

  handleYes = (event, reason) => {
    this.callback(true)
    this.setState({ open: false })
  }

  handleNo = (event, reason) => {
    this.callback(false)
    this.setState({ open: false })
  }
}

export default withStyles(theme => ({
  root: {
    top: '75px'
  }
}))(PreventNavigationDialog)