/**
 * @flow
 * @relayHash 157f3bb74cb6a2040c457e62b263741b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobWorkProgressUpdate = {|
  claimJobId: string,
  statusDate: any,
  portfolioType: PortfolioType,
  statusId: string,
  reasonId: string,
|};
export type EditHistoryDialogMutationVariables = {|
  input: JobWorkProgressUpdate
|};
export type EditHistoryDialogMutationResponse = {|
  +jobWorkProgressUpdate: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +id: string,
      +logdate: ?any,
      +statusdate: ?any,
      +completed: boolean,
      +status: ?{|
        +statusName: string,
        +datePopup: boolean,
      |},
    |},
  |}
|};
export type EditHistoryDialogMutation = {|
  variables: EditHistoryDialogMutationVariables,
  response: EditHistoryDialogMutationResponse,
|};
*/


/*
mutation EditHistoryDialogMutation(
  $input: JobWorkProgressUpdate!
) {
  jobWorkProgressUpdate(input: $input) {
    success
    messages
    result {
      id
      logdate
      statusdate
      completed
      status {
        statusName
        datePopup
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobWorkProgressUpdate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "JobWorkProgressUpdate!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logdate",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusdate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completed",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "datePopup",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EditHistoryDialogMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobWorkProgressUpdate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobProgressPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobProgress",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "status",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobStatusInfo",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditHistoryDialogMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobWorkProgressUpdate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobProgressPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobProgress",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "status",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobStatusInfo",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "EditHistoryDialogMutation",
    "id": null,
    "text": "mutation EditHistoryDialogMutation(\n  $input: JobWorkProgressUpdate!\n) {\n  jobWorkProgressUpdate(input: $input) {\n    success\n    messages\n    result {\n      id\n      logdate\n      statusdate\n      completed\n      status {\n        statusName\n        datePopup\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e3a878022c94d6a6e3e429f15cebdf15';
module.exports = node;
