import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typo from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

const { REACT_APP_COMPANY_PATH } = process.env

const EndCall = ({ classes }) => {
  const location = useLocation()
  const { logo, message } = qs.parse(location.search)
  const endLogo = logo ? logo : `${REACT_APP_COMPANY_PATH}/endata/logo.png`

  const [isTopbar, setIsTopbar] = React.useState(false)
  React.useState(() => {
    if(localStorage.TOKEN) setIsTopbar(true)
  }, [])

  return (
    <div className={classes.root} style={{ height: isTopbar ? 'calc(100vh - 55px)' : '100vh' }}>
      <Grid container direction='row' justify='center' alignItems='center' className={classes.container}>
        <Grid item xs={12}>
          <p className={classes.item}>
            <img src={endLogo} className={classes.logo} alt='ENData video tool' />
          </p>
          <Typo variant='subtitle1' className={classes.item}>
            {message
              ? message
              : 'Thank you for using ENData video tool!'
            }
          </Typo>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles({
  root: {
    width: '100vw',
    overflow: 'hidden'
  },
  container: {
    height: '100%'
  },
  logo: {
    height: '13vmax'
  },
  item: {
    textAlign: 'center',
    marginBottom: 0
  }
})(EndCall)