import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";

import Grid from "components/Mui/Grid";
import { Cube } from "components/Mui/Loader";
import JobNotesList from "./JobNotesList";
import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";
import usePortfolioFilter from "hooks/usePortfolioFilter";

export default (props) => {
  const meta = React.useContext(ClaimMetaContext);
  const [portfolio, handlePortfolio] = usePortfolioFilter(meta);

  const [refresh, setRefresh] = React.useState(false);
  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  React.useEffect(() => {
    window[Symbol.for("__dkmm")] = { handleRefresh };

    return () => {
      delete window[Symbol.for("__dkmm")];
    };
  }, [refresh]);

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        first: 500,
        where: {
          claimId: meta.claim.claimId,
          portfolios: portfolio || undefined,
        },
        claimJobWhere: {
          id: meta.claim.claimId,
        },
        claimWhere: {
          id: meta.claim.claimId,
        },
        refresh,
      }}
      render={({ error, props }) => {
        if (error) {
          return "Error!!";
        }

        if (!props)
          return (
            <Grid fluid style={{ height: "100px" }}>
              <Grid item xs>
                <Cube />
              </Grid>
            </Grid>
          );
        return (
          <div>
            <JobNotesList
              data={props}
              handleRefresh={handleRefresh}
              handlePortfolio={handlePortfolio}
              jobNotesPage_claim={props.claimJobs.edges[0].node}
              portfolio={portfolio}
              meta={meta}
              claimId={meta.claim.claimId}
              actions={props.claimJob.view.actions}
              {...props}
            />
          </div>
        );
      }}
    />
  );
};

const query = graphql`
  query JobNotesPageQuery(
    $first: Int
    $after: String
    $where: ClaimItemFilter
    $claimJobWhere: ClaimJobFilter
    $claimWhere: ENDataEntityKey!
  ) {
    ...JobNotesList
    claimJob(where: $claimWhere) {
      view {
        actions {
          awaitingInfo {
            label
            isDisplay
            isDisabled
          }
        }
      }
    }
    claimJobs(where: $claimJobWhere) {
      edges {
        node {
          building {
            progress {
              initialCallMade
              appointmentBooked
            }
            claimStatus {
              statusId
            }
            scopingSupplier {
              companyId
            }
          }
          restoration {
            progress {
              initialCallMade
              appointmentBooked
            }
            claimStatus {
              statusId
            }
            scopingSupplier {
              companyId
            }
          }
        }
      }
    }
  }
`;
