import React, { Component, cloneElement } from "react";

import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Form from "components/Mui/Form";
import Button from "components/Mui/Button";
import cn from "clsx";

import { withStyles } from "@material-ui/core/styles";

class Dialog extends Component {
  state = {
    open: false,
  };

  componentDidMount = () => {
    window[Symbol.for(this.props.symbol)] = {
      handleOpen: this.handleOpen,
      handleClose: this.handleClose,
    };
  };

  componentWillUnmount = () => {
    delete window[Symbol.for(this.props.symbol)];
  };

  handleOpen = (e) => {
    this.setState({ open: true });
  };

  handleClose = (e) => {
    if (this.props.onClose) this.props.onClose();

    !this.props.noClose && this.setState({ open: false });
    if (this.props.setUpdate) {
      this.props.setUpdate(true);
    }
  };

  render() {
    const {
      classes,
      title,
      content,
      actions,
      handleSubmit,
      children,
      centeredActions,
      noForm,
      noActions,
      paperProps,
      paperClassname,
      onEnter,
      minWidth,
      setUpdate,
      footerComponent,
      formComponent: FormC,
    } = this.props;
    return (
      <MuiDialog
        className={classes.root}
        open={this.state.open}
        onClose={this.handleClose}
        onEnter={onEnter}
        PaperProps={{
          ...paperProps,
          style: { minWidth: minWidth },
          className: cn(classes.paper, paperClassname),
        }}
      >
        {noForm ? (
          <>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{content}</DialogContent>
            {footerComponent && footerComponent}
            {!noActions && (
              <>
                <DialogActions
                  className={centeredActions ? classes.actions : ""}
                >
                  {actions &&
                    actions.map((action, key) =>
                      cloneElement(action, { key: key })
                    )}
                  {!this.props.noClose && (
                    <Button
                      label="Close"
                      color="primary"
                      onClick={this.handleClose}
                    />
                  )}
                </DialogActions>
              </>
            )}
            {children}
          </>
        ) : FormC ? (
          <FormC>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{content}</DialogContent>
            {!noActions && (
              <DialogActions className={centeredActions ? classes.actions : ""}>
                {actions &&
                  actions.map((action, key) =>
                    cloneElement(action, { key: key })
                  )}
                {!this.props.noClose && (
                  <Button
                    label="Close"
                    color="primary"
                    onClick={this.handleClose}
                  />
                )}
              </DialogActions>
            )}
            {children}
          </FormC>
        ) : (
          <Form onSubmit={handleSubmit}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{content}</DialogContent>
            {!noActions && (
              <DialogActions className={centeredActions ? classes.actions : ""}>
                {actions &&
                  actions.map((action, key) =>
                    cloneElement(action, { key: key })
                  )}
                {!this.props.noClose && (
                  <Button
                    label="Close"
                    color="primary"
                    onClick={this.handleClose}
                  />
                )}
              </DialogActions>
            )}
            {children}
          </Form>
        )}
      </MuiDialog>
    );
  }
}

export default withStyles((theme) => ({
  root: {
    zoom: "0.91",
  },
  paper: {
    minWidth: "60%",
    maxWidth: "initial",
    // minHeight: ' 40vh',
  },
  actions: {
    display: "flex",
    justifyContent: "center",
  },
}))(Dialog);
