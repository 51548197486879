import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'

import ClaimListFilter from './ClaimListFilter'
import ClaimList from './ClaimList'

import Fabs from 'components/Mui/Fabs'
import Fab from 'components/Mui/Fabs/Fab'

import { SpinnerRect } from 'components/Mui/Loader'
import VideoCallInitialDialog from 'components/VideoCallInitialDialog'

const query = graphql`
  query ClaimListPageQuery {
    me {
      userRole
      hasMultipleAccess
    }
    currentUser {
      actions {
        createNewClaim {
          isDisabled
        }
      }
    }
  }
`

class ClaimListPage extends Component {
  state = {
    variables: {
      where: null
    },
    isAddClaimDisplay:true
  }

  render() {
    const { where } = this.state.variables

    return (
      <div className='ClaimListPage'>
        <ClaimListFilter filterChange={this.filterChange} filterWhere={where} />
        <VideoCallInitialDialog
          symbol={'__initiateVideoCallDialogActionInList'}
        />

        <QueryRenderer
          environment={environment}
          query={query}
          render={({ error, props }) => {
            if(error) return 'Error!!'
            if(!props) return <SpinnerRect />
            const isAddClaimDisplay = props.currentUser.actions.createNewClaim.isDisabled;
            const isAdmin = ['Administrator',
                              'Insurance',
                              'Adjustor',
                              'LossAdjuster',
                              'SystemAdmin',
                              'Agent'
                            ].indexOf(props.me.userRole) !== -1
            return (
              <>
                <ClaimList user={props.me} where={where}/>
                {!isAddClaimDisplay&&isAdmin && <Fabs
                  mainFab={<Fab icon='add' label='NEW'/>}
                  childFabs={[
                    <Fab icon='note_add' label='Claim' backgroundColor='#b00020' component={Link} to='/add-claim' />,
                    // <Fab onClick={() => {
                    //   if(window[Symbol.for('__initiateVideoCallDialogActionInList')]){
                    //     window[Symbol.for('__initiateVideoCallDialogActionInList')].handleOpen()
                    //   }
                    // }} icon='videocam' label='Video Triage Session' backgroundColor='#2B78FE'/>,
                  ]}
                /> }
              </>
            )
          }}
        />
      </div>
    )
  }

  filterChange = where => {
    this.setState({ variables: {
      ...this.state.variables,
      where: {
        ...this.state.variables.where,
        ...where
      }
    }})
  }
}



export default ClaimListPage
