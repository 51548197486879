import React from 'react'
import { fetchQuery, graphql } from 'relay-runtime'
import environment from 'common/relay'
import { withStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import BuilderOptionForm from 'pages/User/Settings/Actions/BuilderOptionForm'

const initObject = {
  type: 'add',
  name: '', phoneNumber: '', disabled: 'no',
  where: {
    id: '', portfolioType: ''
  }
}

const Estimator = ({ classes }) => {
  const [ refresh, setRefresh ] = React.useState(false)
  window[Symbol.for('__refreshEstimatorSettings')] = () => {
    setRefresh(!refresh)
  }

  const [estimators, setEstimators] = React.useState(null)
  const variables = {
    after: null,
    first: 100000,
    where: {
      isDisabled: null
    },
  };
  React.useEffect(() => {
    fetchQuery(environment, query, variables)
      .then(data => {
        const estimators = data.estimators.edges.map(({ node }) => node)
        setEstimators(estimators)
      })
  }, [ refresh ])

  const [formData, setFormData] = React.useState({ ...initObject })
  const openAddEstimator = () => {
    setFormData({ ...initObject, type: 'add' })
    const dlActions = window[Symbol.for('__estimatorAction')]
    if(dlActions) dlActions.handleOpen()
  }

  return (
    <>
      <BuilderOptionForm
        symbol={'__estimatorAction'}
        title='Add new estimator'
        data={formData}
        model='estimator'
      />
      <Toolbar>
        <div className={classes.title}>
          <Typography variant='h6'>
            Estimator list
          </Typography>
        </div>
        <div className={classes.spacer}></div>
        <div className={classes.actions}>
          <IconButton aria-label='Add'
            onClick={openAddEstimator}
          >
            <Icon>add</Icon>
          </IconButton>
        </div>
      </Toolbar>
      {
        estimators === null ? 'Loading...' :
        estimators.length === 0 ? 'No supervisor found' :
        (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Disabled</TableCell>
                <TableCell padding='checkbox'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estimators.map((estimator, index) => (
                <EstimatorItem
                  key={index}
                  classes={classes}
                  estimator={estimator}
                  setFormData={setFormData}
                />
              ))}
            </TableBody>
          </Table>
        )
      }
    </>
  )
}

const EstimatorItem = ({ classes, estimator, setFormData }) => {
  const openUpdateEstimator = () => {
    setFormData({
      type: 'update',

      name: estimator.name || '',
      phoneNumber: estimator.phoneNumber || '',
      disabled: estimator.disabled? 'yes' : 'no',
      where: {
        id: estimator.id,
      }
    })
    const dlActions = window[Symbol.for('__estimatorAction')]
    if(dlActions) dlActions.handleOpen()
  }

  return (
    <TableRow>
      <TableCell>{estimator.name}</TableCell>
      <TableCell>{estimator.phoneNumber}</TableCell>
      <TableCell>{estimator.disabled? 'YES': 'NO'}</TableCell>
      <TableCell padding='checkbox' className={classes.actions}>
        <IconButton aria-label='Edit' className={classes.action}
          onClick={openUpdateEstimator}
        >
          <Icon>edit</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(theme => ({
  title: {
    flex: '0 0 auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    display: 'flex'
  },
  action: {
    marginLeft: 'auto',
    marginRight: 10
  }
}))(Estimator)

const query = graphql`
  query EstimatorQuery(
    $after: String
    $first: Int
    $where: EstimatorIndex
  ) {
    estimators(after: $after, first: $first, where: $where) {
      edges {
        node {
          id
          name
          phoneNumber
          disabled
        }
      }
    }
  }
`
