import React from "react";
import environment from "common/relay";
import { graphql, fetchQuery } from "react-relay";
import { Formik, Form } from "formik";
import Input from "components/Formik/Input";
import queryString from "query-string";
import useReactRouter from "use-react-router";

import { withStyles } from "@material-ui/core/styles";

import Paper from "components/Mui/Paper";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";

import LoginMutation from "./LoginMutation";
import tokenRefresh from "common/utils/tokenRefresh";
import handleUserData from "common/utils/handleUserData";

const ENDataFullLogo =
  "https://www.endataclaims.com/NewWebsite/Images/ENDataTransparent.png";

const Login = ({ classes }) => {
  const { history } = useReactRouter();

  const [token, setToken] = React.useState(null);

  React.useEffect(() => {
    // RefreshToken on url
    const { token: refreshToken, redirect_from } = queryString.parse(
      history.location.search
    );
    if (refreshToken) {
      localStorage.removeItem("ACCESS_PATHS");
      localStorage.removeItem("REFRESH_TOKEN");
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("ROOT_ROUTE");
      localStorage.removeItem("REDIRECT_URL");
      localStorage.setItem("REFRESH_TOKEN", refreshToken);
      localStorage.setItem(
        "REDIRECT_URL",
        redirect_from || process.env.REACT_APP_DEFAULT_REDIRECT_URL
      );

      tokenRefresh().then((res) => {
        if (res.data) {
          const { accessToken } = res.data.userRenewToken;
          localStorage.setItem("TOKEN", accessToken);
          setToken(accessToken);
        }
      });
    }
    // if user already login
    else if (localStorage.TOKEN && localStorage.ROOT_ROUTE) {
      history.push(localStorage.ROOT_ROUTE);
    } else {
      if (process.env.REACT_APP_DEV !== "dev") {
        window.location.href = process.env.REACT_APP_DEFAULT_REDIRECT_URL;
      }
    }
  }, []);

  /**
   * get user data and redirect if TOKEN is available
   */
  const [root, setRoot] = React.useState(null);
  React.useEffect(() => {
    if (token) {
      fetchQuery(environment, query)
        .then((data) => handleUserData(data.me, history))
        .then((resolvedRoot) => {
          setRoot(resolvedRoot);
          history.push(resolvedRoot, { firstRender: true });
        });
    }
  }, [token]);

  if (token && !root) return null;

  if (process.env.REACT_APP_DEV !== "dev") {
    return null;
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
          <Paper
            content={
              <Formik
                initialValues={{
                  userLogin: "",
                  password: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.userLogin) errors.userLogin = "Required!";
                  if (!values.password) errors.password = "Required!";
                  return errors;
                }}
                onSubmit={(values, actions) => {
                  setTimeout(async () => {
                    const res = await LoginMutation(values);
                    if (res.ok) {
                      if (res.data) {
                        localStorage.setItem("TOKEN", res.data.token);
                        localStorage.setItem(
                          "REFRESH_TOKEN",
                          res.data.refreshToken
                        );
                        const root = handleUserData(res.data.user);
                        localStorage.setItem(
                          "REDIRECT_URL",
                          process.env.REACT_APP_DEFAULT_REDIRECT_URL
                        );

                        history.push(root, { firstRender: true });
                      }
                    } else if (res && res.errors) {
                      actions.setSubmitting(false);
                      window[Symbol.for("__snackbar")] &&
                        window[Symbol.for("__snackbar")].toggleOpen({
                          message: res.errors.message,
                        });
                    }
                  }, 400);
                }}
              >
                {({ isSubmitting }) => {
                  return (
                    <Form>
                      <img
                        className={classes.companyLogo}
                        src={ENDataFullLogo}
                        alt="ENData Claims"
                      />
                      <h1 className={classes.title}>Log in</h1>
                      <p className={classes.subtitle}>
                        with your ENData account
                      </p>
                      <Input
                        label="Username"
                        name="userLogin"
                        required
                        isShrink
                      />
                      <Input
                        label="Password"
                        name="password"
                        required
                        type="password"
                        isShrink
                      />
                      <p style={{ textAlign: "right", margin: 0 }}>
                        <Button
                          label="Login"
                          disabled={isSubmitting}
                          variant="contained"
                          color="primary"
                          type="submit"
                        />
                      </p>
                    </Form>
                  );
                }}
              </Formik>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles((theme) => ({
  root: {
    height: "100vh",
    marginTop: 0,
  },
  headIcon: {
    display: "block",
    fontSize: "5rem",
    margin: "10px auto 15px",
  },
  title: {
    textAlign: "center",
  },
  subtitle: {
    textAlign: "center",
    ...theme.typography.body1,
  },
  companyLogo: {
    display: "block",
    height: "25px",
    margin: "10px auto 15px",
  },
  captcha: {
    display: "flex",
    justifyContent: "center",
  },
}))(Login);

const query = graphql`
  query LoginQuery {
    me {
      userName
      userRole
      hasMultipleAccess
      preference {
        applicationFont
        applicationLanguage
        applicationTheme
      }
      company {
        customisation {
          applicationAlias
          applicationFavicon
          applicationLogo
          displayCompanyLogo
          logoHeight
        }
      }
      privileges {
        menus {
          nodeName
        }
      }
    }
  }
`;
