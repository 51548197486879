import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation RequestExternalSourceMutation($where: ClaimUniqueWhere!) {
    requestExternalSourceData(where:$where){
      success
      messages
    }
  }
`

export default (where) => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables: where,
        onCompleted: res => {
          resolve({
            ok: true,
            success: res.requestExternalSourceData.success,
            message: res.requestExternalSourceData.messages
          })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}
