import React, { Component } from 'react'
import { QueryRenderer, graphql } from 'react-relay'
import environment from 'common/relay'
import { SpinnerRect } from 'components/Mui/Loader'
import ClaimListPagination from './ClaimListPagination'

const query = graphql`
  query ClaimListQuery(
    $first: Int!, $after: String, $where: ClaimJobFilter,
    $isAdmin: Boolean!, $isBuilder: Boolean!,
    $isRestorer: Boolean!, $isContent: Boolean!
  ) {
    ...ClaimListPagination
  }
`

class ClaimList extends Component {
  state = {
    variables: {
      first: 20,
      after: null,
    }
  }

  render() {
    const { user } = this.props
    const { variables } = this.state
    const isAdmin = user.userRole === 'Administrator'
      || user.userRole === 'Insurance' || user.userRole === 'Adjustor'
      || user.userRole === 'LossAdjuster' || user.userRole === 'SystemAdmin'
      || user.userRole === 'Agent'
    const isBuilder = user.userRole === 'Builder'
      || user.userRole === 'Estimator' || user.userRole === 'InternalAB'
    const isRestorer = user.userRole === 'Restorer'
    const isContent = user.userRole === 'ContentSupplier'

    return (
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{
          ...variables,
          where: this.props.where,
          isAdmin,
          isBuilder,
          isRestorer,
          isContent
        }}
        render={({ error, props }) => {
          if(error) return 'Error!!'
          if(!props) return <SpinnerRect />

          return <ClaimListPagination data={props} user={{
            ...user, isAdmin, isBuilder, isRestorer, isContent
          }}/>
        }}
      />
    )
  }
}

export default ClaimList
