import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import VariationItem from "./VariationItem";
import { withRouter } from "react-router-dom";

import VariationDetails from "../VariationDetails";

class VariationListPresentation extends Component {
  state = {
    variationId: Number.isInteger(
      parseInt(
        this.props.location.search.substr(
          this.props.location.search.lastIndexOf("=") + 1
        )
      )
    )
      ? parseInt(
          this.props.location.search.substr(
            this.props.location.search.lastIndexOf("=") + 1
          )
        )
      : null,
  };
  detailsRef = React.createRef();
  componentDidMount() {
    if (this.state.variationId) this.toggleDetails();
  }
  setVariationId = (id, fn) => {
    this.setState({ variationId: id }, () => fn);
  };
  toggleDetails = () => {
    this.detailsRef.current.handleOpen();
  };

  render() {
    const { classes, variations, portfolios, setUpdate } = this.props;
    if (variations.edges.length === 0) return "No variations found";
    return (
      <div className={classes.listBody}>
        {variations.edges.map(({ node }, index) => (
          <VariationItem
            key={index}
            variation={node}
            portfolios={portfolios}
            variationId={this.state.variationId}
            setVariationId={this.setVariationId}
            toggleDetails={this.toggleDetails}
          />
        ))}
        <VariationDetails
          dlRef={this.detailsRef}
          id={this.state.variationId}
          portfolios={portfolios}
          setUpdate={setUpdate}
        />
      </div>
    );
  }
}

export default withStyles((theme) => ({
  listBody: {
    maxHeight: "65vh",
    overflow: "auto",
  },
}))(withRouter(VariationListPresentation));
