import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from 'components/Mui/Button';
import Typography from '@material-ui/core/Typography';
import Radio from './Radio';
import TextField from '@material-ui/core/TextField';
import Badge from '@material-ui/core/Badge';
import SubmitSurveyMutation from './SubmitSurveyMutation'

const icon = [
  {value: '1'},
  {value: '2'},
  {value: '3'},
  {value: '4'},
  {value: '5'},
];
const Survey = ({ classes }) => {
  const query = graphql`
    query SurveyQuery($scoreFormWhere: ClaimScoreFormWhere) {
      claimScoreForm(where:$scoreFormWhere){
        claimRef
        id
        logo
        supplierName
        ratingCompleted
      }
    }
  `
  const snackbar = window[Symbol.for('__snackbar')]
  const [selectedValue, setSelectedValue] = React.useState();
  const [inputValue, setInputValue] = React.useState();
  const [helperText, setHelperText] = React.useState();
  const [iserror, setIsError] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);
  const [isFinished, setIsFinished] = React.useState(false);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const handleInput = (e) => {
    setInputValue(e.target.value)
  }
  const handleSubmit = async () => {
    setIsTouched(true)
    if ( iserror || !isTouched ) return null
    const res = await SubmitSurveyMutation({
      where: {
        id: 1,
      },
      input: {
        rating: parseInt(selectedValue),
        additionalNote: inputValue
      }
    })
    if(res) {
      if(res.ok) {
        setIsFinished(true)
      } else {
        snackbar.toggleOpen({
          message: 'Something unexpected happened !'
        })
        }
    }
  }
  React.useEffect(() => {
    if (!selectedValue) {setIsError(true); setHelperText('please rate your experience !')}

    if (selectedValue) {setIsError(false); setHelperText(); setIsTouched(true)}
  },[selectedValue, setIsError])
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{scoreFormWhere:{id:1}}}
      render={({error, props}) => {
        if (!props) return <div>Loading...</div>
        if((props && props.claimScoreForm && props.claimScoreForm.ratingCompleted) || isFinished) return (
          <Card className={classes.card}>
            <div>
              <CardHeader
                classes={{ root: classes.header, avatar: classes.imgWrapper }}
                avatar={
                  <img
                    className={classes.img}
                    src='https://companyresources.blob.core.windows.net/image/endata/InFocus-Thanks-for-using-TP.png'
                    alt='Infocus'
                  ></img>
                }
                title={
                  'Our builder ABC has completed repairs to your property under claim 123456'
                }
              />
              <p style={{textAlign:"center"}}>Thank you, your feedback has been submitted</p>
            </div>
          </Card>
          )
        return (
          <Card className={classes.card}>
            <div>
              <CardHeader
                classes={{ root: classes.header, avatar: classes.imgWrapper }}
                avatar={
                  <img
                    className={classes.img}
                    src='https://companyresources.blob.core.windows.net/image/endata/InFocus-Thanks-for-using-TP.png'
                    alt='Infocus'
                  ></img>
                }
                title={
                  'Our builder ABC has completed repairs to your property under claim 123456'
                }
              />

              <CardActions className={classes.form}>
                <CardContent className={classes.QuestionTitle}>
                    <Radio classes={classes}
                    selectedValue={selectedValue}
                    handleChange={handleChange}
                    helperText={helperText}
                    iserror={iserror}
                    isTouched={isTouched}
                    />
                </CardContent>

                <CardContent className={classes.QuestionTitle}>
                  <Typography component='p' style={{ fontWeight: 'bold' }}>
                    Please provide some feedback on your experience
                  </Typography>
                  <TextField
                    className={classes.margin}
                    onChange={handleInput}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={5}
                  />

                </CardContent>
                <div>
                  <Button
                    label='Submit'
                    onClick={handleSubmit}
                    variant='contained'
                    color='secondary'
                    size='large'
                  />
                </div>
              </CardActions>
            </div>
          </Card>)}}
    />

  );
};

const styles = {
  card: {
    maxWidth: '100%',
    height: '100vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent:'center'
  },
  img: {
    height: '110px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  imgWrapper: {
    marginRight: '0',
  },
  QuestionTitle: {
    padding: '4px',
  },
  form: {
    justifyContent: 'center',
    flexDirection: 'column',
  },
  icon: {
    fontSize: '40px',
  }
};

export default withStyles(styles)(Survey);

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      color: 'white',
      backgroundColor:'black',
      right: -5,
      top: 10,
      border: `2px solid`,
      padding: '0 4px',
    },
  }),
)(Badge);
