import React from 'react'

export default ({
  begin,
  localVideoRef, remoteVideoRef,
  mediaConstraints, configuration,
}) => {
  const [ready, setReady] = React.useState(false)

  const [peer, setPeer] = React.useState(null)
  const [offer, setOffer] = React.useState(null)
  const [candidates, setCandidates] = React.useState([])
  React.useEffect(() => {
    const start = async () => {
      const kurentoPeer = await getKurentoPeer({
        localVideo: localVideoRef.current,
        remoteVideo: remoteVideoRef.current,
        onicecandidate : candidate => setCandidates(previous => ([ ...previous, JSON.stringify(candidate) ])),
        oncandidategatheringdone: () => setReady(true),
        mediaConstraints,
        connectionConstraints: {
          offerToReceiveAudio: true,
          offerToReceiveVideo: true
        },
        configuration
      })
      .catch(err => {
        console.log(`[KURENTO_ERROR]: ${err}`)
      })
      if(!kurentoPeer) return null

      setPeer(kurentoPeer)

      const sdpOffer = await getOffer(kurentoPeer)
      setOffer(sdpOffer)

      return kurentoPeer
    }
    const kurentoPeer = begin ? start() : null

    return () => {
      if(kurentoPeer) kurentoPeer.then(hangup)
    }
  // eslint-disable-next-line
  }, [begin])

  /**
   * SIGNALLING HELPERS
   */
  const [answer, setAnswer] = React.useState(null)
  const [addedAnswer, setAddedAnswer] = React.useState(false)
  React.useEffect(() => {
    if(answer && !addedAnswer) {
      // process answer
      setAddedAnswer(true)
      peer.processAnswer(answer, err => {
        if(err) return console.log(err)
      })
    }
  }, [answer, addedAnswer, peer])
  const [serverCandidates, setServerCandidates] = React.useState(null)
  React.useEffect(() => {
    if(serverCandidates && addedAnswer) {
      // add ice candidates
      serverCandidates.forEach(_candidate => {
        peer.addIceCandidate(JSON.parse(_candidate), err => {
          if(err) return console.log(err)
        })
      })
    }
  }, [ peer, addedAnswer, serverCandidates ])

  /**
   * ACTIONS
   */
  const getPhoto = () => {
    return peer.currentFrame.toDataURL()
  }

  const hangup = kurentoPeer => {
    const kPeer = peer ? peer : kurentoPeer
    if(!kPeer) return kPeer

    // stop local media streams, tracks
    const localStreams = kPeer.peerConnection.getLocalStreams()
    localStreams.forEach(stream => {
      stream.getTracks().forEach(track => {
        // console.log('stop track')
        track.stop()
      })
    })

    // dispose event and close peer connection
    kPeer.peerConnection.onicegatheringstatechange = null
    kPeer.peerConnection.onconnectionstatechange = null
    kPeer.peerConnection.close()
  }

  // video, audio
  const [videoState, setVideoState] = React.useState(true)
  const toggleVideo = () => {
    // peer.videoEnabled = !peer.videoEnabled
    // const localStream = peer.peerConnection.getLocalStreams()[0]
    // const tracks = localStream.getTracks()
    // const track = tracks.find(track => track.kind === 'video')
    // track.enabled = !track.enabled
    // track.muted = !track.muted
    setVideoState(previous => {
      peer.videoEnabled = !videoState
      return peer.videoEnabled
    })
  }

  const [audioState, setAudioState] = React.useState(true)
  const toggleAudio = () => {
    setAudioState(previous => {
      peer.audioEnabled = !audioState
      return peer.audioEnabled
    })
  }

  return {
    ready, peer,
    signallingData: { offer, candidates },
    helpers: { setServerCandidates, setAnswer },
    actions: {
      getPhoto, hangup,
      videoState, toggleVideo,
      audioState, toggleAudio,
    }
  }
}

/**
 * PROMISE HELPERS
 */
const getKurentoPeer = options => {
  // return new Promise(async (resolve,reject) => {
  //   const kurento = await React.lazy(() => import('asd'))
  //     ._ctor()
  //     .then(module => module.default)

  //   const kurentoPeer = kurento.WebRtcPeer.WebRtcPeerSendrecv(options, err => {
  //     if(err) reject(err)

  //     resolve(kurentoPeer)
  //   })
  // })
}

const getOffer = peer => {
  return new Promise((resolve,reject) => {
    peer.generateOffer(
      { offerToReceiveVideo: true, offerToReceiveAudio: true },
      (error, offer) => {
        if (error) reject(error)

        resolve(offer)
      }
    )
  })
}


// if(options.mediaConstraints.video === false) {
//   const kurentoPeer = kurento.WebRtcPeer.WebRtcPeerRecvonly(options, err => {
//     if(err) reject(err)
//
//     resolve(kurentoPeer)
//   })
// }
// else {
//   const kurentoPeer = kurento.WebRtcPeer.WebRtcPeerSendrecv(options, err => {
//     if(err) reject(err)
//
//     resolve(kurentoPeer)
//   })
// }