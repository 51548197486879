/**
 * @flow
 * @relayHash 6b8107ee3970459036c7c7c581b02526
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LogLevel = "Critical" | "Debug" | "Error" | "Information" | "None" | "Trace" | "Warning" | "%future added value";
export type FeeItemCreateInput = {|
  claimId: string,
  feeTypeId: number,
  authorisedAmount: number,
  supplierId?: ?number,
|};
export type FeeItemCreateDialogMutationVariables = {|
  input: FeeItemCreateInput
|};
export type FeeItemCreateDialogMutationResponse = {|
  +feeItemCreate: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +fieldErrors: ?$ReadOnlyArray<?{|
      +fieldName: string,
      +level: LogLevel,
      +message: string,
    |}>,
  |}
|};
export type FeeItemCreateDialogMutation = {|
  variables: FeeItemCreateDialogMutationVariables,
  response: FeeItemCreateDialogMutationResponse,
|};
*/


/*
mutation FeeItemCreateDialogMutation(
  $input: FeeItemCreateInput!
) {
  feeItemCreate(input: $input) {
    success
    messages
    fieldErrors {
      fieldName
      level
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "FeeItemCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "feeItemCreate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "FeeItemCreateInput!"
      }
    ],
    "concreteType": "FeeItemPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "fieldErrors",
        "storageKey": null,
        "args": null,
        "concreteType": "EntityFieldError",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fieldName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "level",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeItemCreateDialogMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeItemCreateDialogMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "FeeItemCreateDialogMutation",
    "id": null,
    "text": "mutation FeeItemCreateDialogMutation(\n  $input: FeeItemCreateInput!\n) {\n  feeItemCreate(input: $input) {\n    success\n    messages\n    fieldErrors {\n      fieldName\n      level\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d61396a4193f2854dc1f85a7326ecb4';
module.exports = node;
