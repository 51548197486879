import React, { Component } from "react";
import { withStyles } from "@material-ui/core/";
import Grid from "components/Mui/Grid";

import ClaimDetailsPortfolioFilter from "../../ClaimDetailsPortfolioFilter";
import SiteReportActionBar from "./SiteReportActionBar";

import ReportDetails from "./ReportDetails";
import BuildingInfo from "./BuildingInfo";
import SiteReportDescription from "./SiteReportDescription";

class SiteReportPresentation extends Component {
  render() {
    const {
      classes,
      claim,
      options,
      forceUpdate,
      meta,
      data,
      action,
      portfolios,
      isAbleToEdit,
      isSubmitting,
      //requestQuotesAction,
      //claimParticipantData,
      jobScopeTemplatesData,
      tenderClosingDate,
      vulnerableMember,
      submitForm,
    } = this.props;
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <SiteReportActionBar
              //requestQuotesAction={requestQuotesAction}
              meta={meta}
              data={data}
              portfolios={portfolios}
              action={action}
              claim={claim}
              options={options}
              forceUpdate={forceUpdate}
              isAbleToEdit={isAbleToEdit}
              isSubmitting={isSubmitting}
              //claimParticipantData={claimParticipantData}
              jobScopeTemplatesData={jobScopeTemplatesData}
              tenderClosingDate={tenderClosingDate}
              submitForm={submitForm}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Grid fluid>
              <Grid
                item
                xs
                style={{ position: "relative" }}
                className={classes.leftBlock}
              >
                <ClaimDetailsPortfolioFilter
                  noAll
                  portfolio={{
                    hasBuilding: meta.claim.hasBuilding,
                    hasRestoration: meta.claim.hasRestoration,
                  }}
                  changePortfolio={this.props.changePortfolio}
                  value={portfolios.toLowerCase()}
                />

                <ReportDetails
                  meta={meta}
                  data={data}
                  claim={claim}
                  isAbleToEdit={isAbleToEdit}
                  portfolios={portfolios}
                  vulnerableMember={vulnerableMember}
                />
              </Grid>
            </Grid>
            <Grid fluid>
              <Grid item xs>
                <BuildingInfo
                  data={data}
                  claim={claim}
                  isAbleToEdit={isAbleToEdit}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <SiteReportDescription
              isAbleToEdit={isAbleToEdit}
              portfolios={portfolios}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles((theme) => ({
  leftBlock: {
    paddingLeft: "0 !important",
  },
  editor: {
    "& .DraftEditor-root": {
      maxHeight: "51rem",
    },
  },
}))(SiteReportPresentation);
