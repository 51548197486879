import React from 'react'

export default ({ skip, supported: { video, audio }, isSupportNoDevice, setIsSupportNoDevice }) => {
  const [state, setState] = React.useState({
    ready: false,
    permitted: {
      video: false,
      audio: false,
    }
  })

  React.useEffect(() => {
    (async () => {
      if(!skip) {
        const videoStream = video
          ? await navigator.mediaDevices.getUserMedia({ video: true, audio: false })
            .catch(error => console.log(`Get camera: ${error}`))
          : null
        const audioStream = audio
          ? await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
            .catch(error => console.log(`Get microphone: ${error}`))
          : null

        if (videoStream) videoStream.getTracks().forEach(track => track.stop())
        if (audioStream) audioStream.getTracks().forEach(track => track.stop())
        if (isSupportNoDevice === null && !videoStream && !audioStream){ setIsSupportNoDevice(true) } else { setIsSupportNoDevice(false) }
        setState({
          ready: true,
          permitted: {
            video: isSupportNoDevice || Boolean(videoStream),
            audio: isSupportNoDevice || Boolean(audioStream),
          }
        })
      }
    })()
  }, [skip, video, audio, isSupportNoDevice])

  return state
}
