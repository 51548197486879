import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation MakeSafeUpdateItemMutation(
    $where: ENDataPortfolioKey!,
    $input: QuoteItemUpdate!
  ) {
    updateJobMakeSafeItem(
      where: $where,
      input: $input
    ) {
      messages
      success
      result {
        id
        makeSafeId
        location {
          costLocationId
          locationName
        } dimensions

        costCentre { costCentreName }
        costItem { itemDescription } lineDescription
        unit { unitName }
        qty
        rate
        marginRate
        subtotal
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.updateJobMakeSafeItem.success,
          message: res.updateJobMakeSafeItem.messages[0]
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}