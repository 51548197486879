/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClaimItem_contents$ref: FragmentReference;
export type ClaimItem_contents = {|
  +contents: ?{|
    +jobSuppliers: ?$ReadOnlyArray<?{|
      +requestDate: ?any,
      +requestType: ?string,
      +quote: ?{|
        +total: ?number,
        +jobQuoteId: number,
        +quoteStatus: ?{|
          +statusName: ?string
        |},
      |},
    |}>,
    +claimStatus: ?{|
      +statusName: ?string
    |},
  |},
  +$refType: ClaimItem_contents$ref,
|};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "statusName",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "ClaimItem_contents",
  "type": "ClaimJob",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contents",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimPortfolio",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "jobSuppliers",
          "storageKey": null,
          "args": null,
          "concreteType": "JobSupplier",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "requestDate",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "requestType",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "quote",
              "storageKey": null,
              "args": null,
              "concreteType": "JobQuote",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "total",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "jobQuoteId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": "quoteStatus",
                  "name": "quoteJobStatus",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ClaimStatus",
                  "plural": false,
                  "selections": (v0/*: any*/)
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "claimStatus",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimStatus",
          "plural": false,
          "selections": (v0/*: any*/)
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c883f2e3bf3803380038b605159fb596';
module.exports = node;
