import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { moneyFormat } from "common/utils";
import { getData } from "common/utils/transformQuotesData";
import TextField from "@material-ui/core/TextField";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";
import IconButton from "components/Mui/IconButton";
import Chip from "@material-ui/core/Chip";

import ResetScopeMutation from "../QuotesAction/ResetScopeMutation";
import RecommendSupplierMutation from "../QuotesAction/RecommendSupplierMutation";

import AcceptQuoteDialog from "../QuotesAction/AcceptQuoteDialog";
import TenderClosingDateAction from "../QuotesAction/TenderClosingDateAction";
import QuotesDetail from "./QuotesDetail";
import QuoteRemoveAuthorityMutation from "../QuotesAction/QuoteRemoveAuthorityMutation";

const QuotesPresentation = (props) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const acceptDlRef = React.createRef();
  const [acceptingQuoteId, setAcceptingQuoteId] = React.useState(null);
  const { quotes, classes, portfolioType, meta, claimJobData, claimId } = props;
  const displayValidationValue =
    quotes[0] && quotes[0].node.fields
      ? quotes[0].node.fields.scopeValidation
      : false;

  const handleRecommendSupplier = async (companyName, jobQuoteId) => {
    const snackRes = await snackbar.toggleOpen({
      message: `Recommend ${companyName}?`,
      type: "confirm",
      firstButton: "Recommend",
      secondButton: "Cancel",
    });
    if (snackRes.ok) {
      const res = await RecommendSupplierMutation({
        where: {
          id: jobQuoteId,
          portfolioType: props.portfolioType,
        },
      });
      if (res.ok) {
        snackbar.toggleOpen({
          message: res.messages[0],
        });
        if (res.success) {
          if (window[Symbol.for("__refreshQuotes")]) {
            window[Symbol.for("__refreshQuotes")].handleRefresh();
          }
        }
      } else {
        snackbar.toggleOpen({
          message: res.messages[0],
        });
      }
    }
  };

  const resetScopeHandle = async (id) => {
    const { portfolioType } = props;
    const confirm = await snackbar.toggleOpen({
      message: "Do you want to reset the scope?",
      firstButton: "Yes",
      secondButton: "No",
      type: "confirm",
    });

    if (confirm.ok) {
      const res = await ResetScopeMutation({
        where: { id, portfolioType },
      });
      if (res.success) {
        snackbar.toggleOpen({
          message: "Scope is reset",
        });
      } else {
        snackbar.toggleOpen({
          message: "Something is wrong, please try again",
        });
      }
    }
  };

  const getTableData = (quotes, portfolioType) => {
    const isContents = Boolean(portfolioType === "Contents");
    const scoping = quotes[0].node;
    const quoting = quotes.filter((x, y) => y !== 0).map((x) => x.node);
    return [
      {
        name: "",
        supplier: true,
        scoping: scoping.supplier.companyName,
        quoting: quoting.map((quote) => quote.supplier.companyName),
      },
      {
        name: "",
        status: true,
        scoping: scoping.quoteStatus.statusName,
        quoting: quoting.map((quote) => quote.quoteStatus.statusName),
      },
      { name: null },
      {
        name: "Unprocessed Item(s)",
        info: true,
        unMount: Boolean(!scoping.unProcessed.length),
      },
      { name: null },
      ...getData(quotes, "unProcessed"),
      { name: null },
      // {
      //   name: isContents ? null : 'Subtotal', sumary: true,
      //   sir: moneyFormat(scoping.sirSubtotal),
      //   scoping: moneyFormat(scoping.subtotal),
      //   quoting: quoting.map(quote => moneyFormat(quote.subtotal)),
      // },
      // {
      //   name: isContents ? null : 'GST', sumary: true,
      //   sir: moneyFormat(scoping.sirGst),
      //   scoping: moneyFormat(scoping.gst),
      //   quoting: quoting.map(quote => moneyFormat(quote.gst)),
      // },
      // {
      //   name: 'Grand Total', sumary: true,
      //   sir: moneyFormat(scoping.sirTotal),
      //   scoping: moneyFormat(scoping.total),
      //   quoting: quoting.map(quote => moneyFormat(quote.total)),
      // },
      // { name: null },

      {
        name: "",
        scoping: (() => {
          return (
            <div className={classes.action}>
              {scoping.actions &&
              scoping.actions.acceptQuote &&
              scoping.actions.acceptQuote.isDisplay ? (
                scoping.actions.acceptQuote.label === "Accept" ? (
                  <div>
                    {/* <Button label='Reset' color='primary' */}
                    {/*   onClick={() => resetScopeHandle(scoping.jobQuoteId)} */}
                    {/* />   */}
                    <Button
                      label={scoping.actions.acceptQuote.label}
                      color="primary"
                      variant="contained"
                      style={{ padding: "8px 16px" }}
                      onClick={() => acceptJobQuote(scoping.jobQuoteId)}
                    />
                  </div>
                ) : (
                  <div>
                    <Chip
                      label={scoping.actions.acceptQuote.label}
                      color={
                        scoping.actions.acceptQuote.label === "Winning Supplier"
                          ? "primary"
                          : "default"
                      }
                    />
                  </div>
                )
              ) : null}
              {scoping.actions &&
                scoping.actions.removeAuthorityQuote &&
                scoping.actions.removeAuthorityQuote.isDisplay && (
                  <div>
                    <RemoveAuthority
                      item={scoping}
                      portfolio={props.portfolioType}
                      snackbar={snackbar}
                    />
                  </div>
                )}
            </div>
          );
        })(),
        quoting: quoting.map((quote) => {
          return (
            <div className={classes.action}>
              {quote.actions &&
                quote.actions.resetQuote &&
                quote.actions.resetQuote.isDisplay && (
                  <Button
                    label="Reset"
                    color="primary"
                    onClick={() => resetScopeHandle(quote.jobQuoteId)}
                  />
                )}
              {quote.actions &&
                quote.actions.acceptQuote &&
                quote.actions.acceptQuote.isDisplay &&
                quote.actions.acceptQuote.label === "Winning Supplier" && (
                  <Chip label="Winning Supplier" color="primary" />
                )}
              {quote.actions &&
                quote.actions.acceptQuote &&
                quote.actions.acceptQuote.isDisplay &&
                quote.actions.acceptQuote.label === "Job Lost" && (
                  <Chip label="Job Lost" />
                )}
              {quote.actions &&
                quote.actions.acceptQuote &&
                quote.actions.acceptQuote.isDisplay &&
                quote.actions.acceptQuote.label === "Accept" && (
                  <Button
                    label="Accept"
                    color="primary"
                    variant="contained"
                    style={{ padding: "8px 16px" }}
                    disabled={quote.actions.acceptQuote.isDisabled}
                    onClick={() => acceptJobQuote(quote.jobQuoteId)}
                  />
                )}
              {quote.actions &&
                quote.actions.removeAuthorityQuote &&
                quote.actions.removeAuthorityQuote.isDisplay && (
                  <RemoveAuthority
                    item={quote}
                    portfolio={props.portfolioType}
                    snackbar={snackbar}
                  />
                )}
            </div>
          );
        }),
      },
      { name: null },
      {
        name: "Processed Item(s)",
        info: true,
        unMount: Boolean(!scoping.processed.length),
      },
      { name: null },
      ...getData(quotes, "processed"),
      { name: null },
      // { name: '',
      //   scoping: (() => {
      //     if((!scoping.completed || !scoping.reviewed) && scoping.approved === null ) return null
      //     if(scoping.approved) return <Chip label='Winning Supplier' color='primary' />
      //     if(scoping.approved === false) return <Chip label='Job Lost' />
      //   })(),
      //   quoting: quoting.map(quote => {
      //     if(!quote.completed && quote.approved === null) return null
      //     if(quote.approved) return <Chip label='Winning Supplier' color='primary' />
      //     if(!quote.approved) return <Chip label='Job Lost' />
      //     return ''
      //   })
      // },
      { name: null },
      {
        name: isContents ? null : "Start After receipt of Contract",
        scoping: scoping.startAfterContractReceived,
        quoting: quoting.map((quote) => quote.startAfterContractReceived),
      },
      {
        name: isContents ? null : "Estimated Time for Repairs",
        scoping: scoping.estimatedTimeForRepairs,
        quoting: quoting.map((quote) => quote.estimatedTimeForRepairs),
      },
      { name: null },
      {
        name: isContents ? null : "Grand total",
        sumary: true,
        sir: moneyFormat(scoping.sirTotal),
        variation: moneyFormat(scoping.validationTotal),
        scoping: moneyFormat(scoping.total),
        quoting: quoting.map((quote) => moneyFormat(quote.total)),
      },
      {
        name: isContents ? null : "Original Quoted Price",
        sumary: true,
        scoping: moneyFormat(scoping.originalTotal),
        quoting: quoting.map((quote) => moneyFormat(quote.originalTotal)),
      },
      {
        name: meta.claim.view.fields.savings.isDisplay
          ? meta.claim.view.fields.savings.label
          : null,
        sumary: true,
        scoping: meta.claim.view.fields.savings.isDisplay
          ? moneyFormat(getSaving(scoping))
          : null,
        quoting: meta.claim.view.fields.savings.isDisplay
          ? quoting.map((quote) => moneyFormat(getSaving(quote)))
          : null,
      },

      { name: null },

      {
        name: isContents ? null : "  Recommended by scope validation team",
        scoping: (
          <IconButton
            onClick={() =>
              handleRecommendSupplier(
                scoping.supplier.companyName,
                scoping.jobQuoteId
              )
            }
            icon={
              scoping.recommended
                ? "radio_button_checked"
                : "radio_button_unchecked"
            }
          />
        ),
        quoting: quoting.map((quote) => (
          <IconButton
            onClick={() =>
              handleRecommendSupplier(
                quote.supplier.companyName,
                quote.jobQuoteId
              )
            }
            icon={
              quote.recommended
                ? "radio_button_checked"
                : "radio_button_unchecked"
            }
          />
        )),
      },
    ];
  };

  const getSaving = ({ originalTotal = 0, total = 0 }) => {
    return originalTotal - total;
  };
  const acceptJobQuote = (id) => {
    setAcceptingQuoteId(id);
    acceptDlRef.current.handleOpen();
  };

  if (!quotes || quotes.length === 0)
    return (
      <div>
        <div>No quote found</div>
        <TenderClosingDateAction
          data={claimJobData[`${portfolioType.toLowerCase()}`]}
          portfolioType={portfolioType}
          claimId={claimId}
        />
      </div>
    );

  const rows = getTableData(quotes, portfolioType);

  return (
    <div>
      <Grid fluid spacing={0}>
        {rows.map((row, rowIndex) => (
          <QuotesDetail
            row={row}
            key={rowIndex}
            meta={meta}
            classes={classes}
            displayValidationValue={displayValidationValue}
          />
        ))}
        <AcceptQuoteDialog
          dlRef={acceptDlRef}
          id={acceptingQuoteId}
          meta={meta}
          portfolioType={portfolioType}
        />
        <TenderClosingDateAction
          data={claimJobData[`${portfolioType.toLowerCase()}`]}
          portfolioType={portfolioType}
          claimId={claimId}
        />
      </Grid>
    </div>
  );
};

export default withStyles((theme) => {
  return {
    row: {
      padding: theme.spacing.unit,
      display: "flex",
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.grey[400],
    },
    scopeType: {
      display: "flex",
      backgroundColor: theme.palette.grey[200],
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.grey[400],
      fontWeight: "bold",
    },
    content: {
      textAlign: "center",
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
    },
    break: {
      height: 3,
      background: theme.palette.grey[400],
    },
    expandBtn: {
      margin: "-10px",
      padding: theme.spacing.unit,
    },
    supplier: {
      fontWeight: 600,
    },
    item: {
      fontStyle: "italic",
    },
    itemTotal: {
      fontWeight: 600,
    },
    sumary: {
      fontWeight: 600,
    },
    status: {
      fontWeight: 400,
      color: theme.palette.grey[600],
    },

    sir: {
      color: theme.palette.grey[800],
      fontStyle: "italic",
    },
    action: {
      display: " flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };
})(QuotesPresentation);

const RemoveAuthority = ({ item, portfolio, snackbar }) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState(false);
  return (
    <div>
      {!open && (
        <Button
          label={item.actions.removeAuthorityQuote.label}
          color="primary"
          variant="contained"
          style={{ padding: "8px 16px" }}
          disabled={item.actions.removeAuthorityQuote.isDisabled}
          onClick={() => setOpen(true)}
        />
      )}
      {open && (
        <div>
          <TextField
            style={{ width: "300px" }}
            multiline
            rows={5}
            variant="outlined"
            required
            label="Reason for Removing Authority"
            helperText={error && "Reason Required!"}
            error={error}
            onChange={(e) => setValue(e.target.value)}
          />
          <div>
            <IconButton
              style={{ width: "10px", height: "10px", marginLeft: "10px" }}
              icon="check"
              onClick={() => {
                if (!value) {
                  setError(true);
                } else {
                  setError(false);
                  handleRemoveAuthority(
                    item.jobQuoteId,
                    portfolio,
                    value,
                    snackbar
                  );
                }
              }}
            />
            <IconButton
              style={{ width: "10px", height: "10px", margin: 0 }}
              icon="close"
              onClick={() => {
                setError(false);
                setOpen(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const handleRemoveAuthority = async (id, portfolio, reason, snackbar) => {
  const res = await QuoteRemoveAuthorityMutation({
    where: {
      id: id,
      portfolioType: portfolio,
    },
    input: {
      reason: reason,
    },
  });
  if (res.ok) {
    snackbar.toggleOpen({
      message: res.message,
    });
    if (res.success) {
      window.location.reload();
    }
  } else {
    snackbar.toggleOpen({
      message: res.message,
    });
  }
};
