import React, { Component } from 'react'
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'

import MakeSafeUpdateCostItemPresentation from './MakeSafeUpdateCostItemPresentation'

const query = graphql`
  query MakeSafeUpdateCostItemContainerQuery($where: QuoteCostItemIndex!) {
    items: quoteCostItems(where: $where) {
      edges {
        node {
          label: itemDescription
          value: costItemId
          
          units {
            label: unitName
            value: unitId
          }
        }
      }
    }
  }
`
class MakeSafeUpdateCostItemContainer extends Component {
  render() {
    const { values, meta } = this.props

    return (
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{
          where: {
            portfolioType: values.portfolioType,
            costCentreId: values.costCentreId,
            quotationType: 'MakeSafe'
          },
        }}
        render={({ error, props }) => {
          if(!props) return null

          let items = props.items.edges.map(x => x.node)
          const units = items
            .filter(x => x.value === values.costItemId)
            .map(x => x.units)[0]

          return (
            <MakeSafeUpdateCostItemPresentation
              values={values}
              items={items}
              units={units}
              meta={meta}
            />  
          )
        }}
      />
    )
  }
}

export default MakeSafeUpdateCostItemContainer