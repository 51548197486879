import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation FollowUpActionMutation($input: FollowUpClaimInputType!, $where: ClaimUniqueWhere!) {
    claimFollowUp(input: $input, where: $where) {
      messages
      success
      result {
        id
        jobNoteId
        logDate
        message
        portfolioType
        private
        user {
          userName
          company {
            companyName
          }
        }
        userId
      }
    }
  }
`

export default (variables) => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        updater: store => {
          const payload = store.getRootField('claimFollowUp')
          if(payload.getValue('success')) {
            const newRow = payload.getLinkedRecord('result')
            const storeRoot = store.getRoot()
            const claimNote = ConnectionHandler.getConnection(
              storeRoot,
              'JobNotesList_claimNotes'
            )
            const claimHistory = ConnectionHandler.getConnection(
              storeRoot,
              'HistoriesListItem_claimUpdateHistories'
            )
            // if(!claimNote) return null
            if(claimNote) {
              const newEdge = ConnectionHandler.createEdge(
                store,
                claimNote,
                newRow,
                'DocumentEdge'
              )
              ConnectionHandler.insertEdgeBefore(claimNote, newEdge)
            }
            // if(claimHistory) {
            //   const newEdge = ConnectionHandler.createEdge(
            //     store,
            //     claimHistory,
            //     newRow,
            //     'DocumentEdge'
            //   )
            //   ConnectionHandler.insertEdgeBefore(claimHistory, newEdge)
            // }
          }
        },
        onCompleted: res => {
          resolve({ok: true, ...res.claimFollowUp})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}
