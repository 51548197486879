/**
 * @flow
 * @relayHash 49ac4dae76dfe856993710c191496f83
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuotationType = "AssessorReport" | "MakeSafe" | "Scope" | "UnDefined" | "Variation" | "%future added value";
export type JobScopeTypeWhere = {|
  insurerId: string,
  claimId?: ?string,
  forManager: boolean,
  quotationType: QuotationType,
  portfolioType: PortfolioType,
|};
export type ScopeActionBarWithoutTransactionQueryVariables = {|
  where: JobScopeTypeWhere
|};
export type ScopeActionBarWithoutTransactionQueryResponse = {|
  +jobScopeTypes: ?$ReadOnlyArray<?{|
    +id: string,
    +name: string,
  |}>
|};
export type ScopeActionBarWithoutTransactionQuery = {|
  variables: ScopeActionBarWithoutTransactionQueryVariables,
  response: ScopeActionBarWithoutTransactionQueryResponse,
|};
*/


/*
query ScopeActionBarWithoutTransactionQuery(
  $where: JobScopeTypeWhere!
) {
  jobScopeTypes(where: $where) {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JobScopeTypeWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "jobScopeTypes",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "JobScopeTypeWhere!"
      }
    ],
    "concreteType": "JobScopeType",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ScopeActionBarWithoutTransactionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ScopeActionBarWithoutTransactionQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ScopeActionBarWithoutTransactionQuery",
    "id": null,
    "text": "query ScopeActionBarWithoutTransactionQuery(\n  $where: JobScopeTypeWhere!\n) {\n  jobScopeTypes(where: $where) {\n    id\n    name\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6733df7c6598a7cef160f6a94dbc3270';
module.exports = node;
