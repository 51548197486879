import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { graphql, QueryRenderer, fetchQuery } from "react-relay";
import environment from "common/relay";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import LeftArea from "./LeftArea/";
import RightArea from "./RightArea/";
import Announcement from "./Announcement/Announcement";

const query = graphql`
  query TopbarQuery($isFetched: Boolean!) {
    currentUser {
      menuItems @include(if: $isFetched) {
        admin {
          alignment
          icon
          id
          isDisplay
          isExternal
          label
          url
        }
        claim {
          alignment
          icon
          id
          isDisplay
          isExternal
          label
          url
        }
        config {
          alignment
          icon
          id
          isDisplay
          isExternal
          label
          url
        }
        dashboard {
          alignment
          icon
          id
          isDisplay
          isExternal
          label
          url
        }
        outstandingCommunication {
          alignment
          icon
          id
          isDisplay
          isExternal
          label
          url
        }
        reporting {
          alignment
          icon
          id
          isDisplay
          isExternal
          label
          url
        }
        scoreboard {
          alignment
          icon
          id
          isDisplay
          isExternal
          label
          url
        }
        video {
          alignment
          icon
          id
          isDisplay
          isExternal
          label
          url
        }
        modernVersion {
          label
          isDisplay
        }
        announcementsNotification {
          label
          isDisplay
          icon
        }
      }
    }
  }
`;
const Topbar = (props) => {
  const [showAnnouncement, setShowAnnouncement] = React.useState(false);
  const handleShowAnnouncement = () => {
    setShowAnnouncement(true);
  };
  // const [refresh, setRefresh] = React.useState(false)
  // const [data, setData] = React.useState()
  const [isFetched, setIsFetched] = React.useState(false);
  const { classes } = props;
  const firstRender =
    props.state && props.state.firstRender
      ? JSON.stringify(props.state.firstRender)
      : false;
  React.useEffect(() => {
    if (firstRender) {
      handleShowAnnouncement();
    }
  }, [firstRender]);
  // const string = JSON.stringify(data && data.currentUser && data.currentUser.menuItems)
  // React.useEffect(() => {
  //   if(!isFetched) {
  //     setIsFetched(true)
  //   }
  //   if(data && data.currentUser && !data.currentUser.menuItems) {
  //     setRefresh(!refresh)
  //   }
  //   fetchQuery(environment, query, {isFetched: isFetched, refresh}, {force: true} ).then(data => setData(data)).catch(error => console.log(error))

  // },[isFetched, string, refresh])
  // console.log(isFetched)
  // console.log(data)
  // if(!data) return <AppBar className={classes.appBar} position='sticky' color='default'></AppBar>
  // const menu = data.currentUser.menuItems
  // if(!menu) return <AppBar className={classes.appBar} position='sticky' color='default'></AppBar>
  // const leftMenu = [
  //   { path: '/dashboard', label: menu.dashboard.label, icon: menu.dashboard.icon, accessPath: 'dashboard',
  //   isExternal: menu.dashboard.isExternal, isDisplay: menu.dashboard.isDisplay, url: menu.dashboard.url },
  //   { path: '/claims', label: menu.claim.label, icon: menu.claim.icon, accessPath: 'claims',
  //   isExternal: menu.claim.isExternal, isDisplay: menu.claim.isDisplay, url: menu.claim.url },

  //   { path: '/supplierScorecard', label: menu.scoreboard.label, icon: menu.scoreboard.icon, accessPath: 'scorecard',
  //   isExternal: menu.scoreboard.isExternal, isDisplay: menu.scoreboard.isDisplay, url: menu.scoreboard.url },
  //   { path: '/video/rooms', label: menu.video.label, icon: menu.video.icon, accessPath: 'video',
  //   isExternal: menu.video.isExternal, isDisplay: menu.video.isDisplay, url: menu.video.url },

  //   { path: '/admin', label: menu.admin.label, icon: menu.admin.icon, accessPath: 'dashboard',
  //   isExternal: menu.admin.isExternal, isDisplay: menu.admin.isDisplay, url: menu.admin.url },
  //   { path: '/reports', label: menu.reporting.label, icon: menu.reporting.icon, accessPath: 'reports',
  //   isExternal: menu.reporting.isExternal, isDisplay: menu.reporting.isDisplay, url: menu.reporting.url },
  // ]
  // const rightMenu = {
  //   communication: { label: menu.outstandingCommunication.label, icon: menu.outstandingCommunication.icon, isExternal: menu.outstandingCommunication.isExternal,
  //   isDisplay: menu.outstandingCommunication.isDisplay, url: menu.outstandingCommunication.url },
  //   setting: { label: menu.config.label, icon: menu.config.icon, isExternal: menu.config.isExternal,
  //   isDisplay: menu.config.isDisplay, url: menu.config.url }
  // }
  return (
    <>
      <AppBar className={classes.appBar} position="sticky" color="default">
        <QueryRenderer
          environment={environment}
          query={query}
          variables={{ isFetched: isFetched }}
          render={({ error, props }) => {
            setIsFetched(true);
            if (error) return null;
            if (!props) return null;
            const menu = props.currentUser.menuItems;
            if (!menu) return null;
            const leftMenu = [
              {
                path: "/dashboard",
                label: menu.dashboard.label,
                icon: menu.dashboard.icon,
                accessPath: "dashboard",
                isExternal: menu.dashboard.isExternal,
                isDisplay: menu.dashboard.isDisplay,
                url: menu.dashboard.url,
              },
              {
                path: "/claims",
                label: menu.claim.label,
                icon: menu.claim.icon,
                accessPath: "claims",
                isExternal: menu.claim.isExternal,
                isDisplay: menu.claim.isDisplay,
                url: menu.claim.url,
              },

              {
                path: "/scoreboard",
                label: menu.scoreboard.label,
                icon: menu.scoreboard.icon,
                accessPath: "scorecard",
                isExternal: menu.scoreboard.isExternal,
                isDisplay: menu.scoreboard.isDisplay,
                url: menu.scoreboard.url,
              },
              {
                path: "/video/rooms",
                label: menu.video.label,
                icon: menu.video.icon,
                accessPath: "video",
                isExternal: menu.video.isExternal,
                isDisplay: menu.video.isDisplay,
                url: menu.video.url,
              },

              {
                path: "/admin",
                label: menu.admin.label,
                icon: menu.admin.icon,
                accessPath: "dashboard",
                isExternal: menu.admin.isExternal,
                isDisplay: menu.admin.isDisplay,
                url: menu.admin.url,
              },
              {
                path: "/reports",
                label: menu.reporting.label,
                icon: menu.reporting.icon,
                accessPath: "reports",
                isExternal: menu.reporting.isExternal,
                isDisplay: menu.reporting.isDisplay,
                url: menu.reporting.url,
              },
            ];
            const rightMenu = {
              modernPortal: { isDisplay: menu.modernVersion.isDisplay },
              communication: {
                label: menu.outstandingCommunication.label,
                icon: menu.outstandingCommunication.icon,
                isExternal: menu.outstandingCommunication.isExternal,
                isDisplay: menu.outstandingCommunication.isDisplay,
                url: menu.outstandingCommunication.url,
              },
              setting: {
                label: menu.config.label,
                icon: menu.config.icon,
                isExternal: menu.config.isExternal,
                isDisplay: menu.config.isDisplay,
                url: menu.config.url,
              },
              announcementsNotification: {
                isDisplay: menu.announcementsNotification.isDisplay,
                icon: menu.announcementsNotification.icon,
              },
            };
            return (
              <Toolbar className={classes.toolBar}>
                <LeftArea menu={leftMenu} />
                <RightArea menu={rightMenu} />
              </Toolbar>
            );
          }}
        />
      </AppBar>
      {showAnnouncement && <Announcement showAnnouncement={showAnnouncement} />}
    </>
  );
};

export default withStyles((theme) => {
  return {
    appBar: {
      flexGrow: 1,
      height: "50px",
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
    },
    toolBar: {
      height: "100%",
      width: "80%",
      minHeight: "0",
      justifyContent: "space-between",
    },
  };
})(Topbar);
