import React, { Component } from 'react'
import { graphql, createPaginationContainer } from 'react-relay'
import { withStyles } from '@material-ui/core'
import { withClaimMeta } from '../../ClaimMetaContext'
// import environment from 'common/relay'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import Button from 'components/Mui/Button'

import ClaimDetailsPortfolioFilter from '../../ClaimDetailsPortfolioFilter'
import CommunicationsListItem from './CommunicationsListItem'

class CommunicationsList extends Component {
  snackbar = window[Symbol.for('__snackbar')]
  componentDidMount = () => document.querySelector('#communicationPagination_scrolling-list').addEventListener('scroll', this.scrollHandler)
  componentWillUnmount = () => document.querySelector('#communicationPagination_scrolling-list').removeEventListener('scroll', this.scrollHandler)

  render() {
    const { classes, data, meta, portfolio } = this.props
    const CommunicationDisabled = meta.claim.view.actions.addCommunication.isDisabled
    const externalSourceAction = meta && meta.claim.view.actions.externalSourceData
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Paper>
              <Grid fluid justify='space-between'>
                <Grid item xs={2}>
                  <Button fullWidth label='Next Step' variant='outlined' color='primary' onClick={this.handleNextStep}/>
                </Grid>
                {
                  externalSourceAction.isDisplay &&　
                  <Grid item xs={2}>
                    <Button onClick={this.handleEmbeddedAction}
                      leadComp={
                        <img alt="logo" src={`${process.env.REACT_APP_COMPANY_PATH}/handdii/logo.png`} style={{width: '24px', marginRight: '8px'}} />
                      } fullWidth label={externalSourceAction.label} disabled={externalSourceAction.isDisabled} variant='outlined' color='primary'/>
                  </Grid>
                }
                <Grid item xs={2}>
                  {!CommunicationDisabled?<Button onClick={this.handleCommunication} fullWidth label='New Message' variant='outlined' color='primary'/>:null}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs className={classes.relative}>
            <ClaimDetailsPortfolioFilter
              value={portfolio ? portfolio.toLowerCase() : undefined}
              portfolio={meta.claim}
              changePortfolio={portfolio => this.props.handlePortfolio(portfolio)}
            />
            <Paper
              title={(
                <Grid fluid className={classes.headerContainer}>
                  {[
                    {col: 2, name: 'Date'},
                    {col: 1, name: 'Company'},
                    {col: 1, name: 'User'},
                    {col: 1, name: 'Receiver'},
                    {col: 1, name: 'Privacy'},
                    {col: 1, name: 'Acknowledged'},
                    {col: 5, name: 'Message'}
                  ].map(({col, name}, key) => <Grid className={classes.alignCenter} item key={key} xs={col}>{name}</Grid>)}
                </Grid>
              )}
            >
              <div className={classes.listBody} id='communicationPagination_scrolling-list'>
                {
                  data.claimCommuications && data.claimCommuications.edges.length !== 0
                    ? data.claimCommuications.edges.map(({ node }, k) => <CommunicationsListItem
                      index={k+1} key={k} item={node}
                      meta={meta}
                    />)
                    : 'No communication found'
                }
              </div>
            </Paper>
          </Grid>
        </Grid>
      </>
    )
  }

  handleNextStep = () => {
    if(window[Symbol.for('__nextStepAction')]){
      window[Symbol.for('__nextStepAction')].handleOpen()
    }
  }

  handleCommunication = () => {
    if(window[Symbol.for('__newCommunicationAction')]){
      window[Symbol.for('__newCommunicationAction')].toggleOpen()
    }
  }

  handleEmbeddedAction = () => {
    if(window[Symbol.for('__communicationEmbeddedAction')]){
      window[Symbol.for('__communicationEmbeddedAction')].toggleOpen()
    }
    if(window[Symbol.for('__CommunicationEmbeddedActionDisplay')]){
      window[Symbol.for('__CommunicationEmbeddedActionDisplay')].toggleDisplay()
    }
  }

  scrollHandler = e => {
    const { target } = e
    if((target.scrollTop + target.offsetHeight) >= target.scrollHeight*0.7) {
      this._loadMore()
    }
  }
  _loadMore = () => {
    if (!this.props.relay.hasMore()) return
    else if (this.props.relay.isLoading()) return

    this.props.relay.loadMore()
  }
}

export default createPaginationContainer(
  withStyles(theme => ({
    alignCenter: theme.mixins.alignJustifyContainer(theme),
    listBody: {
      maxHeight: '55vh',
      overflow: 'auto'
    },
    relative: {
      position: 'relative'
    },
    headerContainer: {
      '& > div': {
        borderRight: '1px solid white',
      },
      '& > div:last-child': {
        borderRight: 0,
      }
    }
  }))(withClaimMeta(CommunicationsList)),
  graphql`
    fragment CommunicationsList on Query {
      claimCommuications(first: $first, after: $after, where: $where)
      @connection(key: "CommunicationsList_claimCommuications", filters: []) {
        edges {
          node {
            ...CommunicationsListItem_item
          }
        }
      }
    }
  `,
  {
    query: graphql`
      query CommunicationsListQuery ($first: Int!, $after: String, $where: CommunicationFilter) {
        ...CommunicationsList
      }
    `,
    getVariables(props, paginationInfo, fragmentVariables) {
      const { cursor } = paginationInfo
      const { where, first } = fragmentVariables
      return {
        first,
        after: cursor,
        where
      }
    }
  }
)
