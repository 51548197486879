import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const mutation = graphql`
  mutation GetMakeSafeMutation(
    $where: ENDataPortfolioKey!
    $input: QuoteItemFieldInput
  ) {
    pdf: pdfProduceForMakeSafe(where: $where, input: $input) {
      messages
      success
    }
  }
`;

export default (variables) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (res) => {
        resolve({ ok: true, pdf: res.pdf.messages[0] });
      },
      onError: (errors) => {
        resolve({ ok: false });
      },
    });
  });
};
