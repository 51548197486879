/**
 * @flow
 * @relayHash def02334c5bce6efccf94ea891137482
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessRole = "Adjustor" | "Administrator" | "Agent" | "Builder" | "ContentSupplier" | "Customer" | "Estimator" | "Insurance" | "InternalAB" | "LossAdjuster" | "MultiTenderAssessor" | "Restorer" | "Specialist" | "SystemAdmin" | "UNDEFINED" | "%future added value";
export type FilterType = "ComboBox" | "ComboBox_multiple" | "Date" | "DateTime" | "Search" | "Select" | "Select_multiple" | "Text" | "Time" | "%future added value";
export type ClaimListFilterQueryVariables = {||};
export type ClaimListFilterQueryResponse = {|
  +currentUser: {|
    +claimFilters: ?$ReadOnlyArray<?{|
      +icon: ?string,
      +id: string,
      +isDisabled: boolean,
      +label: ?string,
      +name: string,
      +options: ?$ReadOnlyArray<?{|
        +value: ?string,
        +group: ?string,
        +label: string,
      |}>,
      +type: FilterType,
    |}>
  |},
  +me: ?{|
    +userRole: ?AccessRole,
    +privileges: ?{|
      +menus: ?$ReadOnlyArray<?{|
        +nodeName: string
      |}>
    |},
  |},
|};
export type ClaimListFilterQuery = {|
  variables: ClaimListFilterQueryVariables,
  response: ClaimListFilterQueryResponse,
|};
*/


/*
query ClaimListFilterQuery {
  currentUser {
    claimFilters {
      icon
      id
      isDisabled
      label
      name
      options {
        value: id
        group
        label
        id
      }
      type
    }
    id
  }
  me {
    userRole
    privileges {
      menus {
        nodeName
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "icon",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisabled",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "group",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "me",
  "storageKey": null,
  "args": null,
  "concreteType": "UserProfile",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "userRole",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "privileges",
      "storageKey": null,
      "args": null,
      "concreteType": "UserPrivileges",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "menus",
          "storageKey": null,
          "args": null,
          "concreteType": "PrivilegeMenu",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "nodeName",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ClaimListFilterQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticatedUser",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claimFilters",
            "storageKey": null,
            "args": null,
            "concreteType": "FilterInput",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "options",
                "storageKey": null,
                "args": null,
                "concreteType": "FilterInputOption",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v3/*: any*/)
                ]
              },
              (v7/*: any*/)
            ]
          }
        ]
      },
      (v8/*: any*/)
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ClaimListFilterQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticatedUser",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claimFilters",
            "storageKey": null,
            "args": null,
            "concreteType": "FilterInput",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "options",
                "storageKey": null,
                "args": null,
                "concreteType": "FilterInputOption",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v3/*: any*/),
                  (v1/*: any*/)
                ]
              },
              (v7/*: any*/)
            ]
          },
          (v1/*: any*/)
        ]
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ClaimListFilterQuery",
    "id": null,
    "text": "query ClaimListFilterQuery {\n  currentUser {\n    claimFilters {\n      icon\n      id\n      isDisabled\n      label\n      name\n      options {\n        value: id\n        group\n        label\n        id\n      }\n      type\n    }\n    id\n  }\n  me {\n    userRole\n    privileges {\n      menus {\n        nodeName\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '64b95dd724a506bdfd80ba0da829a2fd';
module.exports = node;
