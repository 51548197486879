/**
 * @flow
 * @relayHash 853f6070eb64458f933e136bf9885804
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type SupplierStatusFilter = "AcceptedOnly" | "RequestedAndAvailable" | "RequestedOnly" | "%future added value";
export type SupplierTypeFilter = "ManagerAndSupplier" | "QuotingSupplier" | "Supplier" | "SupplierExcludeScoping" | "%future added value";
export type ClaimParticipantIndex = {|
  claimId: string,
  postcode?: ?string,
  portfolioTypes?: ?$ReadOnlyArray<?PortfolioType>,
  participantStatus?: ?SupplierStatusFilter,
  participantType?: ?SupplierTypeFilter,
|};
export type QuotingRestorersCardQueryVariables = {|
  where?: ?ClaimParticipantIndex
|};
export type QuotingRestorersCardQueryResponse = {|
  +claimParticipants: ?$ReadOnlyArray<?{|
    +companyName: string,
    +companyId: number,
    +requested: boolean,
    +id: string,
  |}>
|};
export type QuotingRestorersCardQuery = {|
  variables: QuotingRestorersCardQueryVariables,
  response: QuotingRestorersCardQueryResponse,
|};
*/


/*
query QuotingRestorersCardQuery(
  $where: ClaimParticipantIndex
) {
  claimParticipants(where: $where) {
    companyName
    companyId
    requested
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimParticipantIndex",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimParticipants",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ClaimParticipantIndex"
      }
    ],
    "concreteType": "ClaimParticipant",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "companyName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "companyId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "requested",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "QuotingRestorersCardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "QuotingRestorersCardQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "QuotingRestorersCardQuery",
    "id": null,
    "text": "query QuotingRestorersCardQuery(\n  $where: ClaimParticipantIndex\n) {\n  claimParticipants(where: $where) {\n    companyName\n    companyId\n    requested\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6b089bf62b53a27388fb316c898c064f';
module.exports = node;
