import Landing from 'pages/Landing'
import Login from 'pages/User/Login'
// import Test from 'pages/Test'
import NotFound from 'pages/NotFound'
import Settings from 'pages/User/Settings'
import Dashboard from 'pages/Dashboard'
import SupplierScorecard from 'pages/SupplierScorecard'
import ClaimListPage from 'pages/Claim/ClaimListPage'
import Survey from 'pages/Survey'
import ClaimDetails from 'pages/Claim/ClaimDetails'
import AddClaim from 'pages/Claim/AddClaim'
import Reports from 'pages/Reports'
import Communication from 'pages/Communication'
import Daily from 'pages/Daily'
import ExternalLogin from 'pages/User/Login/ExternalLogin'

import Video from 'pages/Video'

export const publicRoutes = [
  { exact: true, path: '/', component: Landing },
  { exact: true, path: '/user/login', component: Login },
  { exact: false, path: '/externalLogin', component: ExternalLogin },
  { exact: false, path: '/video', component: Video },
  // { exact: true, path: '/test', component: Test },
  { exact: true, component: NotFound },
]

export const privateRoutes = [
  { exact: false, path: '/settings', component: Settings },
]

export const accessRoutes = [
  { exact: true, path: '/dashboard', accessPath: 'dashboard', component: Dashboard },

  { exact: true, path: '/claims', accessPath: 'claims', component: ClaimListPage },


  { exact: true, path: '/scoreboard', accessPath: 'scorecard', component: SupplierScorecard },
  { exact: true, path: '/survey', accessPath: 'scorecard', component: Survey },


  { exact: false, path: '/claim', accessPath: 'claims', component: ClaimDetails },
  { exact: true, path: '/add-claim', accessPath: 'claims', component: AddClaim },

  { exact: true, path: '/reports', accessPath: 'reports', component: Reports },

  { exact: true, path: '/communication', accessPath: 'communicationtasks', component: Communication },
  { exact: true, path: '/dailytask', accessPath: 'dailytasks', component: Daily },
]
