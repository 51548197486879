import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

import Button from 'components/Mui/Button';
import { withStyles } from '@material-ui/core/styles'
import classNames from 'clsx'

import { tryParse } from 'common/utils'

let {
  REACT_APP_COMPANY_PATH
} = process.env

// console.log(localStorage.APPLICATION_CUSTOM)
class LeftArea extends Component {
  state = {
    activeButton: 0,
    logoHeight: 50,
  }

  componentWillMount = () => {
    // const accessPaths = tryParse(localStorage.ACCESS_PATHS)
    const { menu } = this.props

    const leftItem = menu.filter(path => path.isDisplay).map((path, key) => ([path.path, key])).reduce((total, current) => [...total, ...current],[])
    const pathname = this.props.location.pathname
    const index =
      pathname.indexOf('/claims') !== -1 ? leftItem[leftItem.indexOf('/claims') + 1] :
      pathname.indexOf('/claim') !== -1 ? leftItem[leftItem.indexOf('/claims') + 1] :
      pathname.indexOf('/dashboard') !== -1 ? leftItem[leftItem.indexOf('/dashboard') + 1] :
      pathname.indexOf('/reports') !== -1 ? leftItem[leftItem.indexOf('/reports') + 1] :
      null

    this.setState({activeButton: index})

    const { logoHeight = 50 } = JSON.parse(localStorage.APPLICATION_CUSTOM)
    this.setState({logoHeight})
  }

  componentDidUpdate = (prevProps, prevState) => {
    // const accessPaths = tryParse(localStorage.ACCESS_PATHS)
    const { menu } = this.props

    const leftItem = menu.filter(path => path.isDisplay)
    .map((path, key) => ([path.path, key]))
    .reduce((total, current) => [...total, ...current],[])
    const pathname = this.props.location.pathname
    const index =
      pathname.indexOf('/claims') !== -1 ? leftItem[leftItem.indexOf('/claims') + 1] :
      pathname.indexOf('/claim') !== -1 ? leftItem[leftItem.indexOf('/claims') + 1] :
      pathname.indexOf('/dashboard') !== -1 ? leftItem[leftItem.indexOf('/dashboard') + 1] :
      pathname.indexOf('/reports') !== -1 ? leftItem[leftItem.indexOf('/reports') + 1] :
      null

    if(prevState.activeButton !== index) this.setState({activeButton: index})
  }

  render() {
    const { menu } = this.props
    // const accessPaths = tryParse(localStorage.ACCESS_PATHS)
    const applicationCustom = tryParse(localStorage.APPLICATION_CUSTOM)
    const { classes } = this.props
    const leftItem = menu.filter(path => path.isDisplay)

    return (
      <div className={classes.root}>
        {applicationCustom && applicationCustom.companyLogo && <img style={{
          height: `calc(${this.state.logoHeight}% - 4px)`,
          borderRadius: '6px',
          marginRight: '10px',
          padding: '2px',
        }} alt='endata' src={`${REACT_APP_COMPANY_PATH}/${applicationCustom.companyLogo}`}/> }
        { leftItem.map(({ path, label, icon, url, isExternal }, key) => {
          if(isExternal) return (
            <Button key={key}
              className={classNames(
                classes.button,
                {[classes.active]: key === this.state.activeButton}
              )}
              label={label}
              labelClass={classes.label}
              leadIcon={icon}
              component='a'
              target='_blank'
              href={url}
            >
            </Button>
          )

          return (
            <Button component={Link} to={path} key={key}
              className={classNames(
                classes.button,
                {[classes.active]: key === this.state.activeButton}
              )}
              onClick={e => this.handleChangeActiveButton(key)}
              label={label}
              labelClass={classes.label}
              leadIcon={icon}
            >
            </Button>
          )
        })}
      </div>
    )
  }
  handleChangeActiveButton = key => {
    this.setState({ activeButton: key })
  }
}

export default withStyles(theme => ({
  '@supports ( -moz-appearance:none )': {
    root: {
      width: '110%',
      transformOrigin: '0rem 0rem',
      transform: 'scale(0.91)',
    }
  },
  root: {
    height: '100%',
    // marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    zoom: 0.91
  },
  image: {
    height: 'calc(50% - 4px)',
    borderRadius: '6px',
    padding: '2px',
  },
  button: {
    height: '100%',
    margin: 0,
    color: '#fff',
    padding: theme.spacing.unit * 1.5,
  },
  label: {
    fontSize: '1rem'
  },
  icon: {
    marginRight: '5px'
  },
  active: {
    background: 'rgba(0,0,0, 0.3)'
  }
}))(withRouter(LeftArea))