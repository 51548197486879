import React from 'react'

export default ({ skip }) => {
  const [state, setState] = React.useState({
    ready: false,
    supported: {
      video: false,
      audio: false,
    }
  })

  React.useEffect(() => {
    if(!skip) {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const video = Boolean(devices.find(device => device.kind === 'videoinput'))
          const audio = Boolean(devices.find(device => device.kind === 'audioinput'))
          // if(!devices.length) {
          //   setIsSupportNoDevice(true)
          // }
          setState({ ready: true, supported: { video, audio } })
        })
        .catch(error => console.log(error))
    }
  }, [skip])

  return state
}
