import React from 'react'
import { graphql } from 'relay-runtime'

import { useQuery } from 'relay-hooks'
import { requestSubscription } from 'react-relay'
import { ReactRelayContext } from 'relay-hooks'

const CALL_SESSIONS_QUERY = graphql`
  query useVideoRoomSubscriptionQuery($where: CallSessionWhereInput!) {
    callSessions(where: $where) {
      id
      userType
      callStatus {
        status
        message
      }
      room {
        id
        status
        account {
          id
          clientActionsConfig {
            takingPicture
            toggleVideo
            toggleAudio
            toggleRecording
            hangup
          }

          callStatusConfigs { status message }

          companyConfigs { endLogo }

          consentConfigs { isChecking title content }
        }
      }
    }
  }
`

const PEER_CALL_SESSION_SUBSCRIPTION = graphql`
  subscription useVideoRoomSubscription($where: CallSessionSubscriptionWhereInput) {
    callSession(where: $where) {
      id
      userType
      callStatus {
        status
        message
      }
      room {
        status
        videos { url }
      }
    }
  }
`

export default ({ callSessionId, claimId }) => {
  const { props: data } = useQuery({
    query: CALL_SESSIONS_QUERY,
    variables: {
      where: { callSessionId },
    }
  })

  const { environment } = React.useContext(ReactRelayContext)
  React.useEffect(() => {
    if (callSessionId) {
      const subscription = requestSubscription(environment, {
        subscription: PEER_CALL_SESSION_SUBSCRIPTION,
        variables: { where: { callSessionId } },
        onError: error => console.log(error)
      })
      return () => {
        subscription.dispose()
      }
    }
  }, [callSessionId])

  const dataString = data ? JSON.stringify(data) : null
  const props = React.useMemo(() => {
    if(!data) return { ready: false }

    const room = data.callSessions[0].room
    const ownerSession = data.callSessions.find(session => session.userType === 'OWNER')
    const guestSession = data.callSessions.find(session => session.userType === 'GUEST')
    const isOwner = ownerSession ? ownerSession.id === callSessionId : false
    const roomStatus = room.status
    const ownerStatus = ownerSession ? ownerSession.callStatus.status : null
    const guestStatus = guestSession ? guestSession.callStatus.status : null
    const guestMessage = guestSession ? guestSession.callStatus.message : null

    const {
      consentConfigs: consents,
      callStatusConfigs,
      companyConfigs,
      clientActionsConfig
    } = data.callSessions[0].room.account
    const consentConfigs =
      isOwner ? { isChecking: false } :
      consents ? consents :
      { isChecking: false }
    const messageConfigs = callStatusConfigs
      ? callStatusConfigs.reduce((total, current) => {
        total[current.status] = current.message
        return total
      }, {})
      : null

    const endLogo = (companyConfigs && companyConfigs.endLogo) ? `?logo=${companyConfigs.endLogo}` : ''
    const endMessage = (messageConfigs && messageConfigs.ENDED) ? `&message=${messageConfigs.ENDED}` : ''
    const endLocation =
      !isOwner ? `/video/end-call${endLogo}${endMessage}` :
      (isOwner && !claimId) ? `/video/rooms/${room.id}` :
      `/claim/job-info?id=${claimId}`

    return {
      ready: Boolean(ownerSession),

      isOwner,
      roomStatus, ownerStatus, guestStatus, guestMessage,

      consentConfigs, messageConfigs, actionConfigs: clientActionsConfig,

      endLocation, photoUploadId: guestSession.id
    }
  }, [claimId, dataString])

  return props
}
