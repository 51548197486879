/**
 * @flow
 * @relayHash 20be1e648925a8e90ace2608f8f0df02
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SpecialistWhere = {|
  claimId: string,
  specialistCategoryId: number,
|};
export type SpecialistRequestEdit_SpecialistQueryVariables = {|
  where: SpecialistWhere
|};
export type SpecialistRequestEdit_SpecialistQueryResponse = {|
  +options: ?$ReadOnlyArray<?{|
    +label: string,
    +value: number,
  |}>
|};
export type SpecialistRequestEdit_SpecialistQuery = {|
  variables: SpecialistRequestEdit_SpecialistQueryVariables,
  response: SpecialistRequestEdit_SpecialistQueryResponse,
|};
*/


/*
query SpecialistRequestEdit_SpecialistQuery(
  $where: SpecialistWhere!
) {
  options: specialists(where: $where) {
    label: companyName
    value: companyId
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "SpecialistWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "SpecialistWhere!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "companyId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SpecialistRequestEdit_SpecialistQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "options",
        "name": "specialists",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SpecialistRequestEdit_SpecialistQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "options",
        "name": "specialists",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SpecialistRequestEdit_SpecialistQuery",
    "id": null,
    "text": "query SpecialistRequestEdit_SpecialistQuery(\n  $where: SpecialistWhere!\n) {\n  options: specialists(where: $where) {\n    label: companyName\n    value: companyId\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '82c72bc49c27e3c25f24f070e804ad6a';
module.exports = node;
