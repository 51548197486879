import React from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import { Grid, CircularProgress } from '@material-ui/core'

import Toolbar from '@material-ui/core/Toolbar'
import ToolbarButton from 'components/MuiExtended/ToolbarButton'
import VideoCallImageBar from '../Call/components/VideoCallImageBar'

const VideoRoom = ({
  classes,
  loading = true,
  viewMode = 'host',
  message = 'Loading...',
  peerMessage,
  localVideoRef,
  remoteVideoRef,
  actions,
  photos,
  isDisplayRemote
}) => {
  return (
    <div
      className={clsx(
        'VideoRoomRoot',
        { [classes.root]: viewMode === 'host' },
        { [classes.guestRoot]: viewMode !== 'host' },
      )}
    >
      {loading && (
        <Grid
          container direction='column' justify='center' alignItems='center'
          className={clsx('VideoRoom--loading', classes.loading)}
        >
          <CircularProgress style={{ marginBottom: 24 }} />
          <div style={{ marginBottom: 24 }}>{message}</div>
          {(viewMode === 'host' && peerMessage) && <div style={{ marginBottom: 24 }}>[GUEST] {peerMessage}</div>}
        </Grid>
      )}
      <Grid container direction='row' className={clsx('VideoRoom--main-view', classes.mainView)}>
        {(photos && viewMode === 'host') && (
          <Grid item className={clsx('VideoRoom--photo-wrapper', classes.photoWrapper)}>
            <VideoCallImageBar
              isOwner={viewMode === 'host'}
              photos={photos}
            />
          </Grid>
        )}
        <Grid item xs className={clsx('VideoRoom--video-wrapper', classes.videoWrapper)}>
          <video ref={localVideoRef} autoPlay playsInline
            style={viewMode !== 'host' ? { objectFit: 'cover' } : {}}
            className={clsx(
              { [classes.subVideo]: viewMode === 'host' },
              { [classes.mainVideo]: viewMode !== 'host' },
            )}
          />
          <video ref={remoteVideoRef} autoPlay playsInline
            className={clsx(
              { [classes.hide]: !isDisplayRemote },
              { [classes.subVideo]: viewMode !== 'host' },
              { [classes.mainVideo]: viewMode === 'host' },
            )}
          />
          {actions && (
            <div className={clsx('VideoRoom--action-bar', classes.actionBar)}>
              {actions.map(action => <ToolbarButton key={action.name} {...action} />)}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 55px)',
  },
  guestRoot: {
    position: 'relative',
    width: '100%',
    height: '100vh',
  },
  loading: {
    position: 'absolute',
    top: 0, right: 0, bottom: 0, left: 0,
    background: theme.palette.background.paper,
    zIndex: 1000,
    width: '100%'
  },
  actionBar: {
    zIndex: 1500,
    display: 'flex',
    justifyContent: 'center',

    position: 'absolute',
    bottom: theme.spacing.unit * 4,
    width: '100%',
  },

  mainView: {
    height: '100%',
    width: '100%',
  },
  photoWrapper: {
    height: '100%',
    width: 200,
  },
  videoWrapper: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  mainVideo: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    background: 'black',
  },
  subVideo: {
    position: 'absolute',
    top: 0, right: 0,
    height: '10vmax'
  },
  hide: {
    display: 'none'
  }
}))(VideoRoom)