import React from 'react'
import useReactRouter from 'use-react-router'

export default ({ roomStatus, ownerSessionStatus, guestSessionStatus, endLocation }) => {
  const { history } = useReactRouter()
  const [loading, setLoading] = React.useState(true)
  const [ownerMessage, setOwnerMessage] = React.useState(null)
  const [guestMessage, setGuestMessage] = React.useState(null)

  React.useEffect(() => {
    // ROOM IS ENDED
    if(roomStatus === 'ENDED' && endLocation) {
      setOwnerMessage('Session is expired')
      setGuestMessage('Session is expired')
      /**
       * FIX ME
       */
      history.push(endLocation)
    }
  }, [roomStatus, endLocation])

  React.useEffect(() => {
    if(ownerSessionStatus) {
      if(roomStatus !== 'ENDED') {
        setOwnerMessage(ownerSessionStatus.message)
      }
    }
  }, [roomStatus, ownerSessionStatus])

  React.useEffect(() => {
    if(guestSessionStatus) {
      if(roomStatus !== 'ENDED') {
        setGuestMessage(`${guestSessionStatus.message}`)
      }
    }
  }, [roomStatus, guestSessionStatus])

  React.useEffect(() => {
    if(
      loading === true &&
      roomStatus !== 'ENDED' &&
      (ownerSessionStatus && ownerSessionStatus.status === 'CONNECTED') &&
      (guestSessionStatus && guestSessionStatus.status === 'CONNECTED')
    ) {
      setLoading(false)
    }
    else {
      setLoading(true)
    }
  }, [roomStatus, ownerSessionStatus, guestSessionStatus])

  return {
    loading,
    ownerMessage, setOwnerMessage,
    guestMessage, setGuestMessage,
  }
}