/**
 * @flow
 * @relayHash 13f7a3295f3cba458936092f72e68fa0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeeInvoiceUniqueWhere = {|
  feeInvoiceId: number
|};
export type FeeInvoiceDeleteMutationVariables = {|
  where: FeeInvoiceUniqueWhere
|};
export type FeeInvoiceDeleteMutationResponse = {|
  +feeInvoiceDelete: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type FeeInvoiceDeleteMutation = {|
  variables: FeeInvoiceDeleteMutationVariables,
  response: FeeInvoiceDeleteMutationResponse,
|};
*/


/*
mutation FeeInvoiceDeleteMutation(
  $where: FeeInvoiceUniqueWhere!
) {
  feeInvoiceDelete(where: $where) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "FeeInvoiceUniqueWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "feeInvoiceDelete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "FeeInvoiceUniqueWhere!"
      }
    ],
    "concreteType": "FeeInvoicePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeInvoiceDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeInvoiceDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "FeeInvoiceDeleteMutation",
    "id": null,
    "text": "mutation FeeInvoiceDeleteMutation(\n  $where: FeeInvoiceUniqueWhere!\n) {\n  feeInvoiceDelete(where: $where) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a587d59a4d54b0591dc74c525f628d4c';
module.exports = node;
