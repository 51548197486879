import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Field } from 'formik'
import MuiSwitch from '@material-ui/core/Switch'

class Switch extends Component {
  render() {
    const {
      classes, label,
      labelOn = 'Yes', labelOff = 'No',
      readOnly, disabled, onChange, onClick,
      ...props
    } = this.props
    return (
      <FormControl component='fieldset' fullWidth>
        {label && <FormLabel component='legend' className={classes.label}>{label}</FormLabel>}
        <Field {...props}
          render={({
            field, form: { isSubmitting },
            ...props
          }) => (
            <FormControlLabel className={classes.switchRoot}
              label={field.value ? labelOn : labelOff} control={(
                <MuiSwitch
                  disabled={isSubmitting || disabled || readOnly}
                  {...field} {...props}
                  value={field.name}
                  checked={field.value}
                  onChange={e => {
                    field.onChange && field.onChange(e)
                    onChange && onChange(e.target.value)
                  }}
                />
              )}
            />
          )}
        /> 

      </FormControl>
    )
  }
}

export default withStyles(theme => ({
  switchRoot: {
    display: 'flex',
    justifyContent: 'center'
  },
  label: {
    fontSize: '0.8rem',
    fontWeight: 600
  },
}))(Switch)