/**
 * @flow
 * @relayHash b534c1900a749aa1de0b66852ccc41ab
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AnnouncementTypeEnum = "System_Release" | "Terms_And_Conditions" | "%future added value";
export type AnnouncementWhere = {|
  id?: ?string,
  includeAcknowledged?: ?boolean,
  includeFuture?: ?boolean,
  disabled?: ?boolean,
  announcementType?: ?AnnouncementTypeEnum,
|};
export type AnnouncementsBellQueryVariables = {|
  where: AnnouncementWhere,
  first?: ?number,
|};
export type AnnouncementsBellQueryResponse = {|
  +announcements: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +title: string,
        +content: string,
        +id: string,
        +type: ?AnnouncementTypeEnum,
        +displayType: string,
      |}
    |}>
  |}
|};
export type AnnouncementsBellQuery = {|
  variables: AnnouncementsBellQueryVariables,
  response: AnnouncementsBellQueryResponse,
|};
*/


/*
query AnnouncementsBellQuery(
  $where: AnnouncementWhere!
  $first: Int
) {
  announcements(where: $where, first: $first) {
    edges {
      node {
        title
        content
        id
        type
        displayType
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "AnnouncementWhere!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "announcements",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "AnnouncementWhere"
      }
    ],
    "concreteType": "AnnouncementConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "AnnouncementEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Announcement",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "content",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "displayType",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AnnouncementsBellQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AnnouncementsBellQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "AnnouncementsBellQuery",
    "id": null,
    "text": "query AnnouncementsBellQuery(\n  $where: AnnouncementWhere!\n  $first: Int\n) {\n  announcements(where: $where, first: $first) {\n    edges {\n      node {\n        title\n        content\n        id\n        type\n        displayType\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '955521b489d8687849b2c8b4a9b1b507';
module.exports = node;
