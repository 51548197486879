import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation AcceptContentItemMutation($input: JobContentAuthoriseInput!, $where: ENDataEntityKey!) {
    jobContentAuthorise(
      where: $where
      input: $input
    ) {
      messages
      success
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          const { success, messages } = res.jobContentAuthorise
          resolve({ ok: true, success, messages })
        },
        onError: errors => {
          resolve({ ok: false, messages: ['Unable to complete action, please try again'] })
        }
      }
    )
  })
}