import React from 'react'
import { Field } from 'formik'
import MuiPhoneInput from 'material-ui-phone-number'

export default props => {
  const [defaultCountry, setDefaultCountry] = React.useState('au')
  React.useEffect(() => {
    fetch('https://extreme-ip-lookup.com/json')
      .then(res => res.json())
      .then(res => {
        if (res.countryCode) {
          setDefaultCountry(res.countryCode.toLowerCase())
        }
      })
      .catch(err => setDefaultCountry('au'))
  }, [])

  return (
    <Field
      component={MuiPhoneCountryInput}
      {...props}
      defaultCountry={defaultCountry}
    />
  )
}

const MuiPhoneCountryInput = ({
  field,
  form,
  readOnly,

  onlyCountries = ['au', 'nz', 'vn'],
  countryCodeEditable = false,
  defaultCountry = 'au',

  ...props
}) => {
  const isError = Boolean(form.dirty && form.touched[field.name] && form.errors[field.name])
  const disabled = props.disabled || form.isSubmitting
  if (!onlyCountries.includes(defaultCountry)) defaultCountry = 'au'
  return (
    <MuiPhoneInput
      variant='outlined'
      fullWidth
      {...props}

      onChange={value => {
        const cleanedValue = value.split('-').join(' ')
        form.setFieldValue([field.name], cleanedValue)
      }}
      onBlur={e => {
        field.onBlur && field.onBlur(e)
      }}
      value={field.value}
      error={isError}
      helperText={isError && form.errors[field.name]}
      disabled={disabled}
      readOnly={readOnly}

      onlyCountries={onlyCountries}
      countryCodeEditable={countryCodeEditable}
      defaultCountry={defaultCountry}
    />
  )
}
