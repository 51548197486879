/**
 * @flow
 * @relayHash 5e82fc0821c6d1695f9734fbfe55f685
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupervisorCreateInput = {|
  name: string,
  phoneNumber: string,
|};
export type SupervisorCreateMutationVariables = {|
  input: SupervisorCreateInput
|};
export type SupervisorCreateMutationResponse = {|
  +supervisorCreate: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type SupervisorCreateMutation = {|
  variables: SupervisorCreateMutationVariables,
  response: SupervisorCreateMutationResponse,
|};
*/


/*
mutation SupervisorCreateMutation(
  $input: SupervisorCreateInput!
) {
  supervisorCreate(input: $input) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SupervisorCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "supervisorCreate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SupervisorCreateInput!"
      }
    ],
    "concreteType": "SupervisorPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SupervisorCreateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SupervisorCreateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SupervisorCreateMutation",
    "id": null,
    "text": "mutation SupervisorCreateMutation(\n  $input: SupervisorCreateInput!\n) {\n  supervisorCreate(input: $input) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '762e98c579a9f2048b2ac8eb5b910b5c';
module.exports = node;
