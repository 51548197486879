import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import jwt from 'jsonwebtoken'

const mutation = graphql`
  mutation InitCallMutation($where: ENDataEntityKey!) {
    claimInitialCall(where: $where) {
      success
      messages
      result {
        progress {
          initialCallMade
        }
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        updater: store => {
          const token = localStorage.getItem('TOKEN')
          if(token) {
            const decoded = jwt.decode(token)
            const role = decoded.role
            const type = ['Estimator', 'Builder', 'InternalAB'].indexOf(role) !== -1
              ? 'building'
              : role === 'Restorer'
              ? 'restoration'
              : ''
            const payload = store.getRootField('claimInitialCall')
            const success = payload.getValue('success')
            if(success && type) {
              const result = payload.getLinkedRecord('result')
              const newProgress = result.getLinkedRecord('progress')
              const newAppoint = newProgress.getValue('initialCallMade')

              const storeRoot = store.getRoot()

              const claimJob = storeRoot.getLinkedRecord('claimJob(where:{"id":'+variables.where.id+'})')
              const portfolio = claimJob.getLinkedRecord(type)
              const progress = portfolio.getLinkedRecord('progress')

              progress.setValue(newAppoint, 'initialCallMade')
            }
          }
        },
        onCompleted: ({claimInitialCall}) => {
          const { messages, success } = claimInitialCall
          resolve({ok: true, messages, success})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}