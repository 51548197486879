/**
 * @flow
 * @relayHash 460de265e2753f4a69daa0f74cb813be
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type MakeSafeResetMutationVariables = {|
  where: ENDataPortfolioKey
|};
export type MakeSafeResetMutationResponse = {|
  +jobMakeSafeQuoteReset: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +completed: boolean
    |},
  |}
|};
export type MakeSafeResetMutation = {|
  variables: MakeSafeResetMutationVariables,
  response: MakeSafeResetMutationResponse,
|};
*/


/*
mutation MakeSafeResetMutation(
  $where: ENDataPortfolioKey!
) {
  jobMakeSafeQuoteReset(where: $where) {
    messages
    success
    result {
      completed
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completed",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MakeSafeResetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobMakeSafeQuoteReset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobMakeSafePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobMakeSafe",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MakeSafeResetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobMakeSafeQuoteReset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobMakeSafePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobMakeSafe",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "MakeSafeResetMutation",
    "id": null,
    "text": "mutation MakeSafeResetMutation(\n  $where: ENDataPortfolioKey!\n) {\n  jobMakeSafeQuoteReset(where: $where) {\n    messages\n    success\n    result {\n      completed\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'af187c2339bd0b2973cfa30d2ac165df';
module.exports = node;
