import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import Paper from 'components/Mui/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

const AdditionalDataCard = ({ data, classes }) => {
  return (
    <Paper
      title={data.categoryName}
      content={(
        <div className={classes.tableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Content</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map(({ id, title, content }) => (
                <TableRow hover key={id}>
                  <TableCell>{title}</TableCell>
                  <TableCell>{content}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    />
  )
}

export default withStyles(theme => ({
  tableWrapper: {
    maxHeight: 150,
    overflow: 'auto',
  }
}))(AdditionalDataCard)