/**
 * @flow
 * @relayHash 3e3b69a8f86508378a8350984b996dfd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ENDataEntityKey = {|
  id: string
|};
export type FinaliseClaimActionQueryVariables = {|
  claimJobWhere: ENDataEntityKey
|};
export type FinaliseClaimActionQueryResponse = {|
  +claimFilterOptions: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +claimJob: ?{|
    +hasBuilding: boolean,
    +hasContents: boolean,
    +hasRestoration: boolean,
    +building: ?{|
      +claimStatus: ?{|
        +statusName: ?string
      |},
      +finaliseReason: ?{|
        +name: string
      |},
      +dateCompleted: ?any,
    |},
    +restoration: ?{|
      +claimStatus: ?{|
        +statusName: ?string
      |},
      +finaliseReason: ?{|
        +name: string
      |},
      +dateCompleted: ?any,
    |},
    +contents: ?{|
      +claimStatus: ?{|
        +statusName: ?string
      |},
      +finaliseReason: ?{|
        +name: string
      |},
      +dateCompleted: ?any,
    |},
  |},
|};
export type FinaliseClaimActionQuery = {|
  variables: FinaliseClaimActionQueryVariables,
  response: FinaliseClaimActionQueryResponse,
|};
*/


/*
query FinaliseClaimActionQuery(
  $claimJobWhere: ENDataEntityKey!
) {
  claimFilterOptions(where: {subject: "finalisedReasons"}) {
    label: name
    value
    id
  }
  claimJob(where: $claimJobWhere) {
    hasBuilding
    hasContents
    hasRestoration
    building {
      claimStatus {
        statusName
        id
      }
      finaliseReason {
        name
        id
      }
      dateCompleted
      id
    }
    restoration {
      claimStatus {
        statusName
        id
      }
      finaliseReason {
        name
        id
      }
      dateCompleted
      id
    }
    contents {
      claimStatus {
        statusName
        id
      }
      finaliseReason {
        name
        id
      }
      dateCompleted
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "claimJobWhere",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "subject": "finalisedReasons"
    },
    "type": "ClaimJobFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "claimJobWhere",
    "type": "ENDataEntityKey!"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasBuilding",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasContents",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasRestoration",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dateCompleted",
  "args": null,
  "storageKey": null
},
v11 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimStatus",
    "storageKey": null,
    "args": null,
    "concreteType": "ClaimStatus",
    "plural": false,
    "selections": [
      (v8/*: any*/)
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "finaliseReason",
    "storageKey": null,
    "args": null,
    "concreteType": "ClaimFinaliseReason",
    "plural": false,
    "selections": [
      (v9/*: any*/)
    ]
  },
  (v10/*: any*/)
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v13 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimStatus",
    "storageKey": null,
    "args": null,
    "concreteType": "ClaimStatus",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      (v12/*: any*/)
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "finaliseReason",
    "storageKey": null,
    "args": null,
    "concreteType": "ClaimFinaliseReason",
    "plural": false,
    "selections": [
      (v9/*: any*/),
      (v12/*: any*/)
    ]
  },
  (v10/*: any*/),
  (v12/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FinaliseClaimActionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimFilterOptions",
        "storageKey": "claimFilterOptions(where:{\"subject\":\"finalisedReasons\"})",
        "args": (v1/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "building",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v11/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "restoration",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v11/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contents",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v11/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FinaliseClaimActionQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimFilterOptions",
        "storageKey": "claimFilterOptions(where:{\"subject\":\"finalisedReasons\"})",
        "args": (v1/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v12/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "building",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "restoration",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contents",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v13/*: any*/)
          },
          (v12/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FinaliseClaimActionQuery",
    "id": null,
    "text": "query FinaliseClaimActionQuery(\n  $claimJobWhere: ENDataEntityKey!\n) {\n  claimFilterOptions(where: {subject: \"finalisedReasons\"}) {\n    label: name\n    value\n    id\n  }\n  claimJob(where: $claimJobWhere) {\n    hasBuilding\n    hasContents\n    hasRestoration\n    building {\n      claimStatus {\n        statusName\n        id\n      }\n      finaliseReason {\n        name\n        id\n      }\n      dateCompleted\n      id\n    }\n    restoration {\n      claimStatus {\n        statusName\n        id\n      }\n      finaliseReason {\n        name\n        id\n      }\n      dateCompleted\n      id\n    }\n    contents {\n      claimStatus {\n        statusName\n        id\n      }\n      finaliseReason {\n        name\n        id\n      }\n      dateCompleted\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '827347bad54d492158150cb9f0a59f9d';
module.exports = node;
