import { graphql } from 'relay-runtime'

export default graphql`
  mutation DocPhotoUploadMutation(
    $input: ClaimDocumentCreate!
  ) {
    createClaimDocument(input: $input) {
      success
    }
  }
`