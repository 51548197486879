import React, { Component } from "react";
import Grid from "components/Mui/Grid";

import VariationDetailsInfo from "./VariationDetailsInfo";
import VariationDetailsList from "./VariationDetailsList";
import VariationDetailsActions from "./VariationDetailsActions";

class VariationDetailsPresentation extends Component {
  render() {
    const {
      meta,
      info,
      portfolio,
      items,
      completed,
      actionData,
      handleRefresh,
      scopeTypeId,
      variation,
      ScopeTypeOptions,
      transactionReasons,
      setUpdate,
    } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <VariationDetailsActions
            portfolio={portfolio}
            ScopeTypeOptions={ScopeTypeOptions}
            variation={variation}
            meta={meta}
            data={actionData}
            handleRefresh={handleRefresh}
            items={items}
            scopeTypeId={scopeTypeId}
            transactionReasons={transactionReasons}
            completed={completed}
            setUpdate={setUpdate}
          />
        </Grid>
        <Grid item xs={12}>
          <VariationDetailsInfo info={info} />
        </Grid>
        <Grid item xs={12}>
          <VariationDetailsList
            meta={meta}
            variation={variation}
            portfolio={portfolio}
            completed={completed}
            handleRefresh={handleRefresh}
          />
        </Grid>
        {/* <Grid item xs={12}> */}
        {/*   <VariationDetailsReport report={report} /> */}
        {/* </Grid> */}
      </Grid>
    );
  }
}

export default VariationDetailsPresentation;
