import React, { Component } from 'react'
import { createFragmentContainer, QueryRenderer, graphql } from 'react-relay'
import environment from 'common/relay'
import moment from 'moment'
import { SingleReactSelect } from 'components/Formik/ReactSelect'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'

import InfoBox from 'components/Mui/InfoBox/'

import DateInput from 'components/Formik/DateInput'
import Input from 'components/Formik/Input/'
import Switch from 'components/Formik/Switch/'
import MoneyInput from 'components/Formik/MoneyInput/'

const query = graphql`
  query ClaimDetailsCardQuery(
    $eventTypeWhere: ClaimJobFilter,
    $catCodesWhere: ClaimJobFilter,
    $policyTypesWhere: ClaimJobFilter,
    $policyCoversWhere: ClaimJobFilter,
    $managersWhere: ClaimJobFilter,
    $internalAssessorsWhere: ClaimJobFilter,
    $assessorTypeVisitsWhere: ClaimJobFilter,
    $adjustersWhere: ClaimJobFilter,
    $agentWhere: ClaimJobFilter,
    $isFetched: Boolean!,
  ) {
    eventTypes: claimFilterOptions(where: $eventTypeWhere) @include(if: $isFetched) {
      label: name
      value
    }

    catCodes: claimFilterOptions(where: $catCodesWhere) @include(if: $isFetched) {
      label: name
      value
    }

    policyTypes: claimFilterOptions(where: $policyTypesWhere) @include(if: $isFetched) {
      label: name
      value
    }

    policyCovers: claimFilterOptions(where: $policyCoversWhere) @include(if: $isFetched) {
      label: name
      value
    }

    managers: claimFilterOptions(where: $managersWhere) @include(if: $isFetched) {
      label: name
      value
    }

    internalAssessors: claimFilterOptions(where: $internalAssessorsWhere) @include(if: $isFetched) {
      label: name
      value
    }

    assessorTypeVisits: claimFilterOptions(where: $assessorTypeVisitsWhere) @include(if: $isFetched) {
      label: name
      value
    }

    adjusters: claimFilterOptions(where: $adjustersWhere) @include(if: $isFetched) {
      label: name
      value
    }

    agent: claimFilterOptions(where: $agentWhere) @include(if: $isFetched) {
      label: name
      value
    }
  }
`

class ClaimDetailsCard extends Component {
  getOptions = (options, current) => {
    if(!current || current === null || current === undefined) return options

    return options
      .map(option => option.value)
      .indexOf(current.value.toString()) === -1
        ? [
          ...options, current
        ]
        : options
  }

  render() {
    const { user, claim } = this.props
    const { isAdmin, isBuilder, isRestorer, isContent } = user.type
    const vehicleActions = window[Symbol.for('__vehicleActions')]

    return (
      <Paper
        title={'Claim Details'}
        content={(
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              eventTypeWhere: { subject: 'eventTypes', insurers: claim.insurer.companyId },
              catCodesWhere: { subject: 'catCodes', insurers: claim.insurer.companyId },
              policyTypesWhere: { subject: 'policyTypes', insurers: claim.insurer.companyId },
              policyCoversWhere: { subject: 'policyCovers', insurers: claim.insurer.companyId },
              managersWhere: { subject: 'managers', insurers: claim.insurer.companyId },
              internalAssessorsWhere: { subject: 'internalAssessors', insurers: claim.insurer.companyId },
              assessorTypeVisitsWhere: { subject: 'assessorTypeVisits', insurers: claim.insurer.companyId },
              adjustersWhere: { subject: 'adjusters', insurers: claim.insurer.companyId },
              agentWhere: { subject: 'agents', insurers: claim.insurer.companyId },
              isFetched: isAdmin
            }}
            render={({ error, props }) => {
              if(error) return null
              if(!props) return null

              return (
                <>
                  <Grid fluid spacing={0}>
                    <Grid item xs={6}>
                      <Grid fluid >
                        {
                          [
                            {
                              col: 6, component: InfoBox,
                              label: 'Insurance Company',
                              text: claim.insurer.companyName,
                            },
                            {
                              col: 6, component: DateInput,
                              label: 'Incident Date',
                              name: 'incidentDate',
                              required: true,
                              maxDate: moment()
                              // disableFuture: true,
                            },
                            {
                              col: 6, component: Input, leadIcon: 'link',
                              label: 'Insurance Ref #',
                              name: 'refNumber',
                              required: true,
                            },
                            {
                              col: 6, component: Input, leadIcon: 'link',
                              label: 'Contents Ref#' /* label:'Content ref#'|'Contents Ref#' */,
                              name: 'contentsRefNum',
                              unMountOn: (!claim.insurer.contentsref || !claim.hasContents)
                            },
                            {
                              col: 6, component: InfoBox, label: 'FNOL',
                              text: claim.lodgeDate
                            },
                            {
                              col: 6, component: Switch,
                              label: 'Scope & Repair (Quick Repair)' /* label:'Quick Repair?'|'Scope & Repair (Quick Repair)' */,
                              name: 'hold',
                              unMountOn: !claim.insurer.quickrepair
                            },

                            {
                              col: 6, component: SingleReactSelect, leadIcon: 'event',
                              label: claim.view.fields.eventType.label,
                              name: 'eventTypeId',
                              required: true, select: true,
                              options: isAdmin?this.getOptions(
                                props.eventTypes,
                                {
                                  label: claim.incidentDetail.eventType.eventName,
                                  value: claim.incidentDetail.eventType.eventTypeId
                                }
                              ):[{label:claim.incidentDetail.eventType.eventName, value:claim.incidentDetail.eventType.eventTypeId}]

                            },
                            {
                              col: 6, component: SingleReactSelect, leadIcon: 'whatshot',
                              label: claim.view.fields.catCode.label,
                              name: 'catCodeId',
                              required: true, select: true,
                              options: isAdmin?this.getOptions(
                                props.catCodes,
                                claim.incidentDetail.cATCode &&
                                  { label:
                                    claim.incidentDetail.cATCode.cATCodeName,
                                    value: String(claim.incidentDetail.cATCode.cATCodeId)
                                  }
                              ):[{label:claim.incidentDetail.cATCode && claim.incidentDetail.cATCode.cATCodeName,
                                value:claim.incidentDetail.cATCode && claim.incidentDetail.cATCode.cATCodeId}]

                            },
                            {
                              col: 6, component: SingleReactSelect, leadIcon: 'assignment_turned_in',
                              label: claim.view.fields.policyType.label /* label:'Policy Type'|'Distributor' */,
                              name: 'policyTypeId',
                              required: true, select: true,
                              options: isAdmin?                             this.getOptions(props.policyTypes,
                                claim.policyType && {
                                  label: claim.policyType.policyTypeName,
                                  value: String(claim.policyType.policyTypeId)
                                }
                              ):[{label:claim.policyType && claim.policyType.policyTypeName,
                                value:claim.policyType && claim.policyType.policyTypeId}],

                              unMountOn: !claim.insurer.policyTypeSuppliersView
                            },
                            {
                              col: 6, component: SingleReactSelect, leadIcon: 'assignment_turned_in',
                              label: claim.view.fields.policyCover.label /* label:'Policy Cover'|'PDS Reference' */,
                              name: 'policyCoverId',
                              select: true,
                              required: claim.insurer.policyCoverSuppliersView,
                              options: isAdmin? this.getOptions(props.policyCovers,
                                Boolean(claim.policyType && claim.policyCover)
                                  && {
                                    label: claim.policyCover.policyCoverName,
                                    value: String(claim.policyCover.policyCoverId)
                                  }
                              ): [{label: Boolean(claim.policyType && claim.policyCover)
                                && claim.policyCover.policyCoverName, value: Boolean(claim.policyType && claim.policyCover)
                                && claim.policyCover.policyCoverId}],

                              unMountOn: !claim.insurer.policyCoverSuppliersView
                            },
                            {
                              col: 6, component: Input, leadIcon: 'face',
                              label: 'Risk Name',
                              name: 'riskname',
                              unMountOn: !claim.insurer.enableMultipleRisks
                            },
                            {
                              col: 6, component: Input, leadIcon: 'link',
                              label: 'Additional Ref #',
                              name: 'additionalRefNumber',
                              unMountOn: !claim.insurer.additionalRefNo
                            },
                            {
                              col: 6, component: SingleReactSelect, leadIcon: 'event',
                              label: claim.view.fields.agent.label /* label:'Policy Type'|'Distributor' */,
                              name: 'agentId',
                              select: true, disabled: claim.view.fields.agent.isDisabled,
                              options: isAdmin? this.getOptions(
                                props.agent,
                                claim.agent &&
                                  { label:
                                    claim.agent.companyName,
                                    value: String(claim.agent.companyId)
                                  }
                              ): [{label: claim.agent
                                && claim.agent.companyName, value: claim.agent
                                && claim.agent.companyId}],
                              unMountOn: !claim.view.fields.agent.isDisplay
                            }
                          ].map((child, key) => {
                            const { col, component: Component, unMountOn, ...props } = child
                            return (
                              <Grid item xs={col} key={key}>
                                {Component && !unMountOn && <Component {...props}
                                  readOnly={!isAdmin}
                                />}
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid fluid >
                        {[
                          {
                            col: 6, component: SingleReactSelect, leadIcon: 'assignment_ind',
                            label: 'Case Manager',
                            name: 'casemanagerId',
                            select: true, required: true,
                            options: isAdmin? this.getOptions(props.managers,
                              claim.caseManager
                                && {
                                  label: claim.caseManager.managerName,
                                  value: String(claim.caseManager.managerId)
                                },
                            ): [{label:claim.caseManager
                              && claim.caseManager.managerName, value:claim.caseManager
                              && claim.caseManager.managerId}],
                          },
                          {
                            col: 6, component: Input, leadIcon: 'assignment_ind',
                            label: claim.view.fields.externalLossAdjustingFirm.label,
                            name: 'externalLossAdjusterId',
                            select: true, /* isUnselect: true, */
                            options: isAdmin? this.getOptions(props.adjusters,
                              claim.externalLossAdjuster
                                && {
                                  label: claim.externalLossAdjuster.companyName,
                                  value: claim.externalLossAdjuster.companyId
                                }
                            ):[{label:claim.externalLossAdjuster
                              &&claim.externalLossAdjuster.companyName, value:claim.externalLossAdjuster
                              &&claim.externalLossAdjuster.companyId}],

                          },
                          {
                            col: 6, component: InfoBox, label: 'Builder Allocated',
                            text: this.getSupplier(claim, { isAdmin, isBuilder, isRestorer, isContent }, 'building')
                          },
                          {
                            col: 6, component: InfoBox, label: 'Restorer Allocated',
                            text: this.getSupplier(claim, { isAdmin, isBuilder, isRestorer, isContent }, 'restoration')
                          },

                          {
                            col: 4, component: claim.building && claim.building.toCollectExcess !== null ? Switch : InfoBox,
                            label: vehicleActions.isVehicleReport? 'Policy Coverage':'Builder Collect Excess' /* label:'Builder collect excess'|'Builder Collect Excess' */,
                            name: 'Portfolios_Building_ToCollectExcess',
                            text: claim.building && claim.building.toCollectExcess !== null ? '' : 'N/A'
                          },
                          {
                            col: 4, component: claim.building && claim.building.excessValue !== null ? MoneyInput : Input,
                            label: 'Excess' /* label:'Building Excess'|'Excess' */,
                            /* type: 'number', */
                            name: 'Portfolios_Building_ExcessValue',
                            required: true, fixedDecimal: false, decimalScale: 0, format: '#########',
                            disabled: !(claim.building && claim.building.excessValue !== null),
                          },
                          {
                            col: 4, component: claim.buildingSumInsured !== null ? MoneyInput : Input,
                            label: 'Sum Insured',
                            name: 'Portfolios_Building_SumInsured',
                            required: true, fixedDecimal: false, decimalScale: 0, format: '#########',
                            disabled: !(claim.building && claim.building.sumInsured !== null),
                          },

                          {
                            col: 4, component: claim.contents && claim.contents.toCollectExcess !== null ? Switch : InfoBox,
                            label: vehicleActions.isVehicleReport? 'Building Policy':'Contents Provider Collect Excess' /* label:'Provider collect excess'|'Contents Provider Collect Excess' */,
                            name: 'Portfolios_Contents_ToCollectExcess',
                            text: claim.contents && claim.contents.toCollectExcess !== null ? '' : 'N/A'
                          },
                          {
                            col: 4, component: claim.contents && claim.contents.excessValue !== null ? MoneyInput : Input,
                            label: 'Excess' /* label:'Contents Excess'|'Excess' */,
                            name: 'Portfolios_Contents_ExcessValue',
                            required: true, fixedDecimal: false, decimalScale: 0, format: '#########',
                            disabled: !(claim.contents && claim.contents.excessValue !== null),
                          },
                          {
                            col: 4, component: claim.contents && claim.contents.sumInsured !== null ? MoneyInput : Input,
                            label: 'Sum Insured',
                            name: 'Portfolios_Contents_SumInsured',
                            required: true, fixedDecimal: false, decimalScale: 0, format: '#########',
                            disabled: !(claim.contents && claim.contents.sumInsured !== null),
                          },

                          {
                            col: 4, component: claim.restoration && claim.restoration.toCollectExcess !== null ? Switch : InfoBox,
                            label: vehicleActions.isVehicleReport? 'Contents Coverage':'Restorer Collect Excess' /* label:'Restorer collect Excess'|'Restorer Collect Excess' */,
                            name: 'Portfolios_Restoration_ToCollectExcess',
                            text: claim.restoration && claim.restoration.toCollectExcess !== null ? '' : 'N/A',
                          },
                          {
                            col: 4, component: claim.restoration && claim.restoration.excessValue !== null ? MoneyInput : Input,
                            label: 'Excess' /* label:'Restoration excess'|'Excess' */,
                            name: 'Portfolios_Restoration_ExcessValue',
                            required: true, fixedDecimal: false, decimalScale: 0, format: '#########',
                            disabled: !(claim.restoration && claim.restoration.excessValue !== null),
                          },
                          {
                            col: 4, component: claim.restoration && claim.restoration.sumInsured !== null ? MoneyInput : Input,
                            label: 'Sum Insured',
                            name: 'Portfolios_Restoration_SumInsured',
                            required: true, fixedDecimal: false, decimalScale: 0, format: '#########',
                            disabled: !(claim.restoration && claim.restoration.sumInsured !== null),
                          },

                          {
                            col: 6, component: SingleReactSelect, select: true, name: 'homeAssessorId',
                            label: claim.view.fields.internalAssessor.label /* label:'Internal Assessor'|'Specialist Review' */,
                            leadIcon: 'work',
                            value: claim.homeAssessor ? claim.homeAssessor.assesorId : null,
                            currentOption: claim.homeAssessor ? { label: claim.homeAssessor.assesorName, value: claim.homeAssessor.assesorId } : {},
                            options: props.internalAssessors
                            ?
                            this.getOptions(
                              props.internalAssessors,
                              claim.homeAssessor &&
                              {
                                label: claim.homeAssessor.assesorName,
                                value: claim.homeAssessor.assesorId
                              }
                            )
                            :
                            [{
                              label:claim.homeAssessor && claim.homeAssessor.assesorName,
                              value:claim.homeAssessor && claim.homeAssessor.assesorId
                            }],
                            unMountOn: !claim.insurer.useInternalAssessor
                          },
                          // {
                          //   col: 6, component: Input, select: true, name: 'visitTypeId',
                          //   label: 'Assessor Type Visit', leadIcon: 'work',
                          //   value: claim.hATypeVisit ? claim.hATypeVisit.typeVisitId : null,
                          //   currentOption: claim.hATypeVisit ? { label: claim.hATypeVisit.visitName, value: claim.hATypeVisit.typeVisitId } : {},
                          //   options: props.assessorTypeVisits
                          // },

                          {
                            col: 6, component: Input,  leadIcon: 'work',
                            label: 'BC/ BRC/ Loss Adjuster',
                            name: 'brcId',
                            select: true,
                            options: isAdmin? this.getOptions(props.managers,
                              claim.brc && {
                                label: claim.brc.managerName ,
                                value: claim.brc.managerId
                              }
                            ):[{label: claim.brc && claim.brc.managerName, value: claim.brc && claim.brc.managerId}],
                            unMountOn: !claim.insurer.cm2nd || !isAdmin,
                          }
                        ].map((child, key) => {
                          const { col, component: Component, unMountOn, ...props } = child
                          return (
                            <Grid item xs={col} key={key}>
                              {Component && !unMountOn && <Component {...props}
                                readOnly={!isAdmin}
                              />}
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )
            }}
          />
        )}
      />
    )
  }

  getSupplier = (claim, user, type) => {
    if(user.isAdmin || user.isContent) {
      if(type === 'building' && claim.building) {
        if(claim.building.authorisedSupplier) return claim.building.authorisedSupplier.companyName
        if(claim.building.scopingSupplier) return claim.building.scopingSupplier.companyName
      }
      if(type === 'restoration' && claim.restoration) {
        if(claim.restoration.authorisedSupplier) return claim.restoration.authorisedSupplier.companyName
        if(claim.restoration.scopingSupplier) return claim.restoration.scopingSupplier.companyName
      }
    }
    if(user.isBuilder) {
      if(type === 'building' && claim.building && claim.building.jobSuppliers[0].quote) return claim.building.jobSuppliers[0].quote.supplier.companyName
      if(type === 'restoration' && claim.restoration) {
        if(claim.restoration.authorisedSupplier) return claim.restoration.authorisedSupplier.companyName
        if(claim.restoration.scopingSupplier) return claim.restoration.scopingSupplier.companyName
      }
    }
    if(user.isRestorer) {
      if(type === 'building' && claim.building) {
        if(claim.building.authorisedSupplier) return claim.building.authorisedSupplier.companyName
        if(claim.building.scopingSupplier) return claim.building.scopingSupplier.companyName
      }
      if(type === 'restoration' && claim.restoration && claim.restoration.jobSuppliers[0].quote) return claim.restoration.jobSuppliers[0].quote.supplier.companyName
    }

    return 'N/A'
  }
}

export default createFragmentContainer(ClaimDetailsCard, graphql`
  fragment ClaimDetailsCard_claim on ClaimJob {
    riskname
    hasContents
    lodgeDate
    contentsRefNum
    additionalRefNumber
    buildingSumInsured
    agent {
      id
      companyName
      companyId
    }
    view {
      fields {
         catCode {
          label
        }
        eventType {
          label
        }
        internalAssessor {
          label
        }
        externalLossAdjustingFirm {
          label
        }
        policyType {
          label
        }
        policyCover {
          label
        }
        agent {
          isDisplay
          label
          isDisabled
          name
        }
      }
    }
    insurer {
      companyId

      contentsref
      companyName
      policy
      enableMultipleRisks
      additionalRefNo
      cm2nd
      policyTypeSuppliersView
      policyCoverSuppliersView
      quickrepair
      useInternalAssessor
    }

    # here
    caseManager {
      managerName
      managerId
    }
    externalLossAdjuster {
      companyName
      companyId
    }
    incidentDetail {
      eventType {
        eventName
        eventTypeId
      }
      cATCode {
        cATCodeName
        cATCodeId
      }
    }
    policyType {
      policyTypeName
      policyTypeId
    }
    policyCover {
      policyCoverName
      policyCoverId
    }
    brc {
      managerName
      managerId
    }
    #here


    refNumber
    incidentDetail { eventType { eventTypeId }}
    caseManager { managerName managerId }
    externalLossAdjuster { companyName companyId }
    building {
      authorisedSupplier { companyName }
      scopingSupplier { companyName }
      jobSuppliers { quote { supplier { companyName } } }
    }
    restoration {
      authorisedSupplier { companyName }
      scopingSupplier { companyName }
      jobSuppliers { quote { supplier { companyName } } }
    }
    contents {
      authorisedSupplier { companyName }
      scopingSupplier { companyName }
      jobSuppliers { quote { supplier { companyName } } }
    }


    hATypeVisit { visitName typeVisitId }
    homeAssessor { assesorName assesorId }
  }
`)
