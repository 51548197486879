import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import jwt from 'jsonwebtoken'

const mutation = graphql`
  mutation ChangeAppointmentMutation($input: AppointmentInput!, $where: ENDataEntityKey!) {
    claimMakeAppointment(input: $input, where: $where) {
      success
      messages
      result {
        progress {
          appointmentBooked
        }
        claimStatus {
          statusId
        }
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        updater: store => {
          const token = localStorage.getItem('TOKEN')
          if(token) {
            const decoded = jwt.decode(token)
            const role = decoded.role
            const type = ['Estimator', 'Builder', 'InternalAB'].indexOf(role) !== -1
              ? 'building'
              : role === 'Restorer'
              ? 'restoration'
              : ''
            const payload = store.getRootField('claimMakeAppointment')
            const success = payload.getValue('success')
            if(success && type) {
              const result = payload.getLinkedRecord('result')
              const newProgress = result.getLinkedRecord('progress')
              const newAppoint = newProgress.getValue('appointmentBooked')
              const newStatus = result.getLinkedRecord('claimStatus')
              const newStatusId = newStatus.getValue('statusId')

              const storeRoot = store.getRoot()

              const claimJob = storeRoot.getLinkedRecord('claimJob(where:{"id":'+variables.where.id+'})')
              const portfolio = claimJob.getLinkedRecord(type)
              const progress = portfolio.getLinkedRecord('progress')
              const status = portfolio.getLinkedRecord('claimStatus')

              progress.setValue(newAppoint, 'appointmentBooked')
              status.setValue(newStatusId, 'statusId')
            }
          }
        },
        onCompleted: (res) => {
          const { messages, success } = res.claimMakeAppointment

          resolve({ ok: true, success, messages })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}