/**
 * @flow
 * @relayHash f2a4971d97f801371fc251945652d52c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ReallocateScopingSupplierInput = {|
  supplierId: number,
  availableForQuoting: boolean,
  note?: ?string,
|};
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type AllocateBuilderActionMutationVariables = {|
  input: ReallocateScopingSupplierInput,
  where: ENDataPortfolioKey,
|};
export type AllocateBuilderActionMutationResponse = {|
  +claimReallocateScopingSupplier: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +reallocate: boolean,
      +scopingSupplier: ?{|
        +companyName: string
      |},
    |},
  |}
|};
export type AllocateBuilderActionMutation = {|
  variables: AllocateBuilderActionMutationVariables,
  response: AllocateBuilderActionMutationResponse,
|};
*/


/*
mutation AllocateBuilderActionMutation(
  $input: ReallocateScopingSupplierInput!
  $where: ENDataPortfolioKey!
) {
  claimReallocateScopingSupplier(input: $input, where: $where) {
    success
    messages
    result {
      reallocate
      scopingSupplier {
        companyName
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReallocateScopingSupplierInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ReallocateScopingSupplierInput!"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reallocate",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AllocateBuilderActionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimReallocateScopingSupplier",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "scopingSupplier",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllocateBuilderActionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimReallocateScopingSupplier",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "scopingSupplier",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AllocateBuilderActionMutation",
    "id": null,
    "text": "mutation AllocateBuilderActionMutation(\n  $input: ReallocateScopingSupplierInput!\n  $where: ENDataPortfolioKey!\n) {\n  claimReallocateScopingSupplier(input: $input, where: $where) {\n    success\n    messages\n    result {\n      reallocate\n      scopingSupplier {\n        companyName\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e0ea7dfa9014f86c04585500bb0c7d19';
module.exports = node;
