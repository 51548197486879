/**
 * @flow
 * @relayHash 2f8418f189e8fc0dc2e5f2cade956c25
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobVariationItemsCreate = {|
  variationId: number,
  portfolioType: PortfolioType,
  items?: ?$ReadOnlyArray<?JobVariationItemCreate>,
|};
export type JobVariationItemCreate = {|
  costCentreId: number,
  costItemId: number,
  lineDescription: string,
  locationId: number,
  scopeTypeId?: ?string,
  propertyTypeId?: ?string,
  propertyNumber?: ?string,
  dimensions?: ?string,
  qty: number,
  unitId: number,
  marginRate: number,
  gst?: ?number,
  rate: number,
|};
export type VariationAddLineItemMutationVariables = {|
  input: JobVariationItemsCreate
|};
export type VariationAddLineItemMutationResponse = {|
  +createJobVariationItems: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?$ReadOnlyArray<?{|
      +id: string,
      +variationId: number,
      +variationItemId: number,
      +location: ?{|
        +costLocationId: number,
        +locationName: string,
      |},
      +dimensions: ?string,
      +costCentre: ?{|
        +costCentreName: string,
        +costCentreId: number,
      |},
      +costItem: ?{|
        +itemDescription: string,
        +costItemId: number,
      |},
      +lineDescription: string,
      +unit: ?{|
        +unitName: string,
        +unitId: number,
      |},
      +qty: number,
      +rate: ?number,
      +marginRate: number,
      +subtotal: ?number,
    |}>,
  |}
|};
export type VariationAddLineItemMutation = {|
  variables: VariationAddLineItemMutationVariables,
  response: VariationAddLineItemMutationResponse,
|};
*/


/*
mutation VariationAddLineItemMutation(
  $input: JobVariationItemsCreate!
) {
  createJobVariationItems(input: $input) {
    messages
    success
    result {
      id
      variationId
      variationItemId
      location {
        costLocationId
        locationName
        id
      }
      dimensions
      costCentre {
        costCentreName
        costCentreId
        id
      }
      costItem {
        itemDescription
        costItemId
        id
      }
      lineDescription
      unit {
        unitName
        unitId
      }
      qty
      rate
      marginRate
      subtotal
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobVariationItemsCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "JobVariationItemsCreate!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationItemId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costLocationId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "locationName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimensions",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreId",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemDescription",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costItemId",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lineDescription",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "unit",
  "storageKey": null,
  "args": null,
  "concreteType": "QuoteItemUnit",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "unitName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "unitId",
      "args": null,
      "storageKey": null
    }
  ]
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "qty",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rate",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "marginRate",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VariationAddLineItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobVariationItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobVariationItemsPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariationItem",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "location",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              },
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costCentre",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costItem",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostItem",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/)
                ]
              },
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VariationAddLineItemMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobVariationItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobVariationItemsPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariationItem",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "location",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costCentre",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v4/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costItem",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostItem",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "VariationAddLineItemMutation",
    "id": null,
    "text": "mutation VariationAddLineItemMutation(\n  $input: JobVariationItemsCreate!\n) {\n  createJobVariationItems(input: $input) {\n    messages\n    success\n    result {\n      id\n      variationId\n      variationItemId\n      location {\n        costLocationId\n        locationName\n        id\n      }\n      dimensions\n      costCentre {\n        costCentreName\n        costCentreId\n        id\n      }\n      costItem {\n        itemDescription\n        costItemId\n        id\n      }\n      lineDescription\n      unit {\n        unitName\n        unitId\n      }\n      qty\n      rate\n      marginRate\n      subtotal\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9abd38408d5e4feca645518d28f57952';
module.exports = node;
