/**
 * @flow
 * @relayHash b3706d84faf49ec05ab36155bf59b0ea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type JobQuoteRemoveAuthorityInput = {|
  reason: string
|};
export type QuoteRemoveAuthorityMutationVariables = {|
  where: ENDataPortfolioKey,
  input: JobQuoteRemoveAuthorityInput,
|};
export type QuoteRemoveAuthorityMutationResponse = {|
  +jobQuoteRemoveAuthority: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type QuoteRemoveAuthorityMutation = {|
  variables: QuoteRemoveAuthorityMutationVariables,
  response: QuoteRemoveAuthorityMutationResponse,
|};
*/


/*
mutation QuoteRemoveAuthorityMutation(
  $where: ENDataPortfolioKey!
  $input: JobQuoteRemoveAuthorityInput!
) {
  jobQuoteRemoveAuthority(where: $where, input: $input) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobQuoteRemoveAuthorityInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "jobQuoteRemoveAuthority",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "JobQuoteRemoveAuthorityInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ENDataPortfolioKey!"
      }
    ],
    "concreteType": "JobQuotePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "QuoteRemoveAuthorityMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "QuoteRemoveAuthorityMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "QuoteRemoveAuthorityMutation",
    "id": null,
    "text": "mutation QuoteRemoveAuthorityMutation(\n  $where: ENDataPortfolioKey!\n  $input: JobQuoteRemoveAuthorityInput!\n) {\n  jobQuoteRemoveAuthority(where: $where, input: $input) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7a3f0f12e1c5c53068a53d17807305e2';
module.exports = node;
