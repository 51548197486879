import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation AcceptOfferMutation($where: ENDataPortfolioKey!) {
    claimAcceptRequest(where: $where){
      messages
      success
      result {
        jobSuppliers {
          quote {
            id
            quoteStatus: quoteJobStatus { statusName }
            supplier { companyName }
          }
        }
      }
    }
  }
`

// supplier { companyName }

export default ({ acceptOfferId, acceptOfferPortfolio, claimId }) => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables: {
          where: {
            id: acceptOfferId,
            portfolioType: acceptOfferPortfolio
          }
        },
        updater: store => {
          const payload = store.getRootField('claimAcceptRequest')
          const success = payload.getValue('success')
          if(success) {
            const result = payload.getLinkedRecord('result')
            const newJobSupplier = result.getLinkedRecords('jobSuppliers')[0]
            const newQuote = newJobSupplier.getLinkedRecord('quote')

            const storeRoot = store.getRoot()

            // console.log(newQuote)
            // console.log(storeRoot)

            const claimJob = storeRoot.getLinkedRecord('claimJob(where:{"id":'+claimId+'})')
            const portfolio = claimJob.getLinkedRecord(acceptOfferPortfolio.toLowerCase())
            const jobSupplier = portfolio.getLinkedRecords('jobSuppliers')[0]
            
            jobSupplier.setLinkedRecord(newQuote, 'quote')
          }
        },
        onCompleted: ({claimAcceptRequest}) => {
          const { messages, success } = claimAcceptRequest
          resolve({ok: true, messages, success})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}