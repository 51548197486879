/**
 * @flow
 * @relayHash eeed20c020776e0bd56383b91630261d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeeItemUniqueWhere = {|
  feeItemId: number
|};
export type FeeItemDeleteMutationVariables = {|
  where: FeeItemUniqueWhere
|};
export type FeeItemDeleteMutationResponse = {|
  +feeItemDelete: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type FeeItemDeleteMutation = {|
  variables: FeeItemDeleteMutationVariables,
  response: FeeItemDeleteMutationResponse,
|};
*/


/*
mutation FeeItemDeleteMutation(
  $where: FeeItemUniqueWhere!
) {
  feeItemDelete(where: $where) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "FeeItemUniqueWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "feeItemDelete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "FeeItemUniqueWhere!"
      }
    ],
    "concreteType": "FeeItemPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeItemDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeItemDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "FeeItemDeleteMutation",
    "id": null,
    "text": "mutation FeeItemDeleteMutation(\n  $where: FeeItemUniqueWhere!\n) {\n  feeItemDelete(where: $where) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8b00a1697d153411e95628123cc0caa';
module.exports = node;
