import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Icon from '@material-ui/core/Icon'
import IconButton from 'components/Mui/IconButton'
import jwt from 'jsonwebtoken'
import LogoutMutation from './LogoutMutation'

import { cache } from 'common/relayMiddlewares'
import { getSafe } from 'common/utils'

class MenuButton extends Component {
  state = {
    anchorEl: null
  }
  render() {
    const { REACT_APP_PORTAL_URL } = process.env
    const { classes, icon, isShowModernPortal } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    return (
      <Fragment>
        {
          REACT_APP_PORTAL_URL &&
          (<iframe
            src={`${REACT_APP_PORTAL_URL}/externallogin`}
            id="portal"
            width={0}
            height={0}
            title="classicPortal"
            style={{ visibility: 'hidden' }}
          />)
        }
        <IconButton
          icon={icon}
          className={classes.iconButton}
          iconClass={classes.icon}
          onClick={this.handleClick}
          // badgeContent={52} badgeMax={9} badgeColor='error'
        />

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          disableAutoFocusItem
          className={classes.menu}
        >
          <List
            component='nav'
            subheader={
              <ListSubheader className={classes.subheader} component='div'>
                Welcome, {getSafe(() => jwt.decode(localStorage.TOKEN).unique_name, '')}
              </ListSubheader>
            }
            className={classes.list}
          >
            <ListItem button onClick={this.settingHandle}>
              <ListItemIcon>
                <Icon>settings</Icon>
              </ListItemIcon>
              <ListItemText primary='Setting'/>
            </ListItem>
            {
              isShowModernPortal &&
                <ListItem button onClick={this.toModern}>
                  <ListItemIcon>
                    <Icon>directions_run</Icon>
                  </ListItemIcon>
                  <ListItemText primary='To Modern'/>
                </ListItem>
            }
            <ListItem button onClick={this.logoutHandle}>
              <ListItemIcon>
                <Icon>power_settings_new</Icon>
              </ListItemIcon>
              <ListItemText primary='Logout'/>
            </ListItem>
          </List>
        </Menu>
      </Fragment>
    )
  }

  settingHandle = e => {
    this.handleClose()
    this.props.history.push('/settings')
  }

  logoutHandle = async e => {
    this.handleClose()

    await LogoutMutation()

    //Clear all storage data and cache when logout
    const redirectUrl = localStorage.REDIRECT_URL

    cache.clear()
    localStorage.clear()
    if(process.env.REACT_APP_DEV !== 'dev') {
      setTimeout(() => window.location.href = redirectUrl)
    } else {
      this.props.history.push('/user/login')
    }
  }

  toModern = () => {
    const { REACT_APP_PORTAL_URL } = process.env
      const { location } = this.props
      const currentPath = location.pathname
      const currentQuery = location.search
      const portal = document.getElementById('portal')
      const refreshToken = localStorage.getItem('REFRESH_TOKEN')
      const token = localStorage.getItem('TOKEN')
      const data = JSON.stringify({ token, refreshToken })
      //@ts-ignore
      portal.contentWindow.postMessage(data, '*')
      const [, page, tab] = currentPath.split('/')
      const [claimString, portfolio, portfolioFilter] = currentQuery.split('&').map(e => e.split('=')[1])
      const claimId = window.btoa(`ClaimJob:${claimString}`)
      const currentPortfolio = tab && Boolean(tab !== 'make-safe' &&　tab !== 'site-report' &&　tab !== 'scope' &&　tab !== 'quotes') ? portfolioFilter : portfolio
      const currentPortfolioUpperCase = currentPortfolio && currentPortfolio.charAt(0).toUpperCase() + currentPortfolio.slice(1)
      window.setTimeout(() => {
        window.open(`${REACT_APP_PORTAL_URL}/${page}${tab ? `/${claimId}/${tab === 'fees' ? 'invoices': tab}`: ''}${currentPortfolio ? `?portfolio=${currentPortfolioUpperCase}` : ''}`)
      }, 1000)
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }
}

export default withRouter(withStyles(theme => ({
  list: {
    maxWidth: '350px',
    paddingBottom: 0,
  },
  subheader: {
    borderBottom: '2px grey solid'
  },
  iconButton: {
    height: '100%',
    // padding: theme.spacing.unit * 1.5
  },
  icon: {
    height: '100%',
    color: theme.palette.primary.contrastText,
    fontSize: '28px',
  },
  menu: {
    '& div[role="document"]': {
      top: '50px !important'
    }
  }
}))(MenuButton))
