import React, { Component } from "react";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { contextToMarkup, markupToContext, mapUserType } from "common/utils";
import {
  nameRegexp,
  emailRegexp,
  refNumberRegexp,
  addressRegexp,
  suburbRegexp,
  clientNameRegexp,
} from "common/inputRegex.js";

import Grid from "components/Mui/Grid";
import { Formik, Form } from "formik";

import AddClaimNewTemplate from "./AddClaimPages/AddClaimNewTemplate";
import AddClaimChooseCompanyCard from "./AddClaimPages/AddClaimChooseCompanyCard";
import AddClaimClientInfoCard from "./AddClaimPages/AddClaimClientInfoCard";
import AddClaimDetailsCard from "./AddClaimPages/AddClaimDetailsCard";
import AddClaimQuotingBuildersCard from "./AddClaimPages/AddClaimQuotingBuildersCard";
import AddClaimQuotingRestorersCard from "./AddClaimPages/AddClaimQuotingRestorersCard";
import AddDescriptionCard from "./AddClaimPages/AddDescriptionCard";

import AddClaimMutation from "./AddClaimMutation";
import AddClaimByTemplateMutation from "./AddClaimByTemplateMutation";

const query = graphql`
  query AddClaimPagesQuery(
    $companyWhere: ENDataPortfolioKey!
    $hasInsurerId: Boolean!
    $managersWhere: ClaimJobFilter
    $eventTypeWhere: ClaimJobFilter
    $catCodesWhere: ClaimJobFilter
    $adjustersWhere: ClaimJobFilter
    $policyTypesWhere: ClaimJobFilter
    $policyCoversWhere: ClaimJobFilter
    $assessorTypeVisitsWhere: ClaimJobFilter
    $internalAssessorsWhere: ClaimJobFilter
    $buildersWhere: ClaimJobFilter
    $restorersWhere: ClaimJobFilter
    $agentWhere: ClaimJobFilter
  ) {
    ...AddClaimDetailsCard_details
    ...AddClaimQuotingBuildersCard_details
    ...AddClaimQuotingRestorersCard_details
    company(where: $companyWhere) @include(if: $hasInsurerId) {
      ...AddClaimClientInfoCard_company
      ...AddClaimNewTemplate_company
      quotesperclaim
      companyName
      quickrepair
      contentsref
      isToProvideSiteReportView
      policyTypeSuppliersView
      policyCoverSuppliersView
      enableMultipleRisks
      additionalRefNo
      cm2nd
      insrefnumLength
      useInternalAssessor

      isToProvideSiteReportView
      claimJobFields {
        defaultLodgeTemplateId
        eventType {
          label
        }
        catCode {
          label
        }
        externalLossAdjustingFirm {
          label
        }
        internalAssessor {
          label
        }
        policyCover {
          label
        }
        policyType {
          label
        }
        agent {
          isDisplay
          label
          isDisabled
        }
        vulnerableMember {
          isDisplay
          label
          isDisabled
        }
      }
    }
    user: me {
      type: userRole
    }
    claimFilterOptions(where: { subject: "insurers" }) {
      label: name
      value
    }
    catCodes: claimFilterOptions(where: $catCodesWhere) {
      label: name
      value
    }
  }
`;

class AddClaim extends Component {
  state = {
    data: {},
    submitted: false,
  };

  render() {
    const { classes } = this.props;

    const { data } = this.state;
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Grid fluid justify="center">
            <QueryRenderer
              environment={environment}
              query={query}
              variables={{
                hasInsurerId: Boolean(data.insurerId),
                companyWhere: {
                  id: data.insurerId ? data.insurerId : 0,
                  portfolioType: "Building",
                },

                adjustersWhere: {
                  subject: "adjusters",
                  insurers: data.insurerId ? data.insurerId : 0,
                }, //postcode: String(data.postcode)
                managersWhere: {
                  subject: "managers",
                  insurers: data.insurerId ? data.insurerId : 0,
                },
                buildersWhere: {
                  subject: "suppliers",
                  insurers: data.insurerId ? data.insurerId : 0,
                  portfolios: "Building",
                }, //, postcode: String(data.postcode)
                restorersWhere: {
                  subject: "suppliers",
                  insurers: data.insurerId ? data.insurerId : 0,
                  portfolios: "Restoration",
                }, //, postcode: String(data.postcode)
                eventTypeWhere: {
                  subject: "eventTypes",
                  insurers: data.insurerId ? data.insurerId : 0,
                },
                catCodesWhere: {
                  subject: "catCodes",
                  insurers: data.insurerId ? data.insurerId : 0,
                },
                internalAssessorsWhere: {
                  subject: "internalAssessors",
                  insurers: data.insurerId ? data.insurerId : 0,
                },
                assessorTypeVisitsWhere: {
                  subject: "assessorTypeVisits",
                  insurers: data.insurerId ? data.insurerId : 0,
                },
                policyTypesWhere: {
                  subject: "policyTypes",
                  insurers: data.insurerId ? data.insurerId : 0,
                },
                policyCoversWhere: {
                  subject: "policyCovers",
                  insurers: data.insurerId ? data.insurerId : 0,
                },
                agentWhere: {
                  subject: "agents",
                  insurers: data.insurerId ? data.insurerId : 0,
                },
              }}
              render={({ error, props }) => {
                if (error) return "Error...";
                if (!props) return "Loading...";
                const userType = mapUserType(props.user.type);
                return (
                  <>
                    {!data.insurerId && (
                      <Grid item xs={4}>
                        <AddClaimChooseCompanyCard
                          claimFilterOptions={props.claimFilterOptions}
                          handleInsurerChoosen={this.handleInsurerChoosen}
                        />
                      </Grid>
                    )}
                    {data.insurerId && (
                      <Grid item xs={12}>
                        <Formik
                          initialValues={{
                            insured_salutation: "",
                            insured_name: "",
                            insured_email: "",
                            incidentDetail_riskAddress_line1: "",
                            incidentDetail_riskAddress_suburb: "",
                            incidentDetail_riskAddress_state: "",
                            requireCustomLogin: false,
                            customLoginEmail: "",
                            insured_phone1: "",
                            insured_phone2: "",
                            incidentDetail_habitableProperty: true,
                            incidentDetail_asbestos: false,
                            incidentDetail_category: "",
                            portfolioType: [],
                            incidentDetail_riskAddress_postcode: "",
                            incidentDetail_catCodeId:
                              props.catCodes.length > 0 &&
                              props.catCodes[0].value,
                            Portfolios_Building_QuotingSupplierIds: [],
                            Portfolios_Restoration_QuotingSupplierIds: [],
                            incidentDetail_incidentDate: "",
                            refNumber: "",
                            contentsRefNum: "",
                            incidentDetail_hold: true,
                            incidentDetail_eventTypeId: "",
                            policyTypeId: "",
                            policyCoverId: "",
                            riskname: "",
                            additionalRefNumber: "",
                            incidentDetail_makeSafeRequired: false,
                            toProvideSiteReport: true,
                            casemanagerId: "",
                            externalLossAdjusterId: "",
                            buildingScopingSupplierId: "",
                            restorationScopingSupplierId: "",
                            Portfolios_Building_ExcessValue: "",
                            Portfolios_Building_ToCollect: false,
                            Portfolios_Building_SumInsured: "",
                            Portfolios_Contents_ExcessValue: "",
                            Portfolios_Contents_ToCollect: false,
                            Portfolios_Contents_SumInsured: "",
                            Portfolios_Restoration_ExcessValue: "",
                            Portfolios_Restoration_ToCollect: false,
                            Portfolios_Restoration_SumInsured: "",
                            homeAssessorId: "",
                            visitTypeID: "",
                            brcId: "",
                            agentId: "",
                            claimDescription: markupToContext(""),
                            vulnerableMember: false,
                          }}
                          onSubmit={(values, actions) => {
                            if (
                              props.company.claimJobFields
                                .defaultLodgeTemplateId === 1
                            ) {
                              setTimeout(async () => {
                                let submitData = {
                                  insurerId: this.state.data.insurerId,
                                  refNumber: values.refNumber,
                                  insured: {
                                    name: values.insured_name,
                                    phone1: values.insured_phone1,
                                    email: values.insured_email,
                                  },
                                  incidentDetail: {
                                    riskAddress: {
                                      line1:
                                        values.incidentDetail_riskAddress_line1,
                                      suburb:
                                        values.incidentDetail_riskAddress_suburb,
                                      state:
                                        values.incidentDetail_riskAddress_state,
                                      postcode:
                                        values.incidentDetail_riskAddress_postcode,
                                    },
                                    vulnerableMember: values.vulnerableMember,
                                  },
                                };
                                const originData = { ...submitData };
                                let submitDataInt = parseIntThis(submitData);
                                submitDataInt.insured.name = String(
                                  originData.insured.name
                                );
                                submitDataInt.insured.email = String(
                                  originData.insured.email
                                );
                                submitDataInt.refNumber = String(
                                  originData.refNumber
                                );
                                submitDataInt.insured.phone1 = String(
                                  values.insured_phone1
                                );
                                submitDataInt.incidentDetail.riskAddress.postcode =
                                  String(
                                    originData.incidentDetail.riskAddress
                                      .postcode
                                  );
                                submitDataInt.incidentDetail.riskAddress.suburb =
                                  String(
                                    originData.incidentDetail.riskAddress.suburb
                                  );
                                submitDataInt.incidentDetail.riskAddress.line1 =
                                  String(
                                    originData.incidentDetail.riskAddress.line1
                                  );
                                let submitDataNotEmpt =
                                  removeEmpty(submitDataInt);
                                const res = await AddClaimByTemplateMutation(
                                  submitDataNotEmpt,
                                  1
                                );
                                actions.setSubmitting(false);

                                if (res) {
                                  window[Symbol.for("__snackbar")].toggleOpen({
                                    message: res.messages[0],
                                  });
                                  if (res.ok) {
                                    if (res.success) {
                                      this.props.history.push(
                                        `claim/job-info?id=${res.result.claimId}`
                                      );
                                    } else {
                                      actions.setErrors(
                                        res.fieldErrors.reduce(
                                          (total, current) => {
                                            total[current.fieldName] =
                                              current.message;
                                            return total;
                                          },
                                          {}
                                        )
                                      );
                                    }
                                  }
                                }
                              }, 400);
                            } else {
                              setTimeout(async () => {
                                const Portfolios_Building_QuotingSupplierIds =
                                  values.Portfolios_Building_QuotingSupplierIds.filter(
                                    (e) => e !== undefined
                                  );
                                const Portfolios_Restoration_QuotingSupplierIds =
                                  values.Portfolios_Restoration_QuotingSupplierIds.filter(
                                    (e) => e !== undefined
                                  );
                                const buildingPortfolio = {
                                  portfolioType: "Building",
                                  toProvideSiteReport:
                                    values.toProvideSiteReport,
                                  sumInsured:
                                    values.Portfolios_Building_SumInsured,
                                  excessValue:
                                    values.Portfolios_Building_ExcessValue,
                                  toCollectExcess:
                                    values.Portfolios_Building_ToCollect,
                                  scopingSupplierId:
                                    values.buildingScopingSupplierId,
                                  quotingSupplierIds:
                                    Portfolios_Building_QuotingSupplierIds,
                                };

                                const contentsPortfolio = {
                                  portfolioType: "Contents",
                                  toProvideSiteReport:
                                    values.toProvideSiteReport,
                                  sumInsured:
                                    values.Portfolios_Contents_SumInsured,
                                  excessValue:
                                    values.Portfolios_Contents_ExcessValue,
                                  toCollectExcess:
                                    values.Portfolios_Contents_ToCollect,
                                  scopingSupplierId: 0,
                                };

                                const restorationPortfolio = {
                                  portfolioType: "Restoration",
                                  toProvideSiteReport:
                                    values.toProvideSiteReport,
                                  sumInsured:
                                    values.Portfolios_Restoration_SumInsured,
                                  excessValue:
                                    values.Portfolios_Restoration_ExcessValue,
                                  toCollectExcess:
                                    values.Portfolios_Restoration_ToCollect,
                                  scopingSupplierId:
                                    values.restorationScopingSupplierId,
                                  quotingSupplierIds:
                                    Portfolios_Restoration_QuotingSupplierIds,
                                };

                                const portfolios = [];

                                if (
                                  values.portfolioType.indexOf("Building") !==
                                  -1
                                )
                                  portfolios.push(buildingPortfolio);
                                if (
                                  values.portfolioType.indexOf("Contents") !==
                                  -1
                                )
                                  portfolios.push(contentsPortfolio);
                                if (
                                  values.portfolioType.indexOf(
                                    "Restoration"
                                  ) !== -1
                                )
                                  portfolios.push(restorationPortfolio);
                                let submitData = {
                                  insurerId: this.state.data.insurerId,
                                  claimDescription: contextToMarkup(
                                    values.claimDescription
                                  ),
                                  insured: {
                                    salutation: values.insured_salutation,
                                    name: values.insured_name,
                                    phone1: values.insured_phone1,
                                    phone2: values.insured_phone2,
                                    email: values.insured_email,
                                  },
                                  casemanagerId: values.casemanagerId,
                                  externalLossAdjusterId:
                                    values.externalLossAdjusterId,
                                  refNumber: values.refNumber,
                                  additionalRefNumber:
                                    values.additionalRefNumber,
                                  contentsRefNum: values.contentsRefNum,
                                  requireCustomLogin: values.requireCustomLogin,
                                  customLoginEmail: values.customLoginEmail,
                                  policyTypeId: values.policyTypeId,
                                  policyCoverId: values.policyCoverId,
                                  riskname: values.riskname,
                                  homeAssessorId: values.homeAssessorId,
                                  // visitTypeID: data.visitTypeID,
                                  brcId: values.brcId,
                                  agentId: values.agentId,
                                  incidentDetail: {
                                    makeSafeRequired:
                                      values.incidentDetail_makeSafeRequired,
                                    eventTypeId:
                                      values.incidentDetail_eventTypeId.toString(),
                                    catCodeId:
                                      values.incidentDetail_catCodeId.toString(),
                                    hold: values.incidentDetail_hold,
                                    incidentDate:
                                      values.incidentDetail_incidentDate.format(
                                        "DD/MM/YYYY"
                                      ),
                                    category: values.incidentDetail_category,
                                    habitableProperty:
                                      values.incidentDetail_habitableProperty,
                                    asbestos: values.incidentDetail_asbestos,
                                    riskAddress: {
                                      line1:
                                        values.incidentDetail_riskAddress_line1,
                                      suburb:
                                        values.incidentDetail_riskAddress_suburb,
                                      state:
                                        values.incidentDetail_riskAddress_state,
                                      postcode:
                                        values.incidentDetail_riskAddress_postcode,
                                    },
                                    vulnerableMember: values.vulnerableMember,
                                  },
                                  portfolios,
                                };

                                const originData = { ...submitData };

                                let submitDataInt = parseIntThis(submitData);

                                submitDataInt.insured.name = String(
                                  originData.insured.name
                                );
                                submitDataInt.insured.email = String(
                                  originData.insured.email
                                );
                                submitDataInt.insured.phone2 = String(
                                  values.insured_phone2
                                );
                                submitDataInt.insured.phone1 = String(
                                  values.insured_phone1
                                );
                                submitDataInt.refNumber = String(
                                  originData.refNumber
                                );
                                submitDataInt.additionalRefNumber = String(
                                  originData.additionalRefNumber
                                );
                                submitDataInt.contentsRefNum = String(
                                  originData.contentsRefNum
                                );
                                submitDataInt.riskname = String(
                                  originData.riskname
                                );
                                submitDataInt.incidentDetail.riskAddress.postcode =
                                  String(
                                    originData.incidentDetail.riskAddress
                                      .postcode
                                  );
                                submitDataInt.incidentDetail.riskAddress.suburb =
                                  String(
                                    originData.incidentDetail.riskAddress.suburb
                                  );
                                submitDataInt.incidentDetail.riskAddress.line1 =
                                  String(
                                    originData.incidentDetail.riskAddress.line1
                                  );

                                let submitDataNotEmpt =
                                  removeEmpty(submitDataInt);
                                // window[Symbol.for(this.props.symbol)].toggleOpen({
                                //   message: 'Adding claim'
                                // })
                                const res = await AddClaimMutation(
                                  submitDataNotEmpt
                                );
                                actions.setSubmitting(false);

                                if (res) {
                                  window[Symbol.for("__snackbar")].toggleOpen({
                                    message: res.messages[0],
                                  });
                                  if (res.ok) {
                                    if (res.success) {
                                      this.props.history.push(
                                        `claim/job-info?id=${res.result.claimId}`
                                      );
                                    } else {
                                      actions.setErrors(
                                        res.fieldErrors.reduce(
                                          (total, current) => {
                                            total[current.fieldName] =
                                              current.message;
                                            return total;
                                          },
                                          {}
                                        )
                                      );
                                    }
                                  }
                                }
                              }, 400);
                            }
                          }}
                          validate={(values) => {
                            let errors = {};
                            if (
                              props.company.claimJobFields
                                .defaultLodgeTemplateId === 1
                            ) {
                              ["refNumber"].forEach((e) => {
                                if (
                                  values[e] &&
                                  !refNumberRegexp.test(values[e])
                                )
                                  errors[e] = "Invalid Reference number";
                                if (
                                  props.company &&
                                  props.company.insrefnumLength
                                ) {
                                  if (
                                    String(values[e]).length >
                                    props.company.insrefnumLength
                                  ) {
                                    errors[
                                      e
                                    ] = `Reference numbers cannot be longer than ${props.company.insrefnumLength} characters`;
                                  }
                                }
                              });
                              ["insured_name"].forEach((e) => {
                                if (
                                  values[e] &&
                                  clientNameRegexp.test(values[e])
                                )
                                  errors[e] = "Invalid character";
                              });
                              [
                                "insured_name",
                                "incidentDetail_riskAddress_line1",
                                "incidentDetail_riskAddress_suburb",
                                "incidentDetail_riskAddress_state",
                                "incidentDetail_riskAddress_postcode",
                                "insured_phone1",
                              ].forEach((e) => {
                                if (!values[e] || values[e].length === 0)
                                  errors[e] = "Required!";
                              });
                              if (
                                values.insured_email &&
                                !emailRegexp.test(values.insured_email)
                              )
                                errors.insured_email = "Invalid email";
                              if (
                                values.incidentDetail_riskAddress_line1 &&
                                addressRegexp.test(
                                  values.incidentDetail_riskAddress_line1
                                )
                              )
                                errors.incidentDetail_riskAddress_line1 =
                                  "Invalid address";
                              if (
                                values.incidentDetail_riskAddress_suburb &&
                                suburbRegexp.test(
                                  values.incidentDetail_riskAddress_suburb
                                )
                              )
                                errors.incidentDetail_riskAddress_suburb =
                                  "Invalid suburb";

                              return errors;
                            } else {
                              const valuesHasBuilding =
                                values.portfolioType.indexOf("Building") !== -1;
                              const valuesHasContents =
                                values.portfolioType.indexOf("Contents") !== -1;
                              const valuesHasRestoration =
                                values.portfolioType.indexOf("Restoration") !==
                                -1;

                              if (values.portfolioType.length === 0)
                                errors.portfolioType = "wat";
                              ["insured_salutation"].forEach((e) => {
                                if (values[e] && nameRegexp.test(values[e]))
                                  errors[e] = "Invalid character";
                              });
                              ["insured_name"].forEach((e) => {
                                if (
                                  values[e] &&
                                  clientNameRegexp.test(values[e])
                                )
                                  errors[e] = "Invalid character";
                              });
                              [
                                "insured_name",
                                "incidentDetail_riskAddress_line1",
                                "incidentDetail_riskAddress_suburb",
                                "incidentDetail_riskAddress_state",
                                "incidentDetail_riskAddress_postcode",
                                "incidentDetail_incidentDate",
                                "insured_phone1",
                                "incidentDetail_eventTypeId",
                                "incidentDetail_catCodeId",
                                "casemanagerId",
                              ].forEach((e) => {
                                if (!values[e] || values[e].length === 0)
                                  errors[e] = "Required!";
                              });

                              // Distributor required when hasAccess
                              if (
                                props.company.policyTypeSuppliersView &&
                                !values.policyTypeId
                              ) {
                                errors.policyTypeId = "Required!";
                              }

                              if (
                                props.company.policyTypeSuppliersView &&
                                !values.policyCoverId
                              ) {
                                errors.policyCoverId = "Required!";
                              }

                              if (
                                contextToMarkup(values.claimDescription) ===
                                "<p></p>\n"
                              )
                                errors.claimDescription = "Required!";

                              [
                                "buildingScopingSupplierId",
                                "Portfolios_Building_ExcessValue",
                                "Portfolios_Building_SumInsured",
                              ].forEach((e) => {
                                if (
                                  !values[e] &&
                                  valuesHasBuilding &&
                                  values[e] !== 0
                                )
                                  errors[e] = "Required!";
                              });
                              [
                                "Portfolios_Contents_ExcessValue",
                                "Portfolios_Contents_SumInsured",
                              ].forEach((e) => {
                                if (
                                  !values[e] &&
                                  valuesHasContents &&
                                  values[e] !== 0
                                )
                                  errors[e] = "Required!";
                              });
                              [
                                "restorationScopingSupplierId",
                                "Portfolios_Restoration_ExcessValue",
                                "Portfolios_Restoration_SumInsured",
                              ].forEach((e) => {
                                if (
                                  !values[e] &&
                                  valuesHasRestoration &&
                                  values[e] !== 0
                                )
                                  errors[e] = "Required!";
                              });
                              [
                                "contentsRefNum",
                                "refNumber",
                                "additionalRefNumber",
                              ].forEach((e) => {
                                if (
                                  values[e] &&
                                  !refNumberRegexp.test(values[e])
                                )
                                  errors[e] = "Invalid Reference number";
                                if (
                                  props.company &&
                                  props.company.insrefnumLength
                                ) {
                                  if (
                                    String(values[e]).length >
                                    props.company.insrefnumLength
                                  ) {
                                    errors[
                                      e
                                    ] = `Reference numbers cannot be longer than ${props.company.insrefnumLength} characters`;
                                  }
                                }
                              });

                              if (
                                parseInt(
                                  values.Portfolios_Building_SumInsured
                                ) <
                                parseInt(values.Portfolios_Building_ExcessValue)
                              )
                                errors.Portfolios_Building_SumInsured =
                                  "Sum insured must be higher than excess value";
                              if (
                                parseInt(
                                  values.Portfolios_Contents_SumInsured
                                ) <
                                parseInt(values.Portfolios_Contents_ExcessValue)
                              )
                                errors.Portfolios_Contents_SumInsured =
                                  "Sum insured must be higher than excess value";
                              if (
                                parseInt(
                                  values.Portfolios_Restoration_SumInsured
                                ) <
                                parseInt(
                                  values.Portfolios_Restoration_ExcessValue
                                )
                              )
                                errors.Portfolios_Restoration_SumInsured =
                                  "Sum insured must be higher than excess value";

                              if (
                                values.insured_email &&
                                !emailRegexp.test(values.insured_email)
                              )
                                errors.insured_email = "Invalid email";
                              if (
                                values.incidentDetail_riskAddress_line1 &&
                                addressRegexp.test(
                                  values.incidentDetail_riskAddress_line1
                                )
                              )
                                errors.incidentDetail_riskAddress_line1 =
                                  "Invalid address";
                              if (
                                values.incidentDetail_riskAddress_suburb &&
                                suburbRegexp.test(
                                  values.incidentDetail_riskAddress_suburb
                                )
                              )
                                errors.incidentDetail_riskAddress_suburb =
                                  "Invalid suburb";

                              if (
                                String(values.insured_salutation).length > 20
                              ) {
                                errors.insured_salutation =
                                  "Maximum salutation characters limit is 20";
                              }

                              return errors;
                            }
                          }}
                        >
                          {({
                            isSubmitting,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                            dirty,
                          }) => {
                            return (
                              <Form autoComplete="newClaim">
                                {props.company.claimJobFields
                                  .defaultLodgeTemplateId === 1 ? (
                                  <AddClaimNewTemplate
                                    company={props.company}
                                    details={props}
                                    data={data}
                                    userType={userType}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    isSubmitting={isSubmitting}
                                    handlePostcodeChange={
                                      this.handlePostcodeChange
                                    }
                                  />
                                ) : (
                                  <Grid fluid>
                                    <Grid item xs={12}>
                                      <AddClaimClientInfoCard
                                        claimJobFields={
                                          props.company.claimJobFields
                                        }
                                        company={props.company}
                                        handlePostcodeChange={
                                          this.handlePostcodeChange
                                        }
                                        values={values}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <AddClaimDetailsCard
                                        company={props.company}
                                        details={props}
                                        data={data}
                                        userType={userType}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <AddClaimQuotingBuildersCard
                                        company={props.company}
                                        details={props}
                                        data={data}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <AddClaimQuotingRestorersCard
                                        company={props.company}
                                        details={props}
                                        data={data}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <AddDescriptionCard
                                        company={props.company}
                                        details={props}
                                        data={data}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        isSubmitting={isSubmitting}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </Form>
                            );
                          }}
                        </Formik>
                      </Grid>
                    )}
                  </>
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  handlePostcodeChange = (postcode) => {
    this.setState((state) => ({ data: { ...state.data, postcode } }));
  };

  handleInsurerChoosen = (insurerId) => {
    // turn insurerId to fuking in
    const id = parseInt(insurerId);
    this.setState((state) => ({ data: { ...state.data, insurerId: id } }));
  };

  waitForSubmit = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ ok: true });
      }, 3000);
    });
  };

  handleSubmit = async (data) => {};
}

export default withStyles((theme) => ({
  "@supports ( -moz-appearance:none )": {
    "@media screen and (max-width: 1000px)": {
      root: {
        width: "110% !important",
        transformOrigin: "0rem 1rem !important",
      },
    },
    root: {
      width: "104%",
      transformOrigin: "35rem 1rem",
      transform: "scale(0.91)",
    },
  },
  "@supports ( -webkit-appearance:none )": {
    root: {
      zoom: 0.92,
    },
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  hidden: {
    display: "none",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))(withRouter(AddClaim));

const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
    else if (obj[key] === undefined || obj[key] === "") delete obj[key];
  });
  return obj;
};

const parseIntThis = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") {
      parseIntThis(obj[key]);
    } else {
      obj[key] = TryParseInt(obj[key]);
    }
  });
  return obj;
};

const TryParseInt = (str) => {
  // var retValue = undefined;
  if (str !== null) {
    if (str.length > 0) {
      if (!isNaN(str)) {
        return parseInt(str);
      }
    }
  }
  return str;
};
