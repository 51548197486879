export default d => {
  const data = {...d}
  Object.entries(data).forEach(([key, val]) => {
    if(Array.isArray(val)) {
      data[key] = val.map(v => TryParseInt(v))
    } else if (typeof val === 'object') {
      Object.entries(val).map(([k, io]) => {
        if(Array.isArray(io)) {
          data[key][k] = io.map(wut => {
            return TryParseInt(wut)
          })
        }
        return null
      })
    }
  })
  return data
}

export const TryParseInt = (str) => {
  // var retValue = undefined;
  if(str !== null) {
    if(str.length > 0) {
      if (!isNaN(str)) {
        return parseInt(str);
      }
    }
  }
  return str;
}