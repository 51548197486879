import React from 'react'

export default ({ skip, consentConfigs }) => {
  const videoDialogActions = window[Symbol.for(`__videoDialog`)]

  const [state, setState] = React.useState({
    ready: false,
    approved: false,
  })

  const isChecking = consentConfigs ? consentConfigs.isChecking : null
  const title = consentConfigs ? consentConfigs.title : null
  const content = consentConfigs ? consentConfigs.content : null

  React.useEffect(() => {
    if(skip) {}
    else if(!isChecking) {
      setState(pre => ({ ready: true, approved: true }))
    }
    else {
      videoDialogActions.toggleOpen({ title, content, firstButton: 'Accept', secondButton: 'Cancel' })
        .then(({ ok }) => {
          setState(pre => ({ ready: true, approved: ok }))
        })
    }
  }, [skip, isChecking, title, content])

  return state
}