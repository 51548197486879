import { getSafe } from 'common/utils'

export default quotes => {
  if(!quotes || quotes.length === 0) return []

  const scoping = quotes.slice(0, 1)[0].node
  const quoting = quotes.slice(1).map(x => x.node)
  const scopeItems = scoping.lineItems.map(item => ({
    type: 'item',
    label: `${item.costCentre.costCentreName}${getSafe(() => ` - ${item.costItem.itemDescription}`, '')}${` - ${item.lineDescription}`}`,
    payload: [{
      //
      itemId: item.jobQuoteItemId,

      text: item.subtotal,
      authorised: item.authorised,
      // tooltip
      substitute: item.substitutename,
      // scope item field
      qty: item.qty,
      policy: item.policyLimit
    }]
  }))

  scopeItems.forEach(scopeItem => {
    quoting.forEach(quote => {
      const quoteItems = quote.lineItems.reduce((total, item) => {
        return {
          ...total,
          [item.scopeItemId]: {
            itemId: item.jobQuoteItemId,

            text: item.subtotal,
            authorised: item.authorised,
            // tooltip
            substitute: item.substitutename
          }
        }
      }, {})

      scopeItem.payload.push(
        quoteItems[scopeItem.payload[0].itemId]
          ? quoteItems[scopeItem.payload[0].itemId]
          : { type: null }
      )
    })
  })

  const rows = [
    { type: 'title', payload: [] },
    { type: 'subtitle', payload: [] },
    ...scopeItems,
    { type: 'report', label: 'Supplier instructed to collect excess', payload: [] },
    { type: 'report', label: 'Delivery Charges', payload: [] },
    { type: 'report', label: 'Grand total', payload: [] },
    { type: 'action', payload: [] }
  ]

  quotes.forEach(quote => {
    // title row
    const phone1 = getSafe(() => quote.node.supplier.companyPhone1, null)
    const phone2 = getSafe(() => quote.node.supplier.companyPhone2, null)
    rows[0].payload.push({
      text: getSafe(() => quote.node.supplier.companyName, ''),
      phone: `${(phone1 || phone2) ? 'phone: ' : ''}${phone1 ? `${phone1}` : ''}${phone2 ? ` - ${phone2}` : ''}`
    })
    // subtitle row
    rows[1].payload.push({
      text: getSafe(() => quote.node.quoteStatus.statusName, '')
    })
    // collect excess
    rows[rows.length - 4].payload.push({
      completeRule: quote.node.collectExcess
    })
    // Delivery Charges
    rows[rows.length - 3].payload.push({
      text: getSafe(() => quote.node.deliveryCharges, ''),
      completeRule: getSafe(() => quote.node.deliveryChargeUsed, false)
    })
    // Grand total
    rows[rows.length - 2].payload.push({
      text: getSafe(() => quote.node.subtotal, '')
    })
    // action row
    rows[rows.length - 1].payload.push({
      jobQuoteId: quote.node.jobQuoteId,
      completed: quote.node.completed,
      status: quote.node.quoteStatus.statusName
    })
  })

  return rows
}