import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation QuoteInitialCallMutation($where: ENDataEntityKey!) {
    claimInitialCall(where: $where) {
      success
      messages
      result {
        progress {
          initialCallMade
        }
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: res => {
        resolve({
          messages: res.claimInitialCall.messages,
          success: res.claimInitialCall.success,
        })
      },
      onError: errors => resolve({
        messages: ['Unable to complete this action. Please try again!']
      })
    })
  })
}
