import React from "react";
import environment from "common/relay";
import { graphql, fetchQuery } from "react-relay";
import AnnouncementDialog from "./AnnouncementDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const Announcement = ({ showAnnouncement }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [announcements, setAnnouncements] = React.useState(null);
  const announcementsString = JSON.stringify(announcements);
  React.useEffect(() => {
    if (showAnnouncement) {
      const variables = {
        first: 100000,
        where: {
          includeAcknowledged: false,
          disabled: false,
        },
      };

      fetchQuery(environment, query, variables)
        .then((data) => setAnnouncements(data.announcements))
        .catch((error) => console.log(error));
    }
  }, []);
  React.useEffect(() => {
    if (announcementsString) {
      handleClickOpen();
    }
  }, [announcementsString]);
  if (
    !announcements ||
    !announcements.edges ||
    announcements.edges.length === 0
  )
    return null;
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent>
        <AnnouncementDialog
          announcements={announcements.edges}
          handleClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default Announcement;

const query = graphql`
  query AnnouncementQuery($where: AnnouncementWhere!, $first: Int) {
    announcements(where: $where, first: $first) {
      edges {
        node {
          title
          content
          id
          type
          displayType
        }
      }
    }
  }
`;
