import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation EnterDatesMutation(
    $input: JobWorkOrderDateInput!
    $where: ENDataPortfolioKey!
  ) {
    updateJobWorkDate(input: $input, where: $where) {
      success
      messages
      result {
        costCentre { costCentreId costCentreName }
        subtotal
        startDate
        completeDate
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.updateJobWorkDate.success,
          message: res.updateJobWorkDate.messages[0]
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}