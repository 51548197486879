/**
 * @flow
 * @relayHash 4cd9d9dee58bf25054574816637f0d64
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessRole = "Adjustor" | "Administrator" | "Agent" | "Builder" | "ContentSupplier" | "Customer" | "Estimator" | "Insurance" | "InternalAB" | "LossAdjuster" | "MultiTenderAssessor" | "Restorer" | "Specialist" | "SystemAdmin" | "UNDEFINED" | "%future added value";
export type ClaimListPageQueryVariables = {||};
export type ClaimListPageQueryResponse = {|
  +me: ?{|
    +userRole: ?AccessRole,
    +hasMultipleAccess: boolean,
  |},
  +currentUser: {|
    +actions: {|
      +createNewClaim: {|
        +isDisabled: boolean
      |}
    |}
  |},
|};
export type ClaimListPageQuery = {|
  variables: ClaimListPageQueryVariables,
  response: ClaimListPageQueryResponse,
|};
*/


/*
query ClaimListPageQuery {
  me {
    userRole
    hasMultipleAccess
  }
  currentUser {
    actions {
      createNewClaim {
        isDisabled
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "me",
  "storageKey": null,
  "args": null,
  "concreteType": "UserProfile",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "userRole",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasMultipleAccess",
      "args": null,
      "storageKey": null
    }
  ]
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisabled",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ClaimListPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      (v0/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticatedUser",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "actions",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthenticatedUserAction",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "createNewClaim",
                "storageKey": null,
                "args": null,
                "concreteType": "ActionControl",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ClaimListPageQuery",
    "argumentDefinitions": [],
    "selections": [
      (v0/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticatedUser",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "actions",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthenticatedUserAction",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "createNewClaim",
                "storageKey": null,
                "args": null,
                "concreteType": "ActionControl",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ClaimListPageQuery",
    "id": null,
    "text": "query ClaimListPageQuery {\n  me {\n    userRole\n    hasMultipleAccess\n  }\n  currentUser {\n    actions {\n      createNewClaim {\n        isDisabled\n        id\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0740bd10885be233c05014976a4e2b23';
module.exports = node;
