import React from "react";
import Dialog from "components/Mui/Dialog";
import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
import Form from "components/Mui/Form";
import Button from "components/Mui/Button";
import SmallLineItemListGroup from "components/LineItemList/SmallLineItemListGroup/NoScopeTypeLineItemListGroup";
import ScopeTypeLineItemListGroup from "components/LineItemList/SmallLineItemListGroup/ScopeTypeLineItemListGroup";
import VariationAuditMutation from "./VariationAuditMutation";

const VariationApprove = ({
  handleRefresh,
  variation,
  items,
  ScopeTypeOptions,
  data,
  portfolio,
  meta,
  classes,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    const notAcceptedItemIds = Object.entries(e)
      .map((x) => {
        if (x[1] !== true) return parseInt(x[0]);
        return null;
      })
      .filter((x) => x !== null);
    const { variationId, portfolios } = data;
    const snackbar = window[Symbol.for("__snackbar")];
    const res = await VariationAuditMutation({
      where: {
        id: variationId,
        portfolioType: portfolios,
      },
      input: {
        notAcceptedItemIds,
        auditToStatus: "VariationApproved",
      },
    });
    if (res) {
      setIsSubmitting(false);
      snackbar.toggleOpen({
        message: res.message,
      });

      if (res.success) handleRefresh();
    }
  };
  let group;
  if (!meta.claim.view.actions.updateScopeType.isDisplay) {
    group = Object.values(
      items.reduce((total, current) => {
        return {
          ...total,
          [current.location.costLocationId]: total[
            current.location.costLocationId
          ]
            ? {
                ...total[current.location.costLocationId],
                items: [
                  ...total[current.location.costLocationId].items,
                  current,
                ],
              }
            : {
                name: current.location.locationName,
                items: [current],
              },
        };
      }, {})
    );
  }

  return (
    <Dialog
      noForm
      noActions
      symbol="__VariationAuditDialog"
      paperProps={{
        style: { minWidth: "70%" },
      }}
      title="Approve Variation Item"
      content={
        <Form onSubmit={(e) => handleSubmit(e)}>
          {meta.claim.view.actions.updateScopeType.isDisplay ? (
            <ScopeTypeLineItemListGroup
              quote={variation}
              ScopeTypeOptions={ScopeTypeOptions}
              portfolioType={portfolio}
              update={handleRefresh}
              quotationType="Variation"
            />
          ) : (
            <NoScopeTypeTable classes={classes} group={group} />
          )}
          <p style={{ textAlign: "right", margin: 0 }}>
            <Button
              color="primary"
              label="Close"
              onClick={() =>
                window[Symbol.for("__VariationAuditDialog")].handleClose()
              }
            />
            <Button
              color="primary"
              variant="contained"
              label="Accept"
              type="submit"
              disabled={isSubmitting}
            />
          </p>
        </Form>
      }
    />
  );
};

export default withStyles((theme) => ({
  root: {
    ...theme.mixins.alignJustifyContainer(theme),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: "center",
    borderRadius: "8px",

    "&:nth-child(even)": {
      background: theme.palette.grey[100],
    },
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
  rowItem: {
    ...theme.mixins.alignJustifyContainer(theme),
    alignItems: "center",
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(VariationApprove);

const NoScopeTypeTable = ({ classes, group }) => {
  return (
    <Paper
      title={
        <Grid fluid className={classes.headerContainer}>
          {[
            { col: 1, name: "" },
            { col: 2, name: "Cost Centre" },
            { col: true, name: "Description" },
            { col: 1, name: "Units" },
            { col: 1, name: "Qty" },
            { col: 1, name: "Rate Ex" },
            { col: 1, name: "Margin" },
            { col: 1, name: "Subtotal" },
          ].map(({ col, name }, key) => (
            <Grid className={classes.alignCenter} item key={key} xs={col}>
              {name}
            </Grid>
          ))}
        </Grid>
      }
    >
      {group.map((item, index) => (
        <Grid item xs={12} key={index}>
          <SmallLineItemListGroup item={item} type={"variation"} />
        </Grid>
      ))}
    </Paper>
  );
};
