import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from 'components/Mui/HexagonButton'
import DashboardDialog from '../Dashboard/DashboardDialog'

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f8f4f2',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#efe8e6',
    },
  }
}))(TableRow);

const Comparison = ({classes, data, where}) =>{ 
  let title
  if(data.rows.length) {title = data.rows[0].fields.map(e=>e.name)}
  return (
    <div style={{width:'100%'}}>
    {data.rows.length?
      <Table className={classes.table} aria-label="customized table">
          <TableHead>
          <StyledTableRow>
            <TableCell>{data.name}</TableCell>
            {title.map(e=> 
              <TableCell padding="none" align="center" style={{width:'65px'}}>{e}</TableCell>)}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {data.rows.map(e => (
            <StyledTableRow key={e.name}>
              <TableCell align="center">
                {e.name}
              </TableCell>
              {
                e.fields.map(value=>
                  <TableCell align="center" style={{padding:'1px'}}>
                    <DashboardDialog cardId={value.id} where={where}
                    title={`${e.name} - ${value.name}`}
                    style={{backgroundColor:value.color, color: 'white'}} 
                    label={value.ranking?value.ranking:'-'}/>
                   </TableCell>
                  )
              }
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>:<div>No Table Data</div>}
    </div>
  )
}

export default withStyles(Theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)'
  },
  tableSpacing: {
    padding: '10px'
  }
}))(Comparison)