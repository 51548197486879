/**
 * @flow
 * @relayHash 8f91376cd7ff747abcc2030ca4906951
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimJobStatus = "VariationApproved" | "VariationRejected" | "VariationReset" | "VariationSubmitted" | "%future added value";
export type JobRecommendationStatus = "NotRecommended" | "Recommended" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuoteRequestStatus = "AdditionalItems" | "AppointmentMade" | "AwaitingInfo" | "AwaitingInfoContents" | "ClaimFinalised" | "InitialCallMade" | "JobAuthorised" | "JobLost" | "NewClaims" | "OfferScopeAcc" | "QuoteSubmitted" | "WaitingQuotes" | "WaitingResult" | "WaitingScope" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type SupervisorIndex = {|
  isDisabled?: ?boolean
|};
export type ClaimJobFilter = {|
  id?: ?string,
  parentId?: ?string,
  subject?: ?string,
  createDateFrom?: ?any,
  createDateTo?: ?any,
  incidentDate?: ?any,
  incidentDateFrom?: ?any,
  incidentDateTo?: ?any,
  keyWords?: ?string,
  refNumber?: ?string,
  postcode?: ?string,
  managerTeams?: ?$ReadOnlyArray<?string>,
  managers?: ?$ReadOnlyArray<string>,
  insurers?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  agents?: ?$ReadOnlyArray<string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  buildingSuppliers?: ?$ReadOnlyArray<string>,
  contentsSuppliers?: ?$ReadOnlyArray<string>,
  restorationSuppliers?: ?$ReadOnlyArray<string>,
  buildingStatus?: ?$ReadOnlyArray<string>,
  contentsStatus?: ?$ReadOnlyArray<string>,
  restorationStatus?: ?$ReadOnlyArray<string>,
  lossAdjusterStatus?: ?$ReadOnlyArray<string>,
  suppliers?: ?$ReadOnlyArray<string>,
  status?: ?$ReadOnlyArray<string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  jobStatuses?: ?$ReadOnlyArray<string>,
  claimStatusStageIds?: ?$ReadOnlyArray<string>,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  states?: ?$ReadOnlyArray<?StateEnum>,
  variationStatuses?: ?$ReadOnlyArray<?ClaimJobStatus>,
  requestStatuses?: ?$ReadOnlyArray<?QuoteRequestStatus>,
  recommendationStatus?: ?$ReadOnlyArray<JobRecommendationStatus>,
  or?: ?ClaimJobFilter,
  and?: ?ClaimJobFilter,
  not?: ?ClaimJobFilter,
|};
export type EstimatorIndex = {|
  isDisabled?: ?boolean
|};
export type ReportDetailsQueryVariables = {|
  supervisorsWhere?: ?SupervisorIndex,
  eventTypeWhere?: ?ClaimJobFilter,
  estimatorsWhere?: ?EstimatorIndex,
  claimRecommendationsWhere?: ?ClaimJobFilter,
  isClaimWhere?: ?ClaimJobFilter,
|};
export type ReportDetailsQueryResponse = {|
  +supervisors: ?{|
    +items: ?$ReadOnlyArray<?{|
      +label: string,
      +value: number,
      +id: string,
    |}>
  |},
  +eventTypes: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +estimators: ?{|
    +items: ?$ReadOnlyArray<?{|
      +label: string,
      +value: number,
      +id: string,
    |}>
  |},
  +claimRecommendations: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +isClaim: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
|};
export type ReportDetailsQuery = {|
  variables: ReportDetailsQueryVariables,
  response: ReportDetailsQueryResponse,
|};
*/


/*
query ReportDetailsQuery(
  $supervisorsWhere: SupervisorIndex
  $eventTypeWhere: ClaimJobFilter
  $estimatorsWhere: EstimatorIndex
  $claimRecommendationsWhere: ClaimJobFilter
  $isClaimWhere: ClaimJobFilter
) {
  supervisors(where: $supervisorsWhere) {
    items {
      label: nameWithPhone
      value
      id
    }
  }
  eventTypes: claimFilterOptions(where: $eventTypeWhere) {
    label: name
    value
    id
  }
  estimators(where: $estimatorsWhere) {
    items {
      label: nameWithPhone
      value
      id
    }
  }
  claimRecommendations: claimFilterOptions(where: $claimRecommendationsWhere) {
    label: name
    value
    id
  }
  isClaim: claimFilterOptions(where: $isClaimWhere) {
    label: name
    value
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "supervisorsWhere",
    "type": "SupervisorIndex",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "eventTypeWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "estimatorsWhere",
    "type": "EstimatorIndex",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "claimRecommendationsWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isClaimWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": "label",
    "name": "nameWithPhone",
    "args": null,
    "storageKey": null
  },
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "supervisors",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "supervisorsWhere",
      "type": "SupervisorIndex"
    }
  ],
  "concreteType": "SupervisorConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "items",
      "storageKey": null,
      "args": null,
      "concreteType": "Supervisor",
      "plural": true,
      "selections": (v3/*: any*/)
    }
  ]
},
v5 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "eventTypeWhere",
    "type": "ClaimJobFilter"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  (v1/*: any*/)
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "estimators",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "estimatorsWhere",
      "type": "EstimatorIndex"
    }
  ],
  "concreteType": "EstimatorConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "items",
      "storageKey": null,
      "args": null,
      "concreteType": "Estimator",
      "plural": true,
      "selections": (v3/*: any*/)
    }
  ]
},
v9 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "claimRecommendationsWhere",
    "type": "ClaimJobFilter"
  }
],
v10 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "isClaimWhere",
    "type": "ClaimJobFilter"
  }
],
v11 = [
  (v6/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReportDetailsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v4/*: any*/),
      {
        "kind": "LinkedField",
        "alias": "eventTypes",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v7/*: any*/)
      },
      (v8/*: any*/),
      {
        "kind": "LinkedField",
        "alias": "claimRecommendations",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v9/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v7/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "isClaim",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v10/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v7/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ReportDetailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v4/*: any*/),
      {
        "kind": "LinkedField",
        "alias": "eventTypes",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v11/*: any*/)
      },
      (v8/*: any*/),
      {
        "kind": "LinkedField",
        "alias": "claimRecommendations",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v9/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v11/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "isClaim",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v10/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v11/*: any*/)
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ReportDetailsQuery",
    "id": null,
    "text": "query ReportDetailsQuery(\n  $supervisorsWhere: SupervisorIndex\n  $eventTypeWhere: ClaimJobFilter\n  $estimatorsWhere: EstimatorIndex\n  $claimRecommendationsWhere: ClaimJobFilter\n  $isClaimWhere: ClaimJobFilter\n) {\n  supervisors(where: $supervisorsWhere) {\n    items {\n      label: nameWithPhone\n      value\n      id\n    }\n  }\n  eventTypes: claimFilterOptions(where: $eventTypeWhere) {\n    label: name\n    value\n    id\n  }\n  estimators(where: $estimatorsWhere) {\n    items {\n      label: nameWithPhone\n      value\n      id\n    }\n  }\n  claimRecommendations: claimFilterOptions(where: $claimRecommendationsWhere) {\n    label: name\n    value\n    id\n  }\n  isClaim: claimFilterOptions(where: $isClaimWhere) {\n    label: name\n    value\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9e709ddd608a88da2bc13a29dc8095d6';
module.exports = node;
