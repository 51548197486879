import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import React from 'react'

const mutation = graphql`
  mutation DashboardExportToExcelMutation($where: SupplierScoreFilter!) {
    csvProduceForSupplierScoreDetail(where:$where){
      success
      messages
    }
  }
`
export default variables => {
    return new Promise((resolve, reject) => {
        commitMutation(environment, {
            mutation,
            variables,
            onCompleted: res => {
                resolve({
                    messages: res.csvProduceForSupplierScoreDetail.messages,
                    success: res.csvProduceForSupplierScoreDetail.success,
                })
            },
            onError: errors => resolve({
                messages: ['Unable to complete this action. Please try again!']
            })
        })
    })
}
