/**
 * @flow
 * @relayHash 36ca97d0c494fec56be6fb6bc34d4d1d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobWorkProgressInput = {|
  claimJobId: string,
  statusDate?: ?any,
  portfolioType: PortfolioType,
|};
export type StatusHistoryMutationVariables = {|
  input: JobWorkProgressInput
|};
export type StatusHistoryMutationResponse = {|
  +createJobWorkProgress: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +id: string,
      +logdate: ?any,
      +completed: boolean,
      +status: ?{|
        +statusName: string,
        +datePopup: boolean,
      |},
    |},
  |}
|};
export type StatusHistoryMutation = {|
  variables: StatusHistoryMutationVariables,
  response: StatusHistoryMutationResponse,
|};
*/


/*
mutation StatusHistoryMutation(
  $input: JobWorkProgressInput!
) {
  createJobWorkProgress(input: $input) {
    success
    messages
    result {
      id
      logdate
      completed
      status {
        statusName
        datePopup
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobWorkProgressInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "JobWorkProgressInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logdate",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completed",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "datePopup",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StatusHistoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobWorkProgress",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobProgressPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobProgress",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "status",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobStatusInfo",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StatusHistoryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobWorkProgress",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobProgressPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobProgress",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "status",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobStatusInfo",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "StatusHistoryMutation",
    "id": null,
    "text": "mutation StatusHistoryMutation(\n  $input: JobWorkProgressInput!\n) {\n  createJobWorkProgress(input: $input) {\n    success\n    messages\n    result {\n      id\n      logdate\n      completed\n      status {\n        statusName\n        datePopup\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f8985db46c986e0e2317559dc667d29';
module.exports = node;
