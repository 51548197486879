import React from 'react'
import { withStyles, TextField, Grid, InputAdornment } from '@material-ui/core';
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'
import QuickAction from 'components/Mui/QuickActions/QuickAction'
import MuiCircularProgress from '@material-ui/core/CircularProgress'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CommentIcon from '@material-ui/icons/Comment'

const query = graphql`
query FollowUpInfoQuery ($where: ENDataEntityKey!) {
  claimJob(where: $where) {
    lastFollowUp {
      date
      nextFollowUpDate
      note
      user {
        userName
      }
    }
  }
}`

const FollowUpInfo = props => {
  const { classes, portfolio, claimId, handleRefresh, ...rest } = props
  const qaRef = React.createRef()
  const snackbar = window[Symbol.for('__snackbar')]
  return (
      <QuickAction
        title="Last Follow Up"
        innerRef={qaRef}
        // paperProps={{style: { maxWidth: '65%', minWidth:'20%' }}}
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{where: {id: claimId}}}
            render={({error, props}) => {
              if(!props) return <div className={classes.loading}><MuiCircularProgress size={40}/></div>
              const data = props.claimJob.lastFollowUp
              return (
                <Grid container spacing={2} justify="center">
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Note"
                      value={data.note? data.note : ''}
                      // className={classes.textField}
                      rowsMax={5}
                      rows={5}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Date"
                      value={data.date? data.date : ''}
                      // className={classes.textField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarTodayIcon />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="User"
                      value={(data.user && data.user.userName)? data.user.userName : ''}
                      // className={classes.textField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Next FollowUp Date"
                      value={data.nextFollowUpDate? data.nextFollowUpDate : ''}
                      // className={classes.textField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarTodayIcon />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
               </Grid>
              )
            }}
          />
        }
        {...rest}
        />
  )
}

const styles = {
  icon: {
    fontSize: '40px',
  },
  button: {
    display: 'flex'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center'
  }
};

export default withStyles(styles)(FollowUpInfo);
