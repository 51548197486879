/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddClaimClientInfoCard_company$ref: FragmentReference;
export type AddClaimClientInfoCard_company = {|
  +removeHabitableAsbestos: ?boolean,
  +setupcustomerlogin: ?number,
  +hideCategoryOfClaim: ?boolean,
  +$refType: AddClaimClientInfoCard_company$ref,
|};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AddClaimClientInfoCard_company",
  "type": "CompanyProfile",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "removeHabitableAsbestos",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "setupcustomerlogin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hideCategoryOfClaim",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '5a600fe92aff92a7365c6fcc177a91cb';
module.exports = node;
