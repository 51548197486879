import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

const VideoCallLoading = ({ classes, loading, isOwner, ownerMessage, guestMessage }) => {
  if(!loading) return null

  return (
    <div className={classes.loading}>
      <Grid container direction='column' justify='center' alignItems='center' className={classes.root}>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}>
          <CircularProgress className={classes.progress} />
        </Grid>
        {isOwner
          ? (
            <>
              {ownerMessage && (
                  <Grid item xs={1} className={classes.messageGrid}>
                    <p className={classes.message}>{ownerMessage}</p>
                  </Grid>
                )
              }
              {guestMessage && (
                  <Grid item xs={1} className={classes.messageGrid}>
                    <p className={classes.message}>[GUEST] {guestMessage}</p>
                  </Grid>
                )
              }
            </>
          )
          : (
            <Grid item xs={1} className={classes.messageGrid}>
              <p className={classes.message}>{guestMessage}</p>
            </Grid>
          )
        }
      </Grid>
    </div>
  )
}

export default withStyles(theme => ({
  loading: {
    position: 'absolute',
    top: 0, right: 0, bottom: 0, left: 0,
    background: theme.palette.background.paper,
    zIndex: 1000,
  },

  root: {
    height: '100%'
  },

  messageGrid: {
    maxWidth: '100%'
  },
  message: {
    textAlign: 'center'
  }
}))(VideoCallLoading)