/**
 * @flow
 * @relayHash ce63f98a65d9f9c0bf95e6dff881482e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type RequestCategory = "All" | "AssessorReport" | "Authorised" | "DesktopQuoting" | "Lost" | "ScopeValidation" | "Scoping" | "%future added value";
export type JobQuoteIndex = {|
  id?: ?$ReadOnlyArray<number>,
  claimId?: ?string,
  scopeTypeId?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  requestCategory?: ?RequestCategory,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type AcceptContentItemQueryVariables = {|
  where: JobQuoteIndex
|};
export type AcceptContentItemQueryResponse = {|
  +accepting: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +supplier: ?{|
          +companyName: string
        |},
        +deliveryChargeUsed: boolean,
        +items: ?$ReadOnlyArray<?{|
          +authorised: ?boolean,
          +itemId: number,
          +centre: ?{|
            +name: string
          |},
          +costItem: ?{|
            +itemDescription: string
          |},
          +description: string,
          +qty: number,
          +rate: ?number,
          +margin: ?number,
          +subtotal: ?number,
        |}>,
      |}
    |}>
  |}
|};
export type AcceptContentItemQuery = {|
  variables: AcceptContentItemQueryVariables,
  response: AcceptContentItemQueryResponse,
|};
*/


/*
query AcceptContentItemQuery(
  $where: JobQuoteIndex!
) {
  accepting: jobQuotes(where: $where) {
    edges {
      node {
        id
        supplier {
          companyName
          id
        }
        deliveryChargeUsed
        items: lineItems {
          authorised
          itemId: jobQuoteItemId
          centre: costCentre {
            name: costCentreName
            id
          }
          costItem {
            itemDescription
            id
          }
          description: lineDescription
          qty
          rate
          margin
          subtotal
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JobQuoteIndex!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "JobQuoteIndex!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliveryChargeUsed",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "authorised",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": "itemId",
  "name": "jobQuoteItemId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": "name",
  "name": "costCentreName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemDescription",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": "description",
  "name": "lineDescription",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "qty",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rate",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "margin",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AcceptContentItemQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "accepting",
        "name": "jobQuotes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuoteConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuoteEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobQuote",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "supplier",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ]
                  },
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": "items",
                    "name": "lineItems",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobQuoteItem",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": "centre",
                        "name": "costCentre",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "QuoteCostCentre",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "costItem",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "QuoteCostItem",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ]
                      },
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AcceptContentItemQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "accepting",
        "name": "jobQuotes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuoteConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuoteEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobQuote",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "supplier",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": "items",
                    "name": "lineItems",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobQuoteItem",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": "centre",
                        "name": "costCentre",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "QuoteCostCentre",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "costItem",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "QuoteCostItem",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AcceptContentItemQuery",
    "id": null,
    "text": "query AcceptContentItemQuery(\n  $where: JobQuoteIndex!\n) {\n  accepting: jobQuotes(where: $where) {\n    edges {\n      node {\n        id\n        supplier {\n          companyName\n          id\n        }\n        deliveryChargeUsed\n        items: lineItems {\n          authorised\n          itemId: jobQuoteItemId\n          centre: costCentre {\n            name: costCentreName\n            id\n          }\n          costItem {\n            itemDescription\n            id\n          }\n          description: lineDescription\n          qty\n          rate\n          margin\n          subtotal\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be3516c0317c197364fea72546638c2c';
module.exports = node;
