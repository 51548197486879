import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";

import Input from "components/Formik/Input";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";
import Dialog from "components/Mui/Dialog";

import QuoteItemUpdate from "./QuoteItemUpdate";
import QuoteItemUpdateMutation from "./QuoteItemUpdateMutation";

class QuoteUpdate extends Component {
  snackbar = window[Symbol.for("__snackbar")];

  render() {
    const { symbol, classes, initialData } = this.props;
    return (
      <Dialog
        noForm
        noActions
        symbol={symbol}
        title="Update Quote"
        paperProps={{
          style: { maxWidth: "50%" },
        }}
        content={
          <Formik
            initialValues={{
              ...initialData,
            }}
            validate={validateInput}
            onSubmit={(
              { id, portfolioType, marginRate, ...input },
              actions
            ) => {
              setTimeout(async () => {
                const res = await QuoteItemUpdateMutation({
                  where: {
                    id,
                    portfolioType,
                  },
                  input: {
                    costCentreId: Number(input.costCentreId),
                    costItemId: Number(input.costItemId),
                    locationId: Number(input.locationId),
                    dimensions: input.dimensions,
                    lineDescription: input.lineDescription,
                    unitId: Number(input.unitId),
                    qty: Number(input.qty),
                    rate: Number(input.rate),
                    marginRate: Number(marginRate),
                    gst: Number(input.gst),
                  },
                });
                const ref = window[Symbol.for("__refreshQuote")];
                if (res.ok) {
                  if (res.success) {
                    window[Symbol.for(symbol)].handleClose();
                    this.snackbar.toggleOpen({ message: res.message });
                    if (ref) ref();
                    else {
                    }
                  }
                  if (!ref) actions.setSubmitting(false);
                }
              }, 400);
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <Grid fluid>
                  {[
                    {
                      col: 7,
                      component: Input,
                      label: "Location",
                      name: "locationId",
                      required: true,
                      select: true,
                      options: [
                        {
                          label: initialData.locationName,
                          value: initialData.locationId,
                        },
                      ],
                      disabled: true,
                    },
                    {
                      col: 5,
                      component: Input,
                      label: "Room Size",
                      name: "dimensions",
                      required: true,
                      disabled: true,
                    },
                    {
                      col: 6,
                      component: Input,
                      label: "Cost Centre",
                      name: "costCentreId",
                      options: [
                        {
                          label: initialData.costCentreName,
                          value: initialData.costCentreId,
                        },
                      ],
                      disabled: true,
                      select: true,
                    },
                  ].map(({ col, unMount, component: C, ...props }, index) => (
                    <Grid item xs={col} key={index}>
                      {!unMount && (
                        <C fullWidth variant="outlined" {...props} />
                      )}
                    </Grid>
                  ))}

                  <QuoteItemUpdate values={values} />

                  <div className={classes.actionArea}>
                    <Button
                      label="Close"
                      color="primary"
                      onClick={() => window[Symbol.for(symbol)].handleClose()}
                      disabled={isSubmitting}
                    />
                    <Button
                      label="Submit"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    />
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        }
      />
    );
  }
}

export default withStyles((theme) => ({
  actionArea: {
    marginLeft: "auto",
  },
}))(QuoteUpdate);

const validateInput = (values) => {
  let errors = {};
  if (!values.locationId) errors.locationId = "required!";
  if (!values.dimensions) errors.dimensions = "required!";

  if (!values.costCentreId) errors.costCentreId = "required!";

  if (values.costCentreId) {
    if (!values.costItemId) errors.costItemId = "required!";
    if (!values.unitId) errors.unitId = "required!";
    if (!values.qty) errors.qty = "required!";
    if (!values.rate && values.rate !== 0) errors.rate = "required!";
  }
  //
  //   if(values.dimensions && commonCharactersRegexp.test(values.dimensions)) errors.dimensions = 'Invalid character'
  //   if(values.dimensions.length > 200) errors.dimensions = 'Maximum character limit is 200'
  //   if(String(values.lineDescription).length > 1500) errors.lineDescription = 'Maximum character limit is 1500'
  if (String(values.qty).length > 4 + 3)
    errors.qty = "Maximum character limit is 4";
  if (
    (String(values.rate).indexOf("-") !== -1 &&
      String(values.rate).length > 7 + 4) ||
    (String(values.rate).indexOf("-") === -1 &&
      String(values.rate).length > 7 + 3)
  )
    errors.rate = "Maximum character limit is 7";

  return errors;
};
