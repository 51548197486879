import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import InfoBox from "components/Mui/InfoBox";

class VariationDetailsInfo extends Component {
  render() {
    const { info, classes } = this.props;

    return (
      <Grid fluid spacing={0} className={classes.root}>
        {info.map((item, index) => (
          <Grid item xs={6} key={index}>
            <InfoBox {...item} maxHeight="80px" />
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    background: theme.palette.grey[300],
    borderRadius: 8,
  },
}))(VariationDetailsInfo);
