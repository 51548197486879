/**
 * @flow
 * @relayHash ea4c34bdb588b04789b2dcddfe31d7ea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EstimatorCreateInput = {|
  name: string,
  phoneNumber: string,
|};
export type EstimatorCreateMutationVariables = {|
  input: EstimatorCreateInput
|};
export type EstimatorCreateMutationResponse = {|
  +estimatorCreate: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type EstimatorCreateMutation = {|
  variables: EstimatorCreateMutationVariables,
  response: EstimatorCreateMutationResponse,
|};
*/


/*
mutation EstimatorCreateMutation(
  $input: EstimatorCreateInput!
) {
  estimatorCreate(input: $input) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "EstimatorCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "estimatorCreate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "EstimatorCreateInput!"
      }
    ],
    "concreteType": "EstimatorPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EstimatorCreateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "EstimatorCreateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "EstimatorCreateMutation",
    "id": null,
    "text": "mutation EstimatorCreateMutation(\n  $input: EstimatorCreateInput!\n) {\n  estimatorCreate(input: $input) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f20e8fde38d646d1d7b8443d0e31eae';
module.exports = node;
