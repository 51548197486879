import React, { Component } from 'react'
import { graphql, createRefetchContainer } from 'react-relay'
import { withStyles } from '@material-ui/core/styles'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import CheckboxGroup from 'components/Formik/CheckboxGroup'

class AddClaimQuotingBuildersCard extends Component {
  state = {
    quotesperclaim: this.props.company ? this.props.company.quotesperclaim : 0
  }

  componentDidUpdate = (prevProps) => {
    const { data, values } = this.props
    if(this.props.values.buildingScopingSupplierId !== prevProps.values.buildingScopingSupplierId) {
      this.props.setFieldValue('Portfolios_Building_QuotingSupplierIds',
        this.props.details.builders.filter(e => e.value !== values.buildingScopingSupplierId).map((e, key) => {
        if(key < this.state.quotesperclaim) {
          return e.value
        }
        return undefined
      }))
    }
    if(prevProps.data.postcode !== this.props.data.postcode) {
      this.props.relay.refetch(
        {
          buildersWhere: {
            subject: 'suppliers',
            insurers: data.insurerId ? data.insurerId : '0',
            portfolios: 'Building', postcode: data.postcode
          },
        },
        null,
        () => {
          this.props.setFieldValue('Portfolios_Building_QuotingSupplierIds',
            this.props.details.builders.filter(e => e.value !== values.buildingScopingSupplierId).map((e, key) => {
            if(key < this.state.quotesperclaim) {
              return e.value
            }
            return undefined
          }))
        },
        {force: true},
      );

    }
  }

  render() {
    const { classes, handleComplete, details, values, company, setFieldValue, ...rest } = this.props
    const quotingBuildersList = details.builders.filter(e => e.value !== values.buildingScopingSupplierId)

    return (
      <>
        <Paper
          title='Quoting Builders'
          {...rest}
          contentClass={classes.overflow}
          content={
            (
              values.portfolioType.indexOf('Building') === -1
              || !values.incidentDetail_riskAddress_postcode
            )
            ? 'Within Customer Details section, please select Supplier Required and input postcode' /* label:'Please select building and postcode'|'Within Customer Details section, please select Supplier Required and input postcode' */
            : this.props.details.builders.length === 0
            ? 'No builder found, please try changing postcode'
            : <Grid fluid spacing={0}>
              <CheckboxGroup
                name='Portfolios_Building_QuotingSupplierIds'
                options={quotingBuildersList}
              />
            </Grid>
          }
        />
      </>
    )
  }
}

export default createRefetchContainer(withStyles(theme => ({
  overflow: {
    height: '15rem',
    overflow: 'auto',
  }
}))(AddClaimQuotingBuildersCard), graphql`
  fragment AddClaimQuotingBuildersCard_details on Query {
    builders: claimFilterOptions(where: $buildersWhere){
      label: name
      value
    }
  }
`,
  graphql`
    query AddClaimQuotingBuildersCardRefetchQuery (
      $buildersWhere: ClaimJobFilter
    ) {
      ...AddClaimQuotingBuildersCard_details
    }
  `
)


