import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "components/Mui/Paper";
import Button from "components/Mui/Button";
import ScopeAddLineItem from "../../Scope/ScopeActionBar/ScopeAddLineItem";
import CompleteScopeAction from "../../Scope/ScopeActionBar/CompleteScopeAction";
import CompleteScopeMutation from "../../Scope/ScopeActionBar/CompleteScopeAction/CompleteScopeMutation";
import ResetQuoteMutation from "../../Scope/ScopeActionBar/ResetQuoteAction/ResetQuoteMutation";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExportToExcelMutation from "../../Scope/ScopeActionBar/ExportToExcelAction/ExportToExcelMutation";
import PrintIcon from "@material-ui/icons/Print";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import GetQuotePdfMutation from "../../Quotes/QuotesAction/GetQuotePdfMutation";

const addLineItem = () => {
  const actions = window[Symbol.for("__internalAssessorAddItem")];
  if (actions) {
    actions.handleOpen();
  }
};

const InternalAssessorActionBar = ({
  classes,
  quote,
  meta,
  portfolio,
  isOwnCompany,
  isContentsAction,
  requestCategory,
  scopeTypeId,
  isProperties,
  scopeTypes,
  disable,
  industryRatesAutoFill,
}) => {
  return (
    <Paper>
      <Grid container justify="space-between" className={classes.root}>
        <InternalAssessoraction
          classes={classes}
          quote={quote}
          meta={meta}
          portfolio={portfolio}
          scopeTypeId={scopeTypeId}
          isOwnCompany={isOwnCompany}
          isContentsAction={isContentsAction}
          requestCategory={requestCategory}
          isProperties={isProperties}
          scopeTypes={scopeTypes}
          disable={disable}
          industryRatesAutoFill={industryRatesAutoFill}
        />
      </Grid>
    </Paper>
  );
};

const InternalAssessoraction = ({
  classes,
  quote,
  meta,
  portfolio,
  isOwnCompany,
  isContentsAction,
  requestCategory,
  scopeTypeId,
  isProperties,
  scopeTypes,
  disable,
  industryRatesAutoFill,
}) => {
  const portfolioMeta = meta.claim[portfolio.toLowerCase()];
  const handleResetScope = async (id, portfolioType) => {
    const snackbar = window[Symbol.for("__snackbar")];

    const res = await ResetQuoteMutation({
      where: {
        id,
        portfolioType,
      },
    });
    if (res.ok) {
      snackbar.toggleOpen({ message: res.messages[0] });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElForScope, setAnchorElForScope] = React.useState(null);

  const [isLoadingForScope, setIsLoadingForScope] = React.useState(false);
  const display = meta.claim.view.actions.updateScopeType.isDisplay;

  const handleMenu = (event) => {
    setAnchorElForScope(event.currentTarget);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElForScope(null);
    setAnchorEl(null);
  };
  var excelMenuList = [{ label: "All Scope Type", value: null }];
  scopeTypes.map((e) => {
    excelMenuList.push({ label: e.name, value: e.id });
  });

  const handleCloseAndMutation = (variables) => {
    setAnchorEl(null);
    handleExportToExcel(variables);
  };

  var jobScopeTypeListForScopeType = [{ label: "All Scope Type", value: null }];
  scopeTypes.map((e) => {
    jobScopeTypeListForScopeType.push({
      label: e.name,
      value: e.id,
    });
  });
  var jobScopeTypeListForQuote = [{ label: "All Scope Type", value: null }];
  scopeTypes.map((e) => {
    jobScopeTypeListForQuote.push({ label: e.name, value: e.id });
  });

  const handleExportToExcel = async (variables) => {
    const snackbar = window[Symbol.for("__snackbar")];
    const res = await ExportToExcelMutation(variables);
    if (res.ok) {
      if (res.success) {
        window.open(res.messages[0], "_blank");
      } else {
        snackbar.toggleOpen({
          message: res.messages[0],
        });
      }
    } else {
      snackbar.toggleOpen({
        message: res.messages[0],
      });
    }
  };

  const handlePrintWorkScope = async (item) => {
    setAnchorElForScope(null);
    setIsLoadingForScope(true);
    const res = await GetQuotePdfMutation({
      where: {
        claimId: meta.claim.claimId,
        requestCategory: "AssessorReport",
        portfolios: portfolio,
        scopeTypeId: item,
      },
    });

    if (res.ok) {
      setIsLoadingForScope(false);
      window.open(
        res.pdf,
        "Site Report Print Preview",
        "resizable, scrollbars, status"
      );
    }
  };

  return (
    <>
      {quote ? (
        <>
          {quote.actions.resetQuote.isDisplay && (
            <Grid item xs={2} className={classes.actionBlock}>
              <Button
                fullWidth
                label={quote.actions.resetQuote.label}
                disabled={quote.actions.resetQuote.isDisabled}
                variant="outlined"
                color="primary"
                onClick={() => handleResetScope(quote.jobQuoteId, portfolio)}
              />
            </Grid>
          )}
          <Grid item xs={2} className={classes.actionBlock}>
            {quote.actions.addLineItem.isDisplay && (
              <Button
                fullWidth
                label={
                  quote.actions.addLineItem.label
                    ? quote.actions.addLineItem.label
                    : "Add Line Item"
                }
                variant="outlined"
                color="primary"
                isDisabled={quote.actions.addLineItem.isDisabled}
                onClick={() => addLineItem()}
              />
            )}
            {/* ) : null} */}
          </Grid>
          {display ? (
            <>
              <Grid item xs={2} className={classes.actionBlock}>
                <Button
                  fullWidth
                  label="Export to excel"
                  variant="outlined"
                  color="primary"
                  aria-controls="excelMenuButton"
                  aria-haspopup="true"
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  // onClick={() => handleExportToExcel({
                  //   where: {
                  //     claimId: meta.claim.claimId,
                  //     portfolios: portfolio,
                  //     requestCategory: 'Scoping',
                  //   }
                  // })}
                />
                <Menu
                  id="excelMenuButton"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {excelMenuList.map((option, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() =>
                          handleCloseAndMutation({
                            where: {
                              claimId: meta.claim.claimId,
                              portfolios: portfolio,
                              requestCategory: "AssessorReport",
                              scopeTypeId: option.value,
                            },
                          })
                        }
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Grid>
              <Grid item xs={2} className={classes.actionBlock}>
                <Button
                  aria-controls="scopeOfWorksMenu"
                  aria-haspopup="true"
                  variant="outlined"
                  color="primary"
                  onClick={handleMenu}
                  leadIcon={
                    !isLoadingForScope ? (
                      <PrintIcon />
                    ) : (
                      <MuiCircularProgress size={24} />
                    )
                  }
                  fullWidth
                  disabled={disable}
                  label={
                    portfolio !== "Contents"
                      ? "Scope of works"
                      : "Schedule of Loss"
                  }
                />
                <Menu
                  id="scopeOfWorksMenu"
                  anchorEl={anchorElForScope}
                  keepMounted
                  open={Boolean(anchorElForScope)}
                  onClose={handleClose}
                >
                  {jobScopeTypeListForScopeType.map((option, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => handlePrintWorkScope(option.value)}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={2} className={classes.actionBlock}>
                <Button
                  fullWidth
                  label="Export to excel"
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    handleCloseAndMutation({
                      where: {
                        claimId: meta.claim.claimId,
                        portfolios: portfolio,
                        requestCategory: "AssessorReport",
                        scopeTypeId: null,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={2} className={classes.actionBlock}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handlePrintWorkScope(null)}
                  leadIcon={
                    !isLoadingForScope ? (
                      <PrintIcon />
                    ) : (
                      <MuiCircularProgress size={24} />
                    )
                  }
                  fullWidth
                  disabled={!quote.jobQuoteId}
                  label={
                    portfolio !== "Contents"
                      ? "Scope of works"
                      : "Schedule of Loss"
                  }
                />
              </Grid>
            </>
          )}

          <Grid item xs={2} className={classes.actionBlock}>
            {quote.actions.submitQuote.isDisplay && (
              <Button
                onClick={() =>
                  handleCompleteScope(
                    portfolio,
                    quote.jobQuoteId,
                    meta.claim.claimId
                  )
                }
                fullWidth
                label={
                  quote.actions.submitQuote.label
                    ? quote.actions.submitQuote.label
                    : "Scope Completed"
                }
                variant="outlined"
                color="primary"
                disabled={quote.actions.submitQuote.isDisabled}
              />
            )}
          </Grid>

          <ScopeAddLineItem
            symbol="__internalAssessorAddItem"
            jobQuoteId={quote.jobQuoteId}
            portfolioType={portfolio}
            scopeTypeId={scopeTypeId}
            isProperties={isProperties}
            quote={quote}
            industryRatesAutoFill={industryRatesAutoFill}
          />
          <CompleteScopeAction
            symbol="__scopeComplete"
            claimId={meta.claim.claimId}
            id={quote.jobQuoteId}
            portfolioType={portfolio}
          />
        </>
      ) : null}
    </>
  );
};

const handleCompleteScope = async (portfolioType, id, claimId) => {
  const actions = window[Symbol.for("__scopeComplete")];
  const snackbar = window[Symbol.for("__snackbar")];
  if (actions) {
    if (portfolioType !== "Contents") actions.handleOpen();
    else {
      const variables = {
        input: { startAfterContractReceived: "" },
        where: {
          claimId,
          id,
          portfolioType,
        },
      };
      const snackbarRes = await snackbar.toggleOpen({
        message: "Would you like to complete scope?",
        firstButton: "Yes",
        secondButton: "No",
        type: "confirm",
      });
      if (snackbarRes.ok) {
        const res = await CompleteScopeMutation(variables);
        if (res.ok) {
          snackbar.toggleOpen({ message: res.messages[0] });
        } else {
          snackbar.toggleOpen({
            message: "Can not complete scope, please try again",
          });
        }
      }
    }
  }
};

export default withStyles((theme) => ({
  root: {
    width: "100%",
  },
  actionBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))(InternalAssessorActionBar);
