/**
 * @flow
 * @relayHash 65687ae6dc47323ad20341ccbb806c5b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ENDataEntityKey = {|
  id: string
|};
export type JobQuoteItemUpdate = {|
  costCentreId?: ?number,
  costItemId?: ?number,
  locationId?: ?number,
  dimensions?: ?string,
  lineDescription?: ?string,
  qty?: ?number,
  rate?: ?number,
  proofOfLoss?: ?string,
  directsupply?: ?boolean,
  purchasePrice?: ?string,
  ageOfItem?: ?string,
  policyLimit?: ?number,
  substitutename?: ?string,
|};
export type UpdateContentScopeItemMutationVariables = {|
  where: ENDataEntityKey,
  input: JobQuoteItemUpdate,
|};
export type UpdateContentScopeItemMutationResponse = {|
  +updateJobContentItem: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type UpdateContentScopeItemMutation = {|
  variables: UpdateContentScopeItemMutationVariables,
  response: UpdateContentScopeItemMutationResponse,
|};
*/


/*
mutation UpdateContentScopeItemMutation(
  $where: ENDataEntityKey!
  $input: JobQuoteItemUpdate!
) {
  updateJobContentItem(where: $where, input: $input) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobQuoteItemUpdate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateJobContentItem",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "JobQuoteItemUpdate!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ENDataEntityKey!"
      }
    ],
    "concreteType": "JobQuotePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateContentScopeItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateContentScopeItemMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateContentScopeItemMutation",
    "id": null,
    "text": "mutation UpdateContentScopeItemMutation(\n  $where: ENDataEntityKey!\n  $input: JobQuoteItemUpdate!\n) {\n  updateJobContentItem(where: $where, input: $input) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3602aa6a0eab2130f51cf0f12f018d49';
module.exports = node;
