import React from 'react'
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'
import { withRouter } from 'react-router-dom'
import usePortfolioFilter from 'hooks/usePortfolioFilter'
import ClaimMetaContext from 'pages/Claim/ClaimDetails/ClaimMetaContext'

import Grid from 'components/Mui/Grid'
import { Cube } from 'components/Mui/Loader'
import CommunicationsList from './CommunicationsList'

const query = graphql`
  query CommunicationsPageQuery ($first: Int!, $after: String, $where: CommunicationFilter) {
    ...CommunicationsList
  }
`

const Communications = props => {
  const meta = React.useContext(ClaimMetaContext)
  const [ portfolio, handlePortfolio ] = usePortfolioFilter(meta)

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        first: 15,
        after: null,
        where: {
          claimId: props.claimId,
          portfolios: portfolio || undefined,
        }
      }}
      render={({ error, props: data }) => {
        if(error) {
          // console.log(error)
          return 'Error!!'
        }
        if(!data) return (
          <Grid fluid style={{ height: '100px' }}>
            <Grid item xs>
              <Cube />
            </Grid>
          </Grid>
        )
        return <CommunicationsList
          data={data}
          portfolio={portfolio}
          handlePortfolio={handlePortfolio}
          {...props}
        />
      }}
    />
  )
}

export default withRouter(Communications)