/**
 * @flow
 * @relayHash c68cbc38c3bf13a930baf551ea51ad12
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimLossAdjusterDocumentCreate = {|
  claimId: string,
  description: string,
  documentFileName: string,
  fileBase64: string,
  fileId?: ?string,
  private: boolean,
  reportTypeId?: ?number,
|};
export type UploadLossAdjusterActionMutationVariables = {|
  input: ClaimLossAdjusterDocumentCreate
|};
export type UploadLossAdjusterActionMutationResponse = {|
  +createClaimLossAdjusterDocument: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +id: string,
      +uploadDate: ?any,
      +company: ?{|
        +companyName: string
      |},
      +reportType: ?{|
        +reportTypeName: string
      |},
      +url: string,
      +private: boolean,
      +description: string,
    |},
  |}
|};
export type UploadLossAdjusterActionMutation = {|
  variables: UploadLossAdjusterActionMutationVariables,
  response: UploadLossAdjusterActionMutationResponse,
|};
*/


/*
mutation UploadLossAdjusterActionMutation(
  $input: ClaimLossAdjusterDocumentCreate!
) {
  createClaimLossAdjusterDocument(input: $input) {
    success
    messages
    result {
      id
      uploadDate
      company {
        companyName
        id
      }
      reportType {
        reportTypeName
      }
      url
      private
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimLossAdjusterDocumentCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ClaimLossAdjusterDocumentCreate!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uploadDate",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reportType",
  "storageKey": null,
  "args": null,
  "concreteType": "LossAdjusterReportType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reportTypeName",
      "args": null,
      "storageKey": null
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "private",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UploadLossAdjusterActionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimLossAdjusterDocument",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimLossAdjusterDocumentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimLossAdjusterDocument",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "company",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ]
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UploadLossAdjusterActionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimLossAdjusterDocument",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimLossAdjusterDocumentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimLossAdjusterDocument",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "company",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UploadLossAdjusterActionMutation",
    "id": null,
    "text": "mutation UploadLossAdjusterActionMutation(\n  $input: ClaimLossAdjusterDocumentCreate!\n) {\n  createClaimLossAdjusterDocument(input: $input) {\n    success\n    messages\n    result {\n      id\n      uploadDate\n      company {\n        companyName\n        id\n      }\n      reportType {\n        reportTypeName\n      }\n      url\n      private\n      description\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4108e863b663baada215c51b5229d850';
module.exports = node;
