import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";
import Input from "components/Formik/Input";
import { SingleReactSelect } from "components/Formik/ReactSelect";

import useLineItemOptions from "hooks/useLineItemOptions";
import AddItemGroupForm from "./AddItemGroupForm";
import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";

const AddItemForm = ({
  classes,
  portfolioType,
  values,
  isSubmitting,
  setFieldValue,
  quotationType,
  isProperties,
  quote,
  industryRatesAutoFill,
}) => {
  const meta = useMetaContext();
  const variables = {
    location: { portfolioType },
    centre: { portfolioType, quotationType },

    isItem: Boolean(values.labour_costCentreId),
    item: {
      portfolioType,
      costCentreId: values.labour_costCentreId || 0,
      quotationType,
    },

    isMaterialItem: Boolean(values.material_costCentreId),
    materialItem: {
      portfolioType,
      costCentreId: values.material_costCentreId || 0,
      quotationType,
    },
    jobScopeWhere: {
      insurerId: meta.claim.insurer.companyId,
      quotationType,
      portfolioType,
    },
    isJobScope:
      !meta.claim.view.actions.updateScopeType.isDisabled &&
      meta.claim.view.actions.updateScopeType.isDisplay,
    isProperties: isProperties ? true : false,
    properties: {
      insurerId: meta.claim.insurer.companyId,
      portfolioType: portfolioType,
    },
  };
  const [
    locationOptions,
    centres,
    labourItems,
    materialItems,
    jobScopeOptions,
    propertiesOptions,
  ] = useLineItemOptions(variables, [
    portfolioType,
    isProperties,
    values.labour_costCentreId,
    values.material_costCentreId,
  ]);
  const centreOptions = getCentreGroups(centres);
  React.useEffect(() => {
    setFieldValue("labour_costItemId", "", false);
    setFieldValue("labour_unitId", "", false);
  }, [values.labour_costCentreId]);

  React.useEffect(() => {
    setFieldValue("material_costItemId", "", false);
    setFieldValue("material_unitId", "", false);
  }, [values.material_costCentreId]);

  return (
    <>
      {[
        {
          col: 12,
          component: SingleReactSelect,
          label: "Scope Type",
          name: "scopeTypeId",
          select: true,
          required: true,
          options: jobScopeOptions,
          disabled: meta.claim.view.actions.updateScopeType.isDisabled,
          unMountOn: !meta.claim.view.actions.updateScopeType.isDisplay,
        },
        {
          col: 6,
          component: SingleReactSelect,
          label: quote && quote.fields.propertyTypeId.label,
          name: "propertyTypeId",
          required: true,
          options: propertiesOptions,
          unMountOn: !isProperties,
        },
        {
          col: 6,
          component: Input,
          label: quote && quote.fields.propertyNumber.label,
          name: "propertyNumber",
          required: true,
          unMountOn: !isProperties,
        },
        {
          col: 6,
          component: SingleReactSelect,
          label: "Location",
          name: "locationId",
          select: true,
          required: true,
          options: locationOptions,
        },
        {
          col: 6,
          component: Input,
          label: "Dimensions",
          name: "dimensions",
          required: true,
        },
        {
          col: 6,
          component: Button,
          className: classes.groupButton,
          color: "primary",
          variant: "contained",
          leadIcon: "nature_people",
          label: "Labour",
        },
        {
          col: 6,
          component: Button,
          className: classes.groupButton,
          color: "primary",
          variant: "contained",
          leadIcon: "build",
          label: "Material",
        },
      ].map(({ col, unMountOn, component: C, ...props }, index) => (
        <Grid item xs={col} key={index}>
          {!unMountOn && <C fullWidth variant="outlined" {...props} />}
        </Grid>
      ))}
      <Grid item xs={6}>
        <AddItemGroupForm
          type="labour_"
          values={values}
          centreOptions={centreOptions.labour}
          itemOptions={labourItems}
          portfolioType={portfolioType}
          setFieldValue={setFieldValue}
          industryRatesAutoFill={industryRatesAutoFill}
        />
      </Grid>
      <Grid item xs={6}>
        <AddItemGroupForm
          type="material_"
          values={values}
          centreOptions={centreOptions.material}
          itemOptions={materialItems}
          portfolioType={portfolioType}
          setFieldValue={setFieldValue}
          industryRatesAutoFill={industryRatesAutoFill}
        />
      </Grid>
    </>
  );
};

export default withStyles((theme) => ({
  groupButton: {
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "700",
    borderRadius: "7px",
    fontSize: "1.2rem",
    marginLeft: 0,
  },
}))(AddItemForm);

const getCentreGroups = (centres) => {
  if (centres.length === 0) return { labour: [], material: [] };
  return centres.reduce(
    (total, current) => {
      if (current.group === "Labour") {
        return {
          ...total,
          labour: [
            ...total.labour,
            {
              label: current.label,
              value: current.value,
            },
          ],
        };
      } else if (current.group === "Material") {
        return {
          ...total,
          material: [
            ...total.material,
            {
              label: current.label,
              value: current.value,
            },
          ],
        };
      }
      return null;
    },
    { labour: [], material: [] }
  );
};
