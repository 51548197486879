/**
 * @flow
 * @relayHash 13882330d1b6e547fc03a5ca42244707
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimUniqueWhere = {|
  claimId: string
|};
export type AppointmentInput = {|
  appointmentDate: any,
  note?: ?string,
|};
export type AdjusterAppointmentMadeMutationVariables = {|
  where: ClaimUniqueWhere,
  input: AppointmentInput,
|};
export type AdjusterAppointmentMadeMutationResponse = {|
  +adjusterMakeAppointment: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type AdjusterAppointmentMadeMutation = {|
  variables: AdjusterAppointmentMadeMutationVariables,
  response: AdjusterAppointmentMadeMutationResponse,
|};
*/


/*
mutation AdjusterAppointmentMadeMutation(
  $where: ClaimUniqueWhere!
  $input: AppointmentInput!
) {
  adjusterMakeAppointment(where: $where, input: $input) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimUniqueWhere!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AppointmentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "adjusterMakeAppointment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AppointmentInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ClaimUniqueWhere!"
      }
    ],
    "concreteType": "ClaimJobPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdjusterAppointmentMadeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AdjusterAppointmentMadeMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AdjusterAppointmentMadeMutation",
    "id": null,
    "text": "mutation AdjusterAppointmentMadeMutation(\n  $where: ClaimUniqueWhere!\n  $input: AppointmentInput!\n) {\n  adjusterMakeAppointment(where: $where, input: $input) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d5a4ba8f894c7c528ea9b9dee9fd573';
module.exports = node;
