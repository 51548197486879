/**
 * @flow
 * @relayHash f83e6d8d0675f1e3e8279d1ca31c299a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessRole = "Adjustor" | "Administrator" | "Agent" | "Builder" | "ContentSupplier" | "Customer" | "Estimator" | "Insurance" | "InternalAB" | "LossAdjuster" | "MultiTenderAssessor" | "Restorer" | "Specialist" | "SystemAdmin" | "UNDEFINED" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimUpdateCreate = {|
  claimId: string,
  updateTypeId: string,
  responseRequired: boolean,
  message: string,
  privacy: boolean,
  receiverCompanyId?: ?string,
  portfolioType?: ?PortfolioType,
  receiverRole?: ?AccessRole,
|};
export type HistoryActionMutationVariables = {|
  input: ClaimUpdateCreate
|};
export type HistoryActionMutationResponse = {|
  +createClaimUpdate: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +id: string,
      +portfolioType: ?PortfolioType,
      +logDate: any,
      +acknowledgeDate: ?any,
      +acknowledgeUserName: ?string,
      +logCompanyName: ?string,
      +logUserName: ?string,
      +updateTypeName: ?string,
      +message: ?string,
      +receiverCompanyName: ?string,
      +acknowledged: ?boolean,
      +private: boolean,
      +actions: {|
        +acknowledge: {|
          +isDisplay: boolean
        |}
      |},
    |},
  |}
|};
export type HistoryActionMutation = {|
  variables: HistoryActionMutationVariables,
  response: HistoryActionMutationResponse,
|};
*/


/*
mutation HistoryActionMutation(
  $input: ClaimUpdateCreate!
) {
  createClaimUpdate(input: $input) {
    messages
    success
    result {
      id
      portfolioType
      logDate
      acknowledgeDate
      acknowledgeUserName
      logCompanyName
      logUserName
      updateTypeName
      message
      receiverCompanyName
      acknowledged
      private
      actions {
        acknowledge {
          isDisplay
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimUpdateCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ClaimUpdateCreate!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "portfolioType",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logDate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "acknowledgeDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "acknowledgeUserName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logCompanyName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logUserName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updateTypeName",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "message",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "receiverCompanyName",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "acknowledged",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "private",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "HistoryActionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimUpdate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimUpdateHistoryPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimUpdateHistory",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimUpdateAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "acknowledge",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HistoryActionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimUpdate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimUpdateHistoryPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimUpdateHistory",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimUpdateAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "acknowledge",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "HistoryActionMutation",
    "id": null,
    "text": "mutation HistoryActionMutation(\n  $input: ClaimUpdateCreate!\n) {\n  createClaimUpdate(input: $input) {\n    messages\n    success\n    result {\n      id\n      portfolioType\n      logDate\n      acknowledgeDate\n      acknowledgeUserName\n      logCompanyName\n      logUserName\n      updateTypeName\n      message\n      receiverCompanyName\n      acknowledged\n      private\n      actions {\n        acknowledge {\n          isDisplay\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b9345f34df198b44a8262995d8325e8';
module.exports = node;
