import React, { Component } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
import Button from "components/Mui/Button";

import ClaimDetailsPortfolioFilter from "../../ClaimDetailsPortfolioFilter";
import MakeSafeListContainer from "./MakeSafeList/MakeSafeListContainer";

import MakeSafeAppointmentMade from "./MakeSafeActions/MakeSafeAppointmentMade";
import GetMakeSafeMutation from "./MakeSafeActions/GetMakeSafeMutation";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DialogContent from "@material-ui/core/DialogContent";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

class MakeSafePresentation extends Component {
  state = {
    makeSafe: null,
    isMakeSafeCompleted: null,
    isOwn: null,
    isScopingCompany: null,
    makeSafeProgress: null,
    isShowDialogForPDF: false,
    isPrintWithRate: true,
    isPrintWithTotal: true,
  };
  handleChangePrintRate = (event) => {
    this.setState({ isPrintWithRate: event.target.checked });
  };
  handleChangePrintTotal = (event) => {
    this.setState({ isPrintWithTotal: event.target.checked });
  };

  showPDFModal = (event) => {
    this.setState({ isShowDialogForPDF: true });
  };

  handleClose = () => {
    this.setState({ isShowDialogForPDF: false });
  };

  render() {
    const { meta, makeSafes, claim, portfolios, actions } = this.props;
    const { isShowDialogForPDF } = this.state;
    const { selectedDialogOption } = this.state;
    const { isPrintWithRate } = this.state;
    const { isPrintWithTotal } = this.state;
    const makeSafe =
      makeSafes.makeSafes.edges.length !== 0
        ? makeSafes.makeSafes.edges[0].node
        : null;

    if (!makeSafe) {
      return (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Paper>
                <Grid fluid justify="space-between">
                  <Grid item xs={2}>
                    <Button
                      onClick={this.props.actions.handleNextStep}
                      fullWidth
                      label="Next Step"
                      variant="outlined"
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ position: "relative" }}>
              <ClaimDetailsPortfolioFilter
                noAll
                portfolio={{
                  hasBuilding: meta.claim.hasBuilding,
                  // hasContents: meta.claim.hasContents,
                  hasRestoration: meta.claim.hasRestoration,
                }}
                changePortfolio={actions.changePortfolio}
                value={portfolios.toLowerCase()}
              />
              <Paper>No scope found</Paper>
            </Grid>
          </Grid>
        </>
      );
    }

    const isMakeSafeCompleted = makeSafe && makeSafe.completed;
    // const isMakeSafeReviewed = makeSafe && makeSafe.reviewed
    const isOwn = makeSafe
      ? makeSafe.supplier.companyId === meta.user.company.companyId
      : false;

    const isAction =
      (!isMakeSafeCompleted && isOwn) ||
      (meta.user.type.isAdmin && meta.claim.insurer.nonPanelSupplier);

    const isScopingCompany =
      portfolios === "Building"
        ? claim.claim.building &&
          claim.claim.building.scopingSupplier &&
          claim.claim.building.scopingSupplier.companyId ===
            meta.user.company.companyId
        : claim.claim.restoration &&
          claim.claim.restoration.scopingSupplier &&
          claim.claim.restoration.scopingSupplier.companyId ===
            meta.user.company.companyId;

    const makeSafeProgress =
      portfolios === "Building"
        ? claim.claim.building && claim.claim.building.makeSafeProgress
        : claim.claim.restoration && claim.claim.restoration.makeSafeProgress;

    const makesafeCompletedDate =
      portfolios === "Building"
        ? claim.claim.building && claim.claim.building.makesafeCompletedDate
        : claim.claim.restoration &&
          claim.claim.restoration.makesafeCompletedDate;

    return (
      <>
        <MakeSafeAppointmentMade
          symbol="__makeSafeAppointmentMade"
          portfolios={portfolios}
          id={claim.claim.id}
          claimId={meta.claim.claimId}
        />
        <Grid container>
          <Grid item xs={12}>
            <Paper>
              <Grid fluid justify="space-between">
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    label="Next Step"
                    onClick={actions.handleNextStep}
                  />
                </Grid>
                <Grid item xs={2}>
                  {makeSafe &&
                    makeSafe.actions.makeSafeInitialCall.isDisplay && (
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        label="Make Safe Initial Call"
                        onClick={(e) =>
                          actions.handleInitialCall(claim.claim.id)
                        }
                      />
                    )}
                  {makeSafe &&
                    makeSafe.actions.makeSafeAppointment.isDisplay && (
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        label="Make Safe Appointment Made"
                        onClick={actions.handleAppointmentMade}
                      />
                    )}
                  {makeSafe && makeSafe.actions.onSiteMakeSafe.isDisplay && (
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      label="Onsite Make Safe Complete"
                      onClick={(e) =>
                        actions.handleOnsiteComplete(claim.claim.id)
                      }
                    />
                  )}
                  {makeSafe && makeSafe.actions.resetMakeSafe.isDisplay && (
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      disabled={makeSafe.actions.resetMakeSafe.isDisabled}
                      label={makeSafe.actions.resetMakeSafe.label}
                      onClick={actions.handleResetMakeSafe}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  {makeSafe && makeSafe.actions.addLineItem.isDisplay && (
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      label="Add New Item"
                      disabled={
                        makeSafe && makeSafe.actions.addLineItem.isDisabled
                      }
                      onClick={actions.handleAddNewItem}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    label="Make Safe"
                    fullWidth
                    leadIcon="print"
                    variant="outlined"
                    color="primary"
                    onClick={this.showPDFModal}
                  />
                  <Dialog
                    open={isShowDialogForPDF}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth="md"
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      Please select one of the following types
                    </DialogTitle>
                    <DialogContent>
                      <FormGroup row>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {isPrintWithRate ? (
                            <AttachMoneyIcon />
                          ) : (
                            <MoneyOffIcon />
                          )}
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isPrintWithRate}
                                onChange={this.handleChangePrintRate}
                                name="rate"
                              />
                            }
                            label={
                              isPrintWithRate
                                ? "With Line Item Rate"
                                : "Without Line Item Rate"
                            }
                          />
                        </div>{" "}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {isPrintWithTotal ? (
                            <AttachMoneyIcon />
                          ) : (
                            <MoneyOffIcon />
                          )}
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isPrintWithTotal}
                                onChange={this.handleChangePrintTotal}
                                name="total"
                              />
                            }
                            label={
                              isPrintWithTotal
                                ? "With Totals"
                                : "Without Totals"
                            }
                          />
                        </div>
                      </FormGroup>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() =>
                          this.handlePrintMakeSafe(
                            isPrintWithRate,
                            isPrintWithTotal
                          )
                        }
                        variant="outlined"
                        color="primary"
                        fullWidth
                        label="Print"
                      />
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={2}>
                  {makeSafe && makeSafe.actions.submitMakeSafe.isDisplay && (
                    <Button
                      label="Make Safe Completed"
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={actions.handleMakeSafeCompleted}
                      disabled={
                        makeSafe && makeSafe.actions.submitMakeSafe.isDisabled
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} style={{ position: "relative" }}>
            <ClaimDetailsPortfolioFilter
              noAll
              portfolio={{
                hasBuilding: meta.claim.hasBuilding,
                hasRestoration: meta.claim.hasRestoration,
              }}
              changePortfolio={actions.changePortfolio}
              value={portfolios.toLowerCase()}
            />
            <MakeSafeListContainer
              meta={meta}
              items={makeSafe && makeSafe.lineItems}
              isAction={isAction}
              portfolios={portfolios}
              report={makeSafe && makeSafe}
              makeSafeScopeTypes={makeSafe && makeSafe.scopeTypes}
            />
          </Grid>
        </Grid>
      </>
    );
  }
  handlePrintMakeSafe = async (isShowRate, isShowTotal) => {
    const res = await GetMakeSafeMutation({
      where: {
        id: this.props.meta.claim.claimId,
        portfolioType: this.props.portfolios,
      },
      input: {
        rate: isShowRate,
        total: isShowTotal,
      },
    });
    if (res.ok)
      window.open(
        res.pdf,
        "Make Safe Print Preview",
        "resizable, scrollbars, status"
      );
    this.handleClose();
  };
}
//
export default createFragmentContainer(
  MakeSafePresentation,
  graphql`
    fragment MakeSafePresentation_makeSafes on Query {
      makeSafes: jobMakeSafes(first: $first, after: $after, where: $where)
        @connection(key: "MakeSafeContainerQuery_makeSafes", filters: []) {
        edges {
          node {
            completed
            reviewed
            actions {
              addLineItem {
                isDisplay
                isDisabled
                label
              }
              makeSafeAppointment {
                isDisplay
                isDisabled
                label
              }
              makeSafeInitialCall {
                isDisplay
                isDisabled
                label
              }
              onSiteMakeSafe {
                isDisplay
                isDisabled
                label
              }
              resetMakeSafe {
                isDisplay
                isDisabled
                label
              }
              submitMakeSafe {
                isDisplay
                isDisabled
                label
              }
            }
            supplier {
              companyId
            }

            lineItems {
              ...MakeSafeListContainer_items
            }

            scopeTypes {
              ...MakeSafeListContainer_makeSafeScopeTypes
            }
            ...MakeSafeListContainer_report
          }
        }
      }
    }
    fragment MakeSafePresentation_claim on Query {
      claim: claimJob(where: $claimWhere) {
        id
        building {
          makesafeCompletedDate
          makeSafeProgress {
            initialCallMade
            appointmentBooked
          }
          scopingSupplier {
            companyId
          }
        }
        restoration {
          makesafeCompletedDate
          makeSafeProgress {
            initialCallMade
          }
          scopingSupplier {
            companyId
          }
        }
      }
    }
  `
);
