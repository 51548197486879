import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation EditHistoryDialogMutation(
    $input: JobWorkProgressUpdate!
  ) {
    jobWorkProgressUpdate(input: $input) {
      success
      messages
      result {
        id
        logdate
        statusdate
        completed
        status {
          statusName
          datePopup
        }
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      // updater: store => {
      //   const payload = store.getRootField('jobWorkProgressUpdate')
      //   const success = payload.getValue('success')
      //   if(success) {
      //     const result = payload.getLinkedRecord('result')
      //     const root = store.getRoot()
      //     const conn = ConnectionHandler.getConnection(root, 'StatusHistory_jobWorkProgresses')
      //     const newEdge = ConnectionHandler.createEdge(store, conn, result, 'jobWorkProgressesEdge')
      //     ConnectionHandler.insertEdgeBefore(conn, newEdge)
      //   }
      // },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.jobWorkProgressUpdate.success,
          message: res.jobWorkProgressUpdate.messages[0]
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}
