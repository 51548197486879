import React from 'react'
import { graphql } from 'relay-runtime'

import { useQuery } from 'relay-hooks'
import { requestSubscription } from 'react-relay'
import { ReactRelayContext } from 'relay-hooks'

export default ({ callSessionId, claimId }) => {
  const { props: data } = useQuery({
    query: CALL_SESSIONS_QUERY,
    variables: {
      where: { callSessionId },
    }
  })

  const { environment } = React.useContext(ReactRelayContext)
  React.useEffect(() => {
    if(callSessionId) {
      // console.log(`subscribe to room ${roomId}`)
      const subscription = requestSubscription(environment, {
        subscription: PEER_CALL_SESSION_SUBSCRIPTION,
        variables: { where: { callSessionId } },
        // updater: store => console.log('updated'),
        // onCompleted: () => console.log('stop subscription'),
        onError: error => console.log(error)
      })
      return () => {
        // console.log(`unsubscribe to room ${roomId}`)
        subscription.dispose()
      }
    }
  }, [callSessionId])

  const room = data ? data.callSessions[0].room : null
  const ownerSession = data ? data.callSessions.find(session => session.userType === 'OWNER') : null
  const guestSession = data ? data.callSessions.find(session => session.userType === 'GUEST') : null

  const isOwner = React.useMemo(() => {
    return (ownerSession && ownerSession.id) === callSessionId
  }, [callSessionId, ownerSession && ownerSession.id])

  const endLogo = room && room.account && room.account.companyConfigs && room.account.companyConfigs.endLogo
    ? `?logo=${room.account.companyConfigs.endLogo}`
    : ''
  const endStatus = room && room.account && room.account.callStatusConfigs && room.account.callStatusConfigs.find(s => s.status === 'ENDED')
  const endMessage = endStatus && endStatus.message
    ? `&message=${endStatus.message}`
    : ''

  if (room) console.log(endStatus.message)
  const endLocation = React.useMemo(() => {
    if (!isOwner) return `/video/end-call${endLogo}${endMessage}`
    if (isOwner && !claimId) return `/video/rooms/${room.id}`
    return `/claim/job-info?id=${claimId}`
  }, [isOwner, claimId, endLogo, endMessage])

  const statusMessages = (room && room.account && room.account.callStatusConfigs)
    ? room.account.callStatusConfigs.reduce((total, current) => {
      total[current.status] = current.message
      return total
    }, {})
    : null

  const consentConfigs = (room && room.account && room.account.consentConfigs)
    ? room.account.consentConfigs
    : { isChecking: false }

  return {
    ready: Boolean(ownerSession),
    room,
    ownerSession, guestSession,
    isOwner,
    statusMessages,
    endLocation,
    consentConfigs,
  }
}

const CALL_SESSIONS_QUERY = graphql`
  query useRoomSubscriptionQuery($where: CallSessionWhereInput!) {
    callSessions(where: $where) {
      id
      userType
      callStatus {
        status
        message
      }
      room {
        id
        status
        account {
          id
          clientActionsConfig {
            takingPicture
            toggleVideo
            toggleAudio
            toggleRecording
            hangup
          }

          callStatusConfigs { status message }

          companyConfigs { endLogo }

          consentConfigs { isChecking title content }
        }
      }
    }
  }
`

const PEER_CALL_SESSION_SUBSCRIPTION = graphql`
  subscription useRoomSubscription($where: CallSessionSubscriptionWhereInput) {
    callSession(where: $where) {
      id
      userType
      callStatus {
        status
        message
      }
      room {
        status
      }
    }
  }
`