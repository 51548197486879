import React from 'react'
import { Grid } from '@material-ui/core'
import VehicleReportActionBar from './VehicleReportActionBar'
import VehicleReportContent from './VehicleReportContent'

const VehicleReportPresentation = () => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
         <VehicleReportActionBar />
        </Grid>
        <Grid item xs={12}>
         <VehicleReportContent />
        </Grid>
      </Grid>
    </div>
  )
}
export default VehicleReportPresentation