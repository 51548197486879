import React from "react";
import { moneyFormat } from "common/utils";
import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import IconButton from "components/Mui/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Paper from "components/Mui/Paper";
import LineItemListHeader from "components/LineItemList/LineItemListHeader";

const ScopeGroupCostCentreTableItems = ({ classes, portfolio, scopeType }) => {
  const [expand, setExpand] = React.useState(true);
  return (
    <>
      <Paper
        title={
          <LineItemListHeader
            cols={[
              {
                col: 2,
                name: "Unit",
                show: true,
              },
              {
                col: 2,
                name: "Qty",
                show: true,
              },
              {
                col: 3,
                name: "Total",
                show: true,
              },
              {
                col: 2,
                name: "Validation Qty",
                show: true,
              },
              {
                col: 3,
                name: "Validation Subtotal",
                show: true,
              },
            ]}
          />
        }
      >
        <div className={classes.scopeTypeRow}>
          <IconButton
            icon={expand ? "expand_less" : "expand_more"}
            className={classes.expandBtn}
            onClick={() => setExpand(!expand)}
          />
          {scopeType.scopeType.name}
        </div>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <div className={classes.root}>
            {scopeType.length === 0 ? (
              <div className={classes.noItem}>No item</div>
            ) : (
              scopeType.costCentres.map((costCentre, index) => {
                return (
                  <ListProperties
                    key={index}
                    costCentre={costCentre}
                    classes={classes}
                  />
                );
              })
            )}
          </div>
        </Collapse>
      </Paper>
    </>
  );
};

const ListProperties = ({ costCentre, classes }) => {
  const [expand, setExpand] = React.useState(true);
  return (
    <div>
      <div className={classes.propertiesRow}>
        <IconButton
          icon={expand ? "expand_less" : "expand_more"}
          className={classes.propertiesExpandBtn}
          onClick={() => setExpand(!expand)}
        />
        {costCentre.costCentre.costCentreName}
      </div>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <div>
          {costCentre.units.length === 0 ? (
            <div className={classes.noItem}>No item</div>
          ) : (
            <>
              {costCentre.units.map((unit, index) => {
                return (
                  <Grid
                    fluid
                    spacing={0}
                    className={classes.row}
                    style={{ backgroundColor: "white" }}
                    key={index}
                  >
                    {[
                      {
                        col: 2,
                        text: unit.unitName,
                        show: true,
                      },
                      {
                        col: 2,
                        text: unit.qty,
                        show: true,
                      },
                      {
                        col: 3,
                        text: moneyFormat(unit.subtotal),
                        show: true,
                      },
                      {
                        col: 2,
                        text: unit.validationQty,
                        show: true,
                      },
                      {
                        col: 3,
                        text: moneyFormat(unit.validationSubtotal),
                        show: true,
                      },
                    ]
                      .filter((e) => e.show === true)
                      .map(({ col, text, noActions, className }, key) => {
                        return (
                          <Grid
                            className={`${className} ${classes.alignCenter}`}
                            item
                            key={key}
                            xs={col}
                          >
                            {text}
                          </Grid>
                        );
                      })}
                  </Grid>
                );
              })}
            </>
          )}
        </div>
      </Collapse>
    </div>
  );
};

// export default withStyles((theme) => ({
//   row: {
//     paddingTop: theme.spacing.unit,
//     paddingBottom: theme.spacing.unit,
//     paddingLeft: theme.spacing.unit * 3,
//     paddingRight: theme.spacing.unit * 3,
//     alignItems: "center",
//     borderRadius: "8px",

//     "&:nth-child(even)": {
//       background: theme.palette.grey[100],
//     },
//     "&:hover": {
//       background: theme.palette.grey[200],
//     },
//   },
//   actionBtn: {
//     margin: 0,
//     padding: theme.spacing.unit,
//   },
//   alignCenter: {
//     ...theme.mixins.alignJustifyContainer(theme),
//     padding: "4px",
//     textAlign: "center",
//     flexWrap: "wrap",
//   },
//   sir: {
//     color: theme.palette.grey[800],
//     fontStyle: "italic",
//   },
//   menuPaper: {
//     width: "600px",
//     minHeight: "500px",
//     position: "absolute",
//     left: "45% !important",
//     top: "25% !important",
//     outline: "none",
//   },
// }))(ScopeGroupCostCentreTableItems);
export default ScopeGroupCostCentreTableItems;
