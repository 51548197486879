import React, { Component } from 'react'
import Grid from 'components/Mui/Grid'
import Input from 'components/Formik/Input'
import MoneyInput from 'components/Formik/MoneyInput'
import FloatInput from 'components/Formik/FloatInput'

class MakeSafeUpdateCostItemPresentation extends Component {
  render() {
    const { items, units, values, meta } = this.props

    return <>
      {[
        {
          col: 6, label: 'Description', name: 'costItemId',
          required: true, select: true, options: items ? items : [],
        },
        { label: 'More Details', name: 'lineDescription', rows: 3, rowsMax: 3, },
        {
          col: 6,
          label: 'Unit of Measure', name: 'unitId',
          required: true, select: true, options: units ? units : [],
          disabled: !values.costItemId,
        },
        {
          col: 6, // leadIcon: '%',
          label: 'Margin', name: 'marginRate',
          type: 'number', required: true,
          min: 0, max: 100, disabled: meta && !meta.claim.insurer.isMarginUpdate,
        },
        { col: 6, label: 'Quantity', name: 'qty', required: true, component: FloatInput },
        {
          col: 6, leadIcon: 'attach_money',
          label: 'Rate Ex GST', name: 'rate',
          component: MoneyInput, required: true
        },
      ].map(({ col, disabled, component: Component, ...props }, index) => (
        <Grid item xs={col ? col : 12} key={index}>
          {
            Component ? <Component
              {...props}
              disabled={
                disabled ? disabled : true
                && !values.costCentreId
              }
            />
            : <Input {...props}
              disabled={
                disabled ? disabled : true
                && !values.costCentreId
              }
            />
          }
        </Grid>
      ))}
    </>
  }
}

export default MakeSafeUpdateCostItemPresentation