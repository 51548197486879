import React from "react";
import Grid from "components/Mui/Grid";
import IconButton from "components/Mui/IconButton";
import Input from "components/Formik/Input";
import FloatInput from "components/Formik/FloatInput";
import MoneyInput from "components/Formik/MoneyInput";
import { Formik } from "formik";
import useLineItemOptions from "hooks/useLineItemOptions";
import UpdateContentScopeItemMutation from "./UpdateContentScopeItemMutation";
import { SingleReactSelect } from "components/Formik/ReactSelect";

const NewUpdateScopeContentForm = ({ classes, item, setEditMode }) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const refresh = window[Symbol.for("__refreshScope")];
  const [isDirectSupply, setIsDirectSupply] = React.useState(false);
  return (
    <Formik
      initialValues={{
        locationId: item.location.costLocationId,
        costCentreId: item.costCentre.costCentreId,
        costItemId: item.costItem.costItemId,
        directsupply: item.directsupply,
        lineDescription: item.lineDescription,
        purchasePrice: item.purchasePrice,
        ageOfItem: item.ageOfItem,
        proofOfLoss: item.proofOfLoss,
        qty: item.qty,
        rate: item.rate,
        policyLimit: item.policyLimit,
      }}
      validate={(values) => {
        let errors = {};
        if (!values.locationId) errors.locationId = "required!";
        if (!values.costCentreId) errors.costCentreId = "required!";
        if (!values.costItemId) errors.costItemId = "required!";
        if (!values.qty) errors.qty = "required!";
        if (!values.rate && values.rate !== 0) errors.rate = "required!";
        if (values.directsupply && !values.policyLimit)
          errors.policyLimit = "required!";
        if (isDirectSupply) {
          if (values.directsupply !== true && values.directsupply !== false)
            errors.directsupply = "Required!";
        }
        return errors;
      }}
      onSubmit={(input, actions) => {
        setTimeout(async () => {
          const variables = {
            where: { id: item.jobQuoteItemId },
            input: {
              costCentreId: Number(input.costCentreId),
              costItemId: Number(input.costItemId),
              locationId: Number(input.locationId),
              dimensions: input.dimensions,
              lineDescription: input.lineDescription,
              qty: Number(input.qty),
              rate: Number(input.rate),
              proofOfLoss: input.proofOfLoss,
              directsupply: input.directsupply,
              purchasePrice: input.purchasePrice,
              ageOfItem: input.ageOfItem,
              policyLimit: Number(input.policyLimit),
              substitutename: input.substitutename,
            },
          };

          const res = await UpdateContentScopeItemMutation(variables);
          if (res.ok) {
            snackbar.toggleOpen({ message: res.messages[0] });
            actions.setSubmitting(false);

            if (res.success) {
              refresh();
            }
          } else {
            snackbar.toggleOpen({
              message: "Unable to update the item, please try again",
            });
          }
        }, 400);
      }}
    >
      {({ values, isSubmitting, setFieldValue, handleSubmit, submitForm }) => (
        <EditItemContent
          values={values}
          classes={classes}
          setEditMode={setEditMode}
          submitForm={submitForm}
          directSupply={[isDirectSupply, setIsDirectSupply]}
        />
      )}
    </Formik>
  );
};

export default NewUpdateScopeContentForm;

const EditItemContent = ({
  classes,
  setIsAddNewItem,
  values,
  submitForm,
  setEditMode,
  directSupply,
}) => {
  const [isCommentMode, setIdCommentMode] = React.useState(false);
  const [isDirectSupply, setIsDirectSupply] = directSupply
    ? directSupply
    : [false, () => null];
  const portfolioType = "Contents";
  const quotationType = "Scope";
  const variables = {
    location: { portfolioType },
    centre: { portfolioType, quotationType },

    isItem: Boolean(values.costCentreId),
    item: {
      portfolioType,
      costCentreId: values.costCentreId || 0,
      quotationType,
    },
    materialItem: {
      portfolioType,
      costCentreId: values.material_costCentreId || 0,
      quotationType,
    },
  };

  const [locationOptions, centreOptions, itemOptions] = useLineItemOptions(
    variables,
    [portfolioType, values.costCentreId]
  );
  const directSupplyOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  React.useEffect(() => {
    const current = centreOptions.filter(
      (x) => x.value === values.costCentreId
    );
    setIsDirectSupply(current.length !== 0 && current[0].directSupplier);
  }, [values.costCentreId, centreOptions.length]);

  return (
    <Grid fluid spacing={0} className={classes.row}>
      <Grid className={classes.alignCenter} item xs={1}>
        <SingleReactSelect
          name="locationId"
          label="Location"
          select={true}
          required={true}
          options={locationOptions}
          isContentTable={true}
        />
      </Grid>
      <Grid className={classes.alignCenter} item xs={2}>
        <SingleReactSelect
          name="costCentreId"
          label="Cost Centre"
          select={true}
          required={true}
          isUnselect={true}
          options={centreOptions}
          isContentTable={true}
        />
      </Grid>
      <Grid className={classes.alignCenter} item xs={1}>
        <SingleReactSelect
          name="costItemId"
          label="Description"
          select={true}
          required={true}
          options={itemOptions}
          disabled={!values.costCentreId && itemOptions.length === 0}
          isContentTable={true}
        />
      </Grid>
      <Grid className={classes.alignCenter} item xs={1}>
        <FloatInput
          name="policyLimit"
          label="Policy Item Limit"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid className={classes.alignCenter} item xs={1}>
        <Input
          name="directsupply"
          label="Direct Supply"
          select={true}
          required={true}
          options={directSupplyOptions}
          disabled={!isDirectSupply}
        />
      </Grid>
      <Grid
        className={`${classes.alignCenter} ${classes.truncate}`}
        item
        xs={1}
      >
        <Input
          name="purchasePrice"
          label="Purchase Price"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid
        className={`${classes.alignCenter} ${classes.truncate}`}
        item
        xs={1}
      >
        <Input
          name="ageOfItem"
          label="Age of Item"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid
        className={`${classes.alignCenter} ${classes.truncate}`}
        item
        xs={1}
      >
        <Input
          name="proofOfLoss"
          label="Proof of Loss"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid className={classes.alignCenter} item xs={1}>
        <FloatInput name="qty" label="Qty" required={true} />
      </Grid>
      <Grid className={classes.alignCenter} item xs={1}>
        <MoneyInput name="rate" label="Reserve Inc GST" required={true} />
      </Grid>
      {/* {!isCommentMode ? (

      ) : (
        <Grid className={classes.alignCenter} item xs={12}>
          <Grid xs={11}>
            <Input
              name="lineDescription"
              label="More Details"
              rows="5"
              rowsMax="5"
            />
          </Grid>
          <Grid xs={1}>
            <IconButton
              icon="done"
              className={classes.actionBtn}
              onClick={() => setIdCommentMode(false)}
            />
          </Grid>
        </Grid>
      )} */}
      <Grid className={classes.alignCenter} item xs={1}>
        <div>
          {/* <IconButton
              icon="comment"
              className={classes.actionBtn}
              onClick={() => setIdCommentMode(true)}
            /> */}
          <IconButton
            icon="close"
            className={classes.actionBtn}
            onClick={() => setEditMode(false)}
          />
          <IconButton
            icon="done"
            className={classes.actionBtn}
            type="submit"
            onClick={() => {
              submitForm();
            }}
          />
        </div>
      </Grid>
      <Grid className={classes.alignCenter} item xs={12}>
        <Grid xs={12}>
          <Input
            name="lineDescription"
            label="More Details"
            rows="5"
            rowsMax="5"
          />
        </Grid>
        {/* <Grid xs={1}>
            <IconButton
              icon="done"
              className={classes.actionBtn}
              onClick={() => setIdCommentMode(false)}
            />
          </Grid> */}
      </Grid>
    </Grid>
  );
};
