import React, { Component } from "react";

import { Field } from "formik";
import { TextField } from "formik-material-ui";
import MuiTextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

export class Input extends Component {
  state = {
    autoComplete: this.props.options,
  };

  fuzzyQuery = (lists, keyWord) => {
    // let reg =  new RegExp('[\\?&]' + keyWord + '=([^&#]*)', "i");
    let reg = new RegExp(keyWord, "i");
    let resArr = [];
    lists.filter((item) => {
      for (let i in item) {
        if (reg.test(item[i])) {
          resArr.push(item);
        }
      }
    });
    return resArr;
  };

  onChangeFunc = (e) => {
    // const newOptions = this.props.options.filter(item=>item.label===e.target.value)
    const newOptions = this.fuzzyQuery(this.props.options, e.target.value);
    this.setState({ autoComplete: newOptions });
    if (!e.target.value) {
      this.setState({ autoComplete: this.props.options });
    }
  };
  render() {
    const {
      select,
      isSearched,
      leadDom,
      trailDom,
      leadIcon,
      trailIcon,
      inputRef,
      inputProps,
      readOnly,
      isUnselect,
      rows,
      rowsMax,
      options,
      isShrink,
      ...props
    } = this.props;
    const optionsWithSearch = this.state.autoComplete;
    const option = isSearched ? optionsWithSearch : options;
    return (
      <TextField
        select={select}
        {...props}
        fullWidth
        variant="outlined"
        rows={rows}
        rowsMax={rowsMax}
        multiline={Boolean(rows)}
        InputLabelProps={{
          shrink: isShrink,
        }}
        InputProps={{
          readOnly,
          startAdornment: leadIcon ? (
            <InputAdornment position="start">
              <Icon>{leadIcon}</Icon>
            </InputAdornment>
          ) : leadDom ? (
            <InputAdornment position="start">{leadDom}</InputAdornment>
          ) : null,
          endAdornment: trailIcon ? (
            <InputAdornment position="start">
              <Icon>{trailIcon}</Icon>
            </InputAdornment>
          ) : trailDom ? (
            <InputAdornment position="end">{trailDom}</InputAdornment>
          ) : null,
          inputComponent: "input",
          inputProps: {
            ref: this.props.inputRef,
            autoComplete: "removeAutoComplete",
            ...inputProps,
          },
        }}
      >
        {isSearched && (
          <MenuItem style={{ padding: "0", height: "70px" }}>
            <MuiTextField
              label="Search"
              variant="outlined"
              onClick={(e) => e.stopPropagation()}
              onChange={this.onChangeFunc}
              fullWidth
            />
          </MenuItem>
        )}
        {isUnselect && <MenuItem value=""></MenuItem>}
        {select &&
          option &&
          groupReducerMapper(option).map(({ group, groupOptions }) => {
            group !== "undefined" &&
              groupOptions.unshift({
                label: group,
                disabled: true,
                value: "sa",
                style: {
                  fontWeight: 700,
                  color: "black",
                  opacity: 0.8,
                },
              });
            return groupOptions.map((option, index) => (
              <MenuItem
                key={index}
                disabled={option.disabled}
                value={option.value}
                style={option.style}
              >
                {option.label}
              </MenuItem>
            ));
          })}
      </TextField>
    );
  }
}

const groupReducerMapper = (data) =>
  data &&
  Object.entries(
    data.reduce(
      (data, item) => ({
        ...data,
        [item.group]: data[item.group] ? [...data[item.group], item] : [item],
      }),
      {}
    )
  ).map((e) => ({ group: e[0], groupOptions: e[1] }));

class FormikInput extends Component {
  render() {
    return <Field component={Input} {...this.props} />;
  }
}

export default FormikInput;
