import React from "react";
import VariationDetailsListTable from "./VariationDetailsListTable";

const VariationDetailsList = ({
  variation,
  meta,
  portfolio,
  handleRefresh,
  isValidationAction,
  validationRefresh,
  isValidationItem,
}) => {
  const tableArray = variation && variation.scopeTypes;
  if (!tableArray) return null;
  return (
    <div>
      {tableArray.map((e, index) => {
        return (
          <VariationDetailsListTable
            key={index}
            variation={variation}
            portfolio={portfolio}
            meta={meta}
            handleRefresh={handleRefresh}
            validationRefresh={handleRefresh}
            isValidationAction={isValidationAction}
            tableItem={e}
            isValidationItem={isValidationItem}
          />
        );
      })}
    </div>
  );
};

export default VariationDetailsList;
