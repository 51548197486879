import React from 'react'
// import { withStyles } from '@material-ui/core/styles'

import Grid from 'components/Mui/Grid'
import Input from 'components/Formik/Input'
import FloatInput from 'components/Formik/FloatInput'
import MoneyInput from 'components/Formik/MoneyInput'
import useLineItemOptions from 'hooks/useLineItemOptions'

const UpdateContentItemForm = ({
  jobQuoteId, portfolioType,
  values, isSubmitting, setFieldValue,
  isQuote,
  directSupply
}) => {
  const [ isDirectSupply, setIsDirectSupply ] = directSupply
    ? directSupply
    : [ false, () => null ]
  const [touched, setTouched] = React.useState(false)
  const variables = {
    location: { portfolioType },
    centre: { portfolioType, quotationType: 'Scope' },

    isItem: Boolean(values.costCentreId),
    item: { portfolioType, costCentreId: values.costCentreId || 0, quotationType: 'Scope' },
  }

  const [locationOptions, centreOptions, itemOptions] = useLineItemOptions(
    variables, [
      portfolioType,
      values.costCentreId
    ]
  )

  React.useEffect(() => {
    if(touched) {
      setFieldValue('costItemId', '', false)
    }
    setTouched(true)
  }, [ values.costCentreId ])

  // disabled rule for direct supply
  React.useEffect(() => {
    const current = centreOptions.filter(x => x.value === values.costCentreId)
    setIsDirectSupply(current.length !== 0 && current[0].directSupplier)
  }, [ values.costCentreId, centreOptions.length ])
  return (
    <>
      {[
        {
          col: 6, component: Input,
          label: 'Location', name: 'locationId',
          select: true, required: true,
          options: locationOptions,
          disabled: isQuote
        },
        {
          col: 6, component: Input,
          label: 'Cost Centre', name: 'costCentreId',
          required: true, select: true,
          options: centreOptions,
          disabled: isQuote
        },
        {
          col: 9, component: Input,
          label: 'Description', name: 'costItemId',
          required: true, select: true,
          options: itemOptions,
          disabled: isQuote || (!values.costCentreId && itemOptions.length === 0)
        },
        {
          col: 3, component: Input,
          label: 'Direct Supply', name: 'directsupply',
          required: true, select: true,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          disabled: isQuote || !isDirectSupply,
          unMountOn: !isDirectSupply
        },
        {
          col: 12, component: Input,
          label: 'More Details', name: 'lineDescription',
          disabled: isQuote,
          rows: 3, rowsMax: 3,
        },
        {
          col: 4, component: Input,
          label: 'Purchase Price', name: 'purchasePrice',
          disabled: isQuote
        },
        {
          col: 4, component: Input,
          label: 'Age of Item', name: 'ageOfItem',
          disabled: isQuote
        },
        {
          col: 4, component: Input,
          label: 'Proof of Loss Type', name: 'proofOfLoss',
        },
        {
          col: 6, component: FloatInput,
          label: 'Qty', name: 'qty',
          required: true,
          disabled: isQuote
        },
        {
          col: 6, component: MoneyInput,
          label: isQuote ? 'Rate' : 'Reserve Price', name: 'rate',
          required: true, allowNegative: true,
        },
        {
          col: 6, component: FloatInput,
          label: 'Policy Item Limit',
          name: 'policyLimit',
          disabled: isQuote,
          // required: !isQuote,
        },
        {
          col: 6, component: Input,
          label: 'Substitute', name: 'substitutename',
          unMountOn: !isQuote
        },
      ].map(({ col, unMountOn, component: C,  ...props }, index) => (
        <Grid item xs={col} key={index}>
          {!unMountOn && <C fullWidth variant='outlined' {...props} />}
        </Grid>
      ))}
    </>
  )
}

export default UpdateContentItemForm