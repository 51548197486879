import React, { Component } from 'react'
import environment from 'common/relay'
import { QueryRenderer, graphql } from 'react-relay'

import Dialog from 'components/Mui/Dialog'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'
import GetDocumentsPdfMutation from '../GetDocumentsPdfMutation'
// import Typography from '@material-ui/core/Typography'

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './document.carousel.css'

const query = graphql`
  query DocumentViewAllActionQuery ($first: Int!, $after: String, $where: ClaimItemFilter) {
    claimDocuments(first: $first, after: $after, where: $where)
    @connection(key: "DocumentViewAllActionQuery_claimDocuments", filters: []) {
      edges {
        node {
          id
          portfolioType
          uploadDate
          company {
            companyName
          }
          url
          private
          description
        }
      }
    }
  }
`
//, $claimJobWhere: ENDataEntityKey!
// claimJob(where: $claimJobWhere) {
//       refNumber
//       incidentDetail {
//         eventType {
//           eventName
//         }
//         incidentDate
//       }
//       insured {
//         name
//         postalAddress {
//           line1
//           postcode
//           state
//           suburb
//         }
//       }
//     }

class DocumentViewAllAction extends Component {
  render() {
    const imageFormats = ['jpg', 'png', 'jpeg', 'gif']
    const videoFormats = ['mp4', 'm4v']
    const { isViewAll, viewItemIndex } = this.props

    return (
      <Dialog
        noActions
        noForm
        symbol='__documentViewAllAction'
        paperProps={{
          style: {
            maxWidth: '60%'
          }
        }}
        title={
          <Grid fluid spacing={0} justify='space-between'>
            <Grid item xs={6}>
              View documents
            </Grid>
            <Grid item xs={6}>
              <p style={{ textAlign: 'right', margin: 0 }}>
                {isViewAll && (<Button variant='outlined' color='primary'
                  onClick={this.handlePrintDocuments}
                  leadIcon='print'
                  label='Documents'
                />)}
                <Button color='primary'
                  onClick={() => window[Symbol.for('__documentViewAllAction')].handleClose()}
                  label='Close'
                />
              </p>
            </Grid>
          </Grid>
        }
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              first: 999999999,
              after: null,
              where: {
                claimId: this.props.claimId,
                portfolios: this.props.portfolios || undefined
              },
              claimJobWhere: {
                id: this.props.claimId,
              }
            }}
            render={({error, props}) => {
              if(!props) return 'Loading...'

              const { claimDocuments } = props
              const photos = !claimDocuments.edges.length
                ? null
                : claimDocuments.edges
                  .map(({ node: { url, description } }) => {
                    const ext = url.substring(url.lastIndexOf('.') + 1, url.length).toLowerCase()

                    if (!isViewAll) return { url, description, ext }
                    if (imageFormats.indexOf(ext) !== -1) return { url, description, ext }
                  })
                  .filter(x => x)
                  // .filter(y => {
                  //   const ext = url.substring(url.lastIndexOf('.') + 1, url.length).toLowerCase()
                  //   return imageFormats.indexOf(ext) !== -1
                  // })

              return (
                <Grid fluid>
                  <Grid item xs={12}>
                    <div style={{
                      height: 600,
                      maxWidth: '100%'
                    }}>
                      <Carousel
                        showThumbs={false}
                        showIndicators={false}
                        selectedItem={viewItemIndex}
                      >
                        {
                          (photos && photos.length)
                            ? photos.map(({ url, description, ext }, key) => {
                              return (
                                <Grid container fluid
                                  justify='center'
                                  alignItems='center'
                                  key={key}
                                  style={{
                                    height: 600,
                                    margin: 0,
                                    width: 'auto !important'
                                  }}
                                >
                                  <Grid item style={{ width: '100%' }}>
                                    {
                                      ext === 'pdf' ? <iframe src={url} style={{ height: 600 }} /> :
                                      imageFormats.indexOf(ext) !== -1 ? <img src={url} alt='endata' /> :
                                      videoFormats.indexOf(ext) !== -1 ? <video src={url} style={{ height: 600 }} controls/> :
                                      (<>
                                        <p style={{ color: '#fff' }}>File is not supported</p>
                                        <Button
                                          color='primary' variant='contained'
                                          label='Download'
                                          component='a' href={url} target='_blank'
                                        />
                                      </>)
                                    }
                                  </Grid>
                                  <p className='legend'>{description}</p>
                                </Grid>
                              )
                            })
                            : 'No Document'
                        }
                      </Carousel>
                    </div>
                  </Grid>
                </Grid>
              )
            }}
          />
        }
      />
    )
  }
  handlePrintDocuments = async () => {
    const res = await GetDocumentsPdfMutation({
      where: {
        claimId: this.props.claimId,
        portfolios: this.props.portfolios || undefined,
      }
    })

    if(res.ok) window.open(
      res.pdf,
      'Site Report Print Preview',
      'resizable, scrollbars, status'
    )
  }
}

export default DocumentViewAllAction