export default (user, history) => {
  const {
    applicationAlias,
    applicationFavicon,
    applicationLogo,
    displayCompanyLogo,
    logoHeight,
  } = user.company.customisation;

  // console.log(applicationAlias, applicationFavicon)

  const accessPaths = user.privileges.menus.map((x) =>
    x.nodeName.toLowerCase()
  );
  // console.log(data.user.userRole)
  // console.log(data.privileges.menus)
  const root =
    accessPaths.indexOf("dashboard") !== -1
      ? "/dashboard"
      : accessPaths.indexOf("claims") !== -1
      ? "/claims"
      : accessPaths.indexOf("reports") !== -1
      ? "/reports"
      : accessPaths.indexOf("video") !== -1
      ? "/video/rooms"
      : "/settings";

  // store token to localStorage

  localStorage.setItem("ACCESS_PATHS", JSON.stringify(accessPaths));
  localStorage.setItem("ROOT_ROUTE", root);

  localStorage.setItem(
    "APPLICATION_CUSTOM",
    JSON.stringify({
      alias: applicationAlias,
      favicon: applicationFavicon,
      logo: applicationLogo,
      companyLogo: displayCompanyLogo,
      logoHeight,
    })
  );

  return root;
};
