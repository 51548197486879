import React from 'react'
import Grid from 'components/Mui/Grid'
import { moneyFormat } from 'common/utils'
import Checkbox from '@material-ui/core/Checkbox'

const Group = ({ classes, items, item }) => {
  const [checkbox, setcheckbox] = React.useState(true)
  return (
    <Grid fluid spacing={24}
            direction='row'
            justify='center'
            alignItems='center'
          >
      {[
        { col: 1, text: (
          <Checkbox
            checked={checkbox}
            onChange={e=>{setcheckbox(!checkbox)}}
            name={items.variationItemId.toString()}
          />
        )},

        { col: 2, text: items.costCentre.costCentreName },
        { col: true, text:
          `${items.costItem.itemDescription} ${items.lineDescription ? items.lineDescription : ''}`
        },
        { col: 1, text: items.unit.unitName },
        { col: 1, text: items.qty },
        { col: 1, text: `${moneyFormat(items.rate)}` },
        { col: 1, text: `${(items.marginRate)}%` },
        { col: 1, text: `${moneyFormat(items.subtotal)}` },
      ].map(({ col, text }, key) => {
        return (
          <Grid item key={key} xs={col} className={classes.rowItem}>
            {text}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Group
