import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import MakeSafeListGroupPresentation from './MakeSafeListGroupPresentation'

class MakeSafeListGroup extends Component {
  render() {
    const { classes, groups, isAction, meta, makeSafeScopeTypes, ...rest } = this.props
    if(!groups || groups.length === 0) return 'No items found'
    if(!makeSafeScopeTypes.locations || makeSafeScopeTypes.locations === 0) return 'No items found'
    const locations = makeSafeScopeTypes.locations
    const makeSafeInfo = {
      discount: makeSafeScopeTypes.discount,
      gst: makeSafeScopeTypes.gst,
      id: makeSafeScopeTypes.id,
      margin: makeSafeScopeTypes.margin,
      scopeType: makeSafeScopeTypes.scopeType,
      subtotal: makeSafeScopeTypes.subtotal,
      total: makeSafeScopeTypes.total
    }

    return (
      <div className={classes.root}>
        {locations.map((item, index) => (
          <MakeSafeListGroupPresentation
            key={index}
            //group={group}
            meta={meta}
            classes={classes}
            isAction={isAction}
            makeSafeInfo={makeSafeInfo}
            locations={item}
            fields={makeSafeScopeTypes.fields}
            {...rest}
          />
        ))}
      </div>
    )
  }
}

export default withStyles(theme => ({
  root: {
    maxHeight: '46vh',
    overflow: 'auto'
  },
  groupRow: {
    padding: 0,
    fontWeight: 600,
    background: theme.palette.grey[200],

    '&:nth-child(even)': {
      background: theme.palette.grey[300],
    },

    '&:hover': {
      background: theme.palette.grey[400],
    }
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit
  },
  row: {
    ...theme.mixins.alignJustifyContainer(theme),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: 'center',
    borderRadius: '8px',

    '&:nth-child(even)': {
      background: theme.palette.grey[100],
    },
    '&:hover': {
      background: theme.palette.grey[200],
    }
  },
  rowItem: {
    ...theme.mixins.alignJustifyContainer(theme),
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  subGroupRow: {
    fontWeight: 600,
    borderRadius: 8,
    background: theme.palette.grey[200],

    '&:nth-child(even)': {
      background: theme.palette.grey[300],
    },

    '&:hover': {
      background: theme.palette.grey[400],
    }
  },
  expandBtn: {
    margin: 0,
    marginLeft: '20px',
    padding: theme.spacing.unit
  },
}))(MakeSafeListGroup)