import React, { Component } from 'react'
import { Formik, Form } from 'formik'
import { commonCharactersRegexp } from 'common/inputRegex'

import Input from 'components/Formik/Input'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'

import AddVariationMutation from './AddVariationMutation'

class AddVariationPresentation extends Component {
  snackbar = window[Symbol.for('__snackbar')]

  render() {
    const { claimJobId, portfolioType, reasons } = this.props

    return (
      <Formik
        initialValues={{
          claimJobId,
          portfolioType,

          variationTitle: '',
          variationReasonId: '',
          variationDescription: '',
        }}
        validate={(values) => {
          let errors = {}
          if(!values.variationTitle) errors.variationTitle = 'required!'
          if(values.variationTitle && commonCharactersRegexp.test(values.variationTitle)) errors.variationTitle = 'Invalid character!'
          if(values.variationTitle.length > 200) errors.variationTitle = 'Invalid character!'
          if(!values.variationReasonId) errors.variationReasonId = 'required!'
          if(!values.variationDescription) errors.variationDescription = 'required!'
          return errors
        }}
        onSubmit={(values, actions) => {
          setTimeout(async () => {

            const res = await AddVariationMutation({ input: values })
            if(res.ok) {
              actions.setSubmitting(false)
              this.snackbar.toggleOpen({ message: res.message })
              if(res.success) {
                this.handleClose()
              }
            }
          }, 400)
        }}
      >
        {({ isSubmitting, values }) => {
          return <Form>
            <Grid fluid>
              {[
                {
                  col: 6, required: true,
                  label: 'Name', name: 'variationTitle', 
                },
                {
                  col: 6, required: true,
                  options: reasons, select: true,
                  label: 'Reason', name: 'variationReasonId',
                },
                { 
                  required: true, multiline: true,
                  rows: 5, rowsMax: 8,
                  label: 'Description', name: 'variationDescription'
                },
              ].map(({ col = 12, ...props }, index) => (
                <Grid item xs={col} key={index}>
                  <Input {...props} />
                </Grid>  
              ))}

              <div style={{ marginLeft: 'auto' }}>
                <Button
                  label='Close' color='primary'
                  onClick={this.handleClose}
                  disabled={isSubmitting}
                />
                <Button
                  label='Submit' type='submit'
                  color='primary' variant='contained'
                  disabled={isSubmitting}
                />
              </div>
            </Grid>
          </Form>
        }}
      </Formik>
    )
  }
  handleClose = () => {
    this.props.dlRef.current.handleClose()
  }
}

export default AddVariationPresentation