/**
 * @flow
 * @relayHash eabce99b8f42a4002d89bb3a7d54e324
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessRole = "Adjustor" | "Administrator" | "Agent" | "Builder" | "ContentSupplier" | "Customer" | "Estimator" | "Insurance" | "InternalAB" | "LossAdjuster" | "MultiTenderAssessor" | "Restorer" | "Specialist" | "SystemAdmin" | "UNDEFINED" | "%future added value";
export type SettingsQueryVariables = {||};
export type SettingsQueryResponse = {|
  +me: ?{|
    +type: ?AccessRole
  |}
|};
export type SettingsQuery = {|
  variables: SettingsQueryVariables,
  response: SettingsQueryResponse,
|};
*/


/*
query SettingsQuery {
  me {
    type: userRole
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "me",
    "storageKey": null,
    "args": null,
    "concreteType": "UserProfile",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": "type",
        "name": "userRole",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SettingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SettingsQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SettingsQuery",
    "id": null,
    "text": "query SettingsQuery {\n  me {\n    type: userRole\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5bdcdd19ede00d472b9735501b26f491';
module.exports = node;
