import React, { Component, cloneElement } from "react";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

class Fabs extends Component {
  state = {
    displayFabs: false,
  };

  render() {
    const { classes, mainFab, childFabs, ...rest } = this.props;
    const { displayFabs } = this.state;
    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div className={classes.root} {...rest}>
          {childFabs.map((child, key) =>
            cloneElement(child, { key: key, display: displayFabs })
          )}
          {cloneElement(mainFab, {
            onClick: this.toggleDisplayFabs,
            mainFab: true,
          })}
        </div>
      </ClickAwayListener>
    );
  }

  toggleDisplayFabs = () =>
    this.setState({ displayFabs: !this.state.displayFabs });
  handleClickAway = () => this.setState({ displayFabs: false });
}

export default withStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "50px",
    right: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    zIndex: "1300",
  },
}))(Fabs);
