import React from "react";
import environment from "common/relay";
import { graphql, QueryRenderer } from "react-relay";

import Dialog from "components/Mui/Dialog";
import { Grid, withStyles } from "@material-ui/core";
import ScopeItemList from "../../ScopeItemList";
import ScopeValidationViewDialogFooter from "./ScopeValidationViewDialogFooter";
import ScopeValidationTitleAndButton from "./ScopeValidationTitleAndButton";

const query = graphql`
  query ScopeValidationActionQuery(
    $where: JobQuoteIndex!
    $claimParticipantsWhere: ClaimParticipantIndex
  ) {
    jobQuotes(where: $where) {
      edges {
        node {
          total
          validationTotal
          validationSavings
          validationSavingPercentage
          jobQuoteId
          actions {
            completeValidation {
              id
              isDisabled
              isDisplay
              label
            }
            resetValidation {
              label
              isDisabled
              isDisplay
            }
            submitValidation {
              label
              isDisabled
              isDisplay
            }
          }
        }
      }
    }
    claimParticipants(where: $claimParticipantsWhere) {
      companyName
      companyId
      requested
      id
    }
  }
`;

const ScopeValidationAction = (props) => {
  const { meta, portfolioType, setUpdate, isDisplay } = props;

  const portfolioMeta = meta.claim[portfolioType.toLowerCase()];
  const isApprove = portfolioMeta.authorised && meta.user.type.isAdmin;

  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    window[Symbol.for("__ScopeValidationAction")] = () => setUpdate(!refresh);
    return () => {
      setRefresh(false);
      delete window[Symbol.for("__ScopeValidationAction")];
    };
  }, [refresh]);

  const refreshToggle = () => {
    setRefresh(!refresh);
  };
  const handleClose = () => {
    const dlActions = window[Symbol.for("__ScopeValidationAction")];
    if (dlActions) dlActions.handleClose();
  };

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        where: {
          claimId: meta.claim.claimId,
          portfolios: portfolioType,
          requestCategory: "ScopeValidation",
        },
        claimParticipantsWhere: {
          claimId: meta.claim.claimId,
          portfolioTypes: portfolioType,
          participantStatus: "RequestedAndAvailable",
          participantType: "Supplier",
        },
        refresh,
      }}
      render={({ error, props }) => {
        if (!props) return "Loading...";
        return (
          <Dialog
            title={
              <ScopeValidationTitleAndButton
                portfolioType={portfolioType}
                meta={meta}
                handleClose={handleClose}
                refreshValidation={refreshToggle}
                isApprove={isApprove}
              />
            }
            noForm
            noActions
            //noClose
            minWidth="90%"
            symbol="__ScopeValidationAction"
            setUpdate={setUpdate}
            footerComponent={
              meta.user.type.isAdmin ? (
                <ScopeValidationViewDialogFooter
                  meta={meta}
                  portfolioType={portfolioType}
                />
              ) : null
            }
            content={
              <Grid container style={{ width: "99%" }}>
                <Grid item xs={12}>
                  <ScopeItemList
                    portfolio={portfolioType}
                    meta={meta}
                    isAction
                    isValidationAction={true}
                    isDisplay={isDisplay}
                    setValidationRefresh={refreshToggle}
                    isValidationItem={true}
                  />
                </Grid>
              </Grid>
            }
          />
        );
      }}
    />
  );
};
export default withStyles((theme) => ({
  portfolioIcon: {
    textAlign: "end",
  },
}))(ScopeValidationAction);
