import React from 'react'
import { fetchQuery, graphql } from 'relay-runtime'
import environment from 'common/relay'
import { withStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import BuilderOptionForm from 'pages/User/Settings/Actions/BuilderOptionForm'

const initObject = {
  type: 'add',
  name: '', phone: '', disabled: 'no' ,
  where: {
    id: '', portfolioType: ''
  }
}

const Supervisor = ({ classes }) => {
  const [ refresh, setRefresh ] = React.useState(false)
  window[Symbol.for('__refreshSupervisorSettings')] = () => {
    setRefresh(!refresh)
  }

  const [supervisors, setSupervisors] = React.useState(null)
  const variables = {
    after: null,
    first: 100000,
    where: {
      isDisabled: null
    },
  };
  React.useEffect(() => {
    fetchQuery(environment, query, variables)
      .then(data => {
        const supervisors = data.supervisors.edges.map(({ node }) => node)
        setSupervisors(supervisors)
      })
  }, [ refresh ])

  const [formData, setFormData] = React.useState({ ...initObject })
  const openAddSupervisor = () => {
    setFormData({ ...initObject, type: 'add' })
    const dlActions = window[Symbol.for('__supervisorAction')]
    if(dlActions) dlActions.handleOpen()
  }

  return (
    <>
      <BuilderOptionForm
        symbol={'__supervisorAction'}
        title='Add new supervisor'
        data={formData}
        model='supervisor'
      />
      <Toolbar>
        <div className={classes.title}>
          <Typography variant='h6'>
            Supervisor list
          </Typography>
        </div>
        <div className={classes.spacer}></div>
        <div className={classes.actions}>
          <IconButton aria-label='Add'
            onClick={openAddSupervisor}
          >
            <Icon>add</Icon>
          </IconButton>
        </div>
      </Toolbar>
      {
        supervisors === null ? 'Loading...' :
        supervisors.length === 0 ? 'No supervisor found' :
        (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Disabled</TableCell>
                <TableCell padding='checkbox'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {supervisors.map((supervisor, index) => (
                <SupervisorItem
                  key={index}
                  classes={classes}
                  supervisor={supervisor}
                  setFormData={setFormData}
                />
              ))}
            </TableBody>
          </Table>
        )
      }
    </>
  )
}

const SupervisorItem = ({ classes, supervisor, setFormData }) => {
  const openUpdateSupervisor = () => {
    setFormData({
      type: 'update',

      name: supervisor.name || '',
      phoneNumber: supervisor.phoneNumber || '',
      disabled: supervisor.disabled? 'yes' : 'no',
      where: {
        id: supervisor.id,
      }
    })
    const dlActions = window[Symbol.for('__supervisorAction')]
    if(dlActions) dlActions.handleOpen()
  }

  return (
    <TableRow>
      <TableCell>{supervisor.name}</TableCell>
      <TableCell>{supervisor.phoneNumber}</TableCell>
      <TableCell>{supervisor.disabled? 'YES': 'NO'}</TableCell>
      <TableCell padding='checkbox' className={classes.actions}>
        <IconButton aria-label='Edit' className={classes.action}
          onClick={openUpdateSupervisor}
        >
          <Icon>edit</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(theme => ({
  title: {
    flex: '0 0 auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    display: 'flex'
  },
  action: {
    marginLeft: 'auto',
    marginRight: 10
  }
}))(Supervisor)

const query = graphql`
  query SupervisorQuery(
    $after: String
    $first: Int
    $where: SupervisorIndex
  ) {
    supervisors(after: $after, first: $first, where: $where) {
      edges {
        node {
          id
          name
          phoneNumber
          disabled
        }
      }
    }
  }
`
