/**
 * @flow
 * @relayHash 441d42b267a8e2f8333a262dd02dbe8a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ENDataEntityKey = {|
  id: string
|};
export type SpecialistCard_HeaderQueryVariables = {|
  where: ENDataEntityKey
|};
export type SpecialistCard_HeaderQueryResponse = {|
  +claimJob: ?{|
    +view: ?{|
      +actions: {|
        +createSpecialistRequest: {|
          +label: string,
          +isDisplay: boolean,
          +isDisabled: boolean,
        |}
      |}
    |}
  |}
|};
export type SpecialistCard_HeaderQuery = {|
  variables: SpecialistCard_HeaderQueryVariables,
  response: SpecialistCard_HeaderQueryResponse,
|};
*/


/*
query SpecialistCard_HeaderQuery(
  $where: ENDataEntityKey!
) {
  claimJob(where: $where) {
    view {
      actions {
        createSpecialistRequest {
          label
          isDisplay
          isDisabled
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataEntityKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisabled",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SpecialistCard_HeaderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "view",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJobView",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "createSpecialistRequest",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SpecialistCard_HeaderQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "view",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJobView",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "createSpecialistRequest",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SpecialistCard_HeaderQuery",
    "id": null,
    "text": "query SpecialistCard_HeaderQuery(\n  $where: ENDataEntityKey!\n) {\n  claimJob(where: $where) {\n    view {\n      actions {\n        createSpecialistRequest {\n          label\n          isDisplay\n          isDisabled\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '821a95652e80b5c4e0e7f119fe550d33';
module.exports = node;
