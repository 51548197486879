import React, { Component } from "react";
import Dialog from "components/Mui/Dialog";
import { Formik, Form } from "formik";
import { withStyles } from "@material-ui/core/styles";
import { commonCharactersRegexp } from "common/inputRegex";
import { SingleReactSelect } from "components/Formik/ReactSelect";

import Input from "components/Formik/Input";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";

import MakeSafeUpdateCostItemContainer from "./MakeSafeUpdateCostItem/MakeSafeUpdateCostItemContainer";
import MakeSafeUpdateItemMutation from "./MakeSafeUpdateItemMutation";

class MakeSafeUpdateItemPresentation extends Component {
  snackbar = window[Symbol.for("__snackbar")];

  state = {
    locationOptions: null,
    centreOptions: null,
    jobScopeOptions: null,
  };

  componentWillMount = () => {
    const { data } = this.props;

    const locationOptions = data.locations.edges.map((x) => x.node);
    const centreOptions = data.centres.edges.map((x) => x.node);
    const jobScopeOptions = data.jobScopeItems;

    this.setState({
      locationOptions,
      centreOptions,
      jobScopeOptions,
    });
  };

  render() {
    const { classes, updateItemDlRef, inititalData, meta } = this.props;
    const { locationOptions, centreOptions, jobScopeOptions } = this.state;

    return (
      <Dialog
        noForm
        noActions
        innerRef={updateItemDlRef}
        title="Update Make Safe Line Item"
        paperProps={{
          style: { maxWidth: "50%" },
        }}
        content={
          <Formik
            initialValues={{
              ...inititalData,
              marginRate: inititalData.marginRate,
              // marginRate: 0
            }}
            validate={(values) => {
              let errors = {};
              if (!values.locationId) errors.locationId = "required!";
              if (!values.dimensions) errors.dimensions = "required!";

              if (!values.costCentreId) errors.costCentreId = "required!";

              if (values.costCentreId) {
                if (!values.costItemId) errors.costItemId = "required!";
                if (!values.unitId) errors.unitId = "required!";
                // if(!values.marginRate) errors.marginRate = 'required!'
                if (!values.qty) errors.qty = "required!";
                if (!values.rate && values.rate !== 0)
                  errors.rate = "required!";
              }

              if (
                values.dimensions &&
                commonCharactersRegexp.test(values.dimensions)
              )
                errors.dimensions = "Invalid character";
              if (values.dimensions.length > 200)
                errors.dimensions = "Maximum character limit is 200";
              if (String(values.lineDescription).length > 1500)
                errors.lineDescription = "Maximum character limit is 1500";
              if (String(values.qty).length > 4 + 3)
                errors.qty = "Maximum character limit is 4";
              if (
                (String(values.rate).indexOf("-") !== -1 &&
                  String(values.rate).length > 7 + 4) ||
                (String(values.rate).indexOf("-") === -1 &&
                  String(values.rate).length > 7 + 3)
              )
                errors.rate = "Maximum character limit is 7";

              // if(values.marginRate < 0 || values.marginRate > 100)
              //   errors.marginRate = 'choose value between 0 - 100'
              // if(values.material_marginRate < 0 || values.material_marginRate > 100)
              //   errors.material_marginRate = 'choose value between 0 - 100'

              return errors;
            }}
            onSubmit={(
              { id, portfolioType, marginRate, qty, setSubmitting, ...rest },
              actions
            ) => {
              setTimeout(async () => {
                const refresh = window[Symbol.for("__refreshMakeSafe")];
                const variables = {
                  where: { id, portfolioType },
                  input: { ...rest, marginRate, qty: Number(qty) },
                };

                const res = await MakeSafeUpdateItemMutation(variables);
                if (res.ok) {
                  this.snackbar.toggleOpen({ message: res.message });
                  if (res.success) {
                    if (refresh) refresh();
                    // this.props.updateItemDlRef.current.handleClose()
                  } else {
                    actions.setSubmitting(false);
                  }
                }
              }, 400);
            }}
          >
            {({ isSubmitting, values }) => {
              return (
                <Form>
                  <Grid fluid>
                    {[
                      {
                        col: 12,
                        component: SingleReactSelect,
                        label: "Scope Type",
                        name: "scopeTypeId",
                        select: true,
                        required: true,
                        options: jobScopeOptions,
                        disabled:
                          meta.claim.view.actions.updateScopeType.isDisabled,
                        unMountOn:
                          !meta.claim.view.actions.updateScopeType.isDisplay,
                      },
                      {
                        col: 7,
                        component: Input,
                        label: "Location",
                        name: "locationId",
                        select: true,
                        required: true,
                        options: locationOptions,
                      },
                      {
                        col: 5,
                        component: Input,
                        label: "Room Size",
                        name: "dimensions",
                        required: true,
                      },
                      {
                        col: 6,
                        component: Input,
                        label: "Cost Centre",
                        name: "costCentreId",
                        select: true,
                        options: centreOptions,
                      },
                    ].map(({ col, unMount, component: C, ...props }, index) => (
                      <Grid item xs={col} key={index}>
                        {!unMount && (
                          <C fullWidth variant="outlined" {...props} />
                        )}
                      </Grid>
                    ))}
                    <MakeSafeUpdateCostItemContainer
                      values={values}
                      meta={meta}
                    />

                    <div className={classes.actionArea}>
                      <Button
                        label="Close"
                        color="primary"
                        onClick={() =>
                          this.props.updateItemDlRef.current.handleClose()
                        }
                        disabled={isSubmitting}
                      />
                      <Button
                        label="Submit"
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      />
                    </div>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}

export default withStyles((theme) => ({
  actionArea: {
    marginLeft: "auto",
  },
}))(MakeSafeUpdateItemPresentation);

/*
  $itemsWhere: QuoteCostItemIndex!

  items: quoteCostItems(where: $itemsWhere) {
    edges {
      node {
        label: itemDescription
        value: costItemId

        units {
          label: unitName
          value: unitId
        }
      }
    }
  }

  ,
  itemsWhere: {
    portfolioType: data.portfolioType,
    costCentreId: data.costCentreId
  },

  let items = data
    ? data.items.edges.map(x => x.node)
    : []

  const units = inititalData.costItemId
    ? items
      .filter(x => x.value === inititalData.costItemId)
      .map(x => x.units)[0]
    : []
*/
