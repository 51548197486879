import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
// import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation VariationDeleteItemMutation(
    $where: ENDataPortfolioKey!
  ) {
    deleteJobVariationItem(
      where: $where
    ) {
      messages
      success
    }
  }
`

export default variables => {
  // const { id } = variables.where

  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
//       updater: store => {
//         const payload = store.getRootField('deleteJobVariationItem')
// 
//         if(payload.getValue('success')) {
//           const root = store.getRoot()
//           const conn = ConnectionHandler.getConnection(root, 'VariationDetailsQuery_jobVariations')
//           const node = conn.getLinkedRecords('edges')[0].getLinkedRecord('node')
//           
//           const items = node.getLinkedRecords('lineItems')
//           const newItems = items.filter(item => item.getValue('variationItemId') !== id )
// 
//           node.setLinkedRecords(newItems, 'lineItems')
//         }
//       },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.deleteJobVariationItem.success,
          message: res.deleteJobVariationItem.messages[0]
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}