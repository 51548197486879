import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from 'components/Mui/IconButton'
import Collapse from '@material-ui/core/Collapse'
import VariationGroup from './VariationGroup'
import QuotesGroup from './QuotesGroup'

const LineItemListGroup = ({ classes, item, type }) => {
  const [ expand, setExpand ] = React.useState(true)

  return (
    <>
      <div className={classes.groupRow}>
        <IconButton icon={expand ? 'expand_less' : 'expand_more' } 
          className={classes.expandBtn}
          onClick={() => setExpand(!expand)}
        />
        {item.name}{item.items[0].dimensions ? ` - ${item.items[0].dimensions}` : ''}
      </div>
      <Collapse in={expand} timeout='auto'>
        {item.items.map((items, index) => { 
            return(
              (type === 'quotes')?(<QuotesGroup items={items} classes={classes} item={item}/>):
              (<VariationGroup items={items} classes={classes} item={item}/>) 
            )}
          )}
      </Collapse>
    </>  
  )
}

export default withStyles(theme => ({
  groupRow: {
    fontWeight: 600,
    borderRadius: 8,
    background: theme.palette.grey[200],

    '&:nth-child(even)': {
      background: theme.palette.grey[300],
    },

    '&:hover': {
      background: theme.palette.grey[400],
    }
  },
  expandBtn: {
    margin: 0,
    padding: theme.spacing.unit
  },
  bodyListItems: {
    ...theme.mixins.alignJustifyContainer(theme)
  }
}))(LineItemListGroup)