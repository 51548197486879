/**
 * @flow
 * @relayHash bf46862d4ebd3388dc3dbc3c2cb21e23
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CallSessionUserType = "GUEST" | "OWNER" | "%future added value";
export type CallStatusEnum = "CHECK_REQUIREMENTS" | "CHECK_REQUIREMENTS_FAILED" | "CONNECTED" | "ENDED" | "INIT_SESSION" | "SIGNALLING" | "SIGNALLING_FAILED" | "%future added value";
export type RoomStatus = "ENDED" | "IN_CALL" | "ON_HOLD" | "%future added value";
export type CallSessionWhereInput = {|
  callSessionId?: ?string
|};
export type useRoomSubscriptionQueryVariables = {|
  where: CallSessionWhereInput
|};
export type useRoomSubscriptionQueryResponse = {|
  +callSessions: $ReadOnlyArray<{|
    +id: string,
    +userType: ?CallSessionUserType,
    +callStatus: {|
      +status: CallStatusEnum,
      +message: string,
    |},
    +room: {|
      +id: string,
      +status: ?RoomStatus,
      +account: {|
        +id: string,
        +clientActionsConfig: {|
          +takingPicture: boolean,
          +toggleVideo: boolean,
          +toggleAudio: boolean,
          +toggleRecording: boolean,
          +hangup: boolean,
        |},
        +callStatusConfigs: $ReadOnlyArray<{|
          +status: CallStatusEnum,
          +message: string,
        |}>,
        +companyConfigs: ?{|
          +endLogo: ?string
        |},
        +consentConfigs: ?{|
          +isChecking: boolean,
          +title: ?string,
          +content: ?string,
        |},
      |},
    |},
  |}>
|};
export type useRoomSubscriptionQuery = {|
  variables: useRoomSubscriptionQueryVariables,
  response: useRoomSubscriptionQueryResponse,
|};
*/


/*
query useRoomSubscriptionQuery(
  $where: CallSessionWhereInput!
) {
  callSessions(where: $where) {
    id
    userType
    callStatus {
      status
      message
      id
    }
    room {
      id
      status
      account {
        id
        clientActionsConfig {
          takingPicture
          toggleVideo
          toggleAudio
          toggleRecording
          hangup
          id
        }
        callStatusConfigs {
          status
          message
          id
        }
        companyConfigs {
          endLogo
          id
        }
        consentConfigs {
          isChecking
          title
          content
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "CallSessionWhereInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "CallSessionWhereInput"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userType",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "message",
  "args": null,
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "takingPicture",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "toggleVideo",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "toggleAudio",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "toggleRecording",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hangup",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "endLogo",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "consentConfigs",
  "storageKey": null,
  "args": null,
  "concreteType": "ConsentConfigs",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isChecking",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "content",
      "args": null,
      "storageKey": null
    }
  ]
},
v14 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "useRoomSubscriptionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "callSessions",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CallSession",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "callStatus",
            "storageKey": null,
            "args": null,
            "concreteType": "CallStatusConfig",
            "plural": false,
            "selections": (v6/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "room",
            "storageKey": null,
            "args": null,
            "concreteType": "Room",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "account",
                "storageKey": null,
                "args": null,
                "concreteType": "Account",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "clientActionsConfig",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClientActionsConfig",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "callStatusConfigs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CallStatusConfig",
                    "plural": true,
                    "selections": (v6/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "companyConfigs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CompanyConfigs",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/)
                    ]
                  },
                  (v13/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "useRoomSubscriptionQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "callSessions",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CallSession",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "callStatus",
            "storageKey": null,
            "args": null,
            "concreteType": "CallStatusConfig",
            "plural": false,
            "selections": (v14/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "room",
            "storageKey": null,
            "args": null,
            "concreteType": "Room",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "account",
                "storageKey": null,
                "args": null,
                "concreteType": "Account",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "clientActionsConfig",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClientActionsConfig",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "callStatusConfigs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CallStatusConfig",
                    "plural": true,
                    "selections": (v14/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "companyConfigs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CompanyConfigs",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  (v13/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "useRoomSubscriptionQuery",
    "id": null,
    "text": "query useRoomSubscriptionQuery(\n  $where: CallSessionWhereInput!\n) {\n  callSessions(where: $where) {\n    id\n    userType\n    callStatus {\n      status\n      message\n      id\n    }\n    room {\n      id\n      status\n      account {\n        id\n        clientActionsConfig {\n          takingPicture\n          toggleVideo\n          toggleAudio\n          toggleRecording\n          hangup\n          id\n        }\n        callStatusConfigs {\n          status\n          message\n          id\n        }\n        companyConfigs {\n          endLogo\n          id\n        }\n        consentConfigs {\n          isChecking\n          title\n          content\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5f1a37b3963b690819518302fac19b82';
module.exports = node;
