import React from 'react'
import { Grid } from '@material-ui/core'
import { graphql, usePagination } from 'relay-hooks'
import { Route, useRouteMatch } from 'react-router-dom'

import MediaListRoot from './MediaListRoot'
import RoomList from './RoomList'

const query = graphql`
      fragment RoomListRoot_room on Account
      @argumentDefinitions(
        count: {type: "Int", defaultValue: 20}
        cursor: {type: "String"}
        where: {type: "RoomWhereInput"}
      ) {
        rooms(
          first: $count
          after: $cursor
          where: $where
        ) @connection(key: "RoomListRoot_rooms") {
          edges {
            node {
              id
              createdAt
              case { id referenceNumber name address }
              isDeleted
              callSessions { userType contact { email phone }}
              videos(isDeleted: false) { id url name }
              photos(isDeleted: false) { id url name }
            }
          }
        }
      }
    `;

export default ({ loading, fragmentData, videoLogo, keyword, setKeyword }) => {
  const [selectedRoom, setSelectedRoom] = React.useState(null)
  const { isExact } = useRouteMatch({ path: '/video/rooms' })
  const [ data, { isLoading, hasMore, loadMore } ] = usePagination(query.room(), fragmentData)
  const [listLoading, setListLoading] = React.useState(isLoading)
  if (!data) return <div>Loading...</div>

  const rooms = data.rooms.edges.map(e => e.node).filter(e => e)
  const connectionConfig = {
    getVariables(props, {count, cursor}, fragmentVariables) {
      return {
        count,
        cursor,
        where: {
          keyword: keyword
        }
      };
    },
    query: graphql`
      query RoomListRootPaginationQuery(
        $count: Int!
        $cursor: String
        $where: RoomWhereInput!
      ) {
        account {
          ...RoomListRoot_room @arguments(count: $count, cursor: $cursor, where: $where)
        }
      }
    `
  };

  const onScroll = ({target: { scrollTop, offsetHeight, scrollHeight }}, loadMore, hasMore, setListLoading) => {
    if (!hasMore()) return null
    if ((scrollTop + offsetHeight) >= scrollHeight*0.9) {
      setListLoading(true)
      loadMore(connectionConfig, 40)
    }
  }
  return (
    <Grid container style={{ margin: 0, width: '100%', height: '100%' }}>
      <Grid item style={{ height: '100%', position: 'relative', zIndex: 3 }} onScroll={e => onScroll(e, loadMore, hasMore, setListLoading)}>
        <RoomList loading={loading} rooms={rooms} selectedRoom={selectedRoom} keyword={keyword} setKeyword={setKeyword} listLoading={listLoading} setListLoading={setListLoading}/>
      </Grid>
      <Grid item xs style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {(isExact && videoLogo)
          ? <img alt='ENData Claims Video Tool' src={videoLogo} style={{ maxWidth: 600, height: 'auto' }} />
          : null
        }
        <Route path='/video/rooms/:roomId' render={props => (
          <MediaListRoot {...props} setSelectedRoom={setSelectedRoom} rooms={rooms} />
        )}/>
      </Grid>
    </Grid>
  )
}

