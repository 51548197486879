import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import { graphql } from "relay-hooks";
import { useMutation } from "react-relay-mutation";
import SpecialistRequestEdit from "pages/Claim/ClaimDetails/ClaimDetailsAction/SpecialistRequestEdit";

export default ({ requests }) => {
  if (!requests || (requests && requests.length === 0))
    return "No request found";

  const [deleteSpecialist] = useMutation(SPECIALIST_REQUEST_DELETE_MUTATION);
  const snackbar = window[Symbol.for("__snackbar")];

  const deleteRequestHandle = async (specialistRequestId) => {
    const res = await snackbar.toggleOpen({
      message: "Do you want to delete specialist",
      firstButton: "Delete",
      secondButton: "Cancel",
      type: "confirm",
    });
    if (!res.ok) return null;

    const {
      data: { success, messages },
    } = await deleteSpecialist({
      variables: { where: { specialistRequestId } },
    });

    if (success) {
      const { reload, setReload } = window[
        Symbol.for("__SpecialistCardReload")
      ];
      setReload(!reload);
    } else {
      // error message
      snackbar.toggleOpen({ message: messages[0] });
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Requested Time</TableCell>
          <TableCell>Category</TableCell>
          <TableCell>Specialist</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {requests.map(
          ({
            id,
            specialistRequestId,
            requestedDate: date,
            specialist: { companyName, companyId },
            specialistCategory: { name: category, specialistCategoryId },
            actions,
          }) => {
            return (
              <TableRow key={id}>
                <TableCell>{date}</TableCell>
                <TableCell>{category}</TableCell>
                <TableCell>{companyName}</TableCell>
                <TableCell>
                  <div style={{ display: "flex" }}>
                    {actions.updateRequest.isDisplay ? (
                      <div>
                        <IconButton
                          onClick={() => {
                            if (
                              window[
                                Symbol.for(`__specialistRequestEdit-${id}`)
                              ]
                            ) {
                              window[
                                Symbol.for(`__specialistRequestEdit-${id}`)
                              ].handleOpen();
                            }
                          }}
                          disabled={actions.updateRequest.isDisabled}
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                        <SpecialistRequestEdit
                          symbol={`__specialistRequestEdit-${id}`}
                          initialData={{
                            requestId: specialistRequestId,
                            specialistId: companyId,
                            specialistCategoryId: specialistCategoryId,
                          }}
                        />
                      </div>
                    ) : null}
                    {actions.deleteRequest.isDisplay ? (
                      <IconButton
                        onClick={() => deleteRequestHandle(specialistRequestId)}
                        disabled={actions.deleteRequest.isDisabled}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    ) : null}
                    {/* {actions.map(({ actionType, isDisabled, isDisplay }) => {
                      if (!isDisplay) return null;
                      switch (actionType) {
                        case "SPECIALIST_REQUEST_EDIT":
                          return (
                            <div>
                              <IconButton
                                onClick={() => {
                                  if (
                                    window[
                                      Symbol.for(
                                        `__specialistRequestEdit-${id}`
                                      )
                                    ]
                                  ) {
                                    window[
                                      Symbol.for(
                                        `__specialistRequestEdit-${id}`
                                      )
                                    ].handleOpen();
                                  }
                                }}
                                disabled={isDisabled}
                              >
                                <Icon>edit</Icon>
                              </IconButton>
                              <SpecialistRequestEdit
                                symbol={`__specialistRequestEdit-${id}`}
                                initialData={{
                                  requestId: specialistRequestId,
                                  specialistId: companyId,
                                  specialistCategoryId: specialistCategoryId,
                                }}
                              />
                            </div>
                          );
                        case "SPECIALIST_REQUEST_DELETE":
                          return (
                            <IconButton
                              onClick={() =>
                                deleteRequestHandle(specialistRequestId)
                              }
                              disabled={isDisabled}
                            >
                              <Icon>delete</Icon>
                            </IconButton>
                          );
                        default:
                          return null;
                      }
                    })} */}
                  </div>
                </TableCell>
              </TableRow>
            );
          }
        )}
      </TableBody>
    </Table>
  );
};

const SPECIALIST_REQUEST_DELETE_MUTATION = graphql`
  mutation SpecialistRequestTableDeleteMutation(
    $where: SpecialistRequestUniqueWhere!
  ) {
    data: specialistRequestDelete(where: $where) {
      success
      messages
      fieldErrors {
        fieldName
        level
        message
      }
    }
  }
`;
