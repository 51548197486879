import React from 'react'
import Dialog from 'components/Mui/Dialog'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'

import { Formik, Form } from 'formik'
import Input from 'components/Formik/Input'
import { SingleReactSelect } from "components/Formik/ReactSelect";
import PhoneInput from 'components/Formik/PhoneInput'
import { clientNameRegexp } from 'common/inputRegex'

import EstimatorCreateMutation from '../EstimatorCreateMutation'
import EstimatorUpdateMutation from '../EstimatorUpdateMutation'
import SupervisorCreateMutation from '../SupervisorCreateMutation'
import SupervisorUpdateMutation from '../SupervisorUpdateMutation'

const BuilderOptionForm = ({ symbol, title, data, model }) => {
  const handleClose = () => {
    const actions = window[Symbol.for(symbol)]
    if(actions) actions.handleClose()
  }
  const { type, where, ...formData } = data

  return (
    <Dialog
      noForm noActions
      symbol={symbol}
      title={title}
      content={(
        <Formik
          initialValues={{ ...formData }}
          validate={values => {
            let errors = {}

            if(!values.name) errors.name = 'Required!'
            if(clientNameRegexp.test(values.name)) errors.name = 'Invalid character'
            if(values.initials.length > 20) errors.initials = 'Maximum character limit is 20'
            if(values.name.length > 200) errors.name = 'Maximum character limit is 200'

            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(async () => {
              const refreshSupervisor = window[Symbol.for('__refreshSupervisorSettings')]
              const refreshEstimator = window[Symbol.for('__refreshEstimatorSettings')]
              const snackbar = window[Symbol.for('__snackbar')]

              const variables =
                type === 'add'
                  ? { input: { name: values.name, phoneNumber: values.phoneNumber }}
                  : { where, input: { ...values, disabled: values.disabled === 'yes' ? true : false } }

              const res =
                (model === 'supervisor' && type === 'add')      ? await SupervisorCreateMutation(variables) :
                (model === 'supervisor' && type === 'update')   ? await SupervisorUpdateMutation(variables) :
                (model === 'estimator' && type === 'add')       ? await EstimatorCreateMutation(variables) :
                (model === 'estimator' && type === 'update')    ? await EstimatorUpdateMutation(variables) :
                null

              setSubmitting(false)
              if(res) {
                snackbar.toggleOpen({ message: res.messages[0] })
                if(res.success) {
                  handleClose()
                  if(model === 'supervisor') refreshSupervisor()
                  if(model === 'estimator') refreshEstimator()
                }
              }

            }, 400)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid fluid>
                <Grid item xs={8}>
                  <Input label='Name' name='name' required />
                </Grid>
                {
                  type === 'update' &&
                  <Grid item xs={4}>
                    <SingleReactSelect
                      name="disabled"
                      label="Disabled"
                      select={true}
                      options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                    />
                  </Grid>
                }
                <Grid item xs={12}>
                  <PhoneInput label='Phone' name='phoneNumber' />
                </Grid>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    label='Cancel' color='primary'
                    onClick={handleClose}
                    disabled={isSubmitting}
                  />
                  <Button
                    label='Submit' type='submit'
                    color='primary' variant='contained'
                    disabled={isSubmitting}
                  />
                </div>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    />
  )
}

export default BuilderOptionForm
