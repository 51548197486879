import React from "react";
import environment from "common/relay";
import { graphql, QueryRenderer } from "react-relay";

import { Grid, withStyles } from "@material-ui/core";
import Button from "components/Mui/Button";
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon";
import SubmitVariationValidation from "./SubmitVariationValidation";
import CompleteVariationValidationMutation from "./CompleteVariationValidationMutation";
import ResetVariationValidation from "./ResetVariationValidationMutation";

const VariationValidationDialogTitle = ({
  portfolioType,
  meta,
  handleClose,
  refreshValidation,
  variation,
}) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const handleResetVariationValidation = async (variationId) => {
    const confirm = await snackbar.toggleOpen({
      message: "Please confirm to reset the Variation Validation",
      firstButton: "Yes",
      secondButton: "No",
      type: "confirm",
    });
    if (confirm.ok) {
      const res = await ResetVariationValidation({
        where: {
          id: variationId,
          portfolioType: portfolioType,
        },
      });
      if (res.ok) {
        if (res.success) {
          snackbar.toggleOpen({ message: res.messages[0] });
          refreshValidation(true);
        }
        snackbar.toggleOpen({ message: res.messages[0] });
      }
    }
  };
  const handleCompleteVariationValidation = async (variationId) => {
    const confirm = await snackbar.toggleOpen({
      message: "Please confirm to Complete the Variation Validation",
      firstButton: "Yes",
      secondButton: "No",
      type: "confirm",
    });
    if (confirm.ok) {
      const res = await CompleteVariationValidationMutation({
        where: {
          id: variationId,
          portfolioType: portfolioType,
        },
      });
      if (res.ok) {
        if (res.success) {
          snackbar.toggleOpen({ message: res.messages[0] });
          handleClose();
        }
        snackbar.toggleOpen({ message: res.messages[0] });
      }
    }
  };
  return (
    <Grid container spacing={3} style={{ width: "100%" }}>
      <Grid
        item
        xs={6}
        style={{
          display: "flex",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <ClaimTypeIcon type={portfolioType} size="2rem" />
        <div>Variation Validation</div>
      </Grid>
      <Grid item xs={6}>
        {variation.actions.resetValidation.isDisplay && (
          <Button
            style={{ float: "right" }}
            variant="outlined"
            color="primary"
            disabled={variation.actions.resetValidation.isDisabled}
            label={variation.actions.resetValidation.label}
            onClick={() =>
              handleResetVariationValidation(variation.variationId)
            }
          />
        )}
        {variation.actions.completeValidation.isDisplay && (
          <Button
            style={{ float: "right" }}
            variant="outlined"
            color="primary"
            disabled={variation.actions.completeValidation.isDisabled}
            label={variation.actions.completeValidation.label}
            onClick={() =>
              handleCompleteVariationValidation(variation.variationId)
            }
          />
        )}
        <SubmitVariationValidation
          variation={variation}
          setRefresh={refreshValidation}
          portfolio={portfolioType}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles((theme) => ({
  portfolioIcon: {
    textAlign: "end",
  },
}))(VariationValidationDialogTitle);
