import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation DeleteScopeItemMutation($where: ENDataPortfolioKey!) {
    delete: deleteJobQuoteItem(where: $where) {
      success
      messages
    }
  }
`
export default variables => {
  const { id } = variables.where

  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        // updater: store => {
        //   const payload = store.getRootField('deleteJobQuoteItem')
        //   if(payload.getValue('success')) {
        //     const root = store.getRoot()
        //     const conn = ConnectionHandler.getConnection(root, 'ScopeQuery_jobQuotes')

        //     const node = conn.getLinkedRecords('edges')[0].getLinkedRecord('node')

        //     const items = node.getLinkedRecords('lineItems')
        //     const newItems = items.filter(item => item.getValue('jobQuoteItemId') !== id)

        //     node.setLinkedRecords(newItems, 'lineItems')
        //   }
        // },
        onCompleted: res => {
          resolve({
            ok: true,
            success: res.delete.success,
            messages: res.delete.messages
          })
        },
        onError: errors => {
          resolve({ ok: false })
        }
      }
    )
  })
}
