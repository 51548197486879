import React from "react";
import { fetchQuery, graphql } from "relay-runtime";
import environment from "common/relay";
import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";

const query = graphql`
  query useUpdateScopeLineItemQuery(
    $where: QuoteItemSirUniqueWhere!
    $isIncluded: Boolean!
  ) {
    rates: quoteItemSir(where: $where) @include(if: $isIncluded) {
      id
      sirMarginRate
      sirRate
    }
  }
`;

export default (
  {
    portfolioType,
    unitId,
    descriptionId,
    isIncluded = false,
    setFieldValue,
    values,
    industryRatesAutoFill,
  },
  watches
) => {
  const meta = React.useContext(ClaimMetaContext);
  const [rate, setRate] = React.useState();
  const [margin, setMargin] = React.useState();

  if (industryRatesAutoFill) {
    React.useEffect(() => {
      if (unitId && descriptionId) {
        const getData = async () => {
          const queryPromise = fetchQuery(environment, query, {
            where: {
              claimId: meta.claim.claimId,
              unitId: unitId,
              costDescriptionId: descriptionId,
              portfolioType: portfolioType,
            },
            isIncluded,
          }).then(({ rates }) => {
            if (rates && rates.sirRate) {
              setRate(rates.sirRate);
            }
            if (rates && rates.sirMarginRate) {
              setMargin(rates.sirMarginRate);
            }
          });

          return () => queryPromise.resolve();
        };

        getData();
      }
    }, watches);

    React.useEffect(() => {
      setFieldValue("rate", rate);
    }, [rate]);

    React.useEffect(() => {
      setFieldValue("marginRate", margin);
    }, [margin]);
  }

  return [rate, margin];
};
