import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation CommunicationActionMutation($input: ClaimCommunicationCreate!) {
    createClaimCommunication(input: $input){
      messages
      success
      result{
        id
        acknowledged
        claimId
        communicationId
        message
        acknowledgeUserName
        acknowledgeDate
        portfolioType
        recieverCompanyId
        recieverCompanyName
        sendDate
        senderCompanyId
        senderCompanyName
        senderId
        senderName
        private
        actions {
          acknowledge {
            isDisplay
          }
        }
      }
    }
  }
`

export default input => {
  const { path } = input
  delete input.path
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables: { input },
        updater: store => {
          if(path === '/claim/communications') {
            const payload = store.getRootField('createClaimCommunication')
            const newCommunication = payload.getLinkedRecord('result')

            if(payload.getValue('success')) {
              const newCommunicationId = newCommunication.getValue('id')
              const root = store.getRoot()
              const conn = ConnectionHandler.getConnection(root, 'CommunicationsList_claimCommuications')
              const newEdge = ConnectionHandler.createEdge(store, conn, newCommunication, 'IClaimCommunicationEdge')
              ConnectionHandler.insertEdgeBefore(conn, newEdge, newCommunicationId)
            }
          }
        },
        onCompleted: res => {
          resolve({
            ok: true,
            success: res.createClaimCommunication.success,
            message: res.createClaimCommunication.messages
          })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}