import React from 'react'

export default ({ stateName, messageConfigs, kurentoError }) => {
  const [message, setMessage] = React.useState('Creating new room')
  const messageConfigsString = JSON.stringify(messageConfigs)
  // console.log(messageConfigs)
  React.useEffect(() => {
    if (messageConfigs) {
      switch (stateName) {
        case 'INIT': return setMessage(messageConfigs.INIT_SESSION)

        case 'CHECKING_CONSENT': return setMessage(messageConfigs.CHECK_REQUIREMENTS)
        case 'CHECKING_CONSENT_FAILED': return setMessage('Approve conditions to continue')

        case 'CHECKING_SUPPORT': return setMessage(messageConfigs.CHECK_REQUIREMENTS)

        case 'CHECKING_PERMISSION': return setMessage(messageConfigs.CHECK_REQUIREMENTS)
        case 'CHECKING_PERMISSION_FAILED': return setMessage('Allow camera and microphone to continue')

        case 'SIGNALLING_LOCAL': return setMessage(`Signalling...`) // messageConfigs.SIGNALLING
        case 'SIGNALLING_LOCAL_FAILED': return setMessage(`Signalling failed ${kurentoError}`)

        case 'SIGNALLING_SERVER': return setMessage(messageConfigs.SIGNALLING)
        case 'SIGNALLING_SERVER_FAILED': return setMessage('Session expired')

        case 'READY': return setMessage(messageConfigs.CONNECTED)

        default: break
      }
    }
  }, [stateName, messageConfigsString, kurentoError])

  return { message, setMessage }
}