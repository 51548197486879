import { Environment, RecordSource, Store, /* Network */ } from 'relay-runtime'
import { RelayNetworkLayer, urlMiddleware, authMiddleware, uploadMiddleware /* retryMiddleware */ } from 'react-relay-network-modern/es'
import { cacheMiddleware, /* loggingMiddleware, */ } from 'common/relayMiddlewares'
import tokenRefresh from './utils/tokenRefresh'
import { SubscriptionClient } from 'subscriptions-transport-ws'

const kickUser = () => {
  const redirectRoute = localStorage.REDIRECT_URL || '/'
  localStorage.clear()
  window.location.replace(redirectRoute)
}

const network = new RelayNetworkLayer(
  [
    cacheMiddleware(),
    urlMiddleware({
      url: req => Promise.resolve(process.env.REACT_APP_API_ENDPOINT),
      // credentials: 'include'
    }),
    // retryMiddleware({
    //   fetchTimeout: 15000,
    //   retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100, // or simple array [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600],
    //   beforeRetry: ({ forceRetry, abort, delay, attempt, lastError, req }) => {
    //     if (attempt > 10) abort();
    //     window.forceRelayRetry = forceRetry;
    //     console.log('call `forceRelayRetry()` for immediately retry! Or wait ' + delay + ' ms.');
    //   },
    //   statusCodes: [500, 503, 504], // 401
    // }),
    authMiddleware({
      token: () => localStorage.getItem('TOKEN'),
      allowEmptyToken: true,
      tokenRefreshPromise: async (req, res) => {
        if(!localStorage.REFRESH_TOKEN) kickUser()

        const response = await tokenRefresh()
          .catch(() => null)

        if(!response || (response && response.data && !response.data.userRenewToken)) {
          console.log('here 2')
          console.log(response)
          kickUser()
        }

        const { accessToken, refreshToken } = response.data.userRenewToken
        localStorage.setItem('TOKEN', accessToken)
        localStorage.setItem('REFRESH_TOKEN', refreshToken)

        return accessToken
      },
    }),
    // forceLogoutMiddleware(),
    // loggerMiddleware(),
    // loggingMiddleware,
    uploadMiddleware()
  ],
  {
    subscribeFn: (config, variables, cacheConfig, observer) => {
      const query = config.text
      const subscriptionClient = new SubscriptionClient(process.env.REACT_APP_SUBSCRIPTION_ENDPOINT, {
        reconnect: true,
        connectionParams: {
          context: {
            authorization: `Bearer ${localStorage.getItem('TOKEN')}`
          }
        }
      })

      const subscriptionId = subscriptionClient.subscribe({ query, variables }, (error, payload) => {
        if(error) console.log(error)

        observer.onNext({ data: payload })
      })

      return {
        dispose: () => subscriptionClient.unsubscribe(subscriptionId)
      }
    }
  }
)

// const network = Network.create((operation, variables, cacheConfig, uploadables) => {
//   return fetch(process.env.REACT_APP_API_ENDPOINT, {
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${localStorage.TOKEN}`
//     },
//     body: JSON.stringify({
//       query: operation.text,
//       variables
//     })
//   })
//   .then(res => {
//     console.log(res)
//     // if(res.status < 400) return res.json()
//     return res.json()
//   })
//   .catch(err => {
//     console.log(err)
//   })
// })

export default new Environment({
  network, //Network.create(fetchQuery),
  store: new Store(new RecordSource())
})