/**
 * @flow
 * @relayHash d13a633f0ae54681c2b796f17a5c827e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AnnouncementLogCreateInput = {|
  announcementId: string
|};
export type AnnouncementDialogMutationVariables = {|
  input: AnnouncementLogCreateInput
|};
export type AnnouncementDialogMutationResponse = {|
  +announcementLogCreate: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type AnnouncementDialogMutation = {|
  variables: AnnouncementDialogMutationVariables,
  response: AnnouncementDialogMutationResponse,
|};
*/


/*
mutation AnnouncementDialogMutation(
  $input: AnnouncementLogCreateInput!
) {
  announcementLogCreate(input: $input) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AnnouncementLogCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "announcementLogCreate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AnnouncementLogCreateInput!"
      }
    ],
    "concreteType": "AnnouncementLogPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AnnouncementDialogMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AnnouncementDialogMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AnnouncementDialogMutation",
    "id": null,
    "text": "mutation AnnouncementDialogMutation(\n  $input: AnnouncementLogCreateInput!\n) {\n  announcementLogCreate(input: $input) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '67481c5796a5f6f7d7be03e7c6c53251';
module.exports = node;
