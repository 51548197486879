// import moment from 'moment'
import { EditorState, convertToRaw } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import draftToHtml from 'draftjs-to-html'

// export from modules
export { default as lazyGetPage } from './lazyGetPage'
export { default as getSafe } from './getSafe'
export { default as parseFile } from './parseFile'

export { default as moneyFormat } from './moneyFormat'
export { default as dateFormat } from './dateFormat'
export { default as mapUserType } from './mapUserType'
export { default as intData } from './intData'

export const tryParse = string => {
  try { return JSON.parse(string) }
  catch(e) { return string }
}

// check if use ?!?!?
export { default as detectIE } from './detectIE'

export const setFavicon = icon => {
  const link = document.querySelector('link[rel*="icon"]')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = icon
  document.getElementsByTagName('head')[0].appendChild(link);
}

export const setTitle = title => document.querySelector('title').innerText = title

export const colorLum = (hex, lum = 0) => {
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if(hex.length < 6) hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2]
  let rgb = "#", c, i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substring(i*2,2), 16)
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
    rgb += ("00"+c).substring(c.length)
  }

  return rgb
}

export const hexToRgbA = hex => {
    let c
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
    throw new Error('Bad Hex');
}

export const getColor = (color, lum = 0, opacity = 1) => {
  // let colorArray = hexToRgbA(colorLum(color, lum)).split(',')
  let colorArray = hexToRgbA(color).split(',')
  colorArray[3] = colorArray[3].replace('1', opacity)
  colorArray = colorArray.join(',')

  return colorArray
}

export const markupToContext = markup => markup
  ? EditorState.createWithContent(stateFromHTML(markup))
  : EditorState.createEmpty()
export const contextToMarkup = context => draftToHtml(
  convertToRaw(context.getCurrentContent()),
  // hashtagConfig,
  // directional,
  // customEntityTransform
)

export const dateInputFormat = date => date.format('YYYY-MM-DDTHH:mm:ss')
// ISO and local
// export const formatDateRange = (date, separator) => {
//   const splittedDate = (date.length !== 0) ? date.split('-').map(e => e.trim()) : undefined
//   const dateFrom = splittedDate && moment(splittedDate[0], 'DD-MM-YYYY').isValid()
//     ? moment(splittedDate[0], 'DD-MM-YYYY').toISOString()
//     : undefined
//   const dateTo = splittedDate && moment(splittedDate[1], 'DD-MM-YYYY').isValid()
//     ? moment(splittedDate[1], 'DD-MM-YYYY').toISOString()
//     : undefined
//   return { dateFrom, dateTo }
// }
