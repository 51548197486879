import React from "react";
import { moneyFormat } from "common/utils";
import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import IconButton from "components/Mui/IconButton";
import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";
import VariationEditItem from "../VariationDetailsActions/VariationEditItem";
import VariationDeleteItemMutation from "../VariationDetailsActions/VariationDeleteItem/VariationDeleteItemMutation";
import VariationValidationActionAudit from "../VariationDetailsActions/VariationValidation/VariationValidationActionAudit";
import VariationValidationActionValidate from "../VariationDetailsActions/VariationValidation/VariationValidationActionValidate";

const VariationDetailsListItem = ({
  classes,
  item,
  portfolio,
  handleRefresh,
  tableItem,
  isValidationAction,
  validationRefresh,
}) => {
  const updateDfRef = React.createRef();
  const editItemOpen = () => {
    updateDfRef.current.handleOpen();
  };
  const meta = useMetaContext();
  const deleteItem = async (variationItemId) => {
    const res = await VariationDeleteItemMutation({
      where: {
        id: variationItemId,
        portfolioType: portfolio,
      },
    });

    if (res.ok) {
      handleRefresh();
    }
  };

  return (
    <>
      <Grid
        fluid
        spacing={0}
        className={classes.row}
        style={{
          backgroundColor:
            item.quoteItemType === "ValidationAudit"
              ? "yellow"
              : item.quoteItemType === "Original"
              ? "lightblue"
              : "",
        }}
      >
        >
        {[
          // cost centre
          {
            col: 2,
            text: item.costCentre.costCentreName,
            show: tableItem.fields.costCentre.isDisplay,
          },
          // description
          {
            col: true,
            text: `${item.costItem ? item.costItem.itemDescription : ""} ${
              item.lineDescription
            }`,
            show: tableItem.fields.description.isDisplay,
          },
          // sir rate
          {
            col: 1,
            text: moneyFormat(item.sirRate),
            show: tableItem.fields.sIRRate.isDisplay,
            className: classes.sir,
          },
          // unit
          {
            col: 1,
            text: item.unit && item.unit.unitName,
            show: tableItem.fields.unit.isDisplay,
          },
          // qty
          { col: 1, text: item.qty, show: tableItem.fields.qty.isDisplay },
          // rate ex
          {
            col: 1,
            text: moneyFormat(item.rate),
            show: tableItem.fields.rate.isDisplay,
          },
          // margin
          {
            col: 1,
            text: `${item.marginRate}%`,
            show: tableItem.fields.marginRate.isDisplay,
          },
          // subtotal
          {
            col: 1,
            text: moneyFormat(item.subtotal),
            show: tableItem.fields.subtotal.isDisplay,
          },
          // actions
          {
            col: 1,
            show: isValidationAction || tableItem.fields.action.isDisplay,
            text: (
              <>
                {isValidationAction ? (
                  <div>
                    {item.actions.accept.isDisplay && (
                      <VariationValidationActionAudit
                        validationType={"accept"}
                        item={item}
                        meta={meta}
                        portfolioType={portfolio}
                        validationRefresh={validationRefresh}
                      />
                    )}
                    {item.actions.decline.isDisplay && (
                      <VariationValidationActionAudit
                        validationType={"decline"}
                        item={item}
                        meta={meta}
                        portfolioType={portfolio}
                        validationRefresh={validationRefresh}
                      />
                    )}
                    {item.actions.amend.isDisplay && (
                      <VariationValidationActionAudit
                        validationType={"amend"}
                        item={item}
                        meta={meta}
                        portfolioType={portfolio}
                        validationRefresh={validationRefresh}
                      />
                    )}
                    {item.actions.validate.isDisplay && (
                      <VariationValidationActionValidate
                        validationType={"validate"}
                        handleRefresh={handleRefresh}
                        item={item}
                        meta={meta}
                        portfolioType={portfolio}
                        validationRefresh={validationRefresh}
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    <IconButton
                      icon="edit"
                      className={classes.actionBtn}
                      onClick={() => editItemOpen()}
                    />
                    <IconButton
                      icon="delete"
                      className={classes.actionBtn}
                      onClick={() => deleteItem(item.variationItemId)}
                    />
                  </div>
                )}
              </>
            ),
          },
          { col: 1, text: item.note, show: isValidationAction === true },
          {
            col: 1,
            text: item.lineStatusDescription,
            show: isValidationAction === true,
          },
        ]
          .filter((e) => e.show === true)
          .map(({ col, text, unMountOn, noActions, className }, key) => {
            return (
              <Grid
                className={`${className} ${classes.alignCenter}`}
                item
                key={key}
                xs={col}
              >
                {!noActions && text}
              </Grid>
            );
          })}
      </Grid>
      <VariationEditItem
        dlRef={updateDfRef}
        id={item.variationItemId}
        handleRefresh={handleRefresh}
        portfolioType={portfolio}
        initialData={{
          costCentreId: item.costCentre.costCentreId,
          costItemId: item.costItem.costItemId,
          locationId: item.location.costLocationId,
          dimensions: item.dimensions,
          lineDescription: item.lineDescription,
          scopeTypeId:
            item.scopeType && item.scopeType.id ? item.scopeType.id : "",
          unitId: item.unit.unitId,
          qty: item.qty,
          rate: item.rate,
          marginRate: item.marginRate,
          gst: item.gst,
        }}
      />
    </>
  );
};

export default withStyles((theme) => ({
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: "center",
    borderRadius: "8px",

    "&:nth-child(even)": {
      background: theme.palette.grey[100],
    },
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit,
  },
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
    padding: "4px",
    textAlign: "center",
    flexWrap: "wrap",
  },
  sir: {
    color: theme.palette.grey[800],
    fontStyle: "italic",
  },
}))(VariationDetailsListItem);
