import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation QuoteRemoveAuthorityMutation($where: ENDataPortfolioKey!, $input: JobQuoteRemoveAuthorityInput!) {
    jobQuoteRemoveAuthority(where: $where, input: $input) {
      messages
      success
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          resolve({ ok: true, success: res.jobQuoteRemoveAuthority.success, message: res.jobQuoteRemoveAuthority.messages[0] })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}
