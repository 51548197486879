/**
 * @flow
 * @relayHash e6823d3a6eaca07a19900c662d8d7726
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimJobStatus = "VariationApproved" | "VariationRejected" | "VariationReset" | "VariationSubmitted" | "%future added value";
export type JobRecommendationStatus = "NotRecommended" | "Recommended" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuoteRequestStatus = "AdditionalItems" | "AppointmentMade" | "AwaitingInfo" | "AwaitingInfoContents" | "ClaimFinalised" | "InitialCallMade" | "JobAuthorised" | "JobLost" | "NewClaims" | "OfferScopeAcc" | "QuoteSubmitted" | "WaitingQuotes" | "WaitingResult" | "WaitingScope" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type ClaimJobFilter = {|
  id?: ?string,
  parentId?: ?string,
  subject?: ?string,
  createDateFrom?: ?any,
  createDateTo?: ?any,
  incidentDate?: ?any,
  incidentDateFrom?: ?any,
  incidentDateTo?: ?any,
  keyWords?: ?string,
  refNumber?: ?string,
  postcode?: ?string,
  managerTeams?: ?$ReadOnlyArray<?string>,
  managers?: ?$ReadOnlyArray<string>,
  insurers?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  agents?: ?$ReadOnlyArray<string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  buildingSuppliers?: ?$ReadOnlyArray<string>,
  contentsSuppliers?: ?$ReadOnlyArray<string>,
  restorationSuppliers?: ?$ReadOnlyArray<string>,
  buildingStatus?: ?$ReadOnlyArray<string>,
  contentsStatus?: ?$ReadOnlyArray<string>,
  restorationStatus?: ?$ReadOnlyArray<string>,
  lossAdjusterStatus?: ?$ReadOnlyArray<string>,
  suppliers?: ?$ReadOnlyArray<string>,
  status?: ?$ReadOnlyArray<string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  jobStatuses?: ?$ReadOnlyArray<string>,
  claimStatusStageIds?: ?$ReadOnlyArray<string>,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  states?: ?$ReadOnlyArray<?StateEnum>,
  variationStatuses?: ?$ReadOnlyArray<?ClaimJobStatus>,
  requestStatuses?: ?$ReadOnlyArray<?QuoteRequestStatus>,
  recommendationStatus?: ?$ReadOnlyArray<JobRecommendationStatus>,
  or?: ?ClaimJobFilter,
  and?: ?ClaimJobFilter,
  not?: ?ClaimJobFilter,
|};
export type BuildingInfoQueryVariables = {|
  houseTypeWhere?: ?ClaimJobFilter,
  claddingTypeWhere?: ?ClaimJobFilter,
  roofTypeWhere?: ?ClaimJobFilter,
  dwellingTypeWhere?: ?ClaimJobFilter,
  foundationTypeWhere?: ?ClaimJobFilter,
  dwellingAgeWhere?: ?ClaimJobFilter,
|};
export type BuildingInfoQueryResponse = {|
  +houseType: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +claddingType: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +roofType: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +dwellingType: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +foundationType: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +dwellingAge: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
|};
export type BuildingInfoQuery = {|
  variables: BuildingInfoQueryVariables,
  response: BuildingInfoQueryResponse,
|};
*/


/*
query BuildingInfoQuery(
  $houseTypeWhere: ClaimJobFilter
  $claddingTypeWhere: ClaimJobFilter
  $roofTypeWhere: ClaimJobFilter
  $dwellingTypeWhere: ClaimJobFilter
  $foundationTypeWhere: ClaimJobFilter
  $dwellingAgeWhere: ClaimJobFilter
) {
  houseType: claimFilterOptions(where: $houseTypeWhere) {
    label: name
    value
    id
  }
  claddingType: claimFilterOptions(where: $claddingTypeWhere) {
    label: name
    value
    id
  }
  roofType: claimFilterOptions(where: $roofTypeWhere) {
    label: name
    value
    id
  }
  dwellingType: claimFilterOptions(where: $dwellingTypeWhere) {
    label: name
    value
    id
  }
  foundationType: claimFilterOptions(where: $foundationTypeWhere) {
    label: name
    value
    id
  }
  dwellingAge: claimFilterOptions(where: $dwellingAgeWhere) {
    label: name
    value
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "houseTypeWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "claddingTypeWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "roofTypeWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dwellingTypeWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "foundationTypeWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dwellingAgeWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "houseTypeWhere",
    "type": "ClaimJobFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "claddingTypeWhere",
    "type": "ClaimJobFilter"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "roofTypeWhere",
    "type": "ClaimJobFilter"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "dwellingTypeWhere",
    "type": "ClaimJobFilter"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "foundationTypeWhere",
    "type": "ClaimJobFilter"
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "dwellingAgeWhere",
    "type": "ClaimJobFilter"
  }
],
v10 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BuildingInfoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "houseType",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "claddingType",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "roofType",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v6/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "dwellingType",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "foundationType",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v8/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "dwellingAge",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v9/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v4/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BuildingInfoQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "houseType",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v10/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "claddingType",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v10/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "roofType",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v6/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v10/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "dwellingType",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v10/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "foundationType",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v8/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v10/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "dwellingAge",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v9/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v10/*: any*/)
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BuildingInfoQuery",
    "id": null,
    "text": "query BuildingInfoQuery(\n  $houseTypeWhere: ClaimJobFilter\n  $claddingTypeWhere: ClaimJobFilter\n  $roofTypeWhere: ClaimJobFilter\n  $dwellingTypeWhere: ClaimJobFilter\n  $foundationTypeWhere: ClaimJobFilter\n  $dwellingAgeWhere: ClaimJobFilter\n) {\n  houseType: claimFilterOptions(where: $houseTypeWhere) {\n    label: name\n    value\n    id\n  }\n  claddingType: claimFilterOptions(where: $claddingTypeWhere) {\n    label: name\n    value\n    id\n  }\n  roofType: claimFilterOptions(where: $roofTypeWhere) {\n    label: name\n    value\n    id\n  }\n  dwellingType: claimFilterOptions(where: $dwellingTypeWhere) {\n    label: name\n    value\n    id\n  }\n  foundationType: claimFilterOptions(where: $foundationTypeWhere) {\n    label: name\n    value\n    id\n  }\n  dwellingAge: claimFilterOptions(where: $dwellingAgeWhere) {\n    label: name\n    value\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8b2f078fef8c6fce2e114a511caf8888';
module.exports = node;
