/**
 * @flow
 * @relayHash c455c565941621302ddeda08c7d151f5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CallSessionUserType = "GUEST" | "OWNER" | "%future added value";
export type CallStatusEnum = "CHECK_REQUIREMENTS" | "CHECK_REQUIREMENTS_FAILED" | "CONNECTED" | "ENDED" | "INIT_SESSION" | "SIGNALLING" | "SIGNALLING_FAILED" | "%future added value";
export type RoomStatus = "ENDED" | "IN_CALL" | "ON_HOLD" | "%future added value";
export type CallSessionSubscriptionWhereInput = {|
  roomId?: ?string,
  callSessionId?: ?string,
|};
export type useVideoRoomSubscriptionVariables = {|
  where?: ?CallSessionSubscriptionWhereInput
|};
export type useVideoRoomSubscriptionResponse = {|
  +callSession: ?{|
    +id: string,
    +userType: ?CallSessionUserType,
    +callStatus: {|
      +status: CallStatusEnum,
      +message: string,
    |},
    +room: {|
      +status: ?RoomStatus,
      +videos: ?$ReadOnlyArray<{|
        +url: string
      |}>,
    |},
  |}
|};
export type useVideoRoomSubscription = {|
  variables: useVideoRoomSubscriptionVariables,
  response: useVideoRoomSubscriptionResponse,
|};
*/


/*
subscription useVideoRoomSubscription(
  $where: CallSessionSubscriptionWhereInput
) {
  callSession(where: $where) {
    id
    userType
    callStatus {
      status
      message
      id
    }
    room {
      status
      videos {
        url
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "CallSessionSubscriptionWhereInput",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "CallSessionSubscriptionWhereInput"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userType",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "message",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "useVideoRoomSubscription",
    "type": "Subscription",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "callSession",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CallSession",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "callStatus",
            "storageKey": null,
            "args": null,
            "concreteType": "CallStatusConfig",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "room",
            "storageKey": null,
            "args": null,
            "concreteType": "Room",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "videos",
                "storageKey": null,
                "args": null,
                "concreteType": "File",
                "plural": true,
                "selections": [
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "useVideoRoomSubscription",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "callSession",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CallSession",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "callStatus",
            "storageKey": null,
            "args": null,
            "concreteType": "CallStatusConfig",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "room",
            "storageKey": null,
            "args": null,
            "concreteType": "Room",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "videos",
                "storageKey": null,
                "args": null,
                "concreteType": "File",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v2/*: any*/)
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "subscription",
    "name": "useVideoRoomSubscription",
    "id": null,
    "text": "subscription useVideoRoomSubscription(\n  $where: CallSessionSubscriptionWhereInput\n) {\n  callSession(where: $where) {\n    id\n    userType\n    callStatus {\n      status\n      message\n      id\n    }\n    room {\n      status\n      videos {\n        url\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '81cfec025ab347db1a36f4bfa0eae7cc';
module.exports = node;
