/**
 * @flow
 * @relayHash 7bb25636d5d13e1cc60bf654d66514bb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type HistoriesListItem$ref = any;
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataEntityKey = {|
  id: string
|};
export type ClaimUpdateWhere = {|
  claimId: string,
  updateTypeId?: ?string,
  portfolioType?: ?PortfolioType,
|};
export type HistoriesListActionQueryVariables = {|
  historyWhere: ENDataEntityKey,
  where: ClaimUpdateWhere,
  first?: ?number,
  after?: ?string,
|};
export type HistoriesListActionQueryResponse = {|
  +claimJob: ?{|
    +view: ?{|
      +actions: {|
        +awaitingInfo: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
          +id: string,
        |},
        +createFeedback: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
          +id: string,
        |},
        +createFollowUp: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
          +id: string,
        |},
        +makeAppointment: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
          +id: string,
        |},
        +makeInitialCall: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
          +id: string,
        |},
        +changeAppointment: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
          +id: string,
        |},
      |}
    |}
  |},
  +$fragmentRefs: HistoriesListItem$ref,
|};
export type HistoriesListActionQuery = {|
  variables: HistoriesListActionQueryVariables,
  response: HistoriesListActionQueryResponse,
|};
*/


/*
query HistoriesListActionQuery(
  $historyWhere: ENDataEntityKey!
  $where: ClaimUpdateWhere!
  $first: Int
  $after: String
) {
  ...HistoriesListItem
  claimJob(where: $historyWhere) {
    view {
      actions {
        awaitingInfo {
          isDisplay
          isDisabled
          label
          id
        }
        createFeedback {
          isDisplay
          isDisabled
          label
          id
        }
        createFollowUp {
          isDisplay
          isDisabled
          label
          id
        }
        makeAppointment {
          isDisplay
          isDisabled
          label
          id
        }
        makeInitialCall {
          isDisplay
          isDisabled
          label
          id
        }
        changeAppointment {
          isDisplay
          isDisabled
          label
          id
        }
      }
    }
    id
  }
}

fragment HistoriesListItem on Query {
  claimUpdateHistories(first: $first, after: $after, where: $where) {
    edges {
      node {
        id
        portfolioType
        logDate
        acknowledgeDate
        acknowledgeUserName
        logCompanyName
        logUserName
        updateTypeName
        message
        receiverCompanyName
        acknowledged
        private
        actions {
          acknowledge {
            isDisplay
            id
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "historyWhere",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimUpdateWhere!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "historyWhere",
    "type": "ENDataEntityKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "isDisabled",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "label",
    "args": null,
    "storageKey": null
  },
  (v3/*: any*/)
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "view",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimJobView",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actions",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimJobAction",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "awaitingInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "ActionControl",
          "plural": false,
          "selections": (v4/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "createFeedback",
          "storageKey": null,
          "args": null,
          "concreteType": "ActionControl",
          "plural": false,
          "selections": (v4/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "createFollowUp",
          "storageKey": null,
          "args": null,
          "concreteType": "ActionControl",
          "plural": false,
          "selections": (v4/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "makeAppointment",
          "storageKey": null,
          "args": null,
          "concreteType": "ActionControl",
          "plural": false,
          "selections": (v4/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "makeInitialCall",
          "storageKey": null,
          "args": null,
          "concreteType": "ActionControl",
          "plural": false,
          "selections": (v4/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "changeAppointment",
          "storageKey": null,
          "args": null,
          "concreteType": "ActionControl",
          "plural": false,
          "selections": (v4/*: any*/)
        }
      ]
    }
  ]
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ClaimUpdateWhere!"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "HistoriesListActionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "HistoriesListItem",
        "args": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HistoriesListActionQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimUpdateHistories",
        "storageKey": null,
        "args": (v6/*: any*/),
        "concreteType": "ClaimUpdateHistoryConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimUpdateHistoryEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimUpdateHistory",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "portfolioType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "logDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "acknowledgeDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "acknowledgeUserName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "logCompanyName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "logUserName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "updateTypeName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "message",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "receiverCompanyName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "acknowledged",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "private",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimUpdateAction",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "acknowledge",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ActionControl",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "claimUpdateHistories",
        "args": (v6/*: any*/),
        "handle": "connection",
        "key": "HistoriesListItem_claimUpdateHistories",
        "filters": []
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "HistoriesListActionQuery",
    "id": null,
    "text": "query HistoriesListActionQuery(\n  $historyWhere: ENDataEntityKey!\n  $where: ClaimUpdateWhere!\n  $first: Int\n  $after: String\n) {\n  ...HistoriesListItem\n  claimJob(where: $historyWhere) {\n    view {\n      actions {\n        awaitingInfo {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n        createFeedback {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n        createFollowUp {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n        makeAppointment {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n        makeInitialCall {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n        changeAppointment {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment HistoriesListItem on Query {\n  claimUpdateHistories(first: $first, after: $after, where: $where) {\n    edges {\n      node {\n        id\n        portfolioType\n        logDate\n        acknowledgeDate\n        acknowledgeUserName\n        logCompanyName\n        logUserName\n        updateTypeName\n        message\n        receiverCompanyName\n        acknowledged\n        private\n        actions {\n          acknowledge {\n            isDisplay\n            id\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c6e822e0d03fb42ff4c458d59221d46e';
module.exports = node;
