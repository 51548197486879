import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation HistoryActionMutation($input: ClaimUpdateCreate!) {
    createClaimUpdate(input: $input){
      messages
      success
      result{
        id
        portfolioType
        logDate
        acknowledgeDate
        acknowledgeUserName
        logCompanyName
        logUserName
        updateTypeName
        message
        receiverCompanyName
        acknowledged
        private
        actions {
          acknowledge {
            isDisplay
          }
        }
      }
    }
  }
`

export default input => {
  const { path } = input
  delete input.path
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables: { input },
        updater: store => {
          if(path === '/claim/histories') {
            const payload = store.getRootField('createClaimUpdate')
            const newHistory = payload.getLinkedRecord('result')
            if(payload.getValue('success')) {
              const newHistoryId = newHistory.getValue('id')
              const root = store.getRoot()
              const conn = ConnectionHandler.getConnection(root, 'HistoriesListItem_claimUpdateHistories')
              const newEdge = ConnectionHandler.createEdge(store, conn, newHistory, 'IClaimHistoryEdge')
              ConnectionHandler.insertEdgeBefore(conn, newEdge, newHistoryId)
            }
          }
        },
        onCompleted: res => {
          resolve({
            ok: true,
            success: res.createClaimUpdate.success,
            message: res.createClaimUpdate.messages
          })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}
