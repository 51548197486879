import React from 'react'
import Paper from 'components/Mui/Paper'
import { Grid } from '@material-ui/core'
import Button from 'components/Mui/Button'

const VehicleReportActionBar = () => {
  return (
    <Paper style={{marginTop:'8px'}}>
      <Grid container>
        <Grid item xs={3}>
          <Button fullWidth label='Next Step'
          variant='outlined' color='primary'
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
export default VehicleReportActionBar