import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const mutation = graphql`
  mutation UpdateClaimMutation(
    $input: ClaimJobInput!
    $where: ENDataEntityKey!
  ) {
    updateClaimJob(input: $input, where: $where) {
      success
      messages

      fieldErrors {
        fieldName
        level
        message
      }

      result {
        refNumber
        view {
          fields {
            lossAdjusterStatus {
              id
              displayValue
              isDisplay
            }
          }
        }
        insured {
          name
          phone1
          phone2
          phone3
        }

        incidentDetail {
          eventType {
            eventName
          }
          cATCode {
            cATCodeName
          }
          riskAddress {
            line1
            suburb
            state
            postcode
          }
        }

        building {
          claimStatus {
            statusName
          }
          excessValue
          toCollectExcess
        }

        restoration {
          claimStatus {
            statusName
          }
          excessValue
          toCollectExcess
        }

        contents {
          claimStatus {
            statusName
          }
          toCollectExcess
          excessValue
        }

        policyCover {
          policyCoverName
          policyCoverId
        }

        policyType {
          policyTypeName
          policyTypeId
        }
      }
    }
  }
`;

export default (variables) => {
  // variables.input.policyTypeId = parseInt(variables.input.policyTypeId)
  // variables.input.eventTypeId = parseInt(variables.input.eventTypeId)
  // variables.input.catCodeId = parseInt(variables.input.catCodeId)

  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (res) => {
        resolve({
          ok: true,
          success: res.updateClaimJob.success,
          fieldErrors: res.updateClaimJob.fieldErrors,
          messages: res.updateClaimJob.messages,
        });
      },
      onError: (res) => {
        resolve({ ok: false, errors: res.res && res.res.errors });
      },
    });
  });
};
