/**
 * @flow
 * @relayHash 628a8c7c683ba6ad6c52622f9a5dcba1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimScoreFormWhere = {|
  id: string
|};
export type ClaimScoreInput = {|
  rating: number,
  additionalNote: string,
|};
export type SubmitSurveyMutationVariables = {|
  where: ClaimScoreFormWhere,
  input: ClaimScoreInput,
|};
export type SubmitSurveyMutationResponse = {|
  +completeClaimScoreForm: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +ratingCompleted: boolean,
      +logo: ?string,
      +supplierName: ?string,
    |},
  |}
|};
export type SubmitSurveyMutation = {|
  variables: SubmitSurveyMutationVariables,
  response: SubmitSurveyMutationResponse,
|};
*/


/*
mutation SubmitSurveyMutation(
  $where: ClaimScoreFormWhere!
  $input: ClaimScoreInput!
) {
  completeClaimScoreForm(where: $where, input: $input) {
    success
    messages
    result {
      ratingCompleted
      logo
      supplierName
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimScoreFormWhere!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimScoreInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ClaimScoreInput!"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ClaimScoreFormWhere!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ratingCompleted",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logo",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "supplierName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SubmitSurveyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "completeClaimScoreForm",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimScoreFormPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimScoreForm",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SubmitSurveyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "completeClaimScoreForm",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimScoreFormPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimScoreForm",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "SubmitSurveyMutation",
    "id": null,
    "text": "mutation SubmitSurveyMutation(\n  $where: ClaimScoreFormWhere!\n  $input: ClaimScoreInput!\n) {\n  completeClaimScoreForm(where: $where, input: $input) {\n    success\n    messages\n    result {\n      ratingCompleted\n      logo\n      supplierName\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3381065522c12c015b01e6581aa7d2f1';
module.exports = node;
