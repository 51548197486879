/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ClaimItem_admin$ref = any;
type ClaimItem_builder$ref = any;
type ClaimItem_claim$ref = any;
type ClaimItem_contents$ref = any;
type ClaimItem_restorer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClaimListPagination$ref: FragmentReference;
export type ClaimListPagination = {|
  +currentUser: {|
    +claimListFields: {|
      +lossAdjusterStatus: {|
        +name: string,
        +label: ?string,
        +isDisplay: boolean,
      |}
    |}
  |},
  +claimJobs: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +$fragmentRefs: ClaimItem_claim$ref & ClaimItem_admin$ref & ClaimItem_builder$ref & ClaimItem_restorer$ref & ClaimItem_contents$ref
      |}
    |}>,
    +pageInfo: ?{|
      +hasNextPage: boolean,
      +endCursor: ?string,
    |},
    +totalCount: ?number,
  |},
  +$refType: ClaimListPagination$ref,
|};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ClaimListPagination",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "claimJobs"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "after",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "where",
      "type": "ClaimJobFilter"
    },
    {
      "kind": "RootArgument",
      "name": "isAdmin",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "isBuilder",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "isRestorer",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "isContent",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "currentUser",
      "storageKey": null,
      "args": null,
      "concreteType": "AuthenticatedUser",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "claimListFields",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimListField",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "lossAdjusterStatus",
              "storageKey": null,
              "args": null,
              "concreteType": "FieldDisplay",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "label",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "isDisplay",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "claimJobs",
      "name": "__ClaimListPagination_claimJobs_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "ClaimJobConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimJobEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ClaimJob",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ClaimItem_claim",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "isAdmin",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ClaimItem_admin",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "isBuilder",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ClaimItem_builder",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "isRestorer",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ClaimItem_restorer",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "isContent",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ClaimItem_contents",
                      "args": null
                    }
                  ]
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a885a1da35ee1b6f13ee3c3fe0881c36';
module.exports = node;
