import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { IconButton, FormControl, FormHelperText, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import StarBorderOutlined from '@material-ui/icons/StarBorderOutlined';
import Star from '@material-ui/icons/Star';
import Badge from '@material-ui/core/Badge';
const icon = [
  {value: '1'},
  {value: '2'},
  {value: '3'},
  {value: '4'},
  {value: '5'},
];
const SurveyRadio = props => {
  const {classes, selectedValue, handleChange, iserror, isTouched, helperText} = props 
  return (
    <div>
      <Typography component='p' style={{ fontWeight: 'bold' }}>
        How would you rate your experience?
      </Typography>
      <FormControl  error={iserror}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>         
          {icon.map((e) => {
            return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Radio
                checked={selectedValue === e.value}
                onChange={handleChange}
                value={e.value}
                checkedIcon={
                  <IconButton
                    style={{padding:'4px', color: (e.value<=selectedValue)?'gold':'black'}}
                  >
                    <StyledBadge badgeContent={e.value} color="error">

                      <Star className={classes.icon}/>
                    </StyledBadge>
                  </IconButton>
                }
                icon={
                  <IconButton
                    style={{padding:'4px', color: (e.value<=selectedValue)?'gold':'grey'}}
                  >
                    <StyledBadge badgeContent={e.value} color="secondary">
                      {(e.value<=selectedValue)?<Star className={classes.icon}/>:<StarBorderOutlined className={classes.icon}/>}
                    </StyledBadge>
                  </IconButton>
                }
              />
            </div>
          )})}
        </div>
        {helperText && isTouched && 
          <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </div>
  )
}
export default SurveyRadio

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      color: 'white',
      backgroundColor:'black',
      right: -5,
      top: 10,
      border: `2px solid`,
      padding: '0 4px',
    },
  }),
)(Badge);
