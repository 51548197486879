import React from "react";

import { Form } from "formik";
import MoneyInput from "components/Formik/MoneyInput";
import Input from "components/Formik/Input";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";
import { useQuery, graphql } from "relay-hooks";
// import { SingleReactSelect } from 'components/Formik/ReactSelect

const query = graphql`
  query FormBodyCreateDialogQuery($where: FeeCategoryWhere!) {
    feeCategories(where: $where) {
      value: feeCategoryId
      label: name
      feeTypes {
        value: feeTypeId
        label: name
        suppliers {
          value: companyId
          label: companyName
        }
        suppliersAndAmounts {
          label: supplierName
          value: supplierId
          defaultAmount
          maxAmount
        }
      }
    }
  }
`;

export default ({
  formik,
  symbol,
  portfolios,
  claimId,
  portfolio,
  setPortfolio,
  setApprovedAmountMeta,
}) => {
  const { props: data } = useQuery({
    query,
    variables: {
      where: {
        claimId,
        claimPortfolioTypes: portfolio,
      },
    },
    dataFrom: "STORE_THEN_NETWORK",
  });

  const { values, setFieldValue, isSubmitting } = formik;
  const categoryOptions = data && data.feeCategories;

  const [feeTypeOptions, setFeeTypeOptions] = React.useState(null);
  const [supplierOptions, setSupplierOptions] = React.useState(null);

  React.useEffect(() => {
    setFieldValue("feeTypeId", "");
    setFieldValue("supplierId", "");

    const feeTypes =
      data && data.feeCategories.find((e) => e.value === values.feeCategory);
    setFeeTypeOptions(feeTypes ? feeTypes.feeTypes : [{}]);
  }, [values.feeCategory]);

  React.useEffect(() => {
    if (feeTypeOptions) {
      setFieldValue("supplierId", "");
      const suppliers = feeTypeOptions.find(
        (e) => e.value === values.feeTypeId
      );
      setSupplierOptions(suppliers ? suppliers.suppliersAndAmounts : [{}]);
    }
  }, [values.feeTypeId]);

  React.useEffect(() => {
    if (supplierOptions) {
      const supplier = supplierOptions.find(
        (e) => e.value === values.supplierId
      );
      if (supplier && !isNaN(supplier.defaultAmount)) {
        setFieldValue("authorisedAmount", supplier.defaultAmount);
      }
      if (supplier && !isNaN(supplier.maxAmount)) {
        setApprovedAmountMeta({ maxAmount: supplier.maxAmount });
      }
    }
  }, [values.supplierId]);

  React.useEffect(() => {
    setFieldValue("supplierId", "");
    setFieldValue("feeTypeId", "");
    setFieldValue("feeCategory", "");

    setPortfolio(values.claimPortfolioTypes);
  }, [values.claimPortfolioTypes]);

  if (!data) return "loading";

  return (
    <Form>
      <Grid fluid justify="flex-end">
        {[
          {
            col: 12,
            label: "Portfolio",
            leadIcon: "event",
            component: Input,
            type: "select",
            name: "claimPortfolioTypes",
            select: true,
            options: portfolios,
          },
          {
            col: 12,
            label: "Category",
            unmountOn: false,
            isClearable: true,
            leadIcon: "event",
            component: Input,
            type: "select",
            name: "feeCategory",
            select: true,
            options: categoryOptions,
          },
          {
            col: 12,
            label: "Approved Item",
            unmountOn: !values.feeCategory, // required: true,
            isClearable: true,
            leadIcon: "event",
            component: Input,
            type: "select",
            name: "feeTypeId",
            select: true,
            options: feeTypeOptions,
          },
          {
            col: 12,
            label: "Supplier",
            unmountOn: !values.feeTypeId, // required: true,
            isClearable: true,
            leadIcon: "event",
            component: Input,
            type: "select",
            name: "supplierId",
            select: true,
            options: supplierOptions,
          },
          {
            col: 12,
            label: "Approved Amount",
            unmountOn: !values.supplierId,
            component: MoneyInput,
            name: "authorisedAmount",
          },
        ].map(
          (
            { col, component: Component, unmountOn, readOnly = false, ...rest },
            key
          ) => {
            return (
              !unmountOn && (
                <Grid item xs={col} key={key}>
                  {Component && (
                    <Component readOnly={readOnly} {...rest} required={true} />
                  )}
                </Grid>
              )
            );
          }
        )}
        <Grid item xs={12} style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }} />
          <Button
            label="Close"
            disabled={isSubmitting}
            onClick={() => {
              if (
                window[Symbol.for(symbol)] &&
                window[Symbol.for(symbol)].handleClose
              ) {
                window[Symbol.for(symbol)].handleClose();
              }
            }}
          />
          <Button
            // fullWidth
            disabled={isSubmitting}
            label="Save"
            type="submit"
            variant="contained"
            color="primary"
          />
        </Grid>
      </Grid>
    </Form>
  );
};
