import React from 'react'
import ReactPaginate from 'react-paginate'
import { withStyles } from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import './style.css'

const Pagination = props => {
  const {onSelect, setOnSelect, totalPage}= props
  const test = props => {
    setOnSelect(props.selected+1)
  }
  return (
    <ReactPaginate
      previousLabel={<NavigateBeforeIcon/>}
      nextLabel={<NavigateNextIcon />}
      breakLabel={'...'}
      breakClassName={'break-me'}
      forcePage={onSelect-1}
      pageCount={totalPage}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={test}
      containerClassName={'pagination'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
    />
  )
}

export default withStyles(() => ({
  container: {
    listStyle: 'none',
    padding: 0,
    display: 'inline'
  },
  view: {
    color: 'rgba(0, 0, 0, 0.78) !important'
  }
}))(Pagination)
