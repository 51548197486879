import 'babel-polyfill'
import 'react-app-polyfill/ie9'
import 'mutationobserver-shim'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
// import * as Sentry from '@sentry/browser'

import App from 'common/App'
import * as serviceWorker from 'common/serviceWorker'

const { REACT_APP_BASE_NAME } = process.env

// Sentry.init({
//   dsn: "https://641e8ccf9ea84cd68312533f07c74a69@sentry.io/1459587"
// })

const getUserConfirmation = (dialogKey, callback) => {
  const dialogTrigger = window[Symbol.for(dialogKey)]

  if (dialogTrigger) return dialogTrigger(callback)

  callback(true)
}

ReactDOM.render(
  <BrowserRouter
    basename={REACT_APP_BASE_NAME}
    getUserConfirmation={getUserConfirmation}
  >
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </BrowserRouter>
, document.getElementById('root'))
serviceWorker.unregister()