import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation AddContentsItemMutation($input: JobContentItemsCreate!) {
    createJobContentItems(input: $input) {
      success
      messages 

      result {
        jobQuoteItemId
        accepted

        location { costLocationId locationName } dimensions
        costCentre { costCentreId costCentreName }
        costItem { costItemId itemDescription } lineDescription
        
        unit { unitId unitName }

        directsupply
        purchasePrice
        ageOfItem
        proofOfLoss

        qty

        rate
        marginRate
        subtotal
      }

      fieldErrors {
        fieldName
        message
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      updater: store => {
        const payload = store.getRootField('createJobContentItems')

        if(payload.getValue('success')) {
          const root = store.getRoot()
          const conn = ConnectionHandler.getConnection(root, 'ScopeQuery_jobQuotes')

          const node = conn.getLinkedRecords('edges')[0].getLinkedRecord('node')
          
          const items = node.getLinkedRecords('lineItems')

          const newItem = payload.getLinkedRecords('result')
          const newItems = [ ...items, ...newItem ]
          node.setLinkedRecords(newItems, 'lineItems')
        }
      },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.createJobContentItems.success,
          messages: res.createJobContentItems.messages,
          fieldErrors: res.createJobContentItems.fieldErrors
        })
      },
      onError: errors => {
        console.log(errors)
        resolve({ ok: false })
      }
    })
  })
}