import React from "react";
import { Formik, Form } from "formik";
import { withStyles } from "@material-ui/core/styles";
import { commonCharactersRegexp } from "common/inputRegex";
import Grid from "components/Mui/Grid";
// import Input from 'components/Formik/Input'
import Button from "components/Mui/Button";

// import VariationAddLineCostItem from './VariationAddLineCostItem'
import VariationAddLineItemMutation from "./VariationAddLineItemMutation";
import AddItemForm from "components/LineItem/AddItemForm";

import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";

const VariationAddLineItemPresentation = ({
  classes,
  dlRef,
  variationId,
  portfolio,
  handleRefresh,
  scopeTypeId,
}) => {
  const meta = useMetaContext();

  const snackbar = window[Symbol.for("__snackbar")];
  // refresh: handleRefresh
  const handleClose = () => {
    if (dlRef.current) dlRef.current.handleClose();
  };

  return (
    <Formik
      initialValues={{
        ...initValues,
        scopeTypeId:
          scopeTypeId && scopeTypeId[0] && scopeTypeId[0].id
            ? scopeTypeId[0].id
            : "",
        labour_marginRate: meta.claim.insurer.isMarginUpdate ? "" : 0,
        material_marginRate: meta.claim.insurer.isMarginUpdate ? "" : 0,
      }}
      validate={validation}
      onSubmit={(values, actions) => {
        setTimeout(async () => {
          const variables = getVariables(values, portfolio, variationId);

          const res = await VariationAddLineItemMutation(variables);
          actions.setSubmitting(false);

          if (res) {
            snackbar.toggleOpen({ message: res.messages[0] });
            if (res.success) {
              handleRefresh();
            }
          }
        }, 400);
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form>
          <Grid fluid>
            <AddItemForm
              portfolioType={portfolio}
              values={values}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              quotationType="Variation"
            />
            <Grid item xs={12} className={classes.actionRow}>
              <div className={classes.actionArea}>
                <Button
                  color="primary"
                  label="Cancel"
                  onClick={handleClose}
                  disabled={isSubmitting}
                />
                <Button
                  color="primary"
                  variant="contained"
                  label="Submit"
                  type="submit"
                  disabled={isSubmitting}
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default withStyles((theme) => ({
  actionRow: {
    display: "flex",
  },
  actionArea: {
    marginLeft: "auto",
  },
}))(VariationAddLineItemPresentation);

const getVariables = (values, portfolioType, variationId) => {
  let items = [];
  if (values.labour_costCentreId)
    items.push({
      locationId: Number(values.locationId),
      dimensions: values.dimensions,
      costCentreId: Number(values.labour_costCentreId),
      scopeTypeId: values.scopeTypeId,
      costItemId: Number(values.labour_costItemId),
      lineDescription: values.labour_lineDescription,
      unitId: Number(values.labour_unitId),
      marginRate: Number(values.labour_marginRate),
      qty: Number(values.labour_qty),
      rate: Number(values.labour_rate),
    });
  if (values.material_costCentreId)
    items.push({
      locationId: Number(values.locationId),
      dimensions: values.dimensions,
      costCentreId: Number(values.material_costCentreId),
      scopeTypeId: values.scopeTypeId,
      costItemId: Number(values.material_costItemId),
      lineDescription: values.material_lineDescription,
      unitId: Number(values.material_unitId),
      marginRate: Number(values.material_marginRate),
      qty: Number(values.material_qty),
      rate: Number(values.material_rate),
    });

  return {
    input: {
      variationId,
      portfolioType,
      items,
    },
  };
};

const initValues = {
  locationId: "",
  dimensions: "",

  labour_costCentreId: "",
  labour_costItemId: "",
  labour_lineDescription: "",
  labour_unitId: "",
  labour_marginRate: "",
  labour_qty: "",
  labour_rate: "",

  material_costCentreId: "",
  material_costItemId: "",
  material_lineDescription: "",
  material_unitId: "",
  material_marginRate: "",
  material_qty: "",
  material_rate: "",
};

const validation = (values) => {
  let errors = {};
  if (!values.locationId) errors.locationId = "required!";
  if (!values.dimensions) errors.dimensions = "required!";

  if (!values.labour_costCentreId && !values.material_costCentreId) {
    errors.labour_costCentreId = "Select at aleast 1";
    errors.material_costCentreId = "Select at aleast 1";
  }

  if (values.labour_costCentreId) {
    if (!values.labour_costItemId) errors.labour_costItemId = "required!";
    if (!values.labour_unitId) errors.labour_unitId = "required!";
    if (!values.labour_qty) errors.labour_qty = "required!";
    if (!values.labour_rate && values.labour_rate !== 0)
      errors.labour_rate = "required!";
  }
  if (values.material_costCentreId) {
    if (!values.material_costItemId) errors.material_costItemId = "required!";
    if (!values.material_unitId) errors.material_unitId = "required!";
    if (!values.material_qty) errors.material_qty = "required!";
    if (!values.material_rate && values.material_rate !== 0)
      errors.material_rate = "required!";
  }

  if (values.dimensions && commonCharactersRegexp.test(values.dimensions))
    errors.dimensions = "Invalid character";
  if (values.dimensions.length > 200)
    errors.dimensions = "Maximum character limit is 200";

  if (values.labour_lineDescription.length > 1500)
    errors.labour_lineDescription = "Maximum character limit is 1500";
  if (values.material_lineDescription.length > 1500)
    errors.material_lineDescription = "Maximum character limit is 1500";

  if (values.labour_qty.length > 4 + 3)
    errors.labour_qty = "Maximum character limit is 4";
  if (values.material_qty.length > 4 + 3)
    errors.material_qty = "Maximum character limit is 4";

  if (
    (String(values.labour_rate).indexOf("-") !== -1 &&
      String(values.labour_rate).length > 7 + 4) ||
    (String(values.labour_rate).indexOf("-") === -1 &&
      String(values.labour_rate).length > 7 + 3)
  )
    errors.labour_rate = "Maximum character limit is 7";

  if (
    (String(values.material_rate).indexOf("-") !== -1 &&
      String(values.material_rate).length > 7 + 4) ||
    (String(values.material_rate).indexOf("-") === -1 &&
      String(values.material_rate).length > 7 + 3)
  )
    errors.material_rate = "Maximum character limit is 7";

  return errors;
};
