import React from 'react'
import Input from 'components/Formik/Input'
import MoneyInput from 'components/Formik/MoneyInput'
import FloatInput from 'components/Formik/FloatInput'

import Grid from 'components/Mui/Grid'

import { useMetaContext } from 'pages/Claim/ClaimDetails/ClaimMetaContext'

export default ({ values }) => {
  const meta = useMetaContext()
  return (
    <>
      {[
        {
          col: 6, label: 'Description', name: 'costItemId',
          required: true, select: true,
          options: [{label:values.itemDescription, value:values.costItemId}],
          disabled: true
        },
        {
          label: 'More Details',
          rows: 3, rowsMax: 3,
          name: 'lineDescription',
          disabled: true,
        },
        {
          col: 6,
          label: 'Unit of Measure', name: 'unitId',
          required: true, select: true,
          options: [{label:values.unitName, value:values.unitId}],
          disabled: !values.costItemId || true,
        },
        {
          col: 6, leadIcon: '%',
          label: 'Margin', name: 'marginRate',
          type: 'number', required: true,
          min: 0, max: 100, disabled: !meta.claim.insurer.isMarginUpdate
        },
        { col: 6, component: FloatInput, label: 'Quantity', name: 'qty', required: true },
        {
          col: 6, leadIcon: 'attach_money',
          label: 'Rate Ex GST', name: 'rate',
          required: true,
          component: MoneyInput,
        },
      ].map(({ col, disabled, component: Component, ...props }, index) => (
        <Grid item xs={col ? col : 12} key={index}>
          {
            Component ? <Component
              {...props}
              disabled={
                disabled ? disabled : true
                && !values.costItemId
              }
            />
            : <Input {...props}
              disabled={
                disabled ? disabled : true
                && !values.costItemId
              }
            />
          }
        </Grid>
      ))}
    </>
  )
}
