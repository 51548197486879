import React from "react";
import { withStyles, Icon, CircularProgress, Link } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import RequestExternalSourceMutation from './RequestExternalSourceMutation'

import QuickAction from "components/Mui/QuickActions/QuickAction";

const CommunicationEmbeddedAction = ({classes, claimId, label, display, toggleDisplay, ...rest}) => {
  const qaRef = React.createRef();
  const snackbar = window[Symbol.for("__snackbar")]
  const [isZoom, setIsZoom] = React.useState(false)
  const [link, setLink] = React.useState('')

  React.useEffect(() => {
    if(display) {
      const mutation = async() => {
        const res = await RequestExternalSourceMutation({
          where: {
            claimId: claimId,
          },
        });
        if(res.ok) {
          if(res.success) {
            setLink(res.message[0])
          } else {
            snackbar.toggleOpen({
              message: res.message[0],
            })
            if(window[Symbol.for('__communicationEmbeddedAction')]){
              window[Symbol.for('__communicationEmbeddedAction')].toggleOpen()
            }
            if(window[Symbol.for('__CommunicationEmbeddedActionDisplay')]){
              window[Symbol.for('__CommunicationEmbeddedActionDisplay')].toggleDisplay()
            }
          }
        } else {
          snackbar.toggleOpen({
            message: 'Internal error'
          })
          if(window[Symbol.for('__communicationEmbeddedAction')]){
            window[Symbol.for('__communicationEmbeddedAction')].toggleOpen()
          }
          if(window[Symbol.for('__CommunicationEmbeddedActionDisplay')]){
            window[Symbol.for('__CommunicationEmbeddedActionDisplay')].toggleDisplay()
          }
        }
      }
      mutation()
    }
    if(!display){
      setLink('')
    }
  },[display])

  return (
    <QuickAction
      title={label}
      innerRef={qaRef}
      width={isZoom ? '1500px' : '800px'}
      extraAction = {<Icon onClick={() => setIsZoom(!isZoom)}>{isZoom ? 'expand_more' : 'expand_less'}</Icon>}
      extraCloseAction = {() => {
        if(window[Symbol.for('__CommunicationEmbeddedActionDisplay')]){
          window[Symbol.for('__CommunicationEmbeddedActionDisplay')].toggleDisplay()
        }
      }}
      content={
        <div className={classes.loader} >
        {
          link ?
            <object data={link} width={isZoom ? "1450px" : "750px"} height={isZoom ? "700px" : "400px"} type="text/html">
              external source link
            </object>
            :
            <CircularProgress />
        }
        </div>
      }
      {...rest}
    />
  );
}

export default withStyles((theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
  },
  loader: {
    textAlign: 'center'
  }
}))(withRouter(CommunicationEmbeddedAction));
