import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from 'components/Mui/Paper'

import useLineItemList from 'hooks/useLineItemList'
import LineItemListHeader from 'components/LineItemList/LineItemListHeader'
import ListItemListReport from 'components/LineItemList/ListItemListReport'
import LineItemListGroup from 'components/LineItemList/LineItemListGroup'
import QuoteContentsGroupItem from './QuoteContentsGroupItem'

const QuoteContentsItemList = ({ classes, quote, isAction, portfolio, isOwnCompany, isContentsAction, meta }) => {
  const [ groups, report ] = useLineItemList(quote)
  const { deliveryCharges, deliveryChargeUsed } = quote
  const reportData = {
    'delivery charges': deliveryCharges || 0,
    ...report
  }

  return (
    <Paper
      title={(
        <LineItemListHeader
          cols={[
            { col: 1, name: 'Date' },
            { col: 2, name: 'Cost Centre' },
            { col: true, name: 'Description' },
            { col: 1, name: 'Policy Item Limit' },
            { col: 1, name: 'Direct Supply' },
            // {col: 1, name: 'Purschase Price' },
            // {col: 1, name: 'Age' },
            // {col: 1, name: 'Proof of Loss' },

            {col: 1, name: 'Qty'},
            {col: 1, name: 'Rate Inc GST' },
            {col: 1, name: 'Subtotal'},

            { col: 1, name: 'Action'}
          ]}
        />
      )}
    >
      <div className={classes.root}>
        {groups.length === 0
          ? <div className={classes.noItem}>No item</div>
          : (<>
            {groups.map((group, index) => (
              <LineItemListGroup key={index}
                quote={quote}
                isAction={isAction} group={group}
                portfolio={portfolio}
                ItemComponent={QuoteContentsGroupItem}
              />
            ))}
            <ListItemListReport report={reportData} deliveryChargeUsed={deliveryChargeUsed} />
          </>)
        }
      </div>
    </Paper>
  )
}

export default withStyles(theme => ({
  noItem: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
  subHeader: {
    fontStyle: 'italic',
    fontWeight: 600,
    background: theme.palette.grey[400],

    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    borderRadius: 8,

    borderTop: '1px solid #ececec'
  },
  root: {
    maxHeight: '60vh',
    overflow: 'auto'
  }
}))(QuoteContentsItemList)
