import React, { Component } from 'react'

import Paper from 'components/Mui/Paper'
import EditorInput from 'components/Formik/EditorInput'

class ClaimDescriptionCard extends Component {
  render() {
    const { user } = this.props

    return (
      <Paper title={'Claim Description'}>
        <EditorInput
          name='claimDescription'
          disabled={!user.type.isAdmin}
        />
      </Paper> 
    )
  }
}

export default ClaimDescriptionCard