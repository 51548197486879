/**
 * @flow
 * @relayHash 11e53da801925e4706d7df142e13aba8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type MultipleTenderInput = {|
  scopeTemplateId: string,
  quotingSupplierIds: $ReadOnlyArray<string>,
  tenderCloseDate: any,
|};
export type ClaimPortfoliosUniqueWhere = {|
  claimId: string,
  claimPortfolioType: PortfolioType,
|};
export type RequestMultipleQuotesTendersQuotingBuildersCardMutationVariables = {|
  input: MultipleTenderInput,
  where: ClaimPortfoliosUniqueWhere,
|};
export type RequestMultipleQuotesTendersQuotingBuildersCardMutationResponse = {|
  +claimUpdateMultipleTender: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +id: string,
      +tenderCloseDate: ?any,
    |},
  |}
|};
export type RequestMultipleQuotesTendersQuotingBuildersCardMutation = {|
  variables: RequestMultipleQuotesTendersQuotingBuildersCardMutationVariables,
  response: RequestMultipleQuotesTendersQuotingBuildersCardMutationResponse,
|};
*/


/*
mutation RequestMultipleQuotesTendersQuotingBuildersCardMutation(
  $input: MultipleTenderInput!
  $where: ClaimPortfoliosUniqueWhere!
) {
  claimUpdateMultipleTender(input: $input, where: $where) {
    success
    messages
    result {
      id
      tenderCloseDate
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "MultipleTenderInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimPortfoliosUniqueWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimUpdateMultipleTender",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "MultipleTenderInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ClaimPortfoliosUniqueWhere!"
      }
    ],
    "concreteType": "ClaimPortfolioPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "result",
        "storageKey": null,
        "args": null,
        "concreteType": "ClaimPortfolio",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tenderCloseDate",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RequestMultipleQuotesTendersQuotingBuildersCardMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RequestMultipleQuotesTendersQuotingBuildersCardMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RequestMultipleQuotesTendersQuotingBuildersCardMutation",
    "id": null,
    "text": "mutation RequestMultipleQuotesTendersQuotingBuildersCardMutation(\n  $input: MultipleTenderInput!\n  $where: ClaimPortfoliosUniqueWhere!\n) {\n  claimUpdateMultipleTender(input: $input, where: $where) {\n    success\n    messages\n    result {\n      id\n      tenderCloseDate\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '740527c7436f6d327bb14a88fe350346';
module.exports = node;
