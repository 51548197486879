import React from 'react'

export default ({
  skip,
  mode = 'sendRecv',
  localVideoRef, remoteVideoRef,
  mediaConstraints,
  configuration,
  fakeStreamRef,
  dataChannelEvents,
  isSupportNoDevice,
  setForceStop
}) => {
  const [state, setState] = React.useState({
    ready: false,
    peer: null,
    signallingData: {
      offer: null,
      candidates: []
    },
    error: null
  })

  React.useEffect(() => {
    const start = async () => {
      const videoStream = !mediaConstraints.video
        ? await getStreamFromCanvas(fakeStreamRef.current)
        : undefined
      if(isSupportNoDevice === undefined || isSupportNoDevice === null ) return null
      const peer = await createPeer(isSupportNoDevice?'recv':mode, {
        videoStream,
        localVideo: localVideoRef.current,
        remoteVideo: remoteVideoRef.current,
        mediaConstraints,
        configuration,
        dataChannels: true,
        dataChannelConfig: {
          id: 'some_unique_id_maybe',
          ...dataChannelEvents,
          onmessage: (event: MessageEvent) => {
            if (dataChannelEvents && dataChannelEvents.onmessage) {
              dataChannelEvents.onmessage({ ...event, data: tryParse(event.data) })
            }
          }
        },
        onicecandidate: candidate => {
          setState(pre => ({
            ...pre,
            signallingData: {
              ...pre.signallingData,
              candidates: [ ...pre.signallingData.candidates, JSON.stringify(candidate) ]
            }
          }))
        },
        oncandidategatheringdone: () => setState(pre => ({ ...pre, ready: true })),
      })
        .then(async peer => {
          const offer = await getOffer(peer)
          setState(pre => ({
            ...pre,
            peer,
            signallingData: {
              ...pre.signallingData,
              offer
            }
          }))

          return peer
        })
        .catch(error => {
          console.log(`[KURENTO_ERROR]: ${error}`)
          setState(pre => ({
            ...pre,
            ready: true,
            error
          }))
        })
    }

    if (!skip && fakeStreamRef && fakeStreamRef.current) {
      const kurentoPeerPromise = start()

      return () => {
        if (kurentoPeerPromise) kurentoPeerPromise.then(destroy)
      }
    }
  }, [skip, fakeStreamRef]) // , localVideoRef, remoteVideoRef, mediaConstraintsString, configurationString

  /**
    * SIGNALLING HELPERS
    */
  const [answer, setAnswer] = React.useState(null)
  const [addedAnswer, setAddedAnswer] = React.useState(false)
  React.useEffect(() => {
    if (answer && !addedAnswer) {
      setAddedAnswer(true)
      state.peer.processAnswer(answer, err => {
        if (err) return console.log(err)
      })
    }
  }, [answer, addedAnswer, state.peer])
  const [serverCandidates, setServerCandidates] = React.useState(null)
  React.useEffect(() => {
    if (serverCandidates && addedAnswer) {
      serverCandidates.forEach(_candidate => {
        state.peer.addIceCandidate(JSON.parse(_candidate), err => {
          if (err) return console.log(err)
        })
      })
    }
  }, [addedAnswer, serverCandidates, state.peer])

  /**
   * ACTIONS
   */
  const getPhoto = () => state.peer.currentFrame.toDataURL()
  const hangup = () => destroy(state.peer, isSupportNoDevice, setForceStop)

  const [videoState, setVideoState] = React.useState(true)
  const toggleVideo = () => {
    setVideoState(previous => {
      state.peer.videoEnabled = !videoState
      return state.peer.videoEnabled
    })
  }

  const [audioState, setAudioState] = React.useState(true)
  const toggleAudio = () => {
    setAudioState(previous => {
      state.peer.audioEnabled = !audioState
      return state.peer.audioEnabled
    })
  }

  return {
    ...state,
    helpers: { setAnswer, setServerCandidates },
    actions: {
      getPhoto,
      hangup,
      videoState, toggleVideo,
      audioState, toggleAudio,
    },
    dataChannelSend: message => {
      const parsedMessage = typeof message === 'object' ? JSON.stringify(message) : message
      if (state.peer) state.peer.dataChannel.send(parsedMessage)
    }
  }
}

const createPeer = (mode, options) => new Promise(async (resolve, reject) => {
  const kurentoUtils = await React.lazy(() => import('kurento-utils'))
    ._ctor()
    .then(module => module.default)

  const createPC =
    mode === 'sendRecv' ? kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv :
    mode === 'recv' ? kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly :
    null

  if (!createPC) reject('specify the mode')

  const pc = createPC(options, error => {
    if (error) reject(error)

    resolve(pc)
  })
})

const getOffer = kurentoPeer => {
  return new Promise((resolve, reject) => {
    // { offerToReceiveVideo: true, offerToReceiveAudio: true },
    kurentoPeer.generateOffer(
      { offerToReceiveVideo: true, offerToReceiveAudio: true },
      (error, offer) => {
        if (error) reject(error)

        resolve(offer)
      }
    )
  })
}

const destroy = (kurentoPeer, isSupportNoDevice, setForceStop) => {
  if(!kurentoPeer) return

  // stop local media streams, tracks
  const localStreams = kurentoPeer.peerConnection.getLocalStreams()
  localStreams.forEach(stream => {
    stream.getTracks().forEach(track => {
      track.stop()
    })
  })

  // dispose event and close peer connection
  kurentoPeer.peerConnection.onicecandidate = null
  kurentoPeer.peerConnection.oncandidategatheringdone = null
  kurentoPeer.peerConnection.close()
  kurentoPeer.dispose()
  if(isSupportNoDevice) {
    setForceStop(true)
  }
}

const draw = (ctx) => {
  // ctx.fillStyle = 'red'
  ctx.fillStyle = 'rgba(0,0,0,0)'
  ctx.fillRect(0, 0, 5, 5)
  requestAnimationFrame(() => draw(ctx))
}
const getStreamFromCanvas = async canvas => {
  if (!canvas) return null
  const ctx = canvas.getContext('2d')
  requestAnimationFrame(() => draw(ctx))

  const canvasStream = canvas.captureStream(10)
  if (!canvasStream) return null

  const tracks = canvasStream.getVideoTracks()
  if (!tracks || (tracks && !tracks[0])) return null
  const videoTrack = tracks[0]

  const audioTrack = await navigator.mediaDevices.getUserMedia({ audio: true })
    .then(s => s.getAudioTracks()[0])

  const stream = new MediaStream([videoTrack, audioTrack])

  return stream
}

const tryParse = (string: string) => {
  try {
    return JSON.parse(string)
  }
  catch (error) {
    return string
  }
}
