import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";

import VariationListPresentation from "./VariationListPresentation";

class VariationList extends Component {
  render() {
    const { classes, variations, portfolios, setUpdate } = this.props;

    return (
      <Paper
        className={classes.root}
        title={
          <Grid fluid className={classes.headerContainer}>
            {[
              { col: 2, name: "Date" },
              { col: 4, name: "Name" },
              { col: 3, name: "Reason" },
              { col: 1, name: "Cost" },
              { col: 2, name: "Status" },
            ].map(({ col, name }, key) => (
              <Grid className={classes.alignCenter} item key={key} xs={col}>
                {name}
              </Grid>
            ))}
          </Grid>
        }
      >
        <VariationListPresentation
          variations={variations}
          portfolios={portfolios}
          setUpdate={setUpdate}
        />
      </Paper>
    );
  }
}

export default withStyles((theme) => ({
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
  },
  headerContainer: {
    "& > div": {
      borderRight: "1px solid white",
    },
    "& > div:last-child": {
      borderRight: 0,
    },
  },
}))(VariationList);
