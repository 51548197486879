import React from 'react'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import Button from 'components/Mui/Button'

export default () => {
  return (
    <Paper>
      <Grid fluid justify='space-between'>
        <Grid item xs={2}>
          <Button label='Next Step' variant='outlined' color='primary'/>
        </Grid>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={2}>
          <Button fullWidth leadIcon='print' label='Site Report' variant='outlined' color='primary'/>
        </Grid>
        <Grid item xs={2}>
          <Button fullWidth label='Save' variant='outlined' color='primary'/>
        </Grid>
      </Grid>
    </Paper>
  )
}