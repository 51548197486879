import { createMuiTheme } from '@material-ui/core/styles' // MuiThemeProvider
import muiRed from '@material-ui/core/colors/red'

const colorPallete = {
  endata: {
    main: '#027dff',
    contrastText: '#ececec'
  },
  sophia: {
    main: '#0f5b78',
    contrastText: '#ececec'
  },
  jade: {
    main: '#5ca794',
    contrastText: '#ececec'
  },
  amber: {
    main: '#edaa38',
    contrastText: '#ececec'
  },
  rollingInTheDark: {
    main: 'rgba(0, 0, 0, 0.79)',
    contrastText: '#ececec'
  },
}

export const getTheme = color => {
  switch(color){
    case 'endata':
      return createMuiTheme({
        palette: {
          primary: colorPallete.endata,
          secondary: colorPallete.amber,
          error: muiRed,
        },
        ...overrides,
      })
    case 'sophia':
      return createMuiTheme({ 
        palette: {
          primary: colorPallete.sophia,
          secondary: colorPallete.jade,
          error: muiRed,
        },
        ...overrides,
      })
    case 'jade':
      return createMuiTheme({
        palette: {
          primary: colorPallete.jade,
          secondary: colorPallete.sophia,
          error: muiRed,
        },
        ...overrides,
      })
    case 'amber':
      return createMuiTheme({
        palette: {
          primary: colorPallete.amber,
          secondary: colorPallete.sophia,
          error: muiRed,
        },
        ...overrides,
      })
    case 'dev':
      return createMuiTheme({
        palette: {
          primary: colorPallete.rollingInTheDark,
          secondary: colorPallete.jade,
          error: muiRed,
        },
        ...overrides,
      })
    default:
      return createMuiTheme({ 
        palette: {
          primary: colorPallete.sophia,
          secondary: colorPallete.amber,
          error: muiRed,
        },
        ...overrides,
      })
  }
}

const mixins = {
  alignJustifyContainer: theme => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  truncate: theme => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })
}

const spacing = 8
const overrides = {
  spacing: { unit: spacing },
  status: { danger: 'orange' },
  typography: {
    useNextVariants: true
  },

  overrides: {
    MuiGrid: {
      container: {
        margin: 'auto',
        width: '95%',
      }
    },
    MuiSelect: {
      root: {
        position: 'initial'
      }
    },
    MuiButton: {
      root: { 
        textTransform: 'initial',
        marginTop: spacing,
        marginBottom: spacing
      },
      flat: {
        paddingTop: spacing, paddingBottom: spacing
      }, 
      contained: {
        padding: '8px 24px'
      }, 
      outlined: { 
        padding: '8px 24px' 
      } 
    }, 
    MuiCardHeader: { 
      title: {
        fontWeight: '500' 
      } 
    },
    MuiPaper: {
      rounded: {
        borderRadius: '8px'
      }
    },
    MuiInputLabel: {
      root: {
        fontWeight: '600',
        color: '#000'
      },
    },
    MuiFormControl: {
      root: {
        margin: '8px 0'
      },
      fullWidth: {
        width: 'calc(100%)'
      }
    },
    MuiFormLabel: {
      root: {
        fontWeight: '600',
        color: '#000',
        // fontSize: '0.75rem'
      }
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: colorPallete.endata.main
      }
    },
    MuiTab: {
      label: {
        textTransform: 'capitalize',
        letterSpacing: '0.1rem'
      }
    },
    MuiSwitch: {
      switchBase: {
        color: 'red',
        '&$checked': {
          color: "green !important"
        },
      },
    },
    MuiInputBase: {
      root: {
        '& .material-icons': {
          opacity: '0.6'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '.8rem',
      }
    }
  },
  mixins
}