import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import jwt from 'jsonwebtoken'

const mutation = graphql`
  mutation AdjusterInitialCallMutation($where: ClaimUniqueWhere!) {
    adjusterInitialCall(where: $where) {
      success
      messages
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: ({adjusterInitialCall}) => {
          const { messages, success } = adjusterInitialCall
          resolve({ok: true, messages, success})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}