import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { commonCharactersRegexp } from "common/inputRegex";

import Grid from "components/Mui/Grid";
import Dialog from "components/Mui/Dialog";
import Button from "components/Mui/Button";
import { Formik, Form } from "formik";

import UpdateItemForm from "components/LineItem/UpdateItemForm";
import UpdateScopeItemMutation from "./UpdateScopeItemMutation";

const UpdateScopeItem = ({
  classes,
  symbol,
  jobQuoteId,
  portfolioType,
  initialValues,
  isProperties,
  quote,
}) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const refresh = window[Symbol.for("__refreshScope")];

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => validate(values)}
      onSubmit={(values, actions) => {
        setTimeout(async () => {
          const variables = {
            where: { id: jobQuoteId, portfolioType },
            input: {
              locationId: Number(values.locationId),
              dimensions: values.dimensions,
              scopeTypeId: values.scopeTypeId,
              costCentreId: Number(values.costCentreId),
              costItemId: Number(values.costItemId),
              lineDescription: values.lineDescription,
              unitId: Number(values.unitId),
              marginRate: Number(values.marginRate),
              qty: Number(values.qty),
              rate: Number(values.rate),
              propertyNumber: values.propertyNumber,
              propertyTypeId: values.propertyTypeId,
            },
          };
          const res = await UpdateScopeItemMutation(variables);
          if (res.ok) {
            snackbar.toggleOpen({ message: res.messages[0] });
            actions.setSubmitting(false);

            if (res.success) {
              refresh();
            }
          } else {
            snackbar.toggleOpen({
              message: "Unable to update the item, please try again",
            });
          }
        }, 400);
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Dialog
          noForm
          noActions
          symbol={symbol}
          paperClassname={classes.paper}
          title="Update Scope Line Item"
          content={
            <Form>
              <Grid fluid>
                <UpdateItemForm
                  quote={quote}
                  isProperties={isProperties}
                  values={values}
                  isSubmitting={isSubmitting}
                  quotationType={"Scope"}
                  jobQuoteId={jobQuoteId}
                  portfolioType={portfolioType}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid fluid>
                <div style={{ marginLeft: "auto" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    label="Update"
                    type="submit"
                    disabled={isSubmitting}
                  />
                  <Button
                    color="primary"
                    label="Close"
                    onClick={() => window[Symbol.for(symbol)].handleClose()}
                    disabled={isSubmitting}
                  />
                </div>
              </Grid>
            </Form>
          }
        />
      )}
    </Formik>
  );
};

export default withStyles((theme) => ({
  paper: {
    maxWidth: "50% !important",
  },
}))(UpdateScopeItem);

// const initialValues = {
//   locationId: '',
//   dimensions: '',
//
//   costCentreId: '',
//   costItemId: '',
//   lineDescription: '',
//   unitId: '',
//   marginRate: 0,
//   qty: '',
//   rate: ''
// }

const validate = (values) => {
  let errors = {};

  // required
  if (!values.locationId) errors.locationId = "Required!";
  if (!values.dimensions) errors.dimensions = "required!";
  if (!values.costCentreId) errors.costCentreId = "Required";
  if (!values.costItemId) errors.costItemId = "Required";
  if (!values.unitId) errors.unitId = "Required";
  if (!values.qty) errors.qty = "Required";
  if (!values.rate && values.rate !== 0) errors.rate = "Required";

  // Extra rules
  if (values.dimensions && commonCharactersRegexp.test(values.dimensions))
    errors.dimensions = "Invalid character";
  if (values.dimensions.length > 200)
    errors.dimensions = "Maximum character limit is 200";

  if (values.lineDescription.length > 1500)
    errors.lineDescription = "Maximum character limit is 1500";

  if (values.qty.length > 4 + 3) errors.qty = "Maximum character limit is 4";
  if (
    (String(values.rate).indexOf("-") !== -1 &&
      String(values.rate).length > 7 + 4) ||
    (String(values.rate).indexOf("-") === -1 &&
      String(values.rate).length > 7 + 3)
  )
    errors.rate = "Maximum character limit is 7";

  return errors;
};
