import React from 'react'
import { moneyFormat } from 'common/utils'
import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'
import { TextField, MenuItem } from '@material-ui/core'
import UpdateScopeTypeMutation from './UpdateScopeTypeMutation'
import UpdateVariationScopeTypeMutation from './UpdateVariationScopeTypeMutation'
import MuiCircularProgress from '@material-ui/core/CircularProgress'

const GroupItem = ({ classes, item, tableItem, ScopeTypeOptions, portfolioType, quotationType, loading, setLoading }) => {
  const handleChange = async props => {
    setLoading(true)
    if(quotationType === 'Quotes') {
      const res = await UpdateScopeTypeMutation({
        where: {
          id: item.jobQuoteItemId,
          portfolioType
        },
        input: {
          scopeTypeId: props.target.value
        }
      })
      if(res.success) {
        setLoading(false)
        // update()
      } else {
        setLoading(false)
      }
    }
    if(quotationType === 'Variation') {
      const res = await UpdateVariationScopeTypeMutation({
        where: {
          id: item.variationItemId,
          portfolioType
        },
        input: {
          scopeTypeId: props.target.value
        }
      })
      if(res.success) {
        setLoading(false)
        // update()
      } else {
        setLoading(false)
      }
    }
  }
  return (
    <>
      <Grid fluid spacing={0} className={classes.row}>
        {[
          {col: 2, text: (
            <div style={{width: '100%', position: 'relative'}}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="standard-select-currency"
                  select
                  label="Scope Type"
                  value={item.scopeType && item.scopeType.id ? item.scopeType.id: null}
                  disabled={loading}
                  onChange={handleChange}
                >
                  {ScopeTypeOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              {
                loading && <MuiCircularProgress className={classes.loading}/>
              }
            </div>
          )},
          // cost centre
          {col: 2, text: item.costCentre.costCentreName, unMountOn: !tableItem.fields.costCentre.isDisplay},
          // description
          {col: true, text: `${item.costItem ? item.costItem.itemDescription : ''} ${item.lineDescription}`, unMountOn: !tableItem.fields.description.isDisplay},
          // unit
          {col: 1, text: item.unit && item.unit.unitName, unMountOn: !tableItem.fields.unit.isDisplay},
          // qty
          {col: 1, text: item.qty, unMountOn: !tableItem.fields.qty.isDisplay},
          // rate ex
          {col: 1, text: moneyFormat(item.rate), unMountOn: !tableItem.fields.rate.isDisplay},
          // margin
          {col: 1, text: `${item.marginRate}%`, unMountOn: !tableItem.fields.marginRate.isDisplay},
          // subtotal
          {col: 1, text: moneyFormat(item.subtotal), unMountOn: !tableItem.fields.subtotal.isDisplay},
          // actions
        ].map(({ col, text, unMountOn, noActions, className }, key) => {
          if(unMountOn) return null

          return (
            <Grid className={`${className} ${classes.alignCenter}`} item key={key} xs={col}>
              {text}
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default withStyles(theme => ({
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: 'center',
    borderRadius: '8px',

    '&:nth-child(even)': {
      background: theme.palette.grey[100],
    },
    '&:hover': {
      background: theme.palette.grey[200],
    }
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit
  },
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
    padding: '4px',
    textAlign: 'center',
    flexWrap: 'wrap'
  },
  loading: {
    position: 'absolute',
    left: '40px',
    top: '15px'
  }
}))(GroupItem)
