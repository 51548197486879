import React, { Component } from 'react'

class ListGroup extends Component {
	render() {
		const { children } = this.props
		return (
			<div className='mdc-list-group'>
				{children}
			</div>
		)
	}
}

export default ListGroup