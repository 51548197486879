import { commitMutation, graphql } from 'react-relay'
import { ConnectionHandler } from 'relay-runtime'
import environment from 'common/relay'

const mutation = graphql`
  mutation DeleteDocumentMutation($where: ENDataPortfolioKey!) {
    deleteClaimDocument(input: $where) {
      success
      messages
    }
  }
`

export default (variables, id) => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        updater: store => {
          const payload = store.getRootField('deleteClaimDocument')

          if(payload.getValue('success')) {
            const root = store.getRoot()
            const conn = ConnectionHandler.getConnection(root, 'DocumentsList_claimDocuments')
            ConnectionHandler.deleteNode(conn, id)
          }
        },
        onCompleted: ({deleteClaimDocument}) => {
          const { messages, success } = deleteClaimDocument
          resolve({ok: true, messages, success})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}