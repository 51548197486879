import React, { Component, createRef, Fragment } from 'react'
import { MDCList } from '@material/list'
import IconButton from 'components/MultipleSelect/IconButton/'

class List extends Component {
	element = createRef()
	componentDidMount = () => {
		const { selectList } = this.props
		const list = new MDCList(this.element.current)

		if(selectList) list.singleSelection = true
	}
	render() {
		const { children, groupHeader, className, checkedGroupChange, groupData, localStorageData, GroupState } = this.props

		const { twoline, selectList } = this.props
		return (
			<Fragment>
				{groupHeader && <CheckboxItem label={groupHeader} checkedGroupChange={checkedGroupChange} 
				groupData={groupData} localStorageData={localStorageData} GroupState={GroupState}/>}
				<ul ref={this.element}
					className={`${twoline ? twolineClass : ''} ${className ? className : ''} mdc-list`}
					role={selectList ? 'listbox' : null}
				>
				  	{children}
					{groupHeader && <li role="separator" className="mdc-list-divider"></li>}
				</ul>
			</Fragment>	
		)
	}
}

export default List

const twolineClass = 'mdc-list--two-line'

// implement checkbox list
// implement radio list

class CheckboxItem extends Component {
	element = createRef()
	
  state = {
    active: false
  }

  componentDidMount = () => {
		const { checkedGroupChange, groupData, label, localStorageData } = this.props
		this.setState({active:localStorageData?(groupData.filter(e => e[0] === label)[0][1].length) === (localStorageData.data[label] && localStorageData.data[label].length):localStorageData})
    this.element.current.addEventListener('click', e => {
      e.stopPropagation()
			this.setState({active: !this.state.active}, () => {
        checkedGroupChange(groupData, label, this.state.active)
			}
			)
    })
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.unCheckFlag !== prevProps.unCheckFlag) {
      this.setState({active: false})
		}
		if(this.props.GroupState.groupClear !== prevProps.GroupState.groupClear) {
			this.setState({active: false})
		}
		if(this.props.GroupState.selectALL !== prevProps.GroupState.selectALL) {
			if(this.props.GroupState.selectALL) {
				this.setState({active: true})
			} else {
				this.setState({active: false})
			}
		}
  }

  render() {
		const { label } = this.props
    return (
      <div ref={this.element} style={{display:'flex', alignItems:'center'}}>
        <IconButton active={this.state.active} icon='check_box_outline_blank' iconOn='check_box'/>
        <h3>{label}</h3>
      </div>
    )
  }
}