/**
 * @flow
 * @relayHash 7eeada2ca2d3d8c540bc266237a63325
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type JobQuoteAuthoriseInput = {|
  notAcceptedItemIds?: ?$ReadOnlyArray<number>
|};
export type AcceptJobQuoteMutationVariables = {|
  where: ENDataPortfolioKey,
  input?: ?JobQuoteAuthoriseInput,
|};
export type AcceptJobQuoteMutationResponse = {|
  +jobQuoteAuthorise: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +completed: boolean,
      +reviewed: boolean,
      +portfolioType: ?PortfolioType,
      +subtotal: ?number,
      +supplier: ?{|
        +companyId: number,
        +companyName: string,
      |},
    |},
  |}
|};
export type AcceptJobQuoteMutation = {|
  variables: AcceptJobQuoteMutationVariables,
  response: AcceptJobQuoteMutationResponse,
|};
*/


/*
mutation AcceptJobQuoteMutation(
  $where: ENDataPortfolioKey!
  $input: JobQuoteAuthoriseInput
) {
  jobQuoteAuthorise(where: $where, input: $input) {
    messages
    success
    result {
      completed
      reviewed
      portfolioType
      subtotal
      supplier {
        companyId
        companyName
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobQuoteAuthoriseInput",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "JobQuoteAuthoriseInput"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completed",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reviewed",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "portfolioType",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyId",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AcceptJobQuoteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobQuoteAuthorise",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuotePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuote",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "supplier",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AcceptJobQuoteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobQuoteAuthorise",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuotePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuote",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "supplier",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              },
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AcceptJobQuoteMutation",
    "id": null,
    "text": "mutation AcceptJobQuoteMutation(\n  $where: ENDataPortfolioKey!\n  $input: JobQuoteAuthoriseInput\n) {\n  jobQuoteAuthorise(where: $where, input: $input) {\n    messages\n    success\n    result {\n      completed\n      reviewed\n      portfolioType\n      subtotal\n      supplier {\n        companyId\n        companyName\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0b72be290ec93b29d856d4a9b8ffb5a6';
module.exports = node;
