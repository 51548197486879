import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import environment from 'common/relay'
import { QueryRenderer } from 'react-relay'

import { Formik, Form } from 'formik'
import Typography from '@material-ui/core/Typography'
import Dialog from 'components/Mui/Dialog'
import Button from 'components/Mui/Button'
import Input from 'components/Formik/Input'
import MoneyInput from 'components/Formik/MoneyInput'
import Grid from 'components/Mui/Grid'
import CompleteQuoteActionMutation from './CompleteQuoteActionMutation'

class CompleteQuoteAction extends Component {
  snackbar = window[Symbol.for('__snackbar')]


  render() {
    const { quote, meta, portfolios } = this.props
    const options = [
      {label: '1 Week', value: '1 Week'},
      {label: '2 Week', value: '2 Week'},
      {label: '3 Week', value: '3 Week'},
      {label: '4 Week', value: '4 Week'},
      {label: '2 - 3 Months', value: '2 - 3 Months'},
      {label: 'Above 3 Months', value: 'Above 3 Months'},
    ]
    return (
      <Dialog
        symbol='__CompleteQuoteAction'
        title='Complete Quote'
        noForm
        content={
          <QueryRenderer
            environment={environment}
            variables={{
            }}
            render={({ error, props }) => {
              if(!props) return 'Loading...'
              return (
                <Grid item xs>
                  <Grid fluid spacing={8} justify='center' style={{height: '100%'}}>
                    <Grid item xs={12}>
                      {
                        portfolios === 'Contents' ? (
                          <Formik
                            initialValues={{
                              deliveryCharges: '',
                            }}
                            validate={(values) => {
                              let errors = {}

                              if(String(values.deliveryCharges).length > (7 + 3)) errors.deliveryCharges = 'Maximum character limit is 7'

                              return errors
                            }}
                            onSubmit={async (values, actions) => {
                              const res = await CompleteQuoteActionMutation({
                                input: {
                                  deliveryCharges: values.deliveryCharges || null
                                },
                                where: {
                                  id: quote.jobQuoteId,
                                  claimId: meta.claim.claimId,
                                  portfolioType: portfolios
                                }
                              })
                              if(res.ok){
                                this.snackbar.toggleOpen({
                                  message: res.message
                                })
                                if(res.success) {
                                  const ref = window[Symbol.for('__refreshQuote')]
                                  if(ref) ref()
                                }
                              }
                            }}
                          >
                            {({ isSubmitting, values }) => {
                              return (
                                <Form>
                                  <Grid fluid spacing={8} justify='center'>
                                    <Grid item xs={12}>
                                      <MoneyInput
                                        name='deliveryCharges'
                                        label='Delivery Charges'
                                      />
                                    </Grid>
                                    {
                                      quote.lineItems.some(e => e.subtotal === null) && (
                                        <Typography color='error'>
                                          You have not quoted on All items. Click Complete Quote to proceed or Close to quote on other items
                                        </Typography>
                                      )
                                    }
                                    <Button
                                      fullWidth
                                      variant='contained'
                                      type='submit'
                                      color='primary'
                                      label='Complete Quote'
                                      disabled={isSubmitting}
                                    />
                                  </Grid>
                                </Form>
                              )
                            }}
                          </Formik>
                        ) : (
                          <Formik
                            initialValues={{
                              startAfterContractReceived: '', estimatedTimeForRepairs: ''
                            }}
                            validate={(values) => {
                              let errors = {}
                              if(!values.startAfterContractReceived) errors.startAfterContractReceived = 'required!'
                              if(!values.estimatedTimeForRepairs) errors.estimatedTimeForRepairs = 'required!'

                              return errors
                            }}
                            onSubmit={async (values, actions) => {
                              const res = await CompleteQuoteActionMutation({
                                input: values,
                                where: {
                                  id: quote.jobQuoteId,
                                  claimId: meta.claim.claimId,
                                  portfolioType: portfolios
                                }
                              })
                              if(res.ok){
                                const actions = window[Symbol.for('__CompleteQuoteAction')]
                                if (res.success) {
                                  this.snackbar.toggleOpen({message: "Completed"})
                                  if(actions) {
                                    actions.handleClose()
                                  }
                                } else {
                                  this.snackbar.toggleOpen({message: res.message})
                                  if(actions) {
                                    actions.handleClose()
                                  }
                                  actions.setSubmitting(false)
                                }
                              }
                            }}
                          >
                            {({ isSubmitting, values }) => {
                              return (
                                <Form>
                                  <Grid fluid spacing={8} justify='center'>
                                    <Grid item xs={12}>
                                      <Input
                                        required
                                        name='startAfterContractReceived'
                                        label='Start after receipt of contract'
                                        options={options}
                                        select
                                        value={values.startAfterContractReceived}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Input
                                        required
                                        name='estimatedTimeForRepairs'
                                        label='Estimated time for repairs'
                                        options={options}
                                        select
                                        value={values.estimatedTimeForRepairs}
                                      />
                                    </Grid>
                                    <Button
                                      fullWidth
                                      variant='contained'
                                      type='submit'
                                      color='primary'
                                      label='Complete Quote'
                                      disabled={isSubmitting}
                                    />
                                  </Grid>
                                </Form>
                              )
                            }}
                          </Formik>
                        )
                      }
                    </Grid>
                  </Grid>
                </Grid>
              )
            }}
          />
        }
      />
    )
  }
}

export default withRouter(CompleteQuoteAction)
