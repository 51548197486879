import React, { Component } from "react";

import Grid from "components/Mui/Grid";

import ChartPaper from "components/Mui/ChartPaper";

const backgroundColors = [
  "#0076c4",
  "#002566",
  "#00b4f1",
  "#0076c4",
  "#002566",
  "#00b4f1",
  "#0076c4",
  "#002566",
  "#00b4f1",
];

class DashboardChartContainer extends Component {
  render() {
    const { chartWidgets } = this.props;
    const chartsData = chartWidgets.map((dashData) => {
      return {
        type: "bar",
        title: dashData.title,
        data: {
          labels: dashData.labels,
          datasets: JSON.parse(
            JSON.stringify(
              dashData.data.map((e, key) => ({
                type: e.valueDisplay !== "ChartLine" ? "bar" : "line",
                label: e.legend,
                data: e.values,
                yAxisID: `y-axis-${e.valueGroup - 1}`,
                borderWidth: e.valueDisplay !== "ChartLine" ? 0 : 4,
                fill: e.valueDisplay !== "ChartLine" ? true : false,
                lineTension: 0,
                backgroundColor: backgroundColors[key],
              }))
            )
          ),
        },
        options: {
          legend: {
            position: "bottom", //setting only
          },
          tooltips: {
            mode: "label", //setting only
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true, //setting only
                },
                gridLines: {
                  // display: false
                },
                id: "y-axis-0", //setting only
                position: "left", //setting only
                stacked: dashData.data.some(
                  (e) => e.valueDisplay === "ChartProgressBar"
                ), // if you want the bars/line depends on the LEFT y axis to be
                // be stacked on y axis
              },
              {
                ticks: {
                  beginAtZero: true, //setting only
                },
                gridLines: {
                  display: false, //setting only
                },
                id: "y-axis-1", //setting only
                position: "right", //setting only
                stacked: false, // if you want the bars/line depends on the RIGHT y axis to be
                // be stacked on y axis
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false, //setting only
                },
                stacked: dashData.data.some(
                  (e) => e.valueDisplay === "ChartProgressBar"
                ), // if you want the bars/lines to sack on the xAxes
              },
            ],
          },
        },
      };
    });
    return (
      <>
        {chartsData.map(({ title, data, options, type }, key) => (
          <Grid item xs={6} key={key}>
            <ChartPaper
              title={title}
              data={data}
              options={options}
              type={type}
            />
          </Grid>
        ))}
      </>
    );
  }
}

export default DashboardChartContainer;
