/**
 * @flow
 * @relayHash aa178df69730256a6c7033a671bee056
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type MakeSafeCompletedMutationVariables = {|
  where: ENDataPortfolioKey
|};
export type MakeSafeCompletedMutationResponse = {|
  +jobMakeSafeQuoteComplete: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +completed: boolean,
      +actions: ?{|
        +addLineItem: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
        |},
        +makeSafeAppointment: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
        |},
        +makeSafeInitialCall: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
        |},
        +onSiteMakeSafe: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
        |},
        +resetMakeSafe: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
        |},
        +submitMakeSafe: {|
          +isDisplay: boolean,
          +isDisabled: boolean,
          +label: string,
        |},
      |},
    |},
  |}
|};
export type MakeSafeCompletedMutation = {|
  variables: MakeSafeCompletedMutationVariables,
  response: MakeSafeCompletedMutationResponse,
|};
*/


/*
mutation MakeSafeCompletedMutation(
  $where: ENDataPortfolioKey!
) {
  jobMakeSafeQuoteComplete(where: $where) {
    messages
    success
    result {
      completed
      actions {
        addLineItem {
          isDisplay
          isDisabled
          label
          id
        }
        makeSafeAppointment {
          isDisplay
          isDisabled
          label
          id
        }
        makeSafeInitialCall {
          isDisplay
          isDisabled
          label
          id
        }
        onSiteMakeSafe {
          isDisplay
          isDisabled
          label
          id
        }
        resetMakeSafe {
          isDisplay
          isDisabled
          label
          id
        }
        submitMakeSafe {
          isDisplay
          isDisabled
          label
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completed",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisabled",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v8 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v10 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v9/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MakeSafeCompletedMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobMakeSafeQuoteComplete",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobMakeSafePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobMakeSafe",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "JobMakeSafeAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addLineItem",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v8/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "makeSafeAppointment",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v8/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "makeSafeInitialCall",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v8/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "onSiteMakeSafe",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v8/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "resetMakeSafe",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v8/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "submitMakeSafe",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v8/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MakeSafeCompletedMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobMakeSafeQuoteComplete",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobMakeSafePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobMakeSafe",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "JobMakeSafeAction",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addLineItem",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "makeSafeAppointment",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "makeSafeInitialCall",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "onSiteMakeSafe",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "resetMakeSafe",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "submitMakeSafe",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionControl",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  }
                ]
              },
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "MakeSafeCompletedMutation",
    "id": null,
    "text": "mutation MakeSafeCompletedMutation(\n  $where: ENDataPortfolioKey!\n) {\n  jobMakeSafeQuoteComplete(where: $where) {\n    messages\n    success\n    result {\n      completed\n      actions {\n        addLineItem {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n        makeSafeAppointment {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n        makeSafeInitialCall {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n        onSiteMakeSafe {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n        resetMakeSafe {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n        submitMakeSafe {\n          isDisplay\n          isDisabled\n          label\n          id\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e6333905ff0d0c8c2a0d068045b93ef4';
module.exports = node;
