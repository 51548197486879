import React, { Component, createRef } from "react";
import { graphql, createPaginationContainer } from "react-relay";
import { withStyles } from "@material-ui/core/styles";

import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
import Button from "components/Mui/Button";
import { withClaimMeta } from "../../ClaimMetaContext";

import UploadLossAdjusterAction from "../../ClaimDetailsAction/UploadLossAdjusterAction";
import LossAdjusterListItem from "./LossAdjusterListItem";
import AdjusterInitialCallMutation from "./LossAdjusterActions/AdjusterInitialCallMutation";
import AdjusterAppointmentMade from "./LossAdjusterActions/AdjusterAppointmentMade";
import LossAdjusterStatus from "./LossAdjusterActions/LossAdjusterStatus";

class LossAdjusterList extends Component {
  upload = createRef();
  snackbar = window[Symbol.for("__snackbar")];
  render() {
    const { classes, data, claimId, meta } = this.props;
    // const isInitialCallButton = meta.claim.actions.find(
    //   (e) => e.actionType === "ADJUSTER_INITIAL_CALL"
    // );
    const isInitialCallButton =
      data.claimJob.view.actions.makeLossAdjusterInitialCall;
    // const isAppointmentMadeButton = meta.claim.actions.find(
    //   (e) => e.actionType === "ADJUSTER_APPOINTMENT"
    // );
    const isAppointmentMadeButton =
      data.claimJob.view.actions.makeLossAdjusterAppointment;
    const lossAdjusterDisabled =
      meta.claim.view.actions.addLossAdjusterDocument.isDisabled;
    const updateLossAdjusterStatus =
      meta.claim.view.actions.updateLossAdjusterStatus;

    return (
      <>
        <AdjusterAppointmentMade></AdjusterAppointmentMade>
        <LossAdjusterStatus claimId={claimId} />
        <Grid container>
          <Grid item xs={12}>
            <Paper>
              <Grid fluid>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    label="Next Step"
                    variant="outlined"
                    color="primary"
                    onClick={this.handleNextStep}
                  />
                </Grid>
                <Grid item xs={2}>
                  {isInitialCallButton && isInitialCallButton.isDisplay && (
                    <Button
                      fullWidth
                      label="Initial Call"
                      variant="outlined"
                      color="primary"
                      onClick={this.handleInitialCall}
                    />
                  )}{" "}
                </Grid>
                <Grid item xs={2}>
                  {isAppointmentMadeButton &&
                    isAppointmentMadeButton.isDisplay && (
                      <Button
                        fullWidth
                        label="Appointment Made"
                        variant="outlined"
                        color="primary"
                        onClick={this.handleAppointmentMade}
                      />
                    )}
                </Grid>
                {/* <Grid item xs={2}> */}
                {/* <Button fullWidth label='View All Photo' variant='outlined' color='primary'/> */}
                {/* </Grid> */}
                <Grid item xs={2}>
                  {updateLossAdjusterStatus &&
                    updateLossAdjusterStatus.isDisplay && (
                      <Button
                        fullWidth
                        label="Change Status"
                        variant="outlined"
                        color="primary"
                        onClick={this.handleStatus}
                        disabled={updateLossAdjusterStatus.isDisabled}
                      />
                    )}
                </Grid>
                {!lossAdjusterDisabled && meta.user.type.isAdmin && (
                  <Grid item xs={2}>
                    <Button
                      onClick={this.uploadFile}
                      fullWidth
                      label="Upload A File"
                      variant="outlined"
                      color="primary"
                    />
                  </Grid>
                )}
                <Grid item xs={2}>
                  {!lossAdjusterDisabled && (
                    <Button
                      fullWidth
                      label="View All Photos"
                      variant="outlined"
                      color="primary"
                      onClick={(e) => this.handleViewAllPhotos()}
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              className={classes.root}
              title={
                <Grid fluid className={classes.headerContainer}>
                  {[
                    { col: 2, name: "Date" },
                    { col: 2, name: "Company" },
                    { col: 1, name: "Report" },
                    { col: 1, name: "File" },
                    { col: 1, name: "Privacy" },
                    { col: 5, name: "Description" },
                  ].map(({ col, name }, key) => (
                    <Grid
                      className={classes.alignCenter}
                      item
                      key={key}
                      xs={col}
                    >
                      {name}
                    </Grid>
                  ))}
                </Grid>
              }
            >
              <div className={classes.listBody}>
                {data.claimLossAdjusterDocuments.edges.length !== 0
                  ? data.claimLossAdjusterDocuments.edges.map(({ node }, k) => (
                      <LossAdjusterListItem index={k + 1} key={k} item={node} />
                    ))
                  : "No document found"}
              </div>
            </Paper>
          </Grid>
        </Grid>
        <UploadLossAdjusterAction
          symbol="__uploadLossAdjuster"
          claimId={claimId}
        />
      </>
    );
  }
  uploadFile = () => {
    if (window[Symbol.for("__uploadLossAdjuster")]) {
      window[Symbol.for("__uploadLossAdjuster")].handleOpen();
    }
  };

  handleNextStep = () => {
    if (window[Symbol.for("__nextStepAction")]) {
      window[Symbol.for("__nextStepAction")].handleOpen();
    }
  };

  handleInitialCall = async () => {
    const res = await this.snackbar.toggleOpen({
      message: "Please confirm that initial call has been made",
      firstButton: "Call Made",
      secondButton: "Not Yet",
      type: "confirm",
    });
    if (res.ok) {
      const mutationRes = await AdjusterInitialCallMutation({
        where: {
          claimId: this.props.claimId,
        },
      });
      if (mutationRes.ok) {
        window[Symbol.for("__refreshClaimDetails")]();
        this.snackbar.toggleOpen({
          message: mutationRes.messages[0],
        });
      } else {
        this.snackbar.toggleOpen({
          message: "Failed",
        });
      }
    }
  };

  handleAppointmentMade = async () => {
    window[Symbol.for("__AdjusterAppointmentMadeAction")].handleOpen();
  };

  handleStatus = async () => {
    window[Symbol.for("__LossAdjusterStatusAction")].handleOpen();
  };

  handleViewAllPhotos = () => {
    if (window[Symbol.for("__lossAdjusterViewAllAction")]) {
      window[Symbol.for("__lossAdjusterViewAllAction")].handleOpen();
    }
  };
}

export default createPaginationContainer(
  withStyles((theme) => ({
    alignCenter: {
      ...theme.mixins.alignJustifyContainer(theme),
    },
    listBody: {
      maxHeight: "60vh",
      overflow: "auto",
    },
    headerContainer: {
      "& > div": {
        borderRight: "1px solid white",
      },
      "& > div:last-child": {
        borderRight: 0,
      },
    },
  }))(withClaimMeta(LossAdjusterList)),
  graphql`
    fragment LossAdjusterList on Query {
      claimLossAdjusterDocuments(first: $first, after: $after, where: $where)
        @connection(
          key: "LossAdjusterList_claimLossAdjusterDocuments"
          filters: []
        ) {
        edges {
          node {
            ...LossAdjusterListItem_item
          }
        }
      }
      claimJob(where: $claimJobWhere) {
        view {
          actions {
            makeLossAdjusterInitialCall {
              isDisabled
              isDisplay
              label
            }
            makeLossAdjusterAppointment {
              isDisabled
              isDisplay
              label
            }
          }
        }
      }
    }
  `,
  {
    query: graphql`
      query LossAdjusterListQuery(
        $first: Int!
        $after: String
        $where: ClaimItemFilter
        $claimJobWhere: ENDataEntityKey!
      ) {
        ...LossAdjusterList
      }
    `,
    getVariables(props, paginationInfo, fragmentVariables) {
      const { cursor } = paginationInfo;
      const { where, first, claimJobWhere } = fragmentVariables;
      return {
        first,
        after: cursor,
        where,
        claimJobWhere,
      };
    },
  }
);
