/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CommunicationButtonFragment_communications$ref: FragmentReference;
export type CommunicationButtonFragment_communications = {|
  +claimCommuications: ?{|
    +totalCount: ?number,
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +message: ?string,
        +claimId: ?string,
        +claimRef: string,
      |}
    |}>,
  |},
  +$refType: CommunicationButtonFragment_communications$ref,
|};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CommunicationButtonFragment_communications",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "claimCommuications",
      "storageKey": "claimCommuications(first:2,where:{\"acknowledged\":false,\"boxes\":\"Inbox\"})",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 2,
          "type": "Int"
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "acknowledged": false,
            "boxes": "Inbox"
          },
          "type": "CommunicationFilter"
        }
      ],
      "concreteType": "ClaimCommunicationConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimCommunicationEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ClaimCommunication",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "message",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "claimId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "claimRef",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ac16faabf4ee2e9b94e3e58156eb337b';
module.exports = node;
