/**
 * @flow
 * @relayHash 8d2951ae8bd69d8b374991be4670110e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type useActionsVideoQueryVariables = {|
  id: string
|};
export type useActionsVideoQueryResponse = {|
  +callSession: ?{|
    +room: {|
      +id: string,
      +videos: ?$ReadOnlyArray<{|
        +id: string,
        +url: string,
      |}>,
    |}
  |}
|};
export type useActionsVideoQuery = {|
  variables: useActionsVideoQueryVariables,
  response: useActionsVideoQueryResponse,
|};
*/


/*
query useActionsVideoQuery(
  $id: ID!
) {
  callSession(id: $id) {
    room {
      id
      videos {
        id
        url
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "room",
  "storageKey": null,
  "args": null,
  "concreteType": "Room",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "videos",
      "storageKey": null,
      "args": null,
      "concreteType": "File",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "useActionsVideoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "callSession",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CallSession",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "useActionsVideoQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "callSession",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CallSession",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "useActionsVideoQuery",
    "id": null,
    "text": "query useActionsVideoQuery(\n  $id: ID!\n) {\n  callSession(id: $id) {\n    room {\n      id\n      videos {\n        id\n        url\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '548bbe8b8c297e08e57cdbea74889b42';
module.exports = node;
