import React from 'react'
import { DatePicker } from 'material-ui-pickers'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'

const DateField = ({ value, form, onChange, label, leadIcon, classes, ...rest }) => {
  return (
    <div className={classes.root}>
      <DatePicker
        // Default styling
        variant='outlined'
        fullWidth
        autoOk
        // keyboard
        clearable
        disableOpenOnEnter
        animateYearScrolling={false}
        InputLabelProps={{shrink:leadIcon && true}}
        InputProps={{
          classes:{input:leadIcon && classes.input}
        }}
        // Date format
        format='DD/MM/YYYY'
        // placeholder='DD/MM/YYYY'
        // mask={value =>
        //   // handle clearing outside if value can be changed outside of the component
        //   value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
        // }

        // More
        // minDate, minDateMessage, maxDate, maxDateMessage

        value={value}
        onChange={date => onChange(date)}
        label={label}
        {...rest}
      />
      {leadIcon && <Icon className={classes.icon} color="action" >{leadIcon}</Icon>}
    </div>
  )
}
export default withStyles(theme => ({
  root:{
    position: 'relative'
  },
  input: {
    padding: '18.5px 45px'
  },
  icon: {
    position: 'absolute',
    top: '35%',
    left: '5%'
  }
}))(DateField)