import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation VariationCompleteMutation(
    $where: ENDataPortfolioKey!
  ) {
    jobVariationComplete(
      where: $where
    ) {
      messages
      success
      result {
        completed
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.jobVariationComplete.success,
          message: res.jobVariationComplete.messages[0]
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}