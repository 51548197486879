/**
 * @flow
 * @relayHash c6573e45bba8e55d92a98e030b122063
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimJobStatus = "VariationApproved" | "VariationRejected" | "VariationReset" | "VariationSubmitted" | "%future added value";
export type JobRecommendationStatus = "NotRecommended" | "Recommended" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuoteRequestStatus = "AdditionalItems" | "AppointmentMade" | "AwaitingInfo" | "AwaitingInfoContents" | "ClaimFinalised" | "InitialCallMade" | "JobAuthorised" | "JobLost" | "NewClaims" | "OfferScopeAcc" | "QuoteSubmitted" | "WaitingQuotes" | "WaitingResult" | "WaitingScope" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type ClaimJobFilter = {|
  id?: ?string,
  parentId?: ?string,
  subject?: ?string,
  createDateFrom?: ?any,
  createDateTo?: ?any,
  incidentDate?: ?any,
  incidentDateFrom?: ?any,
  incidentDateTo?: ?any,
  keyWords?: ?string,
  refNumber?: ?string,
  postcode?: ?string,
  managerTeams?: ?$ReadOnlyArray<?string>,
  managers?: ?$ReadOnlyArray<string>,
  insurers?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  agents?: ?$ReadOnlyArray<string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  buildingSuppliers?: ?$ReadOnlyArray<string>,
  contentsSuppliers?: ?$ReadOnlyArray<string>,
  restorationSuppliers?: ?$ReadOnlyArray<string>,
  buildingStatus?: ?$ReadOnlyArray<string>,
  contentsStatus?: ?$ReadOnlyArray<string>,
  restorationStatus?: ?$ReadOnlyArray<string>,
  lossAdjusterStatus?: ?$ReadOnlyArray<string>,
  suppliers?: ?$ReadOnlyArray<string>,
  status?: ?$ReadOnlyArray<string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  jobStatuses?: ?$ReadOnlyArray<string>,
  claimStatusStageIds?: ?$ReadOnlyArray<string>,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  states?: ?$ReadOnlyArray<?StateEnum>,
  variationStatuses?: ?$ReadOnlyArray<?ClaimJobStatus>,
  requestStatuses?: ?$ReadOnlyArray<?QuoteRequestStatus>,
  recommendationStatus?: ?$ReadOnlyArray<JobRecommendationStatus>,
  or?: ?ClaimJobFilter,
  and?: ?ClaimJobFilter,
  not?: ?ClaimJobFilter,
|};
export type AllocateBuilderActionQueryVariables = {|
  buildersWhere?: ?ClaimJobFilter,
  restorersWhere?: ?ClaimJobFilter,
|};
export type AllocateBuilderActionQueryResponse = {|
  +Building: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +Restoration: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
|};
export type AllocateBuilderActionQuery = {|
  variables: AllocateBuilderActionQueryVariables,
  response: AllocateBuilderActionQueryResponse,
|};
*/


/*
query AllocateBuilderActionQuery(
  $buildersWhere: ClaimJobFilter
  $restorersWhere: ClaimJobFilter
) {
  Building: claimFilterOptions(where: $buildersWhere) {
    label: name
    value
    id
  }
  Restoration: claimFilterOptions(where: $restorersWhere) {
    label: name
    value
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "buildersWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "restorersWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "buildersWhere",
    "type": "ClaimJobFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "restorersWhere",
    "type": "ClaimJobFilter"
  }
],
v6 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AllocateBuilderActionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "Building",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "Restoration",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v4/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllocateBuilderActionQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "Building",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v6/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "Restoration",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v6/*: any*/)
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AllocateBuilderActionQuery",
    "id": null,
    "text": "query AllocateBuilderActionQuery(\n  $buildersWhere: ClaimJobFilter\n  $restorersWhere: ClaimJobFilter\n) {\n  Building: claimFilterOptions(where: $buildersWhere) {\n    label: name\n    value\n    id\n  }\n  Restoration: claimFilterOptions(where: $restorersWhere) {\n    label: name\n    value\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c4aceb849c720cf715fdd68ad6b3a32';
module.exports = node;
