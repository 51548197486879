/**
 * @flow
 * @relayHash 0cf6351ed6ef21c5bfe70ff853d9fdb8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ENDataEntityKey = {|
  id: string
|};
export type AwaitingInfoActionQueryVariables = {|
  claimJobWhere: ENDataEntityKey
|};
export type AwaitingInfoActionQueryResponse = {|
  +building: ?$ReadOnlyArray<?{|
    +name: string,
    +value: string,
  |}>,
  +contents: ?$ReadOnlyArray<?{|
    +name: string,
    +value: string,
  |}>,
  +restoration: ?$ReadOnlyArray<?{|
    +name: string,
    +value: string,
  |}>,
  +claimJob: ?{|
    +hasBuilding: boolean,
    +hasContents: boolean,
    +hasRestoration: boolean,
    +building: ?{|
      +claimStatus: ?{|
        +statusId: number,
        +statusName: ?string,
      |}
    |},
    +restoration: ?{|
      +claimStatus: ?{|
        +statusId: number,
        +statusName: ?string,
      |}
    |},
    +contents: ?{|
      +claimStatus: ?{|
        +statusId: number,
        +statusName: ?string,
      |}
    |},
  |},
|};
export type AwaitingInfoActionQuery = {|
  variables: AwaitingInfoActionQueryVariables,
  response: AwaitingInfoActionQueryResponse,
|};
*/


/*
query AwaitingInfoActionQuery(
  $claimJobWhere: ENDataEntityKey!
) {
  building: claimFilterOptions(where: {subject: "awaitingInfoReasons", portfolios: Building}) {
    name
    value
    id
  }
  contents: claimFilterOptions(where: {subject: "awaitingInfoReasons", portfolios: Contents}) {
    name
    value
    id
  }
  restoration: claimFilterOptions(where: {subject: "awaitingInfoReasons", portfolios: Restoration}) {
    name
    value
    id
  }
  claimJob(where: $claimJobWhere) {
    hasBuilding
    hasContents
    hasRestoration
    building {
      claimStatus {
        statusId
        statusName
        id
      }
      id
    }
    restoration {
      claimStatus {
        statusId
        statusName
        id
      }
      id
    }
    contents {
      claimStatus {
        statusId
        statusName
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "claimJobWhere",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "portfolios": "Building",
      "subject": "awaitingInfoReasons"
    },
    "type": "ClaimJobFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "portfolios": "Contents",
      "subject": "awaitingInfoReasons"
    },
    "type": "ClaimJobFilter"
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "portfolios": "Restoration",
      "subject": "awaitingInfoReasons"
    },
    "type": "ClaimJobFilter"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "claimJobWhere",
    "type": "ENDataEntityKey!"
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasBuilding",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasContents",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasRestoration",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusId",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusName",
  "args": null,
  "storageKey": null
},
v13 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimStatus",
    "storageKey": null,
    "args": null,
    "concreteType": "ClaimStatus",
    "plural": false,
    "selections": [
      (v11/*: any*/),
      (v12/*: any*/)
    ]
  }
],
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v15 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v14/*: any*/)
],
v16 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimStatus",
    "storageKey": null,
    "args": null,
    "concreteType": "ClaimStatus",
    "plural": false,
    "selections": [
      (v11/*: any*/),
      (v12/*: any*/),
      (v14/*: any*/)
    ]
  },
  (v14/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AwaitingInfoActionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "building",
        "name": "claimFilterOptions",
        "storageKey": "claimFilterOptions(where:{\"portfolios\":\"Building\",\"subject\":\"awaitingInfoReasons\"})",
        "args": (v1/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "contents",
        "name": "claimFilterOptions",
        "storageKey": "claimFilterOptions(where:{\"portfolios\":\"Contents\",\"subject\":\"awaitingInfoReasons\"})",
        "args": (v5/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "restoration",
        "name": "claimFilterOptions",
        "storageKey": "claimFilterOptions(where:{\"portfolios\":\"Restoration\",\"subject\":\"awaitingInfoReasons\"})",
        "args": (v6/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "building",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "restoration",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contents",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v13/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AwaitingInfoActionQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "building",
        "name": "claimFilterOptions",
        "storageKey": "claimFilterOptions(where:{\"portfolios\":\"Building\",\"subject\":\"awaitingInfoReasons\"})",
        "args": (v1/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v15/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "contents",
        "name": "claimFilterOptions",
        "storageKey": "claimFilterOptions(where:{\"portfolios\":\"Contents\",\"subject\":\"awaitingInfoReasons\"})",
        "args": (v5/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v15/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "restoration",
        "name": "claimFilterOptions",
        "storageKey": "claimFilterOptions(where:{\"portfolios\":\"Restoration\",\"subject\":\"awaitingInfoReasons\"})",
        "args": (v6/*: any*/),
        "concreteType": "FilterOption",
        "plural": true,
        "selections": (v15/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "building",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "restoration",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contents",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          (v14/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AwaitingInfoActionQuery",
    "id": null,
    "text": "query AwaitingInfoActionQuery(\n  $claimJobWhere: ENDataEntityKey!\n) {\n  building: claimFilterOptions(where: {subject: \"awaitingInfoReasons\", portfolios: Building}) {\n    name\n    value\n    id\n  }\n  contents: claimFilterOptions(where: {subject: \"awaitingInfoReasons\", portfolios: Contents}) {\n    name\n    value\n    id\n  }\n  restoration: claimFilterOptions(where: {subject: \"awaitingInfoReasons\", portfolios: Restoration}) {\n    name\n    value\n    id\n  }\n  claimJob(where: $claimJobWhere) {\n    hasBuilding\n    hasContents\n    hasRestoration\n    building {\n      claimStatus {\n        statusId\n        statusName\n        id\n      }\n      id\n    }\n    restoration {\n      claimStatus {\n        statusId\n        statusName\n        id\n      }\n      id\n    }\n    contents {\n      claimStatus {\n        statusId\n        statusName\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '21201212d3b45d0853576f4370b8cbd6';
module.exports = node;
