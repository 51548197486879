import React from "react";

import { Formik, Form } from "formik";
import Dialog from "components/Mui/Dialog";
import Grid from "components/Mui/Grid";
import MoneyInput from "components/Formik/MoneyInput";
import Button from "components/Mui/Button";

import { graphql } from "relay-hooks";
import { useMutation } from "react-relay-mutation";

import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";

export default ({ symbol }) => {
  const meta = useMetaContext();
  const [updateReserve] = useMutation(UPDATE_RESERVE_MUTATION);
  const snackbar = window[Symbol.for("__snackbar")];

  return (
    <Dialog
      noForm
      noActions
      symbol={symbol}
      title="Update Reserves"
      content={
        <Formik
          initialValues={{
            buildingReserve: "",
            contentsReserve: "",
          }}
          validate={(values) => {
            let errors = {};
            if (!values.buildingReserve && values.buildingReserve !== 0)
              errors.buildingReserve = "Required!";
            if (String(values.buildingReserve).length > 9 + 3)
              errors.buildingReserve = "Maximum character limit is 9";

            if (!values.contentsReserve && values.contentsReserve !== 0)
              errors.contentsReserve = "Required!";
            if (String(values.contentsReserve).length > 9 + 3)
              errors.contentsReserve = "Maximum character limit is 9";

            return errors;
          }}
          onSubmit={(values, actions) => {
            const submitValue = values;
            setTimeout(async () => {
              const {
                data: { success, messages },
              } = await updateReserve({
                variables: {
                  input: submitValue,
                  where: {
                    claimId: meta.claim.claimId,
                  },
                },
              });
              actions.setSubmitting(false);

              if (success) {
                // close
                if (window[Symbol.for(symbol)]) {
                  window[Symbol.for(symbol)].handleClose();
                }
                // and reload
                window[Symbol.for("__refreshClaimDetails")]();
              } else {
                // error message
                snackbar.toggleOpen({ message: messages[0] });
              }
            });
          }}
        >
          {({ values, isSubmitting }) => (
            <RequestForm
              values={values}
              isSubmitting={isSubmitting}
              symbol={symbol}
            />
          )}
        </Formik>
      }
    />
  );
};

const RequestForm = ({ values, isSubmitting, symbol }) => {
  return (
    <Form>
      <Grid fluid>
        <Grid xs={12}>
          <MoneyInput
            required
            label="Building Reserve"
            name="buildingReserve"
          />
        </Grid>
        <Grid xs={12}>
          <MoneyInput
            required
            label="Contents Reserve"
            name="contentsReserve"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Button
          label="Close"
          color="primary"
          onClick={() => {
            if (window[Symbol.for(symbol)]) {
              window[Symbol.for(symbol)].handleClose();
            }
          }}
        />
        <Button
          label="Update"
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        />
      </Grid>
    </Form>
  );
};

const UPDATE_RESERVE_MUTATION = graphql`
  mutation FeeUpdateReservesDialog_CreateMutation(
    $input: ClaimReserveInput!
    $where: ClaimUniqueWhere!
  ) {
    data: claimReserveUpdate(input: $input, where: $where) {
      success
      messages
      fieldErrors {
        fieldName
        level
        message
      }
    }
  }
`;
