/**
 * @flow
 * @relayHash a59c85aa244eb849ca6a551e43b2e702
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobQuoteItemsCreate = {|
  jobQuoteId: number,
  portfolioType: PortfolioType,
  items?: ?$ReadOnlyArray<?JobQuoteItemCreate>,
|};
export type JobQuoteItemCreate = {|
  costCentreId: number,
  costItemId: number,
  lineDescription: string,
  locationId: number,
  scopeTypeId?: ?string,
  propertyTypeId?: ?string,
  propertyNumber?: ?string,
  dimensions?: ?string,
  qty: number,
  unitId: number,
  marginRate: number,
  gst?: ?number,
  rate: number,
|};
export type AddScopeItemMutationVariables = {|
  input: JobQuoteItemsCreate
|};
export type AddScopeItemMutationResponse = {|
  +createJobQuoteItems: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?$ReadOnlyArray<?{|
      +jobQuoteItemId: number,
      +accepted: boolean,
      +location: ?{|
        +costLocationId: number,
        +locationName: string,
      |},
      +dimensions: ?string,
      +costCentre: ?{|
        +costCentreId: number,
        +costCentreName: string,
      |},
      +costItem: ?{|
        +costItemId: number,
        +itemDescription: string,
      |},
      +lineDescription: string,
      +unit: ?{|
        +unitId: number,
        +unitName: string,
      |},
      +directsupply: boolean,
      +purchasePrice: ?string,
      +ageOfItem: ?string,
      +proofOfLoss: ?string,
      +qty: number,
      +rate: ?number,
      +marginRate: number,
      +subtotal: ?number,
    |}>,
    +fieldErrors: ?$ReadOnlyArray<?{|
      +fieldName: string,
      +message: string,
    |}>,
  |}
|};
export type AddScopeItemMutation = {|
  variables: AddScopeItemMutationVariables,
  response: AddScopeItemMutationResponse,
|};
*/


/*
mutation AddScopeItemMutation(
  $input: JobQuoteItemsCreate!
) {
  createJobQuoteItems(input: $input) {
    success
    messages
    result {
      jobQuoteItemId
      accepted
      location {
        costLocationId
        locationName
        id
      }
      dimensions
      costCentre {
        costCentreId
        costCentreName
        id
      }
      costItem {
        costItemId
        itemDescription
        id
      }
      lineDescription
      unit {
        unitId
        unitName
      }
      directsupply
      purchasePrice
      ageOfItem
      proofOfLoss
      qty
      rate
      marginRate
      subtotal
      id
    }
    fieldErrors {
      fieldName
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobQuoteItemsCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "JobQuoteItemsCreate!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "jobQuoteItemId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accepted",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costLocationId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "locationName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimensions",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreId",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costItemId",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemDescription",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lineDescription",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "unit",
  "storageKey": null,
  "args": null,
  "concreteType": "QuoteItemUnit",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "unitId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "unitName",
      "args": null,
      "storageKey": null
    }
  ]
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "directsupply",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "purchasePrice",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ageOfItem",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "proofOfLoss",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "qty",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rate",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "marginRate",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "fieldErrors",
  "storageKey": null,
  "args": null,
  "concreteType": "EntityFieldError",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fieldName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddScopeItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobQuoteItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuoteItemsPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuoteItem",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "location",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              },
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costCentre",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costItem",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostItem",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/)
                ]
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/)
            ]
          },
          (v23/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddScopeItemMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobQuoteItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuoteItemsPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuoteItem",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "location",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v24/*: any*/)
                ]
              },
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costCentre",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v24/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costItem",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostItem",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v24/*: any*/)
                ]
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v24/*: any*/)
            ]
          },
          (v23/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddScopeItemMutation",
    "id": null,
    "text": "mutation AddScopeItemMutation(\n  $input: JobQuoteItemsCreate!\n) {\n  createJobQuoteItems(input: $input) {\n    success\n    messages\n    result {\n      jobQuoteItemId\n      accepted\n      location {\n        costLocationId\n        locationName\n        id\n      }\n      dimensions\n      costCentre {\n        costCentreId\n        costCentreName\n        id\n      }\n      costItem {\n        costItemId\n        itemDescription\n        id\n      }\n      lineDescription\n      unit {\n        unitId\n        unitName\n      }\n      directsupply\n      purchasePrice\n      ageOfItem\n      proofOfLoss\n      qty\n      rate\n      marginRate\n      subtotal\n      id\n    }\n    fieldErrors {\n      fieldName\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c9ba98d349db9672c57b7fc12f260bfc';
module.exports = node;
