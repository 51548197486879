import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import Badge from '@material-ui/core/Badge'
import MuiIconButton from '@material-ui/core/IconButton'

class IconButton extends Component {
  state = {
    isOn: false
  }
  render () {
    const { 
      classes, className, iconClass,
      icon, iconOn,
      onClick,
      badgeContent, badgeMax, badgeColor,
      iconStyle,
      ...rest
    } = this.props

    const { isOn } = this.state
    return (
      <MuiIconButton
        className={`${className ? className : ''} ${classes.button}`}
        onClick={this.onClickHandle}
        {...rest}
      >
        <Badge badgeContent={badgeContent ? badgeContent : ''} max={badgeMax} color={badgeColor ? badgeColor : 'error'}>
          <Icon style={iconStyle} className={`${iconClass ? iconClass : '' } ${classes.icon}`}>
            {isOn ? (iconOn ? iconOn : icon) : icon }
          </Icon>
        </Badge>
      </MuiIconButton>  
    )
  }

  onClickHandle = e => {
    this.setState({isOn: !this.state.isOn})
    this.props.onClick && this.props.onClick(e)
  }
}

export default withStyles(theme => ({
  button: {
    margin: theme.spacing.unit,
  }
}))(IconButton)