import React, { Component, createRef } from "react";

import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";
import { states, filtersData } from "common/static_data";
import moment from "moment";
import Collapse from "@material-ui/core/Collapse";

import Icon from "@material-ui/core/Icon";
import Paper from "components/Mui/Paper";
import Grid from "components/Mui/Grid";
import Input from "components/Mui/Input";
import Button from "components/Mui/Button";
import Form from "components/Mui/Form";
import MultipleSelect from "components/MultipleSelect";
import { CubeGrid } from "components/Mui/Loader";
import DateInput from "components/Mui/DateInput";
import { intData, mapUserType } from "common/utils";
import IconButton from "components/Mui/IconButton";
import { withStyles } from "@material-ui/core/styles";
import transformFilterGroup from "common/utils/transformFilterGroup";

const query = graphql`
  query CommunicationListFilterQuery {
    currentUser {
      communicationFilters {
        icon
        id
        isDisabled
        label
        name
        options {
          value: id
          group
          label
        }
        type
      }
    }
  }
`;

class CommunicationListFilter extends Component {
  state = {
    date: null,
    searching: false,
    filterMore: false,
  };
  keyWordsInput = createRef();
  dateInput = createRef();

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container style={{ width: "100%", marginTop: 0 }}>
          <Grid item xs>
            <Paper
              style={{ position: "relative" }}
              content={
                <Form onChange={this.handleChange}>
                  <QueryRenderer
                    environment={environment}
                    query={query}
                    render={({ error, props }) => {
                      if (error) return "Error";
                      if (!props)
                        return (
                          <div style={{ height: "60px" }}>
                            <CubeGrid />
                          </div>
                        );
                      const optionArray =
                        props.currentUser.communicationFilters;
                      const firstRow = optionArray.slice(0, 6);
                      const subRow = optionArray.slice(6);

                      return (
                        <>
                          <Grid fluid>
                            {firstRow.map((item) => {
                              return (
                                <Grid item xs={2}>
                                  {item.type === "Search" && (
                                    <Input
                                      inputRef={this.keyWordsInput}
                                      label="Search"
                                      leadDom={<Icon>search</Icon>}
                                      inputProps={{
                                        maxlength: 255,
                                      }}
                                      trailDom={
                                        <Button
                                          onClick={this.changeKeyWords}
                                          variant="contained"
                                          color="primary"
                                          label="Go"
                                          type="submit"
                                          disabled={this.state.searching}
                                        />
                                      }
                                    />
                                  )}
                                  {item.type === "ComboBox_multiple" && (
                                    <MultipleSelect
                                      filtered
                                      filterPage="CommunicationListFilter"
                                      label={item.label}
                                      id={item.name}
                                      outline
                                      leadIcon={item.icon}
                                      options={
                                        item.options !== 0
                                          ? item.options
                                          : [{ label: "Empty", value: "" }]
                                      }
                                    />
                                  )}
                                  {item.type === "Date" && (
                                    <DateInput
                                      label="Date"
                                      value={this.state.date}
                                      onChange={(date) => this.changeDate(date)}
                                      maxDate={moment()}
                                    />
                                  )}
                                </Grid>
                              );
                            })}
                          </Grid>
                          <Collapse
                            in={this.state.filterMore}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Grid fluid>
                              {subRow.map((item) => {
                                return (
                                  <Grid item xs={2}>
                                    {item.type === "ComboBox_multiple" && (
                                      <MultipleSelect
                                        filtered
                                        filterPage="CommunicationListFilter"
                                        label={item.label}
                                        id={item.name}
                                        outline
                                        leadIcon={item.icon}
                                        options={
                                          item.options !== 0
                                            ? item.options
                                            : [{ label: "Empty", value: "" }]
                                        }
                                      />
                                    )}
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Collapse>
                          {subRow && Boolean(subRow.length) && (
                            <IconButton
                              style={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                              }}
                              icon={
                                this.state.filterMore
                                  ? "expand_less"
                                  : "expand_more"
                              }
                              onClick={(e) =>
                                this.setState({
                                  filterMore: !this.state.filterMore,
                                })
                              }
                            />
                          )}
                        </>
                      );
                    }}
                  />
                </Form>
              }
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  changeKeyWords = () => {
    const keyWords = this.keyWordsInput.current.value;
    const where = {
      keyWords: keyWords ? String(keyWords) : undefined,
    };
    this.setState({ searching: true }, () => {
      setTimeout(() => this.setState({ searching: false }), 1000);
    });
    this.props.filterChange(where);
  };

  changeDate = (date) => {
    this.setState({ date });
    const where = {
      createDateFrom: date ? date.toISOString() : undefined,
    };

    this.props.filterChange(where);
  };

  handleChange = (data) => {
    this.props.filterChange(transformFilterGroup(data));
  };
}

export default withStyles((Theme) => ({
  "@supports ( -moz-appearance:none )": {
    root: {
      transformOrigin: "1rem 1rem",
      width: "110%",
      transform: "scale(0.91)",
    },
  },
  root: {
    transformOrigin: "1rem 1rem",
    width: "110%",
    transform: "scale(0.91)",
  },
}))(CommunicationListFilter);
