/**
 * @flow
 * @relayHash 9dc26506869d16792842d10a637995b3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type SupplierStatusFilter = "AcceptedOnly" | "RequestedAndAvailable" | "RequestedOnly" | "%future added value";
export type SupplierTypeFilter = "ManagerAndSupplier" | "QuotingSupplier" | "Supplier" | "SupplierExcludeScoping" | "%future added value";
export type CostedScopeOptionWhere = {|
  insurerId: string
|};
export type ENDataEntityKey = {|
  id: string
|};
export type ClaimParticipantIndex = {|
  claimId: string,
  postcode?: ?string,
  portfolioTypes?: ?$ReadOnlyArray<?PortfolioType>,
  participantStatus?: ?SupplierStatusFilter,
  participantType?: ?SupplierTypeFilter,
|};
export type SiteReportActionBarQueryVariables = {|
  where: CostedScopeOptionWhere,
  isFetch: boolean,
  claimJobWhere: ENDataEntityKey,
  isBuilding: boolean,
  isRestoration: boolean,
  claimParticipantsWhere?: ?ClaimParticipantIndex,
|};
export type SiteReportActionBarQueryResponse = {|
  +costedScopeOptions?: ?$ReadOnlyArray<?{|
    +value: number,
    +label: string,
  |}>,
  +claimParticipants: ?$ReadOnlyArray<?{|
    +companyName: string,
    +companyId: number,
    +requested: boolean,
    +id: string,
  |}>,
  +claimJob: ?{|
    +view: ?{|
      +actions: {|
        +requestMultipleQuotesOrTenders: {|
          +building?: ?{|
            +id: string,
            +isDisabled: boolean,
            +isDisplay: boolean,
            +label: string,
            +name: string,
          |},
          +restoration?: ?{|
            +id: string,
            +isDisabled: boolean,
            +isDisplay: boolean,
            +label: string,
            +name: string,
          |},
        |}
      |}
    |}
  |},
|};
export type SiteReportActionBarQuery = {|
  variables: SiteReportActionBarQueryVariables,
  response: SiteReportActionBarQueryResponse,
|};
*/


/*
query SiteReportActionBarQuery(
  $where: CostedScopeOptionWhere!
  $isFetch: Boolean!
  $claimJobWhere: ENDataEntityKey!
  $isBuilding: Boolean!
  $isRestoration: Boolean!
  $claimParticipantsWhere: ClaimParticipantIndex
) {
  costedScopeOptions(where: $where) @include(if: $isFetch) {
    value: costScopeOptionId
    label: displayName
    id
  }
  claimParticipants(where: $claimParticipantsWhere) {
    companyName
    companyId
    requested
    id
  }
  claimJob(where: $claimJobWhere) {
    view {
      actions {
        requestMultipleQuotesOrTenders {
          building @include(if: $isBuilding) {
            id
            isDisabled
            isDisplay
            label
            name
          }
          restoration @include(if: $isRestoration) {
            id
            isDisabled
            isDisplay
            label
            name
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "CostedScopeOptionWhere!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isFetch",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "claimJobWhere",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isBuilding",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isRestoration",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "claimParticipantsWhere",
    "type": "ClaimParticipantIndex",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "claimParticipants",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "claimParticipantsWhere",
      "type": "ClaimParticipantIndex"
    }
  ],
  "concreteType": "ClaimParticipant",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requested",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/)
  ]
},
v3 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "claimJobWhere",
    "type": "ENDataEntityKey!"
  }
],
v4 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "isDisabled",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "isDisplay",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "label",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "view",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimJobView",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actions",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimJobAction",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "requestMultipleQuotesOrTenders",
          "storageKey": null,
          "args": null,
          "concreteType": "PortfolioAction",
          "plural": false,
          "selections": [
            {
              "kind": "Condition",
              "passingValue": true,
              "condition": "isBuilding",
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "building",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ActionControl",
                  "plural": false,
                  "selections": (v4/*: any*/)
                }
              ]
            },
            {
              "kind": "Condition",
              "passingValue": true,
              "condition": "isRestoration",
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "restoration",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ActionControl",
                  "plural": false,
                  "selections": (v4/*: any*/)
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v6 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "CostedScopeOptionWhere!"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "costScopeOptionId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "displayName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SiteReportActionBarQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v2/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v3/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isFetch",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "costedScopeOptions",
            "storageKey": null,
            "args": (v6/*: any*/),
            "concreteType": "CostedScopeOption",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SiteReportActionBarQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v2/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v3/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/)
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isFetch",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "costedScopeOptions",
            "storageKey": null,
            "args": (v6/*: any*/),
            "concreteType": "CostedScopeOption",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SiteReportActionBarQuery",
    "id": null,
    "text": "query SiteReportActionBarQuery(\n  $where: CostedScopeOptionWhere!\n  $isFetch: Boolean!\n  $claimJobWhere: ENDataEntityKey!\n  $isBuilding: Boolean!\n  $isRestoration: Boolean!\n  $claimParticipantsWhere: ClaimParticipantIndex\n) {\n  costedScopeOptions(where: $where) @include(if: $isFetch) {\n    value: costScopeOptionId\n    label: displayName\n    id\n  }\n  claimParticipants(where: $claimParticipantsWhere) {\n    companyName\n    companyId\n    requested\n    id\n  }\n  claimJob(where: $claimJobWhere) {\n    view {\n      actions {\n        requestMultipleQuotesOrTenders {\n          building @include(if: $isBuilding) {\n            id\n            isDisabled\n            isDisplay\n            label\n            name\n          }\n          restoration @include(if: $isRestoration) {\n            id\n            isDisabled\n            isDisplay\n            label\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8ae2b9e6276a6c3d5da8ab1b53abb7ad';
module.exports = node;
