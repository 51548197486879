import React from "react";
import { QueryRenderer, graphql } from "react-relay";
import environment from "common/relay";
import usePortfolio from "hooks/usePortfolio";
import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";

import Grid from "components/Mui/Grid";

import ClaimDetailsPortfolioFilter from "../../ClaimDetailsPortfolioFilter";
import VariationActions from "./VariationActions";
import VariationList from "./VariationList/";

const Variations = () => {
  const meta = React.useContext(ClaimMetaContext);
  const [portfolio, changePortfolio] = usePortfolio([
    "building",
    "restoration",
  ]);

  const [update, setUpdate] = React.useState(false);
  React.useEffect(() => {
    window[Symbol.for("__refreshVariation")] = () => setUpdate(!update);
    return () => {
      delete window[Symbol.for("__refreshVariation")];
    };
  }, [update]);

  if (!portfolio) return null;

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        where: {
          portfolios: portfolio,
          claimId: meta.claim.claimId,
          includeValidationItems: false,
        },
        update,
      }}
      render={({ errors, props }) => {
        if (!props) return null;

        return (
          <>
            <Grid container>
              <Grid item xs={12}>
                <VariationActions meta={meta} portfolios={portfolio} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ position: "relative" }}>
                <ClaimDetailsPortfolioFilter
                  noAll
                  portfolio={{
                    hasBuilding: meta.claim.hasBuilding,
                    hasRestoration: meta.claim.hasRestoration,
                  }}
                  changePortfolio={changePortfolio}
                  value={portfolio.toLowerCase()}
                />
                <VariationList
                  variations={props.variations}
                  portfolios={portfolio}
                  setUpdate={setUpdate}
                />
              </Grid>
            </Grid>
          </>
        );
      }}
    />
  );
};

export default Variations;

const query = graphql`
  query VariationsQuery($where: JobVariationIndex!) {
    variations: jobVariations(where: $where, first: 10000000)
      @connection(key: "VariationsQuery_variations", filters: []) {
      edges {
        node {
          variationId
          # date
          logdate
          # name
          variationTitle
          variationDescription
          # reason
          variationReason {
            reasonDescription
          }
          #cost
          total
          #status
          variationStatus
        }
      }
    }
  }
`;
