import React from "react";
import Button from "components/Mui/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form } from "formik";
import Grid from "@material-ui/core/Grid";
import { useMutation } from "react-relay-mutation";
import Input from "components/Formik/Input";
import RecommendVariationValidationMutation from "./RecommendVariationValidationMutation";

const VariationRecommend = ({ variation, portfolio }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [recommendValidation] = useMutation(
    RecommendVariationValidationMutation
  );

  const isJustifiedOptions = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

  const snackbar = window[Symbol.for("__snackbar")];
  return (
    <div style={{ textAlign: "right" }}>
      {variation.actions.recommendVariation.isDisplay && (
        <Button
          variant="outlined"
          color="primary"
          disabled={variation.actions.recommendVariation.isDisabled}
          label={variation.actions.recommendVariation.label}
          onClick={handleClickOpen}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        minWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          Recommend Variation Validation
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              justified: null,
            }}
            onSubmit={(values, actions) => {
              setTimeout(async () => {
                const res = await recommendValidation({
                  variables: {
                    input: {
                      justified: values.justified,
                      comments: values.comments,
                    },
                    where: {
                      id: variation.variationId,
                      portfolioType: portfolio,
                    },
                  },
                });
                if (res) {
                  window[Symbol.for("__snackbar")].toggleOpen({
                    message: res.jobVariationValidationRecommend.messages,
                  });
                  handleClose();
                } else {
                  snackbar.toggleOpen({
                    message: res.jobVariationValidationRecommend.messages,
                  });
                }
              }, 400);
            }}
            validate={(values) => {
              let errors = {};

              if (values.isClaim === null) errors.isClaim = "Required";
              if (values.requestQuote === null)
                errors.requestQuote = "Required";
              if (
                values.requestQuote &&
                JSON.stringify(values.quotingSupplierIds) === "[]"
              ) {
                errors.quotingSupplierIds =
                  "quoting Supplier is Required! At least one";
                window[Symbol.for("__snackbar")].toggleOpen({
                  message: errors.quotingSupplierIds,
                });
              }
              return errors;
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={8} justify="left">
                    <Grid item xs={12}>
                      <Input
                        required
                        name="justified"
                        label="Is Variation Justified"
                        options={isJustifiedOptions}
                        value={values.justified}
                        select
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Communication To Case Manager"
                        name="comments"
                        rows={5}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Button
                      style={{
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: "20px",
                        width: "100%",
                      }}
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="primary"
                      label="Recommend Variation Validation"
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VariationRecommend;
