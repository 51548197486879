import React, { Component, Fragment } from 'react'
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'
import Button from 'components/Mui/HexagonButton'

import Card from './Card'
import Grid from 'components/Mui/Grid'

import { withStyles } from '@material-ui/core/styles'
import DashboardInfo from './DashboardInfo'

const Dashboard = ({ classes, data, where }) =>{
  return (
    <Grid className={classes.root} container>

      {data.map(e=>
        <Grid item xs className={classes.tableSpacing}>
          <Card data={e} where={where} />
        </Grid>  
      )}
      <DashboardInfo />
    </Grid>
  )
}

export default withStyles(() => ({
  root: {
    margin: '10px auto 0 auto',
  },
  tableSpacing:{
    padding:'4px',
    flexGrow: 0,
    flexBasis: 'auto'
  },
  bullet: {
    backgroundColor:'#f8f4f2'
  }
}))(Dashboard)

