import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "components/Mui/Paper";
import Button from "components/Mui/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ScopeAddLineItem from "./ScopeAddLineItem";
import CompleteScopeAction from "./CompleteScopeAction";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "components/Formik/Input";

import ResetQuoteMutation from "./ResetQuoteAction/ResetQuoteMutation";
import QuoteReviewMutation from "./ReviewQuoteAction/QuoteReviewMutation";
import RequestQuotesMutation from "./RequestQuoteAction/RequestQuotesMutation";
import ExportToExcelMutation from "./ExportToExcelAction/ExportToExcelMutation";
import CompleteScopeMutation from "./CompleteScopeAction/CompleteScopeMutation";
import AllocateScopeSupplierAction from "./AllocateScopeSupplierAction/";
import ScopeValidation from "./ScopeValidation";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form } from "formik";
import StartScopeValidationMutation from "./StartScopeValidation/StartScopeValidationMutation";
import DialogContent from "@material-ui/core/DialogContent";
import { useMutation } from "react-relay-mutation";

const query = graphql`
  query ScopeActionBarQuery(
    $where: JobScopeTypeWhere!
    $reasonWhere: TransactionReasonWhere!
    $validationWhere: ValidationActionWhere!
  ) {
    jobScopeTypes(where: $where) {
      id
      name
    }
    validationAction(where: $validationWhere) {
      value: id
      label: name
      communicationRequired
    }
    transactionReasons(where: $reasonWhere) {
      value: id
      label: name
    }
  }
`;

const queryWithoutTransaction = graphql`
  query ScopeActionBarWithoutTransactionQuery($where: JobScopeTypeWhere!) {
    jobScopeTypes(where: $where) {
      id
      name
    }
  }
`;

const ScopeActionBar = ({
  classes,
  quote,
  meta,
  portfolio,
  isOwnCompany,
  isContentsAction,
  requestCategory,
  scopeTypeId,
  isProperties,
  setUpdate,
}) => {
  return (
    <Paper>
      <Grid container justify="space-between" className={classes.root}>
        <Grid item xs={2} className={classes.actionBlock}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            label="Next Step"
            onClick={handleNextStep}
          />
        </Grid>
        <QuoteActions
          classes={classes}
          quote={quote}
          meta={meta}
          portfolio={portfolio}
          scopeTypeId={scopeTypeId}
          isOwnCompany={isOwnCompany}
          isContentsAction={isContentsAction}
          requestCategory={requestCategory}
          isProperties={isProperties}
          setUpdate={setUpdate}
        />
      </Grid>
    </Paper>
  );
};

const QuoteActions = ({
  classes,
  quote,
  meta,
  portfolio,
  isOwnCompany,
  isContentsAction,
  requestCategory,
  scopeTypeId,
  isProperties,
  setUpdate,
}) => {
  const [category, toggleCategory] = requestCategory;
  const snackbar = window[Symbol.for("__snackbar")];
  const [startValidation] = useMutation(StartScopeValidationMutation);
  const [openStartValidationDialog, setOpenStartValidationDialog] =
    React.useState(false);
  const handleStartValidationOpen = () => {
    setOpenStartValidationDialog(true);
  };
  const handleStartValidationClose = () => {
    setOpenStartValidationDialog(false);
  };
  const portfolioMeta = meta.claim[portfolio.toLowerCase()];

  const display = meta.claim.view.actions.updateScopeType.isDisplay;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAndMutation = (variables) => {
    setAnchorEl(null);
    handleExportToExcel(variables);
  };

  const handleAllocateScopeSupplier = () => {
    if (window[Symbol.for("__allocateScopeSupplierAction")]) {
      window[Symbol.for("__allocateScopeSupplierAction")].handleOpen();
    }
  };
  if (!quote)
    return (
      <>
        <QueryRenderer
          environment={environment}
          query={queryWithoutTransaction}
          variables={{
            where: {
              insurerId: meta.claim.insurer.companyId,
              portfolioType: portfolio,
              quotationType: "Scope",
              claimId: meta.claim.claimId,
              forManager: false,
            },
          }}
          render={({ error, props }) => {
            if (!props) return null;
            else
              return (
                <>
                  <Grid item xs={2} className={classes.actionBlock}>
                    {portfolio !== "contents" &&
                      meta.claim.view.actions.reallocateSupplier[
                        portfolio.toLowerCase()
                      ] &&
                      meta.claim.view.actions.reallocateSupplier[
                        portfolio.toLowerCase()
                      ].isDisplay && (
                        <Button
                          fullWidth
                          label="Reallocate Supplier"
                          variant="outlined"
                          color="primary"
                          onClick={() => handleAllocateScopeSupplier()}
                        />
                      )}
                    {/* ALLOCATE_SCOPE_SUPPLIER: meta, portfolio */}
                    <AllocateScopeSupplierAction
                      meta={meta}
                      portfolioType={portfolio}
                    />
                    <Button
                      fullWidth
                      label="Export to excel"
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        handleCloseAndMutation({
                          where: {
                            claimId: meta.claim.claimId,
                            portfolios: portfolio,
                            requestCategory: "Scoping",
                            scopeTypeId: null,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.actionBlock}></Grid>
                </>
              );
          }}
        />
      </>
    );
  return (
    <>
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{
          where: {
            insurerId: meta.claim.insurer.companyId,
            portfolioType: portfolio,
            quotationType: "Scope",
            claimId: meta.claim.claimId,
          },
          reasonWhere: {
            insurerId: meta.claim.insurer.companyId,
            transactionType: "JOB_BUILDING_RESET",
          },
          validationWhere: {
            quoteId: quote.jobQuoteId,
            portfolioType: portfolio,
            type: "Scope",
          },
        }}
        render={({ error, props }) => {
          if (!props) return null;
          else {
            const handleScopeValidation = () => {
              if (window[Symbol.for("__ScopeValidationAction")]) {
                window[Symbol.for("__ScopeValidationAction")].handleOpen();
              }
            };
            const transactionReasons = props.transactionReasons;
            const validationStatus = props.validationAction;
            var excelMenuList = [{ label: "All Scope Type", value: null }];
            props.jobScopeTypes.map((e) => {
              excelMenuList.push({ label: e.name, value: e.id });
            });
            var scopeExcelButton = null;
            if (display) {
              scopeExcelButton = (
                <>
                  <Button
                    fullWidth
                    label="Export to excel"
                    variant="outlined"
                    color="primary"
                    aria-controls="excelMenuButton"
                    aria-haspopup="true"
                    variant="outlined"
                    color="primary"
                    onClick={handleClick}
                  />
                  <Menu
                    id="excelMenuButton"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {excelMenuList.map((option, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() =>
                            handleCloseAndMutation({
                              where: {
                                claimId: meta.claim.claimId,
                                portfolios: portfolio,
                                requestCategory: "Scoping",
                                scopeTypeId: option.value,
                              },
                            })
                          }
                        >
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </>
              );
            } else
              scopeExcelButton = (
                <>
                  <Button
                    fullWidth
                    label="Export to excel"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      handleCloseAndMutation({
                        where: {
                          claimId: meta.claim.claimId,
                          portfolios: portfolio,
                          requestCategory: "Scoping",
                          scopeTypeId: null,
                        },
                      })
                    }
                  />
                </>
              );

            return (
              <>
                <Grid item xs={2} className={classes.actionBlock}>
                  {quote.actions.addLineItem.isDisplay && (
                    <Button
                      fullWidth
                      label={quote.actions.addLineItem.label}
                      variant="outlined"
                      color="primary"
                      isDisabled={quote.actions.addLineItem.isDisabled}
                      onClick={() => addLineItem()}
                    />
                  )}
                  {quote.actions.reallocateSupplier.isDisplay && (
                    <Button
                      fullWidth
                      label={quote.actions.reallocateSupplier.label}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleAllocateScopeSupplier()}
                    />
                  )}
                  {scopeExcelButton}
                  {portfolioMeta.authorised && meta.user.type.isAdmin && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={category !== "Scoping"}
                          onChange={toggleCategory}
                        />
                      }
                      label={
                        category === "Scoping"
                          ? "Original Scope"
                          : "Approved Scope"
                      }
                    />
                  )}
                </Grid>
                {quote.actions.startValidation.isDisplay && (
                  <Grid item xs={2} className={classes.actionBlock}>
                    <Button
                      fullWidth
                      label={quote.actions.startValidation.label}
                      variant="outlined"
                      color="primary"
                      disabled={quote.actions.startValidation.isDisabled}
                      onClick={() => handleStartValidationOpen()}
                    />
                    <Dialog
                      fullWidth
                      maxWidth="md"
                      open={openStartValidationDialog}
                      onClose={handleStartValidationClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {quote.actions.startValidation.label}
                      </DialogTitle>
                      <DialogContent>
                        <Grid item xs={12}>
                          <Formik
                            initialValues={{
                              reasonId: null,
                              message: null,
                              validationStatus: "ValidationInProgress",
                            }}
                            validate={(values) => {
                              let errors = {};
                              if (
                                values.validationStatus ===
                                  "ValidationResetJob" &&
                                !values.reasonId
                              )
                                errors.reasonId = "Please Select The Reason";
                              if (
                                values.validationStatus ===
                                  "ValidationResetJob" &&
                                !values.message
                              )
                                errors.reasonId = "Please Leave The Message";
                              return errors;
                            }}
                            onSubmit={(values, actions) => {
                              setTimeout(async () => {
                                const res = await startValidation({
                                  variables: {
                                    input: {
                                      ...values,
                                    },
                                    where: {
                                      id: quote.jobQuoteId,
                                      portfolioType: portfolio,
                                    },
                                  },
                                });
                                if (res) {
                                  const refresh =
                                    window[Symbol.for("__refreshScope")];
                                  if (res.jobScopeValidationStart.success) {
                                    handleStartValidationClose();
                                    //setUpdate(true);
                                    if (
                                      values.validationStatus ===
                                      "ValidationInProgress"
                                    ) {
                                      handleScopeValidation();
                                    } else {
                                      refresh();
                                      window[
                                        Symbol.for("__refreshClaimDetails")
                                      ]();
                                    }
                                  } else {
                                    snackbar.toggleOpen({
                                      message:
                                        res.jobScopeValidationStart.messages,
                                    });
                                  }
                                }
                              }, 400);
                            }}
                          >
                            {({ isSubmitting, values }) => {
                              return (
                                <Form>
                                  <Grid fluid spacing={8} justify="center">
                                    <Grid item xs={12}>
                                      <Input
                                        required
                                        name="validationStatus"
                                        label="Action"
                                        options={validationStatus}
                                        value={values.validationStatus}
                                        select
                                        isShrink
                                      />
                                      {values.validationStatus ===
                                        "ValidationResetJob" && (
                                        <Input
                                          required
                                          name="reasonId"
                                          label="Reason"
                                          options={transactionReasons}
                                          value={values.reasonId}
                                          select
                                          isShrink
                                        />
                                      )}
                                      {validationStatus.find(
                                        (item) =>
                                          item.value === values.validationStatus
                                      ).communicationRequired && (
                                        <Input
                                          name="message"
                                          label="Message To Supplier"
                                          rows={4}
                                          value={values.message}
                                          isShrink
                                          required={true}
                                        />
                                      )}
                                    </Grid>
                                    <Button
                                      style={{
                                        marginLeft: 0,
                                        marginRight: 0,
                                        marginTop: "20px",
                                        width: "100%",
                                      }}
                                      fullWidth
                                      variant="contained"
                                      type="submit"
                                      color="primary"
                                      label="Submit"
                                      disabled={isSubmitting}
                                    />
                                  </Grid>
                                </Form>
                              );
                            }}
                          </Formik>
                        </Grid>
                      </DialogContent>
                    </Dialog>
                  </Grid>
                )}
                {quote.actions.viewValidation.isDisplay && (
                  <Grid item xs={2} className={classes.actionBlock}>
                    <Button
                      fullWidth
                      label={quote.actions.viewValidation.label}
                      variant="outlined"
                      color="primary"
                      disabled={quote.actions.viewValidation.isDisabled}
                      onClick={() => {
                        handleScopeValidation();
                      }}
                    />
                  </Grid>
                )}
                {quote.actions.resetQuote.isDisplay && (
                  <Grid item xs={2} className={classes.actionBlock}>
                    <Button
                      fullWidth
                      label={quote.actions.resetQuote.label}
                      disabled={quote.actions.resetQuote.isDisabled}
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        handleResetScope(quote.jobQuoteId, portfolio, setUpdate)
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={2} className={classes.actionBlock}>
                  {quote.actions.reviewQuote.isDisplay && (
                    <Button
                      fullWidth
                      label={quote.actions.reviewQuote.label}
                      disabled={quote.actions.reviewQuote.isDisabled}
                      variant="outlined"
                      color="primary"
                      onClick={(e) =>
                        handleQuoteReview(quote.jobQuoteId, portfolio)
                      }
                    />
                  )}
                  {quote.actions.submitQuote.isDisplay && (
                    <Button
                      onClick={() =>
                        handleCompleteScope(
                          portfolio,
                          quote.jobQuoteId,
                          meta.claim.claimId,
                          setUpdate
                        )
                      }
                      fullWidth
                      label={quote.actions.submitQuote.label}
                      variant="outlined"
                      color="primary"
                      disabled={quote.actions.submitQuote.isDisabled}
                    />
                  )}
                  {quote.actions.requestQuotes.isDisplay && (
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      disabled={quote.actions.requestQuotes.isDisabled}
                      label={quote.actions.requestQuotes.label}
                      onClick={() =>
                        handleRequestQuotes(meta.claim.claimId, portfolio)
                      }
                    />
                  )}
                </Grid>
                {/* ADD_LINE_ITEN: jobQuoteId, portfolioType */}
                <ScopeAddLineItem
                  symbol="__scopeAddItem"
                  jobQuoteId={quote.jobQuoteId}
                  portfolioType={portfolio}
                  scopeTypeId={scopeTypeId}
                  isProperties={isProperties}
                  quote={quote}
                />
                {/* ADD_LINE_ITEN: claimId, jobQuoteId, portfolioType */}
                <CompleteScopeAction
                  symbol="__scopeComplete"
                  claimId={meta.claim.claimId}
                  id={quote.jobQuoteId}
                  portfolioType={portfolio}
                />
                {/* ALLOCATE_SCOPE_SUPPLIER: meta, portfolio */}
                <AllocateScopeSupplierAction
                  meta={meta}
                  portfolioType={portfolio}
                />
                <ScopeValidation
                  meta={meta}
                  portfolioType={portfolio}
                  setUpdate={setUpdate}
                  isDisplay={true}
                />
              </>
            );
          }
        }}
      />
    </>
  );
};

const handleExportToExcel = async (variables) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const res = await ExportToExcelMutation(variables);
  if (res.ok) {
    if (res.success) {
      window.open(res.messages[0], "_blank");
    } else {
      snackbar.toggleOpen({
        message: res.messages[0],
      });
    }
  } else {
    snackbar.toggleOpen({
      message: res.messages[0],
    });
  }
};

const handleNextStep = () => {
  const actions = window[Symbol.for("__nextStepAction")];
  if (actions) {
    actions.handleOpen();
  }
};
const addLineItem = () => {
  const actions = window[Symbol.for("__scopeAddItem")];
  if (actions) {
    actions.handleOpen();
  }
};
const handleResetScope = async (id, portfolioType, setUpdate) => {
  const snackbar = window[Symbol.for("__snackbar")];

  const res = await ResetQuoteMutation({
    where: {
      id,
      portfolioType,
    },
  });
  if (res.ok) {
    snackbar.toggleOpen({ message: res.messages[0] });
    setUpdate();
  }
};
const handleQuoteReview = async (id, portfolioType) => {
  const snackbar = window[Symbol.for("__snackbar")];

  const snackbarRes = await snackbar.toggleOpen({
    message: "Have you reviewed a quotable scope?",
    firstButton: "Yes",
    secondButton: "No",
    type: "confirm",
  });
  if (snackbarRes.ok) {
    const res = await QuoteReviewMutation({
      where: {
        id,
        portfolioType,
      },
    });
    if (res.ok) {
      if (res.success) {
        snackbar.toggleOpen({
          message: res.messages ? res.messages[0] : "Request quotes completed",
        });
      } else {
        snackbar.toggleOpen({ message: res.messages[0] });
      }
    }
  }
};
const handleCompleteScope = async (portfolioType, id, claimId, setUpdate) => {
  const actions = window[Symbol.for("__scopeComplete")];
  const snackbar = window[Symbol.for("__snackbar")];
  if (actions) {
    if (portfolioType !== "Contents") actions.handleOpen();
    else {
      const variables = {
        input: { startAfterContractReceived: "" },
        where: {
          claimId,
          id,
          portfolioType,
        },
      };
      const snackbarRes = await snackbar.toggleOpen({
        message: "Would you like to complete scope?",
        firstButton: "Yes",
        secondButton: "No",
        type: "confirm",
      });
      if (snackbarRes.ok) {
        const res = await CompleteScopeMutation(variables);
        if (res.ok) {
          snackbar.toggleOpen({ message: res.messages[0] });
          setUpdate(true);
        } else {
          snackbar.toggleOpen({
            message: "Can not complete scope, please try again",
          });
        }
      }
    }
  }
};
const handleRequestQuotes = async (claimId, portfolioType) => {
  const snackbar = window[Symbol.for("__snackbar")];

  const snackbarRes = await snackbar.toggleOpen({
    message: "Would you like to request quotes?",
    firstButton: "Yes",
    secondButton: "No",
    type: "confirm",
  });
  if (snackbarRes.ok) {
    const res = await RequestQuotesMutation({
      where: {
        id: claimId,
        portfolioType,
      },
    });
    if (res.ok) {
      if (res.success) {
        snackbar.toggleOpen({ message: "Request quotes completed" });
      } else {
        snackbar.toggleOpen({ message: res.messages[0] });
      }
    }
  }
};

export default withStyles((theme) => ({
  root: {
    width: "100%",
  },
  actionBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))(ScopeActionBar);
