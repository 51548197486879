import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const StartScopeValidationMutation = graphql`
  mutation StartScopeValidationMutation(
    $input: JobValidationStartInput!
    $where: ENDataPortfolioKey!
  ) {
    jobScopeValidationStart(input: $input, where: $where) {
      success
      messages
      result {
        id
        jobQuoteId
        actions {
          completeValidation {
            id
            isDisabled
            isDisplay
            label
          }
          resetValidation {
            label
            isDisabled
            isDisplay
          }
        }
        scopeTypes {
          discount
          fields {
            marginBreakdown {
              isDisplay
              label
            }
            discount {
              isDisplay
              label
              isDisabled
            }
            sIRRate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            gst {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            total {
              label
              isDisplay
            }
            action {
              label
              isDisplay
            }
            costCentre {
              label
              isDisplay
            }
            description {
              label
              isDisplay
            }
            marginRate {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            qty {
              label
              isDisplay
            }
            rate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            unit {
              label
              isDisplay
            }
          }
          gst
          id
          locations {
            id
            location {
              id
              costLocationId
              locationName
            }
            items {
              propertyNumber
              propertyType {
                id
              }
              jobQuoteItemId
              accepted
              location {
                costLocationId
                locationName
              }
              dimensions
              costCentre {
                costCentreId
                costCentreName
              }
              costItem {
                costItemId
                itemDescription
              }
              lineDescription
              unit {
                unitId
                unitName
              }
              directsupply
              purchasePrice
              ageOfItem
              proofOfLoss
              policyLimit
              qty
              rate
              marginRate
              subtotal
              logdate
              policyLimit
              requestedQuote
              sirRate
              scopeType {
                name
                id
              }
            }
          }
          margin
          scopeType {
            id
            name
          }
          sirGst
          sirMargin
          sirSubtotal
          sirTotal
          subtotal
          total
        }
      }
    }
  }
`;

export default StartScopeValidationMutation;
