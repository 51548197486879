/**
 * @flow
 * @relayHash d164f7499b5509289a248612a812d02e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuoteCostType = "Labour" | "Material" | "UnDefined" | "%future added value";
export type RequestCategory = "All" | "AssessorReport" | "Authorised" | "DesktopQuoting" | "Lost" | "ScopeValidation" | "Scoping" | "%future added value";
export type JobQuoteIndex = {|
  id?: ?$ReadOnlyArray<number>,
  claimId?: ?string,
  scopeTypeId?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  requestCategory?: ?RequestCategory,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type ScopeGroupCostCentreTableQueryVariables = {|
  where: JobQuoteIndex
|};
export type ScopeGroupCostCentreTableQueryResponse = {|
  +jobQuotes: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +scopeTypes: ?$ReadOnlyArray<?{|
          +scopeType: ?{|
            +name: string
          |},
          +costCentres: ?$ReadOnlyArray<?{|
            +costCentre: ?{|
              +costCentreName: string,
              +costCentreId: number,
              +costType: ?QuoteCostType,
              +directSupplier: boolean,
            |},
            +units: ?$ReadOnlyArray<?{|
              +qty: number,
              +subtotal: ?number,
              +unitName: string,
              +validationQty: ?number,
              +validationSubtotal: ?number,
            |}>,
          |}>,
        |}>
      |}
    |}>
  |}
|};
export type ScopeGroupCostCentreTableQuery = {|
  variables: ScopeGroupCostCentreTableQueryVariables,
  response: ScopeGroupCostCentreTableQueryResponse,
|};
*/


/*
query ScopeGroupCostCentreTableQuery(
  $where: JobQuoteIndex!
) {
  jobQuotes(where: $where) {
    edges {
      node {
        scopeTypes {
          scopeType {
            name
            id
          }
          costCentres {
            costCentre {
              costCentreName
              costCentreId
              costType
              directSupplier
              id
            }
            units {
              qty
              subtotal
              unitName
              validationQty
              validationSubtotal
              id
            }
            id
          }
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JobQuoteIndex!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "JobQuoteIndex!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costType",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "directSupplier",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "qty",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unitName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "validationQty",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "validationSubtotal",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ScopeGroupCostCentreTableQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobQuotes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuoteConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuoteEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobQuote",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "scopeTypes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobQuoteScopeTypeGroup",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "scopeType",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "JobScopeType",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "costCentres",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "JobQuoteItemCostCentreGroup",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "costCentre",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "QuoteCostCentre",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "units",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "JobQuoteUnitGroup",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ScopeGroupCostCentreTableQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobQuotes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobQuoteConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobQuoteEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobQuote",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "scopeTypes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobQuoteScopeTypeGroup",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "scopeType",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "JobScopeType",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v12/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "costCentres",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "JobQuoteItemCostCentreGroup",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "costCentre",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "QuoteCostCentre",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v12/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "units",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "JobQuoteUnitGroup",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ]
                          },
                          (v12/*: any*/)
                        ]
                      },
                      (v12/*: any*/)
                    ]
                  },
                  (v12/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ScopeGroupCostCentreTableQuery",
    "id": null,
    "text": "query ScopeGroupCostCentreTableQuery(\n  $where: JobQuoteIndex!\n) {\n  jobQuotes(where: $where) {\n    edges {\n      node {\n        scopeTypes {\n          scopeType {\n            name\n            id\n          }\n          costCentres {\n            costCentre {\n              costCentreName\n              costCentreId\n              costType\n              directSupplier\n              id\n            }\n            units {\n              qty\n              subtotal\n              unitName\n              validationQty\n              validationSubtotal\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a179a5526e842876ab88851d4af6e3c0';
module.exports = node;
