import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation VariationAuditMutation(
    $where: ENDataPortfolioKey!
    $input: JobVariationAuditInput!
  ) {
    jobVariationAudit(
      where: $where
      input: $input
    ) {
      messages
      success
      result {
        completed
        variationStatus
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.jobVariationAudit.success,
          message: res.jobVariationAudit.messages[0]
        })
      },
      onError: errors => resolve({
        ok: false,
        success: false,
        message: 'Unable to complete this action. Please try again!'
      })
    })
  })
}