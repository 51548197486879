import React, { Component } from 'react'
import { QueryRenderer, graphql } from 'react-relay'
import environment from 'common/relay'
import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import Dialog from 'components/Mui/Dialog'
import Button from 'components/Mui/Button'
import Form from 'components/Mui/Form'
import { getSafe } from 'common/utils'
import AcceptJobQuoteMutation from '../QuotesAction/AcceptJobQuoteMutation'
import NoScopeTypeLineItemListGroup from 'components/LineItemList/SmallLineItemListGroup/NoScopeTypeLineItemListGroup'
import ScopeTypeLineItemListGroup from 'components/LineItemList/SmallLineItemListGroup/ScopeTypeLineItemListGroup'

const query = graphql`
  query AcceptQuoteDialogQuery($where: JobQuoteIndex!, $isScopeType: Boolean!, $jobScopeWhere: JobScopeTypeWhere!) {
    ScopeTypeOptions: jobScopeTypes(where: $jobScopeWhere) @include (if: $isScopeType) {
      value: id
      label: name
    }
    jobQuotes(where: $where) {
      edges {
        node {
          id
          scopeTypes(processed: false) @include (if: $isScopeType) {
            id
            subtotal
            total
            gst
            margin
            discount
            locations {
              id
              location {
                id
                costLocationId
                locationName
              }
              items {
                jobQuoteItemId
                location { costLocationId locationName } dimensions
                costCentre { costCentreId costCentreName }
                costItem { costItemId itemDescription } lineDescription
                unit { unitId unitName }
                qty
                rate
                marginRate
                subtotal
                scopeType {
                  name
                  id
                }
              }
            }
            scopeType {
              id
              name
            }
            id
            fields {
            discount {
              isDisplay
              label
              isDisabled
            }
            gst {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            total {
              label
              isDisplay
            }
            action {
              label
              isDisplay
            }
            costCentre {
              label
              isDisplay
            }
            description {
              label
              isDisplay
            }
            marginRate {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            qty {
              label
              isDisplay
            }
            rate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            unit {
              label
              isDisplay
            }
          }
        }
          items: lineItems @skip (if: $isScopeType) {
            location { costLocationId locationName } dimensions
            itemId: jobQuoteItemId
            costCentre { costCentreName }
            costItem { itemDescription } lineDescription
            unit { unitName }
            qty
            rate
            margin
            subtotal
          }
          notDone: notAcceptedItems @skip (if: $isScopeType) {
            itemId: jobQuoteItemId

            costCentre { costCentreName }
            costItem { itemDescription } lineDescription
            unit { unitName }
            qty
            rate
            margin
            subtotal
          }
        }
      }
    }
  }
`

class AcceptQuoteDialog extends Component {
  snackbar = window[Symbol.for('__snackbar')]
  state = {
    update: false
  }

  update = () => {
    this.setState({update: !this.state.update})
  }

  render() {
    const { classes, dlRef, id, portfolioType, meta } = this.props
    return (
      <Dialog noForm noActions
        innerRef={dlRef}
        title='Accept Quote'
        onClose={() => {
          const refreshQuotes = window[Symbol.for('__refreshQuotes')]
          refreshQuotes.handleRefresh()
        }}
        content={(
          <Form onSubmit={e => this.handleSubmit(e)}>
            <Grid fluid spacing={0}>
              <QueryRenderer
                environment={environment}
                query={query}
                variables={{
                  update: this.state.update,
                  where: {
                    id,
                    portfolios: portfolioType
                  },
                  jobScopeWhere: {
                    insurerId: meta.claim.insurer.companyId,
                    quotationType: 'Scope',
                    portfolioType: portfolioType,
                    forManager: true,
                    claimId: this.props.meta.claim.claimId
                  },
                  isScopeType: meta.claim.view.actions.updateScopeType.isDisplay
                }}
                render={({ errors, props }) => {
                  if(!props) return 'Loading'
                  if(props.jobQuotes.edges.length === 0) return 'No quote'
                  const quote = props.jobQuotes.edges.length !== 0
                  ? props.jobQuotes.edges[0].node
                  : null
                  let group
                  const { items } = getSafe(() => props.jobQuotes.edges[0].node, [])
                  if(!meta.claim.view.actions.updateScopeType.isDisplay) {
                    group = Object.values(items.reduce((total, current) => {
                      return ({
                        ...total,
                        [current.location.costLocationId]:
                          total[current.location.costLocationId]
                            ? {
                              ...total[current.location.costLocationId],
                              items: [
                                ...total[current.location.costLocationId].items,
                                current
                              ]
                            }
                            : {
                              name: current.location.locationName,
                              items: [ current ]
                            }
                      })
                    }, {}))
                  }
                  return (
                    <Grid fluid spacing={0} style={{ paddingLeft: 16 }}>
                          {
                            meta.claim.view.actions.updateScopeType.isDisplay ?
                            <ScopeTypeLineItemListGroup
                              quote={quote}
                              ScopeTypeOptions={props.ScopeTypeOptions}
                              portfolioType={portfolioType}
                              update={this.update}
                              quotationType="Quotes"
                            />
                              :
                            (
                              <Paper
                              style={{ width: '100%' }}
                              title={<AcceptQuoteDialogHeader classes={classes} />}
                              >
                                <div className={classes.bodyList}>
                                  {
                                    group.map((item, index) => (
                                      <Grid item xs={12} key={index}>
                                        <NoScopeTypeLineItemListGroup item={item} type={'quotes'}/>
                                      </Grid>
                                    ))
                                  }
                                </div>
                              </Paper>
                            )
                          }
                    </Grid>
                  )
                }}
              />
              <div style={{ marginLeft: 'auto' }}>
                <Button color='primary'
                  label='Close'
                  onClick={this.handleCloseDl}
                />
                <Button color='primary' variant='contained'
                  label='Accept' type='submit'
                  // onClick={this.acceptQuote}
                />
              </div>
            </Grid>
          </Form>
        )}
      />
    )
  }

  handleSubmit = data => {
    const ints = Object.entries(data)
      .map(x => {
        if(x[1] !== true) return parseInt(x[0])
        return null
      })
      .filter(x => x !== null)

    this.acceptQuote(ints)
  }

  handleCloseDl = () => {
    const refreshQuotes = window[Symbol.for('__refreshQuotes')]
    refreshQuotes.handleRefresh()
    this.props.dlRef.current.handleClose()
  }

  acceptQuote = async notAcceptedItemIds => {
    const claimDetailsRefresh = window[Symbol.for('__refreshClaimDetails')]
    const { portfolioType, id } = this.props
    const confirm = await this.snackbar.toggleOpen({
      message: 'Do you want to accept the scope?',
      firstButton: 'Yes',
      secondButton: 'No',
      type: 'confirm',
    })

    if(confirm.ok) {
      const res = await AcceptJobQuoteMutation({
        where: { id, portfolioType },
        input: notAcceptedItemIds.length !== 0
          ? { notAcceptedItemIds }
          : undefined
      })

      if(res) {
        this.snackbar.toggleOpen({
          message: res.message[0],
        })
        if(res.success) {
          if(claimDetailsRefresh) claimDetailsRefresh()
        }
      }
    }
  }
}

export default withStyles(theme => ({
  headerList: {
    '& > div': {
      borderRight: '1px solid white',
    },
    '& > div:last-child': {
      borderRight: 0,
    }
  },
  headerListItems: {
    ...theme.mixins.alignJustifyContainer(theme),
    height: 26
  },
  bodyList: {
    maxHeight: '65vh',
    overflow: 'auto',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  bodyListItems: {
    ...theme.mixins.alignJustifyContainer(theme)
  },
}))(AcceptQuoteDialog)

const AcceptQuoteDialogHeader = ({ classes }) => (
  <Grid fluid spacing={8}
    direction='row'
    justify='center'
    alignItems='center'
    className={classes.headerList}
  >
    <Grid className={classes.headerListItems} item xs></Grid>
    <Grid className={classes.headerListItems} item xs={2}>Cost Centre</Grid>
    <Grid className={classes.headerListItems} item xs={4}>Description</Grid>
    <Grid className={classes.headerListItems} item xs>Unit</Grid>
    <Grid className={classes.headerListItems} item xs>Qty</Grid>
    <Grid className={classes.headerListItems} item xs>Rate</Grid>
    <Grid className={classes.headerListItems} item xs>Margin</Grid>
    <Grid className={classes.headerListItems} item xs>Subtotal</Grid>
  </Grid>
)
