import { QueryResponseCache } from 'relay-runtime'

const cachingQueries = ['ClaimListFilterQuery', 'AddClaimDetailsCardQuery']

export const cache = new QueryResponseCache({
  size: 100, // 100 requests
  ttl: 15 * 60 * 1000 // 15 minutes
})

export const cacheMiddleware = () => {
  return next => async req => {
    // const token = localStorage.getItem('TOKEN')
    if(req.isMutation() || req.isFormData()) return next(req)
    try {
      const queryId = req.getID()
      const variables = req.getVariables()
      let cacheRes = cache.get(queryId, variables)
      if(cachingQueries.indexOf(queryId) !== -1) {

        if(cacheRes) return cacheRes

        const res = await next(req)

        const data = Object.values(res.data).filter(d => d !== null)

        if(!res.errors || (res.errors && data.length !== 0)) {
          cache.set(queryId, variables, res)
        }
        return res
      }
    }
    catch (e) {
      console.log({ e })
    }

    return next(req)
  }
}