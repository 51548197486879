/**
 * @flow
 * @relayHash e00bd87be3a53650e565f16fb46b800a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type FeeCategoryWhere = {|
  claimId: string,
  claimPortfolioTypes?: ?$ReadOnlyArray<PortfolioType>,
|};
export type FormBodyCreateDialogQueryVariables = {|
  where: FeeCategoryWhere
|};
export type FormBodyCreateDialogQueryResponse = {|
  +feeCategories: ?$ReadOnlyArray<?{|
    +value: number,
    +label: string,
    +feeTypes: $ReadOnlyArray<?{|
      +value: number,
      +label: string,
      +suppliers: ?$ReadOnlyArray<?{|
        +value: number,
        +label: string,
      |}>,
      +suppliersAndAmounts: ?$ReadOnlyArray<?{|
        +label: string,
        +value: number,
        +defaultAmount: ?number,
        +maxAmount: ?number,
      |}>,
    |}>,
  |}>
|};
export type FormBodyCreateDialogQuery = {|
  variables: FormBodyCreateDialogQueryVariables,
  response: FormBodyCreateDialogQueryResponse,
|};
*/


/*
query FormBodyCreateDialogQuery(
  $where: FeeCategoryWhere!
) {
  feeCategories(where: $where) {
    value: feeCategoryId
    label: name
    feeTypes {
      value: feeTypeId
      label: name
      suppliers {
        value: companyId
        label: companyName
        id
      }
      suppliersAndAmounts {
        label: supplierName
        value: supplierId
        defaultAmount
        maxAmount
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "FeeCategoryWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "FeeCategoryWhere!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "feeCategoryId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "feeTypeId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "companyId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "supplierName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "supplierId",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "defaultAmount",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "maxAmount",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FormBodyCreateDialogQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feeCategories",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FeeCategory",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feeTypes",
            "storageKey": null,
            "args": null,
            "concreteType": "FeeType",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "suppliers",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "suppliersAndAmounts",
                "storageKey": null,
                "args": null,
                "concreteType": "FeeAmount",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FormBodyCreateDialogQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feeCategories",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FeeCategory",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feeTypes",
            "storageKey": null,
            "args": null,
            "concreteType": "FeeType",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "suppliers",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v11/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "suppliersAndAmounts",
                "storageKey": null,
                "args": null,
                "concreteType": "FeeAmount",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ]
              },
              (v11/*: any*/)
            ]
          },
          (v11/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FormBodyCreateDialogQuery",
    "id": null,
    "text": "query FormBodyCreateDialogQuery(\n  $where: FeeCategoryWhere!\n) {\n  feeCategories(where: $where) {\n    value: feeCategoryId\n    label: name\n    feeTypes {\n      value: feeTypeId\n      label: name\n      suppliers {\n        value: companyId\n        label: companyName\n        id\n      }\n      suppliersAndAmounts {\n        label: supplierName\n        value: supplierId\n        defaultAmount\n        maxAmount\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cb659478cffdf3c4c0e7dda52039bac3';
module.exports = node;
