import React, { Component } from "react";
import { QueryRenderer, graphql } from "react-relay";
import environment from "common/relay";
import Dialog from "components/Mui/Dialog";
import { getSafe } from "common/utils";
import { withRouter } from "react-router-dom";

import { withClaimMeta } from "../../../ClaimMetaContext";

import VariationDetailsPresentation from "./VariationDetailsPresentation";

class VariationDetails extends Component {
  state = {
    refresh: true,
  };

  render() {
    const { dlRef, id, portfolios, meta, location, setUpdate } = this.props;
    const isFromDashboard = location.search.includes("reportItemId");
    const originalUrl = location.search.substr(
      0,
      location.search.lastIndexOf("&")
    );
    return (
      <Dialog
        noForm
        noActions
        innerRef={dlRef}
        paperProps={{
          style: { minWidth: "70%" },
        }}
        onClose={
          isFromDashboard
            ? this.props.history.push(`/claim/variations${originalUrl}`)
            : null
        }
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              where: { id, portfolios, includeValidationItems: false },
              claimWhere: { id: meta.claim.claimId },
              refresh: this.state.refresh,
              jobScopeWhere: {
                insurerId: meta.claim.insurer.companyId,
                quotationType: "Variation",
                portfolioType: portfolios,
                claimId: this.props.meta.claim.claimId,
                forManager: true,
              },
              jobScopeAddLineItemWhere: {
                insurerId: meta.claim.insurer.companyId,
                quotationType: "Variation",
                portfolioType: portfolios,
              },
              reasonWhere: {
                insurerId: meta.claim.insurer.companyId,
                transactionType: "JOB_BUILDING_RESET",
              },
            }}
            render={({ errors, props }) => {
              if (!props || props.jobVariations.edges.length === 0) return null;
              const variation = props.jobVariations.edges[0].node;
              const claim = props.claimJob;
              const transactionReasons = props.transactionReasons;

              // info vars
              const { riskAddress } = claim.incidentDetail;
              const { name, phone1, phone2, phone3 } = claim.insured;
              // report & lineItems
              const {
                subtotal,
                gst,
                margin,
                total,
                lineItems,
                variationId,
                completed,
                variationStatus,
              } = variation;
              const info = [
                { label: "Insurance Ref", text: claim.refNumber },
                {
                  label: "Customer" /* label:'Client Name'|'Customer' */,
                  text: name,
                },
                {
                  label: "Variation Name",
                  text: `${variation.variationTitle} - ${variation.variationDescription}`,
                },
                {
                  label:
                    "Property/Risk Address" /* label:'Risk Address'|'Property/Risk Address' */,
                  text: `${riskAddress.line1}, ${riskAddress.suburb} ${riskAddress.state} ${riskAddress.postcode}`,
                },
                {
                  label: "Variation Reason",
                  text: getSafe(
                    () => variation.variationReason.reasonDescription,
                    ""
                  ),
                },
                {
                  label: "Phone",
                  text: `${phone1}${phone2 ? `, ${phone2}` : ""}${
                    phone3 ? `, ${phone3}` : ""
                  }`,
                },
              ];

              return (
                <VariationDetailsPresentation
                  portfolio={portfolios}
                  completed={completed}
                  ScopeTypeOptions={props.ScopeTypeOptions}
                  actionData={{
                    variationId,
                    completed,
                    variationStatus,
                    portfolios,
                  }}
                  variation={variation}
                  meta={meta}
                  info={info}
                  items={lineItems}
                  report={{ subtotal, gst, margin, total }}
                  scopeTypeId={props.ScopeTypeAddLineItem}
                  handleRefresh={this.handleRefresh}
                  transactionReasons={transactionReasons}
                  setUpdate={setUpdate}
                />
              );
            }}
          />
        }
      />
    );
  }

  handleRefresh = () => {
    this.setState({ refresh: !this.state.refresh });
  };
}

export default withClaimMeta(withRouter(VariationDetails));

const query = graphql`
  query VariationDetailsQuery(
    $where: JobVariationIndex!
    $claimWhere: ENDataEntityKey!
    $jobScopeWhere: JobScopeTypeWhere!
    $jobScopeAddLineItemWhere: JobScopeTypeWhere!
    $reasonWhere: TransactionReasonWhere!
  ) {
    ScopeTypeOptions: jobScopeTypes(where: $jobScopeWhere) {
      value: id
      label: name
    }
    transactionReasons(where: $reasonWhere) {
      value: id
      label: name
    }
    jobVariations(where: $where, first: 1000000)
      @connection(key: "VariationDetailsQuery_jobVariations", filters: []) {
      edges {
        node {
          id
          variationId
          completed
          variationStatus

          # info card
          variationTitle
          variationDescription
          variationReason {
            reasonDescription
          }
          actions {
            removeAuthority {
              isDisabled
              isDisplay
              label
            }
            resetValidation {
              isDisabled
              isDisplay
              label
            }
            recommendVariation {
              isDisabled
              isDisplay
              label
            }
            viewValidation {
              isDisabled
              isDisplay
              label
            }
            accept {
              isDisabled
              isDisplay
              label
            }
            addLineItem {
              isDisabled
              isDisplay
              label
            }
            decline {
              isDisabled
              isDisplay
              label
            }
            reset {
              isDisabled
              isDisplay
              label
            }
            submit {
              isDisabled
              isDisplay
              label
            }
            submitValidation {
              isDisabled
              isDisplay
              label
            }
            startValidation {
              isDisabled
              isDisplay
              label
            }
            completeValidation {
              isDisabled
              isDisplay
              label
            }
            submitValidation {
              isDisabled
              isDisplay
              label
            }
          }

          scopeTypes {
            properties {
              locations {
                location {
                  id
                  costLocationId
                  locationName
                }
                items {
                  actions {
                    accept {
                      isDisabled
                      isDisplay
                      label
                    }
                    amend {
                      isDisabled
                      isDisplay
                      label
                    }
                    decline {
                      isDisabled
                      isDisplay
                      label
                    }
                    validate {
                      isDisabled
                      isDisplay
                      label
                    }
                  }
                  variationItemId
                  quoteItemType
                  accepted
                  location {
                    costLocationId
                    locationName
                  }
                  dimensions
                  costCentre {
                    costCentreId
                    costCentreName
                  }
                  costItem {
                    costItemId
                    itemDescription
                  }
                  lineDescription
                  unit {
                    unitId
                    unitName
                  }
                  qty
                  note
                  lineStatusDescription
                  rate
                  marginRate
                  subtotal
                  logdate
                  sirRate
                  scopeType {
                    name
                    id
                  }
                }
                validationItems {
                  actions {
                    accept {
                      isDisabled
                      isDisplay
                      label
                    }
                    amend {
                      isDisabled
                      isDisplay
                      label
                    }
                    decline {
                      isDisabled
                      isDisplay
                      label
                    }
                    validate {
                      isDisabled
                      isDisplay
                      label
                    }
                  }
                  variationItemId
                  quoteItemType
                  accepted
                  location {
                    costLocationId
                    locationName
                  }
                  dimensions
                  costCentre {
                    costCentreId
                    costCentreName
                  }
                  costItem {
                    costItemId
                    itemDescription
                  }
                  lineDescription
                  unit {
                    unitId
                    unitName
                  }
                  qty
                  note
                  lineStatusDescription
                  rate
                  marginRate
                  subtotal
                  logdate
                  sirRate
                  scopeType {
                    name
                    id
                  }
                }
              }
            }
            locations {
              id
              location {
                id
                costLocationId
                locationName
              }
              items {
                actions {
                  accept {
                    isDisabled
                    isDisplay
                    label
                  }
                  amend {
                    isDisabled
                    isDisplay
                    label
                  }
                  decline {
                    isDisabled
                    isDisplay
                    label
                  }
                  validate {
                    isDisabled
                    isDisplay
                    label
                  }
                }
                variationItemId
                quoteItemType
                accepted
                location {
                  costLocationId
                  locationName
                }
                dimensions
                costCentre {
                  costCentreId
                  costCentreName
                }
                costItem {
                  costItemId
                  itemDescription
                }
                lineDescription
                unit {
                  unitId
                  unitName
                }
                qty
                note
                lineStatusDescription
                rate
                marginRate
                subtotal
                logdate
                sirRate
                scopeType {
                  name
                  id
                }
              }
            }
            scopeType {
              id
              name
            }
            id
            gst
            margin
            sirGst
            sirMargin
            sirSubtotal
            sirTotal
            subtotal
            total
            discount
            fields {
              marginBreakdown {
                isDisplay
                label
              }
              discount {
                isDisplay
                label
                isDisabled
              }
              sIRRate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              gst {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              total {
                label
                isDisplay
              }
              action {
                label
                isDisplay
              }
              costCentre {
                label
                isDisplay
              }
              description {
                label
                isDisplay
              }
              marginRate {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              qty {
                label
                isDisplay
              }
              rate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              unit {
                label
                isDisplay
              }
            }
          }

          # item table
          lineItems {
            variationItemId
            accepted

            location {
              costLocationId
              locationName
            }
            dimensions
            sirRate
            costCentre {
              costCentreId
              costCentreName
            }
            costItem {
              costItemId
              itemDescription
            }
            lineDescription
            unit {
              unitId
              unitName
            }
            marginRate
            qty
            rate
            gst
            subtotal
          }

          # report
          subtotal
          gst
          margin
          total
        }
      }
    }
    claimJob(where: $claimWhere) {
      # info card
      refNumber
      insured {
        name
        phone1
        phone2
        phone3
      }
      incidentDetail {
        riskAddress {
          line1
          suburb
          state
          postcode
        }
      }
    }
    ScopeTypeAddLineItem: jobScopeTypes(where: $jobScopeAddLineItemWhere) {
      id
    }
  }
`;
