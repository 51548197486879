import React from 'react'
import { Button, IconButton, TextField, withStyles } from '@material-ui/core'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import Pagination from 'components/Mui/Pagination'


const CustomPagination = props => {
  const {onSelect, setOnSelect, totalPage, onChangePage, totalNumber, classes}= props
  const [number, setNumber] = React.useState()

  React.useEffect(()=>{
    onChangePage(onSelect, totalPage)
  },[onSelect, totalPage])
  return (
    <div className={classes.root}>
      <div>
        <p>{`Showing ${((onSelect-1)*10)+1} to ${onSelect*10 <= totalNumber ? onSelect*10 : totalNumber} of ${totalNumber} entries`}</p>
      </div>
      <div className={classes.paginate}>
        <TextField
          label="Jump To"
          variant="outlined"
          size="small"
          onChange={e => setNumber(e.target.value)}
          style={{margin:'0', maxWidth:'200px'}}
          InputProps={{
            endAdornment:(
              <Button variant="contained" onClick={e=>{
                if(number && number>0 && number<=totalPage){
                setOnSelect(parseInt(number))}}}>Go</Button>
            )
          }}
        />

        <FirstPageIcon onClick={() => {setOnSelect(1)}} className={classes.iconButton}/>
          <Pagination onSelect={onSelect} setOnSelect={setOnSelect} totalPage={totalPage} />
        <LastPageIcon className={classes.iconButton} onClick={() => {setOnSelect(totalPage)}}/>
      </div>
    </div>
  )
}

export default withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  paginate: {
    display:'flex',
    justifyContent:'flex-end',
    alignItems:'center'
  },
  iconButton: {
    '&:hover': {
      backgroundColor:'lightgrey',
      cursor: 'pointer'
    }
  }
}))(CustomPagination)

