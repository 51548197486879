export default inputRef => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const file = inputRef.files[0]
    if(inputRef && !inputRef.files && inputRef.files.length === 0) {
      resolve({ok: false})
      return     
    }
    reader.onload = e => {
      resolve({
        ok: true,
        fileBase64: e.target.result.slice(
          e.target.result.indexOf('base64,') + 7,
          e.target.result.length
        ),
        fileName: file.name,
      })
    }
    reader.onerror = e => {
      resolve({ok: false})
    }

    if(file) reader.readAsDataURL(file)
    else resolve({ok: false})
  })
}

export const parseFromFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    if(!file) {
      resolve({ok: false})
      return
    }
    reader.onload = e => {
      resolve({
        ok: true,
        fileBase64: e.target.result.slice(
          e.target.result.indexOf('base64,') + 7,
          e.target.result.length
        ),
        fileName: file.name,
      })
    }
    reader.onerror = e => {
      resolve({ok: false})
    }

    if(file) reader.readAsDataURL(file)
    else resolve({ok: false})
  })
}