import React from 'react'
import { graphql } from 'relay-runtime'
import { useMutation } from 'react-relay-mutation'
import uaParser from 'ua-parser-js'

export default ({ skip, callSessionId, signallingData, isSupportNoDevice, forceStop }) => {
  const [joinRoom] = useMutation(mutation)

  const [state, setState] = React.useState({
    ready: false,
    serverSignallingData: null,
    error: null,
  })

  const signallingDataString = signallingData ? JSON.stringify(signallingData) : null
  React.useEffect(() => {
    if(!skip) {
      // getUserAddressInfo()
      //   .then(userAddressInfo => {
          const userAgentInfo = getUserAgentInfo()
        //   return { userAgentInfo, userAddressInfo }
        // })
        // .then(({ userAgentInfo, userAddressInfo }) => {
          // return
          joinRoom({
            variables: {
              where: { callSessionId },
              data: {
                sdpOffer: signallingData.offer,
                clientIceCandidates: signallingData.candidates,
                userAgentInfo,
                isSupportNoDevice: isSupportNoDevice,
                forceStop: forceStop
                // userAddressInfo
              }
            }
          })
        // })
        .then(res => {
          const { sdpAnswer, serverIceCandidates } = res.roomJoin

          setState({
            ready: true,
            serverSignallingData: {
              answer: sdpAnswer,
              candidates: serverIceCandidates
            }
          })
        })
        .catch(error => {
          setState({ ready: true, error })
        })
    }
  }, [skip, callSessionId, signallingDataString, forceStop])

  return state
}

const mutation = graphql`
  mutation useSignallingMutation($where: RoomJoinWhereInput, $data: RoomJoinDataInput) {
    roomJoin(where: $where, data: $data) {
      sdpAnswer
      serverIceCandidates
      callSession { id callStatus { status message }}
    }
  }
`

const getUserAgentInfo = () => {
  const parser = new uaParser()
  const userAgentInfo = parser.getResult()
  return JSON.stringify(userAgentInfo)
}

const getUserAddressInfo = () => {
  return fetch('https://extreme-ip-lookup.com/json')
    .then(res => res.json())
    .then(res => ({
      ip: res.query,
      lat: res.lat,
      long: res.lon,
      city: res.city,
      region: res.region,
      country: res.country,
      countryCode: res.countryCode,
      continent: res.continent
    }))
    .then(JSON.stringify)
}
