import React, { Component } from 'react'

import Paper from 'components/Mui/Paper/'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import classNames from 'clsx'

class QuickAction extends Component {
  state = {
    collapsed: false,
    display: false,
    unmount: true,
  }

  componentDidMount = () => {
    window[Symbol.for(this.props.symbol)] = {
      toggleOpen: this.toggleOpen,
      toggleCollapse: this.toggleCollapse,
      display: this.state.display
    }
  }

  componentWillUnmount = () => {
    delete window[Symbol.for(this.props.symbol)]
  }

  render() {
    const {
      classes,
      title, content,
      symbol,
      unmountOnClose,
      extraButton,
      extraAction,
      width,
      extraCloseAction,
      ...rest
    } = this.props
    const { collapsed, display, unmount } = this.state
    const hidden = this.props.unmountOnClose ? false : !display
    return (
      (!unmount || !this.props.unmountOnClose) && (
        <Paper
          style={{width: width}}
          className={classNames(
            classes.root,
            {[classes.hidden]: hidden})
          }
          title={
            <>
              <div className={classes.titleSpan}>
                {title}
                {extraButton && React.cloneElement(extraButton, {className: classes.extraButton})}
              </div>
              <div className={classes.titleButtons}>
                <Icon onClick={this.toggleCollapse}>{collapsed ? 'add': 'remove'}</Icon>
                {extraAction && React.cloneElement(extraAction)}
                <Icon onClick={() => this.toggleOpen(extraCloseAction)}>close</Icon>
              </div>
            </>
          }
          titleClass={classes.title}
          content={content}
          contentClass={collapsed ? classes.hidden : ''}
          {...rest}
        />
      )
    )
  }

  toggleCollapse = e => {
    this.setState({collapsed: !this.state.collapsed})
  }

  toggleOpen = (extraCloseAction) => {
    if(this.props.unmountOnClose) {
      this.setState({unmount: !this.state.unmount})
    }
    else {
      this.setState({display: !this.state.display})
    }
    if(extraCloseAction) extraCloseAction()
  }
}

export default withStyles(theme => ({
  root: {
    width: '30rem',
    marginRight: '5px'
  },
  hidden: {
    display: 'none',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleSpan: {
    display: 'flex',
    alignItems: 'center'
  },
  extraButton: {
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'white'
    }
  }

}))(QuickAction)
