/**
 * @flow
 * @relayHash e27f8ee7886bad40f5656638993a7e56
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobVariationCreate = {|
  variationTitle: string,
  variationDescription: string,
  variationReasonId: number,
  claimJobId: string,
  portfolioType?: ?PortfolioType,
|};
export type AddVariationMutationVariables = {|
  input: JobVariationCreate
|};
export type AddVariationMutationResponse = {|
  +createJobVariation: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +variationId: number,
      +logdate: any,
      +variationTitle: string,
      +variationDescription: string,
      +variationReason: ?{|
        +reasonDescription: string
      |},
      +total: ?number,
      +variationStatus: ?string,
    |},
  |}
|};
export type AddVariationMutation = {|
  variables: AddVariationMutationVariables,
  response: AddVariationMutationResponse,
|};
*/


/*
mutation AddVariationMutation(
  $input: JobVariationCreate!
) {
  createJobVariation(input: $input) {
    messages
    success
    result {
      variationId
      logdate
      variationTitle
      variationDescription
      variationReason {
        reasonDescription
      }
      total
      variationStatus
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobVariationCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "JobVariationCreate!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logdate",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationTitle",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationDescription",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "variationReason",
  "storageKey": null,
  "args": null,
  "concreteType": "JobVariationReason",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reasonDescription",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationStatus",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddVariationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobVariation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobVariationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariation",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddVariationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobVariation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobVariationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariation",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddVariationMutation",
    "id": null,
    "text": "mutation AddVariationMutation(\n  $input: JobVariationCreate!\n) {\n  createJobVariation(input: $input) {\n    messages\n    success\n    result {\n      variationId\n      logdate\n      variationTitle\n      variationDescription\n      variationReason {\n        reasonDescription\n      }\n      total\n      variationStatus\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '32558bf5c9ba995b6b0f4a39062fc874';
module.exports = node;
