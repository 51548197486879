import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation MakeSafeAppoinmentMadeMutation($input: AppointmentInput!, $where: ENDataPortfolioKey!) {
    claimMakeSafeAppointment(input: $input, where: $where) {
      success
      messages
      result {
        makeSafeProgress {
          appointmentBooked
        }
      }
    }
  }
`

export default (variables, id) => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: ({claimMakeSafeAppointment}) => {
          const { messages, success } = claimMakeSafeAppointment
          resolve({ ok: true, success, messages })
        },
        updater: store => {
          const success = store.getRootField('claimMakeSafeAppointment')
          .getValue('success')
          if(success) {
            const claim = store.get(id)
            const makeSafeProgress = claim.getLinkedRecord(variables.where.portfolioType.toLowerCase())
              .getLinkedRecord('makeSafeProgress')
            
            const newAppointmentBook = store.getRootField('claimMakeSafeAppointment')
              .getLinkedRecord('result')
              .getLinkedRecord('makeSafeProgress')
              .getValue('appointmentBooked')

            makeSafeProgress.setValue(newAppointmentBook, 'appointmentBooked')
          }
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}