import React, { Component } from 'react'
import { parseFile } from 'common/utils'
import { blacklistedExtensions } from 'common/static_data'
// import { commonCharactersRegexp } from 'common/inputRegex'

import { Formik, Form } from 'formik'
import Input from 'components/Formik/Input'
import MoneyInput from 'components/Formik/MoneyInput'
import Switch from 'components/Formik/Switch'
import { withRouter } from 'react-router-dom'

import Dialog from 'components/Mui/Dialog'
import Button from 'components/Mui/Button'
import Grid from 'components/Mui/Grid'
import CreateDocumentsActionMutation from './CreateDocumentsActionMutation'

class CreateDocumentsAction extends Component {
  snackbar = window[Symbol.for('__snackbar')]
  inputRef = React.createRef()
  render() {
    const { dlRef, claimId, meta, ...rest } = this.props
    const vehicleActions = window[Symbol.for('__vehicleActions')]
    const portfolios = []
    if(meta.claim.hasBuilding) portfolios.push({label: vehicleActions.isVehicleReport?'Car':'Building', value: 'Building'})
    if(meta.claim.hasRestoration) portfolios.push({label: 'Restoration', value: 'Restoration'})
    if(meta.claim.hasContents) portfolios.push({label: 'Contents', value: 'Contents'})

    const isFeeModule = meta.claim.feeTab.isDisplay

    return (
      <Dialog
        noForm
        noActions
        innerRef={dlRef}
        title='Upload Document'
        paperProps={{
          style: { maxWidth: '65%' }
        }}
        content={
          <Formik
            initialValues={{
              file: '', portfolioType: portfolios[0].value,
              isInvoice: false, private: false,
              amountInvoice: '', invoiceNumber: '',
              description: '',
            }}
            validate={(values) => {
              let errors = {}
              if(!values.portfolioType && meta.user.type.isAdmin) errors.portfolioType = 'Select at least 1!'
              if(!values.description) errors.description = 'Required!'
              if(!values.file) errors.file = 'Required!'
              if (!isFeeModule && values.isInvoice) {
                if(!values.amountInvoice) errors.amountInvoice = 'Required!'
                if(!values.invoiceNumber) errors.invoiceNumber = 'Required!'
                if(values.invoiceNumber.length > 200) errors.invoiceNumber = 'Maximum character limit is 200'
                if(String(values.amountInvoice).length > (9 + 3)) errors.amountInvoice = 'Maximum character limit is 9'
              }

              // if(values.description && commonCharactersRegexp.test(values.description)) errors.description = 'Invalid character'

              // if(values.invoiceNumber && commonCharactersRegexp.test(values.invoiceNumber)) errors.invoiceNumber = 'Invalid character'
              if(values.description.length > 200) errors.description = 'Maximum character limit is 200'
              const { name, size } = this.inputRef.current.files[0]
              if(blacklistedExtensions.find(e => e.toUpperCase() === `.${name.split('.')[name.split('.').length - 1]}`.toUpperCase())) {
                errors.file = 'File extension is not supported'
              }

              if(size > 31457280) errors.file = 'Maximum file size is 30MB'
              if(size < 1) errors.file = 'Minimum file size is 1 byte'

              if(name.length > 60) errors.file = 'Maximum file name length is 60'
              if(name.length < 5) errors.file = 'Minimum file name length is 5'

              return errors
            }}
            onSubmit={(values, actions) => {
              setTimeout(async () => {
                const submitValues = {...values}
                const { ok, fileBase64, fileName } = await parseFile(this.inputRef.current)
                if(ok) {
                  submitValues.amountInvoice = parseFloat(submitValues.amountInvoice)
                  delete submitValues.file
                  if(!values.isInvoice) {
                    delete submitValues.invoiceNumber
                    delete submitValues.amountInvoice
                  }

                  if (!meta.user.type.isAdmin) {
                    delete submitValues.portfolioType
                  }
                  const res = await CreateDocumentsActionMutation({
                    ...submitValues,
                    fileBase64,
                    documentFileName: fileName,
                    claimId,
                  }, {
                    path: this.props.location.pathname,
                    search: this.props.location.search
                  })
                  if(res.ok) {
                    if(window[Symbol.for('__newDocumentAction')]){
                      window[Symbol.for('__newDocumentAction')].handleClose()
                    }
                    actions.resetForm({
                      file: '', portfolioType: portfolios[0].value,
                      isInvoice: false, private: false,
                      amountInvoice: '', invoiceNumber: '',
                      description: '',
                    })
                    this.snackbar.toggleOpen({
                      message: res.message[0]
                    })
                  } else {
                    this.snackbar.toggleOpen({
                      message: 'Action has not succeed'
                    })
                  }
                  actions.setSubmitting(false)
                }
              }, 400)
            }}
          >
            {({ isSubmitting, values, touched, setFieldValue }) => {
              return (
                <Form>
                  <Grid fluid spacing={8}>
                    {
                      [
                        {
                          col: 6, label: 'Claim Type',
                          component: Input, name: 'portfolioType',
                          select: true, options: portfolios,
                          required: true,
                          unmountOn: !meta.user.type.isAdmin
                        },
                        {
                          col: meta.user.type.isAdmin ? 6 : 12, label: 'Choose a file',
                          component: Input, name: 'file',
                          inputRef: this.inputRef,
                          leadIcon: 'attach_file',
                          type: 'file', required: true,
                        },
                        {
                          col: 2, label: 'Is this an invoice?',
                          component: Switch, name: 'isInvoice',
                          switchLabel: values.isInvoice ? 'Yes' : 'No',
                          onChange: () => {
                            if(!values.isInvoice) {
                              setFieldValue('private', true)
                            }
                          },
                          unmountOn: isFeeModule,
                        },
                        {
                          col: 5, label: 'Amount Invoice', required: values.isInvoice,
                          component: MoneyInput, name: 'amountInvoice',
                          disabled: !values.isInvoice, unmountOn: isFeeModule
                        },
                        {
                          col: 5, label: 'Invoice Number', required: values.isInvoice,
                          component: Input, name: 'invoiceNumber',
                          type: 'text',
                          disabled: !values.isInvoice, unmountOn: isFeeModule,
                        },
                        {
                          col: 12, label: 'File Description',
                          component: Input, name: 'description',
                          type: 'text', multiline: true,
                          rowsMax: 5,
                          required: true,
                        },
                        {
                          col: 2, label: 'Privacy',
                          component: Switch, name: 'private',
                          labelOn: 'Private',
                          labelOff: 'Public'
                        },
                        {
                          col: 7,
                        },
                        {
                          col: 3, label: 'Submit',
                          component: Button, type: 'submit',
                          fullWidth: true, color: 'primary', variant: 'contained',
                          disabled: isSubmitting,
                        }
                      ].map(({col, component: Component, unmountOn, ...rest}, key) => !unmountOn && (
                        <Grid item xs={col} key={key}>
                          {Component && <Component {...rest}/>}
                        </Grid>
                      ))
                    }

                  </Grid>
                </Form>
              )
            }}
          </Formik>
        }
        {...rest}
      />
    )
  }
}

export default withRouter(CreateDocumentsAction)