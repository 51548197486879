import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation ResetClaimActionMutation($where: ENDataPortfolioKey!) {
    claimResetFinalise(where: $where) {
      success
      messages
      result {
        claimStatus {
          statusId
          statusName
        }
        finaliseReason {
          name
        }
        dateCompleted
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        updater: store => {
          let payload = store.getRootField('claimResetFinalise')
          let result = payload.getLinkedRecord('result')
          let newStatus = result.getLinkedRecord('claimStatus')
          let newDateCompleted = result.getValue('dateCompleted')
          // let newFinaliseReason = result.getLinkedRecord('finaliseReason')

          let storeRoot = store.getRoot()

          let claimJob = storeRoot.getLinkedRecord('claimJob(where:{"id":'+variables.where.id+'})')
          let building = claimJob.getLinkedRecord(variables.where.portfolioType.toLowerCase())

          building.setLinkedRecord(newStatus, 'claimStatus')
          building.setValue(newDateCompleted, 'dateCompleted')
          // building.setLinkedRecord(newFinaliseReason, 'finaliseReason')
        },
        onCompleted: res => {
          // console.log(res)

          resolve({ok: true, message: res.claimResetFinalise.messages[0]})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}