import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
// import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation UpdateScopeItemMutation(
    $where: ENDataPortfolioKey!
    $input: QuoteItemUpdate!
  ) {
    updateJobQuoteItem(where: $where, input: $input) {
      success
      messages
    }
  }
`

/*
  result {
    id
    accepted
    jobQuoteItemId
    location { locationName costLocationId }
    dimensions
    costCentre { costType costCentreName costCentreId }
    costItem { itemDescription }
    lineDescription
    unit { unitName }
    directsupply
    purchasePrice
    ageOfItem
    proofOfLoss
    qty
    rate
    marginRate
    subtotal
  }
*/

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
//       updater: store => {
//         const payload = store.getRootField('updateJobQuoteItem')
//         if(payload.getValue('success')) {
//           const root = store.getRoot()
//           const conn = ConnectionHandler.getConnection(root, 'ScopeQuery_jobQuotes')
//           console.log(conn)
// //           const node = conn.getLinkedRecords('edges')[0].getLinkedRecord('node')
// //
// //           const items = node.getLinkedRecords('lineItems')
// //
// //           const newItem = payload.getLinkedRecords('result')
//           // const newItems = [ ...items, ...newItem ]
//           // node.setLinkedRecords(newItems, 'lineItems')
//         }
//       },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.updateJobQuoteItem.success,
          messages: res.updateJobQuoteItem.messages
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}
