import React, { Component } from 'react'
import Grid from 'components/Mui/Grid'
import { withStyles } from '@material-ui/core/styles'

class MakeSafeListHeader extends Component {
  render() {
    const { classes, isAction, makeSafeScopeTypes } = this.props
    const fields = makeSafeScopeTypes.fields
    return (
      <Grid fluid className={classes.root}>
        {[
          { col: 2, text: fields.costCentre.label, unMountOn: !fields.costCentre.isDisplay},
          { col: true, text: fields.description.label, unMountOn: !fields.description.isDisplay },
          { col: 1, text: fields.unit.label, unMountOn: !fields.unit.isDisplay },
          { col: 1, text: fields.qty.label, unMountOn: !fields.qty.isDisplay },
          { col: 1, text: fields.rate.label, unMountOn: !fields.rate.isDisplay },
          { col: 1, text: fields.marginRate.label, unMountOn: !fields.marginRate.isDisplay },
          { col: 1, text: fields.subtotal.label, unMountOn: !fields.subtotal.isDisplay },
          { col: 1, text: fields.action.label, unMountOn: !fields.action.isDisplay },
        ].map(({ col, text, unMountOn }, key) => {
          if(unMountOn) return null
          return (
            <Grid item key={key} xs={col} className={classes.headerItems}>
              {text}
            </Grid>  
          )          
        })}
      </Grid>
    )
  }  
}

export default withStyles(theme => ({
  root: {
    '& > div': {
      borderRight: '1px solid white',
    },
    '& > div:last-child': {
      borderRight: 0,
    }
  },
  headerItems: theme.mixins.alignJustifyContainer(theme)
}))(MakeSafeListHeader)