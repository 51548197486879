import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
import { ConnectionHandler } from 'relay-runtime'
import queryString from 'query-string'

const mutation = graphql`
  mutation CreateDocumentsActionMutation($input: ClaimDocumentCreate!) {
    createClaimDocument(input:$input){
      success
      messages
      result {
        id
        documentId
        portfolioType
        uploadDate
        company {
          companyName
        }
        url
        private
        description
        amountInvoice
      }
    }
  }
`

export default (input, info) => {
  const { path, search } = info
  const qs = queryString.parse(search)
  const portfolio = (qs.portfolioFilter && qs.portfolioFilter.length > 0) ?
    qs.portfolioFilter.charAt(0).toUpperCase()
      + qs.portfolioFilter.slice(1) : undefined

  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables: { input },
        updater: store => {
          if(path === '/claim/documents') {
            const payload = store.getRootField('createClaimDocument')
            const newDocument = payload.getLinkedRecord('result')

            if(payload.getValue('success')) {
              // const newDocumentId = newDocument.getValue('id')
              const newDocumentPortfolio = newDocument.getValue('portfolioType')
              if (!portfolio || (portfolio === newDocumentPortfolio)) {
                const root = store.getRoot()
                const conn = ConnectionHandler.getConnection(root, 'DocumentsList_claimDocuments')
                // console.log(conn)
                const newEdge = ConnectionHandler.createEdge(store, conn, newDocument, 'IClaimDocumentEdge')
                ConnectionHandler.insertEdgeBefore(conn, newEdge)
              }
            }
          }
        },
        onCompleted: res => {
          resolve({
            ok: true,
            success: res.createClaimDocument.success,
            message: res.createClaimDocument.messages
          })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}