import React from "react";
import { fetchQuery, graphql } from "relay-runtime";
import environment from "common/relay";
import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";

const query = graphql`
  query useLineItemOptionsQuery(
    $location: QuoteCostLocationIndex!
    $centre: QuoteCostCentreIndex!
    $item: QuoteCostItemIndex!
    $isItem: Boolean!
    $materialItem: QuoteCostItemIndex!
    $isMaterialItem: Boolean!
    $jobScopeWhere: JobScopeTypeWhere!
    $isJobScope: Boolean!
    $properties: PropertyTypeWhere!
    $isProperties: Boolean!
  ) {
    locations: quoteCostLocations(where: $location) {
      edges {
        node {
          label: locationName
          value: costLocationId
        }
      }
    }
    centres: quoteCostCentres(where: $centre) {
      edges {
        node {
          group: costType
          label: costCentreName
          value: costCentreId

          directSupplier
        }
      }
    }
    properties: propertyTypes(where: $properties) @include(if: $isProperties) {
      label: name
      value: id
    }
    items: quoteCostItems(where: $item) @include(if: $isItem) {
      edges {
        node {
          label: itemDescription
          value: costItemId
          fullDescription

          units {
            label: unitName
            value: unitId
          }
        }
      }
    }

    materialItems: quoteCostItems(where: $materialItem)
      @include(if: $isMaterialItem) {
      edges {
        node {
          label: itemDescription
          value: costItemId
          fullDescription

          units {
            label: unitName
            value: unitId
          }
        }
      }
    }
    jobScopeItems: jobScopeTypes(where: $jobScopeWhere)
      @include(if: $isJobScope) {
      label: name
      value: id
    }
  }
`;

export default (
  {
    isMaterialItem = false,
    isJobScope = false,
    isProperties = false,
    jobScopeWhere = {
      insurerId: 1,
      quotationType: "Scope",
      portfolioType: "Building",
      forManager: false,
    },
    materialItem = {
      portfolioType: "Building",
      costCentreId: 0,
      quotationType: "Scope",
    },
    properties = { insurerId: 0, portfolioType: "Building" },
    ...variables
  },
  watches
) => {
  const meta = React.useContext(ClaimMetaContext);
  const [locationOptions, setLocationOptions] = React.useState([]);
  const [centreOptions, setCentreOptions] = React.useState([]);
  const [itemOptions, setItemOptions] = React.useState([]);
  const [materialItemsOptions, setMaterialItemOptions] = React.useState([]);
  const [jobScopeOptions, setJobScopeOptions] = React.useState([]);
  const [propertiesOptions, setPropertiesOptions] = React.useState([]);
  React.useEffect(() => {
    const getData = async () => {
      const queryPromise = fetchQuery(environment, query, {
        ...variables,
        centre: {
          ...variables.centre,
          insurerId: meta.claim.insurer.companyId,
        },
        isMaterialItem,
        materialItem,
        isJobScope,
        isProperties,
        properties,
        jobScopeWhere,
      }).then(
        ({
          locations,
          centres,
          items,
          materialItems,
          jobScopeItems,
          properties,
        }) => {
          if (locations) {
            const locationOptions = locations.edges.map(({ node }) => node);
            setLocationOptions(locationOptions);
          }
          if (centres) {
            const centreOptions = centres.edges.map(({ node }) => node);
            setCentreOptions(centreOptions);
          }
          if (items) {
            const itemOptions = items.edges.map(({ node }) => node);
            setItemOptions(itemOptions);
          }
          if (materialItems) {
            const materialItemsOptions = materialItems.edges.map(
              ({ node }) => node
            );
            setMaterialItemOptions(materialItemsOptions);
          }
          if (jobScopeItems) {
            setJobScopeOptions(jobScopeItems);
          }
          if (properties) {
            setPropertiesOptions(properties);
          }
        }
      );

      return () => queryPromise.resolve();
    };

    getData();
  }, watches);

  return [
    locationOptions,
    centreOptions,
    itemOptions,
    materialItemsOptions,
    jobScopeOptions,
    propertiesOptions,
  ];
};
