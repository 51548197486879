import React from "react";
import { Formik, Form } from "formik";
import { commonCharactersRegexp } from "common/inputRegex";

import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";
import Dialog from "components/Mui/Dialog";

import UpdateItemForm from "components/LineItem/UpdateItemForm";
import UpdateVariationItemForm from "components/LineItem/UpdateVariationItemForm";
import VariationEditItemMutation from "./VariationEditItemMutation";

const VariationEditItem = ({
  id,
  portfolioType,
  initialData,
  dlRef,
  handleRefresh,
}) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const handleClose = () => {
    if (dlRef.current) dlRef.current.handleClose();
  };

  return (
    <Dialog
      noForm
      noActions
      innerRef={dlRef}
      paperProps={{
        style: { maxWidth: "50%" },
      }}
      title="Edit Item"
      content={
        <Formik
          initialValues={{
            ...initialData,
          }}
          validate={validation}
          onSubmit={(input, actions) => {
            setTimeout(async () => {
              const variables = {
                input: {
                  costCentreId: Number(input.costCentreId),
                  costItemId: Number(input.costItemId),
                  locationId: Number(input.locationId),
                  scopeTypeId: input.scopeTypeId,
                  propertyTypeId: input.propertyTypeId,
                  propertyNumber: input.propertyNumber,
                  dimensions: input.dimensions,
                  lineDescription: input.lineDescription,
                  unitId: Number(input.unitId),
                  qty: Number(input.qty),
                  rate: Number(input.rate),
                  marginRate: Number(input.marginRate),
                  gst: Number(input.gst),
                },
                where: { id, portfolioType },
              };

              const res = await VariationEditItemMutation(variables);
              actions.setSubmitting(false);

              if (res) {
                snackbar.toggleOpen({ message: res.message });
                if (res.success) {
                  handleRefresh();
                }
              }
            }, 400);
          }}
        >
          {({ isSubmitting, setSubmitting, setFieldValue, values }) => (
            <Form>
              <Grid fluid>
                <UpdateItemForm
                  portfolioType={portfolioType}
                  quotationType={"Variation"}
                  values={values}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid fluid>
                <div style={{ marginLeft: "auto" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    label="Update"
                    type="submit"
                  />
                  <Button
                    color="primary"
                    label="Close"
                    onClick={() => handleClose()}
                  />
                </div>
              </Grid>
            </Form>
          )}
        </Formik>
      }
    />
  );
};

export default VariationEditItem;

const validation = (values) => {
  let errors = {};
  if (!values.locationId) errors.locationId = "required!";
  if (!values.dimensions) errors.dimensions = "required!";

  if (!values.costCentreId) errors.costCentreId = "required!";

  if (values.costCentreId) {
    if (!values.costItemId) errors.costItemId = "required!";
    if (!values.unitId) errors.unitId = "required!";
    if (!values.qty) errors.qty = "required!";
    if (!values.rate && values.rate !== 0) errors.rate = "required!";
  }

  if (values.dimensions && commonCharactersRegexp.test(values.dimensions))
    errors.dimensions = "Invalid character";
  if (values.dimensions.length > 200)
    errors.dimensions = "Maximum character limit is 200";
  if (String(values.lineDescription).length > 1500)
    errors.lineDescription = "Maximum character limit is 1500";
  if (String(values.qty).length > 4 + 3)
    errors.qty = "Maximum character limit is 4";
  if (
    (String(values.rate).indexOf("-") !== -1 &&
      String(values.rate).length > 7 + 4) ||
    (String(values.rate).indexOf("-") === -1 &&
      String(values.rate).length > 7 + 3)
  )
    errors.rate = "Maximum character limit is 7";

  return errors;
};
