import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { createFragmentContainer, graphql } from "react-relay";
import classNames from "clsx";
import Grid from "components/Mui/Grid";
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon";
import IconButton from "components/Mui/IconButton";
import Collapse from "@material-ui/core/Collapse";

import ClaimItemExpand from "./ClaimItemExpand";
import { moneyFormat } from "common/utils";

class ClaimItem extends Component {
  state = {
    expand: false,
  };
  render() {
    const { expand } = this.state;
    const {
      classes,
      claim,
      index,
      accessLevel,
      admin,
      builder,
      restorer,
      contents,
    } = this.props;
    return (
      <div className={classes.row}>
        <Grid
          className={classes.innerOfRow}
          fluid
          spacing={0}
          onClick={(e) =>
            this.props.history.push(`/claim/job-info?id=${claim.claimId}`)
          }
        >
          {[
            {
              text: (
                <div className={classes.centerCell}>
                  <IconButton
                    icon={expand ? "expand_less" : "expand_more"}
                    className={classes.expandBtn}
                    onClick={this.expandMore}
                  />
                  {index}
                  {claim.hasBuilding && (
                    <ClaimTypeIcon
                      className={classes.typeIcon}
                      type="Building"
                    />
                  )}
                  {claim.hasContents && (
                    <ClaimTypeIcon
                      className={classes.typeIcon}
                      type="Contents"
                    />
                  )}
                  {claim.hasRestoration && (
                    <ClaimTypeIcon
                      className={classes.typeIcon}
                      type="Restoration"
                    />
                  )}
                </div>
              ),
              className: classNames(classes.claimType, classes.col),
            },
            {
              text: claim.refNumber,
              className: classNames(classes.insRef, classes.col),
            },
            {
              text: claim.insurer.companyName,
              className: classNames(classes.insComp, classes.col),
            },

            {
              text: admin && admin.lodgeDate,
              className: classNames(classes.fnol, classes.col),
              unMount: !admin,
            },

            {
              text: this.getRequestDate(this.props),
              className: classNames(classes.requestDate, classes.col),
              unMount: admin,
            },
            {
              text: this.getRequest(this.props),
              className: classNames(classes.request, classes.col),
              unMount: admin,
            },

            {
              text: claim.insured.name,
              className: classNames(classes.client, classes.col),
            },
            {
              text: claim.incidentDetail.riskAddress.suburb,
              className: classNames(classes.suburb, classes.col),
            },
            {
              text: claim.incidentDetail.riskAddress.state,
              className: classNames(classes.state, classes.col),
            },
            {
              text: this.getValue(this.props)
                ? moneyFormat(this.getValue(this.props))
                : "-",
              className: classNames(classes.value, classes.col),
            },
            {
              text:
                (admin &&
                  admin.view.fields.lossAdjusterStatus &&
                  admin.view.fields.lossAdjusterStatus.displayValue) ||
                "-",
              className: classNames(classes.builder, classes.col),
              unMount:
                !accessLevel.claimListFields.lossAdjusterStatus.isDisplay,
            },
            {
              text: this.getStatus(this.props),
              className: classNames(classes.status, classes.col),
              unMount: !builder && !restorer && !contents,
            },
            {
              text: this.getBuilder(this.props),
              className: classNames(classes.builder, classes.col),
              unMount: contents,
            },

            {
              text: admin && this.getBuildingStatus(admin.building),
              className: classNames(classes.buildingStatus, classes.col),
              unMount: !admin,
            },
            {
              text:
                (admin && admin.building && admin.building.daysAtStatus) || "-",
              className: classNames(classes.days, classes.col),
              unMount: !admin,
            },
            {
              text: admin && this.getRestorer(admin),
              className: classNames(classes.restorer, classes.col),
              unMount: !admin,
            },
            {
              text: admin && this.getRestorationStatus(admin.restoration),
              className: classNames(classes.restorationStatus, classes.col),
              unMount: !admin,
            },
            {
              text: admin && this.getContentStatus(admin.contents),
              className: classNames(classes.contentsStatus, classes.col),
              unMount: !admin,
            },
            // {Building Status}, {Days}, {Restorer}, {Restoration Status}, {Contents Status}
          ].map(
            (row, index) =>
              !row.unMount && (
                <Grid item className={row.className} key={index}>
                  {row.text}
                </Grid>
              )
          )}
        </Grid>
        <Collapse
          in={expand}
          timeout="auto"
          unmountOnExit
          className={classes.collapseWrapper}
        >
          <ClaimItemExpand data={this.props} />
        </Collapse>
      </div>
    );
  }

  expandMore = (e) => {
    e.stopPropagation();
    this.setState({ expand: !this.state.expand });
  };

  getRequestDate = ({ builder, restorer, contents }) => {
    if (builder) return builder.building.jobSuppliers[0].requestDate;
    if (restorer) return restorer.restoration.jobSuppliers[0].requestDate;
    if (contents) return contents.contents.jobSuppliers[0].requestDate;
  };

  getRequest = ({ builder, restorer, contents }) => {
    if (builder) return builder.building.jobSuppliers[0].requestType;
    if (restorer) return restorer.restoration.jobSuppliers[0].requestType;
    if (contents) return contents.contents.jobSuppliers[0].requestType;
  };

  getValue = ({ admin, builder, restorer, contents }) => {
    if (admin && admin.building)
      return (
        admin.building.authorisedValue || admin.building.scopedValue || "0"
      );

    if (
      builder &&
      builder.building &&
      builder.building.jobSuppliers[0] &&
      builder.building.jobSuppliers[0].quote
    )
      return builder.building.jobSuppliers[0].quote.total || "0";

    if (
      restorer &&
      restorer.restoration &&
      restorer.restoration.jobSuppliers[0] &&
      restorer.restoration.jobSuppliers[0].quote
    )
      return restorer.restoration.jobSuppliers[0].quote.total || "0";

    if (
      contents &&
      contents.contents &&
      contents.contents.jobSuppliers[0] &&
      contents.contents.jobSuppliers[0].quote
    )
      return contents.contents.jobSuppliers[0].quote.total || "0";

    return null;
  };
  getStatus = ({ builder, restorer, contents }) => {
    if (
      builder &&
      builder.building &&
      builder.building.jobSuppliers[0] &&
      builder.building.jobSuppliers[0].quote &&
      builder.building.jobSuppliers[0].quote.quoteStatus
    )
      return builder.building.jobSuppliers[0].quote.quoteStatus.statusName;
    if (
      restorer &&
      restorer.restoration &&
      restorer.restoration.jobSuppliers[0] &&
      restorer.restoration.jobSuppliers[0].quote &&
      restorer.restoration.jobSuppliers[0].quote.quoteStatus
    )
      return restorer.restoration.jobSuppliers[0].quote.quoteStatus.statusName;
    if (
      contents &&
      contents.contents &&
      contents.contents.jobSuppliers[0] &&
      contents.contents.jobSuppliers[0].quote &&
      contents.contents.jobSuppliers[0].quote.quoteStatus
    )
      return contents.contents.jobSuppliers[0].quote.quoteStatus.statusName;

    if (
      builder &&
      builder.building &&
      builder.building.claimStatus &&
      builder.building.claimStatus.statusName
    )
      return builder.building.claimStatus.statusName;
    if (
      restorer &&
      restorer.restoration &&
      restorer.restoration.claimStatus &&
      restorer.restoration.claimStatus.statusName
    )
      return restorer.restoration.claimStatus.statusName;
    if (
      contents &&
      contents.contents &&
      contents.contents.claimStatus &&
      contents.contents.claimStatus.statusName
    )
      return contents.contents.claimStatus.statusName;

    return "-";
  };
  getBuilder = ({ admin, builder, restorer }) => {
    if (admin && admin.building) {
      if (admin.building.authorisedSupplier)
        return admin.building.authorisedSupplier.companyName;
      if (admin.building.scopingSupplier)
        return admin.building.scopingSupplier.companyName;
    }
    if (builder && builder.restoration) {
      if (builder.restoration.authorisedSupplier)
        return builder.restoration.authorisedSupplier.companyName;
      if (builder.restoration.scopingSupplier)
        return builder.restoration.scopingSupplier.companyName;
    }
    if (restorer && restorer.building) {
      if (restorer.building.authorisedSupplier)
        return restorer.building.authorisedSupplier.companyName;
      if (restorer.building.scopingSupplier)
        return restorer.building.scopingSupplier.companyName;
    }

    return "-";
  };

  getRestorer = (admin) => {
    if (admin.restoration) {
      const { authorisedSupplier, scopingSupplier } = admin.restoration;
      if (authorisedSupplier) return authorisedSupplier.companyName;
      if (scopingSupplier) return scopingSupplier.companyName;
    }

    return "-";
  };

  getBuildingStatus = (building) => {
    if (building) return building.claimStatus.statusName || "-";
    return "-";
  };
  getRestorationStatus = (restoration) => {
    if (restoration) return restoration.claimStatus.statusName || "-";
    return "-";
  };
  getContentStatus = (contents) => {
    if (contents) return contents.claimStatus.statusName || "-";
    return "-";
  };
}

export default createFragmentContainer(
  withRouter(ClaimItem),
  graphql`
    fragment ClaimItem_claim on ClaimJob {
      claimId
      hasBuilding
      hasContents
      hasRestoration
      refNumber
      insurer {
        companyName
      }
      insured {
        name
        phone1
        phone2
        phone3
        email
      }
      incidentDetail {
        incidentDate
        riskAddress {
          line1
          suburb
          state
          postcode
        }
      }
    }
    fragment ClaimItem_admin on ClaimJob {
      view {
        fields {
          lossAdjusterStatus {
            id
            displayValue
            isDisplay
          }
        }
      }
      lodgeDate
      building {
        authorisedSupplier {
          companyName
          companyPhone1
        }
        scopingSupplier {
          companyName
          companyPhone1
        }
        authorisedValue
        scopedValue
        claimStatus {
          statusName
        }
        daysAtStatus
      }
      restoration {
        authorisedSupplier {
          companyName
          companyPhone1
        }
        scopingSupplier {
          companyName
          companyPhone1
        }
        claimStatus {
          statusName
        }
      }
      contents {
        claimStatus {
          statusName
        }
      }
    }
    fragment ClaimItem_builder on ClaimJob {
      building {
        jobSuppliers {
          requestDate
          requestType
          quote {
            total
            jobQuoteId
            quoteStatus: quoteJobStatus {
              statusName
            }
          }
        }
        claimStatus {
          statusName
        }
      }
      restoration {
        authorisedSupplier {
          companyName
          companyPhone1
        }
        scopingSupplier {
          companyName
          companyPhone1
        }
      }
    }
    fragment ClaimItem_restorer on ClaimJob {
      restoration {
        jobSuppliers {
          requestDate
          requestType
          quote {
            total
            jobQuoteId
            quoteStatus: quoteJobStatus {
              statusName
            }
          }
        }
        claimStatus {
          statusName
        }
      }
      building {
        authorisedSupplier {
          companyName
          companyPhone1
        }
        scopingSupplier {
          companyName
          companyPhone1
        }
      }
    }
    fragment ClaimItem_contents on ClaimJob {
      contents {
        jobSuppliers {
          requestDate
          requestType
          quote {
            total
            jobQuoteId
            quoteStatus: quoteJobStatus {
              statusName
            }
          }
        }
        claimStatus {
          statusName
        }
      }
    }
  `
);
