import React from "react";
import { Field } from "formik";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";

export default (props) => {
  return <Field component={MuiFloatInput} {...props} />;
};

export const MuiFloatInput = ({
  field,
  form,
  disabled,
  leadIcon,
  readOnly,
  fixedDecimal,
  decimalScale,
  format,
  ...props
}) => {
  const isError = Boolean(
    form.dirty && form.touched[field.name] && form.errors[field.name]
  );

  return (
    <TextField
      variant="outlined"
      fullWidth
      disabled={disabled || form.isSubmitting}
      // Formik handling
      {...props}
      {...field}
      onChange={(value) => {
        if (Boolean(value)) {
          form.setFieldValue([field.name], value);
        } else {
          form.setFieldValue([field.name], null);
        }
      }}
      onBlur={(e) => {
        field.onBlur && field.onBlur(e);
      }}
      value={field.value}
      // Formik error handling
      error={isError}
      helperText={isError && form.errors[field.name]}
      // Formatted input handling
      InputProps={{
        readOnly,
        inputComponent: FloatFormatInput,
        inputProps: {
          autoComplete: "teriTeriMasuMasuKawaii",
          fixedDecimal,
          decimalScale,
          format,
        },
      }}
    />
  );
};

export const FloatFormatInput = ({
  inputRef,
  onChange,
  fixedDecimal = true,
  decimalScale = 2,
  format = undefined,
  ...props
}) => {
  return (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.value);
      }}
      format={format}
      allowNegative={false}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimal}
    />
  );
};
