import React, { Component } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import AcknowledgeCommunicationMutation from '../../ClaimDetailsAction/AcknowledgeCommunicationMutation'
import { withStyles } from '@material-ui/core/styles'
import ClaimTypeIcon from 'components/Mui/ClaimTypeIcon/'
import Grid from 'components/Mui/Grid'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'

class CommunicationsListItem extends Component {
  snackbar = window[Symbol.for('__snackbar')]
  render() {
    const { classes, item } = this.props
    return (
      <Grid fluid spacing={0} className={classes.listItem}>
        <Grid item xs={2} className={classes.alignCenter}>
          <ClaimTypeIcon type={item.portfolioType}/>
          <span>{item.sendDate}</span>
        </Grid>
        <Grid className={classes.alignCenter} item xs={1}>{item.senderCompanyName}</Grid>
        <Grid className={classes.alignCenter} item xs={1}>{item.senderName}</Grid>
        <Grid className={classes.alignCenter} item xs={1}>{item.recieverCompanyName}</Grid>
        <Grid className={classes.alignCenter} item xs={1}>{item.private ? 'Private' : 'Public'}</Grid>
        <Grid className={classes.alignCenter} item xs={1}>
          {
            (item.acknowledged) ? (
              <Tooltip title={(item.acknowledgeUserName && item.acknowledged) ? `${item.acknowledgeUserName} - ${item.acknowledgeDate}` : ''}>
                <Icon>check_circle</Icon>
              </Tooltip>
            )
            : (item.actions.acknowledge.isDisplay) ? (
              <Icon onClick={e => this.acknowledge(e, item)}>radio_button_unchecked</Icon>
            ) : ''
          }
        </Grid>
        <Grid className={classes.alignLeft} item xs={5}>{item.message}</Grid>
      </Grid>
    )
  }
  acknowledge = async (e, item) => {
    e.stopPropagation()
    const res = await AcknowledgeCommunicationMutation({
      where: {
        id: item.communicationId,
        portfolioType: item.portfolioType
      }
    })
    if(res.success) {
      const ref = window[Symbol.for('__refreshCommunication')]
      if(ref) ref.refresh()
    }
    else this.snackbar.current.toggleOpen({message: res.messages[0]})
  }
}

export default createFragmentContainer(
  withStyles(theme => ({
    listItem: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      '&:nth-child(even)': {
        background: theme.palette.grey[100]
      },
      '&:hover': {
        background: theme.palette.grey[300]
      },
      position: 'relative',
    },
    alignCenter: {
      ...theme.mixins.alignJustifyContainer(theme),
      textAlign: 'center'
    },
    alignLeft: {
      display: 'flex',
      alignContents: 'flex-start'
    }
  }))(CommunicationsListItem),
  graphql`
    fragment CommunicationsListItem_item on ClaimCommunication {
      acknowledged
      claimId
      communicationId
      message
      acknowledgeUserName
      acknowledgeDate
      portfolioType
      recieverCompanyId
      recieverCompanyName
      sendDate
      senderCompanyId
      senderCompanyName
      senderId
      senderName
      private
      actions {
        acknowledge {
          isDisplay
        }
      }
    }
  `
)
