import React, { Component } from 'react'
import { graphql, createRefetchContainer } from 'react-relay'
import moment from 'moment'
import Input from 'components/Formik/Input'
import { SingleReactSelect } from 'components/Formik/ReactSelect'
import MoneyInput from 'components/Formik/MoneyInput'
import DateInput from 'components/Formik/DateInput'
import Switch from 'components/Formik/Switch'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import InfoBox from 'components/Mui/InfoBox'

class AddClaimDetailsCard extends Component {
  state = {}

  componentDidUpdate = (prevProps) => {
    const { data, values } = this.props

    if (prevProps.values.externalLossAdjusterId !== values.externalLossAdjusterId) {
      if (values.externalLossAdjusterId !== "0") {
        this.props.setFieldValue("toProvideSiteReport", false)
      } else {
        this.props.setFieldValue("toProvideSiteReport", true)
      }
    }

    if(prevProps.data.postcode !== data.postcode) {
      this.props.relay.refetch(
        {
          adjustersWhere: {subject: 'adjusters', insurers: data.insurerId ? data.insurerId : 0, postcode: String(data.postcode)},
          managersWhere: {subject: 'managers', insurers: data.insurerId ? data.insurerId : 0},
          buildersWhere: {subject: 'suppliers', insurers: data.insurerId ? data.insurerId : 0, portfolios: 'Building', postcode: String(data.postcode)},
          restorersWhere: {subject: 'suppliers', insurers: data.insurerId ? data.insurerId : 0, portfolios: 'Restoration', postcode: String(data.postcode)},
          eventTypeWhere: {subject: 'eventTypes', insurers: data.insurerId ? data.insurerId : 0},
          catCodesWhere: {subject: 'catCodes', insurers: data.insurerId ? data.insurerId : 0},
          internalAssessorsWhere: {subject: 'internalAssessors', insurers: data.insurerId ? data.insurerId : 0},
          assessorTypeVisitsWhere: {subject: 'assessorTypeVisits', insurers: data.insurerId ? data.insurerId : 0},
          policyTypesWhere: {subject: 'policyTypes', insurers: data.insurerId ? data.insurerId : 0},
          policyCoversWhere: {subject: 'policyCovers', insurers: data.insurerId ? data.insurerId : 0},
          agentWhere: {subject: 'agents', insurers: data.insurerId ? data.insurerId : 0},
        },  // Our refetchQuery needs to know the `itemID`
        null,  // We can use the refetchVariables as renderVariables
        () => {
          this.props.setFieldValue('externalLossAdjusterId', '')
          this.props.setFieldValue('buildingScopingSupplierId', '')
          this.props.setFieldValue('restorationScopingSupplierId', '')
        },
        {force: true},  // Assuming we've configured a network layer cache, we want to ensure we fetch the latest data.
      );
    }
  }
  render() {
    const { handleComplete, data, company, validated, details, values, userType, ...rest } = this.props
    return (
      <Paper
        title='Claim Details' /* label:'Claim details'|'Claim Details' */
        {...rest}
        content={
          <Grid fluid spacing={0}>
          {/* body */}
            <Grid item xs={12}>
              <Grid fluid>
                {
                  [
                    {
                      col: 3, label: 'Insurance Company',
                      hasAccess: true,
                      leadIcon: 'business',
                      component: InfoBox, text: company.companyName
                    },
                    {
                      col: 3, component: DateInput, label: 'Incident Date',
                      hasAccess:true, required: true,
                      name: 'incidentDetail_incidentDate',
                      maxDate: moment(),
                    },
                    {
                      col: 3, label:'Case Manager',
                      hasAccess: true, required: true,
                      leadIcon: 'event',
                      component: SingleReactSelect, type: 'text', name: 'casemanagerId',
                      select: true, options: details.managers
                    },
                    {
                      col: 3, label: company.claimJobFields.externalLossAdjustingFirm.label,
                      hasAccess: true,
                      leadIcon: 'event',
                      component: Input, type: 'text', name: 'externalLossAdjusterId',
                      select: true, options: details.adjusters
                    },
                    {
                      col: 3, label: 'Insurance Ref#',
                      hasAccess: true, required: true,
                      leadIcon: 'link',
                      component: Input, type: 'text', name: 'refNumber',
                    },
                    {
                      col: 3, label: 'Contents Ref#' /* label:'Content Ref#'|'Contents Ref#' */,
                      hasAccess: company.contentsref && !this.disableContent(),
                      leadIcon: 'link',
                      component: Input, type: 'text', name: 'contentsRefNum',
                    },
                    {
                      col: 3, label:'Builder',
                      hasAccess: true, required: true, disabled: this.disableBuilding() || !data.postcode,
                      leadIcon: 'event',
                      component: Input, type: 'text', name: 'buildingScopingSupplierId',
                      select: true, options: details.builders
                    },
                    {
                      col: 3, label:'Restorer',
                      hasAccess: true, required: true, disabled: this.disableRestoration() || !data.postcode,
                      leadIcon: 'event',
                      component: Input, type: 'text', name: 'restorationScopingSupplierId',
                      select: true, options: details.restorers
                    },
                    {
                      col: 3, label: 'FNOL',
                      hasAccess: true,
                      leadIcon: 'event',
                      component: InfoBox,
                      text: moment(new Date()).format('DD/MM/YYYY')
                    },
                    {
                      col: 3, label: 'Scope & Repair (Quick Repair)' /* label:'Quick Repair'|'Scope & Repair (Quick Repair)' */,
                      hasAccess: company.quickrepair,
                      component: Switch, name: 'incidentDetail_hold',
                    },
                    {
                      col: 2, label: 'Builder Collect Excess' /* label:'Builder collect excess'|'Builder Collect Excess' */,
                      hasAccess: true, disabled: this.disableBuilding(),
                      component: Switch, name: 'Portfolios_Building_ToCollect',
                    },
                    {
                      col: 2, label: 'Excess' /* label:'Building Excess'|'Excess' */,
                      hasAccess: true, required: !this.disableBuilding(), disabled: this.disableBuilding(),
                      leadIcon: 'attach_money',
                      name: 'Portfolios_Building_ExcessValue',
                      component: MoneyInput,
                      fixedDecimal: false, decimalScale: 0, format: '#########',
                    },
                    {
                      col: 2, label:'Sum Insured' /* label:'Sum insured'|'Sum Insured' */,
                      hasAccess: true, required: !this.disableBuilding(), disabled: this.disableBuilding(),
                      leadIcon: 'attach_money',
                      name: 'Portfolios_Building_SumInsured',
                      component: MoneyInput,
                      fixedDecimal: false, decimalScale: 0, format: '#########',
                    },
                    {
                      col: 3, label: company.claimJobFields.eventType.label,
                      hasAccess: true, required: true,
                      leadIcon: 'event',
                      component: SingleReactSelect, type: 'text', name: 'incidentDetail_eventTypeId',
                      select: true, options: details.eventTypes
                    },
                    {
                      col: 3, label: company.claimJobFields.catCode.label,
                      hasAccess: true, required: true,
                      leadIcon: 'whatshot',
                      component: SingleReactSelect, type: 'text', name: 'incidentDetail_catCodeId',
                      select: true, options: details.catCodes,
                    },
                    {
                      col: 2, label:'Contents Provider Collect Excess' /* label:'Provider collect excess'|'Contents Provider Collect Excess' */,
                      hasAccess: true, disabled: this.disableContent(),
                      component: Switch, name: 'Portfolios_Contents_ToCollect',
                    },
                    {
                      col: 2, label: 'Excess' /* label:'Contents Excess'|'Excess' */,
                      hasAccess: true, required: !this.disableContent(), disabled: this.disableContent(),
                      leadIcon: 'attach_money',
                      name: 'Portfolios_Contents_ExcessValue',
                      component: MoneyInput,
                      fixedDecimal: false, decimalScale: 0, format: '#########',
                    },
                    {
                      col: 2, label: 'Sum Insured' /* label:'Sum insured'|'Sum Insured' */,
                      hasAccess: true, required: !this.disableContent(), disabled: this.disableContent(),
                      leadIcon: 'attach_money',
                      name: 'Portfolios_Contents_SumInsured',
                      component: MoneyInput,
                      fixedDecimal: false, decimalScale: 0, format: '#########',
                    },
                    {
                      col: 3, label: company.claimJobFields.policyType.label /* label:'Policy Type'|'Distributor' */,
                      hasAccess: company.policyTypeSuppliersView, required: true,
                      leadIcon: 'assignment_turned_in',
                      component: SingleReactSelect, type: 'text', name: 'policyTypeId',
                      select: true, options: details.policyTypes,
                    },
                    {
                      col: 3, label: company.claimJobFields.policyCover.label/* label:'Policy Cover'|'PDS Reference' */,
                      hasAccess: company.policyCoverSuppliersView,
                      required: company.policyCoverSuppliersView,
                      leadIcon: 'assignment_turned_in',
                      component: SingleReactSelect, type: 'text', name: 'policyCoverId',
                      select: true, options: details.policyCovers,
                    },
                    {
                      col: 2, label:'Restorer Collect Excess' /* label:'Restorer collect Excess'|'Restorer Collect Excess' */,
                      hasAccess: true, disabled: this.disableRestoration(),
                      component: Switch, name: 'Portfolios_Restoration_ToCollect',
                    },
                    {
                      col: 2, label:'Excess' /* label:'Restoration excess'|'Excess' */,
                      hasAccess: true, required: !this.disableRestoration(), disabled: this.disableRestoration(),
                      leadIcon: 'attach_money',
                      name: 'Portfolios_Restoration_ExcessValue',
                      component: MoneyInput,
                      fixedDecimal: false, decimalScale: 0, format: '#########',
                    },
                    {
                      col: 2, label:'Sum Insured' /* label:'Sum insured'|'Sum Insured' */,
                      hasAccess: true, required: !this.disableRestoration(), disabled: this.disableRestoration(),
                      leadIcon: 'attach_money',
                      name: 'Portfolios_Restoration_SumInsured',
                      component: MoneyInput,
                      fixedDecimal: false, decimalScale: 0, format: '#########',
                    },
                    {
                      col: 3, label: 'Make Safe Required',
                      hasAccess: true,
                      component: Switch, name: 'incidentDetail_makeSafeRequired',
                    },
                    {
                      col: 3, label: 'Supplier(s) to provide Site Report and Scope of Works',
                      hasAccess: company.isToProvideSiteReportView,
                      component: Switch, name: 'toProvideSiteReport',
                    },
                    {
                      col: 3, label:'Additional Ref#',
                      hasAccess: company.additionalRefNo,
                      leadIcon: 'link',
                      component: Input, type: 'text', name: 'additionalRefNumber',
                    },
                    {
                      col: 3, label: company.claimJobFields.internalAssessor.label /* label:'Internal Assessor'|'Specialist Review' */,
                      hasAccess: company.useInternalAssessor,
                      leadIcon: 'home',
                      component: SingleReactSelect, type: 'text', name: 'homeAssessorId',
                      select: true, options: details.internalAssessors,
                    },
                    // {
                    //   col: 3, label:'Assessor Type Visit',
                    //   hasAccess: true,
                    //   leadIcon: 'perm_contact_calendar',
                    //   component: Input, type: 'text', name: 'visitTypeID',
                    //   select: true, options: details.assessorTypeVisits,
                    // },
                    {
                      col: 3, label:'BC/BRC/Loss Adjuster',
                      hasAccess: company.cm2nd && userType.isAdmin,
                      leadIcon: 'restore_page',
                      component: Input, type: 'text', name: 'brcId',
                      select: true, options: details.managers
                    },
                    {
                      col: 3, label:'Risk Name',
                      hasAccess: company.enableMultipleRisks,
                      leadIcon: 'face',
                      component: Input, type: 'text', name: 'riskname',
                    },
                    {
                      col: 3, label: company.claimJobFields.agent.label,
                      hasAccess: company.claimJobFields.agent.isDisplay,
                      disabled: company.claimJobFields.agent.isDisabled,
                      leadIcon: 'event',
                      component: SingleReactSelect, type: 'text', name: 'agentId',
                      select: true, options: details.agent,
                    },
                  ].map((child, key) => {
                    const { col, component: Component, hasAccess, ...props} = child
                    return (
                      <Grid item xs={col} key={key}>
                        {hasAccess && <Component {...props}/>}
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
            {/* <Grid item xs={6}> */}
            {/*   <Grid fluid> */}
            {/*     { */}
            {/*       [  */}
            {/*       ].map((child, key) => { */}
            {/*         const { col, component: Component, hasAccess, ...props} = child */}
            {/*         return ( */}
            {/*           <Grid item xs={col} key={key}> */}
            {/*             {hasAccess && <Component {...props}/>} */}
            {/*           </Grid> */}
            {/*         ) */}
            {/*       }) */}
            {/*     }   */}
            {/*   </Grid> */}
            {/* </Grid> */}
          </Grid>
        }
      />
    )
  }
  disableBuilding = () => {
    if(this.props.values.portfolioType.indexOf('Building') !== -1) return false
    return true
  }
  disableContent = () => {
    if(this.props.values.portfolioType.indexOf('Contents') !== -1) return false
    return true
  }
  disableRestoration = () => {
    if(this.props.values.portfolioType.indexOf('Restoration') !== -1) return false
    return true
  }
}

export default createRefetchContainer(AddClaimDetailsCard, graphql`
  fragment AddClaimDetailsCard_details on Query {
    insurers: claimFilterOptions(where: {
      subject: "insurers"
    }){
      label: name
      value
    }
    managers: claimFilterOptions(where: $managersWhere){
      label: name
      value
    }
    builders: claimFilterOptions(where: $buildersWhere){
      label: name
      value
    }
    restorers: claimFilterOptions(where: $restorersWhere){
      label: name
      value
    }
    eventTypes: claimFilterOptions(where: $eventTypeWhere){
      label: name
      value
    }
    catCodes: claimFilterOptions(where: $catCodesWhere){
      label: name
      value
    }
    adjusters: claimFilterOptions(where: $adjustersWhere){
      label: name
      value
    }
    eventTypes: claimFilterOptions(where: $eventTypeWhere){
      label: name
      value
    }
    policyTypes: claimFilterOptions(where: $policyTypesWhere){
      label: name
      value
    }
    policyCovers: claimFilterOptions(where: $policyCoversWhere){
      label: name
      value
    }
    assessorTypeVisits: claimFilterOptions(where: $assessorTypeVisitsWhere){
      label: name
      value
    }
    internalAssessors: claimFilterOptions(where: $internalAssessorsWhere){
      label: name
      value
    }
    agent: claimFilterOptions(where: $agentWhere){
      label: name
      value
    }
  }
`,
  graphql`
    query AddClaimDetailsCardRefetchQuery(
      $managersWhere: ClaimJobFilter
      $eventTypeWhere: ClaimJobFilter
      $catCodesWhere: ClaimJobFilter
      $adjustersWhere: ClaimJobFilter
      $policyTypesWhere: ClaimJobFilter
      $policyCoversWhere: ClaimJobFilter
      $assessorTypeVisitsWhere: ClaimJobFilter
      $internalAssessorsWhere: ClaimJobFilter
      $buildersWhere: ClaimJobFilter
      $restorersWhere: ClaimJobFilter
      $agentWhere: ClaimJobFilter
    ) {
      ...AddClaimDetailsCard_details
    }
  `
)
