import React from 'react'
import { parse, stringify } from 'query-string'
import ClaimMetaContext from 'pages/Claim/ClaimDetails/ClaimMetaContext'

import useReactRouter from 'use-react-router'

// const encodeSearch = () => {
//   Object.entries(temp1).reduce((total, current) => {
//     total += `&${current[0]}=${current[1]}`
//     return total
//   }, '')
// }

export default portfolios => {
  const meta = React.useContext(ClaimMetaContext)
  const { history } = useReactRouter()

  const [ portfolio, setPortfolio ] = React.useState(null)
  const { portfolio: routePortfolio } = parse(history.location.search)

  const changePortfolio = portfolio => {
    setPortfolio(portfolio)

    const search = parse(history.location.search)
    search.portfolio = portfolio.toLowerCase()
    
    history.push(`${history.location.pathname}?${stringify(search)}`)
  }

  React.useEffect(() => {
    // Check claim portfolios
    if(!meta.claim.hasBuilding) portfolios = portfolios.filter(x => x !== 'building')
    if(!meta.claim.hasContents) portfolios = portfolios.filter(x => x !== 'contents')
    if(!meta.claim.hasRestoration) portfolios = portfolios.filter(x => x !== 'restoration')

    // check current portfolio
    if(routePortfolio && portfolios.indexOf(routePortfolio) !== -1) {
      setPortfolio(`${routePortfolio[0].toUpperCase()}${routePortfolio.substr(1)}`)
    }
    // default portfolio based on user type
    else if(meta.user.type.isBuilder && portfolios.indexOf('building') !== -1) {
      setPortfolio('Building')
    }
    else if(meta.user.type.isContent && portfolios.indexOf('contents') !== -1) {
      setPortfolio('Contents')
    }
    else if(meta.user.type.isRestorer && portfolios.indexOf('restoration') !== -1) {
      setPortfolio('Restoration')
    }
    // default to the first portfolio in portfolios list
    else {
      setPortfolio(`${portfolios[0][0].toUpperCase()}${portfolios[0].substr(1)}`) 
    }
  }, [portfolio, history.location.search])

  // Default portfolio routing
  React.useEffect(() => {
    if(portfolio) changePortfolio(portfolio)
  }, [portfolio])

  return [ portfolio, changePortfolio ]
}