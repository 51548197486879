import React, { Component, createRef } from "react";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";

import { withStyles } from "@material-ui/core/styles";
import Form from "components/Mui/Form";
import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
import { CubeGrid } from "components/Mui/Loader";
import { intData } from "common/utils";
import { states, filtersData } from "common/static_data";
import transformFilterGroup from "common/utils/transformFilterGroup";

import Filters from "./Filters";

const query = graphql`
  query ClaimListFilterQuery {
    currentUser {
      claimFilters {
        icon
        id
        isDisabled
        label
        name
        options {
          value: id
          group
          label
        }
        type
      }
    }
    me {
      userRole
      privileges {
        menus {
          nodeName
        }
      }
    }
  }
`;
class ClaimListFilter extends Component {
  state = {
    data: { keyWords: "" },
    refreshFilterField: true,
  };

  filterForm = createRef();
  render() {
    const { filterWhere, filterChange, classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid fluid spacing={0}>
          <Grid item xs>
            <Paper
              style={{ position: "relative" }}
              content={
                <div style={{ marginBottom: "-12px" }}>
                  <Form
                    onChange={this.filterChangeHandle}
                    ref={this.filterForm}
                  >
                    <QueryRenderer
                      environment={environment}
                      query={query}
                      render={({ error, props }) => {
                        if (!props) return <CubeGrid />;

                        const data = props.currentUser.claimFilters;
                        const {
                          userRole,
                          privileges: { menus },
                        } = props.me;
                        const isWaterFallFilter = menus
                          .map(({ nodeName }) => nodeName)
                          .includes("Waterfall");
                        return (
                          <Filters
                            filters={data}
                            isDateSearch={userRole === "Insurance"}
                            isWaterFallFilter={isWaterFallFilter}
                            refreshFilterField={this.refreshFilterField}
                            changeKeyWords={this.changeKeyWords}
                            changeDate={this.changeDate}
                            filterWhere={filterWhere}
                            filterChange={filterChange}
                          />
                        );
                      }}
                    />
                  </Form>
                </div>
              }
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  changeKeyWords = (keyWords) => {
    this.props.filterChange({
      keyWords,
    });
  };

  changeDate = (date) => {
    this.props.filterChange({
      incidentDate: date ? date.format("YYYY-MM-DDTHH:mm:ss") : undefined,
    });
  };

  refreshFilterField = () => {
    this.props.filterChange({});
    // this.filterForm.current.dispatchEvent(new Event('change'))
  };

  filterChangeHandle = (data) => {
    // const {
    //   suppliers,
    //   adjusters,
    //   status,
    //   ...rest
    // } = data

    // const inputSupplier = {
    //   buildingSuppliers: suppliers ? suppliers.Builder : undefined,
    //   restorationSuppliers: suppliers ? suppliers.Restorer : undefined,
    //   contentsSuppliers: suppliers ? suppliers.ContentSupplier : undefined
    // }
    // const inputStatus = {
    //   buildingStatus: status ? status.Building : undefined,
    //   restorationStatus: status ? status.Restoration : undefined,
    //   contentsStatus: status ? status.Contents : undefined,
    //   lossAdjusterStatus: status ? status["Loss Adjuster"] : undefined
    // }

    const where = {
      ...transformFilterGroup(data),
      // ...Object.entries(rest).map(e => {
      //     return {[e[0]]: e[1].length !== 0 ? e[1] : undefined}
      //   }).reduce((total, current) => {
      //     return {...total, ...current}
      //   }, {}),
      // adjusters: (adjusters && adjusters.length !== 0) ? adjusters : undefined,
      // ...inputSupplier,
      // ...inputStatus
    };

    this.props.filterChange(where);
  };
}

export default withStyles((theme) => ({
  root: {
    width: "111%",
    transformOrigin: "0rem 1rem",
    transform: "scale(0.9)",
  },
}))(ClaimListFilter);
