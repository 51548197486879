import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation GetScoreCardPdfMutation($where: ClaimScoreBoardFilter!) {
    pdf: pdfProduceForClaimScoreBoard(where: $where) {
      messages
      success
    }
  }
`
// { id: 210655, portfolioType: Building }

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          resolve({ ok: true, pdf: res.pdf.messages[0] })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}