/**
 * @flow
 * @relayHash be62235af8cb1752169e8d08b6d4f443
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CommunicationsList$ref = any;
export type ClaimCommunicationBox = "Inbox" | "InboxAndSharedBox" | "OutBox" | "UnDefined" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type CommunicationFilter = {|
  id?: ?string,
  claimId?: ?string,
  keyWords?: ?string,
  managers?: ?$ReadOnlyArray<string>,
  insurers?: ?$ReadOnlyArray<string>,
  managerTeams?: ?$ReadOnlyArray<?string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  acknowledged?: ?boolean,
  states?: ?$ReadOnlyArray<?StateEnum>,
  boxes?: ?$ReadOnlyArray<?ClaimCommunicationBox>,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type CommunicationsPageQueryVariables = {|
  first: number,
  after?: ?string,
  where?: ?CommunicationFilter,
|};
export type CommunicationsPageQueryResponse = {|
  +$fragmentRefs: CommunicationsList$ref
|};
export type CommunicationsPageQuery = {|
  variables: CommunicationsPageQueryVariables,
  response: CommunicationsPageQueryResponse,
|};
*/


/*
query CommunicationsPageQuery(
  $first: Int!
  $after: String
  $where: CommunicationFilter
) {
  ...CommunicationsList
}

fragment CommunicationsList on Query {
  claimCommuications(first: $first, after: $after, where: $where) {
    edges {
      node {
        ...CommunicationsListItem_item
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment CommunicationsListItem_item on ClaimCommunication {
  acknowledged
  claimId
  communicationId
  message
  acknowledgeUserName
  acknowledgeDate
  portfolioType
  recieverCompanyId
  recieverCompanyName
  sendDate
  senderCompanyId
  senderCompanyName
  senderId
  senderName
  private
  actions {
    acknowledge {
      isDisplay
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "CommunicationFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "CommunicationFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CommunicationsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "CommunicationsList",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CommunicationsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimCommuications",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimCommunicationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimCommunicationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimCommunication",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "acknowledged",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "claimId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "communicationId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "message",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "acknowledgeUserName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "acknowledgeDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "portfolioType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "recieverCompanyId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "recieverCompanyName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "sendDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "senderCompanyId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "senderCompanyName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "senderId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "senderName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "private",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimCommunicationAction",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "acknowledge",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ActionControl",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "isDisplay",
                            "args": null,
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "claimCommuications",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "CommunicationsList_claimCommuications",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CommunicationsPageQuery",
    "id": null,
    "text": "query CommunicationsPageQuery(\n  $first: Int!\n  $after: String\n  $where: CommunicationFilter\n) {\n  ...CommunicationsList\n}\n\nfragment CommunicationsList on Query {\n  claimCommuications(first: $first, after: $after, where: $where) {\n    edges {\n      node {\n        ...CommunicationsListItem_item\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CommunicationsListItem_item on ClaimCommunication {\n  acknowledged\n  claimId\n  communicationId\n  message\n  acknowledgeUserName\n  acknowledgeDate\n  portfolioType\n  recieverCompanyId\n  recieverCompanyName\n  sendDate\n  senderCompanyId\n  senderCompanyName\n  senderId\n  senderName\n  private\n  actions {\n    acknowledge {\n      isDisplay\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb7a276c525eeedf6d9a8fe6ffb2c932';
module.exports = node;
