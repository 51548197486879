const localSignalling = (mode, options) => new Promise(async (resolve) => {
  let offer = null
  let candidates = []

  const kurentoPeer = await createPeer(mode, {
    ...options,
    localVideo: options.localVideoRef ? options.localVideoRef.current : options.localVideo,
    remoteVideo: options.remoteVideoRef ? options.remoteVideoRef.current : options.remoteVideo,
    onicecandidate: candidate => {
      candidates.push(JSON.stringify(candidate))
      // candidates = [...candidates, ]
    },
    oncandidategatheringdone: async () => {
      await new Promise(resolve => setTimeout(() => resolve(), 1000))
      resolve({
        kurentoPeer,
        signallingData: { offer, candidates }
      })
    },
    connectionConstraints: {
      offerToReceiveAudio: true,
      offerToReceiveVideo: true
    },
  })

  offer = await getOffer(kurentoPeer)
})

export default localSignalling

const createPeer = (mode, options) => new Promise(async (resolve, reject) => {
  // const kurentoUtils = await React.lazy(() => import('asd'))
  //   ._ctor()
  //   .then(module => module.default)

  // const createPC =
  //   mode === 'sendRecv' ? kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv :
  //     mode === 'recv' ? kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly :
  //       null

  // if (!createPC) reject('specify the mode')

  // const pc = kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(options, error => {
  //   if (error) reject(error)

  //   resolve(pc)
  // })
})


const getOffer = kurentoPeer => {
  return new Promise((resolve, reject) => {
    // { offerToReceiveVideo: true, offerToReceiveAudio: true },
    kurentoPeer.generateOffer(
      { offerToReceiveVideo: true, offerToReceiveAudio: true },
      (error, offer) => {
        if (error) reject(error)

        resolve(offer)
      }
    )
  })
}