import React, { Component } from 'react'

import classNames from 'clsx'
import { withTheme, withStyles } from '@material-ui/core/styles'
import MuiGrid from '@material-ui/core/Grid'

class Grid extends Component {
  render() {
    const { 
      theme, classes,
      className,
      children, fluid, container, spacing,
      ...rest
    } = this.props

    return (
      <MuiGrid
        container={fluid || container ? true : false}
        className={classNames(
          className,
          { [classes.fluidContainer]: fluid },
          { [classes.root]: container }
        )}
        spacing={(fluid && !spacing && spacing !== 0) ? theme.spacing.unit : spacing}
        {...rest}
      >
        {children}
      </MuiGrid>
    )
  }
}

export default withStyles(theme => ({
  root: {
    marginTop: theme.spacing.unit,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '95%',
    }
  },
  fluidContainer: { width: '100%' }
}))(withTheme()(Grid))