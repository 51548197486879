/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AccessRole = "Adjustor" | "Administrator" | "Agent" | "Builder" | "ContentSupplier" | "Customer" | "Estimator" | "Insurance" | "InternalAB" | "LossAdjuster" | "MultiTenderAssessor" | "Restorer" | "Specialist" | "SystemAdmin" | "UNDEFINED" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type StandaloneCommunicationListItem_item$ref: FragmentReference;
export type StandaloneCommunicationListItem_item = {|
  +claimId: ?string,
  +claimRef: string,
  +communicationId: number,
  +customerName: string,
  +message: ?string,
  +portfolioType: ?PortfolioType,
  +recieverCompanyId: ?number,
  +recieverCompanyName: ?string,
  +sendDate: any,
  +senderCompanyId: ?number,
  +senderCompanyName: ?string,
  +senderId: ?number,
  +senderName: ?string,
  +senderRole: ?AccessRole,
  +$refType: StandaloneCommunicationListItem_item$ref,
|};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "StandaloneCommunicationListItem_item",
  "type": "ClaimCommunication",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "claimId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "claimRef",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "communicationId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "customerName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "portfolioType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "recieverCompanyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "recieverCompanyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sendDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "senderCompanyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "senderCompanyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "senderId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "senderName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "senderRole",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9a63730dbaf92d4d95c9bdc1dd12df4b';
module.exports = node;
