import React from "react";
import { withStyles } from "@material-ui/core";
import { withClaimMeta } from "../../ClaimMetaContext";
import { graphql, QueryRenderer } from "react-relay";
import Tooltip from "@material-ui/core/Tooltip";
import environment from "common/relay";
// import environment from 'common/relay'
import { Cube } from "components/Mui/Loader";
import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
import Button from "components/Mui/Button";
//import Input from 'components/Formik/Input/'
import { SingleReactSelect } from "components/Formik/ReactSelect";
import Survey from "./Survey";

import AppointmentMadeAction from "../../ClaimDetailsAction/AppointmentMadeAction";
import ChangeAppointmentAction from "../../ClaimDetailsAction/ChangeAppointmentAction";
import InitCallMutation from "../../ClaimDetailsAction/InitCallMutation";
import AwaitingInfoAction from "../../ClaimDetailsAction/AwaitingInfoAction";
import ClaimDetailsPortfolioFilter from "../../ClaimDetailsPortfolioFilter";
import HistoriesListItem from "./HistoriesListItem";

const HistoriesList = (props) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const awaitingInfo = React.createRef();
  const appointment = React.createRef();
  const changeAppointment = React.createRef();
  const toggleAwaitingInfo = () => awaitingInfo.current.handleOpen();
  const toggleMakeAppointment = () => appointment.current.handleOpen();
  const toggleChangeAppointment = () => changeAppointment.current.handleOpen();
  const [refresh, setRefresh] = React.useState(false);
  const handleRefresh = () => {
    setRefresh(!refresh);
    if (window[Symbol.for("__refreshClaimDetails")]) {
      window[Symbol.for("__refreshClaimDetails")]();
    }
  };
  React.useEffect(() => {
    window[Symbol.for("__HistoriesRefresh")] = { handleRefresh };

    return () => {
      delete window[Symbol.for("__HistoriesRefresh")];
    };
  }, [refresh]);

  const { classes, data, meta, portfolio, updateTypeId } = props;

  return (
    <>
      <QueryRenderer
        environment={environment}
        query={actionQuery}
        variables={{
          historyWhere: { id: meta.claim.claimId },
          refresh,
          first: 15,
          after: null,
          where: {
            claimId: meta.claim.claimId,
            portfolioType: portfolio || undefined,
            updateTypeId: updateTypeId || undefined,
          },
        }}
        render={({ error, props }) => {
          if (error) throw new Error(error);
          if (!props)
            return (
              <Grid fluid style={{ height: "100px" }}>
                <Grid item xs>
                  <Cube />
                </Grid>
              </Grid>
            );
          const awaitingInfoAction = props.claimJob.view.actions.awaitingInfo;
          const createFollowUpAction =
            props.claimJob.view.actions.createFollowUp;
          const createFeedbackAction =
            props.claimJob.view.actions.createFeedback;
          const initialCallAction = props.claimJob.view.actions.makeInitialCall;
          const makeAppointmentAction =
            props.claimJob.view.actions.makeAppointment;
          const changeAppointmentAction =
            props.claimJob.view.actions.changeAppointment;

          return (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Paper>
                    <Grid fluid justify="space-between">
                      <Grid item xs={2}>
                        <Button
                          fullWidth
                          label="Next Step"
                          variant="outlined"
                          color="primary"
                          onClick={handleNextStep}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SingleReactSelect
                          select={true}
                          label="Update Type"
                          name="updateTypeId"
                          leadIcon="update"
                          options={meta.claimUpdateTypes}
                          isClearable
                        />
                      </Grid>
                      {createFeedbackAction.isDisplay && (
                        <Grid item xs={2}>
                          <Button
                            fullWidth
                            label="Feedback"
                            variant="outlined"
                            color="primary"
                            disabled={createFeedbackAction.isDisabled}
                            onClick={() => {
                              if (window[Symbol.for("__JobSurvey")]) {
                                window[Symbol.for("__JobSurvey")].handleOpen();
                              }
                            }}
                          />
                          <Survey />
                        </Grid>
                      )}
                      {createFollowUpAction.isDisplay && (
                        <Grid item xs={2}>
                          <Button
                            fullWidth
                            label="Follow Up"
                            variant="outlined"
                            color="primary"
                            disabled={createFollowUpAction.isDisabled}
                            onClick={() => {
                              if (window[Symbol.for("__newFollowUpAction")]) {
                                window[
                                  Symbol.for("__newFollowUpAction")
                                ].toggleOpen();
                              }
                            }}
                          />
                        </Grid>
                      )}
                      {initialCallAction && initialCallAction.isDisplay && (
                        <Grid item xs={2}>
                          <Tooltip
                            title={
                              initialCallAction.isDisabled
                                ? "Please accept the offer on the job info tab before making the initial call"
                                : ""
                            }
                          >
                            <span>
                              <Button
                                fullWidth
                                label={initialCallAction.label}
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleInitialCallMade(meta.claim.claimId)
                                }
                                disabled={initialCallAction.isDisabled}
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                      )}
                      {makeAppointmentAction &&
                        makeAppointmentAction.isDisplay && (
                          <Grid item xs={2}>
                            <Button
                              onClick={toggleMakeAppointment}
                              fullWidth
                              label={makeAppointmentAction.label}
                              disabled={makeAppointmentAction.isDisabled}
                              variant="outlined"
                              color="primary"
                            />
                            <AppointmentMadeAction
                              claimId={meta.claim.claimId}
                              dlRef={appointment}
                              handleRefresh={handleRefresh}
                            />
                          </Grid>
                        )}
                      {changeAppointmentAction &&
                        changeAppointmentAction.isDisplay && (
                          <Grid item xs={2}>
                            <Button
                              onClick={toggleChangeAppointment}
                              fullWidth
                              label={changeAppointmentAction.label}
                              disabled={changeAppointmentAction.isDisabled}
                              variant="outlined"
                              color="primary"
                            />
                            <ChangeAppointmentAction
                              claimId={meta.claim.claimId}
                              dlRef={changeAppointment}
                              handleRefresh={handleRefresh}
                            />
                          </Grid>
                        )}
                      {!awaitingInfoAction.isDisabled &&
                        awaitingInfoAction.isDisplay && (
                          <Grid item xs={2}>
                            <Button
                              onClick={toggleAwaitingInfo}
                              fullWidth
                              label={awaitingInfoAction.label}
                              variant="outlined"
                              color="primary"
                            />
                            <AwaitingInfoAction
                              claimId={meta.claim.claimId}
                              dlRef={awaitingInfo}
                              meta={meta}
                              handleRefresh={handleRefresh}
                            />
                          </Grid>
                        )}
                      <Grid item xs={2}>
                        <Button
                          onClick={handleHistory}
                          fullWidth
                          label="New Update"
                          variant="outlined"
                          color="primary"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs className={classes.relative}>
                  <ClaimDetailsPortfolioFilter
                    value={portfolio ? portfolio.toLowerCase() : undefined}
                    portfolio={meta.claim}
                    changePortfolio={(portfolio) =>
                      props.handlePortfolio(portfolio)
                    }
                  />
                  <Paper
                    title={
                      <Grid fluid className={classes.headerContainer}>
                        {[
                          { col: 2, name: "Date" },
                          { col: 1, name: "Company" },
                          { col: 1, name: "User" },
                          { col: 2, name: "Type" },
                          { col: 3, name: "Update" },
                          { col: 1, name: "Recipient" },
                          { col: 1, name: "Acknowledged" },
                          { col: 1, name: "Privacy" },
                        ].map(({ col, name }, key) => (
                          <Grid
                            className={classes.alignCenter}
                            item
                            key={key}
                            xs={col}
                          >
                            {name}
                          </Grid>
                        ))}
                      </Grid>
                    }
                  >
                    <HistoriesListItem data={props} />
                  </Paper>
                </Grid>
              </Grid>
            </>
          );
        }}
      />
    </>
  );
};

const actionQuery = graphql`
  query HistoriesListActionQuery(
    $historyWhere: ENDataEntityKey!
    $where: ClaimUpdateWhere!
    $first: Int
    $after: String
  ) {
    ...HistoriesListItem
    claimJob(where: $historyWhere) {
      view {
        actions {
          awaitingInfo {
            isDisplay
            isDisabled
            label
            id
          }
          createFeedback {
            isDisplay
            isDisabled
            label
            id
          }
          createFollowUp {
            isDisplay
            isDisabled
            label
            id
          }
          makeAppointment {
            isDisplay
            isDisabled
            label
            id
          }
          makeInitialCall {
            isDisplay
            isDisabled
            label
            id
          }
          changeAppointment {
            isDisplay
            isDisabled
            label
            id
          }
        }
      }
    }
  }
`;

const handleNextStep = () => {
  if (window[Symbol.for("__nextStepAction")]) {
    window[Symbol.for("__nextStepAction")].handleOpen();
  }
};

const handleHistory = () => {
  if (window[Symbol.for("__newHistoryAction")]) {
    window[Symbol.for("__newHistoryAction")].toggleOpen();
  }
};

const handleInitialCallMade = async (claimId) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const res = await snackbar.toggleOpen({
    message: "Please confirm that initial call has been made",
    firstButton: "Call Made",
    secondButton: "Not Yet",
    type: "confirm",
  });
  if (res.ok) {
    const mutationRes = await InitCallMutation({
      where: { id: claimId },
    });
    if (mutationRes.success) {
      snackbar.toggleOpen({
        message: "Confirmed",
      });
      if (window[Symbol.for("__HistoriesRefresh")]) {
        window[Symbol.for("__HistoriesRefresh")].handleRefresh();
      }
      if (window[Symbol.for("__refreshClaimDetails")]) {
        window[Symbol.for("__refreshClaimDetails")]();
      }
    } else {
      snackbar.toggleOpen({
        message: mutationRes.messages[0],
      });
    }
  }
};

export default withStyles((theme) => ({
  alignCenter: theme.mixins.alignJustifyContainer(theme),
  relative: {
    position: "relative",
  },
  headerContainer: {
    "& > div": {
      borderRight: "1px solid white",
    },
    "& > div:last-child": {
      borderRight: 0,
    },
  },
}))(withClaimMeta(HistoriesList));
