import React, { Component, createRef } from "react";

import { MDCSelect } from "@material/select";

import List from "components/MultipleSelect/List/";
import ListGroup from "components/MultipleSelect/List/ListGroup/";
import IconButton from "components/MultipleSelect/IconButton/";
import TextField from "components/MultipleSelect/TextField/";
import Icon from "@material-ui/core/Icon";

import "./index.scss";

const initialState = {
  selectedText: [],
  filterText: "",
  data: "",
  activeIndexes: [],
  unCheckFlag: true,
  groupClear: false,
  selectALL: false,
};

class MultipleSelect extends Component {
  state = initialState;
  element = createRef();
  hiddenInput = createRef();
  surface = createRef();

  componentDidMount = () => {
    const select = new MDCSelect(this.element.current);

    select.required = this.props.required;
    select.listen("MDCSelect:change", () => {
      this.setState({ data: select.value });
    });

    if (localStorage[`${this.props.filterPage}_${this.props.id}`]) {
      if (!this.props.notStoreInLocal)
        this.setState(
          JSON.parse(localStorage[`${this.props.filterPage}_${this.props.id}`])
        );
    }
  };

  componentWillMount = () => {
    if (localStorage[`${this.props.filterPage}_${this.props.id}`]) {
      if (!this.props.notStoreInLocal)
        this.setState(
          JSON.parse(localStorage[`${this.props.filterPage}_${this.props.id}`])
        );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.hiddenInput.current) {
      const input = this.hiddenInput.current;
      let { data } = this.state;
      if (!(input instanceof window.HTMLInputElement)) return;

      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
      ).set;
      if (
        (Array.isArray(data) || typeof data === "object" || data !== null) &&
        String(data).length !== 0
      ) {
        nativeInputValueSetter.call(input, JSON.stringify(data));
        if (!this.props.notStoreInLocal)
          localStorage.setItem(
            `${this.props.filterPage}_${this.props.id}`,
            JSON.stringify(this.state)
          );
      } else {
        nativeInputValueSetter.call(input, "");
        if (!this.props.notStoreInLocal)
          localStorage.setItem(
            `${this.props.filterPage}_${this.props.id}`,
            JSON.stringify(this.state)
          );
      }
      const onChangeEvent = new Event("input", { bubbles: true });
      input.dispatchEvent(onChangeEvent);
    }
  };

  render() {
    const { id, className } = this.props;
    const { checkbox, filtered, required } = this.props;
    const {
      filterPlaceholder = "Type here",
      leadIcon,
      label,
      outline,
      ripple,
    } = this.props;
    const { options } = this.props;
    const { selectedText, filterText } = this.state;
    return (
      <div
        ref={this.element}
        className={`mdc-select
        mdc-menu-surface--anchor
        ${className ? className : ""}
        ${outline ? "mdc-select--outlined" : ""}
        ${checkbox ? "mdc-select--checkbox" : ""}
        mdc-select--float
      `}
      >
        {leadIcon && (
          <i className="mdc-select__icon material-icons">{leadIcon}</i>
        )}
        <input
          name={id}
          required={required}
          ref={this.hiddenInput}
          style={{ opacity: 0, width: 0, float: "left" }}
          id={id}
        />
        <i className="mdc-select__dropdown-icon"></i>
        <Icon
          className="mdc-select__dropdown-clear"
          onClick={() => {
            this.props.refreshFilterField && this.props.refreshFilterField();
            this.setState({
              selectedText: [],
              filterText: "",
              data: "",
              activeIndexes: [],
              unCheckFlag: !this.state.unCheckFlag,
            });
            this.clearGroup();
          }}
        >
          close
        </Icon>
        <div className="mdc-select__selected-text truncate">
          {selectedText.map((x) => x.label).join(", ")}
        </div>
        <div
          ref={this.surface}
          className="mdc-select__menu mdc-menu mdc-menu-surface prevent-menu-close"
        >
          {filtered && (
            <TextField
              fullwidth
              className="prevent-menu-close"
              placeholder={filterPlaceholder}
              id={`${id}_filter`}
              onChange={this.filterHandle}
            />
          )}
          <CheckboxSelectAll
            unCheckFlag={this.state.unCheckFlag}
            checkedSelectAllChange={this.checkedSelectAllChange}
            groupData={groupReducerMapper(options)}
            localStorageData={
              localStorage[`${this.props.filterPage}_${this.props.id}`]
                ? JSON.parse(
                    localStorage[`${this.props.filterPage}_${this.props.id}`]
                  )
                : false
            }
          />
          <ListGroup>
            {!options.length && <List>No data</List>}
            {groupReducerMapper(options).map((option, key) => (
              <List
                key={key}
                groupHeader={
                  option[0] !== "undefined" && option[0] !== "null"
                    ? option[0]
                    : ""
                }
                checkedGroupChange={this.checkedGroupChange}
                groupData={groupReducerMapper(options)}
                GroupState={this.state}
                localStorageData={
                  localStorage[`${this.props.filterPage}_${this.props.id}`]
                    ? JSON.parse(
                        localStorage[
                          `${this.props.filterPage}_${this.props.id}`
                        ]
                      )
                    : false
                }
              >
                {option[1].map(({ group, label, id }, key) => (
                  <CheckboxItem
                    checkedChange={this.checkedChange}
                    activeIndexes={this.state.activeIndexes}
                    unCheckFlag={this.state.unCheckFlag}
                    group={group}
                    id={id}
                    label={label}
                    index={key}
                    key={key}
                    active={
                      this.state.activeIndexes.filter(
                        (e) =>
                          JSON.stringify(e) === JSON.stringify({ group, id })
                      ).length !== 0
                    }
                    hidden={
                      label.toLowerCase().indexOf(filterText.toLowerCase()) ===
                      -1
                    }
                  />
                ))}
              </List>
            ))}
          </ListGroup>
        </div>
        {outline ? (
          <div className="mdc-notched-outline">
            <div className="mdc-notched-outline__leading"></div>
            <div className="mdc-notched-outline__notch">
              <label className="mdc-floating-label mdc-floating-label--float-above">
                {label}
              </label>
            </div>
            <div className="mdc-notched-outline__trailing"></div>
          </div>
        ) : (
          <div className="mdc-line-ripple"></div>
        )}
        {!outline && label && (
          <label className="mdc-floating-label mdc-floating-label--float-above">
            {label}
          </label>
        )}
        {ripple && <div className="mdc-line-ripple"></div>}
      </div>
    );
  }
  checkedChange = (checkboxData) => {
    const { group, id, label, active } = checkboxData;
    const { data, selectedText } = this.state;

    if (group) {
      if (active) {
        this.setState({
          data: {
            ...data,
            [group]: data[group] ? [...data[group], id] : [id],
          },
          selectedText: [...selectedText, { group, label }],
          activeIndexes: [...this.state.activeIndexes, { group, id }],
        });
      } else {
        this.setState({
          data: {
            ...data,
            [group]: data[group] ? data[group].filter((x) => x !== id) : [],
          },
          selectedText: selectedText.filter(
            (e) => group !== e.group || label !== e.label
          ),
          activeIndexes: this.state.activeIndexes.filter(
            (e) => JSON.stringify(e) !== JSON.stringify({ group, id })
          ),
        });
      }
    } else {
      if (active) {
        this.setState({
          data: [...data, id],
          selectedText: [...selectedText, { group, label }],
          activeIndexes: [...this.state.activeIndexes, { group, id }],
        });
      } else {
        this.setState({
          data: data.filter((x) => x !== id),
          selectedText: selectedText.filter(
            (e) => group !== e.group || label !== e.label
          ),
          activeIndexes: this.state.activeIndexes.filter(
            (e) => JSON.stringify(e) !== JSON.stringify({ group, id })
          ),
        });
      }
    }
  };
  clearGroup = () => {
    this.setState({ groupClear: !this.state.groupClear });
  };
  checkedGroupChange = (groupData, label, active) => {
    const { data, selectedText, activeIndexes } = this.state;
    const groupArray = groupData.filter((e) => e[0] === label);
    const newSelectedText = groupArray[0][1].map((e) => {
      return { group: label, label: e.label };
    });
    const newActiveIndexes = groupArray[0][1].map((e) => {
      return { group: label, id: e.id };
    });
    if (active) {
      this.setState({
        data: {
          ...data,
          [label]: groupArray[0][1].map((e) => e.id),
        },
        selectedText: [...selectedText, ...newSelectedText],
        activeIndexes: [...activeIndexes, ...newActiveIndexes],
      });
    } else {
      this.setState({
        data: {
          ...data,
          [label]: [],
        },
        selectedText: selectedText.filter((e) => e.group !== label),
        activeIndexes: activeIndexes.filter((e) => e.group !== label),
      });
    }
  };

  checkedSelectAllChange = (groupData, active) => {
    const { data, selectedText, activeIndexes } = this.state;
    const selectAllData =
      groupData[0][0] === "null"
        ? groupData[0][1].map((e) => e.id)
        : Object.assign(
            ...groupData
              .map((e) => [e[0], e[1].map((item) => item.id)])
              .map(([key, val]) => ({ [key]: val }))
          );
    let selectAllIndexes = [];
    groupData.forEach((e) =>
      e[1].forEach((i) => selectAllIndexes.push({ group: i.group, id: i.id }))
    );
    let selectAllText = [];
    groupData.forEach((e) =>
      e[1].forEach((i) =>
        selectAllText.push({ group: i.group, label: i.label })
      )
    );
    if (active) {
      this.setState({
        data: selectAllData,
        selectedText: selectAllText,
        activeIndexes: selectAllIndexes,
        selectALL: true,
      });
    } else {
      this.setState({
        selectedText: [],
        filterText: "",
        data: "",
        activeIndexes: [],
        unCheckFlag: !this.state.unCheckFlag,
        selectALL: false,
      });
    }
  };

  filterHandle = (e) => {
    e.stopPropagation();
    this.setState({
      filterText: e.target.value,
    });
  };
}

const groupReducerMapper = (data) =>
  data &&
  Object.entries(
    data.reduce(
      (data, item) => ({
        ...data,
        [item.group]: data[item.group]
          ? [
              ...data[item.group],
              { group: item.group, label: item.label, id: item.value },
            ]
          : [{ group: item.group, label: item.label, id: item.value }],
      }),
      {}
    )
  );

export default MultipleSelect;

class CheckboxItem extends Component {
  element = createRef();
  state = {
    active: this.props.active,
  };

  componentDidMount = () => {
    const { checkedChange } = this.props;
    const { group, id, label, index } = this.props;
    this.element.current.addEventListener("click", (e) => {
      e.stopPropagation();
      this.setState({ active: !this.state.active }, () => {
        checkedChange({ group, id, label, active: this.state.active, index });
      });
    });
  };

  componentDidUpdate = (prevProps) => {
    const { activeIndexes } = this.props;
    if (this.props.unCheckFlag !== prevProps.unCheckFlag) {
      this.setState({ active: false });
    }
    if (
      JSON.stringify(activeIndexes) !== JSON.stringify(prevProps.activeIndexes)
    ) {
      this.setState({ active: this.props.active });
    }
  };

  render() {
    const { label, hidden } = this.props;
    return (
      <li
        ref={this.element}
        className={`mdc-list-item prevent-menu-close ${hidden ? "hide" : ""}`}
      >
        <IconButton
          active={this.state.active}
          icon="check_box_outline_blank"
          iconOn="check_box"
        />
        {label}
      </li>
    );
  }
}

class CheckboxSelectAll extends Component {
  element = createRef();
  state = {
    active: false,
  };

  componentDidMount = () => {
    const { checkedSelectAllChange, groupData, localStorageData } = this.props;
    let data = [];
    groupData.forEach((e) =>
      e[1].forEach((i) => data.push({ group: i.group, id: i.id }))
    );
    const initialState = localStorageData
      ? data.length ===
        (localStorageData.selectedText && localStorageData.selectedText.length)
      : localStorageData;
    if (initialState) {
      this.setState({ active: true });
    }
    this.element.current.addEventListener("click", (e) => {
      e.stopPropagation();
      this.setState({ active: !this.state.active }, () => {
        checkedSelectAllChange(groupData, this.state.active);
      });
    });
  };

  componentDidUpdate = (prevProps) => {
    const { activeIndexes, unCheckFlag } = this.props;
    if (this.props.unCheckFlag !== prevProps.unCheckFlag) {
      this.setState({ active: false });
    }
    if (
      JSON.stringify(activeIndexes) !== JSON.stringify(prevProps.activeIndexes)
    ) {
      this.setState({ active: this.props.active });
    }
  };

  render() {
    const { label, hidden } = this.props;
    return (
      <div ref={this.element} style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          active={this.state.active}
          icon="check_box_outline_blank"
          iconOn="check_box"
        />
        <h3> Select All </h3>
      </div>
    );
  }
}
