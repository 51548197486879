import React from "react";
import Dialog from "components/Mui/Dialog";
import { Formik, Form } from "formik";
import Grid from "components/Mui/Grid";
import Input from "components/Formik/Input";
import Button from "components/Mui/Button";
import useReactRouter from "use-react-router";
import { getSafe } from "common/utils";
import { emailRegexp, clientNameRegexp } from "common/inputRegex.js";
// import validator from 'validator'
import { useMutation } from "react-relay-mutation";

import CreateRoomMutation from "./CreateRoomMutation.js";
import CreateJobNoteMutation from "pages/Video/Room/mutations/CreateJobNoteMutation";
// import MuiPhoneInput from 'material-ui-phone-number'
import PhoneCountryInput from "../Formik/PhoneCountryInput/index.js";

const VideoCallInitialDialog = ({ symbol, meta }) => {
  const { history } = useReactRouter();
  const claim = meta && meta.claim;
  const [createJobNote] = useMutation(CreateJobNoteMutation);

  const address = getSafe(
    () =>
      `${claim.incidentDetail.riskAddress.line1} ${claim.incidentDetail.riskAddress.suburb} ${claim.incidentDetail.riskAddress.state}, ${claim.incidentDetail.riskAddress.postcode}`,
    null
  );
  return (
    <Dialog
      noForm
      noActions
      symbol={symbol}
      title="Initiate video call"
      content={
        <Formik
          initialValues={
            meta
              ? {
                  referenceNumber: claim.refNumber,
                  name: claim.insured.name,
                  address,
                  phone: "",
                  email: "",
                }
              : {}
          }
          validate={(values) => {
            let errors = {};

            if (values.referenceNumber && values.referenceNumber.length > 20)
              errors.referenceNumber = "Maximum 20 characters";

            if (values.referenceNumber && clientNameRegexp.test(values.name))
              errors.name = "Invalid character";

            if (values.address && values.address.length > 100)
              errors.address = "Maximum 100 characters";

            // if(!values.phone && !values.email) {
            //   errors.phone = 'Required at least phone or email'
            //   errors.email = 'Required at least phone or email'
            // }

            // TODO
            // if (values.phone && !validator.isMobilePhone(values.phone, 'en-AU')) errors.phone = 'Invalid phone'

            if (values.email && !emailRegexp.test(values.email))
              errors.email = "Invalid email";
            if (values.email && values.email.length > 100)
              errors.email = "Maximum 100 characters";

            // console.log(errors)
            return errors;
          }}
          onSubmit={(values, actions) => {
            setTimeout(async () => {
              // if (values.phone && values.phone.slice(0, 2) !== "61") {
              //   const isZero = values.phone.slice(0, 1) === "0"
              //   values.phone = `61${isZero ? values.phone.slice(1) : values.phone}`
              // }

              if (claim) {
                await createJobNote({
                  variables: {
                    input: {
                      claimId: claim.claimId,
                      message: "A new video session has started",
                    },
                  },
                });
              }

              const res = await CreateRoomMutation({ data: values });
              if (res) {
                const { callSessions } = res.roomCreate;
                const callSessionId = callSessions.find(
                  (session) => session.userType === "OWNER"
                ).id;

                history.push(
                  `/video/call?callSessionId=${callSessionId}${
                    claim ? `&claimId=${claim.claimId}` : ""
                  }`
                );
              }
            });
          }}
        >
          {({ isSubmitting, values, touched, setFieldValue }) => {
            return (
              <Form>
                <Grid fluid justify="flex-end">
                  {[
                    // {
                    //   col: 6, label: 'Endata Case ID',
                    //   component: Input, name: 'endataCaseId',
                    //   required: false,
                    //   unmountOn: false
                    // },
                    {
                      col: 3,
                      label: "Reference Number",
                      component: Input,
                      name: "referenceNumber",
                      required: true,
                      unmountOn: false,
                    },
                    {
                      col: 3,
                      label: "Client Name",
                      component: Input,
                      name: "name",
                      required: false,
                      unmountOn: false,
                    },
                    {
                      col: 6,
                      label: "Address",
                      component: Input,
                      name: "address",
                      required: false,
                      unmountOn: false,
                    },
                    {
                      col: 6,
                      label: "Phone",
                      component: PhoneCountryInput,
                      name: "phone",
                      readOnly: false,
                      required: !values.email,
                      unmountOn: false,
                    },
                    {
                      col: 6,
                      label: "Email",
                      component: Input,
                      name: "email",
                      readOnly: false,
                      required: !values.phone,
                      unmountOn: false,
                    },
                  ].map(
                    (
                      {
                        col,
                        component: Component,
                        unmountOn,
                        readOnly = Boolean(meta),
                        ...rest
                      },
                      key
                    ) =>
                      !unmountOn && (
                        <Grid item xs={col} key={key}>
                          {Component ? (
                            <Component readOnly={readOnly} {...rest} />
                          ) : (
                            <div />
                          )}
                        </Grid>
                      )
                  )}
                  <Grid item xs={1}>
                    <Button
                      fullWidth
                      label="Close"
                      onClick={() => {
                        if (window[Symbol.for(symbol)]) {
                          window[Symbol.for(symbol)].handleClose();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      fullWidth
                      disabled={isSubmitting}
                      label="Start"
                      type="submit"
                      variant="contained"
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      }
    />
  );
};

export default VideoCallInitialDialog;
