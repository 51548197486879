import React, { Component } from "react";
import environment from "common/relay";
import { withStyles } from "@material-ui/core/styles";
import { QueryRenderer, graphql } from "react-relay";
import { withRouter } from "react-router-dom";

import QuickAction from "components/Mui/QuickActions/QuickAction";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";

import { Formik, Form } from "formik";
import Switch from "components/Formik/Switch";
import Input from "components/Formik/Input";
import HistoryActionMutation from './HistoryActionMutation'

const HistoryAction = ({classes, claimId, meta, ...rest}) => {
  const qaRef = React.createRef();
  const snackbar = window[Symbol.for("__snackbar")]

  const portfolios = []
  if(meta.claim.hasBuilding) portfolios.push({label: 'Building', value: 'Building'})
  if(meta.claim.hasRestoration) portfolios.push({label: 'Restoration', value: 'Restoration'})
  if(meta.claim.hasContents) portfolios.push({label: 'Contents', value: 'Contents'})
  return (
    <QuickAction
      title="New Update"
      innerRef={qaRef}
      content={
        <QueryRenderer
          environment={environment}
          query={query}
          variables={{
            where: {
              claimId,
              participantStatus: "AcceptedOnly",
            },
          }}
          render={({ error, props }) => {
            if (!props) return "Loading...";
            const receiverOptions = props.claimParticipants.map((e) => ({
              ...e,
              value: `${e.company.companyType}_${e.group}_${e.value}`,
              group: e.group,
            }));

            return (
              <Formik
                initialValues={{
                  portfolioType: portfolios[0].value,
                  updateTypeId: "",
                  message: "",
                  responseRequired: false,
                  receiverCompanyId: "",
                  privacy: false,
                }}
                validate={(values) => {
                  let errors = {};
                  if (!values.updateTypeId) errors.updateTypeId = "required";
                  if (!values.receiverCompanyId && values.responseRequired)
                    errors.receiverCompanyId = "required";
                  if (!values.message) errors.message = "required";

                  return errors;
                }}
                onSubmit={(values, actions) => {
                  const [receiverRole, receiverCompanyId] = values.receiverCompanyId.split(/_.+_/);
                  setTimeout(async () => {
                    const res = await HistoryActionMutation({
                      claimId,
                      updateTypeId: values.updateTypeId,
                      responseRequired: values.responseRequired,
                      message: values.message,
                      privacy: values.privacy,
                      receiverCompanyId: receiverCompanyId ? parseInt(receiverCompanyId) : null,
                      portfolioType: values.portfolioType,
                      receiverRole: receiverRole? receiverRole : null,
                      path: rest.location.pathname
                    });
                    if (res.ok) {
                      actions.setSubmitting(false);
                      snackbar.toggleOpen({
                        message: res.message[0],
                      });
                      if (res.success) {
                        qaRef.current.toggleOpen();
                      }
                    }
                  }, 400);
                }}
              >
                {({ isSubmitting, values, touched, setFieldValue }) => {
                  return (
                    <FormDetail meta={meta} values={values} setFieldValue={setFieldValue}
                    classes={classes} portfolios={portfolios} receiverOptions={receiverOptions} isSubmitting={isSubmitting} />
                  );
                }}
              </Formik>
            );
          }}
        />
      }
      {...rest}
    />
  );
}

export default withStyles((theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
  },
}))(withRouter(HistoryAction));

const query = graphql`
  query HistoryActionQuery($where: ClaimParticipantIndex) {
    claimParticipants(where: $where) {
      company {
        companyType
      }
      value: companyId
      group: companyRole
      label: companyName
    }
  }
`;

const FormDetail = ({meta, values, setFieldValue, classes, portfolios, receiverOptions, isSubmitting}) => {

  React.useEffect(() => {
    const test =  meta.claimUpdateTypes.filter(e => e.value === values.updateTypeId)
    if (test.length > 0 && values.responseRequired !== test[0].responseRequired) {
      setFieldValue("responseRequired", test[0].responseRequired)
    }
    if (test.length > 0 && values.privacy !== test[0].privacy) {
      setFieldValue("privacy", test[0].privacy)
    }
  },[values.updateTypeId])

  return (
    <Form>
      <Grid fluid spacing={0} className={classes.root}>
        <Grid item xs={12}>
          <Input
            label="Portfolio Type"
            select
            required
            name="portfolioType"
            options={portfolios}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Update Type"
            select
            required
            name="updateTypeId"
            options={meta.claimUpdateTypes}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            fullWidth
            variant="outlined"
            label="Update"
            name="message"
            multiline
            rows={5}
            rowsMax={10}
          />
        </Grid>
        <Grid item xs={6}>
          <Switch
            label="Response Required?"
            name="responseRequired"
            labelOn="Yes"
            labelOff="No"
          />
        </Grid>
        <Grid item xs={6}>
          <Switch
            label="Privacy"
            name="privacy"
            labelOn="Private"
            labelOff="Public"
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Recipient"
            select
            name="receiverCompanyId"
            required={values.responseRequired}
            options={receiverOptions}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <Button
            label="Submit"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
          />
        </Grid>
      </Grid>
    </Form>
  )
}
