/**
 * @flow
 * @relayHash 385332b752d9f171f3b8df794bf00568
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuotationType = "AssessorReport" | "MakeSafe" | "Scope" | "UnDefined" | "Variation" | "%future added value";
export type QuoteCostItemIndex = {|
  costCentreId?: ?number,
  insurerId?: ?number,
  portfolioType: PortfolioType,
  quotationType: QuotationType,
|};
export type MakeSafeUpdateCostItemContainerQueryVariables = {|
  where: QuoteCostItemIndex
|};
export type MakeSafeUpdateCostItemContainerQueryResponse = {|
  +items: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +label: string,
        +value: number,
        +units: ?$ReadOnlyArray<?{|
          +label: string,
          +value: number,
        |}>,
      |}
    |}>
  |}
|};
export type MakeSafeUpdateCostItemContainerQuery = {|
  variables: MakeSafeUpdateCostItemContainerQueryVariables,
  response: MakeSafeUpdateCostItemContainerQueryResponse,
|};
*/


/*
query MakeSafeUpdateCostItemContainerQuery(
  $where: QuoteCostItemIndex!
) {
  items: quoteCostItems(where: $where) {
    edges {
      node {
        label: itemDescription
        value: costItemId
        units {
          label: unitName
          value: unitId
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "QuoteCostItemIndex!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "QuoteCostItemIndex!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "itemDescription",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "costItemId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "units",
  "storageKey": null,
  "args": null,
  "concreteType": "QuoteItemUnit",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": "label",
      "name": "unitName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": "value",
      "name": "unitId",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MakeSafeUpdateCostItemContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "items",
        "name": "quoteCostItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "QuoteCostItemConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "QuoteCostItemEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostItem",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MakeSafeUpdateCostItemContainerQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "items",
        "name": "quoteCostItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "QuoteCostItemConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "QuoteCostItemEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostItem",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MakeSafeUpdateCostItemContainerQuery",
    "id": null,
    "text": "query MakeSafeUpdateCostItemContainerQuery(\n  $where: QuoteCostItemIndex!\n) {\n  items: quoteCostItems(where: $where) {\n    edges {\n      node {\n        label: itemDescription\n        value: costItemId\n        units {\n          label: unitName\n          value: unitId\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9605249f7fda0c8f9f53ceb370021284';
module.exports = node;
