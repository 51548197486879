/**
 * @flow
 * @relayHash 3e4d2448dcc2b3d44ee14eb7fc724f4c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobVariationValidationSubmitInput = {|
  justified: boolean,
  comments?: ?string,
|};
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type SubmitVariationValidationMutationVariables = {|
  input: JobVariationValidationSubmitInput,
  where: ENDataPortfolioKey,
|};
export type SubmitVariationValidationMutationResponse = {|
  +jobVariationValidationSubmit: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type SubmitVariationValidationMutation = {|
  variables: SubmitVariationValidationMutationVariables,
  response: SubmitVariationValidationMutationResponse,
|};
*/


/*
mutation SubmitVariationValidationMutation(
  $input: JobVariationValidationSubmitInput!
  $where: ENDataPortfolioKey!
) {
  jobVariationValidationSubmit(where: $where, input: $input) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobVariationValidationSubmitInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "jobVariationValidationSubmit",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "JobVariationValidationSubmitInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ENDataPortfolioKey!"
      }
    ],
    "concreteType": "JobVariationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SubmitVariationValidationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SubmitVariationValidationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SubmitVariationValidationMutation",
    "id": null,
    "text": "mutation SubmitVariationValidationMutation(\n  $input: JobVariationValidationSubmitInput!\n  $where: ENDataPortfolioKey!\n) {\n  jobVariationValidationSubmit(where: $where, input: $input) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c4b615cda36c7b84426e803099661f93';
module.exports = node;
