import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation ReplyMessageMutation($input: ClaimCommunicationCreate!) {
    createClaimCommunication(input: $input) {
      success
      messages
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          resolve({ok: true, success: res.createClaimCommunication.success, messages: res.createClaimCommunication.messages})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}