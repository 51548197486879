/**
 * @flow
 * @relayHash 26a1d76cdabfe3f3a54258d27e4bd7c8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type SupplierStatusFilter = "AcceptedOnly" | "RequestedAndAvailable" | "RequestedOnly" | "%future added value";
export type SupplierTypeFilter = "ManagerAndSupplier" | "QuotingSupplier" | "Supplier" | "SupplierExcludeScoping" | "%future added value";
export type ClaimParticipantIndex = {|
  claimId: string,
  postcode?: ?string,
  portfolioTypes?: ?$ReadOnlyArray<?PortfolioType>,
  participantStatus?: ?SupplierStatusFilter,
  participantType?: ?SupplierTypeFilter,
|};
export type AllocateScopeSupplierActionQueryVariables = {|
  buildersWhere?: ?ClaimParticipantIndex,
  restorersWhere?: ?ClaimParticipantIndex,
|};
export type AllocateScopeSupplierActionQueryResponse = {|
  +Building: ?$ReadOnlyArray<?{|
    +label: string,
    +value: number,
    +portfolioType: PortfolioType,
    +requested: boolean,
    +companyRole: string,
  |}>,
  +Restoration: ?$ReadOnlyArray<?{|
    +label: string,
    +value: number,
    +portfolioType: PortfolioType,
    +requested: boolean,
    +companyRole: string,
  |}>,
|};
export type AllocateScopeSupplierActionQuery = {|
  variables: AllocateScopeSupplierActionQueryVariables,
  response: AllocateScopeSupplierActionQueryResponse,
|};
*/


/*
query AllocateScopeSupplierActionQuery(
  $buildersWhere: ClaimParticipantIndex
  $restorersWhere: ClaimParticipantIndex
) {
  Building: claimParticipants(where: $buildersWhere) {
    label: companyName
    value: companyId
    portfolioType
    requested
    companyRole
    id
  }
  Restoration: claimParticipants(where: $restorersWhere) {
    label: companyName
    value: companyId
    portfolioType
    requested
    companyRole
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "buildersWhere",
    "type": "ClaimParticipantIndex",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "restorersWhere",
    "type": "ClaimParticipantIndex",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "buildersWhere",
    "type": "ClaimParticipantIndex"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "companyId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "portfolioType",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "requested",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyRole",
  "args": null,
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "restorersWhere",
    "type": "ClaimParticipantIndex"
  }
],
v9 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AllocateScopeSupplierActionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "Building",
        "name": "claimParticipants",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimParticipant",
        "plural": true,
        "selections": (v7/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "Restoration",
        "name": "claimParticipants",
        "storageKey": null,
        "args": (v8/*: any*/),
        "concreteType": "ClaimParticipant",
        "plural": true,
        "selections": (v7/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllocateScopeSupplierActionQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "Building",
        "name": "claimParticipants",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimParticipant",
        "plural": true,
        "selections": (v9/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "Restoration",
        "name": "claimParticipants",
        "storageKey": null,
        "args": (v8/*: any*/),
        "concreteType": "ClaimParticipant",
        "plural": true,
        "selections": (v9/*: any*/)
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AllocateScopeSupplierActionQuery",
    "id": null,
    "text": "query AllocateScopeSupplierActionQuery(\n  $buildersWhere: ClaimParticipantIndex\n  $restorersWhere: ClaimParticipantIndex\n) {\n  Building: claimParticipants(where: $buildersWhere) {\n    label: companyName\n    value: companyId\n    portfolioType\n    requested\n    companyRole\n    id\n  }\n  Restoration: claimParticipants(where: $restorersWhere) {\n    label: companyName\n    value: companyId\n    portfolioType\n    requested\n    companyRole\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f0af03467277a715332dc121f02e555';
module.exports = node;
