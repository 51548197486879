import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { createPaginationContainer, graphql } from "react-relay";

import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";

import DashboardKpiDetailsItem from "./DashboardKpiDetailsItem";

class DashboardKpiDetailsPresentation extends Component {
  render() {
    const { edges } = this.props.data.reportingClaimJobGeneral;
    const { classes, isShowChart } = this.props;
    return (
      <Paper
        elevation={10}
        titleClass={classes.tableTitle}
        title={
          <Grid fluid spacing={0} className={classes.headerContainer}>
            <Grid item xs className={classes.centerCell}>
              Claim Number
            </Grid>
            <Grid item xs className={classes.centerCell}>
              {"Customer" /* label:'Client'|'Customer */}{" "}
            </Grid>
            <Grid item xs className={classes.centerCell}>
              Case Manager
            </Grid>
            <Grid item xs className={classes.centerCell}>
              Status
            </Grid>
            <Grid item xs className={classes.centerCell}>
              Days at Status
            </Grid>
          </Grid>
        }
      >
        <Grid
          fluid
          spacing={0}
          className={
            isShowChart ? classes.listBody : classes.listBodyWithoutChart
          }
          onScroll={this.scrollHandler}
        >
          {edges.map(({ node }, index) => (
            <DashboardKpiDetailsItem
              key={index}
              details={node}
              index={index + 1}
            />
          ))}
        </Grid>
      </Paper>
    );
  }

  scrollHandler = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight * 0.7) {
      this._loadMore();
    }
  };
  _loadMore = () => {
    if (!this.props.relay.hasMore()) return;
    else if (this.props.relay.isLoading()) return;

    this.props.relay.loadMore();
  };
}

export default createPaginationContainer(
  withStyles((theme) => ({
    centerCell: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerContainer: {
      "& > div": {
        borderRight: "1px solid white",
      },
      "& > div:last-child": {
        borderRight: 0,
      },
    },
    tableTitle: {
      padding: "8px 0",
    },
    row: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      alignItems: "center",

      "&:nth-child(even)": {
        background: theme.palette.grey[100],
      },
      "&:hover": {
        background: theme.palette.grey[300],
      },
    },
    listBody: {
      maxHeight: "330px",
      overflow: "auto",
    },
    listBodyWithoutChart: {
      maxHeight: "675px",
      overflow: "auto",
    },
  }))(DashboardKpiDetailsPresentation),
  graphql`
    fragment DashboardKpiDetailsPresentation on Query {
      reportingClaimJobGeneral(first: $first, after: $after, where: $where)
        @connection(
          key: "DashboardKpiDetailsPresentation_reportingClaimJobGeneral"
          filters: []
        ) {
        edges {
          node {
            ...DashboardKpiDetailsItem_details
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  `,
  {
    query: graphql`
      query DashboardKpiDetailsPresentationQuery(
        $first: Int!
        $after: String
        $where: CaseReportIndex
      ) {
        ...DashboardKpiDetailsPresentation
      }
    `,
    getVariables(props, paginationInfo, fragmentVariables) {
      const { cursor } = paginationInfo;
      return {
        ...fragmentVariables,
        after: cursor,
      };
    },
  }
);
