import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation LogoutMutation {
    userLogout {
      success
    }
  }
`

export default () => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        onCompleted: res => {
          resolve()
        },
        onError: errors => {
          resolve()
        }
      }
    )
  })
}