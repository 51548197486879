import React from 'react'
import { moneyFormat } from 'common/utils'
import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'
import IconButton from 'components/Mui/IconButton'

import UpdateQuoteItem from '../QuoteActionBar/UpdateQuoteItem'

const QuoteGroupItem = ({ classes, item, isAction, portfolio, tableItem }) => {
  const editQuoteItem = () => {
    const actions = window[Symbol.for(`${item.jobQuoteItemId}__scopeUpdateItem`)]
    if(actions) actions.handleOpen()
  }
  return (
    <>
      <Grid fluid spacing={0} className={classes.row}>
        {[
          // cost centre
          {col: 2, text: item.costCentre.costCentreName, unMountOn: !tableItem.fields.costCentre.isDisplay},
          // description
          {col: true, text: `${item.costItem ? item.costItem.itemDescription : ''} ${item.lineDescription}`, unMountOn: !tableItem.fields.description.isDisplay},
          // sir rate
          {col: 1, text: moneyFormat(item.sirRate), unMountOn: !tableItem.fields.sIRRate.isDisplay, className: classes.sir },
          // unit
          {col: 1, text: item.unit && item.unit.unitName, unMountOn: !tableItem.fields.unit.isDisplay},
          // qty
          {col: 1, text: item.qty, unMountOn: !tableItem.fields.qty.isDisplay},
          // rate ex
          {col: 1, text: moneyFormat(item.rate), unMountOn: !tableItem.fields.rate.isDisplay},
          // margin
          {col: 1, text: `${item.marginRate}%`, unMountOn: !tableItem.fields.marginRate.isDisplay},
          // subtotal
          {col: 1, text: moneyFormat(item.subtotal), unMountOn: !tableItem.fields.subtotal.isDisplay},
          // actions
          {col: 1, noActions: !isAction, unMountOn:!tableItem.fields.action.isDisplay, text: (
            <>
              <IconButton icon='edit' className={classes.actionBtn}
                onClick={() => editQuoteItem()}
              />
            </>
          )},
        ].map(({ col, text, unMountOn, noActions, className }, key) => {
          if(unMountOn) return null

          return (
            <Grid className={`${className} ${classes.alignCenter}`} item key={key} xs={col}>
              {!noActions && text}
            </Grid>
          )
        })}
      </Grid>
      <UpdateQuoteItem
        symbol={`${item.jobQuoteItemId}__scopeUpdateItem`}
        portfolios={portfolio}
        initialData={{
          id: item.jobQuoteItemId,
          portfolioType: portfolio,
          locationId: item.location.costLocationId,
          locationName: item.location.locationName,
          dimensions: item.dimensions,
          costCentreId: item.costCentre.costCentreId,
          costCentreName: item.costCentre.costCentreName,
          costItemId: item.costItem.costItemId,
          itemDescription: item.costItem.itemDescription,
          lineDescription: item.lineDescription,
          unitId: item.unit.unitId,
          unitName: item.unit.unitName,
          marginRate: item.marginRate,
          qty: item.qty,
          rate: item.rate
        }}
      />
    </>
  )
}

export default withStyles(theme => ({
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: 'center',
    borderRadius: '8px',

    '&:nth-child(even)': {
      background: theme.palette.grey[100],
    },
    '&:hover': {
      background: theme.palette.grey[200],
    }
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit
  },
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
    padding: '4px',
    textAlign: 'center',
    flexWrap: 'wrap'
  },
  sir: {
    color: theme.palette.grey[800],
    fontStyle: 'italic'
  }
}))(QuoteGroupItem)
