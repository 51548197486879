import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const mutation = graphql`
  mutation ScopeValidationActionMutation(
    $input: JobValidationReviewInput!
    $where: ENDataPortfolioKey!
  ) {
    jobScopeValidationReview(where: $where, input: $input) {
      messages
      success
      result {
        jobQuoteId
        actions {
          # completeScopeValidation {
          #   label
          #   isDisabled
          #   isDisplay
          # }
          completeValidation {
            id
            isDisabled
            isDisplay
            label
          }
          # resetScopeValidation {
          #   label
          #   isDisabled
          #   isDisplay
          # }
          resetValidation {
            label
            isDisabled
            isDisplay
          }
        }
        scopeTypes {
          properties {
            locations {
              location {
                locationName
                costLocationId
                id
              }
              items {
                jobQuoteItemId
                quoteItemType
                accepted
                actions {
                  accept {
                    label
                    isDisabled
                    isDisplay
                  }
                  decline {
                    label
                    isDisabled
                    isDisplay
                  }
                  amend {
                    label
                    isDisabled
                    isDisplay
                  }
                  validate {
                    label
                    isDisabled
                    isDisplay
                  }
                }
                location {
                  costLocationId
                  locationName
                }
                dimensions
                costCentre {
                  costCentreId
                  costCentreName
                }
                costItem {
                  costItemId
                  itemDescription
                }
                lineDescription
                unit {
                  unitId
                  unitName
                }
                directsupply
                purchasePrice
                ageOfItem
                proofOfLoss
                policyLimit
                qty
                rate
                marginRate
                subtotal
                logdate
                policyLimit
                requestedQuote
                sirRate
                lineStatusDescription
                note
                scopeType {
                  name
                  id
                }
              }
              validationItems {
                jobQuoteItemId
                quoteItemType
                accepted
                actions {
                  accept {
                    label
                    isDisabled
                    isDisplay
                  }
                  decline {
                    label
                    isDisabled
                    isDisplay
                  }
                  amend {
                    label
                    isDisabled
                    isDisplay
                  }
                  validate {
                    label
                    isDisabled
                    isDisplay
                  }
                }
                location {
                  costLocationId
                  locationName
                }
                dimensions
                costCentre {
                  costCentreId
                  costCentreName
                }
                costItem {
                  costItemId
                  itemDescription
                }
                lineDescription
                unit {
                  unitId
                  unitName
                }
                directsupply
                purchasePrice
                ageOfItem
                proofOfLoss
                policyLimit
                qty
                rate
                marginRate
                subtotal
                logdate
                policyLimit
                requestedQuote
                sirRate
                lineStatusDescription
                note
                scopeType {
                  name
                  id
                }
              }
            }
          }
          costCentres {
            costCentre {
              costCentreName
              costCentreId
              costType
              directSupplier
            }
            units {
              qty
              subtotal
              unitName
              validationQty
              validationSubtotal
            }
          }
          locations {
            id
            location {
              id
              costLocationId
              locationName
            }
            items {
              jobQuoteItemId
              quoteItemType
              accepted
              actions {
                accept {
                  label
                  isDisabled
                  isDisplay
                }
                decline {
                  label
                  isDisabled
                  isDisplay
                }
                amend {
                  label
                  isDisabled
                  isDisplay
                }
                validate {
                  label
                  isDisabled
                  isDisplay
                }
              }
              location {
                costLocationId
                locationName
              }
              dimensions
              costCentre {
                costCentreId
                costCentreName
              }
              costItem {
                costItemId
                itemDescription
              }
              lineDescription
              unit {
                unitId
                unitName
              }
              directsupply
              purchasePrice
              ageOfItem
              proofOfLoss
              policyLimit
              qty
              rate
              marginRate
              subtotal
              logdate
              policyLimit
              requestedQuote
              sirRate
              lineStatusDescription
              note
              scopeType {
                name
                id
              }
            }
          }
          scopeType {
            id
            name
          }
          id
          gst
          margin
          sirGst
          sirMargin
          sirSubtotal
          sirTotal
          subtotal
          total
          discount
          fields {
            marginBreakdown {
              isDisplay
              label
            }
            discount {
              isDisplay
              label
              isDisabled
            }
            sIRRate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            gst {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            total {
              label
              isDisplay
            }
            action {
              label
              isDisplay
            }
            costCentre {
              label
              isDisplay
            }
            description {
              label
              isDisplay
            }
            marginRate {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            qty {
              label
              isDisplay
            }
            rate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            unit {
              label
              isDisplay
            }
          }
        }
      }
    }
  }
`;

export default (variables) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (res) => {
        const { success, messages } = res.jobScopeValidationReview;
        resolve({ ok: true, success, messages });
      },
      onError: (errors) => {
        resolve({
          ok: false,
          messages: ["Unable to complete action, please try again"],
        });
      },
    });
  });
};
