import React, { Component } from 'react'
import classNames from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import MuiPaper from '@material-ui/core/Paper'

class Paper extends Component {
  render() {
    const {
      classes, children,
      title, titleClass, titleProps,
      content, contentClass, contentProps,
      smallCard,
      ...rest
    } = this.props

    return (
      <MuiPaper {...rest} classes={{root: smallCard && classes.smallCard}}>
        {title &&
          <div className={classNames(classes.paperTitle, titleClass)} {...titleProps}>
            {title}
          </div>
        }
        {content &&
          <div className={classNames(classes.paperContent, contentClass)} {...contentProps}>
            {content}
          </div>
        }
        {children}
      </MuiPaper>
    )
  }
}

export default withStyles(theme => ({
  paperTitle: {
    ...theme.mixins.gutters(),
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '8px 8px 0 0',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    fontSize: '1rem',
    fontWeight: '800'
  },
  paperContent: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  smallCard: {
    width: '50%',
    margin: 'auto',
  }
}))(Paper)
