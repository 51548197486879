import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";

// hooks
import usePortfolio from "hooks/usePortfolio";

// context
import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";

import Grid from "@material-ui/core/Grid";
import { Cube } from "components/Mui/Loader";
import QuotePresentation from "./QuotePresentation";

const Quote = (props) => {
  const meta = React.useContext(ClaimMetaContext);

  /**
   * HANDLE ROUTING AND DEFAULT SEARCH STRING
   */
  const portfolio = meta.user.type.isBuilder
    ? "Building"
    : meta.user.type.isContent
    ? "Contents"
    : meta.user.type.isRestorer
    ? "Restoration"
    : undefined;

  /**
   * HANDLE HARD REFRESH
   */
  const [update, setUpdate] = React.useState(false);
  React.useEffect(() => {
    window[Symbol.for("__refreshQuote")] = () => setUpdate(!update);
    return () => {
      delete window[Symbol.for("__refreshQuote")];
    };
  }, [update]);

  /**
   * HANDLE REQUEST CATEGORIES
   */

  if (!portfolio) return null;

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        update,
        isContent: portfolio === "Contents",
        where: {
          claimId: meta.claim.claimId,
          portfolios: portfolio,
          requestCategory: "DesktopQuoting",
        },
        jobScopeWhere: {
          insurerId: meta.claim.insurer.companyId,
          quotationType: "Scope",
          portfolioType: portfolio,
          forManager: false,
        },
      }}
      render={({ error, props }) => {
        if (!props) return <Cube />;

        return (
          <Grid container>
            <Grid item xs={12}>
              <QuotePresentation
                quotes={props}
                portfolio={portfolio}
                meta={meta}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default Quote;

const query = graphql`
  query QuoteQuery(
    $where: JobQuoteIndex!
    $jobScopeWhere: JobScopeTypeWhere!
    $isContent: Boolean!
  ) {
    jobQuotes(where: $where, first: 100000)
      @connection(key: "ScopeQuery_jobQuotes", filters: []) {
      edges {
        node {
          id
          actions {
            makeAppointment {
              label
              isDisabled
              isDisplay
            }
            addLineItem {
              label
              isDisabled
              isDisplay
            }
            makeInitialCall {
              label
              isDisabled
              isDisplay
            }
            requestQuotes {
              label
              isDisabled
              isDisplay
            }
            reviewQuote {
              label
              isDisabled
              isDisplay
            }
            submitQuote {
              label
              isDisabled
              isDisplay
            }
            resetQuote {
              label
              isDisabled
              isDisplay
            }
          }
          jobQuoteId
          completed
          reviewed
          subtotal
          gst
          margin
          total
          deliveryCharges
          deliveryChargeUsed
          supplier {
            companyId
          }

          lineItems @include(if: $isContent) {
            authorised
            logdate
            jobQuoteItemId

            location {
              costLocationId
              locationName
            }
            dimensions

            costCentre {
              costCentreId
              costCentreName
            }
            costItem {
              costItemId
              itemDescription
            }
            lineDescription
            directsupply
            purchasePrice
            ageOfItem
            proofOfLoss
            qty
            #reserve price

            rate
            subtotal

            policyLimit
            substitutename
          }

          scopeTypes @skip(if: $isContent) {
            locations {
              id
              location {
                id
                costLocationId
                locationName
              }
              items {
                jobQuoteItemId
                accepted
                location {
                  costLocationId
                  locationName
                }
                dimensions
                costCentre {
                  costCentreId
                  costCentreName
                }
                costItem {
                  costItemId
                  itemDescription
                }
                lineDescription
                unit {
                  unitId
                  unitName
                }
                directsupply
                purchasePrice
                ageOfItem
                proofOfLoss
                policyLimit
                qty
                rate
                marginRate
                subtotal
                logdate
                policyLimit
                requestedQuote
                sirRate
                scopeType {
                  name
                  id
                }
              }
            }
            scopeType {
              id
              name
            }
            id
            gst
            margin
            sirGst
            sirMargin
            sirSubtotal
            sirTotal
            subtotal
            total
            discount
            fields {
              marginBreakdown {
                isDisplay
                label
              }
              discount {
                isDisplay
                label
                isDisabled
              }
              sIRRate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              gst {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              total {
                label
                isDisplay
              }
              action {
                label
                isDisplay
              }
              costCentre {
                label
                isDisplay
              }
              description {
                label
                isDisplay
              }
              marginRate {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              qty {
                label
                isDisplay
              }
              rate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              unit {
                label
                isDisplay
              }
            }
          }
        }
      }
    }
    jobScopeTypes(where: $jobScopeWhere) {
      id
    }
  }
`;
