import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation VariationDiscountUpdateMutation($input: JobVariationDiscountInput!) {
    discountVariationUpdate(input: $input) {
      success
      messages
      result {

        variationId
          completed
          variationStatus

          # info card
          variationTitle variationDescription
          variationReason { reasonDescription }





          scopeTypes {
          locations {
            id
            location {
              id
              costLocationId
              locationName
            }
            items {
              variationItemId
              accepted
              location { costLocationId locationName } dimensions
              costCentre { costCentreId costCentreName }
              costItem { costItemId itemDescription } lineDescription
              unit { unitId unitName }
              qty
              rate
              marginRate
              subtotal
              logdate
              sirRate
              scopeType {
                name
                id
              }
            }
          }
          scopeType {
            id
            name
          }
          id
          gst
          margin
          sirGst
          sirMargin
          sirSubtotal
          sirTotal
          subtotal
          total
          discount
          fields {
            discount {
              isDisplay
              label
              isDisabled
            }
            sIRRate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            gst {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            total {
              label
              isDisplay
            }
            action {
              label
              isDisplay
            }
            costCentre {
              label
              isDisplay
            }
            description {
              label
              isDisplay
            }
            marginRate {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            qty {
              label
              isDisplay
            }
            rate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            unit {
              label
              isDisplay
            }
          }
        }






          # item table
          lineItems {
            variationItemId
            accepted

            location { costLocationId locationName }
            dimensions
            sirRate
            costCentre { costCentreId costCentreName }
            costItem { costItemId itemDescription } lineDescription
            unit { unitId unitName }
            marginRate
            qty
            rate
            gst
            subtotal
          }

          # report
          subtotal gst margin total
      }
    }
  }
`
export default variables => {

  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          resolve({
            ok: true,
            success: res.discountVariationUpdate.success,
            messages: res.discountVariationUpdate.messages
          })
        },
        onError: errors => {
          resolve({ ok: false })
        }
      }
    )
  })
}
