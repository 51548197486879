import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import { fetchQuery, graphql } from "relay-runtime";
import environment from "common/relay";

import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";

const QuotesListHeader = ({
  classes,
  displayValidationValue,
  displayValidation,
}) => {
  const meta = useMetaContext();
  return (
    <Grid fluid spacing={0} className={classes.root}>
      {[
        { col: 2, content: "Trade Type" },
        { col: 1, content: "SIR", unMountOn: !meta.claim.insurer.isSirModule },
        {
          col: 1,
          content: displayValidation.label,
          unMountOn: !displayValidation.isDisplay,
        },
        { col: 1, content: "Scope Provider" },
        { content: "Desktop Quotes" },
      ].map(({ col = true, content, unMountOn, ...props }, index) => {
        if (unMountOn) return null;

        return (
          <Grid item xs={col} key={index} className={classes.headerItems}>
            {content}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default withStyles((theme) => ({
  root: {
    "& > div": {
      borderRight: "1px solid white",
    },
    "& > div:last-child": {
      borderRight: 0,
    },
  },
  headerItems: theme.mixins.alignJustifyContainer(theme),
}))(QuotesListHeader);
