import React, { Component } from "react";
import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
import Button from "components/Mui/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { graphql, QueryRenderer } from "react-relay";
import { Formik } from "formik";
import environment from "common/relay";
import { withClaimMeta } from "pages/Claim/ClaimDetails/ClaimMetaContext";

import GetSiteReportPdfMutation from "../../../ClaimDetailsAction/GetSiteReportPdfMutation";
import GetQuotePdfMutation from "./GetQuotePdfMutation";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import PrintIcon from "@material-ui/icons/Print";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const query = graphql`
  query QuotesActionPresentationQuery($where: JobScopeTypeWhere!) {
    jobScopeTypes(where: $where) {
      id
      name
    }
  }
`;
class QuotesActionPresentation extends Component {
  state = {
    anchorEl: null,
    anchorElForQuote: null,
    isLoadingForSite: false,
    isLoadingForScope: false,
    isLoadingForQuote: false,
    isShowDialogForPDF: false,
    selectedDialogOption: null,
    isShowDialogForPDFWithoutTypes: false,
    isShowDialogForPDFQuotes: false,
    selectedDialogQuoteOption: null,
    isShowDialogForQuotesPDFWithoutTypes: false,
    isPrintWithRate: true,
    isPrintWithTotal: true,
    isWithRateForQuotes: true,
    isWithTotalForQuotes: true,
  };
  showPDFModal = (event) => {
    this.setState({ isShowDialogForPDF: true });
    this.setState({ selectedDialogOption: event });
  };
  showPDFModalWithoutType = (event) => {
    this.setState({ isShowDialogForPDFWithoutTypes: true });
  };
  showPDFModalQuotes = (event) => {
    this.setState({ isShowDialogForPDFQuotes: true });
    this.setState({ selectedDialogQuoteOption: event });
  };
  showPDFModalQuotesWithoutType = (event) => {
    this.setState({ isShowDialogForQuotesPDFWithoutTypes: true });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuForQuote = (event) => {
    this.setState({ anchorElForQuote: event.currentTarget });
  };

  handleChangePrintRate = (event) => {
    this.setState({ isPrintWithRate: event.target.checked });
  };
  handleChangePrintTotal = (event) => {
    this.setState({ isPrintWithTotal: event.target.checked });
  };
  handleChangePrintRateForQuotes = (event) => {
    this.setState({ isWithRateForQuotes: event.target.checked });
  };
  handleChangePrintTotalForQuotes = (event) => {
    this.setState({ isWithTotalForQuotes: event.target.checked });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ anchorElForQuote: null });
    this.setState({ isShowDialogForPDF: null });
    this.setState({ isShowDialogForPDFWithoutTypes: null });
    this.setState({ isShowDialogForPDFQuotes: null });
    this.setState({ isShowDialogForQuotesPDFWithoutTypes: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { anchorElForQuote } = this.state;
    const { isLoadingForSite } = this.state;
    const { isLoadingForScope } = this.state;
    const { isLoadingForQuote } = this.state;
    const { isShowDialogForPDF } = this.state;
    const { selectedDialogOption } = this.state;
    const { isShowDialogForPDFWithoutTypes } = this.state;
    const { isShowDialogForPDFQuotes } = this.state;
    const { selectedDialogQuoteOption } = this.state;
    const { isShowDialogForQuotesPDFWithoutTypes } = this.state;
    const { isPrintWithRate } = this.state;
    const { isPrintWithTotal } = this.state;
    const { isWithRateForQuotes } = this.state;
    const { isWithTotalForQuotes } = this.state;

    const {
      jobQuoteId,
      portfolioType,
      companyId,
      isDisplay,
      claimId,
      tenderClosingDate,
      data,
    } = this.props;
    const display = this.props.isDisplay;
    const tenderClosingDateAction =
      portfolioType === "Building"
        ? tenderClosingDate.building
        : portfolioType === "Restoration"
        ? tenderClosingDate.restoration
        : null;
    return (
      <Formik onSubmit={() => {}}>
        <Paper>
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              where: {
                insurerId: this.props.companyId,
                portfolioType: this.props.portfolioType,
                quotationType: "Scope",
                claimId: this.props.claimId,
                forManager: false,
              },
            }}
            render={({ error, props }) => {
              if (!props) return null;
              else {
                var jobScopeTypeListForScopeType = [
                  { label: "All Scope Type", value: null },
                ];
                props.jobScopeTypes.map((e) => {
                  jobScopeTypeListForScopeType.push({
                    label: e.name,
                    value: e.id,
                  });
                });
                var jobScopeTypeListForQuote = [
                  { label: "All Scope Type", value: null },
                ];
                props.jobScopeTypes.map((e) => {
                  jobScopeTypeListForQuote.push({ label: e.name, value: e.id });
                });
              }

              var scopeTypeButtonMenu = null;
              if (display == true) {
                scopeTypeButtonMenu = (
                  <>
                    <Button
                      aria-controls="scopeOfWorksMenu"
                      aria-haspopup="true"
                      variant="outlined"
                      color="primary"
                      onClick={this.handleMenu}
                      leadIcon={
                        !isLoadingForScope ? (
                          <PrintIcon />
                        ) : (
                          <MuiCircularProgress size={24} />
                        )
                      }
                      fullWidth
                      disabled={!jobQuoteId}
                      label={
                        portfolioType !== "Contents"
                          ? "Scope of works"
                          : "Schedule of Loss"
                      }
                    />
                    <Menu
                      id="scopeOfWorksMenu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={this.handleClose}
                    >
                      {jobScopeTypeListForScopeType.map((option, index) => {
                        return (
                          <>
                            <MenuItem
                              key={index}
                              onClick={() => this.showPDFModal(option)}
                            >
                              {option.label}
                            </MenuItem>
                          </>
                        );
                      })}
                      <Dialog
                        open={isShowDialogForPDF}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth="md"
                      >
                        <DialogTitle id="alert-dialog-slide-title">
                          {`Please select one of the following types of ${
                            selectedDialogOption && selectedDialogOption.label
                              ? selectedDialogOption.label
                              : null
                          }`}
                        </DialogTitle>
                        <DialogContent>
                          <FormGroup row>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {isPrintWithRate ? (
                                <AttachMoneyIcon />
                              ) : (
                                <MoneyOffIcon />
                              )}
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={isPrintWithRate}
                                    onChange={this.handleChangePrintRate}
                                    name="rate"
                                  />
                                }
                                label={
                                  isPrintWithRate
                                    ? "With Line Item Rate"
                                    : "Without Line Item Rate"
                                }
                              />
                            </div>{" "}
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {isPrintWithTotal ? (
                                <AttachMoneyIcon />
                              ) : (
                                <MoneyOffIcon />
                              )}
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={isPrintWithTotal}
                                    onChange={this.handleChangePrintTotal}
                                    name="total"
                                  />
                                }
                                label={
                                  isPrintWithTotal
                                    ? "With Totals"
                                    : "Without Totals"
                                }
                              />
                            </div>
                          </FormGroup>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() =>
                              this.handlePrintWorkScopeWithRate(
                                selectedDialogOption,
                                isPrintWithRate,
                                isPrintWithTotal
                              )
                            }
                            variant="outlined"
                            color="primary"
                            fullWidth
                            label="Print"
                          />
                        </DialogActions>
                      </Dialog>
                    </Menu>
                  </>
                );
              } else {
                scopeTypeButtonMenu = (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => this.showPDFModalWithoutType()}
                      leadIcon={
                        !isLoadingForScope ? (
                          <PrintIcon />
                        ) : (
                          <MuiCircularProgress size={24} />
                        )
                      }
                      fullWidth
                      disabled={!jobQuoteId}
                      label={
                        portfolioType !== "Contents"
                          ? "Scope of works"
                          : "Schedule of Loss"
                      }
                    />
                    <Dialog
                      open={isShowDialogForPDFWithoutTypes}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      fullWidth="md"
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        Please select one of the following types
                      </DialogTitle>
                      <DialogContent>
                        <FormGroup row>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {isPrintWithRate ? (
                              <AttachMoneyIcon />
                            ) : (
                              <MoneyOffIcon />
                            )}
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isPrintWithRate}
                                  onChange={this.handleChangePrintRate}
                                  name="rate"
                                />
                              }
                              label={
                                isPrintWithRate
                                  ? "With Line Item Rate"
                                  : "Without Line Item Rate"
                              }
                            />
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {isPrintWithTotal ? (
                              <AttachMoneyIcon />
                            ) : (
                              <MoneyOffIcon />
                            )}
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isPrintWithTotal}
                                  onChange={this.handleChangePrintTotal}
                                  name="total"
                                />
                              }
                              label={
                                isPrintWithTotal
                                  ? "With Totals"
                                  : "Without Totals"
                              }
                            />
                          </div>
                        </FormGroup>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() =>
                            this.handlePrintWorkScopeWithRate(
                              null,
                              isPrintWithRate,
                              isPrintWithTotal
                            )
                          }
                          variant="outlined"
                          color="primary"
                          fullWidth
                          label="Print"
                        />
                      </DialogActions>
                    </Dialog>
                  </>
                );
              }

              var quoteButtonMenu = null;
              if (display == true) {
                quoteButtonMenu = (
                  <>
                    <Button
                      aria-controls="quotesMenu"
                      aria-haspopup="true"
                      variant="outlined"
                      color="primary"
                      onClick={this.handleMenuForQuote}
                      leadIcon={
                        !isLoadingForQuote ? (
                          <PrintIcon />
                        ) : (
                          <MuiCircularProgress size={24} />
                        )
                      }
                      fullWidth
                      label="Quotes"
                      disabled={!jobQuoteId}
                    />
                    <Menu
                      id="quotesMenu"
                      anchorEl={anchorElForQuote}
                      keepMounted
                      open={Boolean(anchorElForQuote)}
                      onClose={this.handleClose}
                    >
                      {jobScopeTypeListForQuote.map((option, index) => {
                        return (
                          <MenuItem
                            key={index}
                            onClick={() => this.showPDFModalQuotes(option)}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                    <Dialog
                      open={isShowDialogForPDFQuotes}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      fullWidth="md"
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        {`Please select one of the following types of ${
                          selectedDialogQuoteOption &&
                          selectedDialogQuoteOption.label
                            ? selectedDialogQuoteOption.label
                            : null
                        }`}
                      </DialogTitle>
                      <DialogContent>
                        <FormGroup row>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {isWithRateForQuotes ? (
                              <AttachMoneyIcon />
                            ) : (
                              <MoneyOffIcon />
                            )}
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isWithRateForQuotes}
                                  onChange={this.handleChangePrintRateForQuotes}
                                  name="rate"
                                />
                              }
                              label={
                                isWithRateForQuotes
                                  ? "With Line Item Rate"
                                  : "Without Line Item Rate"
                              }
                            />
                          </div>{" "}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {isWithTotalForQuotes ? (
                              <AttachMoneyIcon />
                            ) : (
                              <MoneyOffIcon />
                            )}
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isWithTotalForQuotes}
                                  onChange={
                                    this.handleChangePrintTotalForQuotes
                                  }
                                  name="total"
                                />
                              }
                              label={
                                isWithTotalForQuotes
                                  ? "With Totals"
                                  : "Without Totals"
                              }
                            />
                          </div>
                        </FormGroup>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() =>
                            this.handlePrintQuoteWithRate(
                              selectedDialogQuoteOption,
                              isWithRateForQuotes,
                              isWithTotalForQuotes
                            )
                          }
                          variant="outlined"
                          color="primary"
                          fullWidth
                          label="Print"
                        />
                      </DialogActions>
                    </Dialog>
                  </>
                );
              } else {
                quoteButtonMenu = (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => this.showPDFModalQuotesWithoutType()}
                      leadIcon={
                        !isLoadingForQuote ? (
                          <PrintIcon />
                        ) : (
                          <MuiCircularProgress size={24} />
                        )
                      }
                      fullWidth
                      label="Quotes"
                      disabled={!jobQuoteId}
                    />
                    <Dialog
                      open={isShowDialogForQuotesPDFWithoutTypes}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      fullWidth="md"
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        Please select one of the following types
                      </DialogTitle>
                      <DialogContent>
                        <FormGroup row>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {isWithRateForQuotes ? (
                              <AttachMoneyIcon />
                            ) : (
                              <MoneyOffIcon />
                            )}
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isWithRateForQuotes}
                                  onChange={this.handleChangePrintRateForQuotes}
                                  name="rate"
                                />
                              }
                              label={
                                isWithRateForQuotes
                                  ? "With Line Item Rate"
                                  : "Without Line Item Rate"
                              }
                            />
                          </div>{" "}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {isWithTotalForQuotes ? (
                              <AttachMoneyIcon />
                            ) : (
                              <MoneyOffIcon />
                            )}
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isWithTotalForQuotes}
                                  onChange={
                                    this.handleChangePrintTotalForQuotes
                                  }
                                  name="total"
                                />
                              }
                              label={
                                isWithTotalForQuotes
                                  ? "With Totals"
                                  : "Without Totals"
                              }
                            />
                          </div>
                        </FormGroup>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() =>
                            this.handlePrintQuoteWithRate(
                              null,
                              isWithRateForQuotes,
                              isWithTotalForQuotes
                            )
                          }
                          variant="outlined"
                          color="primary"
                          fullWidth
                          label="Print"
                        />
                      </DialogActions>
                    </Dialog>
                  </>
                );
              }

              return (
                <Grid fluid justify="space-between">
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.handleNextStep}
                      fullWidth
                      label="Next Step"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {tenderClosingDateAction &&
                      tenderClosingDateAction.isDisplay && (
                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          onClick={this.handleTenderClosingDate}
                          disabled={tenderClosingDateAction.isDisabled}
                          label={`${tenderClosingDateAction.label}
                        ${
                          data[`${portfolioType.toLowerCase()}`] &&
                          data[`${portfolioType.toLowerCase()}`].tenderCloseDate
                            ? ` - ${
                                data[`${portfolioType.toLowerCase()}`]
                                  .tenderCloseDate
                              }`
                            : ""
                        }`}
                        />
                      )}
                  </Grid>
                  <Grid item xs={2}>
                    {portfolioType !== "Contents" && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={this.handlePrintSiteReport}
                        leadIcon={
                          !isLoadingForSite ? (
                            <PrintIcon />
                          ) : (
                            <MuiCircularProgress size={24} />
                          )
                        }
                        fullWidth
                        label="Site Report"
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {/* <Button
                      aria-controls="scopeOfWorksMenu"
                      aria-haspopup="true"
                      variant='outlined' color='primary'
                      onClick={isDisplay? this.handleMenu : this.handlePrintWorkScope}
                      leadIcon='print'
                      fullWidth
                      disabled={!jobQuoteId}
                      label={
                        portfolioType !== 'Contents'
                          ? 'Scope of works'
                          : 'Schedule of Loss'
                      }
                    /> */}
                    {scopeTypeButtonMenu}
                  </Grid>
                  <Grid item xs={2}>
                    {/* <Button
                      aria-controls="quotesMenu"
                      aria-haspopup="true"
                      variant='outlined' color='primary'
                      onClick={this.handleMenu}
                      leadIcon='print'
                      fullWidth label='Quotes'
                      disabled={!jobQuoteId}
                    /> */}
                    {quoteButtonMenu}
                  </Grid>
                </Grid>
              );
            }}
          />
        </Paper>
      </Formik>
    );
  }
  handleTenderClosingDate = () => {
    if (window[Symbol.for("__tenderClosingDateAction")]) {
      window[Symbol.for("__tenderClosingDateAction")].handleOpen();
    }
  };
  handleNextStep = () => {
    if (window[Symbol.for("__nextStepAction")]) {
      window[Symbol.for("__nextStepAction")].handleOpen();
    }
  };
  handlePrintSiteReport = async () => {
    this.setState({ isLoadingForSite: true });
    const res = await GetSiteReportPdfMutation({
      where: {
        id: this.props.claimId,
        portfolioType: this.props.portfolioType,
      },
    });

    if (res.ok) {
      this.setState({ isLoadingForSite: false });
      window.open(
        res.pdf,
        "Site Report Print Preview",
        "resizable, scrollbars, status"
      );
    }
  };
  handlePrintWorkScopeWithRate = (item, isWithRate, isWithTotal) => {
    this.handlePrintWorkScope(
      item && item.value ? item.value : null,
      isWithRate,
      isWithTotal
    );
    this.handleClose();
  };
  handlePrintWorkScope = async (item, isWithRate, isWithTotal) => {
    this.setState({ anchorEl: null });
    this.setState({ isLoadingForScope: true });
    const res = await GetQuotePdfMutation({
      where: {
        claimId: this.props.claimId,
        requestCategory: "Scoping",
        portfolios: this.props.portfolioType,
        scopeTypeId: item,
      },
      input: {
        rate: isWithRate,
        total: isWithTotal,
      },
    });

    if (res.ok) {
      this.setState({ isLoadingForScope: false });
      window.open(
        res.pdf,
        "Site Report Print Preview",
        "resizable, scrollbars, status"
      );
    }
  };
  handlePrintQuoteWithRate = (item, isWithRate, isWithTotal) => {
    this.handlePrintQuotes(
      item && item.value ? item.value : null,
      isWithRate,
      isWithTotal
    );
    this.handleClose();
  };
  handlePrintQuotes = async (item, isWithRate, isWithTotal) => {
    this.setState({ isLoadingForQuote: true });
    this.setState({ anchorElForQuote: null });
    const res = await GetQuotePdfMutation({
      where: {
        claimId: this.props.claimId,
        requestCategory: "DesktopQuoting",
        portfolios: this.props.portfolioType,
        scopeTypeId: item,
      },
      input: {
        rate: isWithRate,
        total: isWithTotal,
      },
    });

    if (res.ok) {
      this.setState({ isLoadingForQuote: false });
      window.open(
        res.pdf,
        "Site Report Print Preview",
        "resizable, scrollbars, status"
      );
    }
  };
}

export default withClaimMeta(QuotesActionPresentation);
