import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation AllocateScopeSupplierActionMutation($input: ReallocateScopingSupplierInput!, $where: ENDataPortfolioKey!) {
    claimReallocateScopingSupplier(input: $input, where: $where) {
      success
      messages
    }
  }
`

export default (variables, id) => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          resolve({
            ok: true,
            success: res.claimReallocateScopingSupplier.success,
            message: res.claimReallocateScopingSupplier.messages[0]
          })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}