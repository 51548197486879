import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'clsx'
import Icon from '@material-ui/core/Icon'
import IconButton from 'components/Mui/IconButton'

const ClaimTypeIcon = ({ classes, className, type, iconButton, size, ...props }) => {
  if(!type) return null
  const vehicleActions = window[Symbol.for('__vehicleActions')]
  const buildingIcon = vehicleActions.isVehicleReport?'drive_eta':'home'
  const icon =
    type === 'Building' ? buildingIcon:
    type === 'Contents' ? 'live_tv' :
    'healing'
  const buildingType = vehicleActions.isVehicleReport? 'Car' : 'Building'
  if(iconButton) return (
    <IconButton icon={icon} title={type==='Building'? buildingType:type} className={classNames(className, classes[type])} {...props} />
  )

  return (
    <Icon title={type==='Building'? buildingType:type} style={{fontSize: size}} className={classNames(className, classes[type])} {...props}>
      {icon}
    </Icon>
  )
}

const styles = theme => ({
  Building: {
    marginRight: '10px',
    color: '#0f5b78',
    fontSize: '1.2rem'
  },
  Contents: {
    marginRight: '10px',
    color: 'green',
    fontSize: '1.2rem'
  },
  Restoration: {
    marginRight: '10px',
    color: '#d0a11e',
    fontSize: '1.2rem'
  }
})

export default withStyles(styles)(ClaimTypeIcon)
