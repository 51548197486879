import React, { Component } from "react";
import environment from "common/relay";
import { graphql, QueryRenderer } from "react-relay";

import Dialog from "components/Mui/Dialog";
import Button from "components/Mui/Button";
// import Input from 'components/Mui/Input'
// import Form from 'components/Mui/Form'
import Grid from "components/Mui/Grid";
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon";
import FinaliseClaimActionMutation from "./FinaliseClaimActionMutation";
import ResetClaimActionMutation from "./ResetClaimActionMutation";

import { Formik, Form } from "formik";
import { withClaimMeta } from "pages/Claim/ClaimDetails/ClaimMetaContext";
import moment from "moment";

import Input from "components/Formik/Input";
import DateInput from "components/Formik/DateInput";
import MoneyInput from "components/Formik/MoneyInput";

import { clientNameRegexp } from "common/inputRegex";

const query = graphql`
  query FinaliseClaimActionQuery($claimJobWhere: ENDataEntityKey!) {
    claimFilterOptions(where: { subject: "finalisedReasons" }) {
      label: name
      value
    }
    claimJob(where: $claimJobWhere) {
      hasBuilding
      hasContents
      hasRestoration
      building {
        claimStatus {
          statusName
        }
        finaliseReason {
          name
        }
        dateCompleted
      }
      restoration {
        claimStatus {
          statusName
        }
        finaliseReason {
          name
        }
        dateCompleted
      }
      contents {
        claimStatus {
          statusName
        }
        finaliseReason {
          name
        }
        dateCompleted
      }
    }
  }
`;

class FinaliseClaimAction extends Component {
  snackbar = window[Symbol.for("__snackbar")];

  render() {
    const { symbol } = this.props;
    return (
      <Dialog
        symbol={symbol}
        noForm
        title="Finalise Claim"
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              claimJobWhere: {
                id: this.props.meta.claim.claimId,
              },
            }}
            render={({ error, props }) => {
              if (!props) return "Loading...";

              return (
                <Grid container justify="space-evenly" alignItems="stretch">
                  {[
                    {
                      unmountOn: !props.claimJob.hasBuilding,
                      type: "Building",
                    },
                    {
                      unmountOn: !props.claimJob.hasContents,
                      type: "Contents",
                    },
                    {
                      unmountOn: !props.claimJob.hasRestoration,
                      type: "Restoration",
                    },
                  ].map(({ unmountOn, type }, key) => {
                    const data =
                      type === "Building"
                        ? props.claimJob.building
                        : type === "Contents"
                        ? props.claimJob.contents
                        : type === "Restoration"
                        ? props.claimJob.restoration
                        : null;

                    if (!unmountOn)
                      return (
                        <Grid item xs key={key}>
                          {data.claimStatus.statusName !== "Claim Finalised" ? (
                            <Formik
                              initialValues={{
                                id: this.props.meta.claim.claimId,
                                portfolioType: type,
                                reason: "",
                                dateCompleted: null,
                                settlementValue: "",
                                noPanelSupplier: "",
                              }}
                              validate={(values) => {
                                let errors = {};

                                if (!values.reason) errors.reason = "Required!";
                                if (!values.dateCompleted)
                                  errors.dateCompleted = "Required!";
                                if (
                                  values.settlementValue === "" ||
                                  values.settlementValue < 0
                                )
                                  errors.settlementValue = "Required!";

                                if (
                                  values.noPanelSupplier &&
                                  clientNameRegexp.test(values.noPanelSupplier)
                                ) {
                                  errors.noPanelSupplier = "Invalid character";
                                }

                                return errors;
                              }}
                              onSubmit={(
                                {
                                  id,
                                  portfolioType,
                                  dateCompleted,
                                  settlementValue,
                                  reason,
                                  ...values
                                },
                                { setSubmitting }
                              ) => {
                                setTimeout(async () => {
                                  const variables = {
                                    input: {
                                      ...values,
                                      finaliseReasonId: parseInt(reason),
                                      settlementValue:
                                        parseFloat(settlementValue),
                                      dateCompleted: dateCompleted.format(
                                        "YYYY-MM-DDTHH:mm:ss"
                                      ),
                                    },
                                    where: { id, portfolioType },
                                  };

                                  const res = await FinaliseClaimActionMutation(
                                    variables
                                  );
                                  if (res.ok)
                                    this.snackbar.toggleOpen({
                                      message: res.message,
                                    });

                                  setSubmitting(false);
                                  const { headerReload, setHeaderReload } =
                                    window[
                                      Symbol.for("__SpecialistCardHeaderReload")
                                    ];
                                  await setHeaderReload(!headerReload);
                                }, 100);
                              }}
                            >
                              {({ values, isSubmitting }) => {
                                return (
                                  <Form>
                                    <Grid
                                      fluid
                                      spacing={8}
                                      justify="center"
                                      alignItems="center"
                                    >
                                      <ClaimTypeIcon
                                        style={{ fontSize: "2.5rem" }}
                                        type={type}
                                      />
                                      <Grid item xs={12}>
                                        <Input
                                          label="Reason"
                                          name="reason"
                                          select
                                          required
                                          options={props.claimFilterOptions}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Input
                                          label="Non Panel Provider info"
                                          name="noPanelSupplier"
                                          placeholder="if applicable"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <DateInput
                                          required
                                          label="Job Completion Date"
                                          name="dateCompleted"
                                          maxDate={moment()}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <MoneyInput
                                          //required
                                          label="Settlement Value"
                                          name="settlementValue"
                                          fixedDecimal={false}
                                          decimalScale={0}
                                          format="#########"
                                        />
                                      </Grid>
                                      <Button
                                        fullWidth
                                        disabled={isSubmitting}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        label="Finalise"
                                      />
                                    </Grid>
                                  </Form>
                                );
                              }}
                            </Formik>
                          ) : (
                            <Grid
                              fluid
                              spacing={8}
                              justify="center"
                              alignItems="stretch"
                              style={{ height: "100%" }}
                            >
                              <ClaimTypeIcon
                                style={{ fontSize: "2.5rem" }}
                                type={type}
                              />
                              {/* {console.log(data.dateCompleted)} */}
                              <Grid
                                item
                                xs={12}
                                style={{ textAlign: "center" }}
                              >
                                <h4>
                                  {data.finaliseReason &&
                                    data.finaliseReason.name}
                                </h4>
                                <p>
                                  {moment(
                                    data.dateCompleted,
                                    "YYYY/MM/DD"
                                  ).format("DD/MM/YYYY")}
                                </p>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  label="Reset Claim Finalised"
                                  onClick={() => this.resetFinalised(type)}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      );
                    return null;
                  })}
                </Grid>
              );
            }}
          />
        }
      />
    );
  }

  resetFinalised = async (portfolioType) => {
    await ResetClaimActionMutation({
      where: {
        id: this.props.meta.claim.claimId,
        portfolioType,
      },
    });
    const { headerReload, setHeaderReload } =
      window[Symbol.for("__SpecialistCardHeaderReload")];
    await setHeaderReload(!headerReload);
  };
}

export default withClaimMeta(FinaliseClaimAction);

// <Form onSubmit={e => this.handleSubmit({...e, portfolioType: type})}>
//   <Grid fluid spacing={8} justify='center' alignItems='center'>
//     <ClaimTypeIcon style={{ fontSize: '2.5rem' }} type={type} />
//     <Grid item xs={12}>
//       <Input select required label='Reason' name='reason'
//         options={props.claimFilterOptions.map(({name, value}) => ({label: name, value}))}
//       />
//     </Grid>
//     <Grid item xs={12}>
//       <Input required type='date' leadIcon='event' label='Job Completion Date' name='dateCompleted'/>
//     </Grid>
//     <Grid item xs={12}>
//       <Input required type='money' leadIcon='attach_money' label='Settlement Value' name='settlementValue'/>
//     </Grid>
//     <Button fullWidth variant='contained' type='submit' color='primary' label='Finalise'/>
//   </Grid>
// </Form>
