import React, { Component } from 'react'
import Grid from 'components/Mui/Grid'
import InfoBox from 'components/Mui/InfoBox'

class ClaimItemExpand extends Component {
  render() {
    const { claim, admin, contents } = this.props.data
    return (
      <Grid container>
        {[
          { col: 3, label: 'Ins Ref #', text: claim.refNumber },
          { col: 3, label: 'Customer' /* label:'Client'|'Customer' */, text: claim.insured.name },
          { col: 3, label: 'Phone(s)', text: this.getPhone(claim.insured) },
          { col: 3, label: 'Customer Email' /* label:'Email'|'Customer Email' */, text: claim.insured.email || '-' },
          { col: 3, label: 'Incident Date', text: claim.incidentDetail.incidentDate },
          { col: 3, label: 'Property/Risk Address' /* label:'Risk Address'|'Property/Risk Address' */, text: this.getAddress(claim.incidentDetail.riskAddress) },
          { col: 3, label: 'Builder', text: this.getBuilder(this.props.data), unMount: contents },
          { col: 3, label: 'Restorer', text: this.getRestorer(admin), unMount: !admin },
        ].map((row, index) => (!row.unMount && 
          <Grid item xs={row.col} key={index}>
            <InfoBox label={row.label} text={row.text} />
          </Grid>  
        ))}
      </Grid>  
    )
  }

  getPhone = ({ phone1, phone2, phone3 }) => `${phone1}${phone2 ? `, ${phone2}` : ''}${phone3 ? `, ${phone3}` : ''}`
  getAddress = ({ line1, suburb, state, postcode }) => `${line1}, ${suburb} ${state} ${postcode}`
  getBuilder = ({ admin, builder, restorer }) => {
    if(admin && admin.building) {
      if(admin.building.authorisedSupplier) return admin.building.authorisedSupplier.companyName
      if(admin.building.scopingSupplier) return admin.building.scopingSupplier.companyName
    }
    if(builder && builder.restoration) {
      if(builder.restoration.authorisedSupplier) return builder.restoration.authorisedSupplier.companyName
      if(builder.restoration.scopingSupplier) return builder.restoration.scopingSupplier.companyName
    }
    if(restorer && restorer.building) {
      if(restorer.building.authorisedSupplier) return restorer.building.authorisedSupplier.companyName
      if(restorer.building.scopingSupplier) return restorer.building.scopingSupplier.companyName
    }

    return '-'
  }
  getRestorer = admin => {
    if(admin && admin.restoration) {
      const { authorisedSupplier, scopingSupplier } = admin.restoration
      if(authorisedSupplier) return authorisedSupplier.companyName
      if(scopingSupplier) return scopingSupplier.companyName
    }

    return '-'
  }
}

export default ClaimItemExpand