import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation AddClaimByTemplateMutation($input: ClaimJobTemplateCreate!, $templateId: ID!) {
    createClaimJobByTemplate(input: $input, templateId: $templateId) {
      success
      messages
      fieldErrors {
        fieldName
        level
        message
      }
      result{
        claimId
      }
    }
  }
`

export default (input, templateId) => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables: { input, templateId },
        onCompleted: res => {
          const { messages, fieldErrors, result, success } = res.createClaimJobByTemplate
          resolve({ ok: true, messages, fieldErrors, result, success })
        },
        onError: errors => {
          resolve({
            ok: false,
            messages: ['Unable to add claim! Please try again.']
          })
        }
      }
    )
  })
}
