import React, { Component } from 'react'
import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import Button from 'components/Mui/Button'

import AddVariation from './AddVariation'

class VariationAction extends Component {
  addVariationRef = React.createRef()

  render() {
    const { portfolios } = this.props
    const { user, claim } = this.props.meta

    return (
      <Paper>
        <Grid fluid justify='space-between'>
          <Grid item xs={2}>
            <Button
              variant='outlined' color='primary'
              fullWidth label='Next Step'
              onClick={this.handleNextStep}
            />
          </Grid>
          <Grid item xs={2}></Grid>

          <Grid item xs={2}>
            {(([
              user.type.isBuilder
                && user.company.companyId === (claim.building.authorisedSupplier
                  && claim.building.authorisedSupplier.companyId),
              user.type.isRestorer
                && user.company.companyId === (claim.restoration.authorisedSupplier
                  && claim.restoration.authorisedSupplier.companyId)
            ].some(x => x === true) &&
            (
              (user.type.isBuilder && portfolios === 'Building')
              || (user.type.isRestorer && portfolios === 'Restoration')
            ))
            || (user.type.isAdmin && claim.insurer.nonPanelSupplier))
            && (
              <Button
                variant='outlined' color='primary'
                fullWidth label='Add Variation'
                onClick={this.handleAddVariation}
              />  
            )}
          </Grid>
        </Grid>

        <AddVariation dlRef={this.addVariationRef}
          claimJobId={claim.claimId}
          portfolioType={portfolios}
        />
      </Paper>  
    )
  }
  handleAddVariation = () => {
    this.addVariationRef.current.handleOpen()
  }

  handleNextStep = () => {
    if(window[Symbol.for('__nextStepAction')]){
      window[Symbol.for('__nextStepAction')].handleOpen()
    }
  }
}

export default VariationAction