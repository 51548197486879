/**
 * @flow
 * @relayHash c9c230e336ca36194c65b9d8496bcfd6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeeItemEditInput = {|
  authorisedAmount: number
|};
export type FeeItemUniqueWhere = {|
  feeItemId: number
|};
export type FeeItemEditDialogMutationVariables = {|
  input: FeeItemEditInput,
  where: FeeItemUniqueWhere,
|};
export type FeeItemEditDialogMutationResponse = {|
  +feeItemEdit: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type FeeItemEditDialogMutation = {|
  variables: FeeItemEditDialogMutationVariables,
  response: FeeItemEditDialogMutationResponse,
|};
*/


/*
mutation FeeItemEditDialogMutation(
  $input: FeeItemEditInput!
  $where: FeeItemUniqueWhere!
) {
  feeItemEdit(input: $input, where: $where) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "FeeItemEditInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "FeeItemUniqueWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "feeItemEdit",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "FeeItemEditInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "FeeItemUniqueWhere!"
      }
    ],
    "concreteType": "FeeItemPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeItemEditDialogMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeItemEditDialogMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "FeeItemEditDialogMutation",
    "id": null,
    "text": "mutation FeeItemEditDialogMutation(\n  $input: FeeItemEditInput!\n  $where: FeeItemUniqueWhere!\n) {\n  feeItemEdit(input: $input, where: $where) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c53ed061a3ce3a836673a3987d6d6720';
module.exports = node;
