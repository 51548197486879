import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
// import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation VariationAddLineItemMutation($input: JobVariationItemsCreate!) {
    createJobVariationItems(input: $input) {
      messages
      success
      result {
        id
        variationId
        variationItemId
        
        location {
          costLocationId
          locationName
        } dimensions

        costCentre { costCentreName costCentreId }
        costItem { itemDescription costItemId } lineDescription
        unit { unitName unitId }
        qty
        rate
        marginRate
        subtotal
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
//       updater: store => {
//         const payload = store.getRootField('createJobVariationItems')
// 
//         if(payload.getValue('success')) {
//           const root = store.getRoot()
//           const conn = ConnectionHandler.getConnection(root, 'VariationDetailsQuery_jobVariations')
//           const node = conn.getLinkedRecords('edges')[0].getLinkedRecord('node')
//           
//           const items = node.getLinkedRecords('lineItems')
// 
//           const newItem = payload.getLinkedRecords('result')
//           const newItems = [ ...items, ...newItem ]
// 
//           node.setLinkedRecords(newItems, 'lineItems')
//         }
//       },
      onCompleted: res => {
        const { success, messages } = res.createJobVariationItems
        resolve({ ok: true, success, messages })
      },
      onError: errors => resolve({ ok: false, messages: ['Unable to complete the action! Please try again.'] })
    })
  })
}