/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CallSessionUserType = "GUEST" | "OWNER" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RoomListRoot_room$ref: FragmentReference;
export type RoomListRoot_room = {|
  +rooms: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +createdAt: any,
        +case: ?{|
          +id: string,
          +referenceNumber: string,
          +name: ?string,
          +address: ?string,
        |},
        +isDeleted: boolean,
        +callSessions: $ReadOnlyArray<{|
          +userType: ?CallSessionUserType,
          +contact: ?{|
            +email: ?string,
            +phone: ?string,
          |},
        |}>,
        +videos: ?$ReadOnlyArray<{|
          +id: string,
          +url: string,
          +name: ?string,
        |}>,
        +photos: ?$ReadOnlyArray<{|
          +id: string,
          +url: string,
          +name: ?string,
        |}>,
      |}
    |}>
  |},
  +$refType: RoomListRoot_room$ref,
|};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "isDeleted",
    "value": false,
    "type": "Boolean"
  }
],
v3 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  },
  (v1/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "RoomListRoot_room",
  "type": "Account",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "rooms"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 20
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "where",
      "type": "RoomWhereInput",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "rooms",
      "name": "__RoomListRoot_rooms_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where",
          "type": "RoomWhereInput"
        }
      ],
      "concreteType": "RoomConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RoomEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Room",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "case",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Case",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "referenceNumber",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "address",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "isDeleted",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "callSessions",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CallSession",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "userType",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "contact",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CallSessionContact",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "email",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "phone",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "videos",
                  "storageKey": "videos(isDeleted:false)",
                  "args": (v2/*: any*/),
                  "concreteType": "File",
                  "plural": true,
                  "selections": (v3/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "photos",
                  "storageKey": "photos(isDeleted:false)",
                  "args": (v2/*: any*/),
                  "concreteType": "File",
                  "plural": true,
                  "selections": (v3/*: any*/)
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '393d0911f37fb3f63f1a53f3625d77d6';
module.exports = node;
