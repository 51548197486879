import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import Grid from 'components/Mui/Grid'
import { moneyFormat } from 'common/utils'
import spacer from 'common/utils/spacer'
import IconButton from 'components/Mui/IconButton'

const ListItemListReport = ({ classes, report, deliveryChargeUsed, space, info }) => {
  return (
    <Grid fluid spacing={0} className={classes.row}>
      {info && React.cloneElement(info)}
      {Object.entries(report).map((item, index) => (
        <Grid item xs/* ={3} */ className={classes.rowBlock} key={index}>
          <span className={classes.rowContent}>
            <span className={classes.title}>
              {space === true ? spacer(item[0]) : item[0]}
              {item[0] === 'delivery charges' && deliveryChargeUsed && (
                <Icon style={{ color: 'green'}}>
                  done_all
                </Icon>
              )}
              :
            </span>
            <span className={classes.value}>
              {moneyFormat(item[1])}
            </span>
          </span>
        </Grid>
      ))}
    </Grid>
  )
}

export default withStyles(theme => ({
  row: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    background: theme.palette.grey[400],
    borderRadius: 8,
    alignItems: "center"
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: 600
  },
  value: {
    marginLeft: '1rem'
  },
  rowBlock: {
    display: 'flex'
  },
  rowContent: {
    margin: '0 auto'
  }
}))(ListItemListReport)
