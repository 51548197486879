import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'

import classNames from 'clsx'
import { moneyFormat } from 'common/utils'
import useContentRow from './useContentRow'
import ClaimMetaContext from 'pages/Claim/ClaimDetails/ClaimMetaContext'

import Button from 'components/Mui/Button'
import ResetScopeMutation from '../QuotesAction/ResetScopeMutation'
import AcceptContentItem from './AcceptContentItem'

import UnauthorisedContentItemMutation from './UnauthorisedContentItem/UnauthorisedContentItemMutation'

const QuotesContentPresentation = ({ classes, quotes, portfolioType }) => {
  const [ acceptingQuoteId, setAcceptingQuoteId ] = React.useState(null)
  const snackbar = window[Symbol.for('__snackbar')]
  const __refreshQuotes = window[Symbol.for('__refreshQuotes')]
  const meta = React.useContext(ClaimMetaContext)

  const rows = useContentRow(quotes)

  const resetScopeHandle = async jobQuoteId => {
    const confirm = await snackbar.toggleOpen({
      message: 'Do you want to reset the scope?',
      firstButton: 'Yes',
      secondButton: 'No',
      type: 'confirm',
    })

    if (confirm.ok) {
      const res = await ResetScopeMutation({
        where: {
          id: jobQuoteId,
          portfolioType
        }
      })
      if(res.success) {
        snackbar.toggleOpen({
          message: 'Scope is reset',
        })
        __refreshQuotes.handleRefresh()
      }
      else {
        snackbar.toggleOpen({
          message: 'Something is wrong, please try again',
        })
      }
    }
  }
  const acceptQuoteItems = jobQuoteId => {
    setAcceptingQuoteId(jobQuoteId)
    // acceptDlRef.current.handleOpen()
    const acceptDialog = window[Symbol.for('__AcceptContentItem')]
    if(acceptDialog) acceptDialog.handleOpen()
  }

  const removeAuthority = async itemId => {
    const variables = {
      where: {
        id: meta.claim.claimId
      },
      input: {
        items: [ itemId ]
      }
    }

    const confirm = await snackbar.toggleOpen({
      type: 'confirm',
      message: 'Before cancelling, check with the Supplier that the item has not been processed.',
      firstButton: 'Accept',
      secondButton: 'Cancel'
    })

    if(confirm.ok) {
      const res = await UnauthorisedContentItemMutation(variables)
      if(res) {
        snackbar.toggleOpen({
          message: res.messages[0],
        })

        if(res.success) {
          __refreshQuotes.handleRefresh()
        }
      }
    }
  }
  
  return (
    <div className={classes.wrapper}>
      <AcceptContentItem
        id={acceptingQuoteId}
        portfolioType={portfolioType}
      />
      <Table>
        <TableHead>
          <TableRow className={classes.tableHead}>
            <TableCell align='center' className={classNames(classes.headCell, classes.firstCell)}>
              Cost Centre Item Description
            </TableCell>
            <TableCell align='center' className={classes.headCell}>
              Item Details
            </TableCell>
            <TableCell align='center' colSpan={quotes.length - 1} className={classNames(classes.headCell, classes.lastCell)}>
              Supplier Quotes
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            // empty row
            if(row.type === null) return null
            if(row.type === 'title') return (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}></TableCell>
                {row.payload.map((column, cIndex) => (
                  <Tooltip title={column.phone} key={cIndex}>
                    <TableCell key={cIndex} className={classNames(
                      classes.tableCell, classes.titleCell 
                    )}>
                      {column.text}
                    </TableCell>
                  </Tooltip>
                ))}
              </TableRow>
            )
            if(row.type === 'subtitle') return (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}></TableCell>
                {row.payload.map((column, cIndex) => (
                  <TableCell key={cIndex} className={classNames(
                    classes.tableCell, classes.subtitleCell
                  )}>
                    <Grid fluid spacing={0}>
                       {/* {column.text} */}
                      {cIndex === 0 && (
                        <>
                          <Grid item xs>Reserve</Grid>
                          <Grid item xs={3}>Qty</Grid>
                          <Grid item xs={3}>Limit</Grid>
                        </>
                      )}
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            )

            if(row.type === 'item') return (
              <TableRow key={index}>
                <TableCell className={classNames(classes.tableCell, classes.itemLabelCell)}>
                  {row.label}
                </TableCell>
                {row.payload.map((column, cIndex) => {
                  if(column.type === null) return (
                    <TableCell className={classNames(classes.tableCell, classes.emptyCell)}>

                    </TableCell>
                  ) 

                  return (
                    <Tooltip key={cIndex} title={
                      column.authorised
                        ? 'If you wish to remove the authority for this item, please click on the green tick and follow the prompt.'
                        : ''  
                    }>
                      <TableCell className={classes.tableCell}>
                        <Grid fluid spacing={0}>
                          <Grid item xs>
                            {column.text && moneyFormat(column.text)}
                            {column.authorised && (
                              <Icon
                                style={{ color: 'green', fontSize: '1rem' }}
                                onClick={() => removeAuthority(column.itemId)}
                              >
                                done
                              </Icon>  
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            {column.qty}
                          </Grid>
                          <Grid item xs={3}>
                            {column.policy}
                          </Grid>
                          {cIndex !== 0 && column.substitute && (
                            <Tooltip title={column.substitute}>
                              <Icon color='primary'>info</Icon>
                            </Tooltip> 
                          )}
                        </Grid>
                      </TableCell>
                    </Tooltip> 
                  ) 
                })}
              </TableRow>
            )
            if(row.type === 'report') return (
              <TableRow key={index}>
                <TableCell className={classNames(classes.tableCell, classes.reportLabelCell)}>
                  {row.label}
                </TableCell>
                {row.payload.map((column, cIndex) => (
                  <TableCell key={cIndex} className={classNames(classes.tableCell, classes.reportLabelCell)}>
                    {column.text && moneyFormat(column.text)}
                    {column.completeRule && <Icon style={{ color: 'green' }}>done_all</Icon>}
                  </TableCell>
                ))}
              </TableRow>
            )
            if(row.type === 'action') return (
              <TableRow key={index}>
                <TableCell className={classNames(classes.tableCell, classes.reportLabelCell)}>
                  {row.label}
                </TableCell>
                {row.payload.map((column, cIndex) => (
                  <TableCell key={cIndex} className={classes.tableCell}>
                    {column.completed 
                      ? (
                        <>
                          {cIndex !== 0 && (
                            <Tooltip title='Allows Supplier to requote unauthorised items'>
                              <Button label='Reset' color='primary'
                                onClick={() => resetScopeHandle(column.jobQuoteId)}
                              />
                            </Tooltip>
                          )}
                          <Button label={cIndex === 0
                            ? 'Cash settle'
                            : 'Accept'
                          } 
                            color='primary' variant='contained'
                            onClick={() => acceptQuoteItems(column.jobQuoteId)}
                          />
                        </>
                      )
                      : (
                        <strong>
                          {cIndex !== 0 && column.status}
                        </strong>  
                      )
                    }
                  </TableCell>
                ))}
              </TableRow>
            )
            return null
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default withStyles(theme => ({
  wrapper: {
    overflowX: 'auto'
  },
  tableHead: {
    height: 34
  },
  headCell: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: '1rem',
    fontWeight: 800,
    borderRight: '1px solid #fff'
  },
  firstCell: {
    borderRadius: '8px 0 0 0'
  },
  lastCell: {
    borderRadius: '0 8px 0 0',
    borderRight: 0
  },
  tableCell: {
    minWidth: 200,
    borderLeft: '1px solid #ececec'
  },
  emptyCell: {
    background: theme.palette.grey[300]
  },
  titleCell: {
    fontWeight: 600
  },
  subtitleCell: {
    fontWeight: 400,
    color: theme.palette.grey[600]
  },
  itemLabelCell: {
    fontStyle: 'italic'
  },
  reportLabelCell: {
    fontWeight: 600
  }
}))(QuotesContentPresentation)