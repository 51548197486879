/**
 * @flow
 * @relayHash 3fd0fad329e77cb1b38f43536907eaea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type AcceptOfferMutationVariables = {|
  where: ENDataPortfolioKey
|};
export type AcceptOfferMutationResponse = {|
  +claimAcceptRequest: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +jobSuppliers: ?$ReadOnlyArray<?{|
        +quote: ?{|
          +id: string,
          +quoteStatus: ?{|
            +statusName: ?string
          |},
          +supplier: ?{|
            +companyName: string
          |},
        |}
      |}>
    |},
  |}
|};
export type AcceptOfferMutation = {|
  variables: AcceptOfferMutationVariables,
  response: AcceptOfferMutationResponse,
|};
*/


/*
mutation AcceptOfferMutation(
  $where: ENDataPortfolioKey!
) {
  claimAcceptRequest(where: $where) {
    messages
    success
    result {
      jobSuppliers {
        quote {
          id
          quoteStatus: quoteJobStatus {
            statusName
            id
          }
          supplier {
            companyName
            id
          }
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AcceptOfferMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimAcceptRequest",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "jobSuppliers",
                "storageKey": null,
                "args": null,
                "concreteType": "JobSupplier",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "quote",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobQuote",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": "quoteStatus",
                        "name": "quoteJobStatus",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ClaimStatus",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "supplier",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Company",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AcceptOfferMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimAcceptRequest",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimPortfolioPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimPortfolio",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "jobSuppliers",
                "storageKey": null,
                "args": null,
                "concreteType": "JobSupplier",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "quote",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobQuote",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": "quoteStatus",
                        "name": "quoteJobStatus",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ClaimStatus",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v4/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "supplier",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Company",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v4/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v4/*: any*/)
                ]
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AcceptOfferMutation",
    "id": null,
    "text": "mutation AcceptOfferMutation(\n  $where: ENDataPortfolioKey!\n) {\n  claimAcceptRequest(where: $where) {\n    messages\n    success\n    result {\n      jobSuppliers {\n        quote {\n          id\n          quoteStatus: quoteJobStatus {\n            statusName\n            id\n          }\n          supplier {\n            companyName\n            id\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b17b99586b1ad12115f12e1fb3107098';
module.exports = node;
