/**
 * @flow
 * @relayHash 004e83835944a05b2c877dae83084412
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimDocumentCreate = {|
  claimId: string,
  description: string,
  documentFileName: string,
  fileBase64: string,
  fileId?: ?string,
  isInvoice: boolean,
  invoiceNumber?: ?string,
  amountInvoice?: ?number,
  private: boolean,
  visibleByInsurer?: ?boolean,
  portfolioType?: ?PortfolioType,
|};
export type CreateDocumentsActionMutationVariables = {|
  input: ClaimDocumentCreate
|};
export type CreateDocumentsActionMutationResponse = {|
  +createClaimDocument: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +id: string,
      +documentId: number,
      +portfolioType: ?PortfolioType,
      +uploadDate: ?any,
      +company: ?{|
        +companyName: string
      |},
      +url: string,
      +private: boolean,
      +description: string,
      +amountInvoice: ?number,
    |},
  |}
|};
export type CreateDocumentsActionMutation = {|
  variables: CreateDocumentsActionMutationVariables,
  response: CreateDocumentsActionMutationResponse,
|};
*/


/*
mutation CreateDocumentsActionMutation(
  $input: ClaimDocumentCreate!
) {
  createClaimDocument(input: $input) {
    success
    messages
    result {
      id
      documentId
      portfolioType
      uploadDate
      company {
        companyName
        id
      }
      url
      private
      description
      amountInvoice
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimDocumentCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ClaimDocumentCreate!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "documentId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "portfolioType",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uploadDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "private",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amountInvoice",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateDocumentsActionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimDocument",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimDocumentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDocument",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "company",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ]
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateDocumentsActionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimDocument",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimDocumentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDocument",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "company",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateDocumentsActionMutation",
    "id": null,
    "text": "mutation CreateDocumentsActionMutation(\n  $input: ClaimDocumentCreate!\n) {\n  createClaimDocument(input: $input) {\n    success\n    messages\n    result {\n      id\n      documentId\n      portfolioType\n      uploadDate\n      company {\n        companyName\n        id\n      }\n      url\n      private\n      description\n      amountInvoice\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b9a51c83cba33ecae171e73ae0cfea06';
module.exports = node;
