import React from 'react'
import Input from 'components/Formik/Input'
import MoneyInput from 'components/Formik/MoneyInput'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'
import { MultiReactSelect } from 'components/Formik/ReactSelect'
import { Form } from 'formik'

export default ({
  isSubmitting,
  values,
  touched,
  setFieldValue,
  inputRef,
  props,
  symbol,
}) => {

  React.useEffect(() => {
    setFieldValue('invoiceAmount', values.feeItemIds ? values.feeItemIds.reduce((total, current) => {
      return total + parseFloat(current.split('_')[1])
    }, 0) : 0)
  }, [values])

  return (
    <Form>
      <Grid fluid justify='flex-end'>
        {[
          {
            col: 12, label: 'Fee Type',
            disabled: isSubmitting,
            component: MultiReactSelect, name: 'feeItemIds',
            options: props.feeItems.map(e => ({
              label: `${e.label} - $${e.toBeInvoicedAmount}`,
              value: `${e.value}_${e.toBeInvoicedAmount}`
            })),
            unmountOn: false,
          },
          {
            col: 4, label: 'File to Upload',
            component: Input, name: 'file',
            inputRef,
            leadIcon: 'attach_file',
            type: 'file', required: true,
          },
          {
            col: 4, label: 'Amount of Invoice',
            component: MoneyInput, name: 'invoiceAmount',
            isDisabled: true,
            required: true,
            unmountOn: false
          },
          {
            col: 4, label: 'Invoice number',
            component: Input, name: 'invoiceNumber',
            required: true,
            unmountOn: false
          },
          {
            col: 12, label: 'File Description',
            component: Input, name: 'description',
            type: 'text', multiline: true,
            rowsMax: 5, rows: 3,
            required: true,
          },
        ].map(({col, component: Component, unmountOn, readOnly=false, ...rest}, key) => (
          !unmountOn && <Grid item xs={col} key={key}>
            {Component && <Component readOnly={readOnly} {...rest}/>}
          </Grid>
        ))}
        <Grid xs={1}>
          <Button fullWidth label='Close'
            disabled={isSubmitting}
            onClick={() => {
              if(window[Symbol.for(symbol)]){
                window[Symbol.for(symbol)].handleClose()
              }
            }}
          />
        </Grid>
        <Grid xs={1}>
          <Button
            fullWidth
            disabled={isSubmitting}
            label='Save'
            type='submit'
            variant='contained'
            color='primary'
          />
        </Grid>
      </Grid>
    </Form>
  )
}