import React from 'react'

export default quote => {
  const [ groups, setGroups ] = React.useState([])
  const [ report, setReport ] = React.useState(null)

  React.useEffect(() => {
    if(quote) {
      const groups = mapDataToGroup(quote.lineItems)

      setGroups(groups)
      setReport({
        subtotal: quote.subtotal || 0,
        gst: quote.gst || 0,
        margin: quote.margin || 0,
        total: quote.total || 0
      })
    }
  }, [ quote && quote.lineItems.length ])

  return [ groups, report ]
}

const mapDataToGroup = d => Object.values(d.reduce((total, current) => {
  return ({
    ...total,
    [current.location.costLocationId]:
      total[current.location.costLocationId]
        ? {
          ...total[current.location.costLocationId],
          items: [
            ...total[current.location.costLocationId].items,
            current
          ]
        }
        : {
          name: current.location.locationName,
          items: [ current ]
        }
  })
}, {}))