/**
 * @flow
 * @relayHash 6f9e9225fb6a753e935152dbf8f9de8b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimStatusWhere = {|
  claimId?: ?string,
  forLossAdjuster?: ?boolean,
  workInProgress?: ?boolean,
  portfolioTypes?: ?$ReadOnlyArray<?PortfolioType>,
|};
export type LossAdjusterStatusQueryVariables = {|
  lossAdjusterWhere?: ?ClaimStatusWhere
|};
export type LossAdjusterStatusQueryResponse = {|
  +claimStatus: ?$ReadOnlyArray<?{|
    +id: string,
    +value: number,
    +label: string,
  |}>
|};
export type LossAdjusterStatusQuery = {|
  variables: LossAdjusterStatusQueryVariables,
  response: LossAdjusterStatusQueryResponse,
|};
*/


/*
query LossAdjusterStatusQuery(
  $lossAdjusterWhere: ClaimStatusWhere
) {
  claimStatus(where: $lossAdjusterWhere) {
    id
    value: statusId
    label: statusName
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "lossAdjusterWhere",
    "type": "ClaimStatusWhere",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimStatus",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "lossAdjusterWhere",
        "type": "ClaimStatusWhere"
      }
    ],
    "concreteType": "ClaimJobStatusInfo",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": "value",
        "name": "statusId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": "label",
        "name": "statusName",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LossAdjusterStatusQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "LossAdjusterStatusQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "LossAdjusterStatusQuery",
    "id": null,
    "text": "query LossAdjusterStatusQuery(\n  $lossAdjusterWhere: ClaimStatusWhere\n) {\n  claimStatus(where: $lossAdjusterWhere) {\n    id\n    value: statusId\n    label: statusName\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7310c7c98e16e546b4a29d3500e17b59';
module.exports = node;
