/**
 * @flow
 * @relayHash 335b5055bd6d0c490e51b079f69ad24f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobVariationIndex = {|
  id?: ?$ReadOnlyArray<number>,
  claimId?: ?string,
  includeValidationItems?: ?boolean,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type VariationValidationDialogFooterQueryVariables = {|
  where: JobVariationIndex
|};
export type VariationValidationDialogFooterQueryResponse = {|
  +jobVariations: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +total: ?number,
        +validationTotal: ?number,
        +validationSavings: ?number,
        +validationSavingPercentage: ?string,
        +variationId: number,
      |}
    |}>
  |}
|};
export type VariationValidationDialogFooterQuery = {|
  variables: VariationValidationDialogFooterQueryVariables,
  response: VariationValidationDialogFooterQueryResponse,
|};
*/


/*
query VariationValidationDialogFooterQuery(
  $where: JobVariationIndex!
) {
  jobVariations(where: $where) {
    edges {
      node {
        total
        validationTotal
        validationSavings
        validationSavingPercentage
        variationId
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JobVariationIndex!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "JobVariationIndex!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "validationTotal",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "validationSavings",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "validationSavingPercentage",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VariationValidationDialogFooterQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobVariations",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobVariationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobVariation",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VariationValidationDialogFooterQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobVariations",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobVariationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobVariation",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "VariationValidationDialogFooterQuery",
    "id": null,
    "text": "query VariationValidationDialogFooterQuery(\n  $where: JobVariationIndex!\n) {\n  jobVariations(where: $where) {\n    edges {\n      node {\n        total\n        validationTotal\n        validationSavings\n        validationSavingPercentage\n        variationId\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c347e1369c4122b5a1d2c5e7c38bce9';
module.exports = node;
