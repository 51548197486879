import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation RequestCostedScopeMutation($where: ENDataPortfolioKey!, $input: CostedScopeInput) {
    claimRequestCostedScope(where: $where, input: $input) {
      messages
      success
      result {
        insuranceCompletedSiteReport
        scopingSupplier {
          companyName
        }
      }
    }
  }
`

export default (variables, id, claimId, setIsdisplay) => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          resolve({ ok: true, ...res.claimRequestCostedScope })
          setIsdisplay(true)
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}