import React from 'react'

import { useQuery, graphql } from 'relay-hooks'
import Grid from 'components/Mui/Grid'
const imageFormats = ['jpg', 'png', 'jpeg', 'gif']

export default ({ claimId, portfolios }) => {
  const { props, error } = useQuery({
    query: graphql`
      query PhotoViewAllBodyQuery($where: ClaimItemFilter) {
        claimDocuments(where: $where) {
          edges {
            node {
              id
              url
              description
            }
          }
        }
      }
    `,
    variables: {
      where: {
        claimId,
        portfolios: portfolios || undefined
      }
    }
  })

  if (!props && !error) return 'Loading...'
  if (error) throw new Error(error)

  const photos = props.claimDocuments.edges
    .map(({ node }) => node)
    .filter(({ url }) => {
      const ext = url.substring(url.lastIndexOf('.') + 1, url.length).toLowerCase()
      return imageFormats.indexOf(ext) !== -1
    })
  if(!photos || !photos.length) return 'No photo'

  return (
    <Grid fluid>
      {photos.map(({ id, url, description }) => (
        <Grid key={id} item xs={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div>
            <img
              src={url}
              alt='ENData Claims'
              style={{ maxWidth: '100%', maxHeight: '20rem' }}
            />
          </div>
          <p>{description}</p>
        </Grid>
      ))}
    </Grid>
  )
}