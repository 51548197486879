/**
 * @flow
 * @relayHash 1ffe806cda72c2d6585e884cdd9c3613
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type DeleteDocumentMutationVariables = {|
  where: ENDataPortfolioKey
|};
export type DeleteDocumentMutationResponse = {|
  +deleteClaimDocument: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type DeleteDocumentMutation = {|
  variables: DeleteDocumentMutationVariables,
  response: DeleteDocumentMutationResponse,
|};
*/


/*
mutation DeleteDocumentMutation(
  $where: ENDataPortfolioKey!
) {
  deleteClaimDocument(input: $where) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteClaimDocument",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "where",
        "type": "ENDataPortfolioKey!"
      }
    ],
    "concreteType": "GeneralPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteDocumentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteDocumentMutation",
    "id": null,
    "text": "mutation DeleteDocumentMutation(\n  $where: ENDataPortfolioKey!\n) {\n  deleteClaimDocument(input: $where) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1bdb024ce3425786dc94d85372dc0ddd';
module.exports = node;
