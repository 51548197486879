import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation UpdateScopeTypeMutation($input: QuoteItemScopeTypeUpdate! $where: ENDataPortfolioKey!) {
    updateJobQuoteItemScopeType(input: $input where: $where){
      messages
      success
      result {
          id
          scopeTypes(processed: false) {
            id
            subtotal
            total
            gst
            margin
            discount
            locations {
              id
              location {
                id
                costLocationId
                locationName
              }
              items {
                jobQuoteItemId
                location { costLocationId locationName } dimensions
                costCentre { costCentreId costCentreName }
                costItem { costItemId itemDescription } lineDescription
                unit { unitId unitName }
                qty
                rate
                marginRate
                subtotal
                scopeType {
                  name
                  id
                }
              }
            }
            scopeType {
              id
              name
            }
            id
            fields {
            discount {
              isDisplay
              label
              isDisabled
            }
            gst {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            total {
              label
              isDisplay
            }
            action {
              label
              isDisplay
            }
            costCentre {
              label
              isDisplay
            }
            description {
              label
              isDisplay
            }
            marginRate {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            qty {
              label
              isDisplay
            }
            rate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            unit {
              label
              isDisplay
            }
          }
        }
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: ({updateJobQuoteItemScopeType}) => {
          const { messages, success } = updateJobQuoteItemScopeType
          window[Symbol.for('__snackbar')].toggleOpen({
            message: messages[0]
          })
          resolve({ok: true, messages, success})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}
