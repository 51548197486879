import React, { Component } from 'react'
import classNames from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'

class InfoBox extends Component {
  render() {
    const {
      label, text, classes, className, iconDom,
      tooltip, tooltipProps, open, maxHeight,
    } = this.props
    return (
      <Tooltip title={tooltip ? tooltip : ''} aria-label={tooltip} {...tooltipProps} open={tooltip ? open : false} interactive>
        <List>
          <ListItem className={classNames(className, classes.item)} style={{maxHeight: maxHeight}}>
            <ListItemText
              className={classes.listItemText}
              primaryTypographyProps={{className: classes.primary}}
              secondaryTypographyProps={{className: classes.secondary, component: 'div'}}
              primary={label}
              secondary={(
                <div style={{ display: !maxHeight && 'flex', alignItems: 'center', overflow: maxHeight && 'auto', maxHeight: maxHeight }}>
                  {iconDom}
                  {text}
                </div>
              )}
            />
          </ListItem>
        </List>
      </Tooltip>
    )
  }
}

const styles = theme => ({
  listItemText: {
    width: '100%',
  },
  primary: {
    fontWeight: 600,
    fontSize: '0.75rem'
  },
  item: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: theme.spacing.unit * 14
  },
  secondary: {
    color: 'rgba(0, 0, 0, 0.80)'
  }
})

export default withStyles(styles)(InfoBox)
