/**
 * @flow
 * @relayHash 13932c43c210609677c50ece1656a701
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type FeeItemWhere = {|
  claimId: string,
  availableToBulkInvoice: boolean,
  claimPortfolioTypes?: ?$ReadOnlyArray<PortfolioType>,
|};
export type FeeInvoiceUploadMultiDialogQueryVariables = {|
  where: FeeItemWhere
|};
export type FeeInvoiceUploadMultiDialogQueryResponse = {|
  +feeItems: ?$ReadOnlyArray<?{|
    +label: ?string,
    +value: number,
    +toBeInvoicedAmount: ?number,
  |}>
|};
export type FeeInvoiceUploadMultiDialogQuery = {|
  variables: FeeInvoiceUploadMultiDialogQueryVariables,
  response: FeeInvoiceUploadMultiDialogQueryResponse,
|};
*/


/*
query FeeInvoiceUploadMultiDialogQuery(
  $where: FeeItemWhere!
) {
  feeItems(where: $where) {
    label: description
    value: feeItemId
    toBeInvoicedAmount
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "FeeItemWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "FeeItemWhere!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "description",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "feeItemId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "toBeInvoicedAmount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeInvoiceUploadMultiDialogQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feeItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FeeItem",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeInvoiceUploadMultiDialogQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feeItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FeeItem",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FeeInvoiceUploadMultiDialogQuery",
    "id": null,
    "text": "query FeeInvoiceUploadMultiDialogQuery(\n  $where: FeeItemWhere!\n) {\n  feeItems(where: $where) {\n    label: description\n    value: feeItemId\n    toBeInvoicedAmount\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '656b6bb13a1d3767ee711a1b16c07442';
module.exports = node;
