import React from "react";
import { Field } from "formik";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

export default (props) => {
  return <Field component={MuiMoneyInput} {...props} />;
};

export const MuiMoneyInput = ({
  field,
  form,
  disabled,
  leadIcon,
  readOnly,
  fixedDecimal,
  decimalScale,
  format,
  allowNegative,
  ...props
}) => {
  const isError = Boolean(
    form.dirty && form.touched[field.name] && form.errors[field.name]
  );

  return (
    <TextField
      variant="outlined"
      fullWidth
      disabled={disabled || form.isSubmitting}
      // Formik handling
      {...props}
      {...field}
      onChange={(value) => form.setFieldValue([field.name], value)}
      onBlur={(e) => {
        field.onBlur && field.onBlur(e);
      }}
      value={field.value}
      // Formik error handling
      error={isError}
      helperText={isError && form.errors[field.name]}
      // Formatted input handling
      InputProps={{
        readOnly,
        startAdornment: (
          <InputAdornment position="start">
            <Icon>attach_money</Icon>
          </InputAdornment>
        ),
        inputComponent: MoneyFormatInput,
        inputProps: {
          autoComplete: "teriTeriMasuMasuKawaii",
          fixedDecimal,
          decimalScale,
          format,
          allowNegative,
        },
      }}
    />
  );
};

export const MoneyFormatInput = ({
  inputRef,
  onChange,
  fixedDecimal = true,
  decimalScale = 2,
  format = undefined,
  allowNegative,
  ...props
}) => {
  return (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(Number(values.value));
      }}
      format={format}
      allowNegative={allowNegative}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimal}
    />
  );
};
