/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddClaimDetailsCard_details$ref: FragmentReference;
export type AddClaimDetailsCard_details = {|
  +insurers: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +managers: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +builders: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +restorers: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +eventTypes: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +catCodes: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +adjusters: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +policyTypes: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +policyCovers: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +assessorTypeVisits: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +internalAssessors: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +agent: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +$refType: AddClaimDetailsCard_details$ref,
|};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": "label",
    "name": "name",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "value",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "AddClaimDetailsCard_details",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "managersWhere",
      "type": "ClaimJobFilter"
    },
    {
      "kind": "RootArgument",
      "name": "buildersWhere",
      "type": "ClaimJobFilter"
    },
    {
      "kind": "RootArgument",
      "name": "restorersWhere",
      "type": "ClaimJobFilter"
    },
    {
      "kind": "RootArgument",
      "name": "eventTypeWhere",
      "type": "ClaimJobFilter"
    },
    {
      "kind": "RootArgument",
      "name": "catCodesWhere",
      "type": "ClaimJobFilter"
    },
    {
      "kind": "RootArgument",
      "name": "adjustersWhere",
      "type": "ClaimJobFilter"
    },
    {
      "kind": "RootArgument",
      "name": "policyTypesWhere",
      "type": "ClaimJobFilter"
    },
    {
      "kind": "RootArgument",
      "name": "policyCoversWhere",
      "type": "ClaimJobFilter"
    },
    {
      "kind": "RootArgument",
      "name": "assessorTypeVisitsWhere",
      "type": "ClaimJobFilter"
    },
    {
      "kind": "RootArgument",
      "name": "internalAssessorsWhere",
      "type": "ClaimJobFilter"
    },
    {
      "kind": "RootArgument",
      "name": "agentWhere",
      "type": "ClaimJobFilter"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "insurers",
      "name": "claimFilterOptions",
      "storageKey": "claimFilterOptions(where:{\"subject\":\"insurers\"})",
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "subject": "insurers"
          },
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": "managers",
      "name": "claimFilterOptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "managersWhere",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": "builders",
      "name": "claimFilterOptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "buildersWhere",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": "restorers",
      "name": "claimFilterOptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "restorersWhere",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": "eventTypes",
      "name": "claimFilterOptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "eventTypeWhere",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": "catCodes",
      "name": "claimFilterOptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "catCodesWhere",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": "adjusters",
      "name": "claimFilterOptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "adjustersWhere",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": "policyTypes",
      "name": "claimFilterOptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "policyTypesWhere",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": "policyCovers",
      "name": "claimFilterOptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "policyCoversWhere",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": "assessorTypeVisits",
      "name": "claimFilterOptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "assessorTypeVisitsWhere",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": "internalAssessors",
      "name": "claimFilterOptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "internalAssessorsWhere",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": "agent",
      "name": "claimFilterOptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "agentWhere",
          "type": "ClaimJobFilter"
        }
      ],
      "concreteType": "FilterOption",
      "plural": true,
      "selections": (v0/*: any*/)
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '12748264589262a9af71bc507a609b95';
module.exports = node;
