/**
 * @flow
 * @relayHash fdae8558da293a0f5a75ee1d4a691955
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FilterType = "ComboBox" | "ComboBox_multiple" | "Date" | "DateTime" | "Search" | "Select" | "Select_multiple" | "Text" | "Time" | "%future added value";
export type FiltersQueryVariables = {||};
export type FiltersQueryResponse = {|
  +currentUser: {|
    +claimScoreBoardFilters: ?$ReadOnlyArray<?{|
      +name: string,
      +type: FilterType,
      +label: ?string,
      +options: ?$ReadOnlyArray<?{|
        +value: ?string,
        +label: string,
        +group: ?string,
      |}>,
    |}>
  |},
  +claimScoreBoards: ?$ReadOnlyArray<?{|
    +value: string,
    +title: string,
  |}>,
|};
export type FiltersQuery = {|
  variables: FiltersQueryVariables,
  response: FiltersQueryResponse,
|};
*/


/*
query FiltersQuery {
  currentUser {
    claimScoreBoardFilters {
      name
      type
      label
      options {
        value: id
        label
        group
        id
      }
      id
    }
    id
  }
  claimScoreBoards {
    value: id
    title
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "group",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FiltersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticatedUser",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claimScoreBoardFilters",
            "storageKey": null,
            "args": null,
            "concreteType": "FilterInput",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "options",
                "storageKey": null,
                "args": null,
                "concreteType": "FilterInputOption",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimScoreBoards",
        "storageKey": null,
        "args": null,
        "concreteType": "ClaimScoreBoard",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FiltersQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticatedUser",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claimScoreBoardFilters",
            "storageKey": null,
            "args": null,
            "concreteType": "FilterInput",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "options",
                "storageKey": null,
                "args": null,
                "concreteType": "FilterInputOption",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/)
                ]
              },
              (v6/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimScoreBoards",
        "storageKey": null,
        "args": null,
        "concreteType": "ClaimScoreBoard",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FiltersQuery",
    "id": null,
    "text": "query FiltersQuery {\n  currentUser {\n    claimScoreBoardFilters {\n      name\n      type\n      label\n      options {\n        value: id\n        label\n        group\n        id\n      }\n      id\n    }\n    id\n  }\n  claimScoreBoards {\n    value: id\n    title\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd3568bef2aaa7fe0911bbef3b93a76c9';
module.exports = node;
