import React, { Component } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import Collapse from "@material-ui/core/Collapse";

import Paper from "components/Mui/Paper";
import MakeSafeListHeader from "./MakeSafeListHeader";
import MakeSafeListGroup from "./MakeSafeListGroup/MakeSafeListGroup";
import MakeSafeListFooter from "./MakeSafeListFooter";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "components/Mui/IconButton";

//import PropTypes from 'prop-types';

class MakeSafeListContainer extends Component {
  state = {
    extend: true,
  };
  handleExtend = () => {
    this.setState({ extend: !this.state.extend });
  };

  render() {
    const {
      classes,
      isAction,
      items,
      report,
      meta,
      makeSafeScopeTypes,
      ...rest
    } = this.props;
    const groups = items ? this.mapDataToGroup(items) : null;
    if (!makeSafeScopeTypes) return null;
    return (
      <>
        {makeSafeScopeTypes.map((e) => {
          return (
            <>
              <Paper
                title={
                  <MakeSafeListHeader
                    isAction={isAction}
                    makeSafeScopeTypes={e}
                  />
                }
              >
                <div>
                  {meta.claim.view.actions.updateScopeType.isDisplay && (
                    <div className={classes.scopeTypeRow}>
                      <IconButton
                        icon={this.state.extend ? "expand_less" : "expand_more"}
                        className={classes.expandBtn}
                        onClick={this.handleExtend}
                      />
                      {e.scopeType.name}
                    </div>
                  )}

                  <Collapse in={this.state.extend} timeout="auto" unmountOnExit>
                    <div className={classes.root}>
                      {e.locations.length === 0 ? (
                        <div className={classes.noItem}>No item</div>
                      ) : (
                        <>
                          <MakeSafeListGroup
                            meta={meta}
                            groups={groups}
                            isAction={isAction}
                            makeSafeScopeTypes={e}
                            {...rest}
                          />
                        </>
                      )}
                    </div>
                  </Collapse>
                </div>
                {report && !Object.values(report).every((x) => x === null) ? (
                  <MakeSafeListFooter report={report} makeSafeScopeTypes={e} />
                ) : null}
              </Paper>
            </>
          );
        })}
      </>
    );
    // return (
    //   <Paper title={<MakeSafeListHeader isAction={isAction} makeSafeScopeTypes={makeSafeScopeTypes} />}>
    //     <MakeSafeListGroup
    //       meta={meta}
    //       groups={groups}
    //       isAction={isAction}
    //       makeSafeScopeTypes={makeSafeScopeTypes}
    //       {...rest}
    //     />
    //     {
    //       report && !Object.values(report).every(x => x === null)
    //         ? <MakeSafeListFooter report={report} />
    //         : null
    //     }
    //   </Paper>
    // )
  }

  mapDataToGroup = (d) =>
    Object.values(
      d.reduce(
        (total, current) => ({
          ...total,
          [current.location.costLocationId]: total[
            current.location.costLocationId
          ]
            ? {
                ...total[current.location.costLocationId],
                items: [
                  ...total[current.location.costLocationId].items,
                  current,
                ],
              }
            : {
                name: current.location.locationName,
                items: [current],
              },
        }),
        {}
      )
    );
}

export default withStyles((theme) => ({
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    background: theme.palette.grey[400],
    borderRadius: 8,
    justifyContent: "flex-end",
  },
  scopeTypeRow: {
    fontWeight: 600,
    borderRadius: 8,
    background: theme.palette.grey[400],
    "&:hover": {
      background: theme.palette.grey[600],
    },
  },
  expandBtn: {
    margin: 0,
    padding: theme.spacing.unit,
  },
  noItem: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
  subHeader: {
    fontStyle: "italic",
    fontWeight: 600,
    background: theme.palette.grey[400],

    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    borderRadius: 8,

    borderTop: "1px solid #ececec",
  },
  root: {
    maxHeight: "60vh",
    overflow: "auto",
  },
  discount: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "300px",
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(
  createFragmentContainer(
    MakeSafeListContainer,
    graphql`
      fragment MakeSafeListContainer_report on JobMakeSafe {
        subtotal
        gst
        margin
        total
      }
      fragment MakeSafeListContainer_items on JobMakeSafeItem
      @relay(plural: true) {
        id
        makeSafeItemId
        location {
          costLocationId
          locationName
        }
        dimensions

        costCentre {
          costCentreId
          costCentreName
        }
        costItem {
          costItemId
          itemDescription
        }
        lineDescription
        unit {
          unitId
          unitName
        }
        qty
        rate
        marginRate
        subtotal
      }
      fragment MakeSafeListContainer_makeSafeScopeTypes on JobMakeSafeScopeTypeGroup
      @relay(plural: true) {
        discount
        fields {
          costCentre {
            label
            isDisplay
          }
          description {
            label
            isDisplay
          }
          discount {
            label
            isDisplay
          }
          gst {
            label
            isDisplay
          }
          margin {
            label
            isDisplay
          }
          marginBreakdown {
            label
            isDisplay
          }
          marginRate {
            label
            isDisplay
          }
          qty {
            label
            isDisplay
          }
          rate {
            label
            isDisplay
          }
          sIRRate {
            label
            isDisplay
          }
          subtotal {
            label
            isDisplay
          }
          total {
            label
            isDisplay
          }
          unit {
            label
            isDisplay
          }
          action {
            label
            isDisplay
          }
        }

        gst
        id
        locations {
          dimensions
          id
          items {
            costCentre {
              costCentreId
              costCentreName
              costType
              directSupplier
              id
            }
            costItem {
              costItemId
              id
              itemDescription
              units {
                defaultValue
                unitId
                unitName
              }
            }
            dimensions
            gst
            id
            lineDescription
            location {
              costLocationId
              id
              locationName
            }
            logdate
            makeSafeId
            makeSafeItemId
            margin
            marginRate
            qty
            rate
            scopeType {
              id
              name
            }
            subtotal
            total
            unit {
              defaultValue
              unitName
              unitId
            }
          }
          location {
            costLocationId
            locationName
            id
          }
        }
        margin
        scopeType {
          id
          name
        }
        subtotal
        total
      }
    `
  )
);
