import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation AllocateBuilderActionMutation($input: ReallocateScopingSupplierInput!, $where: ENDataPortfolioKey!) {
    claimReallocateScopingSupplier(input: $input, where: $where) {
      success
      messages
      result {
        reallocate
        scopingSupplier {
          companyName
        }
      }
    }
  }
`

export default (variables, id) => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        updater: store => {
          const payload = store.getRootField('claimReallocateScopingSupplier')
          if(payload.getValue('success')) {
            const claim = store.get(id)
            const claimPortfolio = claim.getLinkedRecord(variables.where.portfolioType.toLowerCase())
            const portfolioScopingSupllier = claimPortfolio.getLinkedRecord('scopingSupplier')

            const newReallocate = payload.getLinkedRecord('result').getValue('reallocate')
            const newCompanyName = payload.getLinkedRecord('result').getLinkedRecord('scopingSupplier')
              .getValue('companyName')

            claimPortfolio.setValue(newReallocate, 'reallocate')
            portfolioScopingSupllier.setValue(newCompanyName, 'companyName')
          }
        },

        onCompleted: res => {
          resolve({ok: true, success: res.claimReallocateScopingSupplier.success, message: res.claimReallocateScopingSupplier.messages[0]})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}