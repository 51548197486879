import React from 'react'
import Grid from 'components/Mui/Grid'
import ClaimTypeIcon from 'components/Mui/ClaimTypeIcon'
import Button from '@material-ui/core/Button'
import { Paper, Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { useQuery, graphql } from 'relay-hooks'

const WaterFC = ({ filterWhere, filterChange, classes }) => {
  const portfolioOptions = ['Building', 'Contents', 'Restoration']
  const [portfolio, setPortfolio] = React.useState(portfolioOptions[0])

  const filterWhereCleanup = React.useMemo(() => ({
    ...filterWhere,
    claimStatusStageIds: undefined
  }), [filterWhere])

  const { props, error } = useQuery({
    query: graphql`
      query WaterfallFilterGroupsQuery($where: ClaimStatusVolumeWhere!) {
        currentUser {
          waterfallFilters(where: $where) {
            id
            items {
              label
              value: id
              claimCount
              color
            }
          }
        }
      }
    `,
    variables: {
      where: {
        ...filterWhereCleanup,
        claimPortfolioType: portfolio
      }
    }
  })

  const waterfallFilters = (props && props.currentUser && props.currentUser.waterfallFilters) ? props.currentUser.waterfallFilters : []
  const [claimStatus, setClaimStatus] = React.useState()
  const handleClaimStatus = status => {
    if(status === claimStatus) setClaimStatus(undefined)
    else setClaimStatus(status)
  }

  React.useEffect(() => {
    filterChange({
      buildingStatus: undefined,
      restorationStatus: undefined,
      contentsStatus: undefined,
      claimStatusStageIds: claimStatus ? [claimStatus] : undefined
    })
  }, [claimStatus])

  return (
    <Grid fluid>
      <Grid item>
        <PortfolioFilterGroup
          options={portfolioOptions}
          value={portfolio}
          setValue={setPortfolio}
        />
      </Grid>
      <Grid item xs className={classes.root}>
        <FilterGroup groups={waterfallFilters} value={claimStatus} setValue={handleClaimStatus} classes={classes}/>
      </Grid>
    </Grid>
  )
}

export default withStyles(theme => ({
  '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
    root: {
      flexBasis: '34px'
    }
 }
}))(WaterFC)

const PortfolioFilterGroup = ({ options, value, setValue }) => {
  return (
    <Paper style={{ display: 'flex', flexDirection: 'column', padding: 4 }}>
      {options.map(option => (
        <Button
          key={option}
          style={{
            margin: 0, marginBottom: 4,
            // padding: 8,
            minWidth: 50, width: 50,
          }}
          variant={value === option ? 'contained' : 'text'}
          onClick={() => setValue(option)}
        >
          <ClaimTypeIcon style={{ margin: 0, fontSize: '1.5rem' }} type={option} />
        </Button>
      ))}
    </Paper>
  )
}

const FilterGroup = ({ groups, value: selected, setValue, classes }) => {
  return (
    <Grid fluid justify="flex-start" alignItems="flex-start">
      {groups.map(({ items }, index) => (
        <Grid key={index} item xs container direction='column' style={{ margin: 0 }} className={classes.root}>
          {items.map(({ value, claimCount, label, color }, index) => (
            <Grid item xs key={value} className={classes.root}>
              <Button
                style={{
                  margin: 0,
                  padding: '1px 16px',
                  // fontWeight: 600,
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  display: 'flex',
                  // background: color,
                  // color: 'rgba(0, 0, 0, 0.87)',
                  background: selected === value ? 'rgba(0, 0, 0, 0.87)' : color,
                  color: selected === value ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                }}
                // variant='contained'
                onClick={() => setValue(value)}
                fullWidth
              >
                <div style={{ flexGrow: 1, display:'flex', justifyContent:'space-between' }}>
                  <div>
                    {label} 
                  </div>
                  <div style={{ justifyContent:'' }}>
                    <span>{claimCount}</span>
                  </div>
                </div>
              </Button>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  )
}