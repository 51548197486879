import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation ReleaseSiteReportMutation($where: ClaimPortfoliosUniqueWhere!, $input: ReleaseSiteReportInput ) {
    releaseSiteReport(where: $where, input: $input) {
      messages
      success
    }
  }
`

export default (variables, id, claimId) => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          resolve({ ok: true, ...res.releaseSiteReport })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}