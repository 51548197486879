import React, { Component, createRef } from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { withStyles } from "@material-ui/core/styles";
import { withClaimMeta } from "../../ClaimMetaContext";
import { getSafe } from "common/utils";

import Tooltip from "@material-ui/core/Tooltip";
import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
import Button from "components/Mui/Button";

import Survey from "./Survey";
import ClaimDetailsPortfolioFilter from "../../ClaimDetailsPortfolioFilter";
import JobNotesListItem from "./JobNotesListItem";
import InitCallMutation from "pages/Claim/ClaimDetails/ClaimDetailsAction/InitCallMutation";
import AwaitingInfoAction from "../../ClaimDetailsAction/AwaitingInfoAction";
import AppointmentMadeAction from "../../ClaimDetailsAction/AppointmentMadeAction";
import ChangeAppointmentAction from "../../ClaimDetailsAction/ChangeAppointmentAction";
import FollowUpAction from "pages/Claim/ClaimDetails/ClaimDetailsAction/FollowUpAction";

class JobNotesList extends Component {
  snackbar = window[Symbol.for("__snackbar")];
  awaitingInfo = createRef();
  appointment = createRef();
  changeAppointment = createRef();

  componentDidMount = () => {
    window[Symbol.for("__refreshJobNote")] = this.handleRefresh;
  };

  render() {
    const {
      classes,
      data,
      jobNotesPage_claim,
      claimId,
      meta,
      portfolio,
      actions,
    } = this.props;
    const role = meta.user.type;
    const awaitingInfoDisabled = actions.awaitingInfo.isDisabled;
    const createFeedbackControl = meta.claim.view.actions.createFeedback;
    const createFollowUpControl = meta.claim.view.actions.createFollowUp;
    const companyId = getSafe(() => meta.user.company.companyId, "cid");
    const userPortfolio = role.isBuilder
      ? "building"
      : role.isRestorer
      ? "restoration"
      : "sid";
    const scopingId = getSafe(
      () => jobNotesPage_claim[userPortfolio].scopingSupplier.companyId,
      "sid"
    );
    const isQuoting = companyId !== scopingId;

    const isAwaitingInfoButton = actions.awaitingInfo.isDisplay;

    const disableButton =
      (role.isBuilder && !meta.claim.building.jobSuppliers[0].quote) ||
      (role.isRestorer && !meta.claim.restoration.jobSuppliers[0].quote);

    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Paper>
              <Grid fluid justify="space-between">
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    label="Next Step"
                    variant="outlined"
                    color="primary"
                    onClick={this.handleNextStep}
                  />
                </Grid>
                <Grid item xs={1}>
                  {createFeedbackControl.isDisplay && (
                    <Button
                      fullWidth
                      label="Feedback"
                      variant="outlined"
                      color="primary"
                      disabled={createFeedbackControl.isDisabled}
                      onClick={JobSurvey}
                    />
                  )}
                </Grid>
                {/*<Grid item xs={1}></Grid>*/}
                <Grid item xs={1}>
                  {createFollowUpControl.isDisplay && (
                    <Button
                      fullWidth
                      label="Follow Up"
                      variant="outlined"
                      color="primary"
                      disabled={createFollowUpControl.isDisabled}
                      onClick={FollowUp}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  {!(
                    role.isBuilder || role.isRestorer
                  ) ? null : (role.isBuilder &&
                      !jobNotesPage_claim.building.progress.initialCallMade &&
                      [34, 11].indexOf(
                        jobNotesPage_claim.building.claimStatus.statusId
                      ) === -1) ||
                    (role.isRestorer &&
                      !jobNotesPage_claim.restoration.progress
                        .initialCallMade &&
                      [34, 11].indexOf(
                        jobNotesPage_claim.restoration.claimStatus.statusId
                      ) === -1) ? (
                    <Tooltip
                      title={
                        disableButton
                          ? "Please accept the offer on the job info tab before making the initial call"
                          : ""
                      }
                    >
                      <span>
                        <Button
                          fullWidth
                          label="Initial Call Made"
                          variant="outlined"
                          color="primary"
                          onClick={this.handleCallMade}
                          disabled={disableButton}
                        />
                      </span>
                    </Tooltip>
                  ) : (role.isBuilder &&
                      jobNotesPage_claim.building.progress.initialCallMade &&
                      !jobNotesPage_claim.building.progress
                        .appointmentBooked) ||
                    (role.isRestorer &&
                      jobNotesPage_claim.restoration.progress.initialCallMade &&
                      !jobNotesPage_claim.restoration.progress
                        .appointmentBooked) ? (
                    <Button
                      onClick={(e) => this.handleAppointment()}
                      fullWidth
                      label="Appointment Made"
                      variant="outlined"
                      color="primary"
                    />
                  ) : (role.isBuilder &&
                      !isQuoting &&
                      [37, 1].indexOf(
                        jobNotesPage_claim.building.claimStatus.statusId
                      ) !== -1) ||
                    (role.isRestorer &&
                      !isQuoting &&
                      [37, 1].indexOf(
                        jobNotesPage_claim.restoration.claimStatus.statusId
                      ) !== -1) ? (
                    <Button
                      onClick={(e) => this.handleChangeAppointment()}
                      fullWidth
                      label="Change Appointment"
                      variant="outlined"
                      color="primary"
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={2}>
                  {!awaitingInfoDisabled && isAwaitingInfoButton && (
                    <Button
                      onClick={this.toggleAwaitingInfo}
                      fullWidth
                      label="Awaiting Info"
                      variant="outlined"
                      color="primary"
                    />
                  )}
                </Grid>
                <Grid item xs={1}></Grid>
                {
                  meta.claim.view.actions.addJobNote.isDisplay &&
                    <Grid item xs={2}>
                      <Button
                        onClick={this.handleNewJobNote}
                        fullWidth
                        disabled={meta.claim.view.actions.addJobNote.isDisabled}
                        label="New Job Note"
                        variant="outlined"
                        color="primary"
                      />
                    </Grid>
                }
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs className={classes.relative}>
            <ClaimDetailsPortfolioFilter
              value={portfolio ? portfolio.toLowerCase() : undefined}
              portfolio={meta.claim}
              changePortfolio={(portfolio) =>
                this.props.handlePortfolio(portfolio)
              }
            />
            <Paper
              title={
                <Grid fluid className={classes.headerContainer}>
                  {[
                    { col: 2, name: "Date" },
                    {
                      col: 2,
                      name: "Company",
                      unmountOn: false /* !role.isAdmin */,
                    },
                    { col: 1, name: "User" },
                    { col: 1, name: "Privacy" },
                    { col: true, name: "Note" },
                  ].map(
                    ({ col, name, unmountOn }, key) =>
                      !unmountOn && (
                        <Grid
                          className={classes.alignCenter}
                          item
                          key={key}
                          xs={col}
                        >
                          {name}
                        </Grid>
                      )
                  )}
                </Grid>
              }
            >
              <div className={classes.listBody}>
                {data.claimNotes && data.claimNotes.edges.length !== 0
                  ? data.claimNotes.edges.map(({ node }, k) => (
                      <JobNotesListItem
                        role={role}
                        index={k + 1}
                        key={k}
                        item={node}
                      />
                    ))
                  : "No note found"}
              </div>
            </Paper>
          </Grid>
        </Grid>

        <AwaitingInfoAction
          claimId={claimId}
          dlRef={this.awaitingInfo}
          portfolio={jobNotesPage_claim}
          meta={meta}
          handleRefresh={this.props.handleRefresh}
        />
        <AppointmentMadeAction
          claimId={claimId}
          dlRef={this.appointment}
          handleRefresh={this.props.handleRefresh}
        />
        <ChangeAppointmentAction
          claimId={claimId}
          dlRef={this.changeAppointment}
          handleRefresh={this.props.handleRefresh}
        />
        <Survey />
        <FollowUpAction
          claimId={claimId}
          portfolio={portfolio}
          handleRefresh={this.props.handleRefresh}
        />
      </>
    );
  }

  handleCallMade = async (data) => {
    const res = await this.snackbar.toggleOpen({
      message: "Please confirm that initial call has been made",
      firstButton: "Call Made",
      secondButton: "Not Yet",
      type: "confirm",
    });
    if (res.ok) {
      const mutationRes = await InitCallMutation({
        where: { id: this.props.claimId },
      });
      if (mutationRes.success) {
        this.snackbar.toggleOpen({
          message: "Confirmed",
        });
        this.props.handleRefresh();
      } else {
        this.snackbar.toggleOpen({
          message: mutationRes.messages[0],
        });
      }
    }
  };

  handleAppointment = () => {
    this.appointment.current.handleOpen();
  };

  handleChangeAppointment = () => {
    this.changeAppointment.current.handleOpen();
  };

  toggleAwaitingInfo = () => this.awaitingInfo.current.handleOpen();

  handleNewJobNote = () => {
    if (window[Symbol.for("__newJobNoteAction")]) {
      window[Symbol.for("__newJobNoteAction")].toggleOpen();
    }
  };

  handleNextStep = () => {
    if (window[Symbol.for("__nextStepAction")]) {
      window[Symbol.for("__nextStepAction")].handleOpen();
    }
  };
}

const JobSurvey = () => {
  if (window[Symbol.for("__JobSurvey")]) {
    window[Symbol.for("__JobSurvey")].handleOpen();
  }
};

const FollowUp = () => {
  if (window[Symbol.for("__newFollowUpAction")]) {
    window[Symbol.for("__newFollowUpAction")].toggleOpen();
  }
};

export default createFragmentContainer(
  withStyles((theme) => ({
    alignCenter: {
      ...theme.mixins.alignJustifyContainer(theme),
    },
    listBody: {
      maxHeight: "55vh",
      overflow: "auto",
    },
    relative: {
      position: "relative",
    },
    headerContainer: {
      "& > div": {
        borderRight: "1px solid white",
      },
      "& > div:last-child": {
        borderRight: 0,
      },
    },
  }))(withClaimMeta(JobNotesList)),
  graphql`
    fragment JobNotesList on Query {
      claimNotes(first: $first, after: $after, where: $where)
        @connection(key: "JobNotesList_claimNotes", filters: []) {
        edges {
          node {
            portfolioType
            ...JobNotesListItem_item
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `
);
