/**
 * @flow
 * @relayHash 01717a8a9d3e82bac46a22091366b15a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CommunicationList$ref = any;
export type ClaimCommunicationBox = "Inbox" | "InboxAndSharedBox" | "OutBox" | "UnDefined" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type CommunicationFilter = {|
  id?: ?string,
  claimId?: ?string,
  keyWords?: ?string,
  managers?: ?$ReadOnlyArray<string>,
  insurers?: ?$ReadOnlyArray<string>,
  managerTeams?: ?$ReadOnlyArray<?string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  acknowledged?: ?boolean,
  states?: ?$ReadOnlyArray<?StateEnum>,
  boxes?: ?$ReadOnlyArray<?ClaimCommunicationBox>,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type CommunicationListQueryVariables = {|
  first: number,
  after?: ?string,
  where?: ?CommunicationFilter,
|};
export type CommunicationListQueryResponse = {|
  +$fragmentRefs: CommunicationList$ref
|};
export type CommunicationListQuery = {|
  variables: CommunicationListQueryVariables,
  response: CommunicationListQueryResponse,
|};
*/


/*
query CommunicationListQuery(
  $first: Int!
  $after: String
  $where: CommunicationFilter
) {
  ...CommunicationList
}

fragment CommunicationList on Query {
  claimCommuications(first: $first, after: $after, where: $where) {
    totalCount
    edges {
      node {
        ...StandaloneCommunicationListItem_item
        id
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}

fragment StandaloneCommunicationListItem_item on ClaimCommunication {
  claimId
  claimRef
  communicationId
  customerName
  message
  portfolioType
  recieverCompanyId
  recieverCompanyName
  sendDate
  senderCompanyId
  senderCompanyName
  senderId
  senderName
  senderRole
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "CommunicationFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "CommunicationFilter"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CommunicationListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "CommunicationList",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CommunicationListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimCommuications",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimCommunicationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimCommunicationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimCommunication",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "claimId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "claimRef",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "communicationId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "customerName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "message",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "portfolioType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "recieverCompanyId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "recieverCompanyName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "sendDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "senderCompanyId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "senderCompanyName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "senderId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "senderName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "senderRole",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "claimCommuications",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "CommunicationList_claimCommuications",
        "filters": [
          "where"
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CommunicationListQuery",
    "id": null,
    "text": "query CommunicationListQuery(\n  $first: Int!\n  $after: String\n  $where: CommunicationFilter\n) {\n  ...CommunicationList\n}\n\nfragment CommunicationList on Query {\n  claimCommuications(first: $first, after: $after, where: $where) {\n    totalCount\n    edges {\n      node {\n        ...StandaloneCommunicationListItem_item\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment StandaloneCommunicationListItem_item on ClaimCommunication {\n  claimId\n  claimRef\n  communicationId\n  customerName\n  message\n  portfolioType\n  recieverCompanyId\n  recieverCompanyName\n  sendDate\n  senderCompanyId\n  senderCompanyName\n  senderId\n  senderName\n  senderRole\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2bd9b1220eed28919ce2491c0fc2b26';
module.exports = node;
