import React from 'react'
import Dialog from 'components/Mui/Dialog'
import { Formik, Form } from 'formik'
import Input from 'components/Formik/Input'
import Grid from 'components/Mui/Grid'
import InfoBox from 'components/Mui/InfoBox'
import Button from 'components/Mui/Button'
import { useQuery, graphql } from 'relay-hooks'
import { useMutation } from 'react-relay-mutation'
import { useMetaContext } from 'pages/Claim/ClaimDetails/ClaimMetaContext'
import { moneyFormat } from 'common/utils'
import { useFeeReloadContext } from '../../FeeReloadContext.js'

const query = graphql`
  query FeeInvoiceUpdateDialogQuery($where: FeeInvoiceStatusWhere!){
    feeInvoiceStatus (where: $where) {
      label: name
      value: invoiceStatusId
    }
  }
`

const mutation = graphql`
  mutation FeeInvoiceUpdateDialogMutation($input: FeeInvoiceUpdateInput!, $where: FeeInvoiceUniqueWhere!) {
    feeInvoiceUpdate(where: $where, input: $input) {
      success
      messages
      fieldErrors {
        fieldName
        level
        message
      }
    }
  }
`

export default ({ symbol, feeInvoice }) => {
  const { claim: { claimId }} = useMetaContext()
  const { reload, setReload } = useFeeReloadContext()

  const {props, error} = useQuery({
    query,
    variables: {
      where: {
        claimId: claimId,
      }
    }
  })

  const [updateFeeInvoice] = useMutation(mutation)

  if (error) return 'error'

  if (!props) return 'loading'

  return (
    <Dialog
      noForm
      noActions
      symbol={symbol}
      title="Update Invoice Status"
      content={
        <Formik
          initialValues={{
            feeInvoiceStatusId: ''
          }}
          validate={values => {
            let errors = {}

            if(!values.feeInvoiceStatusId) errors.feeInvoiceStatusId = 'Required!'

            return errors
          }}
          onSubmit={(values, actions) => {
            setTimeout(async () => {
              const res = await updateFeeInvoice({
                variables: {
                  input: { feeInvoiceStatusId: parseInt(values.feeInvoiceStatusId) },
                  where: { feeInvoiceId: feeInvoice.feeInvoiceId }
                }
              })
              if(res) {
                actions.setSubmitting(false)
                setReload(!reload)
                if(window[Symbol.for(symbol)]){
                  window[Symbol.for(symbol)].handleClose()
                }
              }
            }, 300)
          }}
        >
          {
            ({ isSubmitting, values, touched, setFieldValue }) => {
              return (
                <Form>
                  <Grid fluid justify='flex-end'>
                    {[
                      {
                        col: 3, label: 'Invoice Number',
                        text: feeInvoice.number,
                        component: InfoBox,
                        unmountOn: false
                      },
                      {
                        col: 3, label: 'Total Invoice Amount',
                        text: moneyFormat(feeInvoice.amount),
                        component: InfoBox,
                        unmountOn: false
                      },
                      {
                        col: 6, label: 'Status',
                        isClearable: true,
                        leadIcon: 'event',
                        component: Input, type: 'select', name: 'feeInvoiceStatusId',
                        select: true, options: props.feeInvoiceStatus,
                        required: true
                      },
                      {
                        col: 6, label: `Fee Items ${feeInvoice.feeItemInvoices.length > 1 ? '(combined invoice)' : ''}`,
                        text: <div style={{
                            display: 'block',
                          }}>
                          {feeInvoice.feeItemInvoices.map((e, k) => {
                            return (
                              <p>{e.feeItemName} - ${e.amount}</p>
                            )
                          })}
                        </div>,
                        component: InfoBox,
                        unmountOn: false
                      },
                      {
                        col: 6,
                      }
                    ].map(({col, component: Component, unmountOn, readOnly=false, ...rest}, key) => (
                      !unmountOn && <Grid item xs={col} key={key}>
                        {Component ? <Component readOnly={readOnly} {...rest}/> : <div/>}
                      </Grid>
                    ))}
                    <Grid xs={1}>
                      <Button fullWidth label='Close'
                        disabled={isSubmitting}
                        onClick={() => {
                          if(window[Symbol.for(symbol)]){
                            window[Symbol.for(symbol)].handleClose()
                          }
                        }}
                      />
                    </Grid>
                    <Grid xs={1}>
                      <Button
                        fullWidth
                        disabled={isSubmitting}
                        label='Save'
                        type='submit'
                        variant='contained'
                        color='primary'
                      />
                    </Grid>
                  </Grid>
                </Form>
              )
            }
          }
        </Formik>
      }
    />
  )
}