import React, { Component } from 'react'
import { graphql, createPaginationContainer } from 'react-relay'
import { withStyles } from '@material-ui/core/styles'
import { withClaimMeta } from '../../ClaimMetaContext'
// import environment from 'common/relay'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import Button from 'components/Mui/Button'

import ClaimDetailsPortfolioFilter from '../../ClaimDetailsPortfolioFilter'
import DocumentsListItem from './DocumentsListItem'

class DocumentsList extends Component {
  snackbar = window[Symbol.for('__snackbar')]

  componentDidMount = () => document.querySelector('#documentsList_scrolling-list').addEventListener('scroll', this.scrollHandler)
  componentWillUnmount = () => document.querySelector('#documentsList_scrolling-list').removeEventListener('scroll', this.scrollHandler)

  render() {
    const { classes, data, meta, portfolio, setViewAll, setViewItemIndex } = this.props
    const DocumentDisabled = meta.claim.view.actions.addDocument.isDisabled
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Paper>
              <Grid fluid justify='space-between'>
                <Grid item xs={2}>
                  <Button fullWidth label='Next Step' variant='outlined' color='primary' onClick={this.handleNextStep}/>
                </Grid>
                <Grid item xs={4}></Grid>
                {/* <Grid item xs={2}> */}
                {/*   <Button fullWidth label='Notify' variant='outlined' color='primary' */}
                {/*     onClick={this.notify} */}
                {/*   /> */}
                {/* </Grid> */}
                <Grid item xs={2}>
                  {!DocumentDisabled && <Button onClick={this.handleDocument} fullWidth label='Upload File' variant='outlined' color='primary'/>}
                </Grid>
                <Grid item xs={2}>
                  {!DocumentDisabled && <Button fullWidth label='View All Photos' variant='outlined' color='primary'
                    onClick={e => this.handleViewAllPhotos()}
                  />}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs className={classes.relative}>
            <ClaimDetailsPortfolioFilter
              value={portfolio ? portfolio.toLowerCase() : undefined}
              portfolio={meta.claim}
              changePortfolio={portfolio => this.props.handlePortfolio(portfolio)}
            />
            <Paper
              title={(
                <Grid fluid className={classes.headerContainer}>
                  {[
                    {col: 2, name: 'Date'},
                    {col: 2, name: 'Company'},
                    {col: 1, name: 'File'},
                    {col: 1, name: 'Privacy'},
                    {col: true, name: 'Description'},
                    {col: 1, name: 'Amount'},
                    {col: 1, name: 'Actions'},
                  ].map(({col, name}, key) => <Grid className={classes.alignCenter} item key={key} xs={col}>{name}</Grid>)}
                </Grid>
              )}
            >
              <div id='documentsList_scrolling-list' className={classes.listBody}>
                {
                  data.claimDocuments && data.claimDocuments.edges.length !== 0
                    ? data.claimDocuments.edges.map(({ node }, k) => (
                      <DocumentsListItem
                        key={k}
                        type='Building'
                        item={node}
                        setViewAll={setViewAll}
                        setViewItemIndex={setViewItemIndex}
                        index={k}
                      />
                    ))
                    : 'No document found'
                }
              </div>
            </Paper>
          </Grid>
        </Grid>
      </>
    )
  }

  handleNextStep = () => {
    if(window[Symbol.for('__nextStepAction')]){
      window[Symbol.for('__nextStepAction')].handleOpen()
    }
  }

  handleDocument = () => {
    if(window[Symbol.for('__newDocumentAction')]){
      window[Symbol.for('__newDocumentAction')].handleOpen()
    }
  }

  notify = async () => {
    const res = await this.snackbar.toggleOpen({
      message: 'Would you like to send a notification to case manager about an important document uploaded?',
      firstButton: 'Send Notification',
      secondButton: 'Cancel',
      type: 'confirm',
    })
    if (res.ok) {
      this.snackbar.toggleOpen({
        message: 'Notification sent successfully',
      })
    }
  }

  handleViewAllPhotos = () => {
    if(window[Symbol.for('__photoViewAllAction')]) {
      window[Symbol.for('__photoViewAllAction')].handleOpen()
    }
  }

  scrollHandler = e => {
    const { target } = e
      if((target.scrollTop + target.offsetHeight) >= target.scrollHeight*0.7) {
        this._loadMore()
      }
    }
  _loadMore = () => {
    if (!this.props.relay.hasMore()) return
    else if (this.props.relay.isLoading()) return

    this.props.relay.loadMore(this.props.first)
  }
}

export default createPaginationContainer(
  withStyles(theme => ({
    listBody: {
      maxHeight: '55vh',
      overflow: 'auto'
    },
    alignCenter: {
      ...theme.mixins.alignJustifyContainer(theme),
    },
    relative: {
      position: 'relative'
    },
    headerContainer: {
      '& > div': {
        borderRight: '1px solid white',
      },
      '& > div:last-child': {
        borderRight: 0,
      }
    }
  }))(withClaimMeta(DocumentsList)),
  graphql`
    fragment DocumentsList on Query {
      claimDocuments(first: $first, after: $after, where: $where)
      @connection(key: "DocumentsList_claimDocuments", filters: []) {
        edges {
          node {
            ...DocumentsListItem_item
          }
        }
      }
    }
  `,
  {
    query: graphql`
      query DocumentsListQuery ($first: Int!, $after: String, $where: ClaimItemFilter) {
        ...DocumentsList
      }
    `,
    getVariables(props, paginationInfo, fragmentVariables) {
      const { cursor } = paginationInfo
      const { where, first } = fragmentVariables
      return {
          first,
          after: cursor,
          where
      }
    }
  }
)