import React from "react";
import { Formik, Form } from "formik";
import { withClaimMeta } from "pages/Claim/ClaimDetails/ClaimMetaContext/";
import { Grid } from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "components/Formik/Input";
import Button from "components/Mui/Button";
import InfoBox from "components/Mui/InfoBox/";
import VariationValidationActionValidateMutation from "./VariationValidationActionValidateMutation";

const VariationValidationActionValidate = ({
  validationType,
  item,
  meta,
  portfolioType,
  handleRefresh,
  validationRefresh,
}) => {
  const [open, setOpen] = React.useState(false);
  const snackbar = window[Symbol.for("__snackbar")];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (!item.actions) return null;
  return (
    <div>
      <MuiButton
        color="secondary"
        disabled={item.actions[validationType].isDisabled}
        onClick={() => handleClickOpen()}
        style={{
          fontSize: "0.875rem",
          padding: 0,
          margin: 0,
          alignItems: "inherit",
          textDecoration: "underline",
        }}
      >
        {item.actions[validationType].label}
      </MuiButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="variationValidationValidateDialog"
        //symbol={`__VariationValidationActionValidate${validationType}${item.jobQuoteItemId}`}
      >
        <DialogTitle id="variationValidationValidateDialog">{`Variation Validation - ${validationType}`}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              marginRate: item.marginRate,
              rate: item.rate,
              qty: item.qty,
            }}
            validate={(values) => {
              let errors = {};
              if (
                Math.abs(values.rate * values.qty) >
                Math.abs(item.rate * item.qty)
              )
                errors.rate = `Total for the line item should be less or equal to $${
                  item.rate * item.qty
                }`;
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const res = await VariationValidationActionValidateMutation({
                where: {
                  id: item.variationItemId,
                  portfolioType: portfolioType,
                },
                input: {
                  validationComment: values.comment,
                  validationQty: Number(values.qty),
                  validationRate: Number(values.rate),
                  validationMarginRate: Number(values.marginRate),
                },
              });
              if (res.ok) {
                if (res.success) {
                  snackbar.toggleOpen({ message: res.messages[0] });
                  handleClose();
                } else {
                  snackbar.toggleOpen({ message: res.messages[0] });
                }
              }
              setSubmitting(false);
            }}
          >
            {({ values, isSubmitting }) => (
              <Form style={{ width: "500px" }}>
                <Grid container>
                  <Grid item xs={6}>
                    <InfoBox
                      label="Cost Centre"
                      text={item.costCentre.costCentreName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoBox
                      label="Description"
                      text={item.costItem.itemDescription}
                      maxHeight="200px"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoBox
                      label="Unit of Measure"
                      text={item.unit.unitName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoBox
                      label="More Details"
                      text={item.lineDescription}
                      maxHeight="200px"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoBox label="Room Size" text={item.dimensions} />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoBox label="SIR Rate" text={item.sirRate} />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="% Margin"
                      name="marginRate"
                      required
                      disabled={
                        meta.claim.insurer.isMarginUpdate ? false : true
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Rate Ex Gst"
                      name="rate"
                      required
                      disabled={
                        validationType !==
                        (item.actions.amend.label.toLowerCase() &&
                          item.actions.validate.label.toLowerCase())
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Qty"
                      name="qty"
                      required
                      disabled={
                        validationType !==
                        (item.actions.amend.label.toLowerCase() &&
                          item.actions.validate.label.toLowerCase())
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label="Comments"
                      name="comment"
                      rows={5}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "end " }}>
                    <Button
                      color="primary"
                      label="Submit"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withClaimMeta(VariationValidationActionValidate);
