/**
 * @flow
 * @relayHash fb171444c7d5fb1fec9d80a456b7e015
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SpecialistRequestWhere = {|
  claimId: string
|};
export type SpecialistCardQueryVariables = {|
  where: SpecialistRequestWhere
|};
export type SpecialistCardQueryResponse = {|
  +specialistRequests: ?$ReadOnlyArray<?{|
    +id: string,
    +requestedDate: ?any,
    +specialistRequestId: number,
    +specialistCategory: ?{|
      +name: string,
      +specialistCategoryId: number,
    |},
    +specialist: ?{|
      +companyName: string,
      +companyId: number,
    |},
    +actions: ?{|
      +deleteRequest: {|
        +isDisabled: boolean,
        +isDisplay: boolean,
        +label: string,
        +name: string,
      |},
      +updateRequest: {|
        +isDisabled: boolean,
        +isDisplay: boolean,
        +label: string,
        +name: string,
      |},
    |},
  |}>
|};
export type SpecialistCardQuery = {|
  variables: SpecialistCardQueryVariables,
  response: SpecialistCardQueryResponse,
|};
*/


/*
query SpecialistCardQuery(
  $where: SpecialistRequestWhere!
) {
  specialistRequests(where: $where) {
    id
    requestedDate
    specialistRequestId
    specialistCategory {
      name
      specialistCategoryId
      id
    }
    specialist {
      companyName
      companyId
      id
    }
    actions {
      deleteRequest {
        isDisabled
        isDisplay
        label
        name
        id
      }
      updateRequest {
        isDisabled
        isDisplay
        label
        name
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "SpecialistRequestWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "SpecialistRequestWhere!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "requestedDate",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "specialistRequestId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "specialistCategoryId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyId",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisabled",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v12 = [
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v5/*: any*/)
],
v13 = [
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v5/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SpecialistCardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "specialistRequests",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SpecialistRequest",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "specialistCategory",
            "storageKey": null,
            "args": null,
            "concreteType": "SpecialistCategory",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "specialist",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "actions",
            "storageKey": null,
            "args": null,
            "concreteType": "SpecialistAction",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deleteRequest",
                "storageKey": null,
                "args": null,
                "concreteType": "ActionControl",
                "plural": false,
                "selections": (v12/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "updateRequest",
                "storageKey": null,
                "args": null,
                "concreteType": "ActionControl",
                "plural": false,
                "selections": (v12/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SpecialistCardQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "specialistRequests",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SpecialistRequest",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "specialistCategory",
            "storageKey": null,
            "args": null,
            "concreteType": "SpecialistCategory",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "specialist",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "actions",
            "storageKey": null,
            "args": null,
            "concreteType": "SpecialistAction",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deleteRequest",
                "storageKey": null,
                "args": null,
                "concreteType": "ActionControl",
                "plural": false,
                "selections": (v13/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "updateRequest",
                "storageKey": null,
                "args": null,
                "concreteType": "ActionControl",
                "plural": false,
                "selections": (v13/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SpecialistCardQuery",
    "id": null,
    "text": "query SpecialistCardQuery(\n  $where: SpecialistRequestWhere!\n) {\n  specialistRequests(where: $where) {\n    id\n    requestedDate\n    specialistRequestId\n    specialistCategory {\n      name\n      specialistCategoryId\n      id\n    }\n    specialist {\n      companyName\n      companyId\n      id\n    }\n    actions {\n      deleteRequest {\n        isDisabled\n        isDisplay\n        label\n        name\n        id\n      }\n      updateRequest {\n        isDisabled\n        isDisplay\n        label\n        name\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f84e8e70fa69b08e721325ac2920e329';
module.exports = node;
