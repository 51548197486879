/**
 * @flow
 * @relayHash 6e9e591c60cfd6f9d95c778b403e2b84
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimCategory = "NonUrgent" | "SemiUrgent" | "Urgent" | "%future added value";
export type LogLevel = "Critical" | "Debug" | "Error" | "Information" | "None" | "Trace" | "Warning" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type ClaimJobTemplateCreate = {|
  refNumber: string,
  insurerId: string,
  contentsRefNum?: ?string,
  claimDescription?: ?string,
  mapRef?: ?string,
  lotNumber?: ?string,
  planNumber?: ?string,
  brcId?: ?string,
  homeAssessorId?: ?string,
  visitTypeID?: ?string,
  casemanagerId?: ?string,
  externalLossAdjusterId?: ?string,
  agentId?: ?string,
  multipleRisks?: ?boolean,
  riskname?: ?string,
  additionalRefNumber?: ?string,
  associatedRiskJobId?: ?number,
  requireCustomLogin?: ?boolean,
  customLoginEmail?: ?string,
  policyCoverId?: ?string,
  policyTypeId?: ?string,
  incidentDetail: IncidentDetailTemplateCreate,
  insured: ContactDetailInput,
  tenantDetails?: ?ContactInput,
  portfolios?: ?$ReadOnlyArray<?ClaimPortfolioTemplateCreate>,
|};
export type IncidentDetailTemplateCreate = {|
  eventTypeId?: ?string,
  catCodeId?: ?string,
  habitableProperty?: ?boolean,
  asbestos?: ?boolean,
  hold?: ?boolean,
  vulnerableMember?: ?boolean,
  makeSafeRequired?: ?boolean,
  incidentDate?: ?any,
  riskAddress: AddressInput,
  category?: ?ClaimCategory,
|};
export type AddressInput = {|
  line1?: ?string,
  line2?: ?string,
  suburb?: ?string,
  postcode?: ?string,
  state?: ?StateEnum,
|};
export type ContactDetailInput = {|
  name?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  phone3?: ?string,
  salutation?: ?string,
  email?: ?string,
  fax?: ?string,
  postalAddress?: ?AddressInput,
|};
export type ContactInput = {|
  name?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  phone3?: ?string,
|};
export type ClaimPortfolioTemplateCreate = {|
  portfolioType: PortfolioType,
  toProvideSiteReport?: ?boolean,
  sumInsured?: ?number,
  excessValue?: ?number,
  toCollectExcess?: ?boolean,
  scopingSupplierId?: ?string,
  quotingSupplierIds?: ?$ReadOnlyArray<string>,
|};
export type AddClaimByTemplateMutationVariables = {|
  input: ClaimJobTemplateCreate,
  templateId: string,
|};
export type AddClaimByTemplateMutationResponse = {|
  +createClaimJobByTemplate: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +fieldErrors: ?$ReadOnlyArray<?{|
      +fieldName: string,
      +level: LogLevel,
      +message: string,
    |}>,
    +result: ?{|
      +claimId: number
    |},
  |}
|};
export type AddClaimByTemplateMutation = {|
  variables: AddClaimByTemplateMutationVariables,
  response: AddClaimByTemplateMutationResponse,
|};
*/


/*
mutation AddClaimByTemplateMutation(
  $input: ClaimJobTemplateCreate!
  $templateId: ID!
) {
  createClaimJobByTemplate(input: $input, templateId: $templateId) {
    success
    messages
    fieldErrors {
      fieldName
      level
      message
    }
    result {
      claimId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimJobTemplateCreate!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "templateId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ClaimJobTemplateCreate!"
  },
  {
    "kind": "Variable",
    "name": "templateId",
    "variableName": "templateId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "fieldErrors",
  "storageKey": null,
  "args": null,
  "concreteType": "EntityFieldError",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fieldName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "level",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "claimId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddClaimByTemplateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimJobByTemplate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJob",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddClaimByTemplateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimJobByTemplate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJob",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddClaimByTemplateMutation",
    "id": null,
    "text": "mutation AddClaimByTemplateMutation(\n  $input: ClaimJobTemplateCreate!\n  $templateId: ID!\n) {\n  createClaimJobByTemplate(input: $input, templateId: $templateId) {\n    success\n    messages\n    fieldErrors {\n      fieldName\n      level\n      message\n    }\n    result {\n      claimId\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '91c7ccd802fe6c3f7837a319d1480f3b';
module.exports = node;
