/**
 * @flow
 * @relayHash 7e74e611398b4c00f0c7d141f3744266
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MediaUpdateWhereInput = {|
  mediaId: string
|};
export type MediaUpdateDataInput = {|
  name?: ?string
|};
export type MediaRootMediaUpdateMutationVariables = {|
  where: MediaUpdateWhereInput,
  data: MediaUpdateDataInput,
|};
export type MediaRootMediaUpdateMutationResponse = {|
  +mediaUpdate: ?{|
    +id: string,
    +name: ?string,
    +url: string,
  |}
|};
export type MediaRootMediaUpdateMutation = {|
  variables: MediaRootMediaUpdateMutationVariables,
  response: MediaRootMediaUpdateMutationResponse,
|};
*/


/*
mutation MediaRootMediaUpdateMutation(
  $where: MediaUpdateWhereInput!
  $data: MediaUpdateDataInput!
) {
  mediaUpdate(where: $where, data: $data) {
    id
    name
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "MediaUpdateWhereInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "MediaUpdateDataInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "mediaUpdate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data",
        "type": "MediaUpdateDataInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "MediaUpdateWhereInput!"
      }
    ],
    "concreteType": "File",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MediaRootMediaUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaRootMediaUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "MediaRootMediaUpdateMutation",
    "id": null,
    "text": "mutation MediaRootMediaUpdateMutation(\n  $where: MediaUpdateWhereInput!\n  $data: MediaUpdateDataInput!\n) {\n  mediaUpdate(where: $where, data: $data) {\n    id\n    name\n    url\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7a9786e8a54b111bf3bbc82aa16104af';
module.exports = node;
