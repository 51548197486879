import React from "react";
import { moneyFormat } from "common/utils";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "components/Mui/Grid";
import IconButton from "components/Mui/IconButton";

import UpdateContentQuoteItem from "../QuoteActionBar/UpdateContentQuoteItem";

const QuoteContentsGroupItem = ({
  classes,
  item,
  quote,
  isAction,
  portfolio,
}) => {
  // const refresh =
  const editQuoteItem = () => {
    const actions =
      window[Symbol.for(`${item.jobQuoteItemId}__quoteUpdateItem`)];
    if (actions.handleOpen) actions.handleOpen();
  };

  return (
    <>
      <Grid fluid spacing={0} className={classes.row}>
        {[
          // date
          { col: 1, text: item.logdate },
          // cost centre
          { col: 2, text: item.costCentre.costCentreName },
          // description
          // substitutename
          {
            col: true,
            text: (
              <>
                {item.authorised && (
                  <Icon
                    style={{
                      color: "green",
                      fontSize: "1rem",
                      paddingRight: "2rem",
                    }}
                  >
                    done
                  </Icon>
                )}
                {item.substitutename && (
                  <Tooltip title={item.substitutename}>
                    <Icon
                      color="primary"
                      style={{ fontSize: "1rem", paddingRight: "2rem" }}
                    >
                      information
                    </Icon>
                  </Tooltip>
                )}

                {item.costItem ? item.costItem.itemDescription : ""}
                {item.lineDescription}
              </>
            ),
          },
          // policy item limit
          { col: 1, text: item.policyLimit },
          // direct supply
          { col: 1, text: item.directsupply ? "Yes" : "No" },
          // purschase price
          // {   col: 1, text: moneyFormat(item.purchasePrice) },
          // age
          // {   col: 1, text: item.ageOfItem },
          // proof of loss
          // {   col: 1, text: item.proofOfLoss },
          // qty
          { col: 1, text: item.qty },
          // reserve price
          { col: 1, text: moneyFormat(item.rate) },
          // subtotal
          { col: 1, text: moneyFormat(item.subtotal) },

          {
            col: 1,
            unMountOn:
              portfolio === "Contents"
                ? !isAction || item.authorised || quote.completed
                : !isAction,
            text: (
              <>
                <IconButton
                  icon="edit"
                  className={classes.actionBtn}
                  onClick={() => editQuoteItem()}
                />
              </>
            ),
          },
        ].map(({ col, text, unMountOn }, key) => {
          return (
            <Grid className={classes.alignCenter} item key={key} xs={col}>
              {!unMountOn && text}
            </Grid>
          );
        })}
      </Grid>
      <UpdateContentQuoteItem
        jobQuoteId={item.jobQuoteItemId}
        portfolioType={portfolio}
        initialValues={{
          locationId: item.location.costLocationId,
          costCentreId: item.costCentre.costCentreId,
          costItemId: item.costItem.costItemId,
          directsupply: item.directsupply,
          lineDescription: item.lineDescription,
          qty: item.qty,
          rate: item.rate,

          purchasePrice: item.purchasePrice || "",
          ageOfItem: item.ageOfItem || "",
          proofOfLoss: item.proofOfLoss || "",
          policyLimit: item.policyLimit || "",
          substitutename: item.substitutename || "",
        }}
      />
    </>
  );
};

export default withStyles((theme) => ({
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: "center",
    borderRadius: "8px",

    "&:nth-child(even)": {
      background: theme.palette.grey[100],
    },
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit,
  },
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
    padding: "4px",
    textAlign: "center",
    flexWrap: "wrap",
  },
}))(QuoteContentsGroupItem);
