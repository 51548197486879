/**
 * @flow
 * @relayHash 69ba1e3204e9ed762aff3215b437b22c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimJobStatus = "VariationApproved" | "VariationRejected" | "VariationReset" | "VariationSubmitted" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type JobVariationAuditInput = {|
  notAcceptedItemIds?: ?$ReadOnlyArray<number>,
  auditToStatus: ClaimJobStatus,
|};
export type VariationAuditMutationVariables = {|
  where: ENDataPortfolioKey,
  input: JobVariationAuditInput,
|};
export type VariationAuditMutationResponse = {|
  +jobVariationAudit: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +completed: boolean,
      +variationStatus: ?string,
    |},
  |}
|};
export type VariationAuditMutation = {|
  variables: VariationAuditMutationVariables,
  response: VariationAuditMutationResponse,
|};
*/


/*
mutation VariationAuditMutation(
  $where: ENDataPortfolioKey!
  $input: JobVariationAuditInput!
) {
  jobVariationAudit(where: $where, input: $input) {
    messages
    success
    result {
      completed
      variationStatus
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobVariationAuditInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "JobVariationAuditInput!"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completed",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "variationStatus",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VariationAuditMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobVariationAudit",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobVariationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariation",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VariationAuditMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobVariationAudit",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobVariationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobVariation",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "VariationAuditMutation",
    "id": null,
    "text": "mutation VariationAuditMutation(\n  $where: ENDataPortfolioKey!\n  $input: JobVariationAuditInput!\n) {\n  jobVariationAudit(where: $where, input: $input) {\n    messages\n    success\n    result {\n      completed\n      variationStatus\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '253f0f915f4d7c8b4338b01fada18e44';
module.exports = node;
