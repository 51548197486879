import React, { Component } from 'react'
import { Formik, Form } from 'formik'
import Input from 'components/Formik/Input'

import Paper from 'components/Mui/Paper'
import Button from 'components/Mui/Button'
import Grid from 'components/Mui/Grid'
import Icon from '@material-ui/core/Icon'

class AddClaimChooseCompanyCard extends Component {
  componentDidMount = () => {
    if(this.props.claimFilterOptions.length === 1) {
      this.props.handleInsurerChoosen(this.props.claimFilterOptions[0].value)
    }
  }
  render() {
    const { claimFilterOptions } = this.props
    return (
      <Paper
        title='Select a company'
        content={
          <Formik
            initialValues={{
              insurerId: ''
            }}
            validate={values => {
              const errors = {}
              if(!values.insurerId) errors.insurerId = 'Required!'
              return errors
            }}
            onSubmit={(values, actions) => {
              this.props.handleInsurerChoosen(values.insurerId)
            }}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Grid fluid justify='flex-end'>
                    <Grid item xs={12}>
                      <Input
                        leadDom={<Icon>attach_file</Icon>}
                        label='Company' type='text'
                        name='insurerId'
                        select options={claimFilterOptions}
                        required
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        color='primary'
                        label='Go' variant='contained'
                        type='submit'
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        }
      />  
    )
  }
}

export default AddClaimChooseCompanyCard