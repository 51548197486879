import React from 'react'
import * as Sentry from '@sentry/browser'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { withRouter } from 'react-router-dom'
import { devLog } from 'common/utils/dev'

import Paper from 'components/Mui/Paper'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'
import Icon from '@material-ui/core/Icon'

class ClaimDetailsErrorBoundary extends React.Component {
  state = { hasError: false, eventId: null }

  componentDidCatch(error, info) {
    if(process.env.REACT_APP_DEV !== 'dev') {
      Sentry.withScope(scope => {
        scope.setExtras(info)
        const eventId = Sentry.captureException(error)
        this.setState({eventId})
      })
    }
    devLog(error, info)
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    const { classes } = this.props
    const { TOKEN, ROOT_ROUTE } = localStorage

    if (this.state.hasError) {
      return <Grid 
        className={classes.root}
        container
        direction='row'
        justify='center'
        alignItems='center'
      >
        <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
          <Paper content={(
            <>
              <Icon className={classes.headIcon} alt='ENData Claims'>filter_vintage</Icon>
              <h1 className={classes.title}>Something went wrong...</h1>
              <p className={classes.subtitle}>Either you typed a wrong URL or you followed a bad link</p>
              {
                process.env.REACT_APP_DEV === 'dev' && ( 
                  <p style={{ textAlign: 'center', margin: 0 }}>
                    {!TOKEN
                      ? <Button 
                        variant='contained' color='primary'
                        component={Link} to='/user/login'
                        leadIcon='history' label='Login'
                      />
                      : <Button 
                        variant='contained' color='primary'
                        component={Link} to={ROOT_ROUTE}
                        leadIcon='history' label='Go back'
                      />
                    }
                  </p>
                )
              }
              {/* <a */}
              {/*   href="#" */}
              {/*   onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })} */}
              {/* > */}
              {/*   Report feedback */}
              {/* </a> */}
            </>
          )}/>
        </Grid>
      </Grid>
    }

    return this.props.children 
  }
}

export default withStyles(theme => ({
  root: {
    height: '100vh',
    marginTop: 0
  },
  headIcon: {
    display: 'block',
    fontSize: '5rem',
    margin: '10px auto 15px'
  },
  title: {
    textAlign: 'center'
  },
  subtitle: {
    textAlign: 'center',
    ...theme.typography.body1
  }
}))(withRouter(ClaimDetailsErrorBoundary))

