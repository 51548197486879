import React from "react";

import Paper from "components/Mui/Paper";
import SpecialistCardHeader from "./SpecialistCardHeader";
import SpecialistRequestTable from "./SpecialistRequestTable";

import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";

import { graphql } from "relay-runtime";
import { useQuery } from "relay-hooks";

const SpecialistCard = () => {
  const meta = useMetaContext();
  const [reload, setReload] = React.useState(false);
  const [headerReload, setHeaderReload] = React.useState(false);
  window[Symbol.for("__SpecialistCardReload")] = { reload, setReload };
  window[Symbol.for("__SpecialistCardHeaderReload")] = { headerReload, setHeaderReload };
  const { props: data } = useQuery({
    query: SPECIALIST_REQUEST_QUERY,
    variables: {
      where: { claimId: meta.claim.claimId },
      reload,
    },
    dataFrom: "STORE_THEN_NETWORK",
  });
  const { props: action } = useQuery({
    query: SPECIALIST_REQUEST_HEADER_QUERY,
    variables: {
      where: { id: meta.claim.claimId },
      headerReload,
    },
    dataFrom: "STORE_THEN_NETWORK",
  });
  if (!data) return null;

  return (
    <Paper
      style={{ overflow: "auto" }}
      title={
        <SpecialistCardHeader
          createSpecialistRequest={action && action.claimJob.view.actions.createSpecialistRequest}
        />
      }
      content={<SpecialistRequestTable requests={data.specialistRequests} />}
    />
  );
};

const SPECIALIST_REQUEST_QUERY = graphql`
  query SpecialistCardQuery($where: SpecialistRequestWhere!) {
    specialistRequests(where: $where) {
      id
      requestedDate
      specialistRequestId
      specialistCategory {
        name
        specialistCategoryId
      }
      specialist {
        companyName
        companyId
      }

      actions {
        deleteRequest {
          isDisabled
          isDisplay
          label
          name
        }
        updateRequest {
          isDisabled
          isDisplay
          label
          name
        }
      }
    }
  }
`;

const SPECIALIST_REQUEST_HEADER_QUERY = graphql`
  query SpecialistCard_HeaderQuery($where: ENDataEntityKey!) {
    claimJob(where: $where) {
      view {
        actions {
          createSpecialistRequest {
            label
            isDisplay
            isDisabled
          }
        }
      }
    }
  }
`;

export default SpecialistCard;
