import React from 'react'
import Button from 'components/MuiExtended/Button'
import { withStyles } from '@material-ui/core/styles'

const ToolbarButton = ({ classes, icon, color, ...props }) => {
  return (
    <Button
      className={classes.toolbarButton}
      color={color ? color : 'primary'}
      shape='circular'
      variant='contained'
      iconIsolated
      icon={icon}
      {...props}
    />
  )
}

export default withStyles(theme => ({
  toolbarButton: {
    margin: 4
  },
}))(ToolbarButton)