/**
 * @flow
 * @relayHash b18bbdd978631e7a6eba98ae04145e15
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimItemFilter = {|
  id?: ?number,
  claimId: string,
  keyWords?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type PhotoViewAllBodyQueryVariables = {|
  where?: ?ClaimItemFilter
|};
export type PhotoViewAllBodyQueryResponse = {|
  +claimDocuments: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +url: string,
        +description: string,
      |}
    |}>
  |}
|};
export type PhotoViewAllBodyQuery = {|
  variables: PhotoViewAllBodyQueryVariables,
  response: PhotoViewAllBodyQueryResponse,
|};
*/


/*
query PhotoViewAllBodyQuery(
  $where: ClaimItemFilter
) {
  claimDocuments(where: $where) {
    edges {
      node {
        id
        url
        description
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimItemFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "claimDocuments",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ClaimItemFilter"
      }
    ],
    "concreteType": "ClaimDocumentConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "ClaimDocumentEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDocument",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PhotoViewAllBodyQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PhotoViewAllBodyQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "PhotoViewAllBodyQuery",
    "id": null,
    "text": "query PhotoViewAllBodyQuery(\n  $where: ClaimItemFilter\n) {\n  claimDocuments(where: $where) {\n    edges {\n      node {\n        id\n        url\n        description\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5a0df3cba242768e0434c53e1ae616b4';
module.exports = node;
