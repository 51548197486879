import React from 'react'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'

import GetDocumentsPdfMutation from '../GetDocumentsPdfMutation'

export default ({ claimId, portfolios }) => {
  const handlePrintDocuments = async () => {
    const res = await GetDocumentsPdfMutation({ where: {
      claimId,
      portfolios: portfolios || undefined
    }})

    if (res.ok) window.open(
      res.pdf,
      'Site Report Print Preview',
      'resizable, scrollbars, status'
    )
  }

  return (
    <Grid fluid spacing={0} justify='space-between'>
      <Grid item xs={6}>
        View photos
      </Grid>
      <Grid item xs={6}>
        <p style={{ textAlign: 'right', margin: 0 }}>
          <Button variant='outlined' color='primary'
            onClick={handlePrintDocuments}
            leadIcon='print'
            label='Documents'
          />
          <Button color='primary'
            onClick={() => window[Symbol.for('__photoViewAllAction')].handleClose()}
            label='Close'
          />
        </p>
      </Grid>
    </Grid>
  )
}