import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation AcceptJobQuoteMutation($where: ENDataPortfolioKey!, $input: JobQuoteAuthoriseInput) {
    jobQuoteAuthorise(where: $where, input: $input) {
      messages
      success
      result {
        completed
        reviewed
        portfolioType
        subtotal
        supplier {
          companyId
          companyName
        }
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.jobQuoteAuthorise.success,
          message: res.jobQuoteAuthorise.messages
        })
      },
      onError: errors => resolve({
        ok: false,
        success: false,
        message: [ 'Unable to complete this action. Please try again!' ]
      })
    })
  })
}