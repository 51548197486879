import React from "react";

import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";
import usePortfolioFilter from "hooks/usePortfolioFilter";

import { useQuery, graphql } from "relay-hooks";
import Grid from "components/Mui/Grid";

import ClaimDetailsPortfolioFilter from "pages/Claim/ClaimDetails/ClaimDetailsPortfolioFilter";
import ActionBar from "./ActionBar";
import FeeList from "./FeeList";

import { FeeReloadContextProvider } from "./FeeReloadContext";

const Fees = (props) => {
  // HOOKS: get claim meta data
  const meta = useMetaContext();
  const [reload, setReload] = React.useState(true);

  // HOOKS: portfolio filter
  const [portfolio, changePortfolio] = usePortfolioFilter(meta);

  // HOOKS: fees tab query
  const { props: data /* error, retry, cached */ } = useQuery({
    query: graphql`
      query FeesQuery($where: ClaimPortfoliosWhere!) {
        feePortfolios(where: $where) {
          id
          actions {
            name
            actionType
            isDisplay
            isDisabled
          }
          feeCategories {
            id
            name
            claimPortfolioType
            feeItems {
              id
              feeItemId
              toBeInvoicedAmount
              feeType {
                name
              }
              maxAmount
              supplier {
                name: companyName
              }
              authorisedAmount
              description
              actions {
                name
                actionType
                isDisplay
                isDisabled
                id
              }
              feeItemInvoices {
                amount
                feeInvoice {
                  id
                  feeInvoiceId
                  number
                  amount
                  document {
                    url
                  }
                  feeInvoiceStatus {
                    id
                    invoiceStatusId
                    name
                  }
                  actions {
                    name
                    actionType
                    isDisplay
                    isDisabled
                  }
                  feeItemInvoices {
                    amount
                    feeItemInvoiceId
                    feeItemName
                  }
                }
              }
            }
            feeItemsAggregate {
              totalAuthorisedAmount
            }
          }
          feeCategoriesAggregate {
            totalClaimReserve
            totalAmountInvoiced
            toBeInvoiced
            outstandingPayments
            buildingReserve
            contentsReserve
          }
        }
      }
    `,
    variables: {
      where: {
        claimId: meta.claim.claimId,
        claimPortfolioTypes: portfolio ? portfolio : null,
      },
      reload,
    },
    dataFrom: "STORE_THEN_NETWORK",
  });

  if (!data) return "Loading...";

  return (
    <FeeReloadContextProvider value={{ reload, setReload }}>
      <Grid container>
        <Grid item xs={12}>
          <ActionBar actions={data.feePortfolios[0].actions} />
        </Grid>
        <Grid item xs={12} style={{ position: "relative" }}>
          <FeeList data={data} />
          <ClaimDetailsPortfolioFilter
            value={portfolio ? portfolio.toLowerCase() : undefined}
            portfolio={meta.claim}
            changePortfolio={(portfolio) => changePortfolio(portfolio)}
          />
        </Grid>
      </Grid>
    </FeeReloadContextProvider>
  );
};

export default Fees;
