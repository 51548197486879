/**
 * @flow
 * @relayHash ec16b5da771695dee13ea326d8b702bd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessRole = "Adjustor" | "Administrator" | "Agent" | "Builder" | "ContentSupplier" | "Customer" | "Estimator" | "Insurance" | "InternalAB" | "LossAdjuster" | "MultiTenderAssessor" | "Restorer" | "Specialist" | "SystemAdmin" | "UNDEFINED" | "%future added value";
export type ClaimCommunicationCreate = {|
  claimId: string,
  message: string,
  receiverCompanyId: number,
  private: boolean,
  receiverRole: AccessRole,
|};
export type ReplyMessageMutationVariables = {|
  input: ClaimCommunicationCreate
|};
export type ReplyMessageMutationResponse = {|
  +createClaimCommunication: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type ReplyMessageMutation = {|
  variables: ReplyMessageMutationVariables,
  response: ReplyMessageMutationResponse,
|};
*/


/*
mutation ReplyMessageMutation(
  $input: ClaimCommunicationCreate!
) {
  createClaimCommunication(input: $input) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimCommunicationCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createClaimCommunication",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ClaimCommunicationCreate!"
      }
    ],
    "concreteType": "ClaimCommunicationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReplyMessageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ReplyMessageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ReplyMessageMutation",
    "id": null,
    "text": "mutation ReplyMessageMutation(\n  $input: ClaimCommunicationCreate!\n) {\n  createClaimCommunication(input: $input) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1f823ace2c44f5a97db4c9763f1a7f26';
module.exports = node;
