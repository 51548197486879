/**
 * @flow
 * @relayHash 503e086b180728780ed2afbfb0e02aa2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimStatusInput = {|
  statusId: string,
  statusNote: string,
|};
export type ClaimUniqueWhere = {|
  claimId: string
|};
export type AdjusterUpdateStatusMutationVariables = {|
  input: ClaimStatusInput,
  where: ClaimUniqueWhere,
|};
export type AdjusterUpdateStatusMutationResponse = {|
  +adjusterUpdateStatus: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +view: ?{|
        +fields: {|
          +lossAdjusterStatus: {|
            +displayValue: ?string
          |}
        |},
        +actions: {|
          +updateLossAdjusterStatus: {|
            +isDisplay: boolean,
            +id: string,
          |}
        |},
      |}
    |},
  |}
|};
export type AdjusterUpdateStatusMutation = {|
  variables: AdjusterUpdateStatusMutationVariables,
  response: AdjusterUpdateStatusMutationResponse,
|};
*/


/*
mutation AdjusterUpdateStatusMutation(
  $input: ClaimStatusInput!
  $where: ClaimUniqueWhere!
) {
  adjusterUpdateStatus(where: $where, input: $input) {
    success
    messages
    result {
      view {
        fields {
          lossAdjusterStatus {
            displayValue
            id
          }
        }
        actions {
          updateLossAdjusterStatus {
            isDisplay
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimStatusInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimUniqueWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ClaimStatusInput!"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ClaimUniqueWhere!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayValue",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "actions",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimJobAction",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "updateLossAdjusterStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "ActionControl",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isDisplay",
          "args": null,
          "storageKey": null
        },
        (v5/*: any*/)
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdjusterUpdateStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "adjusterUpdateStatus",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJob",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "view",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobView",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "fields",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimJobField",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "lossAdjusterStatus",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FieldInput",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AdjusterUpdateStatusMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "adjusterUpdateStatus",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimJob",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "view",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimJobView",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "fields",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClaimJobField",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "lossAdjusterStatus",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FieldInput",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v6/*: any*/)
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AdjusterUpdateStatusMutation",
    "id": null,
    "text": "mutation AdjusterUpdateStatusMutation(\n  $input: ClaimStatusInput!\n  $where: ClaimUniqueWhere!\n) {\n  adjusterUpdateStatus(where: $where, input: $input) {\n    success\n    messages\n    result {\n      view {\n        fields {\n          lossAdjusterStatus {\n            displayValue\n            id\n          }\n        }\n        actions {\n          updateLossAdjusterStatus {\n            isDisplay\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '269b08510b69f128f576c8afde8e2795';
module.exports = node;
