import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation VariationEditItemMutation(
    $where: ENDataPortfolioKey!,
    $input: QuoteItemUpdate!
  ) {
    updateJobVariationItem(
      where: $where,
      input: $input
    ) {
      messages
      success
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: res => {
        const { success, messages } = res.updateJobVariationItem
        resolve({ ok: true, success, messages })
      },
      onError: errors => resolve({ ok: false, messages: ['Unable to complete this action! Please try again'] })
    })
  })
}
