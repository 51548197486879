/**
 * @flow
 * @relayHash fb7d892d370d8584e8586739395d75af
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ENDataEntityKey = {|
  id: string
|};
export type FollowUpInfoQueryVariables = {|
  where: ENDataEntityKey
|};
export type FollowUpInfoQueryResponse = {|
  +claimJob: ?{|
    +lastFollowUp: ?{|
      +date: ?any,
      +nextFollowUpDate: ?any,
      +note: ?string,
      +user: ?{|
        +userName: string
      |},
    |}
  |}
|};
export type FollowUpInfoQuery = {|
  variables: FollowUpInfoQueryVariables,
  response: FollowUpInfoQueryResponse,
|};
*/


/*
query FollowUpInfoQuery(
  $where: ENDataEntityKey!
) {
  claimJob(where: $where) {
    lastFollowUp {
      date
      nextFollowUpDate
      note
      user {
        userName
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataEntityKey!"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "lastFollowUp",
  "storageKey": null,
  "args": null,
  "concreteType": "FollowUp",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "date",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "nextFollowUpDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "note",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "userName",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FollowUpInfoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FollowUpInfoQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJob",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FollowUpInfoQuery",
    "id": null,
    "text": "query FollowUpInfoQuery(\n  $where: ENDataEntityKey!\n) {\n  claimJob(where: $where) {\n    lastFollowUp {\n      date\n      nextFollowUpDate\n      note\n      user {\n        userName\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '994768e840616f1625986063fd943312';
module.exports = node;
