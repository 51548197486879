import React from 'react'
import Dialog from 'components/Mui/Dialog'
import { Formik, Form } from 'formik'
import Input from 'components/Formik/Input'
import MoneyInput from 'components/Formik/MoneyInput'
import Grid from 'components/Mui/Grid'
import InfoBox from 'components/Mui/InfoBox'
import Button from 'components/Mui/Button'
import { graphql } from 'relay-hooks'
import { useMutation } from 'react-relay-mutation'

import { parseFile, moneyFormat } from 'common/utils'
import { blacklistedExtensions } from 'common/static_data'
import { useFeeReloadContext } from '../../FeeReloadContext.js'

const mutation = graphql`
  mutation FeeInvoiceUploadDialogMutation($input: FeeInvoiceUploadInput!, $where: FeeInvoiceUploadWhere!) {
    feeInvoiceUpload(input: $input, where: $where) {
      messages
      success
    }
  }
`

export default ({symbol, feeItemId, feeDescription, toBeInvoicedAmount}) => {
  let inputRef = React.useRef(null)

  const snackbar = window[Symbol.for('__snackbar')]

  const [uploadFeeInvoice] = useMutation(mutation)
  const { reload, setReload } = useFeeReloadContext()

  return (
    <Dialog
      noForm
      noActions
      symbol={symbol}
      title="Fee Invoice Upload"
      content={
        <Formik
          initialValues={{
            invoiceAmount: '',
            invoiceNumber: '',
            description: '',
          }}
          validate={values => {
            let errors = {}

            if(!values.file) errors.file = 'Required!'
            if(!values.description) errors.description = 'Required!'

            if(!values.invoiceAmount) errors.invoiceAmount = 'Required!'
            if(!values.invoiceNumber) errors.invoiceNumber = 'Required!'

            if(parseFloat(values.invoiceAmount) > parseFloat(toBeInvoicedAmount)) errors.invoiceAmount = "Exceed maximum amount"

            if(values.description.length > 200) errors.description = 'Maximum character limit is 200'
            if(values.invoiceNumber.length > 200) errors.invoiceNumber = 'Maximum character limit is 200'
            if(String(values.invoiceAmount).length > (9 + 3)) errors.invoiceAmount = 'Maximum character limit is 9'
            const { name, size } = inputRef.current.files[0]
            if(blacklistedExtensions.find(e => e.toUpperCase() === `.${name.split('.')[name.split('.').length - 1]}`.toUpperCase())) {
              errors.file = 'File extension is not supported'
            }

            if(size > 31457280) errors.file = 'Maximum file size is 30MB'
            if(size < 1) errors.file = 'Minimum file size is 1 byte'

            if(name.length > 60) errors.file = 'Maximum file name length is 60'
            if(name.length < 5) errors.file = 'Minimum file name length is 5'

            return errors
          }}
          onSubmit={(values, actions) => {
            setTimeout(async () => {
              const submitValues = {...values}
              const { ok, fileBase64, fileName } = await parseFile(inputRef.current)
              if(ok) {
                delete submitValues.file
                const res = await uploadFeeInvoice({
                  variables: {
                    input: {
                      ...submitValues,
                      invoiceAmount: parseFloat(submitValues.invoiceAmount),
                      fileBase64,
                      fileName,
                    },
                    where: {
                      feeItemIds: [feeItemId]
                    }
                  }
                })
                actions.resetForm({
                  file: '',
                  invoiceAmount: '', invoiceNumber: '',
                  description: '',
                })
                if(res) {
                  snackbar.toggleOpen({
                    message: res.feeInvoiceUpload.messages[0]
                  })
                  setReload(!reload)
                  if(window[Symbol.for(symbol)]) {
                    window[Symbol.for(symbol)].handleClose()
                  }
                } else {
                  snackbar.toggleOpen({
                    message: 'Action has not succeed'
                  })
                }
                actions.setSubmitting(false)
              }
            }, 400)
          }}
        >
          {
            ({ isSubmitting, values, touched, setFieldValue }) => {
              return (
                <Form>
                  <Grid fluid justify='flex-end'>
                    {[
                      {
                        col: 6, label: 'Fee Type',
                        text: feeDescription, disabled: isSubmitting,
                        component: InfoBox, name: 'feeType',
                        unmountOn: false
                      },
                      {
                        col: 6, label: 'File to Upload',
                        component: Input, name: 'file',
                        inputRef,
                        leadIcon: 'attach_file',
                        type: 'file', required: true,
                      },
                      {
                        col: 4, label: 'Amount of Invoice',
                        component: MoneyInput, name: 'invoiceAmount',
                        required: true,
                        unmountOn: false
                      },
                      {
                        col: 2, label: 'Maximum amount',
                        component: InfoBox, text: moneyFormat(toBeInvoicedAmount),
                        unmountOn: false
                      },
                      {
                        col: 6, label: 'Invoice number',
                        component: Input, name: 'invoiceNumber',
                        required: true,
                        unmountOn: false
                      },
                      {
                        col: 12, label: 'File Description',
                        component: Input, name: 'description',
                        type: 'text', multiline: true,
                        rowsMax: 5, rows: 3,
                        required: true,
                      },
                    ].map(({col, component: Component, unmountOn, readOnly=false, ...rest}, key) => (
                      !unmountOn && <Grid item xs={col} key={key}>
                        {Component && <Component readOnly={readOnly} {...rest}/>}
                      </Grid>
                    ))}
                    <Grid xs={1}>
                      <Button fullWidth label='Close'
                        disabled={isSubmitting}
                        onClick={() => {
                          if(window[Symbol.for(symbol)]){
                            window[Symbol.for(symbol)].handleClose()
                          }
                        }}
                      />
                    </Grid>
                    <Grid xs={1}>
                      <Button
                        fullWidth
                        disabled={isSubmitting}
                        label='Save'
                        type='submit'
                        variant='contained'
                        color='primary'
                      />
                    </Grid>
                  </Grid>
                </Form>
              )
            }
          }
        </Formik>
      }
    />
  )
}