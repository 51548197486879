import React, { Component } from "react";
import { QueryRenderer, createFragmentContainer, graphql } from "react-relay";
import environment from "common/relay";

import CheckboxGroup from "components/Formik/CheckboxGroup";
import Paper from "components/Mui/Paper";
import Grid from "components/Mui/Grid";

const query = graphql`
  query QuotingRestorersCardQuery($where: ClaimParticipantIndex) {
    claimParticipants(where: $where) {
      companyName
      companyId
      requested
      id
    }
  }
`;
class QuotingRestorersCard extends Component {
  render() {
    const { claim, classes, setFieldValue, claimId } = this.props;
    if (!claim) return "Loading...";
    if (!claim.restoration) return null;
    return (
      <Grid item xs={6}>
        <Paper
          title="Quoting Restorers"
          contentClass={classes.supplierList}
          content={
            <QueryRenderer
              environment={environment}
              query={query}
              variables={{
                where: {
                  claimId: claimId,
                  portfolioTypes: "Restoration",
                  participantStatus: "RequestedAndAvailable",
                  participantType: "QuotingSupplier",
                },
              }}
              render={({ error, props }) => {
                if (!props) return "Loading...";
                const jobSuppliers = props.claimParticipants;
                if (!jobSuppliers) return "";

                // const selectSuppliers = jobSuppliers.map((x) =>
                //   String(x.companyId)
                // );
                const selectSuppliers = jobSuppliers.map((x) => {
                  if (x.requested) {
                    return String(x.companyId);
                  }
                });

                // const noScopeOptions = props.claimFilterOptions.filter(
                //   (x) =>
                //     x.value !==
                //     String(claim.restoration.scopingSupplier.companyId)
                // );
                const noScopeOptions = props.claimParticipants.map((x) => {
                  return {
                    label: x.companyName,
                    value: String(x.companyId),
                  };
                });

                return (
                  <QuotingRestorersCheckbox
                    selectSuppliers={selectSuppliers}
                    noScopeOptions={noScopeOptions}
                    setFieldValue={setFieldValue}
                  />
                );
              }}
            />
          }
        />
      </Grid>
    );
  }
}

class QuotingRestorersCheckbox extends Component {
  componentDidMount = () => {
    this.props.setFieldValue("quotingRestorer", this.props.selectSuppliers);
  };
  render() {
    return (
      <CheckboxGroup
        name="quotingRestorer"
        options={this.props.noScopeOptions}
      />
    );
  }
}

export default createFragmentContainer(
  QuotingRestorersCard,
  graphql`
    fragment QuotingRestorersCard_claim on ClaimJob {
      insurer {
        companyId
      }
      incidentDetail {
        riskAddress {
          postcode
        }
      }
      restoration {
        scopingSupplier {
          companyId
        }
        jobSuppliers {
          supplier {
            companyId
            companyName
          }
        }
      }
    }
  `
);
