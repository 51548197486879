import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import cn from "clsx";
import Grid from "components/Mui/Grid";
import CircularProgress from "components/Mui/CircularProgress";
import ButtonBase from "@material-ui/core/ButtonBase";
import Paper from "components/Mui/Paper";
import DashboardKpiDetails from "./DashboardKpiDetails/DashboardKpiDetailsContainer";

// import 'components/Mui/Fabs/Fab/animate.css'

class DashboardKpi extends Component {
  state = {
    activeCircular: "CurrentRedKPI",
  };
  render() {
    const { classes, widgetData, isShowChart } = this.props;
    const kpiWidget =
      widgetData.dashboardWidgets &&
      widgetData.dashboardWidgets.filter((e) => e.viewName === "KPIGear")[0];
    if (!kpiWidget) {
      return "No widget";
    }
    const data = kpiWidget.data;
    const kpiData = data.map((e) => ({
      color:
        e.internalFilterName === "CurrentGreenKPI"
          ? "rgb(39, 198, 82)"
          : e.internalFilterName === "CurrentYellowKPI"
          ? "rgb(255, 232, 58)"
          : e.internalFilterName === "CurrentRedKPI"
          ? "rgb(255, 104, 104)"
          : "rgb(39, 198, 82)",
      internalFilterName: e.internalFilterName,
      value: e.values[0],
    }));
    return (
      <Paper
        title="KPI Alerts"
        content={
          <Grid fluid spacing={8}>
            <Grid item xs={3}>
              <Grid fluid spacing={0} alignItems="center">
                {kpiData
                  .reverse()
                  .map(({ color, value, internalFilterName }, key) => (
                    <Grid item xs={12} className={classes.center} key={key}>
                      <ButtonBase
                        key={key}
                        className={classes.round}
                        onClick={(e) =>
                          this.handleChangeActiveCircular(internalFilterName)
                        }
                      >
                        <CircularProgress
                          className={cn(
                            classes.circularShadow,
                            {
                              [classes.active]:
                                internalFilterName ===
                                this.state.activeCircular,
                            }
                            // {'animated pulse infinite mainFab': internalFilterName === this.state.activeCircular}
                          )}
                          overrideColor={color}
                          variant="static"
                          value={value}
                        />
                      </ButtonBase>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <DashboardKpiDetails
                filter={this.props.filter}
                activeCircular={this.state.activeCircular}
                isShowChart={isShowChart}
              />
            </Grid>
          </Grid>
        }
      />
    );
  }
  handleChangeActiveCircular = (internalFilterName) => {
    this.setState({ activeCircular: internalFilterName });
  };
}
export default withStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
  },
  round: {
    borderRadius: "999px",
  },
  active: {
    backgroundColor: theme.palette.grey[300],
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12) !important",
  },
  circularShadow: {
    boxShadow:
      "0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)",
    borderRadius: "999px",
  },
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    alignItems: "center",

    "&:nth-child(even)": {
      background: theme.palette.grey[100],
    },
    "&:hover": {
      background: theme.palette.grey[300],
    },
  },
}))(DashboardKpi);
