import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation AddClaimMutation($input: ClaimJobCreate!) {
    createClaimJob(input: $input) {
      success
      messages
      fieldErrors {
        fieldName
        level
        message
      }
      result{
        claimId
      }
    }
  }
`

export default input => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables: { input },
        onCompleted: res => {
          const { messages, fieldErrors, result, success } = res.createClaimJob
          resolve({ ok: true, messages, fieldErrors, result, success })
        },
        onError: errors => {
          resolve({
            ok: false,
            messages: ['Unable to add claim! Please try again.']
          })
        }
      }
    )
  })
}