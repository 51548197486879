import React from 'react'

import Grid from 'components/Mui/Grid'
import ReportComponent from './ReportComponent'
import ActionBar from './ActionBar'

export default () => {

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <ActionBar />
        </Grid>
      </Grid>
      <Grid container>
        <Grid fluid>
          {report.components.map(({ xs = 6, ...component }, index) => (
            <Grid key={index} item xs={xs}>
              <ReportComponent {...component} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

const report = {
  components: [
    {
      title: 'Cover Page',
      fields: [
        {
          componentType: 'TEXT_FIELD', xs: 12,
          label: 'Title',  value: 'Technical Assessment',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 12,
          label: '', value: 'This report has been prepare for the sole use of icare HBCF for the purpose of technical evaluation of alleged defects claimed by the insured.'
        },
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'Cover photo', value: 'Chose File',
          leadIcon: 'attach_file'
        },
      ]
    },
    {
      title: 'Job Details',
      fields: [
        {
          xs: 6,
          label: 'Building Owner', value: 'John Smith'
        },
        {
          xs: 6,
          label: 'Address', value: '33 Smith Street, Smithfield NSW 2000'
        },
        {
          xs: 6,
          label: 'Client', value: 'Gallagher Bassett'
        },
        {
          xs: 6,
          label: '', value: ''
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Our Reference', value: 'ABC 00145',
          leadIcon: 'insert_link'
        },
        {
          xs: 6,
          label: 'Client Reference', value: 'HBCF123456'
        },
      ]
    },
    {
      title: 'Introduction',
      fields: [
        {
          xs: 12,
          label: 'Claim Type', value: 'Settlement Damage'
        },
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'Attendees', value: '_'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Inspection date', value: '25/06/2019',
          leadIcon: 'calendar_today'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Inspection date', value: '11:00 AM',
          leadIcon: 'access_time'
        },
      ]
    },
    {
      title: `Building Consultant's Details`,
      fields: [
        {
          xs: 6,
          label: 'Name, Phone', value: 'Building Consultant: 0404040404'
        },
        {
          xs: 6,
          label: 'Reference number', value: 'ABC 00145'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Email address', value: 'consultant@abc.com',
          leadIcon: 'email'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'License number', value: 'ABC 00145',
          leadIcon: 'insert_link'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Consultant Appointed Date', value: '25/06/2019',
          leadIcon: 'calendar_today'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Owner Appointed Date', value: '25/06/2019',
          leadIcon: 'calendar_today'
        },
      ]
    },
    {
      title: `Project & Contract Details`,
      fields: [
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Contract Date', value: '25/06/2019',
          leadIcon: 'calendar_today'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Project Completed?', value: 'Completed',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Contract Type', value: 'NSW Fair Trading - Fixed Price',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Occupation Date', value: '25/06/2019',
          leadIcon: 'calendar_today'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Contract Amount', value: '47,668.00',
          leadIcon: 'attach_money'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: `Builder's Name`, value: 'XXX Building Services Pty Ltd',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Contract Amount', value: '-',
          leadIcon: 'attach_money'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: `Builder's License Number`, value: 'XXX CCC',
          leadIcon: 'assignment_ind'
        },
      ]
    },
    {
      title: `Building Description`,
      fields: [
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Ground Floor', value: 'Concrete Slab',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Occupancy', value: '25/06/2019',
          leadIcon: 'calendar_today'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'First Floor', value: 'Timber Framed',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Building Usage', value: 'Residential',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'External Cladding', value: 'Brick Veneer & Weatherboard',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: `Building Classification`, value: 'Class 1a(house) and Class 10a(Garage)',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Roof Cladding', value: 'Concrete Tiles',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: `Orientation`, value: 'East - Sagittarius Drive',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Storey Quantity', value: 'Two',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: `Site Topography`, value: 'Minor downwoard slope from West to East',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Condition', value: 'Good',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: `Project Stage`, value: 'Complete',
          leadIcon: 'assignment_ind'
        },
      ]
    },

    {
      title: `Claim Details`,
      fields: [
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'Claim Reported Date', value: '12/04/2019',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Property Age when claim reported', value: '1 year 8 months',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Property Age when claim first reported', value: '1 year 8 months',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Is property in time for Major Defects', value: 'Yes',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Is property in time for other defects', value: 'Yes',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Contract Price follow Industry Standard', value: 'Yes',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Contract Price Mesure Method', value: 'Rawlinsons Construction Cost Guide',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Pre-payments made?', value: 'N/a - Contract paid in full',
          leadIcon: 'assignment_ind'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Overpayments identified?', value: 'N/a - Contract paid in full',
          leadIcon: 'assignment_ind'
        }
      ]
    },
    {
      title: `Building Description`,
      fields: [
        {
          xs: 12,
          label: '', value: 'With reference to the instruction letter from John Smith, Claims Consultant, Gallagher Bassett Service Pty Ltd, dated',
        },
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'More Details', value: '-',
          multiline: true, rows: 13
        }
      ]
    },
    {
      title: `Professional Services Engaged by Claimant`,
      fields: [
        {
          xs: 12, componentType: 'BUTTON', variant: 'outlined',
          label: 'New Service', leadIcon: 'library_add'
        },
        {
          xs: 6,
          label: 'Engineer', value: 'Details not provided.',
        },
        {
          xs: 6,
          label: 'Certifier', value: 'Local Group',
        },
        {
          xs: 6,
          label: 'Architect', value: 'Huxley Homes',
        }
      ]
    },
    {
      title: `Areas of Non-Compliance to BCA`,
      fields: [
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'More Details', value: '-',
          multiline: true, rows: 6
        }
      ]
    },
    {
      title: `Outstanding Mandatory Certification Requirements`,
      fields: [
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'More Details', value: '-',
          multiline: true, rows: 5
        }
      ]
    },
    {
      title: `Documents relied on`,
      fields: [
        {
          xs: 12, componentType: 'BUTTON', variant: 'outlined',
          label: 'New Document', leadIcon: 'library_add'
        },
        {
          xs: 6,
          label: 'Certificate of Insurance',
        },
        {
          xs: 6,
          label: 'Final Occupational Certificate',
        },
        {
          xs: 6,
          label: 'Sagittarius Building Report Dated 22/11/2016',
        }
      ]
    },

    {
      title: `References`,
      fields: [
        {
          xs: 12, componentType: 'BUTTON', variant: 'outlined',
          label: 'New Reference', leadIcon: 'library_add'
        },
        {
          xs: 6,
          label: 'Certificate of Insurance',
        },
        {
          xs: 6,
          label: 'Final Occupational Certificate',
        },
        {
          xs: 6,
          label: 'Sagittarius Building Report Dated 22/11/2016',
        }
      ]
    },
    {
      title: `Opinion in relation to Section 95(2) Report`,
      fields: [
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'More Details', value: '-',
          multiline: true, rows: 5
        }
      ]
    },
    {
      title: `Curriculum Vitae`,
      fields: [
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'More Details', value: '-',
          multiline: true, rows: 23
        }
      ]
    },
    {
      title: `Supplementary Commercial In-Confidence Report`,
      fields: [
        {
          xs: 12,
          label: '', value: 'The Report below is ancillary to Technical Assessment and Inspection Report date 06/08/2019',
        },
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'Policy', value: '-',
          multiline: true, rows: 3
        },
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'Non-Completion', value: '-',
          multiline: true, rows: 3
        },
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'Additional Comments', value: '-',
          multiline: true, rows: 3
        },
        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'Comment on Potential Recovery', value: '-',
          multiline: true, rows: 3
        },
      ]
    },

    {
      title: `Schedule of Items`, xs: 12,
      fields: [
        {
          xs: 12, componentType: 'BUTTON', variant: 'outlined',
          label: 'New Item', leadIcon: 'library_add'
        },

        {
          xs: 3, componentType: 'TEXT_FIELD',
          label: 'Item Number', value: '1'
        },
        {
          xs: 3, componentType: 'TEXT_FIELD',
          label: 'Location', value: 'Entry'
        },
        {
          xs: 3, componentType: 'TEXT_FIELD',
          label: 'Cross Ref', value: 'BPI Group reporrt dated 25/10/18'
        },
        {
          xs: 3, componentType: 'TEXT_FIELD',
          label: 'Loss Type', value: 'Entry'
        },

        {
          xs: 3, componentType: 'TEXT_FIELD',
          label: 'Completion Status', value: 'N/a'
        },
        {
          xs: 3, componentType: 'TEXT_FIELD',
          label: 'Recommendation', value: 'Accept'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Item Photo(s)', value: 'Choose File'
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Observation', value: 'The inspection revealed the architrave adjacent to the front entry door',
          multiline: true, rows: 3
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Cause', value: 'Failure to neatly or re-orientate the light switch with due care and skill',
          multiline: true, rows: 3
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Breach(es)', value: 'The Home Building Act 1989 - Part 2C',
          multiline: true, rows: 3
        },
        {
          xs: 6, componentType: 'TEXT_FIELD',
          label: 'Denial Reason', value: 'N/a',
        },

        {
          xs: 12, componentType: 'TEXT_FIELD',
          label: 'Suggested Scope of Works',
          value: 'Allow to Entry',
        },
      ]
    },
  ]
}