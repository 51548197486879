import React, { Component, createRef } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import Grid from 'components/Mui/Grid'
import { withStyles } from '@material-ui/core/styles'

import Icon from '@material-ui/core/Icon'
import Dialog from 'components/Mui/Dialog'

class LossAdjusterListItem extends Component {
  state = {}
  document = createRef()

  render() {
    const { classes, item } = this.props
    const ext = item.url.substring(item.url.lastIndexOf('.') + 1, item.url.length).toLowerCase()
    const dialogable = ['pdf', 'mp4', 'm4v']
    const video = ['mp4', 'm4v']
    const imageFormats = ['jpg', 'jpeg', 'png', 'gif']

    return (
      <>
        <Grid fluid className={classes.listItem}>
          <Grid item xs={2} className={classes.alignCenter}>
            <span>{item.uploadDate}</span>
          </Grid>
          <Grid className={classes.alignCenter} item xs={2}>
            {item.company ? item.company.companyName : '-'}
          </Grid>
          <Grid className={classes.alignCenter} item xs={1}>{item.reportType && item.reportType.reportTypeName}</Grid>
          <Grid className={classes.alignCenter} item xs={1}>
            {
              dialogable.indexOf(ext) === -1 && imageFormats.indexOf(ext) === -1
                ? <a target='_blank' href={item.url} rel='noopener noreferrer'><Icon>description</Icon></a>
                : <Icon onClick={this.openDocument}>description</Icon>
            }
          </Grid>
          <Grid className={classes.alignCenter} item xs={1}>{item.private ? 'Private' : 'Public' }</Grid>
          <Grid className={item.description ? classes.alignLeft : classes.alignCenter} item xs={5}>
            {item.description ? item.description : '-'}
          </Grid>
        </Grid>

        <Dialog
          innerRef={this.document}
          title='Document'
          content={
            <>
              {ext === 'pdf' && <iframe title='pdfviewer' src={item.url} style={{
                width:'80vw',
                height: '70vh',  
              }}></iframe>}
              {video.indexOf(ext) !== -1 &&  <video controls style={{ width:'70vw', height: '90%' }} src={item.url}/>}
              {imageFormats.indexOf(ext) !== -1 && <img 
                  className={classes.documentItem} src={item.url}
                  alt='endata'
                />
              }
            </>
          }
        />
      </>
    )
  }

  openDocument = () => {
    this.document.current.handleOpen()
  }
}

export default createFragmentContainer(
  withStyles(theme => ({
    listItem: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      '&:nth-child(even)': {
        background: theme.palette.grey[100]
      },
      '&:hover': {
        background: theme.palette.grey[300]
      },
      position: 'relative',
    },
    alignLeft: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    alignCenter: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },
    documentItem: {
      width: '100%'
    }
  }))(LossAdjusterListItem),
  graphql`
    fragment LossAdjusterListItem_item on ClaimLossAdjusterDocument {
      id
      uploadDate
      company {
        companyName
      }
      reportType {
        reportTypeName
      }
      url
      private
      description
    }
  `
)