import React from "react";
import environment from "common/relay";
import { graphql, QueryRenderer } from "react-relay";

import { Formik, Form } from "formik";
import Input from "components/Formik/Input";

import Dialog from "components/Mui/Dialog";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon";
import AllocateScopeSupplierActionMutation from "./AllocateScopeSupplierActionMutation";

const query = graphql`
  query AllocateScopeSupplierActionQuery(
    $buildersWhere: ClaimParticipantIndex
    $restorersWhere: ClaimParticipantIndex
  ) {
    Building: claimParticipants(where: $buildersWhere) {
      label: companyName
      value: companyId
      portfolioType
      requested
      companyRole
    }
    Restoration: claimParticipants(where: $restorersWhere) {
      label: companyName
      value: companyId
      portfolioType
      requested
      companyRole
    }
  }
`;

class AllocateScopeSupplierAction extends React.Component {
  snackbar = window[Symbol.for("__snackbar")];
  render() {
    const { meta, portfolioType } = this.props;

    return (
      <Dialog
        title="Allocate supplier"
        noForm
        symbol="__allocateScopeSupplierAction"
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              buildersWhere: {
                claimId: meta.claim.claimId,
                portfolioTypes: "Building",
                participantStatus: "RequestedAndAvailable",
                participantType: "SupplierExcludeScoping",
              },
              restorersWhere: {
                claimId: meta.claim.claimId,
                portfolioTypes: "Restoration",
                participantStatus: "RequestedAndAvailable",
                participantType: "SupplierExcludeScoping",
              },
            }}
            render={({ error, props }) => {
              if (!props) return "Loading...";
              const defaultSupplierId = meta.claim.insurer.isAutoSelectSupplier
                ? props[portfolioType].length
                  ? props[portfolioType][0].value
                  : ""
                : null;
              return (
                <Grid container justify="space-evenly" alignItems="stretch">
                  <Grid item xs>
                    <Grid
                      fluid
                      spacing={8}
                      justify="center"
                      style={{ height: "100%" }}
                    >
                      <Grid item xs={12}>
                        <Grid fluid spacing={8} justify="center">
                          <ClaimTypeIcon
                            style={{
                              fontSize: "2.5rem",
                            }}
                            type={portfolioType}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Formik
                          initialValues={{
                            supplierId: defaultSupplierId,
                            note: "",
                          }}
                          validate={(values) => {
                            let errors = {};

                            if (!values.supplierId)
                              errors.supplierId = "Required!";
                            if (!values.note) errors.note = "Required!";

                            return errors;
                          }}
                          onSubmit={(values, actions) => {
                            setTimeout(async () => {
                              values.supplierId = parseInt(values.supplierId);
                              const variables = {
                                input: {
                                  ...values,
                                  availableForQuoting: true,
                                },
                                where: {
                                  id: meta.claim.claimId,
                                  portfolioType: portfolioType,
                                },
                              };

                              const res =
                                await AllocateScopeSupplierActionMutation(
                                  variables,
                                  meta.claim.id
                                );
                              if (res.ok) {
                                this.snackbar.toggleOpen({
                                  message: res.message,
                                });
                                if (res.success) {
                                  const refreshClaimDetails =
                                    window[Symbol.for("__refreshClaimDetails")];
                                  if (refreshClaimDetails)
                                    refreshClaimDetails();
                                }
                              }
                            }, 400);
                          }}
                        >
                          {({ isSubmitting, values }) => {
                            return (
                              <Form>
                                <Grid fluid spacing={8} justify="center">
                                  <Grid item xs={12}>
                                    <Input
                                      required
                                      name="supplierId"
                                      label={
                                        portfolioType === "Building"
                                          ? "Builder"
                                          : portfolioType === "Restoration"
                                          ? "Restorer"
                                          : ""
                                      }
                                      options={props[portfolioType]}
                                      select
                                    />
                                    <Input
                                      name="note"
                                      label="Reason"
                                      required
                                    />
                                  </Grid>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    label="Reallocate"
                                    disabled={isSubmitting}
                                  />
                                </Grid>
                              </Form>
                            );
                          }}
                        </Formik>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }}
          />
        }
      />
    );
  }
}

export default AllocateScopeSupplierAction;
