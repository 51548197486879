/**
 * @flow
 * @relayHash edbd0d99a173d85980305f8a5619e62a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimPortfoliosUniqueWhere = {|
  claimId: string,
  claimPortfolioType: PortfolioType,
|};
export type ReleaseSiteReportInput = {|
  releaseToSupplier: boolean
|};
export type ReleaseSiteReportMutationVariables = {|
  where: ClaimPortfoliosUniqueWhere,
  input?: ?ReleaseSiteReportInput,
|};
export type ReleaseSiteReportMutationResponse = {|
  +releaseSiteReport: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type ReleaseSiteReportMutation = {|
  variables: ReleaseSiteReportMutationVariables,
  response: ReleaseSiteReportMutationResponse,
|};
*/


/*
mutation ReleaseSiteReportMutation(
  $where: ClaimPortfoliosUniqueWhere!
  $input: ReleaseSiteReportInput
) {
  releaseSiteReport(where: $where, input: $input) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimPortfoliosUniqueWhere!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReleaseSiteReportInput",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "releaseSiteReport",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ReleaseSiteReportInput"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ClaimPortfoliosUniqueWhere!"
      }
    ],
    "concreteType": "ClaimJobPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReleaseSiteReportMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ReleaseSiteReportMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ReleaseSiteReportMutation",
    "id": null,
    "text": "mutation ReleaseSiteReportMutation(\n  $where: ClaimPortfoliosUniqueWhere!\n  $input: ReleaseSiteReportInput\n) {\n  releaseSiteReport(where: $where, input: $input) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '07a1ad124355102072c53b448e2999a9';
module.exports = node;
