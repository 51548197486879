/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type LossAdjusterListItem_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type LossAdjusterList$ref: FragmentReference;
export type LossAdjusterList = {|
  +claimLossAdjusterDocuments: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +$fragmentRefs: LossAdjusterListItem_item$ref
      |}
    |}>
  |},
  +claimJob: ?{|
    +view: ?{|
      +actions: {|
        +makeLossAdjusterInitialCall: {|
          +isDisabled: boolean,
          +isDisplay: boolean,
          +label: string,
        |},
        +makeLossAdjusterAppointment: {|
          +isDisabled: boolean,
          +isDisplay: boolean,
          +label: string,
        |},
      |}
    |}
  |},
  +$refType: LossAdjusterList$ref,
|};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "isDisabled",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "isDisplay",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "label",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "LossAdjusterList",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "claimLossAdjusterDocuments"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "after",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "where",
      "type": "ClaimItemFilter"
    },
    {
      "kind": "RootArgument",
      "name": "claimJobWhere",
      "type": "ENDataEntityKey!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "claimLossAdjusterDocuments",
      "name": "__LossAdjusterList_claimLossAdjusterDocuments_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimLossAdjusterDocumentConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimLossAdjusterDocumentEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ClaimLossAdjusterDocument",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "LossAdjusterListItem_item",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "claimJob",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "claimJobWhere",
          "type": "ENDataEntityKey!"
        }
      ],
      "concreteType": "ClaimJob",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "view",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimJobView",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "actions",
              "storageKey": null,
              "args": null,
              "concreteType": "ClaimJobAction",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "makeLossAdjusterInitialCall",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ActionControl",
                  "plural": false,
                  "selections": (v0/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "makeLossAdjusterAppointment",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ActionControl",
                  "plural": false,
                  "selections": (v0/*: any*/)
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '2d6355740f4a9e86aa368485a158ce27';
module.exports = node;
