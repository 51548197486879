import React from 'react'
import { withRouter } from 'react-router-dom'
import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import Button from 'components/Mui/Button'
import AcceptOfferMutation from '../../ClaimDetailsAction/AcceptOfferMutation'
import DeclineOfferMutation from '../../ClaimDetailsAction/DeclineOfferMutation'
import { getSafe } from 'common/utils'

class JobInfoActionBar extends React.Component {
  snackbar = window[Symbol.for('__snackbar')]

  componentDidMount = () => {
    const { meta, claim } = this.props

    const hasAllocateBuilder = (
      getSafe(() => claim.building.reallocate, false)
      || getSafe(() => claim.restoration.reallocate, false)
    )
    if (meta.user.type.isAdmin && hasAllocateBuilder) {
      this.snackbar.toggleOpen({
        message: `Please immediately allocate the builder by using the
          "Allocate Supplier" button`,
        timeout: 4000,
      })
    }
  }

  render() {
    const { supplier, meta, isSubmitting, claim } = this.props

    const hasAllocateBuilder = (
      getSafe(() => claim.building.reallocate, false)
      || getSafe(() => claim.restoration.reallocate, false)
    )

    return (
      <Paper>
        <Grid fluid justify='space-between'>
          <Grid item xs={3}>
            <Button fullWidth label='Next Step'
              variant='outlined' color='primary'
              onClick={this.handleNextStep}
            />
          </Grid>
          {Boolean(Boolean(Object.keys(supplier).length) && !supplier.quote) && (
            <>
              <Grid item xs={2}>
                <Button
                  fullWidth
                  label='Accept Offer' variant='contained'
                  color='primary'
                  onClick={e => this.handleAcceptOffer({
                    acceptOfferId: parseInt(supplier.jobSupplierId),
                    acceptOfferPortfolio: supplier.portfolio
                  })}
                />
              </Grid>
              <Grid item xs={2}>
              <Button
                fullWidth
                label='Decline Offer' variant='outlined'
                color='primary'
                onClick={e => this.handleDeclineOffer({
                  id: supplier.jobSupplierId,
                  portfolioType: supplier.portfolio
                })}
              />
            </Grid>
            </>
          )}
          {
            meta.user.type.isAdmin && hasAllocateBuilder && (
              <Grid item xs={2}>
                <Button
                  fullWidth
                  label='Allocate Supplier' variant='contained'
                  color='primary'
                  onClick={e => {
                    const allocateBuilder = window[Symbol.for('__allocateBuilderAction')]
                    if(allocateBuilder) allocateBuilder.handleOpen()  
                  }}
                />
              </Grid>
            )  
          }
          <Grid item xs={3}>
            {Boolean(meta.user.type.isAdmin&&!meta.claim.view.actions.updateClaim.isDisabled) &&
              <Button fullWidth label='Save'
                variant='outlined' color='primary'
                type='submit'
                disabled={isSubmitting}
              />
            }
          </Grid>
        </Grid>
      </Paper>
    )
  }

  handleNextStep = () => {
    if(window[Symbol.for('__nextStepAction')]){
      window[Symbol.for('__nextStepAction')].handleOpen()
    }
  }

  handleDeclineOffer = async where => {
    const res = await this.snackbar.toggleOpen({
      message: 'Do you want to Decline this offer now?',
      firstButton: 'Decline',
      secondButton: 'Cancel',
      type: 'confirm'
    })
    if(res.ok) {
      const mutationRes = await DeclineOfferMutation({
        where,
      })
      if(mutationRes.ok) {
        this.snackbar.toggleOpen({
          message: mutationRes.messages[0]
        })
        if(mutationRes.success) {
          this.props.history.push('/claims')
        }
      }
    }
  }

  handleAcceptOffer = async ({acceptOfferId, acceptOfferPortfolio}) => {
    const res = await this.snackbar.toggleOpen({
      message: 'Do you want to accept this offer now?',
      firstButton: 'Accept',
      secondButton: 'Cancel',
      type: 'confirm'
    })
    if(res.ok) {
      const mutationRes = await AcceptOfferMutation({
        acceptOfferId, acceptOfferPortfolio,
        claimId: this.props.meta.claim.claimId
      })
      if(mutationRes.success) {
        this.snackbar.toggleOpen({
          message: 'Succeed',
        })
      } else {
        this.snackbar.toggleOpen({
          message: mutationRes.messages[0],
        })
      }
    }
  }
}

export default withRouter(JobInfoActionBar)