import React from "react";
import Grid from "components/Mui/Grid";
import Input from "components/Formik/Input";
import MoneyInput from "components/Formik/MoneyInput";
import FloatInput from "components/Formik/FloatInput";

import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";
import useLineItemOptions from "hooks/useLineItemOptions";

export default ({
  jobQuoteId,
  portfolioType,
  values,
  isSubmitting,
  setFieldValue,
  directsupply,
  quotationType,
}) => {
  const meta = React.useContext(ClaimMetaContext);
  const [isDirectSupply, setIsDirectSupply] = directsupply;

  const variables = {
    location: { portfolioType },
    centre: { portfolioType, quotationType },

    isItem: Boolean(values.costCentreId),
    item: {
      portfolioType,
      costCentreId: values.costCentreId || 0,
      quotationType,
    },
    materialItem: {
      portfolioType,
      costCentreId: values.material_costCentreId || 0,
      quotationType,
    },
  };
  const [locationOptions, centreOptions, itemOptions] = useLineItemOptions(
    variables,
    [portfolioType, values.costCentreId]
  );

  const directSupplyOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  // disabled rule for direct supply
  React.useEffect(() => {
    const current = centreOptions.filter(
      (x) => x.value === values.costCentreId
    );
    setIsDirectSupply(current.length !== 0 && current[0].directSupplier);
  }, [values.costCentreId, centreOptions.length]);

  // default select value for location field
  React.useEffect(() => {
    if (locationOptions[0] && !values.locationId)
      setFieldValue("locationId", locationOptions[0].value);
  }, [locationOptions]);

  // disabled rule for direct supply
  React.useEffect(() => {
    const current = centreOptions.filter(
      (x) => x.value === values.costCentreId
    );
    setIsDirectSupply(current.length !== 0 && current[0].directSupplier);
  }, [values.costCentreId, centreOptions.length]);

  return (
    <>
      {[
        {
          col: 6,
          component: Input,
          label: "Location",
          name: "locationId",
          select: true,
          options: locationOptions,
          isShrink: true,
          // disabled: true,
        },
        {
          col: 6,
          component: Input,
          label: "Cost Centre",
          name: "costCentreId",
          select: true,
          required: true,
          isUnselect: true,
          options: centreOptions,
          isShrink: true,
        },
        {
          col: 9,
          component: Input,
          label: "Description",
          name: "costItemId",
          required: true,
          select: true,
          options: itemOptions,
          disabled: !values.costCentreId,
          isShrink: true,
        },
        {
          col: 3,
          component: Input,
          label: "Direct Supply",
          name: "directsupply",
          select: true,
          required: true,
          options: directSupplyOptions,
          disabled: !values.costCentreId || !isDirectSupply,
          unMountOn: !meta.claim.insurer.contentauth || !isDirectSupply,
          isShrink: true,
        },
        {
          col: 12,
          component: Input,
          rows: 3,
          rowsMax: 3,
          label: "More Details",
          name: "lineDescription",
          isShrink: true,
        },

        {
          col: 4,
          component: Input,
          label: "Purchase Price  ",
          name: "purchasePrice",
          isShrink: true,
        },
        {
          col: 4,
          component: Input,
          label: "Age of Item",
          name: "ageOfItem",
          isShrink: true,
        },
        {
          col: 4,
          component: Input,
          label: "Proof of Loss Type  ",
          name: "proofOfLoss",
          isShrink: true,
        },
        {
          col: 4,
          component: FloatInput,
          label: "Qty",
          name: "qty",
          required: true,
        },
        {
          col: 4,
          component: MoneyInput,
          label: "Reserve Inc GST",
          name: "rate",
          required: true,
        },
        {
          col: 4,
          component: FloatInput,
          label: "Policy Item Limit",
          name: "policyLimit",
          required: isDirectSupply,
        },
      ].map(({ col, unMountOn, component: C, ...props }, index) => (
        <Grid item xs={col} key={index}>
          {!unMountOn && <C fullWidth variant="outlined" {...props} />}
        </Grid>
      ))}
    </>
  );
};
