import React from 'react'

const NoCamError = { code: 'NO_CAM_ERROR', message: 'No camera found.' }
const NoMicError = { code: 'NO_MIC_ERROR', message: 'No microphone found.' }

export default () => {
  const [done, setDone] = React.useState(false)
  const [errors, setErrors] = React.useState(null)

  const [isVideoInput, setIsVideoInput] = React.useState(false)
  const [isAudioOutput, setIsAudioOutput] = React.useState(false)

  React.useEffect(() => {
    (async () => {
      let deviceErrors = []

      const videoStream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true })
        .catch(error => null)
      const videoTracks = videoStream ? videoStream.getVideoTracks() : []
      if(videoTracks.length !== 0) {
        setIsVideoInput(true)
      }
      else {
        deviceErrors.push(NoCamError)
      }

      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        .catch(error => null)
      const audioTracks = audioStream ? audioStream.getAudioTracks() : []
      if(audioTracks.length !== 0) {
        setIsAudioOutput(true)
      }
      else {
        deviceErrors.push(NoMicError)
      }

      // stop stream
      if(videoStream) videoStream.getTracks().forEach(track => track.stop())
      if(audioStream) audioStream.getTracks().forEach(track => track.stop())

      setErrors(deviceErrors)
      setDone(true)
    })()
  }, [])

  return {
    done, errors,
    isVideoInput, isAudioOutput
  }
}

// React.useEffect(() => {
//   (async () => {
//     let deviceErrors = []
//     const devices = await navigator.mediaDevices.enumerateDevices()
//
//     const isVideoInput = Boolean(devices.find(device => device.kind === 'videoinput'))
//     setIsVideoInput(isVideoInput)
//     if(!isVideoInput) deviceErrors.push(NoCamError)
//
//     const isAudioOutput = Boolean(devices.find(device => device.kind === 'audiooutput'))
//     setIsAudioOutput(isAudioOutput)
//     if(!isAudioOutput) deviceErrors.push(NoMicError)
//
//     setErrors(deviceErrors)
//     setDone(true)
//   })()
// }, [])