import React, { Component } from 'react'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import EditorInput from 'components/Formik/EditorInput'

import Button from 'components/Mui/Button'

class AddDescriptionCard extends Component {
  state = {
  }
  render() {
    const { handleComplete, data, setFieldValue, isSubmitting,  ...rest } = this.props
    return (
      <Paper
        title='Claim Description'
        {...rest}
        content={
          <Grid fluid spacing={0}>
            <Grid item xs={12}>
              <EditorInput
                name='claimDescription'
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Grid fluid spacing={0} justify='flex-end'>
                <Grid item xs={2}>
                  <Button label='Submit' type='submit'
                    variant='contained' color='primary'
                    fullWidth
                    disabled={this.props.isSubmitting}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    )
  }
}

export default AddDescriptionCard
