/**
 * @flow
 * @relayHash 882793fe3c10f5941b004636eb43c267
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobWorkOrderIndex = {|
  id?: ?$ReadOnlyArray<number>,
  claimId?: ?string,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type EnterDatesQueryVariables = {|
  where: JobWorkOrderIndex
|};
export type EnterDatesQueryResponse = {|
  +works: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +costCentres: ?$ReadOnlyArray<?{|
          +firstLineItemId: number,
          +costCentre: ?{|
            +costCentreId: number,
            +costCentreName: string,
          |},
          +subtotal: ?number,
          +startDate: ?any,
          +completeDate: ?any,
        |}>,
        +subtotal: ?number,
        +gst: ?number,
        +total: ?number,
      |}
    |}>
  |}
|};
export type EnterDatesQuery = {|
  variables: EnterDatesQueryVariables,
  response: EnterDatesQueryResponse,
|};
*/


/*
query EnterDatesQuery(
  $where: JobWorkOrderIndex!
) {
  works: jobWorkOrders(where: $where) {
    edges {
      node {
        costCentres {
          firstLineItemId
          costCentre {
            costCentreId
            costCentreName
            id
          }
          subtotal
          startDate
          completeDate
          id
        }
        subtotal
        gst
        total
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "JobWorkOrderIndex!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "JobWorkOrderIndex!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstLineItemId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "startDate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completeDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "gst",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EnterDatesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "works",
        "name": "jobWorkOrders",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobWorkOrderConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobWorkOrderEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobWorkOrder",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "costCentres",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobWorkOrderItemCostCentreGroup",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "costCentre",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "QuoteCostCentre",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ]
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ]
                  },
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EnterDatesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "works",
        "name": "jobWorkOrders",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobWorkOrderConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobWorkOrderEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobWorkOrder",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "costCentres",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobWorkOrderItemCostCentreGroup",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "costCentre",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "QuoteCostCentre",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v10/*: any*/)
                        ]
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v10/*: any*/)
                    ]
                  },
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "EnterDatesQuery",
    "id": null,
    "text": "query EnterDatesQuery(\n  $where: JobWorkOrderIndex!\n) {\n  works: jobWorkOrders(where: $where) {\n    edges {\n      node {\n        costCentres {\n          firstLineItemId\n          costCentre {\n            costCentreId\n            costCentreName\n            id\n          }\n          subtotal\n          startDate\n          completeDate\n          id\n        }\n        subtotal\n        gst\n        total\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0a125b727e165132a4296594e904cdcf';
module.exports = node;
