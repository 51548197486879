/**
 * @flow
 * @relayHash 5bb4c2369ab08152289526f6e06d6353
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type JobWorkOrderDateInput = {|
  startDate?: ?any,
  completeDate?: ?any,
|};
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type EnterDatesMutationVariables = {|
  input: JobWorkOrderDateInput,
  where: ENDataPortfolioKey,
|};
export type EnterDatesMutationResponse = {|
  +updateJobWorkDate: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +costCentre: ?{|
        +costCentreId: number,
        +costCentreName: string,
      |},
      +subtotal: ?number,
      +startDate: ?any,
      +completeDate: ?any,
    |},
  |}
|};
export type EnterDatesMutation = {|
  variables: EnterDatesMutationVariables,
  response: EnterDatesMutationResponse,
|};
*/


/*
mutation EnterDatesMutation(
  $input: JobWorkOrderDateInput!
  $where: ENDataPortfolioKey!
) {
  updateJobWorkDate(input: $input, where: $where) {
    success
    messages
    result {
      costCentre {
        costCentreId
        costCentreName
        id
      }
      subtotal
      startDate
      completeDate
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JobWorkOrderDateInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "JobWorkOrderDateInput!"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "startDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completeDate",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EnterDatesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateJobWorkDate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobWorkOrderItemCostCentreGroupPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobWorkOrderItemCostCentreGroup",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costCentre",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ]
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EnterDatesMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateJobWorkDate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobWorkOrderItemCostCentreGroupPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobWorkOrderItemCostCentreGroup",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costCentre",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v9/*: any*/)
                ]
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "EnterDatesMutation",
    "id": null,
    "text": "mutation EnterDatesMutation(\n  $input: JobWorkOrderDateInput!\n  $where: ENDataPortfolioKey!\n) {\n  updateJobWorkDate(input: $input, where: $where) {\n    success\n    messages\n    result {\n      costCentre {\n        costCentreId\n        costCentreName\n        id\n      }\n      subtotal\n      startDate\n      completeDate\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e5936c90806d82915876026bc87d1cdd';
module.exports = node;
