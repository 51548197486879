import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Paper from 'components/Mui/Paper'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'
import Icon from '@material-ui/core/Icon'

class NotFound extends Component {
  render() {
    const { classes } = this.props
    const { TOKEN, ROOT_ROUTE } = localStorage
    // console.log(TOKEN)
    return (
      <Grid
        className={classes.root}
        container
        direction='row'
        justify='center'
        alignItems='center'
      >
        <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
          <Paper content={(
            <>
              <Icon className={classes.headIcon} alt='ENData Claims'>filter_vintage</Icon>
              <h1 className={classes.title}>Not Found</h1>
              <p className={classes.subtitle}>Either you typed a wrong URL or you followed a bad link</p>
              {
                process.env.REACT_APP_DEV === 'dev' && ( 
                  <p style={{ textAlign: 'center', margin: 0 }}>
                    {!TOKEN
                      ? <Button 
                        variant='contained' color='primary'
                        component={Link} to='/user/login'
                        leadIcon='history' label='Login'
                      />
                      : <Button 
                        variant='contained' color='primary'
                        component={Link} to={ROOT_ROUTE}
                        leadIcon='history' label='Go back'
                      />
                    }
                  </p>
                )
              }
            </>
          )}/>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(theme => ({
  root: {
    height: '100vh',
    marginTop: 0
  },
  headIcon: {
    display: 'block',
    fontSize: '5rem',
    margin: '10px auto 15px'
  },
  title: {
    textAlign: 'center'
  },
  subtitle: {
    textAlign: 'center',
    ...theme.typography.body1
  }
}))(NotFound)