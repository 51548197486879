/**
 * @flow
 * @relayHash a6eb712444e31cc3d2ce1fff07374208
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Alignment = "Centre" | "Hidden" | "Left" | "Right" | "%future added value";
export type TopbarQueryVariables = {|
  isFetched: boolean
|};
export type TopbarQueryResponse = {|
  +currentUser: {|
    +menuItems?: {|
      +admin: {|
        +alignment: Alignment,
        +icon: ?string,
        +id: string,
        +isDisplay: boolean,
        +isExternal: boolean,
        +label: ?string,
        +url: ?string,
      |},
      +claim: {|
        +alignment: Alignment,
        +icon: ?string,
        +id: string,
        +isDisplay: boolean,
        +isExternal: boolean,
        +label: ?string,
        +url: ?string,
      |},
      +config: {|
        +alignment: Alignment,
        +icon: ?string,
        +id: string,
        +isDisplay: boolean,
        +isExternal: boolean,
        +label: ?string,
        +url: ?string,
      |},
      +dashboard: {|
        +alignment: Alignment,
        +icon: ?string,
        +id: string,
        +isDisplay: boolean,
        +isExternal: boolean,
        +label: ?string,
        +url: ?string,
      |},
      +outstandingCommunication: {|
        +alignment: Alignment,
        +icon: ?string,
        +id: string,
        +isDisplay: boolean,
        +isExternal: boolean,
        +label: ?string,
        +url: ?string,
      |},
      +reporting: {|
        +alignment: Alignment,
        +icon: ?string,
        +id: string,
        +isDisplay: boolean,
        +isExternal: boolean,
        +label: ?string,
        +url: ?string,
      |},
      +scoreboard: {|
        +alignment: Alignment,
        +icon: ?string,
        +id: string,
        +isDisplay: boolean,
        +isExternal: boolean,
        +label: ?string,
        +url: ?string,
      |},
      +video: {|
        +alignment: Alignment,
        +icon: ?string,
        +id: string,
        +isDisplay: boolean,
        +isExternal: boolean,
        +label: ?string,
        +url: ?string,
      |},
      +modernVersion: {|
        +label: ?string,
        +isDisplay: boolean,
      |},
      +announcementsNotification: {|
        +label: ?string,
        +isDisplay: boolean,
        +icon: ?string,
      |},
    |}
  |}
|};
export type TopbarQuery = {|
  variables: TopbarQueryVariables,
  response: TopbarQueryResponse,
|};
*/


/*
query TopbarQuery(
  $isFetched: Boolean!
) {
  currentUser {
    menuItems @include(if: $isFetched) {
      admin {
        alignment
        icon
        id
        isDisplay
        isExternal
        label
        url
      }
      claim {
        alignment
        icon
        id
        isDisplay
        isExternal
        label
        url
      }
      config {
        alignment
        icon
        id
        isDisplay
        isExternal
        label
        url
      }
      dashboard {
        alignment
        icon
        id
        isDisplay
        isExternal
        label
        url
      }
      outstandingCommunication {
        alignment
        icon
        id
        isDisplay
        isExternal
        label
        url
      }
      reporting {
        alignment
        icon
        id
        isDisplay
        isExternal
        label
        url
      }
      scoreboard {
        alignment
        icon
        id
        isDisplay
        isExternal
        label
        url
      }
      video {
        alignment
        icon
        id
        isDisplay
        isExternal
        label
        url
      }
      modernVersion {
        label
        isDisplay
        id
      }
      announcementsNotification {
        label
        isDisplay
        icon
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "isFetched",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "icon",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "alignment",
    "args": null,
    "storageKey": null
  },
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "isExternal",
    "args": null,
    "storageKey": null
  },
  (v4/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
],
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "admin",
  "storageKey": null,
  "args": null,
  "concreteType": "MenuItem",
  "plural": false,
  "selections": (v5/*: any*/)
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "claim",
  "storageKey": null,
  "args": null,
  "concreteType": "MenuItem",
  "plural": false,
  "selections": (v5/*: any*/)
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "config",
  "storageKey": null,
  "args": null,
  "concreteType": "MenuItem",
  "plural": false,
  "selections": (v5/*: any*/)
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "dashboard",
  "storageKey": null,
  "args": null,
  "concreteType": "MenuItem",
  "plural": false,
  "selections": (v5/*: any*/)
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "outstandingCommunication",
  "storageKey": null,
  "args": null,
  "concreteType": "MenuItem",
  "plural": false,
  "selections": (v5/*: any*/)
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reporting",
  "storageKey": null,
  "args": null,
  "concreteType": "MenuItem",
  "plural": false,
  "selections": (v5/*: any*/)
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "scoreboard",
  "storageKey": null,
  "args": null,
  "concreteType": "MenuItem",
  "plural": false,
  "selections": (v5/*: any*/)
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "video",
  "storageKey": null,
  "args": null,
  "concreteType": "MenuItem",
  "plural": false,
  "selections": (v5/*: any*/)
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TopbarQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticatedUser",
        "plural": false,
        "selections": [
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "isFetched",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "menuItems",
                "storageKey": null,
                "args": null,
                "concreteType": "MenuView",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "modernVersion",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MenuItem",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "announcementsNotification",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MenuItem",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/),
                      (v1/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TopbarQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticatedUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "isFetched",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "menuItems",
                "storageKey": null,
                "args": null,
                "concreteType": "MenuView",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "modernVersion",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MenuItem",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "announcementsNotification",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MenuItem",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "TopbarQuery",
    "id": null,
    "text": "query TopbarQuery(\n  $isFetched: Boolean!\n) {\n  currentUser {\n    menuItems @include(if: $isFetched) {\n      admin {\n        alignment\n        icon\n        id\n        isDisplay\n        isExternal\n        label\n        url\n      }\n      claim {\n        alignment\n        icon\n        id\n        isDisplay\n        isExternal\n        label\n        url\n      }\n      config {\n        alignment\n        icon\n        id\n        isDisplay\n        isExternal\n        label\n        url\n      }\n      dashboard {\n        alignment\n        icon\n        id\n        isDisplay\n        isExternal\n        label\n        url\n      }\n      outstandingCommunication {\n        alignment\n        icon\n        id\n        isDisplay\n        isExternal\n        label\n        url\n      }\n      reporting {\n        alignment\n        icon\n        id\n        isDisplay\n        isExternal\n        label\n        url\n      }\n      scoreboard {\n        alignment\n        icon\n        id\n        isDisplay\n        isExternal\n        label\n        url\n      }\n      video {\n        alignment\n        icon\n        id\n        isDisplay\n        isExternal\n        label\n        url\n      }\n      modernVersion {\n        label\n        isDisplay\n        id\n      }\n      announcementsNotification {\n        label\n        isDisplay\n        icon\n        id\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1dce5186e6a7582a44c4357c71b95301';
module.exports = node;
