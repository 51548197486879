import React from 'react'
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'
import { withRouter } from 'react-router-dom'
import ClaimMetaContext from 'pages/Claim/ClaimDetails/ClaimMetaContext'

import usePortfolioFilter from 'hooks/usePortfolioFilter'

import Grid from 'components/Mui/Grid'
import { Cube } from 'components/Mui/Loader'
import DocumentsList from './DocumentsList'
import DocumentViewAllAction from './DocumentsActions/DocumentViewAllAction'
import PhotoViewAllAction from './DocumentsActions/PhotoViewAllAction'

const query = graphql`
  query DocumentsQuery ($first: Int!, $after: String, $where: ClaimItemFilter) {
    ...DocumentsList
  }
`

const Documents = props => {
  const meta = React.useContext(ClaimMetaContext)
  const [ portfolio, handlePortfolio ] = usePortfolioFilter(meta)
  const [viewAll, setViewAll] = React.useState(true)
  const [viewItemIndex, setViewItemIndex] = React.useState(0)

  return (
    <>
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{
          first: 99969,
          after: null,
          where: {
            claimId: props.claimId,
            portfolios: portfolio || undefined
          }
        }}
        render={({ error, props: data }) => {
          if(error) {
            // console.log(error)
            return 'Error!!'
          }

          if(!data) return (
            <Grid fluid style={{ height: '100px' }}>
              <Grid item xs>
                <Cube />
              </Grid>
            </Grid>
          )

          return (
            <DocumentsList
              data={data}
              portfolio={portfolio}
              handlePortfolio={handlePortfolio}
              setViewAll={setViewAll}
              setViewItemIndex={setViewItemIndex}
              {...props}
            />
          )
        }}
      />
      <DocumentViewAllAction
        claimId={props.claimId}
        portfolios={portfolio}
        viewItemIndex={viewItemIndex}
        isViewAll={viewAll}
      />
      <PhotoViewAllAction
        claimId={props.claimId}
        portfolios={portfolio}
      />
    </>
  )
}

export default withRouter(Documents)