import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from 'components/Mui/IconButton'
import Collapse from '@material-ui/core/Collapse'
import Grid from 'components/Mui/Grid'
import { getSafe } from 'common/utils'
import { moneyFormat } from 'common/utils'
import Checkbox from '@material-ui/core/Checkbox'

const Group = ({ classes, items, item }) => {
  const [checkbox, setcheckbox] = React.useState(true)

  return (
    <Grid fluid spacing={24}
            direction='row'
            justify='center'
            alignItems='center'
          >
            <Grid className={classes.bodyListItems} item xs>
              <Checkbox
                checked={checkbox}
                name={items.itemId.toString()}
                onChange={e=>{setcheckbox(!checkbox)}}
              />
            </Grid>
            <Grid item xs={2}>{getSafe(() => items.costCentre.costCentreName, '')}</Grid>
            <Grid item xs={4}>{getSafe(() => `${items.costItem.itemDescription} ${items.lineDescription}`, '')} {item.lineDescription}</Grid>
            <Grid className={classes.bodyListItems} item xs>{getSafe(() => items.unit.unitName, '')}</Grid>
            <Grid className={classes.bodyListItems} item xs>{items.qty}</Grid>
            <Grid className={classes.bodyListItems} item xs>{moneyFormat(items.rate)}</Grid>
            <Grid className={classes.bodyListItems} item xs>{moneyFormat(items.margin)}</Grid>
            <Grid className={classes.bodyListItems} item xs>{moneyFormat(items.subtotal)}</Grid>
          </Grid>
  )
}

export default Group