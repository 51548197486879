import React from "react";
import Dialog from "components/Mui/Dialog";
import { Formik } from "formik";
import { blacklistedExtensions } from "common/static_data";
import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";
import { useQuery, graphql } from "relay-hooks";
import { useMutation } from "react-relay-mutation";
import { parseFile } from "common/utils";

import FormBody from "./FormBody";
import { useFeeReloadContext } from "../../FeeReloadContext.js";

const query = graphql`
  query FeeInvoiceUploadMultiDialogQuery($where: FeeItemWhere!) {
    feeItems(where: $where) {
      label: description
      value: feeItemId
      toBeInvoicedAmount
    }
  }
`;

const mutation = graphql`
  mutation FeeInvoiceUploadMultiDialogMutation(
    $input: FeeInvoiceUploadInput!
    $where: FeeInvoiceUploadWhere!
  ) {
    feeInvoiceUpload(input: $input, where: $where) {
      messages
      success
    }
  }
`;

export default ({ symbol }) => {
  let inputRef = React.useRef(null);

  const { claim } = useMetaContext();
  const snackbar = window[Symbol.for("__snackbar")];
  const [uploadFeeInvoice] = useMutation(mutation);
  const { reload, setReload } = useFeeReloadContext();

  const { props, error } = useQuery({
    query,
    variables: {
      where: {
        claimId: parseInt(claim.claimId),
        availableToBulkInvoice: true,
      },
      reload,
    },
    dataFrom: "STORE_THEN_NETWORK",
  });

  if (error) return "error";
  if (!props) return "loading...";

  return (
    <Dialog
      noForm
      noActions
      paperProps={{
        style: {
          width: "100%",
        },
      }}
      symbol={symbol}
      title="Fee Invoice Upload"
      content={
        <Formik
          initialValues={{
            invoiceAmount: "",
            invoiceNumber: "",
            description: "",
            feeItemIds: [],
          }}
          validate={(values) => {
            console.log(values);
            let errors = {};

            if (!values.feeItemIds || values.feeItemIds.length === 0)
              errors.feeItemIds = "Select at least 1!";
            if (!values.file) errors.file = "Required!";
            if (!values.description) errors.description = "Required!";

            if (!values.invoiceAmount) errors.invoiceAmount = "Required!";
            if (!values.invoiceNumber) errors.invoiceNumber = "Required!";

            if (values.description.length > 200)
              errors.description = "Maximum character limit is 200";
            if (values.invoiceNumber.length > 200)
              errors.invoiceNumber = "Maximum character limit is 200";
            if (Number(values.invoiceAmount).toFixed(2).length > 9 + 3)
              errors.invoiceAmount = "Maximum character limit is 9";
            if (inputRef.current) {
              const { name, size } = inputRef.current.files[0];
              if (
                blacklistedExtensions.find(
                  (e) =>
                    e.toUpperCase() ===
                    `.${
                      name.split(".")[name.split(".").length - 1]
                    }`.toUpperCase()
                )
              ) {
                errors.file = "File extension is not supported";
              }

              if (size > 31457280) errors.file = "Maximum file size is 30MB";
              if (size < 1) errors.file = "Minimum file size is 1 byte";

              if (name.length > 60)
                errors.file = "Maximum file name length is 60";
              if (name.length < 5)
                errors.file = "Minimum file name length is 5";
            }

            return errors;
          }}
          onSubmit={(values, actions) => {
            const feeItemIds = values.feeItemIds.map((e) =>
              parseInt(e.split("_")[0])
            );

            setTimeout(async () => {
              const submitValues = { ...values };
              const { ok, fileBase64, fileName } = await parseFile(
                inputRef.current
              );
              if (ok) {
                delete submitValues.file;
                delete submitValues.feeItemIds;
                console.log(Number(submitValues.invoiceAmount));
                const variables = {
                  input: {
                    ...submitValues,
                    invoiceAmount: Number(
                      parseFloat(submitValues.invoiceAmount).toFixed(2)
                    ),
                    fileBase64,
                    fileName,
                  },
                  where: {
                    feeItemIds,
                  },
                };

                const res = await uploadFeeInvoice({
                  variables,
                });
                actions.resetForm({
                  file: "",
                  feeItemIds: [],
                  invoiceAmount: "",
                  invoiceNumber: "",
                  description: "",
                });
                if (res) {
                  snackbar.toggleOpen({
                    message: res.feeInvoiceUpload.messages[0],
                  });
                  setReload(!reload);
                  if (window[Symbol.for(symbol)]) {
                    window[Symbol.for(symbol)].handleClose();
                  }
                } else {
                  snackbar.toggleOpen({
                    message: "Action has not succeed",
                  });
                }
              } else {
                console.log("stuck");
              }
            }, 400);
          }}
        >
          {({ isSubmitting, values, touched, setFieldValue }) => {
            return (
              <FormBody
                {...{
                  isSubmitting,
                  values,
                  touched,
                  setFieldValue,
                  props,
                  inputRef,
                  symbol,
                }}
              />
            );
          }}
        </Formik>
      }
    />
  );
};
