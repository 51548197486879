import React, { Component } from 'react'
import { withFormik, Form } from 'formik'

import Grid from '@material-ui/core/Grid'
import Paper from 'components/Mui/Paper'
import Button from '@material-ui/core/Button'

import DateInput from 'components/Formik/DateInput'
import TimeInput from 'components/Formik/TimeInput'
import DateTimeInput from 'components/Formik/DateTimeInput'

import PhoneInput from 'components/Formik/PhoneInput'
import { SingleReactSelect } from 'components/Formik/ReactSelect/'
import MoneyInput from 'components/Formik/MoneyInput'
import PostcodeInput from 'components/Formik/PostcodeInput'
import Switch from 'components/Formik/Switch'


class Reports extends Component {
  render() {
    return (
      <Grid container>
        <Grid item xs>
          <Paper
            title='Reports' 
            content={(
              <Form>
                <DateInput label='Date' name='date' />
                <TimeInput label='Time' name='time' />
                <DateTimeInput label='Date Time' name='datetime' />
                <br /><br />

                <PhoneInput label='Phone' name='phone' leadIcon='phone' required />
                <MoneyInput label='Money' name='money' required />
                <PostcodeInput label='Postcode' name='postcode' leadIcon='location_on' required
                  onBlur={(e) => console.log(e)}
                />

                <br /><br />
                <Button variant='contained' color='primary' size='large' type='submit'>Go</Button>
                <Switch
                  onClick={() => console.log('asd')}
                  name='testswitch' label='test'/>
                <SingleReactSelect
                  options={suggestions} label='asd'
                  required
                  leadIcon='event' name='single'
                />
              </Form>
            )}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withFormik({
  mapPropsToValues: () => ({
    date: undefined, time: undefined, datetime: undefined,
    phone: '', money: '', postcode: '', rad: '',
    testswitch: false,
  }),

  validate: (values, props) => {
    const errors = {}

      if(!values.phone) errors.phone = 'Required'
      if(values.phone.length !== 10) errors.phone = 'Phone need to have 10 numbers'

      if(!values.money) errors.money = 'Required'
      if(!values.single) errors.single = 'Required'
      if(values.money > 999999999) errors.money = 'Maximum 999,999,999'

    return errors
  },

  handleSubmit: (values, { setSubmitting }) => {
    setTimeout(() => {
      alert(JSON.stringify(values, null, 2))
      setSubmitting(false)
    }, 1000)
  },

  displayName: 'BasicForm',
})(Reports)


const suggestions = [
  { label: 'Afghanistan asdasdasndkhaskjdhnkajsdhkjashdkjahsjdhkjashdkjhsa' },
  { label: 'Aland Islands' },
  { label: 'Albania' },
  { label: 'Algeria' },
  { label: 'American Samoa' },
  { label: 'Andorra' },
  { label: 'Azerbaijan' },
  { label: 'Bahamas' },
  { label: 'Bahrain' },
  { label: 'Bangladesh' },
  { label: 'Barbados' },
  { label: 'Belarus' },
  { label: 'Belgium' },
  { label: 'Belize' },
  { label: 'Benin' },
  { label: 'Bermuda' },
  { label: 'Bhutan' },
  { label: 'Bolivia, Plurinational State of' },
  { label: 'Bonaire, Sint Eustatius and Saba' },
  { label: 'Bosnia and Herzegovina' },
  { label: 'Botswana' },
  { label: 'Bouvet Island' },
  { label: 'Brazil' },
  { label: 'British Indian Ocean Territory' },
  { label: 'Brunei Darussalam' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}))