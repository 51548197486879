import React from "react";
import useReactRouter from "use-react-router";
import { graphql, fetchQuery } from "react-relay";
import environment from "common/relay";
import handleUserData from 'common/utils/handleUserData'

import tokenRefresh from "common/utils/tokenRefresh";

const Login = () => {
  const { history } = useReactRouter();
  const [token, setToken] = React.useState(null)
  React.useEffect(() => {
    setToken(null)
    localStorage.removeItem("ACCESS_PATHS");
    localStorage.removeItem("REFRESH_TOKEN");
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("ROOT_ROUTE");
    localStorage.removeItem("REDIRECT_URL");
    localStorage.removeItem("APPLICATION_CUSTOM");
    const status = JSON.stringify({ status: 'render' })
    window.parent.postMessage(status, '*')
  },[])

  React.useEffect(() => {
    window.addEventListener(
      "message",
      function (event) {
        const data = JSON.parse(event.data)
        if (data && data.status !== ("render" || "injected")) {
          const refreshToken = JSON.parse(event.data).refreshToken
          localStorage.setItem("REFRESH_TOKEN", refreshToken);
          localStorage.setItem(
            "REDIRECT_URL", process.env.REACT_APP_DEFAULT_REDIRECT_URL
          );
          tokenRefresh().then((res) => {
            if (res.data) {
              const { accessToken } = res.data.userRenewToken;
              localStorage.setItem("TOKEN", accessToken);
              setToken(accessToken);
            }
          });
        }
      },
      false
    );
  }, []);
  React.useEffect(() => {
    if (token) {
      fetchQuery(environment, query)
        .then((data) => handleUserData(data.me, history))
        .then((resolvedRoot) => {
          const initialRoute = localStorage.getItem('ROOT_ROUTE')
          const status = JSON.stringify({
            status: 'injected',
            initialRoute: initialRoute ? initialRoute.split("/")[1] : 'claims',
          })
          window.parent.postMessage(status, '*')
        });
    }
  }, [token]);
  return (
      <div></div>
  )
}
export default Login

const query = graphql`
  query ExternalLoginQuery {
    me {
      userName
      userRole
      hasMultipleAccess
      preference {
        applicationFont
        applicationLanguage
        applicationTheme
      }
      company {
        customisation {
          applicationAlias
          applicationFavicon
          applicationLogo
          displayCompanyLogo
          logoHeight
        }
      }
      privileges {
        menus {
          nodeName
        }
      }
    }
  }
`;
