const destroyPeer = kurentoPeer => {
  if (!kurentoPeer) return null

  const localStreams = kurentoPeer.peerConnection.getLocalStreams()
  localStreams.forEach(stream => {
    stream.getTracks().forEach(track => {
      track.stop()
    })
  })

  kurentoPeer.peerConnection.oncandidategatheringdone = null
  kurentoPeer.peerConnection.onicecandidate = null
  kurentoPeer.peerConnection.close()
}

export default destroyPeer