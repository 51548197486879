/**
 * @flow
 * @relayHash 9a965e7f3bfda5287e611d54b91837d6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type QuoteItemUpdate = {|
  costCentreId?: ?number,
  costItemId?: ?number,
  locationId?: ?number,
  scopeTypeId?: ?string,
  propertyTypeId?: ?string,
  propertyNumber?: ?string,
  dimensions?: ?string,
  lineDescription?: ?string,
  unitId?: ?number,
  qty?: ?number,
  rate?: ?number,
  marginRate?: ?number,
  gst?: ?number,
|};
export type MakeSafeUpdateItemMutationVariables = {|
  where: ENDataPortfolioKey,
  input: QuoteItemUpdate,
|};
export type MakeSafeUpdateItemMutationResponse = {|
  +updateJobMakeSafeItem: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
    +result: ?{|
      +id: string,
      +makeSafeId: number,
      +location: ?{|
        +costLocationId: number,
        +locationName: string,
      |},
      +dimensions: ?string,
      +costCentre: ?{|
        +costCentreName: string
      |},
      +costItem: ?{|
        +itemDescription: string
      |},
      +lineDescription: string,
      +unit: ?{|
        +unitName: string
      |},
      +qty: number,
      +rate: ?number,
      +marginRate: number,
      +subtotal: ?number,
    |},
  |}
|};
export type MakeSafeUpdateItemMutation = {|
  variables: MakeSafeUpdateItemMutationVariables,
  response: MakeSafeUpdateItemMutationResponse,
|};
*/


/*
mutation MakeSafeUpdateItemMutation(
  $where: ENDataPortfolioKey!
  $input: QuoteItemUpdate!
) {
  updateJobMakeSafeItem(where: $where, input: $input) {
    messages
    success
    result {
      id
      makeSafeId
      location {
        costLocationId
        locationName
        id
      }
      dimensions
      costCentre {
        costCentreName
        id
      }
      costItem {
        itemDescription
        id
      }
      lineDescription
      unit {
        unitName
      }
      qty
      rate
      marginRate
      subtotal
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "QuoteItemUpdate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "QuoteItemUpdate!"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataPortfolioKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "messages",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "makeSafeId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costLocationId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "locationName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimensions",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "costCentreName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemDescription",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lineDescription",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "unit",
  "storageKey": null,
  "args": null,
  "concreteType": "QuoteItemUnit",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "unitName",
      "args": null,
      "storageKey": null
    }
  ]
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "qty",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rate",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "marginRate",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MakeSafeUpdateItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateJobMakeSafeItem",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobMakeSafeItemPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobMakeSafeItem",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "location",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              },
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costCentre",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costItem",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostItem",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ]
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MakeSafeUpdateItemMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateJobMakeSafeItem",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobMakeSafeItemPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "JobMakeSafeItem",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "location",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costCentre",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v4/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "costItem",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostItem",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "MakeSafeUpdateItemMutation",
    "id": null,
    "text": "mutation MakeSafeUpdateItemMutation(\n  $where: ENDataPortfolioKey!\n  $input: QuoteItemUpdate!\n) {\n  updateJobMakeSafeItem(where: $where, input: $input) {\n    messages\n    success\n    result {\n      id\n      makeSafeId\n      location {\n        costLocationId\n        locationName\n        id\n      }\n      dimensions\n      costCentre {\n        costCentreName\n        id\n      }\n      costItem {\n        itemDescription\n        id\n      }\n      lineDescription\n      unit {\n        unitName\n      }\n      qty\n      rate\n      marginRate\n      subtotal\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39d805ec382f5ef31bfb018c52b99209';
module.exports = node;
