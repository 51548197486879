import React from 'react'

import { Formik, Form } from 'formik'
import Dialog from 'components/Mui/Dialog'
import Grid from '@material-ui/core/Grid'
import Input from 'components/Formik/Input'
import Button from 'components/Mui/Button'

import { useQuery, graphql } from 'relay-hooks'
import { useMutation } from 'react-relay-mutation'

import { useMetaContext } from 'pages/Claim/ClaimDetails/ClaimMetaContext'

export default ({ symbol }) => {
  const meta = useMetaContext()
  const [requestSpecialist] = useMutation(SPECIALIST_REQUEST_MUTATION)
  const snackbar = window[Symbol.for('__snackbar')]

  return (
    <Dialog
      noForm
      noActions
      symbol={symbol}
      title='Request a specialist'
      content={(
        <Formik
          initialValues={{
            claimId: meta.claim.claimId,
            specialistCategoryId: '',
            specialistId: ''
          }}
          validate={values => {
            let errors = {}

            if(!values.specialistCategoryId) errors.specialistCategoryId = 'Required!'
            if(!values.specialistId) errors.specialistId = 'Required!'

            return errors
          }}
          onSubmit={(values, actions) => {
            setTimeout(async () => {
              const { data: { success, messages } } = await requestSpecialist({ variables: { input: values }})
              actions.setSubmitting(false)

              if(success) {
                // close
                if(window[Symbol.for(symbol)]){
                  window[Symbol.for(symbol)].handleClose()
                }
                // and reload
                const { reload, setReload } = window[Symbol.for('__SpecialistCardReload')]
                setReload(!reload)
              }
              else {
                // error message
                snackbar.toggleOpen({ message: messages[0] })
              }
            })
          }}
        >
          {({ values, isSubmitting }) => (
            <RequestForm
              values={values}
              isSubmitting={isSubmitting}
            />
          )}
        </Formik>
      )}
    />
  )
}

const RequestForm = ({ values, isSubmitting }) => {
  const { props: categoriesData } = useQuery({
    query: CATEGORY_QUERY,
    variables: {
      where: {
        claimId: values.claimId
      }
    }
  })

  const { props: specialistsData } = useQuery({
    query: SPECIALIST_QUERY,
    variables: {
      where: {
        claimId: values.claimId,
        specialistCategoryId: values.specialistCategoryId
      }
    }
  })

  return (
    <Form>
      <Grid fluid justify='flex-end'>
        <Grid xs={12}>
          {(categoriesData && categoriesData.options.length) ? (
            <Input type='select' name='specialistCategoryId'
              label='Specialist Category'
              isSearched
              select={true}
              required={true}
              options={categoriesData.options}
            />
          ) : null}
        </Grid>
        <Grid xs={12}>
          {(specialistsData && specialistsData.options.length) ? (
            <Input type='select' name='specialistId'
              isSearched
              label='Specialist'
              select={true}
              required={true}
              options={specialistsData.options}
            />
          ) : null}
        </Grid>
        {(categoriesData && categoriesData.options.length) ? (
          <Grid xs={12} style={{ textAlign: 'right' }}>
            <Button
              label='Request'
              type='submit'
              variant='contained'
              color='primary'
              disabled={isSubmitting}
            />
          </Grid>
        ) : null}
      </Grid>
    </Form>
  )
}

const CATEGORY_QUERY = graphql`
  query SpecialistRequestAdd_CategoryQuery($where: SpecialistCategoryWhere!) {
    options: specialistCategories(where: $where) {
      label: name
      value: specialistCategoryId
    }
  }
`

const SPECIALIST_QUERY = graphql`
  query SpecialistRequestAdd_SpecialistQuery($where: SpecialistWhere!) {
    options: specialists(where: $where) {
      label: companyName
      value: companyId
    }
  }
`

const SPECIALIST_REQUEST_MUTATION = graphql`
  mutation SpecialistRequestAdd_CreateMutation($input: SpecialistRequestCreateInput!) {
    data: specialistRequestCreate(input: $input) {
      success
      messages
      fieldErrors {
        fieldName
        level
        message
      }
    }
  }
`
