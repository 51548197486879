import React from 'react'

export default ({
  ready,
  consentCheckingReady, approved,
  supportCheckingReady,
  permissionCheckingReady, permitted,
  kurentoReady, kurentoError,
  signallingReady, signallingError
}) => {
  const [stateName, setStateName] = React.useState('INIT')

  React.useEffect(() => {
    if (!ready) setStateName('INIT')
    else {
      if (!consentCheckingReady) setStateName('CHECKING_CONSENT')
      else {
        if (!approved) setStateName('CHECKING_CONSENT_FAILED')
        else if(approved && !supportCheckingReady) {
          setStateName('CHECKING_SUPPORT')
        }
        else {
          if (!permissionCheckingReady) setStateName('CHECKING_PERMISSION')
          else {
            const isPermissionFailed = !permitted.video && !permitted.audio
            if (isPermissionFailed) setStateName('CHECKING_PERMISSION_FAILED')
            else {
              if (!kurentoReady) setStateName('SIGNALLING_LOCAL')
              else {
                if (kurentoError) setStateName('SIGNALLING_LOCAL_FAILED')
                else {
                  if (!signallingReady) setStateName('SIGNALLING_SERVER')
                  else {
                    if (signallingError) setStateName('SIGNALLING_SERVER_FAILED')
                    else {
                      if (signallingReady) setStateName('READY')
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // if (ready && consentCheckingReady && supportCheckingReady && !permissionCheckingReady) {
    //   setStateName('CHECKING_PERMISSION')
    // }
    // const isPermissionFailed = !permitted.video && !permitted.audio
    // if (ready && consentCheckingReady && supportCheckingReady && permissionCheckingReady && isPermissionFailed) {
    //   setStateName('CHECKING_PERMISSION_FAILED')
    // }

    // if (ready && consentCheckingReady && supportCheckingReady && permissionCheckingReady && !isPermissionFailed && !kurentoReady) {
    //   setStateName('SIGNALLING_LOCAL')
    // }
    // if (
    //   ready && consentCheckingReady && supportCheckingReady
    //   && permissionCheckingReady && !isPermissionFailed
    //   && kurentoReady && kurentoError
    // ) {
    //   setStateName('SIGNALLING_LOCAL_FAILED')
    // }

    // if (ready && consentCheckingReady && supportCheckingReady && permissionCheckingReady && kurentoReady && !signallingReady) setStateName('SIGNALLING_SERVER')
    // if (ready && consentCheckingReady && supportCheckingReady && permissionCheckingReady && kurentoReady && signallingReady && signallingError) setStateName('SIGNALLING_SERVER_FAILED')

    // if (ready && consentCheckingReady && supportCheckingReady && permissionCheckingReady && kurentoReady && signallingReady) setStateName('READY')
  }, [
    ready,
    consentCheckingReady, approved,
    supportCheckingReady,
    permissionCheckingReady, permitted.video, permitted.audio,
    kurentoReady,
    signallingReady, signallingError
  ])

  return stateName
}