import React from "react";
import environment from "common/relay";
//import Dialog from "components/Mui/Dialog";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { QueryRenderer, graphql } from "react-relay";
import VariationDetailsList from "../../VariationDetailsList";
import VariationValidationDialogTitle from "./VariationValidationDialogTitle";
import VariationValidationDialogSummary from "./VariationValidationDialogSummary";
import VariationValidationDialogFooter from "./VariationValidationDialogFooter";

const VariationValidationDialog = ({
  //variation,
  data,
  meta,
  handleRefresh,
  completed,
  portfolio,
  open,
  onClose,
  isValidationItem,
  ...props
}) => {
  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    window[Symbol.for("__ScopeValidationAction")] = () => setRefresh(!refresh);
    return () => {
      setRefresh(false);
      delete window[Symbol.for("__ScopeValidationAction")];
    };
  }, [refresh]);

  const refreshToggle = () => {
    setRefresh(!refresh);
  };

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        where: {
          id: data.variationId,
          portfolios: portfolio,
          includeValidationItems: true,
        },
        refresh,
      }}
      render={({ error, props }) => {
        if (!props || props.jobVariations.edges.length === 0) return null;
        const variation = props.jobVariations.edges[0].node;
        return (
          <Dialog maxWidth="xl" fullWidth open={open} onClose={onClose}>
            <DialogTitle>
              <VariationValidationDialogTitle
                portfolioType={portfolio}
                meta={meta}
                handleClose={onClose}
                refreshValidation={refreshToggle}
                variation={variation}
              />
            </DialogTitle>
            <DialogContent>
              <div>
                <VariationDetailsList
                  meta={meta}
                  variation={variation}
                  portfolio={portfolio}
                  completed={completed}
                  handleRefresh={refreshToggle}
                  isValidationAction
                  isAction
                  isValidationItem={isValidationItem}
                />
                {meta.user.type.isAdmin && (
                  <VariationValidationDialogSummary
                    meta={meta}
                    portfolio={portfolio}
                    id={data.variationId}
                  />
                )}
              </div>
            </DialogContent>
            {meta.user.type.isAdmin && (
              <VariationValidationDialogFooter
                id={data.variationId}
                meta={meta}
                portfolioType={portfolio}
              />
            )}
          </Dialog>
        );
      }}
    />
  );
};

export default VariationValidationDialog;

const query = graphql`
  query VariationValidationDialogQuery($where: JobVariationIndex!) {
    jobVariations(where: $where, first: 1000) {
      edges {
        node {
          id
          variationId
          completed
          variationStatus
          # info card
          variationTitle
          variationDescription
          variationReason {
            reasonDescription
          }
          actions {
            accept {
              isDisabled
              isDisplay
              label
            }
            addLineItem {
              isDisabled
              isDisplay
              label
            }
            decline {
              isDisabled
              isDisplay
              label
            }
            reset {
              isDisabled
              isDisplay
              label
            }
            submit {
              isDisabled
              isDisplay
              label
            }
            submitValidation {
              isDisabled
              isDisplay
              label
            }
            completeValidation {
              isDisabled
              isDisplay
              label
            }
            resetValidation {
              id
              isDisabled
              isDisplay
              label
            }
          }

          scopeTypes {
            properties {
              locations {
                location {
                  id
                  costLocationId
                  locationName
                }
                items {
                  actions {
                    accept {
                      isDisabled
                      isDisplay
                      label
                    }
                    amend {
                      isDisabled
                      isDisplay
                      label
                    }
                    decline {
                      isDisabled
                      isDisplay
                      label
                    }
                    validate {
                      isDisabled
                      isDisplay
                      label
                    }
                  }
                  variationItemId
                  quoteItemType
                  accepted
                  location {
                    costLocationId
                    locationName
                  }
                  dimensions
                  costCentre {
                    costCentreId
                    costCentreName
                  }
                  costItem {
                    costItemId
                    itemDescription
                  }
                  lineDescription
                  unit {
                    unitId
                    unitName
                  }
                  note
                  lineStatusDescription
                  qty
                  rate
                  marginRate
                  subtotal
                  logdate
                  sirRate
                  scopeType {
                    name
                    id
                  }
                }
                validationItems {
                  actions {
                    accept {
                      isDisabled
                      isDisplay
                      label
                    }
                    amend {
                      isDisabled
                      isDisplay
                      label
                    }
                    decline {
                      isDisabled
                      isDisplay
                      label
                    }
                    validate {
                      isDisabled
                      isDisplay
                      label
                    }
                  }
                  variationItemId
                  quoteItemType
                  accepted
                  location {
                    costLocationId
                    locationName
                  }
                  dimensions
                  costCentre {
                    costCentreId
                    costCentreName
                  }
                  costItem {
                    costItemId
                    itemDescription
                  }
                  lineDescription
                  unit {
                    unitId
                    unitName
                  }
                  note
                  lineStatusDescription
                  qty
                  rate
                  marginRate
                  subtotal
                  logdate
                  sirRate
                  scopeType {
                    name
                    id
                  }
                }
              }
            }
            locations {
              id
              location {
                id
                costLocationId
                locationName
              }
              items {
                actions {
                  accept {
                    isDisabled
                    isDisplay
                    label
                  }
                  amend {
                    isDisabled
                    isDisplay
                    label
                  }
                  decline {
                    isDisabled
                    isDisplay
                    label
                  }
                  validate {
                    isDisabled
                    isDisplay
                    label
                  }
                }
                variationItemId
                quoteItemType
                accepted
                location {
                  costLocationId
                  locationName
                }
                dimensions
                costCentre {
                  costCentreId
                  costCentreName
                }
                costItem {
                  costItemId
                  itemDescription
                }
                lineDescription
                unit {
                  unitId
                  unitName
                }
                note
                lineStatusDescription
                qty
                rate
                marginRate
                subtotal
                logdate
                sirRate
                scopeType {
                  name
                  id
                }
              }
            }
            scopeType {
              id
              name
            }
            id
            gst
            margin
            sirGst
            sirMargin
            sirSubtotal
            sirTotal
            subtotal
            total
            discount
            fields {
              marginBreakdown {
                isDisplay
                label
              }
              discount {
                isDisplay
                label
                isDisabled
              }
              sIRRate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              gst {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              total {
                label
                isDisplay
              }
              action {
                label
                isDisplay
              }
              costCentre {
                label
                isDisplay
              }
              description {
                label
                isDisplay
              }
              marginRate {
                label
                isDisplay
              }
              margin {
                label
                isDisplay
              }
              qty {
                label
                isDisplay
              }
              rate {
                label
                isDisplay
              }
              subtotal {
                label
                isDisplay
              }
              unit {
                label
                isDisplay
              }
            }
          }

          # item table
          lineItems {
            variationItemId
            accepted

            location {
              costLocationId
              locationName
            }
            dimensions
            sirRate
            costCentre {
              costCentreId
              costCentreName
            }
            costItem {
              costItemId
              itemDescription
            }
            lineDescription
            unit {
              unitId
              unitName
            }
            marginRate
            qty
            rate
            gst
            subtotal
          }
          # report
          subtotal
          gst
          margin
          total
        }
      }
    }
  }
`;
