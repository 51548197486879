import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'

import Paper from 'components/Mui/Paper'
import ClaimTypeIcon from 'components/Mui/ClaimTypeIcon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Icon from '@material-ui/core/Icon'

class ClaimDetailsPortfolioFilter extends Component {
  render() {
    const { classes, portfolio, noAll, changePortfolio, value } = this.props
    return (
      <Paper className={classes.root}>
        <List component='div'>
          {portfolio && Object.values(portfolio).filter(x => x === true).length > 1 && !noAll &&
            <ListItem button className={classes.listItem} 
              onClick={e => changePortfolio(undefined)}
              selected={!Boolean(value)}
            >
              <Icon className={classes.icon}>select_all</Icon>
            </ListItem>
          }
          {portfolio && portfolio.hasBuilding && 
            <ListItem button className={classes.listItem}
              onClick={e => changePortfolio('Building')}
              selected={value === 'building'}
            >
              <ClaimTypeIcon className={classes.icon} type='Building'/>
            </ListItem>}
          {portfolio && portfolio.hasContents && 
            <ListItem button className={classes.listItem}
              onClick={e => changePortfolio('Contents')}
              selected={value === 'contents'}
            >
              <ClaimTypeIcon className={classes.icon} type='Contents'/>
            </ListItem>}
          {portfolio && portfolio.hasRestoration && 
            <ListItem button className={classes.listItem}
              onClick={e => changePortfolio('Restoration')}
              selected={value === 'restoration'}
            >
              <ClaimTypeIcon className={classes.icon} type='Restoration'/>
            </ListItem>}
        </List>
      </Paper>  
    )
  }
}

export default withStyles(theme => ({
  icon: {
    fontSize: '1.2rem',
    marginRight: 0,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'center'
  },
  root: {
    position: 'absolute',
    top: 0,
    width: '2rem',
    left: '-2rem'
  }
}))(ClaimDetailsPortfolioFilter)