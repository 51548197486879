import React from "react";
import { Link } from "react-router-dom";

import Fabs from "components/Mui/Fabs";
import Fab from "components/Mui/Fabs/Fab";
import QuickActions from "components/Mui/QuickActions";

import { withClaimMeta } from "../ClaimMetaContext";
import FollowUpAction from "../ClaimDetailsAction/FollowUpAction";
import FollowUpInfo from "../ClaimDetailsAction/FollowUpAction/FollowUpInfo";
import NewNoteAction from "../ClaimDetailsAction/NewNoteAction";
import CreateDocumentsAction from "../ClaimDetailsAction/CreateDocumentsAction";
import CommunicationAction from "../ClaimDetailsAction/CommunicationAction";
import CommunicationEmbeddedAction from "../ClaimDetailsAction/CommunicationEmbeddedAction";
// import NewScopeAction from '../ClaimDetailsAction/NewScopeAction'
// import ScopeCompleteAction from '../ClaimDetailsAction/ScopeCompleteAction'
// import NewMakeSafeAction from '../ClaimDetailsAction/NewMakeSafeAction'
// import MakeSafeCompleteAction from '../ClaimDetailsAction/MakeSafeCompleteAction'
import NextStepAction from "../ClaimDetailsAction/NextStepAction";
import FinaliseClaimAction from "../ClaimDetailsAction/FinaliseClaimAction";
import CashSettleAction from "../ClaimDetailsAction/CashSettleAction";
import AddNewClaimTypeAction from "../ClaimDetailsAction/AddNewClaimTypeAction";
import VideoCallInitialDialog from "components/VideoCallInitialDialog";
import HistoryAction from "../ClaimDetailsAction/HistoryAction";

import SpecialistRequestAdd from "../ClaimDetailsAction/SpecialistRequestAdd";

class ClaimDetailsFabActions extends React.Component {
  state = {
    CommunicationEmbeddedActionDisplay: false,
  };
  handleCommunicationEmbeddedActionDisplay = () => {
    this.setState({
      CommunicationEmbeddedActionDisplay:
        !this.state.CommunicationEmbeddedActionDisplay,
    });
  };
  componentDidMount = () => {
    window[Symbol.for("__CommunicationEmbeddedActionDisplay")] = {
      toggleDisplay: this.handleCommunicationEmbeddedActionDisplay,
    };
  };

  componentWillUnmount = () => {
    delete window[Symbol.for("__CommunicationEmbeddedActionDisplay")];
  };
  render() {
    const { claimId, meta } = this.props;
    const hasAllClaimType =
      meta.claim.hasBuilding &&
      meta.claim.hasRestoration &&
      meta.claim.hasContents;
    const AddClaimTypeDisabled =
      meta.claim.view.actions.addClaimPortfolio.isDisabled;
    const externalSourceAction = meta.claim.view.actions.externalSourceData;
    const childFabs = [
      <Fab
        onClick={this.toggleNextStep}
        icon="live_help"
        label="Next Step"
        backgroundColor="#00acc1"
      />,
      // <Fab onClick={this.toggleFollowUp} icon='screen_share' label='Follow Up' backgroundColor='#118e68'/>,
      <Fab
        onClick={this.toggleNewUpdate}
        icon="update"
        label="New Update"
        backgroundColor="#6432a8"
      />,
      // <Fab onClick={this.toggleInitiateVideoCall} icon='videocam' label='Video Triage Session' backgroundColor='#2B78FE'/>
    ];
    if (
      !meta.claim.view.actions.addJobNote.isDisabled &&
      meta.claim.view.actions.addJobNote.isDisplay
    ) {
      childFabs.push(
        <Fab
          onClick={this.toggleNewNote}
          icon="rate_review"
          label="Job Note"
          backgroundColor="#f4511e"
        />
      );
    }
    if (
      !meta.claim.view.actions.addDocument.isDisabled &&
      meta.claim.view.actions.addDocument.isDisplay
    ) {
      childFabs.push(
        <Fab
          onClick={this.toggleDocument}
          icon="description"
          label="Documents"
          backgroundColor="#444444"
        />
      );
    }
    if (
      !meta.claim.view.actions.createFollowUp.isDisabled &&
      meta.claim.view.actions.createFollowUp.isDisplay
    ) {
      childFabs.push(
        <Fab
          onClick={this.toggleFollowUp}
          icon="add_comment"
          label="Follow Up"
          backgroundColor="#06694d"
        />
      );
    }
    if (
      !meta.claim.view.actions.addCommunication.isDisabled &&
      meta.claim.view.actions.addCommunication.isDisplay
    ) {
      childFabs.push(
        <Fab
          onClick={this.toggleCommunication}
          icon="chat"
          label="Communication"
          backgroundColor="#0f5b78"
        />
      );
    }
    if (meta.claim.insurer.isVideoToolModule) {
      childFabs.push(
        <Fab
          onClick={this.toggleInitiateVideoCall}
          icon="videocam"
          label="VIDEO SESSION"
          backgroundColor="#2B78FE"
        />
      );
    }
    if (!hasAllClaimType && meta.user.type.isAdmin && !AddClaimTypeDisabled) {
      childFabs.push(
        <Fab
          onClick={this.toggleAddClaim}
          icon="library_add"
          label="Add claim type"
          backgroundColor="#795548"
        />
      );
    }
    if (
      !meta.currentUser.actions.createNewClaim.isDisabled &&
      meta.user.type.isAdmin
    ) {
      childFabs.push(
        <Fab
          icon="note_add"
          label="Claim"
          backgroundColor="#b00020"
          component={Link}
          to="/add-claim"
        />
      );
    }
    return (
      <>
        <Fabs
          mainFab={
            process.env.REACT_APP_DEV === "dev" ? (
              <img
                width="50px"
                height="50px"
                src="https://avatarfiles.alphacoders.com/955/95566.png"
                alt=""
              />
            ) : (
              <Fab icon="add" label="NEW" />
            )
          }
          childFabs={childFabs}
        />

        <QuickActions>
          {/* <FollowUpAction claimId={claimId} symbol='__newFollowUpAction'/> */}
          <NewNoteAction
            claimId={claimId}
            symbol="__newJobNoteAction"
            meta={meta}
          />
          <CommunicationAction
            claimId={claimId}
            symbol="__newCommunicationAction"
            unmountOnClose
          />
          <HistoryAction
            symbol="__newHistoryAction"
            claimId={claimId}
            meta={meta}
            unmountOnClose
          />
          <CommunicationEmbeddedAction
            claimId={claimId}
            symbol="__communicationEmbeddedAction"
            label={externalSourceAction && externalSourceAction.label}
            display={this.state.CommunicationEmbeddedActionDisplay}
            toggleDisplay={this.handleCommunicationEmbeddedActionDisplay}
            unmountOnClose
          />
          <FollowUpAction
            claimId={claimId}
            symbol="__newFollowUpAction"
            unmountOnClose
          />
          <FollowUpInfo
            claimId={claimId}
            symbol="__FollowUpInfo"
            unmountOnClose
          />
        </QuickActions>
        {[
          { component: CreateDocumentsAction, symbol: "__newDocumentAction" },
          { component: NextStepAction, symbol: "__nextStepAction" },
          { component: FinaliseClaimAction, symbol: "__finaliseClaimAction" },
          { component: CashSettleAction, symbol: "__cashSettleButton" },
          {
            component: AddNewClaimTypeAction,
            symbol: "__addNewClaimTypeAction",
          },
          {
            component: VideoCallInitialDialog,
            symbol: "__initiateVideoCallDialogAction",
          },
          {
            component: SpecialistRequestAdd,
            symbol: "__specialistRequestDialog",
          },
        ].map(({ component: Component, symbol }, key) => (
          <Component key={key} symbol={symbol} claimId={claimId} meta={meta} />
        ))}
      </>
    );
  }

  toggleNewNote = () => {
    if (window[Symbol.for("__newJobNoteAction")]) {
      window[Symbol.for("__newJobNoteAction")].toggleOpen();
    }
  };

  toggleNewUpdate = () => {
    if (window[Symbol.for("__newHistoryAction")]) {
      window[Symbol.for("__newHistoryAction")].toggleOpen();
    }
  };

  toggleFollowUp = () => {
    if (window[Symbol.for("__newFollowUpAction")]) {
      window[Symbol.for("__newFollowUpAction")].toggleOpen();
    }
  };

  toggleCommunication = () => {
    // console.log(window[Symbol.for('__newCommunicationAction')])
    if (window[Symbol.for("__newCommunicationAction")]) {
      window[Symbol.for("__newCommunicationAction")].toggleOpen();
    }
  };

  toggleDocument = () => {
    if (window[Symbol.for("__newDocumentAction")]) {
      window[Symbol.for("__newDocumentAction")].handleOpen();
    }
  };

  toggleNextStep = () => {
    if (window[Symbol.for("__nextStepAction")]) {
      window[Symbol.for("__nextStepAction")].handleOpen();
    }
  };

  toggleAddClaim = () => {
    if (window[Symbol.for("__addNewClaimTypeAction")]) {
      window[Symbol.for("__addNewClaimTypeAction")].handleOpen();
    }
  };

  toggleInitiateVideoCall = () => {
    if (window[Symbol.for("__initiateVideoCallDialogAction")]) {
      window[Symbol.for("__initiateVideoCallDialogAction")].handleOpen();
    }
  };
}

export default withClaimMeta(ClaimDetailsFabActions);
