/**
 * @flow
 * @relayHash 24b1c9d13461394749d22af19a6b361a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type QuoteItemUpdate = {|
  costCentreId?: ?number,
  costItemId?: ?number,
  locationId?: ?number,
  scopeTypeId?: ?string,
  propertyTypeId?: ?string,
  propertyNumber?: ?string,
  dimensions?: ?string,
  lineDescription?: ?string,
  unitId?: ?number,
  qty?: ?number,
  rate?: ?number,
  marginRate?: ?number,
  gst?: ?number,
|};
export type QuoteItemUpdateMutationVariables = {|
  where: ENDataPortfolioKey,
  input: QuoteItemUpdate,
|};
export type QuoteItemUpdateMutationResponse = {|
  +updateJobQuoteItem: ?{|
    +messages: $ReadOnlyArray<string>,
    +success: boolean,
  |}
|};
export type QuoteItemUpdateMutation = {|
  variables: QuoteItemUpdateMutationVariables,
  response: QuoteItemUpdateMutationResponse,
|};
*/


/*
mutation QuoteItemUpdateMutation(
  $where: ENDataPortfolioKey!
  $input: QuoteItemUpdate!
) {
  updateJobQuoteItem(where: $where, input: $input) {
    messages
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "QuoteItemUpdate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateJobQuoteItem",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "QuoteItemUpdate!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ENDataPortfolioKey!"
      }
    ],
    "concreteType": "JobQuotePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "QuoteItemUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "QuoteItemUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "QuoteItemUpdateMutation",
    "id": null,
    "text": "mutation QuoteItemUpdateMutation(\n  $where: ENDataPortfolioKey!\n  $input: QuoteItemUpdate!\n) {\n  updateJobQuoteItem(where: $where, input: $input) {\n    messages\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '64eba09d22054159395b8f55f853790e';
module.exports = node;
