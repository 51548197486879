import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "components/Mui/Dialog";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";

import { Formik, Form } from "formik";
import { commonCharactersRegexp } from "common/inputRegex";

import AddContentsItemForm from "./AddContentsItemForm";
import AddContentsItemMutation from "./AddContentsItemForm/AddContentsItemMutation";

import AddItemForm from "components/LineItem/AddItemForm";
import AddScopeItemMutation from "./AddScopeItemMutation";

import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";

const ScopeAddLineItem = ({
  classes,
  symbol,
  jobQuoteId,
  portfolioType,
  scopeTypeId,
  isProperties,
  quote,
  industryRatesAutoFill,
}) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const refresh = window[Symbol.for("__refreshScope")];
  const [isDirectSupply, setIsDirectSupply] = React.useState(false);
  const handleClose = () => {
    const actions = window[Symbol.for(symbol)];
    if (actions) actions.handleClose();
  };

  const meta = useMetaContext();

  return (
    <Formik
      initialValues={{
        locationId: "",
        dimensions: "",
        scopeTypeId: scopeTypeId ? scopeTypeId.id : "",
        propertyTypeId: "",
        propertyNumber: "",
        costCentreId: "",
        costItemId: "",
        lineDescription: "",
        qty: "",
        rate: "",
        directsupply: null,
        purchasePrice: "",
        ageOfItem: "",
        proofOfLoss: "",
        policyLimit: null,

        labour_costCentreId: "",
        labour_costItemId: "",
        labour_lineDescription: "",
        labour_unitId: "",
        labour_marginRate: meta.claim.insurer.isMarginUpdate ? "" : 0,
        labour_qty: "",
        labour_rate: "",

        material_costCentreId: "",
        material_costItemId: "",
        material_lineDescription: "",
        material_unitId: "",
        material_marginRate: meta.claim.insurer.isMarginUpdate ? "" : 0,
        material_qty: "",
        material_rate: "",
      }}
      validate={(values) => validate(values, portfolioType, isDirectSupply)}
      onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
        setTimeout(async () => {
          const variables = getVariables(values, portfolioType, jobQuoteId);
          let res;
          if (portfolioType === "Contents") {
            res = await AddContentsItemMutation(variables);
          } else {
            res = await AddScopeItemMutation(variables);
          }

          setSubmitting(false);
          if (res && res.ok) {
            snackbar.toggleOpen({ message: res.messages[0] });
            if (res.success) {
              // resetForm(initialValues)
              // setStatus({ success: true })
              // handleClose()
              refresh();
            }
          }
        }, 400);
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Dialog
          noForm
          noActions
          symbol={symbol}
          title="Add Line Item"
          paperClassname={classes.paper}
          content={
            <Form>
              <Grid fluid>
                {portfolioType === "Contents" ? (
                  <AddContentsItemForm
                    portfolioType={portfolioType}
                    values={values}
                    isSubmitting={isSubmitting}
                    setFieldValue={setFieldValue}
                    directsupply={[isDirectSupply, setIsDirectSupply]}
                    quotationType="Scope"
                  />
                ) : (
                  <AddItemForm
                    portfolioType={portfolioType}
                    values={values}
                    isSubmitting={isSubmitting}
                    setFieldValue={setFieldValue}
                    quotationType="Scope"
                    isProperties={isProperties}
                    quote={quote}
                    industryRatesAutoFill={industryRatesAutoFill}
                  />
                )}
                <Grid item xs={12} className={classes.actionRow}>
                  <div className={classes.actionArea}>
                    <Button
                      color="primary"
                      label="Cancel"
                      onClick={handleClose}
                      disabled={isSubmitting}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      label="Submit"
                      type="submit"
                      disabled={isSubmitting}
                    />
                  </div>
                </Grid>
              </Grid>
            </Form>
          }
        />
      )}
    </Formik>
  );
};

export default withStyles((theme) => ({
  paper: {
    maxWidth: "50% !important",
  },
  actionRow: {
    display: "flex",
  },
  actionArea: {
    marginLeft: "auto",
  },
}))(ScopeAddLineItem);

const validate = (values, portfolioType, isDirectSupply) => {
  let errors = {};

  if (!values.locationId) errors.locationId = "Required!";

  if (portfolioType === "Contents") {
    if (!values.costCentreId) errors.costCentreId = "Required!";
    if (!values.costItemId) errors.costItemId = "Required!";
    if (!values.qty) errors.qty = "Required!";
    if (!values.rate && values.rate !== 0) errors.rate = "Required!";
    // if(!values.policyLimit) errors.policyLimit = 'Required!'
    if (isDirectSupply) {
      if (values.directsupply !== true && values.directsupply !== false) {
        errors.directsupply = "Required!";
      }
      if (!values.policyLimit) errors.policyLimit = "Required!";
    }

    ["purchasePrice", "ageOfItem", "proofOfLoss"].map((e) => {
      if (values[e] && commonCharactersRegexp.test(values[e]))
        errors[e] = "Invalid character";
      return null;
    });

    if (String(values.purchasePrice).length > 200)
      errors.purchasePrice = "Maximum character limit is 200";
    if (String(values.ageOfItem).length > 200)
      errors.ageOfItem = "Maximum character limit is 200";
    if (String(values.proofOfLoss).length > 200)
      errors.proofOfLoss = "Maximum character limit is 200";

    if (String(values.lineDescription).length > 200)
      errors.lineDescription = "Maximum character limit is 200";
    if (String(values.qty).length > 4 + 3)
      errors.qty = "Maximum character limit is 4";
    if (
      (String(values.rate).indexOf("-") !== -1 &&
        String(values.rate).length > 7 + 4) ||
      (String(values.rate).indexOf("-") === -1 &&
        String(values.rate).length > 7 + 3)
    )
      errors.rate = "Maximum character limit is 7";
    if (String(values.policyLimit).length > 7 + 3)
      errors.policyLimit = "Maximum character limit is 7";
  } else {
    if (!values.dimensions) errors.dimensions = "required!";
    if (values.dimensions && commonCharactersRegexp.test(values.dimensions))
      errors.dimensions = "Invalid character";
    if (values.dimensions.length > 200)
      errors.dimensions = "Maximum character limit is 200";

    if (!values.labour_costCentreId && !values.material_costCentreId) {
      errors.labour_costCentreId = "Select at least one";
      errors.material_costCentreId = "Select at least one";
    }
    if (values.labour_costCentreId) {
      if (!values.labour_unitId) errors.labour_unitId = "Required";
      if (!values.labour_qty) errors.labour_qty = "Required";
      if (!values.labour_rate && values.labour_rate !== 0)
        errors.labour_rate = "Required";
      if (!values.labour_costItemId) errors.labour_costItemId = "Required";

      if (String(values.labour_lineDescription).length > 1500)
        errors.labour_lineDescription = "Maximum character limit is 1500";
      if (String(values.labour_qty).length > 4 + 3)
        errors.labour_qty = "Maximum character limit is 4";
      if (
        (String(values.labour_rate).indexOf("-") !== -1 &&
          String(values.labour_rate).length > 7 + 4) ||
        (String(values.labour_rate).indexOf("-") === -1 &&
          String(values.labour_rate).length > 7 + 3)
      )
        errors.labour_rate = "Maximum character limit is 7";
    }
    if (values.material_costCentreId) {
      if (!values.material_unitId) errors.material_unitId = "Required";
      if (!values.material_qty) errors.material_qty = "Required";
      if (!values.material_rate && values.material_rate !== 0)
        errors.material_rate = "Required";
      if (!values.material_costItemId) errors.material_costItemId = "Required";

      if (String(values.material_lineDescription).length > 1500)
        errors.material_lineDescription = "Maximum character limit is 1500";
      if (String(values.material_qty).length > 4 + 3)
        errors.material_qty = "Maximum character limit is 4";
      if (
        (String(values.material_rate).indexOf("-") !== -1 &&
          String(values.material_rate).length > 7 + 4) ||
        (String(values.material_rate).indexOf("-") === -1 &&
          String(values.material_rate).length > 7 + 3)
      )
        errors.material_rate = "Maximum character limit is 7";
    }
  }

  return errors;
};

const getVariables = (values, portfolioType, jobQuoteId) => {
  if (portfolioType === "Contents") {
    return {
      input: {
        jobQuoteId,
        items: [
          {
            locationId: Number(values.locationId),
            costCentreId: Number(values.costCentreId),
            costItemId: Number(values.costItemId),
            lineDescription: values.lineDescription,
            qty: Number(values.qty),
            rate: Number(values.rate),
            directsupply: values.directsupply,
            purchasePrice: values.purchasePrice,
            ageOfItem: values.ageOfItem,
            proofOfLoss: values.proofOfLoss,
            policyLimit: Number(values.policyLimit),
          },
        ],
      },
    };
  } else {
    let items = [];
    if (values.labour_costCentreId)
      items.push({
        locationId: Number(values.locationId),
        dimensions: values.dimensions,
        costCentreId: Number(values.labour_costCentreId),
        scopeTypeId: values.scopeTypeId,
        costItemId: Number(values.labour_costItemId),
        lineDescription: values.labour_lineDescription,
        unitId: Number(values.labour_unitId),
        marginRate: Number(values.labour_marginRate),
        qty: Number(values.labour_qty),
        rate: Number(values.labour_rate),
        propertyTypeId: values.propertyTypeId,
        propertyNumber: values.propertyNumber,
      });
    if (values.material_costCentreId)
      items.push({
        locationId: Number(values.locationId),
        dimensions: values.dimensions,
        costCentreId: Number(values.material_costCentreId),
        scopeTypeId: values.scopeTypeId,
        costItemId: Number(values.material_costItemId),
        lineDescription: values.material_lineDescription,
        unitId: Number(values.material_unitId),
        marginRate: Number(values.material_marginRate),
        qty: Number(values.material_qty),
        rate: Number(values.material_rate),
        propertyTypeId: values.propertyTypeId,
        propertyNumber: values.propertyNumber,
      });

    return {
      input: {
        jobQuoteId,
        portfolioType,
        items,
      },
    };
  }
};
