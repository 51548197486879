import React from 'react'

export default ({ peer, remoteVideoRef }) => {
  React.useEffect(() => {
    const start = () => {
      const remote = peer.getRemoteStream()
      if (remote && remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = remote
        remoteVideoRef.current.play()
      }
    }

    setTimeout(() => {
      if (peer) start()
    }, 1000)
  }, [peer, remoteVideoRef])
}