import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "components/Mui/Paper";
import Button from "components/Mui/Button";
import { graphql } from "react-relay";
import environment from "common/relay";
import QuoteInitialCallContentMutation from "./QuoteInitialCall/QuoteInitialCallContentMutation";
import QuoteInitialCallMutation from "./QuoteInitialCall/QuoteInitialCallMutation";
import QuoteExportToExcelMutation from "./ExportToExcelAction/QuoteExportToExcelMutation";
import CompleteQuoteAction from "./CompleteQuoteAction";
import AppointmentMadeAction from "./AppointmentMadeAction";
// import MuiCircularProgress from '@material-ui/core/CircularProgress'

const query = graphql`
  query QuoteActionBarQuery($where: JobScopeTypeWhere!) {
    jobScopeTypes(where: $where) {
      id
      name
    }
  }
`;

const ScopeActionBar = ({
  classes,
  quote,
  meta,
  portfolio,
  requestCategory,
  scopeTypeId,
}) => {
  return (
    <Paper>
      <Grid container justify="space-between" className={classes.root}>
        <Grid item xs={2} className={classes.actionBlock}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            label="Next Step"
            onClick={handleNextStep}
          />
        </Grid>
        <QuoteActions
          classes={classes}
          quote={quote}
          meta={meta}
          portfolio={portfolio}
          scopeTypeId={scopeTypeId}
        />
      </Grid>
    </Paper>
  );
};

const QuoteActions = ({ classes, quote, meta, portfolio, scopeTypeId }) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const appointment = React.createRef();
  const portfolioMeta = meta.claim[portfolio.toLowerCase()];

  const display = meta.claim.view.actions.updateScopeType.isDisplay;

  var scopeExcelButton = null;

  const handleInitialCall = async () => {
    const snackbarRes = await snackbar.toggleOpen({
      message: "Initial call made?",
      type: "confirm",
      firstButton: "Call made",
      secondButton: "Cancel",
    });
    if (snackbarRes.ok) {
      if (portfolio !== "Contents") {
        const res = await QuoteInitialCallMutation({
          where: {
            id: meta.claim.claimId,
          },
        });
        if (res) {
          snackbar.toggleOpen({
            message: res.messages[0],
          });
          if (res.success) {
            const ref = window[Symbol.for("__refreshQuote")];
            if (ref) ref();
          }
        }
      } else {
        const res = await QuoteInitialCallContentMutation({
          where: {
            //id: meta.claim.claimId,
            id: quote.jobQuoteId,
          },
        });
        if (res) {
          snackbar.toggleOpen({
            message: res.messages[0],
          });
          if (res.success) {
            const ref = window[Symbol.for("__refreshQuote")];
            if (ref) ref();
          }
        }
      }
    }
  };

  const handleAppointment = () => {
    appointment.current.handleOpen();
  };

  const handleExportToExcel = async () => {
    const res = await QuoteExportToExcelMutation({
      where: {
        claimId: meta.claim.claimId,
        portfolios: portfolio,
        requestCategory: "DesktopQuoting",
      },
    });
    if (res.ok && res.messages) window.open(res.messages[0]);
  };

  const handleQuoteCompleted = async () => {
    const actions = window[Symbol.for("__CompleteQuoteAction")];
    if (actions) {
      actions.handleOpen();
    }
  };

  return (
    <>
      {quote.actions.makeInitialCall.isDisplay && (
        <Grid item xs={2} className={classes.actionBlock}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleInitialCall}
            fullWidth
            label={quote.actions.makeInitialCall.label}
            disabled={quote.actions.makeInitialCall.isDisabled}
          />
        </Grid>
      )}
      {quote.actions.makeAppointment.isDisplay && (
        <Grid item xs={2} className={classes.actionBlock}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAppointment}
            fullWidth
            label={quote.actions.makeAppointment.label}
            disabled={quote.actions.makeAppointment.isDisabled}
          />
        </Grid>
      )}
      <Grid item xs={2} className={classes.actionBlock}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleExportToExcel}
          fullWidth
          label="Export to excel"
        />
      </Grid>
      {quote.actions.submitQuote.isDisplay && (
        <Grid item xs={2} className={classes.actionBlock}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleQuoteCompleted}
            fullWidth
            label={quote.actions.submitQuote.label}
            disabled={quote.actions.submitQuote.isDisabled}
          />
        </Grid>
      )}
      <CompleteQuoteAction portfolios={portfolio} quote={quote} meta={meta} />
      <AppointmentMadeAction claimId={meta.claim.claimId} dlRef={appointment} />
    </>
  );
};

const handleNextStep = () => {
  const actions = window[Symbol.for("__nextStepAction")];
  if (actions) {
    actions.handleOpen();
  }
};

export default withStyles((theme) => ({
  root: {
    width: "100%",
  },
  actionBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))(ScopeActionBar);
