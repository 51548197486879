import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: res => {
          resolve(res)
        },
        onError: errors => {
          console.log(errors)
          resolve({ ok: false })
        }
      }
    )
  })
}

const mutation = graphql`
  mutation CreateRoomMutation($data: RoomCreateDataInput!) {
    roomCreate(data: $data) {
      id
      callSessions {
        id
        userType
        callStatus {
          status
          message
        }
      }
    }
  }
`