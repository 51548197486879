import React, { Component } from 'react'

import Grid from 'components/Mui/Grid/'
import Paper from 'components/Mui/Paper/'
import Form from 'components/Mui/Form/'
import Button from 'components/Mui/Button/'
import Input from 'components/Mui/Input/'
import Switch from 'components/Mui/Switch/'

class Daily extends Component {
  render() {
    return (
      <Form onSubmit={this.submitHandle}>
        <Grid container>
          <Grid item xs>
            <Paper
              content={(
                <>
                  <Grid fluid>
                    <Grid item xs={3}>
                      <Input type='text' label='Text' name='text' />
                    </Grid>
                    <Grid item xs={3}>
                      <Input type='date' label='Date' name='date' />
                    </Grid>
                    <Grid item xs={3}>
                      <Input type='date' label='Date' />
                    </Grid>
                    <Grid item xs={3}>
                      <Switch label='Is true?' name='yeah' />
                    </Grid>
                  </Grid>
                  <Grid fluid>
                    <Grid item xs={3}>
                      <Button variant='contained' color='primary' type='submit' label='Submit' />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
        </Grid>
      </Form>
    )
  }
  submitHandle = data => console.log(data)
}

export default Daily