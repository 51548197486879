import React from 'react'

import { useMutation } from 'react-relay-mutation'
import { graphql } from 'relay-runtime'
import JoinRoomMutation from '../../Room/mutations/JoinRoomMutation'
import uaParser from 'ua-parser-js'

export default ({ ready, callSessionId, signallingData }) => {
  const [joinRoom] = useMutation(JoinRoomMutation)

  const [data, setData] = React.useState(null)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if(ready) {
      (async () => {
        const userAgentInfo = getUserAgentInfo()
        // const userAddressInfo = await getUserAddressInfo()
        //   .catch(error => undefined)

        // console.log({
        //   userAgentInfo,
        //   userAddressInfo
        // })

        const res = await joinRoom({ variables: {
          where: { callSessionId },
          data: {
            sdpOffer: signallingData.offer,
            clientIceCandidates: signallingData.candidates,
            userAgentInfo,
            // userAddressInfo
          }
        }})
        .catch(setError)

        if(res) {
          const { sdpAnswer, serverIceCandidates } = res.roomJoin
          setData({ sdpAnswer, serverIceCandidates })
        }
      })()
    }
  }, [ready, callSessionId, joinRoom, signallingData.candidates, signallingData.offer])

  return { data, error }
}

const getUserAgentInfo = () => {
  const parser = new uaParser()
  const userAgentInfo = parser.getResult()
  return JSON.stringify(userAgentInfo)
}

const getUserAddressInfo = () => {
  return fetch('https://extreme-ip-lookup.com/json')
    .then(res => res.json())
    .then(res => ({
      ip: res.query,
      lat: res.lat,
      long: res.lon,
      city: res.city,
      region: res.region,
      country: res.country,
      countryCode: res.countryCode,
      continent: res.continent
    }))
    .then(JSON.stringify)
}
