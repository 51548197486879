import React, { Component } from "react";
import { graphql, createPaginationContainer } from "react-relay";

import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";

import StandaloneCommunicationListItem from "./StandaloneCommunicationListItem";

class CommunicationList extends Component {
  componentDidMount = () =>
    document
      .querySelector("#communicationList_scrolling-list")
      .addEventListener("scroll", this.scrollHandler);
  componentWillUnmount = () =>
    document
      .querySelector("#communicationList_scrolling-list")
      .removeEventListener("scroll", this.scrollHandler);

  render() {
    const { classes, data } = this.props;

    return (
      <>
        <Grid container style={{ width: "98%" }}>
          <Grid item xs={12}>
            Found {data.claimCommuications.totalCount} communications
          </Grid>
        </Grid>
        <Grid container style={{ width: "98%" }}>
          <Grid item xs={12}>
            <Paper
              titleClass={classes.noGut}
              title={
                <Grid fluid spacing={0} className={classes.headerContainer}>
                  {[
                    { col: 1, name: "Type" },
                    { col: 1, name: "Ins Ref#" },
                    { col: 1, name: "Customer" },
                    { col: 1, name: "Date" },
                    { col: 1, name: "Sender" },
                    { col: 1, name: "Company" },
                    { col: 5, name: "Message" },
                    { col: 1, name: "Actions" },
                  ].map(({ col, name }, key) => (
                    <Grid
                      className={classes.alignCenter}
                      item
                      key={key}
                      xs={col}
                    >
                      {name}
                    </Grid>
                  ))}
                </Grid>
              }
            >
              <div
                id="communicationList_scrolling-list"
                className={classes.listBody}
              >
                {data.claimCommuications.edges.map(({ node }, k) => (
                  <StandaloneCommunicationListItem
                    index={k + 1}
                    key={k}
                    item={node}
                  />
                ))}
                <div
                  className={
                    data.claimCommuications.edges.length > 0
                      ? classes.notFoundList
                      : ""
                  }
                >
                  None found
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
  scrollHandler = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight * 0.7) {
      this._loadMore();
    }
  };
  _loadMore = () => {
    if (!this.props.relay.hasMore()) return;
    else if (this.props.relay.isLoading()) return;

    this.props.relay.loadMore(this.props.first);
  };
}

export default createPaginationContainer(
  withStyles((theme) => ({
    alignCenter: theme.mixins.alignJustifyContainer(theme),
    noGut: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    listBody: {
      maxHeight: "70vh",
      overflow: "auto",
    },
    notFoundList: {
      display: "none",
    },
    headerContainer: {
      "& > div": {
        borderRight: "1px solid white",
      },
      "& > div:last-child": {
        borderRight: 0,
      },
    },
  }))(CommunicationList),
  graphql`
    fragment CommunicationList on Query {
      claimCommuications(first: $first, after: $after, where: $where)
        @connection(key: "CommunicationList_claimCommuications") {
        totalCount
        edges {
          node {
            ...StandaloneCommunicationListItem_item
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `,
  {
    query: graphql`
      query CommunicationListQuery(
        $first: Int!
        $after: String
        $where: CommunicationFilter
      ) {
        ...CommunicationList
      }
    `,
    getVariables(props, paginationInfo, fragmentVariables) {
      const { cursor } = paginationInfo;
      const { where, first } = fragmentVariables;
      return {
        first,
        after: cursor,
        where,
      };
    },
  }
);
