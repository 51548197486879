import React from 'react'

export const InvoiceActiveContext = React.createContext()

export const InvoiceActiveContextProvider = ({ reducer, initialState, children }) => {
  return (
    <InvoiceActiveContext.Provider value={React.useReducer(reducer, initialState)}>
      {children}
    </InvoiceActiveContext.Provider>
  )
}

export const useInvoiceActiveContext = () => React.useContext(InvoiceActiveContext)