import React, { Component } from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Dialog from 'components/Mui/Dialog'
import Button from 'components/Mui/Button'
import DateInput from 'components/Formik/DateInput'

import MakeSafeAppoinmentMadeMutation from './MakeSafeAppoinmentMadeMutation'

import { withClaimMeta } from 'pages/Claim/ClaimDetails/ClaimMetaContext/'

class MakeSafeAppoinmentMade extends Component {
  snackbar = window[Symbol.for('__snackbar')]
  render() {
    const { dlRef, symbol, meta } = this.props
    const refresh = window[Symbol.for('__refreshMakeSafe')]
    return (
      <Dialog
        noForm noActions
        innerRef={dlRef}
        title='Make Appointment'
        symbol={symbol}
        handleSubmit={this.handleSubmit}
        content={(
          <Formik
            initialData={{
              date: moment()
            }}
            validate={values => {
              let errors = {}
              if(!values.date) errors.date = 'Required!'
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(async () => {
                const { meta, portfolios, id } = this.props

                const variables = {
                  input:{
                    appointmentDate: values.date.format('YYYY-MM-DDTHH:mm:ss'),
                  },
                  where: {
                    id: meta.claim.claimId,
                    portfolioType: portfolios
                  }
                }

                const res = await MakeSafeAppoinmentMadeMutation(variables, id)
                if(res.ok){
                  this.snackbar.toggleOpen({message: res.messages[0]})
                  refresh()
                  this.handleDlClose()
                }
                else {
                  this.snackbar.toggleOpen({message: 'Server error'})
                }

                setSubmitting(false)
              }, 400)
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <DateInput required
                      label='Appointment Date'
                      name='date'
                      // minDate={moment()}
                    />
                  </Grid>
                  <div style={{ marginLeft: 'auto' }}>
                    <Button label='Make appointment' type='submit' variant='contained' color='primary'
                      disabled={isSubmitting}
                    />
                    <Button label='Cancel' variant='outlined' color='primary'
                      onClick={this.handleDlClose}
                      disabled={isSubmitting}
                    />
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      />
    )
  }

  handleDlClose = () => {
    window[Symbol.for('__makeSafeAppointmentMade')].handleClose()
  }
}

export default withClaimMeta(MakeSafeAppoinmentMade)
