/**
 * @flow
 * @relayHash fa7a23a22289c90d5eb01c17aeeaac19
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JobValidationStatus = "ValidationAdjusted" | "ValidationCompleted" | "ValidationDecline" | "ValidationInProgress" | "ValidationNoAdjust" | "ValidationRequired" | "ValidationResetJob" | "ValidationUnableToValidate" | "ValidationUnsureDecline" | "ValidationWithdraw" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuotationType = "AssessorReport" | "MakeSafe" | "Scope" | "UnDefined" | "Variation" | "%future added value";
export type ValidationActionWhere = {|
  quoteId: number,
  portfolioType: PortfolioType,
  type: QuotationType,
|};
export type VariationDetailsActionsQueryVariables = {|
  where: ValidationActionWhere
|};
export type VariationDetailsActionsQueryResponse = {|
  +validationAction: ?$ReadOnlyArray<?{|
    +value: JobValidationStatus,
    +label: string,
    +communicationRequired: boolean,
  |}>
|};
export type VariationDetailsActionsQuery = {|
  variables: VariationDetailsActionsQueryVariables,
  response: VariationDetailsActionsQueryResponse,
|};
*/


/*
query VariationDetailsActionsQuery(
  $where: ValidationActionWhere!
) {
  validationAction(where: $where) {
    value: id
    label: name
    communicationRequired
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ValidationActionWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "validationAction",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ValidationActionWhere!"
      }
    ],
    "concreteType": "ValidationActionGraphType",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": "value",
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": "label",
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "communicationRequired",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VariationDetailsActionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "VariationDetailsActionsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "VariationDetailsActionsQuery",
    "id": null,
    "text": "query VariationDetailsActionsQuery(\n  $where: ValidationActionWhere!\n) {\n  validationAction(where: $where) {\n    value: id\n    label: name\n    communicationRequired\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2d040370445300e63ac45f325fc629c5';
module.exports = node;
