import React from "react";
import environment from "common/relay";
import { graphql, QueryRenderer } from "react-relay";

import { Grid, withStyles } from "@material-ui/core";
import ResetScopeValidationMutation from "./ResetScopeValidationMutation";
import CompleteScopeValidationMutation from "./CompleteScopeValidationMutation";
import SubmitScopeValidation from "./SubmitScopeValidation";
import Button from "components/Mui/Button";
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon";

const query = graphql`
  query ScopeValidationTitleAndButtonQuery(
    $where: JobQuoteIndex!
    $claimParticipantsWhere: ClaimParticipantIndex
  ) {
    jobQuotes(where: $where) {
      edges {
        node {
          jobQuoteId
          actions {
            completeValidation {
              id
              isDisabled
              isDisplay
              label
            }
            resetValidation {
              label
              isDisabled
              isDisplay
            }
            submitValidation {
              label
              isDisabled
              isDisplay
            }
          }
        }
      }
    }
    claimParticipants(where: $claimParticipantsWhere) {
      companyName
      companyId
      requested
      id
    }
  }
`;
const ScopeValidationTitleAndButton = ({
  portfolioType,
  meta,
  handleClose,
  refreshValidation,
  isApprove,
}) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const [refresh, setRefresh] = React.useState(false);
  const refreshToggle = () => {
    setRefresh(!refresh);
  };
  const handleResetScopeValidation = async (jobQuoteId) => {
    const confirm = await snackbar.toggleOpen({
      message: "Please confirm to reset the scope validation",
      firstButton: "Yes",
      secondButton: "No",
      type: "confirm",
    });
    if (confirm.ok) {
      const res = await ResetScopeValidationMutation({
        where: {
          id: jobQuoteId,
          portfolioType: portfolioType,
        },
      });
      if (res.ok) {
        if (res.success) {
          snackbar.toggleOpen({ message: res.messages[0] });
          refreshToggle();
          const refresh = window[Symbol.for("__refreshScopeList")];
          refresh();
          const refreshFooter = window[Symbol.for("__refreshScopeListFooter")];
          if (refreshFooter) refreshFooter();
        }
        snackbar.toggleOpen({ message: res.messages[0] });
      }
    }
  };

  const handleCompleteScopeValidation = async (jobQuoteId) => {
    const confirm = await snackbar.toggleOpen({
      message: "Please confirm to Complete the scope validation",
      firstButton: "Yes",
      secondButton: "No",
      type: "confirm",
    });
    if (confirm.ok) {
      const res = await CompleteScopeValidationMutation({
        where: {
          id: jobQuoteId,
          portfolioType: portfolioType,
        },
      });
      if (res.ok) {
        if (res.success) {
          snackbar.toggleOpen({ message: res.messages[0] });
          //refreshToggle();
          handleClose();
        }
        snackbar.toggleOpen({ message: res.messages[0] });
      }
    }
  };

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        where: {
          claimId: meta.claim.claimId,
          portfolios: portfolioType,
          requestCategory: "ScopeValidation",
        },
        claimParticipantsWhere: {
          claimId: meta.claim.claimId,
          portfolioTypes: portfolioType,
          participantStatus: "RequestedAndAvailable",
          participantType: "QuotingSupplier",
        },
        refresh,
      }}
      render={({ error, props }) => {
        if (!props) return "Loading...";
        const quote =
          props.jobQuotes.edges.length !== 0
            ? props.jobQuotes.edges[0].node
            : null;
        const claimParticipants = props.claimParticipants;
        return (
          <Grid container style={{ width: "99%" }}>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <ClaimTypeIcon type={portfolioType} size="2rem" />
              <div>Scope Validation</div>
            </Grid>
            <Grid item xs={6}>
              {quote.actions.resetValidation.isDisplay && (
                <Button
                  style={{ float: "right" }}
                  variant="outlined"
                  color="primary"
                  disabled={quote.actions.resetValidation.isDisabled}
                  label={quote.actions.resetValidation.label}
                  onClick={() =>
                    handleResetScopeValidation(
                      props.jobQuotes.edges[0].node.jobQuoteId
                    )
                  }
                />
              )}
              {quote.actions.completeValidation.isDisplay && (
                <Button
                  style={{ float: "right" }}
                  variant="outlined"
                  color="primary"
                  disabled={quote.actions.completeValidation.isDisabled}
                  label={quote.actions.completeValidation.label}
                  onClick={() =>
                    handleCompleteScopeValidation(
                      props.jobQuotes.edges[0].node.jobQuoteId
                    )
                  }
                />
              )}
              {quote.actions.submitValidation.isDisplay && (
                <SubmitScopeValidation
                  quote={quote}
                  portfolio={portfolioType}
                  claimParticipants={claimParticipants}
                  setRefresh={setRefresh}
                  refreshValidation={refreshValidation}
                  isApprove={isApprove}
                />
              )}
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default withStyles((theme) => ({
  portfolioIcon: {
    textAlign: "end",
  },
}))(ScopeValidationTitleAndButton);
