import React from 'react'

import ScrollContainer from 'react-scrollbars-custom'
import { withStyles } from '@material-ui/core/styles'
import Paper from 'components/Mui/Paper'

import LineItemListHeader from 'components/LineItemList/LineItemListHeader'
import ListItemListReport from 'components/LineItemList/ListItemListReport'
import FeeListBody from './FeeListBody'
// import spacer from 'common/utils/spacer'

const FeeList = ({ classes, data }) => {
  if (data.feePortfolios.length === 0) {
    return <div className={classes.noItem}>No item</div>
  }
  const { feeCategories, feeCategoriesAggregate, actions } = data.feePortfolios[0]
  if(!feeCategories || (feeCategories && feeCategories.length) === 0) {
    return <div className={classes.noItem}>No item</div>
  }
  const feePreserveComponent = actions.find(e => e.actionType === 'RESERVE_UPDATE')
  let { buildingReserve, contentsReserve, ...noServeAggregate } = feeCategoriesAggregate

  return (
    <Paper className={classes.root} title={(
        <LineItemListHeader
          cols={[
            { col: 4, name: 'Approved Items' },
            { col: 1, name: 'Authorised Amount' },
            { col: 1, name: 'Action' },

            { col: 1, name: 'Invoice Number' },
            { col: 1, name: 'Invoice Amount' },
            { col: 2, name: 'Payment Status' },
            { col: 2, name: 'Invoice Action' },
          ]}
        />
      )}>
      <ScrollContainer className={classes.scrollBody}>
        <FeeListBody categories={feeCategories}/>
      </ScrollContainer>
      <ListItemListReport space={true} report={(feePreserveComponent && feePreserveComponent.isDisplay) ?
        feeCategoriesAggregate :
        noServeAggregate
      } />
    </Paper>
  )
}

export default withStyles(theme => ({
  root: {
    marginTop: theme.spacing.unit
  },
  noItem: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
  scrollBody: {
    height: '49vh !important',
    maxHeight: '49vh',
    overflow: 'auto'
  }
}))(FeeList)
