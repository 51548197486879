import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import MuiDialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'components/Mui/Button'

import Markdown from 'react-markdown'

let resolvePromise
const DialogPromise = ({ classes, symbol }) => {
  const [state, setState] = React.useState({
    open: false,
    title: null,
    content: null,
    firstButton: null,
    secondButton: null,
  })
  const handleClose = () => setState(pre => ({ ...pre, open: false }))

  const toggleOpen = ({ title, content, firstButton, secondButton } = {}) => {
    return new Promise(resolve => {
      resolvePromise = resolve
      setState({ open: true, title, content, firstButton, secondButton })
    })
  }
  const handleYes = () => {
    handleClose()
    if (resolvePromise) {
      resolvePromise({ ok: true })
      resolvePromise = undefined
    }
  }
  const handleNo = () => {
    handleClose()
    if(resolvePromise) {
      resolvePromise({ ok: false })
      resolvePromise = undefined
    }
  }

  React.useEffect(() => {
    window[Symbol.for(symbol)] = {
      toggleOpen,
      handleYes,
      handleNo,
    }

    return () => {
      delete window[Symbol.for(symbol)]
    }
  }, [symbol])

  return (
    <MuiDialog
      open={state.open}
      onClose={handleClose}
      style={{
        zIndex: 1500
      }}
    >
      {state.title && (
        <DialogTitle classes={{ root: classes.title }}>
          {state.title}
        </DialogTitle>
      )}
      {state.content
        && (
          <DialogContent>
            <Markdown source={state.content} escapeHtml={false} />
          </DialogContent>
        )
      }
      <DialogActions>
        {state.secondButton && <Button label={state.secondButton} color='primary' onClick={handleNo} />}
        {state.firstButton && <Button label={state.firstButton} variant='contained' color='primary' onClick={handleYes} />}
      </DialogActions>
    </MuiDialog>
  )
}
export default withStyles({
  title: {
    '& > h6': {
      color: '#000',
      fontSize: 'initial',
    }
  }
})(DialogPromise)