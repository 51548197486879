/**
 * @flow
 * @relayHash 15fa1213e472507d4613c1d13d21656a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type PaymentTypeWhere = {|
  claimId: string,
  claimPortfolioType: PortfolioType,
|};
export type CashSettleAction_PaymentTypesQueryVariables = {|
  where: PaymentTypeWhere
|};
export type CashSettleAction_PaymentTypesQueryResponse = {|
  +options: ?$ReadOnlyArray<?{|
    +label: string,
    +value: number,
  |}>
|};
export type CashSettleAction_PaymentTypesQuery = {|
  variables: CashSettleAction_PaymentTypesQueryVariables,
  response: CashSettleAction_PaymentTypesQueryResponse,
|};
*/


/*
query CashSettleAction_PaymentTypesQuery(
  $where: PaymentTypeWhere!
) {
  options: paymentTypes(where: $where) {
    label: name
    value: paymentTypeId
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "PaymentTypeWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "PaymentTypeWhere!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "paymentTypeId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CashSettleAction_PaymentTypesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "options",
        "name": "paymentTypes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PaymentType",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CashSettleAction_PaymentTypesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "options",
        "name": "paymentTypes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PaymentType",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CashSettleAction_PaymentTypesQuery",
    "id": null,
    "text": "query CashSettleAction_PaymentTypesQuery(\n  $where: PaymentTypeWhere!\n) {\n  options: paymentTypes(where: $where) {\n    label: name\n    value: paymentTypeId\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '85f108efd3f44564f515c737bb2a296d';
module.exports = node;
