import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'

import { moneyFormat } from 'common/utils'

class VariationItem extends Component {

  render() {
    const { classes, variation, setVariationId, toggleDetails } = this.props

    return (
      <>
        <Grid fluid spacing={0} onClick={() => setVariationId(variation.variationId, toggleDetails())} className={classes.row}>
          {[
            { col: 2, name: variation.logdate },
            { col: 4, name: `${variation.variationTitle} - ${variation.variationDescription}` },
            { col: 3, name: variation.variationReason && variation.variationReason.reasonDescription },
            { col: 1, name: `${moneyFormat(variation.total) || 0 }`},
            { col: 2, name: variation.variationStatus },
          ].map(({col, name}, key) => (
              <Grid item key={key} xs={col} className={classes.alignCenter}>
                {name}
              </Grid>
            ))
          }
        </Grid>
      </>
    )
  }

}

export default withStyles(theme => ({
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
  },
  row: {
    ...theme.mixins.alignJustifyContainer(theme),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: 'center',
    borderRadius: '8px',

    '&:nth-child(even)': {
      background: theme.palette.grey[100],
    },
    '&:hover': {
      background: theme.palette.grey[200],
    }
  },
}))(VariationItem)
