import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const mutation = graphql`
  mutation ResetScopeValidationMutation($where: ENDataPortfolioKey!) {
    jobScopeValidationReset(where: $where) {
      success
      messages
      # result {
      #   jobQuoteId
      #   actions {
      #      {
      #       label
      #       isDisabled
      #       isDisplay
      #     }
      #     resetScopeValidation {
      #       label
      #       isDisabled
      #       isDisplay
      #     }
      #   }
      #   scopeTypes {
      #     locations {
      #       id
      #       location {
      #         id
      #         costLocationId
      #         locationName
      #       }
      #       items {
      #         jobQuoteItemId
      #         quoteItemType
      #         accepted
      #         actions {
      #           accept {
      #             label
      #             isDisabled
      #             isDisplay
      #           }
      #           decline {
      #             label
      #             isDisabled
      #             isDisplay
      #           }
      #           amend {
      #             label
      #             isDisabled
      #             isDisplay
      #           }
      #         }
      #         location { costLocationId locationName } dimensions
      #         costCentre { costCentreId costCentreName }
      #         costItem { costItemId itemDescription } lineDescription
      #         unit { unitId unitName }
      #         directsupply
      #         purchasePrice
      #         ageOfItem
      #         proofOfLoss
      #         policyLimit
      #         qty
      #         rate
      #         marginRate
      #         subtotal
      #         logdate
      #         policyLimit
      #         requestedQuote
      #         sirRate
      #         lineStatusDescription
      #         note
      #         scopeType {
      #           name
      #           id
      #         }
      #       }
      #     }
      #     scopeType {
      #       id
      #       name
      #     }
      #     id
      #     gst
      #     margin
      #     sirGst
      #     sirMargin
      #     sirSubtotal
      #     sirTotal
      #     subtotal
      #     total
      #     discount
      #     fields {
      #       marginBreakdown {
      #         isDisplay
      #         label
      #       }
      #       discount {
      #         isDisplay
      #         label
      #         isDisabled
      #       }
      #       sIRRate {
      #         label
      #         isDisplay
      #       }
      #       subtotal {
      #         label
      #         isDisplay
      #       }
      #       gst {
      #         label
      #         isDisplay
      #       }
      #       margin {
      #         label
      #         isDisplay
      #       }
      #       total {
      #         label
      #         isDisplay
      #       }
      #       action {
      #         label
      #         isDisplay
      #       }
      #       costCentre {
      #         label
      #         isDisplay
      #       }
      #       description {
      #         label
      #         isDisplay
      #       }
      #       marginRate {
      #         label
      #         isDisplay
      #       }
      #       margin {
      #         label
      #         isDisplay
      #       }
      #       qty {
      #         label
      #         isDisplay
      #       }
      #       rate {
      #         label
      #         isDisplay
      #       }
      #       subtotal {
      #         label
      #         isDisplay
      #       }
      #       unit {
      #         label
      #         isDisplay
      #       }
      #     }
      #   }
      # }
    }
  }
`;

export default (variables) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (res) => {
        const { success, messages } = res.jobScopeValidationReset;
        resolve({ ok: true, success, messages });
      },
      onError: (errors) => {
        resolve({
          ok: false,
          messages: ["Unable to complete this action. Please try again!"],
        });
      },
    });
  });
};
