import React from 'react'

import ToggleIcon from 'material-ui-toggle-icon'
import Icon from '@material-ui/core/Icon'

export default ({
  actionConfigs, actions,
  isOwner, loading
}) => {
  const actionButtons = [
    {
      name: 'takingPicture', icon: 'add_a_photo',
      onClick: actions.getPhoto
    },
    {
      name: 'toggleAudio',
      onClick: actions.toggleVideo,
      icon: (
        <ToggleIcon
          on={actions.videoState}
          onIcon={<Icon>videocam</Icon>}
          offIcon={<Icon>videocam_off</Icon>}
        />
      )
    },
    {
      name: 'toggleVideo',
      onClick: actions.toggleAudio,
      icon: (
        <ToggleIcon
          on={actions.audioState}
          onIcon={<Icon>mic</Icon>}
          offIcon={<Icon>mic_off</Icon>}
        />
      )
    },
    {
      name: 'hangup', icon: 'call_end', color: 'danger',
      onClick: actions.hangup
    },
  ].filter(button => {
    if(loading && button.name === 'hangup') return true
    if(loading) return false

    if(isOwner) return true

    if(actionConfigs) return actionConfigs[button.name]

    return false
  })

  return actionButtons
}