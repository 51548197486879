import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const SubmitScopeValidationMutation = graphql`
  mutation SubmitScopeValidationMutation(
    $input: JobScopeValidationSubmitInput!
    $where: ENDataPortfolioKey!
  ) {
    jobScopeValidationSubmit(where: $where, input: $input) {
      success
      messages
    }
  }
`;

// export default (variables) => {
//   return new Promise((resolve, reject) => {
//     commitMutation(environment, {
//       mutation,
//       variables,
//       onCompleted: (res) => {
//         const { success, messages } = res.jobScopeValidationSubmit;
//         resolve({ ok: true, success, messages });
//       },
//       onError: (errors) => {
//         resolve({
//           ok: false,
//           messages: ["Unable to complete this action. Please try again!"],
//         });
//       },
//     });
//   });
// };
export default SubmitScopeValidationMutation;
