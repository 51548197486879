/**
 * @flow
 * @relayHash 7a2609a363bb199cc8d5e5163ad30e76
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LogLevel = "Critical" | "Debug" | "Error" | "Information" | "None" | "Trace" | "Warning" | "%future added value";
export type SpecialistRequestUniqueWhere = {|
  specialistRequestId: number
|};
export type SpecialistRequestTableDeleteMutationVariables = {|
  where: SpecialistRequestUniqueWhere
|};
export type SpecialistRequestTableDeleteMutationResponse = {|
  +data: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +fieldErrors: ?$ReadOnlyArray<?{|
      +fieldName: string,
      +level: LogLevel,
      +message: string,
    |}>,
  |}
|};
export type SpecialistRequestTableDeleteMutation = {|
  variables: SpecialistRequestTableDeleteMutationVariables,
  response: SpecialistRequestTableDeleteMutationResponse,
|};
*/


/*
mutation SpecialistRequestTableDeleteMutation(
  $where: SpecialistRequestUniqueWhere!
) {
  data: specialistRequestDelete(where: $where) {
    success
    messages
    fieldErrors {
      fieldName
      level
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "SpecialistRequestUniqueWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "data",
    "name": "specialistRequestDelete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "SpecialistRequestUniqueWhere!"
      }
    ],
    "concreteType": "SpecialistRequestPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "fieldErrors",
        "storageKey": null,
        "args": null,
        "concreteType": "EntityFieldError",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fieldName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "level",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SpecialistRequestTableDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SpecialistRequestTableDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SpecialistRequestTableDeleteMutation",
    "id": null,
    "text": "mutation SpecialistRequestTableDeleteMutation(\n  $where: SpecialistRequestUniqueWhere!\n) {\n  data: specialistRequestDelete(where: $where) {\n    success\n    messages\n    fieldErrors {\n      fieldName\n      level\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a8b94c3f6e47421dee919ad05396df6a';
module.exports = node;
