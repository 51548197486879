/**
 * @flow
 * @relayHash 154f0a8cfdac514080c5e89c14bfabba
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClaimJobStatus = "VariationApproved" | "VariationRejected" | "VariationReset" | "VariationSubmitted" | "%future added value";
export type JobRecommendationStatus = "NotRecommended" | "Recommended" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuoteRequestStatus = "AdditionalItems" | "AppointmentMade" | "AwaitingInfo" | "AwaitingInfoContents" | "ClaimFinalised" | "InitialCallMade" | "JobAuthorised" | "JobLost" | "NewClaims" | "OfferScopeAcc" | "QuoteSubmitted" | "WaitingQuotes" | "WaitingResult" | "WaitingScope" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type ClaimStatusVolumeWhere = {|
  id?: ?string,
  parentId?: ?string,
  subject?: ?string,
  createDateFrom?: ?any,
  createDateTo?: ?any,
  incidentDate?: ?any,
  incidentDateFrom?: ?any,
  incidentDateTo?: ?any,
  keyWords?: ?string,
  refNumber?: ?string,
  postcode?: ?string,
  managerTeams?: ?$ReadOnlyArray<?string>,
  managers?: ?$ReadOnlyArray<string>,
  insurers?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  agents?: ?$ReadOnlyArray<string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  buildingSuppliers?: ?$ReadOnlyArray<string>,
  contentsSuppliers?: ?$ReadOnlyArray<string>,
  restorationSuppliers?: ?$ReadOnlyArray<string>,
  buildingStatus?: ?$ReadOnlyArray<string>,
  contentsStatus?: ?$ReadOnlyArray<string>,
  restorationStatus?: ?$ReadOnlyArray<string>,
  lossAdjusterStatus?: ?$ReadOnlyArray<string>,
  suppliers?: ?$ReadOnlyArray<string>,
  status?: ?$ReadOnlyArray<string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  jobStatuses?: ?$ReadOnlyArray<string>,
  claimStatusStageIds?: ?$ReadOnlyArray<string>,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  states?: ?$ReadOnlyArray<?StateEnum>,
  variationStatuses?: ?$ReadOnlyArray<?ClaimJobStatus>,
  requestStatuses?: ?$ReadOnlyArray<?QuoteRequestStatus>,
  recommendationStatus?: ?$ReadOnlyArray<JobRecommendationStatus>,
  or?: ?ClaimJobFilter,
  and?: ?ClaimJobFilter,
  not?: ?ClaimJobFilter,
  claimPortfolioType: PortfolioType,
|};
export type ClaimJobFilter = {|
  id?: ?string,
  parentId?: ?string,
  subject?: ?string,
  createDateFrom?: ?any,
  createDateTo?: ?any,
  incidentDate?: ?any,
  incidentDateFrom?: ?any,
  incidentDateTo?: ?any,
  keyWords?: ?string,
  refNumber?: ?string,
  postcode?: ?string,
  managerTeams?: ?$ReadOnlyArray<?string>,
  managers?: ?$ReadOnlyArray<string>,
  insurers?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  agents?: ?$ReadOnlyArray<string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  buildingSuppliers?: ?$ReadOnlyArray<string>,
  contentsSuppliers?: ?$ReadOnlyArray<string>,
  restorationSuppliers?: ?$ReadOnlyArray<string>,
  buildingStatus?: ?$ReadOnlyArray<string>,
  contentsStatus?: ?$ReadOnlyArray<string>,
  restorationStatus?: ?$ReadOnlyArray<string>,
  lossAdjusterStatus?: ?$ReadOnlyArray<string>,
  suppliers?: ?$ReadOnlyArray<string>,
  status?: ?$ReadOnlyArray<string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  jobStatuses?: ?$ReadOnlyArray<string>,
  claimStatusStageIds?: ?$ReadOnlyArray<string>,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  states?: ?$ReadOnlyArray<?StateEnum>,
  variationStatuses?: ?$ReadOnlyArray<?ClaimJobStatus>,
  requestStatuses?: ?$ReadOnlyArray<?QuoteRequestStatus>,
  recommendationStatus?: ?$ReadOnlyArray<JobRecommendationStatus>,
  or?: ?ClaimJobFilter,
  and?: ?ClaimJobFilter,
  not?: ?ClaimJobFilter,
|};
export type WaterfallFilterGroupsQueryVariables = {|
  where: ClaimStatusVolumeWhere
|};
export type WaterfallFilterGroupsQueryResponse = {|
  +currentUser: {|
    +waterfallFilters: ?$ReadOnlyArray<{|
      +id: string,
      +items: $ReadOnlyArray<{|
        +label: string,
        +value: string,
        +claimCount: number,
        +color: string,
      |}>,
    |}>
  |}
|};
export type WaterfallFilterGroupsQuery = {|
  variables: WaterfallFilterGroupsQueryVariables,
  response: WaterfallFilterGroupsQueryResponse,
|};
*/


/*
query WaterfallFilterGroupsQuery(
  $where: ClaimStatusVolumeWhere!
) {
  currentUser {
    waterfallFilters(where: $where) {
      id
      items {
        label
        value: id
        claimCount
        color
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimStatusVolumeWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ClaimStatusVolumeWhere!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "claimCount",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "color",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WaterfallFilterGroupsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticatedUser",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "waterfallFilters",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "ClaimStatusVolumeGroup",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "items",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimStatusVolume",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "WaterfallFilterGroupsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticatedUser",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "waterfallFilters",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "ClaimStatusVolumeGroup",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "items",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimStatusVolume",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "WaterfallFilterGroupsQuery",
    "id": null,
    "text": "query WaterfallFilterGroupsQuery(\n  $where: ClaimStatusVolumeWhere!\n) {\n  currentUser {\n    waterfallFilters(where: $where) {\n      id\n      items {\n        label\n        value: id\n        claimCount\n        color\n        id\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6b50c102248adc98a8a0c23079e0b9b7';
module.exports = node;
