import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";
import { getSafe } from "common/utils";

const mutation = graphql`
  mutation AddNewClaimTypeActionMutation(
    $input: AddPortfolioInput!
    $where: ENDataEntityKey!
  ) {
    claimAddPortfolio(input: $input, where: $where) {
      success
      messages
    }
  }
`;

export default (variables, id) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      //         updater: store => {
      //           const payload = store.getRootField('claimReallocateScopingSupplier')
      //           if(payload.getValue('success')) {
      //             const claim = store.get(id)
      //             const claimPortfolio = claim.getLinkedRecord(variables.where.portfolioType.toLowerCase())
      //             const portfolioScopingSupllier = claimPortfolio.getLinkedRecord('scopingSupplier')
      //
      //             const newReallocate = payload.getLinkedRecord('result').getValue('reallocate')
      //             const newCompanyName = payload.getLinkedRecord('result').getLinkedRecord('scopingSupplier')
      //               .getValue('companyName')
      //
      //             claimPortfolio.setValue(newReallocate, 'reallocate')
      //             portfolioScopingSupllier.setValue(newCompanyName, 'companyName')
      //           }
      //         },

      onCompleted: (res) => {
        resolve({
          ok: true,
          success: getSafe(() => res.claimAddPortfolio.success, ""),
          messages: getSafe(() => res.claimAddPortfolio.messages, ""),
        });
      },
      onError: (errors) => {
        resolve({ ok: false });
      },
    });
  });
};
