import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Mui/Button'
import MuiSnackbar from '@material-ui/core/Snackbar'

class Snackbar extends Component {
  resolve
  state = {
    open: false,
    message: '',
    firstButton: '',
    secondButton: '',
    type: 'info',
  }

  componentDidMount = () => {
    window[Symbol.for(this.props.symbol)] = {
      toggleOpen: this.toggleOpen,
      handleYes: this.handleYes,
      handleNo: this.handleNo,
    }
  }

  componentWillUnmount = () => {
    delete window[Symbol.for(this.props.symbol)]
  }

  toggleOpen = ({message, firstButton, secondButton, type, timeout}) => {
    return new Promise((resolve, reject) => {
      this.setState({ open: true, message, firstButton, secondButton, type, timeout })
      this.resolve = resolve
    })
  }

  handleYes = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.resolve({ ok: true })
    this.setState({ open: false })
  }

  handleNo = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.resolve({ ok: false })

    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props
    const { message, firstButton, secondButton, type, timeout } = this.state

    return (
      <MuiSnackbar
        key={message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={type === 'confirm' ? undefined : timeout ? timeout : 1200}
        onClose={this.handleNo}
        ContentProps={{
          'aria-describedby': 'message-id',
          // style: {
          //   backgroundColor:
          //     type === 'error' ? 'red' :
          //     type === 'info' ? 'green' :
          //     undefined            
          // }
        }}
        className={classes.root}
        message={<div id='message-id' className={classes.messages}>{message}</div>}
        action={type === 'confirm' ? [
          <Button key='firstButton' label={firstButton} variant='contained' color='primary' size='small' onClick={this.handleYes}/>,
          <Button key='secondButton' label={secondButton} variant='contained' color='secondary' size='small' onClick={this.handleNo}/>,
        ] : undefined }
      />
    )
  }
}

export default withStyles(theme => ({
  root: {
    top: '75px'
  }
}))(Snackbar)