import { graphql } from "react-relay";

const RecommendVariationValidationMutation = graphql`
  mutation RecommendVariationValidationMutation(
    $input: JobVariationValidationSubmitInput!
    $where: ENDataPortfolioKey!
  ) {
    jobVariationValidationRecommend(where: $where, input: $input) {
      success
      messages
      result {
        id
        variationId
        completed
        variationStatus

        # info card
        variationTitle
        variationDescription
        variationReason {
          reasonDescription
        }
        actions {
          removeAuthority {
            isDisabled
            isDisplay
            label
          }
          resetValidation {
            isDisabled
            isDisplay
            label
          }
          recommendVariation {
            isDisabled
            isDisplay
            label
          }
          viewValidation {
            isDisabled
            isDisplay
            label
          }
          accept {
            isDisabled
            isDisplay
            label
          }
          addLineItem {
            isDisabled
            isDisplay
            label
          }
          decline {
            isDisabled
            isDisplay
            label
          }
          reset {
            isDisabled
            isDisplay
            label
          }
          submit {
            isDisabled
            isDisplay
            label
          }
          submitValidation {
            isDisabled
            isDisplay
            label
          }
          startValidation {
            isDisabled
            isDisplay
            label
          }
          completeValidation {
            isDisabled
            isDisplay
            label
          }
          submitValidation {
            isDisabled
            isDisplay
            label
          }
        }

        scopeTypes {
          locations {
            id
            location {
              id
              costLocationId
              locationName
            }
            items {
              actions {
                accept {
                  isDisabled
                  isDisplay
                  label
                }
                amend {
                  isDisabled
                  isDisplay
                  label
                }
                decline {
                  isDisabled
                  isDisplay
                  label
                }
                validate {
                  isDisabled
                  isDisplay
                  label
                }
              }
              variationItemId
              quoteItemType
              accepted
              location {
                costLocationId
                locationName
              }
              dimensions
              costCentre {
                costCentreId
                costCentreName
              }
              costItem {
                costItemId
                itemDescription
              }
              lineDescription
              unit {
                unitId
                unitName
              }
              qty
              note
              lineStatusDescription
              rate
              marginRate
              subtotal
              logdate
              sirRate
              scopeType {
                name
                id
              }
            }
          }
          scopeType {
            id
            name
          }
          id
          gst
          margin
          sirGst
          sirMargin
          sirSubtotal
          sirTotal
          subtotal
          total
          discount
          fields {
            marginBreakdown {
              isDisplay
              label
            }
            discount {
              isDisplay
              label
              isDisabled
            }
            sIRRate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            gst {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            total {
              label
              isDisplay
            }
            action {
              label
              isDisplay
            }
            costCentre {
              label
              isDisplay
            }
            description {
              label
              isDisplay
            }
            marginRate {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            qty {
              label
              isDisplay
            }
            rate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            unit {
              label
              isDisplay
            }
          }
        }

        # item table
        lineItems {
          variationItemId
          accepted

          location {
            costLocationId
            locationName
          }
          dimensions
          sirRate
          costCentre {
            costCentreId
            costCentreName
          }
          costItem {
            costItemId
            itemDescription
          }
          lineDescription
          unit {
            unitId
            unitName
          }
          marginRate
          qty
          rate
          gst
          subtotal
        }

        # report
        subtotal
        gst
        margin
        total
      }
    }
  }
`;

export default RecommendVariationValidationMutation;
