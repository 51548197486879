import React from "react";

import { Formik, Form } from "formik";
import Dialog from "components/Mui/Dialog";
import Grid from "components/Mui/Grid";
import Input from "components/Formik/Input";
import DateInput from "components/Formik/DateInput";
import MoneyInput from "components/Formik/MoneyInput";
import Button from "components/Mui/Button";

import { useQuery, graphql } from "relay-hooks";
import { useMutation } from "react-relay-mutation";

import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";

export default ({ symbol }) => {
  const meta = useMetaContext();
  const [castSettle] = useMutation(CASH_SETTLE_MUTATION);
  const snackbar = window[Symbol.for("__snackbar")];

  return (
    <Dialog
      noForm
      noActions
      symbol={symbol}
      title="Cash Settle"
      content={
        <Formik
          initialValues={{
            claimPortfolioType: "",
            settlementDate: "",
            settlementValue: "",
            bankName: "",
            bsb: "",
            account: "",
            comments: "",
            paymentTypeId: "",
            paymentMethodId: "",
          }}
          validate={(values) => {
            let errors = {};

            if (!values.claimPortfolioType)
              errors.claimPortfolioType = "required";
            if (!values.settlementDate) errors.settlementDate = "required";
            if (values.settlementValue === "" || values.settlementValue < 0)
              errors.settlementValue = "required";
            if (!values.paymentTypeId) errors.paymentTypeId = "required";
            if (!values.paymentMethodId) errors.paymentMethodId = "required";

            if (values.bankName.length > 100)
              errors.bankName = "Maximum character limit is 100";
            if (values.bsb.length > 50)
              errors.bsb = "Maximum character limit is 50";
            if (values.account.length > 50)
              errors.account = "Maximum character limit is 50";
            if (values.comments.length > 250)
              errors.comments = "Maximum character limit is 250";

            return errors;
          }}
          onSubmit={(values, actions) => {
            const submitValue = {
              ...values,
              settlementDate: values.settlementDate.format("DD/MM/YYYY"),
              claimId: meta.claim.claimId,
            };
            setTimeout(async () => {
              console.log(submitValue);
              const {
                data: { success, messages },
              } = await castSettle({ variables: { input: submitValue } });
              actions.setSubmitting(false);

              if (success) {
                // close
                if (window[Symbol.for(symbol)]) {
                  window[Symbol.for(symbol)].handleClose();
                }
                // and reload
                window[Symbol.for("__refreshClaimDetails")]();
              } else {
                // error message
                snackbar.toggleOpen({ message: messages[0] });
              }
            });
          }}
        >
          {({ values, isSubmitting }) => (
            <RequestForm
              values={values}
              isSubmitting={isSubmitting}
              symbol={symbol}
            />
          )}
        </Formik>
      }
    />
  );
};

const RequestForm = ({ values, isSubmitting, symbol }) => {
  return (
    <Form>
      <Grid fluid>
        <Grid xs={12}>
          <Input
            required
            leadIcon="event"
            label="Portfolio type"
            name="claimPortfolioType"
            fullWidth
            variant="outlined"
            select
            options={[
              {
                label: "Building",
                value: "Building",
              },
              {
                label: "Contents",
                value: "Contents",
              },
            ]}
          />
        </Grid>
        {values.claimPortfolioType && (
          <CashSettleFormFields values={values} isSubmitting={isSubmitting} />
        )}
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            label="Close"
            color="primary"
            onClick={() => {
              if (window[Symbol.for(symbol)]) {
                window[Symbol.for(symbol)].handleClose();
              }
            }}
          />
          <Button
            label="Settle"
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

const CashSettleFormFields = ({ values, isSubmitting }) => {
  const meta = useMetaContext();

  const { props: paymentTypes } = useQuery({
    query: PAYMENT_TYPE_QUERY,
    variables: {
      where: {
        claimId: meta.claim.claimId,
        claimPortfolioType: values.claimPortfolioType,
      },
    },
  });

  const { props: paymentMethods } = useQuery({
    query: PAYMENT_METHOD_QUERY,
    variables: {
      where: {
        claimId: meta.claim.claimId,
      },
    },
  });

  if (!paymentTypes || !paymentMethods) {
    return null;
  }

  return (
    <>
      <Grid item xs={3}>
        <DateInput
          disableFuture
          required
          label="Settlement Date"
          name="settlementDate"
        />
      </Grid>
      <Grid item xs={3}>
        <MoneyInput required label="Settlement Value" name="settlementValue" />
      </Grid>
      <Grid item xs={3}>
        <Input
          required
          leadIcon="event"
          label="Payment Type"
          name="paymentTypeId"
          fullWidth
          variant="outlined"
          select
          options={paymentTypes.options}
        />
      </Grid>
      <Grid item xs={3}>
        <Input
          required
          leadIcon="event"
          label="Payment Method"
          name="paymentMethodId"
          fullWidth
          variant="outlined"
          select
          options={paymentMethods.options}
        />
      </Grid>
      <Grid item xs={3}>
        <Input label="Bank Name" name="bankName" />
      </Grid>
      <Grid item xs={3}>
        <Input label="BSB" name="bsb" />
      </Grid>
      <Grid item xs={2}>
        <Input label="Account" name="account" />
      </Grid>
      <Grid item xs={4}>
        <Input label="Comments" name="comments" />
      </Grid>
    </>
  );
};

const PAYMENT_TYPE_QUERY = graphql`
  query CashSettleAction_PaymentTypesQuery($where: PaymentTypeWhere!) {
    options: paymentTypes(where: $where) {
      label: name
      value: paymentTypeId
    }
  }
`;

const PAYMENT_METHOD_QUERY = graphql`
  query CashSettleAction_PaymentMethodsQuery($where: PaymentMethodWhere!) {
    options: paymentMethods(where: $where) {
      label: name
      value: paymentMethodId
    }
  }
`;

const CASH_SETTLE_MUTATION = graphql`
  mutation CashSettleAction_CreateMutation($input: ClaimCashSettleInput!) {
    data: claimCaseSettleCreate(input: $input) {
      success
      messages
      fieldErrors {
        fieldName
        level
        message
      }
    }
  }
`;
