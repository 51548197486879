import React from 'react'
import { parse, stringify } from 'query-string'

import useReactRouter from 'use-react-router'

export default meta => {
  const { history } = useReactRouter()

  const [ portfolio, setPortfolio ] = React.useState(null)

  React.useEffect(() => {
    let portfolios = []
    if(meta.claim.hasBuilding) portfolios.push('building')
    if(meta.claim.hasContents) portfolios.push('contents')
    if(meta.claim.hasRestoration) portfolios.push('restoration')
    if(portfolios.length >= 2) portfolios.push('')

    if(portfolios.indexOf('') !== -1) {
      setPortfolio('')
    } else {
      setPortfolio(`${portfolios[0][0].toUpperCase()}${portfolios[0].substr(1)}`)
    }
  }, [portfolio, history.location.search])

  // Default portfolio routing
  const changePortfolio = portfolio => {
    setPortfolio(portfolio)

    const search = parse(history.location.search)
    search.portfolioFilter = portfolio ? portfolio.toLowerCase() : ''

    history.push(`${history.location.pathname}?${stringify(search)}`)
  }

  React.useEffect(() => {
    if(portfolio || portfolio === '') changePortfolio(portfolio)
  }, [portfolio])

  return [ portfolio, changePortfolio ]
}