import React from "react";

import moneyFormat from "common/utils/moneyFormat";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import FeeInfo from "./FeeInfo";

import { useMutation } from "react-relay-mutation";
import FeeInvoiceUpdateDialog from "../../Actions/FeeInvoiceUpdateDialog";
import ViewDocButton from "components/ViewDocButton";
import { useInvoiceActiveContext } from "./InvoiceActiveContext";
import FeeInvoiceDeleteMutation from "../../Actions/FeeInvoiceDeleteMutation";
import { useFeeReloadContext } from "../../FeeReloadContext.js";

export default ({ invoices }) => {
  return (
    <List component="div" style={{ padding: 0 }}>
      {invoices.map((feeItemInvoice, index) => (
        <FeeInvoice
          key={feeItemInvoice.id}
          feeItemInvoice={feeItemInvoice}
          isLast={Boolean(index === invoices.length - 1)}
        />
      ))}
    </List>
  );
};
const FeeInvoice = withStyles((theme) => ({
  root: { margin: 0, padding: 0, height: 56 },
  invoiceNumber: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  info: { marginLeft: "10px" },
  amount: { textAlign: "center" },
  actions: { textAlign: "center" },
  action: { padding: 8 },
  viewDoc: {
    display: "inline-flex",
    position: "relative",
    verticalAlign: "middle",
    "& > a": {
      textDecoration: "none",
    },
  },
  invoiceHover: {
    backgroundColor: "#acacac !important",
  },
}))(({ feeItemInvoice, isLast, classes }) => {
  const [{ id: activeInvoiceId }, dispatch] = useInvoiceActiveContext();
  const [deleteFeeInvoice] = useMutation(FeeInvoiceDeleteMutation);
  const { reload, setReload } = useFeeReloadContext();
  const snackbar = window[Symbol.for("__snackbar")];
  const getAcions = (actions) => {
    return actions.map((action) => {
      switch (action.actionType) {
        case "INVOICE_UPDATE_STATUS":
          return {
            ...action,
            icon: "money_off",
            onClick: () => {
              if (
                window[
                  Symbol.for(
                    `__feeInvoiceUpdateDialog_${feeItemInvoice.feeInvoice.id}`
                  )
                ]
              ) {
                window[
                  Symbol.for(
                    `__feeInvoiceUpdateDialog_${feeItemInvoice.feeInvoice.id}`
                  )
                ].handleOpen();
              }
            },
          };
        case "INVOICE_DELETE":
          return {
            ...action,
            icon: "delete",
            onClick: async () => {
              const res = await window[Symbol.for("__snackbar")].toggleOpen({
                message: "Please confirm to delete the item",
                firstButton: "Delete",
                secondButton: "Cancel",
                type: "confirm",
              });
              if (res.ok) {
                const ret = await deleteFeeInvoice({
                  variables: {
                    where: {
                      feeInvoiceId: feeItemInvoice.feeInvoice.feeInvoiceId,
                    },
                  },
                });
                snackbar.toggleOpen({
                  message: ret.feeInvoiceDelete.messages[0],
                });
                if (ret.feeInvoiceDelete.success) {
                  setReload(!reload);
                }
              }
            },
          };
        default:
          return action;
      }
    });
  };

  const mouseEnterHandler = () => {
    if (activeInvoiceId !== feeItemInvoice.feeInvoice.id) {
      dispatch({ type: "mouseEnter", id: feeItemInvoice.feeInvoice.id });
    }
  };
  const mouseLeaveHandler = () => {
    if (activeInvoiceId !== null) {
      dispatch({ type: "mouseLeave" });
    }
  };

  return (
    <>
      <FeeInvoiceUpdateDialog
        feeInvoice={feeItemInvoice.feeInvoice}
        symbol={`__feeInvoiceUpdateDialog_${feeItemInvoice.feeInvoice.id}`}
      />
      <ListItem
        component={Grid}
        fluid
        className={clsx(classes.root, {
          [classes.invoiceHover]: Boolean(
            activeInvoiceId === feeItemInvoice.feeInvoice.id
          ),
        })}
        onMouseEnter={() => mouseEnterHandler()}
        onMouseLeave={() => mouseLeaveHandler()}
      >
        <Grid item xs={2} className={classes.invoiceNumber}>
          <FeeInfo invoiceItem={feeItemInvoice} />
          <p className={classes.info}>{feeItemInvoice.feeInvoice.number}</p>
        </Grid>
        <Grid item xs={2} className={classes.amount}>
          {moneyFormat(feeItemInvoice.amount)}
        </Grid>
        <Grid item xs={4}>
          {feeItemInvoice.feeInvoice.feeInvoiceStatus
            ? feeItemInvoice.feeInvoice.feeInvoiceStatus.name
            : null}
        </Grid>
        <Grid item xs={4} className={classes.actions}>
          <Grid fluid>
            {getAcions(feeItemInvoice.feeInvoice.actions)
              .filter((button) => button.actionType !== "RESERVE_DETAIL_VIEW")
              .map((button, index) => {
                return (
                  <Grid item xs={4}>
                    {button.actionType === "INVOICE_VIEW_DOCUMENT" &&
                    button.isDisplay ? (
                      <ViewDocButton
                        key={index}
                        title={button.name}
                        disabled={button.isDisabled}
                        className={classes.viewDoc}
                        url={feeItemInvoice.feeInvoice.document.url}
                      />
                    ) : (
                      button.isDisplay && (
                        <IconButton
                          key={index}
                          title={button.name}
                          disabled={button.isDisabled}
                          className={classes.action}
                          onClick={button.onClick}
                        >
                          <Icon>{button.icon}</Icon>
                        </IconButton>
                      )
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </ListItem>
      {!isLast && <Divider />}
    </>
  );
});
