import React, { Component, createRef } from 'react'
import { MDCRipple } from '@material/ripple'
import { MDCIconButtonToggle } from '@material/icon-button'

import { withRouter } from 'react-router-dom'

class IconButton extends Component {
	element = createRef()
	icon = {}
	componentDidMount = () => {
		const { ripple, iconOn } = this.props

		if(ripple) {
			const iconButtonRipple = new MDCRipple(this.element.current)
			iconButtonRipple.unbounded = true
		}
		if(iconOn) {
			this.icon = new MDCIconButtonToggle(this.element.current)
		}
    if(this.icon) {
      this.icon.on = this.props.active
    }
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.active !== prevProps.active) {
    // console.log(this.props.active)
			this.icon.on = this.props.active
		}
	}

	render() {
		const { className, icon, iconOn, img, imgOn, onClick, cardIcon, disabled, style, children } = this.props	
		return (
			<button ref={this.element} 
				className={`${cardIcon ? cardIconClass : ''} ${className ? className : ''} mdc-icon-button`}
				onClick={onClick ? onClick : undefined}
				aria-hidden='true' aria-pressed='false'
				disabled={disabled} style={style}
			>
				{iconOn && <i className='material-icons mdc-icon-button__icon mdc-icon-button__icon--on'>{iconOn}</i>}
				{icon && <i className='material-icons mdc-icon-button__icon'>{icon}</i>}
				
				{img && <img src={img} className='mdc-icon-button__icon' alt='' />}
				{imgOn && <img src={imgOn} className='mdc-icon-button__icon mdc-icon-button__icon--on' alt='' />}
				{children}
			</button>
		)
	}
}

export default withRouter(IconButton)

const cardIconClass = 'mdc-card__action--icon'