import React from 'react'
import environment from 'common/relay'
import { Formik, Form } from 'formik'
import { Card, CardContent, CardActions, withStyles, Typography, TextField } from '@material-ui/core';
import Dialog from 'components/Mui/Dialog'
import Button from 'components/Mui/Button'
import Grid from 'components/Mui/Grid'
import Radio from 'pages/Survey/Radio'
import SubmitSurveyMutation from 'pages/Survey/SubmitSurveyMutation'
import ClaimMetaContext from 'pages/Claim/ClaimDetails/ClaimMetaContext'

const Survey = ({classes}) => {
  const meta = React.useContext(ClaimMetaContext)
  const snackbar = window[Symbol.for('__snackbar')]
  const actions = window[Symbol.for('__JobSurvey')]
  const [selectedValue, setSelectedValue] = React.useState();
  const [inputValue, setInputValue] = React.useState('');
  const [helperText, setHelperText] = React.useState();
  const [iserror, setIsError] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const handleInput = (e) => {
    setInputValue(e.target.value)
  }
  const handleSubmit = async () => {
    setIsTouched(true)
    if (!isTouched || iserror) return null
    const res = await SubmitSurveyMutation({
      where: {
        id: meta.claim.claimId,
      },
      input: {
        rating: parseInt(selectedValue),
        additionalNote: inputValue
      }
    })
    if(res) {
      actions.handleClose()
      if(res.ok) {
        if(res.success) {
          snackbar.toggleOpen({
            message: 'Saved Successfully'
          })
        } else {
          snackbar.toggleOpen({
            message: res.messages[0]
          })
        }
       } else {
          snackbar.toggleOpen({
            message: 'Save Failed'
          })
        }
    }
  }

  React.useEffect(() => {
    if (!selectedValue) {setIsError(true); setHelperText('please rate your experience !')}
    
    if (selectedValue) {setIsError(false); setHelperText(); setIsTouched(true)}
  },[selectedValue, setIsError])

  return (
      <Dialog 
        noForm
        noActions
        onClose={()=>{
          setInputValue('')
        }}
        symbol='__JobSurvey'
        paperProps={{style: { maxWidth: '65%', minWidth:'20%' }}}
        content={
          <Card className={classes.card}>
            <CardContent className={classes.QuestionTitle}>
                <Radio classes={classes} 
                selectedValue={selectedValue} 
                handleChange={handleChange} 
                helperText={helperText}
                iserror={iserror}
                isTouched={isTouched}
                />
            </CardContent>
            <CardContent className={classes.QuestionTitle}>
              <Typography component='p' style={{ fontWeight: 'bold' }}>
                Please provide some feedback on your experience
              </Typography>
              <TextField
                className={classes.margin}
                onChange={handleInput}
                variant="outlined"
                multiline
                fullWidth
                rows={5}
              />
                      
            </CardContent>
            <div>
              <Button
                label='Submit'
                onClick={handleSubmit}
                variant='contained'
                color='primary'
                size='large'
              />
            </div>
          </Card>
        } /> 
  )
}

const styles = {
  card: {
    maxWidth: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderTop: 'solid 0.5px #dbdbd9'
  },
  icon: {
    fontSize: '40px',
  }
};

export default withStyles(styles)(Survey);
