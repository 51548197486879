import React from 'react'

import { Formik, Form } from 'formik'
import Dialog from 'components/Mui/Dialog'
import Grid from '@material-ui/core/Grid'
import Input from 'components/Formik/Input'
import Button from 'components/Mui/Button'

import { useQuery, graphql } from 'relay-hooks'
import { useMutation } from 'react-relay-mutation'

import { useMetaContext } from 'pages/Claim/ClaimDetails/ClaimMetaContext'

export default ({ symbol, initialData }) => {
  const meta = useMetaContext()
  const [requestSpecialist] = useMutation(SPECIALIST_REQUEST_MUTATION)
  const snackbar = window[Symbol.for('__snackbar')]
  return (
    <Dialog
      noForm
      noActions
      symbol={symbol}
      title='Request a specialist'
      content={(
        <Formik
          initialValues={{
            claimId: meta.claim.claimId,
            specialistCategoryId: initialData.specialistCategoryId,
            specialistId: initialData.specialistId
          }}
          validate={values => {
            let errors = {}

            if(!values.specialistCategoryId) errors.specialistCategoryId = 'Required!'
            if(!values.specialistId) errors.specialistId = 'Required!'

            return errors
          }}
        >
          {({ values, isSubmitting, setSubmitting }) => (
            <RequestForm
              values={values}
              snackbar={snackbar}
              initialData={initialData}
              requestSpecialist={requestSpecialist}
              isSubmitting={isSubmitting}
              setSubmitting={setSubmitting}
            />
          )}
        </Formik>
      )}
    />
  )
}

const RequestForm = ({ values, isSubmitting, initialData, requestSpecialist, setSubmitting, snackbar }) => {
  const { props: categoriesData } = useQuery({
    query: CATEGORY_QUERY,
    variables: {
      where: {
        claimId: values.claimId
      }
    }
  })

  const { props: specialistsData } = useQuery({
    query: SPECIALIST_QUERY,
    variables: {
      where: {
        claimId: values.claimId,
        specialistCategoryId: values.specialistCategoryId
      }
    }
  })

  return (
    <Form>
      <Grid fluid justify='flex-end'>
        <Grid xs={12}>
          {(categoriesData && categoriesData.options.length) ? (
            <Input type='select' name='specialistCategoryId'
              label='Specialist Category'
              isSearched
              select={true}
              required={true}
              options={categoriesData.options}
            />
          ) : null}
        </Grid>
        <Grid xs={12}>
          {(specialistsData && specialistsData.options.length) ? (
            <Input type='select' name='specialistId'
              isSearched
              label='Specialist'
              select={true}
              required={true}
              options={specialistsData.options}
            />
          ) : null}
        </Grid>
        {(categoriesData && categoriesData.options.length) ? (
          <Grid xs={12} style={{ textAlign: 'right' }}>
            <Button
              label='Request'
              onClick={() => {
                setTimeout(async () => {
                  const { data: { success, messages } } = await requestSpecialist({ variables: { where: {specialistRequestId: initialData.requestId},
                    input: { specialistCategoryId: values.specialistCategoryId, specialistId: values.specialistId } }})
                  setSubmitting(false)

                  if(success) {
                    // close
                    if(window[Symbol.for('__specialistRequestEdit')]){
                      window[Symbol.for('__specialistRequestEdit')].handleClose()
                    }
                    // and reload
                    const { reload, setReload } = window[Symbol.for('__SpecialistCardReload')]
                    setReload(!reload)
                  }
                  else {
                    // error message
                    snackbar.toggleOpen({ message: messages[0] })
                  }
                })
              }}
              variant='contained'
              color='primary'
              disabled={isSubmitting}
            />
          </Grid>
        ) : null}
      </Grid>
    </Form>
  )
}

const CATEGORY_QUERY = graphql`
  query SpecialistRequestEdit_CategoryQuery($where: SpecialistCategoryWhere!) {
    options: specialistCategories(where: $where) {
      label: name
      value: specialistCategoryId
    }
  }
`

const SPECIALIST_QUERY = graphql`
  query SpecialistRequestEdit_SpecialistQuery($where: SpecialistWhere!) {
    options: specialists(where: $where) {
      label: companyName
      value: companyId
    }
  }
`

const SPECIALIST_REQUEST_MUTATION = graphql`
  mutation SpecialistRequestEdit_CreateMutation($where: SpecialistRequestUniqueWhere!, $input: SpecialistRequestEditInput!) {
    data: specialistRequestEdit(where: $where, input: $input) {
      success
      messages
      fieldErrors {
        fieldName
        level
        message
      }
    }
  }
`
