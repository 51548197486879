import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation AcknowledgeHistoryMutation($where: ENDataPortfolioKey!) {
    claimUpdateAcknowledge(where: $where){
      messages
      success
      result {
        acknowledged
        id
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: (data) => {
          const { messages, success } = data.claimUpdateAcknowledge
          window[Symbol.for('__snackbar')].toggleOpen({
            message: messages[0]
          })
          resolve({ok: true, messages, success})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}
