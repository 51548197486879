/**
 * @flow
 * @relayHash c18f954403ec77651dd00c0d32f9d36c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataEntityKey = {|
  id: string
|};
export type NextStepActionQueryVariables = {|
  where: ENDataEntityKey
|};
export type NextStepActionQueryResponse = {|
  +claimNextStep: ?$ReadOnlyArray<?{|
    +statusName: string,
    +description: string,
    +nextStep: string,
    +portfolioType: ?PortfolioType,
  |}>
|};
export type NextStepActionQuery = {|
  variables: NextStepActionQueryVariables,
  response: NextStepActionQueryResponse,
|};
*/


/*
query NextStepActionQuery(
  $where: ENDataEntityKey!
) {
  claimNextStep(where: $where) {
    statusName
    description
    nextStep
    portfolioType
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataEntityKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ENDataEntityKey!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "nextStep",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "portfolioType",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NextStepActionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimNextStep",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobStatusInfo",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NextStepActionQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimNextStep",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobStatusInfo",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "NextStepActionQuery",
    "id": null,
    "text": "query NextStepActionQuery(\n  $where: ENDataEntityKey!\n) {\n  claimNextStep(where: $where) {\n    statusName\n    description\n    nextStep\n    portfolioType\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e94435835570fb23a0f758ea470edbee';
module.exports = node;
