import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const mutation = graphql`
  mutation LoginMutation($input: UserCredential) {
    login: userAuthenticate(input: $input) {
      token

      accessToken
      refreshToken

      server
      user {
        userName
        userRole
        hasMultipleAccess
        preference {
          applicationFont
          applicationLanguage
          applicationTheme
        }
        company {
          customisation {
            applicationAlias
            applicationFavicon
            applicationLogo
            displayCompanyLogo
            logoHeight
          }
        }
        privileges {
          menus {
            nodeName
          }
        }
      }
    }
  }
`;

export default ({ userLogin, password }) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          userLogin: String(userLogin),
          password: String(password),
        },
      },
      onCompleted: (res) => {
        const data = res.login;
        resolve({ ok: true, data });
      },
      onError: (errors) => {
        resolve({ ok: false, errors });
      },
    });
  });
};
