import React, { Component } from 'react'
import { QueryRenderer, graphql } from 'react-relay'
import environment from 'common/relay'

import { withStyles } from '@material-ui/core/styles'
import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import IconButton from 'components/Mui/IconButton'

import { Formik, Form } from 'formik'
import DateInput from 'components/Formik/DateInput'

import EnterDatesMutation from './EnterDatesMutation'
import { dateFormat, moneyFormat } from 'common/utils'

import moment from 'moment'

const query = graphql`
  query EnterDatesQuery($where: JobWorkOrderIndex!) {
    works: jobWorkOrders(where: $where) {
      edges {
        node {
          costCentres {
            firstLineItemId
            costCentre { costCentreId costCentreName }
            subtotal
            startDate
            completeDate
          }

          subtotal gst total
        }
      }
    }
  }
`

class EnterDates extends Component {
  render() {
    const { classes, meta, portfolios } = this.props

    const isAction =
      (portfolios === 'Building' && meta.user.type.isBuilder)
      || (portfolios === 'Restoration' && meta.user.type.isRestorer)
    // console.log(portfolios)
    // console.log(meta.user.type)

    return (
      <Paper
        className={classes.paper}
        title='Job Progress :: Enter Dates'
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              where: {
                portfolios,
                claimId: meta.claim.claimId,
              }
            }}
            render={({ errors, props }) => {
              if(!props)
                return 'Loading...'
              if(props.works.edges.length === 0)
                return 'No work found'

              const work = props.works.edges[0].node

              return (
                <Grid fluid spacing={0} className={classes.root}>
                  <WorkHeader classes={classes} isAction={isAction} />
                  <WorkList
                    classes={classes}
                    centres={work.costCentres}
                    portfolios={portfolios}
                    isAction={isAction}
                  />
                  <WorkFooter classes={classes} report={{
                    subtotal: work.subtotal,
                    gst: work.gst,
                    total: work.total
                  }}/>
                </Grid>
              )
            }}
          />
        }
      />
    )
  }
}

export default withStyles(theme => ({
  header: {
    height: '2rem',
    background: theme.palette.primary.light,
    padding: '5px',
    color: theme.palette.primary.contrastText,
    borderRadius: '5px',
    fontWeight: 700,
  },
  listItem: {
    padding: '5px',
    '&:nth-child(even)': {
      background: theme.palette.grey[100]
    },
    '&:hover': {
      background: theme.palette.grey[300]
    },
  },
  paper: {
    // maxHeight: '60vh',
    overflow: 'auto'
  }
}))(EnterDates)

const WorkHeader = props => {
  const { classes, isAction } = props

  return (
    <Grid item xs={12} className={classes.header}>
      <Grid fluid spacing={0} alignItems='center'>
        <Grid item xs={3}>Cost Centre</Grid>
        <Grid item xs={2}>Amt ex GST</Grid>
        <Grid item xs>Start Date</Grid>
        <Grid item xs>End Date</Grid>
        {isAction && <Grid item xs={1}></Grid>}
      </Grid>
    </Grid>
  )
}

const WorkFooter = props => {
  const { classes, report } = props

  return <>
    {
      [
        {costCentre: 'Subtotal', amount: moneyFormat(report.subtotal) },
        {costCentre: 'GST', amount: moneyFormat(report.gst) },
        {costCentre: 'Total', amount: moneyFormat(report.total) },
      ].map(({ costCentre, amount, name }, key) => (
        <Grid item xs={4} className={classes.listItem} key={key}>
          <Grid fluid alignItems='center'>
            <Grid item xs>
              <strong>{costCentre}</strong>
            </Grid>
            <Grid item xs>{amount}</Grid>
          </Grid>
        </Grid>
      ))
    }
  </>
}

const WorkList = props => {
  const { classes, centres, portfolios, isAction } = props

  return <Grid item xs={12}>
    {
      centres.map((centre, key) => (
        <WorkListItem
          classes={classes} key={key}
          centre={centre}
          portfolios={portfolios}
          isAction={isAction}
        />
      ))
    }
  </Grid>
}

const WorkListItem = props => {
  const snackbar = window[Symbol.for('__snackbar')]
  const { centre, classes, portfolios, isAction } = props

  return (
    <Formik
      initialValues={{
        id: centre.firstLineItemId,
        portfolioType: portfolios,
        startDate: dateFormat(centre.startDate, 'date') || '',
        completeDate: dateFormat(centre.completeDate, 'date') || ''
      }}
      validate={(values) => {
        let errors = {}
        if(isAction && !values.startDate && !values.completeDate) {
          errors.startDate = 'At least one must be selected!'
          errors.completeDate = 'At least one must be selected!!'
        }

        if(moment(values.startDate).isAfter(values.completeDate)) {
          errors.startDate = 'End date must be equal or after start date'
          errors.completeDate = 'End date must be equal or after start date'
        }
        // if(isAction && !values.completeDate)
        return errors
      }}
      onSubmit={({ id, portfolioType, startDate, completeDate }, actions) => {
        console.log(startDate, moment(completeDate, 'YYYY-MM-DD'));
        setTimeout(async () => {
          const variables = {
            where: { id, portfolioType },
            input: {
              startDate: startDate ? moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss') : null,
              completeDate: completeDate ? moment(completeDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss') : null,
            }
          }

          const res = await EnterDatesMutation(variables)
          if(res.ok) {
            actions.setSubmitting(false)
            snackbar.toggleOpen({ message: res.message })
          }

        }, 400)
      }}
    >
      {({ isSubmitting, values }) => {
        return (
          <Grid item xs={12} className={classes.listItem}>
            <Form>
              <Grid fluid justify='center' alignItems='center'>
                <Grid item xs={3}>{centre.costCentre.costCentreName}</Grid>
                <Grid item xs={2}>{moneyFormat(centre.subtotal)}</Grid>

                <Grid item xs>
                  <DateInput name='startDate' disabled={!isAction}/>
                </Grid>
                <Grid item xs>
                  <DateInput name='completeDate' disabled={!isAction}/>
                </Grid>

                {isAction && <Grid item xs={1}>
                  <IconButton
                    color='primary'
                    icon='save' type='submit'
                    disabled={isSubmitting}
                  />
                </Grid>}
              </Grid>
            </Form>
          </Grid>
        )
      }}
    </Formik>
  )
}

// leadIcon='calendar_today' label='End Date'