import React, { Component } from "react";
import Grid from "components/Mui/Grid";
import { graphql } from "react-relay";
import moment from "moment";
import CheckboxGroup from "components/Formik/CheckboxGroup";
import { Form, Formik } from "formik";
import { useMutation } from "react-relay-mutation";
import DateInput from "components/Formik/DateInput";
import Input from "components/Formik/Input";
import Button from "components/Mui/Button";

const RequestMultipleQuotesTenders = (props) => {
  const mutation = graphql`
    mutation RequestMultipleQuotesTendersQuotingBuildersCardMutation(
      $input: MultipleTenderInput!
      $where: ClaimPortfoliosUniqueWhere!
    ) {
      claimUpdateMultipleTender(input: $input, where: $where) {
        success
        messages
        result {
          id
          tenderCloseDate
        }
      }
    }
  `;
  const portfolioType = props.portfolioType;
  const [updateClaimUpdateMultipleTender] = useMutation(mutation);
  const jobScopeTemplates = props.jobScopeTemplatesData;
  //if (isReqMuliQuotesLoading) return null;
  //if (requestMutQuoteOptions && requestMutQuoteOptions.length) {
  // }
  const jobSuppliers = props.claimParticipantData;
  if (!jobSuppliers) return "";
  const selectSuppliers = jobSuppliers
    .map((x) => {
      if (x.requested) {
        return String(x.companyId);
      }
    })
    .filter(Boolean);
  const noScopeOptions = props.claimParticipantData.map((x) => {
    return {
      label: x.companyName,
      value: String(x.companyId),
    };
  });

  const submitForm = (values) => {
    setTimeout(async () => {
      const res = await updateClaimUpdateMultipleTender({
        variables: {
          input: {
            scopeTemplateId: values.scopeOption,
            quotingSupplierIds: values.quotingOption,
            tenderCloseDate: values.tenderCloseDate.format("YYYY/MM/DD"),
          },
          where: {
            claimId: props.id,
            claimPortfolioType: portfolioType,
          },
        },
      });
      if (res) {
        window[Symbol.for("__snackbar")].toggleOpen({
          message: res.claimUpdateMultipleTender.messages[0],
        });
        props.forceUpdate();
        props.setOpen(false);
      }
    }, 300);
  };

  return (
    <>
      <Formik
        initialValues={{
          scopeOption:
            jobScopeTemplates.length === 1 ? jobScopeTemplates[0].value : "",
          quotingOption: selectSuppliers,
          scopeTemplateId: "",
          tenderCloseDate: props.tenderClosingDate
            ? moment(props.tenderClosingDate, "DD-MM-YYYY").add(10, "h")
            : null,
        }}
        validate={(values) => {
          let errors = {};
          if (!values.scopeOption) {
            errors.scopeOption = "Scope Option Required!";
            window[Symbol.for("__snackbar")].toggleOpen({
              message: errors.scopeOption,
            });
          }
          if (values.quotingOption.length === 0) {
            errors.quotingOption = "Required! At least one Quoting option!";
            window[Symbol.for("__snackbar")].toggleOpen({
              message: errors.quotingOption,
            });
          }
          if (!values.tenderCloseDate) {
            errors.tenderCloseDate = "Required!";

            window[Symbol.for("__snackbar")].toggleOpen({
              message: errors.tenderCloseDate,
            });
          }
          return errors;
        }}
      >
        {({ isSubmitting, values, touched, setFieldValue }) => {
          return (
            <Form>
              <Grid fluid justift="flex-end">
                <Grid item xs={12}>
                  <Input
                    required
                    label="Scope Option"
                    name="scopeOption"
                    select={true}
                    options={jobScopeTemplates}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} spacing={8}>
                  <DateInput
                    required
                    label="Quote Closing Date"
                    name="tenderCloseDate"
                    minDate={moment()}
                  />
                </Grid>
                <Grid item xs={12} spacing={8}>
                  <QuotingCheckbox
                    selectSuppliers={selectSuppliers}
                    noScopeOptions={noScopeOptions}
                    setFieldValue={setFieldValue}
                    portfolioType={portfolioType}
                  />
                </Grid>
                <Grid item xs={12} spacing={8} container justify="flex-end">
                  <Grid item xs={2}>
                    <Button
                      fullWidth
                      disabled={isSubmitting}
                      label="Submit"
                      // type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        submitForm(values);
                        props.setOpen(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

class QuotingCheckbox extends Component {
  componentDidMount = () => {
    this.props.setFieldValue("quotingOption", this.props.selectSuppliers);
  };
  render() {
    return (
      <CheckboxGroup
        required
        label={
          this.props.portfolioType === "Building"
            ? "Quoting Builders"
            : "Quoting Restorers"
        }
        name="quotingOption"
        select
        options={this.props.noScopeOptions}
      />
    );
  }
}

const DateField = ({ date, setDate, label, minDate, maxDate }) => {
  return (
    <DateInput
      label={label}
      value={date}
      clearable={false}
      //leadIcon={"event_note"}
      onChange={(date) => {
        setDate(date);
      }}
      minDate={minDate ? minDate : moment()}
      maxDate={maxDate ? maxDate : undefined}
    />
  );
};

export default RequestMultipleQuotesTenders;
