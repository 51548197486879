/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClaimItem_admin$ref: FragmentReference;
export type ClaimItem_admin = {|
  +view: ?{|
    +fields: {|
      +lossAdjusterStatus: {|
        +id: string,
        +displayValue: ?string,
        +isDisplay: boolean,
      |}
    |}
  |},
  +lodgeDate: ?any,
  +building: ?{|
    +authorisedSupplier: ?{|
      +companyName: string,
      +companyPhone1: ?string,
    |},
    +scopingSupplier: ?{|
      +companyName: string,
      +companyPhone1: ?string,
    |},
    +authorisedValue: ?number,
    +scopedValue: ?number,
    +claimStatus: ?{|
      +statusName: ?string
    |},
    +daysAtStatus: ?number,
  |},
  +restoration: ?{|
    +authorisedSupplier: ?{|
      +companyName: string,
      +companyPhone1: ?string,
    |},
    +scopingSupplier: ?{|
      +companyName: string,
      +companyPhone1: ?string,
    |},
    +claimStatus: ?{|
      +statusName: ?string
    |},
  |},
  +contents: ?{|
    +claimStatus: ?{|
      +statusName: ?string
    |}
  |},
  +$refType: ClaimItem_admin$ref,
|};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "companyName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "companyPhone1",
    "args": null,
    "storageKey": null
  }
],
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "authorisedSupplier",
  "storageKey": null,
  "args": null,
  "concreteType": "Company",
  "plural": false,
  "selections": (v0/*: any*/)
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "scopingSupplier",
  "storageKey": null,
  "args": null,
  "concreteType": "Company",
  "plural": false,
  "selections": (v0/*: any*/)
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "claimStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimStatus",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusName",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "ClaimItem_admin",
  "type": "ClaimJob",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "view",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimJobView",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "fields",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimJobField",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "lossAdjusterStatus",
              "storageKey": null,
              "args": null,
              "concreteType": "FieldInput",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "displayValue",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "isDisplay",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lodgeDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "building",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimPortfolio",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "authorisedValue",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "scopedValue",
          "args": null,
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "daysAtStatus",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "restoration",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimPortfolio",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contents",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimPortfolio",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '684c2f317eeb5a5bbba93b71eb8986b8';
module.exports = node;
