/**
 * @flow
 * @relayHash 9c2b6101de25bc9b23c6cd96ba69a4e6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimNoteCreate = {|
  claimId: string,
  message: string,
  private: number,
  portfolioType?: ?PortfolioType,
|};
export type useActionsCreateJobNoteMutationVariables = {|
  input: ClaimNoteCreate
|};
export type useActionsCreateJobNoteMutationResponse = {|
  +createClaimNote: ?{|
    +success: boolean
  |}
|};
export type useActionsCreateJobNoteMutation = {|
  variables: useActionsCreateJobNoteMutationVariables,
  response: useActionsCreateJobNoteMutationResponse,
|};
*/


/*
mutation useActionsCreateJobNoteMutation(
  $input: ClaimNoteCreate!
) {
  createClaimNote(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClaimNoteCreate!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createClaimNote",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ClaimNoteCreate!"
      }
    ],
    "concreteType": "ClaimNotePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "useActionsCreateJobNoteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "useActionsCreateJobNoteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "useActionsCreateJobNoteMutation",
    "id": null,
    "text": "mutation useActionsCreateJobNoteMutation(\n  $input: ClaimNoteCreate!\n) {\n  createClaimNote(input: $input) {\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e7285e7ae52c4a5e336dde7da9a444f';
module.exports = node;
