import React, { Component } from "react";
import Grid from "components/Mui/Grid";
import Paper from "components/Mui/Paper";
import Button from "components/Mui/Button";
import { withStyles } from "@material-ui/core/styles";
import { graphql, fetchQuery } from "react-relay";
import environment from "common/relay";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import GetSiteReportPdfMutation from "../../../ClaimDetailsAction/GetSiteReportPdfMutation";
import RequestCostedScopeMutation from "./RequestCostedScopeMutation";
import ReleaseSiteReportMutation from "./ReleaseSiteReportMutation";
import RequestCostedScopeWithOptions from "./RequestCostedScopeWithOptions";
import RequestMultipleQuotesTenders from "./RequestMultipleQuotesTenders";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

const SiteReportActionBar = (props) => {
  const {
    meta,
    data,
    action,
    isAbleToEdit,
    isSubmitting,
    forceUpdate,
    classes,
    submitForm,
    //requestQuotesAction,
    //claimParticipantData,
    jobScopeTemplatesData,
    tenderClosingDate,
    portfolios,
  } = props;

  const snackbar = window[Symbol.for("__snackbar")];
  const [options, setOptions] = React.useState([]);
  const [idLoading, setIsloading] = React.useState(true);
  const [isDisplay, setIsdisplay] = React.useState(false);
  const [isReleaseSiteReport, setIsReleaseSiteReport] = React.useState(
    data.toProvideSiteReport
  );
  const [requestQuotesAction, setRequestQuotesAction] = React.useState(null);
  const [requestMutQuoteOptions, setRequestMutQuoteOptions] = React.useState(
    []
  );
  const [claimParticipantData, setClaimParticipantData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    const query = graphql`
      query SiteReportActionBarQuery(
        $where: CostedScopeOptionWhere!
        $isFetch: Boolean!
        $claimJobWhere: ENDataEntityKey!
        # $isFetchForClaimJob: Boolean!
        $isBuilding: Boolean!
        $isRestoration: Boolean!
        $claimParticipantsWhere: ClaimParticipantIndex
      ) {
        costedScopeOptions(where: $where) @include(if: $isFetch) {
          value: costScopeOptionId
          label: displayName
        }
        claimParticipants(
          where: $claimParticipantsWhere # @include(if: $isFetchForClaimJob)
        ) {
          companyName
          companyId
          requested
          id
        }
        claimJob(
          where: $claimJobWhere # @include(if: $isFetchForClaimJob)
        ) {
          view {
            actions {
              requestMultipleQuotesOrTenders {
                building @include(if: $isBuilding) {
                  id
                  isDisabled
                  isDisplay
                  label
                  name
                }
                restoration @include(if: $isRestoration) {
                  id
                  isDisabled
                  isDisplay
                  label
                  name
                }
              }
            }
          }
        }
      }
    `;
    const variables = {
      where: {
        insurerId: props.meta.claim.insurer.companyId,
      },
      claimJobWhere: {
        id: meta.claim.claimId,
      },
      claimParticipantsWhere: {
        claimId: meta.claim.claimId,
        portfolioTypes: portfolios,
        participantStatus: "RequestedAndAvailable",
        participantType: "Supplier",
      },
      isBuilding: portfolios === "Building",
      isRestoration: portfolios === "Restoration",
      isFetch:
        !props.data.toProvideSiteReport &&
        !props.data.insuranceCompletedSiteReport &&
        props.meta.user.type.isAdmin,
      //isFetchForClaimJob: open,
    };
    fetchQuery(environment, query, variables)
      .then((data) => {
        setOptions(data.costedScopeOptions);
        setIsloading(false);
        const requestQuotesAction =
          portfolios === "Building" && data.claimJob
            ? data.claimJob.view.actions.requestMultipleQuotesOrTenders.building
            : portfolios === "Restoration" && data.claimJob
            ? data.claimJob.view.actions.requestMultipleQuotesOrTenders
                .restoration
            : null;
        setRequestQuotesAction(requestQuotesAction);
        const claimParticipantData = data.claimParticipants;
        setClaimParticipantData(claimParticipantData);
      })
      .catch((error) => console.log(error));
  }, []);

  const isRequestCostedScope =
    !data.toProvideSiteReport &&
    !data.insuranceCompletedSiteReport &&
    meta.user.type.isAdmin &&
    !isDisplay;

  const handleNextStep = () => {
    if (window[Symbol.for("__nextStepAction")]) {
      window[Symbol.for("__nextStepAction")].handleOpen();
    }
  };
  const printPreview = async () => {
    const { meta, portfolios } = props;

    const res = await GetSiteReportPdfMutation({
      where: {
        id: meta.claim.claimId,
        portfolioType: portfolios,
      },
    });

    if (res.ok)
      window.open(
        res.pdf,
        "Site Report Print Preview",
        "resizable, scrollbars, status"
      );
  };

  const requestMultipleQuotesTenders = async () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const requestCostedScope = async () => {
    const { meta, portfolios, claim } = props;
    if (idLoading) return null;
    if (options && options.length) {
      if (window[Symbol.for("__requestCostedScope")]) {
        window[Symbol.for("__requestCostedScope")].handleOpen();
      }
    } else {
      const res = await snackbar.toggleOpen({
        message: "Request costed scope",
        firstButton: "Yes",
        secondButton: "No",
        type: "confirm",
      });
      if (res.ok) {
        const mutationRes = await RequestCostedScopeMutation(
          {
            where: {
              id: meta.claim.claimId,
              portfolioType: portfolios,
            },
          },
          claim.id,
          meta.claim.claimId,
          setIsdisplay
        );
        if (mutationRes.success) {
          forceUpdate();
          snackbar.toggleOpen({
            message: mutationRes.messages[0],
          });
        }
      }
    }
  };
  const releaseSiteReport = async () => {
    const { meta, portfolios, claim } = props;
    const res = await snackbar.toggleOpen({
      message: "Release site report",
      firstButton: "Yes",
      secondButton: "No",
      type: "confirm",
    });

    if (res.ok) {
      const mutationRes = await ReleaseSiteReportMutation(
        {
          where: {
            claimId: meta.claim.claimId,
            claimPortfolioType: portfolios,
          },
          input: {
            releaseToSupplier: !data.toProvideSiteReport,
          },
        },
        claim.id,
        meta.claim.claimId
      );
      if (mutationRes.success) {
        setIsReleaseSiteReport(!isReleaseSiteReport);
        forceUpdate();
        snackbar.toggleOpen({
          message: mutationRes.messages[0],
        });
      } else {
        mutationRes.messages &&
          snackbar.toggleOpen({
            message: mutationRes.messages[0],
          });
      }
    }
  };

  return (
    <Paper>
      <Grid fluid justify="space-between">
        <Grid item xs={2}>
          <Button
            fullWidth
            label="Next Step"
            variant="outlined"
            color="primary"
            onClick={() => handleNextStep()}
          />
        </Grid>
        <Grid item xs={2} style={{ alignSelf: "center" }}>
          {action.isDisplay && (
            <FormControlLabel
              control={
                <Switch
                  disabled={action.isDisabled}
                  checked={isReleaseSiteReport}
                  onChange={() => releaseSiteReport()}
                  classes={{ colorSecondary: classes.switch }}
                />
              }
              label={action.label}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {requestQuotesAction && requestQuotesAction.isDisplay && (
            <Button
              fullWidth
              label={requestQuotesAction.label}
              variant="outlined"
              color="primary"
              onClick={requestMultipleQuotesTenders}
              disabled={requestQuotesAction.isDisabled}
            />
          )}
          <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <div className={classes.titleBar}>
                Request Multiple Quotes
                <Button
                  label="close"
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                />
              </div>
            </DialogTitle>
            <DialogContent>
              <RequestMultipleQuotesTenders
                scopeOption={requestMutQuoteOptions}
                portfolioType={props.portfolios}
                id={meta.claim.claimId}
                setOpen={setOpen}
                claimParticipantData={claimParticipantData}
                jobScopeTemplatesData={jobScopeTemplatesData}
                tenderClosingDate={tenderClosingDate}
                forceUpdate={forceUpdate}
              />
            </DialogContent>
          </Dialog>
          {/* )} */}
        </Grid>
        <Grid item xs={2}>
          {isRequestCostedScope && (
            <Button
              fullWidth
              label="Request Costed Scope"
              variant="outlined"
              color="primary"
              onClick={requestCostedScope}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <Button
            fullWidth
            leadIcon="print"
            label="Site Report"
            variant="outlined"
            color="primary"
            onClick={printPreview}
          />
        </Grid>
        <Grid item xs={2}>
          {isAbleToEdit && (
            <Button
              fullWidth
              label="Save"
              type="submit"
              variant="outlined"
              color="primary"
              disabled={isSubmitting || data.authorised}
              onClick={submitForm}
            />
          )}
        </Grid>
      </Grid>
      <RequestCostedScopeWithOptions
        options={options}
        portfolios={props.portfolios}
        forceUpdate={forceUpdate}
        meta={props.meta}
        claim={props.claim}
        setIsdisplay={setIsdisplay}
      />
      {/* <RequestMultipleQuotesTenders
        setIsMultDisplay={setIsMultDisplay}
        portfolios={props.portfolios}
      /> */}
    </Paper>
  );
};

export default withStyles({
  switch: {
    color: "red",
    "&$checked": {
      color: "green",
    },
  },
  titleBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
})(SiteReportActionBar);
