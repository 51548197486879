import React from "react";
import {
  Dialog,
  Button,
  withStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import MuiButton from "components/Mui/Button";
import DataTable from "react-data-table-component";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";
import CustomPagination from "./CustomPagination";
import { SpinnerRect } from "components/Mui/Loader";
import { useMutation } from "react-relay-mutation";
import DashboardExportToExcelMutation from "./DashboardExportToExcel";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { CircularProgress } from "@material-ui/core";
import { object } from "yup";

const DashboardDialog = (props) => {
  const { cardId, classes, where, title } = props;
  const [onSelect, setOnSelect] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [after, setAfter] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = React.useState(false);

  const handleExportToExcel = async ({ cardId, where }) => {
    setLoading(true);
    const res = await DashboardExportToExcelMutation({
      where: {
        scoreItemId: cardId,
        createDateFrom: where.createDateFrom,
        createDateTo: where.createDateTo,
        suppliers: where.suppliers,
        catCodes: where.catCodes,
        eventTypes: where.eventTypes,
        adjusters: where.adjusters,
        regions: where.regions,
      },
    });
    if (res.messages) window.open(res.messages[0]);
    setLoading(false);
  };

  React.useEffect(() => {
    const afterBase64 = btoa(`arrayconnection:${(onSelect - 1) * 10 - 1}`);
    if (!(onSelect - 1)) {
      setAfter(null);
    } else {
      setAfter(afterBase64);
    }
  }, [onSelect]);

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="contained"
        style={props.style}
        className={props.className}
      >
        {props.label}
      </Button>
      <Dialog
        onClose={handleClose}
        open={open}
        classes={{ paper: classes.paper }}
      >
        <div>
          <Grid container style={{ width: "100%" }}>
            <Grid item xs={6}>
              <DialogTitle>{title}</DialogTitle>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <DialogActions>
                <MuiButton
                  color="secondary"
                  style={{ fontSize: "larger" }}
                  onClick={() => handleExportToExcel({ cardId, where })}
                  leadIcon={
                    !loading ? (
                      <Icon>cloud_download</Icon>
                    ) : (
                      <CircularProgress size={22} />
                    )
                  }
                  label={"Export To Excel"}
                  disabled={loading}
                >
                  &nbsp;Export To Excel
                </MuiButton>
                <Button color="primary" onClick={handleClose}>
                  <Icon style={{ fontSize: "xx-large" }}>close</Icon>
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
          <DialogContent>
            <QueryRenderer
              environment={environment}
              query={query}
              variables={{
                first: 10,
                after: after,
                where: {
                  scoreItemId: cardId,
                  createDateFrom: where.createDateFrom,
                  createDateTo: where.createDateTo,
                  suppliers: where.suppliers,
                  catCodes: where.catCodes,
                  eventTypes: where.eventTypes,
                  adjusters: where.adjusters,
                  regions: where.regions,
                },
              }}
              render={({ error, props }) => {
                if (error) return "Error!!";
                if (!props) return <SpinnerRect />;
                const tableData = props.supplierScoreDetails.edges.map(
                  (e) => e.node
                );
                const tableHeader = Object.values(
                  props.supplierScoreDetailFields
                )
                  .map(({ name: selector, label: name, order, isDisplay }) => ({
                    name,
                    selector,
                    order,
                    isDisplay,
                    style: { fontSize: "16px" },
                  }))
                  .sort((a, b) => a.order - b.order);
                const newTableHeader = tableHeader.filter(
                  (e) => e.isDisplay === true
                );
                const total = props.supplierScoreDetails.totalCount;
                const rowPerPage = 10;
                const totalPage = Math.ceil(total / rowPerPage);
                return (
                  <div>
                    <DataTable
                      columns={newTableHeader}
                      data={tableData}
                      customStyles={customStyles}
                      highlightOnHover
                      paginationServer
                      pagination
                      paginationComponent={(props) => (
                        <CustomPagination
                          onSelect={onSelect}
                          setOnSelect={setOnSelect}
                          setAfter={setAfter}
                          rowPerPage={rowPerPage}
                          totalPage={totalPage}
                          totalNumber={total}
                          {...props}
                        />
                      )}
                    />
                  </div>
                );
              }}
            />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default withStyles(() => ({
  root: {
    zoom: "0.91",
  },
  paper: {
    minWidth: "95%",
    // minHeight: '80%',
    zoom: "0.91",
    maxWidth: "initial",
    // minHeight: ' 40vh',
  },
  actions: {
    display: "flex",
    justifyContent: "center",
  },
}))(DashboardDialog);

const query = graphql`
  query DashboardDialogQuery(
    $where: SupplierScoreFilter!
    $first: Int
    $after: String
  ) {
    supplierScoreDetails(where: $where, first: $first, after: $after) {
      totalCount
      edges {
        node {
          approvedDate
          approvedVariationNumber
          assessorName
          catCode
          claimNumber
          eventCategory
          eventType
          externalLossAdjuster
          jobStatus
          postcode
          region
          requestDate
          startDate
          scopeSubmitDate
          scoreItemName
          state
          # {
          #   stateId
          #   name
          #   lastUpdate
          #   userId
          #   disabled
          #   regions {
          #     id
          #     name
          #     stateId
          #     userId
          #     postcodes {
          #       postCodeId
          #       number
          #       regionId
          #       userId
          #       disabled
          #     }
          #   }
          # }
          supplierName
          totalApprovedJobValue
          transactionDate
          value
          workCompletedDate
          additionalField1
          additionalField2
          additionalField3
          additionalField4
        }
      }
    }
    supplierScoreDetailFields(where: $where) {
      startDate {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      approvedDate {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      approvedVariationNumber {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      assessorName {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      catCode {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      claimNumber {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      eventCategory {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      eventType {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      externalLossAdjuster {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      jobStatus {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      postcode {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      region {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      requestDate {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      scopeSubmitDate {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      scoreItemName {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      state {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      supplierName {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      totalApprovedJobValue {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      transactionDate {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      value {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      workCompletedDate {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      additionalField1 {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      additionalField2 {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      additionalField3 {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
      additionalField4 {
        displayValue
        id
        isDisplay
        label
        name
        order
        value
      }
    }
  }
`;
const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "16px",
      fontWeight: "bold",
      // '&:nth-child(even)': {
      //   borderRight: 'solid 1px black',
      //   borderLeft: 'solid 1px black',
      // },
      padding: "1px",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      // backgroundColor: 'rgb(230, 244, 244)',
      // borderBottomColor: '#FFFFFF',
      // borderRadius: '25px',
      // outline: '1px solid #FFFFFF',
    },
    style: {
      "&:nth-child(even)": {
        // backgroundColor: 'green',
      },
      // backgroundColor: 'green'
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};
