/**
 * @flow
 * @relayHash b308e8465f07cbd96256133e84581d62
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SubStatusUpdateInput = {|
  subStatusId: string
|};
export type ClaimUniqueWhere = {|
  claimId: string
|};
export type SubStatusChangeMutationVariables = {|
  input: SubStatusUpdateInput,
  where: ClaimUniqueWhere,
|};
export type SubStatusChangeMutationResponse = {|
  +subStatusClaimUpdate: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
    +result: ?{|
      +id: string,
      +name: ?string,
      +subStatusId: string,
    |},
  |}
|};
export type SubStatusChangeMutation = {|
  variables: SubStatusChangeMutationVariables,
  response: SubStatusChangeMutationResponse,
|};
*/


/*
mutation SubStatusChangeMutation(
  $input: SubStatusUpdateInput!
  $where: ClaimUniqueWhere!
) {
  subStatusClaimUpdate(input: $input, where: $where) {
    success
    messages
    result {
      id
      name
      subStatusId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SubStatusUpdateInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimUniqueWhere!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "subStatusClaimUpdate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SubStatusUpdateInput!"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ClaimUniqueWhere!"
      }
    ],
    "concreteType": "ClaimSubStatusPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "result",
        "storageKey": null,
        "args": null,
        "concreteType": "ClaimSubStatus",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "subStatusId",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SubStatusChangeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SubStatusChangeMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SubStatusChangeMutation",
    "id": null,
    "text": "mutation SubStatusChangeMutation(\n  $input: SubStatusUpdateInput!\n  $where: ClaimUniqueWhere!\n) {\n  subStatusClaimUpdate(input: $input, where: $where) {\n    success\n    messages\n    result {\n      id\n      name\n      subStatusId\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '83ffcab84b32b17a479ac3e19d8d05b3';
module.exports = node;
