import React, { Component } from 'react'
import environment from 'common/relay'
import { QueryRenderer, graphql } from 'react-relay'

import Dialog from 'components/Mui/Dialog'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'

import GetLossAdjusterPdfMutation from '../GetLossAdjusterPdfMutation'

const query = graphql`
  query LossAdjusterViewAllActionQuery ($first: Int!, $after: String, $where: ClaimItemFilter) {
    claimLossAdjusterDocuments(first: $first, after: $after, where: $where)
    @connection(key: "LossAdjusterViewAllActionQuery_claimLossAdjusterDocuments", filters: []) {
      edges {
        node {
          id
          uploadDate
          company {
            companyName
          }
          reportType {
            reportTypeName
          }
          url
          private
          description
        }
      }
    }

  }
`
//, $claimJobWhere: ENDataEntityKey!
// claimJob(where: $claimJobWhere) {
//       refNumber
//       incidentDetail {
//         eventType {
//           eventName
//         }
//         incidentDate
//       }
//       insured {
//         name
//         postalAddress {
//           line1
//           postcode
//           state
//           suburb
//         }
//       }
//     }

class LossAdjusterViewAllAction extends Component {
  render() {
    const imageFormats = ['jpg', 'png', 'gif', 'jpeg']
    return (
      <Dialog
        noForm
        noActions
        symbol='__lossAdjusterViewAllAction'
        title={
          <Grid fluid spacing={0} justify='space-between'>
            <Grid item xs={6}>
              View documents
            </Grid>
            <Grid item xs={6}>
              <p style={{ textAlign: 'right', margin: 0 }}>
                <Button variant='outlined' color='primary'
                  onClick={this.handlePrintLossAdjusters}
                  leadIcon='print'
                  label='Documents'
                />
                <Button color='primary'
                  onClick={() => window[Symbol.for('__lossAdjusterViewAllAction')].handleClose()}
                  label='Close'
                />
              </p>
            </Grid>
          </Grid>
        }
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              first: 15,
              after: null,
              where: {
                claimId: this.props.claimId,
              },
              claimJobWhere: {
                id: this.props.claimId,
              }
            }}
            render={({error, props}) => {
              if(!props) return 'Loading...'

              const { claimLossAdjusterDocuments } = props
              return (
                <Grid container>
                  <Grid item xs={12} style={{
                    maxHeight: '40vh',
                  }}>
                    <Grid fluid>
                      {
                        claimLossAdjusterDocuments.edges.length > 0 ? claimLossAdjusterDocuments.edges.map(({node}, key) => {
                          const { url, description } = node
                          const ext = url.substring(url.lastIndexOf('.') + 1, url.length).toLowerCase()
                          return imageFormats.indexOf(ext) !== -1 && (
                            <Grid item xs={6} key={key}>
                              <Grid fluid>
                                <Grid item xs={12} style={{
                                  display: 'flex',
                                  justifyContent: 'center'
                                }}>
                                  <img src={url} alt='endata'
                                    style={{
                                      maxWidth: '100%',
                                      maxHeight: '20rem'
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} style={{
                                  display: 'flex',
                                  justifyContent: 'center'
                                }}>
                                  {description}
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        }) : 'No document'
                      }
                    </Grid>
                  </Grid>
                </Grid>
              )
            }}
          />
        }
      />
    )
  }
  handlePrintLossAdjusters = async () => {
    const res = await GetLossAdjusterPdfMutation({
      where: {
        claimId: this.props.claimId,
        portfolios: this.props.portfolios || undefined,
      }
    })

    if(res.ok) window.open(
      res.pdf,
      'Site Report Print Preview',
      'resizable, scrollbars, status'
    )
  }
}

export default LossAdjusterViewAllAction