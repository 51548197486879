import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import ScopeActionBar from "./ScopeActionBar";
import PortfolioFilter from "pages/Claim/ClaimDetails/ClaimDetailsPortfolioFilter";
import ScopeItemList from "./ScopeItemList";
import ScopeContentsItemList from "./ScopeContentsItemList";

const ScopePresentation = ({
  classes,
  meta,
  quotes,
  portfolio,
  changePortfolio,
  requestCategory,
  isProperties,
  update,
  setUpdate,
}) => {
  const quote =
    quotes.jobQuotes.edges.length !== 0 ? quotes.jobQuotes.edges[0].node : null;
  const isContentsAction = portfolio === "Contents" && meta.user.type.isAdmin;
  const isOwnCompany =
    quote &&
    quote.supplier &&
    quote.supplier.companyId === meta.user.company.companyId;
  // const isAction = (isOwnCompany && !quote.completed) || isContentsAction
  const isAction =
    (isOwnCompany || isContentsAction || meta.claim.insurer.nonPanelSupplier) &&
    !Boolean(quote && quote.completed);
  return (
    <Grid container spacing={8} className={classes.root}>
      <Grid item xs={12}>
        <ScopeActionBar
          scopeTypeId={quotes.jobScopeTypes && quotes.jobScopeTypes[0]}
          quote={quote}
          meta={meta}
          portfolio={portfolio}
          isOwnCompany={isOwnCompany}
          isContentsAction={isContentsAction}
          isProperties={isProperties}
          requestCategory={requestCategory}
          setUpdate={setUpdate}
        />
      </Grid>
      <Grid item xs={12} className={classes.bodyGroup}>
        <PortfolioFilter
          noAll
          portfolio={{
            hasBuilding: meta.claim.hasBuilding,
            hasContents: meta.claim.hasContents,
            hasRestoration: meta.claim.hasRestoration,
          }}
          changePortfolio={changePortfolio}
          value={portfolio.toLowerCase()}
        />
        {portfolio !== "Contents" ? (
          <ScopeItemList
            quote={quote}
            portfolio={portfolio}
            meta={meta}
            isAction={isAction}
            isOwnCompany={isOwnCompany}
            isContentsAction={isContentsAction}
            isProperties={isProperties}
          />
        ) : (
          <ScopeContentsItemList
            quote={quote}
            portfolio={portfolio}
            meta={meta}
            isAction={isAction}
            isOwnCompany={isOwnCompany}
            isContentsAction={isContentsAction}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles((theme) => ({
  root: {
    marginTop: theme.spacing.unit,
  },
  bodyGroup: {
    position: "relative",
    paddingTop: "0 !important",
    paddingLeft: "0 !important",
  },
}))(ScopePresentation);
