import React from 'react'
import QuotesPresentation from './'

const QuotesContainer = ({ quotes, portfolioType, meta, claimJobData, claimId }) => {
  return <QuotesPresentation
    quotes={quotes}
    portfolioType={portfolioType}
    claimJobData={claimJobData}
    meta={meta}
    claimId={claimId}
  />
}

export default QuotesContainer
