import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "components/Mui/Paper";

import useLineItemList from "hooks/useLineItemList";
import LineItemListHeader from "components/LineItemList/LineItemListHeader";
import ListItemListReport from "components/LineItemList/ListItemListReport";
import LineItemListGroup from "components/LineItemList/LineItemListGroup";
import ScopeContentsGroupItem from "./ScopeContentsGroupItem";
//import ScopeContentsNewItem from "pages/Claim/ClaimDetails/ClaimDetailsPage/Scope/ScopeContentsItemList/ScopeContentsNewItem";

const ScopeContentsItemList = ({
  classes,
  quote,
  isAction,
  portfolio,
  isOwnCompany,
  isContentsAction,
  meta,
}) => {
  const [groups, report] = useLineItemList(quote);
  const isDisplayAddLineItem =
    quote && quote.actions.addLineItem ? quote.actions.addLineItem : false;
  // const [ isRequestQuote, setIsRequestQuote ] = React.useState(false)
  // item.requestedQuote === true

  // React.useEffect(() => {
  //   setIsRequestQuote(quote && quote.lineItems.every(e => e.requestedQuote === true))
  // }, [quote && quote.lineItems])

  if (groups.length !== 0) {
    if (groups[0].name !== "ScopeContentsAddLineItem") {
      groups.unshift({ name: "ScopeContentsAddLineItem", items: [] });
    }
    if (groups[groups.length - 1].name !== "ScopeContentsAddLineItem") {
      groups.push({ name: "ScopeContentsAddLineItem", items: [] });
    }
  } else {
    groups.push({ name: "ScopeContentsAddLineItem", items: [] });
  }
  return (
    <Paper
      title={
        <LineItemListHeader
          cols={[
            { col: 1, name: "Date" },
            { col: 2, name: "Cost Centre" },
            { col: true, name: "Description" },
            { col: 1, name: "Policy Item Limit" },
            { col: 1, name: "Direct Supply" },
            { col: 1, name: "Purchase Price" },
            { col: 1, name: "Age" },
            { col: 1, name: "Proof of Loss" },

            { col: 1, name: "Qty" },
            { col: 1, name: "Reserve Price" },
            // {col: 1, name: 'Subtotal'},

            {
              col: 1,
              name: "Action",
              show:
                quote &&
                !quote.completed &&
                (isOwnCompany ||
                  isContentsAction ||
                  /*TOBE REMOVED*/ (meta.user.type.isAdmin &&
                    meta.claim.insurer
                      .nonPanelSupplier)) /*TOBE REMOVED*/ /* || isRequestQuote */,
              /* , unMountOn: !isAction */
            },
          ]}
        />
      }
    >
      <div className={classes.root}>
        {groups.length === 0 ? (
          <div className={classes.noItem}>No item</div>
        ) : (
          <>
            {groups.map((group, index) => (
              <LineItemListGroup
                key={index}
                isAction={isAction}
                group={group}
                isOwnCompany={isOwnCompany}
                isContentsAction={isContentsAction}
                meta={meta}
                quote={quote}
                portfolio={portfolio}
                ItemComponent={ScopeContentsGroupItem}
                isDisplayAddLineItem={isDisplayAddLineItem}
              />
            ))}
            {groups.length !== 1 &&
              groups.filter((e) => e.name === "ScopeContentsAddLineItem")
                .length > 0 && <ListItemListReport report={report} />}
          </>
        )}
      </div>
    </Paper>
  );
};

export default withStyles((theme) => ({
  noItem: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
  subHeader: {
    fontStyle: "italic",
    fontWeight: 600,
    background: theme.palette.grey[400],

    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    borderRadius: 8,

    borderTop: "1px solid #ececec",
  },
  root: {
    maxHeight: "65vh",
    overflow: "auto",
  },
}))(ScopeContentsItemList);
