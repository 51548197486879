import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { createFragmentContainer, graphql } from "react-relay";
import { withRouter } from "react-router-dom";
import { fetchQuery } from "relay-runtime"
import environment from "common/relay"
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon/";

import Grid from "components/Mui/Grid";

const query = graphql`
  query DashboardKpiDetailsItemQuery(
    $where: ENDataEntityKey!
  ) {
    claim: claimJob(where: $where) {
      view {
        tabs {
          historyTab {
            isDisplay
          }
        }
      }
    }
  }
`

class DashboardKpiDetailsItem extends Component {
  render() {
    const { classes } = this.props;
    const {
      refNumber,
      daysAtStatus,
      insuredName,
      reportManagerName,
      claimId,
      portfolioType,
      reportStatusName,
      reportCategoryName,
      reportItemId,
    } = this.props.details;

    const routeFilter = (access) => {
     switch(reportCategoryName) {
        case "Invoices":
          return `/claim/fees?id=${claimId}`
        case "FollowUps":
          return `${access ? `/claim/histories?id=${claimId}` : `/claim/job-notes?id=${claimId}`}`
        case "ScopeValidation":
        case "RealTimeValidation":
        case "RealTimeValidationAssess":
          return `/claim/scope?id=${claimId}&portfolio=${portfolioType.toLowerCase()}`
        case "ScopeRecommendation":
        case "RealTimeRecommendation":
          return `/claim/quotes?id=${claimId}&portfolio=${portfolioType.toLowerCase()}`
        case "VariationValidationAssess":
        case "Variations":
        case "VariationRecommendation":
        case "VariationValidation":
          return `/claim/variations?id=${claimId}&portfolio=${portfolioType.toLowerCase()}&reportItemId=${reportItemId}`
        default:
          return `/claim/job-info?id=${claimId}`
      }

    };

    return (
      <Grid
        item
        xs={12}
        className={classes.row}
        onClick={(e) => {
          if(reportCategoryName === "FollowUps"){
            fetchQuery(environment, query, {
              where: {
                id: parseInt(claimId),
              },
            }).then(data => {
              const access = data.claim.view.tabs.historyTab.isDisplay
              this.props.history.push(routeFilter(access))
            });
          } else {
            this.props.history.push(routeFilter())
          }
          }
        }
      >
        <Grid fluid spacing={0}>
          <Grid item xs className={classes.centerCell}>
            {portfolioType && <ClaimTypeIcon type={portfolioType} />}
            {refNumber}
          </Grid>
          <Grid item xs className={classes.centerCell}>
            {insuredName}
          </Grid>
          <Grid item xs className={classes.centerCell}>
            {reportManagerName}
          </Grid>
          <Grid item xs className={classes.centerCell}>
            {reportStatusName}
          </Grid>
          <Grid item xs className={classes.centerCell}>
            {daysAtStatus}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default createFragmentContainer(
  withStyles((theme) => ({
    centerCell: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    row: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      alignItems: "center",

      "&:nth-child(even)": {
        background: theme.palette.grey[100],
      },
      "&:hover": {
        background: theme.palette.grey[300],
      },
    },
  }))(withRouter(DashboardKpiDetailsItem)),
  graphql`
    fragment DashboardKpiDetailsItem_details on ClaimJobGeneral {
      refNumber
      insuredName
      caseManagerName
      reportManagerName
      status {
        statusName
      }
      daysAtStatus
      claimId
      portfolioType
      reportStatusName
      reportCategoryName
      reportItemId
    }
  `
);
