import React, { Component } from "react";
import environment from "common/relay";
import { QueryRenderer, graphql } from "react-relay";
import Grid from "components/Mui/Grid";
import DashboardKpiDetailsPresentation from "./DashboardKpiDetailsPresentation";

import { SpinnerRect } from "components/Mui/Loader";

const query = graphql`
  query DashboardKpiDetailsContainerQuery(
    $first: Int
    $after: String
    $where: CaseReportIndex!
  ) {
    ...DashboardKpiDetailsPresentation
  }
`;

class DashboardKpiDetails extends Component {
  render() {
    return (
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{
          first: this.props.isShowChart ? 15 : 30,
          after: null,
          where: {
            internalFilterName: this.props.activeCircular,
            filter: this.props.filter,
          },
        }}
        render={({ error, props }) => {
          if (!props)
            return (
              <Grid
                fluid
                spacing={0}
                style={{ position: "relative", height: "100%" }}
                alignItems="center"
              >
                <Grid item xs={4}>
                  <SpinnerRect />
                </Grid>
              </Grid>
            );

          return (
            <DashboardKpiDetailsPresentation
              data={props}
              handleChangeKpiFilter={this.handleChangeKpiFilter}
              isShowChart={this.props.isShowChart}
            />
          );
        }}
      />
    );
  }
}

export default DashboardKpiDetails;
