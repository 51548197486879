import React from "react";

import Grid from "components/Mui/Grid";
import Input from "components/Formik/Input";
import FloatInput from "components/Formik/FloatInput";
import MoneyInput from "components/Formik/MoneyInput";
import useLineItemOptions from "hooks/useLineItemOptions";
import { SingleReactSelect } from "components/Formik/ReactSelect";

import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";
import useUpdateScopeLineItem from "hooks/useUpdateScopeLineItem";

const UpdateItemForm = ({
  jobQuoteId,
  portfolioType,
  quotationType,
  values,
  isSubmitting,
  setFieldValue,
  isProperties,
  quote,
}) => {
  const [touched, setTouched] = React.useState(false);
  const [unitOptions, setUnitOptions] = React.useState([]);
  const meta = useMetaContext();

  const variables = {
    location: { portfolioType },
    centre: { portfolioType, quotationType: "Scope" },

    isItem: Boolean(values.costCentreId),
    item: {
      portfolioType,
      quotationType: "Scope",
      costCentreId: values.costCentreId || 0,
    },
    jobScopeWhere: {
      insurerId: meta.claim.insurer.companyId,
      quotationType,
      portfolioType,
    },
    isJobScope:
      !meta.claim.view.actions.updateScopeType.isDisabled &&
      meta.claim.view.actions.updateScopeType.isDisplay,
    isProperties: isProperties ? true : false,
    properties: {
      insurerId: meta.claim.insurer.companyId,
      portfolioType: portfolioType,
    },
  };

  const [
    locationOptions,
    centreOptions,
    itemOptions,
    __materialItems,
    jobScopeOptions,
    propertiesOptions,
  ] = useLineItemOptions(variables, [portfolioType, values.costCentreId]);

  useUpdateScopeLineItem(
    {
      portfolioType: portfolioType,
      unitId: values.unitId,
      descriptionId: values.costItemId,
      isIncluded: true,
      values,
      setFieldValue,
      industryRatesAutoFill:
        quote && quote.industryRatesAutoFill
          ? quote.industryRatesAutoFill
          : null,
    },
    [portfolioType, values.unitId, values.costItemId]
  );
  // React.useEffect(() => {
  //   if(itemOptions && values.costCentreId) {
  //     setFieldValue('costItemId', '', false)
  //   }
  // }, [ portfolioType, values.costCentreId ])

  React.useEffect(() => {
    if (touched) {
      setFieldValue("costItemId", "", false);
      setFieldValue("unitId", "", false);
    }

    setTouched(true);
  }, [values.costCentreId]);

  React.useEffect(() => {
    const unitOptions =
      values.costCentreId && values.costItemId
        ? (() => {
            const selectedItem = itemOptions.filter(
              (x) => x.value === values.costItemId
            );
            if (selectedItem.length !== 0) return selectedItem[0].units;
            return [];
          })()
        : [];
    setUnitOptions(unitOptions);
  }, [values.costItemId, itemOptions.length]);

  return (
    <>
      {[
        {
          col: 12,
          component: SingleReactSelect,
          label: "Scope Type",
          name: "scopeTypeId",
          select: true,
          required: true,
          options: jobScopeOptions,
          disabled: meta.claim.view.actions.updateScopeType.isDisabled,
          unMountOn: !meta.claim.view.actions.updateScopeType.isDisplay,
        },
        {
          col: 6,
          component: SingleReactSelect,
          label: quote && quote.fields.propertyTypeId.label,
          name: "propertyTypeId",
          required: true,
          options: propertiesOptions,
          unMountOn: !isProperties,
        },
        {
          col: 6,
          component: Input,
          label: quote && quote.fields.propertyNumber.label,
          name: "propertyNumber",
          required: true,
          unMountOn: !isProperties,
        },
        {
          col: 6,
          component: SingleReactSelect,
          label: "Location",
          name: "locationId",
          select: true,
          required: true,
          options: locationOptions,
        },
        {
          col: 6,
          component: Input,
          label: "Room Size",
          name: "dimensions",
          required: true,
        },
        {
          col: 6,
          component: SingleReactSelect,
          label: "Cost Centre",
          name: "costCentreId",
          required: true,
          options: centreOptions,
        },
        {
          col: 6,
          component: SingleReactSelect,
          label: "Description",
          name: "costItemId",
          required: true,
          options: itemOptions,
        },
        {
          col: 12,
          component: Input,
          label: "More Details",
          name: "lineDescription",
          rows: 3,
          rowsMax: 3,
        },
        {
          col: 6,
          component: Input,
          label: "Unit of Measure",
          name: "unitId",
          required: true,
          select: true,
          options: unitOptions,
        },
        {
          col: 6,
          component: Input,
          label: "Margin",
          name: "marginRate",
          required: true,
          disabled: meta.claim.insurer.isMarginUpdate ? false : true,
        },
        {
          col: 6,
          component: FloatInput,
          label: "Quantity",
          name: "qty",
          required: true,
        },
        {
          col: 6,
          component: MoneyInput,
          label: "Rate Ex GST",
          name: "rate",
          required: true,
          allowNegative: true,
        },
      ].map(({ col, unMountOn, component: C, ...props }, index) => (
        <Grid item xs={col} key={index}>
          {!unMountOn && <C fullWidth variant="outlined" {...props} />}
        </Grid>
      ))}
    </>
  );
};

export default UpdateItemForm;
