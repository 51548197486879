import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const mutation = graphql`
  mutation AnnouncementDialogMutation($input: AnnouncementLogCreateInput!) {
    announcementLogCreate(input: $input) {
      messages
      success
    }
  }
`;

export default (variables) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: ({ announcementLogCreate }) => {
        const { messages, success } = announcementLogCreate;
        window[Symbol.for("__snackbar")].toggleOpen({
          message: messages[0],
        });
        resolve({ ok: true, messages, success });
      },
      onError: (errors) => {
        resolve({ ok: false });
      },
    });
  });
};
