import React from 'react'
import { graphql, fetchQuery } from 'react-relay'
import environment from 'common/relay'
import { mapUserType } from 'common/utils'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import SwipeRoutes from 'react-swipeable-routes'
import { Route, Link } from 'react-router-dom'
import useReactRouter from 'use-react-router'
// import { usePreloadLazy } from 'hooks/usePreloadLazy'

import Estimator from './Pages/Estimator'
import Supervisor from './Pages/Supervisor'
import Interface from './Pages/Interface'

const Settings = ({ theme, classes }) => {
  /**
   * FETCHING META DATA
   */
  const [me, setMe] = React.useState(null)
  // const [errors, setErrors] = React.useState(null)
  React.useEffect(() => {
    fetchQuery(environment, query)
      .then(data => {
        if(data && data.me) {
          setMe({
            ...data.me,
            type: mapUserType(data.me.type)
          })
        }
      })
  }, [])

  /**
   * SETUP ROUTE FOR USER TYPE
   */
  const [routes, setRoutes] = React.useState(null)
  React.useEffect(() => {
    let setupRoutes = [ ...setup ]
    if(me) {
      if(me.type.isAdmin || me.type.isContent) {
        setupRoutes = setupRoutes.filter(route => route.path !== '/settings/supervisor-options')
        setupRoutes = setupRoutes.filter(route => route.path !== '/settings/estimator-options')
      }
      setRoutes(setupRoutes)
    }
  }, [ me && me.type ])

  /**
   * GO TO DEFAULT ROUTE IN SETTINGS
   */
  const { history } = useReactRouter()
  React.useEffect(() => {
    if(routes) {
      if(history.location.pathname === '/settings') {
        history.push(routes[0].path)
      }
    }
  }, [ routes && routes[0].path ])

  /**
   * ROUTING HANLDE
   */
  const [route, setRoute] = React.useState(0)
  const handleChangeRoute = (event, newIndex) => setRoute(newIndex)
  const handleChangeRouteIndex = index => setRoute(index)

  if(!routes) return null

  return (
    <Grid container direction='row' justify='center'>
      <Grid item xs={12}>1.0.0</Grid>
      <Grid item xs={12}>
        <AppBar position='static' color='inherit' elevation={2}>
          <Tabs value={route}
            onChange={handleChangeRoute}
            indicatorColor='primary'
            variant='fullWidth'
          >
            {routes.map(route => (
              <Tab key={route.path}
                label={route.label}
                component={Link}
                to={route.path}
              />
            ))}
          </Tabs>
        </AppBar>
        <div>
          <SwipeRoutes
            axis={theme.direction === 'rtl' ? 'x-reverse': 'x'}
            index={route} onChangeIndex={handleChangeRouteIndex}
          >
            {routes.map(({ component: Component, ...route }, index) => (
              <Route key={index} {...route}
                render={props => (
                  <Paper square elevation={0} className={classes.pageRoot}
                    key={route.path}
                  >
                    <Component {...props} />
                  </Paper>
                )}
              />
            ))}
          </SwipeRoutes>
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(theme => ({
  pageRoot: {
    padding: theme.spacing.unit * 3
  }
}), { withTheme: true })(Settings)

const setup = [
  {
    path: '/settings/general', component: Interface,
    label: 'Interface Settings',
  },
  {
    path: '/settings/supervisor-options', component: Supervisor,
    label: 'Supervisor',
  },
  {
    path: '/settings/estimator-options', component: Estimator,
    label: 'Estimator',
  }
]

const query = graphql`
  query SettingsQuery {
    me {
      type: userRole
    }
  }
`
