import React from "react";
import { moneyFormat } from "common/utils";
import { withStyles } from "@material-ui/core/styles";
import { Button, Menu } from "@material-ui/core";
import Grid from "components/Mui/Grid";
import IconButton from "components/Mui/IconButton";

import DeleteScopeItemMutation from "../ScopeActionBar/DeleteScopeItem/DeleteScopeItemMutation";

import UpdateScopeItem from "../ScopeActionBar/UpdateScopeItem/";
import ScopeValidationActionAudit from "../ScopeActionBar/ScopeValidation/ScopeValidationActionAudit";

import { useMetaContext } from "pages/Claim/ClaimDetails/ClaimMetaContext";
import ScopeValidationActionValidate from "../ScopeActionBar/ScopeValidation/ScopeValidationActionValidate";

const ScopeGroupItem = ({
  classes,
  item,
  isAction,
  portfolio,
  tableItem,
  isValidationAction,
  validationRefresh,
  quote,
  isProperties,
  isShowAction,
  setValidationRefresh,
}) => {
  const handleClick = (e) => {
    const action = window[Symbol.for(`__ScopeValidationActionAudit`)];
    if (action) {
      action.handleOpen();
    }
  };
  const snackbar = window[Symbol.for("__snackbar")];
  const refresh = window[Symbol.for("__refreshScope")];
  const meta = useMetaContext();
  const editScopeItem = () => {
    const actions =
      window[Symbol.for(`${item.jobQuoteItemId}__scopeUpdateItem`)];
    if (actions) actions.handleOpen();
  };

  const deleteScopeItem = async (id) => {
    const res = await DeleteScopeItemMutation({
      where: { id, portfolioType: portfolio },
    });

    if (res.ok) {
      snackbar.toggleOpen({ message: res.messages[0] });
      if (res.success) {
        refresh();
      }
    } else {
      snackbar.toggleOpen({
        message: "Cannot delete item, please try again",
      });
    }
  };
  return (
    <>
      <Grid
        fluid
        spacing={0}
        className={classes.row}
        style={{
          backgroundColor:
            item.quoteItemType === "ValidationAudit"
              ? "yellow"
              : item.quoteItemType === "Original"
              ? "lightblue"
              : "",
        }}
      >
        {[
          // cost centre
          {
            col: 2,
            text: item.costCentre.costCentreName,
            show: tableItem.fields.costCentre.isDisplay,
          },
          // description
          {
            col: true,
            text: `${item.costItem ? item.costItem.itemDescription : ""} ${
              item.lineDescription
            }`,
            show: tableItem.fields.description.isDisplay,
          },
          // sir rate
          {
            col: 1,
            text: moneyFormat(item.sirRate),
            show: tableItem.fields.sIRRate.isDisplay,
            //show: true,
            //className: classes.sir,
          },
          // unit
          {
            col: 1,
            text: item.unit && item.unit.unitName,
            show: tableItem.fields.unit.isDisplay,
          },
          // qty
          { col: 1, text: item.qty, show: tableItem.fields.qty.isDisplay },
          // rate ex
          {
            col: 1,
            text: moneyFormat(item.rate),
            show: tableItem.fields.rate.isDisplay,
          },
          // margin
          {
            col: 1,
            text: `${item.marginRate}%`,
            show: tableItem.fields.marginRate.isDisplay,
          },
          // subtotal
          {
            col: 1,
            text: moneyFormat(item.subtotal),
            show: tableItem.fields.subtotal.isDisplay,
          },
          // actions
          {
            col: 1,
            noActions: !isShowAction && !isAction,
            show:
              isShowAction ||
              isValidationAction ||
              tableItem.fields.action.isDisplay,
            text: (
              <>
                {isValidationAction ? (
                  <div>
                    {item.actions.accept.isDisplay && (
                      <ScopeValidationActionAudit
                        validationType={"accept"}
                        item={item}
                        meta={meta}
                        portfolioType={portfolio}
                        validationRefresh={validationRefresh}
                      />
                    )}
                    {item.actions.decline.isDisplay && (
                      <ScopeValidationActionAudit
                        validationType={"decline"}
                        item={item}
                        meta={meta}
                        portfolioType={portfolio}
                        validationRefresh={validationRefresh}
                      />
                    )}
                    {item.actions.amend.isDisplay && (
                      <ScopeValidationActionAudit
                        validationType={"amend"}
                        item={item}
                        meta={meta}
                        portfolioType={portfolio}
                        validationRefresh={validationRefresh}
                      />
                    )}
                    <ScopeValidationActionValidate
                      validationType={"validate"}
                      item={item}
                      meta={meta}
                      portfolioType={portfolio}
                      validationRefresh={validationRefresh}
                      setValidationRefresh={setValidationRefresh}
                      isDisplay={item.actions.validate.isDisplay}
                    />
                  </div>
                ) : (
                  <div>
                    <IconButton
                      icon="edit"
                      className={classes.actionBtn}
                      onClick={() => editScopeItem()}
                    />
                    <IconButton
                      icon="delete"
                      className={classes.actionBtn}
                      onClick={() => deleteScopeItem(item.jobQuoteItemId)}
                    />
                  </div>
                )}
              </>
            ),
          },
          { col: 1, text: item.note, show: isValidationAction === true },
          {
            col: 1,
            text: item.lineStatusDescription,
            show: isValidationAction === true,
          },
        ]
          .filter((e) => e.show === true)
          .map(({ col, text, noActions, className }, key) => {
            return (
              <Grid
                className={`${className} ${classes.alignCenter}`}
                item
                key={key}
                xs={col}
              >
                {!noActions && text}
              </Grid>
            );
          })}
      </Grid>
      <UpdateScopeItem
        symbol={`${item.jobQuoteItemId}__scopeUpdateItem`}
        jobQuoteId={item.jobQuoteItemId}
        isProperties={isProperties}
        quote={quote}
        portfolioType={portfolio}
        initialValues={{
          locationId: item.location.costLocationId,
          dimensions: item.dimensions,
          scopeTypeId:
            item.scopeType && item.scopeType.id ? item.scopeType.id : "",
          costCentreId: item.costCentre.costCentreId,
          costItemId: item.costItem.costItemId,
          lineDescription: item.lineDescription,
          unitId: item.unit.unitId,
          marginRate: item.marginRate,
          qty: item.qty,
          rate: item.rate,
          propertyTypeId: item.propertyType && item.propertyType.id,
          propertyNumber: item.propertyNumber,
        }}
      />
    </>
  );
};

export default withStyles((theme) => ({
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: "center",
    borderRadius: "8px",

    "&:nth-child(even)": {
      background: theme.palette.grey[100],
    },
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit,
  },
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
    padding: "4px",
    textAlign: "center",
    flexWrap: "wrap",
  },
  sir: {
    color: theme.palette.grey[800],
    fontStyle: "italic",
  },
  menuPaper: {
    width: "600px",
    minHeight: "500px",
    position: "absolute",
    left: "45% !important",
    top: "25% !important",
    outline: "none",
  },
}))(ScopeGroupItem);
