import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "components/Mui/Grid";
import IconButton from "components/Mui/IconButton";
import { Formik } from "formik";
import { SingleReactSelect } from "components/Formik/ReactSelect";
import Input from "components/Formik/Input";
import MoneyInput from "components/Formik/MoneyInput";
import FloatInput from "components/Formik/FloatInput";
import useLineItemOptions from "hooks/useLineItemOptions";
import AddContentsItemMutation from "../ScopeActionBar/ScopeAddLineItem/AddContentsItemForm/AddContentsItemMutation";
//import ClaimMetaContext from "pages/Claim/ClaimDetails/ClaimMetaContext";

const ScopeContentsNewItem = ({ classes, setIsAddNewItem, jobQuoteId }) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const refresh = window[Symbol.for("__refreshScope")];
  const [isDirectSupply, setIsDirectSupply] = React.useState(false);
  return (
    <Formik
      initialValues={{
        locationId: "",
        costCentreId: "",
        costItemId: "",
        directsupply: null,
        lineDescription: "",
        purchasePrice: "",
        ageOfItem: "",
        proofOfLoss: "",
        qty: "1",
        rate: "0",
        policyLimit: null,
      }}
      validate={(values) => {
        let errors = {};
        if (!values.locationId) errors.locationId = "required!";
        if (!values.costCentreId) errors.costCentreId = "required!";
        if (!values.costItemId) errors.costItemId = "required!";
        if (!values.qty) errors.qty = "required!";
        if (!values.rate && values.rate !== 0) errors.rate = "required!";
        if (isDirectSupply) {
          if (values.directsupply !== true && values.directsupply !== false) {
            errors.directsupply = "Required!";
          }
          if (!values.policyLimit) errors.policyLimit = "Required!";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(async () => {
          const variables = getVariables(values, jobQuoteId);
          const res = await AddContentsItemMutation(variables);
          setSubmitting(false);
          if (res && res.ok) {
            snackbar.toggleOpen({ message: res.messages[0] });
            if (res.success) {
              refresh();
            }
          }
        }, 400);
      }}
    >
      {({ values, isSubmitting, setFieldValue, submitForm }) => (
        <NewItemContent
          classes={classes}
          setIsAddNewItem={setIsAddNewItem}
          values={values}
          submitForm={submitForm}
          setFieldValue={setFieldValue}
          directsupply={[isDirectSupply, setIsDirectSupply]}
        />
      )}
    </Formik>
  );
};

export default withStyles((theme) => ({
  truncate: {
    ...theme.mixins.truncate(theme),
    fontWeight: 100,
    width: "100%",
    display: "block !important",
  },
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    alignItems: "end",
    borderRadius: "8px",

    "&:nth-child(even)": {
      background: theme.palette.grey[100],
    },
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit,
  },
  alignCenter: {
    ...theme.mixins.alignJustifyContainer(theme),
    fontWeight: 100,
    padding: "4px",
    textAlign: "center",
    flexWrap: "wrap",
  },
}))(ScopeContentsNewItem);

const NewItemContent = ({
  classes,
  setIsAddNewItem,
  values,
  submitForm,
  setFieldValue,
  directsupply,
}) => {
  const [isDirectSupply, setIsDirectSupply] = directsupply;
  //const meta = React.useContext(ClaimMetaContext);
  const portfolioType = "Contents";
  const quotationType = "Scope";
  const variables = {
    location: { portfolioType },
    centre: { portfolioType, quotationType },

    isItem: true,
    item: {
      portfolioType,
      costCentreId: values.costCentreId || 0,
      //costCentreId: null,
      quotationType,
    },
    materialItem: {
      portfolioType,
      costCentreId: values.material_costCentreId || 0,
      //costCentreId: null,
      quotationType,
    },
  };

  const [locationOptions, centreOptions, itemOptions] = useLineItemOptions(
    variables,
    [portfolioType, , values.costCentreId]
  );
  const itemOptionsWithFullDes = itemOptions.map((item) => {
    return {
      value: item.value,
      label: item.fullDescription,
    };
  });

  React.useEffect(() => {
    const current = centreOptions.filter(
      (x) => x.value === values.costCentreId
    );
    setIsDirectSupply(current.length !== 0 && current[0].directSupplier);
  }, [values.costCentreId, centreOptions.length]);

  React.useEffect(() => {
    if (locationOptions[0] && !values.locationId)
      setFieldValue("locationId", locationOptions[0].value);
  }, [locationOptions]);

  const directSupplyOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  return (
    <Grid fluid spacing={0} className={classes.row}>
      <Grid className={classes.alignCenter} item xs={1}>
        <SingleReactSelect
          name="locationId"
          label="Location"
          select={true}
          required={true}
          options={locationOptions}
          isContentTable={true}
        />
      </Grid>
      <Grid className={classes.alignCenter} item xs={2}>
        <SingleReactSelect
          name="costCentreId"
          label="Cost Centre"
          select={true}
          required={true}
          isUnselect={true}
          options={centreOptions}
          isContentTable={true}
        />
      </Grid>
      <Grid className={classes.alignCenter} item xs={1}>
        <SingleReactSelect
          name="costItemId"
          label="Description"
          select={true}
          required={true}
          //options={itemOptionsWithFullDes}
          options={itemOptions}
          disabled={!values.costCentreId}
          isContentTable={true}
        />
      </Grid>
      <Grid className={classes.alignCenter} item xs={1}>
        <FloatInput
          name="policyLimit"
          label="Policy Item Limit"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid className={classes.alignCenter} item xs={1}>
        <SingleReactSelect
          name="directsupply"
          label="Direct Supply"
          select={true}
          required={true}
          options={directSupplyOptions}
          disabled={!values.costCentreId || !isDirectSupply}
          isContentTable={true}
        />
      </Grid>
      <Grid
        className={`${classes.alignCenter} ${classes.truncate}`}
        item
        xs={1}
      >
        <Input
          name="purchasePrice"
          label="Purchase Price"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid
        className={`${classes.alignCenter} ${classes.truncate}`}
        item
        xs={1}
      >
        <Input
          name="ageOfItem"
          label="Age of Item"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid
        className={`${classes.alignCenter} ${classes.truncate}`}
        item
        xs={1}
      >
        <Input
          name="proofOfLoss"
          label="Proof of Loss"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid className={classes.alignCenter} item xs={1}>
        <FloatInput name="qty" label="Qty" required={true} />
      </Grid>
      <Grid className={classes.alignCenter} item xs={1}>
        <MoneyInput name="rate" label="Reserve Inc GST" required={true} />
      </Grid>
      <Grid className={classes.alignCenter} item xs={1}>
        <div>
          <IconButton
            icon="close"
            className={classes.actionBtn}
            onClick={() => {
              setIsAddNewItem(false);
            }}
          />
          <IconButton
            icon="done"
            className={classes.actionBtn}
            type="submit"
            onClick={submitForm}
          />
        </div>
      </Grid>
      <Grid className={classes.alignCenter} item xs={true}>
        <Input
          name="lineDescription"
          label="More Details"
          rows="5"
          rowsMax="5"
        />
      </Grid>
    </Grid>
  );
};

const getVariables = (values, jobQuoteId) => {
  return {
    input: {
      jobQuoteId,
      items: [
        {
          locationId: Number(values.locationId),
          //costCentreId: null,
          costCentreId: Number(values.costCentreId),
          costItemId: Number(values.costItemId),
          lineDescription: values.lineDescription,
          qty: Number(values.qty),
          rate: Number(values.rate),
          directsupply: values.directsupply,
          purchasePrice: values.purchasePrice,
          ageOfItem: values.ageOfItem,
          proofOfLoss: values.proofOfLoss,
          policyLimit: Number(values.policyLimit),
        },
      ],
    },
  };
};
