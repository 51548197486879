import React from 'react'

const FeeReloadContext = React.createContext()

export const FeeReloadContextProvider = ({ value, children }) => {
  return (
    <FeeReloadContext.Provider value={value}>
      {children}
    </FeeReloadContext.Provider>  
  )
}

export const useFeeReloadContext = () => {
  return React.useContext(FeeReloadContext)
}