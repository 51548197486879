import React from 'react'
import { fetchQuery, graphql } from 'relay-runtime'
import environment from 'common/relay'
import { withStyles } from '@material-ui/core/styles'
import Dialog from 'components/Mui/Dialog'

import AcceptContentItemForm from './AcceptContentItemForm'
import ClaimMetaContext from 'pages/Claim/ClaimDetails/ClaimMetaContext'

const query = graphql`
  query AcceptContentItemQuery($where: JobQuoteIndex!) {
    accepting: jobQuotes(where: $where) {
      edges {
        node {
          id

          supplier {
            companyName
          }
          deliveryChargeUsed

          items: lineItems {
            authorised

            itemId: jobQuoteItemId
            centre: costCentre { name: costCentreName }
            costItem { itemDescription } description: lineDescription
            qty
            rate
            margin
            subtotal
          }
        }
      }
    }
  }
`
const AcceptContentItem = ({ classes, id, portfolioType }) => {
  const [ items, setItems ] = React.useState(null)
  const [ supplier, setSupplier ] = React.useState(null)
  const [ deliveryChargeUsed, setDeliveryChargeUsed ] = React.useState(null)
  const meta = React.useContext(ClaimMetaContext)

  const [ refresh, setRefresh ] = React.useState(false)
  React.useEffect(() => {
    if(id) {
      const variables = {
        where: { id, portfolios: 'Contents' },
        scopeWhere: {
          claimId: meta.claim.claimId,
          requestCategory: 'Scoping'
        }
      }
      fetchQuery(environment, query, variables)
        .then(data => {
          const { items, supplier, deliveryChargeUsed } = data.accepting.edges[0].node
          const displayItems = items.filter(item => item.authorised !== false)

          setItems(displayItems)
          setSupplier(supplier)
          setDeliveryChargeUsed(deliveryChargeUsed || false)
        })
    }

    return () => {
      setItems(null)
      setSupplier(null)
      setDeliveryChargeUsed(null)
    }
  }, [ id, refresh ])

  return (
    <Dialog noForm noActions
      symbol='__AcceptContentItem'
      title={`Accept Quote${supplier ? ` - ${supplier.companyName}` : ''}`}
      paperClassname={classes.paper}
      onEnter={() => {
        setRefresh(!refresh)
      }}
      content={<AcceptContentItemForm
        claimId={meta.claim.claimId}
        items={items}
        deliveryChargeUsed={deliveryChargeUsed}
      />}
    />
  )
}

export default withStyles(theme => ({
  paper: {
    minWidth: '70%'
  }
}))(AcceptContentItem)
