/**
 * @flow
 * @relayHash 86c115ac6db39e5d05795e9d3de71606
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuotationType = "AssessorReport" | "MakeSafe" | "Scope" | "UnDefined" | "Variation" | "%future added value";
export type QuoteCostType = "Labour" | "Material" | "UnDefined" | "%future added value";
export type QuoteCostLocationIndex = {|
  portfolioType: PortfolioType
|};
export type QuoteCostCentreIndex = {|
  insurerId?: ?number,
  costType?: ?QuoteCostType,
  portfolioType: PortfolioType,
  quotationType: QuotationType,
|};
export type QuoteCostItemIndex = {|
  costCentreId?: ?number,
  insurerId?: ?number,
  portfolioType: PortfolioType,
  quotationType: QuotationType,
|};
export type JobScopeTypeWhere = {|
  insurerId: string,
  claimId?: ?string,
  forManager: boolean,
  quotationType: QuotationType,
  portfolioType: PortfolioType,
|};
export type PropertyTypeWhere = {|
  insurerId: string,
  portfolioType: PortfolioType,
|};
export type useLineItemOptionsQueryVariables = {|
  location: QuoteCostLocationIndex,
  centre: QuoteCostCentreIndex,
  item: QuoteCostItemIndex,
  isItem: boolean,
  materialItem: QuoteCostItemIndex,
  isMaterialItem: boolean,
  jobScopeWhere: JobScopeTypeWhere,
  isJobScope: boolean,
  properties: PropertyTypeWhere,
  isProperties: boolean,
|};
export type useLineItemOptionsQueryResponse = {|
  +locations: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +label: string,
        +value: number,
      |}
    |}>
  |},
  +centres: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +group: ?QuoteCostType,
        +label: string,
        +value: number,
        +directSupplier: boolean,
      |}
    |}>
  |},
  +properties?: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
  +items?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +label: string,
        +value: number,
        +fullDescription: string,
        +units: ?$ReadOnlyArray<?{|
          +label: string,
          +value: number,
        |}>,
      |}
    |}>
  |},
  +materialItems?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +label: string,
        +value: number,
        +fullDescription: string,
        +units: ?$ReadOnlyArray<?{|
          +label: string,
          +value: number,
        |}>,
      |}
    |}>
  |},
  +jobScopeItems?: ?$ReadOnlyArray<?{|
    +label: string,
    +value: string,
  |}>,
|};
export type useLineItemOptionsQuery = {|
  variables: useLineItemOptionsQueryVariables,
  response: useLineItemOptionsQueryResponse,
|};
*/


/*
query useLineItemOptionsQuery(
  $location: QuoteCostLocationIndex!
  $centre: QuoteCostCentreIndex!
  $item: QuoteCostItemIndex!
  $isItem: Boolean!
  $materialItem: QuoteCostItemIndex!
  $isMaterialItem: Boolean!
  $jobScopeWhere: JobScopeTypeWhere!
  $isJobScope: Boolean!
  $properties: PropertyTypeWhere!
  $isProperties: Boolean!
) {
  locations: quoteCostLocations(where: $location) {
    edges {
      node {
        label: locationName
        value: costLocationId
        id
      }
    }
  }
  centres: quoteCostCentres(where: $centre) {
    edges {
      node {
        group: costType
        label: costCentreName
        value: costCentreId
        directSupplier
        id
      }
    }
  }
  properties: propertyTypes(where: $properties) @include(if: $isProperties) {
    label: name
    value: id
    id
  }
  items: quoteCostItems(where: $item) @include(if: $isItem) {
    edges {
      node {
        label: itemDescription
        value: costItemId
        fullDescription
        units {
          label: unitName
          value: unitId
        }
        id
      }
    }
  }
  materialItems: quoteCostItems(where: $materialItem) @include(if: $isMaterialItem) {
    edges {
      node {
        label: itemDescription
        value: costItemId
        fullDescription
        units {
          label: unitName
          value: unitId
        }
        id
      }
    }
  }
  jobScopeItems: jobScopeTypes(where: $jobScopeWhere) @include(if: $isJobScope) {
    label: name
    value: id
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "location",
    "type": "QuoteCostLocationIndex!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "centre",
    "type": "QuoteCostCentreIndex!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "item",
    "type": "QuoteCostItemIndex!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isItem",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "materialItem",
    "type": "QuoteCostItemIndex!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isMaterialItem",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobScopeWhere",
    "type": "JobScopeTypeWhere!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isJobScope",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "properties",
    "type": "PropertyTypeWhere!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isProperties",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "location",
    "type": "QuoteCostLocationIndex!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "locationName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "costLocationId",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "centre",
    "type": "QuoteCostCentreIndex!"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": "group",
  "name": "costType",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "costCentreName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "costCentreId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "directSupplier",
  "args": null,
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "properties",
    "type": "PropertyTypeWhere!"
  }
],
v10 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "name",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "id",
  "args": null,
  "storageKey": null
},
v12 = [
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "item",
    "type": "QuoteCostItemIndex!"
  }
],
v14 = {
  "kind": "ScalarField",
  "alias": "label",
  "name": "itemDescription",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": "value",
  "name": "costItemId",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullDescription",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "units",
  "storageKey": null,
  "args": null,
  "concreteType": "QuoteItemUnit",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": "label",
      "name": "unitName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": "value",
      "name": "unitId",
      "args": null,
      "storageKey": null
    }
  ]
},
v18 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "QuoteCostItemEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "QuoteCostItem",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/)
        ]
      }
    ]
  }
],
v19 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "materialItem",
    "type": "QuoteCostItemIndex!"
  }
],
v20 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "jobScopeWhere",
    "type": "JobScopeTypeWhere!"
  }
],
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v22 = [
  (v10/*: any*/),
  (v11/*: any*/),
  (v21/*: any*/)
],
v23 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "QuoteCostItemEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "QuoteCostItem",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v21/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "useLineItemOptionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "locations",
        "name": "quoteCostLocations",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "QuoteCostLocationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "QuoteCostLocationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "centres",
        "name": "quoteCostCentres",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "QuoteCostCentreConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "QuoteCostCentreEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isProperties",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "properties",
            "name": "propertyTypes",
            "storageKey": null,
            "args": (v9/*: any*/),
            "concreteType": "PropertyType",
            "plural": true,
            "selections": (v12/*: any*/)
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isItem",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "quoteCostItems",
            "storageKey": null,
            "args": (v13/*: any*/),
            "concreteType": "QuoteCostItemConnection",
            "plural": false,
            "selections": (v18/*: any*/)
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isMaterialItem",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "materialItems",
            "name": "quoteCostItems",
            "storageKey": null,
            "args": (v19/*: any*/),
            "concreteType": "QuoteCostItemConnection",
            "plural": false,
            "selections": (v18/*: any*/)
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isJobScope",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "jobScopeItems",
            "name": "jobScopeTypes",
            "storageKey": null,
            "args": (v20/*: any*/),
            "concreteType": "JobScopeType",
            "plural": true,
            "selections": (v12/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "useLineItemOptionsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "locations",
        "name": "quoteCostLocations",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "QuoteCostLocationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "QuoteCostLocationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostLocation",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v21/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "centres",
        "name": "quoteCostCentres",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "QuoteCostCentreConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "QuoteCostCentreEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "QuoteCostCentre",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v21/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isProperties",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "properties",
            "name": "propertyTypes",
            "storageKey": null,
            "args": (v9/*: any*/),
            "concreteType": "PropertyType",
            "plural": true,
            "selections": (v22/*: any*/)
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isItem",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "quoteCostItems",
            "storageKey": null,
            "args": (v13/*: any*/),
            "concreteType": "QuoteCostItemConnection",
            "plural": false,
            "selections": (v23/*: any*/)
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isMaterialItem",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "materialItems",
            "name": "quoteCostItems",
            "storageKey": null,
            "args": (v19/*: any*/),
            "concreteType": "QuoteCostItemConnection",
            "plural": false,
            "selections": (v23/*: any*/)
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isJobScope",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "jobScopeItems",
            "name": "jobScopeTypes",
            "storageKey": null,
            "args": (v20/*: any*/),
            "concreteType": "JobScopeType",
            "plural": true,
            "selections": (v22/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "useLineItemOptionsQuery",
    "id": null,
    "text": "query useLineItemOptionsQuery(\n  $location: QuoteCostLocationIndex!\n  $centre: QuoteCostCentreIndex!\n  $item: QuoteCostItemIndex!\n  $isItem: Boolean!\n  $materialItem: QuoteCostItemIndex!\n  $isMaterialItem: Boolean!\n  $jobScopeWhere: JobScopeTypeWhere!\n  $isJobScope: Boolean!\n  $properties: PropertyTypeWhere!\n  $isProperties: Boolean!\n) {\n  locations: quoteCostLocations(where: $location) {\n    edges {\n      node {\n        label: locationName\n        value: costLocationId\n        id\n      }\n    }\n  }\n  centres: quoteCostCentres(where: $centre) {\n    edges {\n      node {\n        group: costType\n        label: costCentreName\n        value: costCentreId\n        directSupplier\n        id\n      }\n    }\n  }\n  properties: propertyTypes(where: $properties) @include(if: $isProperties) {\n    label: name\n    value: id\n    id\n  }\n  items: quoteCostItems(where: $item) @include(if: $isItem) {\n    edges {\n      node {\n        label: itemDescription\n        value: costItemId\n        fullDescription\n        units {\n          label: unitName\n          value: unitId\n        }\n        id\n      }\n    }\n  }\n  materialItems: quoteCostItems(where: $materialItem) @include(if: $isMaterialItem) {\n    edges {\n      node {\n        label: itemDescription\n        value: costItemId\n        fullDescription\n        units {\n          label: unitName\n          value: unitId\n        }\n        id\n      }\n    }\n  }\n  jobScopeItems: jobScopeTypes(where: $jobScopeWhere) @include(if: $isJobScope) {\n    label: name\n    value: id\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b1cb36481363f52a67ee0d8b8ae41aca';
module.exports = node;
