import React from "react";
import environment from "common/relay";
import { graphql, QueryRenderer } from "react-relay";

import { Grid } from "@material-ui/core";
import { moneyFormat } from "common/utils";

const VariationValidationDialogFooter = ({ portfolioType, meta, id }) => {
  const query = graphql`
    query VariationValidationDialogFooterQuery($where: JobVariationIndex!) {
      jobVariations(where: $where) {
        edges {
          node {
            total
            validationTotal
            validationSavings
            validationSavingPercentage
            variationId
          }
        }
      }
    }
  `;
  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    window[Symbol.for("__refreshVariationValidationDialogFooter")] = () =>
      setRefresh(!refresh);
    return () => {
      setRefresh(false);
      delete window[Symbol.for("__refreshVariationValidationDialogFooter")];
    };
  }, [refresh]);

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        where: {
          id: id,
          portfolios: portfolioType,
          includeValidationItems: true,
        },
        refresh,
      }}
      render={({ error, props }) => {
        if (!props) return "Loading...";
        const variation =
          props.jobVariations.edges.length !== 0
            ? props.jobVariations.edges[0].node
            : null;
        return (
          <Grid
            container
            spacing={0}
            style={{
              marginTop: "1.5rem",
              marginBottom: "1.5rem",
              textAlign: "center",
            }}
          >
            <Grid
              item
              xs={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div style={{ fontWeight: 600 }}>Total: &nbsp;</div>
              <div>{moneyFormat(variation.total)}</div>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div style={{ fontWeight: 600 }}>Validation Total: &nbsp;</div>
              <div>{moneyFormat(variation.validationTotal)}</div>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div style={{ fontWeight: 600 }}>Savings: &nbsp;</div>
              <div>{moneyFormat(variation.validationSavings)}</div>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div style={{ fontWeight: 600 }}>Saving(%): &nbsp;</div>
              <div>{variation.validationSavingPercentage}</div>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default VariationValidationDialogFooter;
