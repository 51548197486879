/**
 * @flow
 * @relayHash aa9b7086bc61ddc589b0a7b5d4fb2a49
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoomUpdateWhereInput = {|
  roomId: string
|};
export type RoomUpdateDataInput = {|
  referenceNumber?: ?string,
  name?: ?string,
  address?: ?string,
|};
export type MediaRootRoomUpdateMutationVariables = {|
  where: RoomUpdateWhereInput,
  data: RoomUpdateDataInput,
|};
export type MediaRootRoomUpdateMutationResponse = {|
  +updateRoom: ?{|
    +id: string,
    +case: ?{|
      +referenceNumber: string,
      +name: ?string,
      +address: ?string,
    |},
  |}
|};
export type MediaRootRoomUpdateMutation = {|
  variables: MediaRootRoomUpdateMutationVariables,
  response: MediaRootRoomUpdateMutationResponse,
|};
*/


/*
mutation MediaRootRoomUpdateMutation(
  $where: RoomUpdateWhereInput!
  $data: RoomUpdateDataInput!
) {
  updateRoom(where: $where, data: $data) {
    id
    case {
      referenceNumber
      name
      address
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "RoomUpdateWhereInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "RoomUpdateDataInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "data",
    "type": "RoomUpdateDataInput!"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "RoomUpdateWhereInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "referenceNumber",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "address",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MediaRootRoomUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRoom",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Room",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "case",
            "storageKey": null,
            "args": null,
            "concreteType": "Case",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaRootRoomUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRoom",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Room",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "case",
            "storageKey": null,
            "args": null,
            "concreteType": "Case",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "MediaRootRoomUpdateMutation",
    "id": null,
    "text": "mutation MediaRootRoomUpdateMutation(\n  $where: RoomUpdateWhereInput!\n  $data: RoomUpdateDataInput!\n) {\n  updateRoom(where: $where, data: $data) {\n    id\n    case {\n      referenceNumber\n      name\n      address\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7829ae97d7584215d7d78cef9f70a618';
module.exports = node;
