import React from "react";
import { Formik, Form } from "formik";
import moment from "moment";
import { withClaimMeta } from "pages/Claim/ClaimDetails/ClaimMetaContext/";
import { Typography, Grid } from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";
import Dialog from "components/Mui/Dialog";
import Input from "components/Formik/Input";
import Button from "components/Mui/Button";
import InfoBox from "components/Mui/InfoBox/";
import ScopeValidationActionMutation from "./ScopeValidationActionMutation";

const ScopeValidationActionAudit = ({
  validationType,
  item,
  meta,
  portfolioType,
  validationRefresh,
}) => {
  const snackbar = window[Symbol.for("__snackbar")];
  const handleClick = (e) => {
    const action =
      window[
        Symbol.for(
          `__ScopeValidationActionAudit${validationType}${item.jobQuoteItemId}`
        )
      ];
    if (action) {
      action.handleOpen();
    }
  };
  if (!item.actions) return null;
  return (
    <div>
      <MuiButton
        style={{
          fontSize: "0.875rem",
          padding: 0,
          margin: 0,
          alignItems: "inherit",
          textDecoration: "underline",
        }}
        color="secondary"
        disabled={item.actions[validationType].isDisabled}
        onClick={handleClick}
      >
        {item.actions[validationType].label}
      </MuiButton>
      <Dialog
        noForm
        noActions
        symbol={`__ScopeValidationActionAudit${validationType}${item.jobQuoteItemId}`}
        title={`Scope Validation - ${validationType}`}
        minWidth="10%"
        content={
          <Formik
            initialValues={{
              marginRate: item.marginRate,
              rate: item.rate,
              qty: item.qty,
            }}
            validate={(values) => {
              let errors = {};
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const res = await ScopeValidationActionMutation({
                where: {
                  id: item.jobQuoteItemId,
                  portfolioType: portfolioType,
                },
                input: {
                  comment: values.comment,
                  reviewType: validationType,
                  item: {
                    qty: Number(values.qty),
                    rate: Number(values.rate),
                    marginRate: Number(values.marginRate),
                  },
                },
              });
              if (res.ok) {
                if (res.success) {
                  snackbar.toggleOpen({ message: res.messages[0] });
                  //validationRefresh();
                  const action =
                    window[
                      Symbol.for(
                        `__ScopeValidationActionAudit${validationType}${item.jobQuoteItemId}`
                      )
                    ];
                  if (action) {
                    action.handleClose();
                  }
                } else {
                  snackbar.toggleOpen({ message: res.messages[0] });
                }
              }
              setSubmitting(false);
            }}
          >
            {({ values, isSubmitting }) => (
              <Form style={{ width: "500px" }}>
                <Grid container>
                  <Grid item xs={6}>
                    <InfoBox
                      label="Cost Centre"
                      text={item.costCentre.costCentreName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoBox
                      label="Description"
                      text={item.costItem.itemDescription}
                      maxHeight="200px"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoBox
                      label="Unit of Measure"
                      text={item.unit.unitName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoBox
                      label="More Details"
                      text={item.lineDescription}
                      maxHeight="200px"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoBox label="Room Size" text={item.dimensions} />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="% Margin"
                      name="marginRate"
                      required
                      disabled={
                        meta.claim.insurer.isMarginUpdate ? false : true
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Rate Ex Gst"
                      name="rate"
                      required
                      disabled={
                        validationType !==
                        item.actions.amend.label.toLowerCase()
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Qty"
                      name="qty"
                      required
                      disabled={
                        validationType !==
                        item.actions.amend.label.toLowerCase()
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label="Comments"
                      name="comment"
                      rows={5}
                      required={
                        validationType ===
                        item.actions.decline.label.toLowerCase()
                      }
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "end " }}>
                    <Button
                      color="primary"
                      label="Submit"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default withClaimMeta(ScopeValidationActionAudit);
