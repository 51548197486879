import React from 'react'
import environment from 'common/relay'
import { graphql, QueryRenderer } from 'react-relay'
import { getSafe } from 'common/utils'

import { Formik, Form } from 'formik'
import Input from 'components/Formik/Input'

import Dialog from 'components/Mui/Dialog'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'
import ClaimTypeIcon from 'components/Mui/ClaimTypeIcon'
import AllocateBuilderActionMutation from './AllocateBuilderActionMutation'

const query = graphql`
  query AllocateBuilderActionQuery(
    $buildersWhere: ClaimJobFilter
    $restorersWhere: ClaimJobFilter
  ) {
    Building: claimFilterOptions(where: $buildersWhere){
      label: name
      value
    }
    Restoration: claimFilterOptions(where: $restorersWhere){
      label: name
      value
    }
  }
`

class AllocateBuilderAction extends React.Component {
  snackbar = window[Symbol.for('__snackbar')]
  render() {
    const { insurerId, postcode, meta, claim } = this.props
    return (
      <Dialog
        title='Allocate supplier'
        noForm
        symbol='__allocateBuilderAction'
        content={
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              buildersWhere: {subject: 'suppliers',
                insurers: insurerId,
                portfolios: 'Building', postcode: String(postcode)
              }, 
              restorersWhere: {subject: 'suppliers',
                insurers: insurerId,
                portfolios: 'Restoration', postcode: String(postcode)
              },
            }}
            render={({error, props}) => {
              if(!props) return 'Loading...'
              return (
                <Grid container justify='space-evenly' alignItems='stretch'>
                  {
                    [
                      {unmountOn: (
                        !meta.claim.hasBuilding
                        || !getSafe(() => claim.building.reallocate, false)
                      ), type:'Building', inputLabel: 'Builder'},
                      {unmountOn: (
                        !meta.claim.hasRestoration
                        || !getSafe(() => claim.restoration.reallocate, false)
                      ), type:'Restoration', inputLabel: 'Restorer'},
                    ].map(({ unmountOn, type, inputLabel }, key) => {
                      if(unmountOn) return null
                      return (
                        <Grid item xs key={key}>
                          <Grid fluid spacing={8} justify='center' style={{height: '100%'}}>
                            <Grid item xs={12}>
                              <Grid fluid spacing={8} justify='center'>
                                <ClaimTypeIcon
                                  style={{
                                    fontSize: '2.5rem'
                                }} type={type}/>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Formik
                                initialValues={{
                                  supplierId: ''
                                }}
                                validate={(values) => {
                                  let errors = {}

                                  if(!values.supplierId) errors.supplierId = 'Required!'

                                  return errors
                                }}
                                onSubmit={(values, actions) => {
                                  values.supplierId = parseInt(values.supplierId)
                                  setTimeout(async () => {
                                    const variables = {
                                      input: {
                                        ...values,
                                        availableForQuoting: true,
                                      },
                                      where: { id: meta.claim.claimId, portfolioType: type },
                                    }

                                    const res = await AllocateBuilderActionMutation(variables, meta.claim.id)
                                    if(res.ok) {
                                      this.snackbar.toggleOpen({
                                        message: res.message
                                      })
                                      window[Symbol.for('__allocateBuilderAction')].handleClose()
                                    }
                                  }, 400)
                                }}
                              >
                                {({ isSubmitting, values }) => {
                                  return (
                                    <Form>
                                      <Grid fluid spacing={8} justify='center'>
                                        <Grid item xs={12}>
                                          <Input
                                            required
                                            name='supplierId'
                                            label={inputLabel} 
                                            options={props[type]}
                                            select
                                            value={values.supplierId}
                                          />
                                        </Grid>
                                        <Button
                                          fullWidth
                                          variant='contained'
                                          type='submit'
                                          color='primary'
                                          label='Reallocate'
                                          disabled={isSubmitting}
                                        />
                                      </Grid>
                                    </Form>
                                  )  
                                }}
                              </Formik>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>  
              )
            }}
          />
        }
      />
    )
  }
}

export default AllocateBuilderAction