import React, { Component } from 'react'
import { graphql, QueryRenderer } from 'react-relay'
import environment from 'common/relay'

import MakeSafeUpdateItemPresentation from './MakeSafeUpdateItemPresentation'

const query = graphql`
  query MakeSafeUpdateItemContainerQuery(
    $locationsWhere: QuoteCostLocationIndex!
    $centresWhere: QuoteCostCentreIndex!
    $jobScopeWhere: JobScopeTypeWhere!
  ) {
    locations: quoteCostLocations(where: $locationsWhere) {
      edges {
        node {
          label: locationName
          value: costLocationId
        }
      }
    }
    centres: quoteCostCentres(where: $centresWhere) {
      edges {
        node {
          label: costCentreName
          value: costCentreId
        }
      }
    }
    jobScopeItems: jobScopeTypes(where: $jobScopeWhere) {
      label: name
      value: id
    }
  }
`

class MakeSafeUpdateItemContainer extends Component {
  render() {
    // initial data
    const { data, updateItemDlRef, meta } = this.props

    return <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        locationsWhere: {
          portfolioType: data.portfolioType,
        },
        centresWhere: {
          portfolioType: data.portfolioType,
          quotationType: 'MakeSafe'
        },
        jobScopeWhere: {
          portfolioType: data.portfolioType,
          quotationType: 'MakeSafe',
          insurerId: meta.claim.insurer.companyId,
          forManager: false
        }
      }}
      render={({ errors, props }) => {
        if(!props) return null

        return <MakeSafeUpdateItemPresentation
          updateItemDlRef={updateItemDlRef}
          inititalData={data}
          data={props}
          meta={meta}
        />
      }}
    />
  }
}

export default MakeSafeUpdateItemContainer
