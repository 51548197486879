import { commitMutation, graphql } from "react-relay";
import environment from "common/relay";

const mutation = graphql`
  mutation VariationValidationActionMutation(
    $input: JobValidationReviewInput!
    $where: ENDataPortfolioKey!
  ) {
    jobVariationValidationReview(where: $where, input: $input) {
      messages
      success
      result {
        total
        validationTotal
        validationSavings
        validationSavingPercentage
        variationId
        id
        variationId
        completed
        variationStatus

        # info card
        variationTitle
        variationDescription
        variationReason {
          reasonDescription
        }
        actions {
          viewValidation {
            isDisabled
            isDisplay
            label
          }
          accept {
            isDisabled
            isDisplay
            label
          }
          addLineItem {
            isDisabled
            isDisplay
            label
          }
          decline {
            isDisabled
            isDisplay
            label
          }
          reset {
            isDisabled
            isDisplay
            label
          }
          submit {
            isDisabled
            isDisplay
            label
          }
          submitValidation {
            isDisabled
            isDisplay
            label
          }
          startValidation {
            isDisabled
            isDisplay
            label
          }
          completeValidation {
            isDisabled
            isDisplay
            label
          }
          submitValidation {
            isDisabled
            isDisplay
            label
          }
        }

        scopeTypes {
          properties {
            locations {
              location {
                id
                costLocationId
                locationName
              }
              items {
                actions {
                  accept {
                    isDisabled
                    isDisplay
                    label
                  }
                  amend {
                    isDisabled
                    isDisplay
                    label
                  }
                  decline {
                    isDisabled
                    isDisplay
                    label
                  }
                  validate {
                    isDisabled
                    isDisplay
                    label
                  }
                }
                variationItemId
                quoteItemType
                accepted
                location {
                  costLocationId
                  locationName
                }
                dimensions
                costCentre {
                  costCentreId
                  costCentreName
                }
                costItem {
                  costItemId
                  itemDescription
                }
                lineDescription
                unit {
                  unitId
                  unitName
                }
                note
                lineStatusDescription
                qty
                rate
                marginRate
                subtotal
                logdate
                sirRate
                scopeType {
                  name
                  id
                }
              }
              validationItems {
                actions {
                  accept {
                    isDisabled
                    isDisplay
                    label
                  }
                  amend {
                    isDisabled
                    isDisplay
                    label
                  }
                  decline {
                    isDisabled
                    isDisplay
                    label
                  }
                  validate {
                    isDisabled
                    isDisplay
                    label
                  }
                }
                variationItemId
                quoteItemType
                accepted
                location {
                  costLocationId
                  locationName
                }
                dimensions
                costCentre {
                  costCentreId
                  costCentreName
                }
                costItem {
                  costItemId
                  itemDescription
                }
                lineDescription
                unit {
                  unitId
                  unitName
                }
                note
                lineStatusDescription
                qty
                rate
                marginRate
                subtotal
                logdate
                sirRate
                scopeType {
                  name
                  id
                }
              }
            }
          }
          locations {
            id
            location {
              id
              costLocationId
              locationName
            }
            items {
              actions {
                accept {
                  isDisabled
                  isDisplay
                  label
                }
                amend {
                  isDisabled
                  isDisplay
                  label
                }
                decline {
                  isDisabled
                  isDisplay
                  label
                }
                validate {
                  isDisabled
                  isDisplay
                  label
                }
              }
              variationItemId
              quoteItemType
              accepted
              location {
                costLocationId
                locationName
              }
              dimensions
              costCentre {
                costCentreId
                costCentreName
              }
              costItem {
                costItemId
                itemDescription
              }
              lineDescription
              unit {
                unitId
                unitName
              }
              note
              lineStatusDescription
              qty
              rate
              marginRate
              subtotal
              logdate
              sirRate
              scopeType {
                name
                id
              }
            }
          }
          scopeType {
            id
            name
          }
          id
          gst
          margin
          sirGst
          sirMargin
          sirSubtotal
          sirTotal
          subtotal
          total
          discount
          fields {
            marginBreakdown {
              isDisplay
              label
            }
            discount {
              isDisplay
              label
              isDisabled
            }
            sIRRate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            gst {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            total {
              label
              isDisplay
            }
            action {
              label
              isDisplay
            }
            costCentre {
              label
              isDisplay
            }
            description {
              label
              isDisplay
            }
            marginRate {
              label
              isDisplay
            }
            margin {
              label
              isDisplay
            }
            qty {
              label
              isDisplay
            }
            rate {
              label
              isDisplay
            }
            subtotal {
              label
              isDisplay
            }
            unit {
              label
              isDisplay
            }
          }
        }

        # item table
        lineItems {
          variationItemId
          accepted

          location {
            costLocationId
            locationName
          }
          dimensions
          sirRate
          costCentre {
            costCentreId
            costCentreName
          }
          costItem {
            costItemId
            itemDescription
          }
          lineDescription
          unit {
            unitId
            unitName
          }
          marginRate
          qty
          rate
          gst
          subtotal
        }

        # report
        subtotal
        gst
        margin
        total
      }
    }
  }
`;

export default (variables) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (res) => {
        const { success, messages } = res.jobVariationValidationReview;
        resolve({ ok: true, success, messages });
      },
      onError: (errors) => {
        resolve({
          ok: false,
          messages: ["Unable to complete action, please try again"],
        });
      },
    });
  });
};
