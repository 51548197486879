/**
 * @flow
 * @relayHash 56c2b3355f9fa33ba7003c33e5d7f911
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type RecommendSupplierMutationVariables = {|
  where: ENDataPortfolioKey
|};
export type RecommendSupplierMutationResponse = {|
  +jobQuoteRecommend: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type RecommendSupplierMutation = {|
  variables: RecommendSupplierMutationVariables,
  response: RecommendSupplierMutationResponse,
|};
*/


/*
mutation RecommendSupplierMutation(
  $where: ENDataPortfolioKey!
) {
  jobQuoteRecommend(where: $where) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "jobQuoteRecommend",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ENDataPortfolioKey!"
      }
    ],
    "concreteType": "JobQuotePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RecommendSupplierMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RecommendSupplierMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RecommendSupplierMutation",
    "id": null,
    "text": "mutation RecommendSupplierMutation(\n  $where: ENDataPortfolioKey!\n) {\n  jobQuoteRecommend(where: $where) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ce5176efef1a8489d9f8da06cf2d8bbb';
module.exports = node;
