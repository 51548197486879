import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ScopeGroupCostCentreTableItems from "./ScopeGroupCostCentreTableItems";
import ListIcon from "@material-ui/icons/List";
import { graphql, QueryRenderer } from "react-relay";
import environment from "common/relay";

const query = graphql`
  query ScopeGroupCostCentreTableQuery($where: JobQuoteIndex!) {
    jobQuotes(where: $where) {
      edges {
        node {
          scopeTypes {
            scopeType {
              name
            }
            costCentres {
              costCentre {
                costCentreName
                costCentreId
                costType
                directSupplier
              }
              units {
                qty
                subtotal
                unitName
                validationQty
                validationSubtotal
              }
            }
          }
        }
      }
    }
  }
`;

const ScopeGroupCostCentreTable = ({
  classes,
  //quote,
  meta,
  portfolio,
  //setRefresh,
}) => {
  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    window[Symbol.for("__refreshScopeValidationSummary")] = () =>
      setRefresh(!refresh);
    return () => {
      setRefresh(false);
      delete window[Symbol.for("__refreshScopeValidationSummary")];
    };
  }, [refresh]);

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        where: {
          claimId: meta.claim.claimId,
          portfolios: portfolio,
          requestCategory: "ScopeValidation",
        },
        refresh,
      }}
      render={({ error, props }) => {
        if (!props) return "Loading...";
        const quote =
          props.jobQuotes.edges.length !== 0
            ? props.jobQuotes.edges[0].node
            : null;
        if (!quote || !quote.scopeTypes.length) return "No Data";
        const scopeTypes = quote.scopeTypes;
        return (
          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                textAlign: "Left",
                marginTop: "1rem",
                marginBottom: "1rem",
                fontSize: "1.25rem",
              }}
            >
              <div style={{ display: "flex", verticalAlign: "middle" }}>
                <ListIcon style={{ fontSize: "2rem", color: "#0f5b78" }} />
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  &nbsp;Summary of Trades
                </div>
              </div>
            </div>
            {scopeTypes.map((scopeType, index) => {
              return (
                <ScopeGroupCostCentreTableItems
                  key={index}
                  scopeType={scopeType}
                  classes={classes}
                  portfolio={portfolio}
                />
              );
            })}
          </div>
        );
      }}
    />
  );
};

export default withStyles((theme) => ({
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    background: theme.palette.grey[400],
    borderRadius: 8,
    justifyContent: "flex-end",
  },
  scopeTypeRow: {
    fontWeight: 600,
    borderRadius: 8,
    background: theme.palette.grey[400],
    "&:hover": {
      background: theme.palette.grey[600],
    },
  },
  propertiesRow: {
    fontWeight: 600,
    borderRadius: 8,
    background: theme.palette.grey[300],
    "&:hover": {
      background: theme.palette.grey[500],
    },
  },
  expandBtn: {
    margin: 0,
    padding: theme.spacing.unit,
  },
  propertiesExpandBtn: {
    margin: 0,
    padding: theme.spacing.unit,
    marginLeft: "20px",
  },
  noItem: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
  subHeader: {
    fontStyle: "italic",
    fontWeight: 600,
    background: theme.palette.grey[400],

    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    borderRadius: 8,

    borderTop: "1px solid #ececec",
  },
  root: {
    maxHeight: "60vh",
    overflow: "auto",
  },
  discount: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "300px",
  },
  actionBtn: {
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(ScopeGroupCostCentreTable);
