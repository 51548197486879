import React from "react";
import { Formik, Form } from "formik";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { markupToContext, contextToMarkup } from "common/utils";
import {
  nameRegexp,
  emailRegexp,
  refNumberRegexp,
  addressRegexp,
  suburbRegexp /* commonCharactersRegexp, */,
  clientNameRegexp,
} from "common/inputRegex.js";
import PreventNavigationDialog from "components/PreventNavigationDialog";

import Grid from "components/Mui/Grid";

import AllocateBuilderAction from "pages/Claim/ClaimDetails/ClaimDetailsAction/AllocateBuilderAction";
import JobInfoActionBar from "./JobInfoActionBar";

import ClaimDetailsCard from "./Cards/ClaimDetailsCard";
import ClientInfoCard from "./Cards/ClientInfoCard";
import PostalAddressCard from "./Cards/PostalAddressCard";
import TenantInfoCard from "./Cards/TenantInfoCard";
import ClaimDescriptionCard from "./Cards/ClaimDescriptionCard";
import QuotingBuildersCard from "./Cards/QuotingBuildersCard";
import QuotingRestorersCard from "./Cards/QuotingRestorersCard";
import AdditionalDataCard from "./Cards/AdditionalDataCard";
import SpecialistCard from "./Cards/SpecialistCard";

import UpdateClaimMutation from "./UpdateClaimMutation";

class JobInfoPresentation extends React.Component {
  snackbar = window[Symbol.for("__snackbar")];
  finaliseClaim = React.createRef();

  state = {
    isEditing: null,
    supplier: this.props.meta.user.type.isBuilder
      ? {
          ...this.props.meta.claim.building.jobSuppliers[0],
          portfolio: "Building",
        }
      : this.props.meta.user.type.isRestorer
      ? {
          ...this.props.meta.claim.restoration.jobSuppliers[0],
          portfolio: "Restoration",
        }
      : {},
  };

  componentWillUpdate = (nextProps) => {
    const newSupplier = nextProps.meta.user.type.isBuilder
      ? {
          ...nextProps.meta.claim.building.jobSuppliers[0],
          portfolio: "Building",
        }
      : nextProps.meta.user.type.isRestorer
      ? {
          ...nextProps.meta.claim.restoration.jobSuppliers[0],
          portfolio: "Restoration",
        }
      : {};

    if (JSON.stringify(this.state.supplier) !== JSON.stringify(newSupplier)) {
      this.setState({ supplier: newSupplier });
    }
  };

  render() {
    const { classes, meta, claim, actions } = this.props;
    const { supplier } = this.state;
    return (
      <>
        <AllocateBuilderAction
          insurerId={claim.insurer.companyId}
          postcode={claim.incidentDetail.riskAddress.postcode}
          meta={meta}
          claim={claim}
        />
        <Formik
          initialValues={mapJobInfoInitialData(claim)}
          validate={(values) => {
            if (!meta.user.type.isAdmin) return {};

            let errors = {};

            [
              "riskname",
              "insuredSalutation",
              "riskAddressSuburb",
              "postalAddressSuburb",
            ].forEach((e) => {
              if (values[e] && nameRegexp.test(values[e]))
                errors[e] = "Invalid character";
            });
            ["riskAddressLine1", "postalAddressLine1"].forEach((e) => {
              if (values[e] && addressRegexp.test(values[e]))
                errors[e] = "Invalid character";
            });
            ["insuredName", "tenantName"].forEach((e) => {
              if (values[e] && clientNameRegexp.test(values[e]))
                errors[e] = "Invalid character";
            });
            ["riskAddressSuburb", "postalAddressSuburb"].forEach((e) => {
              if (values[e] && suburbRegexp.test(values[e]))
                errors[e] = "Invalid character";
            });
            ["refNumber", "contentsRefNum", "additionalRefNumber"].forEach(
              (e) => {
                if (values[e] && !refNumberRegexp.test(values[e]))
                  errors[e] = "Invalid Reference number";
                if (claim.insurer && claim.insurer.insrefnumLength) {
                  if (
                    String(values[e]).length > claim.insurer.insrefnumLength
                  ) {
                    errors[
                      e
                    ] = `Reference numbers cannot be longer than ${claim.insurer.insrefnumLength} characters`;
                  }
                }
              }
            );

            if (
              parseInt(values.Portfolios_Building_SumInsured) <
              parseInt(values.Portfolios_Building_ExcessValue)
            )
              errors.Portfolios_Building_SumInsured =
                "Sum insured must be higher than excess value";
            if (
              parseInt(values.Portfolios_Contents_SumInsured) <
              parseInt(values.Portfolios_Contents_ExcessValue)
            )
              errors.Portfolios_Contents_SumInsured =
                "Sum insured must be higher than excess value";
            if (
              parseInt(values.Portfolios_Restoration_SumInsured) <
              parseInt(values.Portfolios_Restoration_ExcessValue)
            )
              errors.Portfolios_Restoration_SumInsured =
                "Sum insured must be higher than excess value";
            ["insuredEmail", "customLoginEmail"].forEach((e) => {
              if (values[e] && !emailRegexp.test(values[e]))
                errors[e] = "Invalid email";
            });
            [
              "incidentDate",
              "refNumber",
              "eventTypeId",
              "catCodeId",
              "casemanagerId",
              "insuredName",
              "riskAddressLine1",
              "riskAddressSuburb",
              "riskAddressState",
              "riskAddressPostcode",
              "insuredPhone1",
            ].forEach((e) => {
              if (!values[e]) errors[e] = "Required!";
            });

            // Distributor required when hasAccess
            if (
              meta.claim.insurer.policyTypeSuppliersView &&
              !values.policyTypeId
            ) {
              errors.policyTypeId = "Required!";
            }

            if (
              meta.claim.insurer.policyCoverSuppliersView &&
              !values.policyCoverId
            ) {
              errors.policyCoverId = "Required!";
            }

            [
              "Portfolios_Building_SumInsured",
              "Portfolios_Building_ExcessValue",
              "Portfolios_Contents_SumInsured",
              "Portfolios_Contents_ExcessValue",
              "Portfolios_Restoration_SumInsured",
              "Portfolios_Restoration_ExcessValue",
            ].forEach((e) => {
              if (!values[e] && values[e] !== 0) errors[e] = "Required!";
            });

            if (String(values.insuredSalutation).length > 20) {
              errors.insuredSalutation =
                "Maximum salutation characters limit is 20";
            }

            return errors;
          }}
          onSubmit={(
            { claimDescription, incidentDate, homeAssessorId, ...values },
            actions
          ) => {
            setTimeout(async () => {
              // return
              const variables = await this.dataToInput({
                ...values,
                homeAssessorId: homeAssessorId
                  ? parseInt(homeAssessorId)
                  : undefined,
                incidentDate: incidentDate.format("YYYY-MM-DDTHH:mm:ss"),
                claimDescription: contextToMarkup(claimDescription),
              });
              const res = await UpdateClaimMutation(variables);
              if (res.ok) {
                this.snackbar.toggleOpen({
                  message: res.messages[0],
                });
                if (res.success) {
                  actions.setTouched({});
                } else {
                  if (res.fieldErrors) {
                    res.fieldErrors.forEach((x) => {
                      actions.setFieldError([x.fieldName], x.message);
                    });
                  }
                  if (res.errors) {
                    this.snackbar.toggleOpen({
                      message: res.errors[0].message,
                    });
                  }
                }
              }

              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, setFieldValue, isSubmitting, touched, setTouched }) => {
            const isTouched = Object.values(touched).indexOf(true) !== -1;
            return (
              <>
                <PreventNavigationDialog
                  when={isTouched && meta.user.type.isAdmin}
                />
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <JobInfoActionBar
                        claim={claim}
                        supplier={supplier}
                        meta={meta}
                        isSubmitting={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.root}>
                    <Grid item xs>
                      <Grid fluid spacing={0} className={classes.card}>
                        <Grid item xs>
                          <ClaimDetailsCard claim={claim} user={meta.user} />
                        </Grid>
                      </Grid>

                      <Grid fluid spacing={0} className={classes.card}>
                        <Grid item xs>
                          <ClientInfoCard
                            claim={claim}
                            user={meta.user}
                            metaClaim={meta.claim}
                            actions={actions}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        fluid
                        className={classes.card}
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                      >
                        <Grid item xs>
                          <PostalAddressCard
                            user={meta.user}
                            values={values}
                            setFieldValue={setFieldValue}
                            setTouched={setTouched}
                            touched={touched}
                          />
                        </Grid>
                        <Grid item xs>
                          <TenantInfoCard user={meta.user} />
                        </Grid>
                      </Grid>

                      <Grid fluid className={classes.card}>
                        <Grid item xs>
                          <ClaimDescriptionCard
                            claim={claim}
                            user={meta.user}
                          />
                        </Grid>
                      </Grid>

                      {meta.user.type.isAdmin && (
                        <Grid fluid className={classes.card}>
                          <QuotingBuildersCard
                            setFieldValue={setFieldValue}
                            claim={claim}
                            classes={classes}
                            claimId={meta.claim.claimId}
                          />

                          <QuotingRestorersCard
                            claim={claim}
                            classes={classes}
                            setFieldValue={setFieldValue}
                            claimId={meta.claim.claimId}
                          />
                        </Grid>
                      )}

                      {meta.claim.insurer.isSpecialistModule && (
                        <Grid fluid className={classes.card}>
                          <Grid item xs={6}>
                            <SpecialistCard />
                          </Grid>
                        </Grid>
                      )}

                      {claim.additionalData && claim.additionalData.length ? (
                        <Grid fluid className={classes.card}>
                          {claim.additionalData.map((data) => (
                            <Grid item xs>
                              <AdditionalDataCard key={data.id} data={data} />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
  dataToInput = async (d) => {
    let data = { ...d };

    const quotingBuilder = data.quotingBuilder.filter((e) => e !== undefined);
    delete data.quotingBuilder;
    const quotingRestorer = data.quotingRestorer.filter((e) => e !== undefined);
    delete data.quotingRestorer;

    const buildingPortfolio = {
      portfolioType: "Building",
      excessValue: data["Portfolios_Building_ExcessValue"],
      sumInsured: data["Portfolios_Building_SumInsured"],
      toCollectExcess: data["Portfolios_Building_ToCollectExcess"],
      quotingSupplierIds: quotingBuilder.map((e) => parseInt(e)),
    };

    const contentsPortfolio = {
      portfolioType: "Contents",
      sumInsured: data["Portfolios_Contents_SumInsured"],
      excessValue: data["Portfolios_Contents_ExcessValue"],
      toCollectExcess: data["Portfolios_Contents_ToCollectExcess"],
    };

    const restorationPortfolio = {
      portfolioType: "Restoration",
      sumInsured: data["Portfolios_Restoration_SumInsured"],
      excessValue: data["Portfolios_Restoration_ExcessValue"],
      toCollectExcess: data["Portfolios_Restoration_ToCollectExcess"],
      quotingSupplierIds: quotingRestorer.map((e) => parseInt(e)),
    };

    const portfolios = [];

    if (data["Portfolios_Building_ExcessValue"] !== "N/A")
      portfolios.push(buildingPortfolio);
    if (data["Portfolios_Contents_ExcessValue"] !== "N/A")
      portfolios.push(contentsPortfolio);
    if (data["Portfolios_Restoration_ExcessValue"] !== "N/A")
      portfolios.push(restorationPortfolio);

    delete data["Portfolios_Building_ExcessValue"];
    delete data["Portfolios_Building_SumInsured"];
    delete data["Portfolios_Building_ToCollectExcess"];

    delete data["Portfolios_Contents_ExcessValue"];
    delete data["Portfolios_Contents_SumInsured"];
    delete data["Portfolios_Contents_ToCollectExcess"];

    delete data["Portfolios_Restoration_ExcessValue"];
    delete data["Portfolios_Restoration_ToCollectExcess"];
    delete data["Portfolios_Restoration_SumInsured"];

    // FIXME: parseInt Start
    portfolios.forEach((e, i) => {
      if (!e) return;
      Object.keys(e).forEach((key, j) => {
        if (key === "sumInsured" || key === "excessValue") {
          portfolios[i][key] = parseInt(portfolios[i][key]);
        }
      });
    });
    [
      "casemanagerId",
      "externalLossAdjusterId",
      "eventTypeId",
      "catCodeId",
      "policyTypeId",
      "policyCoverId",
      "brcId",
    ].forEach((e) => {
      if (!data[e]) return;
      data[e] = parseInt(data[e]);
    });

    // parse all the data to string
    // Object.keys(data)
    //   .map(x => data[x] = String(data[x]))

    data = removeEmpty(data);

    return {
      input: {
        ...data,
        portfolios,
      },
      where: {
        id: this.props.meta.claim.claimId,
      },
    };
  };
}

export default withStyles((theme) => ({
  root: {
    marginTop: 0,
  },
  card: {
    marginTop: theme.spacing.unit,
  },
  supplierList: {
    height: "12rem",
    overflow: "auto",
  },
}))(JobInfoPresentation);

const mapJobInfoInitialData = (claim) => ({
  // claim details card 1st
  agentId: claim.agent && claim.agent.companyId ? claim.agent.companyId : null,
  incidentDate: moment(claim.incidentDetail.incidentDate, "DD/MM/YYYY"),
  refNumber: claim.refNumber,
  contentsRefNum: claim.contentsRefNum,
  hold: claim.incidentDetail.hold,
  eventTypeId: claim.incidentDetail.eventType.eventTypeId || null,
  catCodeId: claim.incidentDetail.cATCode
    ? claim.incidentDetail.cATCode.cATCodeId
    : null,
  policyTypeId: claim.policyType ? claim.policyType.policyTypeId : null,
  policyCoverId: claim.policyCover ? claim.policyCover.policyCoverId : null,
  riskname: claim.riskname || null,
  additionalRefNumber: claim.additionalRefNumber,
  homeAssessorId: claim.homeAssessor ? claim.homeAssessor.assesorId : null,
  // claim details card 2nd
  casemanagerId: claim.caseManager ? claim.caseManager.managerId : null,
  externalLossAdjusterId: claim.externalLossAdjuster
    ? claim.externalLossAdjuster.companyId
    : null,
  Portfolios_Building_ExcessValue: Boolean(
    claim.building && claim.building.excessValue !== null
  )
    ? claim.building.excessValue
    : "N/A",
  Portfolios_Building_ToCollectExcess: Boolean(
    claim.building && claim.building.toCollectExcess !== null && claim.building
  )
    ? claim.building.toCollectExcess
    : null,
  Portfolios_Building_SumInsured: Boolean(claim.buildingSumInsured !== null)
    ? claim.buildingSumInsured
    : "N/A",
  Portfolios_Contents_ExcessValue: Boolean(
    claim.contents && claim.contents.excessValue !== null
  )
    ? claim.contents.excessValue
    : "N/A",
  Portfolios_Contents_ToCollectExcess: Boolean(
    claim.contents && claim.contents.toCollectExcess !== null && claim.contents
  )
    ? claim.contents.toCollectExcess
    : null,
  Portfolios_Contents_SumInsured: Boolean(
    claim.contents && claim.contents.sumInsured !== null
  )
    ? claim.contents.sumInsured
    : "N/A",
  Portfolios_Restoration_ExcessValue: Boolean(
    claim.restoration && claim.restoration.excessValue !== null
  )
    ? claim.restoration.excessValue
    : "N/A",
  Portfolios_Restoration_ToCollectExcess: Boolean(
    claim.restoration &&
      claim.restoration.toCollectExcess !== null &&
      claim.restoration
  )
    ? claim.restoration.toCollectExcess
    : null,
  Portfolios_Restoration_SumInsured: Boolean(
    claim.restoration && claim.restoration.sumInsured !== null
  )
    ? claim.restoration.sumInsured
    : "N/A",
  brcId: claim.brc && claim.brc.managerId,

  // client info card 1st
  insuredSalutation: claim.insured.salutation,
  insuredName: claim.insured.name,
  riskAddressLine1: claim.incidentDetail
    ? claim.incidentDetail.riskAddress.line1
    : null,
  riskAddressSuburb: claim.incidentDetail
    ? claim.incidentDetail.riskAddress.suburb
    : null,
  riskAddressState: claim.incidentDetail
    ? claim.incidentDetail.riskAddress.state
    : null,
  riskAddressPostcode: claim.incidentDetail
    ? claim.incidentDetail.riskAddress.postcode
    : null,
  insuredPhone1: claim.insured.phone1,
  insuredPhone2: claim.insured.phone2,
  insuredPhone3: claim.insured.phone3,
  insuredFax: claim.insured.fax,
  // client info card 2nd
  insuredEmail: claim.insured.email,
  requireCustomLogin: claim.requireCustomLogin,
  customLoginEmail: claim.customLoginEmail,
  habitableProperty: claim.incidentDetail.habitableProperty,
  asbestos: claim.incidentDetail.asbestos,
  category: claim.incidentDetail.category || null,
  vulnerableMember: claim.incidentDetail.vulnerableMember,

  // postal address card
  postalAddressLine1: claim.insured.postalAddress.line1
    ? claim.insured.postalAddress.line1
    : null,
  postalAddressSuburb: claim.insured.postalAddress.suburb
    ? claim.insured.postalAddress.suburb
    : null,
  postalAddressState: claim.insured.postalAddress.state
    ? claim.insured.postalAddress.state
    : null,
  postalAddressPostcode: claim.insured.postalAddress.postcode
    ? claim.insured.postalAddress.postcode
    : null,

  // tenant info card
  tenantName: claim.tenantDetails.name || "",
  tenantPhone1: claim.tenantDetails.phone1 || "",
  tenantPhone2: claim.tenantDetails.phone2 || "",
  tenantPhone3: claim.tenantDetails.phone3 || "",

  quotingBuilder: [],
  quotingRestorer: [],

  claimDescription: markupToContext(claim.claimDescription),
});

const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
    else if (
      obj[key] === undefined ||
      obj[key] === "null" ||
      obj[key] === "undefined"
    )
      delete obj[key];
  });
  return obj;
};
// || obj[key] === ''
