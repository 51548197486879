import React from 'react'

import { Link } from 'react-router-dom'
import useReactRouter from 'use-react-router'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'

const TabsBar = ({ routes, classes }) => {
  const { /* history, */ location } = useReactRouter()
  const [ selectedIndex, setSelectedIndex ] = React.useState(0)

  React.useEffect(() => {
    const path = location.pathname.replace('/claim', '')

    const match = routes
      .map((route, index) => route.path === path ? index : null)
      .filter(x => x !== null)

    setSelectedIndex(match)
  }, [ routes.length, location.pathname ])

  return (
    <Grid container>
      <Grid item xs>
        <Paper className={classes.paper}>
          <Tabs
            value={parseInt(selectedIndex)}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='on'
          >
            {routes.map((route, key) => {
              if (!route.path) return null
              return (
                <Tab
                  label={route.label} component={Link} key={key}
                  to={`/claim${route.path}${location.search}`}
                  className={classes.tab}
                />
              )
            })}
          </Tabs>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default withStyles(Theme => ({
  '@supports ( -moz-appearance:none )': {
    paper:{
      width :'100.2%'
    },
  },
  tab:{
    minWidth: '130px',
  },
}))(TabsBar)