/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClaimItem_restorer$ref: FragmentReference;
export type ClaimItem_restorer = {|
  +restoration: ?{|
    +jobSuppliers: ?$ReadOnlyArray<?{|
      +requestDate: ?any,
      +requestType: ?string,
      +quote: ?{|
        +total: ?number,
        +jobQuoteId: number,
        +quoteStatus: ?{|
          +statusName: ?string
        |},
      |},
    |}>,
    +claimStatus: ?{|
      +statusName: ?string
    |},
  |},
  +building: ?{|
    +authorisedSupplier: ?{|
      +companyName: string,
      +companyPhone1: ?string,
    |},
    +scopingSupplier: ?{|
      +companyName: string,
      +companyPhone1: ?string,
    |},
  |},
  +$refType: ClaimItem_restorer$ref,
|};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "statusName",
    "args": null,
    "storageKey": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "companyName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "companyPhone1",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "ClaimItem_restorer",
  "type": "ClaimJob",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "restoration",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimPortfolio",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "jobSuppliers",
          "storageKey": null,
          "args": null,
          "concreteType": "JobSupplier",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "requestDate",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "requestType",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "quote",
              "storageKey": null,
              "args": null,
              "concreteType": "JobQuote",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "total",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "jobQuoteId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": "quoteStatus",
                  "name": "quoteJobStatus",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ClaimStatus",
                  "plural": false,
                  "selections": (v0/*: any*/)
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "claimStatus",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimStatus",
          "plural": false,
          "selections": (v0/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "building",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimPortfolio",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "authorisedSupplier",
          "storageKey": null,
          "args": null,
          "concreteType": "Company",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "scopingSupplier",
          "storageKey": null,
          "args": null,
          "concreteType": "Company",
          "plural": false,
          "selections": (v1/*: any*/)
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '927710d7af17bc3950ba000fae94c8bc';
module.exports = node;
