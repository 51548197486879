import React from 'react'
import { useParams } from 'react-router-dom'
import { Paper, Grid, Button, IconButton, Fab } from '@material-ui/core'
import InfoBox from 'components/Mui/InfoBox'
import { Formik, Form } from 'formik'
import TextField from 'components/Formik/Input'
import GetAppIcon from '@material-ui/icons/GetApp'
import MuiCircularProgress from '@material-ui/core/CircularProgress'
import tokenRefresh from 'common/utils/tokenRefresh'

import { useMutation } from 'react-relay-mutation'
import { graphql } from 'react-relay'
import validFileName from 'valid-filename'
import { SpinnerRect } from 'components/Mui/Loader'

export default ({ roomInfo, contactInfo, items, setSelectedMedia }) => {
  const { mediaId } = useParams()
  const [photoUrl, setPhotoUrl] = React.useState()
  const [videoUrl, setVideoUrl] = React.useState()
  const [isTokenChange, setIsTokenChange] = React.useState(false)
  const [isDownloadAllLoading, setIsDownloadAllLoading] = React.useState(false)

  const item = items ? items.find(item => item.id === mediaId) : null
  const itemId = item ? item.id : null
  React.useEffect(() => {
    if (itemId) setSelectedMedia(itemId)
  }, [itemId])

  if (!item) return 'Media not found'

  const downloadAll = async items => {
    setIsDownloadAllLoading(true)
    const files = items
      .filter(({ type }) => type !== 'Video')
      .map(({ url }) => url)

    await downloadZip(files)
    setIsDownloadAllLoading(false)

    // items
    //   .filter(({ type }) => type !== 'Video')
    //   .forEach(({ url }, index) => {
    //     setTimeout(() => {
    //       download(url)
    //     }, 100 * index)
    //   })
  }
  // const string = JSON.stringify

  React.useEffect(() => {
    setPhotoUrl()
    setVideoUrl()
    if(mediaId && item.url){
      const downloadStream = async() => {
        const photoStream = await fetch(`${process.env.REACT_APP_API_HOST}/rest/video/download?string=${window.btoa(item.url)}`,
        {headers:{
          "Authorization": `Bearer ${localStorage.TOKEN}`
        }})
        if(photoStream.status === 401) {
          const response = await tokenRefresh()
          .catch(() => null)
          if(!response || (response && response.data && !response.data.userRenewToken)) {
            console.log('refresh token error')
          }
          const { accessToken, refreshToken } = response.data.userRenewToken
          localStorage.setItem('TOKEN', accessToken)
          localStorage.setItem('REFRESH_TOKEN', refreshToken)
          setIsTokenChange(!isTokenChange)
          return null
        }
        const blob = await photoStream.blob()
        const blobUrl = window.URL.createObjectURL(blob)
        const file = await fetch(blobUrl)
        .then(res => res.blob())
        .then(blob => new File([blob], `${Date.now()}.png`, { type: 'image/png' }))
        const fileUrl = window.URL.createObjectURL(file)
        item && item.type === 'Photo'? setPhotoUrl(fileUrl) : setVideoUrl(blobUrl)
      }
      downloadStream()
    }
  },[item.url, mediaId, isTokenChange])

  const downloadVideo = () => {
    const link = document.createElement('a')
      link.href = videoUrl
      link.setAttribute('download', `${item.url.substring(item.url.lastIndexOf('/')+1)}`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
  }

  return (
    <Paper style={{ padding: 24, height: '100%', overflow: 'auto' }}>
      <Grid container style={{ height: 'calc(100% - 70px)', width: '100%' }}>
        <Grid item xs={12} container alignItems='center' style={{zoom:0.9}}>
          <Grid item>
            {roomInfo && <RoomInfoForm roomInfo={roomInfo} />}
          </Grid>
          <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {item.type === 'Video'
              ? (
                <Button variant='contained' color='primary' onClick={downloadVideo}>
                  Download Video
                </Button>
              )
              : (
                <div style={{ position: 'relative' }}>
                  {isDownloadAllLoading && <MuiCircularProgress size="32px" style={{ position: 'absolute', right: '35%', bottom: '20%' }} />}
                    <Button variant='contained' color='primary' onClick={() => downloadAll(items)} disabled={isDownloadAllLoading}>
                      Download all
                    </Button>
                </div>
              )}
          </Grid>
        </Grid>
        <Grid item container xs={12} style={{zoom:0.9}}>
          <Grid item xs={6}>
            {<MediaUpdateForm key={item.id} {...item} />}
          </Grid>
          {contactInfo.email && (
            <Grid item xs={3}>
              <InfoBox label='Email' text={contactInfo.email} />
            </Grid>
          )}
          {contactInfo.phone && (
            <Grid item xs={3}>
              <InfoBox label='Phone' text={contactInfo.phone} />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} style={{ height: 'calc(100vh - 265px)' }}>
          {
            !videoUrl && !photoUrl
            ? <SpinnerRect />
            : item.type === 'Video'
            ? <video src={videoUrl} controls style={{ height: '100%', width: '100%' }} controlsList="nodownload" />
            : (
              <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              {photoUrl &&
                <a href={photoUrl} download={`${item.url.substring(item.url.lastIndexOf('/')+1)}`}>
                  <div style={{ position: 'relative' }}>
                    <img alt='ENData Claims' src={photoUrl} style={{ height: '100%', width: 'auto' }} />
                    <Fab
                        color='primary' style={{ position: 'absolute', right: 0, bottom: 5 }}
                        component='a'
                      >
                      <GetAppIcon />
                    </Fab>
                  </div>
                </a>
              }
              </div>
            )
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

const RoomUpdateMutation = graphql`
  mutation MediaRootRoomUpdateMutation($where: RoomUpdateWhereInput!, $data: RoomUpdateDataInput!) {
    updateRoom(where: $where, data: $data) {
      id
      case {
        referenceNumber
        name
        address
      }
    }
  }
`
const RoomInfoForm = ({ roomInfo: { id, ...roomInfo } }) => {
  const [updateRoom] = useMutation(RoomUpdateMutation)
  const { roomId } = useParams()

  return (
    <Formik
      initialValues={roomInfo}
      onSubmit={async (values, { setSubmitting }) => {
        const res = await updateRoom({ variables: {
          where: { roomId },
          data: values
        }})
        setSubmitting(false)
      }}
    >
      <Form>
        <Grid container spacing={8}>
          {[
            { name: 'referenceNumber', label: 'Reference number' },
            { name: 'name', label: 'Client Name' },
            { name: 'address', label: 'Address' },
          ].map(({ name, label }) => (
            <Grid item xs={3} key={name}>
              <TextField name={name} label={label} />
            </Grid>
          ))}
          <Grid item xs={3} >
            <Button type='submit' variant='outlined' color='primary'>
              Save
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

const MediaUpdateMutation = graphql`
  mutation MediaRootMediaUpdateMutation($where: MediaUpdateWhereInput!, $data: MediaUpdateDataInput!) {
    mediaUpdate(where: $where, data: $data) {
      id
      name
      url
    }
  }
`
const MediaUpdateForm = ({ id, name }) => {
  const [updateMedia] = useMutation(MediaUpdateMutation)

  return (
    <Formik
      initialValues={{
        name: name || ''
      }}
      validate={values => {
        let errors = {}

        if (!validFileName(values.name)) errors.name = 'File name is invalid!'

        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await updateMedia({
          variables: {
            where: { mediaId: id },
            data: values
          }
        })
        setSubmitting(false)
      }}
    >
      <Form>
        <Grid container spacing={8}>
          {[
            { name: 'name', label: 'Media Name' },
          ].map(({ name, label }) => (
            <Grid item xs={3} key={name}>
              <TextField name={name} label={label} />
            </Grid>
          ))}
          <Grid item xs={3} >
            <Button type='submit' variant='outlined' color='primary'>
              Save
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

const { REACT_APP_API_HOST } = process.env
const downloadZip = async (files) => {
  const snackbar = window[Symbol.for('__snackbar')]
  const res = await fetch(`${REACT_APP_API_HOST}/rest/video/photozip?string=${window.btoa(JSON.stringify(files))}`, {
    headers:{
      "Authorization": `Bearer ${localStorage.TOKEN}`
    }
  })
  if(res.status === 401 ) {
    snackbar.toggleOpen({message: 'Access denied'})
    return null
  } else if (
    res.status === 404
  ) {
    snackbar.toggleOpen({message: 'Record not found'})
    return null
  }
  const blob = await res.blob()
  const blobUrl = window.URL.createObjectURL(blob)
  downloadZipFile(blobUrl)
}

const downloadZipFile = blobUrl => {
  const link = document.createElement('a')
    link.href = blobUrl
    link.setAttribute('download', `photos.zip`)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
}
