/**
 * @flow
 * @relayHash 7220808974cb7c1f2353fedb7ed77609
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessRole = "Adjustor" | "Administrator" | "Agent" | "Builder" | "ContentSupplier" | "Customer" | "Estimator" | "Insurance" | "InternalAB" | "LossAdjuster" | "MultiTenderAssessor" | "Restorer" | "Specialist" | "SystemAdmin" | "UNDEFINED" | "%future added value";
export type Server = "AIG" | "ALL" | "ALZ" | "DEV" | "ENDATA" | "IAG" | "QBE" | "RAC" | "RACQ" | "RACT" | "SIT" | "TSETPVT" | "UAT" | "WBC" | "%future added value";
export type UserCredential = {|
  userLogin: string,
  password: string,
|};
export type LoginMutationVariables = {|
  input?: ?UserCredential
|};
export type LoginMutationResponse = {|
  +login: ?{|
    +token: string,
    +accessToken: string,
    +refreshToken: string,
    +server: ?Server,
    +user: ?{|
      +userName: string,
      +userRole: ?AccessRole,
      +hasMultipleAccess: boolean,
      +preference: ?{|
        +applicationFont: string,
        +applicationLanguage: string,
        +applicationTheme: string,
      |},
      +company: ?{|
        +customisation: ?{|
          +applicationAlias: ?string,
          +applicationFavicon: ?string,
          +applicationLogo: ?string,
          +displayCompanyLogo: ?string,
          +logoHeight: number,
        |}
      |},
      +privileges: ?{|
        +menus: ?$ReadOnlyArray<?{|
          +nodeName: string
        |}>
      |},
    |},
  |}
|};
export type LoginMutation = {|
  variables: LoginMutationVariables,
  response: LoginMutationResponse,
|};
*/


/*
mutation LoginMutation(
  $input: UserCredential
) {
  login: userAuthenticate(input: $input) {
    token
    accessToken
    refreshToken
    server
    user {
      userName
      userRole
      hasMultipleAccess
      preference {
        applicationFont
        applicationLanguage
        applicationTheme
      }
      company {
        customisation {
          applicationAlias
          applicationFavicon
          applicationLogo
          displayCompanyLogo
          logoHeight
        }
        id
      }
      privileges {
        menus {
          nodeName
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UserCredential",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UserCredential"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "token",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accessToken",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "refreshToken",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "server",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userRole",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasMultipleAccess",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "preference",
  "storageKey": null,
  "args": null,
  "concreteType": "UserPreference",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationFont",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationLanguage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationTheme",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customisation",
  "storageKey": null,
  "args": null,
  "concreteType": "CompanyCustomisation",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationAlias",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationFavicon",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "applicationLogo",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "displayCompanyLogo",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "logoHeight",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "privileges",
  "storageKey": null,
  "args": null,
  "concreteType": "UserPrivileges",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "menus",
      "storageKey": null,
      "args": null,
      "concreteType": "PrivilegeMenu",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "nodeName",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LoginMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "login",
        "name": "userAuthenticate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserValidation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserProfile",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "company",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyProfile",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ]
              },
              (v11/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LoginMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "login",
        "name": "userAuthenticate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserValidation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserProfile",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "company",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyProfile",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              (v11/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "LoginMutation",
    "id": null,
    "text": "mutation LoginMutation(\n  $input: UserCredential\n) {\n  login: userAuthenticate(input: $input) {\n    token\n    accessToken\n    refreshToken\n    server\n    user {\n      userName\n      userRole\n      hasMultipleAccess\n      preference {\n        applicationFont\n        applicationLanguage\n        applicationTheme\n      }\n      company {\n        customisation {\n          applicationAlias\n          applicationFavicon\n          applicationLogo\n          displayCompanyLogo\n          logoHeight\n        }\n        id\n      }\n      privileges {\n        menus {\n          nodeName\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd8602a67f79e1bf1b767a4d8f2a54b93';
module.exports = node;
