import React, { Component, createRef, Fragment } from 'react'
import { MDCTextField } from '@material/textfield'
import { MDCTextFieldHelperText } from '@material/textfield/helper-text'

class TextField extends Component {
	element = createRef()
	helper = createRef()

	componentDidMount = () => {
		new MDCTextField(this.element.current)
		new MDCTextFieldHelperText(this.helper.current)
	}

	render() {
		// filled
		const { id, className, type = 'text', label, helperText } = this.props
		const { ripple, fullwidth, outline } = this.props
		const { leadIcon, trailIcon, style } = this.props
		//html props
		let { 
			disabled, required, 
			min, max, minLength, maxLength, 
			defaultValue, value,
			onChange, onClick, 
			aboveLabel, placeholder, autoFocus,
			textArea, rows, cols,
			dense
		} = this.props
		
		if(type === 'dateSelect') value = value.toLocaleDateString()
		if(type === 'dateRangeSelect') value = value.map(val => val.toLocaleDateString()).join(' - ')
		return (
			<Fragment>
				{aboveLabel && <div className='textFieldAboveLabel'>{aboveLabel}</div>}
				<div ref={this.element} onClick={onClick}
					style={style}
					className={`
						${fullwidth ? fullwidthClass : ''}
						${outline ? outlineClass : ''}
						${leadIcon ? leadingIconClass : ''}
						${trailIcon ? trailingIconClass : ''}
						${disabled ? disabledClass : ''}
						${dense ? denseClass : ''}
						${className ? className : ''}
						${textArea ? textAreaClass : ''}
						mdc-text-field	
					`}
				>
					{leadIcon && <i className='material-icons mdc-text-field__icon mdc-text-field__leading-icon'>{leadIcon}</i>}
					{!textArea 
						? (
							<input id={id} type={type} className='mdc-text-field__input'
								required={required} disabled={disabled}
								min={min} max={max} autoFocus={autoFocus}
								minLength={minLength} maxLength={maxLength}
								defaultValue={defaultValue}
								aria-controls={helperText ? `${id}-helper` : null}
								aria-describedby={helperText ? `${id}-helper` : null}
								onChange={onChange} value={value}
								placeholder={placeholder}
							/>	
						)
						: (
							<textarea id={id} type={type} className='mdc-text-field__input'
								required={required} disabled={disabled}
								min={min} max={max} autoFocus={autoFocus}
								minLength={minLength} maxLength={maxLength}
								defaultValue={defaultValue}
								aria-controls={helperText ? `${id}-helper` : null}
								aria-describedby={helperText ? `${id}-helper` : null}
								onChange={onChange} value={value}
								placeholder={placeholder}
								rows={rows} cols={cols}
							/>	
						)
					}
					{trailIcon && <i className='material-icons mdc-text-field__icon' tabIndex='0' role='button'>{trailIcon}</i>}

					{label && !outline && <label  htmlFor={id} className={`${defaultValue ? labelFloatAboveClass : ''} mdc-floating-label`}>{label}</label>}
					{ripple && !outline && <div className='mdc-line-ripple'></div>}

					{outline && (
						<div className='mdc-notched-outline'>
							<div className='mdc-notched-outline__leading'></div>
							<div className='mdc-notched-outline__notch'>
								<label htmlFor={id} className='mdc-floating-label'>{label}</label>
							</div>
							<div className='mdc-notched-outline__trailing'></div>
						</div>	
					)}
				</div>
				{helperText && (
					<p ref={this.helper} id={`${id}-helper`} className='mdc-text-field-helper-text'>
						{helperText}	
					</p>	
				)}
			</Fragment>
		)
	}
}

export default TextField

const fullwidthClass = 'mdc-text-field--fullwidth'
const outlineClass = 'mdc-text-field--outlined'

const leadingIconClass = 'mdc-text-field--with-leading-icon'
const trailingIconClass = 'mdc-text-field--with-trailing-icon'

const disabledClass = 'mdc-text-field--disabled'

const labelFloatAboveClass = 'mdc-floating-label--float-above'

const textAreaClass = 'mdc-text-field--textarea'

const denseClass = 'mdc-text-field--dense'