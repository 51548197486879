import React from 'react'
import { useRouteMatch, useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Paper, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Divider, IconButton } from '@material-ui/core'
import PhotoIcon from '@material-ui/icons/Photo'
import DuoIcon from '@material-ui/icons/Duo'
// import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutation, graphql } from 'relay-hooks'

export default ({ items, selectedMedia }) => {
  const { url } = useRouteMatch()
  const { push } = useHistory()
  const snackbar = window[Symbol.for('__snackbar')]
  const [commit] = useMutation(
    graphql`
      mutation MediaListDeleteMutation($id: ID!) {
        mediaDelete(id: $id) {
          id
          isDeleted
          deletedAt
        }
      }
    `,
    {
      configs: [{
        type: 'NODE_DELETE',
        deletedIDFieldName: 'id'
      }]
    }
  )
  const handleRoomDelete = async id => {
    const confirm = await snackbar.toggleOpen({
      message: 'Please confirm to delete the item',
      firstButton: 'Delete',
      secondButton: 'Cancel',
      type: 'confirm'
    })
    if(confirm.ok) {
      await push(url)
      const res = await commit({ variables: { id } })
      if (res) snackbar.toggleOpen({ message: 'Deleted' })
    }
  }

  return (
    <Paper elevation={5} style={{ height: '100%', overflow: 'auto' }}>
      <List>
        {items.map(({ id, type, name }, index) => (
          <React.Fragment key={id}>
            <ListItem
              button
              selected={selectedMedia === id}
              component={Link}
              to={`${url}/${id}`}
            >
              <ListItemIcon>
                {type === 'Photo' ? <PhotoIcon /> : <DuoIcon />}
              </ListItemIcon>
              <ListItemText primary={name || `${type} ${index + 1}`} />
              {/* {selectedMedia === id && (
                <ListItemSecondaryAction>
                  <ChevronRightIcon />
                </ListItemSecondaryAction>
              )} */}
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleRoomDelete(id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Paper>
  )
}
