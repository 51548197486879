import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import CommunicationButton from "./CommunicationButton";
// import DailyTasksButton from './DailyTasksButton'
import MenuButton from "./MenuButton";
import { tryParse } from "common/utils";
import AnnouncementsBell from "./AnnouncementsBell";

let { REACT_APP_COMPANY_PATH } = process.env;

// const accessPaths = JSON.parse(localStorage.ACCESS_PATHS)

// const applicationCustom = JSON.parse(localStorage.APPLICATION_CUSTOM)

class RightArea extends Component {
  render() {
    const { classes, menu } = this.props;
    // const accessPaths = tryParse(localStorage.ACCESS_PATHS)
    const applicationCustom = tryParse(localStorage.APPLICATION_CUSTOM);
    return (
      <div className={classes.root}>
        {/* { accessPaths.communicationtasks && <DailyTasksButton classes={classes}/> } */}
        {menu.communication.isDisplay && (
          <CommunicationButton icon={menu.communication.icon} />
        )}
        {menu.announcementsNotification.isDisplay && (
          <AnnouncementsBell
            style={{ paddingBottom: 0 }}
            icon={menu.announcementsNotification.icon}
          />
        )}
        {menu.setting.isDisplay && (
          <MenuButton
            icon={menu.setting.icon}
            isShowModernPortal={menu.modernPortal.isDisplay}
          />
        )}
        {applicationCustom && applicationCustom.logo && (
          <img
            className={classes.companyImage}
            alt="endata"
            src={`${REACT_APP_COMPANY_PATH}/${applicationCustom.logo}`}
          />
        )}
        <img
          className={classes.endataImage}
          alt="endata"
          src={`${REACT_APP_COMPANY_PATH}/endata/logo.png`}
        />
      </div>
    );
  }
}

export default withStyles((theme) => ({
  root: {
    height: "100%",
    // marginLeft: 'auto',
    alignItems: "center",
    display: "flex",
  },
  companyImage: {
    height: "calc(100% - 4px)",
    borderRadius: "6px",
    padding: "2px",
  },
  endataImage: {
    height: "calc(100% - 4px)",
    borderRadius: "6px",
    padding: "2px",
  },
}))(RightArea);
