/**
 * @flow
 * @relayHash 7fd3bb31c840a9eb07edc9276d0c3b1f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DataDisplay = "ChartBar" | "ChartGraph" | "ChartLine" | "ChartProgressBar" | "Text" | "%future added value";
export type DataSense = "Commendatory" | "Derogatory" | "Neutral" | "%future added value";
export type DataUnit = "Currency" | "Number" | "Percentage" | "%future added value";
export type DateRangeOption = "Annual" | "Daily" | "FinancialYear" | "Month" | "Monthly" | "Quarter" | "Today" | "UnDefined" | "Week" | "Weekly" | "Yearly" | "%future added value";
export type JobRecommendationStatus = "NotRecommended" | "Recommended" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type WidgetViewName = "ChartJs" | "DataDouble" | "DataSingle" | "JobTasks" | "KPIGear" | "ListCheck" | "ListData" | "Messages" | "UnDefined" | "%future added value";
export type DashboardWidgetIndex = {|
  dashboardId: number,
  filter?: ?CaseReportFilter,
|};
export type CaseReportFilter = {|
  insurers?: ?$ReadOnlyArray<string>,
  managers?: ?$ReadOnlyArray<string>,
  managerTeams?: ?$ReadOnlyArray<?string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  agents?: ?$ReadOnlyArray<string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  buildingSuppliers?: ?$ReadOnlyArray<string>,
  contentsSuppliers?: ?$ReadOnlyArray<string>,
  restorationSuppliers?: ?$ReadOnlyArray<string>,
  buildingStatus?: ?$ReadOnlyArray<string>,
  contentsStatus?: ?$ReadOnlyArray<string>,
  restorationStatus?: ?$ReadOnlyArray<string>,
  lossAdjusterStatus?: ?$ReadOnlyArray<string>,
  suppliers?: ?$ReadOnlyArray<string>,
  status?: ?$ReadOnlyArray<string>,
  feeTypeIds?: ?$ReadOnlyArray<string>,
  invoiceStatus?: ?$ReadOnlyArray<string>,
  variationStatus?: ?$ReadOnlyArray<string>,
  kpiCategories?: ?$ReadOnlyArray<string>,
  regions?: ?$ReadOnlyArray<?string>,
  dateRange?: ?DateRangeOption,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  states?: ?$ReadOnlyArray<?StateEnum>,
  recommendationStatus?: ?$ReadOnlyArray<JobRecommendationStatus>,
  createDateFrom?: ?any,
  createDateTo?: ?any,
|};
export type DashboardKpiQueryVariables = {|
  where: DashboardWidgetIndex
|};
export type DashboardKpiQueryResponse = {|
  +dashboardWidgets: ?$ReadOnlyArray<?{|
    +title: string,
    +viewName: ?WidgetViewName,
    +labels: $ReadOnlyArray<?string>,
    +description: ?string,
    +data: ?$ReadOnlyArray<?{|
      +dataSetName: string,
      +internalFilterName: ?string,
      +legend: ?string,
      +valueDisplay: ?DataDisplay,
      +valueGroup: number,
      +valueUnit: ?DataUnit,
      +valueSense: ?DataSense,
      +values: ?$ReadOnlyArray<?number>,
    |}>,
  |}>
|};
export type DashboardKpiQuery = {|
  variables: DashboardKpiQueryVariables,
  response: DashboardKpiQueryResponse,
|};
*/


/*
query DashboardKpiQuery(
  $where: DashboardWidgetIndex!
) {
  dashboardWidgets(where: $where) {
    title
    viewName
    labels
    description
    data {
      dataSetName
      internalFilterName
      legend
      valueDisplay
      valueGroup
      valueUnit
      valueSense
      values
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "DashboardWidgetIndex!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "DashboardWidgetIndex!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "labels",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "data",
  "storageKey": null,
  "args": null,
  "concreteType": "WidgetData",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dataSetName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "internalFilterName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legend",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "valueDisplay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "valueGroup",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "valueUnit",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "valueSense",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "values",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardKpiQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dashboardWidgets",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Widget",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardKpiQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dashboardWidgets",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Widget",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DashboardKpiQuery",
    "id": null,
    "text": "query DashboardKpiQuery(\n  $where: DashboardWidgetIndex!\n) {\n  dashboardWidgets(where: $where) {\n    title\n    viewName\n    labels\n    description\n    data {\n      dataSetName\n      internalFilterName\n      legend\n      valueDisplay\n      valueGroup\n      valueUnit\n      valueSense\n      values\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '948085f31aab964628a69c7d333d8b27';
module.exports = node;
