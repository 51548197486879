import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation MakeSafeInitialCallMutation($where: ENDataPortfolioKey!) {
    claimMakeSafeInitialCall(where: $where) {
      messages
      success
      result {
        makeSafeProgress {
          initialCallMade
        }
      }
    }
  }
`

export default (variables, id) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      updater: store => {
        const success = store.getRootField('claimMakeSafeInitialCall')
        .getValue('success')
        if(success) {
          const claim = store.get(id)
          const makeSafeProgress = claim.getLinkedRecord(variables.where.portfolioType.toLowerCase())
            .getLinkedRecord('makeSafeProgress')
          
          const newInitialCallMade = store.getRootField('claimMakeSafeInitialCall')
            .getLinkedRecord('result')
            .getLinkedRecord('makeSafeProgress')
            .getValue('initialCallMade')

          makeSafeProgress.setValue(newInitialCallMade, 'initialCallMade')
        }
      },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.claimMakeSafeInitialCall.success,
          messages: res.claimMakeSafeInitialCall.messages
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}