import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
// import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation ContentQuoteUpdateMutation(
    $where: ENDataEntityKey!,
    $input: JobQuoteItemUpdate!
  ) {
    updateJobContentItem(where: $where, input: $input) {
      success
      messages
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.updateJobContentItem.success,
          messages: res.updateJobContentItem.messages
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}
