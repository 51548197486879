import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation QuoteInitialCallContentMutation($where: ENDataEntityKey!) {
    jobContentInitialCall(where: $where) {
      messages
      success
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: res => {
        resolve({
          messages: res.jobContentInitialCall.messages,
          success: res.jobContentInitialCall.success,
        })
      },
      onError: errors => resolve({
        messages: ['Unable to complete this action. Please try again!']
      })
    })
  })
}
