import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import classNames from 'clsx'

class Button extends Component {
  render() {
    const {
      classes, className,
      onClick,
      labelClass, leadIconClass, trailIconClass,
      label, leadIcon, leadComp, trailIcon,
      fullWidth, viewMode,
      ...rest
    } = this.props
    return (
      <MuiButton
        onClick={onClick}
        className={classNames(
          className,
          classes.button,
          {[classes.fullWidth]: fullWidth}
        )}
        {...rest}
      >
        {leadIcon && <Icon className={`${leadIconClass ? leadIconClass : ''} ${classes.leadIcon}`}>{leadIcon}</Icon>}
        {leadComp && React.cloneElement(leadComp)}
        <span className={`${labelClass ? labelClass : ''} ${classes.label}`}>{label}</span>
        {trailIcon && <Icon className={`${trailIconClass ? trailIconClass : ''} ${classes.trailIcon}`}>{trailIcon}</Icon>}
      </MuiButton>
    )
  }
}

export default withStyles(theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  // label: {
  //   fontSize: '1rem',
  // },
  fullWidth: {
    width: 'calc(100% - 8px)'
  },
  leadIcon: {
    marginRight: theme.spacing.unit,
  },
  trailIcon: {
    marginLeft: theme.spacing.unit,
  },
}))(Button)
