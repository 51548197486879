import moment from 'moment'

export default (date, type) => {
  if(!Boolean(date)) return null
  switch(type) {
    case 'date': return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    case 'time': return moment(date, 'DD/MM/YYYY HH:mm:ss A').format('HH:mm:ss')

    default: return moment(date, 'DD/MM/YYYY HH:mm:ss A').format('YYYY-MM-DDTHH:mm:ss')
  }
}
