import React from 'react'

import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'

import VideoCallLoading from './components/VideoCallLoading'
import VideoCallImageBar from './components/VideoCallImageBar'
import VideoCallActionBar from './components/VideoCallActionBar'

const VideoCall = ({
  classes,
  loading, isOwner,
  ownerMessage, guestMessage,
  localVideoRef, remoteVideoRef,
  actions,
  photos
}) => {
  return (
    <div className={clsx(
      classes.root,
      { [classes.rootGuest]: !isOwner }
    )}>
      <VideoCallLoading
        isOwner={isOwner}
        ownerMessage={ownerMessage}
        guestMessage={guestMessage}
        loading={loading}
      />
      <VideoCallImageBar
        isOwner={isOwner}
        photos={photos}
      />

      <div className={clsx(classes.videoWrapper, {[classes.videoWrapperGuest]: !isOwner})}>
        <video ref={localVideoRef} autoPlay playsInline
          className={clsx(
            { [classes.subVideo]: isOwner },

            { [classes.mainVideoGuest]: !isOwner },
          )}
        />
        <video ref={remoteVideoRef} autoPlay playsInline
          className={clsx(
            { [classes.subVideo]: !isOwner },

            { [classes.mainVideo]: isOwner },
          )}
        />
      </div>

      <VideoCallActionBar actions={actions} />
    </div>
  )
}

export default withStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 55px)',
    display: 'flex'
  },
  rootGuest: {
    height: '100vh !important'
  },

  videoWrapper: {
    height: '100%',
    width: 'calc(100% - 200px)',
    background: 'black',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoWrapperGuest: {
    width: '100% !important',
  },

  mainVideo: {
    height: '100%',
    width: 'auto',
    objectFit: 'inherit',
    // display: 'block',
    // margin: '0 auto'
  },
  mainVideoGuest: {
    height: '100%',
    width: '100%',
    objectFit: 'inherit',
  },

  subVideo: {
    position: 'absolute',
    top: 0, right: 0,
    height: '10vmax'
  },
}))(VideoCall)