import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import { moneyFormat } from 'common/utils'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import DashboardInfo from './DashboardInfo'
import DashboardDialog from './DashboardDialog'


const MuiCard=({ classes, where, data:{icon, name, items, orientation, groupDescription} })=>{
  const unicodeToChar = (text) => {
    return text.replace(/\\u[\dA-F]{4}/gi, 
           function (match) {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
           });
 }
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes[`${orientation}-card`]}>
        <div className={classes.header}>
          <Typography variant="h5" color="textSecondary" style={{marginRight:'10px'}}>
          {name}
          </Typography>
          <i className='material-icons'>{unicodeToChar(icon)}</i>
          <DashboardInfo groupDescription={groupDescription}/>
        </div>
        <div className={classes[`${orientation}-wrapper`]}>
          {items.map(e=>
            <div className={classes[`${orientation}-content`]}>
              <Typography color="textSecondary" gutterBottom className={classes[`${orientation}-word`]}>
              {e.name}
              </Typography>
              {<DashboardDialog cardId={e.id} where={where} className={classes[`${orientation}-button`]} 
                title={`${name} - ${e.name}`}
                style={{backgroundColor:e.color, color: 'white'}} label={
                e.value?e.unit==='Currency'?moneyFormat(e.value):
                e.unit==='Percentage'?`${parseFloat((e.value*100).toFixed(10))}%`:
                e.value:'-'
              }/>
            }
            </div>
          )}      
        </div>  
      </CardContent>
      </Card>
  );
}

export default withStyles(()=>({
  root: {
    width: '300px',
    minHeight: 250,
    backgroundColor: '#f8f4f2',
    height: '100%',
    position: 'relative'
  },
  info: {
    position: 'absolute',
    right: '10px',
    top: '10px'
  },
  header: {
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  'Horizontal-wrapper': {
    display:'flex',
    alignItems:'center',
    flexDirection: 'column'
  },
  'Horizontal-content':{
    display:'flex',
    alignItems:'center',
    width:'250px',
  },
  'Horizontal-word':{
    marginRight:'10px',
    width: '120px'
  },
  'Horizontal-button':{
    backgroundColor: '#c6cee2',
    color:'black'
  },  
  'Vertical-wrapper': {
    display:'flex',
    justifyContent:'space-around',
    flexWrap: 'wrap'
  },
  'Vertical-content':{
      margin:'5px',
      textAlign:'center'
  },
  'Vertical-word':{
    textAlign:'center'
  },
  'Vertical-button':{
    backgroundColor: '#b9c9fb',
    color:'black'
  },
  
}))(MuiCard)