/**
 * @flow
 * @relayHash ffdaa30233e4ab460b0758eecedca092
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ClaimJobScopeTypeIndex = {|
  claimId: string,
  portfolioType: PortfolioType,
|};
export type ReportSummaryInfoQueryVariables = {|
  where: ClaimJobScopeTypeIndex
|};
export type ReportSummaryInfoQueryResponse = {|
  +claimJobScopeType: ?{|
    +id: string,
    +items: ?$ReadOnlyArray<?{|
      +itemName: string,
      +marginRate: number,
      +rawSubtotal: number,
      +calculateAmount: number,
      +margin: number,
      +notCalculateAmount: number,
      +subtotal: number,
      +gst: number,
      +total: number,
    |}>,
    +total: {|
      +itemName: string,
      +marginRate: number,
      +rawSubtotal: number,
      +calculateAmount: number,
      +margin: number,
      +notCalculateAmount: number,
      +subtotal: number,
      +gst: number,
      +total: number,
    |},
    +fields: ?{|
      +marginRate: {|
        +label: ?string,
        +order: number,
        +name: string,
        +isDisplay: boolean,
      |},
      +gst: {|
        +label: ?string,
        +order: number,
        +name: string,
        +isDisplay: boolean,
      |},
      +itemName: {|
        +label: ?string,
        +order: number,
        +name: string,
        +isDisplay: boolean,
      |},
      +margin: {|
        +label: ?string,
        +order: number,
        +name: string,
        +isDisplay: boolean,
      |},
      +notCalculateAmount: {|
        +label: ?string,
        +order: number,
        +name: string,
        +isDisplay: boolean,
      |},
      +calculateAmount: {|
        +label: ?string,
        +order: number,
        +name: string,
        +isDisplay: boolean,
      |},
      +subtotal: {|
        +label: ?string,
        +order: number,
        +name: string,
        +isDisplay: boolean,
      |},
      +total: {|
        +label: ?string,
        +order: number,
        +name: string,
        +isDisplay: boolean,
      |},
    |},
  |}
|};
export type ReportSummaryInfoQuery = {|
  variables: ReportSummaryInfoQueryVariables,
  response: ReportSummaryInfoQueryResponse,
|};
*/


/*
query ReportSummaryInfoQuery(
  $where: ClaimJobScopeTypeIndex!
) {
  claimJobScopeType(where: $where) {
    id
    items {
      itemName
      marginRate
      rawSubtotal
      calculateAmount
      margin
      notCalculateAmount
      subtotal
      gst
      total
      id
    }
    total {
      itemName
      marginRate
      rawSubtotal
      calculateAmount
      margin
      notCalculateAmount
      subtotal
      gst
      total
      id
    }
    fields {
      marginRate {
        label
        order
        name
        isDisplay
        id
      }
      gst {
        label
        order
        name
        isDisplay
        id
      }
      itemName {
        label
        order
        name
        isDisplay
        id
      }
      margin {
        label
        order
        name
        isDisplay
        id
      }
      notCalculateAmount {
        label
        order
        name
        isDisplay
        id
      }
      calculateAmount {
        label
        order
        name
        isDisplay
        id
      }
      subtotal {
        label
        order
        name
        isDisplay
        id
      }
      total {
        label
        order
        name
        isDisplay
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimJobScopeTypeIndex!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ClaimJobScopeTypeIndex!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "marginRate",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rawSubtotal",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "calculateAmount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "margin",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "notCalculateAmount",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtotal",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "gst",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v12 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDisplay",
  "args": null,
  "storageKey": null
},
v17 = [
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/)
],
v18 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v2/*: any*/)
],
v19 = [
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReportSummaryInfoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJobScopeType",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobScopeType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "items",
            "storageKey": null,
            "args": null,
            "concreteType": "JobScopeTypeQuote",
            "plural": true,
            "selections": (v12/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "total",
            "storageKey": null,
            "args": null,
            "concreteType": "JobScopeTypeQuote",
            "plural": false,
            "selections": (v12/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fields",
            "storageKey": null,
            "args": null,
            "concreteType": "JobScopeTypeQuoteField",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "marginRate",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v17/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "gst",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v17/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "itemName",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v17/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "margin",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v17/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "notCalculateAmount",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v17/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "calculateAmount",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v17/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "subtotal",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v17/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "total",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v17/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ReportSummaryInfoQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimJobScopeType",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimJobScopeType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "items",
            "storageKey": null,
            "args": null,
            "concreteType": "JobScopeTypeQuote",
            "plural": true,
            "selections": (v18/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "total",
            "storageKey": null,
            "args": null,
            "concreteType": "JobScopeTypeQuote",
            "plural": false,
            "selections": (v18/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fields",
            "storageKey": null,
            "args": null,
            "concreteType": "JobScopeTypeQuoteField",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "marginRate",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v19/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "gst",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v19/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "itemName",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v19/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "margin",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v19/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "notCalculateAmount",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v19/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "calculateAmount",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v19/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "subtotal",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v19/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "total",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldDisplay",
                "plural": false,
                "selections": (v19/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ReportSummaryInfoQuery",
    "id": null,
    "text": "query ReportSummaryInfoQuery(\n  $where: ClaimJobScopeTypeIndex!\n) {\n  claimJobScopeType(where: $where) {\n    id\n    items {\n      itemName\n      marginRate\n      rawSubtotal\n      calculateAmount\n      margin\n      notCalculateAmount\n      subtotal\n      gst\n      total\n      id\n    }\n    total {\n      itemName\n      marginRate\n      rawSubtotal\n      calculateAmount\n      margin\n      notCalculateAmount\n      subtotal\n      gst\n      total\n      id\n    }\n    fields {\n      marginRate {\n        label\n        order\n        name\n        isDisplay\n        id\n      }\n      gst {\n        label\n        order\n        name\n        isDisplay\n        id\n      }\n      itemName {\n        label\n        order\n        name\n        isDisplay\n        id\n      }\n      margin {\n        label\n        order\n        name\n        isDisplay\n        id\n      }\n      notCalculateAmount {\n        label\n        order\n        name\n        isDisplay\n        id\n      }\n      calculateAmount {\n        label\n        order\n        name\n        isDisplay\n        id\n      }\n      subtotal {\n        label\n        order\n        name\n        isDisplay\n        id\n      }\n      total {\n        label\n        order\n        name\n        isDisplay\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54d76534ee43bcc83f8393d06b2ea93b';
module.exports = node;
