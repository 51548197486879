import React, { Component } from 'react'

import { states } from 'common/static_data'
import { graphql, createFragmentContainer } from 'react-relay'
import Button from 'components/Mui/Button'
import MuiCircularProgress from "@material-ui/core/CircularProgress";

import PostcodeInput from 'components/Formik/PostcodeInput'
import Input from 'components/Formik/Input'
import PhoneInput from 'components/Formik/PhoneInput'

import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
// import Input from 'components/Mui/Input'

class AddClaimNewTemplate extends Component {
  state = {
    hasBuilding: false,
    hasContents: false,
    hasRestoration: false,
  }
  render() {
    const {
      handleComplete, handlePostcodeChange,
      data, company, errors, values,
      isSubmitting,
      ...rest } = this.props
    return (
      <Paper
        title='Add New Claim' /* label:'Claim Client Info'|'Customer Details' */
        smallCard
        {...rest}
        content={
          <Grid fluid spacing={16}>
            <Grid item xs={12}>
              <Grid fluid>
                {
                  [
                    {
                      col: 6, label: 'Insurance Ref#',
                      hasAccess: true, required: true,
                      leadIcon: 'link',
                      component: Input, type: 'text', name: 'refNumber',
                    },
                    {
                      col: 6, label: 'Customer' /* label:'Client name'|'Customer' */,
                      required: true,
                      leadIcon: 'person',
                      component: Input, type: 'text', name: 'insured_name',
                    },
                    {
                      col: 6, label: 'Customer Email' /* label:'Client Email'|'Customer Email' */,
                      leadIcon: 'person',
                      component: Input, type: 'text', name: 'insured_email',
                    },
                    {
                      col: 6, label:'Property/Risk Address' /* label:'Risk Address'|'Property/Risk Address' */,
                      required: true,
                      leadIcon: 'location_on',
                      component: Input, type: 'text', name: 'incidentDetail_riskAddress_line1',
                    },
                    {
                      col: 6, label:'Suburb',
                      required: true,
                      leadIcon: 'location_on',
                      component: Input, type: 'text', name: 'incidentDetail_riskAddress_suburb',
                    },
                    {
                      col: 6, label:'State',
                      required: true,
                      leadIcon: 'location_on',
                      component: Input, type: 'text', name: 'incidentDetail_riskAddress_state',
                      select: true, options: states,
                    },
                    {
                      col: 6, label:'Postcode',
                      required: true, name: 'incidentDetail_riskAddress_postcode',
                      leadIcon: 'location_on',
                      component: PostcodeInput,
                      onBlur: e => handlePostcodeChange(e.target.value)
                    },
                    {
                      col: 6, label:'Phone',
                      required: true,
                      leadIcon: 'phone',
                      component: PhoneInput, type: 'text', name: 'insured_phone1',
                    },
                  ].map((child, key) => {
                    const { col, component: Component, unmountOn, ...props} = child
                    return (
                      <Grid item xs={col} key={key}>
                        {!unmountOn && <Component {...props}/>}
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid fluid spacing={0} justify='flex-end'>
                <Grid item xs={2}>
                  <Button label='Submit' type='submit'
                    variant='contained' color='primary'
                    fullWidth
                    disabled={isSubmitting}
                    leadIcon={
                      isSubmitting && <MuiCircularProgress size={24} />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    )
  }
}

export default createFragmentContainer(AddClaimNewTemplate, graphql`
  fragment AddClaimNewTemplate_company on CompanyProfile {
    id
  }
`)
