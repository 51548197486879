import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation StatusHistoryMutation($input: JobWorkProgressInput!) {
    createJobWorkProgress(input: $input) {
      success
      messages
      result {
        id
        logdate
        completed
        status {
          statusName
          datePopup
        }
      }
    }
  }
`

export default (variables, id) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      updater: store => {

      },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.createJobWorkProgress.success,
          message: res.createJobWorkProgress.messages[0]
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}