import React, { Component } from 'react'
import Collapse from '@material-ui/core/Collapse'
import IconButton from 'components/Mui/IconButton'

import MakeSafeListItem from './MakeSafeListItem'

class MakeSafeListGroupPresentation extends Component {
  state = {
    expand: true
  }
  render() {
    const { group, classes, isAction, locations, fields, makeSafeInfo, ...rest } = this.props
    const { expand } = this.state

    return (
      <>
        <div className={classes.groupRow}>
          <IconButton icon={expand ? 'expand_less' : 'expand_more' } 
            className={classes.expandBtn}
            onClick={this.toggleExpand}
          />
          {locations.location.locationName} - {locations.dimensions}
        </div>
        <Collapse in={expand} timeout='auto' unmountOnExit>
          {locations.items.map((item, i) => (
            <MakeSafeListItem key={i}
              className={classes.row}
              rowItemClassName={classes.rowItem}
              actionBtnClassName={classes.actionBtn}
              item={item} isAction={isAction}
              makeSafeInfo={makeSafeInfo}
              fields={fields}
              {...rest}
            />
          ))}
        </Collapse>
      </>
    )
  }
  toggleExpand = () => {
    this.setState({ expand: !this.state.expand })
  } 
}

export default MakeSafeListGroupPresentation