const checkSupported = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices()
    .catch(error => console.log(error))
  if (!devices) return null

  const video = Boolean(devices.find(device => device.kind === 'videoinput'))
  const audio = Boolean(devices.find(device => device.kind === 'audioinput'))

  return { video, audio }
}

export default checkSupported