import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation QuoteAppointmentMadeActionMutation($input: AppointmentInput!, $where: ENDataEntityKey!) {
    claimMakeAppointment(input: $input, where: $where) {
      success
      messages
      result {
        progress {
          appointmentBooked
        }
        claimStatus {
          statusId
        }
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: ({claimMakeAppointment}) => {
          const { messages, success } = claimMakeAppointment

          resolve({ ok: true, success, messages })
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}
