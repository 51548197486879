import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import useReactRouter from "use-react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { getTheme } from "common/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import TopBar from "components/Topbar/";
import Snackbar from "components/Mui/Snackbar";
import "./font.css";

import { setTitle, setFavicon } from "common/utils";
// import usePreloadLazy from 'hooks/usePreloadLazy'
import { Bouncer } from "components/Mui/Loader";

import { publicRoutes, privateRoutes, accessRoutes } from "./routes";

import { RelayEnvironmentProvider } from "relay-hooks";
import environment from "common/relay";

export default () => {
  const { REACT_APP_COMPANY_PATH } = process.env;
  const { history } = useReactRouter();
  const { APPLICATION_CUSTOM, ACCESS_PATHS, TOKEN } = localStorage;

  /**
   * HANDLE THEME
   */
  const defaultTheme = process.env.REACT_APP_DEV === "dev" ? "dev" : "sophia";
  const [theme, setTheme] = React.useState(getTheme(defaultTheme));
  const [isVehicleReport, setIsVehicleReport] = React.useState(false);
  const changeTheme = (theme) => setTheme(getTheme(theme));
  React.useEffect(() => {
    window[Symbol.for("__themeActions")] = {
      setTheme: changeTheme,
    };
    return () => {
      delete window[Symbol.for("__themeActions")];
    };
  }, []);
  React.useEffect(() => {
    window[Symbol.for("__vehicleActions")] = {
      isVehicleReport: isVehicleReport,
      setIsVehicleReport: setIsVehicleReport,
    };
    return () => {
      delete window[Symbol.for("__iconActions")];
    };
  }, [isVehicleReport]);

  /**
   * HANDLE APP CUSTOMIZATIONS
   */
  React.useEffect(() => {
    if (APPLICATION_CUSTOM) {
      const appCustom = JSON.parse(APPLICATION_CUSTOM);
      if (appCustom.alias) setTitle(appCustom.alias);
      if (appCustom.favicon)
        setFavicon(`${REACT_APP_COMPANY_PATH}/${appCustom.favicon}`);
    }
  }, [APPLICATION_CUSTOM]);

  /**
   * HANDLE ROUTE CHANGE EVENT
   */
  const [currentPath, setCurrentPath] = React.useState(null);
  React.useEffect(() => {
    if (
      currentPath === history.location.pathname &&
      history.location.pathname === "/claims"
    ) {
      history.go(0);
    }

    setCurrentPath(history.location.pathname);
  }, [history.location]);

  /**
   * HANDLE APP ROUTES
   */
  const [routes, setRoutes] = React.useState([]);
  React.useEffect(() => {
    const accessPaths = ACCESS_PATHS ? JSON.parse(ACCESS_PATHS) : [];
    const accessLvRoutes = TOKEN
      ? accessRoutes.filter(
          (route) => accessPaths.indexOf(route.accessPath) !== -1
        )
      : [];
    const privateLvRoutes = TOKEN ? privateRoutes : [];

    setRoutes([...accessLvRoutes, ...privateLvRoutes, ...publicRoutes]);
  }, [ACCESS_PATHS, TOKEN]);

  return (
    <RelayEnvironmentProvider environment={environment}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          {TOKEN &&
            APPLICATION_CUSTOM &&
            ACCESS_PATHS &&
            currentPath !== "/survey" && (
              <TopBar state={history.location.state} />
            )}
          <Suspense fallback={<Bouncer />}>
            <Switch>
              {routes &&
                routes.map(({ exact, path, component: Component }, key) => (
                  <Route
                    key={key}
                    exact={exact}
                    path={path}
                    render={(props) => (
                      <Component setTheme={changeTheme} {...props} />
                    )}
                  />
                ))}
            </Switch>
          </Suspense>
          <Snackbar symbol="__snackbar" />
        </div>
      </MuiThemeProvider>
    </RelayEnvironmentProvider>
  );
};
