import React, { Component } from "react";
import Grid from "components/Mui/Grid";
import Button from "components/Mui/Button";
import { QueryRenderer, graphql } from "react-relay";
import environment from "common/relay";
import { Menu, MenuItem, TextField } from "@material-ui/core";
import IconButton from "components/Mui/IconButton";

import VariationAddLineItem from "./VariationAddLineItem";
import VariationCompleteMutation from "./VariationComplete/VariationCompleteMutation";
import GetVariationMutation from "./GetVariationMutation";
import VariationRemoveAuthorityMutation from "./VariationRemoveAuthorityMutation";

import VariationApprove from "./VariationAudit/VariationApprove";
import VariationAuditMutation from "./VariationAudit/VariationAuditMutation";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import PrintIcon from "@material-ui/icons/Print";
import VariationValidation from "./VariationValidation";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { Formik, Form } from "formik";
import Input from "components/Formik/Input";
import StartVariationValidationMutation from "./StartVariationValidationMutation";
import { useMutation } from "react-relay";
import VariationValidationDialog from "./VariationValidation/VariationValidationDialog";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import VariationRecommend from "../VariationDetailsActions/VariationRecommend";

class VariationDetailsActions extends Component {
  state = {
    isSubmitting: false,
    anchorEl: null,
    isLoadingForVariation: false,
    removeAuthorityAnchor: null,
    removeAuthorityLoading: false,
    removeAuthorityReason: "",
    removeAuthorityError: false,

    isShowDialogForPDF: false,
    selectedDialogOption: null,
    isShowDialogForPDFWithoutTypes: false,
    isShowStartVariationValidation: false,
    isPrintWithRate: true,
    isPrintWithTotal: true,
    isShowValidationDialog: false,
  };

  openShowValidationDialog = (event) => {
    this.setState({ isShowValidationDialog: true });
  };
  showPDFModal = (event) => {
    this.setState({ isShowDialogForPDF: true });
    this.setState({ selectedDialogOption: event });
  };
  showPDFModalWithoutType = (event) => {
    this.setState({ isShowDialogForPDFWithoutTypes: true });
  };
  showStartVariationValidation = (event) => {
    this.setState({ isShowStartVariationValidation: true });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ isShowDialogForPDF: null });
    this.setState({ isShowDialogForPDFWithoutTypes: null });
    this.setState({ isShowStartVariationValidation: null });
  };
  handleChangePrintRate = (event) => {
    this.setState({ isPrintWithRate: event.target.checked });
  };
  handleChangePrintTotal = (event) => {
    this.setState({ isPrintWithTotal: event.target.checked });
  };

  snackbar = window[Symbol.for("__snackbar")];
  addItemDlRef = React.createRef();

  render() {
    const { anchorEl } = this.state;
    const { isLoadingForVariation } = this.state;

    const { isShowDialogForPDF } = this.state;
    const { selectedDialogOption } = this.state;
    const { isShowDialogForPDFWithoutTypes } = this.state;
    const { isShowStartVariationValidation } = this.state;
    const { isPrintWithRate } = this.state;
    const { isPrintWithTotal } = this.state;
    const { isShowValidationDialog } = this.state;
    const snackbar = window[Symbol.for("__snackbar")];
    const {
      data,
      meta,
      handleRefresh,
      variation,
      items,
      scopeTypeId,
      ScopeTypeOptions,
      portfolio,
      transactionReasons,
      completed,
      setUpdate,
    } = this.props;
    const query = graphql`
      query VariationDetailsActionsQuery($where: ValidationActionWhere!) {
        validationAction(where: $where) {
          value: id
          label: name
          communicationRequired
        }
      }
    `;
    const closeShowValidationDialog = (event) => {
      this.setState({ isShowValidationDialog: false });
      handleRefresh();
    };
    const isDisPlay =
      this.props.meta.claim.view.actions.updateScopeType.isDisplay;
    var variationScopeTypeList = [{ value: null, label: "All Scope Type" }];
    var variationScopeTypeMenu = null;
    if (isDisPlay) {
      variationScopeTypeList = [
        ...variationScopeTypeList,
        ...this.props.ScopeTypeOptions,
      ];
      variationScopeTypeMenu = (
        <>
          <Button
            aria-controls="variationMenuButton"
            aria-haspopup="true"
            variant="outlined"
            color="primary"
            onClick={this.handleMenu}
            leadIcon={
              !isLoadingForVariation ? (
                <PrintIcon />
              ) : (
                <MuiCircularProgress size={24} />
              )
            }
            fullWidth
            //disabled={isDisabled}
            label="Variation"
          />
          <Menu
            id="variationMenuButton"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            {variationScopeTypeList.map((option, index) => {
              return (
                <MenuItem key={index} onClick={() => this.showPDFModal(option)}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Menu>
          <Dialog
            open={isShowDialogForPDF}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {`Please select one of the following types of ${
                selectedDialogOption && selectedDialogOption.label
                  ? selectedDialogOption.label
                  : null
              }`}
            </DialogTitle>
            <DialogContent>
              <FormGroup row>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isPrintWithRate ? <AttachMoneyIcon /> : <MoneyOffIcon />}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isPrintWithRate}
                        onChange={this.handleChangePrintRate}
                        name="rate"
                      />
                    }
                    label={
                      isPrintWithRate
                        ? "With Line Item Rate"
                        : "Without Line Item Rate"
                    }
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isPrintWithTotal ? <AttachMoneyIcon /> : <MoneyOffIcon />}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isPrintWithTotal}
                        onChange={this.handleChangePrintTotal}
                        name="total"
                      />
                    }
                    label={isPrintWithTotal ? "With Totals" : "Without Totals"}
                  />
                </div>
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.handlePrintVariationWithRate(
                    selectedDialogOption,
                    isPrintWithRate,
                    isPrintWithTotal
                  )
                }
                variant="outlined"
                color="primary"
                fullWidth
                label="Print"
              />
            </DialogActions>
          </Dialog>
        </>
      );
    } else {
      variationScopeTypeMenu = (
        <>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            label="Variation"
            variant="outlined"
            color="primary"
            onClick={() => this.showPDFModalWithoutType()}
            leadIcon={
              !isLoadingForVariation ? (
                <PrintIcon />
              ) : (
                <MuiCircularProgress size={24} />
              )
            }
            fullWidth
            label="Variation"
          />
          <Dialog
            open={isShowDialogForPDFWithoutTypes}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth="md"
          >
            <DialogTitle id="alert-dialog-slide-title">
              Please select one of the following types
            </DialogTitle>
            <DialogContent>
              <FormGroup row>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isPrintWithRate ? <AttachMoneyIcon /> : <MoneyOffIcon />}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isPrintWithRate}
                        onChange={this.handleChangePrintRate}
                        name="rate"
                      />
                    }
                    label={
                      isPrintWithRate
                        ? "With Line Item Rate"
                        : "Without Line Item Rate"
                    }
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isPrintWithTotal ? <AttachMoneyIcon /> : <MoneyOffIcon />}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isPrintWithTotal}
                        onChange={this.handleChangePrintTotal}
                        name="total"
                      />
                    }
                    label={isPrintWithTotal ? "With Totals" : "Without Totals"}
                  />
                </div>
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.handlePrintVariationWithRate(
                    null,
                    isPrintWithRate,
                    isPrintWithTotal
                  )
                }
                variant="outlined"
                color="primary"
                fullWidth
                label="Print"
              />
            </DialogActions>
          </Dialog>
        </>
      );
    }

    return (
      <Grid fluid justify="space-between">
        {variation.actions.startValidation.isDisplay && (
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              label={variation.actions.startValidation.label}
              onClick={() => this.showStartVariationValidation()}
              disabled={variation.actions.startValidation.isDisabled}
            />
            <Dialog
              open={isShowStartVariationValidation}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth="md"
            >
              <DialogTitle id="alert-dialog-slide-title">
                Variation Validation
              </DialogTitle>
              <DialogContent>
                <Grid item xs={12}>
                  <Formik
                    initialValues={{
                      reasonId: null,
                      message: null,
                      validationStatus: "ValidationInProgress",
                    }}
                    onSubmit={(values, actions) => {
                      setTimeout(async () => {
                        const variables = {
                          input: {
                            ...values,
                          },
                          where: {
                            id: this.props.data.variationId,
                            portfolioType: this.props.data.portfolios,
                          },
                        };
                        const res = await StartVariationValidationMutation(
                          variables
                        );
                        if (res.ok) {
                          // eslint-disable-next-line no-unreachable
                          if (res.success) {
                            this.handleClose();
                            //this.props.handleRefresh();
                            //handleVariationValidationDialog();
                            if (
                              values.validationStatus === "ValidationInProgress"
                            ) {
                              this.openShowValidationDialog();
                            } else {
                              const refresh =
                                window[Symbol.for("__refreshVariation")];
                              refresh();
                            }
                            snackbar.toggleOpen({ message: res.messages[0] });
                          }
                          snackbar.toggleOpen({ message: res.messages[0] });
                        } else {
                          snackbar.toggleOpen({ message: res.messages[0] });
                        }
                      }, 400);
                    }}
                  >
                    {({ isSubmitting, values }) => {
                      return (
                        <Form>
                          <Grid fluid spacing={8} justify="center">
                            <Grid item xs={12}>
                              <QueryRenderer
                                environment={environment}
                                query={query}
                                variables={{
                                  where: {
                                    quoteId: variation.variationId,
                                    portfolioType: portfolio,
                                    type: "Variation",
                                  },
                                }}
                                render={({ error, props }) => {
                                  if (!props) return null;
                                  return (
                                    <>
                                      <Input
                                        required
                                        name="validationStatus"
                                        label="Action"
                                        options={props.validationAction}
                                        value={values.validationStatus}
                                        select
                                        isShrink
                                      />
                                      {values.validationStatus ===
                                        "ValidationResetJob" && (
                                        <Input
                                          name="reasonId"
                                          label="Reason"
                                          value={values.reasonId}
                                          options={transactionReasons}
                                          select
                                          required={true}
                                          isShrink
                                        />
                                      )}
                                      {props.validationAction.find(
                                        (item) =>
                                          item.value === values.validationStatus
                                      ).communicationRequired && (
                                        <Input
                                          name="message"
                                          label="Message To Supplier"
                                          rows={4}
                                          value={values.message}
                                          isShrink
                                          required={true}
                                        />
                                      )}
                                    </>
                                  );
                                }}
                              />
                            </Grid>
                            <Button
                              style={{
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: "20px",
                                width: "100%",
                              }}
                              fullWidth
                              variant="contained"
                              type="submit"
                              color="primary"
                              label="Submit"
                              disabled={isSubmitting}
                            />
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>
                </Grid>
              </DialogContent>
            </Dialog>
          </Grid>
        )}
        {variation.actions.viewValidation.isDisplay && (
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              label={variation.actions.viewValidation.label}
              disabled={variation.actions.viewValidation.isDisabled}
              onClick={this.openShowValidationDialog}
            />
          </Grid>
        )}
        {variation.actions.addLineItem.isDisplay && (
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              label={variation.actions.addLineItem.label}
              onClick={this.handleAddNewItem}
              disabled={variation.actions.addLineItem.isDisabled}
            />
            <VariationAddLineItem
              dlRef={this.addItemDlRef}
              handleRefresh={handleRefresh}
              scopeTypeId={scopeTypeId}
              {...data}
            />
          </Grid>
        )}
        {variation.actions.recommendVariation.isDisplay && (
          <Grid item xs={2}>
            <VariationRecommend variation={variation} portfolio={portfolio} />
          </Grid>
        )}
        <Grid item xs={2}>
          {/* <Button fullWidth variant='outlined' color='primary'
            label='Remove Authority'
            onClick={this.handleRemoveAuthorityOpen}
            // disabled={!data.completed || data.variationStatus !== 'Pending' || this.state.isSubmitting}NoScopeTypeTablem
          /> */}
          <Menu
            anchorEl={this.state.removeAuthorityAnchor}
            keepMounted
            open={Boolean(this.state.removeAuthorityAnchor)}
            onClose={this.handleRemoveAuthorityClose}
          >
            <div style={{ padding: "5px", outline: "none" }}>
              <TextField
                style={{ width: "300px" }}
                multiline
                rows={5}
                variant="outlined"
                label="Reason for Removing Authority"
                disabled={this.state.removeAuthorityLoading}
                helperText={
                  this.state.removeAuthorityError && "Reason Required!"
                }
                error={this.state.removeAuthorityError}
                onChange={(e) =>
                  this.setState({ removeAuthorityReason: e.target.value })
                }
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  style={{ width: "10px", height: "10px", marginLeft: "10px" }}
                  icon="check"
                  onClick={() => {
                    if (!this.state.removeAuthorityReason) {
                      this.setState({ removeAuthorityError: true });
                    } else {
                      this.setState({ removeAuthorityError: false });
                      this.handleRemoveAuthority();
                    }
                  }}
                  disabled={this.state.removeAuthorityLoading}
                />
                <IconButton
                  style={{ width: "10px", height: "10px", margin: 0 }}
                  icon="close"
                  onClick={() => {
                    this.setState({ removeAuthorityError: false });
                    this.handleRemoveAuthorityClose();
                  }}
                  disabled={this.state.removeAuthorityLoading}
                />
              </div>
            </div>
          </Menu>
        </Grid>
        {variation.actions.reset.isDisplay && (
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              label={variation.actions.reset.label}
              onClick={() => this.handleVariationAudit("VariationReset")}
              disabled={variation.actions.reset.isDisabled}
            />
          </Grid>
        )}
        {variation.actions.accept.isDisplay && (
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              label={variation.actions.accept.label}
              onClick={() => this.handleVariationApprove()}
              disabled={variation.actions.accept.isDisabled}
            />
            <VariationApprove
              handleRefresh={handleRefresh}
              ScopeTypeOptions={ScopeTypeOptions}
              items={items}
              meta={meta}
              portfolio={portfolio}
              variation={variation}
              data={this.props.data}
            />
          </Grid>
        )}
        <Grid item xs={2}>
          {variationScopeTypeMenu}
        </Grid>
        <VariationValidation meta={meta} portfolioType={portfolio} />
        {variation.actions.decline.isDisplay && (
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              label={variation.actions.decline.label}
              onClick={() => this.handleVariationAudit("VariationRejected")}
              disabled={variation.actions.decline.isDisabled}
            />
          </Grid>
        )}
        {/* {variation.actions.viewVariationValidation.isDisplay && (
          <Grid item xs={2}>
            <Button
              fullWidth
              label={variation.actions.viewVariationValidation.label}
              variant="outlined"
              color="primary"
              disabled={variation.actions.viewVariationValidation.isDisabled}
              onClick={() => this.handleVariationValidation()}
            />
          </Grid>
        )} */}
        {variation.actions.submit.isDisplay && (
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              label={variation.actions.submit.label}
              onClick={this.handleVariationCompleted}
              disabled={variation.actions.submit.isDisabled}
            />
          </Grid>
        )}

        {isShowValidationDialog && (
          <VariationValidationDialog
            //variation={variation}
            open={isShowValidationDialog}
            onClose={closeShowValidationDialog}
            data={data}
            meta={meta}
            handleRefresh={handleRefresh}
            completed={completed}
            portfolio={this.props.data.portfolios}
            setUpdate={setUpdate}
            isValidationItem={true}
          />
        )}
      </Grid>
    );
  }
  handleAddNewItem = () => {
    this.addItemDlRef.current.handleOpen();
  };

  handleVariationValidation = () => {
    if (window[Symbol.for("__VariationValidationAction")]) {
      window[Symbol.for("__VariationValidationAction")].handleOpen();
    }
  };
  handlePrintVariationWithRate = (item, isWithRate, isWithTotal) => {
    this.handlePrintVariation(
      item && item.value ? item.value : null,
      isWithRate,
      isWithTotal
    );
    this.handleClose();
  };
  handleStartVariationValidation = async () => {};

  handlePrintVariation = async (item, isWithRate, isWithTotal) => {
    this.setState({ anchorEl: null });
    this.setState({ isLoadingForVariation: true });
    const res = await GetVariationMutation({
      where: {
        variationId: this.props.data.variationId,
        portfolioType: this.props.data.portfolios,
        scopeTypeId: item,
      },
      input: {
        rate: isWithRate,
        total: isWithTotal,
      },
    });
    if (res.ok) {
      this.setState({ isLoadingForVariation: false });
      window.open(
        res.pdf,
        "Site Report Print Preview",
        "resizable, scrollbars, status"
      );
    }
  };
  handleVariationCompleted = async () => {
    const { variationId, portfolios } = this.props.data;
    const res = await VariationCompleteMutation({
      where: {
        id: variationId,
        portfolioType: portfolios,
      },
    });
    if (res.ok) {
      this.snackbar.toggleOpen({
        message: res.message,
      });
      const { handleRefresh } = this.props;
      handleRefresh();
    }
  };
  handleVariationAudit = async (type) => {
    this.setState({ isSubmitting: true });
    const { variationId, portfolios } = this.props.data;
    const res = await VariationAuditMutation({
      where: {
        id: variationId,
        portfolioType: portfolios,
      },
      input: {
        auditToStatus: type,
      },
    });
    if (res) {
      this.setState({ isSubmitting: false });
      if (res.ok) {
        this.snackbar.toggleOpen({
          message: res.message,
        });

        if (res.success) this.props.handleRefresh();
      }
    }
  };

  handleRemoveAuthority = async (type) => {
    const { variationId, portfolios } = this.props.data;
    const { removeAuthorityReason } = this.state;
    this.setState({ removeAuthorityLoading: true });
    const res = await VariationRemoveAuthorityMutation({
      where: {
        id: variationId,
        portfolioType: portfolios,
      },
      input: {
        reason: removeAuthorityReason,
      },
    });
    if (res.ok) {
      this.setState({ removeAuthorityLoading: false });
      this.handleRemoveAuthorityClose();
      this.snackbar.toggleOpen({
        message: res.message,
      });
      if (res.success) {
        // window.location.reload()
      }
    } else {
      this.snackbar.toggleOpen({
        message: res.message,
      });
    }
  };

  handleRemoveAuthorityOpen = (e) => {
    this.setState({ removeAuthorityAnchor: e.currentTarget });
  };

  handleRemoveAuthorityClose = (e) => {
    this.setState({ removeAuthorityAnchor: null });
  };

  handleVariationApprove = () => {
    window[Symbol.for("__VariationAuditDialog")].handleOpen();
  };
}

export default VariationDetailsActions;
