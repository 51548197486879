import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Formik, Form, Field } from 'formik'
import Grid from 'components/Mui/Grid'
import Paper from 'components/Mui/Paper'
import Button from 'components/Mui/Button'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { moneyFormat } from 'common/utils'
import Switch from 'components/Formik/Switch'

import AcceptContentItemMutation from './AcceptContentItemMutation'

const AcceptContentItemForm = ({ classes, claimId, items, deliveryChargeUsed }) => {
  const snackbar = window[Symbol.for('__snackbar')]
  const __refreshQuotes = window[Symbol.for('__refreshQuotes')]
  const acceptDialog = window[Symbol.for('__AcceptContentItem')]
  const [ initValues, setInitValues ] = React.useState(null)

  React.useEffect(() => {
    if(items) {
      const initialValues = items.reduce((total, item) => {
        return {
          ...total,
          [item.itemId]: Boolean(item.authorised)
        }
      }, {})
      setInitValues(initialValues)
    }
  }, [ items ])

  if(!initValues || deliveryChargeUsed === null || items === null) return 'loading...'

  return (
    <Formik
      initialValues={{
        ...initValues,
        deliveryChargeUsed: deliveryChargeUsed || false
      }}
      onSubmit={({ deliveryChargeUsed, ...values }, { setSubmitting }) => {
        setTimeout(async () => {
          const dlActions = window[Symbol.for('__AcceptContentItem')]
          const authorisedItems = Object.entries(values)
            .filter(x => x[1] === true)
            .map(x => parseInt(x[0]))

          const variables = {
            where: { id: claimId },
            input: { authorisedItems, deliveryChargeUsed }
          }

          const res = await AcceptContentItemMutation(variables)
          setSubmitting(false)

          if(res) {
            snackbar.toggleOpen({
              message: res.messages[0]
            })
            if(res.success) {
              __refreshQuotes.handleRefresh()
              if(dlActions) dlActions.handleClose()
            }
          }
        }, 400)
      }}
    >
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <Switch label='Delivery charge' name='deliveryChargeUsed'
              disabled={values.deliveryChargeUsed}
            />
            <FormGroup>
              <Paper
                title={<Header />}
                content={(
                  <Grid fluid spacing={0}>
                    <Grid item xs={12}>
                    {items.length !== 0
                      ? items.map(item => (
                        <FormControlLabel key={item.itemId}
                          control={(
                            <Field name={item.itemId}>
                              {({ field, form }) => {
                                return (
                                  <Checkbox color='primary' 
                                    checked={field.value}
                                    onChange={e => {
                                      form.setTouched({ [field.name]: true })
                                      form.setFieldValue(field.name, e.target.checked)
                                    }}
                                  />
                                )  
                              }}
                            </Field>
                          )}
                          classes={{ root: classes.root, label: classes.label }}
                          label={(
                            <Grid fluid spacing={0}>
                              <Grid item xs={3}>{item.centre.name}</Grid>
                              <Grid item xs>{item.costItem.itemDescription}{item.description}</Grid>
                              <Grid item xs={1}>{item.qty}</Grid>
                              <Grid item xs={2}>{moneyFormat(item.rate)}</Grid>
                              <Grid item xs={1}>{moneyFormat(item.margin)}</Grid>
                              <Grid item xs={2}>{moneyFormat(item.subtotal)}</Grid>
                            </Grid>
                          )}
                        />  
                      ))
                      : 'No item found'
                    }
                    </Grid>
                    <div className={classes.actions}>
                      <Button
                        label='Cancel'
                        color='primary'
                        disabled={isSubmitting}
                        onClick={() => acceptDialog.handleClose()}
                      />
                      {items.length !== 0 && (
                        <Button
                          label='Accept'
                          variant='contained' color='primary'
                          type='submit'
                          disabled={isSubmitting}
                        />
                      )}
                    </div>
                  </Grid>
                )}
              />
            </FormGroup>
          </Form>
        )  
      }}
    </Formik>
  )
}

export default withStyles(theme => ({
  root: {
    width: '100%'
  },
  label: {
    width: '100%'
  },
  actions: {
    marginLeft: 'auto'
  }
}))(AcceptContentItemForm)

const Header = () => (
  <Grid fluid spacing={0}>
    <Grid item xs={3}>
      Cost Centre
    </Grid>
    <Grid item xs>
      Description
    </Grid>
    <Grid item xs={1}>
      Quantity
    </Grid>
    <Grid item xs={2}>
      Rate
    </Grid>
    <Grid item xs={1}>
      Margin
    </Grid>
    <Grid item xs={2}>
      Subtotal
    </Grid>
  </Grid>   
)