import React from "react";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiCheckbox from "@material-ui/core/Checkbox";

import { Field } from "formik";

class Checkbox extends React.Component {
  render() {
    return (
      <Field name={this.props.name}>
        {({ field, form }) => {
          return (
            <FormControlLabel
              label={this.props.label}
              control={
                <MuiCheckbox
                  color="primary"
                  checked={
                    field.value && field.value.includes(this.props.value)
                  }
                  onChange={() => {
                    form.setTouched({ [field.name]: true });
                    if (!form.isSubmitting) {
                      if (field.value.includes(this.props.value)) {
                        const nextValue = field.value.filter(
                          (value) => value !== this.props.value
                        );
                        form.setFieldValue(this.props.name, nextValue);
                      } else {
                        const nextValue = field.value.concat(this.props.value);
                        form.setFieldValue(this.props.name, nextValue);
                      }
                    }
                  }}
                  value={this.props.value}
                />
              }
            />
          );
        }}
      </Field>
    );
  }
}

class CheckboxGroup extends React.Component {
  render() {
    const { name, label, options, row, error, ...rest } = this.props;
    return (
      <FormControl error={Boolean(error)}>
        <FormLabel>{this.props.label}</FormLabel>
        <FormGroup row={this.props.row}>
          {this.props.options &&
            this.props.options.map(({ label, value }, key) => {
              return (
                <Checkbox
                  {...rest}
                  name={this.props.name}
                  value={value}
                  label={label}
                  key={key}
                />
              );
            })}
        </FormGroup>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    );
  }
}

export default CheckboxGroup;
