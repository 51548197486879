/**
 * @flow
 * @relayHash 436218dfec84df8b94ddccbb83ac8124
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type ENDataPortfolioKey = {|
  id: string,
  portfolioType: PortfolioType,
|};
export type CompleteVariationValidationMutationVariables = {|
  where: ENDataPortfolioKey
|};
export type CompleteVariationValidationMutationResponse = {|
  +jobVariationValidationComplete: ?{|
    +success: boolean,
    +messages: $ReadOnlyArray<string>,
  |}
|};
export type CompleteVariationValidationMutation = {|
  variables: CompleteVariationValidationMutationVariables,
  response: CompleteVariationValidationMutationResponse,
|};
*/


/*
mutation CompleteVariationValidationMutation(
  $where: ENDataPortfolioKey!
) {
  jobVariationValidationComplete(where: $where) {
    success
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ENDataPortfolioKey!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "jobVariationValidationComplete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where",
        "type": "ENDataPortfolioKey!"
      }
    ],
    "concreteType": "JobVariationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "messages",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CompleteVariationValidationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CompleteVariationValidationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CompleteVariationValidationMutation",
    "id": null,
    "text": "mutation CompleteVariationValidationMutation(\n  $where: ENDataPortfolioKey!\n) {\n  jobVariationValidationComplete(where: $where) {\n    success\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ad3af7e00a44c96999f4aaabf1589e3a';
module.exports = node;
