import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import InternalAssessorActionBar from "./InternalAssessorActionBar";
import PortfolioFilter from "pages/Claim/ClaimDetails/ClaimDetailsPortfolioFilter";
import ScopeItemList from "../../ClaimDetailsPage/Scope/ScopeItemList";

const InternalAssessorPresentation = ({
  classes,
  meta,
  quotes,
  portfolio,
  changePortfolio,
  requestCategory,
  isProperties,
  scopeTypes,
  industryRatesAutoFill,
}) => {
  const quote =
    quotes.jobQuotes.edges.length !== 0 ? quotes.jobQuotes.edges[0].node : null;

  const isContentsAction = portfolio === "Contents" && meta.user.type.isAdmin;
  const isOwnCompany =
    quote &&
    quote.supplier &&
    quote.supplier.companyId === meta.user.company.companyId;
  const isAction =
    (isOwnCompany || isContentsAction || meta.claim.insurer.nonPanelSupplier) &&
    !Boolean(quote && quote.completed);
  return (
    <Grid container spacing={8} className={classes.root}>
      <Grid item xs={12}>
        <InternalAssessorActionBar
          scopeTypeId={quotes.jobScopeTypes && quotes.jobScopeTypes[0]}
          quote={quote}
          meta={meta}
          portfolio={portfolio}
          isOwnCompany={isOwnCompany}
          isContentsAction={isContentsAction}
          isProperties={isProperties}
          requestCategory={requestCategory}
          scopeTypes={scopeTypes}
          industryRatesAutoFill={industryRatesAutoFill}
        />
      </Grid>
      {quote && quote.jobQuoteId ? (
        <Grid item xs={12} className={classes.bodyGroup}>
          <PortfolioFilter
            noAll
            portfolio={{
              hasBuilding: meta.claim.hasBuilding,
              hasContents: meta.claim.hasContents,
              hasRestoration: meta.claim.hasRestoration,
            }}
            changePortfolio={changePortfolio}
            value={portfolio.toLowerCase()}
          />
          {portfolio !== "Contents" ? (
            <ScopeItemList
              quote={quote}
              portfolio={portfolio}
              meta={meta}
              isAction={isAction}
              isOwnCompany={isOwnCompany}
              isContentsAction={isContentsAction}
              isProperties={isProperties}
              isShowAction={true}
            />
          ) : null}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withStyles((theme) => ({
  root: {
    marginTop: theme.spacing.unit,
  },
  bodyGroup: {
    position: "relative",
    paddingTop: "0 !important",
    paddingLeft: "0 !important",
  },
}))(InternalAssessorPresentation);
