const { REACT_APP_API_ENDPOINT } = process.env

export default () => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.REFRESH_TOKEN}`,
  }

  return fetch(REACT_APP_API_ENDPOINT, {
    method: 'POST',
    headers,
    // credentials: 'include',
    body: JSON.stringify({
      id: 'userRenewTokenMutation',
      query: `mutation { userRenewToken { accessToken refreshToken } }`
    })
  })
  .then(res => res.json())
}
