/**
 * @flow
 * @relayHash 00d8f75b649312a705f74f88c48ddd7c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DataUnit = "Currency" | "Number" | "Percentage" | "%future added value";
export type Orientation = "Horizontal" | "Vertical" | "%future added value";
export type ClaimScoreBoardFilter = {|
  scoreBoardId?: ?string,
  suppliers?: ?$ReadOnlyArray<string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  regions?: ?$ReadOnlyArray<?string>,
  createDateFrom: any,
  createDateTo: any,
|};
export type SupplierScorecardQueryVariables = {|
  where: ClaimScoreBoardFilter
|};
export type SupplierScorecardQueryResponse = {|
  +claimScoreBoard: ?{|
    +title: string,
    +insurerHeader: string,
    +insurerLogo: string,
    +scoreGroups: ?$ReadOnlyArray<?{|
      +groupDescription: string,
      +orientation: ?Orientation,
      +name: string,
      +icon: ?string,
      +items: ?$ReadOnlyArray<?{|
        +id: string,
        +color: ?string,
        +ranking: ?number,
        +name: string,
        +unit: ?DataUnit,
        +value: ?number,
      |}>,
    |}>,
    +tables: ?$ReadOnlyArray<?{|
      +name: string,
      +rows: ?$ReadOnlyArray<?{|
        +id: string,
        +name: string,
        +fields: ?$ReadOnlyArray<?{|
          +id: string,
          +color: ?string,
          +name: string,
          +ranking: ?number,
          +unit: ?DataUnit,
          +value: ?number,
        |}>,
      |}>,
    |}>,
  |}
|};
export type SupplierScorecardQuery = {|
  variables: SupplierScorecardQueryVariables,
  response: SupplierScorecardQueryResponse,
|};
*/


/*
query SupplierScorecardQuery(
  $where: ClaimScoreBoardFilter!
) {
  claimScoreBoard(where: $where) {
    title
    insurerHeader
    insurerLogo
    scoreGroups {
      groupDescription
      orientation
      name
      icon
      items {
        id
        color
        ranking
        name
        unit
        value
      }
      id
    }
    tables {
      name
      rows {
        id
        name
        fields {
          id
          color
          name
          ranking
          unit
          value
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClaimScoreBoardFilter!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where",
    "type": "ClaimScoreBoardFilter!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "insurerHeader",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "insurerLogo",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "groupDescription",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orientation",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "icon",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "color",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ranking",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unit",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "items",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimScoreItem",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v7/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/)
  ]
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "rows",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimScoreRow",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    (v7/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fields",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimScoreItem",
      "plural": true,
      "selections": [
        (v9/*: any*/),
        (v10/*: any*/),
        (v7/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/)
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SupplierScorecardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimScoreBoard",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimScoreBoard",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scoreGroups",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimScoreGroup",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v14/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tables",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimScoreTable",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v15/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SupplierScorecardQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "claimScoreBoard",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClaimScoreBoard",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scoreGroups",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimScoreGroup",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v14/*: any*/),
              (v9/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tables",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimScoreTable",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v15/*: any*/),
              (v9/*: any*/)
            ]
          },
          (v9/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SupplierScorecardQuery",
    "id": null,
    "text": "query SupplierScorecardQuery(\n  $where: ClaimScoreBoardFilter!\n) {\n  claimScoreBoard(where: $where) {\n    title\n    insurerHeader\n    insurerLogo\n    scoreGroups {\n      groupDescription\n      orientation\n      name\n      icon\n      items {\n        id\n        color\n        ranking\n        name\n        unit\n        value\n      }\n      id\n    }\n    tables {\n      name\n      rows {\n        id\n        name\n        fields {\n          id\n          color\n          name\n          ranking\n          unit\n          value\n        }\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '26b927bab0e949e2353743bf6a70efbd';
module.exports = node;
