import React from "react";
import classNames from "clsx";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import CancelIcon from "@material-ui/icons/Cancel";

// OPTIONS MENU
export const Menu = (props) => {
  // const [ anchorEl ] = props.selectProps.textFieldProps.anchor
  // const open = Boolean(anchorEl)
  // console.log(props.children)
  //
  const contentTableStyle = {
    position: "absolute",
    maxHeight: 300,
    top: "unset",
  };
  return (
    // <MuiMenu anchorEl={anchorEl} open={open}
    //   {...props.innerProps}
    // >
    //   {props.children}
    // </MuiMenu>
    <Paper
      className={props.selectProps.classes.menu}
      style={
        props.selectProps.isContentTable === true
          ? contentTableStyle
          : undefined
      }
      {...props.innerProps}
      id={`menu-${props.selectProps.id}`}
    >
      {props.children}
    </Paper>
  );
};
export const MenuList = (props) => {
  return (
    <div className={props.selectProps.classes.menuList} {...props.innerProps}>
      {props.children}
    </div>
  );
};

export const Option = (props) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        // width: 'max-content'
        height: "auto",
        whiteSpace: "normal",
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};
export const NoOptionsMessage = (props) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

// VALUE CONTAINER
export const ValueContainer = (props) => {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
};
export const SingleValue = (props) => {
  return (
    <Typography
      style={{
        whiteSpace: props.selectProps.isContentTable === true && "normal",
      }}
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};
export const MultiValue = (props) => {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
};

// INPUT CONTROL
export const Control = (props) => {
  // const [ anchorEl, setAnchorEl ] = props.selectProps.textFieldProps.anchor
  // console.log(props)
  return (
    <TextField
      fullWidth
      variant="outlined"
      // margin='normal'
      InputProps={{
        inputComponent,
        startAdornment: props.selectProps.leadIcon ? (
          <InputAdornment position="start">
            <Icon>{props.selectProps.leadIcon}</Icon>
          </InputAdornment>
        ) : props.selectProps.leadDom ? (
          <InputAdornment position="start">
            {props.selectProps.leadDom}
          </InputAdornment>
        ) : null,
        endAdornment: props.selectProps.trailIcon ? (
          <InputAdornment position="start">
            <Icon>{props.selectProps.trailIcon}</Icon>
          </InputAdornment>
        ) : props.selectProps.trailDom ? (
          <InputAdornment position="end">
            {props.selectProps.trailDom}
          </InputAdornment>
        ) : null,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      InputLabelProps={{
        // focused: Boolean(props.selectProps.field.value) || props.isFocused,
        shrink: Boolean(props.selectProps.field.value) || props.isFocused,
      }}
      // onClick={e => setAnchorEl(e.currentTarget)}
      {...props.selectProps.textFieldProps}
    />
  );
};
export const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

// export const Placeholder = (props) => {
//   return (
//     <Typography
//       color="textSecondary"
//       className={props.selectProps.classes.placeholder}
//       {...props.innerProps}
//     >
//       {props.children}
//     </Typography>
//   )
// }
