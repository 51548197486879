import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'

import QuickAction from 'components/Mui/QuickActions/QuickAction'
import Grid from 'components/Mui/Grid'
import Button from 'components/Mui/Button'

import { Formik, Form } from 'formik'
import Switch from 'components/Formik/Switch'
import Input from 'components/Formik/Input'

import NewNoteActionMutation from './NewNoteActionMutation'

class NewNoteAction extends Component {
  qaRef = React.createRef()

  render() {
    const { classes, claimId, meta, ...rest } = this.props

    const portfolios = []
    if(meta.claim.hasBuilding) portfolios.push({label: 'Building', value: 'Building'})
    if(meta.claim.hasRestoration) portfolios.push({label: 'Restoration', value: 'Restoration'})
    if(meta.claim.hasContents) portfolios.push({label: 'Contents', value: 'Contents'})

    return (
      <QuickAction
        title='Claim Note'
        innerRef={this.qaRef}
        content={
          <Formik
            initialValues={{
              message: ''  , private: false, portfolioType: meta.user.type.isAdmin ? portfolios[0].value : undefined
            }}
            validate={(values) => {
              let errors = {}
              if(!values.message) errors.message = 'required'
              if(!values.portfolioType && meta.user.type.isAdmin) errors.portfolioType = 'required'

              return errors  
            }}
            onSubmit={(values, actions) => {
              setTimeout(async () => {
                const { claimId } = this.props
                const res = await NewNoteActionMutation({
                  ...values, 
                  claimId: parseInt(claimId), 
                  private: values.private ? 1 : 0
                })
                if(res) {
                  this.qaRef.current.toggleOpen()

                  const ref = window[Symbol.for('__dkmm')]
                  if(ref) ref.handleRefresh()
                  
                  actions.resetForm({message: ''  , private: false, portfolioType: meta.user.type.isAdmin ? portfolios[0].value : undefined})
                }

                actions.setSubmitting(false)
              }, 400)    
            }}
          >
            {({ isSubmitting, values, touched }) => {
              return (
                <Form>
                  <Grid fluid spacing={0} className={classes.root}>
                    {meta.user.type.isAdmin && (
                      <Grid item xs={12}>
                        <Input required
                          leadIcon='event'
                          label='Portfolio type' name='portfolioType'
                          fullWidth variant='outlined'
                          select options={portfolios}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Input required
                        fullWidth variant='outlined'
                        label='Claim notes' name='message'
                        multiline rows={5} rowsMax={10}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <Switch
                        name='private'
                        label='Privacy'
                        labelOn='Private'
                        labelOff='Public'
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Button label='Submit' type='submit'
                        variant='contained' color='primary'
                        fullWidth disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Form>
              )  
            }}
          </Formik>
        }
        {...rest}
      />
    )
  }

}

export default withStyles(theme => ({
  button: {
    display: 'flex',
    alignItems: 'center',
  }
}))(NewNoteAction)