import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'

const mutation = graphql`
  mutation UpdateVariationScopeTypeMutation($input: QuoteItemUpdate! $where: ENDataPortfolioKey!) {
    updateJobVariationItem(input: $input where: $where) {
      messages
      success
      result {
        variationId
        completed
        variationStatus
        variationTitle variationDescription
        variationReason { reasonDescription }
        scopeTypes {
        locations {
          id
          location {
            id
            costLocationId
            locationName
          }
          items {
            variationItemId
            accepted
            location { costLocationId locationName } dimensions
            costCentre { costCentreId costCentreName }
            costItem { costItemId itemDescription } lineDescription
            unit { unitId unitName }
            qty
            rate
            marginRate
            subtotal
            logdate
            sirRate
            scopeType {
              name
              id
            }
          }
        }
        scopeType {
          id
          name
        }
        id
        gst
        margin
        sirGst
        sirMargin
        sirSubtotal
        sirTotal
        subtotal
        total
        discount
        fields {
          marginBreakdown{
            isDisplay
            label
            isDisplay
          }
          discount {
            isDisplay
            label
            isDisabled
          }
          sIRRate {
            label
            isDisplay
          }
          subtotal {
            label
            isDisplay
          }
          gst {
            label
            isDisplay
          }
          margin {
            label
            isDisplay
          }
          total {
            label
            isDisplay
          }
          action {
            label
            isDisplay
          }
          costCentre {
            label
            isDisplay
          }
          description {
            label
            isDisplay
          }
          marginRate {
            label
            isDisplay
          }
          margin {
            label
            isDisplay
          }
          qty {
            label
            isDisplay
          }
          rate {
            label
            isDisplay
          }
          subtotal {
            label
            isDisplay
          }
          unit {
            label
            isDisplay
          }
        }
      }
        subtotal gst margin total
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment, {
        mutation,
        variables,
        onCompleted: ({updateJobVariationItem}) => {
          const { messages, success } = updateJobVariationItem
          window[Symbol.for('__snackbar')].toggleOpen({
            message: messages[0]
          })
          resolve({ok: true, messages, success})
        },
        onError: errors => {
          resolve({ok: false})
        }
      }
    )
  })
}
