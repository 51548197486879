/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DashboardKpiDetailsItem_details$ref: FragmentReference;
export type DashboardKpiDetailsItem_details = {|
  +refNumber: string,
  +insuredName: string,
  +caseManagerName: string,
  +reportManagerName: ?string,
  +status: ?{|
    +statusName: ?string
  |},
  +daysAtStatus: ?number,
  +claimId: ?string,
  +portfolioType: PortfolioType,
  +reportStatusName: string,
  +reportCategoryName: string,
  +reportItemId: ?string,
  +$refType: DashboardKpiDetailsItem_details$ref,
|};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "DashboardKpiDetailsItem_details",
  "type": "ClaimJobGeneral",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "refNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "insuredName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "caseManagerName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reportManagerName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "status",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimStatus",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "statusName",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "daysAtStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "claimId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "portfolioType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reportStatusName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reportCategoryName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reportItemId",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a8b4e3623fff7ebd240fffa60899ea40';
module.exports = node;
