/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type QuotingBuildersCard_claim$ref: FragmentReference;
export type QuotingBuildersCard_claim = {|
  +insurer: ?{|
    +companyId: number
  |},
  +incidentDetail: ?{|
    +riskAddress: ?{|
      +postcode: ?string
    |}
  |},
  +building: ?{|
    +scopingSupplier: ?{|
      +companyId: number,
      +companyName: string,
    |},
    +jobSuppliers: ?$ReadOnlyArray<?{|
      +supplier: ?{|
        +companyId: number,
        +companyName: string,
      |}
    |}>,
  |},
  +$refType: QuotingBuildersCard_claim$ref,
|};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyId",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "companyName",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "QuotingBuildersCard_claim",
  "type": "ClaimJob",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "insurer",
      "storageKey": null,
      "args": null,
      "concreteType": "Company",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "incidentDetail",
      "storageKey": null,
      "args": null,
      "concreteType": "IncidentDetail",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "riskAddress",
          "storageKey": null,
          "args": null,
          "concreteType": "Address",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "postcode",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "building",
      "storageKey": null,
      "args": null,
      "concreteType": "ClaimPortfolio",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "scopingSupplier",
          "storageKey": null,
          "args": null,
          "concreteType": "Company",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "jobSuppliers",
          "storageKey": null,
          "args": null,
          "concreteType": "JobSupplier",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "supplier",
              "storageKey": null,
              "args": null,
              "concreteType": "Company",
              "plural": false,
              "selections": (v1/*: any*/)
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac2eed57d0a484ffc26fd865d5901cf4';
module.exports = node;
