import { commitMutation, graphql } from 'react-relay'
import environment from 'common/relay'
// import { ConnectionHandler } from 'relay-runtime'

const mutation = graphql`
  mutation MakeSafeDeleteItemMutation(
    $where: ENDataPortfolioKey!
  ) {
    deleteJobMakeSafeItem(
      where: $where
    ) {
      messages
      success
    }
  }
`

export default (variables, nodeId) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
//       updater: store => {
//         const success = store.getRootField('deleteJobMakeSafeItem').getValue('success')
//         if(success) {
//           const root = store.getRoot()
//           const conn = ConnectionHandler.getConnection(root, 'MakeSafeContainerQuery_makeSafes')
//           const node = conn.getLinkedRecords('edges')[0].getLinkedRecord('node')
//           
//           const items = node.getLinkedRecords('lineItems')
//           const newItems = items.filter(item => item.getDataID() !== nodeId)
// 
//           node.setLinkedRecords(newItems, 'lineItems')
//         }
//       },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.deleteJobMakeSafeItem.success,
          message: res.deleteJobMakeSafeItem.messages[0]
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}