import React, { Component } from 'react'

import './loader.css'

export class Bouncer extends Component {
  render() {
    return (
      <div className='spinner'>
        <div className='double-bounce1'></div>
        <div className='double-bounce2'></div>
      </div>
    )
  }
}

export class Cube extends Component {
  render() {
    return (
      <div className='spinner'>
        <div className='cube1'></div>
        <div className='cube2'></div>
      </div>
    )
  }
}

export class CubeGrid extends Component {
  render() {
    return (
      <div className='sk-cube-grid'>
        <div className='sk-cube sk-cube1'></div>
        <div className='sk-cube sk-cube2'></div>
        <div className='sk-cube sk-cube3'></div>
        <div className='sk-cube sk-cube4'></div>
        <div className='sk-cube sk-cube5'></div>
        <div className='sk-cube sk-cube6'></div>
        <div className='sk-cube sk-cube7'></div>
        <div className='sk-cube sk-cube8'></div>
        <div className='sk-cube sk-cube9'></div>
      </div>
    )
  }
}

export class SpinnerRect extends Component {
  render() {
    return (
      <div className='spinner-rect'>
        <div className='rect1'></div>
        <div className='rect2'></div>
        <div className='rect3'></div>
        <div className='rect4'></div>
        <div className='rect5'></div>
      </div>
    )
  }
}