import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import claimRoutes from './routes'
import { parse } from "query-string";
import { getSafe } from "common/utils";

import useReactRouter from "use-react-router";
import ClaimMetaContext from "./ClaimMetaContext";
import ClaimDetailsErrorBoundary from "./ClaimDetailsErrorBoundary";
import { withStyles } from "@material-ui/core";

import ClaimDetailsInfo from "./ClaimDetailsInfo";
import ClaimDetailsMenu from "./ClaimDetailsMenu/";
import ClaimDetailsFabActions from "./ClaimDetailsFabActions";
import { ClaimMetaProvider } from "./ClaimMetaContext";

// import usePreloadLazy from 'hooks/usePreloadLazy'
import { SpinnerRect } from "components/Mui/Loader";

import JobInfo from "pages/Claim/ClaimDetails/ClaimDetailsPage/JobInfo";
import SiteReport from "pages/Claim/ClaimDetails/ClaimDetailsPage/SiteReport";
import JobNotes from "pages/Claim/ClaimDetails/ClaimDetailsPage/JobNotes/";
import Documents from "pages/Claim/ClaimDetails/ClaimDetailsPage/Documents/";
import CommunicationsPage from "pages/Claim/ClaimDetails/ClaimDetailsPage/CommunicationsPage";
import LossAdjuster from "pages/Claim/ClaimDetails/ClaimDetailsPage/LossAdjuster";
import InternalAssessor from "pages/Claim/ClaimDetails/ClaimDetailsPage/InternalAssessor";
// import Invoice from 'pages/Claim/ClaimDetails/ClaimDetailsPage/Invoice'
import Scope from "pages/Claim/ClaimDetails/ClaimDetailsPage/Scope";
import Quotes from "pages/Claim/ClaimDetails/ClaimDetailsPage/Quotes/";
import Quote from "pages/Claim/ClaimDetails/ClaimDetailsPage/Quote/";
import MakeSafe from "pages/Claim/ClaimDetails/ClaimDetailsPage/MakeSafe/MakeSafeContainer";
import Fees from "pages/Claim/ClaimDetails/ClaimDetailsPage/Fees";
import Variations from "pages/Claim/ClaimDetails/ClaimDetailsPage/Variations";
import JobInProgress from "pages/Claim/ClaimDetails/ClaimDetailsPage/JobInProgress";
import Histories from "pages/Claim/ClaimDetails/ClaimDetailsPage/Histories";

import Report from "pages/Claim/ClaimDetails/ClaimDetailsPage/Report";
import VehicleReport from "./ClaimDetailsPage/VehicleReport";

const Claim = (props) => {
  const { location, history } = useReactRouter();
  const { classes } = props;
  /**
   * HANDLE CLAIM DETAILS ID
   */
  const [claimId, setClaimId] = React.useState(null);
  React.useEffect(() => {
    const { id } = parse(location.search);
    if (!id) history.push("/notfound");
    setClaimId(id);
  }, [location]);
  // Handle default root
  const defaultRoot = "/job-info";
  React.useEffect(() => {
    if (location.pathname === "/claim") {
      history.push(`/claim${defaultRoot}${location.search}`);
    }
  }, [claimId]);

  if (!claimId) return null;

  return (
    <ClaimDetailsErrorBoundary>
      <ClaimMetaProvider claimId={parseInt(claimId)}>
        <div className={classes.root}>
          <ClaimDetailsInfo />
        </div>
        <Presentation
          claimId={parseInt(claimId)}
          location={location}
          classes={classes}
        />
      </ClaimMetaProvider>
      <div className={classes.margin}></div>
    </ClaimDetailsErrorBoundary>
  );
};

export default withStyles((theme) => ({
  "@supports ( -moz-appearance:none )": {
    root: {
      width: "110%",
      transformOrigin: "1rem 1rem",
      transform: "scale(0.91)",
      marginBottom: "-0.8rem",
    },
    form: {
      width: "110%",
      transformOrigin: "1rem 1rem",
      transform: "scale(0.91)",
    },
  },
  "@supports ( -webkit-appearance:none )": {
    root: {
      zoom: "92%",
    },
    form: {
      zoom: "92%",
    },
  },
}))(Claim);

const Presentation = ({ claimId, location, classes }) => {
  const routes = useClaimDetailRoutes(claimId);
  // const routes = setup ? usePreloadLazy(setup) : null
  if (!routes) return null;

  return (
    <>
      <ClaimDetailsMenu location={location} routes={routes} />
      <ClaimDetailsFabActions claimId={claimId} />
      <div className={classes.form}>
        <Suspense fallback={<SpinnerRect />}>
          <Switch>
            {routes &&
              routes.map(({ exact, path, component: Component }, key) => (
                <Route
                  key={key}
                  exact={exact}
                  path={path ? `/claim${path}` : undefined}
                  render={() => <Component claimId={claimId} />}
                />
              ))}
          </Switch>
        </Suspense>
      </div>
    </>
  );
};

const useClaimDetailRoutes = (claimId) => {
  let [routes, setRoutes] = React.useState(null);
  const meta = React.useContext(ClaimMetaContext);
  const { claim, user } = meta;
  const tabs = claim.view.tabs;
  const claimRoutes = [
    {
      exact: true,
      path: "/job-info",
      label: tabs.jobInfoTab.label,
      component: JobInfo,
    },
    {
      exact: true,
      path: "/make-safe",
      label: tabs.makeSafeTab.label,
      component: MakeSafe,
    },
    {
      exact: false,
      path: "/site-report",
      label: tabs.siteReportTab.label,
      component: SiteReport,
    },
    {
      exact: false,
      path: "/quote",
      label: tabs.quoteTab.label,
      component: Quote,
    },
    {
      exact: false,
      path: "/scope",
      label: tabs.scopeTab.label,
      component: Scope,
    },
    {
      exact: true,
      path: "/quotes",
      label: tabs.quotesTab.label,
      component: Quotes,
    },
    {
      exact: true,
      path: "/job-in-progress",
      label: tabs.jobProgressTab.label,
      component: JobInProgress,
    },
    {
      exact: true,
      path: "/histories",
      label: tabs.historyTab.label,
      component: Histories,
    },
    {
      exact: true,
      path: "/job-notes",
      label: tabs.jobNotesTab.label,
      component: JobNotes,
    },
    {
      exact: true,
      path: "/claim-survey",
      label: "Claim Survey",
      component: VehicleReport,
    },
    {
      exact: true,
      path: "/documents",
      label: tabs.documentsTab.label,
      component: Documents,
    },
    {
      exact: true,
      path: "/communications",
      label: tabs.communicationsTab.label,
      component: CommunicationsPage,
    },
    {
      exact: true,
      path: "/variations",
      label: tabs.variationsTab.label,
      component: Variations,
    },
    { exact: true, path: "/fees", label: tabs.feeTab.label, component: Fees },

    // Put lost adjuster to the last, TASK-787
    {
      exact: true,
      path: "/loss-adjuster",
      label: tabs.lossAdjusterTab.label,
      component: LossAdjuster,
    },
    {
      exact: true,
      path: "/internal-assessor",
      label: tabs.internalAssessorTab.label,
      component: InternalAssessor,
    },


    // { exact: true, path: '/invoice', label: 'Invoice', component: Invoice },

    // { exact: true, path: '/report', label: tabs.reportTab.label, component: Report },

    { exact: true, component: () => <Redirect to="/claims" /> },
  ];
  let setup = [...claimRoutes];
  const {
    hasBuilding,
    hasContents,
    hasRestoration,
    building,
    restoration /* contents */,
  } = claim;

  const buildingScopingSupplier = building && building.jobSuppliers[0];
  // const contentScopingSupplier = contents && contents.jobSuppliers[0]
  const restorationScopingSupplier = restoration && restoration.jobSuppliers[0];

  const builderAuthorisedSupplierId = getSafe(
    () => building.authorisedSupplier.companyId,
    ""
  );
  const restorerAuthorisedSupplierId = getSafe(
    () => restoration.authorisedSupplier.companyId,
    ""
  );
  const isAuthorisedSupplier =
    (user.type.isBuilder &&
      user.company.companyId === builderAuthorisedSupplierId) ||
    (user.type.isRestorer &&
      user.company.companyId === restorerAuthorisedSupplierId);

  React.useEffect(() => {
    // const isScopingSupplier =
    //   (
    //     user.type.isBuilder
    //       && (getSafe(() => building.scopingSupplier.companyId, '') === user.company.companyId)
    //   )
    //   || (
    //     user.type.isRestorer
    //       && (getSafe(() => restoration.scopingSupplier.companyId, '') === user.company.companyId)
    //   )

    // MAKE SAFE
    if (!tabs.makeSafeTab.isDisplay) {
      setup = setup.filter((route) => route.path !== "/make-safe");
    }

    // SITE REPORT
    if (!tabs.siteReportTab.isDisplay) {
      setup = setup.filter((route) => route.path !== "/site-report");
    }
    // QUOTE
    if (!tabs.quoteTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/quote");
    }
    // SCOPE
    if (!tabs.scopeTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/scope");
    }
    // QUOTES
    if (!tabs.quotesTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/quotes");
    }

    // JOB PROGRESS
    if (!tabs.jobProgressTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/job-in-progress");
    }

    // VARIATIONS
    if (!tabs.variationsTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/variations");
    }

    // FEES
    if (!tabs.feeTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/fees");
    }

    // REPORT
    if (!tabs.reportTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/report");
      setup = setup.filter((e) => e.path !== "/claim-survey");
    }

    // LOSS ADJUSTER
    if (!tabs.lossAdjusterTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/loss-adjuster");
    }

    if (!tabs.jobNotesTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/job-notes");
    }

    if (!tabs.jobInfoTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/job-info");
    }

    if (!tabs.documentsTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/documents");
    }

    if (!tabs.communicationsTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/communications");
    }

    if (!tabs.internalAssessorTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/internal-assessor");
    }
    if (!tabs.historyTab.isDisplay) {
      setup = setup.filter((e) => e.path !== "/histories");
    }

    setRoutes(setup);
  }, [claimId]);

  return routes;
};
