/**
 * @flow
 * @relayHash fe9012466283187bc1d65ed2aa4467a9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddClaimQuotingRestorersCard_details$ref = any;
export type ClaimJobStatus = "VariationApproved" | "VariationRejected" | "VariationReset" | "VariationSubmitted" | "%future added value";
export type JobRecommendationStatus = "NotRecommended" | "Recommended" | "%future added value";
export type PortfolioType = "Building" | "Contents" | "Restoration" | "%future added value";
export type QuoteRequestStatus = "AdditionalItems" | "AppointmentMade" | "AwaitingInfo" | "AwaitingInfoContents" | "ClaimFinalised" | "InitialCallMade" | "JobAuthorised" | "JobLost" | "NewClaims" | "OfferScopeAcc" | "QuoteSubmitted" | "WaitingQuotes" | "WaitingResult" | "WaitingScope" | "%future added value";
export type StateEnum = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type ClaimJobFilter = {|
  id?: ?string,
  parentId?: ?string,
  subject?: ?string,
  createDateFrom?: ?any,
  createDateTo?: ?any,
  incidentDate?: ?any,
  incidentDateFrom?: ?any,
  incidentDateTo?: ?any,
  keyWords?: ?string,
  refNumber?: ?string,
  postcode?: ?string,
  managerTeams?: ?$ReadOnlyArray<?string>,
  managers?: ?$ReadOnlyArray<string>,
  insurers?: ?$ReadOnlyArray<string>,
  adjusters?: ?$ReadOnlyArray<string>,
  agents?: ?$ReadOnlyArray<string>,
  catCodes?: ?$ReadOnlyArray<string>,
  eventTypes?: ?$ReadOnlyArray<string>,
  buildingSuppliers?: ?$ReadOnlyArray<string>,
  contentsSuppliers?: ?$ReadOnlyArray<string>,
  restorationSuppliers?: ?$ReadOnlyArray<string>,
  buildingStatus?: ?$ReadOnlyArray<string>,
  contentsStatus?: ?$ReadOnlyArray<string>,
  restorationStatus?: ?$ReadOnlyArray<string>,
  lossAdjusterStatus?: ?$ReadOnlyArray<string>,
  suppliers?: ?$ReadOnlyArray<string>,
  status?: ?$ReadOnlyArray<string>,
  internalAssessors?: ?$ReadOnlyArray<string>,
  jobStatuses?: ?$ReadOnlyArray<string>,
  claimStatusStageIds?: ?$ReadOnlyArray<string>,
  portfolios?: ?$ReadOnlyArray<?PortfolioType>,
  states?: ?$ReadOnlyArray<?StateEnum>,
  variationStatuses?: ?$ReadOnlyArray<?ClaimJobStatus>,
  requestStatuses?: ?$ReadOnlyArray<?QuoteRequestStatus>,
  recommendationStatus?: ?$ReadOnlyArray<JobRecommendationStatus>,
  or?: ?ClaimJobFilter,
  and?: ?ClaimJobFilter,
  not?: ?ClaimJobFilter,
|};
export type AddClaimQuotingRestorersCardRefetchQueryVariables = {|
  restorersWhere?: ?ClaimJobFilter
|};
export type AddClaimQuotingRestorersCardRefetchQueryResponse = {|
  +$fragmentRefs: AddClaimQuotingRestorersCard_details$ref
|};
export type AddClaimQuotingRestorersCardRefetchQuery = {|
  variables: AddClaimQuotingRestorersCardRefetchQueryVariables,
  response: AddClaimQuotingRestorersCardRefetchQueryResponse,
|};
*/


/*
query AddClaimQuotingRestorersCardRefetchQuery(
  $restorersWhere: ClaimJobFilter
) {
  ...AddClaimQuotingRestorersCard_details
}

fragment AddClaimQuotingRestorersCard_details on Query {
  restorers: claimFilterOptions(where: $restorersWhere) {
    label: name
    value
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "restorersWhere",
    "type": "ClaimJobFilter",
    "defaultValue": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddClaimQuotingRestorersCardRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "AddClaimQuotingRestorersCard_details",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddClaimQuotingRestorersCardRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "restorers",
        "name": "claimFilterOptions",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "restorersWhere",
            "type": "ClaimJobFilter"
          }
        ],
        "concreteType": "FilterOption",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": "label",
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "value",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AddClaimQuotingRestorersCardRefetchQuery",
    "id": null,
    "text": "query AddClaimQuotingRestorersCardRefetchQuery(\n  $restorersWhere: ClaimJobFilter\n) {\n  ...AddClaimQuotingRestorersCard_details\n}\n\nfragment AddClaimQuotingRestorersCard_details on Query {\n  restorers: claimFilterOptions(where: $restorersWhere) {\n    label: name\n    value\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c4f533a8321bb9c9260e40c1c8360280';
module.exports = node;
