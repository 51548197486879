import React, { Component } from "react";
import {
  QueryRenderer /* , createFragmentContainer */,
  graphql,
} from "react-relay";
import environment from "common/relay";
import { /* dateFormat, */ moneyFormat } from "common/utils";

import Paper from "components/Mui/Paper";
import Grid from "components/Mui/Grid";
import InfoBox from "components/Mui/InfoBox";
import Input from "components/Formik/Input";
import DateInput from "components/Formik/DateInput";
import Switch from "components/Formik/Switch/";

class ReportDetails extends Component {
  render() {
    const { claim, data, meta, isAbleToEdit, portfolios, vulnerableMember } =
      this.props;
    return (
      <Paper
        title={`${portfolios} Site Report Details`}
        content={
          <Grid fluid>
            <QueryRenderer
              environment={environment}
              query={query}
              variables={{
                supervisorsWhere: { isDisabled: false },
                eventTypeWhere: { subject: "eventTypes" },
                estimatorsWhere: { isDisabled: false },
                claimRecommendationsWhere: { subject: "claimRecommendations" },
                isClaimWhere: { subject: "isClaim" },
              }}
              render={({ error, props }) => {
                if (!props) return "Loading...";
                return (
                  <>
                    {[
                      {
                        col: 6,
                        component: InfoBox,
                        label:
                          "Incident date" /* label:'Date of incident'|'Incident date' */,
                        text: claim.incidentDetail.incidentDate,
                      },
                      {
                        col: 6,
                        component: InfoBox,
                        label: "Case Manager",
                        text:
                          claim.caseManager && claim.caseManager.managerName,
                      },
                      {
                        col: 6,
                        component: Input,
                        leadIcon: "assignment_ind",
                        label: "Supervisor",
                        select: true,
                        required: !meta.user.type.isAdmin,
                        name: "suppervisorId",
                        options: this.getOptions(
                          props.supervisors.items,
                          data.supervisor && {
                            label: data.supervisor.supervisorNameWithPhone,
                            value: data.supervisor.id,
                          }
                        ),
                      },
                      {
                        col: 6,
                        component: Input,
                        leadIcon: "assignment_ind",
                        label: "Estimator",
                        select: true,
                        required: !meta.user.type.isAdmin,
                        name: "estimatorId",
                        options: this.getOptions(
                          props.estimators.items,
                          data.estimator && {
                            label: data.estimator.estimatorNameWithPhone,
                            value: data.estimator.id,
                          }
                        ),
                      },
                      {
                        component: Input,
                        leadIcon: "assignment_ind",
                        label: "Claim Recommendation",
                        select: true,
                        required: true,
                        name: "claimRecommendationId",
                        options: this.getOptions(
                          props.claimRecommendations,
                          data.claimRecommendation && {
                            label: data.claimRecommendation.description,
                            value:
                              data.claimRecommendation.claimRecommendationId,
                          }
                        ),
                      },
                      {
                        col: 6,
                        component: DateInput,
                        label: "Attendance Date",
                        name: "reportDate",
                        disabled: true,
                      },
                      {
                        col: 6,
                        component: Input,
                        leadIcon: "assignment_ind",
                        label: "Are Emergency / Make Safe works required?",
                        select: true,
                        name: "emergencyMakeSafeWorks",
                        options: [
                          { label: "Yes", value: 1 },
                          { label: "No", value: 0 },
                        ],
                      },
                      {
                        col: 6,
                        component: InfoBox,
                        label: "Sum Insured",
                        text: moneyFormat(data.sumInsured),
                      },
                      {
                        col: 6,
                        component: InfoBox,
                        label: "Excess" /* label:'Excess Amount'|'Excess' */,
                        text: moneyFormat(data.excessValue),
                      },
                      {
                        col: 6,
                        component: InfoBox,
                        label: "Category of Claim",
                        text: claim.incidentDetail.category,
                        unmountOn: claim.insurer.hideCategoryOfClaim,
                      },
                      {
                        col: 6,
                        component: Input,
                        leadIcon: "assignment_ind",
                        label: "Is this a claim?",
                        select: true,
                        name: "isAClaimId",
                        options: this.getOptions(
                          props.isClaim,
                          data.siteReport &&
                            data.siteReport.isAClaim && {
                              label: data.siteReport.isAClaim.isAClaimName,
                              value: data.siteReport.isAClaim.isAClaimId,
                            }
                        ),
                      },
                      {
                        col: 6,
                        component: Switch,
                        label: vulnerableMember.label,
                        name: "vulnerableMember",
                        unmountOn: !vulnerableMember.isDisplay,
                        disabled: claim.incidentDetail.vulnerableMember,
                      },
                    ].map((child, key) => {
                      const {
                        col = 12,
                        unmountOn,
                        component: Component,
                        ...props
                      } = child;
                      return (
                        <Grid item xs={col} key={key}>
                          {Component && !unmountOn && (
                            <Component {...props} readOnly={!isAbleToEdit} />
                          )}
                        </Grid>
                      );
                    })}
                  </>
                );
              }}
            />
          </Grid>
        }
      />
    );
  }

  getOptions = (options, current) => {
    if (!current || current === null || current === undefined) return options;

    return options
      .map((option) => option.value)
      .indexOf(current.value.toString()) === -1
      ? [...options, current]
      : options;
  };
}

export default ReportDetails;

const query = graphql`
  query ReportDetailsQuery(
    $supervisorsWhere: SupervisorIndex
    $eventTypeWhere: ClaimJobFilter
    $estimatorsWhere: EstimatorIndex
    $claimRecommendationsWhere: ClaimJobFilter
    $isClaimWhere: ClaimJobFilter
  ) {
    supervisors(where: $supervisorsWhere) {
      items {
        label: nameWithPhone
        value
        id
      }
    }
    eventTypes: claimFilterOptions(where: $eventTypeWhere) {
      label: name
      value
    }
    estimators(where: $estimatorsWhere) {
      items {
        label: nameWithPhone
        value
        id
      }
    }
    claimRecommendations: claimFilterOptions(
      where: $claimRecommendationsWhere
    ) {
      label: name
      value
    }
    isClaim: claimFilterOptions(where: $isClaimWhere) {
      label: name
      value
    }
  }
`;
