import React, { Component } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import Grid from "components/Mui/Grid";
import { withStyles } from "@material-ui/core/styles";
import { moneyFormat } from "common/utils";
import DeleteDocumentMutation from "./DocumentsActions/DeleteDocumentMutation";

import Icon from "@material-ui/core/Icon";
import ClaimTypeIcon from "components/Mui/ClaimTypeIcon/";
//import ViewDocButton from 'components/ViewDocButton'

class DocumentsListItem extends Component {
  snackbar = window[Symbol.for("__snackbar")];
  state = {};

  render() {
    const { classes, item } = this.props;

    const supportedFormats = [
      "jpg",
      "png",
      "jpeg",
      "BMP",
      "gif",
      "mp4",
      "m4v",
      "pdf",
    ];
    const { url } = item;
    const ext = url
      .substring(url.lastIndexOf(".") + 1, url.length)
      .toLowerCase();
    const isSupported = supportedFormats.indexOf(ext) !== -1;

    return (
      <>
        <Grid fluid className={classes.listItem} spacing={0}>
          <Grid item xs={2} className={classes.alignCenter}>
            <ClaimTypeIcon type={item.portfolioType} />
            <span>{item.uploadDate}</span>
          </Grid>
          <Grid
            className={item.company ? classes.alignCenter : classes.center}
            item
            xs={2}
          >
            {item.company ? item.company.companyName : "-"}
          </Grid>
          <Grid item xs={1} className={classes.alignCenter}>
            {/* <ViewDocButton url={item.url} /> */}
            {isSupported ? (
              <Icon onClick={this.viewDocument}>description</Icon>
            ) : (
              <Icon component="a" href={url} target="_blank">
                description
              </Icon>
            )}
          </Grid>
          <Grid item xs={1} className={classes.alignCenter}>
            {item.private ? "Private" : "Public"}
          </Grid>
          <Grid
            item
            xs={true}
            className={
              item.description ? classes.alignLeft : classes.alignCenter
            }
          >
            {item.description ? item.description : "-"}
          </Grid>
          <Grid item xs={1} className={classes.alignCenter}>
            {item.amountInvoice && moneyFormat(item.amountInvoice)}
          </Grid>
          <Grid item xs={1} className={classes.alignCenter}>
            <Icon onClick={this.deleteDocument}>delete</Icon>
          </Grid>
        </Grid>
      </>
    );
  }

  viewDocument = async () => {
    this.props.setViewAll(false);
    this.props.setViewItemIndex(this.props.index);
    window[Symbol.for("__documentViewAllAction")].handleOpen();
  };

  deleteDocument = async () => {
    const res = await this.snackbar.toggleOpen({
      message: "Please confirm to delete the item",
      firstButton: "Delete",
      secondButton: "Cancel",
      type: "confirm",
    });
    if (res.ok) {
      const mutationRes = await DeleteDocumentMutation(
        {
          where: {
            id: this.props.item.documentId,
            portfolioType: this.props.item.portfolioType,
          },
        },
        this.props.item.id
      );
      if (mutationRes.success) {
        this.snackbar.toggleOpen({
          message: mutationRes.messages[0],
        });
      }
    }
  };
}

export default createFragmentContainer(
  withStyles((theme) => ({
    listItem: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      "&:nth-child(even)": {
        background: theme.palette.grey[100],
      },
      "&:hover": {
        background: theme.palette.grey[300],
      },
      position: "relative",
    },
    alignLeft: {
      display: "flex",
      alignItems: "flex-start",
    },
    alignCenter: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
    },
  }))(DocumentsListItem),
  graphql`
    fragment DocumentsListItem_item on ClaimDocument {
      id
      documentId
      portfolioType
      uploadDate
      company {
        companyName
      }
      url
      private
      description
      amountInvoice
    }
  `
);
