import React from 'react'
import Dialog from 'components/Mui/Dialog'
import { Formik } from 'formik'
import FormBody from './FormBody'
import { graphql } from 'relay-hooks'
import { useMutation } from 'react-relay-mutation'
import { useMetaContext } from 'pages/Claim/ClaimDetails/ClaimMetaContext.js'
import { useFeeReloadContext } from '../../FeeReloadContext'

const mutation = graphql`
  mutation FeeItemCreateDialogMutation($input: FeeItemCreateInput!) {
    feeItemCreate(input: $input) {
      success
      messages
      fieldErrors {
        fieldName
        level
        message
      }
    }
  }
`

export default ({ symbol }) => {
  const { claim: { claimId, hasBuilding, hasContents, hasRestoration } } = useMetaContext()
  const [approvedAmountMeta, setApprovedAmountMeta] = React.useState(null)
  const vehicleActions = window[Symbol.for('__vehicleActions')]

  const portfolios = []
  if(hasBuilding) portfolios.push({label: vehicleActions.isVehicleReport?'Car':'Building', value: 'Building'})
  if(hasRestoration) portfolios.push({label: 'Restoration', value: 'Restoration'})
  if(hasContents) portfolios.push({label: 'Contents', value: 'Contents'})

  const [portfolio, setPortfolio] = React.useState(portfolios[0].value)
  const { reload, setReload } = useFeeReloadContext()

  const [createFeeItem] = useMutation(mutation)

  return (
    <Dialog
      noForm
      noActions
      symbol={symbol}
      title="Add New Fee"
      content={
        <Formik
          initialValues={{
            feeCategory: '',
            feeTypeId: '',
            supplierId: '',
            authorisedAmount: '',
            claimPortfolioTypes: portfolios[0].value,
          }}
          validate={values => {
            let errors = {}

            if(!values.feeCategory) errors.feeCategory = 'Required!'
            if(!values.feeTypeId) errors.feeTypeId = 'Required!'
            if(!values.supplierId) errors.supplierId = 'Required!'
            if(!values.authorisedAmount) errors.authorisedAmount = 'Required!'

            if(String(values.authorisedAmount).length > (9 + 3)) errors.authorisedAmount = 'Maximum character limit is 9'
            const maxAmount = approvedAmountMeta ? approvedAmountMeta.maxAmount : null
            if (maxAmount !== null && maxAmount !== undefined) {
              if (values.authorisedAmount > maxAmount) errors.authorisedAmount = `The amount is over the limit`
            }

            return errors
          }}
          onSubmit={(values, actions) => {
            setTimeout(async () => {
              delete values.feeCategory
              delete values.claimPortfolioTypes
              const res = await createFeeItem({
                variables: {
                  input: {
                    ...values,
                    claimId
                  }
                }
              })
              if(res) {
                actions.resetForm()
                window[Symbol.for('__snackbar')].toggleOpen({ message: res.feeItemCreate.messages[0] })
                if(res.feeItemCreate.success) {
                  setReload(!reload)
                  window[Symbol.for(symbol)].handleClose()
                }
              }
            }, 300)
          }}
        >
          {
            (formik) => <FormBody
              claimId={claimId}
              formik={formik}
              symbol={symbol}
              portfolios={portfolios}
              portfolio={portfolio} setPortfolio={setPortfolio}
              setApprovedAmountMeta={setApprovedAmountMeta}
            />
          }
        </Formik>
      }
    />
  )
}