import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import QuoteActionBar from './QuoteActionBar'
import QuoteItemList from './QuoteItemList'
import QuoteContentsItemList from './QuoteContentsItemList'

const QuotePresentation = ({
  classes,
  meta,
  quotes,
  portfolio,
}) => {
  const quote = quotes.jobQuotes.edges.length !== 0
    ? quotes.jobQuotes.edges[0].node
    : null

  const isContentsAction = portfolio === 'Contents' && meta.user.type.isAdmin
  const isOwnCompany = quote && quote.supplier && quote.supplier.companyId
    === meta.user.company.companyId
  // const isAction = (isOwnCompany && !quote.completed) || isContentsAction

  const isAction =
    (isOwnCompany || isContentsAction || meta.claim.insurer.nonPanelSupplier)
    && !(Boolean(quote && quote.completed))
  return (
    <Grid container spacing={8} className={classes.root}>
      <Grid item xs={12}>
        <QuoteActionBar
          // scopeTypeId={quotes.jobScopeTypes && quotes.jobScopeTypes[0]}
          quote={quote} meta={meta}
          portfolio={portfolio}
        />
      </Grid>
      <Grid item xs={12} className={classes.bodyGroup}>
        {portfolio !== 'Contents'
          ? <QuoteItemList quote={quote} portfolio={portfolio} meta={meta} isAction={isAction} isOwnCompany={isOwnCompany} isContentsAction={isContentsAction}/>
          : <QuoteContentsItemList quote={quote} portfolio={portfolio} meta={meta} isAction={isAction} isOwnCompany={isOwnCompany} isContentsAction={isContentsAction}/>
        }
      </Grid>
    </Grid>
  )
}

export default withStyles(theme => ({
  root: {
    marginTop: theme.spacing.unit
  },
  bodyGroup: {
    position: 'relative',
    paddingTop: '0 !important',
    paddingLeft: '0 !important'
  }
}))(QuotePresentation)
