/**
 * @flow
 * @relayHash b12cc99a7518e8856e95ae9e2e12f68d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddVariationQueryVariables = {||};
export type AddVariationQueryResponse = {|
  +reasons: ?$ReadOnlyArray<?{|
    +label: string,
    +value: number,
  |}>
|};
export type AddVariationQuery = {|
  variables: AddVariationQueryVariables,
  response: AddVariationQueryResponse,
|};
*/


/*
query AddVariationQuery {
  reasons: jobVariationReasons {
    label: reasonDescription
    value: variationReasonId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": "reasons",
    "name": "jobVariationReasons",
    "storageKey": null,
    "args": null,
    "concreteType": "JobVariationReason",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": "label",
        "name": "reasonDescription",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": "value",
        "name": "variationReasonId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddVariationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddVariationQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "AddVariationQuery",
    "id": null,
    "text": "query AddVariationQuery {\n  reasons: jobVariationReasons {\n    label: reasonDescription\n    value: variationReasonId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c06e9fa9f2843b9a8d8d29bcd1c5a4a6';
module.exports = node;
