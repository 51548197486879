import { commitMutation, graphql } from 'react-relay'
import { ConnectionHandler } from 'relay-runtime'
import environment from 'common/relay'

const mutation = graphql`
  mutation MakeSafeResetMutation($where: ENDataPortfolioKey!) {
    jobMakeSafeQuoteReset(where: $where) {
      messages
      success
      result {
        completed
      }
    }
  }
`

export default variables => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      updater: store => {
        const success = store.getRootField('jobMakeSafeQuoteReset').getValue('success')
        if(success) {
          const root = store.getRoot()
          const conn = ConnectionHandler.getConnection(root, 'MakeSafeContainerQuery_makeSafes')
          const node = conn.getLinkedRecords('edges')[0].getLinkedRecord('node')
          
          const newCompleted = store.getRootField('jobMakeSafeQuoteReset')
            .getLinkedRecord('result')
            .getValue('completed')

          node.setValue(newCompleted, 'completed')
        }
      },
      onCompleted: res => {
        resolve({
          ok: true,
          success: res.jobMakeSafeQuoteReset.success,
          messages: res.jobMakeSafeQuoteReset.messages
        })
      },
      onError: errors => resolve({ ok: false })
    })
  })
}