import React from 'react'

import QuotesContentPresentation from './'

const QuotesContentContainer = ({ quotes, portfolioType }) => {
  return <QuotesContentPresentation
    quotes={quotes}
    portfolioType={portfolioType}
  />
}

export default QuotesContentContainer