import React from 'react'

import Paper from 'components/Mui/Paper'
import EditorInput from 'components/Formik/EditorInput'

export default ({ isAbleToEdit, portfolios }) => {
  return (
    <Paper title={`${portfolios} Site Report Description`}>
      <EditorInput
        style={{ height: '70vh', overflow: 'auto' }}
        name='siteReportDescription'
        disabled={!isAbleToEdit}
      />
    </Paper>
  )
}